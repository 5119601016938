import { Action } from '@ngrx/store';

export enum EProductActions {
    CHILD_GROUP_LOAD = '[Child Group] Subgroepen worden ingeladen',
    CHILD_GROUP_LOAD_SUCCESS = '[Child Group] Subgroepen ingelezen',
    SELECT_A_CHILD_GROUP = '[Child Group] Kies een subgroep',
    CLEAR_A_CHILD_GROUP = '[Child Group] Leegmaken subgroep.'
}

export class ChildGroupLoadAction implements Action {
    public readonly type = EProductActions.CHILD_GROUP_LOAD;
    constructor(public productGroupCode: string) { }
}

export class ChildGroupLoadSuccess implements Action {
    public readonly type = EProductActions.CHILD_GROUP_LOAD_SUCCESS;
    constructor(public childGroup: any[]) { }
}

export class SelectAChildGroup implements Action {
    public readonly type = EProductActions.SELECT_A_CHILD_GROUP;
    constructor(public childGroup: string) { }
}

export class ClearAChildGroup implements Action {
    public readonly type = EProductActions.CLEAR_A_CHILD_GROUP;
    constructor(public childGroup: any) { }
}

export type ChildGroupActionsList = ChildGroupLoadAction | ChildGroupLoadSuccess | SelectAChildGroup | ClearAChildGroup;
