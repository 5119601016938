<div class="sales-forecast pt-5">
  <div class="container">
    <div class="row">
      <div class="col-md-8">
        <h4 class="green-text title-3">SIM Card Forecasts {{'SALES_FORECAST.SIM_Card_Forecasts' | transloco}}</h4>
        <p>{{'SALES_FORECAST.SALES_FORECAST_ALERT' | transloco}}.
          <span class="d-block">{{'SALES_FORECAST.SALES_FORECAST_ALERT_2' | transloco}}.</span>
        </p>
      </div>
    </div>
    <div class="kpn-spinner" *ngIf="showLoader">
      <div class="text-center">
        <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
        <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
      </div>
    </div>
    <div *ngIf="!showLoader">
      <div class="row justify-content-between">
        <div class="col-md-auto">
          <p class="dove-grey">{{'SALES_FORECAST.TEST_CUSTOMER_ANALYSIS' | transloco}}</p>
        </div>
        <div class="col-md-auto">
          <p class="dove-grey">{{planningText}}</p>
        </div>
      </div>
      <div class="mb-4">
        <div class="row">
          <div class="offset-3 col-md-3 col-3 col-gap-reduce">
            <div class="form-group kpn-form-group">
              <label>{{'SALES_FORECAST.VALID' | transloco}}</label>
              <input [max]="maxYear" (ngModelChange)="onChangeFromDate($event)"
                (dateTimeChange)="onChangeDate(dateFrom)" [(ngModel)]="fromDate" #dateFrom="ngModel"
                [owlDateTime]="validFrom" [owlDateTimeTrigger]="validFrom" [placeholder]="'VOUCHER_LIST.VALID' | transloco"
                class="form-control kpn-form-control" />
              <owl-date-time [pickerType]="'calendar'" #validFrom></owl-date-time>
              <div *ngIf="dateFrom.invalid && dateFrom.errors.owlDateTimeMax">
                <small class="danger-text"> {{'SALES_FORECAST.DATE_MUST_BE_VALID_TILL' | transloco}} {{maxYear | date: 'dd-MM-yyyy'}} </small>
              </div>
            </div>
          </div>
          <div class="col-md-3 col-3 col-gap-reduce">
            <div class="form-group kpn-form-group">
              <label>{{'VOUCHER_LIST.VALID_UNTIL' | transloco}}</label>
              <input [min]="validFromDate" (dateTimeChange)="onChangeDate(dateTo)" [max]="maxYear" [(ngModel)]="toDate"
                #dateTo="ngModel" [owlDateTime]="validUntil" [placeholder]="'VOUCHER_LIST.VALID_UNTIL' | transloco"
                class="form-control kpn-form-control" [owlDateTimeTrigger]="validUntil" />
              <owl-date-time [pickerType]="'calendar'" #validUntil></owl-date-time>
              <div *ngIf="dateTo.invalid && dateTo.errors.owlDateTimeMin">
                <small class="danger-text"> {{'SALES_FORECAST.DATE_MUST_BE_GREATER_OR_EQUAL_TO' | transloco}} {{validFromDate | date:
                  'dd-MM-yyyy'}}</small>
              </div>
              <div *ngIf="dateTo.invalid && dateTo.errors.owlDateTimeMax">
                <small class="danger-text"> {{'SALES_FORECAST.DATE_MUST_BE_VALID_TILL' | transloco}} {{maxYear | date: 'dd-MM-yyyy'}} </small>
              </div>
            </div>
          </div>
        </div>

        <div class="white-wrapper">
          <div class="column-fixed-wrapper">
            <div class="column-fixed-scroller custom-table">
              <table class="table column-fixed-table">
                <thead>
                  <tr>
                    <th class="column-fixed-sticky-col2"></th>
                    <th class="text-right">{{'SALES_FORECAST.YEAR' | transloco}}</th>
                    <th *ngFor="let year of forecastHeader">{{year?.year}}</th>
                    <th class="column-fixed-sticky-col4">{{'SALES_FORECAST.TOTAL' | transloco}}</th>
                  </tr>
                  <tr>
                    <th class="column-fixed-sticky-col">{{'PRODUCT_LIST.PRODUCT_NAME' | transloco }}</th>
                    <th class="column-fixed-sticky-col2">Code</th>
                    <th class="text-right">{{'SALES_FORECAST.MONTH' | transloco}}</th>
                    <th width="10%" *ngFor="let year of forecastHeader">{{year?.month?.short_name}}</th>
                    <th class="bg-white column-fixed-sticky-col4"> &nbsp;</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of forecastProducts | paginate: config">
                    <td class="column-fixed-sticky-col" [ngbTooltip]="item?.product.name">
                      {{item?.product.name}}
                    </td>
                    <td class="column-fixed-sticky-col2" [ngbTooltip]="item?.product.code">{{item?.product.code}}</td>
                    <td>&nbsp;</td>
                    <td *ngFor="let forecast of item.forecasts">
                      <span class="grey-text" *ngIf="forecast?.is_locked">{{forecast?.quantity > 0 ? forecast?.quantity
                        :
                        '-'}}</span>
                      <div class="field-with-spinner">
                        <input [(ngModel)]="forecast.quantity" [patterns]="pattern" mask="111111111"
                          [disabled]="forecast.loading" (keyup.enter)="addSingleProduct(item, forecast )"
                          (keydown.tab)="addByPressTab(item, forecast )" *ngIf="!forecast?.is_locked" type="text"
                          class="form-control form-control-sm forecast-qty"
                          [placeholder]="'SALES_FORECAST.ENTER_VALUE_AND_THEN_ENTER_OR_TAB' | transloco">
                        <div *ngIf="!forecast?.is_locked && forecast.loading"
                          class="spinner-border spinner-border-sm field-spinner" role="status">
                        </div>
                      </div>
                    </td>
                    <td class="grey-text column-fixed-sticky-col4"> {{item.total}}</td>
                  </tr>
                </tbody>
                <thead>
                  <tr>
                    <th colspan="2">{{'SALES_FORECAST.OVERALL_FORECAST' | transloco}}</th>
                    <th *ngFor="let item of forecastHeader" class="grey-text" width="10%"><span
                        class="pl-1">{{item.monthly_total}}</span></th>
                    <th *ngIf="forecastHeader?.length" class="grey-text column-fixed-sticky-col4" width="10%"><span
                        class="pl-1">{{totalSum}}</span>
                    </th>
                  </tr>
                </thead>
              </table>
            </div>
          </div>
        </div>
        <div class="pagination-new" *ngIf="config.totalItems">
          <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
            (pageChange)="onPageClick($event)"></pagination-controls>
        </div>
      </div>
    </div>
  </div>
