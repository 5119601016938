import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { HandsetsService } from './handsets.service';
import { CartService } from '../cart/cart.service';
import { StorageService } from '../../shared/storage.service';


@Component({
  selector: 'app-handsets',
  templateUrl: './handsets.component.html',
  styleUrls: ['./handsets.component.scss']
})
export class HandsetsComponent implements OnInit {

  products$: Observable<any>;

  isLoading = true;

  constructor(
    private handsetService: HandsetsService,
    private storageService: StorageService,
    private router: Router,
    private cartService: CartService) {
  }

  ngOnInit() {
    this.getHandSets();
  }

  getHandSets() {
    this.products$ = this.handsetService.getHandSets().pipe(
      tap(res => {
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
        this.router.navigateByUrl('/');
      }),
      map(res => res.data.results)
    );
  }

  onAddToCart(product: any) {
    const cart = {
      'market_type_code': 'business',
      'lines': [
        {
          'product_code': `${product.child_products[0].code}`,
          'product_name': `${product.child_products[0].name}`,
          'product_type': `handset`,
          'quantity': 1,
          'flow_type_code': 'new'
        }
      ]
    };

    const contactPersonUUID = this.storageService.getContactPersonUUID();
    const shippingAddressId = this.storageService.getShippingAddressID;
    if (contactPersonUUID) {
      cart['contact_person_uuid'] = contactPersonUUID;
      cart['shipping_address_id'] = shippingAddressId || null ;
    }

    this.cartService.createCart(cart).subscribe(res => {
      this.storageService.setCart(res.data);
    }, err => {
      console.log('err: ', err);
    });
  }

  onProductDetail(product: any) {
    this.handsetService.goToProductDetails(product.code);
  }

}
