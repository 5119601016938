<div class="checkout-process-stepbar checkout-process-stepbar-quote">
  <div class="step-item" (click)="backToCart()"
    [ngClass]="{'active-step':( activeStep === 'overview' ||  activeStep === 'customer_select' || activeStep=== 'order-overview'), 'completed-step': completeStepOne }">
    <h6 class="step-name"> 
      <i class="pb-1">
        <svg width="20" height="19" viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H2.17C3.12886 0 3.97257 0.693074 4.13582 1.6522L4.13682 1.65807L4.1368 1.65807L4.52274 4.01H17.99C19.284 4.01 20.2517 5.22626 19.9292 6.49615C19.9255 6.51098 19.9214 6.52572 19.9169 6.54036L18.3338 11.7578C17.9923 13.0849 16.7945 14 15.43 14H5.83C4.86408 14 4.04494 13.3126 3.86713 12.3643L3.8632 12.3419L2.16616 2H1C0.447715 2 0 1.55228 0 1ZM4.85092 6.01L5.83375 11.9995L5.83391 12H15.43C15.896 12 16.291 11.688 16.3992 11.25C16.4034 11.2331 16.408 11.2163 16.4131 11.1996L17.9878 6.01H4.85092ZM5 17C5 15.8954 5.89543 15 7 15C8.10457 15 9 15.8954 9 17C9 18.1046 8.10457 19 7 19C5.89543 19 5 18.1046 5 17ZM15 15C13.8954 15 13 15.8954 13 17C13 18.1046 13.8954 19 15 19C16.1046 19 17 18.1046 17 17C17 15.8954 16.1046 15 15 15Z"/>
        </svg>
      </i> 
      {{'STEPS.YOUR_SHOPPING_CART' | transloco }}</h6>
  </div>
  <div class="step-item" (click)="backToCustomer()"
    [ngClass]="{'active-step': (activeStep === 'customer_select' || activeStep=== 'order-overview'), 'completed-step': completeStepTwo }">
    <h6 class="step-name"> <i class="kphicon-uniE97E"></i>{{'STEPS.YOUR_DATA' | transloco }} </h6>
  </div>
  <div class="step-item"
    [ngClass]="{'active-step': activeStep === 'order-overview', 'completed-step': completeStepThree }">
    <h6 class="step-name"> 
      <i class="pb-1">
        <svg width="16" height="20" viewBox="0 0 16 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 3C2 2.45228 2.45228 2 3 2H9V6C9 6.55229 9.44771 7 10 7H14V17C14 17.5477 13.5477 18 13 18H3C2.45228 18 2 17.5477 2 17V3ZM16 6V17C16 18.6523 14.6523 20 13 20H3C1.34772 20 0 18.6523 0 17V3C0 1.34772 1.34772 0 3 0H10H10.17C10.9608 0 11.7289 0.312421 12.2913 0.887106L15.1171 3.71289C15.6806 4.27638 16 5.02911 16 5.83V6ZM11 2.42421L13.5758 5H11V2.42421Z" />
        </svg>
      </i> 
    {{'STEPS.TO_CONFIRM' | transloco }} </h6>
  </div>
  <div class="step-item" [ngClass]="{'active-step': activeStep === 'order-success', 'completed-step': completeStepFour }">
    <h6 class="step-name"> <i class="kphicon-uniE91D"></i> {{'STEPS.THANK_YOU' | transloco }}</h6>
  </div>
</div>
