import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { QuoteDetailsService } from '../../../quote-details.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-quote-details-summary',
  templateUrl: './quote-details-summary.component.html',
  styleUrls: ['./quote-details-summary.component.scss']
})
export class QuoteDetailsSummaryComponent {
  // @Input () quote: any;
  @Input() showRejectButton: boolean = false;
  @Input() showPlaceOrderButton: boolean = false;
  @Input () showHeader: boolean = true;
  @Input () bottomZero : boolean = false;
  @Output() openRejectModal: EventEmitter<void> = new EventEmitter();

  removeUnderscoreMethod = this.utilityService.removeUnderscoreFromString;
  isLoading: boolean;
  quote = null;
  additionalCosts = [];
  currentCustomerDetailsQuoteSubscription$: Subscription;
  constructor(

    private utilityService: UtilityService,
    private router: Router,
    private quoteDetailsService: QuoteDetailsService,
    private toastrService: ToastrService

  ) {
   this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe((quote) => {
      this.quote = quote;
      let sysTemTypes = [];
      this.additionalCosts = [];
      if (quote) {
        this.quote.lines = quote.lines?.map((line) => {

          if (line.product_type?.system_type === "additional_cost") {
            this.additionalCosts.push(line);
            return {
              ...line,
              showSystemTypeInFrontend: false,
              showLine: false
            };
          }

          if (sysTemTypes.includes(line.product_type.system_type)) {
            return {
              ...line,
              showSystemTypeInFrontend: false,
              showLine: true
            };
          }
          else {
            sysTemTypes.push(line.product_type.system_type);
            return {
              ...line,
              showSystemTypeInFrontend: true,
              showLine: true
            };
          }
        });
        if (this.quote?.subscription?.lines?.length) {
          let subscriptionSystemTypes = [];
          this.quote.subscription.lines = quote.subscription?.lines?.map((line) => {
            if (subscriptionSystemTypes.includes(line.product_type.system_type)) {
              return {
                ...line,
                showSystemTypeInFrontend: false,
              };
            }
            else {
              subscriptionSystemTypes.push(line.product_type.system_type);
              return {
                ...line,
                showSystemTypeInFrontend: true,
              };
            }
          });
        }
      }
    });
   }
  get eQuoteStatus() { return EQuoteStatus; }

  rejectOffer() {
    this.openRejectModal.emit();
  }
  goToInformationPage() {
    this.router.navigate(['/customer/quote-details-information/', `${this.quote.uuid}`]);
  }
   countTotalProductsQty() {
    return this.quote?.lines?.reduce((acc, line) => {
      const lineQty = line.qty || 0;
      const subLineQty = line.sub_lines?.reduce((acc, subLine) => {
        return acc + subLine.qty;
      }, 0);
      return acc + lineQty + subLineQty;
    }, 0);
  }
  createNewQuote(uuid: string) {
    this.isLoading = true;
    this.quoteDetailsService.createNewQuoteByCustomer(uuid).subscribe(
      (res) => {
        this.toastrService.success(res?.data?.message, 'Success');
        // this.quoteUuid = res?.data?.uuid;
        // this.getQuoteDetails();
        this.isLoading = false;
        this.router.navigate(['/customer/quote-details', `${res?.data?.uuid}`])
      }, error => {
        this.isLoading = false;
        this.toastrService.error(error?.error?.message, 'Error');
      }
    );
  }
  goToOrderDetails(){
    this.router.navigate(['/order-confirmation', `${this.quote?.order?.uuid}`]);

  }
  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
  }

}
