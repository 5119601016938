import { Component, OnInit } from '@angular/core';
import { FavoriteProductsService } from './favorite-products.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CartService } from '../cart/cart.service';
import { StorageService } from 'src/app/shared/storage.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-favorite-products',
  templateUrl: './favorite-products.component.html',
  styleUrls: ['./favorite-products.component.scss']
})
export class FavoriteProductsComponent implements OnInit {

  public wishlistData = null;
  public cart = null;
  public cartError = null;
  public hasWishListDeleteItemPermission = false;

  constructor(
    private router: Router,
    private favoriteProductsService: FavoriteProductsService,
    private toasterService: ToastrService,
    private storageService: StorageService,
    private cartService: CartService,
    private translocoService: TranslocoService) {

    this.favoriteProductsService.favoriteProducts$.subscribe(response => {
      this.wishlistData = response;
    })

    this.cartService.currentCart$.subscribe(res => {
      this.cart = res;
    });

    const permissions = this.storageService.getPermission();
    this.hasWishListDeleteItemPermission = permissions.some(permission => {
      return ['wishlist.delete_wishlistitem'].includes(permission.code);
    });

  }


  checkProductInCart(product_code) {
    return this.cart?.lines?.find(line => {
      return line?.product_code === product_code
    }) || null
  }

  ngOnInit(): void {
  }

  gotoProductDetails(product_code) {
    this.router.navigate(['/product-details', `${product_code}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  removeFromFavorite(wishlist_item) {
    if (!this.hasWishListDeleteItemPermission) {
      this.toasterService.info(`${this.translocoService.translate('WISH_LIST.PERMISSION_DENIED')}`);
      return;
    }
    this.favoriteProductsService.removeFromFavoriteList(wishlist_item?.uuid);
  }


  onAddToCart(selectedProduct) {
    selectedProduct['showSpinner'] = true;
    let quantity = this.checkProductInCart(selectedProduct.code)?.quantity + 1 || 1
    const cart = {
      market_type_code: 'business',
      lines: [
        {
          product_code: selectedProduct.code,
          product_name: selectedProduct.name,
          quantity: quantity,
          flow_type_code: 'new',
          sub_lines: []
        },
      ],
    };

    const contactPersonUUID = this.storageService.getContactPersonUUID();
    if (contactPersonUUID) {
      // tslint:disable-next-line:no-string-literal
      cart['contact_person_uuid'] = contactPersonUUID;
    }

    const shippingAddressUuid = this.storageService.getShippingAddressID() || null;
    if (shippingAddressUuid) {
      // tslint:disable-next-line:no-string-literal
      cart['shipping_address_id'] = shippingAddressUuid;
    }


    if (!this.cart?.uuid) {
      this.createCart(cart, selectedProduct);
    } else {
      const findProduct = this.checkProductInCart(selectedProduct?.code);
      if (findProduct) {
        this.updateCartItem(findProduct, selectedProduct, quantity);
      } else {
        this.updateCart(this.cart?.uuid, cart.lines[0], selectedProduct);
      }
    }
  }

  createCart(cart, product) {
    this.cartError = null;
    if (product.hasOwnProperty('showSpinner')) {
      product.showSpinner = true;
    }
    this.cartService.createCart(cart).subscribe(
      (res) => {
        this.cart = res.data;
        this.updateCartStore(res.data, product);
        this.toasterService.success(`${product?.name} ${this.translocoService.translate('WISH_LIST.ADDED_TO_CART')}`);
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      },
      (err) => {
        this.toasterService.error(err?.error?.message || `${this.translocoService.translate('WISH_LIST.OPERATION_FAILED')}!`);
        this.cartError = err?.error?.message;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      }
    );
  }

  updateCart(cartId, cartLines, product) {
    this.cartError = null;
    if (product.hasOwnProperty('showSpinner')) {
      product.showSpinner = true;
    }
    this.cartService.updateCart(cartId, cartLines).subscribe(
      (res) => {
        this.cart = res.data;
        this.updateCartStore(res.data, product);
        this.toasterService.success(`${product?.name} ${this.translocoService.translate('WISH_LIST.ADDED_TO_CART')}`);
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      },
      (err) => {
        this.toasterService.error(err?.error?.message);
        this.cartError = err?.error?.message;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      }
    );
  }

  updateCartItem(product, selectedProduct, defaultQty = 1) {
    this.cartError = null;
    if (selectedProduct.hasOwnProperty('showSpinner')) {
      selectedProduct.showSpinner = true;
    }

    const payload = {
      quantity: defaultQty,
    };

    this.cartService.updateCartLine(this.cart.uuid, product.uuid, payload).subscribe(res => {
      this.cart = res.data;
      this.updateCartStore(res.data, selectedProduct);
      this.toasterService.success(`${selectedProduct?.name} ${this.translocoService.translate('WISH_LIST.ADDED_TO_CART')}`);
      if (selectedProduct.hasOwnProperty('showSpinner')) {
        selectedProduct.showSpinner = false;
      }
    }, err => {
      this.toasterService.error(err?.error?.message);
      this.cartError = err?.error?.message;
      if (selectedProduct.hasOwnProperty('showSpinner')) {
        selectedProduct.showSpinner = false;
      }
    });
  }

  updateCartStore(data: any, product = null) {
    this.cartService.changeCurrentCart(data);
    this.storageService.setCart(data);
    const selectedProduct = data?.lines?.find(line => line?.product_code === product.code) || null;
    this.cartService.saveOrderUnitOfCartProducts({ product_code: product?.code, order_unit: product?.order_unit ?? 1 }, false);
    this.storageService.setSelectedCartProduct([
      {
        quantity: data?.lines?.find(obj => obj?.product_code === product?.code)?.quantity || 1,
        code: product?.code,
        image: selectedProduct?.image || '../../../assets/images/image-not-available.png',
        name: product?.name,
        color: product?.dimensional_attributes?.find(item => item.attribute_code === 'color')?.attribute_value || '',
        memory_size: product?.dimensional_attributes?.find(item => item.attribute_code === 'memory_size')?.attribute_value || ''
      }
    ]);
  }


}
