<section class="bg-white">
  <app-loader [isLoading]="isLoading"></app-loader>

  <section class="res-search-section pt-0">
    <div class="container" *ngIf="user?.contact_group?.is_external">
      <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-4 pb-2">
        <li  role="button" class="no-arrow"> <a class="grey-text" [routerLink]="['/']" >Home </a></li>
        <li > <a> Offerte overzicht</a> </li>
      </ul>

      <h3 class="font-kpn-black green-text fs-40 mb-3">Offerte overzicht</h3>

      <div class="tri-shadow-xl tri-rounded-20 bg-white mb-3">
        <div class="py-3 c-px-24">
          <ul class="qoute-tab-nav pt-2 mb-4">
            <li (click)="onTabChange(true)" [ngClass]="{'active' : isRunningQuoteTabActive}"  ><span>Lopende aanvragen</span></li>
            <li (click)="onTabChange(false)" [ngClass]="{'active' : !isRunningQuoteTabActive}"><span >Eerdere aanvragen</span></li>
          </ul>

          <div class="d-flex align-items-center mb-2">
            <div class="quote-search d-inline-block">
              <svg class="quote-search-icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="UI Icon">
                <path id="Color" d="M22.275 21.225L17.385 16.29C18.705 14.73 19.5 12.705 19.5 10.5C19.5 5.535 15.465 1.5 10.5 1.5C5.535 1.5 1.5 5.535 1.5 10.5C1.5 15.465 5.535 19.5 10.5 19.5C12.72 19.5 14.76 18.69 16.335 17.34L21.225 22.275C21.375 22.425 21.57 22.5 21.75 22.5C21.93 22.5 22.125 22.425 22.275 22.29C22.575 21.99 22.575 21.525 22.275 21.225ZM3 10.5C3 6.36 6.36 3 10.5 3C14.64 3 18 6.36 18 10.5C18 12.555 17.175 14.415 15.825 15.765C14.46 17.13 12.585 17.985 10.5 17.985C6.36 17.985 3 14.625 3 10.485V10.5Z" fill="#939393"/>
                </g>
              </svg>
              <input type="text" class="form-control kpn-form-control" [(ngModel)]="searchTerm" (ngModelChange)="onSearchTermChange()"  placeholder="Zoeken">
            </div>
            <button
              (click)="onClickQuoteFilter(quoteListFilterModal)"
              class="quote-search-filter ml-2 d-flex align-items-center">
              <i class="kphicon-uniE93F mr-1"></i>
              Filters
            </button>
            <ng-template #quoteListFilterModal let-modal>
              <div class="p-x-24">
                <div class="modal-header tri-modal-header px-0">
                    <h6 class="modal-title green-text font-kpn-black fs-40" id="quoteListFilter">
                        Filters
                    </h6>
                    <button type="button" class="btn close" (click)="modal.dismiss('Cross click');clearFilter()" aria-label="Close">
                        <span class="kpnew-icon-close-new"></span>
                    </button>
                </div>
              </div>

              <div class="modal-body p-24">
                  <div class="row align-items-center">
                      <div class="col-md-12">
                          <div class="p-0">
                              <div>
                                  <div class="mb-3 pb-1">
                                    <h3 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-18 mb-1 pb-1">Status</h3>
                                      <div *ngFor="let item of quoteStatusList">
                                        <label class="checkbox-item checkbox-item-tri d-inline-flex align-items-center mb-1">
                                          <div class="checkbox-btn checkbox-btn-outline">
                                            <input type="checkbox"  [checked]="filterData.status.includes(item)"
                                            (change)="onStatusChange($event.target.checked, item)" [value]="item"  name="quote-status" >
                                            <span class="checkBoxCheckmark checkBoxCheckmark-14"></span>
                                          </div>
                                          <div class="checkbox-label font-kpn-extended ml-2">
                                            <span class="text-capitalize">{{  getModifiedStatusName(item)  | titlecase }} </span>
                                          </div>
                                        </label>
                                      </div>
                                  </div>

                                  <div class="mb-4">
                                    <h3 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-18 mb-1 pb-1">Aanmaakdatum</h3>
                                      <div class="row gutter-row-16 overflow-hidden">
                                        <div class="col-md-6 gutter-col-16">
                                          <div class="custom-datepicker-container">
                                            <span class="date-label font-kpn-extended">Van</span>
                                            <input  class="form-control tri-form-control"  [(ngModel)]="filterData.dateFrom"   [max]="currentDate"   [owlDateTime]="dateFrom"    [owlDateTimeTrigger]="dateFrom"  />
                                            <owl-date-time [pickerType]="'calendar'" #dateFrom ></owl-date-time>
                                            <!-- <input type="date"  [(ngModel)]="filterData.dateFrom" [max]="currentDate"  class="form-control tri-form-control" placeholder="Van" > -->
                                            <img class="icon-calendar" [owlDateTimeTrigger]="dateFrom" src="../../../assets/images/calendar-icon.svg" alt="">
                                          </div>
                                        </div>
                                        <div class="col-md-6 gutter-col-16">
                                          <div class="custom-datepicker-container">
                                            <span class="date-label font-kpn-extended">tot</span>
                                            <!-- <input type="date" [(ngModel)]="filterData.dateTo" [max]="currentDate" class="form-control tri-form-control" placeholder="tot"> -->
                                            <input  class="form-control tri-form-control"  [(ngModel)]="filterData.dateTo"   [max]="currentDate"  [owlDateTime]="dateTo"    [owlDateTimeTrigger]="dateTo"  />

                                            <owl-date-time [pickerType]="'calendar'" #dateTo ></owl-date-time>
                                            <!-- <input type="date"  [(ngModel)]="filterData.dateFrom" [max]="currentDate"  class="form-control tri-form-control" placeholder="Van" > -->
                                            <img class="icon-calendar" [owlDateTimeTrigger]="dateTo" src="../../../assets/images/calendar-icon.svg" alt="">
                                          </div>
                                        </div>
                                      </div>
                                  </div>

                                  <div class="mb-3">
                                    <h3 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-18 mb-1 pb-1">Aangemaakt door</h3>
                                      <div *ngFor="let item of createdBy">
                                        <label
                                          class="checkbox-item checkbox-item-tri d-inline-flex align-items-center mb-0">
                                          <div class="checkbox-btn checkbox-btn-outline">
                                              <input type="checkbox"  [checked]="filterData.createdBy.includes(item)"
                                              (change)="onCreatedByChange($event.target.checked, item)" [value]="item">
                                              <span class="checkBoxCheckmark checkBoxCheckmark-14"></span>
                                          </div>
                                          <div class="checkbox-label font-kpn-extended ml-2">
                                              <span>{{ item?.value }}</span>
                                          </div>
                                      </label>
                                      </div>

                                  </div>

                                  <div class="pt-0">
                                    <div class="d-flex align-items-center mb-2">
                                        <button (click)="onFilterQuotes()"
                                            class="btn px-4 btn-primary mt-2 mr-2 rounded-btn">
                                            <span class="px-2 d-inline-block">Opslaan</span>
                                        </button>
                                        <button (click)="clearFilter()"
                                            class="btn px-4 btn-outline-primary border-2 mt-2 rounded-btn">
                                            <span class="px-2 d-inline-block">Annuleren</span>
                                        </button>
                                    </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
            </ng-template>
          </div>

        </div>

        <div class="quotes-list-table-with-border sortable-table-list">
          <table class="table w-100 mb-4">
            <thead>
              <tr>
                <th>
                  <button class="sortable-table-btn font-kpn-extended letter-spacing-normal kpn-extended-bold" [ngClass]="{'active' : sortColumn === 'reference'}" (click)="sortData('reference')">Referentie <i class="kphicon-uniE930"></i></button>
                </th>
                <th>
                  <!-- active -->
                  <button class="sortable-table-btn font-kpn-extended letter-spacing-normal kpn-extended-bold" [ngClass]="{'active' : sortColumn === 'modifiedStatus'}" (click)="sortData('modifiedStatus')">Status <i class="kphicon-uniE930"></i></button>
                </th>
                <th>
                  <button class="sortable-table-btn font-kpn-extended letter-spacing-normal kpn-extended-bold" [ngClass]="{'active' : sortColumn === 'total_amount_ex_vat'}" (click)="sortData('total_amount_ex_vat')" >Prijs ex. btw <i class="kphicon-uniE930"></i></button>
                </th>
                <th>
                  <button class="sortable-table-btn font-kpn-extended letter-spacing-normal kpn-extended-bold" [ngClass]="{'active' : sortColumn === 'created_at'}" (click)="sortData('created_at')" >Aanmaakdatum <i class="kphicon-uniE930"></i></button>
                </th>
                <th>
                  <button class="sortable-table-btn font-kpn-extended letter-spacing-normal kpn-extended-bold" [ngClass]="{'active' : sortColumn === 'user'}" (click)="sortData('user')" >Aangemaakt door <i class="kphicon-uniE930"></i></button>
                </th>
                <th *ngIf="isRunningQuoteTabActive">
                  <button class="sortable-table-btn font-kpn-extended letter-spacing-normal kpn-extended-bold"   [ngClass]="{'active' : sortColumn === 'validity_in_days'}" (click)="sortData('validity_in_days')">Verloopt over <i class="kphicon-uniE930"></i></button>
                </th>
              </tr>
            </thead>
            <tbody>
              <ng-container  *ngFor="let quote of sortedQuotes; let i = index" >
              <tr (click)="goToCustomerQuoteDetails(quote)" class="click-able-row">
                <td>
                  <a class="cursor-pointer p-0 fs-14" >
                    {{quote.reference}}
                  </a>
                </td>
                <td>
                  <span class="d-flex font-kpn-extended fs-14">
                    <i class="fs-18 mr-1 cpt-1" [ngClass]="getQuoteStatusIcon(quote.status)"></i>
                    {{ (quote?.modifiedStatus)  | titlecase}}
                  </span>
                </td>
                <td class="text-left">{{quote?.total_amount_ex_vat | currencyFormat }}</td>
                <td>{{ quote?.created_at | date: 'dd-MM-yyyy' }}</td>
                <td>{{ quote?.extra_data?.create_flow == 'internal' ? 'Team KPN' : quote?.user }}</td>
                <td *ngIf="isRunningQuoteTabActive">
                  <span *ngIf="quote?.validity_in_days" class="expiry-day kpn-font-color fs-14 d-inline-flex align-items-center ">
                    <i class="kpnew-icon-rejected-light fs-16 mr-1"></i>
                    {{ quote?.validity_in_days }} dagen
                  </span>
                </td>
              </tr>
            </ng-container>
            </tbody>
          </table>
          <div class="table-pagination text-right pb-4"  *ngIf="config?.totalPages">
            <p class="d-flex align-items-center justify-content-end m-0 font-kpn-extended">
                <span class="mr-2 grey-text">Bekijk</span>
                <span class="blue-text mr-2 place-dd-arrow">
                  <select class="perpage-item-selection" [(ngModel)]="config.itemsPerPage" (ngModelChange)="onChangeItemPerPage()" name="" id="">
                    <option value="10">10</option>
                    <option value="20">20</option>
                    <option value="30">30</option>
                  </select>
                </span>
                <span class="mr-1 grey-text">{{ config.from }}-{{ config.to }}</span>
                <span class="grey-text">of {{ config.totalPages }}</span>
                <button class="table-pagination-btn kpnew-icon-chaveron-left kpn-font-color ml-2 mr-2 px-0" [disabled]="config?.currentPage === 1" (click)="onPageChange(config.currentPage-1)"></button>
                <button class="table-pagination-btn kpnew-icon-chavron-right-2 kpn-font-color px-0" [disabled]="config?.currentPage === config?.totalPages" (click)="onPageChange(config.currentPage+1)"></button>
            </p>
          </div>
        </div>


      </div>
    </div>


    <div class="container quote-details-container" *ngIf="!user?.contact_group?.is_external">
      <button class="flat-grey-light mb-3" (click)="goToProductPage()">
        <i class="kphicon-uniE9B1 mr-2"></i> {{'QUOTES.BACK' | transloco}}
      </button>
      <div class="bg-kpn-light box-shadow-sm mb-3 p-4">
        <h4 class="title-4 mb-3 green-text">{{'QUOTES.QUOTES' | transloco}}</h4>
        <p class="text-muted">{{'QUOTES.QUOTES_SEARCH_GUIDELINE' | transloco}}</p>

        <form [formGroup]="quoteSearchForm" (ngSubmit)="onSearchQuotes()">
          <div class="row align-items-center">
            <div class="col-md-6 mb-3">
              <div class="form-group mb-2 kpn-form-group">
                <input type="text" oninput="this.value" formControlName="search_data"
                  class="form-control kpn-form-control" placeholder="{{'QUOTES.QUOTE_SEARCH_PLACEHOLDER' | transloco}}">
              </div>
            </div>
            <div class="col-md-6 mb-3">
              <button type="submit" class="btn btn-primary mb-2 rounded-btn px-4 mr-2"
                [disabled]="!quoteSearchForm.get('search_data').value">{{'QUOTES.TO_SEARCH' | transloco}}</button>
              <button (click)="clearSearch()" *ngIf="quoteSearchForm.get('search_data').value"
                class="btn btn-danger mb-2 mr-2 rounded-btn">{{'QUOTES.CLEAR' | transloco}}</button>
              <button (click)="addNewQuote()" *ngIf="!isExternal"
                class="btn btn-success mb-2 rounded-btn">{{'QUOTES.START_NEW_QUOTE' | transloco}}</button>
            </div>
          </div>
        </form>
      </div>

      <div class="bg-kpn-light box-shadow-sm mb-3 p-4" *ngIf="!isExternal">
        <h4 class="title-4 mb-3 green-text">{{'QUOTES.IMPORT_QUOTATIONS' | transloco}}</h4>
        <form [formGroup]="quoteImportForm">
          <div class="row align-items-center">
            <div class="col-md-4 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'QUOTES.FILE' | transloco}}</label>
                <input type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  class="form-control" formControlName="source_file" (change)="onFileChange($event)">
              </div>
            </div>
            <div class="col-md-4 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'QUOTES.SYSTEM' | transloco}} type</label>
                <select class="form-control" formControlName="external_system">
                  <option *ngFor="let item of systemTypeList" [ngValue]="item?.key">{{item?.value}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-6 col-gap-reduce">
              <button (click)="importQuote()"
                [disabled]='isImporting || !quoteImportForm.valid || !quoteImportForm.dirty'
                class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                <span *ngIf="!isImporting; else showSpinner">{{'QUOTES.IMPORT' | transloco}}</span>
                <ng-template #showSpinner>
                  <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                </ng-template>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="bg-kpn-light box-shadow-sm pl-4">
        <div class="section-loader" *ngIf="isLoading">
          <div class="kpn-spinner">
            <img src="/assets/images/kpn-loader.gif" alt="Loading">
          </div>
        </div>

        <div class="table-responsive custom-table" *ngIf="!isLoading">
          <div *ngIf="isExternal===false" class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
            <label ngbButtonLabel class="checkbox-item d-flex pl-0">
              <div class="checkbox-btn checkbox-btn-outline pt-1">
                <input ngbButton type="checkbox"  (change)="onChangeShowAll()" [(ngModel)]="show_all"
                  />
                <span class="checkBoxCheckmark"></span>
              </div>
              <div class="checkbox-label ml-2 text-left">
                <span>
                  <small class="text-primary">Show All</small>
                </span>
              </div>
            </label>
          </div>
          <table class="table">
            <thead>
              <tr>
                <th> {{'QUOTES.COMPANY_NAME' | transloco}}</th>
                <th>KRN {{'QUOTES.NUMBER' | transloco}}</th>
                <th>{{'QUOTES.REFERENCE' | transloco}}</th>
                <th>Status</th>
                <th>{{'QUOTES.VERSION' | transloco}}</th>
                <th>{{'QUOTES.PRICE' | transloco}}</th>
                <th>{{'QUOTES.MADE_BY' | transloco}}</th>
                <th>{{'QUOTES.UPDATED_ON' | transloco}}</th>
                <th *ngIf="!isExternal">Afdeling</th>
                <th width="150px" class="text-center"></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let quote of quotes | paginate: config; let i = index">
                <td>{{quote.contact?.company_name}}</td>
                <!-- <td>{{ quote.users }}</td> -->
                <td>{{ quote.contact?.external_id1}}</td>
                <td> {{quote.reference}}
                  <span *ngIf="quote?.has_special_bidding" class="dark-orange-tag-pill ml-1">Special Offer</span>
                </td>
                <td>{{quote.status | titlecase}}

                </td>
                <td class="text-center">{{quote?.version }}</td>
                <td class="text-right">{{quote?.total_amount_ex_vat | currencyFormat }}</td>
                <td>{{ quote?.user }}</td>
                <td>{{ quote?.updated_at | date: 'dd-MM-yyyy' }}</td>
                <td *ngIf="!isExternal">{{ quote?.department?.name }}</td>
                <td class="text-right">
                  <button class="btn btn-outline-primary outline-icon-btn pt-0 pb-0 rounded-btn"
                    (click)="quotationDetails(quote)">
                    {{'QUOTES.TO_LOOK_AT' | transloco}}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-new">
            <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}"
              nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true"
              class="pagination d-flex justify-content-center" (pageChange)="onPageClick($event)">
            </pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </section>
</section>
