<div  *ngIf=" selectedQuoteContactPerson">
  <app-loader [isLoading]="isLoading"></app-loader>

  <div >
    <div class="bg-gray-1 tri-rounded-20 p-24 h-100">
      <label for=""
        class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0">Contactpersoon</label>
      <div class="row justify-content-between align-items-center">
        <div class="col-md">
          <div class="min-height-100">
            <p class="font-kpn-extended mb-2">
              <span class="d-block"> {{ selectedQuoteContactPerson?.first_name ? (selectedQuoteContactPerson?.first_name+' '+selectedQuoteContactPerson?.last_name) : selectedQuoteContactPerson?.initials+'.'+selectedQuoteContactPerson?.last_name   }} </span>
              <span class="d-block"> {{ selectedQuoteContactPerson?.mobile_phone_number }} </span>
              <span class="d-block">{{ selectedQuoteContactPerson?.email }} </span>
            </p>
          </div>
        </div>
      </div>
      <p class="mb-0" *ngIf="eQuoteStatus.APPROVED === quote?.status  ||  eQuoteStatus.PENDING === quote?.status">
        <a href="javascript:void(0)" (click)="openContactPersonModal(contactPersonUpdateModal)"
          class="fs-16 font-kpn-extended link-btn-blue">
          Wijzigen <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
      </p>
    </div>
  </div>


  <ng-template #contactPersonUpdateModal let-modal>
    <div class="px-4">
      <div class="px-1 modal-header tri-modal-header">
        <h6 class="modal-title green-text font-kpn-black fs-40" id="quoteListFilter">
          {{ isContactPersonCreating ? 'Contact toevoegen' : ' Contactpersoon wijzigen' }}
        </h6>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click'); temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = selectedQuoteContactPerson?.uuid" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <div>
        <div class="pl-4 pt-4 pr-4">
          <div class="custom-inner-modal-scroll pr-4">
            <div class="address-lines pt-3" *ngIf="!isContactPersonCreating">
              <div class="quote-address-item" *ngFor="let item of quoteContactPersons;index as i">
                <input type="radio" name="payment-option" [id]="i+'contact-person'"
                  [(ngModel)]="temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson" [value]="item.uuid">
                <label [for]="i+'contact-person'" class="d-flex font-kpn-extended">
                  <span *ngIf="item?.is_default"
                    class="standard-address font-kpn-extended kpn-extended-bold fs-12 letter-spacing-normal">Standaard
                    contactpersoon</span>
                  <span class="radio-bullet mt-1"></span>
                  <div>
                    <span class="block">{{ item?.first_name ? (item?.first_name+' '+item?.last_name) : item?.initials+'.'+item?.last_name   }}</span>
                    <span class="d-block grey-text">
                      {{ item?.email }} {{ item?.mobile_phone_number ? ' &nbsp;&nbsp;|&nbsp;&nbsp; '+item?.mobile_phone_number : '' }}
                    </span>
                  </div>
                </label>
              </div>
            </div>

            <div class="row gutter-row-15 mb-2" *ngIf="isContactPersonCreating">


              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Voorletter<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.initials"
                  placeholder="Voorletter">
                <div *ngIf="contactPersonCreateError?.initials" class="text-danger">
                  {{ contactPersonCreateError?.initials?.message || contactPersonCreateError?.initials[0] }}
                </div>
              </div>
              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Voornaam<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.first_name"
                  placeholder="Voornaam">
                <div *ngIf="contactPersonCreateError?.first_name" class="text-danger">
                  {{ contactPersonCreateError?.first_name?.message || contactPersonCreateError?.first_name[0] }}
                </div>
              </div>
              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Tussenvoegsel</label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.surname_prefix"
                  placeholder="Tussenvoegsel">
              </div>
              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Achternaam<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.last_name"
                  placeholder="Achternaam">
                <div *ngIf="contactPersonCreateError?.last_name" class="text-danger">
                  {{ contactPersonCreateError?.last_name?.message || contactPersonCreateError?.last_name[0]}}
                </div>
              </div>
              <div class="mb-4 col-md-12 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">E-mailadres<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.email"
                  placeholder="E-mailadres">
                <div *ngIf="contactPersonCreateError?.email" class="text-danger">
                  {{ contactPersonCreateError?.email?.message || contactPersonCreateError?.email[0]}}
                </div>
              </div>
              <div class="col-md-12 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Telefoonnummer</label>
                <input type="text" class="form-control tri-form-control"
                  [(ngModel)]="newContactPerson.mobile_phone_number" placeholder="Telefoonnummer">
                <div *ngIf="contactPersonCreateError?.mobile_phone_number" class="text-danger">
                  {{ contactPersonCreateError?.mobile_phone_number?.message ||
                  contactPersonCreateError?.mobile_phone_number[0]}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <button *ngIf="!isContactPersonCreating && contactPersonAddPermission"
                (click)="openNewContactPersonCreateView()" class="btn px-4 border-2 btn-outline-primary rounded-btn">
                <span class="d-inline-block px-2"><span>Contact toevoegen</span> <i
                    class="kphicon-uniE985 align-middle fs-24 ml-1"></i></span>
              </button>
              <button *ngIf="isContactPersonCreating" (click)="closeNewContactPersonCreateView()"
                class="btn px-4 border-2 btn-outline-primary rounded-btn">
                <span class="d-inline-block px-2">Vorige</span>
              </button>

            </div>
            <div class="col-md-auto gutter-col-8">
              <button (click)="createContactAndUpdateQuote()" class="btn px-4 btn-primary rounded-btn"
                *ngIf="isContactPersonCreating" [disabled]="
                                  !newContactPerson?.first_name?.trim()?.length ||
                                  !newContactPerson?.last_name?.trim()?.length ||
                                  !newContactPerson?.email?.trim()?.length ||
                                  !newContactPerson?.initials?.trim()?.length">
                <span class="d-inline-block px-2">Ga verder</span>
              </button>

              <button
                [disabled]="temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson === selectedQuoteContactPerson?.uuid"
                (click)="updateQuoteContactPerson()" class="btn px-4 btn-primary rounded-btn"
                *ngIf="!isContactPersonCreating">
                <span d-inline-block px-2>Contact wijzigen</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>

</div>
