import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { timer } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { FavoriteProductsService } from 'src/app/pages/favorite-products/favorite-products.service';
import { ApiBaseService } from 'src/app/shared/api-base.service';
import { AuthService } from 'src/app/shared/auth.service';
import { StorageService } from 'src/app/shared/storage.service';
import { PermissionLoadAction } from 'src/app/store/actions/permission.actions';
import { SiteProfileLoadAction } from 'src/app/store/actions/siteProfile.actions';

@Component({
  selector: 'app-ms-redirect',
  templateUrl: './ms-redirect.component.html',
  styleUrls: ['./ms-redirect.component.scss']
})
export class MsRedirectComponent {
  public code = '';
  private homePath = '/home';
  public errorMessage = null;
  constructor(
    private apiBaseService: ApiBaseService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private authService: AuthService,
    private store: Store<IAppState>,
    private favoriteProductsService: FavoriteProductsService,

   ) {

      this.route.queryParams.subscribe({
        next: (q_params:any)=>{
          this.code = q_params?.code || ''
        }
      })

  }
  ngOnInit() {
    if(this.code){
      this.getTokensFromMS();
    }
    else {
      this.router.navigateByUrl('/login');
    }

  }

  getTokensFromMS(){
    const url = "accounts/azure/login";
    this.apiBaseService.post(url,{code:this.code}).subscribe({
      next: res=>{
        const loggedInUser = res?.data?.user;
        const tokens = { jwt: res?.data?.token, refreshToken: res?.data?.refresh_token };
        this.authService.setDoLoginUser(loggedInUser, tokens);
        this.store.dispatch(new PermissionLoadAction());
        this.store.dispatch(new SiteProfileLoadAction());
        timer(2000).subscribe(time => {
          const permissions = this.storageService.getPermission();
          const hasWishListPermission = permissions?.some(permission => {
            return ['wishlist.view_wishlist'].includes(permission.code);
          });

          if (hasWishListPermission) {
            this.getFavoriteProducts();
          }
        })
        this.router.navigateByUrl(this.homePath);
      },
      error: error=>{
        console.log('error: ',error);
        this.errorMessage = error?.error?.message ?? 'Error occured while logging in. Please try again.';
      }
    })
  }
  getFavoriteProducts() {
    this.favoriteProductsService.getFavoriteProducts().subscribe(response => {
      const wishlistData = response?.data?.results?.find(item => item?.is_default) || null;
      this.favoriteProductsService.changeCurrentFavoriteProducts(wishlistData);
    })
  }

}
