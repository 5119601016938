import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/storage.service';
import { CustomerService } from '../../customer/customer.service';
import { NavigationStart, Router } from '@angular/router';
import { TradeInService } from '../trade-in.service';
import { EProductQualityTypes } from 'src/app/shared/enums/product-type.enums';
import { ToastrService } from 'ngx-toastr';
import { timer } from 'rxjs';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-cart-overview',
  templateUrl: './cart-overview.component.html',
  styleUrls: ['./cart-overview.component.scss']
})
export class CartOverviewComponent implements OnInit {
  public promotionProductModalReference: NgbModalRef;

  public functionalCart = [];
  public nonFunctionalCart = [];
  public promotionCart = [];
  public functionTotalPrice = 0;
  public nonFunctionTotalPrice = 0;
  public functionTotalQty = 0;
  public nonFunctionTotalQty = 0;
  public productPromotionList = [];
  public selectedPromotionProduct = null;

  public tradeInCart = null;
  public contactPerson = null;
  public contactAddress = null;
  public contact = null;
  public orderCreateError = null;
  public loader = false;
  public availableQty = 0;
  public accepted_terms_and_conditions = false;
  public terms_conditions = `Met het plaatsen van deze opdracht ga ik accoord met de <a  href=\"https://fra1.digitaloceanspaces.com/cdn-armor3/product_hub/documents/2023/12/07/CGA_v2.pdf\" target="_blank">voorwaarden</a>`
  public terms_and_conditions_list = [
    {
      content: `Ik heb mijn bestelling gecontroleerd, ga akkoord met de <a target="_blank" href=\"https://www.kpn.com/algemene-voorwaarden\">algemene voorwaarden</a> en de <a target="_blank" href=\"https://www.kpn.com/algemeen/bestelvoorwaarden\">bestelvoorwaarden</a> van KPN en ga hiermee een betalingsverplichting aan. Klik hier voor de <a target="_blank" href=\"https://www.kpn.com/algemeen/missie-en-privacy-statement/privacy-statement.htm\">Privacy statement | KPN</a>
      `,
      accepted: false
    },
    {
      content: `Ik bevestig dat ik de  <a target="_blank"  href="assets/files/CGA_v2.DOCX">algemene voorwaarden</a>, de <a target="_blank"  href="assets/files/Terms_of_Property_Transfer_v0524.pdf">overdrachtsvoorwaarden</a> en het <a target="_blank"  href="assets/files/Personal_data_protection_policy.docx">beleid voor de bescherming van persoonsgegevens</a> van Recommerce heb gelezen en begrepen. Hierbij ga ik akkoord met deze voorwaarden en de volledige en definitieve overdracht van mijn producten aan Recommerce`,
      accepted: false
    }

  ];
  public tradeInBusinessPartner = null
  constructor(
    private modalService: NgbModal,
    private storageService: StorageService,
    private customerService: CustomerService,
    private tradeInService: TradeInService,
    private router: Router,
    private translocoService: TranslocoService,
    private toaster: ToastrService) {
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (
          event instanceof NavigationStart &&
          event.navigationTrigger == "popstate" &&
          event.restoredState
        ) {
          timer(100).subscribe(time => {
            this.back();
          })
        }
      });
  }

  get eProductQuality() { return EProductQualityTypes; }

  get isTermsAndConditionsAccepted() {
    return this.terms_and_conditions_list.every(item => item.accepted);
  }

  getAvailableQty() {
    const promotionQty = this.promotionCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const funAndNonFunQty = this.functionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0) + this.nonFunctionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
    return funAndNonFunQty - promotionQty;
  }
  getFunAndNonFunQty() {
    return this.functionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0) + this.nonFunctionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
  }
  getContact(personUuid) {
    this.customerService.getContact(personUuid).subscribe(
      (response) => {
        this.contact = response.data;
        this.contactPerson = this.contact?.contact_person.find(element => element?.uuid === this.tradeInCart?.contact_person_uuid);
        if (this.tradeInCart?.shipping_address_uuid) {
          this.contactAddress = this.contact?.contact_addresses.find(element => element?.uuid === this.tradeInCart?.shipping_address_uuid);
          return;
        }
        if (!this.tradeInBusinessPartner && !this.tradeInCart?.shipping_address_uuid && (
          this.tradeInCart?.shipping_address_company_name ||
          this.tradeInCart?.shipping_address_initials ||
          this.tradeInCart?.shipping_address_first_name ||
          this.tradeInCart?.shipping_address_last_name ||
          this.tradeInCart?.shipping_address_surname_prefix ||
          this.tradeInCart?.shipping_address_street ||
          this.tradeInCart?.shipping_address_house_number ||
          this.tradeInCart?.shipping_address_house_number_extension ||
          this.tradeInCart?.shipping_address_zip_code ||
          this.tradeInCart?.shipping_address_city ||
          this.tradeInCart?.shipping_address_country
        )) {
          this.contactAddress = {
            initials: this.tradeInCart?.shipping_address_initials || '',
            first_name: this.tradeInCart?.shipping_address_first_name || '',
            surname_prefix: this.tradeInCart?.shipping_address_surname_prefix || '',
            last_name: this.tradeInCart?.shipping_address_last_name || '',
            company_name: this.tradeInCart?.shipping_address_company_name || '',
            street: this.tradeInCart?.shipping_address_street || '',
            house_number: this.tradeInCart?.shipping_address_house_number || '',
            house_number_extension: this.tradeInCart?.shipping_address_house_number_extension || '',
            zip_code: this.tradeInCart?.shipping_address_zip_code || '',
            city: this.tradeInCart?.shipping_address_city || '',
            country: this.tradeInCart?.shipping_address_country || 'NL',
          }
          return;
        }
        if (!this.tradeInBusinessPartner && !this.tradeInCart?.shipping_address_uuid && (
          !this.tradeInCart?.shipping_address_company_name &&
          !this.tradeInCart?.shipping_address_initials &&
          !this.tradeInCart?.shipping_address_first_name &&
          !this.tradeInCart?.shipping_address_last_name &&
          !this.tradeInCart?.shipping_address_surname_prefix &&
          !this.tradeInCart?.shipping_address_street &&
          !this.tradeInCart?.shipping_address_house_number &&
          !this.tradeInCart?.shipping_address_house_number_extension &&
          !this.tradeInCart?.shipping_address_zip_code &&
          !this.tradeInCart?.shipping_address_city &&
          !this.tradeInCart?.shipping_address_country
        )) {
          this.contactAddress = this.contact?.contact_addresses.find(address => address?.address_type === 'contact');
          return;
        }
        if (this.tradeInBusinessPartner && this.tradeInCart?.cart_extra?.end_customer) {
          this.contactAddress = this.contact?.contact_addresses.find(address => address?.address_type === 'contact');
          return;
        }
        if (this.tradeInBusinessPartner && this.tradeInCart?.cart_extra?.end_customer === false && !this.tradeInCart?.shipping_address_uuid && !this.tradeInCart?.shipping_address_company_name &&
          !this.tradeInCart?.shipping_address_initials &&
          !this.tradeInCart?.shipping_address_first_name &&
          !this.tradeInCart?.shipping_address_last_name &&
          !this.tradeInCart?.shipping_address_surname_prefix &&
          !this.tradeInCart?.shipping_address_street &&
          !this.tradeInCart?.shipping_address_house_number &&
          !this.tradeInCart?.shipping_address_house_number_extension &&
          !this.tradeInCart?.shipping_address_zip_code &&
          !this.tradeInCart?.shipping_address_city &&
          !this.tradeInCart?.shipping_address_country) {
          this.contactAddress = this.contact?.contact_addresses.find(address => address?.address_type === 'contact');
          return;
        }
        if (this.tradeInBusinessPartner && this.tradeInCart?.cart_extra?.end_customer === false && !this.tradeInCart?.shipping_address_uuid && (
          this.tradeInCart?.shipping_address_company_name ||
          this.tradeInCart?.shipping_address_initials ||
          this.tradeInCart?.shipping_address_first_name ||
          this.tradeInCart?.shipping_address_last_name ||
          this.tradeInCart?.shipping_address_surname_prefix ||
          this.tradeInCart?.shipping_address_street ||
          this.tradeInCart?.shipping_address_house_number ||
          this.tradeInCart?.shipping_address_house_number_extension ||
          this.tradeInCart?.shipping_address_zip_code ||
          this.tradeInCart?.shipping_address_city ||
          this.tradeInCart?.shipping_address_country
        )) {
          this.contactAddress = {
            initials: this.tradeInCart?.shipping_address_initials || '',
            first_name: this.tradeInCart?.shipping_address_first_name || '',
            surname_prefix: this.tradeInCart?.shipping_address_surname_prefix || '',
            last_name: this.tradeInCart?.shipping_address_last_name || '',
            company_name: this.tradeInCart?.shipping_address_company_name || '',
            street: this.tradeInCart?.shipping_address_street || '',
            house_number: this.tradeInCart?.shipping_address_house_number || '',
            house_number_extension: this.tradeInCart?.shipping_address_house_number_extension || '',
            zip_code: this.tradeInCart?.shipping_address_zip_code || '',
            city: this.tradeInCart?.shipping_address_city || '',
            country: this.tradeInCart?.shipping_address_country || 'NL',
          }
          return;
        }
      }, (err) => {
        this.contact = null;
      });
  }

  back() {
    if (this.tradeInBusinessPartner) {
      this.router.navigate([`trade-in/shipping-method`], {
        queryParams: {},
        queryParamsHandling: 'merge'
      });
      return;
    }
    const q_params = {
      is_trade_in: true,
      contact_person: this.tradeInCart?.contact_person_uuid,
    };
    this.router.navigate([`trade-in/contact/${this.tradeInCart?.contact_uuid}`], {
      queryParams: q_params,
      queryParamsHandling: 'merge'
    });
  }

  goToOrderDetails(order_uuid: string) {
    this.router.navigate([`trade-in/thank-you/${order_uuid}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }



  createOrder() {
    let payload = {
      accepted_terms_and_conditions: true
    }
    this.tradeInService.tradeInCartUpdate(payload, this.tradeInCart?.uuid).toPromise().then(res => {
      this.storageService.setReComCart(res?.data);
      this.orderCreateError = null;
      this.loader = true;
      this.tradeInService.createTradeInOrder(this.tradeInCart?.uuid).subscribe(res => {
        this.storageService.removeReComCart();
        this.loader = false;
        this.toaster.success(`${this.translocoService.translate('TRADE_CART_OVERVIEW.TRADER_PLACED_ORDER_SUCCESSFULLY')}`)

        this.goToOrderDetails(res?.data?.uuid);
      }, error => {
        this.orderCreateError = error?.error?.message;
        this.loader = false;
      })
    })

  }

  deleteCartLine(line) {
    this.tradeInService.deleteTradeInCartLine(this.tradeInCart?.uuid, line?.uuid).subscribe(res => {
      this.storageService.setReComCart(res?.data);
    }, error => {
      console.log('error: ', error);
    })
  }
  incrementQuantity(line, index, type) {
    const prevQty = line.qty;
    line.qty = ++line.qty;
    this.updateQuantity(line, index, type, prevQty);
  }
  decrementQuantity(line, index, type) {
    if (line.qty < 1) {
      return;
    }
    const prevQty = line.qty;
    line.qty = --line.qty;
    this.updateQuantity(line, index, type, prevQty);
  }
  updateQuantity(line, index, type, prevQty) {
    this.tradeInService.updateTradeInCartLine(this.tradeInCart?.uuid, line?.uuid, { qty: line?.qty }).subscribe(res => {
      this.saveTradeInCart(res?.data);
    }, error => {
      if (type === "functional") {

        this.functionalCart[index].qty = prevQty;
      }
      if (type === "nonFunctional") {
        this.nonFunctionalCart[index].qty = prevQty;
      }
      this.errorShowTradeInCart(error?.error?.message);
    });
  }
  updatePromotionProductQuantity(line, qty) {
    if (qty < 0 || qty === '' || qty === null || qty === undefined || isNaN(qty)) {
      return;
    }
    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + qty;

    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    this.availableQty = this.getFunAndNonFunQty() - newPromotionQty;
    this.tradeInService.updateTradeInCartLine(this.tradeInCart?.uuid, line?.uuid, { qty: qty }).subscribe(res => {
      this.saveTradeInCart(res?.data);
      this.promotionProductModalReference.close();

    }, error => {
      this.errorShowTradeInCart(error?.error?.message);
    });
  }
  restrictInput(event) {
    const key = event.key;
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    // Allow digits, backspace, delete, arrow keys, and decimal point
    if (/[0-9]/.test(key) || controlKeys?.includes(key)) {
      return true;
    }
    event.preventDefault();
    return false;
  }
  onChangePromotionQty(event) {
    let qty = event.target.value;
    if (qty < 0 || qty === '' || qty === null || qty === undefined || isNaN(qty)) {
      this.selectedPromotionProduct.totalPrice = 0.00;
      return;
    }
    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + parseInt(qty);

    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    this.availableQty = this.getFunAndNonFunQty() - newPromotionQty;

    this.selectedPromotionProduct.qty = qty;
    this.selectedPromotionProduct.totalPrice = (parseFloat(this.selectedPromotionProduct?.price[0]?.price_ex_vat) * parseInt(qty)).toFixed(2);
  }
  saveTradeInCart(cart) {
    this.toaster.success(`${this.translocoService.translate('TRADE_CART.PRODUCT_QUANTITY_UPDATED')}`);
    this.storageService.setReComCart(cart);
  }
  errorShowTradeInCart(error) {
    this.toaster.error(error || `${this.translocoService.translate('TRADE_CART.PRODUCT_QUANTITY_UPDATE_FAILED')}`);
  }
  addPromotionToCart() {
    if (this.selectedPromotionProduct.qty < 0 || this.selectedPromotionProduct.qty === '' || this.selectedPromotionProduct.qty === null || this.selectedPromotionProduct.qty === undefined || isNaN(this.selectedPromotionProduct.qty)) {
      return;
    }
    const otherPromotionQty = this.promotionCart.filter(item => item?.code !== this.selectedPromotionProduct?.code).reduce((acc, line) => acc + parseInt(line?.qty), 0);

    const newPromotionQty = otherPromotionQty + this.selectedPromotionProduct.qty;
    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    const payload = {
      product_code: this.selectedPromotionProduct?.code,
      product_quality: 'functional',
      qty: this.selectedPromotionProduct.qty,
      find_my_device_status_disabled: true,
      remove_google_account_status_disabled: true,
    }
    this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
      this.storageService.setReComCart(res?.data);
      this.promotionProductModalReference.close();
      // const index = this.productPromotionList.findIndex(item => item?.code === this.selectedPromotionProduct?.code);
      // this.productPromotionList[index] = {
      //   ...this.productPromotionList[index],
      //   cart: res?.data,
      //   selected: true,
      // }
    }, error => {
      this.toaster.error(error?.error?.message, 'Error');
    });
  }
  aboutPromoProduct(aboutPromoProductModal, promotion) {
    this.availableQty = this.getAvailableQty();
    this.selectedPromotionProduct = {
      ...promotion,
      help_text: promotion?.help_text
        .replace(/{{\s*productName\s*}}/g, promotion?.name)
        .replace(/{{\s*availableQTY\s*}}/g, this.availableQty)
    };
    this.promotionProductModalReference = this.modalService.open(aboutPromoProductModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });
    this.promotionProductModalReference.result.then((result) => {
    }).catch(e => {

    });
  }
  closePromotionProductModal() {
    this.promotionProductModalReference.close();
  }
  getProductsPromotionList() {
    this.tradeInService.getProductsPromotions().subscribe(res => {
      this.productPromotionList = res?.data?.results?.map((item) => {
        return {
          ...item,
          qty: 1,
          cart: null,
          totalPrice: item?.price[0]?.price_ex_vat,
        }
      }) ?? [];
      this.storageService.getTradeInCart().subscribe(cart => {
        if (cart) {
          this.tradeInCart = JSON.parse(cart);
          this.accepted_terms_and_conditions = this.tradeInCart?.accepted_terms_and_conditions;
          if (!this.contact) {
            this.getContact(this.tradeInCart?.contact_person_uuid);
          }
          this.functionalCart = [];
          this.nonFunctionalCart = [];
          this.promotionCart = [];

          this.tradeInCart?.lines?.map(line => {
            if (line?.product?.type?.type === "promotion") {
              this.promotionCart.push(line);
              this.productPromotionList = this.productPromotionList.map(item => {
                if (item?.code === line?.product?.code) {
                  return {
                    ...item,
                    cart: line,
                    qty: line?.qty,
                    totalPrice: line?.total_price_ex_vat,
                  }
                }
                return item;
              });
            }
            else if (line?.product_quality === 'functional') {
              this.functionalCart.push(line);
            }
            else if (line?.product_quality === 'non_functional') {
              this.nonFunctionalCart.push(line);
            }

          });

          // this.functionalCart = this.tradeInCart?.lines?.filter(line => line?.product_quality === 'functional');
          // this.nonFunctionalCart = this.tradeInCart?.lines?.filter(line => line?.product_quality === 'non_functional');
          this.functionTotalPrice = this.functionalCart?.reduce((acc, line) => (parseFloat(acc) + parseFloat(line?.total_price_ex_vat)).toFixed(2), 0);
          this.nonFunctionTotalPrice = this.nonFunctionalCart?.reduce((acc, line) => (parseFloat(acc) + parseFloat(line?.total_price_ex_vat)).toFixed(2), 0);
          this.functionTotalQty = this.functionalCart?.reduce((acc, line) => acc + parseInt(line?.qty), 0);
          this.nonFunctionTotalQty = this.nonFunctionalCart?.reduce((acc, line) => acc + parseInt(line?.qty), 0);
        } else {
          this.tradeInCart = null;
        }
      });
    }, error => {
      this.toaster.error(error?.error?.message || `Promotion list failed to load`);
    })
  }
  updatePromotionQty(qty) {
    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + qty;
    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }

    this.availableQty = this.getFunAndNonFunQty() - newPromotionQty;

    this.selectedPromotionProduct.qty = qty;
    this.selectedPromotionProduct.totalPrice = (parseFloat(this.selectedPromotionProduct?.price[0]?.price_ex_vat) * parseInt(qty)).toFixed(2);

  }
  deletePromotionProductFromCart(line) {
    this.tradeInService.deleteTradeInCartLine(this.tradeInCart?.uuid, line?.uuid).subscribe(res => {
      if (line?.product?.type?.type === "promotion") {
        this.productPromotionList = this.productPromotionList.map(item => {
          if (item?.code === line?.product?.code) {
            return {
              ...item,
              cart: null,
              qty: 1,
              totalPrice: item?.price[0]?.price_ex_vat,
            }
          }
          return item;
        });

      }
      if (this.promotionProductModalReference) {
        this.promotionProductModalReference.close();
      }
      this.storageService.setReComCart(res?.data);
    }, error => {
      this.toaster.error(error?.error?.message, 'Server Error');
    })
  }
  setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
  }
  ngOnInit(): void {
    this.getProductsPromotionList();
    this.setTradeInBusinessPartner();
  }

}
