import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../storage.service';
import { EProductAttributes } from '../enums/product-type.enums';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { EQuoteStatus } from '../enums/order-status.enums';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {


  constructor(
    private router: Router,
    private storageService: StorageService,
  ) {
  }
  // E981
  // kphicon-uniE9541 excel
  // kphicon-uniE92E  File
  public iconList = [

    { type: "xlsx", icon: "kphicon-uniE9541 icon-size-md" },
    { type: "xls", icon: "kphicon-uniE9541 icon-size-md" },
    { type: "pdf", icon: "kphicon-uniE93D icon-size-md" },
    { type: "jpg", icon: "kphicon-uniE981 icon-size-md" },
    { type: "jpeg", icon: "kphicon-uniE981 icon-size-md" },
    { type: "png", icon: "kphicon-uniE981 icon-size-md" },
    { type: "doc", icon: "kphicon-uniE93C icon-size-md" },
    { type: "csv", icon: "kphicon-uniE93B icon-size-md" },
    { type: "zip", icon: "kphicon-uniE93E icon-size-md" },
    { type: "webm", icon: "kphicon-uniE9B3 icon-size-md" },
  ];


  getFileExtension(file_name) {
    let ext = file_name.split(".").pop();
    return this.iconList.find(row => {
      return row.type === ext;
    })?.icon || 'kphicon-uniE92E icon-size-md';
  }


  backToCustomer() {
    const customer = this.storageService.getCustomer() || this.storageService.getCurrentUser()?.contact;
    const cart = this.storageService.getCart() || null;


    if (customer) {
      this.router.navigate([`/customer/${customer?.uuid}`], {
        queryParams: {
          contact_person: cart?.contact_person_uuid || null,
          shipping_address: this.getShippingAddressById(cart?.shipping_address_id)
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  getShippingAddressById(id: number) {
    const customer = this.storageService.getCustomer() || null;
    const cartAddress = customer?.contact_addresses?.find(address => {
      return address.id === id;
    }) || null;

    return cartAddress ? cartAddress?.uuid : null;
  }


  zipCodePattern() {
    return {
      0: { pattern: new RegExp('\[A-Za-z\]') },
      1: { pattern: new RegExp('\[0-9\]') },
      A: { pattern: new RegExp('\[A-Za-z0-9\]') }
    };
  }

  getOrderPaymentParameters(order_data) {
    const totalAmountToBePaidDirectly = order_data?.order?.total_amount_to_be_paid_directly;
    const totalAmountPaidDirectly = order_data?.order?.total_amount_paid_directly;
    const needPayDirectly = order_data?.order?.direct_payment_required;

    const isVoucher = order_data?.order_line.some(data => data?.product_code?.product_type__system_type === 'voucher');
    const isDepot = order_data?.order_line.some(data => data?.payment_type?.gateway === 'depot');
    const isOnAccount = order_data?.order_line.some(data => data?.payment_type?.code === 'on_account');
    const isIdealPayment = order_data?.order_line.some(data => data?.payment_type?.code === 'ideal_talos');

    const isOnlyVoucher = isVoucher && (totalAmountToBePaidDirectly === totalAmountPaidDirectly);
    const isPaidWithDepot = isDepot && (totalAmountToBePaidDirectly === totalAmountPaidDirectly);
    const isPaidOnAccount = isOnAccount && !needPayDirectly;
    const isNonVoucherIdeal = isIdealPayment && !isVoucher;
    const isVoucherIdeal = needPayDirectly && isVoucher && isIdealPayment && (totalAmountToBePaidDirectly !== totalAmountPaidDirectly);

    return {
      quoteRef: null,
      orderId: order_data?.order?.id,
      orderRef: order_data?.order?.reference,
      payment_required: order_data?.order?.direct_payment_required,
      is_paid: order_data?.order?.is_paid,
      contact: order_data?.contact_person?.uuid,
      only_voucher: isOnlyVoucher,
      only_depot: isPaidWithDepot,
      only_account: isPaidOnAccount,
      only_non_voucher: isNonVoucherIdeal,
      only_voucher_ideal: isVoucherIdeal,
      remaining_amount: totalAmountToBePaidDirectly,
      is_total_amount_paid: parseFloat(totalAmountToBePaidDirectly) ? false : true
    };
  }

  paymentMessage(qp: any, orderRef: string) {
    let numberMsg = '';
    let description = '';

    if (JSON.parse(qp?.only_voucher)) {
      numberMsg = `Bedankt voor jouw bestelling met ordernummer ${orderRef}`;
      description = `Je voucher is succesvol verzilverd, we gaan jouw bestelling in orde maken. Bedankt voor het vertrouwen.`;
    } else if (JSON.parse(qp?.only_depot)) {
      numberMsg = `Bedankt voor jouw bestelling met ordernummer ${orderRef}`;
      description = `Het bedrag is in mindering gebracht op het depot. Het depot bedraagt na
      betaling: ${qp?.remaining_amount}.  We gaan jouw bestelling in orde maken. Bedankt voor het
      vertrouwen.`;
    } else if (JSON.parse(qp?.only_account)) {
      numberMsg = `Je bestelling met ordernummer ${orderRef} is succesvol geplaatst.`;
      description = `We gaan de bestelling in orde maken.  Verzending is te volgen met Track&Trace bij bestellingen nadat deze is georderpickt. Na levering ontvang je een factuur van KPN.`;
    } else if (JSON.parse(qp?.only_non_voucher)) {
      numberMsg = `Bedankt voor jouw bestelling. Je hebt nog één stap te gaan.`;
      description = `Je moet nog ${qp?.remaining_amount} betalen. In de volgende stap kun je dit eenvoudig met
      iDeal doen. Zodra de betaling is gelukt, gaan we aan de slag om jouw bestelling te verwerken.`;
    } else if (JSON.parse(qp?.only_voucher_ideal)) {
      numberMsg = `Bedankt voor jouw bestelling. Je hebt nog één stap te gaan.`;
      description = `Je voucher is nu verzilverd, en is van het bestelbedrag afgetrokken.
      Je moet nog ${qp?.remaining_amount} betalen. In de volgende stap kun je dit eenvoudig met iDeal doen.
      Zodra de betaling is gelukt, gaan we aan de slag om jouw bestelling te verwerken.`;
    } else {
      numberMsg = `Uw bestelling met ordernummer ${orderRef} is gelukt`;
      description = `We gaan direct voor u aan de slag om alles in orde te maken. U ontvangt een bevestiging van uw bestelling per mail.
      Voor vragen kunt u contact opnemen met kpnwebshop@kpn.com`;
    }

    return {
      numberMsg,
      description
    };
  }


  stickers = [
    { type: EProductAttributes.CISCO, logo: "/assets/images/stickers/Cisco_Bradsoft_logo.png" },
    { type: EProductAttributes.KPN_EEN, logo: "/assets/images/stickers/KPN_EEN_logo.png" },
    { type: EProductAttributes.TEAMS, logo: "/assets/images/stickers/TEAMS_logo.png" },
    { type: EProductAttributes.GENESYS, logo: "/assets/images/stickers/Genesys_logo.png" },
    { type: EProductAttributes.MITEL, logo: "/assets/images/stickers/Mitel_logo.png" },
  ]

  getFileExtensionByBlobType(blobType: string): string {
    switch (blobType) {
      case 'application/pdf':
        return 'pdf';
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        return 'docx';
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        return 'xlsx';
      case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
        return 'pptx';
      case 'image/jpeg':
        return 'jpeg';
      case 'image/png':
        return 'png';
      case 'image/gif':
        return 'gif';
      case 'text/plain':
        return 'txt';
      case 'application/json':
        return 'json';
      case 'application/zip':
        return 'zip';
      case 'application/x-rar-compressed':
        return 'rar';
      case 'audio/mpeg':
        return 'mp3';
      case 'video/mp4':
        return 'mp4';
      case 'video/quicktime':
        return 'mov';
      case 'video/x-msvideo':
        return 'avi';
      case 'application/vnd.ms-excel':
        return 'xls';
      case 'application/msword':
        return 'doc';
      case 'application/vnd.ms-powerpoint':
        return 'ppt';
      case 'application/x-shockwave-flash':
        return 'swf';
      case 'application/xml':
        return 'xml';
      case 'application/xhtml+xml':
        return 'xhtml';
      case 'text/html':
        return 'html';
      case 'application/javascript':
        return 'js';
      case 'text/css':
        return 'css';
      case 'application/vnd.ms-outlook':
        return 'msg';
      case 'application/vnd.apple.keynote':
        return 'key';
      case 'application/x-msdownload':
        return 'exe';
      case 'application/x-msmetafile':
        return 'wmf';
      case 'audio/x-wav':
        return 'wav';
      case 'audio/midi':
        return 'midi';
      case 'audio/x-aiff':
        return 'aif';
      case 'application/octet-stream':
        return 'bin';
      case 'application/vnd.android.package-archive':
        return 'apk';
      case 'application/ogg':
        return 'ogg';
      case 'audio/wav':
        return 'wav';
      case 'audio/aac':
        return 'aac';
      case 'audio/flac':
        return 'flac';
      case 'video/webm':
        return 'webm';
      case 'video/x-flv':
        return 'flv';
      case 'image/bmp':
        return 'bmp';
      case 'image/tiff':
        return 'tiff';
      case 'image/webp':
        return 'webp';
      case 'application/x-pkcs12':
        return 'p12';
      case 'application/vnd.ms-cab-compressed':
        return 'cab';
      case 'application/x-7z-compressed':
        return '7z';
      case 'application/gzip':
        return 'gz';
      case 'application/x-tar':
        return 'tar';
      // Add more cases for other common file types as needed
      default:
        // For unknown types or types you haven't explicitly handled
        return 'unknown';
    }
  }

  getNextDay(date) {
    // Create a new Date object based on the provided date
    const nextDay = new Date(date);

    // Add one day to the date
    nextDay.setDate(nextDay.getDate() + 1);

    // Return the new date
    return nextDay;
  }

  passwordMatchValidator(password: string, confirmPassword: string): ValidatorFn {
    return (formGroup: AbstractControl): ValidationErrors | null => {
      const passwordControl = formGroup.get(password);
      const confirmPasswordControl = formGroup.get(confirmPassword);

      if (!passwordControl || !confirmPasswordControl) {
        return null;
      }

      const error = passwordControl.value !== confirmPasswordControl.value ? { passwordsMismatch: true } : null;

      confirmPasswordControl.setErrors(error);

      return error;
    };
  }

  passwordValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;

      if (!value) {
        return null;
      }

      const hasNumber = /[0-9]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);
      const isValidLength = value.length >= 10;

      if (!isValidLength) {
        return { length: true }
      } else if (!hasNumber) {
        return { numberError: true }
      } else if (!hasUpperCase) {
        return { upperCaseError: true }
      } else if (!hasLowerCase) {
        return { lowerCaseError: true }
      } else if (!hasSpecialCharacter) {
        return { specialCharacterError: true }
      } else {
        return null
      }
    };
  }

  isInclVAT() {
    return this.storageService.getSiteProfile()?.business_to_consumer;
  }
  removeUnderscoreFromString(value: string): string {
    return value ? value.replace(/_/g, ' ') : '';
  }
  getQuoteStatusIcon(status: string) {
    let icon = '';

    switch (status) {
      case EQuoteStatus.APPROVED:
      case EQuoteStatus.NEW:
        icon = `kpnew-icon-info-new blue-text`;
        break;
      case EQuoteStatus.PENDING:
      case EQuoteStatus.REJECTED:
      case EQuoteStatus.ERROR:
      case EQuoteStatus.EXCEPTION:
        icon = `kpnew-icon-warning-new orange-text`;
        break;
      case EQuoteStatus.CANCELLED:
        icon = `kpnew-icon-rejected-light text-danger`;
        break;
      case EQuoteStatus.EXPIRED:
        icon = `kpnew-icon-clock-light text-muted`;
        break;
      case EQuoteStatus.PROCESSED:
        icon = `kpnew-icon-check-new text-success`;
        break;
      default:
        icon = `kpnew-icon-warning-new orange-text`;
    }

    return icon;
  }
  getModifiedStatusName(status: string='') {
    const statusName = status.toLocaleLowerCase().trim();
    switch (statusName) {
      case 'pending':
        return 'In behandeling';
      case 'new':
        return 'Nieuwe offerte';
      case 'approved':
        return 'Approved';
      case 'processed':
        return 'Besteld';
      case 'rejected':
        return 'Afgewezen';
      case 'cancelled':
        return 'Afgewezen';
      case 'expired':
        return 'Verlopen';
      default:
        return status;
    }
  }
}
