<div class="trade-in-checkout-process-stepbar checkout-process-stepbar checkout-process-stepbar-quote">
  <div class="step-item" style="cursor: pointer;" (click)="backToCart()"
    [ngClass]="{'active-step':( activeStep === 'overview'),'completed-step':( activeStep === 'overview' ||  activeStep === 'customer_select' || activeStep=== 'order-overview'  || activeStep === 'order-success' || activeStep ==='shipping-method') }">
    <h6 class="step-name"> <i class="kpn-ui-icon ui-preferences"></i>Overzicht</h6>
  </div>
  <div class="step-item" [style]="(activeStep === 'customer_select' || activeStep === 'order-overview'  || activeStep === 'order-success' || activeStep === 'customer_select') ? 'cursor: pointer; ' : ''"  (click)="backToCustomer()"
    [ngClass]="{'active-step': (activeStep === 'customer_select'),'completed-step': (activeStep === 'customer_select' || activeStep=== 'order-overview'  || activeStep === 'order-success' || activeStep ==='shipping-method') }">
    <h6 class="step-name"> 
      <i>
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M17.2494 6.75C17.2494 3.855 14.8944 1.5 11.9994 1.5C9.10444 1.5 6.74944 3.855 6.74944 6.75C6.74944 9.645 9.10444 12 11.9994 12C14.8944 12 17.2494 9.645 17.2494 6.75ZM11.9994 10.5C9.92944 10.5 8.24944 8.82 8.24944 6.75C8.24944 4.68 9.92944 3 11.9994 3C14.0694 3 15.7494 4.68 15.7494 6.75C15.7494 8.82 14.0694 10.5 11.9994 10.5ZM20.9694 19.86L20.8194 18.945C20.1294 14.925 16.6044 12 12.4494 12H11.5644C7.40944 12 3.88444 14.925 3.19444 18.945L3.04444 19.86C2.93944 20.505 3.11944 21.18 3.53944 21.69C3.97444 22.215 4.61944 22.5 5.30944 22.5H18.7194C19.4094 22.5 20.0394 22.2 20.4894 21.69C20.9244 21.18 21.1044 20.52 20.9844 19.86H20.9694ZM19.3194 20.715C19.1694 20.895 18.9444 21 18.7044 21H5.29444C5.05444 21 4.82944 20.895 4.67944 20.715C4.52944 20.535 4.46944 20.325 4.51444 20.115L4.66444 19.2C5.23444 15.9 8.12944 13.5 11.5494 13.5H12.4344C15.8544 13.5 18.7644 15.9 19.3194 19.2L19.4694 20.115C19.4994 20.34 19.4544 20.55 19.3044 20.715H19.3194Z" />
          </svg>
      </i>
      Gegevens</h6>
  </div>
  <div *ngIf="tradeInBusinessPartner" class="step-item" [style]="(activeStep === 'shipping-method' || activeStep=== 'order-overview'  || activeStep === 'order-success') ? 'cursor: pointer; ' : ''"  (click)="backToShippingMethod()"
    [ngClass]="{'active-step': (activeStep === 'shipping-method'),'completed-step': (  activeStep === 'order-success' || activeStep==='shipping-method' || activeStep=== 'order-overview') }">
    <h6 class="step-name"> <i class="kphicon-uniE94A"></i>Verzendmethode</h6>
  </div>
  <div class="step-item"
    [ngClass]="{'active-step': (activeStep === 'order-overview'), 'completed-step': (activeStep=== 'order-overview' || activeStep === 'order-success' ) }">
    <h6 class="step-name"> 
      <i><svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M17.7446 7.02501C17.7446 7.02501 17.6996 6.90501 17.6546 6.84502C17.3996 6.36502 17.0696 6.02001 17.0246 5.96001L12.2246 1.16001C12.0296 0.965015 11.8046 0.815015 11.5646 0.710015C11.2796 0.590015 10.9796 0.515015 10.6496 0.515015H3.76465C1.69465 0.515015 0.0146484 2.19501 0.0146484 4.26501V17.765C0.0146484 19.835 1.69465 21.515 3.76465 21.515H14.2646C16.4696 21.515 18.0146 19.97 18.0146 17.765V8.00001C18.0146 7.64001 17.9096 7.32502 17.7596 7.04002L17.7446 7.02501ZM15.4346 6.50001H12.7346C12.3296 6.50001 11.9846 6.17001 11.9846 5.75001V3.05001L15.4196 6.48501L15.4346 6.50001ZM14.2496 20H3.74965C2.50465 20 1.49965 18.995 1.49965 17.75V4.25001C1.49965 3.00501 2.50465 2.00001 3.74965 2.00001H10.4996V5.75001C10.4996 6.99501 11.5046 8.00001 12.7496 8.00001H16.4996V17.75C16.4996 19.115 15.6146 20 14.2496 20Z" />
      </svg></i>
      Samenvatting </h6>
  </div>
  <div class="step-item"  [ngClass]="{'active-step': activeStep === 'order-success' }">
    <h6 class="step-name"> <i class="kphicon-uniE91D"></i> Klaar!</h6>
  </div>
</div>
<!-- 'completed-step': (activeStep === 'customer_select' || activeStep=== 'order-overview'  || activeStep === 'order-success' || activeStep ==='shipping-method') -->
