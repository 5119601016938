<section class="inner-content-wrapper inner-content-blue-bg">
  <div class="container">
    <div class="row g-4 mt-4 p-3">
      <div class="col-md-12">
        <div *ngIf="activationSuccessMsg" class="alert alert-success" role="alert">
          <p [innerHTML]="activationSuccessMsg"></p>
        </div>
        <div *ngIf="invitationResendMsg" class="alert alert-success" role="alert">
          <p [innerHTML]="invitationResendMsg"></p>
        </div>
        <div *ngIf="activationErrorMsg" class="alert alert-danger" role="alert">
          <p [innerHTML]="activationErrorMsg"></p>
          <button (click)="reSendInvitation()" *ngIf="showReSend" class="btn btn-sm btn-info mb-2 mr-2">
            <small> Verzend de activering opnieuw</small></button>
        </div>
      </div>
    </div>
  </div>
</section>