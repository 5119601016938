import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { ApiBaseService } from '../../shared/api-base.service';
import { StorageService } from '../../shared/storage.service';
import { AuthService } from '../../shared/auth.service';
import { NotificationService } from 'src/app/component/notification/notification.service';
import { environment } from 'src/environments/environment';
import { PermissionLoadAction } from 'src/app/store/actions/permission.actions';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { SiteProfileLoadAction } from 'src/app/store/actions/siteProfile.actions';
import { FavoriteProductsService } from 'src/app/pages/favorite-products/favorite-products.service';
import { timer } from 'rxjs';
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public isLoginError = false;
  public loginErrorMessage = '';

  loginForm: UntypedFormGroup;
  loginUri = '';
  public username = null;
  public hasResetPasswordRequestSent = false;
  public passResetError = null;
  public passResetEmailMsg = null;
  public gripLogin = false;
  public msLogin = false;
  public accountLogin = false;
  public isInAccountLoginScreen = false;
  public showForgotPassword = false;
  public siteProfile = null;
  public logoSrc = '';
  public isInvited = false;
  public returnUrl = '';

  @ViewChild('forgotPasswordModal', { static: false }) forgotPasswordModal: ElementRef;
  public user;
  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private apiBaseService: ApiBaseService,
    private storageService: StorageService,
    private authService: AuthService,
    private store: Store<IAppState>,
    private notificationService: NotificationService,
    private favoriteProductsService: FavoriteProductsService,
    public translocoService: TranslocoService,
    private toaster: ToastrService
  ) {
    this.activateRoute.queryParams.subscribe(qp => {
      this.isInvited = ['true', 'false'].includes(qp?.invited) ? JSON.parse(qp?.invited) : false;
      this.returnUrl = qp?.returnUrl || '';
    });
    this.user = this.storageService.getCurrentUser();
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
      this.logoSrc = this.siteProfile?.logo_url || `/assets/images/logoipsum-logo.svg`;
      this.gripLogin = ((this.siteProfile?.two_factor_auth_required ) && (this.siteProfile?.allow_grip_login) ) ?? false;
      this.msLogin = this.siteProfile?.allow_azure_login ?? false;
      this.accountLogin = this.siteProfile?.allow_account_login ?? false;
    });
    this.initializeForm();
  }

  ngOnInit() {
    if (this.user) {
      this.router.navigateByUrl('/');
    }
    this.notificationService.setNotification(null, null);
  }

  forgotPassword() {
    this.username = null;
    this.passResetError = null;
    this.hasResetPasswordRequestSent = false;
    this.passResetEmailMsg = null;
    this.showForgotPassword = true;
  }





  initializeForm() {
    this.loginForm = this.fb.group({
      username: ['', Validators.required],
      password: ['', Validators.required]
    });
  }

  onSubmit() {
    this.loginErrorMessage = '';
    this.authService.login(this.loginForm.value).subscribe(res => {
      if (res?.data?.two_factor) {
        this.router.navigate(['two-factor-auth'], {
          queryParams: {
            returnUrl: this.returnUrl || null
          },
        });
      } else {
        const loggedInUser = res?.data?.user;
        const tokens = { jwt: res?.data?.token, refreshToken: res?.data?.refresh_token };
        this.authService.setDoLoginUser(loggedInUser, tokens);
        this.store.dispatch(new PermissionLoadAction());
        this.store.dispatch(new SiteProfileLoadAction());
        timer(2000).subscribe(time => {
          const permissions = this.storageService.getPermission();
          const hasWishListPermission = permissions?.some(permission => {
            return ['wishlist.view_wishlist'].includes(permission.code);
          });

          if (hasWishListPermission) {
            this.getFavoriteProducts();
          }
        })

        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.router.navigateByUrl('/home');
        }
      }



    }, error => {
      this.isLoginError = true;
      this.loginErrorMessage = this.translocoService.translate('LOGIN.INCORRECT_CREDENTIALS_OR_NO_ACTIVE_ACCOUNT');
      if (error.error && error.error.message) {
        this.loginErrorMessage = error.error.message;
      }
    });
  }


  getFavoriteProducts() {
    this.favoriteProductsService.getFavoriteProducts().subscribe(response => {
      const wishlistData = response?.data?.results?.find(item => item?.is_default) || null;
      this.favoriteProductsService.changeCurrentFavoriteProducts(wishlistData);
    })
  }

  checkUserName() {
    this.username = this.username.trim().length ? this.username.trim() : null;
  }

  onSubmitForgotPassword() {
    this.passResetError = null;
    this.passResetEmailMsg = null;
    this.authService.sendResetPasswordRequest({ username: this.username }).subscribe(res => {
      this.hasResetPasswordRequestSent = true;
      this.passResetEmailMsg = `${this.translocoService.translate('LOGIN.PASSWORD_RESET_EMAIL')}.`;
    }, error => {
      this.hasResetPasswordRequestSent = true;
      this.passResetEmailMsg =  `${this.translocoService.translate('LOGIN.PASSWORD_RESET_EMAIL')}.`;
    });
  }

  switchToAccountLogin() {
    // this.gripLogin = !this.gripLogin;
    this.isInAccountLoginScreen = true;
  }

  loginWithGrip() {
    window.open(environment.gripUrl, '_blank');
  }
  loginWithMicrosoft(){
    const url = 'accounts/azure/authorization-url/';
     this.apiBaseService.get(url).subscribe((res) => {
      console.log(res, "res");
        window.open(res.data.url, '_self');
     }, (error) => {
      this.toaster.error(`${error?.error?.message || 'Error while login with Microsoft'}`, 'Error', { timeOut: 3000 });
     });
  }
  closeForgotPassword() {
    this.showForgotPassword = false;
  }
}
