import { Component, OnInit, ViewChild } from '@angular/core';
import { TradeInService } from '../trade-in.service';
import { StorageService } from 'src/app/shared/storage.service';
import { Router } from '@angular/router';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { Location } from '@angular/common';
@Component({
  selector: 'app-cart-detais',
  templateUrl: './cart-details.component.html',
  styleUrls: ['./cart-details.component.scss']
})
export class CartDetailsComponent implements OnInit {
  public isCollapsedInfo = false;
  public promotionProductModalReference: NgbModalRef;
  public tradeInCart = null;
  public functionalCart = [];
  public nonFunctionalCart = [];
  public promotionCart = [];
  public functionTotalPrice = 0;
  public nonFunctionTotalPrice = 0;
  public functionTotalQty = 0;
  public nonFunctionTotalQty = 0;
  public productPromotionList = [];
  public selectedPromotionProduct = null;
  public user = this.storageService.getCurrentUser();
  public availableQty = 0;
  public tradeInBusinessPartner = null;

  constructor(
    private storageService: StorageService,
    private tradeInService: TradeInService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private translocoService: TranslocoService,
    private location: Location,
    private router: Router) { }

  get promotionProductsCart() {
    return this.productPromotionList.filter(item => item.cart);
  }
  get totalQty() {
    return this.tradeInCart?.lines?.reduce((acc, line) => acc + parseInt(line?.qty), 0) || 0;
  }
  getAvailableQty() {
    const promotionQty = this.promotionCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const funAndNonFunQty = this.functionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0) + this.nonFunctionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
    return funAndNonFunQty - promotionQty;
  }
  getFunAndNonFunQty() {
    return this.functionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0) + this.nonFunctionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
  }
  goBack() {
    const url = JSON.parse(localStorage.getItem('tradeInLastProductDetailsUrl'));
    if (url) {
      this.router.navigate([url], {
        queryParams: {},
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate([`trade-in`], {
        queryParams: {},
        queryParamsHandling: 'merge'
      });
    }
  }
  canAddPromotionToCart(qty = 0) {
    const funAndNonFunQty = this.functionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0) + this.nonFunctionalCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const alreadyAddedPromotionQty = this.promotionCart.reduce((acc, line) => acc + parseInt(line?.qty), 0);
    return funAndNonFunQty < (alreadyAddedPromotionQty + qty);
  }
  aboutPromoProduct(aboutPromoProductModal, promotion) {
    this.availableQty = this.getAvailableQty();
    this.selectedPromotionProduct = {
      ...promotion,
      help_text: promotion?.help_text
        .replace(/{{\s*productName\s*}}/g, promotion?.name)
        .replace(/{{\s*availableQTY\s*}}/g, this.availableQty)
    };
    this.promotionProductModalReference = this.modalService.open(aboutPromoProductModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });

    this.promotionProductModalReference.result.then((result) => {
    }).catch(e => {

    });
  }
  closePromotionProductModal() {
    this.promotionProductModalReference.close();
  }
  deleteCartLine(line) {
    this.tradeInService.deleteTradeInCartLine(this.tradeInCart?.uuid, line?.uuid).subscribe(res => {
      if (line?.product?.type?.type === "promotion") {
        this.productPromotionList = this.productPromotionList.map(item => {
          if (item?.code === line?.product?.code) {
            return {
              ...item,
              cart: null,
              qty: 1,
              totalPrice: item?.price[0]?.price_ex_vat,
            }
          }
          return item;
        });

      }
      this.storageService.setReComCart(res?.data);
    }, error => {
      this.toaster.error(error?.error?.message, 'Server Error');
    })
  }
  deletePromotionProductFromCart(line) {
    this.tradeInService.deleteTradeInCartLine(this.tradeInCart?.uuid, line?.uuid).subscribe(res => {
      if (line?.product?.type?.type === "promotion") {
        this.productPromotionList = this.productPromotionList.map(item => {
          if (item?.code === line?.product?.code) {
            return {
              ...item,
              cart: null,
              qty: 1,
              totalPrice: item?.price[0]?.price_ex_vat,
            }
          }
          return item;
        });

      }
      this.promotionProductModalReference.close();
      this.storageService.setReComCart(res?.data);
    }, error => {
      this.toaster.error(error?.error?.message, 'Server Error');
    })
  }

  back() {
    this.router.navigate([`trade-in`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  next() {
    if (this.tradeInBusinessPartner) {
      this.router.navigate([`trade-in/contact/${this?.user?.contact?.uuid}`], {
        queryParams: {
          is_trade_in: true,
          // contact_person:  this.user?.contact_person?.uuid ,
        },
        queryParamsHandling: 'merge'
      });
      return;
    }
    if (this.tradeInCart?.contact_uuid) {
      this.router.navigate([`trade-in/contact/${this.tradeInCart?.contact_uuid}`], {
        queryParams: {
          is_trade_in: true,
          contact_person: this.tradeInCart?.contact_person_uuid || null,
        },
        queryParamsHandling: 'merge'
      });
    } else {
      const customer = this.storageService?.getCustomer();
      const q_params = {
        is_trade_in: true,
        contact_person: this.user?.is_external ? this.user?.contact_person?.uuid : this.storageService.getContactPersonUUID() || null,
      };
      if (customer || this.user?.is_external) {
        if (customer) {
          this.router.navigate(['/trade-in/customers'], {
            queryParams: {
              company_name: customer?.company_name,
              coc_number: customer?.coc_number,
              customer_uuid: customer?.uuid,
              is_trade_in: true,
            },
            queryParamsHandling: 'merge'
          });
        } else {
          this.router.navigate([`trade-in/contact/${customer?.uuid || this?.user?.contact?.uuid}`], {
            queryParams: q_params,
            queryParamsHandling: 'merge'
          });
        }
      } else {
        this.router.navigate([`/trade-in/customers`], {
          queryParams: q_params,
          queryParamsHandling: 'merge'
        });
      }
    }

  }
  incrementQuantity(line, index, type) {
    const prevQty = line.qty;
    line.qty = ++line.qty;
    this.updateQuantity(line, index, type, prevQty);
  }
  decrementQuantity(line, index, type) {
    if (line.qty < 1) {
      return;
    }
    const prevQty = line.qty;
    line.qty = --line.qty;
    this.updateQuantity(line, index, type, prevQty);
  }
  updateQuantity(line, index, type, prevQty) {
    this.tradeInService.updateTradeInCartLine(this.tradeInCart?.uuid, line?.uuid, { qty: line?.qty }).subscribe(res => {
      this.saveTradeInCart(res?.data);
    }, error => {
      if (type === "functional") {
        this.functionalCart[index].qty = prevQty;
      }
      if (type === "nonFunctional") {
        this.nonFunctionalCart[index].qty = prevQty;
      }
      this.errorShowTradeInCart(error?.error?.message);
    });
  }
  updatePromotionProductQuantity(line, qty) {
    if (qty < 0 || qty === '' || qty === null || qty === undefined || isNaN(qty)) {
      return;
    }
    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + qty;

    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    this.availableQty = this.getFunAndNonFunQty() - newPromotionQty;

    this.tradeInService.updateTradeInCartLine(this.tradeInCart?.uuid, line?.uuid, { qty: qty }).subscribe(res => {
      this.saveTradeInCart(res?.data);
      this.promotionProductModalReference.close();

    }, error => {
      this.errorShowTradeInCart(error?.error?.message);
    });
  }
  onChangePromotionQty(event) {
    let qty = event.target.value;
    if (qty < 0 || qty === '' || qty === null || qty === undefined || isNaN(qty)) {
      this.selectedPromotionProduct.totalPrice = 0.00;
      return;
    }
    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + parseInt(qty);

    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    this.availableQty = this.getFunAndNonFunQty() - newPromotionQty;

    this.selectedPromotionProduct.qty = qty;
    this.selectedPromotionProduct.totalPrice = (parseFloat(this.selectedPromotionProduct?.price[0]?.price_ex_vat) * parseInt(qty)).toFixed(2);
  }
  saveTradeInCart(cart) {
    this.toaster.success(`${this.translocoService.translate('TRADE_CART.PRODUCT_QUANTITY_UPDATED')}`);
    this.storageService.setReComCart(cart);
  }
  errorShowTradeInCart(error) {
    this.toaster.error(error || `${this.translocoService.translate('TRADE_CART.PRODUCT_QUANTITY_UPDATE_FAILED')}`);
  }
  getProductsPromotionList() {
    this.tradeInService.getProductsPromotions().subscribe(res => {
      this.productPromotionList = res?.data?.results?.map((item) => {
        return {
          ...item,
          qty: 1,
          cart: null,
          totalPrice: item?.price[0]?.price_ex_vat,
        }
      }) ?? [];
      this.storageService.getTradeInCart().subscribe(cart => {
        if (cart) {
          this.tradeInCart = JSON.parse(cart);
          this.functionalCart = [];
          this.nonFunctionalCart = [];
          this.promotionCart = [];

          this.tradeInCart?.lines?.map(line => {
            if (line?.product?.type?.type === "promotion") {
              this.promotionCart.push({ ...line });
              this.productPromotionList = this.productPromotionList.map(item => {
                if (item?.code === line?.product?.code) {
                  return {
                    ...item,
                    cart: line,
                    qty: line?.qty,
                    totalPrice: line?.total_price_ex_vat,
                  }
                }
                return item;
              });
            }
            else if (line?.product_quality === 'functional') {
              this.functionalCart.push({ ...line });
            }
            else if (line?.product_quality === 'non_functional') {
              this.nonFunctionalCart.push({ ...line });
            }

          });

          // this.functionalCart = this.tradeInCart?.lines?.filter(line => line?.product_quality === 'functional');
          // this.nonFunctionalCart = this.tradeInCart?.lines?.filter(line => line?.product_quality === 'non_functional');
          this.functionTotalPrice = this.functionalCart?.reduce((acc, line) => (parseFloat(acc) + parseFloat(line?.total_price_ex_vat)).toFixed(2), 0);
          this.nonFunctionTotalPrice = this.nonFunctionalCart?.reduce((acc, line) => (parseFloat(acc) + parseFloat(line?.total_price_ex_vat)).toFixed(2), 0);
          this.functionTotalQty = this.functionalCart?.reduce((acc, line) => acc + parseInt(line?.qty), 0);
          this.nonFunctionTotalQty = this.nonFunctionalCart?.reduce((acc, line) => acc + parseInt(line?.qty), 0);
        } else {
          this.tradeInCart = null;
        }
      });
    }, error => {
      this.toaster.error(error?.error?.message || `Promotion list failed to load`);
    })
  }
  updatePromotionQty(qty) {

    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + qty;

    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    this.availableQty = this.getFunAndNonFunQty() - newPromotionQty;

    this.selectedPromotionProduct.qty = qty;
    this.selectedPromotionProduct.totalPrice = (parseFloat(this.selectedPromotionProduct?.price[0]?.price_ex_vat) * parseInt(qty)).toFixed(2);
  }
  addPromotionToCart() {
    if (this.selectedPromotionProduct.qty < 0 || this.selectedPromotionProduct.qty === '' || this.selectedPromotionProduct.qty === null || this.selectedPromotionProduct.qty === undefined || isNaN(this.selectedPromotionProduct.qty)) {
      return;
    }
    const otherPromotionQty = this.promotionCart.filter(item => item?.uuid !== this.selectedPromotionProduct?.cart?.uuid).reduce((acc, line) => acc + parseInt(line?.qty), 0);
    const newPromotionQty = otherPromotionQty + this.selectedPromotionProduct.qty;
    if (this.getFunAndNonFunQty() < newPromotionQty) {
      return;
    }
    const payload = {
      product_code: this.selectedPromotionProduct?.code,
      product_quality: 'functional',
      qty: this.selectedPromotionProduct.qty,
      find_my_device_status_disabled: true,
      remove_google_account_status_disabled: true,

    }
    this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
      this.storageService.setReComCart(res?.data);
      this.promotionProductModalReference.close();
      // const index = this.productPromotionList.findIndex(item => item?.code === this.selectedPromotionProduct?.code);
      // this.productPromotionList[index] = {
      //   ...this.productPromotionList[index],
      //   cart: res?.data,
      //   selected: true,
      // }
    }, error => {
      this.toaster.error(error?.error?.message, 'Error');
    });
  }
  restrictInput(event) {
    const key = event.key;
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    // Allow digits, backspace, delete, arrow keys, and decimal point
    if (/[0-9]/.test(key) || controlKeys?.includes(key)) {
      return true;
    }

    event.preventDefault();
    return false;
  }
  onChangeQty(event, index, type, line) {
    let previousQty = 1;
    let qty = event.target.value;
    if (qty < 0 || qty === '' || qty === null || qty === undefined || isNaN(qty)) {
      return;
    }
    if (type === "functional") {
      previousQty = this.tradeInCart?.lines.find(item => item?.uuid === line?.uuid)?.qty;
    }
    if (type === "nonFunctional") {
      previousQty = this.tradeInCart?.lines.find(item => item?.uuid === line?.uuid)?.qty;
    }
    this.updateQuantity(line, index, type, previousQty);
  }
  removePromotionProductFromCart() {

  }
  setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
    console.log('====================================');
    console.log('tradeInBusinessPartner', this.tradeInBusinessPartner);
    console.log('====================================');
  }
  ngOnInit(): void {
    this.getProductsPromotionList();
    this.setTradeInBusinessPartner();

  }

}
