<section class="quote-details pb-4">
  <div class="container quote-details-container">
    <div class="quote-details-wrap" *ngIf="!user?.contact_group?.is_external;else kpnCustomer">
      <div class="details-top-wrap">
        <div class="row mb-3 align-items-center justify-content-between">
          <div class="col-md-auto mb-2">
            <div class="row align-items-center">
              <div class="col-md-auto">
                <h2 class="title-1-xl mb-0 green-text">{{'QUOTE_DETAILS.QUOTATION' | transloco}} Details</h2>
              </div>
              <div class="col-auto" *ngIf="quote?.agreement_status === 'expired'">
                <button class="flat-grey-light" placement="bottom">
                  <span class="dark-red-tag-pill mr-5">{{'QUOTE_DETAILS.PRICE_AGREEMENT_HAS_EXPIRED' |
                    transloco}}</span>
                </button>
              </div>
              <div class="col-auto" *ngIf="
                  quote?.agreement_status === 'not_found' &&
                  !user?.contact_group?.is_external
                ">
                <button class="flat-grey-light" placement="bottom">
                  <span class="dark-orange-tag-pill mr-5">{{'QUOTE_DETAILS.THERE_IS_NO_PRICE_MATCH_FOR_THIS_CUSTOMER' |
                    transloco}}</span>
                </button>
              </div>
            </div>
          </div>
          <div class="col-md-auto">
            <button class="flat-grey-light mb-2" (click)="gotoPreviousPage()">
              <i class="kphicon-uniE9B1 mr-2"></i>{{'QUOTES.BACK' | transloco}}
            </button>
          </div>
        </div>
        <div class="section-loader" *ngIf="isLoading">
          <div class="kpn-spinner">
            <img src="/assets/images/kpn-loader.gif" alt="Loading" />
          </div>
        </div>

        <div class="kpn-bottom-float-spinner" *ngIf="isSubmitting">
          <div class="spinner-border text-secondary" role="status">
            <span class="visually-hidden"></span>
          </div>
          <p class="mb-0">{{'QUOTE_DETAILS.UPDATING' | transloco}}...</p>
        </div>

        <div class="row" *ngIf="!isLoading">
          <div class="col-md-7">
            <div class="row mb-1 align-items-center">
              <div class="col-md-4">
                <h5 class="title-5 m-0 dark-grey-text">{{'HEADER.CUSTOMER' | transloco}}:</h5>
              </div>
              <div class="col-md-8">
                <!-- <p class="m-0 body-text-18-light grey-text">
                </p> -->

                <div class="row justify-content-between">
                  <div class="col" *ngIf="!defaultCustomer">
                    <p class="m-0 body-text-18-light grey-text">
                      {{ quote?.contact?.company_name }}
                    </p>
                  </div>
                  <div class="col" *ngIf="defaultCustomer">
                    <div class="col-md-auto">
                      <button (click)="selectCustomer()" class="btn btn-outline-primary outline-icon-btn rounded-btn">
                        {{'QUOTE_DETAILS.LINK_CUSTOMER' | transloco}}
                      </button>
                    </div>
                  </div>
                  <div class="col-auto" *ngIf="quote?.external_company_name">
                    <button class="flat-grey-light" placement="bottom" ngbTooltip="{{ quote?.external_company_name }}">
                      <span class="dark-red-tag-pill mr-5">{{'QUOTE_DETAILS.EXTERNAL' | transloco}}</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="!defaultCustomer && quote?.shipping_address_details">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'ORDER_CONFIRMATION.SHIPPING_ADDRESS' | transloco }}:</h5>
              </div>
              <!-- <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text">
                  {{ quote?.shipping_address_details?.street }}
                  {{ quote?.shipping_address_details?.house_number }}
                  {{ quote?.shipping_address_details?.house_number_extension }}
                  <span *ngIf="quote?.shipping_address_details?.zip_code">
                    , {{ quote?.shipping_address_details?.zip_code }},
                  </span>
                  <span *ngIf="quote?.shipping_address_details?.city">
                    {{ quote?.shipping_address_details?.city }}
                  </span>
                </p>
              </div> -->


              <div class="col-md-8">
                <p *ngIf="quoteShippingAddresses?.length" class="m-0 body-text-18-light grey-text" ngbTooltip="{{ selectedQuoteShippingAddress?.name }}">
                  <app-dropdown class="autocomplete-truncate-text" [activeDropdown]="disableUpdate || ![eQuoteStatus.PENDING, eQuoteStatus.NEW]?.includes(quote?.status)"
                    [searchData]="quoteShippingAddresses" [selectedData]="selectedQuoteShippingAddress"
                    (onDataSelect)="setShippingAddress($event)"></app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="!defaultCustomer">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">Contact persoon:</h5>
              </div>
              <div class="col-md-8">
                <p *ngIf="quoteContactPersons?.length" class="m-0 body-text-18-light grey-text" ngbTooltip="{{ selectedQuoteContactPerson?.name }}">
                  <app-dropdown class="autocomplete-truncate-text" [activeDropdown]="disableUpdate || ![eQuoteStatus.PENDING, eQuoteStatus.NEW]?.includes(quote?.status)"
                    [searchData]="quoteContactPersons" [selectedData]="selectedQuoteContactPerson"
                    (onDataSelect)="setContactPersons($event)"></app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-start" *ngIf="!defaultCustomer && selectedQuoteContactPerson">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0 text-capitalize">{{ 'ORDER_OVERVIEW.CONTACT_PERSON' | transloco }} {{ 'REPORT.TITLE' | transloco }} :</h5>
              </div>
              <div class="col-md-4 ">
                <input  class="form-control grey-text" type="text" [disabled]="disableUpdate || ![eQuoteStatus.PENDING, eQuoteStatus.NEW]?.includes(quote?.status)" [(ngModel)]="selectedQuoteContactPersonTitle" (ngModelChange)="clearErrors()">
                <div>
                  <span class="text-danger" >
                    <small>{{ errors?.contact_person_title }}</small>
                  </span>
                </div>
              </div>
              <div class="col-md-2 pl-0 " *ngIf="(!disableUpdate)">
                <button  class="badge badge-primary" (click)="updateContactPersonTitle(this.selectedQuoteContactPersonTitle)" [disabled]="disableUpdate" ><span >Save</span></button>
              </div>

            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'ORDER_CONFIRMATION.TELEPHONE' | transloco }}:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text">
                  {{ quote?.contact_person?.mobile_phone_number }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">E-mail:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text">
                  {{ quote?.contact_person?.email }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">Account Manager:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text" ngbTooltip="{{ quote?.account_manager?.name }}">
                  <app-dropdown class="autocomplete-truncate-text" [activeDropdown]="disableUpdate"
                    [searchData]="accountManagerList" [selectedData]="quote?.account_manager"
                    (onDataSelect)="setAccountManager($event)"></app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="errors?.account_manager">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span style="color: red">
                  <small>{{ errors?.account_manager }}</small>
                </span>
              </div>
            </div>
            <!-- <div class="row mb-1 align-items-center">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">
                  {{'QUOTE_DETAILS.BID_PRICING_CONSULTANT' | transloco}}:
                </h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text" ngbTooltip="{{ quote?.inside_sales_manager?.name }}">
                  <app-dropdown class="autocomplete-truncate-text" [activeDropdown]="disableUpdate"
                    [searchData]="contactPersons" [selectedData]="quote?.inside_sales_manager"
                    (onDataSelect)="setInsideSalesManager($event)"></app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="errors?.inside_sales_manager">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span style="color: red">
                  <small>{{ errors?.inside_sales_manager }}</small>
                </span>
              </div>
            </div> -->
            <div class="row mb-1 align-items-center">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.SIGNING_MANAGER' | transloco}}:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text" ngbTooltip="{{ quote?.signing_manager?.name }}">
                  <app-dropdown class="autocomplete-truncate-text" [activeDropdown]="disableUpdate"
                    [searchData]="signingManagerList" [selectedData]="quote?.signing_manager"
                    (onDataSelect)="setSigningManager($event)"></app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="errors?.signing_manager">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span style="color: red">
                  <small> {{ errors?.signing_manager }}</small>
                </span>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="!user?.contact_group?.is_external">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.QUOTATION_TEMPLATE' | transloco}}:</h5>
              </div>
              <div class="col-md-8" *ngIf="quote">
                <p class="m-0 body-text-18-light grey-text">
                  <app-dropdown class="autocomplete-truncate-text" [activeDropdown]="disableUpdate"
                    [searchData]="templates" [selectedData]="getSelectedTemplatePreproses"
                    (onDataSelect)="setQuotePageTemplate($event)">
                  </app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="errors?.template">
              <div class="col-md-4"></div>
              <div class="col-md-8">
                <span style="color: red">
                  <small>{{ errors?.template }}</small>
                </span>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="quote?.extra_data?.deal_id">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.DEAL_ID' | transloco}}:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text">
                  {{ quote?.extra_data?.deal_id }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="contactFreeFields?.length">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">Contact {{quote?.contact?.company_name}}
                  {{'QUOTE_DETAILS.FREE_FIELD' | transloco}}:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text" *ngFor="let item of contactFreeFields">
                  {{item?.name}} : <b>{{item?.value}}</b>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="freeFields?.length">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.AVAILABLE_FREE_FIELDS' | transloco}}:</h5>
              </div>
              <div class="col-md-8">
                <p class="m-0 body-text-18-light grey-text">
                  <app-dropdown class="autocomplete-truncate-text" [searchData]="freeFields"
                    [activeDropdown]="!freeFieldsPermission.add_contact_free_field" [selectedData]="freeFieldsPayload"
                    (onDataSelect)="onSelectFreeFields($event)">
                  </app-dropdown>
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center"
              *ngIf="freeFields?.length && freeFieldsPayload?.user_defined_field_code">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text mb-3">{{'QUOTE_DETAILS.AVAILABLE_FREE_FIELDS_VALUE' | transloco}}:
                </h5>
              </div>
              <div class="col-md-8">
                <div class="row">
                  <div class="col-md-6 pt-2">
                    <div class="kpn-form-group">
                      <input type="text" [ngClass]="{ 'is-invalid': !freeFieldsPayload.value?.trim()?.length}"
                        class="form-control grey-text" [(ngModel)]="freeFieldsPayload.value"
                        (keyup.enter)="addFreeFieldToContact()" />
                    </div>
                    <small class="text-muted small-font">{{'QUOTE_DETAILS.PRESS' | transloco}}
                      <b>{{'ORDER_OVERVIEW.GIVE' | transloco }}</b> {{'QUOTE_DETAILS.TO_UPDATE' | transloco}}</small>
                  </div>
                </div>
              </div>
            </div>

            <div class="row mb-1 align-items-center mt-2" *ngIf="departments?.length && !user?.contact_group?.is_external">
              <div class="col-md-4">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.DEPARTMENTS' | transloco}}:</h5>
              </div>
              <div class="col-md-4">
                <div class="form-group">
                  <select class="form-control" [disabled]="user?.contact_group?.is_external && quote?.status === eQuoteStatus?.APPROVED || disableUpdate" [(ngModel)]="departmentPayload.department_code" (ngModelChange)="onSelectDepartment()" id="exampleFormControlSelect1">
                    <option *ngFor="let item of departments" [value]="item.code">{{item?.name}}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-5">
            <div class="row mb-1 align-items-center">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.QUOTATION' | transloco}} {{'QUOTE_DETAILS.DATE'
                  | transloco}}:</h5>
              </div>
              <div class="col-md-6">
                <p class="m-0 body-text-18-light grey-text">
                  {{ quote?.original_quotation_date | date : "d MMM y" }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="((quote?.status === eQuoteStatus?.EXPIRED) || (quote?.status === eQuoteStatus?.APPROVED)) && (quote.expire_date)">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0" *ngIf="quote?.external_company_name">
                  {{'QUOTE_DETAILS.CISCO_PRICE_PROTECTION_EXPIRATION_DATE' | transloco}}:
                </h5>
                <h5 class="title-5 dark-grey-text m-0" *ngIf="!quote?.external_company_name">
                  {{'QUOTE_DETAILS.EXPIRATION_DATE' | transloco}}:
                </h5>
              </div>
              <div class="col-md-4">
                <!-- <p class="m-0 body-text-18-light grey-text">
                  {{ quote?.extra_data?.net_price_protection_date }}
                </p> -->
                <input [min]="expiryDateMin" style="visibility: hidden;height: 1px;" (ngModelChange)="onChangeExpiryDateDate($event)"
                [(ngModel)]="quote.expire_date" #dateFrom="ngModel"  [disabled]="(user?.contact_group?.is_external === true) || disableExpiryDateUpdate"
                [owlDateTime]="validFrom" [owlDateTimeTrigger]="validFrom"
                class="form-control kpn-form-control" />

                <input class="" [readonly]="(user?.contact_group?.is_external === true) || disableExpiryDateUpdate"  [disabled]="(user?.contact_group?.is_external === true) || disableExpiryDateUpdate"  [owlDateTimeTrigger]="validFrom"  [value]="quote.expire_date | date:
                'dd-MM-yyyy'" />
              <owl-date-time [pickerType]="'calendar'" #validFrom></owl-date-time>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="errors?.expire_date">
              <div class="col-md-6"></div>
              <div class="col-md-6">
                <span style="color: red">
                  <small>{{ errors?.expire_date }}</small>
                </span>
              </div>
            </div>
            <div class="row mb-3">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0 text-capitalize">Validity Days :</h5>
              </div>
              <div class="col-md-4 ">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input  class="form-control grey-text"  appPositiveInteger type="number" [disabled]="disableUpdate" [(ngModel)]="quote.validity_in_days" (ngModelChange)="onValidityDaysChange($event)">
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 align-items-center" *ngIf="siteProfile?.is_po_number_required">
              <div class="col-md-6">
                <h5 class="title-5 dove-grey m-0">
                  {{ siteProfile?.po_number_label }}
                </h5>
              </div>
              <div class="col-md-6">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input *ngIf="quote?.status != eQuoteStatus?.APPROVED" [disabled]="disableUpdate || quote?.status == eQuoteStatus.PROCESSED  || quote?.status == eQuoteStatus?.EXPIRED"
                     type="text" class="form-control grey-text" [(ngModel)]="quote.po_number" (ngModelChange)="checkQuoteChange(quote)" />
                    <input  *ngIf="quote?.status == eQuoteStatus?.APPROVED" type="text" class="form-control grey-text" [(ngModel)]="po_number" (keydown.enter)="onKeyEnterUpdateQuotePoNumber($event)"/>
                  </div>
                  <small *ngIf="quote?.status == eQuoteStatus?.APPROVED " class="text-muted small-font">{{'QUOTE_DETAILS.PRESS' | transloco}}<b>{{'ORDER_OVERVIEW.GIVE' |
                      transloco }}</b> {{'QUOTE_DETAILS.TO_UPDATE' | transloco}}</small>
                </div>
              </div>
            </div>
            <div class="row mb-3 align-items-center" *ngIf="siteProfile?.is_reference_required">
              <div class="col-md-6">
                <h5 class="title-5 dove-grey m-0">
                  Customer Reference
                </h5>
              </div>
              <div class="col-md-6">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input *ngIf="quote?.status != eQuoteStatus?.APPROVED" [disabled]="disableUpdate || quote?.status == eQuoteStatus.PROCESSED  || quote?.status == eQuoteStatus?.EXPIRED" type="text"
                      class="form-control grey-text" [(ngModel)]="quote.customer_reference"  (ngModelChange)="checkQuoteChange(quote)" />

                    <input *ngIf="quote?.status == eQuoteStatus?.APPROVED" [disabled]="[eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status)" type="text"
                    class="form-control grey-text"  (keydown.enter)="onKeyEnterUpdateQuoteCustomerReference($event)"  [(ngModel)]="customer_reference" />
                  </div>
                  <small *ngIf="quote?.status == eQuoteStatus?.APPROVED " class="text-muted small-font">{{'QUOTE_DETAILS.PRESS' | transloco}}<b>{{'ORDER_OVERVIEW.GIVE' |
                      transloco }}</b> {{'QUOTE_DETAILS.TO_UPDATE' | transloco}}</small>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">{{'QUOTE_DETAILS.STATUS' | transloco}}:</h5>
              </div>
              <div class="col-md-6">
                <div class="row">
                  <div class="col-4">
                    <p class="m-0 body-text-18-light blue-text">
                      {{ quote?.status | uppercase }}
                    </p>
                  </div>
                  <div class="col-auto flex-start">
                    <button  [ngbTooltip]="((siteProfile?.is_reference_required && !quote?.reference?.trim()?.length) ||
                    (!quote?.customer_reference?.trim()?.length) ||
                    (siteProfile?.is_po_number_required && !quote?.po_number?.trim()?.length) ||
                    (hasCpaas && !isCpassDataAdded))
                    ? ('Je kunt de order plaatsen als je'
                    + (siteProfile?.is_reference_required && !quote?.reference?.trim()?.length ? ' een referentie nummer' : '')
                    + (!quote?.customer_reference?.trim()?.length ? ' en een klantreferentie' : '')
                    + (siteProfile?.is_po_number_required && !quote?.po_number?.trim()?.length ? ' en een PO nummer' : '')
                    + (hasCpaas && !isCpassDataAdded ? ' en cpass-gegevens' : '')
                    + ' hebt ingevuld')
                    : ''"

                      [disabled]="orderCreateLoading || (hasCpaas ? (!isCpassDataAdded) : false) ||(siteProfile?.is_reference_required && !quote?.reference?.trim()?.length) || (siteProfile?.is_po_number_required && !quote?.po_number?.trim()?.length)"
                      *ngIf="quote?.status === eQuoteStatus?.APPROVED" class="flat-grey-light" placement="bottom" (click)="checkTermsCondition(quoteTermAndAccepted)">
                      <span
                        [ngClass]="{ 'disable-link': (!quote?.reference?.trim()?.length || !quote?.po_number?.trim()?.length || (hasCpaas ? (!isCpassDataAdded) : false))}"
                        class="dark-blue-tag-pill mr-1">
                        <span class="spinner-border spinner-border-sm mr-2" *ngIf="orderCreateLoading" role="status"
                          aria-hidden="true"></span>
                        {{'CUSTOMER.CREATE' | transloco }} Order</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="quote?.extra_data?.special_discount_requested">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  Pricing Requested:
                </h5>
              </div>
              <div class="col-md-3">
                <p class="m-0 body-text-18-light  dark-grey-text">
                  {{ quote?.extra_data?.special_discount_requested ? 'Yes' : 'No'}}
                </p>
              </div>
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  Request  Reason:
                </h5>
              </div>
              <div class="col-md-6">
                <p class="m-0 body-text-18-light  dark-grey-text">
                  {{ quote?.extra_data?.special_discount_reason }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-6">
                <h5 class="title-5 dove-grey m-0">S-{{'CUSTOMER.NUMBER' | transloco }}:</h5>
              </div>
              <div class="col-md-6" *ngIf="quote">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input [disabled]="disableUpdate" type="text" value="kph-267" class="form-control grey-text"
                      [(ngModel)]="quote.reference" (ngModelChange)="checkQuoteChange(quote)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="!user?.contact_group?.is_external">
              <div class="col-md-6">
                <h5 class="title-5 dove-grey m-0">{{'ORDER_OVERVIEW.REFERENCE_NUMBER' | transloco}} ID:</h5>
              </div>
              <div class="col-md-6">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input [disabled]="disableUpdate" type="text" value="kph-267" class="form-control grey-text"
                      (ngModelChange)="onReferenceInput($event)" [(ngModel)]="referanceID"
                      (ngModelChange)="checkQuoteChange(quote)" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="!user?.contact_group?.is_external">
              <div class="col-md-6">
                <h5 class="title-5 dove-grey m-0">{{'QUOTE_DETAILS.SERVICE_DURATION' | transloco}}:</h5>
              </div>
              <div class="col-md-6" *ngIf="quote">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input [disabled]="disableUpdate" type="number" value="kph-267" class="form-control grey-text"
                      (ngModelChange)="onServiceDuration($event)"
                      [(ngModel)]="quote.extra_data.total_service_duration" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="!user?.contact_group?.is_external">
              <div class="col-md-6">
                <h5 class="title-5 dove-grey m-0">{{'RMA_DETAILS.APPROVE' | transloco}}:</h5>
              </div>
              <div class="col-md-6">
                <div class="edit-able-field" *ngIf="quote?.lines?.length">
                  <div class="edit-text-form" *ngIf="quote?.status != eQuoteStatus?.EXPIRED">
                    <input type="checkbox" [disabled]="approve" (click)="onApproveModal(approveQuoteModal)"
                      [(ngModel)]="approve" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  {{'QUOTE_DETAILS.APPLIED_EXCHANGE_RATE' | transloco}}:
                </h5>
              </div>
              <div class="col-md-6">
                <p class="m-0 body-text-18-light blue-text">
                  {{ quote?.applied_exchange_rate?.rate }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  {{'QUOTE_DETAILS.ACTIVE_EXCHANGE_RATE' | transloco}}:
                </h5>
              </div>
              <div class="col-md-3">
                <p class="m-0 body-text-18-light blue-text">
                  {{ quote?.active_exchange_rate?.rate }}
                </p>
              </div>
              <div class="col-md-3" *ngIf="
                  quote?.status != eQuoteStatus?.EXPIRED &&
                  quote?.status != eQuoteStatus?.APPROVED &&
                  !user?.contact_group?.is_external
                ">
                <button *ngIf="quote?.can_apply_new_rate" class="btn m-0 p-0 btn-block btn-primary rounded-btn"
                  (click)="applyActiveExchangeRate(quote)">
                  Apply
                </button>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="quote?.approved_by">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  Approved By:
                </h5>
              </div>
              <div class="col-md-3">
                <p class="m-0 body-text-18-light dark-grey-text">
                  {{ quote?.approved_by }}
                </p>
              </div>

            </div>
            <div class="row mb-1 align-items-center" *ngIf="quote?.extra_data">
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  Buyer Email:
                </h5>
              </div>
              <div class="col-md-3">
                <p class="m-0 body-text-18-light  dark-grey-text">
                  {{ quote?.extra_data?.buyer_email }}
                </p>
              </div>
              <div class="col-md-6">
                <h5 class="title-5 dark-grey-text m-0">
                  Special Bidding Remarks:
                </h5>
              </div>
              <div class="col-md-3">
                <p class="m-0 body-text-18-light  dark-grey-text">
                  {{ quote?.extra_data?.special_bidding_remarks }}
                </p>
              </div>
            </div>
            <div class="row mb-1 align-items-center" *ngIf="
                quote?.status != eQuoteStatus?.APPROVED &&
                quote?.status != eQuoteStatus?.EXPIRED &&
                !user?.contact_group?.is_external
              ">
              <div class="col-md-6 pt-3">
                <button [disabled]="isEmailButtonLoading" class="btn btn-block icon-btn-primary rounded-btn"
                  (click)="sendEmail()" placement="bottom" [ngClass]="{
                    'btn-primary': !isEmailSent,
                    'btn-success': isEmailSent
                  }">
                  <img *ngIf="isEmailButtonLoading" src="/assets/images/spinner-css-rotate.png" alt=""
                    style="width: 20px" class="rotating mr-2" />
                  <i *ngIf="isEmailSent" class="kphicon-uniE91D mr-2"></i>
                  {{ emailButtonText }}
                </button>
              </div>
              <div class="col-md-6 pt-3" *ngIf="!user?.contact_group?.is_external">
                <button class="btn btn-block btn-primary rounded-btn" placement="bottom"
                  (click)="reCalculateQuote(quote)">
                  {{'QUOTE_DETAILS.RE_CALCULATE' | transloco}}
                </button>
              </div>
            </div>
            <div class="total-budgate">
              <div class="row mb-0">
                <div [ngClass]="checkQuoteTotalAmount() ? 'col-md-4' : 'col-md-6'">
                  <h5 class="title-5 pt-1 m-0 green-text">{{'ORDER_STATUS.TOTAL_AMOUNT' | transloco }}:</h5>
                </div>
                <div class="text-center" [ngClass]="checkQuoteTotalAmount() ? 'col-md-3' : 'col-md-6'">
                  <p class="m-0 body-text-18-light green-text">
                    {{ hidePrice ? 'N/A' : ( quote?.total_amount_ex_vat | currencyFormat) }}
                  </p>
                </div>
                <div class="col-md-5 text-right" *ngIf="checkQuoteTotalAmount() && !quote?.special_discount_requested && user?.contact_group?.is_external && quote?.status != eQuoteStatus?.APPROVED">
                  <button [disabled]="priceRequestLoader"
                    class="px-1  btn btn-sm btn-outline-info text-dark" (click)="requestPrice()">
                    <div *ngIf="priceRequestLoader" class="spinner-border spinner-border-sm field-spinner mt-1"
                      role="status">
                    </div>
                    <i class="kphicon-uniE937 mt-1"></i> Vraag prijzen aan
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row justify-content-between" *ngIf="!isLoading && !user?.contact_group?.is_external">
        <div class="col-md-auto">
          <div class="quote-details-btn-set mb-2">
            <button class="btn btn-outline-primary outline-icon-btn rounded-btn"
              (click)="getQuoteWordContext(quote?.uuid,wordContextModal)">
              View Context
            </button>
            <button class="btn btn-outline-primary outline-icon-btn rounded-btn" *ngIf="(quote?.template?.uuid) && (quote?.template)"
              (click)="generateQuote()">
              <i class="kphicon-uniE9401"></i>
              {{ isQuoteGenerating ? "Generating..." : "Generate Quote" }}
            </button>
            <button [disabled]="quoteDownloading" class="btn btn-outline-primary outline-icon-btn rounded-btn"
              *ngIf="(quote?.template?.uuid) && (quote?.generated_quotation)" (click)="downloadQuote(quote?.generated_quotation)">
              <div *ngIf="quoteDownloading" class="spinner-border spinner-border-sm field-spinner mt-1" role="status">
              </div>
              <i *ngIf="!quoteDownloading" class="kpn-ui-icon ui-download"></i> {{'QUOTE_DETAILS.DOWNLOAD_QUOTE' |
              transloco}}
            </button>
            <button *ngIf="hasQuoteZipDownloadPermission" [disabled]="quoteZippedDownloading" class="btn btn-outline-primary outline-icon-btn rounded-btn"
              (click)="downloadQuoteZippedFile()">
              <div *ngIf="quoteZippedDownloading" class="spinner-border spinner-border-sm field-spinner mt-1" role="status">
              </div>
              <i *ngIf="!quoteZippedDownloading" class="kpn-ui-icon ui-download"></i> {{'QUOTE_DETAILS.DOWNLOAD_QUOTE_ZIPPED' |
              transloco}}
            </button>
          </div>
        </div>
        <div class="col-md-auto" *ngIf="
            quote?.status != eQuoteStatus?.APPROVED &&
            quote?.status != eQuoteStatus?.EXPIRED &&  quote?.status != eQuoteStatus?.PROCESSED
          ">
          <div class="quote-details-btn-set">
            <button class="btn btn-outline-primary outline-icon-btn rounded-btn" *ngIf="disableUpdate"
              (click)="enableUpdate()">
              <i class="kphicon-uniE931"></i> {{'QUOTE_DETAILS.TO_ADJUST' | transloco}}
            </button>
            <button class="btn btn-outline-primary outline-icon-btn rounded-btn" *ngIf="!disableUpdate"
              (click)="cancleUpdate()">
              {{'QUOTE_DETAILS.CANCEL' | transloco}}
            </button>
            <button class="btn btn-primary rounded-btn" *ngIf="!disableUpdate" (click)="updateQuotation(quote)"
              [disabled]="!showUpdate">
              {{'CUSTOMER.UPDATE' | transloco }}
            </button>
            <br />
            <small class="danger-text" *ngIf="quoteUpdateError">{{
              quoteUpdateError
              }}</small>
          </div>
        </div>


          <div class="col-md-auto" *ngIf="
              quote?.status === eQuoteStatus?.EXPIRED && (!user?.contact_group?.is_external) && (quote?.has_new_version === false )
            ">
            <div class="quote-details-btn-set">
              <button class="btn btn-outline-primary outline-icon-btn rounded-btn" *ngIf="disableExpiryDateUpdate"
                (click)="enableExpiryDateUpdate()">
                <i class="kphicon-uniE931"></i> {{'QUOTE_DETAILS.TO_ADJUST' | transloco}}
              </button>
              <button class="btn btn-outline-primary outline-icon-btn rounded-btn" *ngIf="!disableExpiryDateUpdate"
                (click)="cancelExpiryDateUpdate()">
                {{'QUOTE_DETAILS.CANCEL' | transloco}}
              </button>
              <button class="btn btn-primary rounded-btn" *ngIf="!disableExpiryDateUpdate" (click)="updateQuotation(quote)">
                {{'CUSTOMER.UPDATE' | transloco }}
              </button>
              <br />
              <small class="danger-text" *ngIf="quoteUpdateError">{{
                quoteUpdateError
                }}</small>
            </div>
          </div>


        <div class="col-md-auto" *ngIf="
            quote?.status == eQuoteStatus?.APPROVED &&
            !user?.contact_group?.is_external
          ">
          <div class="quote-details-btn-set">
            <button class="btn btn-outline-primary outline-icon-btn rounded-btn" [disabled]="creatingNewQuote"
              (click)="createNewQuote(quote?.uuid)">
              {{ creatingNewQuote ? ('QUOTE_DETAILS.CREATING' | transloco)+'...' : ('QUOTE_DETAILS.CREATE_NEW_VERSION' |
              transloco) }}
            </button>
          </div>
        </div>
      </div>

      <div class="bg-kpn-light box-shadow-sm mb-3 p-4 pt-2" *ngIf="
          !user?.contact_group?.is_external &&
          quote?.status === eQuoteStatus?.APPROVED
        ">
        <h4 class="title-4 mb-3 green-text">{{'QUOTE_DETAILS.IMPORT_QUOTE_UPDATE' | transloco}}</h4>
        <form [formGroup]="quoteImportForm">
          <div class="row align-items-center">
            <div class="col-md-4 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'ORDER_STATUS.FILE' | transloco }}</label>
                <input type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  class="form-control" formControlName="source_file" (change)="onFileChange($event)" />
              </div>
            </div>
            <div class="col-md-4 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'QUOTE_DETAILS.SYSTEM_TYPE' | transloco}}</label>
                <select class="form-control" formControlName="external_system">
                  <option *ngFor="let item of systemType" [ngValue]="item.code">
                    {{ item?.name }}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-4 col-6 col-gap-reduce">
              <button (click)="importQuote()" [disabled]="
                  isImporting ||
                  !quoteImportForm.valid ||
                  !quoteImportForm.dirty
                " class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                <span *ngIf="!isImporting; else showSpinner">{{'QUOTE_DETAILS.IMPORT' | transloco}}</span>
                <ng-template #showSpinner>
                  <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                </ng-template>
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="bg-kpn-light box-shadow-sm mb-3 p-4 pt-2" *ngIf="
                !user?.contact_group?.is_external &&
                (quote?.status === eQuoteStatus?.NEW || quote?.status === eQuoteStatus?.PENDING) && quote?.is_external_quote
              ">
        <h4 class="title-4 mb-3 green-text">{{'QUOTE_DETAILS.IMPORT_QUOTE_UPDATE' | transloco}}</h4>
        <form [formGroup]="quoteImportForm">
          <div class="row align-items-center">
            <div class="col-md-4 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'ORDER_STATUS.FILE' | transloco }}</label>
                <input type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  class="form-control" formControlName="source_file" (change)="onFileChange($event)" />
              </div>
            </div>
            <div class="col-md-4 col-6 col-gap-reduce">
              <button (click)="importQuote('update')" [disabled]="
                        isImporting ||
                        !quoteImportForm?.valid ||
                        !quoteImportForm?.dirty
                      " class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                <span *ngIf="!isImporting; else showSpinner">{{'QUOTES.IMPORT' | transloco}}</span>
                <ng-template #showSpinner>
                  <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                </ng-template>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div *ngIf="hasCpaas && (!isLoading)" class="bg-kpn-light box-shadow-sm p-4 mb-3">
        <div class="info-bar-blue mb-2 d-flex align-items-center justify-content-between">
          <p>
            <span class="kphicon-uniE952"></span>
            {{'ORDER_OVERVIEW.ACTIVE_C_PASS_ALERT' | transloco}}.
          </p>
        </div>
        <from [formGroup]="cPaasForm">
          <div class="row">
            <div class="col-md-3 ">
              <div class="form-group kpn-form-group">
                <label >Email <span class="danger-text">*</span></label>
                <input type="text" class="form-control tri-form-control kpn-form-control" [ngClass]="{'disable-pointer-events': ([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status))  }"  formControlName="cpaas_user_email" placeholder="" [readonly]="([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status))" />
                <div *ngIf="cPaasForm.get('cpaas_user_email').hasError('trimError')" class="text-danger">
                  {{ cPaasForm.get('cpaas_user_email')?.errors?.trimError?.value }}
                </div>
                <div *ngIf="cPaasForm.get('cpaas_user_email')?.invalid && (cPaasForm.get('cpaas_user_email')?.dirty|| cPaasForm.get('cpaas_user_email')?.touched)" class="text-danger">
                  <span
                    *ngIf="cPaasForm.get('cpaas_user_email')?.errors?.required">
                    {{'ORDER_OVERVIEW.EMAIL_IS_REQUIRED'| transloco}}
                  </span>
                  <span *ngIf="cPaasForm.get('cpaas_user_email')?.errors?.email">
                    {{'ORDER_OVERVIEW.THE_EMAIL_MUST_BE_A_VALID_EMAIL_ADDRESS' | transloco}}
                  </span>
                </div>
              </div>

            </div>
            <div class="col-md-3">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.int-num">{{'CUSTOMER.FIRST_NAME' | transloco }} <span
                    class="danger-text">*</span></label>
                <input type="text" class="form-control tri-form-control kpn-form-control" [ngClass]="{'disable-pointer-events': ([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status))  }" placeholder=""  formControlName="cpaas_user_first_name" [readonly]="([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status))" />
                <div *ngIf="cPaasForm.get('cpaas_user_first_name')?.invalid && (cPaasForm.get('cpaas_user_first_name')?.dirty|| cPaasForm.get('cpaas_user_first_name')?.touched)" class="text-danger">
                  <span
                      *ngIf="cPaasForm.get('cpaas_user_first_name')?.errors?.required">
                      {{ 'Voornaam is verplicht' }}
                    </span>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.int-num">{{'SIGN_UP.LAST_NAME' | transloco}} <span
                    class="danger-text">*</span></label>
                <input type="text" class="form-control tri-form-control kpn-form-control" [ngClass]="{'disable-pointer-events': ([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status))  }" formControlName="cpaas_user_last_name" [readonly]="([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status))"
                  placeholder="" />
                <div *ngIf="cPaasForm.get('cpaas_user_last_name')?.invalid && (cPaasForm.get('cpaas_user_last_name')?.dirty|| cPaasForm.get('cpaas_user_last_name')?.touched)" class="text-danger">
                  <span
                      *ngIf="cPaasForm.get('cpaas_user_last_name')?.errors?.required">
                      {{ 'Achternaam is vereist' }}
                    </span>
                 </div>
              </div>
            </div>
            <div class="col-md-2 col-gap-reduce pt-3">
              <button  type="button" class="btn btn-sm btn-block btn-primary mt-2 rounded-btn"   (click)="saveCpassDataOnly()" [disabled]="(!cPaasForm.valid) || ([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status)) ">
                Save CPaaS
              </button>
            </div>
          </div>
        </from>
      </div>
      <div class="quote-product-item-table mb-3" *ngIf="!isLoading">
        <table [border]="'0'" cellpadding="0" cellspacing="0">
          <thead>
            <tr>
              <th class="text-left" *ngIf="
                  !user?.contact_group?.is_external &&
                  quote?.status != eQuoteStatus?.APPROVED &&
                  quote?.status != eQuoteStatus?.EXPIRED
                ">
                <i class="kphicon-uniE94C" style="font-size: 20px"
                  [ngbTooltip]="'QUOTE_DETAILS.SELECT_IF_THE_LINE_SHOULD_NOT_APPEAR_ON_THE_QUOTATION' | transloco"
                  container="body" placement="right"></i>
              </th>
              <th></th>
              <th width="110px"></th>
              <th width="150px"></th>
              <th width="115px" class="text-center">{{'CART.NUMBER' | transloco }}</th>
              <th>{{'QUOTE_DETAILS.DISCOUNT' | transloco}}</th>
              <th *ngIf="!user?.contact_group?.is_external">Service</th>
              <th class="text-right" *ngIf="!user?.contact_group?.is_external">
                Marge
              </th>
              <th class="text-right">Contract {{'QUOTE_DETAILS.DISCOUNT' | transloco}}</th>
              <th class="text-right" *ngIf="!user?.contact_group?.is_external">{{'QUOTE_DETAILS.PURCHASING_LAYOUT' |
                transloco}}</th>
              <th [ngClass]="disableUpdate ? 'text-right' : 'td-center-align'" *ngIf="
                  !user?.contact_group?.is_external
                ">
                <div [ngbTooltip]="'QUOTE_DETAILS.TOTAL_RECURRING_PRICE_OF_THE_LINE_EXCLUDING_VAT' | transloco">
                  RP
                </div>
              </th>
              <th [ngClass]="disableUpdate ? 'text-right' : 'td-center-align'" *ngIf="
                  !user?.contact_group?.is_external &&
                  quote?.system_code &&
                  quote?.system_code !== eQuoteSystemType.CISCO
                ">
                <div [ngbTooltip]="'QUOTE_DETAILS.TOTAL_PURCHASE_PRICE_OF_THE_LINE_EXCLUDING_VAT' | transloco">
                  PP
                </div>
              </th>
              <th class="text-right">
                <div [ngbTooltip]="'QUOTE_DETAILS.TOTAL_SALES_PRICE_OF_THE_LINE_EXCLUDING_VAT' | transloco">
                  SP
                </div>
              </th>
              <th class="text-right">
                <div [ngbTooltip]="'Applied Discount'">
                  AD
                </div>
              </th>
              <th class="text-right">
                <div
                  [ngbTooltip]="'QUOTE_DETAILS.THE_PRICE_CUSTOMER_NEEDS_TO_AFTER_APPLYING_ALL_THE_MARGINS_AND_DISCOUNTS' | transloco">
                  CS
                </div>
              </th>
              <th *ngIf="!disableUpdate && hasQuoteLineDeletePermission "></th>
            </tr>
          </thead>
          <tbody *ngFor="let line of quote?.lines; let idx = index">
            <tr class="min-line">
              <td *ngIf="
                  !user?.contact_group?.is_external &&
                  quote?.status != eQuoteStatus?.APPROVED &&
                  quote?.status != eQuoteStatus?.EXPIRED
                ">
                <div class="edit-able-field">
                  <div class="edit-text-form">
                    <input type="checkbox" [disabled]="disableUpdate" (ngModelChange)="hideQuoteLine($event, idx)"
                      [(ngModel)]="line.extra_data.is_hidden" />
                  </div>
                </div>
              </td>
              <td class="text-right">{{ line?.extra_data?.line_number }}</td>
              <td *ngIf="!line?.extra_data?.is_new">
                {{ line?.product_code }}
              </td>
              <td class="font-family-regular" [attr.colspan]="!line?.extra_data?.is_new ? '1' : '2'">
                <span *ngIf="!line?.extra_data?.is_new; else showSearchBox">
                  <span *ngIf="!line?.is_change_name">
                    {{ line?.product_name }}
                    <button *ngIf="!user?.contact_group?.is_external && quote?.status != eQuoteStatus?.APPROVED"
                      [ngbTooltip]="'QUOTE_DETAILS.CLICK_TO_CHANGE_THE_PRODUCT_NAME' | transloco" type="button"
                      (click)="line.is_change_name = true;line.new_product_name = line?.product_name"
                      class="btn btn-sm btn-link nounderline">
                      <i class="kphicon-uniE931 icon-size-sm mr-1"></i>
                    </button>
                  </span>
                  <div class="name-container"
                    *ngIf="line?.is_change_name && !user?.contact_group?.is_external && quote?.status != eQuoteStatus?.APPROVED">
                    <textarea class="name-ta" (keyup.enter)="changeProductName(line, idx)"
                      [(ngModel)]="line.new_product_name"
                      [placeholder]="'QUOTE_DETAILS.ENTER_PRODUCT_NAME_HERE'| transloco"></textarea>
                    <div class="name-buttons">
                      <button [disabled]="!line?.new_product_name?.length"
                        [ngbTooltip]="'QUOTE_DETAILS.CLICK_TO_SAVE_THE_CHANGE' | transloco" type="button"
                        (click)="changeProductName(line, idx)" class="btn btn-sm btn-link nounderline save">
                        <i class="kphicon-uniE91D icon-size-sm mr-1 green-text"></i>
                      </button>
                      <button [ngbTooltip]="'LOGIN.CANCEL' | transloco" type="button"
                        (click)="line.is_change_name = false;line.new_product_name = line?.product_name"
                        class="btn btn-sm btn-link nounderline cancel ">
                        <i class=" kphicon-uniE926 icon-size-sm mr-1 danger-text"></i>
                      </button>
                    </div>
                  </div>
                  <span class="ml-2" *ngIf=" line?.quotation_line_extra?.extra_info?.usages_prices?.length">
                    <i class="kphicon-uniE9A1 icon-size-md click-able green-text title-6"
                      [ngbTooltip]="'QUOTE_DETAILS.CLICK_TO_VIEW_USAGES_PRICE' | transloco"
                      (click)="openUsagesPriceModal(line,usagesPriceModal)"></i>
                  </span>
                </span>
                <ng-template #showSearchBox>
                  <app-search-product [index]=" idx" (selectedProductEvent)="onSelectProduct($event)">
                  </app-search-product>
                </ng-template>
              </td>
              <td class="quote-cart-qty">
                <div class="cart-qty-amount amount-col">
                  <div class="item-update-btn">
                    <div class="item-add-remove">
                      <button *ngIf="
                          !user?.contact_group?.is_external &&
                          quote?.status != eQuoteStatus?.APPROVED
                        " class="kphicon-uniE972 add-remove-btn dicrement-btn"
                        [disabled]="line.qty <= 1 || disableUpdate" (click)="decrementQty($event, line)"></button>
                      <div class="add-remove-input" *ngIf="!user?.contact_group?.is_external">
                        <input [disabled]="disableUpdate" type="text" min="1" [(ngModel)]="line.qty"
                          (ngModelChange)="updateQtyInQuote(line)" mask="separator.2" />
                      </div>
                      <div class="add-remove-input" *ngIf="user?.contact_group?.is_external">
                        <input disabled type="text" min="1" [(ngModel)]="line.qty"
                          (ngModelChange)="updateQtyInQuote(line)" mask="separator.2" />
                      </div>
                      <button *ngIf="
                          !user?.contact_group?.is_external &&
                          quote?.status != eQuoteStatus?.APPROVED
                        " [disabled]="disableUpdate" class="kphicon-uniE985 add-remove-btn incriment-btn"
                        (click)="incrementQty($event, line)"></button>
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <div class="d-flex flex-row">
                  <div class="discount-input" *ngIf="![eQuoteProductType.HARDWARE_MONTHLY, eQuoteProductType.SOFTWARE_MONTHLY]?.includes(line?.product_type?.system_type)" >
                    <input [disabled]="disableUpdate || [eProductType.ADDITIONAL_COST]?.includes(line?.product_type?.system_type)" *ngIf="line.discount_type === 'flat'" type="text" value=""
                      [(ngModel)]="line.discount_price_ex_vat" (ngModelChange)="checkQuoteChange(quote)"
                      class="form-control" mask="separator.2" />
                    <input [disabled]="disableUpdate || [eProductType.ADDITIONAL_COST]?.includes(line?.product_type?.system_type)" *ngIf="line.discount_type === 'percentage'" type="text" value=""
                      [(ngModel)]="line.discount_percentage" (ngModelChange)="checkPercentageValueInLine(line, quote)"
                      class="form-control" mask="separator.2" />
                  </div>
                  <div class="discount-input" *ngIf="!user?.contact_group?.is_external && [eQuoteProductType.HARDWARE_MONTHLY, eQuoteProductType.SOFTWARE_MONTHLY]?.includes(line?.product_type?.system_type)" >
                    <input [disabled]="disableUpdate || [eProductType.ADDITIONAL_COST]?.includes(line?.product_type?.system_type)" *ngIf="line.discount_type === 'flat'" type="text" value=""
                      [(ngModel)]="line.discount_recurring_monthly_fee_ex_vat" (ngModelChange)="checkQuoteChange(quote)"
                      class="form-control" mask="separator.2" />
                    <input [disabled]="disableUpdate || [eProductType.ADDITIONAL_COST]?.includes(line?.product_type?.system_type)" *ngIf="line.discount_type === 'percentage'" type="text" value=""
                      [(ngModel)]="line.discount_percentage_recurring_monthly_fee" (ngModelChange)="checkPercentageValueInLine(line, quote, true)"
                      class="form-control" mask="separator.2" />
                  </div>
                  <div class="select-dropdown discount-dropdown">
                    <button [disabled]="disableUpdate || [eProductType.ADDITIONAL_COST]?.includes(line?.product_type?.system_type)" class="discount-button" (click)="selectDiscountType(line)">
                      {{ line.discount_type === "flat" ? "€" : "%" }}
                    </button>
                  </div>
                </div>
              </td>
              <td *ngIf="!user?.contact_group?.is_external">
                <span *ngIf="line?.product_type?.system_type == 'special_service'">
                  <p class="m-0 body-text-18-light grey-text">
                    <select class="form-control kpn-form-control" [(ngModel)]="line.service_type"
                      (ngModelChange)="setServiceType(line, $event, quote)"
                      [disabled]="disableUpdate || line.service_type === 3">
                      <option value="0">--None--</option>
                      <option value="3" *ngIf="line.service_type === 3">
                        Software {{'PRODUCT_DETAILS.DOWNLOAD' | transloco }}
                      </option>
                      <option *ngFor="let list of serviceTypeList" [ngValue]="list.value">
                        {{ list.name }}
                      </option>
                    </select>
                  </p>
                </span>
              </td>
              <td class="mid-grey-text text-right" *ngIf="!user?.contact_group?.is_external">
                {{ line?.margin_percentage }}%
              </td>
              <td class="mid-grey-text text-right">
                {{ line?.agreement_discount_percent }}
                {{ line?.agreement_discount_percent ? "%" : "" }}
              </td>
              <td class="mid-grey-text text-right" *ngIf="!user?.contact_group?.is_external">
                {{ line?.agreement_markup_percent }}
                {{ line?.agreement_markup_percent ? "%" : "" }}
              </td>
              <td class="green-text text-right" *ngIf="
                  !user?.contact_group?.is_external
                ">
                  {{ (line.total_recurring_monthly_fee_ex_vat | currencyFormat) }}
              </td>
              <td class="mid-grey-text text-right ml-2" *ngIf="
                  !user?.contact_group?.is_external &&
                  quote?.system_code &&
                  quote?.system_code !== eQuoteSystemType.CISCO
                ">
                <div *ngIf="
                    !user?.contact_group?.is_external &&
                    quote?.system_code &&
                    ![eQuoteSystemType.CISCO]?.includes(quote?.system_code)
                  ">
                  <div *ngIf="disableUpdate || !line?.can_update_pp" ngbTooltip="{{
                      line?.transport_cost_ex_vat > 0
                        ? 'Transport Cost = ' + line?.transport_cost_ex_vat
                        : ''
                    }}">
                    {{ line?.total_purchase_price_ex_vat | currencyFormat }}
                    <strong>{{
                      line?.transport_cost_ex_vat > 0 ? "*" : ""
                      }}</strong>
                  </div>
                  <div *ngIf="
                      !disableUpdate &&
                      quote?.status !== eQuoteStatus.APPROVED &&
                      line?.can_update_pp
                    " class="edit-able-field" ngbTooltip="{{
                      line?.transport_cost_ex_vat > 0
                        ? 'Transport Cost = ' + line?.transport_cost_ex_vat
                        : ''
                    }}">
                    <div class="edit-text-form justify-content-end mb-1">
                      <input type="textbox" class="form-control grey-text" [(ngModel)]="line.purchase_price_ex_vat"
                        appTwoDecimalInput (ngModelChange)="updatePP()" />
                      <strong>{{
                        line?.transport_cost_ex_vat > 0 ? "*" : ""
                        }}</strong>
                    </div>
                  </div>
                  <hr class="m-0" />
                  <div class="text-right">
                    <strong>{{ line?.pp_unit_price | currencyFormat }}</strong>
                  </div>
                </div>
                <div *ngIf="
                    !user?.contact_group?.is_external &&
                    quote?.system_code &&
                    quote?.system_code === eQuoteSystemType.GENERIC
                  ">
                  <div *ngFor="
                      let item of line?.line_prices?.purchase_price_ex_vat
                    ">
                    <div *ngIf="item?.currency === 'EUR'">
                      <div>
                        {{ item?.price_ex_vat | currencyFormat }}
                      </div>
                      <hr class="m-0" />
                      <div class="text-right">
                        <strong>{{
                          item?.total_price_ex_vat | currencyFormat
                          }}</strong>
                      </div>
                    </div>
                    <div class="pt-2" *ngIf="item?.currency === 'USD'">
                      <div>
                        {{ item?.price_ex_vat | currency : "USD" : "symbol" }}
                      </div>
                      <hr class="m-0" />
                      <div class="text-right">
                        <strong>{{
                          item?.total_price_ex_vat | currency : "USD" : "symbol"
                          }}</strong>
                      </div>
                    </div>
                  </div>
                </div>
              </td>
              <td class="mid-grey-text text-right ml-2">
                <div *ngIf="!hidePrice;else NA">
                  <div *ngIf="
                    quote?.system_code &&
                    quote?.system_code !== eQuoteSystemType.GENERIC
                  ">
                  <div *ngIf="
                      !disableUpdate && line?.can_update_sp;
                      else notCustom
                    " class="edit-able-field">
                    <div class="edit-text-form justify-content-end mb-1">
                      <span class="mr-1"><b>€</b></span><input type="textbox" class="form-control grey-text"
                        [(ngModel)]="line.price_ex_vat" [disabled]="line?.extra_data?.lock_sales_price"
                        appTwoDecimalInput (ngModelChange)="updatePP()" />
                      <i *ngIf="
                          !line?.extra_data?.lock_sales_price &&
                          !line?.sp_lock_loader
                        " class="kphicon-uniE966 click-able green-text"
                        [ngbTooltip]="'QUOTE_DETAILS.PRESS_TO_LOCK_SALES_PRICE' | transloco"
                        (click)="lockSalesPrice(line, idx)"></i>
                      <span *ngIf="line?.sp_lock_loader" class="spinner-border spinner-border-sm green-text ml-2"
                        role="status" aria-hidden="true"></span>
                      <i *ngIf="
                          line?.extra_data?.lock_sales_price &&
                          !line?.sp_lock_loader
                        " [ngbTooltip]="'QUOTE_DETAILS.PRESS_TO_UNLOCK_SALES_PRICE' | transloco"
                        class="kphicon-uniE965 click-able green-text" (click)="unLockSalesPrice(line, idx)"></i>
                    </div>
                  </div>
                  <div>
                    <ng-template #notCustom>
                      {{ line.total_price_ex_vat | currencyFormat }}
                      <i *ngIf="
                          !user?.contact_group?.is_external &&
                          line?.extra_data?.lock_sales_price &&
                          !line?.sp_lock_loader
                        " class="kphicon-uniE965 click-able green-text" (click)="unLockSalesPrice(line, idx)"></i>
                    </ng-template>
                    <hr class="m-0" />
                    <div class="text-right">
                      <strong>{{
                        line?.sp_unit_price | currencyFormat
                        }}</strong>
                    </div>
                  </div>
                </div>
                <div *ngIf="
                    quote?.system_code &&
                    quote?.system_code === eQuoteSystemType.GENERIC
                  ">
                  <div *ngIf="!disableUpdate && line?.can_update_sp" class="edit-able-field">
                    <div class="edit-text-form justify-content-end mb-1">
                      <span class="mr-1"><b>€</b></span>
                      <input type="textbox" class="form-control grey-text" [(ngModel)]="line.price_ex_vat"
                        appTwoDecimalInput (ngModelChange)="updatePP()"
                        [disabled]="line?.extra_data?.lock_sales_price" />
                      <i *ngIf="
                          !line?.extra_data?.lock_sales_price &&
                          !line?.sp_lock_loader
                        " class="kphicon-uniE966 click-able green-text"
                        [ngbTooltip]="'QUOTE_DETAILS.PRESS_TO_LOCK_SALES_PRICE' | transloco"
                        (click)="lockSalesPrice(line, idx)"></i>
                      <span *ngIf="line?.sp_lock_loader" class="spinner-border spinner-border-sm green-text ml-2"
                        role="status" aria-hidden="true"></span>
                      <i *ngIf="
                          line?.extra_data?.lock_sales_price &&
                          !line?.sp_lock_loader
                        " [ngbTooltip]="'QUOTE_DETAILS.PRESS_TO_UNLOCK_SALES_PRICE' | transloco"
                        class="kphicon-uniE965 click-able green-text" (click)="unLockSalesPrice(line, idx)"></i>
                    </div>
                  </div>
                  <div>
                    <div *ngFor="let item of line?.line_prices?.sales_price_ex_vat">
                      <div *ngIf="item?.currency === 'EUR'">
                        <div>
                          {{ item?.price_ex_vat | currencyFormat }}
                        </div>
                        <hr class="m-0" />
                        <div class="text-right">
                          <strong>{{
                            item?.total_price_ex_vat | currencyFormat
                            }}</strong>
                        </div>
                      </div>
                      <div class="pt-2" *ngIf="item?.currency === 'USD'">
                        <div>
                          {{ item?.price_ex_vat | currency : "USD" : "symbol" }}
                        </div>
                        <hr class="m-0" />
                        <div class="text-right">
                          <strong>{{
                            item?.total_price_ex_vat
                            | currency : "USD" : "symbol"
                            }}</strong>
                        </div>
                      </div>
                    </div>
                    <!-- <ng-template #notCustom>
                      {{ line.total_price_ex_vat | currencyFormat }}
                    </ng-template>
                    <hr class="m-0">
                    <div class="text-right">
                      <strong>{{ line?.sp_unit_price | currencyFormat }}</strong>
                    </div> -->
                  </div>
                </div>
                </div>
                <ng-template #NA>
                  N/A
                </ng-template>
              </td>
              <td class="green-text text-right">
                {{ hidePrice ? 'N/A' : (line.discount_price_ex_vat | currencyFormat) }}
              </td>
              <td class="green-text text-right">
                {{ hidePrice ? 'N/A' : (line.amount_ex_vat | currencyFormat) }}
              </td>
              <td *ngIf="(!disableUpdate &&
                      !line?.extra_data?.is_new &&
                      line?.extra_data?.is_custom) || (!disableUpdate &&
                      hasQuoteLineDeletePermission &&
                      line?.extra_data?.allow_deletion )">
                <span>
                  <a (click)="saveLine(line, idx)" style="cursor: pointer" class="line-attr-btn save-attr-btn" *ngIf="
                      !disableUpdate &&
                      !line?.extra_data?.is_new &&
                      line?.extra_data?.is_custom
                    ">
                    <i class="kphicon-uniE91D"></i>
                  </a>
                  <a (click)="deleteLine(line, idx)" style="cursor: pointer" class="line-attr-btn trash-attr-btn" *ngIf="
                      !disableUpdate &&
                      hasQuoteLineDeletePermission &&
                      line?.extra_data?.allow_deletion
                    ">
                    <i class="ui-delete kpn-ui-icon icon-size-sm"></i>
                  </a>
                </span>
              </td>
            </tr>
            <tr class="subline-line" *ngFor="let subline of line.sub_lines"
              [ngStyle]="{ border: !subline?.is_valid ? '1px solid red' : '' }">
              <td colspan="4" [ngStyle]="{ color: !subline?.is_valid ? 'red' : '' }">
                {{ subline?.product_name }}
              </td>
              <td class="quote-cart-qty">
                <div class="cart-qty-amount amount-col pt-1">
                  <div class="item-update-btn">
                    <div class="item-add-remove">
                      <button class="kphicon-uniE972 add-remove-btn dicrement-btn disable-link" disabled></button>
                      <div class="add-remove-input">
                        <input type="text" min="1" disabled [(ngModel)]="subline.qty" mask="separator.2" />
                      </div>
                      <button class="kphicon-uniE985 add-remove-btn incriment-btn disable-link" disabled></button>
                    </div>
                  </div>
                </div>
              </td>
              <td [attr.colspan]="!user?.contact_group?.is_external ? '7' : '1'"></td>
              <td class="mid-grey-text text-right">
                {{ !user?.contact_group?.is_external  ? (subline.total_purchase_price_ex_vat | currencyFormat) : "" }}
              </td>
              <td class="mid-grey-text text-right">
                {{ hidePrice ? 'N/A' : ( subline.total_price_ex_vat | currencyFormat ) }}
              </td>
              <td class="mid-grey-text text-right">
                {{  hidePrice ? 'N/A' : ( subline.amount_ex_vat | currencyFormat ) }}
              </td>
            </tr>
            <tr>
              <td [attr.colspan]="!user?.contact_group?.is_external ? '15' : '14'">
                <div class="quote-total mb-0 text-right">
                  <span class="body-text-18-light grey-text mr-5">{{'QUOTE_DETAILS.LINE_TOTAL' | transloco}} :</span>
                  <label class="title-mid-5 mb-0 dark-grey-text text-right">
                    {{  hidePrice ? 'N/A' : ( line?.total_amount_ex_vat | currencyFormat ) }}</label>
                </div>
              </td>
            </tr>
            <tr *ngIf="!user?.contact_group?.is_external">
              <td [attr.colspan]="7">
                <ul>
                  <li class="mb-3" *ngFor="let question of line?.product_quote_questions;let i=index">
                    <label>{{ question?.attribute_name }}</label>
                    <div *ngIf="question?.attribute_type === 'Float'  ">
                      <input type="number" class="form-control" [(ngModel)]="question.value_float" [disabled]="disableUpdate" (ngModelChange)="checkQuoteChange(this.quote)"   placeholder=" " />
                    </div>
                    <div *ngIf="question?.attribute_type === 'Integer'">
                      <input type="number" class="form-control" [(ngModel)]="question.value_integer"  [disabled]="disableUpdate" (ngModelChange)="checkQuoteChange(this.quote)"     placeholder=" " />
                    </div>
                    <div *ngIf="((question?.attribute_type === 'Text') || (question?.attribute_type === 'Rich Text') || (question?.attribute_type === 'None'))">
                     <textarea class="form-control" [(ngModel)]="question.value_text"  [disabled]="disableUpdate"  (ngModelChange)="checkQuoteChange(this.quote)"    placeholder=" "></textarea>
                    </div>
                    <div *ngIf="(question?.attribute_type === 'Date')">
                      <input  placeholder="Select Date" class="form-control" [(ngModel)]="question.value_date"  [disabled]="disableUpdate" [owlDateTime]="validFrom" (ngModelChange)="checkQuoteChange(this.quote)"   [owlDateTimeTrigger]="validFrom"  />
                       <owl-date-time [pickerType]="'calendar'" #validFrom></owl-date-time>
                    </div>
                    <div *ngIf="question?.attribute_type === 'Boolean'">
                      <select class="form-control" [(ngModel)]="question.value_boolean"  [disabled]="disableUpdate" (ngModelChange)="checkQuoteChange(this.quote)"   >
                        <option disabled selected value="null" > -- select an option -- </option>
                        <option value="true">Yes</option>
                        <option value="false">No</option>
                      </select>
                    </div>
                  </li>
                </ul>
              </td>
            </tr>
          </tbody>
          <tbody *ngIf="
              quote?.allow_new_lines &&
              !user?.contact_group?.is_external &&
              quote?.status != eQuoteStatus?.APPROVED &&
              quote?.status != eQuoteStatus?.EXPIRED
            ">
            <tr>
              <td [attr.colspan]="!user?.contact_group?.is_external ? '15' : '14'">
                <div class="quote-total mb-0 text-left">
                  <button class="btn btn-sm btn-primary rounded-btn" placement="bottom" [disabled]="disableUpdate"
                    (click)="addMoreLine()">
                    {{'QUOTE_DETAILS.ADD_EXTRA' | transloco}}.
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>

        <ng-template #usagesPriceModal let-modal>
          <div class="modal-header">
            <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle">
              {{'QUOTE_DETAILS.GRADUATED_PRICES_FOR' | transloco}} {{ selectedQuoteLine?.product_name }}
            </h5>
            <button type="button" class="btn close" aria-label="Close" (click)="closeVoucher()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="kpn-card confirmation-cart-overview">
              <div class="row">
                <div class="col-md-3 col-3 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <b class="ml-3">{{'QUOTE_DETAILS.PRICE_TYPE' | transloco}}</b>
                  </div>
                </div>
                <div class="col-md-3 col-3 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <b class="right-align">{{'PRODUCT_DETAILS.PRICE' | transloco }} (ex BTW)</b>
                  </div>
                </div>
                <div class="col-md-2 col-2 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <b class="ml-2">{{'ORDER_STATUS.QTY' | transloco }}  <span class="danger-text">*</span></b>
                  </div>
                </div>
                <div class="col-md-3 col-3">
                  <div class="d-flex flex-row line-price-label quote-discount-bottom">
                    <b class="ml-3">{{'QUOTE_DETAILS.IMPORT_PRICE' | transloco }} <span class="danger-text">*</span></b>
                  </div>
                </div>
                <div class="col-md-1 col-1">
                  <div class="d-flex flex-row line-price-label quote-discount-bottom">
                  </div>
                </div>
              </div>
              <div class="row"
                *ngFor="let item of selectedQuoteLine?.quotation_line_extra?.extra_info?.usages_prices; let i = index">
                <div class="col-md-3 col-3 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <select disabled
                      class="form-control" [(ngModel)]="item.price_type">
                      <option *ngFor="let item of priceType" [value]="item.code">
                        {{ item.name }}
                      </option>
                    </select>
                    <p class="text-danger" *ngIf="item?.error?.price_type">
                      <span class="kphicon-uniE952"></span>
                      {{ item?.error?.price_type }}
                    </p>
                  </div>
                </div>
                <div class="col-md-3 col-3 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <span class="right-align">
                      {{item?.actual_price_ex_vat | currencyFormat: '€':
                      item?.actual_price_ex_vat?.split('.')?.[1]?.length}}
                    </span>
                  </div>
                </div>

                <div class="col-md-2 col-2">
                  <div class="d-flex flex-row line-price-label quote-discount-bottom">
                    <div class="discount-input-usages-price">
                      <input [disabled]="!hasQuotationExtraPermission || user?.contact_group?.is_external" type="text"
                        value="" [(ngModel)]="item.quantity" mask="0000000000" class="form-control" />
                    </div>
                  </div>
                  <p class="text-danger" *ngIf="item?.error?.quantity">
                    <span class="kphicon-uniE952"></span>
                    {{ item?.error?.quantity }}
                  </p>
                </div>
                <div class="col-md-3 col-3">
                  <div class="d-flex flex-row line-price-label quote-discount-bottom">
                    <div class="discount-input-usages-price">
                      <input [disabled]="!hasQuotationExtraPermission || user?.contact_group?.is_external"
                        *ngIf="item.price_type !== 'discount'" type="text" value="" [(ngModel)]="item.price_ex_vat"
                        class="form-control" mask="separator.2" />
                      <input [disabled]="!hasQuotationExtraPermission || user?.contact_group?.is_external"
                        *ngIf="item.price_type === 'discount'" type="text" min="1" max="5" value=""
                        [(ngModel)]="item.percentage" class="form-control" mask="separator.2" />
                    </div>
                    <div class="select-dropdown discount-dropdown">
                      <button class="discount-button">
                        {{ item.price_type !== "discount" ? "€" : "%" }}
                      </button>
                    </div>
                  </div>
                  <p class="text-danger" *ngIf="item?.error?.price_ex_vat">
                    <span class="kphicon-uniE952"></span>
                    {{ item?.error?.price_ex_vat }}
                  </p>
                </div>
                <div class="col-md-1 col-1">
                  <div *ngIf="hasQuotationExtraPermission && !user?.contact_group?.is_external"
                    class="d-flex flex-row line-price-label quote-discount-bottom">
                    <!-- <i *ngIf="!item?.loading" class="kphicon-uniE931 icon-size-md click-able green-text mr-2"
                      [ngbTooltip]="'QUOTE_DETAILS.CLICK_TO_SAVE_USAGES_PRICE' | transloco"
                      (click)="updateUsagesPrice(i)">
                    </i> -->
                    <div *ngIf="item?.loading" class="spinner-border spinner-border-sm field-spinner mt-1"
                      role="status">
                    </div>
                    <i class="kphicon-uniE929 icon-size-md click-able danger-text"
                      [ngbTooltip]="'QUOTE_DETAILS.CLICK_TO_DELETE_USAGES_PRICE' | transloco"
                      (click)="deleteUsagesPrice(item, i)"></i>
                  </div>
                </div>
              </div>
              <hr>
              <button  *ngIf="hasQuotationExtraPermission && !user?.contact_group?.is_external" class="btn btn-sm btn-dark" placement="bottom" [disabled]=""
              (click)="addMoreUsagePrice()">
              {{'QUOTE_DETAILS.ADD_EXTRA' | transloco}}
             </button>
              <div class="row justify-content-end mb-4">
                <div class="col-md-7 col-7 col-gap-reduce" *ngIf="usagesPriceError">
                  <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                    <p>
                      <span class="kphicon-uniE952"></span>
                      {{ usagesPriceError }}
                    </p>
                    <button class="kphicon-uniE926"></button>
                  </div>
                </div>
                <div class="col-md-auto col-gap-reduce">
                  <button type="button" class="btn-sm" (click)="closeVoucher()">
                    {{'QUOTE_DETAILS.CLOSE' | transloco}}
                  </button>
                </div>
                <div class="col-md-auto col-gap-reduce"
                  *ngIf="hasQuotationExtraPermission && !user?.contact_group?.is_external">
                  <button type="button" [disabled]="usagesPriceSavingLoader"
                    class="btn btn-sm btn-primary " (click)="updateUsagesPrice()">
                    <span *ngIf="!usagesPriceSavingLoader; else showSpinner">{{'QUOTE_DETAILS.SAVE' | transloco}}</span>
                    <ng-template #showSpinner>
                      <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </ng-template>

      </div>

      <!-- <div
        class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total"
        *ngIf="!isLoading"
      >
        <div class="col-auto pr-0">
          <h5 class="title-mid-5 mb-0">Total Purchase Prijs:</h5>
        </div>
        <div class="col-md-auto">
          <div
            class="mid-grey-text text-right line-price-label text-right mr-3 pr-1"
          >
            € {{ quote.total_purchase_price_ex_vat }}
          </div>
        </div>
      </div> -->
      <div class="pr-2 mr-1">
        <div class="row justify-content-end">
          <div class="mr-5">
            <div class="row justify-content-start mb-2 pt-2 discount-in-total" *ngIf="!isLoading">
              <div class="col-md-9 r-0">
                <h5 class="title-mid-5 mb-0">{{'QUOTE_DETAILS.TRANSPORT_COSTS' | transloco}}:</h5>
              </div>
              <div class="col-md-3">
                <div class="mid-grey-text text-right line-price-label text-right mr-3 pr-1">
                  {{  hidePrice ? 'N/A' : ( quote?.total_transport_cost_ex_vat | currencyFormat ) }}
                </div>
              </div>
            </div>
            <div class="row justify-content-start mb-2 pt-2 discount-in-total" *ngIf="!isLoading">
              <div class="col-md-9 pr-0">
                <h5 class="title-mid-5 mb-0">{{'QUOTE_DETAILS.RECURRING_FEE' | transloco}}:</h5>
              </div>
              <div class="col-md-3">
                <div class="mid-grey-text text-right line-price-label text-right mr-3 pr-1">
                  {{  hidePrice ? 'N/A' : ( quote?.total_recurring_monthly_fee | currencyFormat ) }}
                </div>
              </div>
            </div>
            <div class="row justify-content-start mb-2 pt-2 discount-in-total" *ngIf="!isLoading">
              <div class="col-md-9 pr-0">
                <h5 class="title-mid-5 mb-0">{{'QUOTE_DETAILS.RECURRING_FEE_EX_VAT' | transloco}}:</h5>
              </div>
              <div class="col-md-3">
                <div class="mid-grey-text text-right line-price-label text-right mr-3 pr-1">
                  {{  hidePrice ? 'N/A' : ( quote?.total_recurring_monthly_fee_ex_vat | currencyFormat ) }}
                </div>
              </div>
            </div>
          </div>

          <div class="ml-5">
            <div class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total"
              *ngIf="!user?.contact_group?.is_external && !isLoading">
              <div class="col-auto pr-0">
                <h5 class="title-mid-5 mb-0">{{'QUOTE_DETAILS.TOTAL_PURCHASE' | transloco}}:</h5>
              </div>
              <div class="col-md-auto">
                <div class="mid-grey-text text-right line-price-label text-right mr-3 pr-1">
                  {{ quote?.total_purchase_price_and_transport_cost_ex_vat | currencyFormat }}
                </div>
              </div>
            </div>

            <div class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total" *ngIf="!isLoading">
              <div class="col-auto">
                <h5 class="title-mid-5 mb-0">{{'QUOTE_DETAILS.DISCOUNT' | transloco}} :</h5>
              </div>
              <div class="col-auto" *ngIf="quote">
                <div class="d-flex flex-row line-price-label quote-discount-bottom">
                  <div class="discount-input" *ngIf="!user?.contact_group?.is_external">
                    <input [disabled]="disableUpdate" *ngIf="quote.discount_type === 'flat'" type="text" value=""
                      [(ngModel)]="quote.total_discount_ex_vat" (ngModelChange)="checkQuoteChange(quote)"
                      class="form-control" mask="separator.2" />
                    <input [disabled]="disableUpdate" *ngIf="quote.discount_type === 'percentage'" type="text" min="1"
                      max="5" value="" [(ngModel)]="quote.total_discount_percentage"
                      (ngModelChange)="checkPercentageValueInTotal(quote)" class="form-control" mask="separator.2" />
                  </div>
                  <div class="discount-input" *ngIf="user?.contact_group?.is_external">
                    {{  hidePrice ? 'N/A' : ( quote?.total_discount_ex_vat | currencyFormat ) }}
                  </div>
                  <div class="select-dropdown discount-dropdown">
                    <button [disabled]="disableUpdate" class="discount-button" (click)="selectQuoteDiscountType()">
                      {{ quote?.discount_type === "flat" ? "€" : "%" }}
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total" *ngIf="!isLoading">
              <div class="col-auto">
                <h5 class="title-mid-5 mb-0">{{'CART.TOTAL' | transloco}} {{'QUOTE_DETAILS.DISCOUNT' | transloco}}:</h5>
              </div>
              <div class="col-auto" *ngIf="quote">
                <div class="d-flex flex-row line-price-label quote-discount-bottom">
                  <div class="discount-input" *ngIf="user?.contact_group?.is_external">
                    {{  hidePrice ? 'N/A' : ( quote?.total_discount  ) }}
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total"
              *ngIf="!user?.contact_group?.is_external && !isLoading">
              <div class="col-auto pr-0">
                <h5 class="title-mid-5 mb-0">Marge :</h5>
              </div>
              <div class="col-md-auto">
                <div class="mid-grey-text text-right line-price-label text-right mr-3 pr-1">
                  {{  quote?.total_margin | currencyFormat  }}
                </div>
              </div>
            </div>

            <div class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total"
              *ngIf="!user?.contact_group?.is_external && !isLoading">
              <div class="col-auto pr-0">
                <h5 class="title-mid-5 mb-0">Marge Percentage :</h5>
              </div>
              <div class="col-md-auto">
                <div class="mid-grey-text text-right line-price-label text-right mr-3 pr-1">
                  {{  quote?.total_margin_percentage  }} %
                </div>
              </div>
            </div>

            <div class="quote-total mb-4 text-right" *ngIf="!isLoading">
              <span class="body-text-18-light grey-text mr-2 pr-1">{{'ORDER_STATUS.TOTAL_AMOUNT' | transloco }}:</span>
              <label class="title-mid-5 mb-0 line-price-label text-right green-text mr-3 pr-1">
                {{  hidePrice ? 'N/A' : ( quote?.total_amount_ex_vat | currencyFormat ) }}</label>
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center" *ngIf="!user?.contact_group?.is_external && !isLoading">
        <div class="col-md-12">
          <span class="mr-5">{{'QUOTE_DETAILS.PROJECT_NUMBER' | transloco}}:</span>
        </div>
        <div class="col-md-12" *ngIf="quote">
          <input [disabled]="disableUpdate" type="text" [(ngModel)]="quote.extra_data.project_number"
            class="form-control" (ngModelChange)="checkQuoteChange(quote)" />
        </div>
        <div class="col-md-12" *ngIf="quote">
          <span class="mr-5">{{'QUOTE_DETAILS.REMARKS' | transloco}}:</span>
        </div>
        <div class="col-md-12" *ngIf="quote">
          <textarea [disabled]="disableUpdate" [(ngModel)]="quote.remarks" cols="20" class="form-control"
            (ngModelChange)="checkQuoteChange(quote)"></textarea>
        </div>
        <div class="col-md-12" *ngIf="quote">
          <span class="mr-5">{{'QUOTE_DETAILS.PROJECT_DESCRIPTION' | transloco}}:</span>
        </div>
        <div class="col-md-12" *ngIf="quote">
          <textarea [disabled]="disableUpdate" [(ngModel)]="quote.extra_data.project_description" cols="20"
            class="form-control" (ngModelChange)="checkQuoteChange(quote)"></textarea>
        </div>
        <div class="col-md-6 mt-3" *ngIf="quote?.department?.bidding_allowed">
          <div class="mb-3">
            <form [formGroup]="documentUploadForm" (ngSubmit)="onDocumentSubmit()">
              <label>Upload Bidding Document</label>
              <div class="row">
                <div class="col-md-8 form-group kpn-form-group">
                  <input (change)="onDocumentFileChange($event)" disabled="{{disableUpdate}}" type="file"
                    formControlName="file" class="form-control" />
                  <div *ngIf="file?.invalid && (file?.dirty || file?.touched) && file.errors?.['required']"
                    class="text-danger">File is required.</div>
                </div>
                <div class="col-md-4">
                  <button class="btn btn-success px-3" [disabled]="!documentUploadForm.valid">
                    <span *ngIf="!isDocumentUploading; else uploading"> {{'QUOTE_DETAILS.UPLOAD' | transloco}}</span>
                    <ng-template #uploading>
                      <span>Loading...</span>
                    </ng-template>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="col-md-12" *ngIf="qutoteBiddingDocuments?.length">
          <h3 class="title-3 green-text pt-1" i18n="@@arm.order-status.order-list">Bidding Documents :</h3>
          <div class="table-responsive custom-table">
            <table class="table mb-5">
              <thead>
                <tr>
                  <th class="">File name</th>
                  <th>{{'SMS_CAMPAIGN.CREATED' | transloco}}</th>
                  <th>{{'SMS_CAMPAIGN.UPDATED' | transloco}}</th>
                  <th class="text-center">File</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let document of qutoteBiddingDocuments | paginate: config">
                  <td>{{document?.media_name}}</td>
                  <td>
                    {{document?.created_by}} ({{document.created_at | date: 'dd-MM-yyyy HH:mm'}})
                  </td>
                  <td>
                    {{document?.updated_by}} ({{document.updated_at | date: 'dd-MM-yyyy HH:mm'}})
                  </td>
                  <td class="text-center">
                    <button type="button" class="btn btn-sm btn-link" (click)="downloadDocument(document?.uuid)">
                      <i class="{{ utilityService?.getFileExtension(document?.media_name) }}" style="color: #5cb85c"
                        aria-hidden="true"></i>
                      <i class="kpn-ui-icon ui-download v-text-bottom mr-2" style="color: #5cb85c"></i>
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new">
              <pagination-controls id="bidding_documents_pagination"
                previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}"
                responsive="true" class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-12" *ngIf="attachmentDocuments?.length">
        <h3 class="title-3 green-text pt-1" i18n="@@arm.order-status.order-list">Attachment Documents :</h3>
        <div class="table-responsive custom-table">
          <table class="table mb-5">
            <thead>
              <tr>
                <th class="">File name</th>
                <th class="">Description</th>
                <th>{{'SMS_CAMPAIGN.CREATED' | transloco}}</th>
                <th>{{'SMS_CAMPAIGN.UPDATED' | transloco}}</th>
                <th class="text-center">File</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of attachmentDocuments | paginate: attachmentConfig">
                <td>{{document?.media_name}}</td>
                <td>{{document?.description}}</td>
                <td>
                  {{document?.created_by}} ({{document.created_at | date: 'dd-MM-yyyy HH:mm'}})
                </td>
                <td>
                  {{document?.updated_by}} ({{document.updated_at | date: 'dd-MM-yyyy HH:mm'}})
                </td>
                <td class="text-center">
                  <button type="button" class="btn btn-sm btn-link" (click)="downloadDocument(document?.uuid)">
                    <i class="{{ utilityService?.getFileExtension(document?.media_name) }}" style="color: #5cb85c"
                      aria-hidden="true"></i>
                    <i class="kpn-ui-icon ui-download v-text-bottom mr-2" style="color: #5cb85c"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-new">
            <pagination-controls id="attachment_documents_pagination"
              previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}"
              responsive="true" class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>

 <ng-template #kpnCustomer>
  <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 pt-4 pb-3">
    <li class="back-breadcrumb-btn border-0">
      <a routerLink="/quotes">
        <i class="kphicon-uniE906"></i> Terug
      </a>
    </li>
  </ul>
  <div class="">
    <h2 class="font-kpn-black tri-title-40 green-text mb-3">Offerte overzicht</h2>

    <div class="pb-1">
      <div class="bg-white shadow-qty-box tri-rounded-16 p-32 mb-2">
        <h3 class="green-text tri-title-32 font-kpn-black mb-3">Bedrijfsgegevens</h3>
        <div class="pt-1">
          <p class="d-flex aling-items-center font-kpn-extended mb-2 company-details-item-row">
            <label class="font-weight-bold mr-2 mb-0">Bedrijfsnaam</label><span>{{ quote?.contact?.company_name }}</span>
          </p>
          <p class="d-flex aling-items-center font-kpn-extended mb-2 company-details-item-row" *ngIf="quote?.shipping_address_details">
            <label class="font-weight-bold mr-2 mb-0">Adres</label><span>
              {{ quote?.shipping_address_details?.street }}
                {{ quote?.shipping_address_details?.house_number }}
                {{ quote?.shipping_address_details?.house_number_extension }}
            </span>
          </p>
          <p class="d-flex aling-items-center font-kpn-extended mb-2 company-details-item-row">
            <label class="font-weight-bold mr-2 mb-0" *ngIf="quote?.shipping_address_details?.zip_code">Postcode</label><span>{{ quote?.shipping_address_details?.zip_code }}</span>
          </p>
          <p class="d-flex aling-items-center font-kpn-extended mb-2 company-details-item-row">
            <label class="font-weight-bold mr-2 mb-0" *ngIf="quote?.shipping_address_details?.city">Plaats</label><span> {{ quote?.shipping_address_details?.city }}</span>
          </p>
          <p class="d-flex aling-items-center font-kpn-extended mb-2 company-details-item-row">
            <label class="font-weight-bold mr-2 mb-0">Contactpersoon</label><span>
              {{ addPrefixName(quote?.contact_person?.gender) }}
              {{ quote?.contact_person?.initials }}
              {{ quote?.contact_person?.surname_prefix }}
              {{ quote?.contact_person?.last_name }}
            </span>
          </p>
          <p class="d-flex aling-items-center font-kpn-extended mb-2 company-details-item-row">
            <label class="font-weight-bold mr-2 mb-0">Telefoonnummer</label><span> {{ quote?.contact_person?.mobile_phone_number }}</span>
          </p>
          <p class="d-flex aling-items-center font-kpn-extended mb-0 company-details-item-row">
            <label class="font-weight-bold mr-2 mb-0">Email</label><span>{{ quote?.contact_person?.email }}</span>
          </p>
        </div>
      </div>
    </div>
    <div class="pb-1">
      <div class="bg-white shadow-qty-box tri-rounded-16 p-32 mb-2">
        <h3 class="green-text tri-title-32 font-kpn-black mb-3">Offerte gegevens</h3>
        <div class="row pt-1">
          <div class="col-md-5">
            <div>
              <p class="d-flex align-items-center font-kpn-extended mb-2 company-details-item-row">
                <label class="font-weight-bold mr-2 mb-0">{{'QUOTE_DETAILS.QUOTATION' | transloco}} {{'QUOTE_DETAILS.DATE'
                  | transloco}}</label><span> {{ quote?.original_quotation_date | date : "d MMM y" }}</span>
              </p>
              <p class="d-flex align-items-center font-kpn-extended mb-2 company-details-item-row" *ngIf="quote?.validity_in_days">
                <label class="font-weight-bold mr-2 mb-0" >Geldigheidsdagen</label>
                <span>
                  {{ quote?.validity_in_days  }}
                </span>
              </p>
              <p class="d-flex align-items-center font-kpn-extended mb-2 company-details-item-row" *ngIf="(quote?.status === eQuoteStatus?.NEW) || (quote?.status === eQuoteStatus?.EXPIRED) || (quote?.status === eQuoteStatus?.PENDING)">
                <label class="font-weight-bold mr-2 mb-0" >Vervaldatum</label>
                <span>
                  {{ quote?.expire_date | date: 'd MMM y' }}
                </span>
              </p>

              <p class="d-flex align-items-center font-kpn-extended mb-2 company-details-item-row">
                <label class="font-weight-bold mr-2 mb-0">Versie</label><span>{{ quote?.version }}</span>
              </p>
              <p class="d-flex align-items-center font-kpn-extended mb-2 company-details-item-row">
                <label class="font-weight-bold mr-2 mb-0">{{'QUOTE_DETAILS.STATUS' | transloco}}</label><span class="blue-text">{{ quote?.status | titlecase }}</span>
              </p>
              <!-- <p class="d-flex align-items-center font-kpn-extended mb-2 company-details-item-row">
                <label class="font-weight-bold mr-2 mb-0">Account manager</label><span>{{ quote?.account_manager?.name }}</span>
              </p> -->
              <!-- <p class="d-flex align-items-center font-kpn-extended mb-0 company-details-item-row">
                <label class="font-weight-bold mr-2 mb-0">{{'QUOTE_DETAILS.SIGNING_MANAGER' | transloco}}</label><span>{{ quote?.signing_manager?.name }}</span>
              </p> -->
            </div>
          </div>
          <div class="col-md-auto">
            <div>
              <p class="d-flex align-items-center font-kpn-extended mb-1 offer-details-item-row" *ngIf="(quote?.status === eQuoteStatus?.APPROVED) && (user?.contact_group?.is_external)">
                <label class="font-weight-bold mr-2 mb-0">Offerte nummer</label><span>{{ reference }}</span>
              </p>
              <div class="d-flex align-items-center font-kpn-extended mb-2 offer-details-item-row" *ngIf="(quote?.status === eQuoteStatus?.APPROVED) && (user?.contact_group?.is_external) ">
                <label class="font-weight-bold mr-2 mb-0">Referentie nummer</label>
                <div>
                  <div class="kpn-form-group">
                    <input type="text" class="form-control tri-form-control" placeholder="Vul jouw referentie in" (keyup)="updateQuoteCustomerReference($event)"  [(ngModel)]="customer_reference"
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center font-kpn-extended mb-2 offer-details-item-row" *ngIf="
              siteProfile?.is_po_number_required &&
              user?.contact_group?.is_external &&
              quote?.status === eQuoteStatus?.APPROVED">
                <label class="font-weight-bold mr-2 mb-0">Jouw PO nummer</label>
                <div>
                  <div class="kpn-form-group">
                    <input type="text" class="form-control tri-form-control" placeholder="Vul jouw PO nummer in " (keyup)="updateQuotePoNumber($event)"  [(ngModel)]="po_number"
                    >
                  </div>
                </div>
              </div>
              <div class="d-flex align-items-center font-kpn-extended mb-2 offer-details-item-row" *ngIf="hasPermissionSetRequestedDeliveryDate && user?.contact_group?.is_external && quote?.status === eQuoteStatus?.APPROVED">
                <label class="font-weight-bold mr-2 mb-0">Gevraagde afleverdatum</label>
                <div>
                  <div class="kpn-form-group">
                    <input [min]="requestedDateMin" (ngModelChange)="onChangeRequestedDateDate($event)"
                      [(ngModel)]="requested_delivery_date" #dateFrom="ngModel"
                      [owlDateTime]="requestedDeliveryDate" [owlDateTimeTrigger]="requestedDeliveryDate"
                      class="form-control tri-form-control w-100"
                      placeholder="Gevraagde afleverdatum"
                      (keydown)="preventKeyEvent($event)"
                      />
                    <owl-date-time [pickerType]="'calendar'" #requestedDeliveryDate></owl-date-time>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pb-1" *ngIf="hasCpaas">
      <div class="bg-white shadow-qty-box tri-rounded-16 p-32 mb-2">
        <div  class="">
          <h3 class="green-text tri-title-32 font-kpn-black mb-3">CPaaS </h3>

          <from  [formGroup]="cPaasForm">
            <div class="row pt-3">
              <div class="col-md-3 ">
                <div class="form-group kpn-form-group">
                  <label >Email <span class="danger-text">*</span></label>
                  <input type="text" class="form-control tri-form-control kpn-form-control"   formControlName="cpaas_user_email" placeholder="" [ngClass]="{'disable-pointer-events': [eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status)  }"  [readonly]="[eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status) || isLoading"  />
                  <div *ngIf="cPaasForm.get('cpaas_user_email').hasError('trimError')" class="invalid-feedback">
                    {{ cPaasForm.get('cpaas_user_email')?.errors?.trimError?.value }}
                  </div>
                  <div *ngIf="cPaasForm.get('cpaas_user_email')?.invalid && (cPaasForm.get('cpaas_user_email')?.dirty|| cPaasForm.get('cpaas_user_email')?.touched)" class="text-danger">
                    <span
                      *ngIf="cPaasForm.get('cpaas_user_email')?.errors?.required">
                      {{'ORDER_OVERVIEW.EMAIL_IS_REQUIRED'| transloco}}
                    </span>
                    <span *ngIf="cPaasForm.get('cpaas_user_email')?.errors?.email">
                      {{'ORDER_OVERVIEW.THE_EMAIL_MUST_BE_A_VALID_EMAIL_ADDRESS' | transloco}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group kpn-form-group">
                  <label i18n="@@arm.order-status.int-num">{{'CUSTOMER.FIRST_NAME' | transloco }} <span
                      class="danger-text">*</span></label>
                  <input type="text" class="form-control tri-form-control kpn-form-control"  placeholder=""  formControlName="cpaas_user_first_name"  [ngClass]="{'disable-pointer-events': [eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status)  }" [readonly]="[eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status) || isLoading" />
                  <div *ngIf="cPaasForm.get('cpaas_user_first_name')?.invalid && (cPaasForm.get('cpaas_user_first_name')?.dirty|| cPaasForm.get('cpaas_user_first_name')?.touched)" class="text-danger">
                    <span
                        *ngIf="cPaasForm.get('cpaas_user_first_name')?.errors?.required">
                        {{ 'Voornaam is verplicht' }}
                      </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group kpn-form-group">
                  <label i18n="@@arm.order-status.int-num">{{'SIGN_UP.LAST_NAME' | transloco}} <span
                      class="danger-text">*</span></label>
                  <input type="text" class="form-control tri-form-control kpn-form-control"  formControlName="cpaas_user_last_name" [ngClass]="{'disable-pointer-events': [eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status)  }"  [readonly]="[eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status) || isLoading"
                    placeholder="" />
                  <div *ngIf="cPaasForm.get('cpaas_user_last_name')?.invalid && (cPaasForm.get('cpaas_user_last_name')?.dirty|| cPaasForm.get('cpaas_user_last_name')?.touched)" class="text-danger">
                    <span
                        *ngIf="cPaasForm.get('cpaas_user_last_name')?.errors?.required">
                        {{ 'Achternaam is vereist' }}
                      </span>
                   </div>
                </div>
              </div>
              <div class="col-md-2 col-gap-reduce pt-3">
                <button type="button" class="btn btn-sm btn-block btn-primary mt-2 rounded-btn"   (click)="saveCpassDataOnly()" [disabled]="([eQuoteStatus.PROCESSED, eQuoteStatus.EXPIRED]?.includes(quote?.status)) || isLoading || (!cPaasForm.valid)" >
                  Save
                </button>
              </div>
            </div>
          </from>
        </div>
      </div>
    </div>

    <div class="pb-1">
      <div class="bg-white shadow-qty-box tri-rounded-16 p-32 mb-2">
        <h3 class="green-text tri-title-32 font-kpn-black mb-4">Producten</h3>
        <div class="alert alert-warning alert-dismissible fade show mb-4" role="alert" *ngIf="hidePrice">
          De offerte is op dit ogenblik in behandeling. Zodra de offerte klaar is ontvang je een email en worden de speciale prijzen zichtbaar.

        </div>
        <div class="quote-product-list-table table-responsive ">
          <table cellpadding="0" cellspacing="0" border="0">
            <thead>
              <tr>
                <th class="text-left">Artikel</th>
                <th class="text-left">Artikelnummer</th>
                <th class="text-left">Aantal</th>
                <th class="text-right">Productprijs</th>
                <th class="text-right">Subtotaal</th>
                <th class="text-right">{{'QUOTE_DETAILS.DISCOUNT' | transloco}}</th>
                <th class="text-right">Kortingsbedrag</th>
                <th class="text-right text-nowrap">{{'ORDER_STATUS.TOTAL_AMOUNT' | transloco }}</th>
              </tr>
            </thead>
            <tbody *ngFor="let line of quote?.lines; let idx = index">
              <tr>
                <td class="text-left">{{ line?.product_name }}</td>
                <td class="text-left">{{ line?.product_code }}</td>
                <td class="text-left">{{ line?.qty }}</td>
                <td class="text-right">{{  hidePrice ? 'N/A' : (line?.price_ex_vat | currencyFormat)  }}</td>
                <td class="text-right">{{  hidePrice ? 'N/A' : (line?.amount_ex_vat | currencyFormat) }}</td>
                <td class="text-right">
                    <span >{{ hidePrice ? 'N/A' : ((line?.discount_price_ex_vat ? line?.discount_price_ex_vat : line?.discount_on_original_sales_price) | currencyFormat:'')}}</span>
                    <span *ngIf="!hidePrice">{{ line?.discount_type === "flat" ? "€" : "%" }}</span>
                </td>
                <td class="text-right">
                  <span>{{ hidePrice ? 'N/A' : ((line?.discount_percentage ? line?.discount_percentage : line?.discount_on_original_sales_price_percentage) | currencyFormat:'' )  }}</span>
                  <span *ngIf="!hidePrice">{{  line?.discount_type === "flat" ? "€" : "%" }}</span>
                </td>
                <td class="text-right">{{ hidePrice ? 'N/A' : ( line?.total_amount_ex_vat | currencyFormat) }}</td>
              </tr>
              <ng-container *ngFor="let subLine of line?.sub_lines;">
                <tr *ngIf="subLine?.product_type?.code === 'add_on'">
                  <td class="text-left">{{ subLine?.product_name }}</td>
                  <td class="text-left">{{ subLine?.product_code }}</td>
                  <td class="text-left">{{ subLine?.qty }}</td>
                  <td class="text-right">{{ hidePrice ? 'N/A' : (subLine?.price_ex_vat | currencyFormat) }}</td>
                  <td class="text-right">{{ hidePrice ? 'N/A' : (subLine?.amount_ex_vat | currencyFormat) }}</td>
                  <td class="text-right"></td>
                  <td class="text-right"></td>
                  <td class="text-right"></td>
                </tr>
              </ng-container>
            </tbody>

          </table>
        </div>
        <div class="table-footer-price d-flex align-items-center justify-content-end pt-3 mb-4">
          <span class="font-kpn-extended font-weight-bold mr-4">{{'ORDER_STATUS.TOTAL_AMOUNT' | transloco }}</span>
          <span class="fs-20 green-text font-kpn-bold font-weight-bolder">{{  hidePrice ? 'N/A' : ( quote?.total_amount_ex_vat | currencyFormat ) }}</span>
        </div>
        <div class="text-right pt-2"  *ngIf="
        user?.contact_group?.is_external &&
        quote?.status === eQuoteStatus?.APPROVED
      " >
         <span [ngbTooltip]="((siteProfile?.is_reference_required && !quote?.reference?.trim()?.length) ||
         (!quote?.customer_reference?.trim()?.length) ||
         (siteProfile?.is_po_number_required && !quote?.po_number?.trim()?.length) ||
         (hasCpaas && !isCpassDataAdded))
         ? ('Je kunt de order plaatsen als je'
         + (siteProfile?.is_reference_required && !quote?.reference?.trim()?.length ? ' een referentie nummer' : '')
         + (!quote?.customer_reference?.trim()?.length ? ' en een klantreferentie' : '')
         + (siteProfile?.is_po_number_required && !quote?.po_number?.trim()?.length ? ' en een PO nummer' : '')
         + (hasCpaas && !isCpassDataAdded ? ' en cpass-gegevens' : '')
         + ' hebt ingevuld')
         : ''" >
          <button (click)="checkTermsCondition(quoteTermAndAccepted)"
          [disabled]="orderCreateLoading || isLoading || (hasCpaas ? (!isCpassDataAdded) : false)  || (siteProfile?.is_reference_required && !quote?.reference?.trim()?.length) ||(!quote?.customer_reference?.trim()?.length) || (!customer_reference.trim()?.length) || (siteProfile?.is_po_number_required && ((!quote?.po_number?.trim()?.length) || (!po_number?.trim()?.length)))"
          class="btn px-4 btn-primary mt-2 rounded-btn">order plaatsen
          </button>
         </span>

        </div>
      </div>
      <div class="bg-white shadow-qty-box tri-rounded-16 p-32 mt-3" *ngIf="attachmentDocuments?.length">
        <h3 class="green-text tri-title-32 font-kpn-black mb-4">Attachment Documents </h3>

        <div class="table-responsive custom-table">
          <table class="table mb-5">
            <thead>
              <tr>
                <th class="">File name</th>
                <th class="">Description</th>
                <th>{{'SMS_CAMPAIGN.CREATED' | transloco}}</th>
                <th>{{'SMS_CAMPAIGN.UPDATED' | transloco}}</th>
                <th class="text-center">File</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let document of attachmentDocuments | paginate: attachmentConfig">
                <td>{{document?.media_name}}</td>
                <td>{{document?.description}}</td>
                <td>
                  {{document?.created_by}} ({{document.created_at | date: 'dd-MM-yyyy HH:mm'}})
                </td>
                <td>
                  {{document?.updated_by}} ({{document.updated_at | date: 'dd-MM-yyyy HH:mm'}})
                </td>
                <td class="text-center">
                  <button type="button" class="btn btn-sm btn-link" (click)="downloadDocument(document?.uuid)">
                    <i class="{{ utilityService?.getFileExtension(document?.media_name) }}" style="color: #5cb85c"
                      aria-hidden="true"></i>
                    <i class="kpn-ui-icon ui-download v-text-bottom mr-2" style="color: #5cb85c"></i>
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="pagination-new">
            <pagination-controls id="attachment_documents_pagination"
              previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}"
              responsive="true" class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
          </div>
        </div>
      </div>
    </div>
  </div>
 </ng-template>

  </div>
</section>

<ng-template #quoteTermAndAccepted let-modal>
  <div class="modal-header">
    <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle">
      {{'ORDER_OVERVIEW.ACCEPT_THE_TERMS' | transloco }}
    </h5>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.close();">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="kpn-card confirmation-cart-overview">
      <div class="voicelog-overview order-confirmation-user-info loader-wrapper-static loader-wrapper-content-heigth">
        <div class="kpn-spinner tri-rounded-20" *ngIf="termsAndConditionLoading">
          <div class="text-center">
            <img src="/assets/images/kpn-loader.gif" alt="isLoading">
          </div>
        </div>
        <div class="row mb-2 p-4">
          <div class="row" *ngIf="termsAndConditions?.length">
            <div class="col-md-12 col-12">
              <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
                <label ngbButtonLabel class="checkbox-item d-flex" *ngFor="let item of termsAndConditions">
                  <div class="checkbox-btn checkbox-btn-outline pt-1">
                    <input ngbButton type="checkbox" [value]="item.code" [(ngModel)]="item.checked"
                      (ngModelChange)="onChangeTermAndCondition(item)" />
                    <span class="checkBoxCheckmark"></span>
                  </div>
                  <div class="checkbox-label ml-2 text-left">
                    <span>
                      <small [innerHTML]="item?.description"></small>
                    </span>
                  </div>
                </label>
              </div>
            </div>
          </div>
          <div class="col-md-12 col-12 pt-1 right-align">
            <button class="btn btn-primary ctg-btn-overview font-kpn-extended px-3 mr-2 mb-2" [disabled]="!termAccepted" (click)="createOrder()">
              <span *ngIf="orderCreateLoading" class="spinner-border spinner-border-sm mr-2" role="status"
              aria-hidden="true"></span>
              {{'ACCOUNT.SUBMIT' | transloco}}
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #approveQuoteModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title title-2 green-text" id="approveQuoteModal">
      {{'QUOTE_DETAILS.APPROVE' | transloco}} {{'QUOTE_DETAILS.QUOTATION' | transloco}}
    </h5>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.close(); approve = false ">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="kpn-card confirmation-cart-overview">
      <form [formGroup]="approveRemarkForm">
        <div class="form-group">
          <label for="exampleFormControlInputemail">{{'QUOTE_DETAILS.BUYER_EMAIL' | transloco}}</label>
          <input type="email" class="form-control" id="exampleFormControlInputemail" formControlName="buyer_email"  placeholder="name@example.com">
          <div class="alert alert-danger mt-1" role="alert" *ngIf="remarksError?.buyer_email">
            {{remarksError?.buyer_email?.[0]}}
          </div>
        </div>
        <div class="form-group">
          <label for="exampleFormControlTextareaRemarks">{{'QUOTE_DETAILS.REMARKS' | transloco}}</label>
          <textarea class="form-control" id="exampleFormControlTextareaRemarks" formControlName="special_bidding_remarks" rows="3"></textarea>

          <div class="alert alert-danger mt-1" role="alert" *ngIf="remarksError?.special_bidding_remarks">
            {{remarksError?.special_bidding_remarks?.[0]}}
          </div>
        </div>

        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" formControlName="dont_send_approve_email" id="dont_send_email">
            <label class="form-check-label" for="dont_send_email">
              {{'QUOTE_DETAILS.DONT_SEND_EMAIL' | transloco}}
            </label>
          </div>
        </div>
        <div class="form-group">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" value="" formControlName="approve" id="invalidCheck2"  required>
            <label class="form-check-label" for="invalidCheck2">
              {{'QUOTE_DETAILS.APPROVE' | transloco}} {{'QUOTE_DETAILS.QUOTATION' | transloco}}
            </label>
          </div>
        </div>


        <div class="alert alert-danger mt-1" role="alert" *ngIf="remarksError">
          {{remarksError?.message || 'Failed to update'}}
        </div>
        <button class="btn btn-primary" type="submit" [disabled]="approveRemarkForm?.invalid || approving" (click)="updateQuoteWithRemarks()">
          <span *ngIf="approving" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
                {{'QUOTE_DETAILS.APPROVE' | transloco}}
        </button>
      </form>
    </div>
  </div>
</ng-template>
<ng-template #wordContextModal let-contextModal>
  <div class="modal-header">
    <h5 class="modal-title title-2 green-text" id="wordContextModal">
      Quote context details
    </h5>
    <button type="button" class="btn close" aria-label="Close" (click)="contextModal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="kpn-card confirmation-cart-overview">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th>Key</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let key of tableKeys">
          <td>{{ key }}</td>
          <td>
            <ng-container *ngIf="!isArray(wordContext[key])">{{ wordContext[key] }}</ng-container>
            <ng-container *ngIf="isArray(wordContext[key]) && wordContext[key]?.length">
              <table class="table table-sm">
                <thead>
                  <tr>
                    <th>Key</th>
                    <th>Value</th>
                  </tr>
                </thead>
                <tbody>
                  <ng-container *ngFor="let subItem of wordContext[key]; index as i">
                    <tr *ngFor="let subKey of getKeys(subItem)">
                      <td>{{ subKey }}</td>
                      <td>{{ subItem[subKey] }}</td>
                    </tr>
                    <tr colspan="2" *ngIf="wordContext[key]?.length-1 !== i">
                      <td colspan="2" class="bg-light">
                        <hr/>
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </ng-container>
          </td>
        </tr>
      </tbody>
    </table>

  </div>


  </div>
</ng-template>
