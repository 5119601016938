import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';

import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { IResetPasswordPayload } from 'src/app/shared/auth.interface';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  isFormSubmitted = false;
  hasPasswordsMatched = true;
  errors = null;
  public passRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{10,})');
  public passwordStrength = {
    float: 'left',
    width: '100%',
    height: '3px',
    'margin-left': '0px',
    'margin-bottom': '5px',
    'background-color': ''
  };
  logoSrc = '';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private toastrService: ToastrService,
    private store: Store<IAppState>,
    private translocoService: TranslocoService,
    public utilityService: UtilityService
  ) {
    this.store.select(selectSiteProfile).subscribe(data => {
      this.logoSrc = data?.logo_url || `/assets/images/logoipsum-logo.svg`;
    });
  }

  ngOnInit() {
    this.initializeForm();
  }

  initializeForm() {
    this.resetPasswordForm = this.fb.group({
      new_password1: new FormControl<string>('', [Validators.required, this.utilityService.passwordValidator()]),
      new_password2: new FormControl<string>('', [Validators.required]),
    },
      { validator: this.utilityService.passwordMatchValidator('new_password1', 'new_password2') }
    );
  }

  onKey(event: any) {
    this.errors = null;
    // tslint:disable-next-line:max-line-length
    this.resetPasswordForm.value.new_password1 = this.resetPasswordForm.value.new_password1 ? this.resetPasswordForm.value.new_password1.trim() : null;
    // tslint:disable-next-line:max-line-length
    this.resetPasswordForm.value.new_password2 = this.resetPasswordForm.value.new_password2 ? this.resetPasswordForm.value.new_password2.trim() : null;
    if (this.passRegex.test(this.resetPasswordForm.value.new_password1)) {
      this.passwordStrength['background-color'] = 'green';
      if (this.resetPasswordForm.value.new_password1 &&
        this.resetPasswordForm.value.new_password2 &&
        this.resetPasswordForm.value.new_password1 === this.resetPasswordForm.value.new_password2) {
        this.hasPasswordsMatched = true;
      } else {
        this.hasPasswordsMatched = false;
      }
    } else {
      this.passwordStrength['background-color'] = 'red';
    }
  }

  onResetFormSubmit() {
    this.isFormSubmitted = true;
    if (this.isFormSubmitted && this.resetPasswordForm.invalid) {
      return;
    } else if (this.isFormSubmitted && (this.resetPasswordForm.value.new_password1 !== this.resetPasswordForm.value.new_password2)) {
      this.hasPasswordsMatched = false;
      return;
    }
    const resetPasswordPayload: IResetPasswordPayload = {
      ...this.resetPasswordForm.value,
      token: this.route.snapshot.params.token,
      uid: this.route.snapshot.params.uid
    };

    this.authService.resetPassword(resetPasswordPayload).subscribe(res => {
      const message = `${this.translocoService.translate('RESET_PASSWORD.PASSWORD_CHANGED_LOG_IN')}`;
      this.toastrService.success(message, 'Success', {
        timeOut: 5000
      });
      this.router.navigate(['/']);
    }, error => {
      if (error.error.error && error.error.error.token) {
        if (this.errors) {
          this.errors = Object.assign(this.errors, { token: `${this.translocoService.translate('RESET_PASSWORD.TOKEN_IS_INVALID')}.` });
        } else {
          this.errors = {
            token: `${this.translocoService.translate('RESET_PASSWORD.TOKEN_IS_INVALID')}.`
          };
        }
      } else if (error.error.error && error.error.error.uid) {
        if (this.errors) {
          this.errors = Object.assign(this.errors, { uid: `${this.translocoService.translate('RESET_PASSWORD.PROVIDED_UID_IS_INVALID')}.` });
        } else {
          this.errors = {
            uid: `${this.translocoService.translate('RESET_PASSWORD.PROVIDED_UID_IS_INVALID')}.`
          };
        }
      } else if (error.error.error && error.error.error.new_password2) {
        let errorMessage = '';
        error.error.error.new_password2.forEach((element: string) => {
          errorMessage += element;
        });

        if (this.errors) {
          this.errors = Object.assign(this.errors, { new_password2: errorMessage });
        } else {
          this.errors = { new_password2: errorMessage };
        }
      } else {
        this.errors = Object.assign(this.errors, { new_password2: error.statusText });
      }
    });
  }

}
