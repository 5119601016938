import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';
import { StorageService } from 'src/app/shared/storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountTypeService {

  constructor(
    private apiBaseService: ApiBaseService,
    private storageService: StorageService
  ) { }

  getContact() {
    const user = this.storageService.getCurrentUser();
    const contact = this.storageService.getCustomer();
    return contact ? contact?.uuid : user?.contact?.uuid;
  }

  getContactName() {
    const user = this.storageService.getCurrentUser();
    const contact = this.storageService.getCustomer();
    return contact ? contact?.uuid : user?.contact?.uuid;
  }

  getAccountTypes(page = 1) {
    const paramsString = `page=${page}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    const uri = `contacts/${this.getContact()}/account-types`;
    return this.apiBaseService.get(uri, params);
  }

  getContactAccountTypesCustomers() {
    const uri = `contacts/${this.getContact()}/contact-account-type/clients`;
    return this.apiBaseService.get(uri);
  }

  deleteAccountType(account_type_id: string) {
    const uri = `contacts/${this.getContact()}/account-types/${account_type_id}`;
    return this.apiBaseService.delete(uri);
  }

  getAccountTypeCode() {
    let url = `configurations/account-types`;
    return this.apiBaseService.get(url);
  }

  updateAccountType(account_type_id: string, payload) {
    const url = `contacts/${this.getContact()}/account-types/${payload?.account_type_code}/account/${account_type_id}`;
    return this.apiBaseService.patch(url, payload);
  }

  getCartAccountTypes(cart_uuid, paramsString) {
    const params = new HttpParams({
      fromString: paramsString
    });
    const uri = `carts/${cart_uuid}/account-types`;
    return this.apiBaseService.get(uri, params, 'v2');
  }

  getQuoteAccountTypes(quote_uuid, paramsString) {
    const params = new HttpParams({
      fromString: paramsString
    });
    const uri = `quotes/${quote_uuid}/account-types`;
    return this.apiBaseService.get(uri, params);
  }
  updateQuoteAccountTypes(quote_uuid: string, payload) {
    const uri = `quotes/${quote_uuid}/update/contact-account-types`;
    return this.apiBaseService.put(uri, payload);
  }



}
