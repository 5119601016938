<div>
  <div class="flat-table" *ngIf="tradeInCart">
      <table class="table">
          <thead>
              <tr>
                  <th>Model</th>
                  <th class="pl-5">{{'ORDER_STATUS.QTY' | transloco }}</th>
                  <th></th>
                  <th class="text-right">{{'TRADE_CART.UNIT_PRICE' | transloco}}</th>
                  <th class="text-right">{{'TRADE_CART.TOTAL_PRICE' | transloco}}</th>
                  <th></th>
              </tr>
          </thead>
          <tbody>
              <tr *ngFor="let item of tradeInCart?.lines">
                  <td>{{item?.product?.brand?.name}} {{item?.product?.name}}</td>
                  <td>
                      <div class="qa-items">
                          <div class="qa-answer row">
                              <div class="col-auto">
                                  <div class="quantity d-flex align-items-center mb-2">
                                      <div class="item-add-remove">
                                          <div class="d-flex align-items-center">
                                              <button class="kphicon-uniE972 add-remove-btn dicrement-btn"
                                                  [ngClass]="{ 'disable-link': item.qty === 1 }"
                                                  (click)="decrementQuantity(item)"
                                                  [disabled]="item.qty === 1"></button>
                                              <div class="add-remove-input">
                                                  <input appPositiveInteger type="number" [(ngModel)]="item.qty"
                                                      min="1" (keyup.enter)="onEnter(item)">
                                              </div>
                                              <button class="kphicon-uniE985 add-remove-btn incriment-btn"
                                                  (click)="incrementQuantity(item)"></button>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </td>
                  <td>{{tradeInService?.getQuality(item?.product_quality) | titlecase}}</td>
                  <td class="text-right">{{item?.price_ex_vat | currencyFormat}}</td>
                  <td class="text-right">{{item?.total_price_ex_vat | currencyFormat}}</td>
                  <td class="text-right">
                      <button ngbTooltip="Bekijk details van de bestelling" type="button"
                          (click)="deleteCartLine(item)" class="btn btn-sm btn-link nounderline">
                          <i class="kphicon-uniE92A icon-size-md danger-text"></i>
                      </button>
                  </td>
              </tr>
          </tbody>
      </table>
  </div>

  <p class="d-flex justify-content-between bg-kpn-blue p-3  text-white rounded-lg">
      <b>{{'TRADE_CART.TOTAL_VALUE_BASED_ON_YOUR_STATEMENT' | transloco}}</b> {{'TRADE_CART.THIS_VALUE_MAY_DIFFER_FROM_THE_FINAL_VALUE_AFTER_CHECKING' | transloco}}
      <b>{{tradeInCart?.total_price_ex_vat | currencyFormat}}</b>
  </p>
</div>
