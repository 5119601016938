import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SalesforceService } from './salesforce.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-salesforce',
  templateUrl: './salesforce.component.html',
  styleUrls: ['./salesforce.component.scss']
})
export class SalesforceComponent implements OnInit {

  private customerInformation = {
    krn_number: '',
    first_name: '',
    last_name: '',
    first_name_prefix: '',
    snr: '',
    customer_segment: '',
    quote_name: ''

  };
  public quotationCreationError = null;
  public loading = true;

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private salesforceService: SalesforceService,
    private toasterService: ToastrService
  ) { }

  ngOnInit(): void {
    this.quotationCreationError = null;
    this.activateRoute.queryParams.subscribe((qp) => {
      this.customerInformation.krn_number = qp?.krn_id || '';
      this.customerInformation.snr = qp?.snr || qp?.SNR || '';
      this.customerInformation.first_name = qp?.first_name || '';
      this.customerInformation.last_name = qp?.last_name || '';
      this.customerInformation.first_name_prefix = qp?.surname_prefix || '';
      this.customerInformation.customer_segment = qp?.customer_segment || 'LCE';
      this.customerInformation.quote_name = qp?.quote_name || '';
      let validate = this.salesforceService.validatePayload(this.customerInformation);
      if (!validate?.length) {
        this.createQuotation();
      } else {
        this.loading = false;
        this.quotationCreationError = `${validate?.join(', ')} is missing`;
      }
    });
  }

  createQuotation() {
    this.salesforceService.createQuotation(this.customerInformation).subscribe(res => {
      this.toasterService.success('Offerte is succesvol aangemaakt', 'Success');
      this.router.navigate(['quote-details', `${res?.data?.uuid}`]);
    }, err => {
      this.loading = false;
      this.quotationCreationError = err?.message || 'Het maken van een offerte is mislukt';
      this.toasterService.error(this.quotationCreationError, `${err?.status} ${err?.statusText}`)
    })
  }

}
