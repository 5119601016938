<div>
  <app-loader [isLoading]="isLoading"></app-loader>

  <div class="c-px-24">
    <div class="row gutter-row-15 mb-4" *ngIf="contactAccountTypesCustomers?.length">
      <div class="col-md-6 gutter-col-15">
        <label for="" class="font-kpn-bold mb-2" *ngIf="hasViewContactAccountTypeCustomer">Selecteer een klant </label>
        <div  *ngIf="contactAccountTypesCustomers?.length">
          <ng-select [placeholder]="'Selecteer een klant'" class="tri-form-control tri-form-control-select-op" [items]="contactAccountTypesCustomers" [disabled]="!sowAddRemoveEnrollmentBtn"
            bindLabel="name" bindValue="uuid" [(ngModel)]="selectedContactAccountTypesCustomer"
            (ngModelChange)="onContactAccountTypesCustomerChange($event)">
          </ng-select>
        </div>
      </div>
    </div>
    <div class="row gutter-row-24">
      <ng-container *ngFor="let item of accountTypes">
        <div class="mb-2 gutter-col-24" [ngClass]="{ 'col-md-4' : colSizeFour, 'col-md-6' : !colSizeFour }"  *ngIf="onlyShowEnrolledItem ? item?.checked : true" >
          <div class="bg-gray-1 quote-enrollment-box tri-rounded-20 p-24" [ngClass]="{'quote-enrollment-item-added': !item?.checked}" >
            <div class="enrollment-brand-title">
              <h4 class="font-kpn-extended kpn-extended-bold fs-18 letter-spacing-normal">{{ item?.account_name }}
              </h4>
            </div>
            <div class="mb-0">
              <div class="d-flex align-items-center justify-content-between kpn-font-color font-kpn-extended">
                <span>Aantal mobiele telefoons </span>
                <span>{{ item?.product_count }}</span>
              </div>
              <hr class="border-color-tri my-2">
              <div class="d-flex align-items-center justify-content-between grey-text font-kpn-extended">
                <span>Enrollment ID</span>
                <span>{{ item?.account_id }}</span>
              </div>
            </div>
            <p class="mb-0 pt-3" *ngIf="item?.checked && sowAddRemoveEnrollmentBtn" >
              <button  (click)="addOrRemoveAccountTypeToQuote(item,'Remove')" [disabled]="!sowAddRemoveEnrollmentBtn" class="btn btn-block btn-outline-primary border-2 rounded-btn px-4">Verwijder
                enrollment</button>
            </p>
            <p class="mb-0 pt-3" *ngIf="!item?.checked && sowAddRemoveEnrollmentBtn">
              <button (click)="addOrRemoveAccountTypeToQuote(item,'Add')"  [disabled]="!sowAddRemoveEnrollmentBtn" class="btn btn-block btn-primary border-2 rounded-btn px-4">
                Voeg enrollment toe
                <i class="kphicon-uniE985 align-middle fs-20 ml-1"></i>
              </button>
            </p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <hr class="mt-3 mb-3" *ngIf="showEditBtn">

  <p class="mb-0 c-px-24" *ngIf="showEditBtn">
    <a href="javascript:void(0)" (click)="openEnrollmentUpdateModal(enrollmentUpdateModal)"
      class="fs-16 font-kpn-extended link-btn-blue ">
      Wijzigen <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
  </p>


  <ng-template #enrollmentUpdateModal let-modal>
    <div class="px-4">
      <div class="px-1 modal-header tri-modal-header">
        <h6 class="modal-title green-text font-kpn-black fs-40" id="cpaasMainUserModal">
          Bestelgegevens wijzigen
        </h6>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <div>
        <div class="pl-4 pt-4 pr-4">
          <div class="min-h-205">
            <div class="row gutter-row-15 mb-4" *ngIf="contactAccountTypesCustomers?.length">
              <div class="col-md-6 gutter-col-15">
                <label for="" class="font-kpn-bold mb-2" *ngIf="hasViewContactAccountTypeCustomer">Selecteer een klant </label>
                <div  *ngIf="contactAccountTypesCustomers?.length">
                  <ng-select [placeholder]="'Selecteer een klant'" class="tri-form-control tri-form-control-select-op" [items]="contactAccountTypesCustomers"
                    bindLabel="name" bindValue="uuid" [(ngModel)]="selectedContactAccountTypesCustomer"
                    (ngModelChange)="onContactAccountTypesCustomerChange($event)">
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row gutter-row-24">
              <div class="col-md-6 mb-4 gutter-col-24" *ngFor="let item of accountTypes">
                <div class="bg-gray-1 quote-enrollment-box tri-rounded-20 p-24" [ngClass]="{'quote-enrollment-item-added': !item?.checked}" >
                  <div class="enrollment-brand-title">
                    <h4 class="font-kpn-extended kpn-extended-bold fs-18 letter-spacing-normal">{{ item?.account_name }}
                    </h4>
                  </div>
                  <div class="mb-1">
                    <div class="d-flex align-items-center justify-content-between kpn-font-color font-kpn-extended">
                      <span>Aantal mobiele telefoons </span>
                      <span>{{ item?.product_count }}</span>
                    </div>
                    <hr class="border-color-tri my-2">
                    <div class="d-flex align-items-center justify-content-between grey-text font-kpn-extended">
                      <span>Enrollment ID</span>
                      <span>{{ item?.account_id }}</span>
                    </div>

                  </div>
                  <p class="mb-0 pt-3" *ngIf="item?.checked" >
                    <button  (click)="addOrRemoveAccountTypeToQuote(item,'Remove')" class="btn btn-block btn-outline-primary border-2 rounded-btn px-4">Verwijder
                      enrollment</button>
                  </p>
                  <p class="mb-0 pt-3" *ngIf="!item?.checked ">
                    <button (click)="addOrRemoveAccountTypeToQuote(item,'Add')" class="btn btn-block btn-primary border-2 rounded-btn px-4">
                      Voeg enrollment toe
                      <i class="kphicon-uniE985 align-middle fs-20 ml-1"></i>
                    </button>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>

</div>
