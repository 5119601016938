import { ESiteProfileActions, SiteProfileActions } from '../actions/siteProfile.actions';

export function siteProfileReducer(state: any = [], action: SiteProfileActions) {
    switch (action.type) {
        case ESiteProfileActions.SITE_PROFILE_LOAD_SUCCESS:
            return action.siteProfile;
        default:
            return state;
    }
}
