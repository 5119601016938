import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from '../app.state';
import { BasePermission } from '../shared/base/base-permission';
import { StorageService } from '../shared/storage.service';

@Injectable({
  providedIn: 'root'
})
export class RmaGuard extends BasePermission implements CanActivate {
  constructor(
    private router: Router,
    public store: Store<IAppState>,
    private storageService: StorageService
  ) {
    super(store);
  }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const siteProfile = this.storageService.getSiteProfile();
    const permissions = this.storageService.getPermission();
    const hasRmaPermission = permissions.some(permission => {
      return ['return_management.add_rma', 'return_management.view_rma'].includes(permission.code);
    });
    if (hasRmaPermission && siteProfile?.allow_rma_handling) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

}
