import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, HttpErrorResponse, HttpEvent } from '@angular/common/http';
import { Router } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

import { AuthService } from './auth.service';
import { StorageService } from './storage.service';
import { CartService } from '../pages/cart/cart.service';
import { ToastrService } from 'ngx-toastr';
import { FavoriteProductsService } from '../pages/favorite-products/favorite-products.service';
import { PermissionSuccessAction } from '../store/actions/permission.actions';
import { IAppState } from '../app.state';
import { Store } from '@ngrx/store';
import { EUrl } from './enums/messages.enums';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
  public isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);
  private openUrls = ['accounts/password/reset', 'accounts/login'];
  private serverError = `Er is een onverwachte fout ontstaan. Probeer het opnieuw.
  Maak een screenshot van deze pagina voor verder onderzoek`;
  private sales_force_url = null;


  constructor(
    public authService: AuthService,
    private storageService: StorageService,
    private router: Router,
    private cartService: CartService,
    private favoriteProductsService: FavoriteProductsService,
    private toastrService: ToastrService,
    private store: Store<IAppState>,
    private location: Location

  ) { }


  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const accessToken = this.storageService.getToken();
    const isTokenValid = this.isAccessTokenExpired(accessToken);
    if (accessToken && isTokenValid) {
      request = this.addToken(request, accessToken);
    } else if (accessToken && !isTokenValid && !request.url.includes('/accounts/refresh-token/')) {
      console.log('Token is about to expire within the next 2 minutes.');
      return this.handle401Error(request, next);
    }

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        return event;
      }),
      catchError(error => {
        if (error instanceof HttpErrorResponse && error.status === 401 && !request.url.includes('/accounts/refresh-token/')) {
          return this.handle401Error(request, next);
        } else if (error instanceof HttpErrorResponse && error.status === 403) {
          const api = error.url.replace(environment.baseApiUrl, '');
          this.toastrService.error('Permission denied in ' + api, 'PERMISSION_DENIED', {
            timeOut: 5000
          });
          return throwError(error);
        } else if (error instanceof HttpErrorResponse && error.status === 0) {
          this.toastrService.error("Er is een verbindingsfout opgetreden. Zou je het alsjeblieft opnieuw willen proberen?");
          return throwError(error);
        } else {
          this.toastrService.error(this.serverError, 'Error', { timeOut: 5000 });
          return throwError(error);
        }
      })
    );
  }


  public isAccessTokenExpired(accessToken: string, thresholdMinutes = 2) {
    if (!accessToken) return false;
    const parts = accessToken?.replace("JWT ", '')?.split('.');
    if (parts?.length !== 3) {
      return false; // Invalid number of parts
    }

    try {
      const header = JSON.parse(atob(parts[0]));
      const payload = JSON.parse(atob(parts[1]));

      const expirationTimeInSeconds = payload.exp;
      const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000;

      const currentTime = Date.now();

      // Calculate the threshold time (2 minutes before expiration)
      const thresholdTime = expirationTimeInMilliseconds - (thresholdMinutes * 60 * 1000);

      // Check if the current time is after the threshold time
      return thresholdTime > currentTime;

    } catch (error) {
      return false; // Error parsing JSON or decoding Base64
    }
  }


  private addToken(request: HttpRequest<any>, token: string) {
    if (request && request.headers && !request.headers.has('Content-Type') && !request.headers.has('form-data')) {
      request = request.clone({ headers: request.headers.set('Content-Type', 'application/json') });
    }
    request = request.clone({ headers: request.headers.delete('form-data') });
    request = request.clone({ headers: request.headers.set('Accept', 'application/json') });
    return request.clone({
      setHeaders: {
        Authorization: `${token}`
      }
    });
  }


  public clearStorage() {
    this.storageService.removeReComCart();
    this.storageService.removeAllStorage();
    this.cartService.changeCurrentCart(null);
    this.favoriteProductsService.clearFavoriteProducts();
    this.store.dispatch(new PermissionSuccessAction([]));

    let url = this.router?.routerState?.snapshot?.url;
    if (url?.includes(EUrl.SALES_FORCE)) {
      this.sales_force_url = url;
    }

    const currentPath = this.location.path();
    if (!currentPath?.includes('/login')) {
      this.router.navigate(['login'], { queryParams: { returnUrl: this.sales_force_url } }).then(res => {
        location.reload();
      });
    }
  }



  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      const refreshToken = this.storageService.getRefreshToken();

      if (refreshToken) {
        return this.authService.refreshToken(refreshToken).pipe(
          switchMap((tokenResponse: any) => {
            this.isRefreshing = false;
            this.refreshTokenSubject.next(tokenResponse?.data?.token);
            return next.handle(this.addToken(request, ` JWT ${tokenResponse?.data?.token}`));
          }),
          catchError((err) => {
            this.isRefreshing = false;
            this.clearStorage() // Auto logout on refresh failure
            return throwError(err);
          })
        );
      } else {
        this.clearStorage();
      }
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token !== null),
        take(1),
        switchMap((accessToken) => next.handle(this.addToken(request, `JWT ${accessToken!}`)))
      );
    }

    return throwError('No refresh token available');
  }
}


