import { Component } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { QuoteDetailsService } from '../../../quote-details.service';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { AddCustomerService } from 'src/app/pages/add-customer/add-customer.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { EAddressPrecision, EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { BasePermission } from 'src/app/shared/base/base-permission';

@Component({
  selector: 'app-customer-quote-contact-person',
  templateUrl: './customer-quote-contact-person.component.html',
  styleUrls: ['./customer-quote-contact-person.component.scss']
})
export class CustomerQuoteContactPersonComponent extends BasePermission {
  quote = null;
  isLoading: boolean;
  quoteContactPersons = [];
  selectedQuoteContactPerson = null;
  isContactPersonCreating: boolean = false;
  temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = null;
  contactPersonModalRef = null;
  newContactPerson = {
    initials: '',
    first_name: '',
    last_name: '',
    surname_prefix: '',
    email: '',
    mobile_phone_number: ''
  };
  contactPersonCreateError = null;


  private currentCustomerDetailsQuoteSubscription$: Subscription;
  private currentCustomerQuoteDetailsContactInfoSubscription$: Subscription;
  constructor(
    private modalService: NgbModal,
    private quoteDetailsService: QuoteDetailsService,
    public store: Store<IAppState>,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private addCustomerService: AddCustomerService,
    private customerService: CustomerService,

  ) {
    super(store);
  }
  get eQuoteStatus() { return EQuoteStatus; }
  get eAddressPrecision() {
    return EAddressPrecision;
  }
  ngOnInit() {
    this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe(quote => {
      this.quote = quote;
      // if (quote) {
      //   this.getQuoteContactDetails();
      // }
    });
    this.currentCustomerQuoteDetailsContactInfoSubscription$ = this.quoteDetailsService.currentCustomerQuoteDetailsContactInfo$.subscribe(contactInfo => {
      if (contactInfo) {
        this.quoteContactPersons = contactInfo?.contact_persons;
        this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid || item?.email === this.quote?.contact_person?.email) || null;
        this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = this.selectedQuoteContactPerson?.uuid || null;
      }
    });
  }





  openNewContactPersonCreateView() {
    this.isContactPersonCreating = true;

  }
  closeNewContactPersonCreateView() {
    this.isContactPersonCreating = false;
  }










  resetObject<T>(defaultValues: T): T {
    return { ...defaultValues };
  }

  updateQuoteContactPerson() {
    this.isLoading = true;
    this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, { contact_person: this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson }).subscribe(res => {
      this.toastrService.success(`Contact person updated`, 'Success');
      this.quote = res.data;
      this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid ) || null;
      this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = this.selectedQuoteContactPerson?.uuid || null;
      this.contactPersonModalRef.close();
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.toastrService.error(error?.error?.message || 'Contact person updated failed', 'Error');
    });
  }
  async createContactAndUpdateQuote() {
    this.isLoading = true;
    this.contactPersonCreateError = null;
    this.addCustomerService.createContactPerson(this.quote.contact.uuid, this.newContactPerson).toPromise().then(async (response) => {
      if (response?.data) {
        this.quoteContactPersons = [response?.data, ...this.quoteContactPersons];
        this.newContactPerson = {
          initials: '',
          first_name: '',
          last_name: '',
          surname_prefix: '',
          email: '',
          mobile_phone_number: ''
        };
        this.closeNewContactPersonCreateView();
        if (await this.updateQuoteFields({ contact_person: response.data.uuid }, 'Contact person')) {
          this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid) || null;
          this.isLoading = false;
        }
      }
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_CREATE_FAILED'));
      this.contactPersonCreateError = error?.error?.error;
    });
  }
  openContactPersonModal(contactPersonModal) {
    this.contactPersonModalRef = this.modalService.open(contactPersonModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-595 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });

    this.contactPersonModalRef.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  async updateQuoteFields(payload = {}, message: string) {
    return this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, payload).toPromise().then(res => {
      this.toastrService.success(`${message} updated`, 'Success');
      this.quote = res.data;
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${message} updated failed`, 'Error');
      return false;
    });
  }
  getQuoteContactDetails() {
    this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
      this.isLoading = false;

      this.quoteContactPersons = res?.data?.contact_persons;
      this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid || item?.email === this.quote?.contact_person?.email) || null;
      this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = this.selectedQuoteContactPerson?.uuid || null;

    }, error => {
      this.isLoading = false;
      console.log('error: ', error);

    })
  }

  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
    if (this.currentCustomerQuoteDetailsContactInfoSubscription$) {
      this.currentCustomerQuoteDetailsContactInfoSubscription$.unsubscribe();
    }
  }
}
