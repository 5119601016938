<div class="auth-wrapper">
  <div class="auth-gradient">
    <div class="auth-box">
      <div class="auth-logo text-center">
        <img [src]="logoSrc" alt="logo">
      </div>
      <div class="auth-form-box">
        <form [formGroup]="resetPasswordForm" (ngSubmit)="onResetFormSubmit()">
          <h2 class="title-2 green-text mb-3" i18n="@@arm.login.login-title">{{'RESET_PASSWORD.RESET_PASSWORD' | transloco}}</h2>
          <div [ngStyle]="passwordStrength"></div>
          <div class="form-group kpn-form-group">
            <input type="password" formControlName="new_password1" (keyup)="onKey($event)"
              [ngClass]="{ 'is-invalid': (isFormSubmitted && resetPasswordForm.get('new_password1').errors) || !hasPasswordsMatched }"
              class="form-control kpn-form-control" [placeholder]="'RESET_PASSWORD.NEW_PASSWORD' | transloco">
            <div *ngIf="isFormSubmitted && resetPasswordForm.get('new_password1').errors" class="invalid-feedback">
              <div *ngIf="resetPasswordForm.get('new_password1').errors.required"
                i18n="@@arm.login.invalid-msg-password">
                {{'RESET_PASSWORD.ENTER_YOUR_PASSWORD'  | transloco}}
              </div>
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.get('new_password1')?.hasError('required') && resetPasswordForm?.get('new_password1').touched">
              Password is required
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.get('new_password1')?.hasError('length') && resetPasswordForm?.get('new_password1').touched">
              You password must be at least 10 characters.
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.get('new_password1')?.hasError('numberError') && resetPasswordForm?.get('new_password1').touched">
              You password must contain at least 1 number.
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.get('new_password1')?.hasError('lowerCaseError') && resetPasswordForm?.get('new_password1').touched">
              You password must contain at least 1 Lowercase Character.
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.get('new_password1')?.hasError('upperCaseError') && resetPasswordForm?.get('new_password1').touched">
              You password must contain at least 1 Uppercase Character.
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.get('new_password1')?.hasError('specialCharacterError') && resetPasswordForm?.get('new_password1').touched">
              You password must contain at least 1 special character.
            </div>
            <div class="form-text text-muted" *ngIf="passwordStrength['background-color'] !== 'green'">
              <small> {{'RESET_PASSWORD.PASSWORD_RULE' | transloco}}!
              </small>
            </div>
          </div>

          <div class="form-group kpn-form-group mb-4">
            <input type="password" formControlName="new_password2" (keyup)="onKey($event)"
              [ngClass]="{ 'is-invalid': (isFormSubmitted && resetPasswordForm.get('new_password2').errors) || !hasPasswordsMatched }"
              class="form-control kpn-form-control" [placeholder]="'RESET_PASSWORD.RE_NEW_PASSWORD' | transloco">
            <div *ngIf="isFormSubmitted && resetPasswordForm.get('new_password2').errors" class="invalid-feedback">
              <div *ngIf="resetPasswordForm.get('new_password2').errors.required"
                i18n="@@arm.login.invalid-msg-password">
                {{'RESET_PASSWORD.ENTER_PASSWORD_AGAIN' | transloco}}
              </div>
            </div>
            <div class="danger-text" *ngIf="resetPasswordForm?.hasError('passwordsMismatch') && resetPasswordForm?.get('new_password2').touched">
              {{'RESET_PASSWORD.PASSWORD_NOT_MATCH' | transloco}}
            </div>
          </div>
          <p *ngIf="errors?.token" class="login-error">{{errors?.token}}</p>
          <p *ngIf="errors?.uid" class="login-error">{{errors?.uid}}</p>
          <p *ngIf="errors?.new_password2" class="login-error">{{errors?.new_password2}}</p>
          <button type="submit"
            [disabled]="resetPasswordForm?.invalid || !hasPasswordsMatched || !resetPasswordForm.get('new_password1').value || !resetPasswordForm.get('new_password2').value"
            class="btn btn-primary btn-block rounded-btn">
            <span>Reset</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</div>