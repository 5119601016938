import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { StorageService } from '../../shared/storage.service';
import { AuthService } from '../../shared/auth.service';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { PermissionLoadAction } from 'src/app/store/actions/permission.actions';
import { SiteProfileLoadAction } from 'src/app/store/actions/siteProfile.actions';
import { FavoriteProductsService } from 'src/app/pages/favorite-products/favorite-products.service';
import { timer } from 'rxjs';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';

@Component({
  selector: 'app-two-factor-auth',
  templateUrl: './two-factor-auth.component.html',
  styleUrls: ['./two-factor-auth.component.scss'],
})
export class TwofactorauthComponent implements OnInit {
  qrData = null;
  secretCode = null;
  securityCode = null;
  issendSMS = false;
  errorMsg = null;
  msg: string = null;
  isLoading = false;
  selectedOtpType = '';
  otpPlaceHolder = '';
  returnUrl = '';
  logoSrc = '';

  constructor(
    private activateRoute: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private storageService: StorageService,
    private favoriteProductsService: FavoriteProductsService,
    private store: Store<IAppState>,
    public translocoService: TranslocoService,

  ) {
    this.store.select(selectSiteProfile).subscribe(data => {
      this.logoSrc = data?.logo_url || `/assets/images/logoipsum-logo.svg`;
    });
  }

  ngOnInit() {
    this.activateRoute.queryParams.subscribe(qp => {
      this.returnUrl = qp?.returnUrl || '';
    });
    const twoFAAuthData = this.storageService.getTwoFactorAuthData();
    this.qrData =
      twoFAAuthData && twoFAAuthData.hasOwnProperty('qrcode')
        ? twoFAAuthData.qrcode
        : null;
    this.secretCode =
      twoFAAuthData && twoFAAuthData.hasOwnProperty('secret')
        ? twoFAAuthData.secret
        : null;
  }

  sendSMS(otp_type: string) {
    this.selectedOtpType = otp_type;
    this.errorMsg = null;
    this.isLoading = true;
    this.msg = null;
    this.authService.sendSMS(otp_type).subscribe(
      (res) => {
        if (otp_type === 'sms') {
          this.otpPlaceHolder = 'voer de SMS code in';
          this.otpPlaceHolder = this.translocoService.translate('TWO_FACTOR_AUTH.ENTER_THE_SMS_CODE');
        } else if (otp_type === 'email') {
          this.otpPlaceHolder = 'voer de Email code in';
          this.otpPlaceHolder =  this.translocoService.translate('TWO_FACTOR_AUTH.ENTER_THE_EMAIL_CODE');
        }
        this.issendSMS = true;
        this.msg = `${res?.data?.message?.replace('.', '')} per ${res?.data?.otp_type?.toUpperCase()}`;
        this.isLoading = false;
      },
      (err) => {
        this.isLoading = false;
        this.errorMsg = err?.error?.data?.error;
      }
    );
  }

  submitSecurityCode() {
    if (!this.securityCode) {
      return;
    }
    this.msg = null;
    this.isLoading = true;
    const payload = {
      otp: this.securityCode,
      otp_type: this.selectedOtpType || 'qr'
    };
    this.authService.twoFactorLogin(payload).subscribe(
      (res) => {
        const previousSelectedLanguage = JSON.parse(localStorage.getItem('language'));
        const defaultLanguage = res?.data?.user?.contact_person?.language ?? (previousSelectedLanguage ? previousSelectedLanguage : 'nl') ;
        this.translocoService.setActiveLang(defaultLanguage);
        localStorage.setItem('language', JSON.stringify(defaultLanguage));
        this.isLoading = false;
        this.store.dispatch(new PermissionLoadAction());
        this.store.dispatch(new SiteProfileLoadAction());
        timer(2000).subscribe(time => {
          const permissions = this.storageService.getPermission();
          const hasWishListPermission = permissions?.some(permission => {
            return ['wishlist.view_wishlist'].includes(permission.code);
          });

          if (hasWishListPermission) {
            this.getFavoriteProducts();
          }
        })
        if (this.returnUrl) {
          this.router.navigateByUrl(this.returnUrl);
        } else {
          this.router.navigateByUrl('/home');
        }
      },
      (err) => {
        this.isLoading = false;
        this.errorMsg = err.error.data.error;
      }
    );
  }

  getFavoriteProducts() {
    this.favoriteProductsService.getFavoriteProducts().subscribe(response => {
      const wishlistData = response?.data?.results?.find(item => item?.is_default) || null;
      this.favoriteProductsService.changeCurrentFavoriteProducts(wishlistData);
    })
  }

  handleKeyUp() {
    this.submitSecurityCode();
  }
}
