import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/storage.service';
import { CustomerService } from '../../customer/customer.service';
import { TradeInService } from '../trade-in.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-tradein-order-details',
  templateUrl: './tradein-order-details.component.html',
  styleUrls: ['./tradein-order-details.component.scss']
})
export class TradeInOrderDetailsComponent implements OnInit {

  public order_uuid = null;
  public tradeInOrder = null;
  public contactPerson = null;
  public contactAddress = null;
  public contact = null;
  public error = null;
  constructor(
    private storageService: StorageService,
    private customerService: CustomerService,
    public tradeInService: TradeInService,
    private router: Router,
    private activateRoute: ActivatedRoute) {
    this.activateRoute.params.subscribe(param => {
      this.order_uuid = param?.order_uuid;
      this.getOrder();
    })
  }

  getContact(personUuid) {
    this.customerService.getContact(personUuid).subscribe(
      (response) => {
        this.contact = response.data;
        this.contactPerson = this.contact?.contact_person.find(element => element?.uuid === this.tradeInOrder?.contact_person?.uuid);
        // this.contactAddress = this.contact?.contact_addresses.find(element => element?.uuid === this.tradeInOrder?.contact_address?.uuid) || this.tradeInOrder?.contact_address;
      }, (err) => {
        this.contact = null;
      });
  }

  back() {
    this.router.navigate([`trade-in/orders`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  getOrder() {
    this.error = null;
    this.tradeInService.getTradeInOrder(this.order_uuid).subscribe(res => {
      this.tradeInOrder = res?.data;
      this.contactAddress = res?.data?.shipping_address;
      this.getContact(res?.data?.contact_person?.uuid);
    }, error => {
      this.error = error?.error?.message;
    })
  }


  ngOnInit(): void {
  }


}


