import { Component, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { Observable, timer } from 'rxjs';

import { CustomerService } from './customer.service';
import { StorageService } from '../../shared/storage.service';
import { CartService } from '../cart/cart.service';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandleService } from '../../shared/error-handle.service';
import { AddCustomerService } from '../add-customer/add-customer.service';
import { UtilityService } from '../../shared/services/utility.service';
import { BasePermission } from '../../shared/base/base-permission';
import { IAppState } from '../../app.state';
import { Store } from '@ngrx/store';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { AddressService } from 'src/app/shared/services/address.service';
import { EAddressPrecision } from 'src/app/shared/enums/order-status.enums';
import { TranslocoService } from '@ngneat/transloco';
import { CustomersService } from '../customers/customers.service';
import { HttpParams } from '@angular/common/http';
import { EAddressTypes } from 'src/app/shared/enums/flow-type.enums';
export class ContactPerson {
  constructor(is_new = false) {
    if (is_new) {
      this.is_new = is_new;
      this.initials = '';
    }
  }
  initials = '';
  surname_prefix = '';
  last_name = '';
  birth_name_prefix = '';
  birth_name = '';
  gender = '';
  birthday = null;
  email = '';
  mobile_phone_number = '';
  bank_account_number = '';
  // id = new IdType();
  is_new = false;
  uuid = '';
}

export class IdType {
  bpr_proof = false;
  country = null;
  id_type = null;
  id_number = '';
  issue_date = '';
  expiration_date = '';
}




@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomerComponent),
      multi: true
    }
  ]
})
export class CustomerComponent extends BasePermission implements OnInit, OnDestroy {
  customer$: Observable<any>;
  customer: any;
  selectedCompany: any;
  customerUuid: any;
  isContactPersonSelected = false;
  isContactedPersonDataUpdated = false;
  shippingForm: UntypedFormGroup;
  editShippingForm: UntypedFormGroup;

  basicForm: UntypedFormGroup;
  contactPersonForm: UntypedFormGroup;

  formCtrl: UntypedFormControl;

  selectedContactPersonUUID: '';
  isLoading = false;
  errors = null;
  modalReference: any;
  shippingAddress = [];
  originalShippingAddress = [];
  voucherProfileShippingAddress = [];
  countryList = [];
  selectedShippingId = 0;
  contactAddressUuid = '';
  shippingUuid = null;
  shippingIndex = 0;
  shippingId = 0;
  isShippingAddressesLoading = false;
  isAddressFetching = false;
  addressCombinationMsg = null;
  contactPersonUUID = null;
  isContactPersonEmpty = false;
  shipBtnDisable = false;
  shipTimeOut = null;

  public contact_persons = [];
  private contact_addresses = [];
  public selectedShippingAddress: any = null;
  public selectedContactPerson: any = null;
  public trackAndTraceEmail: string = '';
  public trackAndTraceEmailError: [] = [];
  public c_gender = 'M';
  public newContactPerson = {
    initials: '',
    first_name: '',
    last_name: '',
    surname_prefix: '',
    birth_name_prefix: '',
    birth_name: '',
    gender: 'X',
    birthday: null,
    email: '',
    mobile_phone_number: '',
    is_default: false,
  };
  public activeStep = 'customer_select';
  public cart = null;
  public user = null;
  public shippingAddressCreate: any = {
    id: null,
    initials: '',
    first_name: '',
    surname_prefix: '',
    last_name: '',
    company_name: '',
    street: '',
    house_number: '',
    house_number_extension: '',
    zip_code: '',
    city: '',
    country: 'NL',
    isNew: true,
    is_default: false
  };

  public newContactPersonCreate = {
    status: 'new',
  };

  public createAddressLoader = false;
  public createContactPersonLoader = false;
  public deleteContactPersonLoader = false;
  public contactPersonUpdateError = null;
  public contactPersonCreateError = null;
  public zipCodePattern = null;
  public isNewQuote = false;
  public isImportedQuote = false;
  private siteProfile = null;
  public country = [];
  public NL_CODE = 'NL';
  public selectedCountry = this.NL_CODE;
  public selectedCountryObject = null;
  keyword = 'value';
  searchFocused = false;
  addressList: any[] = [];
  foreignAddressSearchError = null;
  testModel = '1';
  searchValue = '';
  addressCreateValue = '';
  addressFound = {
    zipcode: false,
    house_number: false,
    house_number_ext: false,
    city: false,
    street: false,
  };
  vat_number = '';
  vat_loading = false;

  emailConfigurations = [];
  selectedEmailConfig = '';
  hasSelectedConfig = false;
  emailConfigLoader = false;
  cc_contact_persons = [];

  searchCompanyList: [];
  selectedCompanyName: any = '';
  showDropDownItemList = false;
  companySearchKeyword = 'company_name';
  sameAsDeliveryAddress = true;
  saveAsNewAddress = false;
  isCustomerDataLoading = false;
  addressCreateError = {
    zip_code: false,
    house_number: false,
    city: false,
    street: false,
    company_name: false,
  };
  feContactPersonCreateError = {
    email: false,
    initials: false,
    first_name: false,
    last_name: false,
    surname_prefix: false,
    birth_name_prefix: false,
    birth_name: false,
  }

  @ViewChild('auto') auto;
  @ViewChild('customerSearch') customerSearch;


  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private router: Router,
    private toastrService: ToastrService,
    private cartService: CartService,
    private storageService: StorageService,
    private errorHandleService: ErrorHandleService,
    private addCustomerService: AddCustomerService,
    private utilService: UtilityService,
    public store: Store<IAppState>,
    private addressService: AddressService,
    private translocoService: TranslocoService,
    private customersService: CustomersService,


  ) {
    super(store);
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
    this.zipCodePattern = this.utilService.zipCodePattern();
    this.user = this.storageService.getCurrentUser();
    // const customer = this.storageService.getCustomer();
    // if (customer) {
    //   this.customerUuid = customer.uuid;
    // }


    // this.router.events.forEach((event) => {
    //   if (event instanceof NavigationStart) {
    //     if (event.navigationTrigger === 'popstate') {
    //       this.goTocustomersPage();
    //     }
    //   }
    // });

    this.route.queryParams.subscribe(params => {
      this.isNewQuote = params?.newQuote || false;
      this.isImportedQuote = params?.importedQuote || false;
      if (this.isNewQuote || this.isImportedQuote) {
        this.cart = null;
      } else {
        this.cart = this.storageService.getCart() || null;
      }
      if (!this.cart) {
        this.sameAsDeliveryAddress = false;
        this.saveAsNewAddress = false;
      }
    });
    const permissions = this.storageService.getPermission();
    this.hasViewEmailConFigPermission = this.hasViewEmailConFigPermission || permissions.some(permission => {
      return 'emails.view_emailsiteprofile' === permission.code;
    });

  }

  get eAddressPrecision() {
    return EAddressPrecision;
  }

  ngOnInit() {
    this.initializeBasicForm();
    this.initContactPersonFormArray();
    if (this.hasViewEmailConFigPermission) {
      this.getEmailConfigurations();
    }
    this.getCountry();
    this.getCustomerDetails();
    this.selectedShippingId = this.storageService.getShippingAddressID();
    this.getTrackAndTraceEmail();

  }

  getEmailConfigurations() {
    this.customerService.getEmailConfiguration().subscribe(res => {
      this.emailConfigurations = res?.data?.results;
    })
  }

  setGender(contactPerson, value) {
    contactPerson.gender = this.c_gender;
  }

  getCountry() {
    if (this.country?.length) {
      return;
    }
    this.addressService.getCountry().subscribe(res => {
      this.country = res?.data?.results;
    });
  }

  fieldsValueAssignment(customer: any) {
    if (!this.selectedCompanyName?.company_name?.length) {
      this.selectedCompanyName = customer.company_name || this.siteProfile.title;
      this.basicForm.controls.company_name.setValue(customer.company_name || this.siteProfile.title);
    }
    this.basicForm.controls.coc_number.setValue(customer.coc_number);
    this.basicForm.controls.krn_number.setValue(customer.external_id1);
    this.basicForm.controls.vat_number.setValue(customer.vat_number);
    this.basicForm.controls.bank_account_no.setValue(
      customer.bank_account_no
    );

    const contactAddress = customer?.contact_addresses.find(element => element?.address_type === 'contact');

    if (contactAddress) {
      this.contactAddressUuid = contactAddress.uuid;
    }

    if (customer?.contact_addresses?.length) {
      this.basicForm.controls['address'].setValue({
        zipcode: contactAddress ? contactAddress?.zip_code : customer?.contact_addresses[0]?.zip_code,
        house_number: contactAddress ? contactAddress?.house_number : customer?.contact_addresses[0]?.house_number,
        house_number_ext: contactAddress ? contactAddress?.house_number_extension : customer?.contact_addresses[0]?.house_number_extension,
        city: contactAddress ? contactAddress?.city : customer?.contact_addresses[0]?.city,
        street: contactAddress ? contactAddress?.street : customer?.contact_addresses[0]?.street,
        country: contactAddress ? contactAddress?.country : customer?.contact_addresses[0]?.country,
      });
    }

    this.checkSearchedAddressField(this.selectedShippingAddress);

  }

  private setCurrentFormControl() {
    const formCtrls = this.contactPersonForm.controls['contactPersonForms'][
      'controls'
    ];
    this.formCtrl = formCtrls.find(
      (el: any) => el.value.uuid === this.selectedContactPersonUUID
    );
  }

  async onSubmitCustomer() {
    this.setCurrentFormControl();
    let cart = null;

    if (!this.isNewQuote && !this.isImportedQuote) {
      cart = this.storageService.getCart();
      this.cartService.currentCart$.subscribe((data) => {
        cart = data;
      });
    }

    const quoteUuid = this.storageService.getQuoteUuid();
    this.isLoading = true;
    if (this.selectedContactPersonUUID) {
      if (!(await this.updateContactPersonData())) return;
    }
    if (this?.selectedContactPerson?.status === 'new') {

      if (!(await this.createContactAndSaveToCart())) return;

    }


    if ((!this.sameAsDeliveryAddress) && (!this.saveAsNewAddress) && (!this.selectedShippingAddress?.isNew) && (this.selectedShippingId) && (this.hasContactAddressUpdatePermission) && (!cart?.block_delivery_address)) {
      if (!(await this.updateOnlyShippingAddress())) return;
    }
    // await this.updateContactPersonData();
    // if (!this.isContactedPersonDataUpdated) {
    //   return;
    // }

    if (this.sameAsDeliveryAddress && cart) {
      // backend will take care of this
      if (!(await this.updateCartWithEmptyAddressData())) return;

    } else if (this.saveAsNewAddress && cart) {
      if (!(await this.createNewShippingAddressAndSaveToCart())) return;

    } else if (cart && cart.allow_delivery_address_from_contact && (this.selectedShippingAddress?.isNew)) {
      if (!(await this.createNewShippingAddressAndSaveToCart())) return;

    } else if ((!this.sameAsDeliveryAddress) && (!this.saveAsNewAddress) && cart && this.selectedShippingAddress?.isNew) {
      // this is for one time address
      if (!(await this.updateCartWithOneTimeAddressData())) return;

    } else if (this.selectedShippingId && cart && (!this.sameAsDeliveryAddress) && (!this.saveAsNewAddress)) {
      if (!(await this.updateCartWithAddressData())) return;
      // await this.updateCartWithShippingAddress();
    }

    if (!this.cart && (this.selectedShippingAddress?.isNew)) {
      if (!(await this.createNewShippingAddress())) return;
    }



    if (this.isContactPersonSelected && this.trackAndTracePermission && cart) {
      const updateCartWithTrackAndTraceResponse = await this.updateCartWithTrackAndTraceEmail();
      //  if failed to update cart with track and trace email then stop the process
      if (!updateCartWithTrackAndTraceResponse) {
        return;
      }
    }

    // if (this.selectedShippingId && cart) {
    //   await this.updateCartWithShippingAddress();
    // }


    if (this.user.is_external) {
      this.storageService.setCustomer(this.customer);
    }

    if (quoteUuid) {
      this.updateQuote(quoteUuid);
    } else {
      if (cart) {
        if (!cart?.contact_person_uuid) {
          this.updateCartWithContactParson(cart, true);
        } else {
          this.navigateNext(cart);
        }
      } else {
        if (this.isNewQuote) {
          this.customerService.submitEmptyQuote({
            contact: this.customerUuid,
            contact_person: this.storageService.getContactPersonUUID(),
            shipping_address_id: this.selectedShippingId,
          }).subscribe((res) => {
            this.router.navigate(['/quote-details', `${res?.data?.uuid}`]);
          }, (error) => {
            this.toastrService.error(this.translocoService.translate('CUSTOMER.ERROR_ON_EMPTY_QUOTE'), 'Error');
          });
        } else {
          this.router.navigateByUrl('/home');
        }
      }
    }
  }

  updateCartWithShippingAddress() {
    this.isLoading = true;
    const cart = this.storageService.getCart();
    if (cart?.shipping_address_id && cart?.shipping_address_id === this.selectedShippingId) {
      this.isLoading = false;
      return;
    }
    if (cart) {
      const updatePayload = {
        shipping_address_id: this.selectedShippingId, // may be integer ID is temporary
        shipping_address_company_name: "",
        shipping_address_initials: "",
        shipping_address_first_name: "",
        shipping_address_last_name: "",
        shipping_address_surname_prefix: "",
        shipping_address_street: "",
        shipping_address_house_number: null,
        shipping_address_house_number_extension: "",
        shipping_address_zip_code: "",
        shipping_address_city: "",
        shipping_address_country: "",
      };
      this.customerService
        .updateCart(cart.uuid, updatePayload)
        .subscribe(
          (res) => {
            this.storageService.setCart(res.data);
            this.cartService.changeCurrentCart(res.data);
            this.isLoading = false;
            this.cart = res?.data;
            this.showToaster(this.translocoService.translate('CUSTOMER.SHIPPING_ADDRESS_LINKED_TO_CART'));
          },
          (err) => {
            this.isLoading = false;
            this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
          }
        );
    } else {
      this.isLoading = false;
    }
  };
  async updateCartWithTrackAndTraceEmail() {
    this.trackAndTraceEmailError = [];
    this.isLoading = true;
    const cart = this.storageService.getCart();
    if (cart) {
      const updatePayload = {
        track_and_trace_email: this.trackAndTraceEmail
      };
      return this.customerService
        .updateCart(cart.uuid, updatePayload).toPromise().then((res) => {
          this.storageService.setCart(res.data);
          this.isLoading = false;
          this.cart = res?.data ?? cart;
          if (this.trackAndTraceEmail) {
            this.showToaster(`${this.translocoService.translate('CUSTOMER.TRACK_AND_TRACE_EMAIL_ADDED')}`);
          }
          return true;
        }).catch((err) => {
          this.trackAndTraceEmailError = err?.error?.error?.track_and_trace_email ?? [];
          this.isLoading = false;
          this.showErrorToaster(`${this.translocoService.translate('CART.SOMETHING_WRONG')}`);
          return false;
        });
    } else {
      this.isLoading = false;
      return true;
    }
  }

  getTrackAndTraceEmail() {
    const cart = this.storageService.getCart();
    if (cart?.track_and_trace_email) {
      this.trackAndTraceEmail = cart?.track_and_trace_email;
    }
  }

  async updateContactPersonData() {
    const selectedContactPerson = this.contact_persons.find(
      (el: any) => el.uuid === this.selectedContactPersonUUID
    );
    const formGroupData = {
      ...(this.formCtrl?.value || this.selectedContactPerson),
      email: this.selectedContactPerson?.email,
      mobile_phone_number: this.selectedContactPerson?.mobile_phone_number
    };

    // if (
    //   selectedContactPerson.birth_name === formGroupData.birth_name &&
    //   selectedContactPerson.birth_name_prefix === formGroupData.birth_name_prefix &&
    //   selectedContactPerson.birthday === formGroupData.birthday &&
    //   selectedContactPerson.email === formGroupData.email &&
    //   selectedContactPerson.mobile_phone_number === formGroupData.mobile_phone_number &&
    //   selectedContactPerson.surname_prefix === formGroupData.surname_prefix
    // ) {
    //   this.isContactedPersonDataUpdated = true;
    //   console.log('this.isContactedPersonDataUpdated: ', this.isContactedPersonDataUpdated);
    //   return;
    // }

    const updateData = {
      contact_person: {
        ...selectedContactPerson,
        gender: 'X', // formGroupData.gender || selectedContactPerson?.gender || 'M',
        birth_name: formGroupData.birth_name,
        birth_name_prefix: formGroupData.birth_name_prefix,
        birthday: formGroupData.birthday,
        email: formGroupData.email,
        mobile_phone_number: formGroupData.mobile_phone_number,
        surname_prefix: formGroupData.surname_prefix,
        // tslint:disable-next-line:max-line-length
        first_name: selectedContactPerson?.first_name || selectedContactPerson?.initials || selectedContactPerson?.surname_prefix || 'anonymous'
      }
    };

    if (!updateData.contact_person.birthday) {
      delete updateData.contact_person.birthday;
    }
    if (!updateData.contact_person.bank_account_number) {
      delete updateData.contact_person.bank_account_number;
    }
    if (!updateData.contact_person.email) {
      delete updateData.contact_person.email;
    }
    if (!updateData.contact_person.mobile_phone_number) {
      delete updateData.contact_person.mobile_phone_number;
    }

    return await this.customerService
      .updateContactPersonV2(this.customerUuid, this.selectedContactPersonUUID, updateData.contact_person)
      .toPromise()
      .then(res => {
        this.isLoading = false;
        this.showToaster(this.translocoService.translate('CUSTOMER.CONTACT_UPDATED'));
        this.isContactedPersonDataUpdated = true;
        return true;
      })
      .catch(error => {
        this.isLoading = false;
        this.showErrorToaster(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_UPDATE_FAILED'));
        this.isContactedPersonDataUpdated = false;
        this.contactPersonUpdateError = error?.error?.error?.contact_person;
        return false;
      });
  }

  updateQuote(quoteUuid) {
    this.isLoading = true;
    const payload = {
      contact: this.customerUuid,
      contact_person: this.storageService.getContactPersonUUID(),
    };

    if (this.isImportedQuote) {
      payload['shipping_address_id'] = this.selectedShippingId;
    }

    this.customerService.updateQuote(quoteUuid, payload).subscribe(
      (res) => {
        this.isLoading = false;
        this.storageService.removeQuoteUuid();
        // this.router.navigateByUrl('/quotes');
        this.router.navigate(['/quote-details', `${quoteUuid}`]);
      },
      (err) => {
        this.isLoading = false;
        console.log('err: ', err);
      }
    );
  }

  navigateNext(cart) {
    if (cart?.lines?.length) {
      const hasUnsetAccountType = cart?.lines?.some(line => {
        return line?.account_type_required && !line?.extra?.contact_account_type_id;
      });
      if (hasUnsetAccountType) {
        this.router.navigate(['/cart', `${cart.uuid}`]);
      } else {
        this.router.navigate(['/order-overview', `${cart.uuid}`]);
      }
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  initializeBasicForm() {
    this.basicForm = this.fb.group({
      company_name: new UntypedFormControl({ value: '', disabled: true }),
      coc_number: new UntypedFormControl({ value: '', disabled: true }),
      bank_account_no: new UntypedFormControl({ value: '', disabled: true }),
      krn_number: new UntypedFormControl({ value: '', disabled: true }),
      vat_number: new UntypedFormControl({ value: '', disabled: true }),
      address: this.fb.group({
        zipcode: new UntypedFormControl({ value: '', disabled: true }),
        house_number: new UntypedFormControl({ value: '', disabled: true }),
        house_number_ext: new UntypedFormControl({ value: '', disabled: true }),
        city: new UntypedFormControl({ value: '', disabled: true }),
        street: new UntypedFormControl({ value: '', disabled: true }),
        country: new UntypedFormControl({ value: '', disabled: true }),
      }),
    });
  }

  createAddress(createAddressModal: any) {
    this.errors = {}; this.addressCombinationMsg = null;
    this.shippingForm = this.fb.group({
      first_name: [''],
      surname_prefix: [''],
      last_name: [''],
      company_name: [''],
      street: [''],
      house_number: [''],
      house_number_extension: [''],
      zip_code: [''],
      city: [''],
      country: ['NL']
    });
    this.modalReference = this.modalService.open(createAddressModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'lg',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onSelectShippingAddress(selectedShippingId: number) {
    this.selectedShippingId = selectedShippingId;
    if (this.isNewQuote || this.isImportedQuote) {
    } else {
      this.storageService.setShippingAddressID(selectedShippingId);
    }

    if ((this.cart?.allow_delivery_address_from_contact && this.cart?.block_delivery_address) || (
      this.cart?.allow_delivery_address_from_contact && !this.cart?.block_delivery_address
    )) {
      this.updateCartWithShippingAddress();
      // this.createShippingAddressForOwnUser();
    } else if (!this.cart?.allow_delivery_address_from_contact && !this.cart?.block_delivery_address) {
      this.updateCartWithShippingAddress();
    }
  }

  onChangeShippingAddress(event) {
    this.selectedShippingAddress = event;
    this.errors = {};
    this.addressList = [];
    this.selectedCountry = this.selectedShippingAddress?.id ? this.selectedShippingAddress?.country : this.NL_CODE;
    this.selectedCountryObject = this.country?.find(item => item?.country_code === this.selectedCountry) || null
    this.selectedShippingAddress.country = '';
    this.addressCreateValue = '';
    this.resetAddressFound();
    if (this.selectedShippingAddress?.id) {
      this.shippingAddress = JSON.parse(JSON.stringify(this.originalShippingAddress));
      this.selectedShippingAddress = this.shippingAddress.find(item => item.id === this.selectedShippingAddress.id);

      timer(300).subscribe(time => {
        this.selectedShippingAddress.country = this.selectedCountry;

        this.onSelectShippingAddress(this.selectedShippingAddress?.id);
        this.checkSearchedAddressField(this.selectedShippingAddress)
      })
    } else if (this.selectedShippingAddress && !this.selectedShippingAddress?.id) {
      this.shippingAddress = JSON.parse(JSON.stringify(this.originalShippingAddress));
      this.selectedShippingAddress = {
        id: null,
        initials: '',
        first_name: '',
        surname_prefix: '',
        last_name: '',
        company_name: '',
        street: '',
        house_number: '',
        house_number_extension: '',
        zip_code: '',
        city: '',
        country: 'NL',
        isNew: true,
        is_default: false
      };
      this.shippingAddressCreate = this.selectedShippingAddress;
      // this.selectedShippingAddress
    } else {
      this.selectedShippingId = 0;
      this.storageService.setShippingAddressID(this.selectedShippingId);
    }
  }

  onEditShippingAddress(editAddressModal: any, shippingUuid: string, shippingIndex: number, shippingId: number) {
    this.errors = {}; this.addressCombinationMsg = null;
    this.isShippingAddressesLoading = true;
    this.addCustomerService.getContactAddress(this.customerUuid, shippingUuid)
      .subscribe(
        (response) => {
          this.shippingUuid = shippingUuid;
          this.shippingIndex = shippingIndex;
          this.shippingId = shippingId;
          if (response?.data) {
            const value = response?.data;
            this.editShippingForm = this.fb.group({
              first_name: value.first_name,
              surname_prefix: value.surname_prefix,
              last_name: value.last_name,
              company_name: value.company_name,
              street: value.street,
              house_number: value.house_number,
              house_number_extension: value.house_number_extension,
              zip_code: value.zip_code,
              city: value.city,
              country: value.country ? value.country : 'NL'
            });
            this.isShippingAddressesLoading = false;
            this.modalReference = this.modalService.open(editAddressModal, {
              ariaLabelledBy: 'modal-basic-title',
              centered: true,
              size: 'lg',
              scrollable: true,
              backdrop: 'static',
            });
            this.modalReference.result.then((result) => {
              // this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        },
        (error) => {
          this.isShippingAddressesLoading = false;
        }
      );
  }

  initContactPersonFormArray() {
    this.contactPersonForm = this.fb.group({
      contactPersonForms: new UntypedFormArray([]),
    });
  }

  get contactPersonForms() {
    return this.contactPersonForm.get('contactPersonForms') as UntypedFormArray;
  }

  goTocustomersPage() {
    if (this.user?.contact_group?.is_external) {
      return;
    }
    this.router.navigate(['/customers'], {
      queryParams: {
        company_name: this.customer?.company_name,
        coc_number: this.customer?.coc_number,
        customer_uuid: this.customer?.uuid,
      },
      queryParamsHandling: 'merge'
    });
  }
  backToPrevious() {
    const cart = this.storageService.getCart() || null;
    if (cart) {
      this.router.navigate([`/cart/${cart?.uuid}`]);
    } else {
      this.router.navigate([`/home`]);
    }
  }

  createContactPersonForms(contactPerson: ContactPerson): UntypedFormGroup {
    const formGroup = this.fb.group({
      gender: [
        {
          value: 'X', // contactPerson.gender,
          disabled: contactPerson.gender ? true : false,
        },
        Validators.required,
      ],
      initials: [
        {
          value: contactPerson.initials,
          disabled: contactPerson.initials ? true : false,
        },
        Validators.required,
      ],
      surname_prefix: [
        {
          value: contactPerson.surname_prefix,
          disabled: contactPerson.surname_prefix ? true : false,
        },
      ],
      last_name: [
        {
          value: contactPerson.last_name,
          disabled: contactPerson.last_name ? true : false,
        },
        Validators.required,
      ],
      birthday: [
        {
          value: contactPerson.birthday,
          disabled: contactPerson.birthday ? true : false,
        },
        Validators.required,
      ],
      birth_name_prefix: [
        {
          value: contactPerson.birth_name_prefix,
          disabled:
            this.selectedCompany &&
              (this.selectedCompany.export_id ||
                this.selectedCompany.external_id1)
              ? true
              : false,
        },
      ],
      birth_name: [
        {
          value: contactPerson.birth_name,
          disabled:
            this.selectedCompany &&
              (this.selectedCompany.export_id ||
                this.selectedCompany.external_id1)
              ? true
              : false,
        },
      ],
      mobile_phone_number: [
        contactPerson.mobile_phone_number,
        [Validators.required, Validators.minLength(10)]
      ],
      email: [contactPerson.email, [Validators.required, Validators.email]],
      uuid: [contactPerson.uuid],
    });
    return formGroup;
  }

  getCustomerDetails() {

    this.route.params.subscribe(
      (params) => {
        if (!this.customerUuid) {
          if (params?.customer_uuid) {
            this.isCustomerDataLoading = true;
            this.customerUuid = params?.customer_uuid;
          }
        }

        if (!this.customerUuid) {
          this.isCustomerDataLoading = false;
          return;
        }
        this.customerService.getCustomer(this.customerUuid).subscribe(
          (res) => {
            this.processCustomerData(res);
          },
          (err) => {
            this.isCustomerDataLoading = false;
            console.log('err: ', err);
          }
        );
      },
      (err) => {
        this.isCustomerDataLoading = false;
        console.log('err: ', err);
      }
    );
  }


  getSelectedEmailConfig() {
    this.selectedCPs = [];
    let configuration = this.customer?.email_configurations?.find(configuration => {
      return configuration?.email_configuration?.code === this.selectedEmailConfig
    });

    if (configuration) {
      this.hasSelectedConfig = true;
      this.getSelectedCPForConfig(configuration);
    }
  }

  getSelectedCPForConfig(configuration) {
    this.selectedCPs = configuration?.contact_persons?.map(person => {
      return person?.uuid
    });
  }


  reStoreContact(res) {
    this.selectedEmailConfig = ''
    this.customer = res.data;
    this.vat_number = this.customer?.vat_number;
    this.storageService.setCustomer(this.customer);
    if (!this.selectedEmailConfig) {
      this.selectedEmailConfig = this.customer?.email_configurations?.find(item => item)?.email_configuration?.code || '';
    }
    this.getSelectedEmailConfig();
  }

  processCustomerData(res) {
    if (this.cart?.allow_delivery_address_from_contact) {
      this.sameAsDeliveryAddress = false;
    }
    if (!this.cart) {
      this.sameAsDeliveryAddress = false;
      this.saveAsNewAddress = false;
    } else {
      this.saveAsNewAddress = false;
    }
    this.contact_persons = [];
    this.errors = {}; this.addressCombinationMsg = null;
    this.selectedContactPerson = null;
    this.selectedShippingAddress = null;
    this.selectedContactPersonUUID = '';
    this.contactPersonUUID = '';
    this.storageService.removeContactPersonUUID();
    this.selectedShippingId = null;
    this.storageService.removeShippingAddressID();

    this.cc_contact_persons = res?.data?.contact_persons;
    this.reStoreContact(res);
    if (this.cart?.allow_delivery_address_from_contact) {
      this.getVoucherProfileDeliveryAddress();
    } else {
      this.shippingAddress = [...this.customer?.contact_addresses.filter(element => element?.address_type === 'shipping')];
      this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
      if (this.cart?.shipping_address_id) {
        this.selectedShippingAddress = this.shippingAddress?.find(cad => {
          return cad.id === this.cart?.shipping_address_id;
        }) || null;

        if (this.selectedShippingAddress) {
          this.selectedShippingId = this.selectedShippingAddress?.id;
          this.isContactedPersonDataUpdated = true;
        }
      }
      if ((this.cart?.shipping_address_id) && this.selectedShippingAddress && (this.selectedShippingAddress?.id)) {
        this.sameAsDeliveryAddress = false;
      }
    }
    this.storageService.setContactUuid(this.customerUuid);
    this.fieldsValueAssignment(res.data);
    if (this.user?.contact_group?.is_external) {

      this.contact_persons = res.data.contact_persons;
      // if (this.contactPersonAddPermission) {
      // }else {
      //   this.contact_persons = res.data.contact_persons;
      // }

      if (!this.contact_persons?.length) {
        this.contact_persons = res.data.contact_persons;
      }
      this.selectedContactPerson = this.contact_persons?.find(cp => {
        return cp.uuid === this.user?.contact_person?.uuid;
      }) || null;

      if (this.selectedContactPerson) {
        this.isContactPersonSelected = true;
        this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
        this.isContactedPersonDataUpdated = true;
      }
    } else {
      this.contact_persons = res.data.contact_persons;
    }
    this.isContactPersonEmpty = this.contact_persons.length > 0 ? false : true;
    for (const contactPerson of this.contact_persons) {
      this.contactPersonForms.push(
        this.createContactPersonForms(contactPerson)
      );
    }
    if (this?.cart?.contact_person_uuid) {
      this.selectedContactPerson = this.contact_persons.find(cp => {
        return cp.uuid === this.cart.contact_person_uuid;
      }) || null;
      if (this.selectedContactPerson) {
        this.isContactPersonSelected = true;
        this.selectedContactPersonUUID = this.cart.contact_person_uuid;
        this.isContactedPersonDataUpdated = true;
      }
    }
    this.contact_addresses = res.data.contact_addresses.find(item => item.address_type === 'contact');
    this.retrieveSelectedItem();

    if ((!this.cart?.shipping_address_id) && (
      this.cart?.shipping_address_company_name ||
      this.cart?.shipping_address_initials ||
      this.cart?.shipping_address_first_name ||
      this.cart?.shipping_address_last_name ||
      this.cart?.shipping_address_surname_prefix ||
      this.cart?.shipping_address_street ||
      this.cart?.shipping_address_house_number ||
      this.cart?.shipping_address_house_number_extension ||
      this.cart?.shipping_address_zip_code ||
      this.cart?.shipping_address_city ||
      this.cart?.shipping_address_country ||
      this.cart?.shipping_address_email
    )) {
      this.saveAsNewAddress = false;
      this.sameAsDeliveryAddress = false;
      this.selectedShippingAddress = {
        id: null,
        first_name: this.cart?.shipping_address_first_name,
        surname_prefix: this.cart?.shipping_address_surname_prefix,
        last_name: this.cart?.shipping_address_last_name,
        company_name: this.cart?.shipping_address_company_name,
        street: this.cart?.shipping_address_street,
        house_number: this.cart?.shipping_address_house_number + '',
        house_number_extension: this.cart?.shipping_address_house_number_extension + '',
        zip_code: this.cart?.shipping_address_zip_code,
        city: this.cart?.shipping_address_city,
        country: this.cart?.shipping_address_country,
        email: this.cart?.shipping_address_email,
        isNew: true
      }
      this.shippingAddressCreate = this.selectedShippingAddress;
      this.checkSearchedAddressField(this.shippingAddressCreate);
      if (this.cart?.shipping_address_country) {
        this.selectedCountry = this.cart?.shipping_address_country ?? 'NL';
        this.selectedCountryObject = this.country?.find(item => item?.country_code === this.selectedCountry) || null
      }

    }
    let shippingAddressOrContactPersonUpdatePayload = {};
    if (!this.cart?.allow_delivery_address_from_contact) {
      if (this.cart?.shipping_address_id && ((!this.selectedShippingAddress) || (!this.selectedShippingId))) {
        shippingAddressOrContactPersonUpdatePayload = {
          ...shippingAddressOrContactPersonUpdatePayload,
          shipping_address_id: null,
          shipping_address_company_name: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_street: "",
          shipping_address_house_number: null,
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",

        }
      }
    }

    if (this?.cart?.contact_person_uuid && ((!this.selectedContactPerson) || (!this.selectedContactPersonUUID))) {
      shippingAddressOrContactPersonUpdatePayload = {
        ...shippingAddressOrContactPersonUpdatePayload,
        contact_person_uuid: null
      }
    }
    if (Object.keys(shippingAddressOrContactPersonUpdatePayload).length) {
      this.removeShippingAddressOrContactPersonFromCart(shippingAddressOrContactPersonUpdatePayload);
    }
    if (((this.isNewQuote || this.isImportedQuote))) {
      this.sameAsDeliveryAddress = false;
      this.saveAsNewAddress = false;
    }
    this.isCustomerDataLoading = false;
  }


  retrieveSelectedItem() {
    this.route.queryParams.subscribe(qp => {
      if (qp?.contact_person) {
        this.selectedContactPerson = this.contact_persons?.find(cp => {
          return cp.uuid === qp?.contact_person;
        }) || null;

        if (this.selectedContactPerson) {
          this.isContactPersonSelected = true;
          this.selectedContactPersonUUID = qp?.contact_person;
          this.isContactedPersonDataUpdated = true;
        }
      }

      if (qp?.shipping_address) {
        this.selectedShippingAddress = this.shippingAddress?.find(cp => {
          return cp.uuid === qp?.shipping_address;
        }) || null;

        if (this.selectedShippingAddress) {
          this.selectedShippingId = this.selectedShippingAddress?.id;
          this.isContactedPersonDataUpdated = true;
        }
      }
    });
  }

  onSelectContact(status) {
    if (status) {
      this.contactPersonUUID = this.selectedContactPersonUUID;
    }
  }



  onChangeContactPerson() {
    this.contactPersonCreateError = null;
    if (this.selectedContactPerson && !this.selectedContactPerson?.status) {
      this.onContactPersonChecked(this.selectedContactPerson, true);
    } else if (this.selectedContactPerson && !this.selectedContactPerson?.status) {
      this.selectedContactPerson = { ...this.newContactPerson };
    } else {
      this.contactPersonUUID = this.selectedContactPersonUUID = '';
      this.removeContactPerson();
    }
  }


  removeContactPerson() {
    this.storageService.removeContactPersonUUID();
    const cart = this.storageService.getCart();
    if (cart) {
      const updatePayload = {
        contact_person_uuid: null,
      };
      this.customerService
        .updateCart(cart.uuid, updatePayload)
        .subscribe(
          (res) => {
            this.cart = res.data;
            this.storageService.setCart(res.data);
            this.cartService.changeCurrentCart(res.data);
            this.isContactPersonSelected = false;
            this.isLoading = false;
            this.showInfoToaster(this.translocoService.translate('CUSTOMER.CONTACT_REMOVED_FROM_CART'));
          },
          (err) => {
            this.isLoading = false;
            this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
          }
        );
    } else {
      this.isContactPersonSelected = false;
      this.isLoading = false;
    }
  }

  onContactPersonChecked(contactPersonFormGroup: any, status = false) {
    this.isLoading = true;
    this.selectedContactPersonUUID = contactPersonFormGroup?.value?.uuid || contactPersonFormGroup?.uuid;
    if (status) {
      this.onSelectContact(status);
    }

    this.setCurrentFormControl();
    this.storageService.setContactPersonUUID(this.selectedContactPersonUUID);
    const cart = this.storageService.getCart();
    if (cart) {
      this.updateCartWithContactParson(cart);
    } else {
      this.isContactPersonSelected = true;
      this.isLoading = false;
    }
  }


  updateCartWithContactParson(cart, forward = false) {
    this.isLoading = true;
    const updatePayload = {
      contact_person_uuid: this.selectedContactPersonUUID || this.selectedContactPerson?.uuid,
    };

    if (!updatePayload.contact_person_uuid) {
      this.isLoading = false;
      return;
    }
    this.customerService
      .updateCart(cart.uuid, updatePayload)
      .subscribe(
        (res) => {
          this.cart = res.data;
          this.storageService.setCart(res.data);
          this.cartService.changeCurrentCart(res.data);
          this.isContactPersonSelected = true;
          this.isLoading = false;
          this.showToaster(this.translocoService.translate('CUSTOMER.CONTACT_LINKED_TO_CART'));
          if (forward) {
            this.navigateNext(cart);
          }
        },
        (err) => {
          this.isLoading = false;
          this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
        }
      );
  }

  onUpdateShippingAddress() {
    this.isShippingAddressesLoading = true;
    this.shipBtnDisable = true;
    const formValue = this.editShippingForm.controls;
    const data = Object.assign({});
    for (const key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        data[key] = formValue[key].value;
      }
    }
    data.country = data.country ? formValue.country.value : 'NL';
    data.address_type = 'shipping';

    this.addCustomerService.updateNewAddress(data, this.customerUuid, this.shippingUuid)
      .subscribe(
        (response) => {
          if (response?.data) {
            const value = response?.data;
            this.shippingAddress[this.shippingIndex] = {
              first_name: value.first_name,
              surname_prefix: value.surname_prefix,
              last_name: value.last_name,
              company_name: value.company_name,
              street: value.street,
              house_number: value.house_number,
              house_number_extension: value.house_number_extension,
              zip_code: value.zip_code,
              city: value.city,
              country: value.country,
              uuid: this.shippingUuid,
              id: this.shippingId
            };
          }
          this.modalReference.close();
          this.shipBtnDisable = false;
          this.isShippingAddressesLoading = false;
        },
        error => {
          this.shipBtnDisable = false;
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.isShippingAddressesLoading = false;
        }
      );
  }

  async onAddShippingAddress() {
    this.shipBtnDisable = true;
    const formValue = this.shippingForm.controls;
    const value = Object.assign({});

    for (const key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        value[key] = formValue[key].value;
      }
    }

    value.country = value.country ? formValue.country.value : 'NL';
    value.address_type = 'shipping';

    this.addCustomerService.submitNewAddress(value, this.customerUuid)
      .subscribe(
        (response) => {
          if (response?.data) {
            this.shippingAddress.push(response?.data);
          }
          this.shipBtnDisable = false;
          this.modalReference.close();
        },
        error => {
          this.shipBtnDisable = false;
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
        }
      );
  }


  createShippingAddress() {
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.selectedShippingAddress.address_type = 'shipping';
    this.errors = {};
    this.addCustomerService.submitNewAddress(this.selectedShippingAddress, this.customerUuid)
      .subscribe((response) => {
        if (response?.data) {
          this.shippingAddress.unshift(response?.data);
          timer(500).subscribe(time => {
            this.selectedShippingAddress = this.shippingAddress?.find(item => item?.id === response?.data?.id) || null;
            this.selectedShippingId = this.selectedShippingAddress?.id;
            this.storageService.setShippingAddressID(this.selectedShippingId);
            this.updateCartWithShippingAddress();
          });
        }
        this.createAddressLoader = false;
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.createAddressLoader = false;
        }
      );
  }

  updateShippingAddress() {
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.errors = {};
    this.selectedShippingAddress.address_type = 'shipping';
    this.addCustomerService.updateNewAddress(this.selectedShippingAddress, this.customerUuid, this.selectedShippingAddress?.uuid)
      .subscribe((response) => {
        if (response?.data) {
          this.shippingAddress = this.shippingAddress.map(u => {
            return u.id === response?.data?.id ? { ...this.selectedShippingAddress, ...response?.data } : u;
          });
          this.showToaster(this.translocoService.translate('CUSTOMER.SHIPPING_ADDRESS_UPDATED_SUCCESSFULLY'));

          timer(500).subscribe(time => {
            this.selectedShippingAddress = this.shippingAddress?.find(item => item?.id === this.selectedShippingAddress?.id) || null;
            this.selectedShippingId = this.selectedShippingAddress?.id;
            this.storageService.setShippingAddressID(this.selectedShippingId);
            this.updateCartWithShippingAddress();
          });
        }
        this.createAddressLoader = false;
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.createAddressLoader = false;
        }
      );
  }

  createContactPerson() {
    this.createContactPersonLoader = true;
    this.contactPersonCreateError = null;
    this.addCustomerService.createContactPerson(this.customerUuid, this.newContactPerson)
      .subscribe((response) => {
        if (response?.data) {
          this.contact_persons.unshift(response?.data);
          timer(500).subscribe(time => {
            this.selectedContactPerson = this.contact_persons?.find(item => item?.uuid === response?.data?.uuid) || null;
            this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
            this.isContactPersonSelected = true;
            this.storageService.setContactPersonUUID(this.selectedContactPersonUUID);
            this.setCurrentFormControl();
            const cart = this.storageService.getCart();
            if (cart) {
              if (!cart?.contact_person_uuid) {
                this.updateCartWithContactParson(cart, true);
              } else {
                this.navigateNext(cart);
              }
            }
          });
        }
        this.createContactPersonLoader = false;
      },
        error => {
          this.createContactPersonLoader = false;
          this.showErrorToaster(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_CREATE_FAILED'));
          this.contactPersonCreateError = error?.error?.error;
        }
      );
  }

  deleteContactPerson(contact_person_uuid: string) {
    this.deleteContactPersonLoader = true;
    this.addCustomerService.deleteContactPerson(this.customerUuid, contact_person_uuid)
      .subscribe((response) => {
        if (response?.data) {
          this.contact_persons = this.contact_persons.filter(item => {
            return item?.uuid !== contact_person_uuid;
          });
          timer(500).subscribe(time => {
            this.selectedContactPerson = null;
            this.selectedContactPersonUUID = '';
            this.isContactPersonSelected = false;
            this.storageService.removeContactPersonUUID();
          });
        }
        this.deleteContactPersonLoader = false;
      },
        error => {
          this.deleteContactPersonLoader = false;
          this.showErrorToaster(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_DELETE_FAILED'));
        }
      );
  }

  createShippingAddressForOwnUser() {
    this.selectedShippingAddress.address_type = 'shipping';
    this.shippingAddress = [...this.voucherProfileShippingAddress];
    this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
    this.addCustomerService.submitNewAddress(this.selectedShippingAddress, this.customer?.uuid)
      .subscribe((response) => {
        this.shippingAddress.unshift(response?.data);
        this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
        this.selectedShippingAddress = response?.data;
        this.selectedShippingId = response?.data?.id;
        this.updateCartWithShippingAddress();
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
        }
      );
  }

  handleError(error: any) {
    this.errors = {};
    this.errors['city'] = error?.city;
    this.errors['country'] = error?.country;
    this.errors['house_number'] = error?.house_number;
    this.errors['street'] = error?.street;
    this.errors['zip_code'] = error?.zip_code;
    this.errors['surname_prefix'] = error?.surname_prefix;
    this.errors['initials'] = error?.initials;
    this.errors['first_name'] = error?.first_name;
    this.errors['last_name'] = error?.last_name;
  }

  showErrorToaster(msg) {
    this.toastrService.error(`${msg}`, 'Error', {
      timeOut: 4000
    });
  }

  showToaster(msg) {
    this.toastrService.success(`${msg}`, 'Success', {
      timeOut: 2000
    });
  }

  showInfoToaster(msg) {
    this.toastrService.success(`${msg}`, 'Info', {
      timeOut: 2000
    });
  }

  onAddressSearch() {
    if ((this.selectedCountryObject?.country_code !== this.NL_CODE && !this.selectedCountryObject?.searchable) || (this.selectedCountryObject?.country_code !== this.NL_CODE && this.selectedCountryObject?.searchable && this.hasInputCustomAddressPermission)) {
      return
    }

    if (this.selectedShippingAddress?.zip_code && this.selectedShippingAddress?.house_number) {
      this.selectedCountry = this.NL_CODE
      this.searchAddress(
        {
          zipCode: this.selectedShippingAddress?.zip_code,
          houseNumber: this.selectedShippingAddress?.house_number,
          houseNumberExt: this.selectedShippingAddress?.house_number_extension
        }
      );
    } else if (this.shippingForm?.value?.zip_code || this.shippingForm?.value?.house_number) {
      if (this.shippingForm?.value?.zip_code && this.shippingForm?.value?.house_number) {
        this.onSearchAddress(this.shippingForm);

      } else {
        this.shippingForm.controls['street'].setValue('');
        this.shippingForm.controls['city'].setValue('');
      }
    }
  }

  onEditAddressSearch() {
    if (this.editShippingForm.value.zip_code && this.editShippingForm.value.house_number) {
      this.onSearchAddress(this.editShippingForm);
    }
  }

  onSearchAddress(shippingForm) {
    this.errors = {}; this.addressCombinationMsg = null;
    shippingForm.controls['street'].setValue('');
    shippingForm.controls['city'].setValue('');

    clearTimeout(this.shipTimeOut);
    this.shipTimeOut = setTimeout(() => {
      this.isAddressFetching = true;
      this.shipBtnDisable = true;
      this.addressService.getNlAddress({
        zipCode: shippingForm.value.zip_code,
        houseNumber: shippingForm.value.house_number,
        houseNumberExt: shippingForm.value.house_number_extension
      }).subscribe(resp => {
        if (resp.data) {
          shippingForm.controls['street'].setValue(resp.data.street);
          shippingForm.controls['city'].setValue(resp.data.city);
          this.selectedShippingAddress.street = resp.data.street;
          this.selectedShippingAddress.city = resp.data.city;
          this.checkSearchedAddressField(resp.data);
        }
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
      }, error => {
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'CONTACT_NO_LOCATION_DATA') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error) {
          const errors = this.errorHandleService.errorHandle(error.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
        } else {
          this.addressCombinationMsg = this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG');
        }
      });
    }, 1000);
  }

  searchAddress(value) {

    this.resetAddressFound();
    this.errors = {}; this.addressCombinationMsg = null;
    clearTimeout(this.shipTimeOut);
    this.shipTimeOut = setTimeout(() => {
      this.isAddressFetching = true;
      this.shipBtnDisable = true;
      this.addressService.getNlAddress(value).subscribe(resp => {
        if (resp.data) {
          this.selectedShippingAddress.street = resp.data.street;
          this.selectedShippingAddress.city = resp.data.city;
          this.checkSearchedAddressField(resp.data);
        }
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
      }, error => {
        this.selectedShippingAddress.street = null;
        this.selectedShippingAddress.city = null;
        // this.selectedShippingAddress = {
        //   ...this.selectedShippingAddress,
        //   street: null,
        //   city: null
        // };
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
        if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'CONTACT_NO_LOCATION_DATA') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'ADDRESS_LOOKUP_NO_ADDRESS_DATA_FOUND') {
          this.addressCombinationMsg = error.error.error.message;
        }
        else if (error?.error?.error) {
          const errors = this.errorHandleService.errorHandle(error?.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
        } else {
          this.addressCombinationMsg = this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG');
        }
      });
    }, 1000);
  }


  getVoucherProfileDeliveryAddress() {
    this.shippingAddress = [];
    this.cartService.getVoucherProfileDeliveryAddress(this.cart?.uuid).subscribe(res => {
      this.shippingAddress = res?.data?.results?.filter(item => item?.address_type === 'shipping');
      this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
      this.voucherProfileShippingAddress = [...this.shippingAddress];
      if (this.cart?.shipping_address_id) {
        const addedShippingAddress = this.customer?.contact_addresses.find(element => element?.id === this.cart?.shipping_address_id);
        if (addedShippingAddress && (!this.shippingAddress.find(item => item?.id === addedShippingAddress?.id))) {
          this.shippingAddress?.unshift(addedShippingAddress);
          this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
        }
        this.selectedShippingAddress = this.shippingAddress?.find(cad => {
          return cad.id === this.cart?.shipping_address_id;
        }) || this.customer?.contact_addresses?.find(address => {
          return address.id === this.cart?.shipping_address_id;
        });

        if (this.selectedShippingAddress) {
          this.selectedShippingId = this.selectedShippingAddress?.id;
          this.isContactedPersonDataUpdated = true;
        }
      }
      if ((this.cart?.shipping_address_id) && this.selectedShippingAddress && (this.selectedShippingAddress?.id)) {
        this.sameAsDeliveryAddress = false;
      }
      let shippingAddressOrContactPersonUpdatePayload = {};
      if (this.cart?.shipping_address_id && ((!this.selectedShippingAddress) || (!this.selectedShippingId))) {
        shippingAddressOrContactPersonUpdatePayload = {
          ...shippingAddressOrContactPersonUpdatePayload,
          shipping_address_id: null,
          shipping_address_company_name: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_street: "",
          shipping_address_house_number: null,
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",

        }
        this.removeShippingAddressOrContactPersonFromCart(shippingAddressOrContactPersonUpdatePayload);

      }

    });
  }

  identify(index, item) {
    return item.id;
  }

  onSelectCountry(event, update = false) {
    if (update) {
      this.selectedCountry = event?.country_code || '';
    }

    this.selectedCountryObject = event;
    this.addressList = [];
    this.addressCreateValue = '';
    this.resetForm();
    this.resetAddressFound(this.selectedCountry === this.NL_CODE ? false : true);
  }

  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }

  searchCleared() {
    this.resetForm();
  }

  onSelectedAddress(address) {
    timer(50).subscribe(time => {
      this.resetForm();
      if (address?.context && address?.precision === EAddressPrecision.ADDRESS) {
        this.searchValue = address.value;
        this.getAddressDetails(address?.context);
      } else {
        this.searchValue = address.value;
        this.auto.close();
        this.searchAddressForeign(this.searchValue, address?.context || '');
      }
    });
  }

  searchAddressForeign(searchString: string, context = '') {
    if (searchString && searchString.length >= 3) {
      let term = `term=${searchString}`;
      term = context ? `context=${context}&term=${searchString}` : term;
      this.getAddress(term);
    }
  }

  getAddress(term) {
    this.foreignAddressSearchError = '';
    this.isLoading = true;
    this.addressService.getAddress(this.selectedCountry, term).subscribe(res => {
      this.addressList = res?.data?.matches;
      const splitTerm = term?.split('term=');
      const n_term = splitTerm?.length > 1 ? splitTerm[1] : splitTerm[0];
      const find_src = this.addressList.some(item => {
        return item?.value?.includes(n_term);
      });
      if (!find_src) {
        this.auto.clear();
        timer(50).subscribe(time => {
          this.addressCreateValue = n_term;
        })
      }
      this.auto.open();
      this.isLoading = false;
    }, error => {
      this.foreignAddressSearchError = error?.error?.message;
      this.isLoading = false;
    });
  }

  getAddressDetails(context) {
    const term = `context=${context}`;
    this.resetForm();
    this.resetAddressFound();
    this.addressService.getAddressDetails(this.selectedCountry, term).subscribe(res => {
      if (res?.data?.address) {
        this.selectedShippingAddress.street = res?.data?.address?.street;
        this.selectedShippingAddress.city = res?.data?.address?.locality;
        this.selectedShippingAddress.zip_code = res?.data?.address?.postcode;
        this.selectedShippingAddress.house_number = `${res?.data?.address?.buildingNumber ?? ''}`;
        this.selectedShippingAddress.house_number_extension = res?.data?.address?.buildingNumberAddition;
        this.selectedShippingAddress.country = this.selectedCountry;
        this.checkSearchedAddressField(res?.data?.address);

        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
      }
    }, error => {
      this.onAddressStreetChange();
      this.onAddressCityChange();
      this.onAddressPostCodeChange();
      this.onAddressHouseNoChange();
    });
  }

  resetForm() {
    this.selectedShippingAddress.street = '';
    this.selectedShippingAddress.city = '';
    this.selectedShippingAddress.zip_code = '';
    this.selectedShippingAddress.house_number = '';
    this.selectedShippingAddress.house_number_extension = '';
    this.selectedShippingAddress.country = this.selectedCountry;
    this.selectedShippingAddress.extra_address_information_1 = "";
    this.selectedShippingAddress.extra_address_information_2 = "";
    this.addressCombinationMsg = null;
  }

  resetAddressFound(value: boolean = false) {
    for (const key in this.addressFound) {
      if (Object.prototype.hasOwnProperty.call(this.addressFound, key)) {
        this.addressFound[key] = value;
      }
    }
  }

  checkSearchedAddressField(address) {
    this.addressFound.house_number_ext = true;
    if (address?.street) {
      this.addressFound.street = true;
    }
    if (address?.locality || address?.city) {
      this.addressFound.city = true;
    }

    if (address?.postcode || address?.zip_code) {
      this.addressFound.zipcode = true;
    }
    if (address?.buildingNumber || address?.house_number || address?.houseNumber) {
      this.addressFound.house_number = true;
    }
  }


  selectedCPs = [];
  cars = [
    { id: 1, name: 'Volvo' },
    { id: 2, name: 'Saab', disabled: true },
    { id: 3, name: 'Opel' },
    { id: 4, name: 'Audi' },
  ];


  onSelectCP() {
  }


  addEmailConfig() {
    this.emailConfigLoader = true;
    let payload = { contact_person_uuids: this.selectedCPs, email_configuration_code: this.selectedEmailConfig }
    this.customerService.addEmailConfiguration(this.customer?.uuid, payload).subscribe(res => {
      this.reStoreContact(res);
      this.emailConfigLoader = false;
      this.hasSelectedConfig = true;
      this.toastrService.success('Contactpersoon succesvol toegevoegd aan Email CC');
    }, error => {
      this.emailConfigLoader = false;
      this.toastrService.error('Toevoegen van contactpersoon mislukt aan Email CC');
    });
  }


  updateEmailConfig() {
    this.emailConfigLoader = true;
    let payload = { contact_person_uuids: this.selectedCPs, email_configuration_code: this.selectedEmailConfig }
    this.customerService.updateEmailConfiguration(this.customer?.uuid, payload).subscribe(res => {
      this.reStoreContact(res);
      this.emailConfigLoader = false;
      this.toastrService.success('Contactpersoon succesvol toegevoegd aan Email CC');
    }, error => {
      this.emailConfigLoader = false;
      this.toastrService.error('Toevoegen van contactpersoon mislukt aan Email CC');
    });
  }



  onSelectEmailConfig() {
    this.hasSelectedConfig = false;
    this.getSelectedEmailConfig()
  }



  searchCompanies(searchString: string) {
    if (searchString.length >= 3) {
      const companyName = searchString;
      this.selectedCompanyName = companyName;
      this.customersService.getCompanies(companyName).subscribe(res => {
        this.showDropDownItemList = true;
        if (res?.data?.results) {
          this.searchCompanyList = res.data.results?.map(item => {
            return {
              ...item,
              company_name: `${item?.company_name} (${item?.coc_number})`
            };
          });

          const find_src = this.searchCompanyList.some((item: any) => {
            return item?.company_name?.toLowerCase()?.includes(companyName?.toLowerCase());
          });
          if (this.searchCompanyList?.length && !find_src) {
            this.customerSearch.clear();
            timer(50).subscribe(time => {
              this.selectedCompanyName = companyName;
            });
          }
        }
      }, error => { });
    }
  }

  onSelectedCompany(company: object | any) {
    this.showDropDownItemList = false;
    // this.searchForm = this.formBuilder.group({
    //   zipcode: [''],
    //   house_number: [''],
    //   house_number_ext: [''],
    //   coc_no: [company?.coc_number],
    //   krn_no: [''],
    //   is_business: [true]
    // });
    this.onSearchCustomer(company);
  }

  onSearchCustomer(companyObj) {

    let customers = [];
    this.isCustomerDataLoading = true;
    this.isLoading = true;
    // if (companyObj?.company_name) {
    //   this.selectedCompanyName = companyObj?.company_name;
    // }
    this.customersService.getCustomers(this.prepareParamString(companyObj), this.searchType(companyObj))
      .subscribe(res => {
        customers = res.data.results?.map(item => {
          return {
            ...item,
            firstContactAddress: item?.contact_addresses?.find(address => {
              return address?.address_type === EAddressTypes.CONTACT;
            })
          };
        });
        if (customers?.length) {
          this.customerUuid = customers[0]?.uuid;
          this.getCustomerDetails();
        }
        else {
          this.isCustomerDataLoading = false;
        }
        // if (this.customer_uuid) {
        //   this.selectedCustomer = customers?.find((item: any) => item?.uuid === this.customer_uuid);
        // }
        this.isLoading = false;
      }, err => {
        this.isCustomerDataLoading = false;
        this.isLoading = false;
      });
  }
  prepareParamString(companyObj) {

    let params: any;

    if (companyObj?.krn_number) {
      params = companyObj?.krn_number;
    } else if (companyObj?.coc_number) {
      params = companyObj?.coc_number;
    } else {
      let paramsString = '';

      if (companyObj?.zipcode) {
        paramsString += `zip_code=${companyObj?.zipcode}`;
      }
      if (companyObj?.zipcode && companyObj?.house_number) {
        paramsString += `&house_number=${companyObj?.house_number}`;
      }
      if (companyObj?.zipcode && companyObj?.house_number && companyObj?.house_number_ext) {
        paramsString += `&house_number_ext=${companyObj?.house_number_ext}`;
      }

      params = new HttpParams({
        fromString: paramsString
      });
    }

    return params;
  }
  searchType(companyObj) {
    let searchType = '';

    if (companyObj?.krn_number) {
      searchType = 'krn';
    } else if (companyObj?.coc_number) {
      searchType = 'coc';
    } else {
      searchType = 'address';
    }

    return searchType;
  }
  companySearchCleared() {
    this.showDropDownItemList = false;

  }

  removeShippingAddressOrContactPersonFromCart(payloadObj) {
    const cart = this.storageService.getCart();
    if (cart) {
      this.customerService
        .updateCart(cart.uuid, payloadObj)
        .toPromise().then(res => {
          this.storageService.setCart(res.data);
          this.cartService.changeCurrentCart(res.data);
          this.cart = res.data;
          if (payloadObj?.contact_person_uuid === null) {
            this.selectedContactPerson = null;
            this.selectedContactPersonUUID = null;
            this.isContactPersonSelected = false;
            this.storageService.removeContactPersonUUID();
          }
          if (payloadObj?.shipping_address_id === null) {
            this.selectedShippingAddress = null;
            this.selectedShippingId = null;
            this.storageService.removeShippingAddressID();
          }

        }).catch(err => {
          this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
        });
    }
  }

  onChangeSameAsDeliveryAddress() {
    if (this.sameAsDeliveryAddress) {
      this.saveAsNewAddress = false;
    }
  }

  get isNextStepDisabled() {
    if (
      (!this.customer?.is_active) ||
      (this.customer?.order_blocked) ||
      (this?.selectedContactPerson?.status === 'new' ? (
        !this.newContactPerson?.first_name?.trim()?.length ||
        !this.newContactPerson?.last_name?.trim()?.length ||
        !this.newContactPerson?.email?.trim()?.length ||
        !this.newContactPerson?.initials?.trim()?.length
      ) : ((!this.selectedContactPersonUUID || !this.isContactPersonSelected))) ||
      // (!this.isContactPersonSelected) ||
      this.isLoading ||
      (this.cart?.allow_delivery_address_from_contact && (
        (!this.selectedShippingId || !this.shippingAddress?.length) ? (this.createAddressLoader ||
          !this.selectedShippingAddress?.city?.trim()?.length ||
          !this.selectedShippingAddress?.street?.trim()?.length ||
          !this.selectedShippingAddress?.zip_code?.trim()?.length ||
          !this.selectedShippingAddress?.house_number?.trim()?.length ||
          !this.selectedShippingAddress?.company_name?.trim()?.length || !this.addressFound.street || !this.addressFound.city || !this.addressFound.zipcode || !this.addressFound.house_number)
          : (!this.selectedShippingId || !this.shippingAddress?.length)
      )) ||
      ((this.isNewQuote || this.isImportedQuote) && (!this.selectedShippingId ? (!this.selectedShippingAddress?.city?.trim()?.length ||
        !this.selectedShippingAddress?.street?.trim()?.length ||
        !this.selectedShippingAddress?.zip_code?.trim()?.length ||
        !this.selectedShippingAddress?.house_number?.trim()?.length ||
        !this.selectedShippingAddress?.company_name?.trim()?.length || !this.addressFound.street || !this.addressFound.city || !this.addressFound.zipcode || !this.addressFound.house_number) : false)) ||

      ((this.sameAsDeliveryAddress === false && this.saveAsNewAddress === false) && (this?.cart) && (!this.selectedShippingAddress?.isNew) && (!this.selectedShippingId)) ||

      (this.saveAsNewAddress && this.cart && (this.createAddressLoader ||
        !this.selectedShippingAddress?.city?.trim()?.length ||
        !this.selectedShippingAddress?.street?.trim()?.length ||
        !this.selectedShippingAddress?.zip_code?.trim()?.length ||
        !this.selectedShippingAddress?.house_number?.trim()?.length ||
        !this.selectedShippingAddress?.company_name?.trim()?.length || !this.addressFound.street || !this.addressFound.city || !this.addressFound.zipcode || !this.addressFound.house_number)) ||


      ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && this.cart && (this.selectedShippingAddress?.isNew) && (this.createAddressLoader ||
        !this.selectedShippingAddress?.city?.trim()?.length ||
        !this.selectedShippingAddress?.street?.trim()?.length ||
        !this.selectedShippingAddress?.zip_code?.trim()?.length ||
        !this.selectedShippingAddress?.house_number?.trim()?.length ||
        !this.selectedShippingAddress?.company_name?.trim()?.length || !this.addressFound.street || !this.addressFound.city || !this.addressFound.zipcode || !this.addressFound.house_number)) ||

      ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && (!this.cart) && (this.selectedShippingAddress?.isNew) && (this.createAddressLoader ||
        !this.selectedShippingAddress?.city?.trim()?.length ||
        !this.selectedShippingAddress?.street?.trim()?.length ||
        !this.selectedShippingAddress?.zip_code?.trim()?.length ||
        !this.selectedShippingAddress?.house_number?.trim()?.length ||
        !this.selectedShippingAddress?.company_name?.trim()?.length || !this.addressFound.street || !this.addressFound.city || !this.addressFound.zipcode || !this.addressFound.house_number))


    ) { return true; }
  }

  async createNewShippingAddressAndSaveToCart() {
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.selectedShippingAddress.address_type = 'shipping';
    this.errors = {};
    return this.addCustomerService.submitNewAddress(this.selectedShippingAddress, this.customerUuid).toPromise().then(response => {
      if (response?.data) {
        this.shippingAddress = [response?.data, ...this.shippingAddress];
        this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
        this.selectedShippingAddress = this.shippingAddress?.find(item => item?.id === response?.data?.id) || null;
        this.selectedShippingId = this.selectedShippingAddress?.id;
        this.storageService.setShippingAddressID(this.selectedShippingId);
        const cart = this.storageService.getCart();

        const updatePayload = {
          shipping_address_id: this.selectedShippingId,
          shipping_address_company_name: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_street: "",
          shipping_address_house_number: null,
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",
          shipping_extra_address_information_1: this.selectedShippingAddress?.extra_address_information_1 || "",
          shipping_extra_address_information_2: this.selectedShippingAddress?.extra_address_information_2 || ""
        };
        return this.customerService.updateCart(cart.uuid, updatePayload).toPromise().then(res => {
          this.storageService.setCart(res.data);
          this.cartService.changeCurrentCart(res.data);
          this.isLoading = false;
          this.cart = res?.data;
          this.showToaster(this.translocoService.translate('CUSTOMER.SHIPPING_ADDRESS_LINKED_TO_CART'));
          return true;
        }).catch(err => {
          this.handleError(err);
          this.isLoading = false;
          this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
          return false;

        })
      }
      this.createAddressLoader = false;
    }

    ).catch(error => {
      const errors = this.errorHandleService.errorHandle(error.error);
      this.handleError(errors);
      this.createAddressLoader = false;
      return false
    });
  }
  async updateCartWithEmptyAddressData() {
    this.isLoading = true;
    const cart = this.storageService.getCart();

    const updatePayload = {
      shipping_address_id: null,
      shipping_address_company_name: "",
      shipping_address_initials: "",
      shipping_address_first_name: "",
      shipping_address_last_name: "",
      shipping_address_surname_prefix: "",
      shipping_address_street: "",
      shipping_address_house_number: null,
      shipping_address_house_number_extension: "",
      shipping_address_zip_code: "",
      shipping_address_city: "",
      shipping_address_country: "",
      shipping_extra_address_information_1: "",
      shipping_extra_address_information_2: ""
    };
    return this.customerService
      .updateCart(cart.uuid, updatePayload)
      .toPromise().then(res => {
        this.storageService.setCart(res.data);
        this.cartService.changeCurrentCart(res.data);
        this.isLoading = false;
        this.cart = res?.data;
        return true;
      }).catch(err => {
        this.isLoading = false;
        this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
        return false;
      });

  }
  async updateCartWithAddressData() {
    this.isLoading = true;
    const cart = this.storageService.getCart();
    if (cart?.shipping_address_id && (cart?.shipping_address_id === this.selectedShippingId)) {
      this.isLoading = false;
      return true;
    }
    const updatePayload = {
      shipping_address_id: this.selectedShippingId, // may be integer ID is temporary
      shipping_extra_address_information_1: this.selectedShippingAddress?.extra_address_information_1 || "",
      shipping_extra_address_information_2: this.selectedShippingAddress?.extra_address_information_2 || ""
    };
    return this.customerService
      .updateCart(cart.uuid, updatePayload)
      .toPromise().then(res => {
        this.storageService.setCart(res.data);
        this.cartService.changeCurrentCart(res.data);
        this.isLoading = false;
        this.cart = res?.data;
        return true;
      }).catch(err => {
        this.isLoading = false;
        this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
        return false;
      });
  }
  async updateCartWithOneTimeAddressData() {
    this.isLoading = true;
    const cart = this.storageService.getCart();
    const updatePayload = {
      shipping_address_id: null,
      shipping_address_company_name: this.selectedShippingAddress?.company_name ?? '',
      shipping_address_initials: this.selectedShippingAddress?.initials ?? '',
      shipping_address_first_name: this.selectedShippingAddress?.first_name ?? '',
      shipping_address_last_name: this.selectedShippingAddress?.last_name ?? '',
      shipping_address_surname_prefix: this.selectedShippingAddress?.surname_prefix ?? '',
      shipping_address_street: this.selectedShippingAddress?.street ?? '',
      shipping_address_house_number: this.selectedShippingAddress?.house_number ? parseInt(this.selectedShippingAddress?.house_number) : '',
      shipping_address_house_number_extension: this.selectedShippingAddress?.house_number_extension ?? '',
      shipping_address_zip_code: this.selectedShippingAddress?.zip_code ?? '',
      shipping_address_city: this.selectedShippingAddress?.city,
      shipping_address_country: this.selectedCountry,
      shipping_address_email: this.selectedShippingAddress?.email,
      shipping_extra_address_information_1: this.selectedShippingAddress?.extra_address_information_1 || "",
      shipping_extra_address_information_2: this.selectedShippingAddress?.extra_address_information_2 || ""
    };
    return this.customerService
      .updateCart(cart.uuid, updatePayload)
      .toPromise().then(res => {
        this.storageService.setCart(res.data);
        this.cartService.changeCurrentCart(res.data);
        this.isLoading = false;
        this.cart = res?.data;
        return true;
      }).catch(err => {
        this.isLoading = false;
        this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
        return false;
      });
  }

  async createNewShippingAddress() {
    this.isLoading = true;
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.selectedShippingAddress.address_type = 'shipping';
    this.errors = {};
    return this.addCustomerService.submitNewAddress(this.selectedShippingAddress, this.customerUuid).toPromise().then(response => {
      if (response?.data) {
        this.shippingAddress = [response?.data, ...this.shippingAddress];
        this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
        this.selectedShippingAddress = this.shippingAddress?.find(item => item?.id === response?.data?.id) || null;
        this.selectedShippingId = this.selectedShippingAddress?.id;
        this.storageService.setShippingAddressID(this.selectedShippingId);
        this.isLoading = false;
        return true;
      }
      this.createAddressLoader = false;
    }

    ).catch(error => {
      this.isLoading = false;
      const errors = this.errorHandleService.errorHandle(error.error);
      this.handleError(errors);
      this.createAddressLoader = false;
      return false
    });
  }

  async createContactAndSaveToCart() {
    this.createContactPersonLoader = true;
    this.contactPersonCreateError = null;
    return this.addCustomerService.createContactPerson(this.customerUuid, this.newContactPerson).toPromise().then(response => {
      if (response?.data) {

        this.contact_persons = [response?.data, ...this.contact_persons];
        this.selectedContactPerson = this.contact_persons?.find(item => item?.uuid === response?.data?.uuid) || null;
        this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
        this.isContactPersonSelected = true;
        this.storageService.setContactPersonUUID(this.selectedContactPersonUUID);
        this.setCurrentFormControl();
        const cart = this.storageService.getCart();
        if (cart) {
          this.isLoading = true;
          const updatePayload = {
            contact_person_uuid: this.selectedContactPersonUUID || this.selectedContactPerson?.uuid,
          };
          return this.customerService
            .updateCart(cart.uuid, updatePayload).toPromise().then(res => {
              this.storageService.setCart(res.data);
              this.cartService.changeCurrentCart(res.data);
              this.isLoading = false;
              this.cart = res?.data;
              this.showToaster(this.translocoService.translate('CUSTOMER.CONTACT_LINKED_TO_CART'));
              return true;

            }).catch(err => {
              this.isLoading = false;
              this.showErrorToaster(this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG'));
              return false;
            });

        } else {
          return true;
        }
      }
      this.createContactPersonLoader = false;


    }).catch(error => {
      this.isLoading = false;
      this.createContactPersonLoader = false;
      this.showErrorToaster(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_CREATE_FAILED'));
      this.contactPersonCreateError = error?.error?.error;
      return false;
    });
  }

  async updateOnlyShippingAddress() {
    this.isLoading = true;
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.errors = {};
    this.selectedShippingAddress.address_type = 'shipping';
    return this.addCustomerService.updateNewAddress(this.selectedShippingAddress, this.customerUuid, this.selectedShippingAddress?.uuid).toPromise().then(response => {
      if (response?.data) {
        this.shippingAddress = this.shippingAddress.map(u => {
          return u.id === response?.data?.id ? { ...this.selectedShippingAddress, ...response?.data } : u;
        });
        this.originalShippingAddress = JSON.parse(JSON.stringify(this.shippingAddress));
        this.isLoading = false;
        this.createAddressLoader = false;
        this.selectedShippingAddress = this.shippingAddress?.find(item => item?.id === this.selectedShippingAddress?.id) || null;
        this.selectedShippingId = this.selectedShippingAddress?.id;
        this.storageService.setShippingAddressID(this.selectedShippingId);
        return true;
      }
      this.isLoading = false;
      this.createAddressLoader = false;
      return true;
    }).catch(error => {
      this.isLoading = false;
      const errors = this.errorHandleService.errorHandle(error.error);
      this.handleError(errors);
      this.createAddressLoader = false;
      return false;
    });
  }


  onAddressCityChange() {
    if (!this.selectedShippingAddress?.city?.trim()?.length) {
      this.addressCreateError.city = true;
    } else {
      this.addressCreateError.city = false;
    }
  }
  onAddressStreetChange() {
    if (!this.selectedShippingAddress?.street?.trim()?.length) {
      this.addressCreateError.street = true;
    } else {
      this.addressCreateError.street = false;
    }
  }
  onAddressHouseNoChange() {
    if (!this.selectedShippingAddress?.house_number?.toString()?.trim()?.length) {
      this.addressCreateError.house_number = true;
    } else {
      this.addressCreateError.house_number = false;
    }
  }
  onAddressPostCodeChange() {
    if (!this.selectedShippingAddress?.zip_code?.trim()?.length) {
      this.addressCreateError.zip_code = true;
    } else {
      this.addressCreateError.zip_code = false;
    }
  }
  onAddressCompanyChange() {
    if (!this.selectedShippingAddress?.company_name?.trim()?.length) {
      this.addressCreateError.company_name = true;
    } else {
      this.addressCreateError.company_name = false;
    }
  }

  onContactPersonFirstNameChange() {
    if (!this.newContactPerson?.first_name?.trim()?.length) {
      this.feContactPersonCreateError.first_name = true;
    } else {
      this.feContactPersonCreateError.first_name = false;
    }
  }
  onContactPersonEmailChange() {
    if (!this.newContactPerson?.email?.trim()?.length) {
      this.feContactPersonCreateError.email = true;
    } else {
      this.feContactPersonCreateError.email = false;
    }
  }
  onContactPersonLastNameChange() {
    if (!this.newContactPerson?.last_name?.trim()?.length) {
      this.feContactPersonCreateError.last_name = true;
    } else {
      this.feContactPersonCreateError.last_name = false;
    }
  }
  onContactPersonInitialChange() {
    if (!this.newContactPerson?.initials?.trim()?.length) {
      this.feContactPersonCreateError.initials = true;
    } else {
      this.feContactPersonCreateError.initials = false;
    }
  }


  ngOnDestroy(): void {
    if (this.shipTimeOut) {
      clearTimeout(this.shipTimeOut);
    }
  }
}
