export enum ERmaTypes {
    SWAP = "swap",
    TRAIL_PERIOD = "trial_period",
    GUARANTEE_PERIOD = "guarantee_period",
    DOA_BEFORE_SALES = "doa_before_sales",
    RETOUR_SERVICE_EXPERT = "rma_service_expert"
}

export enum ERmaFlowTypes {
    RETOUR_BEKENDE_KLANT = "rma_from_order",
    SWAP_ARTIKELEN = "swap_products",
    DOA_VOOR_VERKOOP = "doa_before_sales",
    RETOUR_ONBENOEMD = "rma_without_original_order",
    RETOUR_SERVICE_EXPERT = "rma_service_expert"
}


export enum ERmaReasons {
    RETURNED_AS_NEW = "as_new",
    RETURNED_DOA = "doa",
    RETURNED_PACKAGE_OPENED = "package_opened"
}
