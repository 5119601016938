import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/storage.service';
import { CustomerService } from '../customer/customer.service';
import { AddCustomerService } from './add-customer.service';
import { ErrorHandleService } from 'src/app/shared/error-handle.service';
import { TranslocoService } from '@ngneat/transloco';

export class ContactPerson {
  constructor(is_new = false) {
    if (is_new) {
      this.is_new = is_new;
      this.initials = '';
    }
  }
  initials = '';
  surname_prefix = '';
  last_name = '';
  birth_name_prefix = '';
  birth_name = '';
  gender = '';
  birthday = null;
  email = '';
  mobile_phone_number = '';
  bank_account_number = '';
  // id = new IdType();
  is_new = false;
  uuid = '';
}

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.scss']
})
export class AddCustomerComponent implements OnInit, OnDestroy, AfterViewInit {
  cartId = null;
  basicForm: UntypedFormGroup;
  contactUuid = null;
  contactPersonForm: UntypedFormGroup;
  shippingForm: UntypedFormGroup;
  editShippingForm: UntypedFormGroup;
  private contactPersons = [];
  selectedContactPersonUUID: '';
  isLoading = false;
  isShippingAddressesLoading = false;
  formCtrl: UntypedFormControl;
  selectedCompany: any;
  isContactPersonSelected = false;
  isContactedPersonDataUpdated = false;
  shippingAddress = [];
  countryList = [];
  selectedShippingId = 0;
  contactAddressUuid = '';
  modalReference: any;
  shippingUuid = null;
  shippingIndex = 0;
  shippingId = 0;
  errors = null;
  isAddressFetching = false;
  addressCombinationMsg = null;
  contactPersonUUID = null;
  shipBtnDisable = false;
  shipTimeOut = null;
  isContactPersonEmpty = false;
  public user = null;

  constructor(
    private fb: UntypedFormBuilder,
    private storageService: StorageService,
    private router: Router,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private toastrService: ToastrService,
    private addCustomerService: AddCustomerService,
    private errorHandleService: ErrorHandleService,
    private translocoService : TranslocoService
  ) {
    this.user = this.storageService.getCurrentUser();
  }

  ngOnInit() {
    this.initializeBasicForm();
    this.getCustomerDetails();
    this.initContactPersonFormArray();
    this.cartId = this.storageService.getCart();
    this.selectedShippingId = this.storageService.getShippingAddressID();
    this.addCustomerService.getCountries().subscribe((response) => {
      this.countryList = response?.data?.results;
    }, error => {
      console.log('error: ', error);
    });
  }

  ngAfterViewInit(): void {
    if (this.user?.contact_group?.is_external) {
      this.router.navigate([`/customer/${this.user?.contact?.uuid}`]);
    }
  }

  initializeBasicForm() {
    this.basicForm = this.fb.group({
      company_name: [''],
      coc_number: [''],
      bank_account_no: [''],
      address: this.fb.group({
        first_name: [''],
        last_name: [''],
        street: [''],
        zip_code: [''],
        city: [''],
        house_number: [''],
        house_number_extension: ['']
      })
    });
  }

  createAddress(createAddressModal: any) {
    this.errors = {}; this.addressCombinationMsg = null;
    this.shippingForm = this.fb.group({
      first_name: [''],
      surname_prefix: [''],
      last_name: [''],
      company_name: [''],
      street: [''],
      house_number: [''],
      house_number_extension: [''],
      zip_code: [''],
      city: [''],
      country: ['NL']
    });
    this.modalReference = this.modalService.open(createAddressModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'lg',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  goToCartPage() {
    this.router.navigate(['/cart', `${this.cartId.uuid}`]);
  }

  getCustomerDetails() {
    const contact = this.storageService.getCurrentUser();
    if (contact) {
      this.contactUuid = contact?.contact?.uuid;
      const personUuid = contact?.contact_person?.uuid;
      this.customerService.getCustomer(this.contactUuid)
        .subscribe((response) => {
          this.fieldsValueAssignment(response.data);
          this.contactPersons = response?.data?.contact_persons;
          this.isContactPersonEmpty = this.contactPersons.length > 0 ? false : true;
          if (contact?.is_external) {
            const person = this.contactPersons.filter(persons => persons?.uuid === personUuid)?.find(obj => true);
            if (person && Object.keys(person).length) {
              const formValue = this.createContactPersonForms(person);
              this.contactPersonForms.push(formValue);
              this.onContactPersonChecked(formValue);
            }
          } else {
            for (const contactPerson of this.contactPersons) {
              this.contactPersonForms.push(
                this.createContactPersonForms(contactPerson)
              );
            }
          }
        }, err => {
          console.log('err: ', err);
        });
    }
  }

  fieldsValueAssignment(customer: any) {
    this.basicForm.controls['company_name'].setValue(customer.company_name);
    this.basicForm.controls['coc_number'].setValue(customer.coc_number);
    this.basicForm.controls['bank_account_no'].setValue(
      customer.bank_account_no
    );

    const contactAddress = customer?.contact_addresses.find(element => element?.address_type === 'contact');
    this.shippingAddress = customer?.contact_addresses.filter(element => element?.address_type === 'shipping');

    if (contactAddress) {
      this.contactAddressUuid = contactAddress.uuid;
    }

    this.basicForm.controls['address'].setValue({
      first_name: contactAddress?.first_name || '',
      last_name: contactAddress?.last_name || '',
      zip_code: contactAddress?.zip_code || '',
      house_number: contactAddress?.house_number || '',
      house_number_extension: contactAddress?.house_number_extension || '',
      city: contactAddress?.city || '',
      street: contactAddress?.street || '',
    });
  }

  initContactPersonFormArray() {
    this.contactPersonForm = this.fb.group({
      contactPersonForms: new UntypedFormArray([]),
    });
  }

  onSelectContact(status) {
    if (status) {
      this.contactPersonUUID = this.selectedContactPersonUUID;
    }
  }

  onContactPersonChecked(contactPersonFormGroup: any, status = false) {
    this.isLoading = true;
    this.selectedContactPersonUUID = contactPersonFormGroup.value.uuid;
    if (status) {
      this.onSelectContact(status);
    }

    this.setCurrentFormControl();
    this.storageService.setContactPersonUUID(contactPersonFormGroup.value.uuid);
    const cart = this.storageService.getCart();
    if (cart) {
      const updatePayload = {
        contact_person_uuid: contactPersonFormGroup.value.uuid,
      };
      this.customerService
        .updateCart(cart.uuid, updatePayload)
        .subscribe(
          (res) => {
            this.storageService.setCart(res.data);
            this.isContactPersonSelected = true;
            this.isLoading = false;
            this.showToaster(`${this.translocoService.translate('ADD_CUSTOMER.CONTACT_PERSON_ADDED_IN_CART')}`);
          },
          (err) => {
            this.isLoading = false;
            this.showErrorToaster(`${this.translocoService.translate('CART.SOMETHING_WRONG')}`);
          }
        );
    } else {
      this.isContactPersonSelected = true;
      this.isLoading = false;
    }
  }

  private setCurrentFormControl() {
    const formCtrls = this.contactPersonForm.controls['contactPersonForms']['controls'];
    this.formCtrl = formCtrls.find(
      (el: any) => el.value.uuid === this.selectedContactPersonUUID
    );
  }

  createContactPersonForms(contactPerson: ContactPerson): UntypedFormGroup {
    const formGroup = this.fb.group({
      gender: [
        {
          value: contactPerson.gender,
          disabled: contactPerson.gender ? true : false,
        },
        Validators.required,
      ],
      initials: [
        {
          value: contactPerson.initials,
          disabled: contactPerson.initials ? true : false,
        },
        Validators.required,
      ],
      surname_prefix: [
        {
          value: contactPerson.surname_prefix,
          disabled: contactPerson.surname_prefix ? true : false,
        },
      ],
      last_name: [
        {
          value: contactPerson.last_name,
          disabled: contactPerson.last_name ? true : false,
        },
        Validators.required,
      ],
      birthday: [
        {
          value: contactPerson.birthday,
          disabled: contactPerson.birthday ? true : false,
        },
        Validators.required,
      ],
      birth_name_prefix: [
        {
          value: contactPerson.birth_name_prefix,
          disabled:
            this.selectedCompany &&
              (this.selectedCompany.export_id ||
                this.selectedCompany.external_id1)
              ? true
              : false,
        },
      ],
      birth_name: [
        {
          value: contactPerson.birth_name,
          disabled:
            this.selectedCompany &&
              (this.selectedCompany.export_id ||
                this.selectedCompany.external_id1)
              ? true
              : false,
        },
      ],
      mobile_phone_number: [
        contactPerson.mobile_phone_number,
        [Validators.required, Validators.minLength(10)]
      ],
      email: [contactPerson.email, [Validators.required, Validators.email]],
      uuid: [contactPerson.uuid],
    });
    return formGroup;
  }

  onSubmitCustomer() {
    this.setCurrentFormControl();
    this.isLoading = true;

    // tslint:disable-next-line:no-string-literal
    const formValue = this.basicForm.controls['address'].value;
    const value = {
      first_name: formValue.first_name,
      last_name: formValue.last_name,
      zip_code: formValue.zip_code,
      house_number: formValue.house_number,
      house_number_extension: formValue.house_number_extension,
      city: formValue.city,
      street: formValue.street,
      address_type: 'contact'
    };

    if (!this.contactAddressUuid) {
      this.isLoading = false;
      this.showErrorToaster(`${this.translocoService.translate('ADD_CUSTOMER.EMPTY_CONTACT_ADDRESS_PLEASE_GO_TO_DASHBOARD_TO_UPDATE_CONTACT_ADDRESS')}`);
      // Company update not allowed
      // this.addCustomerService.submitNewAddress(value, this.contactUuid)
      //   .subscribe(
      //     (response) => {
      //       this.updateContactAndShippingAddress();
      //     }, error => {
      //       this.isLoading = false;
      //       const errors = this.errorHandleService.errorHandle(error.error);
      //       // tslint:disable-next-line:no-string-literal
      //       errors['type'] = 'contact_address';
      //       this.handleError(errors);
      //     }
      //   );
    } else {
      this.addCustomerService.updateNewAddress(value, this.contactUuid, this.contactAddressUuid)
        .subscribe(
          (response) => {
            this.updateContactAndShippingAddress();
          }, error => {
            this.isLoading = false;
            const errors = this.errorHandleService.errorHandle(error.error);
            // tslint:disable-next-line:no-string-literal
            errors['type'] = 'contact_address';
            this.handleError(errors);
          }
        );
    }
  }

  async updateContactAndShippingAddress() {
    const cart = this.storageService.getCart();
    const quoteUuid = this.storageService.getQuoteUuid();
    await this.updateContactPersonData();

    if (this.selectedShippingId) {
      this.updateCartWithShippingAddress();
    }

    if (!this.isContactedPersonDataUpdated) {
      return;
    }

    if (quoteUuid) {
      this.updateQuote(quoteUuid);
    } else {
      if (cart?.lines?.length) {
        // this.router.navigate(['/cart', `${cart.uuid}`]);
        this.router.navigate(['/order-overview', `${cart.uuid}`]);
      } else {
        this.router.navigateByUrl('/home');
      }
    }
  }

  updateQuote(quoteUuid) {
    const payload = {
      contact: this.contactUuid,
      contact_person: this.storageService.getContactPersonUUID(),
    };

    this.customerService.updateQuote(quoteUuid, payload).subscribe(
      (res) => {
        this.isLoading = false;
        this.storageService.removeQuoteUuid();
        this.router.navigateByUrl('/quotes');
      },
      (err) => {
        this.isLoading = false;
        console.log('err: ', err);
      }
    );
  }

  onAddShippingAddress() {
    const formValue = this.shippingForm.controls;
    const value = {
      first_name: formValue.first_name.value,
      surname_prefix: formValue.surname_prefix.value,
      last_name: formValue.last_name.value,
      company_name: formValue.company_name.value,
      zip_code: formValue.zip_code.value,
      house_number: formValue.house_number.value,
      house_number_extension: formValue.house_number_extension.value,
      city: formValue.city.value,
      street: formValue.street.value,
      country: formValue.country.value ? formValue.country.value : 'NL',
      address_type: 'shipping'
    };

    this.addCustomerService.submitNewAddress(value, this.contactUuid)
      .subscribe(
        (response) => {
          if (response?.data) {
            this.shippingAddress.push(response?.data);
          }

          this.modalReference.close();
        },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
        }
      );
  }

  onUpdateShippingAddress() {
    this.isShippingAddressesLoading = true;
    const formValue = this.editShippingForm.value;
    const data = {
      first_name: formValue.first_name,
      surname_prefix: formValue.surname_prefix,
      last_name: formValue.last_name,
      company_name: formValue.company_name,
      street: formValue.street,
      house_number: formValue.house_number,
      house_number_extension: formValue.house_number_extension,
      zip_code: formValue.zip_code,
      city: formValue.city,
      country: formValue.country ? formValue.country : 'NL'
    };
    this.addCustomerService.updateNewAddress(data, this.contactUuid, this.shippingUuid)
      .subscribe(
        (response) => {
          if (response?.data) {
            const value = response?.data;
            this.shippingAddress[this.shippingIndex] = {
              first_name: value.first_name,
              surname_prefix: value.surname_prefix,
              last_name: value.last_name,
              company_name: value.company_name,
              street: value.street,
              house_number: value.house_number,
              house_number_extension: value.house_number_extension,
              zip_code: value.zip_code,
              city: value.city,
              country: value.country ? value.country : 'NL',
              uuid: this.shippingUuid,
              id: this.shippingId
            };
          }
          this.modalReference.close();
          this.isShippingAddressesLoading = false;
        },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
          this.isShippingAddressesLoading = false;
        }
      );
  }

  onEditShippingAddress(editAddressModal: any, shippingUuid: string, shippingIndex: number, shippingId: number) {
    this.errors = {}; this.addressCombinationMsg = null;
    this.isShippingAddressesLoading = true;
    this.addCustomerService.getContactAddress(this.contactUuid, shippingUuid)
      .subscribe(
        (response) => {
          this.shippingUuid = shippingUuid;
          this.shippingIndex = shippingIndex;
          this.shippingId = shippingId;
          if (response?.data) {
            const value = response?.data;
            this.editShippingForm = this.fb.group({
              first_name: value.first_name,
              surname_prefix: value.surname_prefix,
              last_name: value.last_name,
              company_name: value.company_name,
              street: value.street,
              house_number: value.house_number,
              house_number_extension: value.house_number_extension,
              zip_code: value.zip_code,
              city: value.city,
              country: value.country ? value.country : 'NL'
            });
            this.isShippingAddressesLoading = false;
            this.modalReference = this.modalService.open(editAddressModal, {
              ariaLabelledBy: 'modal-basic-title',
              centered: true,
              size: 'lg',
              scrollable: true,
              backdrop: 'static',
            });
            this.modalReference.result.then((result) => {
              // this.closeResult = `Closed with: ${result}`;
            }, (reason) => {
              // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            });
          }
        },
        (error) => {
          this.isShippingAddressesLoading = false;
          console.log('error: ', error);
        }
      );
  }

  updateCartWithShippingAddress() {
    this.isLoading = true;
    const cart = this.storageService.getCart();
    if (cart) {
      const updatePayload = {
        shipping_address_uuid: this.selectedShippingId, // may be integer ID is temporary
      };
      this.customerService
        .updateCart(cart.uuid, updatePayload)
        .subscribe(
          (res) => {
            this.storageService.setCart(res.data);
            this.isLoading = false;
            this.showToaster(`${this.translocoService.translate('ADD_CUSTOMER.SHIPPING_ADDRESS_ADDED_TO_THE_CART')}`);
          },
          (err) => {
            this.isLoading = false;
            this.showErrorToaster(`${this.translocoService.translate('CART.SOMETHING_WRONG')}`);
          }
        );
    } else {
      this.isLoading = false;
    }
  }

  async updateContactPersonData() {
    const selectedContactPerson = this.contactPersons.find(
      (el: any) => el.uuid === this.selectedContactPersonUUID
    );
    const formGroupData = this.formCtrl.value;
    if (
      selectedContactPerson.birth_name === formGroupData.birth_name &&
      selectedContactPerson.birth_name_prefix === formGroupData.birth_name_prefix &&
      selectedContactPerson.birthday === formGroupData.birthday &&
      selectedContactPerson.email === formGroupData.email &&
      selectedContactPerson.mobile_phone_number === formGroupData.mobile_phone_number &&
      selectedContactPerson.surname_prefix === formGroupData.surname_prefix
    ) {
      this.isContactedPersonDataUpdated = true;
      return;
    }

    const updateData = {
      contact_person: {
        ...selectedContactPerson,
        birth_name: formGroupData.birth_name,
        birth_name_prefix: formGroupData.birth_name_prefix,
        birthday: formGroupData.birthday,
        email: formGroupData.email,
        mobile_phone_number: formGroupData.mobile_phone_number,
        surname_prefix: formGroupData.surname_prefix,
        initials: formGroupData.initials
      }
    };

    if (!updateData.contact_person.birthday) {
      delete updateData.contact_person.birthday;
    }
    if (!updateData.contact_person.bank_account_number) {
      delete updateData.contact_person.bank_account_number;
    }
    if (!updateData.contact_person.email) {
      delete updateData.contact_person.email;
    }
    if (!updateData.contact_person.mobile_phone_number) {
      delete updateData.contact_person.mobile_phone_number;
    }
    if (!updateData.contact_person.initials) {
      delete updateData.contact_person.initials;
    }

    await this.customerService
      .updateContactPerson(this.contactUuid, this.selectedContactPersonUUID, updateData)
      .toPromise()
      .then(res => {
        this.isLoading = false;
        // this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG')
        this.showToaster(`${this.translocoService.translate('ADD_CUSTOMER.CONTACT_PERSON_HAS_BEEN_UPDATED')}`);
        this.isContactedPersonDataUpdated = true;
        return;
      })
      .catch(error => {
        this.isLoading = false;
        let errMsg = '';
        if (error?.error?.error?.contact_person) {
          errMsg += 'Voorletters - ' + error?.error?.error?.contact_person?.initials[0] + ' ';
        }
        this.showErrorToaster(errMsg);
        this.isContactedPersonDataUpdated = false;
      });
  }

  onSelectShippingAddress(selectedShippingId: number) {
    this.selectedShippingId = selectedShippingId;
    this.storageService.setShippingAddressID(selectedShippingId);
  }

  handleError(error: any) {
    this.errors = {};
    if (error?.type === 'contact_address') {
      this.errors['contact_city'] = error?.city;
      this.errors['contact_country'] = error?.country;
      this.errors['contact_house_number'] = error?.house_number;
      this.errors['contact_street'] = error?.street;
      this.errors['contact_zip_code'] = error?.zip_code;
    }

    if (error?.type === 'shipping_address') {
      this.errors['city'] = error?.city;
      this.errors['country'] = error?.country;
      this.errors['house_number'] = error?.house_number;
      this.errors['street'] = error?.street;
      this.errors['zip_code'] = error?.zip_code;
    }
  }

  showToaster(msg) {
    this.toastrService.success(`${msg}`, 'Success', {
      timeOut: 2000
    });
  }

  showErrorToaster(msg) {
    this.toastrService.error(`${msg}`, 'Error', {
      timeOut: 4000
    });
  }

  onAddressSearch() {
    if (this.shippingForm.value.zip_code && this.shippingForm.value.house_number) {
      this.onSearchAddress(this.shippingForm);
    } else {
      this.shippingForm.controls['street'].setValue('');
      this.shippingForm.controls['city'].setValue('');
    }
  }

  onEditAddressSearch() {
    if (this.editShippingForm.value.zip_code && this.editShippingForm.value.house_number) {
      this.onSearchAddress(this.editShippingForm);
    }
  }

  onSearchAddress(shippingForm) {
    this.errors = {}; this.addressCombinationMsg = null;
    shippingForm.controls['street'].setValue('');
    shippingForm.controls['city'].setValue('');

    clearTimeout(this.shipTimeOut);
    this.shipTimeOut = setTimeout(() => {
      this.shipBtnDisable = true;
      this.isAddressFetching = true;
      this.customerService.getSearchedAddress({
        zipCode: shippingForm.value.zip_code,
        houseNumber: shippingForm.value.house_number,
        houseNumberExt: shippingForm.value.house_number_extension
      })
        .subscribe(resp => {
          if (resp.data) {
            shippingForm.controls['street'].setValue(resp.data.street);
            shippingForm.controls['city'].setValue(resp.data.city);
          }
          this.isAddressFetching = false;
          this.shipBtnDisable = false;
        }, error => {
          this.isAddressFetching = false;
          this.shipBtnDisable = false;
          if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
            this.addressCombinationMsg = error.error.error.message;
          } else if (error.error.error) {
            const errors = this.errorHandleService.errorHandle(error.error);
            errors['type'] = 'shipping_address';
            this.handleError(errors);
          } else {
            this.addressCombinationMsg = this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG');
          }
        });
    }, 1000);
  }

  get contactPersonForms() {
    return this.contactPersonForm.get('contactPersonForms') as UntypedFormArray;
  }

  ngOnDestroy(): void {
    if (this.shipTimeOut) {
      clearTimeout(this.shipTimeOut);
    }
  }
}
