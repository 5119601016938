<!-- <div class="help-section">
    <div class="container">
        <div class="help-title">
            <h3>Kom je er niet uit?</h3>
            <p>Onze experts zijn 24/7 bereikbaar</p>
        </div>

        <div class="help-content">
            <div class="help-item item-chat">
                <i class="kpn-ui-icon ui-chat help-icon"></i>
                <h4 class="help-item-name">Chat</h4>
                <p class="help-short-disc">Direct contact</p>
            </div>
            <div class="help-item item-whatsapp">
                <i class="kphicon-uniE9BC help-icon"></i>
                <h4 class="help-item-name">Whatsapp</h4>
                <p class="help-short-disc">5 min wachttijd</p>
            </div>
            <div class="help-item item-call">
                <i class="kphicon-uniE97F help-icon"></i>
                <h4 class="help-item-name">Bellen</h4>
                <p class="help-short-disc">15 min wachttijd</p>
            </div>
        </div>

        <div class="help-contact-person">
            <img class="contact-person-img" src="../../../assets/images/help-contact-person.png" alt="">
            <div class="contact-person-details">
                <p class="person-designation">Onze service manager staat voor u klaar</p>
                <h3 class="person-name">Neem contact op met Jan-Willem</h3>
                <div class="person-contact-items">
                    <span> <i class="kphicon-uniE97F"></i> <a href="">06-12348777</a></span>
                    <span> <i class="kpn-icon kpn-mail"></i> <a href="">Stuur een bericht</a></span>
                </div>
            </div>
        </div>
    </div>
</div> -->

<!-- <section class="footer-offer-action pt-4">
    <div class="container">
        <h3 class="footer-offer-title">{{'FOOTER.PROMOTIONS_AND_OFFERS' | transloco}}</h3>
        <div class="offer-action-box">
            <h5 class="offer-title-1">{{'FOOTER.LARGE_BUSINESS_FIBER_OPTIC_FOR_ALBERT_HEIJN_SLOTERDIJK' | transloco}}</h5>
            <h3 class="offer-title-2">{{'FOOTER.EVERYONE_IMMEDIATELY_MORE_PRODUCTIVE' | transloco}}</h3>
            <div class="offer-items">
                <span><i class="kphicon-uniE91D"></i>{{'FOOTER.HIGHEST_SPEED' | transloco}}</span>
                <span><i class="kphicon-uniE91D"></i>{{'FOOTER.ALWAYS_RELIABLE' | transloco}}</span>
                <span><i class="kphicon-uniE91D"></i>{{'FOOTER.SUPER_FAST_UPLOADING_AND_DOWNLOADING' | transloco}}</span>
            </div>
            <a href="" class="forword-link">{{'FOOTER.CHECK_DETAILS' | transloco}} <i class="kphicon-uniE907"></i> </a>
        </div>
    </div>
</section>
<div class="footer-new">
    <div class="container text-center">
        <img [src]="'/assets/images/kpn-logo-light.png'" alt="">
    </div>
</div> -->
<div class="pt-5"*ngIf="isInTradeInFlow">
    <section class="tri-footer-link font-kpn-regular">
        <div class="container">
            <ul>
                <li><a href="assets/files/CGA_v2.DOCX" title="Algemene inkoopvoorwaarden">Algemene inkoopvoorwaarden</a></li>
                <li><a href="assets/files/Personal_data_protection_policy.docx" title="Beleid voor de bescherming van persoonsgegevens">Beleid voor de bescherming van
                        persoonsgegevens</a></li>
            </ul>
        </div>
    </section>
</div>
<div class="faq-footer">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-auto">
                <p class="mb-0">{{'FOOTER.CLIENT_SERVICE' | transloco}}</p>
                <h2 class="text-white fq-footer-title font-matric-bold">{{'FOOTER.HELP' | transloco}}</h2>
                <a routerLink="/faq" class="faq-footer-link">{{'FOOTER.FAQ_LINK' | transloco}}<i
                        class="kphicon-uniE907"></i></a>
            </div>
        </div>
    </div>
</div>
