import { Component, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { ProductDetailService } from 'src/app/pages/product-details/product-detail.service';
import { StorageService } from 'src/app/shared/storage.service';
import { CartService } from 'src/app/pages/cart/cart.service';
import { first } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { Subscription, timer } from 'rxjs';
import { Router } from '@angular/router';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';

import { ExportId } from 'src/app/shared/enums/flow-type.enums';
import { filter } from 'lodash';
import { TranslocoService } from '@ngneat/transloco';
import { EProductAttributesGroup } from 'src/app/shared/enums/product-type.enums';

@Component({
  selector: 'app-selection-overview',
  templateUrl: './selection-overview.component.html',
  styleUrls: ['./selection-overview.component.scss'],
})
export class SelectionOverviewComponent implements OnInit, OnDestroy, OnChanges {
  @Input() product;
  @Input() haveTradeInPermission = false;
  @Output() onTextPromotions: EventEmitter<any> = new EventEmitter<any>();

  public isBusiness = false;

  public preCartLines: any;
  preCart: any;
  public selectedProduct: any;

  public siteProfile = null;
  public quantity = 1;
  public cart = null;
  public showLoding = false;
  private tempQty = 0;
  public voucherCode = null;
  isVoucherLoading = false;
  disabledBtn = false;
  productSubs$: Subscription;
  public selectedGroup = null;
  public productGroup = [];
  public selectedProductGroup = null;
  public isLoading = true;
  public relatedAccessoryProducts = [];
  public relatedProducts = [];
  public relatedGroupProducts = [];
  public restrictExportId = ExportId;
  public cartError = null;
  public preCartError = null;
  private tempSublines = [];
  public promotionalProducts = [];
  public textPromotionalProducts = [];
  public user = null;
  public show_een_message = false;

  constructor(
    private productDetailService: ProductDetailService,
    private storageService: StorageService,
    private cartService: CartService,
    private toastrService: ToastrService,
    private router: Router,
    public store: Store<IAppState>,
    private translocoService: TranslocoService
  ) {

    this.user = this.storageService.getCurrentUser();
    this.getCart();
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
  }

  ngOnInit() {
    this.productSubs$ = this.productDetailService.getSelectedProduct().subscribe((res) => {
      this.selectedProduct = res;
      this.show_een_message = this.checkEENGroup();

      if (this.selectedProduct?.has_promotion) {
        this.getPromotionalProducts(this.selectedProduct?.code);
      } else {
        this.promotionalProducts = [];
        this.textPromotionalProducts = [];
        this.onTextPromotions.emit({
          textPromotions: this.textPromotionalProducts
        })
      }
      this.getRelatedAccessoryProducts(this.selectedProduct?.code);
      this.getRelatedProducts(this.selectedProduct?.code);

      // if (Object.keys(this.selectedProduct).length) {
      //   this.getQuantity();
      //   setTimeout(() => {
      //     this.preCartEnrich(this.quantity);
      //   }, 300);
      // }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }


  checkEENGroup() {
    let een_group = this.selectedProduct?.product_attributes?.find(group => group?.group_code === EProductAttributesGroup.KPN_EEN) || null;
    return een_group ? een_group?.attributes?.some(attr => attr?.attribute_code === EProductAttributesGroup.KPN_EEN) : false;
  }

  getPromotionalProducts(productCode: string) {

    let contact_uuid = '';
    if (this.user?.contact_group?.is_external) {
      const customer = this.storageService?.getCustomer();
      contact_uuid = customer?.uuid ?? this?.user?.contact?.uuid;
    } else {
      const customer = this.storageService?.getCustomer();
      contact_uuid = customer?.uuid;
    }
    this.productDetailService.getPromotionalProducts(productCode, contact_uuid).subscribe(res => {
      this.textPromotionalProducts = res?.data?.results?.filter(item => item?.is_promotional_text);
      this.onTextPromotions.next({
        textPromotions: this.textPromotionalProducts
      })
      this.promotionalProducts = res?.data?.results?.filter(item => !item?.is_promotional_text)?.map(group => {
        return {
          ...group,
          mandatory_products: group?.mandatory_products?.map(product => {
            return {
              ...product,
              selected: false,
              disable: false
            }
          }),
          optional_products: group?.optional_products?.map(product => {
            return {
              ...product,
              selected: false,
              disable: false
            }
          }),
        }
      });

      this.populateCartWithSubLine();
    })
  }

  getRelatedAccessoryProducts(code: string) {
    this.productDetailService.getRelatedAccessoryProducts(code).subscribe((response) => {
      if (Object.keys(response.data).length) {
        this.relatedAccessoryProducts = response.data;
        this.productGroup = Object.keys(response.data).map((key) => ({ name: key, value: key }));
        this.selectedProductGroup = this.productGroup[this.productGroup.length - 1]?.value;
        this.onGroupChange(this.selectedProductGroup);
      }
    }, (error) => {
      console.log('error: ', error);
      this.showLoding = false;

    }
    );
  }

  getRelatedProducts(code: string) {
    this.showLoding = true;
    this.productDetailService.getRelatedProducts(code).subscribe((response) => {
      if (Object.keys(response.data).length) {
        this.relatedProducts = response?.data?.results?.map(group => {
          return {
            ...group,
            products: group?.products?.map(product => {
              return {
                ...product,
                selected: false
              }
            })
          }
        });

        this.populateCartWithSubLine();
      }
    }, (error) => {
      console.log('error: ', error);
      this.showLoding = false;

    }
    );
  }


  populateCartWithSubLine() {
    let sub_lines = this.populateSubLine();
    this.tempSublines = sub_lines;
    this.showLoding = false;
    if (Object.keys(this.selectedProduct).length) {
      this.getQuantity();
      setTimeout(() => {
        this.preCartEnrich(this.quantity, false, sub_lines);
      }, 300);
    }
  }

  onGroupChange(group: string, forRelatedProduct = false) {
    this.selectedProductGroup = group;
    if (forRelatedProduct) return;
    this.relatedGroupProducts = [];
    this.relatedGroupProducts = this.relatedAccessoryProducts[group]?.map(product => ({
      ...product,
      showSpinner: false,
      addedNumber: this.getProductCountOfCart(product),
    }));
  }

  getProductCountOfCart(product) {
    if (this.cart) {
      const line = this.cart?.lines?.find(obj => obj.product_code === product.code);
      return line?.quantity;
    }
    return 0;
  }

  onProductDetail(product, index) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/product-details', `${product?.code}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  getQuantity() {
    const productInCart = this.findProductInCart(this.selectedProduct);
    if (productInCart) {
      this.quantity = productInCart.quantity ? productInCart.quantity : 1;
    } else {
      this.quantity = this.selectedProduct?.order_unit || 1;
    }
    this.tempQty = this.quantity;
  }

  onSelectAttr(productCode: string) {
    const catalogCode = this.storageService.getCatalog() || ''
    this.productDetailService.getProduct(productCode, catalogCode).subscribe(res => {
    }, error => {
      console.log('error: ', error);

    });
  }

  getDecimalLength(price = '0') {
    let floatPrice = `${parseFloat(price)}`;
    return floatPrice?.split('.')?.[1]?.length;
  }


  onChangeRelatedProduct(event, product, group) {
    const selectedProduct = group?.products?.filter(pro => pro.selected);
    this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));
    if (event) {
      // Urgent Fix for production but logic can be optimized
      if (group?.max_required === 1 && group?.min_required > 0) {
        const relatedIdx = this.relatedProducts.findIndex(product => product.product_group === group?.product_group);
        const products = this.relatedProducts[relatedIdx].products.map(item => ({ ...item, selected: item.code === product.code }));
        this.relatedProducts[relatedIdx] = {
          ...this.relatedProducts[relatedIdx],
          products: products
        }
        const newProductCodeList = products.filter(item => item?.code != product?.code);
        if (newProductCodeList?.length) {
          newProductCodeList.forEach(product => {
            this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
          });
        }
      }
      this.tempSublines.push({
        product_code: product.code,
        quantity: this.quantity || 1,
        product_name: product.name,
        flow_type_code: 'new',
        promotion_code: '',
        sub_lines: []
      });
    } else {
      if (selectedProduct.length <= (group?.min_required - 1)) {
        this.toastrService.info(`${this.translocoService.translate('PRODUCT_DETAILS.AT_LEAST')} ${group?.min_required} ${this.translocoService.translate('PRODUCT_DETAILS.SHOULD_BE_SELECTED')} `, 'Info', {
          timeOut: 3000
        });

        timer(500).subscribe(time => {
          product.selected = true;
        })

        return;
      }
      this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
    }
    product.selected = event;
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }

  checkCartSubLineProduct(group) {
    let groupProduct = [...group?.mandatory_products, ...group?.optional_products];
    return this.preCart?.sub_lines?.filter(s_line => {
      return groupProduct?.some(item => item?.code === s_line?.product_code || item?.product?.code === s_line?.product_code)
    })?.length
  }

  onChangePromotionalMandatoryProduct(event, product, group) {
    this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));

    if (event) {
      let total_added = this.checkCartSubLineProduct(group);
      if (total_added < group?.max_promotional_products) {
        this.tempSublines.push({
          product_code: product.code,
          quantity: this.quantity || 1,
          product_name: product.name,
          promotion_code: group?.code,
          flow_type_code: 'new',
          sub_lines: []
        });
      } else {

        this.toastrService.info(`${this.translocoService.translate('PRODUCT_DETAILS.MAX')} ${group?.max_promotional_products} ${this.translocoService.translate('PRODUCT_DETAILS.SHOULD_BE_SELECTED')} `, 'Info', {
          timeOut: 3000
        });

        const relatedIdx = this.promotionalProducts.findIndex(product => product.code === group?.code);
        const products = this.promotionalProducts[relatedIdx].mandatory_products.map(item => ({ ...item, selected: item.code === product.code ? false : item?.selected }));
        this.promotionalProducts[relatedIdx] = {
          ...this.promotionalProducts[relatedIdx],
          mandatory_products: products
        }
        return;

      }
    } else {
      this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
    }
    product.selected = event;
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }

  onChangePromotionalOptionalProduct(event, product, group) {
    this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));
    if (event) {
      let total_added = this.checkCartSubLineProduct(group);
      if (total_added < group?.max_promotional_products) {
        this.tempSublines.push({
          product_code: product.code,
          quantity: this.quantity || 1,
          product_name: product.name,
          flow_type_code: 'new',
          promotion_code: group?.code,
          sub_lines: []
        });
      } else {
        this.toastrService.info(`${this.translocoService.translate('PRODUCT_DETAILS.MAX')} ${group?.max_promotional_products} ${this.translocoService.translate('PRODUCT_DETAILS.SHOULD_BE_SELECTED')} `, 'Info', {
          timeOut: 3000
        });

        const relatedIdx = this.promotionalProducts.findIndex(product => product.code === group?.code);
        const products = this.promotionalProducts[relatedIdx].optional_products.map(item => ({ ...item, selected: item?.product?.code === product.code ? false : item?.selected }));
        this.promotionalProducts[relatedIdx] = {
          ...this.promotionalProducts[relatedIdx],
          optional_products: products
        }

        return;
      }
    } else {
      this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
    }
    product.selected = event;
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }

  populateSubLine() {
    let sub_lines = [];
    const productInCart = this.findProductInCart(this.selectedProduct);
    if (productInCart) {
      let cartSubLines = productInCart?.sub_lines;
      this.relatedProducts?.forEach(group => {
        group.products?.forEach(product => {
          if (cartSubLines?.some(item => item?.product_code === product?.code)) {
            product.selected = true;
          }
        });
      });

      this.populatePromotionalProductInPreCart(cartSubLines);

      sub_lines = cartSubLines?.filter(item => !item?.product_code?.startsWith('tkh_'))?.map(sub_line => {
        return {
          product_code: sub_line?.product_code,
          quantity: this.quantity || 1,
          uuid: sub_line?.uuid,
          product_name: sub_line?.product_name,
          promotion_code: sub_line?.promotion_code,
          flow_type_code: "new",
          sub_lines: []
        }
      });
    } else {
      this.relatedProducts?.forEach(product => {
        if (product?.min_required >= 1) {
          for (let index = 0; index < product?.min_required; index++) {
            const first_product = product?.products[index];
            let first_item = {
              product_code: first_product?.code,
              quantity: this.quantity || 1,
              product_name: first_product?.name,
              promotion_code: '',
              flow_type_code: "new",
              sub_lines: []
            };
            if (product.products[index]) {
              product.products[index].selected = true;
            }
            sub_lines.push(first_item);
          }
        }
      });

      sub_lines = this.populatePromotionalProductAsSelected(sub_lines)
    }

    const unique_sub_lines = sub_lines.reduce((accumulator, current) => {
      if (!accumulator.some((item) => item.product_code === current.product_code)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    return unique_sub_lines;
  }


  populatePromotionalProductInPreCart(cartSubLines) {
    this.promotionalProducts?.forEach(group => {
      group.mandatory_products?.forEach(product => {
        if (cartSubLines?.some(item => item?.product_code === product?.code)) {
          product.selected = true;
          if (group.mandatory_products?.length === 1 && product?.price?.price_ex_vat === 0) {
            product.disable = true;
          }
        }
      });

      group.optional_products?.forEach(product => {
        if (cartSubLines?.some(item => item?.product_code === product?.product?.code)) {
          product.selected = true;
          if (group.optional_products?.length === 1 && product?.product?.price?.price_ex_vat === 0) {
            product.disable = true;
          }
        }
      });
    });
  }

  populatePromotionalProductAsSelected(sub_lines = []) {
    this.promotionalProducts?.forEach(product => {
      if (product?.mandatory_products?.length === 1 && product?.mandatory_products[0]?.price?.price_ex_vat === 0) {
        for (let index = 0; index < product?.mandatory_products?.length; index++) {
          const first_product = product?.mandatory_products[index];
          if (first_product) {
            let first_item = {
              product_code: first_product?.code,
              quantity: this.quantity || 1,
              product_name: first_product?.name,
              flow_type_code: "new",
              sub_lines: [],
              promotion_code: product?.code
            };
            if (product.mandatory_products[index]) {
              product.mandatory_products[index].selected = true;
              product.mandatory_products[index].disable = true;
            }
            sub_lines.push(first_item);
          }
        }
      }

      if (product?.optional_products?.length === 1 && product?.optional_products[0]?.product?.price?.price_ex_vat === 0) {
        for (let index = 0; index < product?.optional_products?.length; index++) {
          const first_product = product?.optional_products[index];
          if (first_product) {
            let first_item = {
              product_code: first_product?.product?.code,
              quantity: this.quantity || 1,
              product_name: first_product?.product?.name,
              flow_type_code: "new",
              sub_lines: [],
              promotion_code: product?.code
            };
            if (product.optional_products[index]) {
              product.optional_products[index].selected = true;
              product.optional_products[index].disable = true;
            }
            sub_lines.push(first_item);
          }
        }
      }
    });

    return sub_lines
  }

  preCartEnrich(amountOfProduct, isAddedToCart = false, sub_lines = [], forRelatedProduct = false) {
    this.showLoding = true;
    this.preCartError = null;
    const contactPersonUuid = this.storageService.getContactPersonUUID();
    const shippingAddressUuid = this.storageService.getShippingAddressID() || null;
    const productPayload = {
      market_type_code: 'business',
      contact_person_uuid: contactPersonUuid,
      shipping_address_id: contactPersonUuid && shippingAddressUuid ? shippingAddressUuid : '',
      lines: [
        {
          product_code: this.selectedProduct?.code,
          quantity: amountOfProduct,
          flow_type_code: 'new',
          sub_lines: sub_lines
        }
      ]
    };

    if (!productPayload.contact_person_uuid) {
      delete productPayload.contact_person_uuid;
    }

    this.cartService.preCartEnrich(productPayload).subscribe((res) => {
      this.showLoding = false;
      this.preCartLines = res.data.lines;
      let has_promotions = this.preCartLines?.find(line => line?.has_promotion);
      this.preCart = res.data.lines.length ? res.data.lines[0] : null;
      this.quantity = this.preCart ? this.preCart.quantity : (this.selectedProduct?.order_unit || 1);
      if (isAddedToCart) {
        this.onAddToCart(this.selectedProduct, 0, forRelatedProduct);
      }
    }, error => {
      this.showLoding = false;
      this.quantity = this.tempQty;
      this.preCartError = error?.error?.message;
      this.showErrorToaster(error?.error?.message);
    });
  }

  increaseQuantity() {
    this.tempQty = this.quantity;
    this.quantity = this.quantity + this.selectedProduct?.order_unit || 1;
    // this.preCartEnrich(this.quantity + (this.selectedProduct?.order_unit || 1), true);
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.tempQty = this.quantity;
      this.quantity = this.quantity - this.selectedProduct?.order_unit || 1;
      // this.preCartEnrich(this.quantity - (this.selectedProduct?.order_unit || 1), true);
    }
  }

  formatPreCartSubLine() {
    return this.preCart?.sub_lines?.filter(item => !item?.product_code?.startsWith('tkh_'))?.map(sub_line => {
      return {
        product_code: sub_line?.product_code,
        quantity: this.quantity || 1,
        uuid: sub_line?.uuid,
        product_name: sub_line?.product_name || '',
        flow_type_code: "new",
        sub_lines: [],
        promotion_code: sub_line?.promotion_code || '',

      }
    })
  }

  onEnter() {
    if (this.quantity > 0) {
      let sub_lines = this.formatPreCartSubLine();
      this.preCartEnrich(this.quantity, true, sub_lines);
    }
  }

  onAddRelatedProduct(product) {
    const quantity = this.findProductInCart(product)?.quantity;
    let sub_lines = this.formatPreCartSubLine();
    this.preCartEnrich(quantity || 1, true, sub_lines);
  }

  onEnterVoucher() {
    if (this.voucherCode === null || this.voucherCode === '') {
      return;
    }

    this.disabledBtn = true;
    this.isVoucherLoading = true;
    this.cartService.addVoucher(this.cart?.uuid, { voucher_code: this.voucherCode }).subscribe(
      (response) => {
        this.voucherCode = null;
        this.disabledBtn = false;
        this.isVoucherLoading = false;
        this.updateCartStore(response.data);
        this.showSuccessToaster(`${this.translocoService.translate('PRODUCT_DETAILS.VOUCHER_ADDED_TO_THE_CART')}.`);
      },
      (error) => {
        this.disabledBtn = false;
        this.isVoucherLoading = false;
        this.showErrorToaster(error?.error?.message || `${this.translocoService.translate('PRODUCT_DETAILS.FAILED')}!`);
      }
    );
  }

  onAddToCart(selectedProduct, defaultQty = 0, forRelatedProduct = false, isAccessoryProduct = false) {
    if (this.quantity < 1 || this.preCartError) {
      this.showErrorToaster(`${this.translocoService.translate('PRODUCT_DETAILS.PLEASE_SELECT_AT_LEAST_ONE')}`);
      return;
    }

    const cartId = this.storageService.getCart().uuid;

    const cart = {
      market_type_code: 'business',
      lines: [
        {
          product_code: selectedProduct.code,
          product_name: selectedProduct.name,
          product_type: selectedProduct?.product_type?.code || null,
          quantity: defaultQty || this.quantity,
          flow_type_code: 'new',
          sub_lines: isAccessoryProduct ? [] : this.formatPreCartSubLine() || []
        },
      ],
    };

    const contactPersonUUID = this.storageService.getContactPersonUUID();
    if (contactPersonUUID) {
      // tslint:disable-next-line:no-string-literal
      cart['contact_person_uuid'] = contactPersonUUID;
    }

    const shippingAddressUuid = this.storageService.getShippingAddressID() || null;
    if (shippingAddressUuid) {
      // tslint:disable-next-line:no-string-literal
      cart['shipping_address_id'] = shippingAddressUuid;
    }


    if (!cartId) {
      this.createCart(cart, selectedProduct, forRelatedProduct);
    } else {
      const findProduct = this.findProductInCart(selectedProduct);
      if (findProduct) {
        this.updateCartItem(findProduct, selectedProduct, defaultQty, forRelatedProduct, isAccessoryProduct);
      } else {
        this.updateCart(cartId, cart.lines[0], selectedProduct, forRelatedProduct);
      }
    }
  }

  getCart() {
    this.cartService.currentCart$.subscribe((res) => {
      this.cart = res;
    }, error => {
      console.log('error: ', error);
    });
  }

  goToCartOverview() {
    if (!this.cart || !this.cart.lines.length) {
      return;
    }
    const cart = this.storageService.getCart();
    this.router.navigate(['/cart', `${this.cart.uuid}`]);
  }

  createCart(cart, product, forRelatedProduct = false) {
    this.showLoding = true;
    if (product.hasOwnProperty('showSpinner')) {
      product.showSpinner = true;
    }
    this.cartService.createCart(cart).subscribe(
      (res) => {
        this.cart = res.data;
        this.updateCartStore(res.data, product);
        this.onGroupChange(this.selectedProductGroup, forRelatedProduct);
        this.showToaster();
        this.showLoding = false;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      },
      (err) => {
        this.showErrorToaster(err?.error?.message || err?.error?.error?.message || `${this.translocoService.translate('PRODUCT_DETAILS.OPERATION_FAILED')}!`);
        this.showLoding = false;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      }
    );
  }

  updateCart(cartId, cartLines, product, forRelatedProduct = false) {
    this.showLoding = true;
    this.cartError = null;
    if (product.hasOwnProperty('showSpinner')) {
      product.showSpinner = true;
    }
    this.cartService.updateCart(cartId, cartLines).subscribe(
      (res) => {
        this.cart = res.data;
        this.updateCartStore(res.data, product);
        this.onGroupChange(this.selectedProductGroup, forRelatedProduct);
        this.showToaster();
        this.showLoding = false;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      },
      (err) => {
        const productInCart = this.findProductInCart(this.selectedProduct);
        this.quantity = productInCart?.quantity || this.quantity;
        this.showErrorToaster(err?.error?.message);
        this.showLoding = false;
        this.cartError = err?.error?.message;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      }
    );
  }

  updateCartItem(product, selectedProduct, defaultQty = 0, forRelatedProduct = false, isAccessoryProduct = false) {
    this.showLoding = true;
    this.cartError = null;
    if (selectedProduct.hasOwnProperty('showSpinner')) {
      selectedProduct.showSpinner = true;
    }

    const payload = {
      quantity: defaultQty ? defaultQty + 1 : this.quantity,
      sub_lines: isAccessoryProduct ? [] : this.formatPreCartSubLine() || []
    };

    this.cartService.updateCartLine(this.cart.uuid, product.uuid, payload).subscribe(res => {
      this.cart = res.data;
      this.tempQty = this.quantity;
      this.updateCartStore(res.data, selectedProduct);
      this.onGroupChange(this.selectedProductGroup, forRelatedProduct);
      this.showToaster();
      this.showLoding = false;
      if (selectedProduct.hasOwnProperty('showSpinner')) {
        selectedProduct.showSpinner = false;
      }
    }, err => {
      this.quantity = this.tempQty;
      this.showErrorToaster(err?.error?.message);
      this.cartError = err?.error?.message;
      this.showLoding = false;
      if (selectedProduct.hasOwnProperty('showSpinner')) {
        selectedProduct.showSpinner = false;
      }
    });
  }

  updateCartStore(data: any, product = null) {
    this.cartService.changeCurrentCart(data);
    this.storageService.setCart(data);
    const selectedProduct = data?.lines?.find(line => line?.product_code === product.code) || null;
    this.cartService.saveOrderUnitOfCartProducts({ product_code: product?.code, order_unit: product?.order_unit ?? 1 }, false);
    this.storageService.setSelectedCartProduct([
      {
        quantity: data?.lines?.find(obj => obj?.product_code === product?.code)?.quantity || 1,
        code: product?.code,
        image: selectedProduct?.image || '../../../assets/images/image-not-available.png',
        name: product?.name,
        color: product?.dimensional_attributes?.find(item => item.attribute_code === 'color')?.attribute_value || '',
        memory_size: product?.dimensional_attributes?.find(item => item.attribute_code === 'memory_size')?.attribute_value || ''
      }
    ]);
  }

  findProductInCart(product) {
    const childProductCode = product?.child_products && product?.child_products.length ? product?.child_products[0]?.code : null;
    return this.cart?.lines.find(obj => {
      return obj.product_code === product.code || obj.product_code === childProductCode;
    }) || null;
  }

  showErrorToaster(msg) {
    if (msg) {
      this.toastrService.error(`${msg}`, 'Error', {
        timeOut: 4000
      });
    }
  }

  showSuccessToaster(msg) {
    this.toastrService.success(`${msg}`, 'Success', {
      timeOut: 4000
    });
  }

  showToaster() {
    // this.toastrService.success('de winkelwagen is bijgewerkt', 'Succes', {
    //   timeOut: 2000
    // });
    this.toastrService.success(this.translocoService.translate('PRODUCT_DETAILS.THE_SHOPPING_CART_HAS_BEEN_UPDATED'), 'Succes', {
      timeOut: 2000
    });
  }

  parsePrice(value = '0.0') {
    return parseFloat(value);
  }

  ngOnDestroy() {
    if (this.productSubs$) {
      this.productSubs$.unsubscribe();
    }
  }
}
