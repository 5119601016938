import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'textTrim'
})
export class TextTrimPipe implements PipeTransform {
    transform(value: any, end: string) {
        if (value.length >= 50) {
            return value.slice(0, end).concat('...');
        }
        return value;
    }
}
