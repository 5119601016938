<!-- <div class="bg-kpn-subtle-grey body-container">
  <div class="oval-bg-overlap">

  </div>
  <section class="pdp-conent-section pt-3">
    <div class="container">
      <p class="back-to-list pt-4 mb-0">
        <i class="kphicon-uniE906"></i>
        <a routerLink="/home"> Alle apparaten</a>
      </p>
      <div class="row">
        <div class="col-md-8">
          <div class="loader-wrapper-static">
            <div class="pdp-rsd-image-details">
              <h2 class="pt-0 mb-0">
                <label>{{(product$|async)?.name}}</label>
              </h2>
            </div>

            <div class="handset-image-wrap">
              <div class="kpn-spinner kpn-image-spinner" *ngIf="loading">
                <img src="../../../assets/images/kpn-loader.gif" alt="Loading">
              </div>

              <div *ngIf="currentHandset?.image" class="pdp-single-img">
                <div class="slide-arrow arrow-left">
                  <img src="../../../assets/images/button_left.svg" alt="">
                </div>
                <img [src]="currentHandset?.image" alt="">
                <div class="slide-arrow arrow-right">
                  <img src="../../../assets/images/button_right.svg" alt="">
                </div>
              </div>

              <div *ngIf="!loading" class="pdp-single-img">
                <div class="slide-arrow arrow-left">
                  <img src="../../../assets/images/button_left.svg" alt="">
                </div>
                  <img *ngIf="!currentHandset?.image" class="no-image-pdp" src="../../../assets/images/image-not-available.png" alt="">
                <div class="slide-arrow arrow-right">
                  <img src="../../../assets/images/button_right.svg" alt="">
                </div>
              </div>
            </div>

            <div>
              <div class="rsd-second-part-gap pdp-second-part-gap-up mb-4">
                <div class="text-center mb-5">
                  <button class="btn icon-btn-flat btn-flat-primary" pageScroll [pageScrollOffset]="30"
                  [pageScrollDuration]="1000" href="#specification">
                    <i class="kphicon-uniE905 mr-2"></i> Specificaties</button>
                </div>
                <div class="color-capacity mb-4 pb-1">
                  <div class="row">
                    <div class="col-md-4">
                      <div class="pdp-colors">
                        <h5 class="sub-title-regular-5 mb-0 green-text">
                          Kleur</h5>
                        <div class="btn-group-toggle" ngbRadioGroup name="radioColor" [(ngModel)]="selectedColor">
                          <label class="color-item" ngbButtonLabel *ngFor="let color of colorOptions"
                            [ngStyle]="{'background': color.code}">
                            <input ngbButton type="radio" [value]="color.code" (change)="onColorChange()">
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="pdp-storages">
                        <h5 class="sub-title-regular-5 mb-1 green-text">
                          Geheugen</h5>
                        <div class="btn-group-toggle btn-group-toggle-small" ngbRadioGroup name="radiostorage"
                          [(ngModel)]="selectedMemorySize">
                          <label *ngFor="let memory_size of memorySizeOptions" class="storage-item" ngbButtonLabel>
                            <input ngbButton type="radio" (change)="onMemoryChange($event)" [value]="memory_size.code">
                            {{memory_size.name}}
                          </label>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-4">
                      <div class="pdp-colors">
                        <h5 class="sub-title-regular-5 mb-1 green-text">voorraad</h5>
                        <div>
                          <label>
                            <strong> 233 </strong>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="product-long-description">
                  <div [innerHTML]="longDescription"></div>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div class="col-md-4">
          <app-selection-overview></app-selection-overview>
        </div>
      </div>

    </div>
  </section>

  <section class="faq-tabing hide-on-pad sticky-top">
    <div class="faq-nav-bar">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <ul class="nav">
              <li class="nav-item">
                <a class="nav-link" pageScroll [pageScrollOffset]="30"
                [pageScrollDuration]="1000" href="#specification"
                  >Specificaties</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

  <div class="specification-description">
    <section id="specification" class="specification-section pt-5 hide-on-pad">
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <h4 class="title-4 green-text mb-4">Specificaties</h4>
            <div class="read-more-wrapper" [ngClass]="{'view-all' : moreSpecification}">
              <div class="row">
                <div class="col-md-6 mb-5" *ngFor="let attr of productAttributes">
                  <h5 class="title-regular-5 mb-3 dark-grey-text">{{attr.group}}</h5>
                  <div *ngFor="let item of attr?.attributes">
                    <div class="row align-items-center mx-0 my-2" *ngIf="item?.attribute_value">
                      <div class="col-auto px-0">
                        <p class="mb-0 body-text-light-1">{{item?.attribute_name}}</p>
                      </div>
                      <div class="col px-0 inline-saperator-col">
                        <div class="inline-saperator"></div>
                      </div>
                      <div class="col-auto px-0" *ngIf="item?.attribute_value !== 'Ja' && item?.attribute_value !== 'Nee' && item?.attribute_value !== false && item?.attribute_value !== true">
                        <p class="mb-0 body-text-light-1">{{item?.attribute_value}}</p>
                      </div>
                      <div class="col-auto px-0"
                        *ngIf="item?.attribute_value === 'Ja' || item?.attribute_value === true">
                        <i class="kpn-ui-icon ui-check is_available green-text"></i>
                      </div>
                      <div class="col-auto px-0"
                        *ngIf="item?.attribute_value === 'Nee' || item?.attribute_value === false">
                        <i class="kpn-icon kpn-times danger-text"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="fadeout" *ngIf="!moreSpecification"></div>
            </div>
            <div class="row mb-5 align-items-center justify-content-center">
              <div class="col-md-3">
                <button (click)="readMoreSpecification()" class="btn btn-primary btn-block rounded-btn mb-2">
                  <span *ngIf="!moreSpecification">Meer lezen</span>
                  <span *ngIf="moreSpecification">Bekijk minder</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</div> -->

<section class="product-details-new">
  <div class="container">
    <h2 class="page-title-new">Hardware</h2>
    <app-breadcrumb [catalogCode]="catalogCode" [groupCode]="groupCode"></app-breadcrumb>

    <div class="row">
      <div class="col-md-7">
        <div class="product-details-new-left">
          <div class="product-details-new-title">
            <!-- <label for="">Samsung</label> -->
            <h2>{{(product$|async)?.code}}-{{(product$|async)?.name}}</h2>
            <p>
              {{(product$|async)?.memory_size?.name}} - {{(product$|async)?.color?.name}}
            </p>
          </div>
          <div class="product-img-viewer-new">
            <div class="navigation-wrapper">
              <div class="keen-slider keen-slider--vertical" #sliderRef>
                <div class="keen-slider__slide number-slide1" *ngFor="let item of productImg">
                  <img [src]="item?.images">
                </div>
              </div>

              <button [ngClass]="'arrow arrow--left ' + (currentSlide === 0 ? 'arrow--disabled' : '')"
                (click)="slider.prev()">
                <i class="kphicon-uniE91F"></i>
              </button>
              <button *ngIf="slider"
                [ngClass]="'arrow arrow--right ' +(slider.track.details.slides.length - 1 === currentSlide ? 'arrow--disabled': '')"
                (click)="slider.next()">
                <i class="kphicon-uniE920"></i>
              </button>
            </div>
            <div class="dots">
              <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-4 mb-2 product-section-new px-3"
              *ngFor="let pdf of selectedProduct?.pdf_documents; let i = index">
              <div class="product-box-new related-product-right">
                <div class="product-img-new">
                  <a (click)="downloadPDF(pdf?.document)" target="_blank">
                    {{ pdf?.document_name }}
                  </a>
                </div>
                <div class="product-new-content product-new-indetails document-box-item">
                  <div class="row">
                    <div class="col-md-12">
                      <button class="btn d-flex align-items-center btn-block btn-sm btn-success justify-content-center"
                        (click)="downloadPDF(pdf?.document)">
                        <i class="kpn-ui-icon ui-download v-text-bottom mr-2"></i> Download
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="accessories-item-new">
              <img class="accesso-img" src="../../../assets/images/download.jpg" alt="">
              <div class="accessories-content-new">
                <h4><span>Inclusief</span> Galaxy Buds </h4>
                <label for="">t.w.v. € 149,-</label>
              </div>
              <i class="kphicon-uniE952 info-icon-accesso click-able"></i>
            </div> -->
          <div class="product-description-new" [ngClass]="{'product-description-new-all': moreSpecification}">
            <h3 class="description-title-new">{{(product$|async)?.name}}</h3>
            <div class="new-description">
              <div [innerHTML]="longDescription"></div>
            </div>
            <a (click)="readMoreSpecification()" class="view-more-new">
              <span *ngIf="!moreSpecification">{{'PRODUCT_DETAILS.MORE_INFORMATION' | transloco }}</span>
              <span *ngIf="moreSpecification">{{'PRODUCT_DETAILS.VIEW_LESS' | transloco }}</span>
            </a>
          </div>
          <div class="row">
            <div class="col-md-6 mb-5" *ngFor="let attr of productAttributes">
              <h5 class="title-regular-5 mb-3 dark-grey-text">{{attr.group}}</h5>
              <div *ngFor="let item of attr?.attributes">
                <div class="row align-items-center mx-0 my-2" *ngIf="item?.attribute_value">
                  <div class="col-auto px-0">
                    <p class="mb-0 body-text-light-1">{{item?.attribute_name}}</p>
                  </div>
                  <div class="col px-0 inline-saperator-col">
                    <div class="inline-saperator"></div>
                  </div>
                  <div class="col-auto px-0"
                    *ngIf="item?.attribute_value !== 'Ja' && item?.attribute_value !== 'Nee' && item?.attribute_value !== false && item?.attribute_value !== true">
                    <p class="mb-0 body-text-light-1">{{item?.attribute_value}}</p>
                  </div>
                  <div class="col-auto px-0" *ngIf="item?.attribute_value === 'Ja' || item?.attribute_value === true">
                    <i class="kpn-ui-icon ui-check is_available green-text"></i>
                  </div>
                  <div class="col-auto px-0" *ngIf="item?.attribute_value === 'Nee' || item?.attribute_value === false">
                    <i class="kpn-icon kpn-times danger-text"></i>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col">
        <app-selection-overview [product]="product"></app-selection-overview>
      </div>
      <!-- <div class="col">
          <div class="product-action-right-new">
            <h4 class="right-new-title mb-4">Kies en bestel</h4>
            <div class="color-new-pd mb-4 pb-3">
              <h4 class="mb-1 action-right-title">Kleur <span>Cosmic Blue</span></h4>
              <div class="color-action-btn">
                <button class="color-item-new cosmic-blue active" style="background-color: #BFE5FA;"></button>
                <button class="color-item-new mat-grey" style="background-color: #818181;"></button>
                <button class="color-item-new pink" style="background-color: #FFC1CC;"></button>
              </div>
            </div>

            <div class="pd-action-att mb-4 pb-3">
              <h4 class="mb-2 action-right-title">Geheugen</h4>
              <div class="pd-action-btn">
                <button class="action-btn active">128 GB</button>
                <button class="action-btn">256 GB</button>
              </div>
            </div>

            <div class="pd-action-att mb-4 pb-3">
              <h4 class="mb-2 action-right-title">Accessoires</h4>
              <div class="pd-action-btn">
                <button class="action-btn active">Geen</button>
                <button class="action-btn">Hoesje + 16</button>
              </div>
            </div>

            <div class="price-action-cart">
               <h2 class="item-price">990,-</h2>
               <p class="incl-price">Incl. Gratis Galaxy Buds</p>
               <button class="btn btn-primary btn-block rounded-btn">
                <i class="kpn-ui-icon ui-shoppingbasket v-text-bottom"></i> Bestellen
               </button>
            </div>

          </div>
        </div> -->
    </div>
  </div>
</section>
