import { Component, OnInit } from '@angular/core';
import { SidebarService } from './sidebar.service';
import { StorageService } from 'src/app/shared/storage.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  menuGroups = [];

  isLoading = true;

  constructor(private sideBarService: SidebarService, private storageService: StorageService, private router: Router) { }

  ngOnInit() {
    this.sideBarService.sidemenus.subscribe(res => {
      if (res) {
        if (!this.menuGroups.length) {
          this.getSideMenuItems();
        } else {
          this.isLoading = false;
        }
      }
    });
  }

  getSideMenuItems() {
    if (this.storageService.getToken()) {
      this.sideBarService.getProductGroups().subscribe(res => {
        this.menuGroups = res.data.results;
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      });
    }
  }

  onMenuItemSelect(group_code: string) {
    this.sideBarService.changeHomeData(group_code);
    this.router.navigateByUrl('/home');
  }
}
