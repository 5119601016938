import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../../shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerListService {

  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  getCustomerList(params: HttpParams): Observable<any> {
    return this.apiBaseService.get(`contacts/available-customers`, params, 'v2');
  }
}
