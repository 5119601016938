import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class RmaService {

  constructor(private apiBaseService: ApiBaseService) { }

  getOrderLineRmaOptions(order_uuid, rma_type) {
    const paramsString = `rma_flow_type_code=${rma_type}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    const uri = `rma/order/${order_uuid}/rma-options`;
    return this.apiBaseService.get(uri, params);
  }

  createRma(payload) {
    const uri = `rma`;
    return this.apiBaseService.post(uri, payload);
  }

  uploadRMAInvoice(formData: FormData, rma_uuid) {
    const url = `rma/${rma_uuid}`;
    return this.apiBaseService.patchFile(url, formData);
  }

  getRMAOrders(page = 1) {
    const uri = `rma`;
    const paramsString = `page=${page}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(uri, params);
  }

  getRMADetails(rma_uuid) {
    const uri = `rma/${rma_uuid}`;
    return this.apiBaseService.get(uri);
  }

  approveRma(rma_uuid) {
    const uri = `rma/${rma_uuid}/approve`;
    return this.apiBaseService.patch(uri);
  }

  getRMATypes(page = 1) {
    const uri = `rma/flow-types`;
    const paramsString = `page=${page}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(uri, params);
  }

  searchRMAProduct(paramsString) {
    const uri = `search/rma-type-catalogs/products`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(uri, params);
  }

  getRMAOptions(rma_flow_type_code: string) {
    const url = `rma/flow-types/${rma_flow_type_code}/rma-options`;
    return this.apiBaseService.get(url);
  }

  getRMAReasonByOption(rma_flow_type_code: string, rma_option: string) {
    const url = `rma/flow-types/${rma_flow_type_code}/rma-options/${rma_option}`;
    return this.apiBaseService.get(url);
  }

  checkRma(rmaUuid: string, paramsString: string = null) {
    const params = new HttpParams({
      fromString: paramsString
    });
    const url = `rma/order/${rmaUuid}/retrieve-order`;
    return this.apiBaseService.get(url, params);
  }

}
