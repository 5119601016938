import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';

@Injectable({
    providedIn: 'root'
})
export class PermissionService {
    constructor(
        private apiBaseService: ApiBaseService
    ) {}

    getPermission() {
        return this.apiBaseService.get(`accounts/permissions`);
    }

    getSiteProfile() {
        return this.apiBaseService.get('tenants/site-profile');
    }
}
