import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  public isInTradeInFlow: boolean = false;
  constructor(
    private router: Router,

  ) { }

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url.includes('/trade-in')) {
          this.isInTradeInFlow = true;
        }
        else{
          this.isInTradeInFlow = false;
        }
      }
    });
  }

}
