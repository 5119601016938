import { filter } from 'rxjs/operators';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/storage.service';
import { QuoteDetailsService } from '../../quote-details.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { EAddressPrecision, EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { ESupplierTypes } from 'src/app/shared/enums/product-type.enums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AccountTypeService } from 'src/app/pages/account-type/account-type.service';
import { CartService } from 'src/app/pages/cart/cart.service';
import { ExportId } from 'src/app/shared/enums/flow-type.enums';

enum OperationType {
  Add = 'Add',
  Remove = 'Remove'
};

@Component({
  selector: 'app-customer-quote-details-place-order-step',
  templateUrl: './customer-quote-details-place-order-step.component.html',
  styleUrls: ['./customer-quote-details-place-order-step.component.scss']
})

export class CustomerQuoteDetailsPlaceOrderStepComponent extends BasePermission {
  private quoteUuid: string;
  public user = null;
  isLoading: boolean;
  quote: any;

  cpaasDataUpdateModalRef = null;

  termsAndConditions = [];
  termAccepted = false;
  siteProfile = null;
  po_number = '';
  customer_reference = '';
  debounceTimer = null;
  quoteUpdateError = null;
  feRequiredFieldErrors = {
    po_number: "",
    customer_reference: "",
    term_and_condition: "",
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: ""
  }
  hasCpaas = null;
  isCpassDataAdded = false;
  cPassData = {
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: "",
  };

  errors = null;
  contactAccountTypesCustomers = [];
  selectedContactAccountTypesCustomer = null;
  accountTypes = [];
  creditLimit = 0.00;
  helpLink = ''
  public restrictExportId = ExportId;

  @ViewChild('creditLimitModal') creditLimitModal: any;
  constructor(
    private modalService: NgbModal,
    private quoteDetailsService: QuoteDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private storageService: StorageService,
    private customerService: CustomerService,
    public store: Store<IAppState>,
    private translocoService: TranslocoService,
    private sanitizer: DomSanitizer,
    private utilService: UtilityService,
    private accountTypeService: AccountTypeService,
    private cartService : CartService,


  ) {
    super(store);

    this.quoteUuid = this.route.snapshot.params.quote_uuid;
    this.user = this.storageService.getCurrentUser();
    this.helpLink = !this.restrictExportId?.includes(this.siteProfile?.export_id) ? 'mailto:kpnwebshop@kpn.com' : 'https://ykpn.facilitor.nl/';

  }
  get eQuoteStatus() { return EQuoteStatus; }
  get eAddressPrecision() {
    return EAddressPrecision;
  }


  ngOnInit() {
    if (this.quoteUuid) {
      this.getQuoteDetails();
    }
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });

  }
  getQuoteDetails() {
    this.isLoading = true;
    this.quoteDetailsService.getQuote(this.quoteUuid).subscribe(
      (res) => {
        this.quote = res.data;
        this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
        if (this.hasCpaas) {
          this.cPassData = {
            cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
            cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
            cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
          }
          this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
        };
        this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
        this.getQuoteContactDetails();
        if (res.data.status === 'approved' || res.data.status === 'new' && !this.termsAndConditions?.length) {
          this.getQuoteTermsAndConditions();
        }
        if (this.quote?.is_contact_account_type_required) {
          if (this.hasViewContactAccountTypeCustomer) {
            this.getContactAccountTypesCustomers();
          } else {
            if (this.quote?.contact_account_types?.length) {
              this.getPreviouslySelectedAccountTypes();
            }else {
              this.getAccountTypes();
            }
          }
        }
      },
      (err) => {
        this.isLoading = false;
        console.log("err: ", err);
      }
    );
  }
  getContactAccountTypesCustomers() {
    this.accountTypeService.getContactAccountTypesCustomers().subscribe(res => {
      this.contactAccountTypesCustomers = res?.data?.results ?? [];
      if (this.quote?.contact_account_types?.length) {
        const existingFirstAccountType = this.quote?.contact_account_types[0];
        for (let i = 0; i < this.contactAccountTypesCustomers.length; i++) {
          const client = this.contactAccountTypesCustomers[i];
          if (client?.account_types?.find(at => at.id === existingFirstAccountType)) {
            this.selectedContactAccountTypesCustomer = client?.uuid;
            this.accountTypes = client?.account_types?.map(item => ({ ...item, checked: true }));
            this.formatAccountTypeData(this.quote);
            break;
          }
        }
      }
      // this.isLoading = true;
      // let paramsString = `page=1`;

      // this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      //   const allAccountTypes = res?.data?.results ?? [];
      //   this.accountTypes = allAccountTypes.filter(item => {
      //     if (this?.quote?.contact_account_types?.find(at=> at === item.id)) {
      //       return item;
      //     }
      //   }).map(item => ({ ...item, checked: true }));
      // }, error => {
      //   this.isLoading = false;
      // })
      // if (existingSelectedContactAccountTypesCustomer) {
      //   this.selectedContactAccountTypesCustomer = this.contactAccountTypesCustomers.find(item => item?.uuid === existingSelectedContactAccountTypesCustomer)?.uuid || null;

      //   this.onContactAccountTypesCustomerChange(existingSelectedContactAccountTypesCustomer);
      // }
    }, error => {
    })
  }
  getAccountTypes(contactAccountTypeCustomerUUID = null) {
    this.isLoading = true;
    let paramsString = `page=1`;
    if (contactAccountTypeCustomerUUID) {
      paramsString = `page=1&contact_account_type_customer_uuid=${contactAccountTypeCustomerUUID}`;
    }
    this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      this.accountTypes = res?.data?.results?.map(item => ({ ...item, checked: true }));
      this.formatAccountTypeData(this.quote);
      this.isLoading = false;
      let accountTypeIds = this.accountTypes.map(item => item.id);
      if (this.hasViewContactAccountTypeCustomer && this.quote?.is_contact_account_type_required) {
        this.updateAccountTypeInQuote(accountTypeIds);

        // if (!this.arraysHaveSameElements(accountTypeIds, this.quote?.contact_account_types)) {
        //   this.updateAccountTypeInQuote(accountTypeIds);
        // }
      } else if (!this.hasViewContactAccountTypeCustomer && this.quote?.is_contact_account_type_required) {
        this.updateAccountTypeInQuote(accountTypeIds);
      }

    }, error => {
      this.isLoading = false;
    })
  }
  getPreviouslySelectedAccountTypes() {
    this.isLoading = true;
    let paramsString = `page=1`;
    this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      this.accountTypes = res?.data?.results?.map(item => ({ ...item, checked: true }));
      this.formatAccountTypeData(this.quote);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    })
  }
  arraysHaveSameElements(arr1 = [], arr2 = []) {
    return arr1.length === arr2.length && arr1.sort().every((value, index) => value === arr2.sort()[index]);
  }
  onContactAccountTypesCustomerChange(event) {
    if (event) {
      this.getAccountTypes(event);
    } else {
      this.accountTypes = [];
      this.updateAccountTypeInQuote([]);
    }
  }

  updateAccountTypeInQuote(ids=[]){
    this.isLoading = true;
    this.accountTypeService.updateQuoteAccountTypes(this.quote?.uuid, { contact_account_types: ids }).subscribe(res => {
      this.formatAccountTypeData(res?.data);
      this.toastrService.success(``, 'Success', {
        timeOut: 2000
      });
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.toastrService.error(error?.error?.message || error?.error?.error);
    })
  }

  addOrRemoveAccountTypeToQuote(obj, type) {
    let ids = [];
    if (type == OperationType.Add) {

      ids = this.accountTypes.filter(item => item?.checked).map(item => item.id);
      ids.push(obj?.id);

    } else {
      ids = this.accountTypes.filter(item => item?.checked).filter(item => item.id !== obj?.id).map(item => item.id);
    }
    this.updateAccountTypeInQuote(ids);

  }
  formatAccountTypeData(data) {
    this.accountTypes = this.accountTypes.map(item => {
      const isChecked = data?.contact_account_types?.find(contactAccountTypeId => contactAccountTypeId === item?.id);
      return {
        ...item,
        checked: isChecked ? true : false
      };
    })
  }

  getQuoteContactDetails() {
    this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
      this.isLoading = false;
      this.quoteDetailsService.currentCustomerQuoteDetailsContactInfo$.next(res.data);
      this.po_number = this.quote?.po_number || '';
      this.customer_reference = this.quote?.customer_reference || '';
      this.creditLimit = res?.data?.credit_limit || 0.00;

    }, error => {
      this.isLoading = false;
      console.log('error: ', error);

    })
  }

  async updateQuoteFields(payload = {}, message: string) {
    return this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, payload).toPromise().then(res => {
      this.toastrService.success(`${message} updated`, 'Success');
      this.quote = res.data;
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${message} updated failed`, 'Error');
      return false;
    });
  }

  getQuoteTermsAndConditions() {
    this.quoteDetailsService.getQuoteTermsAndConditions(this.quoteUuid).subscribe(res => {
      this.termsAndConditions = res?.data?.results?.map(item => {
        return {
          ...item,
          description: this.getSafeHtml(item?.description?.replace(/\/A/gi, '\/a') || item?.name)
        }
      });
      this.formatTnCData(this.quote?.lines, this.termsAndConditions);
    })
  }
  getSafeHtml(htmlContent): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }
  formatTnCData(quoteLines = [], termAndConditions = []) {
    this.termsAndConditions = termAndConditions?.map(item => {
      return { ...item, checked: this.getCheckedValueInQuote(quoteLines, item.code) };
    });
    this.checkAllTermAccepted();
  }
  getCheckedValueInQuote(quote_lines, code) {
    return quote_lines.every(line => {
      return line?.quotation_line_extra?.extra_info?.terms_and_conditions?.[code];
    });
  }
  checkAllTermAccepted() {
    this.termAccepted = !this.termsAndConditions?.length ? true : this.termsAndConditions.every(item => item.checked);
  }
  onChangeTermAndCondition(term) {
    this.isLoading = true;
    const payload = {
      code: term?.code,
      is_accepted: term?.checked
    };
    this.quoteDetailsService.updateQuoteTermsAndConditions(this.quoteUuid, payload).subscribe(response => {
      this.formatTnCData(response?.data?.lines, this.termsAndConditions);
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      term.checked = !term.checked;
      this.checkAllTermAccepted();
    });

  }
  onChangeQuotePoNumber(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.po_number = '';
    }
  }
  // updateQuotePoReference(payload) {
  //   this.quoteUpdateError = null;
  //   this.isLoading = true;
  //   this.quoteDetailsService.updateQuoteWithPoReference(payload).subscribe(
  //     (res) => {
  //       this.isLoading = false;
  //       this.quote = res.data;
  //       this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTATION_SUCCESSFULLY_UPDATED')}`);
  //     },
  //     (error) => {
  //       this.isLoading = false;
  //       this.quoteUpdateError = error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
  //       this.toastrService.error(this.quoteUpdateError, 'Error');
  //     }
  //   );
  // }
  async updateQuotePoReference(payload) {
    this.quoteUpdateError = null;
    this.isLoading = true;
    return this.quoteDetailsService.updateQuoteWithPoReference(payload).toPromise().then(res => {
      this.quote = res.data;
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.quoteUpdateError = error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
      this.toastrService.error(this.quoteUpdateError, 'Error');
      return false;
    });
  }
  onChangeQuoteCustomerReference(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.customer_reference = '';
    }
  }
  validateEmail(emailInput = ''): boolean {

    // Regular expression for validating an email address
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Check if the email matches the regex pattern
    if (emailRegex.test(emailInput)) {
      return true;
    } else {
      return false;
    }
  }
  onCpassLastNameChange(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.cpaas_user_last_name = '';
    }
  }
  onCpassFirstNameChange(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.cpaas_user_first_name = '';
    }
  }
  onCpassEmailChange(event) {
    const value = event.target.value;
    if (value) {
      if (!this.validateEmail(value)) {
        this.feRequiredFieldErrors.cpaas_user_email = 'Ongeldig e-mailadres';
      } else {
        this.feRequiredFieldErrors.cpaas_user_email = '';
      }
    }
  }

  async onPlaceOrder() {
    if (this.siteProfile?.is_po_number_required && !this.po_number) {
      this.feRequiredFieldErrors.po_number = 'PO-nummer is verplicht';
    }
    if (!this.customer_reference) {
      this.feRequiredFieldErrors.customer_reference = 'Klantreferentie is verplicht';
    }
    if (!this.termAccepted) {
      this.feRequiredFieldErrors.term_and_condition = 'Algemene voorwaarden zijn verplicht';
      this.toastrService.error('Algemene voorwaarden zijn verplicht', 'Error');
    }
    if (this.hasCpaas && !this.isCpassDataAdded) {
      if (!this.cPassData.cpaas_user_email) {
        this.feRequiredFieldErrors.cpaas_user_email = 'E-mailadres is verplicht';
      }
      if (this.cPassData.cpaas_user_email && !this.validateEmail(this.cPassData.cpaas_user_email)) {
        this.feRequiredFieldErrors.cpaas_user_email = 'Ongeldig e-mailadres';
      }
      if (!this.cPassData.cpaas_user_first_name) {
        this.feRequiredFieldErrors.cpaas_user_first_name = 'Voornaam is verplicht';
      }
      if (!this.cPassData.cpaas_user_last_name) {
        this.feRequiredFieldErrors.cpaas_user_last_name = 'Achternaam is verplicht';
      }
      if (!this.cPassData.cpaas_user_email || !this.cPassData.cpaas_user_first_name || !this.cPassData.cpaas_user_last_name) {
        return;
      }
    }
    if ((this.siteProfile?.is_po_number_required && !this.po_number) || !this.customer_reference || !this.termAccepted || (this.hasCpaas ? (!this.isCpassDataAdded && (!this.cPassData.cpaas_user_email || !this.validateEmail(this.cPassData.cpaas_user_email) || !this.cPassData.cpaas_user_first_name || !this.cPassData.cpaas_user_last_name)) : false)) {

      return;
    }

    const payload = {
      uuid: this.quote.uuid,
      po_number: this.po_number,
      customer_reference: this.customer_reference
    }
    if (! await this.updateQuotePoReference(payload)) {
      this.isLoading = false;
      return;
    }
    if (this.hasCpaas && !this.isCpassDataAdded) {
      if (! await this.saveCpassData(this.cPassData)) {
        this.isLoading = false;
        return;
      }
    }
    if (this.creditLimit < this.quote?.total_amount_ex_vat && this.creditLimit != 0 ) {
      this.modalService.open(this.creditLimitModal, {
       ariaLabelledBy: 'modal-basic-title',
       centered: true,
       modalDialogClass: 'tri-modal-890 modal-runded',
       size: 'md',
       scrollable: true,
       backdrop: 'static',
      });
      return;
     }
    this.createOrder();

  }
   createOrder () {
    const payload = {
      quote_uuid: this.quote?.uuid
    };
    this.isLoading = true;
    this.cartService.createOrderFromQuote(payload).subscribe((res) => {
      this.isLoading = false;
      this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.ORDER_CREATION_SUCCESS')}`);
      this.router.navigate(['/customer/quote-details-final', res?.data?.order?.reference],  { queryParams: { order_date: res?.data?.order?.original_order_date } });
    }, error => {
      this.isLoading = false;
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.ORDER_CREATION_FAILED')}`);

    });
  }

  async saveCpassData(payload) {
    this.isLoading = true;
    return this.quoteDetailsService.updateCpaasUserInfo(this.quoteUuid, payload).toPromise().then(res => {
      this.isLoading = false;
      this.quote = res.data;
      this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
      this.setCpassData();
      if (this.cpaasDataUpdateModalRef) {
        this.cpaasDataUpdateModalRef.close();
      }
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`);
      return false;
    });
  }
  updateCpassDataFromChild(quote) {
    this.quote = quote;
    this.setCpassData();
  }
  setCpassData() {
    this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
    if (this.hasCpaas) {
      this.cPassData = {
        cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
        cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
        cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
      }
      this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
    }

  }



  addCpaasForm(cpaasMainUserModal) {
    this.modalService.open(cpaasMainUserModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-595 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });


    // this.result.then((result) => {
    //   this.selectedQuoteLine = null;
    // }).catch(e => {

    // });
  }




  navigateToQuoteDetails() {
    this.router.navigate(['/customer/quote-details/', `${this.quote.uuid}`]);
  }


  openQuoteDetailsSummeryModal(openQuoteDetailsSummeryModal) {
    this.modalService.open(openQuoteDetailsSummeryModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      modalDialogClass: 'tri-modal-1124 modal-dialog-scrollable  modal-runded',
      scrollable: true,
      backdrop: 'static',
    });
  }
}
