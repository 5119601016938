import * as CatalogActions from '../actions/catalog.actions';

export function catalogReducer(state: any, action: CatalogActions.CatalogActionsList) {
    switch (action.type) {
        case CatalogActions.ECatalogActions.CATALOG_LOAD_SUCCESS:
            return action.catalogs;
        case CatalogActions.ECatalogActions.CLEAR_CATALOG:
            return [];
        default:
            return state;
    }
}

export function selectedCatalogAndProductGroupReducer(state: any, action: CatalogActions.SelectCatalogAndProductGroup) {
    switch (action.type) {
        case CatalogActions.ECatalogActions.SELECT_CATALOG_AND_PRODUCT_GROUP:
            return action.obj;
        default:
            return state;
    }
}
