import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';
import { StorageService } from 'src/app/shared/storage.service';

@Injectable({
    providedIn: 'root'
})
export class MyAccountService {
    constructor(
        private apiBaseService: ApiBaseService,
        private storageService: StorageService
    ) { }

    userInfo(): {contact_uuid, contact_person_uuid} {
        const user = this.storageService.getCurrentUser();
        return {
            // tslint:disable-next-line:object-literal-key-quotes
            'contact_uuid': user?.contact?.uuid,
            // tslint:disable-next-line:object-literal-key-quotes
            'contact_person_uuid': user?.contact_person?.uuid,
        };
    }

    getUserInfo() {
        if (this.userInfo().contact_person_uuid && this.userInfo().contact_uuid) {
            return this.apiBaseService.get(`contacts/${this.userInfo().contact_uuid}/persons/${this.userInfo().contact_person_uuid}`);
        }
        return null;
    }

    updateUserInfo(payload) {
        if (this.userInfo().contact_person_uuid && this.userInfo().contact_uuid) {
            // tslint:disable-next-line:max-line-length
            return this.apiBaseService.put(`contacts/contacts/${this.userInfo().contact_uuid}/contact-persons/${this.userInfo().contact_person_uuid}`, payload);
        }
        return null;
    }

    updatePassword(payload) {
        return this.apiBaseService.post(`accounts/change-password`, payload);
    }

    getCatalogs() {
        return this.apiBaseService.get('search/catalogs/product-groups');
    }
}
