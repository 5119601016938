import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormControl, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { MyAccountService } from './my-account.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from 'src/app/shared/services/utility.service';


// export function passwordMatchValidator(password: string, confirmPassword: string): ValidatorFn {
//   return (formGroup: AbstractControl): ValidationErrors | null => {
//     const passwordControl = formGroup.get(password);
//     const confirmPasswordControl = formGroup.get(confirmPassword);

//     if (!passwordControl || !confirmPasswordControl) {
//       return null;
//     }

//     const error = passwordControl.value !== confirmPasswordControl.value ? { passwordsMismatch: true } : null;

//     confirmPasswordControl.setErrors(error);

//     return error;
//   };
// }

// export function passwordValidator(): ValidatorFn {
//   return (control: AbstractControl): ValidationErrors | null => {
//     const value = control.value;

//     if (!value) {
//       return null;
//     }

//     const hasNumber = /[0-9]/.test(value);
//     const hasUpperCase = /[A-Z]/.test(value);
//     const hasLowerCase = /[a-z]/.test(value);
//     const hasSpecialCharacter = /[!@#$%^&*(),.?":{}|<>]/.test(value);
//     const isValidLength = value.length >= 10;

//     if (!isValidLength) {
//       return { length: true }
//     } else if (!hasNumber) {
//       return { numberError: true }
//     } else if (!hasUpperCase) {
//       return { upperCaseError: true }
//     } else if (!hasLowerCase) {
//       return { lowerCaseError: true }
//     } else if (!hasSpecialCharacter) {
//       return { specialCharacterError: true }
//     } else {
//       return null
//     }
//   };
// }


@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent extends BasePermission implements OnInit {

  genderList: any = [
    { name: 'Male', code: 'M' },
    { name: 'Female', code: 'F' },
    { name: 'Other', code: 'X' },
  ];

  designationTypeList: any = [
    { name: 'Manager', code: 'manager' },
    { name: 'Sales Person', code: 'sales_person' },
    { name: 'Assistant', code: 'assistant' }
  ];

  selectedDesignation: any;
  shippingForm: UntypedFormGroup;
  userInfo: any;
  basicForm: UntypedFormGroup;
  passwordForm: UntypedFormGroup;
  username: any;
  formDisable: boolean;
  tempFormData: any;
  errors: any;
  pwdErrors: any;
  seePwd = 'password';
  seeOldPwd = 'password';
  seeCnfrmPwd = 'password';
  catalogList: Array<any> = [];
  defaultCatalogCode: string;
  isUpdating = false;
  isCatalogChanged = false;

  constructor(
    private fb: UntypedFormBuilder,
    private myAccountService: MyAccountService,
    private toastrService: ToastrService,
    public store: Store<IAppState>,
    public translocoService: TranslocoService,
    public utilityService: UtilityService

  ) {
    super(store);
  }

  ngOnInit() {
    this.formDisable = true;
    this.initializeForm();
    this.getUserInfo();
    this.getCatalogList();
  }

  initializeForm() {
    this.basicForm = this.fb.group({
      birth_name: '',
      email: '',
      first_name: '',
      gender: '',
      initials: '',
      last_name: '',
      mobile_phone_number: '',
      surname_prefix: '',
    });

    this.setPasswordForm();
  }

  setPasswordForm() {
    this.errors = {};
    this.passwordForm = this.fb.group({
      old_password: new FormControl<string>('', [Validators.required]),
      new_password: new FormControl<string>('', [Validators.required, this.utilityService.passwordValidator()]),
      confirm_password: new FormControl<string>('', [Validators.required]),
    },
      { validator: this.utilityService.passwordMatchValidator('new_password', 'confirm_password') });
  }

  getUserInfo() {
    this.myAccountService.getUserInfo().subscribe((response) => {
      if (response?.data) {
        this.username = response?.data?.username;
        this.defaultCatalogCode = response?.data?.catalog_code;
        this.tempFormData = response?.data;
        this.setForm(response?.data);
      }
    }, () => {
    });
  }

  setForm(response) {
    this.basicForm = this.fb.group({
      birth_name: response?.birth_name,
      email: response?.email,
      first_name: response?.first_name,
      gender: 'X',
      initials: response?.initials,
      last_name: response?.last_name,
      mobile_phone_number: response?.mobile_phone_number,
      surname_prefix: response?.surname_prefix,
    });

    // tslint:disable-next-line:max-line-length
    this.selectedDesignation = this.designationTypeList.find(x => x?.code === response?.designation_type || x?.name === response?.designation_type);
  }

  openEditForm() {
    this.formDisable = !this.formDisable;
    if (this.formDisable) {
      this.errors = {};
      this.setForm(this.tempFormData);
    }
  }

  updateProfile() {
    if (this.basicForm?.touched) {
      this.errors = {};
      const payload = {
        contact_person: {
          ...this.basicForm.value,
        }
      };

      this.myAccountService.updateUserInfo(payload).subscribe((response) => {
        if (response?.data?.contact_persons) {
          this.showToaster(`${this.translocoService.translate('ACCOUNT.GENERAL_INFORMATION_SUCCESSFULLY_UPDATED')}!`);
          this.formDisable = true;
          this.setForm(response?.data?.contact_persons[0]);
        }
      }, (error) => {
        this.handleError(error?.error?.error);
      });
    }
  }

  updatePassword() {
    if (this.passwordForm?.touched) {
      this.pwdErrors = {};
      if (this.passwordForm.value?.new_password !== this.passwordForm.value?.confirm_password) {
        this.pwdErrors.confirm_pwd = `${this.translocoService.translate('ACCOUNT.PASSWORD_MISMATCH')}.`;
        return;
      }
      const payload = {
        old_password: this.passwordForm.value?.old_password,
        new_password: this.passwordForm.value?.new_password,
      };
      this.myAccountService.updatePassword(payload).subscribe((response) => {
        this.formDisable = true;
        this.setPasswordForm();
        this.showToaster(response?.data?.message);

      }, (error) => {
        if (error?.error?.error?.old_password) {
          this.pwdErrors.old_password = error?.error?.error?.old_password[0];
        }
        if (error?.error?.error?.non_field_errors) {
          this.pwdErrors.old_password = error?.error?.error?.non_field_errors[0];
        }
      });
    }
  }

  getCatalogList() {
    this.myAccountService.getCatalogs().subscribe(
      (res) => {
        this.catalogList.push(...res?.data?.results);
        if (!this.defaultCatalogCode) {
          this.defaultCatalogCode = this.catalogList.length ? this.catalogList[0]?.code : null;
          this.isCatalogChanged = true;
        }
      },
      (error) => {
        console.log('error: ', error);
      }
    );
  }

  checkModelChanged(code) {
    this.isCatalogChanged = !(code !== this.defaultCatalogCode);
  }

  setDefaultCatalog() {
    this.isUpdating = true;
    this.errors = {};
    const payload = {
      contact_person: {
        catalog_code: this.defaultCatalogCode,
      }
    };

    this.myAccountService.updateUserInfo(payload).subscribe((response) => {
      if (response?.data?.contact_persons) {
        this.showToaster(`${this.translocoService.translate('ACCOUNT.DEFAULT_CATALOG_UPDATED')}!`);
      }
      this.isCatalogChanged = false;
      this.isUpdating = false;
    }, (error) => {
      this.isUpdating = false;
      this.isCatalogChanged = false;
      this.handleError(error?.error?.error);
    });
  }

  handleError(error: any) {
    this.errors = {};
    if (error?.contact_person) {
      this.errors.initials = error?.contact_person?.initials ? (error?.contact_person?.initials?.message || error?.contact_person?.initials[0]) : null;
      // tslint:disable-next-line:max-line-length
      this.errors.mobile_phone_number = error?.contact_person?.mobile_phone_number ? (error?.contact_person?.mobile_phone_number?.message || error?.contact_person?.mobile_phone_number[0]) : null;
      this.errors.last_name = error?.contact_person?.last_name ? error?.contact_person?.last_name[0] : null;
      this.errors.gender = error?.contact_person?.gender ? error?.contact_person?.gender[0] : null;
    }
  }

  showToaster(msg: string) {
    this.toastrService.success(`${msg}`, 'Succes', {
      timeOut: 2000
    });
  }

  checkForm(baseForm: UntypedFormGroup, pwdForm: UntypedFormGroup) {
    if (baseForm.touched) {
      return false;
    }
    if (pwdForm.touched) {
      return false;
    }
    return true;
  }
}
