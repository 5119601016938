<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <form [formGroup]="cartImportForm">
        <div class="customer-search kpn-card bg-kpn-light mb-4">
          <div class="mb-3">
            <h4 class="title-4 green-text" i18n="@@arm.order-status.serc">
              {{'IMPORT_ORDER.IMPORT_ORDER' | transloco }}
            </h4>
          </div>
          <div class="row align-items-center">
            <div class="col-md-5 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num"></label>
                <input type="file"
                  accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                  class="form-control" formControlName="source_file" (change)="onFileChange($event)"
                  [readonly]="isImporting">
              </div>
            </div>
            <div class="col-md-2 col-6 col-gap-reduce">
              <button (click)="importCart()" [disabled]='isImporting || !cartImportForm.valid || !cartImportForm.dirty'
                class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                <span *ngIf="!isImporting; else showSpinner">
                  {{'IMPORT_ORDER.IMPORT' | transloco }}
                </span>
                <ng-template #showSpinner>
                  <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                </ng-template>
              </button>
            </div>
          </div>
          <div class="row align-items-center" *ngIf="importError">
            <div class="col-md-10 col-10 col-gap-reduce">
              <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                <p>
                  <span class="kphicon-uniE952"></span>
                  {{importError}}
                </p>
                <button class="kphicon-uniE926"></button>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div class="bg-kpn-light box-shadow-sm pl-4" *ngFor="let cart of cartList">
        <div class="new-cart-box p-0 mb-3">
          <div class="item-row-head">
            <div class="row">
              <div class="col">
                <h5><b>{{'IMPORT_ORDER.CUSTOMER' | transloco }}:</b> {{ cart?.contact?.company_name || cart?.contact?.contact_name }}</h5>
              </div>
            </div>
            <div class="row new-cart-thead new-cart-thead-imp-order align-items-center">
              <div class="col">
                <div class="th-product green-text">
                  <i class="kphicon-uniE9A91 mr-3"></i>
                  {{'IMPORT_ORDER.ORDER' | transloco }}
                </div>
              </div>
              <div class="col-auto">
                <div class="row">
                  <div class="col-auto">
                    <label class="t-head-item art-nr"> {{'IMPORT_ORDER.ARTICLE_NUMBER' | transloco }}</label>
                  </div>
                  <div class="col-auto">
                    <label class="t-head-item quantity">{{'IMPORT_ORDER.NUMBER' | transloco }}</label>
                  </div>
                  <div class="col-auto">
                    <label class="t-head-item quantity">{{'IMPORT_ORDER.DELIVERY_OPTION' | transloco }} </label>
                  </div>
                  <div class="col-auto">
                    <label class="t-head-item unit-price">{{'IMPORT_ORDER.UNIT_PRICE' | transloco }}</label>
                  </div>
                  <div class="col-auto">
                    <label class="t-head-item gross-price">{{'IMPORT_ORDER.PRICE' | transloco }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="item-rows item-row-imp-order" *ngFor="let line of cart?.lines; let i = index">
            <div class="row new-cart-tbody">
              <div class="col">
                <div class="td-product">
                  <div class="row">
                    <div class="col-auto">
                      <div class="cart-img-item">
                        <img *ngIf="line?.image" src="{{line?.image}}" alt="">
                        <img *ngIf="!line?.image" src="/assets/images/noimage.png" alt="">
                        <img *ngIf="!line?.image && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                          src="/assets/images/cisco-networking.png" alt="">
                      </div>
                    </div>
                    <div class="col ml-md-0 pl-0">
                      <div class="product-name-details">
                        <!-- <h4 class="name-title mb-0">{{line?.brand}}</h4> -->
                        <h6 class="name-title mb-0 pt-2">{{line?.product_name}}</h6>
                        <p class="att-details" *ngIf="productType?.includes( line?.product_type)">
                          <!-- @Cosmic Blue - @128 GB -->
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="art-nr">
                      <!-- <label class="t-td-item art-nr">Art.nr</label> -->
                      <p class="m-0">{{line?.product_code}}</p>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="quantity d-flex align-items-center mb-2">
                      <!-- <label class="t-head-item quantity">Aantal</label> -->
                      <div class="item-add-remove"
                        *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                        <div class="d-flex align-items-center">
                          <button class="kphicon-uniE972 add-remove-btn dicrement-btn"
                            (click)="decrementQuantity(cart?.uuid, line)" [disabled]="line.quantity === 1"></button>
                          <div class="add-remove-input">
                            <input appPositiveInteger type="number" [(ngModel)]="line.quantity" min="1"
                              (keyup.enter)="onEnter(cart?.uuid, line)">
                          </div>
                          <button class="kphicon-uniE985 add-remove-btn incriment-btn"
                            (click)="incrementQuantity(cart?.uuid, line)"></button>
                        </div>
                      </div>
                      <div *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)"
                        class="ml-4">
                        <a class="text-danger text-decoration-none" href="javascript:void(0)"
                          (click)="deleteCartItem(cart?.uuid,line)">
                          <i class="ui-delete kpn-ui-icon icon-size-md"></i>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto"
                    *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                    <div class="quantity">
                      <h4 class="price-item-new"> {{ line?.delivery_option?.name }}</h4>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="unit-price">
                      <h4 class="price-item-new current-price">{{ line?.price_ex_vat | currencyFormat }}</h4>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="gross-price">
                      <!-- <label class="t-head-item gross-price">Brutoprijs</label> -->
                      <!-- <h4 class="price-item-new prev-price"> {{ line?.total_price | currencyFormat}}</h4> -->
                      <h4 class="price-item-new current-price"> {{ line?.total_price_ex_vat |
                                                currencyFormat}}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row new-cart-tbody" *ngFor="let subLine of line.sub_lines; let j = index">
              <div class="col">
                <div class="td-product">
                  <div class="row">
                    <div class="col-auto">
                      <div class="cart-img-item"></div>
                    </div>
                    <div class="col ml-md-0 pl-0">
                      <div class="product-subline-details">
                        <p class="att-details-name m-0">{{subLine?.product_name}} </p>
                        <!-- <p class="subline-time-to m-0">@Voor 23:00 uur besteld morgen in huis
                                                </p> -->
                        <!-- <a class="line-delete-link" href="javascript:void(0)" (click)="deleteCartItem(line)">Verwijder</a> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-auto">
                <div class="row align-items-center">
                  <div class="col-auto">
                    <div class="total-price">
                      <!-- <label class="t-head-item total-price">Totaal</label> -->
                      <h4 class="price-item-new-sub">{{subLine?.total_price_ex_vat | currencyFormat}}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="new-cart-box new-cart-voucher-box" *ngIf="cart?.lines?.length">
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div class="cart-total-box">
                  <div class="total-price-line">
                    <label>{{'IMPORT_ORDER.TOTAL' | transloco }}</label>
                    <label>{{cart?.total_amount_ex_vat |
                                            currencyFormat}}</label>
                  </div>
                  <div class="total-price-line">
                    <label>{{'IMPORT_ORDER.VAT' | transloco }}</label>
                    <label>{{cart?.total_amount_vat |
                                            currencyFormat}}</label>
                  </div>
                  <div class="total-price-line total-price-line-bold">
                    <label>{{'IMPORT_ORDER.TOTAL' | transloco }}</label>
                    <label>{{cart?.total_amount | currencyFormat}}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="termsAndConditions?.length">
        <div class="col-md-10">
          <h3 class="title-5 mb-1 green-text">{{'IMPORT_ORDER.ACCEPT_TERMS_AND_CONDITIONS' | transloco }} </h3>
        </div>
        <div class="col-md-10 col-10">
          <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
            <label ngbButtonLabel class="checkbox-item d-flex">
              <div class="checkbox-btn checkbox-btn-outline pt-1">
                <input ngbButton type="checkbox" [(ngModel)]="allTermAccepted"
                  (ngModelChange)="onChangeTermAndCondition($event)">
                <span class="checkBoxCheckmark"></span>
              </div>
              <ul>
                <li *ngFor="let item of termsAndConditions">
                  <div class="checkbox-label ml-2 text-left">
                    <small [innerHTML]="item?.description"></small>
                  </div>
                </li>
              </ul>
            </label>
          </div>
        </div>
      </div>
      <div class="bg-kpn-light box-shadow-sm pl-4" *ngIf="cartList?.length">
        <div class="new-cart-box p-0 mb-3">
          <div class="new-cart-box new-cart-voucher-box">
            <div class="row">
              <div class="col-md-8"></div>
              <div class="col-md-4">
                <div class="cart-total-box">
                  <button (click)="submitCart()" [disabled]='isSubmitting || !allTermAccepted || allTermAcceptedLoader'
                    class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                    <div *ngIf="isSubmitting || allTermAcceptedLoader"
                      class="spinner-border spinner-border-sm field-spinner mr-2" role="status">
                    </div>
                    <span>{{'IMPORT_ORDER.MAKE_ORDER' | transloco }} </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>
