import { TranslocoService } from '@ngneat/transloco';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Observable, Subscription, timer } from 'rxjs';
import { ElementRef } from '@angular/core';
import { ChangeContext, Options } from '@angular-slider/ngx-slider';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

import { HomeService } from './home.service';
import { Router, ActivatedRoute } from '@angular/router';
import { SidebarService } from 'src/app/component/sidebar/sidebar.service';
import { CartService } from '../cart/cart.service';
import { StorageService } from 'src/app/shared/storage.service';
import { ToastrService } from 'ngx-toastr';
import { selectACatalogAndProductGroup, selectCatalog } from 'src/app/store/selectors/catalog.selectors';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../app.state';
// tslint:disable-next-line:max-line-length
import { selectChildGroup } from '../../store/selectors/productGroup.selectors';
import { SelectCatalogAndProductGroupAction } from 'src/app/store/actions/catalog.actions';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { selectPermissions } from 'src/app/store/selectors/permission.selectors';
import { BasePermission } from 'src/app/shared/base/base-permission';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends BasePermission implements OnInit, OnDestroy {

  products$: Observable<any>;

  config = {
    itemsPerPage: 0,
    currentPage: 1,
    totalItems: 0
  };

  minValue = 0;
  maxValue = 2500;
  options: Options = {
    floor: 0,
    ceil: 2500,
    step: 50,
    translate: (value: number): string => {
      return '€' + value;
    }
  };

  isLoading = false;
  showPagination = false;

  isViewChanged = true;
  selectedGroupCode = 'hardware';

  searchFocused = false;
  keyword = 'name';
  searchData = [
    {
      id: 1,
      name: 'Emporia CLICKplus, Black'
    },
    {
      id: 2,
      name: 'Apple iPhone 6s Plus'
    },
    {
      id: 3,
      name: 'Apple iPhone 6s Plus 32GB, Silver'
    },
    {
      id: 4,
      name: 'Emporia CLICKplus'
    },
    {
      id: 5,
      name: 'Samsung Galaxy K Zoom, Black'
    },
    {
      id: 6,
      name: 'TelePresence 10 Power Cord - United Kingdom'
    },
  ];

  childItems = [];
  brandItems = [];
  selectedBrands = [];
  selectedAttr = [];
  selectedAttrCode = [];

  configMenu = {
    paddingAtStart: true,
    classname: 'app-inner-sidenav',
    listBackgroundColor: 'rgb(255, 255, 255)',
    fontColor: 'rgb(8, 54, 71)',
    backgroundColor: 'rgb(255, 255, 255)',
    selectedListFontColor: '#0066EE',
    collapseOnSelect: true,
  };

  public viewName: string = null;
  public position: string = null;
  public products: any[] = [];
  public cart = null;
  // tslint:disable-next-line:variable-name
  is_external = false;
  catalogCodeAndProductGroupSubs$: Subscription;
  childGroupSubs$: Subscription;
  catalogSub$: Subscription;
  catalogList: any[] = [];

  catalogCode = null;
  parentGroupCode = null;
  childGroupCode = null;
  filterQueryString = null;
  priceFilterObject = null;
  attrValue: any[] = [];
  searchedProductKeyword = null;
  private pageSize = 30;
  pageLayout = null;
  public siteProfile = null;
  public permissionSubs$: Subscription;
  public brandFilterModalReference: NgbModalRef;
  public systemMessageList = [];
  public currentMessageObj = null;
  private showedSystemMessagesModalSub$: Subscription;

  @ViewChild('systemMessagesModal') systemMessagesModal: any;
  private systemMessageModalRef: NgbModalRef | undefined;
  public showedSystemMessagesModal = false;
  constructor(
    private homeService: HomeService,
    private sideBarService: SidebarService,
    private storageService: StorageService,
    private router: Router,
    private cartService: CartService,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    public productElement: ElementRef,
    public store: Store<IAppState>,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
  ) {
    super(store);
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
    this.getCart();

    this.viewName = this.route.snapshot.queryParams.view || 'list';
    this.position = this.route.snapshot.queryParams.position || null;
    this.viewChanged(this.viewName);
    this.showedSystemMessagesModalSub$ = this.homeService.showedSystemMessagesModal$.subscribe(res => {
      this.showedSystemMessagesModal = res;
    });
  }

  ngOnInit() {
    this.is_external = this.storageService.getCurrentUser()?.is_external;
    // this.getChildItems();
    this.getCatalogList();
    this.permissionSubs$ = this.store.select(selectPermissions).subscribe((permission) => {
      if ((!this.showedSystemMessagesModal) && this.hasSystemMessagesViewPermission) {
        this.getMessages();
        this.homeService.setShowedSystemMessagesModal(true);
      }
    });
    // this.getProducts();
    // this.changeData();
  }

  messageAfterLogin(afterLoginModal) {
    this.modalService.open(afterLoginModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  getChildItems() {
    this.childGroupSubs$ = this.store.select(selectChildGroup).subscribe(list => {
      if (list) {
        this.childItems = list;
      }
    });
  }

  getCatalogList() {
    this.isLoading = true;
    this.catalogSub$ = this.store.select(selectCatalog).subscribe((list) => {
      this.isLoading = false;
      if (list?.length) {
        this.catalogList.push(...list);
      }
    }, error => {
      this.isLoading = false;
    });
  }



  scroll(id) {
    timer(2000).subscribe(val => {
      const el = this.productElement.nativeElement.ownerDocument.getElementById(id);
      if (el) {
        el.scrollIntoView({ behavior: 'smooth' });
      }
    });

  }



  viewChanged(changedBy: string) {
    if (changedBy === 'list') {
      this.isViewChanged = true;
      this.viewName = 'list';
    } else {
      this.isViewChanged = false;
      this.viewName = 'grid';
    }
  }



  getCart() {
    this.cartService.currentCart$.subscribe((res) => {
      this.cart = res;
      if (this.products.length) {
        this.checkProduct();
      }
    }, error => {
      console.log('error: ', error);
    });
  }

  checkProduct() {
    if (this.cart) {
      this.cart.lines.forEach(element => {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.products.length; index++) {
          const product = this.products[index];
          let childProduct = null;
          if (product?.child_products?.length) {
            childProduct = product?.child_products[0]?.code;
          }
          if (product?.code === element?.product_code || childProduct === element?.product_code) {
            product.cartQuantity = element?.quantity;
            break;
          }
        }
      });
    }
  }





  selectEvent(item) {
    // do something with selected item
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }


  changeData() {
    this.sideBarService.home.subscribe(res => {
      if (res) {
        this.selectedGroupCode = res;
        // this.getProducts(res);
      }
    }, err => {
      console.log('err: ', err);
    });
  }

  getProducts(groupCode = 'hardware', pageNumber = 1) {
    this.isLoading = true;
    const catalogAndProductGroupSub$: Observable<any> = this.store.select(selectACatalogAndProductGroup);
    this.catalogCodeAndProductGroupSubs$ = catalogAndProductGroupSub$.subscribe(obj => {
      this.isLoading = false;
      if (obj) {
        this.catalogCode = obj?.catalogCode;
        this.parentGroupCode = obj?.productGroupCode;
        this.pageLayout = obj?.page_layout;
        this.pageSize = (obj.page_layout !== null && obj.page_layout === 'compact') ? 300 : 30;
        this.filterQueryString = null; this.brandItems = []; this.attrValue = []; this.selectedBrands = [];
        this.selectedAttr = []; this.selectedAttrCode = []; this.childItems = obj.productGroupCode ? this.childItems : [];

        this.searchedProductKeyword = obj?.product_search_keyword || null;
        this.getProductsData(this.catalogCode, pageNumber, this.parentGroupCode);
      }
    });
  }

  getProductsData(catalogCode, pageNumber = 1, productGroupCode = null) {
    // if (catalogCode) {
    if (!this.searchedProductKeyword) {
      this.getDynamicSearchItem(catalogCode, productGroupCode);
    }
    this.fetchPaginatedProducts(catalogCode, productGroupCode, pageNumber);
    // }
  }

  fetchPaginatedProducts(catalogCode, productGroupCode, pageNumber) {
    this.isLoading = true;
    this.showPagination = false;
    this.products = [];
    const contactUuid = !this.is_external && this.storageService.getContactUuid() ? this.storageService.getContactUuid() : null;
    this.config = {
      itemsPerPage: 0,
      currentPage: pageNumber,
      totalItems: 0
    };
    // tslint:disable-next-line:max-line-length
    this.homeService.getProducts(catalogCode, productGroupCode, pageNumber, this.pageSize, this.filterQueryString, contactUuid, this.searchedProductKeyword).subscribe(res => {
      if (res.data.count) {
        this.showPagination = true;
        this.config.totalItems = res.data.count;
        this.products = res.data.results.map(obj => ({ ...obj, cartQuantity: 0, showSpinner: false }));
        this.config.itemsPerPage = this.pageSize; // this.config.itemsPerPage ? this.config.itemsPerPage : this.products.length;
        if (this.position) {
          this.scroll(this.position);
        }
        if (this.cart) {
          this.checkProduct();
        }
        this.isLoading = false;
      } else {
        this.products = [];
        timer(3000).subscribe(time => {
          this.isLoading = false;
        });
      }
    }, (err) => {
      this.isLoading = false;
      this.showPagination = false;
      this.router.navigateByUrl('/');
    },
    );
  }

  getDynamicSearchItem(catalogCode, productGroupCode) {
    this.brandItems = [];

    this.homeService.getDynamicSearchItems(catalogCode, productGroupCode).subscribe(res => {
      if (res?.data) {
        this.brandItems = res?.data?.brands.map(obj => {
          return { ...obj, selected: false };
        });
        if (res?.data?.attributes) {
          this.attrValue = [];
          res?.data?.attributes.forEach(element => {
            if (element?.attribute_values.length) {
              this.attrValue.push({
                attrName: element?.attribute_name,
                attrCode: element?.attribute_code,
                attrValue: element?.attribute_values.map(obj => {
                  return { ...obj, selected: false };
                })
              });
            }
          });
        }
      }

    }, (error) => {
      if (error?.error && error?.error?.message) {
        this.toastrService.error(error?.error?.message, 'Error', {
          timeOut: 4000
        });
      }
    });
  }

  onSelectBrand(event) {
    const index = this.selectedBrands.findIndex(obj => {
      return obj === event.target.defaultValue;
    });
    if (event?.target.checked) {
      if (index === -1) {
        this.selectedBrands.push(event.target.defaultValue);
      }
    } else {
      if (index > -1) {
        this.selectedBrands.splice(index, 1);
      }
    }
    this.getFilterProduct();
  }

  onselectAttribute(event, attrCode: string, attrName: string) {
    const index = this.selectedAttr.findIndex(obj => {
      return obj?.value === event.target.defaultValue && obj?.attrCode === attrCode;
    });

    if (event?.target.checked) {
      if (index === -1) {
        this.selectedAttr.push({
          attrCode,
          attrName,
          value: event.target.defaultValue
        });
        this.selectedAttrCode.push(attrCode);
      }
    } else {
      if (index > -1) {
        this.selectedAttr.splice(index, 1);
        const attrIndex = this.selectedAttrCode.indexOf(attrCode);
        if (attrIndex !== -1) {
          this.selectedAttrCode.splice(attrIndex, 1);
        }
      }
    }
    this.getFilterProduct();
  }

  onChangePrice(changeContext: ChangeContext) {
    this.priceFilterObject = changeContext;
    this.getFilterProduct();
  }

  getFilterProduct(callPaginate = true) {
    this.isLoading = true;
    this.showPagination = false;
    this.products = [];
    this.filterQueryString = this.prepareQueryString();
    if (callPaginate) {
      this.fetchPaginatedProducts(this.catalogCode, this.parentGroupCode, 1);
    }
  }

  clearFilter() {
    this.filterQueryString = null;
    this.selectedBrands.forEach(element => {
      const index = this.brandItems.findIndex(obj => {
        return obj.name === element;
      });
      this.brandItems[index].selected = false;
    });
    this.selectedBrands = [];
    this.selectedAttr.forEach(element => {
      const index = this.attrValue.findIndex(obj => obj.attrCode === element.attrCode);
      const attrValue = this.attrValue[index]?.attrValue;
      attrValue.forEach((val, ind) => {
        if (val.selected) {
          this.attrValue[index].attrValue[ind].selected = false;
        }
      });
    });
    this.selectedAttr = [];
    this.selectedAttrCode = [];
    this.fetchPaginatedProducts(this.catalogCode, this.parentGroupCode, 1);
  }

  removeBrandTag(item, index: number) {
    this.selectedBrands.splice(index, 1);
    const ind = this.brandItems.findIndex(obj => {
      return obj.name === item;
    });

    this.brandItems[ind].selected = false;
    this.getFilterProduct();
  }

  removeAttrTag(item, index: number) {
    const attrIndex = this.attrValue.findIndex(obj => obj.attrCode === item.attrCode);
    if (attrIndex > -1) {
      const attrValue = this.attrValue[attrIndex]?.attrValue;
      const ind = attrValue.findIndex(obj => obj.value === item.value);
      if (ind > -1) {
        this.attrValue[attrIndex].attrValue[ind].selected = false;
        this.selectedAttr.splice(index, 1);
        if (this.selectedAttr.findIndex(obj => obj.attrCode === item.attrCode) === -1) {
          this.selectedAttrCode = this.selectedAttrCode.filter(attrCode => attrCode !== item.attrCode);
        }
        this.getFilterProduct();
      }
    }
  }

  removeProductSearchTag() {
    this.searchedProductKeyword = null;
    this.store.dispatch(new SelectCatalogAndProductGroupAction({
      catalogCode: this.catalogCode,
      productGroupCode: this.parentGroupCode,
      page_layout: this.pageLayout,
      product_search_keyword: null,
    }));
    this.getFilterProduct(false);
  }

  onPageClick(event: any) {
    this.position = null;
    this.isLoading = true;
    this.config.currentPage = event;
    this.fetchPaginatedProducts(this.catalogCode, this.parentGroupCode, event);
  }

  removeDuplicates(data) {
    return [...new Set(data)];
  }

  prepareQueryString() {
    let queryString = '';
    if (this.selectedBrands.length) {
      queryString = queryString.concat('&brand='.concat(this.selectedBrands.join()));
    }

    const attrCode = this.removeDuplicates(this.selectedAttrCode);
    this.removeDuplicates(this.selectedAttrCode).forEach(element => {
      // tslint:disable-next-line:max-line-length
      queryString = queryString.concat(`&${element}=${this.selectedAttr.filter(obj => obj.attrCode === element)?.map(obj => obj.value)?.join()}`);
    });

    if (this.priceFilterObject && this.priceFilterObject.value) {
      queryString = queryString.concat('&price_start='.concat(this.priceFilterObject.value));
      queryString = queryString.concat('&price_end='.concat(this.priceFilterObject.highValue));
    }

    return queryString;
  }

  onAddToCart(product: any) {
    product.showSpinner = true;
    const cartId = this.storageService.getCart() || null;

    const cart = {
      market_type_code: 'business',
      lines: [
        {
          product_code: `${product?.child_products?.length ? product?.child_products[0]?.code : product?.code}`,
          product_name: `${product?.child_products?.length ? product?.child_products[0]?.name : product?.name}`,
          product_type: `handset`,
          quantity: product?.order_unit,
          flow_type_code: 'new'
        }
      ]
    };

    const contactPersonUUID = this.storageService.getContactPersonUUID();
    const shippingAddressId = this.storageService.getShippingAddressID;
    if (contactPersonUUID) {
      cart['contact_person_uuid'] = contactPersonUUID;
      cart['shipping_address_id'] = shippingAddressId || null;
    }
    if (!this.cart) {
      this.createCart(cart, product);
    } else {
      this.updateCart(cart.lines[0], product);
    }
  }

  createCart(data, product) {
    this.cartService.createCart(data).subscribe(res => {
      this.cart = res.data;
      this.updateCartStore(this.cart, product);
      product.showSpinner = false;
      this.showToaster();
    }, err => {
      product.showSpinner = false;
      this.showErrorToaster(err?.error?.message);
    });
  }

  updateCart(data, product) {
    this.cartService.updateCart(this.cart.uuid, data).subscribe(res => {
      this.cart = res.data;
      this.updateCartStore(this.cart, product);
      product.showSpinner = false;
      this.showToaster();
    }, err => {
      product.showSpinner = false;
      this.showErrorToaster(err?.error?.message);
    });
  }


  showToaster() {
    this.toastrService.success(`${this.translocoService.translate('BREADCRUMB.THE_SHOPPING_CART_HAS_BEEN_UPDATED')}`, 'Succes', {
      timeOut: 2000
    });
  }

  showErrorToaster(errMsg = null) {
    this.toastrService.error(errMsg || `${this.translocoService.translate('PRODUCT_DETAILS.OPERATION_FAILED')}!`, 'Error', {
      timeOut: 4000
    });
  }


  onProductDetail(product: any, index) {
    this.position = `product-${index}`;
    this.router.navigate(['/product-details', `${product.code}`], { queryParams: { view: this.viewName, position: this.position } });
  }

  incrementQuantity(product) {
    product.cartQuantity = product.cartQuantity + product.order_unit;
    this.updateCartCartItem(product);
  }

  decrementQuantity(product) {
    product.cartQuantity = product.cartQuantity - product.order_unit;
    if (product.cartQuantity < 1) {
      this.deleteCartItem(product);
    } else {
      this.updateCartCartItem(product);
    }
  }

  onEnter(product) {
    if (product.cartQuantity < 0 || isNaN(product.cartQuantity)) {
      return;
    } else if (product.cartQuantity === 0) {
      this.deleteCartItem(product);
    } else if (product.cartQuantity > 0) {
      this.updateCartCartItem(product);
    } else {
      return;
    }

  }

  changeQuantity(event: EventListener, product) {
    if (event === null) {
      product.cartQuantity = '0';
      this.checkWithin2Second(event, product);
    } else if (product.cartQuantity <= 0) {
      this.deleteCartItem(product);
    }
  }

  checkWithin2Second(event, product) {
    timer(3000).subscribe(time => {
      if (product.cartQuantity === '0') {
        this.deleteCartItem(product);
      }
    });
  }

  updateCartCartItem(product) {
    product.showSpinner = true;
    const line = this.findProductInCart(product);
    if (line) {
      const payload = {
        quantity: product.cartQuantity
      };
      this.cartService.updateCartLine(this.cart.uuid, line.uuid, payload).subscribe(res => {
        this.isLoading = false;
        this.cart = res.data;
        this.updateCartStore(this.cart, product);
        product.showSpinner = false;
        this.showToaster();
      }, err => {
        product.cartQuantity = line?.quantity;
        product.showSpinner = false;
        this.showErrorToaster(err?.error?.message);
      });
    } else {
      product.showSpinner = false;
    }
  }

  updateCartStore(cart, product = null, isDelete = false) {
    this.cartService.changeCurrentCart(cart);
    this.storageService.setCart(cart);
    if (product !== null) {
      this.cartService.saveOrderUnitOfCartProducts({ product_code: product?.code, order_unit: product?.order_unit }, isDelete);
    }
  }


  deleteCartItem(product) {
    product.showSpinner = true;
    const line = this.findProductInCart(product);
    if (line) {
      this.cartService.deleteCartItem(this.cart.uuid, line.uuid).subscribe(res => {
        this.cart = res.data;
        this.updateCartStore(this.cart, product, true);
        product.showSpinner = false;
        this.showToaster();
        product.cartQuantity = 0;
      }, err => {
        product.cartQuantity = line?.quantity;
        product.showSpinner = false;
        this.showErrorToaster(err?.error?.message);
      });
    } else {
      product.showSpinner = false;
    }
  }

  findProductInCart(product) {
    const childProductCode = product?.child_products?.length ? product?.child_products[0]?.code : null;
    return this.cart?.lines.find(obj => {
      return obj?.product_code === product?.code || obj?.product_code === childProductCode;
    }) || null;
  }

  getMessages() {
    if (this.storageService.getToken()) {
      this.getSystemMessages();
    }
  }
  getSystemMessages() {
    this.homeService.getSystemMessages().subscribe(res => {
      this.systemMessageList = res?.data?.results?.map((item, index) => ({ ...item, index: index })) ?? [];
      if (this.systemMessageList.length) {
        this.currentMessageObj = this.systemMessageList[0];
        this.openSystemMessageModal();
      }
    }, error => {

    });
  }
  openSystemMessageModal() {
    this.systemMessageModalRef = this.modalService.open(this.systemMessagesModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });
    this.systemMessageModalRef.result.then((result) => {

    }, (reason) => {

    });
  }
  closeSystemMessageModal() {
    this.systemMessageModalRef.close();
    this.storageService.setHasSeenMessage(true);
    this.homeService.setShowedSystemMessagesModal(true);
    setTimeout(() => {
      if (this.systemMessageList.length - 1 > this.currentMessageObj.index) {
        this.currentMessageObj = this.systemMessageList[this.currentMessageObj.index + 1];
        this.openSystemMessageModal();

      }
    }, 100);

  }
  updateSystemMessage() {
    this.homeService.updateSystemMessage(this.currentMessageObj.uuid, { is_dismissed: this.currentMessageObj.is_dismissed }).subscribe(res => {
      this.systemMessageList = this.systemMessageList.map(item => {
        if (item.uuid === this.currentMessageObj.uuid) {
          return { ...res.data, index: item.index };
        }
        return item;
      });
    });

  }
  ngOnDestroy() {
    if (this.catalogCodeAndProductGroupSubs$) {
      this.catalogCodeAndProductGroupSubs$.unsubscribe();
    }
    if (this.childGroupSubs$) {
      this.childGroupSubs$.unsubscribe();
    }
    if (this.catalogSub$) {
      this.catalogSub$.unsubscribe();
    }
    if (this.showedSystemMessagesModalSub$) {
      this.showedSystemMessagesModalSub$.unsubscribe();
    }
    if (this.permissionSubs$) {
      this.permissionSubs$.unsubscribe();
    }

  }
}
