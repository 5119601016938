import { Component, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, timer } from 'rxjs';

import { IAppState } from 'src/app/app.state';
import { CartService } from 'src/app/pages/cart/cart.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { AuthService } from 'src/app/shared/auth.service';
import { StorageService } from 'src/app/shared/storage.service';
import { ClearCatalog } from 'src/app/store/actions/catalog.actions';
import { SignUpService } from './sign-up.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { EAddressPrecision } from 'src/app/shared/enums/order-status.enums';
import { replace } from 'lodash';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {


  submitted = false;
  public customerCreationError = null;
  public beValidationError = null;
  public tokenValidationError = null;
  public invitationResendMsg = null;
  private token = '';
  public isAddressFetching = false;
  public addressSearchError = null;
  public can_overwrite_address = false;
  inputSubject = new Subject<any>();

  customerInformation = new UntypedFormGroup({
    company_name: new UntypedFormControl(''),
    coc_number: new UntypedFormControl(''),
    create_user: new UntypedFormControl(true),
    contact_person: new UntypedFormGroup({
      initials: new UntypedFormControl('', Validators.required),
      surname_prefix: new UntypedFormControl(''),
      last_name: new UntypedFormControl('', Validators.required),
      birth_name_prefix: new UntypedFormControl(''),
      birth_name: new UntypedFormControl(''),
      gender: new UntypedFormControl('X'),
      email: new UntypedFormControl({ value: '', disabled: true }, [Validators.required, Validators.email]),
      password: new UntypedFormControl('', [Validators.required, this.utilService.passwordValidator()]),
      password_2: new UntypedFormControl('', [Validators.required]),
      mobile_phone_number: new UntypedFormControl('', [
        Validators.minLength(10), Validators.maxLength(12)]),
      bank_account_number: new UntypedFormControl(''),
    },
      { validators: this.utilService.passwordMatchValidator('password', 'password_2') }
    ),
    contact_address: new UntypedFormGroup({
      house_number: new UntypedFormControl('', Validators.required),
      house_number_extension: new UntypedFormControl(''),
      street: new UntypedFormControl('', Validators.required),
      city: new UntypedFormControl('', Validators.required),
      zip_code: new UntypedFormControl('', Validators.required),
    })
  }, this.pwdMatchValidator);

  public country = [];
  public NL_CODE = 'NL';
  public selectedCountry = this.NL_CODE;
  keyword = 'value';
  searchFocused = false;
  addressList: any[] = [];
  isLoading = false;
  foreignAddressSearchError = null;

  searchValue = '';
  addressSearchValue = '';
  @ViewChild('auto') auto;
  showReSend = false;
  signUpSuccessMessage = '';


  constructor(
    // private customerService: CustomerService,
    private router: Router,
    private activateRouter: ActivatedRoute,
    private signUpService: SignUpService,
    private toastrService: ToastrService,
    private authService: AuthService,
    private storageService: StorageService,
    private cartService: CartService,
    private route: ActivatedRoute,
    public store: Store<IAppState>,
    private activateRoute: ActivatedRoute,
    private customerService: CustomerService,
    private addressService: AddressService,
    private utilService: UtilityService,
    private translocoService: TranslocoService

  ) {

    this.activateRouter.queryParams.subscribe({
      next: (params: any) => {
        this.token = params?.token || '';
        if (!this.token) {
          this.backToLogin();
        } else {
          this.validateToken();
        }
      }
    });
  }

  get eAddressPrecision() {
    return EAddressPrecision;
  }


  pwdMatchValidator(frm: UntypedFormGroup) {
    const mismatch = frm.get('contact_person.password')?.value === frm.get('contact_person.password_2')?.value ? null : { mismatch: true };
    if (mismatch) {
      frm.get('contact_person.password_2').setErrors(mismatch);
    }
    return mismatch;
  }

  onLogOut() {
    this.authService.logout()
      .subscribe(response => {
        this.storageService.removeAllStorage();
        this.cartService.changeCurrentCart(null);
        this.store.dispatch(new ClearCatalog(null));
      },
        error => {
          console.log('error: ', error);
        });

  }


  validateToken() {
    this.tokenValidationError = null;
    this.invitationResendMsg = '';
    const token = this.storageService.getCurrentUser();
    if (token) {
      this.onLogOut();
    }
    this.signUpService.validateToken(this.token).subscribe(response => {
      if (response?.data?.is_user_exist) {
        this.backToLogin();
      } else {
        this.can_overwrite_address = response?.data?.can_overwrite_address;
        this.customerInformation.patchValue({
          contact_person: { email: response.data?.email, disabled: false }
        });
        if (!response.data?.email) {
          this.customerInformation.get('contact_person.email').enable();
        }
        if (response?.data?.shipping_address) {
          const address = response?.data?.shipping_address;
          this.customerInformation.patchValue({
            contact_address: {
              ...address
            }
          });
          this.selectedCountry = address?.country;
          this.checkSearchedAddressField(address, true);
        }
        this.getCountry();
      }

    }, error => {
      this.tokenValidationError = error?.error?.message || error?.error?.error?.code || error?.error?.error;
      if (this.tokenValidationError === 'User invitation already accepted') {
        this.tokenValidationError = `${this.translocoService.translate('SIGN_UP.ALREADY_HAVE_ACCOUNT')} <a href=\"/login?invited=true\">${this.translocoService.translate('SIGN_UP.LOGIN')}</a> ${this.translocoService.translate('SIGN_UP.PAGE_AND_SIGN_IN')}`;
      }

      if (['USER_INVITATION_EXPIRED']?.includes(error?.error?.code || error?.error?.error?.code || error?.error?.error?.error || error?.error?.error || error?.error)) {
        this.showReSend = true;
      }

    });
  }

  getCountry() {
    this.addressService.getCountry().subscribe(res => {
      this.country = res?.data?.results;
    });
  }

  onSelectCountry(event) {
    this.resetFormValue();
    this.addressSearchValue = '';
    this.resetAddressFound(event?.country_cod);
  }


  onEditAddressSearch() {
    if (this.customerInformation.get('contact_address.zip_code').value &&
      this.customerInformation.get('contact_address.house_number').value) {
      this.addressSearchError = null;
      timer(400).pipe(
      ).subscribe(value => {
        this.onSearchAddress();
      });
    }
  }


  resetFormValue() {
    this.beValidationError = null;
    this.customerInformation.get('contact_address.street').setValue('');
    this.customerInformation.get('contact_address.city').setValue('');
    this.customerInformation.get('contact_address.zip_code').setValue('');
    this.customerInformation.get('contact_address.house_number').setValue('');
    this.customerInformation.get('contact_address.house_number_extension').setValue('');
  }

  resetAddressFound(country = this.selectedCountry) {
    this.customerInformation.get('contact_address.street').disable();
    this.customerInformation.get('contact_address.city').disable();
    if (country === this.NL_CODE) {
      this.customerInformation.get('contact_address.street').enable();
      this.customerInformation.get('contact_address.city').enable();
      this.customerInformation.get('contact_address.zip_code').enable();
      this.customerInformation.get('contact_address.house_number').enable();
      this.customerInformation.get('contact_address.house_number_extension').enable();
    } else {
      this.customerInformation.get('contact_address.zip_code').disable();
      this.customerInformation.get('contact_address.house_number').disable();
      this.customerInformation.get('contact_address.house_number_extension').disable();
    }
  }

  checkSearchedAddressField(address, check_can_overwrite_address = false) {
    this.customerInformation.get('contact_address.street').enable();
    this.customerInformation.get('contact_address.city').enable();
    this.customerInformation.get('contact_address.zip_code').enable();
    this.customerInformation.get('contact_address.house_number').enable();
    this.customerInformation.get('contact_address.house_number_extension').disable();

    if (check_can_overwrite_address && !this.can_overwrite_address) {
      this.disabledAddress(address);
    } else if (check_can_overwrite_address && this.can_overwrite_address) {
      this.customerInformation.get('contact_address.house_number_extension').enable();
      return;
    } else {
      this.customerInformation.get('contact_address.house_number_extension').enable();
      this.disabledAddress(address);
    }
  }


  disabledAddress(address) {
    if (address?.street) {
      this.customerInformation.get('contact_address.street').enable();
    }
    if (address?.locality || address?.city) {
      this.customerInformation.get('contact_address.city').enable();
    }

    if (address?.postcode || address?.zip_code) {
      this.customerInformation.get('contact_address.zip_code').disable();
    }
    if (address?.buildingNumber || address?.house_number || address?.houseNumber) {
      this.customerInformation.get('contact_address.house_number').disable();
    }
  }

  onSearchAddress() {
    this.customerInformation.get('contact_address.street').setValue('');
    this.customerInformation.get('contact_address.city').setValue('');

    this.addressSearchError = null;
    this.isAddressFetching = true;
    this.addressService.getNlAddress({
      zipCode: this.customerInformation.get('contact_address.zip_code').value,
      houseNumber: this.customerInformation.get('contact_address.house_number').value,
      houseNumberExt: this.customerInformation.get('contact_address.house_number_extension').value,
    }).subscribe(resp => {
      if (resp?.data) {
        this.customerInformation.get('contact_address.street').patchValue(resp.data.street);
        this.customerInformation.get('contact_address.city').patchValue(resp.data.city);
        this.addressSearchError = null;
        this.checkSearchedAddressField(resp?.data);
      }
      this.isAddressFetching = false;
    }, error => {
      this.isAddressFetching = false;
      this.addressSearchError = error?.error?.message || error?.error?.error?.message || this.translocoService.translate('SIGN_UP.ADDRESS_NOT_FOUND');
    });
  }

  ngOnInit(): void {
  }

  createCustomer() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.customerInformation.invalid) {
      return;
    }


    this.customerCreationError = null;
    this.beValidationError = null;
    this.signUpSuccessMessage = null;

    const customer: any = { ...this.customerInformation.getRawValue(), invitation_token: this.token, };
    delete customer.contact_person.password_2;
    this.signUpService.createCustomer(customer).subscribe({
      next: res => {
        if (res?.data?.activation_required) {
          this.signUpSuccessMessage = `${this.translocoService.translate('SIGN_UP.SIGN_UP_SUCCESS')}.`
          this.showToaster(this.signUpSuccessMessage);
        } else {
          this.showToaster(`${this.translocoService.translate('SIGN_UP.GOOD_LUCK_WITH_REGISTERING')}!`);
          this.backToLogin();
        }
      },
      error: error => {
        if (error?.status === 0) {
          this.customerCreationError = { "message": `${this.translocoService.translate('SIGN_UP.SIGN_UP_FAILED')}?` };
        } else {
          this.customerCreationError = error?.error || error;
        }
        this.beValidationError = error?.error?.message || error?.error?.error || error?.error?.error?.error;
      }
    });
  }

  showToaster(msg) {
    this.toastrService.success(`${msg}`, 'Success', {
      timeOut: 2000
    });
  }

  showErrorToaster(msg, title = 'Error') {
    this.toastrService.error(`${msg}`, title, {
      timeOut: 4000
    });
  }

  backToLogin() {
    this.router.navigate(['/login'],
      {
        queryParams: {
        }, queryParamsHandling: 'merge'
      }
    );
  }


  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }

  searchCleared() {
    this.resetFormValue();
  }


  onSelectedAddress(address) {
    timer(50).subscribe(time => {
      this.resetFormValue();
      if (address?.context && address?.precision === EAddressPrecision.ADDRESS) {
        this.searchValue = address?.value;
        this.getAddressDetails(address?.context);
      } else {
        this.auto.close();
        this.searchValue = address?.value;
        this.searchAddress(this.searchValue, address?.context || '');
      }
    });
  }

  searchAddress(searchString: string, context = '') {
    if (searchString && searchString.length >= 3) {
      let term = `term=${searchString}`;
      term = context ? `context=${context}&term=${searchString}` : term;
      this.getAddress(term);
    }
  }

  getAddress(term) {
    this.foreignAddressSearchError = '';
    this.isLoading = true;
    this.resetFormValue();
    this.addressService.getAddress(this.selectedCountry, term).subscribe(res => {
      this.addressList = res?.data?.matches;
      const splitTerm = term?.split('term=');
      const n_term = splitTerm?.length > 1 ? splitTerm[1] : splitTerm[0];
      const find_src = this.addressList.some(item => {
        return item?.value?.includes(n_term);
      });
      if (!find_src) {
        this.auto.clear();
        timer(50).subscribe(time => {
          this.addressSearchValue = n_term;
        })
      }
      this.auto.open();
      this.isLoading = false;

    }, error => {
      this.foreignAddressSearchError = error?.error?.message;
      this.isLoading = false;
    });
  }

  getAddressDetails(context) {
    this.resetFormValue();
    this.resetAddressFound();
    const term = `context=${context}`;
    this.addressService.getAddressDetails(this.selectedCountry, term).subscribe(res => {
      if (res?.data?.address) {
        this.customerInformation.get('contact_address.street').setValue(res?.data?.address?.street);
        this.customerInformation.get('contact_address.city').setValue(res?.data?.address?.locality);
        this.customerInformation.get('contact_address.zip_code').setValue(res?.data?.address?.postcode);
        this.customerInformation.get('contact_address.house_number').setValue(res?.data?.address?.buildingNumber);
        this.customerInformation.get('contact_address.house_number_extension').setValue(res?.data?.address?.buildingNumberAddition || '');
        this.checkSearchedAddressField(res?.data?.address);
      } else {
        this.checkSearchedAddressField(null);
      }
    }, error => {
      this.checkSearchedAddressField(null);
    });
  }

  reSendInvitation() {
    this.invitationResendMsg = '';
    this.signUpService.reSendInvitation(this.token).subscribe(res => {
      this.showReSend = false;
      this.tokenValidationError = null;
      this.invitationResendMsg = `De uitnodigingslink is naar uw e-mailadres verzonden. Controleer uw inbox`;
      this.showToaster(this.invitationResendMsg);
      // this.showToaster(`${this.translocoService.translate('SIGN_UP.INVIATION_LINK_SENT')}`);

    }, error => {
      this.showErrorToaster(error?.error?.error?.message || `${this.translocoService.translate('SIGN_UP.RESEND_INVIATION_FAILED')}`, error?.error?.error?.code);
    })
  }



  get f() { return this.customerInformation.controls; }

}
