import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable } from "rxjs";
import { IAppState } from "../app.state";
import { BasePermission } from "../shared/base/base-permission";

@Injectable({
    providedIn: 'root'
})
export class OrderImportGuard extends BasePermission implements CanActivate {
    constructor(
        private router: Router,
        public store: Store<IAppState>
    ) {
        super(store);
    }
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.hasCartImportPermission) {
            return true;
        } else {
            this.router.navigate(['/home']);
            return false;
        }
    }
}
