import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';

export const selectPermissions = (state: IAppState) => state.permission;

export const selectedPermissions = createSelector(
    selectPermissions,
    (permission: any, props: any) => {
        return permission;
    }
);
