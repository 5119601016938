import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class OrderConfirmationService {
    constructor(
        private apiBaseService: ApiBaseService
    ) {}

    getOrderDetails(orderUUID: string): Observable<any> {
        const orderDetailsURI = `orders/${orderUUID}`;
        return this.apiBaseService.get(orderDetailsURI);
    }
}
