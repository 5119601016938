import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class ExportFileService {
    constructor(
        private apiBaseService: ApiBaseService,
    ) {}

    getPriceList() {
        return this.apiBaseService.get('prices/price-list');
    }

    exportPrice(catalogCode: string, pricelist: []) {
        return this.apiBaseService.post(`catalog/${catalogCode}/export`, { price_lists: pricelist });
    }

    checkExportStatus(taskId: string) {
        return this.apiBaseService.get(`tasks/${taskId}`);
    }

    downloadFile(id: number, title: string, fileType: string) {
        return this.apiBaseService.download(`export-reports/${id}/download`).subscribe((res) => {
            const fileURL = window.URL.createObjectURL(new Blob([res]));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `report of ${title}.${fileType}`);
            document.body.appendChild(fileLink);

            fileLink.click();
        }, (err) => {});
    }
    catalogExport() {
      return this.apiBaseService.post(`catalog/export`);
    }
    getReports() {
      const url = `export-reports/price_list_export`;
      return this.apiBaseService.get(url);
    }
    downloadReport(documentUUID: string) {
      const url = `export-reports/${documentUUID}/download`;
      return this.apiBaseService.getFileAsObserve(url);
    }
}
