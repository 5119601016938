<!-- <section class="inner-content-wrapper">
  <div class="container">

    <div class="breadcrumb-custom">
      <ul>
        <li><a [routerLink]="">Home</a></li>
        <li><a [routerLink]="">Product</a></li>
      </ul>
    </div>

    <div class="handset-filter" *ngIf="!pageLayout">
      <div class="row align-items-center justify-content-btween">
        <div class="col text-right">
          <button (click)="viewChanged('grid')"
            class="btn hide-on-pad circle-icon-btn circle-icon-btn-sm-width m-1 p-0">
            <i [ngClass]="isViewChanged ? 'kphicon-Grid' : 'kphicon-Grid_active'"></i>
          </button>
          <button (click)="viewChanged('list')"
            class="btn hide-on-pad circle-icon-btn circle-icon-btn-sm-width m-1 p-0">
            <i [ngClass]="isViewChanged ? 'kphicon-List_active' : 'kphicon-List'"></i>
          </button>
        </div>
      </div>
    </div>

      <div class="row">
        <div class="col-md-auto" *ngIf="childItems">
          <div class="sidebar-accordion">
            <app-nav-menu *ngIf="childItems" [navItems]="childItems"></app-nav-menu>
          </div>
        </div>

        <div class="col-md">
          <div class="advanced-filter" *ngIf="!searchedProductKeyword">
            <div class="row">
              <div class="col">
                <div class="row">
                  <div class="col-md-auto pl-0">
                    <div ngbDropdown #vDropBrand="ngbDropdown" on-mouseover="$event.stopPropagation(); vDropBrand.open();"
                      on-mouseleave="$event.stopPropagation(); vDropBrand.close();"
                      class="kpn-custom-dropdown custom-dropdown-filter dropdown-init-fix">
                      <button class="btn btn-block text-left btn-toggler pl-3" id="barndDropdown" ngbDropdownToggle>
                        <span class="dropdown-placeholder">Merk</span>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="barndDropdown" class="kpn-custom-dropdown-list">
                        <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
                          <label ngbButtonLabel class="checkbox-item d-flex align-items-center"
                            title="{{brand?.name}}" *ngFor="let brand of brandItems">
                            <div class="checkbox-btn checkbox-btn-outline">
                              <input ngbButton type="checkbox" [value]="brand.name" [(ngModel)]="brand.selected" (change)="onSelectBrand($event)">
                              <span class="checkBoxCheckmark"></span>
                            </div>
                            <div class="checkbox-label ml-2">
                              <span> <small> {{brand?.name}} <span class="small-font">({{brand?.count}})</span></small></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-auto pl-0" *ngFor="let item of attrValue">
                    <div ngbDropdown #vDropColor="ngbDropdown" on-mouseover="$event.stopPropagation(); vDropColor.open();"
                      on-mouseleave="$event.stopPropagation(); vDropColor.close();"
                      class="kpn-custom-dropdown custom-dropdown-filter dropdown-init-fix" *ngIf="item?.attrValue?.length">
                      <button class="btn btn-block text-left btn-toggler pl-3" id="colorDropdown" ngbDropdownToggle>
                        <span class="dropdown-placeholder">{{item?.attrName}}</span>
                      </button>
                      <div ngbDropdownMenu aria-labelledby="colorDropdown" class="kpn-custom-dropdown-list">
                        <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
                          <label ngbButtonLabel class="checkbox-item d-flex align-items-center"
                            title="Item 1" *ngFor="let list of item?.attrValue">
                            <div class="checkbox-btn checkbox-btn-outline">
                              <input ngbButton type="checkbox" [value]="list.value" [(ngModel)]="list.selected" (change)="onselectAttribute($event, item?.attrCode, item?.attrName)" >
                              <span class="checkBoxCheckmark"></span>
                            </div>
                            <div class="checkbox-label checkbox-label-color ml-2">
                              <span> <small> {{list?.value}} <span class="small-font">({{list?.count}})</span></small></span>
                            </div>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button placement="top" ngbTooltip="Reset filter"
                class="btn circle-icon-btn reset-filter circle-icon-btn-sm-width m-1 p-0" (click)="clearFilter()">
                <i class="kpn-ui-icon ui-close"></i>
              </button>
            </div>
          </div>
          <div class="filtered-options">
            <div class="tag-item" *ngFor="let item of selectedBrands; let i = index">
              <button class="btn tag-remove-btn circle-icon-btn mr-0 ml-0" (click)="removeBrandTag(item, i)">
                <i class="kpn-ui-icon ui-close"></i>
              </button>
              <span>Merk: {{item}}</span>
            </div>
            <div class="tag-item" *ngFor="let item of selectedAttr; let i = index">
              <button class="btn tag-remove-btn circle-icon-btn mr-0 ml-0" (click)="removeAttrTag(item, i)">
                <i class="kpn-ui-icon ui-close"></i>
              </button>
              <span>{{item.attrName}}: {{item.value}}</span>
            </div>
            <div class="tag-item" *ngIf="searchedProductKeyword">
              <button class="btn tag-remove-btn circle-icon-btn mr-0 ml-0" (click)="removeProductSearchTag()">
                <i class="kpn-ui-icon ui-close"></i>
              </button>
              <span>{{searchedProductKeyword}}</span>
            </div>

            <button class="tag-item tag-item-reset-btn" (click)="clearFilter()" *ngIf="selectedBrands?.length || selectedAttr?.length">
              <label class="btn tag-remove-btn circle-icon-btn mr-0 ml-0">
                <i class="kpn-ui-icon ui-close"></i>
              </label>
              <span>Verwijder alle filters</span>
            </button>
          </div>

          <div class="handset-item-wrapper">
            <div class="section-loader" *ngIf="isLoading">
              <div class="kpn-spinner">
                <img src="../../../assets/images/kpn-loader.gif" alt="Loading">
              </div>
            </div>
            <div class="row" [ngClass]="{'list-view-active' : isViewChanged}" *ngIf="!pageLayout; else easyOrder">
              <div class="col-6 col-md-4" [ngClass]="{'col-md-12' : isViewChanged}"
                *ngFor="let product of products | paginate: config; let i = index">
                <div class="product-item" id="product-{{i}}">
                  <div class="product-item-wrap">
                    <div class="active-onlist-view">
                      <div class="product-img">
                        <a (click)="onProductDetail(product, i)">
                          <img src="{{product.image}}" alt="{{product.name}}" *ngIf="product.image">
                          <img src="../../../assets/images/image-not-available.png" alt="{{product.name}}"
                            *ngIf="!product.image">
                        </a>

                      </div>
                      <div class="product-title-offer">
                        <h4 class="product-name">
                          <a (click)="onProductDetail(product,i)">{{product.name}}
                          </a>
                        </h4>
                        <p class="text-center mb-3" *ngIf="product?.specification">
                          {{product?.specification}}
                        </p>
                      </div>
                    </div>
                    <div class="loader-wrap" *ngIf="product?.showSpinner">
                      <div class="spinner-border" style="width: 2.5rem; height: 2.5rem;" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <div class="product-item-action text-center">
                      <div class="price-tag price-tag-cloud" *ngIf="product?.sales_price_ex_vat">
                        <span>{{ product?.sales_price_ex_vat | salesPriceFilter:'price1' }},<sup>{{ product?.sales_price_ex_vat | salesPriceFilter:'price2' }}</sup></span>
                      </div>

                      <div class="item-update-btn qty-update-btn-in-cart" style="cursor: pointer;" *ngIf="product?.cartQuantity">
                        <div class="item-add-remove">
                          <button class="kphicon-uniE972 add-remove-btn dicrement-btn"
                            (click)="decrementQuantity(product)"></button>
                          <div class="add-remove-input">
                            <input type="number" [(ngModel)]="product.cartQuantity" (ngModelChange)="changeQuantity($event, product)"  min="1" (keyup.enter)="onEnter(product)">
                          </div>
                          <button class="kphicon-uniE985 add-remove-btn incriment-btn"
                            (click)="incrementQuantity(product)"></button>
                        </div>
                        In winkelwagen
                      </div>
                      <button class="btn btn-outline-primary outline-icon-btn rounded-btn add-to-cart-btn" (click)="onAddToCart(product)"
                        *ngIf="!product?.cartQuantity">
                        <i class="kpn-ui-icon ui-shoppingbasket"></i> Toevoegen aan winkelwagen</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ng-template #easyOrder>
              <div class="bg-kpn-light pl-4">
                <div class="table-responsive custom-table" *ngIf="!isLoading">
                  <table class="table">
                    <thead>
                      <tr>
                        <th>Code</th>
                        <th>Name</th>
                        <th class="text-right">Prijs</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let product of products | paginate: config; let i = index">
                        <td>{{ product.code }}</td>
                        <td>{{ product.name }}</td>
                        <td class="text-right">
                          <span *ngIf="product?.sales_price_ex_vat && product?.sales_price_ex_vat != 0">
                            € {{ product?.sales_price_ex_vat | salesPriceFilter:'price1' }},{{ product?.sales_price_ex_vat | salesPriceFilter:'price2' }}
                          </span>
                        </td>
                        <td class="text-center">
                          <div class="loader-wrap" *ngIf="product?.showSpinner; else addToCart">
                            <div class="spinner-border" style="width: 2.2rem; height: 2.2rem;" role="status">
                              <span class="sr-only">Loading...</span>
                            </div>
                          </div>
                          <ng-template #addToCart>
                          <div class="item-update-btn item-update-btn-table d-inline-flex mb-0 qty-update-btn-in-cart" style="cursor: pointer;" *ngIf="product?.cartQuantity">

                            <div class="item-add-remove">
                              <button class="kphicon-uniE972 add-remove-btn dicrement-btn"
                                (click)="decrementQuantity(product)"></button>
                              <div class="add-remove-input">
                                <input type="number" [(ngModel)]="product.cartQuantity" (ngModelChange)="changeQuantity($event, product)"  min="1" (keyup.enter)="onEnter(product)">
                              </div>
                              <button class="kphicon-uniE985 add-remove-btn incriment-btn"
                                (click)="incrementQuantity(product)"></button>
                            </div>
                          </div>
                          <button class="btn btn-outline-primary outline-icon-btn rounded-btn add-to-cart-btn no-wrap" (click)="onAddToCart(product)"
                            *ngIf="!product?.cartQuantity">
                            <i class="kpn-ui-icon ui-shoppingbasket"></i> Toevoegen aan winkelwagen</button>
                          </ng-template>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </ng-template>
            <div *ngIf="showPagination">
              <pagination-controls previousLabel="Vorige" nextLabel="Volgende" responsive="true"  class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
            </div>
          </div>
        </div>
      </div>
  </div>
</section> -->

<!-- New design -->

<!-- <section class="page-banner">
  <div class="container">
    <div class="home-title-content">
      <h2 class="home-title">KPN Webshop</h2>
      <p class="home-title-sub">De webshop voor zakelijk Nederland </p>
    </div>
  </div>
</section> -->

<ng-template #systemMessagesModal let-modal>
  <div class="px-4">
    <div class="modal-header tri-modal-header px-0">
        <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
          Belangrijke mededeling
        </h6>
        <!-- <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
            <span class="kphicon-uniE926"></span>
        </button> -->
    </div>
  </div>

  <div class="modal-body p-4">
      <div class="font-kpn-extended" *ngIf="currentMessageObj">

          <!-- <p [innerHTML]="currentMessageObj?.message?.description">
          </p> -->

          <p class="mb-2" [innerHTML]="currentMessageObj?.message?.description">
          </p>
          <!-- <p class="mb-2">
            Vanaf nu is het mogelijk om bestellingen te plaatsen met Apple Business Manager en/of Samsung KNOX Mobile Enrollment. Het Apple ID en Samsung KNOX ID van jouw bedrijf zijn overgenomen uit het telefonie bestelportaal en de functie is geactiveerd.
          </p> -->
          <!-- <p>
            Heb je vragen over enrollment? Bekijk onze <a href="" class="blue-link">FAQ-pagina</a>.
          </p> -->

          <div class="pt-2">
            <div class="row align-items-center justify-content-between">
              <div class="col-auto">
                <button (click)="closeSystemMessageModal()"
                    class="btn px-4 btn-primary border-2 rounded-btn">
                    <span class="d-inline-block px-2">Sluiten</span>
                </button>
              </div>
              <div class="col-auto">
                <label
                  class="checkbox-item checkbox-item-tri mb-0 d-flex align-items-center">
                  <div class="checkbox-btn checkbox-btn-14 checkbox-btn-outline">
                      <input [(ngModel)]="currentMessageObj.is_dismissed" (ngModelChange)="updateSystemMessage()" type="checkbox">
                      <span class="checkBoxCheckmark"></span>
                  </div>
                  <div class="checkbox-label font-kpn-extended ml-2">
                      <span>Dit bericht niet meer laten zien </span>
                  </div>
              </label>
              </div>
            </div>
         </div>
      </div>
  </div>
</ng-template>

<section class="new-product-list">
  <div class="new-product-banner">
    <div class="container">
      <div class="row flex-row-reverse align-items-center justify-content-between">
        <div class="col-md-auto overflow-hidden">
          <img class="kpn-hero-img" src="../../../assets/images/kpn-banner-img.jpg" alt="">
        </div>
        <div class="col-md pr-md-5 pt-5">
          <div class="product-banner-content pr-md-5">
            <h3>{{ siteProfile?.title || "No Title for Tenant" }}</h3>
            <p>{{ siteProfile?.description || "No Description for Tenant" }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
<div class="category-section category-section-new">
  <div class="container">
    <div class="row">
      <div
        class="section-loader home-new-section-loader mb-4"
        *ngIf="isLoading"
      >
        <div class="kpn-spinner">
          <img src="/assets/images/kpn-loader.gif" alt="Loading" />
        </div>
      </div>
      <div
        class="col-md-12 mt-5 pt-5"
        *ngIf="!isLoading && !catalogList?.length"
      >
        <div
          class="info-bar-blue mb-3 d-flex align-items-center justify-content-between"
        >
          <h6>
            <span class="kphicon-uniE952"></span>
           {{'NO_CATALOG_FOUND' | transloco }}.
          </h6>
        </div>
      </div>
      <div class="col-md-4" *ngFor="let items of catalogList">
        <div class="category-box">
          <div class="icon-title">
            <div class="icon-shape-bg">
              <img src="/assets/images/cisco-networking.png" alt="" />
            </div>
            <h4 class="category-name-title">
              {{ items.name?.replace("kpn", "KPN") }}
            </h4>
          </div>
          <div class="category-sub-item-list">
            <ul>
              <li *ngFor="let item of items?.product_groups | slice : 0 : 6">
                <a
                  [routerLink]="['/product-list']"
                  [queryParams]="{
                    catalogCode: items?.code,
                    groupCode: item?.code,
                    pageLayout: item?.page_layout?.code
                  }"
                >
                  {{ item.name }} <i class="kpn-ui-icon ui-chevron-right"></i>
                </a>
              </li>
            </ul>
            <!-- <div class="text-center">
              <button
                class="btn btn-new btn-primary-dark rounded-btn mb-2"
                [routerLink]="['/product-list']"
                [queryParams]="{
                  catalogCode: items?.code,
                  groupCode: items?.product_groups[0]?.code,
                  pageLayout: items?.product_groups[0]?.page_layout?.code
                }"
              >Alles {{ items.name }}</button>
            </div> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
