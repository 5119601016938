import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SmsCampaignToolService } from '../sms-campaign-tool.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, timer } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { NavigationStart, Router } from '@angular/router';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-sms-campaigntool',
  templateUrl: './sms-campaigntool.component.html',
  styleUrls: ['./sms-campaigntool.component.scss']
})
export class SmsCampaignToolComponent extends BasePermission implements OnInit {

  smsCampaignForm: UntypedFormGroup;
  accountTypeForm: UntypedFormGroup;
  validatingFile = false;
  addingAccountType = false;
  accountTypeAddError = null;
  sendingSms = false;
  isLoading = false;
  smsLoading = false;
  campaigns = [];
  campaignSMS = [];
  config = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0,
    id: 'campaign_pagination'
  };

  smsConfig = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0,
    id: 'campaign_sms_pagination'
  };

  importError = '';
  sendingSmsError = '';
  selectedCampaign = null;
  public search = '';
  private searchSubject = new BehaviorSubject<string>('');
  @ViewChild('fileInput')
  fileInputVariable: ElementRef;


  constructor(
    private smsCampaignToolService: SmsCampaignToolService,
    public store: Store<IAppState>,
    private fb: UntypedFormBuilder,
    private toastrService: ToastrService,
    public utilityService: UtilityService,
    private modalService: NgbModal,
    private router: Router,
    public translocoService: TranslocoService
  ) {
    super(store);
    this.initializeForm();
    this.searchSubject.pipe(
      debounceTime(500), // Adjust the debounce time as needed (in milliseconds)
      distinctUntilChanged()
    ).subscribe(searchTerm => {
      // Call your search function here with the debounced search term
      this.searchSMS(searchTerm);
    });

    this.router.events
      .subscribe((event: NavigationStart) => {
        if (
          event instanceof NavigationStart &&
          event.navigationTrigger == "popstate" &&
          event.restoredState
        ) {
          timer(100).subscribe(time => {
            this.modalService?.dismissAll();
          })
        }
      });
  }

  ngOnInit(): void {
    this.getCampaigns();
  }

  getCampaigns() {
    this.isLoading = true;
    this.smsCampaignToolService.getCampaigns(this.config.currentPage).subscribe(res => {
      this.isLoading = false;
      this.config.totalItems = res?.data?.count;
      this.campaigns = res?.data?.results?.map(item => {
        return {
          ...item,
          sendingSms: false
        }
      });
    }, error => {
      this.isLoading = false;
    })
  }

  initializeForm() {
    this.smsCampaignForm = this.fb.group({
      'title': new UntypedFormControl('', [Validators.required]),
      'file': new UntypedFormControl('', [Validators.required]),
    });
  }


  initializeAccountTypeForm() {
    this.accountTypeForm = this.fb.group({
      'account_type_code': new UntypedFormControl('KPN_DEV_SMS', [Validators.required]),
      'account_id': new UntypedFormControl('', [Validators.required]),
      'account_name': new UntypedFormControl('', []),
      'account_secret': new UntypedFormControl('', [Validators.required]),
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.smsCampaignForm.patchValue({
        file: file
      });
    }
  }

  importMobileNumber() {
    this.importError = '';
    const formData: FormData = new FormData();
    for (const key in this.smsCampaignForm.value) {
      formData.append(key, this.smsCampaignForm.get(key).value);
    }

    this.validatingFile = true;
    this.smsCampaignToolService.validateFile(formData).subscribe(
      (res) => {
        this.validatingFile = false;
        this.toastrService.success(res?.data?.response_message, 'Success', {
          timeOut: 5000
        });
        this.config.currentPage = 1;
        this.fileInputVariable.nativeElement.value = "";
        this.getCampaigns();
        this.initializeForm();
      },
      (error) => {
        this.validatingFile = false;
        this.importError = error?.error?.message || error?.error?.error?.message || 'Validation Error';
        this.toastrService.error(this.importError, 'Error', {
          timeOut: 5000
        });
      }
    )
  }

  downloadDocuments(campaign) {
    const fileLink = document.createElement('a');
    fileLink.href = campaign?.file;
    fileLink.setAttribute('download', `${campaign?.uuid}`);
    document.body.appendChild(fileLink);
    fileLink.click();
    this.toastrService.success(`${this.translocoService.translate('ORDER_STATUS.DOCUMENT_DOWNLOADED_SUCCESSFULLY')}`, 'Success', {
      timeOut: 3000
    });
    document.body.removeChild(fileLink);
  }


  onPageClick(event) {
    this.config.currentPage = event;
    this.getCampaigns();
  }

  onSetAccountType(campaign, setAccountTypeModal) {
    this.initializeAccountTypeForm();
    this.modalService.open(setAccountTypeModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  addAccountType() {
    this.accountTypeAddError = null;
    this.smsCampaignToolService.addAccountType(this.accountTypeForm.value).subscribe(res => {
      this.initializeAccountTypeForm();
      this.getCampaigns();
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
    }, error => {
      this.accountTypeAddError = error?.error?.message || `${this.translocoService.translate('SMS_CAMPAIGN.ACCOUNT_TYPE_ADDING_FAILED')}`;
      console.log('error: ', error);
    })
  }

  SendSMS(campaign) {
    this.sendingSmsError = '';
    campaign.sendingSms = true;
    this.smsCampaignToolService.sendCampaignSms(campaign?.uuid).subscribe(res => {
      campaign.sendingSms = false;

      this.toastrService.success(res?.data?.message || `${this.translocoService.translate('SMS_CAMPAIGN.SMS_SUCCESSFULLY_SENT')}`, `${this.translocoService.translate('SMS_CAMPAIGN.SMS_SENT')}`);
      // ${this.translocoService.translate('SMS_CAMPAIGN.SMS_SENDING_FAILED')}
      this.getCampaigns();
    }, error => {
      campaign.sendingSms = false;
      this.sendingSmsError = error?.error?.message || `${this.translocoService.translate('SMS_CAMPAIGN.SMS_SENDING_FAILED')}`;
      this.toastrService.error(this.sendingSmsError, 'ERROR');
    })
  }



  viewSMS(campaign, viewSMSModal, showModal = true) {
    if (!campaign?.uuid) {
      return;
    }
    this.selectedCampaign = campaign;
    this.smsLoading = true;
    this.campaignSMS = [];
    if (showModal) {
      this.smsConfig.currentPage = 1;
      this.search = '';
    }
    this.smsCampaignToolService.viewCampaignSMS(campaign?.uuid, this.smsConfig.currentPage, this.search).subscribe(
      (res) => {
        this.smsConfig.totalItems = res?.data?.count;
        this.campaignSMS = res?.data?.results;
        this.smsLoading = false;
        if (showModal) {
          this.modalService.open(viewSMSModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            size: 'xl',
            scrollable: true,
            backdrop: 'static',
          }).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
        }
      }, error => {
        this.smsLoading = false;
        const errorMsg = error?.error?.message || error?.error?.error;
        this.toastrService.error(errorMsg, 'Error');
      });
  }

  onSmsPageClick(event, viewSMSModal) {
    this.smsConfig.currentPage = event;
    this.viewSMS(this.selectedCampaign, viewSMSModal, false)
  }

  searchSMS(search: string, viewSMSModal = null) {
    if (!search?.trim()?.length) {
      this.clearInput(viewSMSModal);
      return;
    } else {
      this.smsConfig.currentPage = 1;
      this.viewSMS(this.selectedCampaign, viewSMSModal, false)
    }
  }

  onInputChange() {
    // Notify the searchSubject about changes in the input
    this.searchSubject.next(this.search);
  }

  clearInput(viewSMSModal) {
    this.search = '';
    this.smsConfig.currentPage = 1;
    this.viewSMS(this.selectedCampaign, viewSMSModal, false);
  }

}