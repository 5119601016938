<div class="product-nav-bar">
    <div class="nav-wrapper">
        <div class="kpn-spinner menu-loader" *ngIf="isLoading">
            <img src="/assets/images/kpn-loader.gif" alt="Loading">
        </div>
        <ul>
            <li *ngFor="let menuGroup of menuGroups">
                <a (click)="onMenuItemSelect(menuGroup.code)">{{menuGroup.name}}</a>
                <ul *ngIf="menuGroup.sub_groups && menuGroup.sub_groups.length">
                    <li *ngFor="let subGroup of menuGroup.sub_groups">
                        <a [routerLink]="subGroup.url">{{subGroup.name}}</a>
                        <ul *ngIf="subGroup.sub_groups && subGroup.sub_groups.length">
                            <li *ngFor="let subSubGroup of subGroup.sub_groups">
                                <a [routerLink]="subSubGroup.url">{{subSubGroup.name}}</a>
                            </li>
                        </ul>
                    </li>
                </ul>
            </li>
        </ul>
    </div>
</div>
