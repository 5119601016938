import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiBaseService } from 'src/app/shared/api-base.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CustomersService {

  private customersURI = 'contacts';
  private companyURI = `contacts/companies/search`;

  constructor(private apiBaseService: ApiBaseService, private router: Router) { }

  getCustomerURI(searchType, params) {
    let customersURI = '';

    if (searchType === 'krn') {
      customersURI = `contacts/customers/krn/${params}`;
    } else if (searchType === 'coc') {
      customersURI = `contacts/customers/coc/${params}`;
    } else {
      customersURI = 'contacts/customers/address';
    }

    return customersURI;
  }

  getCustomers(params, searchType) {

    if (searchType === 'krn' || searchType === 'coc') {
      return this.apiBaseService.get(this.getCustomerURI(searchType, params));
    } else {
      return this.apiBaseService.get(this.getCustomerURI(searchType, params), params);
    }
  }

  getCustomerUuid(externalID) {
    return this.apiBaseService.get(`contacts/customers/krn/${externalID}`);
  }

  goToCustomerDetails(customerUUID: string, isNewQuote: boolean = false, isImported: boolean = false, is_trade_in = false) {
    const queryParams = isNewQuote ? { newQuote: isNewQuote } : {};
    if (isImported) {
      queryParams['importedQuote'] = isImported;
    }

    const url = is_trade_in ? '/trade-in/contact' : '/customer'
    this.router.navigate([url, `${customerUUID}`], {
      queryParams: queryParams
    });
  }

  getCompanies(companyName: string) {
    const params = new HttpParams({ fromString: `company_name=${companyName}` });
    return this.apiBaseService.get(this.companyURI, params);
  }

}
