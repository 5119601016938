import { Component, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-vat-number-input',
  templateUrl: './vat-number-input.component.html',
  styleUrls: ['./vat-number-input.component.scss']
})
export class VatNumberInputComponent {



  public vat_loading = false;
  public vat_number = '';
  public customer = null;
  public user = null;

  @Output() reFetchCartCart = new EventEmitter<any>(false);



  constructor(
    private customerService: CustomerService,
    public toastrService: ToastrService,
    public storageService: StorageService
  ) {

    this.customer = this.storageService?.getCustomer();
    this.user = this.storageService.getCurrentUser();
  }

  updateCustomer() {
    if (this.vat_number?.trim()?.length && this.vat_number !== this.customer?.vat_number) {
      this.vat_loading = true;
      this.customerService.updateCustomer(this.getContact(), { vat_number: this.vat_number }).subscribe(res => {
        this.reFetchCartCart.emit(true);
        this.vat_loading = false;
        this.storageService.setCustomer(this.customer);
        this.toastrService.success("BTW number added to the contact");
        this.vat_number = '';
      }, error => {
        this.vat_loading = false;
        this.toastrService.error("BTW number added to failed");
      })
    }
  }


  getContact() {
    if (this.user?.is_external) {
      const customer = this.storageService?.getCustomer();
      const uuid = customer?.uuid ?? this?.user?.contact?.uuid;
      return uuid;
    } else {
      const customer = this.storageService?.getCustomer();
      return customer?.uuid;
    }
  }


}
