<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="kpn-card bg-kpn-light box-shadow-sm confirmation-cart-overview mb-3">
        <div class="kpn-spinner" *ngIf="isLoading;else details_content">
          <div class="text-center">
            <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
            <p> {{'ORDER_CONFIRMATION.SEARCH' | transloco }}…</p>
          </div>
        </div>
        <ng-template #details_content>
          <div class="text-center">
            <div *ngIf="!failedPayments.includes(paymentStatus)">
              <h2 class="title-1 green-text" i18n="@@arm.order-confirmation.congrate-title">{{'ORDER_CONFIRMATION.THANK_YOU_FOR_YOUR_ORDER' | transloco }}
              </h2>
              <h4 class="sub-title-light-4 mb-3 green-text" i18n="@@arm.order-confirmation.congrate-sub-title">{{'ORDER_CONFIRMATION.THE_ORDER_HAS_BEEN_PLACED' | transloco }}</h4>
            </div>
            <div *ngIf="failedPayments.includes(paymentStatus)">
              <h2 class="title-1 green-text">{{'ORDER_CONFIRMATION.PAYMENT_CANCELLED' | transloco }}.
              </h2>
            </div>
          </div>

          <div class="col-md-12 mt-3 pt-3" *ngIf="objectIdentifier && failedPayments.includes(paymentStatus)">
            <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
              <h6>
                <span class="kphicon-uniE952"></span>
                {{paymentMsg}}
              </h6>
            </div>
          </div>

          <app-payment
            *ngIf="!order?.is_paid && order?.direct_payment_required && !paymentParameter?.is_total_amount_paid"
            [order]="order" [contact]="contact">
          </app-payment>

          <div>
            <div class="voicelog-overview order-confirmation-user-info">
              <div class="row mb-4">
                <div class="col-md-4 client-info client-info-order-conf">
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">{{'ORDER_CONFIRMATION.COMPANY_NAME' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{contact?.company_name}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">{{'ORDER_CONFIRMATION.NAME' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{contactPerson?.initials}} {{contactPerson?.last_name}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.phone">{{'ORDER_CONFIRMATION.TELEPHONE' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{contactPerson?.mobile_phone_number}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.email">E-mail:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{contactPerson?.email}}</p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 client-info client-info-order-conf">
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">{{'ORDER_CONFIRMATION.SHIPPING_ADDRESS' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        <span *ngIf="shippingAddress?.company_name">{{shippingAddress?.company_name}}<br /></span>
                        {{shippingAddress?.street}} {{shippingAddress?.house_number}}
                        {{shippingAddress?.house_number_extension}}<br />
                        {{shippingAddress?.zip_code}} {{shippingAddress?.city}}
                        <span *ngIf="hasInputCustomAddressPermission" class="d-block">{{ order?.shipping_extra_address_information_1 }} <br>
                          {{ order?.shipping_extra_address_information_2 }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">{{'ORDER_CONFIRMATION.BILLING_ADDRESS' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{billingAddress?.street}} {{billingAddress?.house_number}}
                        {{billingAddress?.house_number_extension}}<br />
                        {{billingAddress?.zip_code}} {{billingAddress?.city}}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-md-4 client-info client-info-order-conf">
                  <div class="row" *ngIf="shippingAddress?.bank_account_number">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{'ORDER_CONFIRMATION.ACCOUNT_NUMBER' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{shippingAddress?.bank_account_number}}</p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{'ORDER_CONFIRMATION.ORDER_NUMBER' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{order?.reference}}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="order?.customer_reference">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{siteProfile?.reference_label || ( ('HEADER.CUSTOMER' | transloco)+' '+('ORDER_OVERVIEW.REFERENCE' | transloco))  }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{order?.customer_reference}}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="order?.po_number">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{siteProfile?.po_number_label || 'PO '+('CUSTOMER.NUMBER' | transloco)}}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{order?.po_number}}</p>
                    </div>
                  </div>
                  <div class="row" *ngIf="order?.requested_delivery_date">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{'ORDER_CONFIRMATION.REQUESTED_DELIVERY_DATE' | transloco}}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{order?.requested_delivery_date | date: 'dd-MM-yyyy'}}</p>
                    </div>
                  </div>
                </div>

                <div class="col-md-12 mt-3 pt-3"
                  *ngIf="objectIdentifier && paymentMsg && !failedPayments.includes(paymentStatus)">
                  <div class="info-bar-green mb-3 d-flex align-items-center justify-content-between">
                    <h6>
                      <span class="kphicon-uniE952"></span>
                      {{paymentMsg}}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="!failedPayments.includes(paymentStatus)">
            <p class="body-text-1 mb-3">{{description}}</p>
          </div>
          <div class="cart-items-wrap pl-4 pt-4">
            <div class="cart-item cart-item-single-box">
              <div class="cart-item-row mb-3 border-0">
                <div class="row">
                  <div class="col-lg">
                    <div class="row">
                      <div class="col">
                        <b>Product</b>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row pt-4 action-cart-price align-items-center justify-content-end">
                      <div class="col-auto">
                        <div class="cart-qty-amount text-center"><b>{{'ORDER_CONFIRMATION.QUANTITY' | transloco }}</b></div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col text-right"><b>{{'ORDER_CONFIRMATION.PRICE' | transloco }}</b></div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col text-right"><b>{{'ORDER_CONFIRMATION.TOTAL_PRICE' | transloco }}</b></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-item-row mb-3 border-0" *ngFor="let line of orderLines; let i = index">
                <div class="row">
                  <div class="col-lg">
                    <div class="row">
                      <div class="col-auto px-0 hide-on-pad">
                        <div class="cart-item-image">
                          <img *ngIf="line?.product_code?.image || line?.product_code?.product_thumbnail"
                            src="{{line?.product_code?.image || line?.product_code?.product_thumbnail}}" alt="">
                          <img
                            *ngIf="!line?.product_code?.image && !line?.product_code?.product_thumbnail && (!line.brand?.includes('cisco') || !line.brand?.includes('Cisco'))"
                            src="../../../assets/images/noimage.png" alt="">
                          <img
                            *ngIf="!line?.product_code?.image && !line?.product_code?.product_thumbnail && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                            src="../../../assets/images/cisco-networking.png" alt="">
                        </div>
                      </div>
                      <div class="col">
                        <div class="cart-item-details">
                          <h4 class="title-4 mb-0 pt-2">{{line?.product_code?.brand__name}}</h4>
                          <p class="model-desc">{{line?.product_code?.code}}-{{line?.product_code?.name}}
                            <small *ngIf="line?.promotion_description" class="pt-0 ml-1 mr-1" >
                              <span class="badge badge-info" [innerHTML]="line?.promotion_description"></span>
                            </small>
                          </p>
                          <p class="sub-title-light-5" *ngIf="line?.voucher_code">
                            <small class="text-dark"> Voucher code: <b> {{ line?.voucher_code }}</b></small>
                          </p>
                          <p class="sub-title-light-5" *ngIf="line?.voucher_reference">
                            <small class="text-dark"> Voucher {{'ORDER_OVERVIEW.REFERENCE' | transloco}}: <b> {{ line?.voucher_reference }}</b></small>
                          </p>

                          <p class="sub-title-light-5" *ngIf="line?.provisions">
                            <small class="text-dark"> {{'ORDER_CONFIRMATION.FACILITY_STATUS' | transloco}}: <b> {{ line?.provisions?.status
                                }}</b></small><br>
                            <small class="text-dark"> IMEI: <b> {{ line?.provisions?.imei_number }}</b></small>
                          </p>
                          <p class="sub-title-light-5" *ngIf="line?.order_line_extra?.contact_account_type">
                            <small class="text-dark"> {{'ORDER_OVERVIEW.ACCOUNT_TYPE' | transloco}}: <b> {{
                                line?.order_line_extra?.contact_account_type?.account_id
                                }} <span
                                  *ngIf="line?.order_line_extra?.contact_account_type?.account_type_code">({{line?.order_line_extra?.contact_account_type?.account_type_code}})</span>
                              </b></small>
                          </p>
                          <p class="sub-title-light-5"
                            *ngIf="line?.order_line_extra?.comm_plan || line?.order_line_extra?.rate">
                            <small class="text-dark" *ngIf="line?.order_line_extra?.is_default_rate_plan"><b> 
                              {{'CART.DEFAULT_RATE_PLAN' | transloco}}
                              </b><br>
                            </small>
                            <small class="text-dark"
                              *ngIf="line?.order_line_extra?.comm_plan || line?.order_line_extra?.rate"> {{'CART.COMM_PLAN' | transloco}}: <b>
                                {{line?.order_line_extra?.comm_plan}}
                              </b><br>
                            </small>
                            <small class="text-dark"
                              *ngIf="line?.order_line_extra?.comm_plan || line?.order_line_extra?.rate"> {{'CART.RATE_PLAN' | transloco}}: <b>
                                {{line?.order_line_extra?.rate |currencyFormat}} </b>
                            </small>
                          </p>
                          <span
                            *ngIf="line?.delivery_method && !cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                            <button class="flat-grey-light" placement="bottom">
                              <h6 class="title-6 mb-0 mr-2"> {{'ORDER_CONFIRMATION.DELIVERY_METHOD' | transloco }}: </h6>
                              <span class="dark-orange-tag-pill mr-5"> <b> {{line?.delivery_method?.name}}</b></span>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row pt-4 action-cart-price align-items-center justify-content-end">
                      <div class="col-auto">
                        <div class="cart-qty-amount text-right amount-col pt-1">
                          <span *ngIf="!line?.voucher_code">
                            {{ line?.qty }}
                          </span>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col pt-2 text-right sub-title-light-4 dark-grey-text">
                          <span *ngIf="!line?.voucher_code">
                            {{(utilityService.isInclVAT() ? line?.amount : line?.amount_ex_vat )| currencyFormat}}
                          </span>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col pt-2 text-right sub-title-light-4 dark-grey-text">
                          <span>
                            {{(utilityService.isInclVAT() ? line?.amount : line?.amount_ex_vat )| currencyFormat}}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 cart-sub-items" *ngFor="let subLine of line.sub_lines; let j = index">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <div class="cart-item-details">
                              <p class="model-desc text-left">{{subLine?.product_code?.name}} 
                                <small *ngIf="subLine?.promotion_description" class="pt-0 ml-1 mr-1" >
                                  <span class="badge badge-info" [innerHTML]="subLine?.promotion_description"></span>
                                </small>
                                <span
                                  *ngIf="!subLine?.product_code?.code?.startsWith('tkh_')">
                                  ({{subLine?.product_code?.code}}) <b>x {{subLine?.qty}}</b>
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="row action-cart-price align-items-center justify-content-end">
                          <div class="col-auto sub-item-price-col-auto">
                            <div class="price-col text-right sub-title-light-4 pt-2 dark-grey-text">
                              {{(utilityService.isInclVAT() ? subLine?.amount : subLine?.amount_ex_vat )| currencyFormat}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end item-cart-footer item-cart-footer-orderoverview pr-0 mr-0"
                *ngIf="!isLoading">
                <div class="col-lg cart-total pr-0">
                  <div class="row align-items-center justify-content-end">
                    <div class="col-md-4 col-lg-3 col-12 pr-0">
                      <div class="">
                        <h5 class="title-3 green-text text-right pr-2">excl. {{'ORDER_CONFIRMATION.VAT' | transloco }}</h5>
                        <h5 class="title-3 green-text text-right pr-2">{{'ORDER_CONFIRMATION.VAT' | transloco }}</h5>
                        <h5 class="title-3 green-text text-right total-above-line pr-2 w-100">{{'ORDER_CONFIRMATION.TOTAL_INCL' | transloco }} .</h5>
                      </div>
                    </div>
                    <div class="col-auto pl-0 pr-0">
                      <div class="price-col">
                        <h5 class="title-3 dark-grey-text text-right">{{order?.total_amount_ex_vat | currencyFormat}}</h5>
                        <h5 class="title-3 dark-grey-text text-right">{{order?.total_amount_vat | currencyFormat}}</h5>
                        <h5 class="title-3 dark-grey-text text-right total-above-line">
                          {{order?.total_amount | currencyFormat}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </ng-template>
      </div>
    </div>
  </section>
</div>
