import { MyAccountService } from './../../pages/my-account/my-account.service';
import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, NavigationStart } from '@angular/router';

import { TogglerService } from '../../shared/toggler.service';
import { HeaderService } from './header.service';
import { StorageService } from '../../shared/storage.service';
import { SidebarService } from '../sidebar/sidebar.service';
import { CartService } from '../../pages/cart/cart.service';
import { Observable, of, Subject, Subscription, timer } from 'rxjs';
import { AuthService } from 'src/app/shared/auth.service';
import { ToastrService } from 'ngx-toastr';
import { debounceTime, distinctUntilChanged, filter, skip, switchMap, tap } from 'rxjs/operators';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { select, Store } from '@ngrx/store';
import { IAppState } from '../../app.state';
// tslint:disable-next-line:max-line-length
import { CatalogLoadAction, ClearCatalog, SelectCatalogAndProductGroupAction } from '../../store/actions/catalog.actions';
import { selectCatalog } from '../../store/selectors/catalog.selectors';
// tslint:disable-next-line:max-line-length
import { ChildGroupLoadAction } from '../../store/actions/productGroup.actions';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { selectedSiteProfile, selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { ERmaFlowTypes, ExportId } from 'src/app/shared/enums/flow-type.enums';
import { RmaService } from 'src/app/pages/rma/rma.service';
import { FavoriteProductsService } from 'src/app/pages/favorite-products/favorite-products.service';
import { PermissionLoadAction, PermissionSuccessAction } from 'src/app/store/actions/permission.actions';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from 'src/app/shared/services/utility.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent extends BasePermission implements OnInit, OnDestroy {
  public cart = null;
  public isBusiness = false;
  public hideCatalog = false;

  searchToggler = false;
  cartToggler = false;

  isMouseHovered = false;
  showSideBar = '';
  sideBarToggler = false;
  // tslint:disable-next-line:max-line-length
  ciscoUrl = 'https://tools.cisco.com/eb2b/tnxshop/U2hcServlet?P1=412200867-514dc9bb-16ff10623ba-c8de59aea12c9100967a9e4495ecad40&P2=https%3A%2F%2Fapps.cisco.com%2Fccw%2Fcpc%2Fhome&P3=0839fa607a0933335f624e6b9da582700f86a6e3&P4=create&P6=N';
  userName = '';
  name = '';

  noOfCartItems = 0;
  customerName = '';
  credit = 0;

  isCiscoSystem = false;
  hasPasswordSystem = false;
  showPasswordField = false;

  passwordOfMakeQutation = '';
  catalogList: any[] = [];
  selectedCatalogCode = '';
  is_external = false;
  is_token_change = false;
  isActive = false;
  cat$: Observable<any>;
  catalogSubs$: Subscription;
  parentGroupList: any[] = [];
  // tslint:disable-next-line:no-inferrable-types
  selectedProductGroupCode: string = '';
  searchProductList = [];
  public placeholder = 'Zoeken...';
  keyword = 'name';
  private searchProductKey: Subject<string> = new Subject<string>();
  isHomeUrl = true;
  private selectedPageLayout = null;
  selectedCartSubs$: Subscription;
  selectedProduct: any[] = [];
  public siteProfile = null;
  public logoSrc = '';
  public helpLink = '';
  pageLayout: string = null;
  public customer = null;
  public restrictExportId = ExportId;

  selectedRmaType = null;
  public rmaTypes = [];
  public searchValue = '';
  private translocoService$: Subscription;


  constructor(
    private storageService: StorageService,
    private router: Router,
    private headerService: HeaderService,
    private togglerService: TogglerService,
    private sideBarService: SidebarService,
    public cartService: CartService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toastrService: ToastrService,
    public store: Store<IAppState>,
    private modalService: NgbModal,
    private activateRoute: ActivatedRoute,
    private customerService: CustomerService,
    private rmaService: RmaService,
    private favoriteProductsService: FavoriteProductsService,
    private myAccountService: MyAccountService,
    public translocoService: TranslocoService,
    public utilityService: UtilityService
  ) {
    super(store);
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
      this.logoSrc = this.siteProfile?.logo_url || `/assets/images/logoipsum-logo.svg`;
      // tslint:disable-next-line:max-line-length
      this.helpLink = !this.restrictExportId?.includes(this.siteProfile?.export_id) ? 'mailto:kpnwebshop@kpn.com' : 'https://ykpn.facilitor.nl/';
    });
    router.events
      .pipe(
        filter(event => event instanceof NavigationStart)
      )
      .subscribe((value: NavigationStart) => {
        if (value?.url === '/product-list') {
          this.isHomeUrl = true;
        } else {
          this.isHomeUrl = false;
        }
      });

    // this.route.queryParams.subscribe(queryParams => {
    //   this.pageLayout = queryParams?.pageLayout || null;
    // }, error => {
    //   console.log('queryParams error: ', error);
    // });
  }

  getCustomerDetails(contact_uuid) {
    timer(500).subscribe(time => {
      this.customerService.getCustomer(contact_uuid).subscribe(
        (res) => {
          this.customer = res.data;
        },
        (err) => {
          console.log('err: ', err);
        }
      );
    });
  }

  changeLanguage(languageCode: string): void {
    if (this.storageService.getToken()) {
      const payload = {
        contact_person: {
          language: languageCode
        }
      };
      this.myAccountService.updateUserInfo(payload).subscribe((response) => {
        if (response?.data?.contact_persons) {
          this.translocoService.setActiveLang(languageCode);
          this.storageService.setLanguage(languageCode);
        }
      }, (error) => {
        this.toastrService.error('Failed to update language');
      });
    }

  }
  ngOnInit() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // || event.url.includes('/product-details')
        if ((event.url !== '/trade-in/orders' && event.url.includes('/trade-in'))) {
          this.hideCatalog = true;
        }
        else {
          this.hideCatalog = false;
        }
      }
    });
    let [currentUser, customerName] = this.getStorageData();
    this.updateUserProperty(customerName, currentUser);
    this.getCart();
    this.is_external = currentUser ? currentUser?.is_external : false;
    this.isActive = currentUser ? currentUser?.is_active : false;
    if (this.is_external) {
      this.getCustomerDetails(customerName?.uuid || currentUser?.contact?.uuid);
    } else {
      this.customer = null;

    }

    this.storageService.watchStorage().subscribe(() => {
      [currentUser, customerName] = this.getStorageData();
      this.is_external = currentUser?.is_external;
      this.isActive = currentUser?.is_active;
      this.updateUserProperty(customerName, currentUser);
      if (this.is_external) {
        this.getCustomerDetails(customerName?.uuid || currentUser?.contact?.uuid);
      } else {
        this.customer = null;
      }
    });

    this.watchToken();
    this.watchPermission();
    if (this.isActive && !this.is_token_change) {
      this.store.dispatch(new CatalogLoadAction());
      this.selectedCatalog();
    }
    this.listenSelectedCart();
    // subscribing to language change event and skipping the inistail subsription calls
    this.translocoService.langChanges$.pipe(skip(1)).subscribe((lang) => {
      if (this.authService.isLoggedIn() && this.authService.hasUser()) {
        this.store.dispatch(new CatalogLoadAction());
        this.selectedCatalog();
      }
    });
  }


  watchPermission() {
    this.rmaTypes = [];
    this.storageService.watchPermission()
      .pipe(
        switchMap(value => {
          if (this.siteProfile?.allow_rma_handling && value) {
            const permissions = this.storageService.getPermission();
            const hasRmaPermission = permissions.some(permission => {
              return ['return_management.view_rmaflowtype'].includes(permission.code);
            });
            return hasRmaPermission ? this.rmaService.getRMATypes() : of(null);
          } else {
            return of(null);
          }
        }),
      ).subscribe(res => {
        this.rmaTypes = [];
        if (res) {
          this.rmaTypes = res?.data?.results;
        }
      }, error => {
        this.selectedRmaType = null;
      });
  }

  openSm(content) {
    this.modalService.open(content, { size: 'md' });
  }

  watchToken() {
    this.storageService.watchToken()
      .pipe(
        tap(value => {
          if (this.isActive && this.storageService.getToken()) {
            this.store.dispatch(new CatalogLoadAction());
          }
        }),
      ).subscribe(res => {
        if (this.isActive && this.storageService.getToken()) {
          this.is_token_change = true;
          this.selectedCatalog();
        }
      });
  }

  private listenSelectedCart() {
    this.selectedCartSubs$ = this.storageService.getSelectedCartProduct().subscribe(res => {
      this.selectedProduct = [];
      if (res?.length) {
        this.selectedProduct.push(...res);
        this.toggleCartPannel();
      }
    },
      err => {
        console.log('err: ', err);
      });
  }

  selectedCatalog() {
    this.catalogSubs$ = this.store.select(selectCatalog).subscribe(list => {
      if (list && this.isActive) {
        this.catalogList = [];
        const home = {
          code: null,
          id: 0,
          name: 'Home',
          is_home: true,
          child_groups: [],
          parent_code: null,
          page_layout: null
        };
        this.catalogList.push(home, ...this.catalogRearrange(list));
        this.catalogSubs$.unsubscribe();
      }
    });
  }

  catalogRearrange(list, parentCode: string = null) {
    const tempVal = [];

    list.forEach(item => {
      const subValue = [];
      parentCode = item?.product_groups?.length ? item?.code : parentCode;
      if (item?.product_groups?.length || item?.child_groups?.length) {
        const value = item?.product_groups?.length ? item?.product_groups : item?.child_groups;
        subValue.push(...this.catalogRearrange(value, parentCode));
      }
      tempVal.push({
        code: item?.code,
        id: item?.id,
        name: item?.name,
        is_home: false,
        child_groups: subValue.length ? subValue : item?.child_groups,
        parent_code: parentCode === item?.code ? null : parentCode,
        page_layout: item?.page_layout ? item?.page_layout?.code : null,
      });
    });

    return tempVal;
  }

  updateCatalogList() {
    this.selectedCatalogCode = this.storageService.getCatalog();
    this.selectedCatalogCode = this.selectedCatalogCode ?
      this.selectedCatalogCode :
      this.catalogList.find(data => data?.default === true)?.code || this.catalogList.find(obj => true)?.code;
    this.getSelectedCatalog();
  }

  getCart() {
    this.cartService.currentCart$.subscribe((res) => {
      this.cart = res;
      this.noOfCartItems = res ? res.lines.length : 0;
    });
  }

  getStorageData() {
    return [this.storageService.getCurrentUser() || null, this.storageService.getCustomer() || null];
  }


  checkCisco(user) {
    return user && user.external_systems.length ? user.external_systems[0].system_code === 'cisco' ? true : false : false;
  }

  checkHasPassword(user) {
    return user && user.external_systems.length ? user.external_systems[0].has_password : false;
  }

  updateUserProperty(customer, currentUser) {
    if (currentUser?.is_external) {
      this.customerName = customer ? (customer?.contact_name || customer?.company_name) : (currentUser?.name || null);
    } else {
      this.customerName = customer ? customer.company_name : null;
      this.credit = customer ? customer.credit_limit : null;
    }

    this.isCiscoSystem = this.checkCisco(currentUser);
    this.hasPasswordSystem = this.checkHasPassword(currentUser);
    this.userName = currentUser?.force_sso ? (currentUser?.name ?? '') : (currentUser?.username ?? '');
    this.name = currentUser ? currentUser.name : null;
  }

  goToCartOverview() {
    if (!this.cart || !this.cart.lines.length) {
      return;
    }

    this.resetPagination('/cart-overview');
    const cart = this.storageService.getCart();
    this.headerService.goToCartOverview(cart.uuid);
  }

  jumpToCart() {
    this.toggleCartPannel();
    this.goToCartOverview();
  }

  togglerSearch() {
    this.searchToggler = !this.searchToggler;
    this.searchValue = '';
  }
  toggleCartPannel() {
    this.cartToggler = !this.cartToggler;
  }

  checkExternalSystems() {
    if (this.storageService.getCurrentUser().external_systems.length > 0) {
      if (
        this.storageService.getCurrentUser().external_systems[0].system_code ===
        'cisco'
      ) {
        this.isCiscoSystem = true;
      }
      this.hasPasswordSystem = this.storageService.getCurrentUser().external_systems.has_password;
    }
  }

  goToExistingCustomer() {
    this.resetPagination('/client');
    if (this.is_external && this.hasSupplierCustomerPermission) {
      this.router.navigateByUrl('/customer-list');
    } else {
      this.headerService.goToExistingCustomer();
    }

  }

  goToDepotHistory() {
    this.headerService.goToDepotHistory();
  }

  navigateToPage(url: string) {
    this.headerService.navigatePage(url);
  }


  validationOfSystem() {
    if (this.hasPasswordSystem) {
      this.goToCisco('');
    } else {
      this.showPasswordField = true;
    }
  }

  setPassword() {
    this.goToCisco(this.passwordOfMakeQutation);
  }

  goToCisco(password) {
    this.headerService.punchOut(password).subscribe(
      (res) => {
        this.hasPasswordSystem = true;
        this.showPasswordField = false;
        const win = window.open(res.data.punch_out_url, '_blank');
        win.focus();
      },
      (err) => {
        console.log('err: ', err);
      }
    );
  }

  showSideMenu(showSdieNav: any) {
    this.sideBarToggler = !this.sideBarToggler;
    this.sideBarService.changeSideMenuSubject(true);
    this.showSideBar = showSdieNav;
    this.togglerService.changeData(this.showSideBar);
  }

  hideSideMenu(showSdieNav: any) {
    this.sideBarToggler = !this.sideBarToggler;
    this.showSideBar = showSdieNav;
    this.togglerService.changeData(this.showSideBar);
  }

  showMobileMenu() { }

  onLogOut() {
    this.authService.logout()
      .subscribe(response => {
        this.is_external = false;
        this.isActive = false;
        this.searchValue = '';
        this.searchToggler = false;
        this.storageService.removeReComCart();
        this.storageService.removeAllStorage();
        this.favoriteProductsService.clearFavoriteProducts();
        this.sideBarService.changeHomeData('');
        this.cartService.changeCurrentCart(null);
        this.is_token_change = false;
        this.catalogList = null;
        this.store.dispatch(new ClearCatalog(null));
        this.store.dispatch(new PermissionSuccessAction([]));
        this.router.navigate(['login']).then(res => {
          location.reload();
        });
      },
        error => {
          console.log('error: ', error);
        });

  }

  mouseenter(mouseEentered: any) {
    if (mouseEentered) {
      this.isMouseHovered = true;
    }
  }
  mouseleave(mouseLeaved: any) {
    if (mouseLeaved) {
      this.isMouseHovered = false;
    }
  }

  getSelectedCatalog() {
    if (this.selectedCatalogCode) {
      this.storageService.setCatalog(this.selectedCatalogCode);
      const value = this.catalogList.filter(element => element?.code === this.selectedCatalogCode);
      this.parentGroupList = value.find(obj => true)?.product_groups;
      const firstParentGroup = this.parentGroupList?.find(obj => true);
      // tslint:disable-next-line:max-line-length
      // tslint:disable-next-line:no-unused-expression
      this.parentGroupList?.length ? this.storageService.setProductGroup(firstParentGroup?.code) : '';
      if (this.parentGroupList?.length) {
        this.selectedProductGroupCode = firstParentGroup?.code;
        this.selectedPageLayout = firstParentGroup?.page_layout?.code;
        this.store.dispatch(new SelectCatalogAndProductGroupAction({
          catalogCode: this.selectedCatalogCode,
          productGroupCode: this.selectedProductGroupCode,
          page_layout: this.selectedPageLayout,
          product_search_keyword: null,
        }));
        this.store.dispatch(new ChildGroupLoadAction(firstParentGroup?.code));
      } else {
        this.store.dispatch(new SelectCatalogAndProductGroupAction({
          catalogCode: this.selectedCatalogCode,
          productGroupCode: null,
          page_layout: null,
          product_search_keyword: null,
        }));
      }
    }
  }

  selectProductGroup(list: any) {
    if (list?.code) {
      this.storageService.setProductGroup(list?.code);
      this.store.dispatch(new SelectCatalogAndProductGroupAction({
        catalogCode: this.selectedCatalogCode,
        productGroupCode: list?.code,
        page_layout: list?.page_layout?.code,
        product_search_keyword: null,
      }));
      this.resetPagination('/product-menu');
      this.selectedProductGroupCode = list?.code;
      this.selectedPageLayout = list?.page_layout?.code;
      this.store.dispatch(new ChildGroupLoadAction(list?.code));
      this.router.navigateByUrl('/home');
    }
  }

  searchProduct(searchString: string) {
    if (searchString.length >= 4 && !this.isHomeUrl) {
      if (this.searchProductKey.observers.length === 0) {
        this.searchProductKey
          .pipe(debounceTime(1000), distinctUntilChanged())
          .subscribe(query => {
            this.loadProducts(query);
          });
      }
      this.searchProductKey.next(searchString);
    }
  }

  loadProducts(searchString: string) {
    this.headerService.getProductSearch(searchString)
      .subscribe(res => {
        if (res.data && res.data.results) {
          this.searchProductList = res.data.results;
        }

      }, error => {
        console.log('error: ', error);

      });
  }

  onSearchProduct() {
    if (this.catalogList?.length) {
      // const search = event.target.value;
      // event.target.value = null;
      this.resetPagination('/search-product');
      this.storageService.removeDynamicValue('catalogCode');
      this.storageService.removeDynamicValue('groupCode');
      this.router.navigate(['/product-list'], {
        queryParams: {
          pageLayout: this.storageService.getDynamicValue('pageLayout', false) || null,
          keyword: this.searchValue,
          global: true
        },
        relativeTo: this.activateRoute,
      });
    }
  }

  onSelectedProduct(product: any) {
    this.router.navigate(['/product-details', `${product.code}`]);
    setTimeout(() => {
      location.reload();
    }, 100);
  }

  jumpToPage(pageUrl: string) {
    this.resetPagination(`/${pageUrl}`);
    this.router.navigateByUrl(`/${pageUrl}`);
  }

  resetPagination(pageName: string) {
    this.selectedRmaType = null;
    if ('/' + pageName !== this.router.url) {
      this.storageService.setPagination(null);
      this.storageService.setSearchKeyword(null);
    }
  }

  deleteCart() {
    this.cartService.deleteCart(this.cart?.uuid).subscribe(response => {
      this.storageService.removeCart();
      this.storageService.removeDynamicValue('cartProduct');
      this.storageService.removeShippingAddressID();
      this.cartService.changeCurrentCart(null);
      this.router.navigateByUrl('/home');
      setTimeout(() => {
        window.localStorage.removeItem('selectedContactAccountTypesCustomer');
      }, 1500);
    }, error => {
      console.log('error: ', error);
      // this.storageService.removeCart();
    });
  }


  onChangeRmaTypes(selectedRmaType) {
    this.resetPagination('rma');
    this.selectedRmaType = selectedRmaType;
    if (this.selectedRmaType && this.selectedRmaType?.rma_flow_type === ERmaFlowTypes.DOA) {
      this.router.navigate([`/order-status`],
        {
          queryParams: {
            rma_type: this.selectedRmaType?.code || null,
          }, queryParamsHandling: 'merge'
        });
    } else if (this.selectedRmaType && this.selectedRmaType?.rma_flow_type === ERmaFlowTypes.RMA_WITHOUT_ORIGINAL_ORDER) {
      this.router.navigate([`/rma/create/${this.selectedRmaType?.code}`],
        {
          queryParams: {
            rma_type: this.selectedRmaType?.rma_flow_type || null,
          }, queryParamsHandling: 'merge'
        });
    } else {
      this.router.navigate([`/welcome`]);
    }
  }

  gotoOrders() {
    this.resetPagination('order-status');
    const doa = this.rmaTypes?.find(rma => rma?.rma_flow_type === ERmaFlowTypes.DOA) || null;
    if (doa) {
      this.router.navigate([`/order-status`],
        {
          queryParams: {
            rma_type: doa?.code || null,
          }, queryParamsHandling: 'merge'
        });
    } else {
      this.router.navigate([`/order-status`],
        {
          queryParams: {
          }, queryParamsHandling: 'merge'
        });
    }
  }

  gotoCustomer() {
    const customer = this.storageService?.getCustomer();
    let [currentUser] = this.getStorageData();
    const uuid = customer?.uuid ?? currentUser?.contact?.uuid;
    this.router.navigate([`/customer/${uuid}`], {
      queryParams: {
        contact_person: this.cart?.contact_person_uuid || null,
      },
      queryParamsHandling: 'merge'
    });
  }

  ngOnDestroy() {
    if (this.permissionSubs$) {
      this.permissionSubs$.unsubscribe();
    }
    if (this.catalogSubs$) {
      this.catalogSubs$.unsubscribe();
    }
    if (this.selectedCartSubs$) {
      this.selectedCartSubs$.unsubscribe();
    }
  }
}
