import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class AddCustomerService {
    constructor(private apiBaseService: ApiBaseService) { }

    getCountries() {
        return this.apiBaseService.get('contacts/country-types');
    }

    submitNewAddress(data: any, contactUuid: string): Observable<any> {
        const postUri = `contacts/${contactUuid}/address`;
        return this.apiBaseService.post(postUri, data);
    }

    updateNewAddress(data: any, contactUuid: string, uuid: string): Observable<any> {
        const updateUri = `contacts/${contactUuid}/address/${uuid}`;
        return this.apiBaseService.put(updateUri, data);
    }

    getContactAddress(contactUuid: string, addressUuid: string): Observable<any> {
        const retriveUri = `contacts/${contactUuid}/address/${addressUuid}`;
        return this.apiBaseService.get(retriveUri);
    }

    createContactPerson(contactUuid: string, payload): Observable<any> {
        const url = `contacts/${contactUuid}/persons`;
        return this.apiBaseService.post(url, payload, 'v2');
    }

    deleteContactPerson(contactUuid: string, contact_person_uuid: string): Observable<any> {
        const url = `contacts/${contactUuid}/persons/${contact_person_uuid}`;
        return this.apiBaseService.delete(url, 'v2');
    }
}
