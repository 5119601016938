<section class="inner-content-wrapper inner-content-blue-bg">
  <div class="container">
    <app-steps [activeStep]="'order-success'">
    </app-steps>

    <div class="facts-box mt-n3">
      <div class="what-is-trade-in bg-white p-32 mb-4">
        <h4 class="tri-title-32 font-kpn-black green-text mb-3">Bedankt voor je bestelling</h4>
        <h6 class="font-kpn-bold">{{numberMsg}}</h6>
        <p class="kpn-font-color font-kpn-extended mb-2">
          {{description}}
        </p>
        <div class="pt-1">
          <button  (click)="goDetails()" class="btn btn-primary rounded-btn px-4">&nbsp; Bekijk je bestelling &nbsp;</button>
        </div>
      </div>
    </div>

    <!-- <div class="new-cart-box-overview pt-2 mb-5">
      <div class="row pt-4">
        <div class="col">
          <h3 class="title-2 mb-1 green-text">{{'ORDER_SUCCESS.THANK_YOU_FOR_YOUR' | transloco }} {{!quoteRef ?  ('ORDER_SUCCESS.QUOTE_REQUEST'| transloco ) : ('ORDER_SUCCESS.ORDER'| transloco )}} </h3>
          <h5 class="title-4 pt-3">{{numberMsg}}</h5>
          <div class="row">
            <div class="col-md-6">
              <div class="pt-3">
                <p class="body-text-1 mb-3">{{description}}</p>
              </div>
              <button (click)="goDetails()" class="btn btn-primary rounded-btn mb-2 mr-2">
                {{btnText}}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div style="visibility: hidden;">
      <app-payment *ngIf="!order?.is_paid && order?.direct_payment_required && !order?.is_total_amount_paid"
      [order]="order" [contact]="contact" redirect="true">
    </app-payment>
    </div>
  </div>
</section>
