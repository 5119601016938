import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { selectCatalog } from 'src/app/store/selectors/catalog.selectors';
import { ExportFileService } from './export-file.service';

import { IDropdownSettings } from 'ng-multiselect-dropdown';

@Component({
  selector: 'app-export-file',
  templateUrl: './export-file.component.html',
  styleUrls: ['./export-file.component.scss']
})
export class ExportFileComponent implements OnInit, OnDestroy {

  selectedPrice: any = [];
  dropdownSettings: IDropdownSettings = {};
  isLoading = false;
  catalogList: any[] = [];
  priceList: any = [];
  catalogSubs$: Subscription;
  selectedCatalogCode: any;
  selectedPriceCode: string;
  exportPercentage = 0;
  fileName: string;

  constructor(
    public store: Store<IAppState>,
    private exportFileService: ExportFileService
  ) { }

  ngOnInit() {
    this.getCatalogList();
    this.getPriceList();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'code',
      textField: 'name',
      selectAllText: 'Selecteer alles',
      unSelectAllText: 'Deselecteer alles',
      itemsShowLimit: 3,
      allowSearchFilter: true
    };
  }

  onItemSelect(item: any) {
    console.log(item);
    console.log('selectedItems: ', this.selectedPrice);
  }
  onSelectAll(items: any) {
    console.log(items);
  }

  getCatalogList() {
    this.catalogSubs$ = this.store.select(selectCatalog).subscribe(list => {
      if (list) {
        this.catalogList = list;
        this.selectedCatalogCode = this.catalogList.find(obj => true);
      }
    });
  }

  getPriceList() {
    this.exportFileService.getPriceList().subscribe((res) => {
      if (res.data.results) {
        this.priceList = this.priceList.concat(...res.data.results);
      }
    }, (err) => { });
  }

  exportPriceList() {
    this.exportPercentage = 0;
    this.isLoading = true;
    const price = this.selectedPrice.map(
      data => ({ code: data.code })
    );

    this.fileName = this.selectedCatalogCode?.name + ' Prijslijst';

    this.exportFileService.exportPrice(this.selectedCatalogCode?.code, price).subscribe(
      (res) => {
        if (res?.data?.task_id) {
          this.exportStatus(res?.data?.task_id);
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  exportStatus(taskId: string) {
    const delay = 5;
    let timer = null;
    this.exportFileService.checkExportStatus(taskId).subscribe(
      (res) => {
        const current = res?.data?.current;
        if (current === 0) {
          this.exportPercentage = (this.exportPercentage + delay);
          timer = setTimeout(() => {
            this.exportStatus(taskId);
          }, delay * 1000);
        } else if (current === 100) {
          this.exportPercentage = current;
          clearTimeout(timer);
          const id = res?.data?.report ? res?.data?.report.split('/')[4] : null;
          if (id) {
            this.downloadFile(id);
          }
        }
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }

  async downloadFile(id: number) {
    await this.exportFileService.downloadFile(id, this.fileName, 'xlsx');
    this.isLoading = false;
    this.selectedPrice = [];
    this.fileName = null;
  }

  ngOnDestroy() {
    if (this.catalogSubs$) {
      this.catalogSubs$.unsubscribe();
    }
  }
}
