<section class="inner-content-wrapper inner-content-blue-bg">
  <div class="container">
    <app-steps *ngIf="cart" [activeStep]='activeStep'></app-steps>
    <div *ngIf="pageLoading; else cartDetails">
      <div class="section-loader p-0 pb-2">
        <div class="kpn-spinner">
          <img src="/assets/images/kpn-loader.gif" alt="Loading">
        </div>
      </div>
    </div>
    <ng-template #cartDetails>
      <div *ngIf="isLoading || isVoucherLoading" class="full-screen-loader" id="fullScreenLoader">
        <div class="loader">
            <div class="lds-spinner">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
      </div>

      <div class="row align-items-center mb-3" *ngIf="hasCreditShowPermission && cart?.credit_limit_details">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span> {{'CART.AVAILABLE_CREDIT' | transloco }}: {{cart?.credit_limit_details?.available_credit |
                currencyFormat}}</span><br>
              <span> {{'CART.CREDIT_LIMIT' | transloco }}: {{cart?.credit_limit_details?.credit_limit |
                currencyFormat}}</span> <br>
              <span class="kphicon-uniE952"></span>
              <b> {{cart?.credit_limit_details?.message}}</b>
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <h3 class="font-kpn-black tri-title-40 line-height-40 green-text p-2 mt-n2">Jouw overzicht</h3>

          <div class="bg-white tri-shadow-xl tri-rounded-20 p-32" *ngIf="productLines?.length">
            <div class="add-cart-prd add-cart-prd-flex-gap">
              <div class="add-cart-prd-item" *ngFor="let line of productLines;let i = index">
                <div class="row">
                  <div class="col-md-8 pr-0">
                    <div class="mb-4">
                      <span class="font-kpn-extended">{{ line?.brand }}</span>
                      <h4 class="font-kpn-bold fs-26 pt-1 mb-0">{{line?.product_name}}</h4>
                      <span *ngIf="line?.memory_size" class="font-kpn-extended grey-text">Geheugen: {{
                        line?.memory_size }}</span>
                    </div>
                    <div class="qty-in-di-box d-inline-flex align-items-center mb-4" *ngIf="!line?.voucher_code">
                      <button *ngIf="!line?.voucher_code" (click)="decrementQuantity(line)"
                        [disabled]="line.quantity === 1" class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                      <input appPositiveInteger [(ngModel)]="line.quantity" [(ngModel)]="line.quantity" (keyup.enter)="onEnter(line)"
                        [disabled]="line?.voucher_code" class="in-di-input font-kpn-extended bg-white">
                      <button *ngIf="!line?.voucher_code" (click)="incrementQuantity(line)"
                        class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                    </div>
                    <div>

                      <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
                        *ngIf="line?.account_types?.length">
                        <select name="" class="form-control" id="" [(ngModel)]="line.extra.contact_account_type_id"
                          (ngModelChange)="updateCartCartItem(line)">
                          <option [ngValue]="null">{{'CART.SELECT_ACCOUNT_TYPE' | transloco}}</option>
                          <option *ngFor="let item of line?.account_types" [ngValue]="item.id">
                            {{item?.account_type_code}} ({{item?.account_id}})
                          </option>
                        </select>
                      </div>
                      <small class="danger-text"
                        *ngIf="line?.account_type_required && !line.extra.contact_account_type_id">{{'CART.PLEASE_SELECT_ACCOUNT_TYPE'
                        | transloco}}</small>
                      <div class="col-md-8" *ngIf="line?.account_types?.length && line.extra.contact_account_type_id">
                        <h5 class="mb-0 pb-0 pt-2">{{'CART.DEFAULT_RATE_PLAN' | transloco}}</h5>
                        <div ngbRadioGroup>
                          <div class="radio_group">
                            <label ngbButtonLabel class="default-radio radio-flat mb-2">
                              <div class="radio-btn">
                                <input id="payment_type_{{i}}_yes" ngbButton name="payment_type_{{i}}_yes"
                                  [disabled]="isLoading" (change)="setDefaultRatePlanToYes(line)"
                                  [(ngModel)]="line.extra.is_default_rate_plan" type="radio" [value]="true" />
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">
                                {{'CART.YES' | transloco}}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div ngbRadioGroup>
                          <div class="radio_group">
                            <label ngbButtonLabel class="default-radio radio-flat mb-2">
                              <div class="radio-btn">
                                <input ngbButton id="payment_type_{{i}}_no" name="payment_type_{{i}}_no"
                                  [disabled]="isLoading" (change)="updateCartCartItem(line)"
                                  [(ngModel)]="line.extra.is_default_rate_plan" type="radio" [value]="false" />
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">
                                {{'CART.NO' | transloco}}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div *ngIf="!line.extra.is_default_rate_plan">
                          <div class="form-group kpn-form-group pt-3">
                            <p class="mb-0 pb-0">{{'CART.COMM_PLAN' | transloco}}</p>
                            <div class="d-flex">
                              <input type="text" (keyup.enter)="updateCommPlan(line)" (focusout)="updateCommPlan(line)"
                                class="form-control kpn-form-control" placeholder="Comm plan"
                                [(ngModel)]="line.extra.comm_plan" />
                              <button (click)="updateCommPlan(line)" title="add"
                                class="btn btn-success py-0 my-0 px-2 d-flex align-items-center">
                                <span *ngIf="commPlanLoading; else plusIcon"
                                  class="spinner-border spinner-border-sm mb-1" role="status" aria-hidden="true"></span>
                                <ng-template #plusIcon>
                                  <i class="kphicon-uniE985"></i>
                                </ng-template>
                              </button>
                            </div>
                          </div>
                          <div class="form-group kpn-form-group">
                            <p class="mb-0 pb-0">{{'CART.RATE_PLAN' | transloco}}</p>
                            <div class="d-flex">
                              <input type="text" (keyup.enter)="updateRatePlan(line)" (focusout)="updateRatePlan(line)"
                                class="form-control kpn-form-control" placeholder="Rate plan"
                                [(ngModel)]="line.extra.rate_plan" />
                              <button (click)="updateRatePlan(line)" title="add"
                                class="btn btn-success py-0 my-0 px-2 d-flex align-items-center">
                                <span *ngIf="ratePlanLoading; else plusIcon"
                                  class="spinner-border spinner-border-sm mb-1" role="status" aria-hidden="true"></span>
                                <ng-template #plusIcon>
                                  <i class="kphicon-uniE985"></i>
                                </ng-template>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>


                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)"
                      (click)="deleteCartItem(line)" class="add-cart-remove-btn mb-3"><i
                        class="kphicon-uniE92A"></i></button>
                    <div class="add-cart-pr-img" *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                      <img *ngIf="line?.image" src="{{line?.image}}" alt="">
                      <img *ngIf="!line?.image && !line.brand?.includes('Cisco')" src="/assets/images/noimage.png"
                        alt="">
                      <img *ngIf="!line?.image && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                        src="/assets/images/cisco-networking.png" alt="">
                    </div>
                  </div>
                </div>
                <hr class="border-color-tri mt-2">
                <div class="d-flex justify-content-between align-items-center mb-n1 mt-n1">
                  <span class="font-kpn-extended">Totaal</span>
                  <h4  class="h4 font-kpn-black green-text mb-0 d-flex align-items-center">
                    {{ (utilityService.isInclVAT() ? line?.total_amount : line?.total_amount_ex_vat) | currencyFormat}}
                    <i *ngIf="!line?.isSubLineOpen" (click)="line.isSubLineOpen=!line.isSubLineOpen" class="kpn-ui-icon ui-chevron-down" role="button"></i>
                    <i *ngIf="line?.isSubLineOpen" (click)="line.isSubLineOpen=!line.isSubLineOpen" class="kpn-ui-icon ui-chevron-up" role="button"></i>
                  </h4>
                </div>
                <div class="pt-2 pr-4" *ngIf="line?.isSubLineOpen">
                  <p  class="d-flex align-items-center justify-content-between mb-1"
                    >
                    <span class="font-kpn-extended">{{line?.product_name}}</span>
                    <span class="font-kpn-extended">{{(utilityService.isInclVAT()? line?.amount : line?.amount_ex_vat ) | currencyFormat : ''}}</span>

                  </p>
                  <p  class="d-flex align-items-center justify-content-between mb-1"
                    *ngFor="let subLine of line?.sub_lines">
                    <span class="font-kpn-extended">{{subLine?.product_name}}</span>
                    <span class="font-kpn-extended">{{(utilityService.isInclVAT() ? subLine?.amount : subLine?.amount_ex_vat) | currencyFormat  : ''}}</span>

                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-4 pl-md-0">
          <h3 class="font-kpn-black tri-title-40 line-height-40 green-text p-2 mt-n2">Winkelmand</h3>

          <div class="bg-white tri-shadow-xl tri-rounded-20 px-3 py-4 mb-4">
            <h6 class="font-kpn-bold green-text fs-18 mb-1">Overzicht</h6>

            <div class="mb-4 pl-2">
              <!-- <p class="d-flex justify-content-between mb-1" *ngFor="let line of productLines">
                <span class="font-kpn-extended">{{ line?.brand }} {{line?.product_name}} </span>
                <strong class="font-kpn-extended font-weight-normal" >{{ line?.total_amount_ex_vat | currencyFormat : ''}}</strong>
              </p> -->
              <p class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-kpn-extended">Artikelen({{ cartSummary?.total_products_qty ?? 0 }})</span>
                <strong class="font-kpn-extended font-weight-normal pr-3">{{(utilityService.isInclVAT() ?  cartSummary?.total_products_amount : cartSummary?.total_products_amount_ex_vat) |
                  currencyFormat: ''}}</strong>
              </p>
              <p class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-kpn-extended">Verzendkosten</span>
                <strong class="font-kpn-extended font-weight-normal pr-3">{{(utilityService.isInclVAT() ? cartSummary?.total_shipping_cost : cartSummary?.total_shipping_cost_ex_vat) | currencyFormat: ''}}</strong>
              </p>
              <!-- <p class="d-flex align-items-center justify-content-between align-items-center mb-1" *ngFor="let additionalCostLine of additionalCostLies">
                <span class="font-kpn-extended">{{ additionalCostLine?.brand }} {{additionalCostLine?.product_name}} </span>
                <strong class="font-kpn-extended font-weight-normal">{{ additionalCostLine?.total_amount_ex_vat | currencyFormat : ''}}</strong>
              </p> -->
              <p class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-kpn-extended">BTW</span>
                <strong class="font-kpn-extended font-weight-normal"  [ngClass]="utilityService.isInclVAT() ? 'col-5 text-left' : 'pr-3'">{{cartSummary?.total_vat |
                  currencyFormat: ''}}</strong>
              </p>
            </div>
            <div class="tri-rounded-16 px-3 py-3 mb-3 cart-total-price">
              <p class="d-flex align-items-center justify-content-between mb-0 line-height-20">
                <span class="font-kpn-extended">Totaal prijs</span>
                <strong class="font-kpn-extended mb-0 font-weight-normal">{{cartSummary?.total_amount | currencyFormat: ''}}</strong>
              </p>
            </div>
            <p class="voucher-item mb-1" *ngFor="let voucher of voucherLines">
              <span><i class="kphicon-uniE91D"></i> {{ voucher?.voucher_code }}</span>
              <a href="javascript:void(0)" (click)="deleteCartItem(voucher)">{{'CART.REMOVE' | transloco }} </a>
            </p>
            <div class="pb-1">
              <button (click)="isVoucherVisible = !isVoucherVisible" class="btn fs-18 btn-link text-decoration-none d-flex align-items-center mb-0 px-0">
                <i class="kphicon-uniE907 fs-26 mr-1"></i>Vouchercode invoeren
                <sup *ngIf="voucherRequiredPermission">*</sup>
              </button>
            </div>
            <div class="mb-4" *ngIf="isVoucherVisible">
              <span class="text-info text-sm" *ngIf="isVoucherVisible && voucherCode">
                Press enter to apply voucher
              </span>
                <div class="input-group mb-3">
                  <input type="text" [(ngModel)]="voucherCode" (ngModelChange)="voucherErrorMessage=''" (keyup.enter)="onEnterVoucher()"
                  [disabled]="disabledBtn" class="form-control invalid-bg-size-23" [ngClass]="{'is-invalid': ((voucherErrorMessage?.length > 0) && voucherCode) ? true : false}" placeholder="Voucher code" aria-label="Voucher code" aria-describedby="basic-addon2">
                  <div class="input-group-append">
                    <button [disabled]="!voucherCode?.trim()?.length" class="btn btn-success rounded-right " type="button" (click)="onEnterVoucher()">{{'CART.ADD' | transloco }}</button>
                  </div>
                  <span class="invalid-feedback fs-16 font-kpn-extended">
                    {{ voucherErrorMessage }}
                  </span>
                </div>
            </div>

            <p class="collapse-link fs-18 mb-3" *ngIf="projectList.length">
              <button (click)="isProjectVisible = !isProjectVisible" class="btn fs-18 btn-link text-decoration-none d-flex align-items-center mb-0 px-0">
                <i class="kphicon-uniE907 fs-26 mr-1"></i> {{'CART.LINK_PROJECT' | transloco }}</button>
            </p>
            <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
              *ngIf="projectList.length && isProjectVisible">
              <select name="" class="form-control" id="" [(ngModel)]="selectedProject">
                <option [ngValue]="null">{{'CART.SELECT_PROJECT' | transloco }}</option>
                <option *ngFor="let item of projectList" [ngValue]="item">
                  {{item?.project_price?.name}}
                </option>
              </select>
              <button class="btn" (click)="removeProject()">
                <i class="kphicon-uniE926"></i>
              </button>
            </div>
            <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
              *ngIf="projectList.length && isProjectVisible && selectedProject">
              <input type="text" [(ngModel)]="selectedProjectRef" class="form-control reference-field"
                [placeholder]="'ORDER_OVERVIEW.REFERENCE_NUMBER' | transloco " (keyup.enter)="projectSelection()"
                [disabled]="onProjectPriceLoading" />
              <button class="btn py-1" (click)="projectSelection()" [disabled]="!selectedProjectRef">
                <span *ngIf="onProjectPriceLoading;else plusIcon" class="spinner-border spinner-border-sm mb-1"
                  role="status" aria-hidden="true"></span>
                <ng-template #plusIcon>
                  <i class="kphicon-uniE985"></i>
                </ng-template>
              </button>
            </div>


            <div class="row align-items-center pt-3" *ngIf="account_type_error">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{account_type_error}}
                  </p>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3" *ngIf="cartError">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{cartError}}
                  </p>
                </div>
              </div>
            </div>
            <div class="row align-items-center pt-3" *ngIf="!allowNextWithVoucher">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{'CART.CAN_ONLY_CONTINUE_IF_ENTER_VOUCHER' | transloco }}
                </div>
              </div>
            </div>
            <div class="info-bar-red  px-3 pt-3" *ngIf="!cart?.is_valid">
              <div class="mb-3 d-flex align-items-center justify-content-between">
                <p [innerHTML]="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER ? eMessages.NO_VAT_NUMBER_MSG:
                    eMessages.INVALID_CART">
                </p>
              </div>
              <app-vat-number-input *ngIf="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER"
                (reFetchCartCart)="reFetchCart($event)"></app-vat-number-input>
            </div>
            <div>
              <button (click)="onClickCartToQuote()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
                !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid" *ngIf="hasQuoteCreationPermission"
                class="btn btn-block btn-outline-primary border-2 mt-2 rounded-btn"> {{'CART.CREATE_AN_INVOICE' | transloco
                }}</button>

              <button class="btn btn-block btn-primary mt-2 rounded-btn" (click)="goToOrderOverview()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
                !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid">Doorgaan met bestellen</button>

              <button class="btn btn-block btn-primary mt-2 rounded-btn" (click)="sendRequest()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
                              !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid || sendingSpecialRequest"
                *ngIf="hasQuoteCreationPermission && cart?.discount_request_allowed">
                {{'CART.REQUEST_SPECIAL_QUOTE_PRICE' | transloco
                }}
                <span *ngIf="sendingSpecialRequest" class="spinner-border spinner-border-sm mr-2" role="status"
                  aria-hidden="true"></span>
              </button>
            </div>
          </div>

          <!-- <div class="bg-white tri-shadow-xl tri-rounded-20 p-4">
            <h6 class="font-kpn-bold green-text fs-18 mb-1">Overzicht</h6>
            <p class="d-flex justify-content-between mb-1" *ngFor="let line of productLines">
              <span class="font-kpn-extended">{{ line?.brand }} {{line?.product_name}} </span>
              <strong class="font-kpn-bold green-text">{{ line?.total_amount_ex_vat | currencyFormat : ''}}</strong>
            </p>
            <hr class="mb-3">
            <div>
              <p class="d-flex align-items-center justify-content-between mb-0">
                <span class="font-kpn-extended">Subtotaal</span>
                <strong class="font-kpn-black h4 green-text mb-0">{{cart?.total_amount_ex_vat |
                  currencyFormat: ''}}</strong>
              </p>
              <p class="d-flex align-items-center justify-content-between mb-1">
                <span class="font-kpn-extended">BTW</span>
                <strong class="font-kpn-bold green-text">{{cart?.total_amount_vat |
                  currencyFormat: ''}}</strong>
              </p>
            </div>
            <hr class="mb-3">
            <p class="d-flex align-items-center justify-content-between mb-1">
              <span class="font-kpn-extended">Voorlopig totaal</span>
              <strong class="font-kpn-black green-text h4 mb-0">{{cart?.total_amount | currencyFormat: ''}}</strong>
            </p>
            <p class="d-flex align-items-center justify-content-between mb-1">
              <span class="font-kpn-extended text-black-50">
                Verzending
                <i class="tri-tooltip-btn trade-in-btn-ttb-sm ml-1" aria-controls="collapseExample">i</i>

              </span>
              <span class="font-kpn-extended green-text">Pick-up</span>
            </p>


            <div>
              <button class="btn btn-block btn-primary mt-2 rounded-btn" (click)="goToOrderOverview()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
                !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid">Doorgaan</button>
              <button class="btn btn-block btn-primary mt-2 rounded-btn" (click)="sendRequest()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
                              !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid || sendingSpecialRequest"
                *ngIf="hasQuoteCreationPermission && cart?.discount_request_allowed">
                {{'CART.REQUEST_SPECIAL_QUOTE_PRICE' | transloco
                }}
                <span *ngIf="sendingSpecialRequest" class="spinner-border spinner-border-sm mr-2" role="status"
                  aria-hidden="true"></span>
              </button>
              <button (click)="onClickCartToQuote()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
                !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid" *ngIf="hasQuoteCreationPermission"
                class="btn btn-block btn-outline-primary mt-2 rounded-btn"> {{'CART.CREATE_AN_INVOICE' | transloco
                }}</button>
            </div>
          </div> -->
        </div>
      </div>
    </ng-template>

    <!-- <div>
      <div class="row align-items-center" *ngIf="hasCreditShowPermission && cart?.credit_limit_details">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span> {{'CART.AVAILABLE_CREDIT' | transloco }}: {{cart?.credit_limit_details?.available_credit |
                currencyFormat}}</span><br>
              <span> {{'CART.CREDIT_LIMIT' | transloco }}: {{cart?.credit_limit_details?.credit_limit |
                currencyFormat}}</span> <br>
              <span class="kphicon-uniE952"></span>
              <b> {{cart?.credit_limit_details?.message}}</b>
            </p>
            <button class="kphicon-uniE926"></button>
          </div>
        </div>
      </div>
      <div class="new-cart-box p-0 mb-3" *ngIf="cart?.lines?.length">
        <div class="item-row-head">
          <div class="row new-cart-thead align-items-center">
            <div class="col">
              <div class="th-product green-text">
                <i class="kphicon-uniE9A91 mr-3"></i>
                {{'CART.ORDER' | transloco }}
              </div>
            </div>
            <div class="col-auto">
              <div class="row">
                <div class="col-auto">
                  <label class="t-head-item art-nr">{{'CART.ARTICLE_NUMBER' | transloco }}</label>
                </div>
                <div class="col-auto">
                  <label class="t-head-item quantity">{{'CART.NUMBER' | transloco }}</label>
                </div>
                <div class="col-auto">
                  <label class="t-head-item quantity">{{'CART.DELIVERY_METHOD' | transloco }}</label>
                </div>
                <div class="col-auto">
                  <label class="t-head-item gross-price">{{'CART.PRICE' | transloco }}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="new-cart-box p-0 mb-3" *ngIf="cart?.lines?.length">
        <div class="item-rows" *ngFor="let line of productLines; let i = index">
          <div class="row new-cart-tbody">
            <div class="col">
              <div class="td-product">
                <div class="row">
                  <div class="col-auto">
                    <div class="cart-img-item">
                      <img *ngIf="line?.image" src="{{line?.image}}" alt="">
                      <img *ngIf="!line?.image && !line.brand?.includes('Cisco')" src="/assets/images/noimage.png"
                        alt="">
                      <img *ngIf="!line?.image && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                        src="/assets/images/cisco-networking.png" alt="">
                    </div>
                  </div>
                  <div class="col ml-md-0 pl-0">
                    <div class="product-name-details">
                      <h6 class="name-title mb-0 pt-2">{{line?.product_name}}
                      </h6>
                      <small *ngIf="line?.promotion_description" class="pt-0" >
                        <span class="badge badge-info" [innerHTML]="line?.promotion_description"></span>
                      </small>
                      <p class="att-details" *ngIf="productType?.includes( line?.product_type)">
                      </p>
                      <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
                        *ngIf="line?.account_types?.length">
                        <select name="" class="form-control" id="" [(ngModel)]="line.extra.contact_account_type_id"
                          (ngModelChange)="updateCartCartItem(line)">
                          <option [ngValue]="null">{{'CART.SELECT_ACCOUNT_TYPE' | transloco}}</option>
                          <option *ngFor="let item of line?.account_types" [ngValue]="item.id">
                            {{item?.account_type_code}} ({{item?.account_id}})
                          </option>
                        </select>
                      </div>
                      <small class="danger-text"
                        *ngIf="line?.account_type_required && !line.extra.contact_account_type_id">{{'CART.PLEASE_SELECT_ACCOUNT_TYPE'
                        | transloco}}</small>
                      <div *ngIf="line?.account_types?.length && line.extra.contact_account_type_id">
                        <h5 class="mb-0 pb-0 pt-2">{{'CART.DEFAULT_RATE_PLAN' | transloco}}</h5>
                        <div ngbRadioGroup>
                          <div class="radio_group">
                            <label ngbButtonLabel class="default-radio radio-flat mb-2">
                              <div class="radio-btn">
                                <input id="payment_type_{{i}}_yes" ngbButton name="payment_type_{{i}}_yes"
                                  [disabled]="isLoading" (change)="setDefaultRatePlanToYes(line)"
                                  [(ngModel)]="line.extra.is_default_rate_plan" type="radio" [value]="true" />
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">
                                {{'CART.YES' | transloco}}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div ngbRadioGroup>
                          <div class="radio_group">
                            <label ngbButtonLabel class="default-radio radio-flat mb-2">
                              <div class="radio-btn">
                                <input ngbButton id="payment_type_{{i}}_no" name="payment_type_{{i}}_no"
                                  [disabled]="isLoading" (change)="updateCartCartItem(line)"
                                  [(ngModel)]="line.extra.is_default_rate_plan" type="radio" [value]="false" />
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">
                                {{'CART.NO' | transloco}}
                              </span>
                            </label>
                          </div>
                        </div>
                        <div *ngIf="!line.extra.is_default_rate_plan">
                          <div class="form-group kpn-form-group pt-3">
                            <p class="mb-0 pb-0">{{'CART.COMM_PLAN' | transloco}}</p>
                            <div class="d-flex">
                              <input type="text" (keyup.enter)="updateCommPlan(line)" (focusout)="updateCommPlan(line)"
                                class="form-control kpn-form-control" placeholder="Comm plan"
                                [(ngModel)]="line.extra.comm_plan" />
                              <button (click)="updateCommPlan(line)" title="add"
                                class="btn btn-success py-0 my-0 px-2 d-flex align-items-center">
                                <span *ngIf="commPlanLoading; else plusIcon"
                                  class="spinner-border spinner-border-sm mb-1" role="status" aria-hidden="true"></span>
                                <ng-template #plusIcon>
                                  <i class="kphicon-uniE985"></i>
                                </ng-template>
                              </button>
                            </div>
                          </div>
                          <div class="form-group kpn-form-group">
                            <p class="mb-0 pb-0">{{'CART.RATE_PLAN' | transloco}}</p>
                            <div class="d-flex">
                              <input type="text" (keyup.enter)="updateRatePlan(line)" (focusout)="updateRatePlan(line)"
                                class="form-control kpn-form-control" placeholder="Rate plan"
                                [(ngModel)]="line.extra.rate_plan" />
                              <button (click)="updateRatePlan(line)" title="add"
                                class="btn btn-success py-0 my-0 px-2 d-flex align-items-center">
                                <span *ngIf="ratePlanLoading; else plusIcon"
                                  class="spinner-border spinner-border-sm mb-1" role="status" aria-hidden="true"></span>
                                <ng-template #plusIcon>
                                  <i class="kphicon-uniE985"></i>
                                </ng-template>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="art-nr">
                    <p class="m-0">{{line?.product_code}}</p>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="quantity d-flex align-items-center mb-2">
                    <div class="item-add-remove"
                      *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                      <div class="d-flex align-items-center" *ngIf="!line?.voucher_code">
                        <button class="kphicon-uniE972 add-remove-btn dicrement-btn" *ngIf="!line?.voucher_code"
                          (click)="decrementQuantity(line)" [disabled]="line.quantity === 1"></button>
                        <div class="add-remove-input">
                          <input appPositiveInteger type="number" [(ngModel)]="line.quantity" min="1"
                            (keyup.enter)="onEnter(line)" [disabled]="line?.voucher_code">
                        </div>
                        <button class="kphicon-uniE985 add-remove-btn incriment-btn" *ngIf="!line?.voucher_code"
                          (click)="incrementQuantity(line)"></button>
                      </div>
                    </div>
                    <div *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)" class="ml-4">
                      <a class="text-danger text-decoration-none" href="javascript:void(0)"
                        (click)="deleteCartItem(line)">
                        <i class="ui-delete kpn-ui-icon icon-size-md"></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-auto" *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                  <div class="quantity">
                    <h4 class="price-item-new"> {{ line?.delivery_option?.name }}</h4>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="gross-price">

                    <h4 class="price-item-new current-price"> {{ line?.amount_ex_vat | currencyFormat}}</h4>
                  </div>
                </div>

              </div>
            </div>
          </div>
          <div class="row new-cart-tbody" *ngFor="let subLine of line.sub_lines; let j = index">
            <div class="col">
              <div class="td-product">
                <div class="row">
                  <div class="col-auto">
                    <div class="cart-img-item"></div>
                  </div>
                  <div class="col ml-md-0 pl-0">
                    <div class="product-subline-details">
                      <p class="att-details-name m-0">{{subLine?.product_name}} <span
                          *ngIf="!subLine?.product_code?.startsWith('tkh_')">
                          ({{subLine?.product_code}})
                          <small *ngIf="subLine?.promotion_description" class="pt-0 ml-1 mr-1" >
                            <span class="badge badge-info" [innerHTML]="subLine?.promotion_description"></span>
                          </small>
                          <b>x
                            {{subLine?.quantity}}
                          </b>
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="total-price">
                    <h4 class="price-item-new-sub">{{subLine?.amount_ex_vat | currencyFormat}}</h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="new-cart-box new-cart-voucher-box" *ngIf="cart?.lines?.length">
        <div class="row">
          <div class="col-md-8">
            <p class="collapse-link mb-1"><a (click)="isVoucherVisible = !isVoucherVisible" href="javascript:void(0)">
                <i class="kphicon-uniE907"></i>{{'CART.ENTER_VOUCHER_CODE' | transloco }}</a></p>

            <p class="voucher-item mb-1" *ngFor="let voucher of voucherLines">
              <span><i class="kphicon-uniE91D"></i> {{ voucher?.voucher_code }}</span>
              <a href="javascript:void(0)" (click)="deleteCartItem(voucher)">{{'CART.REMOVE' | transloco }} </a>
            </p>
            <div class="voucher-offer d-flex align-items-center justify-content-start mb-4"
              *ngIf="isVoucherVisible || voucherLines?.length">
              <input type="text" class="form-control" placeholder="Voucher code" [(ngModel)]="voucherCode"
                (keyup.enter)="onEnterVoucher()" [disabled]="disabledBtn">
              <button class="btn py-1" [disabled]="!voucherCode || isVoucherLoading || onProjectPriceLoading"
                (click)="onEnterVoucher()">
                <span *ngIf="isVoucherLoading" class="spinner-border spinner-border-sm mb-1" role="status"
                  aria-hidden="true"></span>
                <i class="kphicon-uniE985" *ngIf="!isVoucherLoading"></i>
              </button>
            </div>

            <p class="collapse-link mb-1" *ngIf="projectList.length"><a (click)="isProjectVisible = !isProjectVisible"
                href="javascript:void(0)"> <i class="kphicon-uniE907"></i> {{'CART.LINK_PROJECT' | transloco }}</a></p>
            <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
              *ngIf="projectList.length && isProjectVisible">
              <select name="" class="form-control" id="" [(ngModel)]="selectedProject">
                <option [ngValue]="null">{{'CART.SELECT_PROJECT' | transloco }}</option>
                <option *ngFor="let item of projectList" [ngValue]="item">
                  {{item?.project_price?.name}}
                </option>
              </select>
              <button class="btn" (click)="removeProject()">
                <i class="kphicon-uniE926"></i>
              </button>
            </div>
            <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
              *ngIf="projectList.length && isProjectVisible && selectedProject">
              <input type="text" [(ngModel)]="selectedProjectRef" class="form-control reference-field"
                [placeholder]="'ORDER_OVERVIEW.REFERENCE_NUMBER' | transloco " (keyup.enter)="projectSelection()"
                [disabled]="onProjectPriceLoading" />
              <button class="btn py-1" (click)="projectSelection()" [disabled]="!selectedProjectRef">
                <span *ngIf="onProjectPriceLoading;else plusIcon" class="spinner-border spinner-border-sm mb-1"
                  role="status" aria-hidden="true"></span>
                <ng-template #plusIcon>
                  <i class="kphicon-uniE985"></i>
                </ng-template>
              </button>
            </div>
          </div>
          <div class="col-md-4">
            <div class="cart-total-box">
              <div class="total-price-line">
                <label>{{'CART.TOTAL' | transloco }}</label>
                <label>{{cart?.total_amount_ex_vat |
                  currencyFormat}}</label>
              </div>
              <div class="total-price-line">
                <label>{{'CART.VAT' | transloco }}</label>
                <label>{{cart?.total_amount_vat |
                  currencyFormat}}</label>
              </div>
              <div class="total-price-line total-price-line-bold">
                <label>{{'CART.TOTAL' | transloco }}</label>
                <label>{{cart?.total_amount | currencyFormat}}</label>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row align-items-center pt-3" *ngIf="account_type_error">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              {{account_type_error}}
            </p>
          </div>
        </div>
      </div>
      <div class="row align-items-center pt-3" *ngIf="!allowNextWithVoucher">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              {{'CART.CAN_ONLY_CONTINUE_IF_ENTER_VOUCHER' | transloco }}
            </p>
          </div>
        </div>
      </div>
      <div class="info-bar-red  px-3 pt-3" *ngIf="!cart?.is_valid">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <p [innerHTML]="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER ? eMessages.NO_VAT_NUMBER_MSG:
            eMessages.INVALID_CART">
          </p>
        </div>
        <app-vat-number-input *ngIf="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER"
          (reFetchCartCart)="reFetchCart($event)"></app-vat-number-input>
      </div>
      <div class="row align-items-center justify-content-between pt-4">
        <div class="col-auto d-flex">
          <button class="btn btn-outline-primary" (click)="goHome()">{{'CART.CONTINUE_SHOPPING' | transloco }}</button>
        </div>
        <div class="col-auto">
          <button class="btn btn-outline-primary mb-2 mr-2" (click)="sendRequest()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
            !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid || sendingSpecialRequest"
            *ngIf="hasQuoteCreationPermission && cart?.discount_request_allowed">
            <span *ngIf="sendingSpecialRequest" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
            {{'CART.REQUEST_SPECIAL_QUOTE_PRICE' | transloco }}
          </button>
          <button class="btn btn-outline-primary mb-2 mr-2" (click)="onClickCartToQuote()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
            !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid" *ngIf="hasQuoteCreationPermission">
            {{'CART.CREATE_AN_INVOICE' | transloco }}
          </button>
          <button class="btn btn-primary rounded-btn mb-2 mr-2" (click)="goToOrderOverview()" [disabled]="!cart?.lines?.length || isLoading || disabledBtn || onProjectPriceLoading ||
            !allowNextWithVoucher || hasUnsetAccountType || !cart?.is_valid">{{'CART.PROCEED_TO_CHECKOUT' | transloco
            }}</button>
        </div>
      </div>
    </div> -->

  </div>
</section>
