import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ApiBaseService } from 'src/app/shared/api-base.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class SidebarService {

  private sidemenus$ = new BehaviorSubject(false);
  sidemenus = this.sidemenus$.asObservable();

  private home$ = new BehaviorSubject('');
  home = this.home$.asObservable();

  private productGroupURI = 'products/groups';

  constructor(private apiBaseService: ApiBaseService, private router: Router) { }

  getProductGroups() {
    return this.apiBaseService.get(this.productGroupURI);
  }

  goToProductGroup(customerUUID: string) {
    this.router.navigate(['/customer', `${customerUUID}`]);
  }

  changeSideMenuSubject(shouldChange = false) {
    this.sidemenus$.next(shouldChange);
  }

  changeHomeData(groupCode: string) {
    this.home$.next(groupCode);
  }

}
