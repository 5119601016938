import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, pipe, timer } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { StorageService } from '../../shared/storage.service';
import { CartService } from '../cart/cart.service';
import { ProductListService } from './product-list.service';
import { selectCatalog } from '../../store/selectors/catalog.selectors';
import { skip } from 'rxjs/operators';
import { FavoriteProductsService } from '../favorite-products/favorite-products.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { ColorDictionary } from 'src/app/shared/utilities/static_variables';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { EProductAttributeCodes } from 'src/app/shared/enums/product-type.enums';


@Component({
  selector: 'app-product-list',
  templateUrl: './product-list.component.html',
  styleUrls: ['./product-list.component.scss']
})
export class ProductListComponent extends BasePermission implements OnInit, OnDestroy {
  isLoading = false;
  user = null;
  catalogCode = null;
  groupCode = null;
  catalog = null;
  group = null;
  pageLayout = null;
  queryParamsObs$ = new Subject<string>();
  pageSize = 21;
  totalCount = 0;
  isExternal = false;
  productList: any[] = [];
  brandList: any[] = [];
  attrValue: any[] = [];
  selectedBrands: any[] = [];
  selectedAttr: any[] = [];
  selectedAttrCode: any[] = [];
  filterQueryString = null;
  showPagination = false;
  searchKeyword = null;
  config: any = {
    itemsPerPage: 21,
    currentPage: 1,
    totalItems: 0
  };
  public cart = null;
  public nameSort = true;
  public priceSort = true;
  public codeSort = true;
  public qParams = null;
  public selectedProducts = [];
  public bulkAddLoading = false;
  public catalogSubs$: Subscription = null;
  public global = false;
  public internalSearch = null;
  public isInternalSearched = false;
  public wishlistData = null;
  public previousCatalogue = '';
  public previousGroup = '';
  private langChanges$ = null;
  public subgroups = null;

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private storageService: StorageService,
    private productListService: ProductListService,
    private cartService: CartService,
    private router: Router,
    public store: Store<IAppState>,
    private cdr: ChangeDetectorRef,
    private favoriteProductsService: FavoriteProductsService,
    public translocoService: TranslocoService,
    public utilityService: UtilityService
  ) {
    super(store);
    this.user = this.storageService.getCurrentUser();
    this.previousCatalogue = this.storageService.getDynamicValue('catalogCode', false);
    this.previousGroup = this.storageService.getDynamicValue('groupCode', false);
    this.favoriteProductsService.favoriteProducts$.subscribe(res => {
      this.wishlistData = res;
      if (this.productList?.length) {
        this.mapProductListData(this.productList)
      }
    })
    // subscribing to language change event and skipping the inistail subsription calls
    this.langChanges$ = translocoService.langChanges$.pipe(skip(1)).subscribe((lang) => {
      if (!this.searchKeyword) {
        this.getBrands();
      } else {
        if ((this.catalogCode && this.groupCode) || this.searchKeyword) {
          this.fetchProducts();
        }
      }
      this.getCataLogList();

    });
  }



  checkFavoriteList(productCode: string) {
    return this.wishlistData?.items?.some(item => {
      return item?.product?.code === productCode;
    })
  }

  ngOnInit() {
    this.utilityService.isInclVAT();
    this.isExternal = this.storageService.getCurrentUser()?.is_external;
    this.route.queryParams.subscribe(queryParams => {
      this.qParams = queryParams;
      this.catalogCode = queryParams?.catalogCode || null;
      this.groupCode = queryParams?.groupCode || null;
      this.pageLayout = queryParams?.pageLayout?.length ? queryParams?.pageLayout : null;
      this.global = queryParams?.global || false;
      this.searchKeyword = queryParams?.keyword;
      this.config.currentPage = this.global ? this.config?.totalItems > 20 ? queryParams?.page : 1 : queryParams?.page || 1;
      this.internalSearch = null;
      this.pageSize = this.pageLayout ? 100 : 21;
      this.productList = [];
      this.totalCount = 0;
      this.selectedBrands = queryParams?.brands ? queryParams?.brands?.split(',') : [];
      this.selectedAttrCode = queryParams?.attributes ? queryParams?.attributes?.split(',') : [];
      this.saveInStorage();
      if (!this.searchKeyword) {
        this.getBrands();
      } else {
        if ((this.catalogCode && this.groupCode) || this.searchKeyword) {
          this.fetchProducts();
        }
      }

      // this.getProductGroup();

      this.getCataLogList();
    }, error => {
    });
  }



  getCataLogList() {
    this.catalogSubs$ = this.store.select(selectCatalog).subscribe(catalogs => {
      this.catalog = catalogs?.find(catalog => catalog?.code === this.catalogCode) || null;
      if (this.catalog) {
        this.group = this.catalog?.product_groups?.find(item => item?.code === this.groupCode) || null;
        if (this.group) {
          this.findSubgroup();
        }
        if (!this.group) {
          // tslint:disable-next-line:prefer-for-of
          for (let index = 0; index < this.catalog?.product_groups.length; index++) {
            const element = this.catalog?.product_groups?.[index];
            const grp = this.findGroup(this.groupCode, element?.child_groups);
            if (grp) {
              this.group = grp;
              this.findSubgroup();
              break;
            }
          }
        }
      }
    });
  }

  findGroup(code, arr: any[]) {
    return arr.reduce((acc, item) => {
      if (acc) {
        return acc;
      }
      if (item.code === code) {
        return item;
      }
      if (item.child_groups?.length) {
        return this.findGroup(this.groupCode, item.child_groups);
      }
    }, null);
  }


  findSubgroup() {
    let parent = this.getNMinusOneParent(this.group?.code, this.catalog?.product_groups, [], 2);
    if (parent) {
      this.subgroups = parent;
    } else {
      this.subgroups = this.getNMinusOneParent(this.group?.code, this.catalog?.product_groups, [], 0) || this.catalog.product_groups?.find(group => group.code === this.group?.code);
    }


  }




  getNMinusOneParent(childCode, array, parentObjects = [], label, lastParentObject = null, secondToLastParentObject = null) {
    for (const item of array) {
      if (item.child_groups && item.child_groups.length > 0) {
        if (item.child_groups.some(child => child.code === childCode)) {
          if (label > 0) {
            return parentObjects[parentObjects.length - label]; // Found the (n-1)-th parent object
          }
          return secondToLastParentObject || item;
        } else {
          const newParentObjects = [...parentObjects, item];
          const result = this.getNMinusOneParent(childCode, item.child_groups, newParentObjects, label, item, lastParentObject);
          if (result) {
            return result; // Continue searching in the children
          }
        }
      }
    }
    return null // Child not found in the array
  }

  saveInStorage() {
    if (this.previousCatalogue !== this.catalogCode) {
      this.attrValue = [];
      this.selectedAttr = [];
      this.selectedAttrCode = [];
    }
    this.storageService.setDynamicValue('groupCode', this.groupCode);
    this.storageService.setDynamicValue('catalogCode', this.catalogCode);
    this.storageService.setDynamicValue('pageLayout', this.pageLayout);
  }


  fetchProducts() {
    this.getCart();
    this.getProducts();
    if (!this.searchKeyword) {
      this.setQueryParamsToLocalStorage();
    }
  }

  setQueryParamsToLocalStorage() {
    this.storageService.setDynamicValue('catalogCode', this.catalogCode);
    this.storageService.setDynamicValue('groupCode', this.groupCode);
    if (this.pageLayout) {
      this.storageService.setDynamicValue('pageLayout', this.pageLayout);
    }
  }

  getCart() {
    this.cartService.currentCart$.subscribe((res) => {
      this.cart = res;
      if (this.productList.length) {
        this.checkProduct();
      }
    }, error => {
    });
  }

  checkProduct() {
    if (this.cart) {
      this.cart.lines.forEach(element => {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.productList.length; index++) {
          const product = this.productList[index];
          let childProduct = null;
          if (product?.child_products?.length) {
            childProduct = product?.child_products[0]?.code;
          }
          if (product?.code === element?.product_code || childProduct === element?.product_code) {
            product.cartQuantity = element?.quantity;
            break;
          }
        }
      });
    }
  }

  getProducts() {
    if (this.searchKeyword) {
      this.filterQueryString = null; this.brandList = []; this.attrValue = []; this.selectedBrands = [];
      this.selectedAttr = []; this.selectedAttrCode = [];
    }
    this.filterQueryString = this.prepareQueryString();
    this.fetchPaginatedProducts(this.config.currentPage || 1);
  }

  fetchPaginatedProducts(pageNumber) {
    this.isLoading = true;
    this.showPagination = false;
    this.productList = [];
    this.config.currentPage = pageNumber;
    this.totalCount = 0;
    const contactUuid = !this.isExternal && this.storageService.getContactUuid() ? this.storageService.getContactUuid() : null;
    this.productListService.getProducts(
      this.catalogCode || this.storageService.getCatalog(),
      this.groupCode || this.storageService.getProductGroup(),
      pageNumber,
      this.pageSize,
      contactUuid,
      this.filterQueryString,
      this.searchKeyword || this.internalSearch,
      this.global
    ).subscribe(res => {
      if (res?.data?.count) {
        this.totalCount = res?.data?.count;
        this.showPagination = true;
        this.config.totalItems = res.data.count;
        this.mapProductListData(res?.data?.results);
        this.config.itemsPerPage = this.pageSize;
        if (this.cart) {
          this.checkProduct();
        }
        this.isLoading = false;
      } else {
        this.productList = [];
        timer(3000).subscribe(time => {
          this.isLoading = false;
        });
      }
    }, (err) => {
      let msg = err?.error?.message || err?.error?.error?.message || err?.error?.error?.detail?.[0] || 'Failed to filter products';
      this.toastrService.error(msg, 'Error')
      this.isLoading = false;
      this.showPagination = false;
    });
  }

  mapProductListData(products = []) {
    this.productList = products.map(obj => ({
      ...obj,
      document_image: obj?.document_image || obj?.image,
      cartQuantity: 0,
      showSpinner: false,
      memory_size: obj?.available_attributes?.find(data => data.code === 'memory_size')?.values,
      color: obj?.available_attributes?.find(data => data.code === 'color')?.values,
      selected: this.checkSelectedProductInCart(obj) || false,
      is_favorite: this.checkFavoriteList(obj?.code),
      uniqueColorChildProducts: this.getUniqueColorProducts(obj?.child_products || []),
      logos: this.checkLogoAttributes(obj)
    })).map(item => {
      return {
        ...item,
        selectedImg: this.getSelectedImage(item)?.image || item?.document_image,
      }
    })
  }

  checkLogoAttributes(product) {
    return product?.available_attributes.filter(attribute => EProductAttributeCodes.some(code => attribute?.values?.includes("true" || true) && attribute.code === code))?.map(attr => {
      return {
        ...attr,
        logo: this.utilityService.stickers?.find(sticker => sticker?.type === attr.code)?.logo
      }
    });

  }


  getUniqueColorProducts(childProducts = []) {
    let data = new Map();
    for (let obj of childProducts) {
      if (!data.has(obj.color)) {
        data.set(obj.color, obj);
      }
    }
    let unique = [...data.values()]?.map((item, index) => {
      return {
        ...item,
        color_code: item?.color_code || this.getColor(item?.color?.trim()),
        selected: index === 0 ? true : false,
        selectable: item?.color ? true : false
      }
    })?.sort((a, b) => a.color.localeCompare(b.color));
    return unique;
  }

  getSelectedImage(product) {
    return product?.uniqueColorChildProducts?.find(item => item?.selected) || null
  }

  getColor(name: string) {
    return name ? ColorDictionary[name?.replace(/[\s\-]/g, '_')] || name : ColorDictionary['NoColor'];
  }

  changeImage(product, uniqueColorChildProducts, color) {
    this.productList = this.productList.map(item => {
      if (item.code === product.code) {
        return {
          ...item,
          selectedImg: color?.image || product?.document_image,
          uniqueColorChildProducts: item.uniqueColorChildProducts.map(obj => {
            return {
              ...obj,
              selected: obj?.code === color?.code,
            }
          }),
        }
      }
      return item;
    });
    // return;
    // uniqueColorChildProducts.forEach(element => {
    //   element.selected = false;
    // });
    // color.selected = true;
    // product.selectedImg = color.image || product?.document_image;
  }

  getFilterProduct(callPaginate = true) {
    this.isLoading = true;
    this.showPagination = false;
    this.productList = [];
    this.filterQueryString = this.prepareQueryString();
    if (callPaginate) {
      this.fetchPaginatedProducts(this.config.currentPage || 1);
    }
  }

  onPageClick(event: any) {
    this.isLoading = true;
    this.config.currentPage = event;
    this.resetQParams();
    this.fetchPaginatedProducts(event);
  }

  onPageRangeChange() {
    this.isLoading = true;
    this.config.currentPage = 1;
    this.fetchPaginatedProducts(this.config.currentPage || 1);
  }

  getBrands() {
    if ((this.previousCatalogue !== this.catalogCode) || (this.previousGroup !== this.groupCode) || !this.attrValue?.length) {
      this.productListService.getDynamicSearchItems(this.catalogCode, this.groupCode).subscribe(res => {
        if (res?.data) {
          this.brandList = res?.data?.brands.map(obj => {
            return { ...obj, selected: this.selectedBrands?.some(item => item === obj?.name) };
          });
          if (res?.data?.attributes) {
            this.attrValue = [];
            res?.data?.attributes.forEach(element => {
              if (element?.attribute_values.length) {
                this.attrValue.push({
                  attrName: element?.attribute_name,
                  attrCode: element?.attribute_code,
                  attrType: element?.attribute_type,
                  attrValue: element?.attribute_values.map(obj => {
                    let n_value = obj?.value;
                    return {
                      ...obj,
                      value: n_value,
                      displayName: element?.attribute_type === 'Boolean' ? obj?.value === 'True' ? 'Ja' : 'Nee' : obj?.value,
                      selected: this.selectedAttrCode?.some(item => item === `${element?.attribute_code}@${n_value}`)
                    };
                  })
                });
              }
            });

            if (!this.selectedAttr?.length) {
              this.populateSelectedAttr();
            }
          }

          this.previousCatalogue = this.catalogCode;
          this.previousGroup = this.groupCode;
          if ((this.catalogCode && this.groupCode) || this.searchKeyword) {
            this.fetchProducts();
          }
        }

      }, (error) => {
        if ((this.catalogCode && this.groupCode) || this.searchKeyword) {
          this.fetchProducts();
        }
        if (error?.error && error?.error?.message) {
          this.toastrService.error(error?.error?.message, 'Error', {
            timeOut: 4000
          });
        }
      });
    } else {
      this.populateBrandAttr();
      if ((this.catalogCode && this.groupCode) || this.searchKeyword) {
        this.fetchProducts();
      }
    }
  }


  populateSelectedAttr() {
    this.attrValue?.filter(attr => this.selectedAttrCode?.some(attrCode => attrCode?.split('@')?.[0] === attr?.attrCode))?.forEach(element => {
      let selectedValues = element?.attrValue?.filter(obj => obj?.selected);
      selectedValues?.forEach(item => {
        let val = {
          attrName: element?.attrName,
          attrCode: element?.attrCode,
          attrType: element?.attrType,
          display: element?.attrType === 'Boolean' ? `${element?.attrName}(${item?.value === 'True' ? 'Ja' : 'Nee'})` : item?.value,
          value: item?.value
        }
        if (!this.selectedAttr?.some(s_attr => s_attr.attrCode === element?.attrCode && s_attr.value === item?.value)) {
          this.selectedAttr.push(val)
        }
      });
    });
  }


  populateBrandAttr() {
    this.brandList = this.brandList.map(obj => {
      return { ...obj, selected: this.selectedBrands?.some(item => item === obj?.name) };
    });
    this.attrValue = this.attrValue.map(element => {
      return {
        attrName: element?.attrName,
        attrCode: element?.attrCode,
        attrType: element?.attrType,
        attrValue: element?.attrValue?.map(obj => {
          let n_value = obj?.value;
          return {
            ...obj,
            value: n_value,
            selected: this.selectedAttrCode?.some(item => item === `${element?.attrCode}@${n_value}`)
          };
        })
      };
    });

    // this.populateSelectedAttr();
    if (!this.selectedAttr?.length) {
      this.populateSelectedAttr();
    }

  }


  resetQParams() {
    this.qParams = {
      ...this.qParams,
      brands: this.selectedBrands?.length ? this.selectedBrands.map(obj => obj).join(',') : null,
      attributes: this.selectedAttrCode?.length ? this.selectedAttrCode.map(obj => obj).join(',') : null,
      page: this.global ? this.config?.totalItems > 20 ? this.config.currentPage : 1 : this.config.currentPage || 1,
      groupCode: this.groupCode

    }

    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: this.qParams,
        queryParamsHandling: 'merge',
      });
  }

  onSelectBrand(event) {
    const selectedBrand = this.brandList?.find(item => item.code === event.target.defaultValue) || null;
    const index = this.selectedBrands.findIndex(obj => {
      return obj === selectedBrand?.name;
    });
    if (event?.target.checked) {
      if (index === -1) {
        this.selectedBrands.push(selectedBrand?.name);
      }
    } else {
      if (index > -1) {
        this.selectedBrands.splice(index, 1);
      }
    }

    this.resetQParams();
  }

  onselectAttribute(event, attrCode: string, attrName: string, attrType: string) {
    const index = this.selectedAttr.findIndex(obj => {
      return obj?.value === event.target.defaultValue && obj?.attrCode === attrCode;
    });

    if (event?.target.checked) {
      if (index === -1) {
        this.selectedAttr.push({
          attrCode,
          attrName,
          value: event.target.defaultValue,
          display: attrType === 'Boolean' ? `${attrName}(${event.target.defaultValue === 'True' ? 'Ja' : 'Nee'})` : event.target.defaultValue,
          attrType
        });
        this.selectedAttrCode.push(`${attrCode}@${event.target.defaultValue}`);
      }
    } else {
      if (index > -1) {
        this.selectedAttr.splice(index, 1);
        const attrIndex = this.selectedAttrCode.indexOf(`${attrCode}@${event.target.defaultValue}`);
        if (attrIndex !== -1) {
          this.selectedAttrCode.splice(attrIndex, 1);
        }
      }
    }

    this.resetQParams();
  }

  removeBrandTag(item, index: number) {
    this.selectedBrands.splice(index, 1);
    const ind = this.brandList.findIndex(obj => {
      return obj.name === item;
    });

    this.brandList[ind].selected = false;
    this.resetQParams();
  }

  removeAttrTag(item, index: number) {
    const attrIndex = this.attrValue.findIndex(obj => obj.attrCode === item.attrCode);
    if (attrIndex > -1) {
      const attrValue = this.attrValue[attrIndex]?.attrValue;
      const ind = attrValue.findIndex(obj => obj.value === item.value);
      if (ind > -1) {
        this.attrValue[attrIndex].attrValue[ind].selected = false;
        this.selectedAttr.splice(index, 1);
        this.selectedAttrCode = this.selectedAttrCode.filter(attrCode => attrCode !== `${item.attrCode}@${item.value}`);
        this.resetQParams();
      }
    }
  }

  removeDuplicates(data) {
    return [...new Set(data)];
  }

  prepareQueryString() {
    let queryString = '';
    if (this.selectedBrands.length) {
      const brandCodes = this.brandList?.filter(item => this.selectedBrands.includes(item?.name)).map(brand => brand.code);
      if (brandCodes?.length) {
        queryString = queryString.concat('&brand='.concat(brandCodes.join()));
      }
    }

    this.removeDuplicates(this.selectedAttrCode).forEach((element: any) => {
      let attrCode = element?.split('@')?.[0];
      if (this.selectedAttr?.length) {
        let qValue = this.selectedAttr.filter(obj => obj.attrCode === attrCode)?.map(obj => obj.value)?.join();
        if (qValue) {
          queryString = queryString.concat(`&${attrCode}=${qValue}`);
        }
      }
    });

    return queryString;
  }

  removeProductSearchTag() { // It's for global search
    this.searchKeyword = null;
    this.getFilterProduct(false);
    this.router.navigateByUrl('/home');
    // this.router.navigate(['/product-list'], {
    //   queryParams: {
    //     catalogCode: this.catalogCode || this.storageService.getDynamicValue('catalogCode', false),
    //     groupCode: this.groupCode || this.storageService.getDynamicValue('groupCode', false),
    //     pageLayout: this.pageLayout,
    //   }
    // });
  }

  clearFilter() {
    this.filterQueryString = null;
    this.brandList = this.brandList?.map(item => {
      return {
        ...item,
        selected: false
      };
    });
    this.selectedBrands = [];
    this.attrValue = this.attrValue?.map(attr => {
      return {
        ...attr,
        attrValue: attr?.attrValue?.map(item => {
          return {
            ...item,
            selected: false
          };
        })
      };
    });

    this.selectedAttr = [];
    this.selectedAttrCode = [];
    this.config.currentPage = 1;
    this.config.totalCount = 0;
    this.resetQParams();
  }

  onProductDetail(product, index) {
    const firstChild = product?.uniqueColorChildProducts?.find(item => item?.selected)?.code || product?.child_products?.find(item => true)?.code || product.code;
    this.router.navigate(['/product-details', `${firstChild}`], {
      queryParams: {
        ...this.qParams,
        brands: this.selectedBrands?.length ? this.selectedBrands.map(obj => obj).join(',') : null,
        attributes: this.selectedAttrCode?.length ? this.selectedAttrCode.map(obj => obj).join(',') : null,
      },
      queryParamsHandling: 'merge'
    });
  }

  selectItem(product) {
    product.selected = !product.selected;
    if (product.selected) {
      this.selectedProducts.push(product);
    } else {
      this.selectedProducts = this.selectedProducts?.filter(item => {
        return item.code !== product.code;
      });
    }
  }

  checkSelectedProductInCart(product) {
    return this.cart?.lines?.some(line => {
      return line?.product_code === product?.code;
    });
  }


  onAddToCart(product: any) {
    product.showSpinner = true;
    const cartId = this.storageService.getCart() || null;

    const cart = {
      market_type_code: 'business',
      lines: [
        {
          product_code: `${product?.child_products?.length ? product?.child_products[0]?.code : product?.code}`,
          product_name: `${product?.child_products?.length ? product?.child_products[0]?.name : product?.name}`,
          product_type: `handset`,
          quantity: product.cartQuantity || product?.order_unit,
          flow_type_code: 'new'
        }
      ]
    };

    const contactPersonUUID = this.storageService.getContactPersonUUID();
    const shippingAddressId = this.storageService.getShippingAddressID;
    if (contactPersonUUID) {
      cart['contact_person_uuid'] = contactPersonUUID;
      cart['shipping_address_id'] = shippingAddressId || null;
    }
    if (!this.cart) {
      this.createCart(cart, product);
    } else {
      this.updateCart(cart.lines[0], product);
    }
  }

  createCart(data, product) {
    this.cartService.createCart(data).subscribe(res => {
      this.cart = res.data;
      this.updateCartStore(this.cart, product);
      product.showSpinner = false;
      product.selected = !product.selected;
      this.showToaster();
    }, err => {
      product.cartQuantity = product.prevCartQuantity;
      product.showSpinner = false;
      this.showErrorToaster(err?.error?.message);
    });
  }



  updateCart(data, product) {
    this.cartService.updateCart(this.cart.uuid, data).subscribe(res => {
      this.cart = res.data;
      this.updateCartStore(this.cart, product);
      product.selected = !product.selected;
      product.showSpinner = false;
      this.showToaster();
    }, err => {
      product.showSpinner = false;
      this.showErrorToaster(err?.error?.message);
    });
  }

  updateCartStore(cart, product = null, isDelete = false) {
    this.cartService.changeCurrentCart(cart);
    this.storageService.setCart(cart);
    // this.populateDrawer(cart);
    if (product !== null) {
      this.cartService.saveOrderUnitOfCartProducts({ product_code: product?.code, order_unit: product?.order_unit }, isDelete);
    }
  }

  populateDrawer(cart) {
    const formatCart = cart?.lines?.map(line => {
      return {
        ...line,
        code: line?.product_code,
        image: line?.image,
        name: line?.product_name,
        color: '',
        memory_size: '',
      };
    });
    this.storageService.setSelectedCartProduct(formatCart);
  }

  showDrawer() {
    this.populateDrawer(this.cart);
  }

  updateCartCartItem(product) {
    product.showSpinner = true;
    const line = this.findProductInCart(product);
    if (line) {
      const payload = {
        quantity: product.cartQuantity
      };
      this.cartService.updateCartLine(this.cart.uuid, line.uuid, payload).subscribe(res => {
        this.isLoading = false;
        this.cart = res.data;
        this.updateCartStore(this.cart, product);
        product.showSpinner = false;
        this.showToaster();
      }, err => {
        product.cartQuantity = line?.quantity;
        product.showSpinner = false;
        this.showErrorToaster(err?.error?.message);
      });
    } else {
      // product.showSpinner = false;
      this.onAddToCart(product);
    }
  }

  findProductInCart(product) {
    const childProductCode = product?.child_products?.length ? product?.child_products[0]?.code : null;
    return this.cart?.lines.find(obj => {
      return obj?.product_code === product?.code || obj?.product_code === childProductCode;
    }) || null;
  }

  incrementQuantity(product) {
    product.prevCartQuantity = product.cartQuantity;
    product.cartQuantity = product.cartQuantity + product.order_unit;
    this.updateCartCartItem(product);
  }

  decrementQuantity(product) {
    product.prevCartQuantity = product.cartQuantity;
    const reqQty = product.cartQuantity - product.order_unit;
    if (reqQty >= 0) {
      product.cartQuantity = reqQty;
      if (product.cartQuantity < 1) {
        this.deleteCartItem(product);
      } else {
        this.updateCartCartItem(product);
      }
    }
  }


  onFocusout(product) {

    if (this.findProductInCart(product) && product.cartQuantity < 1) {
      this.deleteCartItem(product);
    } else {
      if (product.cartQuantity < product.order_unit) {
        // const msg = `Product quantity must be at least ${product.order_unit}`;
        // this.showInfoToaster(msg);
        return;
      }
      this.updateCartCartItem(product);
    }

  }

  onEnter(product) {
    if (product.cartQuantity < 0 || isNaN(product.cartQuantity)) {
      return;
    } else if (product.cartQuantity === 0) {
      this.deleteCartItem(product);
    } else if (product.cartQuantity > 0) {
      this.updateCartCartItem(product);
    } else {
      return;
    }
  }

  deleteCartItem(product) {
    product.showSpinner = true;
    const line = this.findProductInCart(product);
    if (line) {
      this.cartService.deleteCartItem(this.cart.uuid, line.uuid).subscribe(res => {
        this.cart = res.data;
        this.updateCartStore(this.cart, product, true);
        product.showSpinner = false;
        this.showToaster();
        product.cartQuantity = 0;
        product.selected = !product.selected;
      }, err => {
        product.cartQuantity = line?.quantity;
        product.showSpinner = false;
        this.showErrorToaster(err?.error?.message);
      });
    } else {
      product.showSpinner = false;
    }
  }

  changeQuantity(event: EventListener, product) {
    if (event === null) {
      product.cartQuantity = '0';
      this.checkWithin2Second(event, product);
    } else if (product.cartQuantity <= 0) {
      this.deleteCartItem(product);
    }
  }

  checkWithin2Second(event, product) {
    timer(3000).subscribe(time => {
      if (product.cartQuantity === '0') {
        this.deleteCartItem(product);
      }
    });
  }

  showToaster() {
    this.toastrService.success('de winkelwagen is bijgewerkt', 'Succes', {
      timeOut: 2000
    });
  }

  showInfoToaster(mes: string) {
    this.toastrService.info(mes, 'Info', {
      timeOut: 2000
    });
  }

  showErrorToaster(errMsg = null) {
    this.toastrService.error(errMsg || 'Operation failed!', 'Error', {
      timeOut: 4000
    });
  }

  sortProduct(key: string, asc: boolean) {
    if (asc) {
      if (!['sales_price_ex_vat', 'sales_price']?.includes(key)) {
        this.productList.sort((a, b) => a[key].localeCompare(b[key]));
      } else {
        this.productList.sort((a, b) => parseFloat(a[key]) - parseFloat(b[key]));
      }
      this.toggleSort(key);
    } else if (!asc) {
      if (!['sales_price_ex_vat', 'sales_price']?.includes(key)) {
        this.productList.sort((a, b) => b[key].localeCompare(a[key]));
      } else {
        this.productList.sort((a, b) => parseFloat(b[key]) - parseFloat(a[key]));
      }
      this.toggleSort(key);
    }
  }

  toggleSort(field: string) {
    switch (field) {
      case 'name':
        this.nameSort = !this.nameSort;
        break;
      case 'sales_price_ex_vat':
        this.priceSort = !this.priceSort;
        break;
      case 'code':
        this.codeSort = !this.codeSort;
        break;
      default:
        break;
    }
  }


  onSearchProduct() {
    if (!this.internalSearch) {
      return;
    }
    this.isInternalSearched = true;
    this.isLoading = true;
    this.config.currentPage = 1;
    this.global = false;
    this.fetchPaginatedProducts(this.config.currentPage || 1);
  }

  onClearSearch(isReset: boolean = false) {
    this.internalSearch = null;

    if (isReset) {
      this.searchKeyword = null;
      this.isInternalSearched = false;
      this.config.currentPage = 1;
      this.global = false;
      this.router.navigate(['/product-list'], {
        queryParams: {
          pageLayout: this.storageService.getDynamicValue('pageLayout', false),
          catalogCode: this.catalogCode,
          groupCode: this.groupCode,
          change: Math.random()
        },
        relativeTo: this.route,
        queryParamsHandling: ''
      });
    }
  }

  getProductGroup() {
    this.productListService.getProductGroup().subscribe(response => {
      const ll = response?.data?.results?.find(item => {
        return item.code === 'accessoires';
      });

    });
  }

  addToFavorite(product_code: string) {
    this.favoriteProductsService.addToFavorite(product_code);
  }

  removeFromFavorite(product_code) {
    const wishlist_item = this.wishlistData?.items?.find(item => item?.product?.code === product_code)?.uuid;
    this.favoriteProductsService.removeFromFavoriteList(wishlist_item);
  }

  onClickSubGroup(childGroup) {
    this.groupCode = childGroup?.code;
    this.storageService.setDynamicValue('groupCode', this.groupCode);
    this.clearFilter();
  }

  ngOnDestroy(): void {
    if (this.queryParamsObs$) {
      this.queryParamsObs$.unsubscribe();
    }
    this.catalogSubs$?.unsubscribe();
    this.langChanges$?.unsubscribe();
  }
}
