import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { IAppState } from 'src/app/app.state';
import { AuthService } from 'src/app/shared/auth.service';
import { StorageService } from 'src/app/shared/storage.service';
import { PermissionLoadAction } from 'src/app/store/actions/permission.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grip-redirect',
  templateUrl: './grip-redirect.component.html',
  styleUrls: ['./grip-redirect.component.scss']
})
export class GripRedirectComponent implements OnInit {
  public errorMessage = null;
  private gripCode: any;
  private homePath = '/home';

  constructor(
    private route: ActivatedRoute,
    private storageService: StorageService,
    private authService: AuthService,
    private router: Router,
    private store: Store<IAppState>
  ) { }

  ngOnInit() {
    // tslint:disable-next-line:no-string-literal
    // this.gripCode = this.route.snapshot.queryParams['code'];
    // this.redirectPath = this.route.snapshot.queryParams['path'];
    // console.log('this.gripCode: ', this.gripCode);

    this.route?.queryParams.subscribe(qp => {
      this.gripCode = qp?.code || null;
      if (this.gripCode) {
        this.loginWithGrip();
      }
    })
  }

  // Check, token is expired or not
  resetPreviousToken() {
    const jwtHelper = new JwtHelperService();
    const currentToken = !!this.storageService.getToken() ? this.storageService.getToken() : null;
    if (currentToken) {
      const isExpired = jwtHelper.isTokenExpired(currentToken, environment.accessTokenTimeOffset);
      if (isExpired) {
        this.storageService.removeToken();
        this.storageService.removeRefreshToken();
      }
    }
  }

  loginWithGrip() {
    this.errorMessage = null;
    this.authService.loginWithGrip(this.gripCode)
      .pipe(exhaustMap(response => of(response)))
      .subscribe(response => {
        console.log('response: ', response);
        this.store.dispatch(new PermissionLoadAction());
        this.router.navigateByUrl(response.data.redirect_url || this.homePath);
      }, error => {
        console.log('error: ', error);
        this.errorMessage = error.error.message;
      });
  }
}
