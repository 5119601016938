<li *ngFor="let item of navItems" class="parent">
  <a [routerLink]="['/product-list']" [queryParams]="{
      catalogCode: item?.parent_code || item?.code,
      groupCode: item?.parent_code ? item?.code :  item?.child_groups?.length ?  item?.child_groups[0]?.code : item?.code,
      pageLayout: item?.parent_code
        ? item?.page_layout
        : item?.child_groups[0]?.page_layout
    }" *ngIf="!item.is_home; else homeTemplate">
    {{ item.name?.replace("kpn", "KPN") }}
    <span class="expand kphicon-uniE920" *ngIf="item?.parent_code && item?.child_groups?.length"></span>
  </a>
  <ng-template #homeTemplate>
    <!-- <a routerLink="home"><span class="ui-home kpn-ui-icon icon-size-sm"></span></a> -->
  </ng-template>
  <ul class="child" *ngIf="item?.child_groups?.length">
    <app-nav-menu *ngIf="item?.child_groups?.length" [navItems]="item?.child_groups"></app-nav-menu>
  </ul>
</li>

<li class="parent" *ngIf="showTradeIn">
  <div class="short-cart-overview ml-2">
    <a [routerLink]="['/trade-in']" class="circle-icon-btn">
      TradeIn
      <span class="menu-item-amount" *ngIf="tradeInCart?.lines?.length">{{
        tradeInCart?.lines?.length
        }}</span>
      <span class="menu-item-amount" *ngIf="tradeInCart && !tradeInCart?.lines?.length">0</span>
    </a>
  </div>
</li>