import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HostCheckService {

  public apiBaseUrl = 'api/v1/';
  public apiBaseUrl2 = 'api/v2/';

  // Do not take hostname from the site for these locations.
  // REASON: This is to make sure we call the right server to connect to the backend machine
  // running locally
  public exceptionHosts = ['localhost', 'fe-producthub-develop.designed4telecom.com'];

  constructor() {
    if (this.exceptionHosts.includes(window.location.hostname)) {
      this.apiBaseUrl = `${environment.baseApiUrl}`;
    }
  }
}
