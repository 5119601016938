<div *ngIf="isLoading" class="full-screen-loader" id="fullScreenLoader">
  <div class="loader">
      <div class="lds-spinner">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
          <div></div>
      </div>
  </div>
</div>
