import { Component, OnInit } from '@angular/core';
import { TogglerService } from './shared/toggler.service';
import { CartService } from './pages/cart/cart.service';
import { StorageService } from './shared/storage.service';
import { Store } from '@ngrx/store';
import { IAppState } from './app.state';
import { PermissionLoadAction } from './store/actions/permission.actions';
import { ApiBaseService } from './shared/api-base.service';
import { SiteProfileLoadAction } from './store/actions/siteProfile.actions';
import { FavoriteProductsService } from './pages/favorite-products/favorite-products.service';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { HomeService } from './pages/home/home.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {
  title = 'myapp';
  data = null;
  isShowsideBar = false;

  constructor(
    private titleService: Title,
    private togglerService: TogglerService,
    private cartService: CartService,
    private favoriteProductsService: FavoriteProductsService,
    private storageService: StorageService,
    private store: Store<IAppState>,
    private apiBaseService: ApiBaseService,
    private router: Router,
    private modalService: NgbModal,
    public translocoService: TranslocoService,
    public homeService: HomeService


  ) {
    const siteProfile = this.storageService.getSiteProfile();
    this.titleService.setTitle(siteProfile?.title || 'KPN Webshop');
    this.togglerService.currentData.subscribe(data => {
      this.data = data;
      if (this.data === 'open') {
        this.isShowsideBar = true;
      } else {
        this.isShowsideBar = false;
      }
    });
    this.router.events
      .subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
          }
        }
      });
  }

  setPreviousLanguage() {
    const languageCode = this.storageService.getLanguage();
    if (languageCode) {
      this.translocoService.setActiveLang(languageCode);
    }
  }

  ngOnInit() {
    this.setPreviousLanguage();
    this.store.dispatch(new SiteProfileLoadAction());
    const cart = this.storageService.getCart();
    const tradeInCart = this.storageService.getReComCart();
    const favoriteProducts = this.storageService.getFavoriteProducts();
    const permissions = this.storageService.getPermission();
    const hasSeenPopup = this.storageService.getHasSeenMessage();
    this.homeService.setShowedSystemMessagesModal(hasSeenPopup || false)
    if (tradeInCart) {
      this.storageService.setReComCart(tradeInCart);
    }
    if (cart) {
      this.cartService.changeCurrentCart(cart);
    }

    if (permissions) {
      this.store.dispatch(new PermissionLoadAction());
    }

    if (favoriteProducts?.items?.length) {
      this.favoriteProductsService.changeCurrentFavoriteProducts(favoriteProducts);
    } else {
      this.getFavoriteProducts();
    }
  }
  getFavoriteProducts() {
    const currentUser = this.storageService.getCurrentUser();
    const permissions = this.storageService.getPermission() || null;
    const hasWishListPermission = permissions?.some(permission => {
      return ['wishlist.view_wishlist'].includes(permission.code);
    });

    if (currentUser && hasWishListPermission)
      this.favoriteProductsService.getFavoriteProducts().subscribe(response => {
        const wishlistData = response?.data?.results?.find(item => item?.is_default) || null;
        this.favoriteProductsService.changeCurrentFavoriteProducts(wishlistData);
      })
  }


}
