<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="customer-search kpn-card bg-kpn-light mb-4"></div>
      <div class="loader-wrapper" [ngClass]="{ 'spinner-wrapper-height': isLoading }">
        <div class="table-responsive custom-table box-shadow-sm">
          <h3 class="title-3 green-text ml-4 pt-3" i18n="@@arm.order-status.order-list">
            {{'VOUCHER_LIST.VOUCHER_PROFILE' | transloco}}:
          </h3>
          <div class="kpn-spinner" *ngIf="isLoading; else table_content">
            <div class="text-center">
              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading" />
              <p>{{'VOUCHER_LIST.TO_SEARCH' | transloco}}…</p>
            </div>
          </div>
          <ng-template #table_content>
            <table class="table mb-5">
              <thead>
                <tr>
                  <th class="text-center" i18n="@@arm.order-status.mobile">
                    {{'VOUCHER_LIST.ACTIVE' | transloco}}
                  </th>
                  <th class="text-center" i18n="@@arm.order-status.order-num">
                    {{'VOUCHER_LIST.SINGLE_USE' | transloco}}
                  </th>
                  <th i18n="@@arm.order-status.client-num">Code</th>
                  <th i18n="@@arm.order-status.client-num">Voucher Product</th>
                  <th i18n="@@arm.order-status.order-date">{{'VOUCHER_LIST.BILLING_TYPE' | transloco}}</th>
                  <th i18n="@@arm.order-status.company-name" class="text-right">
                    {{'VOUCHER_LIST.DEFAULT_AMOUNT' | transloco}}
                  </th>
                  <th i18n="@@arm.order-status.status">{{'VOUCHER_LIST.VALID' | transloco}}</th>
                  <th i18n="@@arm.order-status.total_amount">{{'VOUCHER_LIST.VALID_UNTIL' | transloco}}</th>
                  <th class="text-center">{{'VOUCHER_LIST.ACTIONS' | transloco}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of voucherProfileList | paginate : config">
                  <td class="text-center">
                    <span class="kphicon-uniE91D" style="color: green" *ngIf="item?.is_available; else notValid"></span>
                    <ng-template #notValid>
                      <span class="kphicon-uniE926" style="color: red"></span>
                    </ng-template>
                  </td>
                  <td class="text-center">
                    <span class="kphicon-uniE91D" style="color: green"
                      *ngIf="item?.single_use; else notSingleUse"></span>
                    <ng-template #notSingleUse>
                      <span class="kphicon-uniE926" style="color: red"></span>
                    </ng-template>
                  </td>
                  <td>{{ item?.code }}</td>
                  <td>{{ item?.voucher_product?.name }}</td>
                  <td>{{ item?.charge_type }}</td>
                  <td class="text-right">
                    {{ item?.default_amount | currencyFormat }}
                  </td>
                  <td>{{ item?.valid_from | date : "dd-MM-yyyy" }}</td>
                  <td>{{ item?.valid_until | date : "dd-MM-yyyy" }}</td>
                  <td class="white-space-nowrap text-center">
                    <button class="btn btn-md  btn-success" (click)="openVoucherModal(item?.code, voucherCreateModal)">
                      {{'VOUCHER_LIST.CREATE' | transloco}}
                    </button>
                    <button class="btn btn-sm  btn-primary ml-2"
                      (click)="openVoucherList(item?.code, voucherListModal)">
                      {{'VOUCHER_LIST.LIST' | transloco}}
                    </button>
                    <button *ngIf="item?.allow_anonymous_voucher" class="btn btn-sm  btn-info ml-2" (click)="
                        openAnonymousVoucherDownload(
                          item?.code,
                          anonymousVoucherModal
                        )
                      ">
                      {{'VOUCHER_LIST.GENERATE_MASS' | transloco}}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new">
              <pagination-controls id="voucher_profile_list" previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}"
                nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                (pageChange)="onPageClick($event)"></pagination-controls>
            </div>
          </ng-template>
        </div>
      </div>
      <ng-template #voucherListModal let-modal>
        <div class="modal-header">
          <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle" i18n="@@arm.order-status.order-details">
            {{'VOUCHER_LIST.VOUCHER_LIST' | transloco }} <span *ngIf="profileCode">({{profileCode}})</span>
          </h5>
          <button type="button" class="btn close" aria-label="Close" (click)="
              modal.close(); profileCode = null; selectedVoucherCode = '';voucherConfig.totalItems = 0;voucherConfig.currentPage = 1;
            ">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form [formGroup]="voucherSearchForm" (ngSubmit)="onSearchVoucher()">
            <div class="row align-items-center">
              <div class="col-md-6 mb-3">
                <div class="form-group mb-2 kpn-form-group">
                  <input type="text" formControlName="search" class="form-control kpn-form-control"
                    [placeholder]="'CUSTOMERS.SEARCH' | transloco">
                </div>
              </div>
              <div class="col-md-6 mb-3">
                <button type="submit" class="btn btn-success mb-2  px-4 mr-2"
                  [disabled]="!voucherSearchForm.get('search').value">{{'CUSTOMERS.SEARCH' | transloco}}</button>

                <button (click)="clearVoucherSearch()" *ngIf="voucherSearchForm.get('search').value"
                  class="btn btn-danger mb-2 mr-2 ">{{'QUOTES.CLEAR' | transloco}}</button>
              </div>
            </div>
          </form>
          <div class="kpn-card confirmation-cart-overview">
            <table class="table mb-1">
              <thead>
                <tr>
                  <th i18n="@@arm.order-status.mobile" class="text-center">
                    {{'VOUCHER_LIST.ACTIVE' | transloco}}
                  </th>
                  <th i18n="@@arm.order-status.order-num" class="text-center">
                    {{'VOUCHER_LIST.HAS_BEEN_USED' | transloco}}
                  </th>
                  <th i18n="@@arm.order-status.client-num">Voucher Code</th>
                  <th i18n="@@arm.order-status.client-num">{{'VOUCHER_LIST.CUSTOMER' | transloco}}</th>
                  <th i18n="@@arm.order-status.order-date">Email</th>
                  <th i18n="@@arm.order-status.company-name" class="text-center">
                    {{'VOUCHER_LIST.AMOUNT' | transloco}}
                  </th>
                  <th i18n="@@arm.order-status.status">{{'VOUCHER_LIST.REFERENCE' | transloco}}</th>
                  <th>{{'VOUCHER_LIST.ACTION' | transloco}}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let item of voucherList | paginate : voucherConfig">
                  <td class="text-center">
                    <span class="kphicon-uniE91D" style="color: green" *ngIf="item?.is_valid; else notValid"></span>
                    <ng-template #notValid>
                      <span class="kphicon-uniE926" style="color: red"></span>
                    </ng-template>
                  </td>
                  <td>
                    <span class="kphicon-uniE91D" *ngIf="item?.is_used"></span>
                  </td>
                  <td>{{ item?.voucher_code }}</td>
                  <td>{{ item?.name }}</td>
                  <td>{{ item?.email }}</td>
                  <td class="text-right">
                    {{ item?.amount | currencyFormat }}
                  </td>
                  <td>{{ item?.reference }}</td>
                  <td>
                    <button *ngIf="item?.is_valid && (!item?.is_date_expired) && item?.email" class="btn py-1 btn btn-sm btn-info" [disabled]="item?.loading"
                      (click)="sendInvitation(profileCode, item)">
                      <span *ngIf="item?.loading" class="spinner-border spinner-border-sm mb-1" role="status"
                        aria-hidden="true">
                      </span>
                      <small> {{'VOUCHER_LIST.SEND_INVITATION' | transloco}} </small>
                    </button>
                    <!-- lock button here -->
                    <button *ngIf="item.is_valid && hasVoucherChangePermission"
                      class="btn py-1 btn btn-sm  btn-danger ml-2" [disabled]="item?.loading"
                      (click)="lockVoucher(item.code)">{{'VOUCHER_LIST.LOCK' | transloco}}</button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new">
              <pagination-controls id="voucher_list" previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}"
                nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                (pageChange)="onVoucherPageClick($event)">
              </pagination-controls>
            </div>
          </div>
        </div>
      </ng-template>
      <ng-template #voucherCreateModal let-modal>
        <div class="modal-header">
          <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle" i18n="@@arm.order-status.order-details">
            {{'VOUCHER_LIST.CREATE_NEW_VOUCHER' | transloco}} {{ selectedProfileCode }}
          </h5>
          <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="kpn-card confirmation-cart-overview">
            <form [formGroup]="basicForm">
              <div class="row">
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.ext-num">Email <span class="danger-text">*</span></label>
                    <input type="text" class="form-control kpn-form-control" formControlName="email" placeholder=""
                      [ngClass]="{
                        'is-invalid':
                          !!errors?.email || basicForm.controls.email?.errors
                      }" />
                    <div *ngIf="errors?.email" class="invalid-feedback">
                      {{ errors?.email }}
                    </div>
                    <div *ngIf="basicForm.controls.email.hasError('trimError')" class="invalid-feedback">
                      {{ basicForm.controls.email.errors?.trimError?.value }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.int-num">{{'VOUCHER_LIST.NAME' | transloco}} <span
                        class="danger-text">*</span></label>
                    <input type="text" class="form-control kpn-form-control" formControlName="name" placeholder=""
                      [ngClass]="{ 'is-invalid': !!errors?.name }" />
                    <div *ngIf="errors?.name" class="invalid-feedback">
                      {{ errors?.name }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.client-num">{{'VOUCHER_LIST.AMOUNT' | transloco}} <span
                        class="danger-text">*</span></label>
                    <input type="number" class="form-control kpn-form-control" formControlName="amount" placeholder=""
                      [ngClass]="{ 'is-invalid': !!errors?.amount }"
                      *ngIf="!availableAmount.length; else selectVoucherAmount" />
                    <ng-template #selectVoucherAmount>
                      <select formControlName="amount" class="form-control">
                        <option value="">-- {{'VOUCHER_LIST.SELECT' | transloco}} --</option>
                        <option *ngFor="let item of availableAmount" [value]="item">
                          {{ item }}
                        </option>
                      </select>
                    </ng-template>
                    <div *ngIf="errors?.amount" class="invalid-feedback">
                      {{ errors?.amount }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.mob-num">{{'VOUCHER_LIST.REFERENCE' | transloco}}</label>
                    <input type="text" class="form-control kpn-form-control" formControlName="reference" placeholder=""
                      [ngClass]="{ 'is-invalid': !!errors?.reference }" />
                    <div *ngIf="errors?.reference" class="invalid-feedback">
                      {{ errors?.reference }}
                    </div>
                  </div>
                </div>

                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.client-num">{{'VOUCHER_LIST.VALID' | transloco}}</label>
                    <input formControlName="valid_from" [owlDateTime]="validFrom" [owlDateTimeTrigger]="validFrom"
                      [placeholder]="'VOUCHER_LIST.VALID' | transloco" class="form-control kpn-form-control" />
                    <owl-date-time #validFrom></owl-date-time>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.mob-num">{{'VOUCHER_LIST.VALID_UNTIL' | transloco}}</label>
                    <input formControlName="valid_until" [owlDateTime]="validUntil" [placeholder]="'VOUCHER_LIST.VALID_UNTIL' | transloco"
                      class="form-control kpn-form-control" [owlDateTimeTrigger]="validUntil" />
                    <owl-date-time #validUntil></owl-date-time>
                  </div>
                </div>
              </div>

              <div class="row align-items-center" *ngIf="errors?.generic">
                <div class="col-md-10 col-10 col-gap-reduce">
                  <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                    <p>
                      <span class="kphicon-uniE952"></span>
                      {{ errors?.generic }}
                    </p>
                    <button class="kphicon-uniE926"></button>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end mb-4">
                <div class="col-md-auto col-6 col-gap-reduce">
                  <button type="button" class="btn px-3 mt-2 rounded-btn" i18n="@@arm.order-status.search-btn"
                    (click)="closeVoucher()" [disabled]="formSubmitting">
                    {{'VOUCHER_LIST.CANCEL' | transloco}}
                  </button>
                </div>
                <div class="col-md-2 col-gap-reduce">
                  <button type="button" class="btn btn-block btn-primary mt-2 rounded-btn"
                    i18n="@@arm.order-status.search-btn" (click)="createVoucher()"
                    [disabled]="!basicForm.valid || formSubmitting">
                    <span *ngIf="!formSubmitting; else showSpinner">{{'VOUCHER_LIST.SEND' | transloco}}</span>
                    <ng-template #showSpinner>
                      <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                    </ng-template>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
      <ng-template #anonymousVoucherModal let-modal>
        <div class="modal-header">
          <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle" i18n="@@arm.order-status.order-details">
            {{'VOUCHER_LIST.GENERATE_NEW_VOUCHERS' | transloco}} {{ selectedProfileCode }}
          </h5>
          <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="kpn-card confirmation-cart-overview">
            <form [formGroup]="anonymousVoucherForm">
              <div class="row">
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label> {{'VOUCHER_LIST.AMOUNT' | transloco}} <span class="danger-text">*</span></label>
                    <input type="number" class="form-control kpn-form-control" formControlName="amount" placeholder=""
                      [ngClass]="{
                        'is-invalid': !!anonymousVoucherErrors?.amount
                      }" *ngIf="!availableAmount.length; else selectVoucherAmount" />
                    <ng-template #selectVoucherAmount>
                      <select formControlName="amount" class="form-control">
                        <option value="">-- {{'VOUCHER_LIST.SELECT' | transloco}} --</option>
                        <option *ngFor="let item of availableAmount" [value]="item">
                          {{ item }}
                        </option>
                      </select>
                    </ng-template>
                    <div *ngIf="anonymousVoucherErrors?.amount" class="invalid-feedback">
                      {{ anonymousVoucherErrors?.amount }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.client-num">{{'VOUCHER_LIST.QUANTITY' | transloco}} <span
                        class="danger-text">*</span></label>
                    <input type="number" class="form-control kpn-form-control" formControlName="quantity" placeholder=""
                      [ngClass]="{
                        'is-invalid': !!anonymousVoucherErrors?.amount
                      }" />
                    <div *ngIf="anonymousVoucherErrors?.amount" class="invalid-feedback">
                      {{ anonymousVoucherErrors?.amount }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.client-num">{{'VOUCHER_LIST.VALID' | transloco}}</label>
                    <input formControlName="valid_from" [owlDateTime]="validFrom" [owlDateTimeTrigger]="validFrom"
                      [placeholder]="'VOUCHER_LIST.VALID' | transloco" class="form-control kpn-form-control" />
                    <owl-date-time #validFrom [startAt]="startAt"></owl-date-time>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.mob-num">{{'VOUCHER_LIST.VALID_UNTIL' | transloco}}</label>
                    <input formControlName="valid_until" [owlDateTime]="validUntil" [placeholder]="'VOUCHER_LIST.VALID_UNTIL' | transloco"
                      class="form-control kpn-form-control" [owlDateTimeTrigger]="validUntil" />
                    <owl-date-time #validUntil [startAt]="startAt"></owl-date-time>
                  </div>
                </div>
              </div>

              <div class="row align-items-center" *ngIf="anonymousVoucherErrors">
                <div class="col-md-12 col-12 col-gap-reduce">
                  <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                    <p>
                      <span class="kphicon-uniE952"></span>
                      {{ anonymousVoucherErrors?.message }}
                    </p>
                    <button class="kphicon-uniE926"></button>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end mb-4">
                <div class="col-md-auto col-6 col-gap-reduce">
                  <button type="button" class="btn px-3 mt-2 rounded-btn" i18n="@@arm.order-status.search-btn"
                    (click)="closeVoucher()" [disabled]="formSubmitting">
                    {{'VOUCHER_LIST.CANCEL' | transloco}}
                  </button>
                </div>
                <div class="col-md-2 col-gap-reduce">
                  <button type="button" class="btn btn-block btn-primary mt-2 rounded-btn"
                    i18n="@@arm.order-status.search-btn" (click)="downloadVoucher()"
                    [disabled]="!anonymousVoucherForm?.valid || formSubmitting">
                    <span *ngIf="!formSubmitting; else showSpinner">{{'VOUCHER_LIST.SEND' | transloco}}</span>
                    <ng-template #showSpinner>
                      <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                    </ng-template>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
