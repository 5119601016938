import { EPermissionActions, PermissionActions } from '../actions/permission.actions';

export function permissionReducer(state: any = [], action: PermissionActions) {
    switch (action.type) {
        case EPermissionActions.PERMISSION_LOAD_SUCCESS:
            return action.permission;
        default:
            return state;
    }
}
