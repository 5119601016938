export const systemTypeList: any[] = [
    { key: 'ingram_micro_hp', value: 'INGRAM MICRO HP' },
    { key: 'exclusive_networks', value: 'Exclusive Networks' },
    { key: 'generic', value: 'GENERIC' },
    { key: 'default', value: 'Default' }, // New added (24-08-2023)
];


export const ColorDictionary = {
    Beige: "#f5f5dc",
    Blauw: "#0000FF",
    Bora_paars: "#92d0d0",
    Diep_paars: "#B8AFE6",
    Goud: "#FFD700",
    Grafiet: "#251607",
    Grijsgroen: "#4d5645",
    Kosmosblauw: "#003249",
    Lichtblauw: "#add8e6",
    Midnight: "#191970",
    Nachtzwart: "#151B54",
    Paars: "#A020F0",
    Phantom_zwart: "#2d3134",
    Rood: "#FF0000",
    Rozegoud: "#B76E79",
    Ruimtezwart: "#505150",
    Starlight: "#F8F9EC",
    Sterrenlicht: "#FFE681",
    Zilver: "#C0C0C0",
    Zwart: "#000000",
    Groen: "#00FF00",
    Crème: "#FFFDD0",
    Lavendel: "#E6E6FA",
    Geweldig_limoen: "#32CD32",
    Geweldig_wit: "#E7DEDB",
    Geweldig_violet: "#7F00FF",
    Geweldig_grafiet: "#a5a6a1",
    Olijfgroen: "#808000",
    Ijsblauw: "#C8E9E9",
    Lichtgroen: "#90EE90",
    Mint: "#3EB489",
    NoColor: "#EBEBE4",
    Blue_Titanium: "#5B798E",
    Natural_Titanium: "#878681",
    White_Titanium: "#e4e4e4",
    Black_Titanium: "#54544e",
    Silver: "#c0c0c0",
    Space_Black: "#505150",
    Midnight_Black: "#00040D",
    Onyx_Gray: "#747473",
    Matzwart: "#28282B",
    Platinum: "#E5E4E2",
    Platina: "#E5E4E2",
    Graphite: "#251607",
    Purple: "#A020F0",
    Cream: "#FFFDD0",
    Grijs: "#808080",
    Gray: "#808080",
    Matte_Black: "#28282B",
    Sky_Blue: "#87CEEB",
    Transparent: "#FFFFFF",
    White: "#FFFFFF",
    Amber_yellow: "#FFBF00",
    Amber_Yellow: "#FFBF00",
    Grijs_marmer: "#BABABA",
    Kobaltviolet: "#974287",
    Onyx_zwart: "#353839",
    Titanium_Violet: "#888586",
    Titanium_violet: "#888586",
    Titanium_zwart: "#54544e",
    Titanium_Black: "#797982",
    Titanium_black: "#797982",
    Titanium_Grey: "#565f6b",
    Titanium_Yellow: "#eee600",
    Titanium_geel: "#eee600 ",
    Titaniumgrijs: "#565f6b"
};
