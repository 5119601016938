<div>
  <div class="tri-top-notification d-flex align-items-center justify-content-center p-2" *ngIf="hasTradeInBusinessPartner">
    <p class="font-kpn-extended m-0 mr-md-4">Je gaat een aanvraag indienen voor een <span class="kpn-extended-bold">{{ tradeInFor === 'own' ? 'eigen gebruik' :''  }} {{ tradeInFor === 'customer' ? 'klant' : ''  }}</span>.</p>
    <a *ngIf="showEdit" (click)="openBusinessPartnerSelectionModal()" class="learn-more-tradein font-kpn-extended cursor-pointer">
        Wijzig optie <i class="kphicon-uniE931 fs-20 align-middle"></i></a>
  </div>
  <ng-template #businessPartnerSelectionModal let-modal>
    <div class="px-4 pt-4">
        <div class="modal-header tri-modal-header p-0 pb-4">
            <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
                Voor wie ga je een aanvraag indienen?
            </h6>
            <!-- <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                <span class="kphicon-uniE926"></span>
            </button> -->
        </div>
    </div>

    <div class="modal-body p-0">
        <div class="p-4 font-kpn-extended">
            <p class="mb-4">Ga je deze aanvraag voor eigen gebruik of voor een klant indienen?</p>
            <div class="row gutter-row-15 mb-3">
                <div class="col-md-6 gutter-col-15">
                    <div class="d-flex align-items-center radio-check-box-type">
                        <input value="own" [(ngModel)]="tradeInFor" (ngModelChange)="onSelectTradeInFor()" type="radio" id="playing-for-1" name="playing-for" >
                        <label for="playing-for-1" class="d-flex w-100 align-items-center font-kpn-extended fs-16">
                            <span class="radio-bullet"></span>
                            Eigen gebruik  </label>
                    </div>
                </div>
                <div class="col-md-6 gutter-col-15">
                    <div class="d-flex align-items-center radio-check-box-type">
                        <input value="customer"  [(ngModel)]="tradeInFor" (ngModelChange)="onSelectTradeInFor()" type="radio" id="playing-for-2" name="playing-for" >
                        <label for="playing-for-2" class="d-flex w-100 align-items-center font-kpn-extended fs-16">
                            <span class="radio-bullet"></span>
                            Een klant </label>
                    </div>
                </div>
            </div>

            <div class="blue-warning-qoute d-flex mb-2">
                <i class="kphicon-uniE952 note-icon mr-3"></i>
                <p class="font-kpn-extended m-0 line-height-24">Je kunt slechts één aanvraag tegelijk per klant indienen.</p>
            </div>

            <div class="pt-2">
              <button [disabled]="!tradeInFor" (click)="modal.close()"
                  class="btn btn-block btn-primary border-2 mt-2 rounded-btn">
                  <span>Ga verder</span>
              </button>
           </div>
        </div>
    </div>
  </ng-template>

</div>
