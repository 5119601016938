import { Component, OnInit } from '@angular/core';
import { TradeInService } from '../trade-in.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-trade-in-orders',
  templateUrl: './trade-in-orders.component.html',
  styleUrls: ['./trade-in-orders.component.scss']
})
export class TradeInOrdersComponent implements OnInit {

  public tradeInOrders = [];
  public config: { itemsPerPage: number, currentPage: number, totalItems: number } = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  public error = null;

  constructor(
    private tradeInService: TradeInService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.getTradeInOrders();
  }

  getTradeInOrders() {
    this.error = null;
    const paramsString = `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}`;
    this.tradeInService.getTradeInOrders(paramsString).subscribe(res => {
      this.config.totalItems = res?.data?.count;
      this.tradeInOrders = res?.data?.results;
    }, error => {
      console.log('error: ', error);
      this.error = error?.error?.message
    })
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getTradeInOrders();
  }

  goToOrderDetails(order) {
    this.router.navigate([`trade-in/order/${order?.uuid}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

}
