import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiBaseService } from 'src/app/shared/api-base.service';
import { EProductTypes } from 'src/app/shared/enums/product-type.enums';
import { StorageService } from 'src/app/shared/storage.service';

@Injectable({
  providedIn: 'root'
})

export class FavoriteProductsService {

  public favoriteProducts$ = new BehaviorSubject(null);
  public currentFavoriteProducts$ = this.favoriteProducts$.asObservable();



  constructor(
    private apiBaseService: ApiBaseService,
    private storageService: StorageService,
    private toasterService: ToastrService
  ) { }

  getFavoriteProducts(page = 1, limit = 100) {
    let paramsString = `page=${page}&limit=${limit}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    const URI = `wishlists`;
    return this.apiBaseService.get(URI, params);
  }
  addFavoriteProduct(product_code: string) {
    const uri = `wishlists/items`;
    return this.apiBaseService.post(uri, { product_code });
  }

  removeFavoriteProduct(item_uuid: string) {
    const uri = `wishlists/items/${item_uuid}`;
    return this.apiBaseService.delete(uri);
  }

  changeCurrentFavoriteProducts(favoriteProducts: any) {
    this.storageService.setFavoriteProducts(favoriteProducts);
    this.favoriteProducts$.next(favoriteProducts);
  }

  clearFavoriteProducts() {
    this.storageService.removeFavoriteProduct();
    this.favoriteProducts$.next(null);
  }

  addToFavorite(product_code: string) {
    this.addFavoriteProduct(product_code).subscribe(response => {
      this.changeCurrentFavoriteProducts(response?.data);
      this.toasterService.success('Product succesvol toegevoegd aan favorieten');
    }, error => {
      this.toasterService.error(error?.error?.message || 'Failed to add item to wishlist')
    })
  }

  removeFromFavoriteList(wishlist_item_uuid) {
    this.removeFavoriteProduct(wishlist_item_uuid).subscribe(res => {
      this.changeCurrentFavoriteProducts(res?.data);
      this.toasterService.success('Product succesvol verwijderd uit favorieten');
    }, error => {
      this.toasterService.error(error?.error?.message || 'Failed to remove from wishlist')
    })
  }
}
