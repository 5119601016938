export enum EOrderStatus {
    PARKED = 'parked',
    NEW = 'new',
    MANUAL = 'manual',
    QUEUED = 'queued'
}


export enum EQuoteStatus {
    NEW = 'new',
    RETRY = 'retry',
    ERROR = 'error',
    MANUAL = 'manual',
    PARKED = 'parked',
    REJECTED = 'rejected',
    CANCELLED = 'cancelled',
    PROCESSED = 'processed',
    PENDING = 'pending',
    EXCEPTION = 'exception',
    EXPIRED = 'expired',
    APPROVED = 'approved',
}

export enum EQuoteSystemType {
    CISCO = 'cisco',
    DEFAULT = 'default',
    GENERIC = 'generic'
}

export enum ERmaStatusType {
    NEW = 'new',
    APPROVED = 'approved',
    CREATED = 'created'
}

export enum ERmaFlowType {
    DOA = 'dead_on_arrival',
}

export enum EPaymentStatusTypes {
    CANCELLED_BY_USER = 'CancelledByUser',
    SUCCESS = 'Success',
    FAILED = 'Failed',
    CANCELLED = 'cancelled',
    REJECTED = 'rejected'

}

export enum EAddressPrecision {
    ADDRESS = 'Address',
}
