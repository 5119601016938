<ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4" *ngIf="catalog && layout !== 'compact'"> 
    <li class="back-breadcrumb-btn">
    <a routerLink="/">
        <i class="kphicon-uniE906"></i> {{'QUOTES.BACK' | transloco }}
    </a>
    </li>
    <li class="home-btn ml-2 px-1 pb-1">
    <a routerLink="/">
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="UI Icon">
            <path id="Color" fill-rule="evenodd" clip-rule="evenodd" d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z" fill="#939393"/>
            </g>
        </svg>
    </a>
    </li>
    <li *ngIf="catalog" class="click-able"><a (click)="gotoGroupCode()">{{catalog?.name | titlecase}}</a></li>
    <li *ngIf="group"><a >{{group?.name}}</a></li>
</ul>
<h2 *ngIf="layout !== 'compact'" class="green-text font-kpn-black h1 fs-40 pt-2">{{group?.name || 'Product List'}}</h2>

<ul class="breadcum-new" *ngIf="catalog && layout === 'compact'">
    <li><a routerLink="/"> {{'BREADCRUMB.HOME' | transloco }}</a></li>
    <li *ngIf="catalog"><a (click)="gotoGroupCode()">{{ catalog?.name }}</a></li>
    <li *ngIf="group"><a>{{ group?.name }}</a></li>
</ul>
<h2 *ngIf="showTitle && group && layout === 'compact'" class="page-title-green">{{'BREADCRUMB.DO_YOU_ORDER' | transloco}} {{ group?.name }}</h2>
