<section class="bg-kpn-subtle-grey">
    <section class="py-5">
        <div class="container">
            <div class="bg-kpn-light cp-4 box-shadow-sm" *ngIf="cartId">
                <button (click)="goToCartPage()" class="flat-grey-light mb-2">
                    <i class="kphicon-uniE9B1 mr-2"></i>Terug
                </button>
                <form [formGroup]="basicForm">
                    <div class="row mb-2 pt-4 mt-4">
                        <div class="col-md-10">
                            <h5 class="title-3 green-text mb-2">
                                {{'ADD_CUSTOMER.COMPANY_INFORMATION' | transloco}}
                            </h5>
                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-6 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">
                                        {{'CUSTOMER.COMPANY_NAME' | transloco }}
                                    </label>
                                    <div class="kpn-form-group">
                                        <input type="text" formControlName="company_name"
                                            class="form-control kpn-form-control" readonly />
                                    </div>
                                </div>
                            </div>
                            <div class="mb-4" formGroupName="address">
                                <div class="row gutter-row-15 mb-2">
                                    <div class="col-md-6 gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">
                                            {{'CUSTOMER.FIRST_NAME' | transloco }}
                                        </label>
                                        <div class="kpn-form-group">
                                            <input type="text" formControlName="first_name"
                                                class="form-control kpn-form-control" readonly />
                                        </div>
                                    </div>
                                    <div class="col-md-6 gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                                        <div class="kpn-form-group">
                                            <div class="field-with-spinner">
                                                <input type="text" formControlName="last_name"
                                                    class="form-control kpn-form-control" readonly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutter-row-15 mb-2">
                                    <div class="col-md-3 gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">Postcode</label>
                                        <div class="kpn-form-group">
                                            <div class="field-with-spinner">
                                                <input type="text" formControlName="zip_code"
                                                    class="form-control kpn-form-control"
                                                    [ngClass]="{ 'is-invalid': errors?.contact_zip_code }" readonly />
                                                <div *ngIf="errors?.contact_zip_code" class="invalid-feedback">
                                                    {{ errors?.contact_zip_code }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-3 gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.HOUSE_NUMBER' | transloco}}</label>
                                        <div class="kpn-form-group">
                                            <div class="field-with-spinner">
                                                <input type="text" formControlName="house_number"
                                                    class="form-control kpn-form-control"
                                                    [ngClass]="{ 'is-invalid': errors?.contact_house_number }"
                                                    readonly />
                                                <div *ngIf="errors?.contact_house_number" class="invalid-feedback">
                                                    {{ errors?.contact_house_number }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-auto gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.ADDITION' | transloco }}</label>
                                        <div class="kpn-form-group">
                                            <div class="field-with-spinner">
                                                <input type="text" formControlName="house_number_extension"
                                                    class="form-control kpn-form-control" readonly />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row gutter-row-15 mb-2">
                                    <div class="col-md-6 gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.STREET' | transloco}}</label>
                                        <div class="kpn-form-group">
                                            <div class="field-with-spinner">
                                                <input type="text" formControlName="street"
                                                    class="form-control kpn-form-control"
                                                    [ngClass]="{ 'is-invalid': errors?.contact_street }"
                                                    disabled
                                                />
                                                <div *ngIf="errors?.contact_street" class="invalid-feedback">
                                                    {{ errors?.contact_street }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 gutter-col-15 mb-2">
                                        <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.CITY' | transloco}}</label>
                                        <div class="kpn-form-group">
                                            <div class="field-with-spinner">
                                                <input type="text" formControlName="city"
                                                    class="form-control kpn-form-control"
                                                    [ngClass]="{ 'is-invalid': errors?.contact_city }"
                                                    disabled
                                                />
                                                <div *ngIf="errors?.contact_city" class="invalid-feedback">
                                                    {{ errors?.contact_city }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
                <div class="row">
                    <div class="col-md-10 pr-3">
                        <h5 class="title-3 green-text mb-2">
                            {{'CUSTOMER.CONTACT_PERSON' | transloco }} <small style="color: crimson;">({{'ADD_CUSTOMER.YOU_STILL_NEED_TO_SELECT_CONTACT' | transloco}})</small>
                        </h5>
                        <div class="row">
                            <div class="col-md-10 pr-2">
                                <div class="alert alert-danger text-center" *ngIf="isContactPersonEmpty">
                                    <b>{{'ADD_CUSTOMER.NO_CONTACT_PERSON_EXISTS' | transloco}}</b>
                                </div>
                                <form [formGroup]="contactPersonForm">
                                    <div *ngFor="let contactPersonFormGroup of contactPersonForms.controls">
                                        <div [formGroup]="contactPersonFormGroup">
                                            <div class="mb-2">
                                                <div class="pack-list mb-4">
                                                    <div ngbRadioGroup name="contactPersonItem"
                                                        [(ngModel)]="selectedContactPersonUUID" class="requester-list"
                                                        [ngModelOptions]="{ standalone: true }">
                                                        <div class="requester-item">
                                                            <label ngbButtonLabel class="pack-item" (click)="onSelectContact(true)">
                                                                <div class="pack-col pack-first-col-wrap">
                                                                    <div class="pack-first-col">
                                                                        <div class="radio-btn">
                                                                            <input ngbButton type="radio" (change)="
                                                                                    onContactPersonChecked(
                                                                                    contactPersonFormGroup, true
                                                                                    )
                                                                                " [value]="
                                                                                    contactPersonFormGroup.controls[
                                                                                    'uuid'
                                                                                    ].value
                                                                                " name="uuid" />
                                                                            <span class="radioBullet"></span>
                                                                        </div>
                                                                        <div class="pack-first-col-label">
                                                                            <h5>
                                                                                {{
                                                                                contactPersonFormGroup.controls[
                                                                                "initials"
                                                                                ]?.value
                                                                                }}
                                                                                {{
                                                                                contactPersonFormGroup.controls[
                                                                                "surname_prefix"
                                                                                ]?.value
                                                                                }}
                                                                                {{
                                                                                contactPersonFormGroup.controls[
                                                                                "last_name"
                                                                                ]?.value
                                                                                }}
                                                                            </h5>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </label>
                                                            <div class="requester-form" *ngIf="contactPersonUUID == selectedContactPersonUUID">
                                                                <div class="mb-3">
                                                                    <label
                                                                        class="form-label-bold dark-grey-text mb-0">{{'ADD_CUSTOMER.SALUTATION' | transloco}}</label>
                                                                    <div ngbRadioGroup formControlName="gender"
                                                                        class="radio_group mb-3">
                                                                        <label ngbButtonLabel
                                                                            class="default-radio mr-4">
                                                                            <div class="radio-btn">
                                                                                <input ngbButton type="radio"
                                                                                    value="M" />
                                                                                <span class="radioBullet"></span>
                                                                            </div>
                                                                            <span class="label">{{'ADD_CUSTOMER.MR' | transloco}}.</span>
                                                                        </label>
                                                                        <label ngbButtonLabel
                                                                            class="default-radio mr-4">
                                                                            <div class="radio-btn">
                                                                                <input ngbButton type="radio"
                                                                                    value="F" />
                                                                                <span class="radioBullet"></span>
                                                                            </div>
                                                                            <span class="label">{{'ADD_CUSTOMER.MRS' | transloco}}.</span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                                <div class="row gutter-row-15 mb-2">
                                                                    <div class="col-md-6 gutter-col-15">
                                                                        <div class="row gutter-row-15">
                                                                            <div class="col-md-7 gutter-col-15 mb-2">
                                                                                <label
                                                                                    class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.INITIALS' | transloco}}</label>
                                                                                <div class="kpn-form-group">
                                                                                    <input type="text"
                                                                                        class="form-control kpn-form-control"
                                                                                        formControlName="initials"
                                                                                        placeholder="bv A.W.R.P" />
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-md-5 gutter-col-15 mb-2">
                                                                                <label
                                                                                    class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.MIDDLE_NAME' | transloco}}</label>
                                                                                <div class="kpn-form-group">
                                                                                    <input type="text"
                                                                                        class="form-control kpn-form-control"
                                                                                        formControlName="surname_prefix"
                                                                                        placeholder="bv. van" />
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-md gutter-col-15 mb-2">
                                                                        <label
                                                                            class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                                                                        <div class="kpn-form-group">
                                                                            <input type="text"
                                                                                class="form-control kpn-form-control"
                                                                                formControlName="last_name"
                                                                                placeholder="" />
                                                                        </div>
                                                                    </div>
                                                                    <!-- <div class="col-md gutter-col-15 mb-2">
                                                                  <label class="form-label-bold dark-grey-text mb-0">Geboortedatum</label>
                                                                  <div class="kpn-form-group">
                                                                    <input type="text" mask="0000-M0-d0" class="form-control kpn-form-control" formControlName="birthday"
                                                                    placeholder="yyyy-mm-dd">
                                                                  </div>
                                                              </div> -->
                                                                </div>
                                                                <div class="row gutter-row-15 mb-0 align-items-start">
                                                                    <div class="col-md-6 gutter-col-15 mb-0">
                                                                        <label
                                                                            class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.PHONE_NUMBER' | transloco}}</label>
                                                                        <div class="kpn-form-group">
                                                                            <input type="text" mask="0000000000"
                                                                                class="form-control kpn-form-control"
                                                                                formControlName="mobile_phone_number" />
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-md-6 gutter-col-15 mb-0">
                                                                        <label
                                                                            class="form-label-bold dark-grey-text mb-0">{{'ADD_CUSTOMER.E_MAIL_ADDRESS' | transloco}}</label>
                                                                        <div class="kpn-form-group">
                                                                            <input type="text"
                                                                                class="form-control kpn-form-control"
                                                                                formControlName="email" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="row mb-2 pt-4 mt-4 aling-items-center justify-content-between">
                            <div class="col-md-auto">
                                <h5 class="title-3 green-text mb-2">
                                    {{'ORDER_CONFIRMATION.SHIPPING_ADDRESS' | transloco }}
                                </h5>
                                <p>
                                    {{'ADD_CUSTOMER.SELECT_ADDRESS_WHICH_THE_ORDER_SHOULD_SENT_YOU_CAN_ALSO_CREATE_NEW_ADDRESS' | transloco}}.
                                </p>
                            </div>
                            <div class="col-md-auto">
                                <button type="button" class="btn btn-sm btn-block btn-primary px-4 rounded-btn"
                                    (click)="createAddress(createAddressModal)">
                                    {{'ADD_CUSTOMER.ADD_ADDRESS' | transloco}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="!isShippingAddressesLoading; else shippingLoader">
                        <div class="table-responsive custom-table border-on-head">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>{{'CUSTOMER.COMPANY_NAME' | transloco }}</th>
                                        <th>{{'ORDER_CONFIRMATION.NAME' | transloco }}</th>
                                        <th>{{'SIGN_UP.LAST_NAME' | transloco}}</th>
                                        <th>{{'SIGN_UP.STREET' | transloco}}</th>
                                        <th>{{'CUSTOMER.HOUSE_NO' | transloco }}.</th>
                                        <th>{{'CUSTOMER.HOUSE_NO' | transloco }}. {{'ADD_CUSTOMER.ADD' | transloco}}.</th>
                                        <th>Postcode</th>
                                        <th>{{'SIGN_UP.CITY' | transloco}}</th>
                                        <th>Land</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let ship of shippingAddress; let i = index">
                                        <td>
                                            <div class="radio-btn-single">
                                                <input type="radio" [id]="'radio'+i" name="radio-group"
                                                    (change)="onSelectShippingAddress(ship.uuid)"
                                                    [checked]="ship.uuid == selectedShippingId || ship.is_default">
                                                <label [for]="'radio'+i" class="m-0">{{ ship.company_name }}</label>
                                            </div>
                                        </td>
                                        <td>{{ ship.first_name }}</td>
                                        <td>{{ ship.last_name }}</td>
                                        <td>{{ ship.street }}</td>
                                        <td>{{ ship.house_number }}</td>
                                        <td>{{ ship.house_number_extension }}</td>
                                        <td>{{ ship.zip_code }}</td>
                                        <td>{{ ship.city }}</td>
                                        <td>{{ ship.country }}</td>
                                        <td>
                                            <button type="button" class="btn btn-sm btn-block btn-primary rounded-btn"
                                                (click)="onEditShippingAddress(editAddressModal, ship.uuid, i, ship.id)">
                                                {{'ADD_CUSTOMER.CHANGE' | transloco}}
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <ng-template #shippingLoader>
                        <div class="col-md-12">
                            <div class="section-loader">
                                <div class="kpn-spinner">
                                    <img src="/assets/images/kpn-loader.gif" alt="Loading" />
                                </div>
                            </div>
                        </div>
                    </ng-template>

                    <div class="col-md-12">
                        <div class="section-loader" *ngIf="isLoading">
                            <div class="kpn-spinner">
                                <img src="/assets/images/kpn-loader.gif" alt="Loading" />
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12 text-right pt-3">
                        <div class="section-loader" *ngIf="isLoading">
                            <div class="kpn-spinner">
                                <img src="/assets/images/kpn-loader.gif" alt="Loading" />
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <ng-template #tipContent>{{'ADD_CUSTOMER.SELECT_AN_APPLICANT_AND_A_SHIPPING_ADDRESS' | transloco}}</ng-template>
                                <span placement="top" [ngbTooltip]="tipContent">
                                    <button (click)="onSubmitCustomer()" class="btn px-5 btn-primary mb-0 rounded-btn"
                                        [disabled]="!isContactPersonSelected || isLoading">
                                        <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                        {{'PAGINATION.NEXT' | transloco }}
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



        <ng-template #createAddressModal let-modal>
            <div class="modal-header">
                <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle"
                    i18n="@@arm.order-status.order-details">
                    {{'CUSTOMER.DELIVERY_ADDRESS' | transloco }}</h5>
                <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="kpn-card confirmation-cart-overview">
                    <div class="px-5 pt-4">
                        <form [formGroup]="shippingForm">
                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.COMPANY_NAME' | transloco }}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="company_name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                                    <div class="kpn-form-group">
                                        <input type="text" class="form-control kpn-form-control"
                                            formControlName="first_name" />
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.LAST_NAME_PREFIX' | transloco }}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="surname_prefix" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="last_name" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">Postcode</label>
                                    <div class="kpn-form-group">
                                        <input type="text" class="form-control kpn-form-control"
                                            formControlName="zip_code" [ngClass]="{ 'is-invalid': errors?.zip_code }"
                                            (keyup)="onAddressSearch()" />
                                        <div *ngIf="errors?.zip_code" class="invalid-feedback">
                                            {{ errors?.zip_code }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}.</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="house_number"
                                                [ngClass]="{ 'is-invalid': errors?.house_number }"
                                                (keyup)="onAddressSearch()" />
                                            <div *ngIf="errors?.house_number" class="invalid-feedback">
                                                {{ errors?.house_number }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}. {{'ADD_CUSTOMER.ADD' | transloco}}.</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="house_number_extension" (keyup)="onAddressSearch()" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.STREET' | transloco}}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="street"
                                                [ngClass]="{ 'is-invalid': errors?.street || addressCombinationMsg }"
                                            />
                                            <div *ngIf="isAddressFetching"
                                                class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                                        </div>
                                        <div *ngIf="errors?.street" class="invalid-feedback">
                                            {{ errors?.street }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.CITY' | transloco}}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="city"
                                                [ngClass]="{ 'is-invalid': errors?.city || addressCombinationMsg }"
                                            />
                                            <div *ngIf="isAddressFetching"
                                                class="spinner-border spinner-border-sm field-spinner" role="status">
                                            </div>
                                            <div *ngIf="errors?.city" class="invalid-feedback">
                                                {{ errors?.city }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">Land</label>
                                    <div class="kpn-form-group">
                                        <select class="form-control kpn-form-control" formControlName="country"
                                            [ngClass]="{ 'is-invalid': errors?.country }">
                                            <option *ngFor="let country of countryList" [ngValue]="country.value">
                                                {{ country.label }}
                                            </option>
                                        </select>
                                        <div *ngIf="errors?.country" class="invalid-feedback">
                                            {{ errors?.country }}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                                <span class="circle-hint-icon">i</span>
                                <p class="body-text-1 mb-0">
                                    {{addressCombinationMsg}}
                                </p>
                            </div>
                            <div class="text-right mb-4 pt-4">
                                <button class="btn px-5 btn-primary mb-0 rounded-btn" (click)="onAddShippingAddress()" [disabled]="shipBtnDisable">
                                    <span>{{'RMA_CREATE.ADD' | transloco }}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </ng-template>

        <ng-template #editAddressModal let-modal>
            <div class="modal-header">
                <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle"
                    i18n="@@arm.order-status.order-details">
                    {{'CUSTOMER.CHANGE_DELIVERY_ADDRESS' | transloco }}</h5>
                <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <div class="kpn-card confirmation-cart-overview">
                    <div class="px-5 pt-4">
                        <form [formGroup]="editShippingForm">
                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.COMPANY_NAME' | transloco }}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="company_name" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                                    <div class="kpn-form-group">
                                        <input type="text" class="form-control kpn-form-control"
                                            formControlName="first_name" />
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.LAST_NAME_PREFIX' | transloco }}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="surname_prefix" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="last_name" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">Postcode</label>
                                    <div class="kpn-form-group">
                                        <input type="text" class="form-control kpn-form-control"
                                            formControlName="zip_code" [ngClass]="{ 'is-invalid': errors?.zip_code }"
                                            (keyup)="onEditAddressSearch()" />
                                        <div *ngIf="errors?.zip_code" class="invalid-feedback">
                                            {{ errors?.zip_code }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}.</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="house_number"
                                                [ngClass]="{ 'is-invalid': errors?.house_number }"
                                                (keyup)="onEditAddressSearch()" />
                                            <div *ngIf="errors?.house_number" class="invalid-feedback">
                                                {{ errors?.house_number }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}. {{'ADD_CUSTOMER.ADD' | transloco}}.</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="house_number_extension"
                                                (keyup)="onEditAddressSearch()" />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row gutter-row-15 mb-2">
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.STREET' | transloco}}</label>
                                    <div class="kpn-form-group">
                                    <div class="field-with-spinner">
                                        <input type="text" class="form-control kpn-form-control"
                                            formControlName="street"
                                            [ngClass]="{ 'is-invalid': errors?.street || addressCombinationMsg }"
                                        />
                                        <div *ngIf="isAddressFetching"
                                            class="spinner-border spinner-border-sm field-spinner" role="status">
                                        </div>
                                        </div>
                                        <div *ngIf="errors?.street" class="invalid-feedback">
                                            {{ errors?.street }}
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.CITY' | transloco}}</label>
                                    <div class="kpn-form-group">
                                        <div class="field-with-spinner">
                                            <input type="text" class="form-control kpn-form-control"
                                                formControlName="city"
                                                [ngClass]="{ 'is-invalid': errors?.city || addressCombinationMsg }"
                                            />
                                            <div *ngIf="isAddressFetching"
                                                class="spinner-border spinner-border-sm field-spinner" role="status">
                                            </div>
                                            <div *ngIf="errors?.city" class="invalid-feedback">
                                                {{ errors?.city }}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 gutter-col-15 mb-2">
                                    <label class="form-label-bold dark-grey-text mb-0">Land</label>
                                    <div class="kpn-form-group">
                                        <select class="form-control kpn-form-control" formControlName="country"
                                            [ngClass]="{ 'is-invalid': errors?.country }">
                                            <option *ngFor="let country of countryList" [ngValue]="country.value">
                                                {{ country.label }}
                                            </option>
                                        </select>
                                        <div *ngIf="errors?.country" class="invalid-feedback">
                                            {{ errors?.country }}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                                <span class="circle-hint-icon">i</span>
                                <p class="body-text-1 mb-0">
                                    {{addressCombinationMsg}}
                                </p>
                            </div>

                            <div class="text-right mb-4 pt-4">
                                <button class="btn px-5 btn-primary mb-0 rounded-btn"
                                    (click)="onUpdateShippingAddress()" [disabled]="shipBtnDisable">
                                    <span>{{'CUSTOMER.UPDATE' | transloco }}</span>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>

        </ng-template>
    </section>
</section>