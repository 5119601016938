import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { RmaService } from '../rma.service';
import { StorageService } from 'src/app/shared/storage.service';
import { RMA, RMALine } from '../models/rma.model';
import { ToastrService } from 'ngx-toastr';
import { ERmaFlowTypes, ERmaReasons, ERmaTypes } from 'src/app/shared/enums/rma-type.enums';
import { param } from 'jquery';
import { EMarketTypes } from 'src/app/shared/enums/market-type.enums';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { TranslocoService } from '@ngneat/transloco';


@Component({
  selector: 'app-rma-create',
  templateUrl: './rma-create.component.html',
  styleUrls: ['./rma-create.component.scss']
})
export class RmaCreateComponent implements OnInit, OnDestroy {

  public searchProducts: any[] = [];
  public searchFocused = false;
  public isLoadingScanIAN: boolean;
  public selectedProduct = null;
  public keyword = 'name';
  public contact_person = null;
  public customer = null;
  public rma_type = null;
  public rma_flow_type = null;
  public rma_flow_type_code = null;
  public rma_options = null;
  public rma_reasons = null;
  public selectedRmaOption = null;
  public selectedRmaReason = null;
  public rmaCreatePayload = new RMA();
  public rmaLine = new RMALine();
  public user = null;
  public marketType = EMarketTypes.BUSINESS;
  public channel = null;
  public disableOption = false;
  public disableReason = false;
  public rmaCreateLoader = false;
  public siteProfile = null;
  public rmaCreateError = null;
  public rmaCreateGenericError = null;


  private customerSubscription: Subscription;
  private requestorSubscription: Subscription;


  @ViewChild('productAutoComplete') auto;

  constructor(
    public store: Store<IAppState>,
    private route: ActivatedRoute,
    public rmaService: RmaService,
    private storageService: StorageService,
    private router: Router,
    private toastrService: ToastrService,
    public translocoService: TranslocoService,


  ) {
    this.user = this.storageService.getCurrentUser();
    const channel = this.user?.channels?.find(obj => true) || null;
    this.channel = channel?.code || null;
    this.rma_flow_type = this.route?.snapshot?.queryParams?.rma_type || null;
    this.rma_type = this.selectHeaderName(this.rma_flow_type);
    this.route.params.subscribe(p => {
      this.rma_flow_type_code = p?.rma_type || null;
      this.searchProducts = [];
      this.selectedProduct = null;
      this.clearScanInput();
      this.getContact();
      this.getRmaType(p?.rma_type);
    });


    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });

  }

  get eRmaTypes() { return ERmaTypes; }


  ngOnInit(): void {
  }

  getRmaType(rma_type) {
    this.rmaService.getRMAOptions(rma_type).subscribe(res => {
      const rma = res?.data?.results?.find(item => item) || null;
      this.rma_options = rma?.rma_options || [];
      this.rmaLine.rma_type = this.rma_options?.find(option => option)?.code || null;
      if (this.rmaLine.rma_type) {
        this.getRMAReason(this.rmaLine.rma_type);
      }
    });
  }

  getRMAReason(rma_type: string) {
    this.rmaLine.return_reason_type = null;
    this.rmaService.getRMAReasonByOption(this.rma_flow_type_code, rma_type).subscribe(response => {
      const rma = response?.data?.results?.find(item => item) || null;
      this.rma_reasons = rma?.return_reasons || [];
      this.rmaLine.return_reason_type = this.rma_reasons?.find(option => option)?.code || null;
    });
  }



  ngOnDestroy() {
    this.customerSubscription?.unsubscribe();
    this.requestorSubscription?.unsubscribe();
  }

  onSelectedProduct(product) {
    this.selectedProduct = product;
    this.rmaLine.price = product.sales_price || 0.00;
    this.getProduct(product?.code);
  }

  getProduct(productCode: string) {
    this.selectedProduct = { ...this.selectedProduct, swap_allowed: true, repair_allowed: false };
    // this.rmaService.getSwapProduct(productCode).subscribe(response => {
    //   const repair_allowed = response?.data?.attributes?.find(obj => {
    //     return obj?.attribute_code === 'repair_allowed';
    //   }) || null;
    //   if (repair_allowed) {
    //     this.selectedProduct = { ...this.selectedProduct, repair_allowed: repair_allowed?.value };
    //   }

    //   if (this.rma_flow_type === ERmaFlowTypes.SWAP_ARTIKELEN) {
    //     const swap_allowed = response?.data?.attributes?.find(obj => {
    //       return obj?.attribute_code === 'swap_allowed';
    //     }) || null;
    //     if (swap_allowed) {
    //       this.selectedProduct = { ...this.selectedProduct, swap_allowed: swap_allowed?.value };
    //     }
    //   } else {
    //     this.selectedProduct = { ...this.selectedProduct, swap_allowed: true };
    //   }
    // }, error => {
    //   console.log('error: ', error);
    // });
  }


  getContactUuid() {
    const contact_uuid = this.storageService.getCustomer()?.uuid || this.storageService.getCurrentUser()?.contact?.uuid;
    return contact_uuid || '';
  }

  getContactPersonUuid() {
    const contact_person_uuid = this.storageService.getContactPersonUUID();
    return contact_person_uuid || '';
  }

  getSelectedContact() {
    const contact = this.storageService.getCustomer();
    return contact || null;
  }


  onSearchProduct(searchString: string) {
    this.isLoadingScanIAN = true;
    this.selectedProduct = null;
    searchString = searchString?.trim();
    if (!searchString?.length) {
      this.searchProducts = [];
      this.isLoadingScanIAN = false;
      return;

    }
    if (searchString.length === 12 && parseInt(searchString, 10)) {
      searchString = '0' + searchString;
    }
    let queryString = `keyword=${searchString}`;
    if (this.rma_flow_type_code) {
      // tslint:disable-next-line:max-line-length
      queryString = `${queryString}&rma_flow_type_code=${this.rma_flow_type_code}&rma_type_code=${this.rmaLine.rma_type}&contact_uuid=${this.getContactUuid()}`;
    }
    this.rmaService.searchRMAProduct(queryString).subscribe(res => {
      if (res?.data?.results) {
        this.searchProducts = res.data.results;
        this.selectedProduct = this.searchProducts?.find(obj => true) || null;
        if (this.selectedProduct) {
          this.onSelectedProduct(this.selectedProduct);
        }
      }
      this.isLoadingScanIAN = false;
    }, error => {
      this.isLoadingScanIAN = false;
    });
  }

  selectEvent(item) {
    if (this.searchProducts?.length === 1) {
      this.onSelectedProduct(this.searchProducts[0]);
    } else if (this.searchProducts?.length > 1) {
      this.onSelectedProduct(item);
    }
  }

  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }
  clearScanInput() {
    this.auto?.clear();
    this.auto?.close();
  }


  addProduct() {
    if (this.selectedProduct && this.rmaLine.rma_type && this.rmaLine.return_reason_type) {
      this.rmaLine.product_code = this.selectedProduct?.code;
      this.rmaLine.product_name = this.selectedProduct?.name;
      const selectedRmaType = this.rmaLine.rma_type;
      this.rmaCreatePayload.lines.push(this.rmaLine);
      this.rmaLine = new RMALine();
      this.checkRmaType(selectedRmaType);
      this.selectedProduct = null;
      this.clearScanInput();
    }
  }

  getRmaTypeName(code: string) {
    return this.rma_options.find(obj => obj.code === code);
  }

  getRmaReasonName(code: string) {
    return this.rma_reasons.find(obj => obj.code === code);
  }

  removeItem(index: number) {
    this.rmaCreatePayload.lines.splice(index, 1);
  }

  createRma() {
    // this.getContact();
    this.rmaCreatePayload.channel_code = this.channel;
    this.rmaCreatePayload.market_type_code = this.marketType;
    this.rmaCreatePayload.rma_flow_type = this.rma_flow_type_code;

    this.rmaCreateLoader = true;
    this.rmaCreateError = null;
    this.rmaCreateGenericError = null;
    this.rmaService.createRma(this.rmaCreatePayload).subscribe(response => {
      this.toastrService.success(this.translocoService.translate('RMA_CREATE.RMA_CREATED_SUCCESSFULLY'), 'Success', {
        timeOut: 3000
      });
      this.rmaCreatePayload = new RMA();
      this.rmaCreateLoader = false;
      if (response?.data?.post_action_data?.order_uuid) {
        this.router.navigateByUrl(`/order-confirmation/${response?.data?.post_action_data?.order_uuid}`);
      } else {
        this.router.navigateByUrl(`/rma/${response?.data?.uuid}`);
      }
    }, error => {
      this.rmaCreateLoader = false;
      this.rmaCreateError = error?.error?.error;
      this.rmaCreateGenericError = error?.error?.message;
      this.toastrService.error(error?.error?.message || this.translocoService.translate('RMA_CREATE.RMA_CREATION_FAILED'), 'Error', {
        timeOut: 3000
      });

    });
  }


  checkRmaType(rma_type: string = '') {
    this.rmaLine.rma_type = rma_type || this.rma_options?.find(option => option)?.code || null;
    this.getRMAReason(this.rmaLine.rma_type);
    this.rmaLine.price = 0;
    this.disableOption = false;
    this.disableReason = false;
  }


  getContact() {
    this.rmaCreatePayload.partner_number = this.getSelectedContact()?.company_code || '';
    this.rmaCreatePayload.contact_person_uuid = this.getContactPersonUuid() || null;
    this.rmaCreatePayload.contact_uuid = this.getContactUuid() || null;
  }


  selectHeaderName(rma_type: string) {
    let title = null;
    switch (rma_type) {
      case 'swap_products':
        title = this.translocoService.translate('RMA_CREATE.SWAP_ITEMS');
        break;
      case 'doa_before_sales':
        title = this.translocoService.translate('RMA_CREATE.DOA_FOR_SALE');
        break;
      case 'rma_without_original_order':
        title = this.translocoService.translate('RMA_CREATE.RETURN_WITH_ANOTHER_PROOF_OF_PURCHASE');
        break;
      case 'rma_service_expert':
        title = this.translocoService.translate('RMA_CREATE.RETURN_SERVICE_EXPERT');
        break;

      default:
        break;
    }
    return title;
  }

  selectReason(rma_type: string) {
    this.disableReason = false;
    this.rmaLine.return_reason_type = null;
    this.rma_reasons = [];
    this.getRMAReason(rma_type);
  }

}
