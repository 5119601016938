import { Injectable } from '@angular/core';
import { GenericToasterService } from './generic-toaster-utils';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {
  public errorCode = [400, 401, 402, 405, 500, 502];
  constructor(
    private genericToasterService: GenericToasterService
  ) { }

  public errorMap(errorObj: any) {
    if (errorObj) {
      const errors = {};
      for (const key in errorObj?.error) {
        if (errorObj?.error?.hasOwnProperty(key)) {
          const element = errorObj?.error[key];
          if (element?.length) {
            errors[key] = element[0];
          } else {
            if (element?.hasOwnProperty('message')) {
              errors[key] = element.message;
            } else {
              for (const key1 in element) {
                if (element?.hasOwnProperty(key1)) {
                  const element1 = element[key1];
                  errors[key1] = element1.message;
                }
              }
            }
          }
        }
      }
      return errors;
    } else {
      return;
    }
  }

  errorHandle(err: any) {
    if (err?.error) {
      return this.errorMap(err);
    } else if (this.errorCode.includes(err?.status_code) && err?.errors == null) {
      this.genericError(err?.message, (err?.status_code).toString());
    } else {
      return;
    }
  }

  genericError(msg: string, errorCode: string) {
    this.genericToasterService.genericToaster('error', msg, errorCode);
  }
}
