import { HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { ApiBaseService } from "src/app/shared/api-base.service";

@Injectable({
  providedIn: "root",
})
export class QuoteDetailsService {
  public currentCustomerDetailsQuote$ = new BehaviorSubject(null);
  public currentCustomerQuoteDetailsContactInfo$ = new BehaviorSubject(null);
  constructor(private apiBaseService: ApiBaseService) { }

  // getQuotes(params = null) {
  //   const quotesURI = `quotes`;
  //   if (params) {
  //     return this.apiBaseService.get(quotesURI, params);
  //   } else {
  //     return this.apiBaseService.get(quotesURI);
  //   }
  // }

  getQuote(quoteUuid) {
    const quoteURI = `quotes/${quoteUuid}`;
    return this.apiBaseService.get(quoteURI);
  }

  updateQuote(quote) {
    const quoteURI = `quotes/${quote.uuid}/update`;
    return this.apiBaseService.put(quoteURI, quote);
  }

  updateQuoteField(quote_uuid: string, payload) {
    const quoteURI = `quotes/${quote_uuid}`;
    return this.apiBaseService.put(quoteURI, payload);
  }

  updateQuoteWithPoReference(quote) {
    const quoteURI = `quotes/${quote.uuid}/update/po-reference`;
    delete quote.uuid;
    return this.apiBaseService.put(quoteURI, quote);
  }

  sendEmail(quoteUuid) {
    const sendEmailURI = `quotes/${quoteUuid}/confirmation-email`;
    return this.apiBaseService.get(sendEmailURI);
  }

  getContactPerson(designation_type: string) {
    const params = new HttpParams({
      fromString: `designation_type=${designation_type}`
    });
    const getContactPerson = `contacts/contact-persons/with-roles`;
    return this.apiBaseService.get(getContactPerson, params);
  }

  getPageTemplates() {
    const URI = `quotes/templates`;
    return this.apiBaseService.get(URI);
  }

  getApproval(quoteUuid) {
    const getApprovalURL = `quotes/${quoteUuid}/approve`;
    return this.apiBaseService.get(getApprovalURL);
  }

  getRecalculation(quoteUuid) {
    const getApprovalURL = `quotes/${quoteUuid}/recalculate`;
    return this.apiBaseService.get(getApprovalURL);
  }

  getProductSearch(keyword: string) {
    const paramsString = `keyword=${keyword}`;
    const productListURI = `search/products/list`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(productListURI, params);
  }

  createCustomLine(payload: any, quoteUuid: string) {
    const URI = `quotes/${quoteUuid}/lines`;
    return this.apiBaseService.post(URI, payload);
  }

  updateCustomLine(payload: any, quote: any) {
    const URI = `quotes/${quote.quoteUuid}/lines/${quote.quoteLineUuid}`;
    return this.apiBaseService.put(URI, payload);
  }

  deleteCustomLine(quote: any) {
    return this.apiBaseService.delete(`quotes/${quote.quoteUuid}/lines/${quote.quoteLineUuid}`);
  }

  generateQuote(quoteUuid: string) {
    return this.apiBaseService.post(`quotes/${quoteUuid}/generate-quotation`);
  }

  createNewQuote(quoteUuid: string) {
    return this.apiBaseService.post(`quotes/${quoteUuid}/new`);
  }
  createNewQuoteByCustomer(quoteUuid: string) {
    return this.apiBaseService.post(`quotes/customer/${quoteUuid}/new`);
  }

  lockSalesPrice(quoteUuid: string, line_uuid: string) {
    const url = `quotes/${quoteUuid}/lines/${line_uuid}/lock-price`;
    const payload = {
      lock_price_type: "lock_sales_price"
    };
    return this.apiBaseService.post(url, payload);
  }

  unLockSalesPrice(quoteUuid: string, line_uuid: string) {
    const url = `quotes/${quoteUuid}/lines/${line_uuid}/lock-price`;
    const payload = {
      lock_price_type: "lock_sales_price"
    };
    return this.apiBaseService.deleteWithPayload(url, payload);
  }

  importQuote(quote_uuid: string, formData: FormData, importType) {
    const url = `quotes/${quote_uuid}/${importType}/import`;
    return this.apiBaseService.putFile(url, formData);
  }


  updateQuoteLineUsagesPrice(quotation_uuid, quotation_line_uuid, payload) {
    let url = `quotes/${quotation_uuid}/lines/${quotation_line_uuid}/extra/usages-prices`;
    return this.apiBaseService.post(url, payload)
  }


  downloadQuote(quoteUUID: string) {
    const url = `quotes/${quoteUUID}/download`;
    return this.apiBaseService.getFileAsObserve(url);
  }

  downloadQuoteZipped(quoteUUID: string) {
    const url = `quotes/${quoteUUID}/download/zip`;
    return this.apiBaseService.getFileAsObserve(url);
  }


  availableFreeFieldOfContact(contact_uuid) {
    const URL = `contacts/${contact_uuid}/user-fields`;
    return this.apiBaseService.get(URL, null, 'v2');
  }

  availableFreeField() {
    const URL = `contacts/user-defined-fields`;
    return this.apiBaseService.get(URL, null, 'v2');
  }

  addFreeFieldToContact(contact_uuid: string, payload) {
    const URL = `contacts/${contact_uuid}/user-fields`;
    return this.apiBaseService.post(URL, payload, 'v2');
  }

  requestPricing(quote_uuid: string) {
    const URL = `quotes/${quote_uuid}/request-pricing/`;
    return this.apiBaseService.get(URL);
  }
  updateQuoteFields(uuid: string, payload) {
    const URL = `quotes/${uuid}`;
    return this.apiBaseService.put(URL, payload);
  }

  getDepartments() {
    const URL = `contacts/departments`;
    return this.apiBaseService.get(URL, null, 'v2');
  }



  getQuoteTermsAndConditions(quote_uuid: string) {
    const URL = `quotes/${quote_uuid}/terms-and-conditions`;
    return this.apiBaseService.get(URL);
  }


  updateQuoteTermsAndConditions(quote_uuid: string, payload) {
    const URL = `quotes/${quote_uuid}/terms-and-conditions/accept`;
    return this.apiBaseService.put(URL, payload);
  }

  uploadBiddingQuoteDocument(quote_uuid: string, formData: FormData) {
    const url = `documents/quotation/${quote_uuid}`;
    return this.apiBaseService.postFile(url, formData);
  }

  getQuoteBiddingDocuments(page, quote_uuid: string) {
    const paramsString = `page=${page}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    const url = `quotes/${quote_uuid}/documents`;
    return this.apiBaseService.get(url, params);
  }

  getAttachments(quote_uuid: string) {
    const url = `quotes/${quote_uuid}/attachments`;
    return this.apiBaseService.get(url);
  }

  downloadQuoteBiddingDocument(documentUUID: string) {
    const url = `documents/${documentUUID}/download`;
    return this.apiBaseService.getFileAsObserve(url);
  }
  getQuoteWordContext(quote_uuid: string) {
    const URL = `quotes/${quote_uuid}/word-context`;
    return this.apiBaseService.get(URL);
  }
  updateCpaasUserInfo(quote_uuid: string, payload) {
    const URL = `quotes/${quote_uuid}/cpaas-user-info`;
    return this.apiBaseService.patch(URL, payload);
  }


  processDownloadFileResponse(response, is_zipped = false) {
    let header_content = response?.headers?.get('content-disposition');
    let file = header_content?.split('=')[1];
    file = is_zipped ? file?.substring(0, file.length) : file?.substring(1, file.length - 1);
    let fileN = file.split('/');
    const fileName = fileN[fileN.length - 1];
    let fName = fileName.split('.')?.[0];
    let extension = fileName.split('.')[1].toLowerCase();
    const fileURL = window.URL.createObjectURL(new Blob([response?.body], {
      type: response?.body?.type
    }));
    const fileLink = document.createElement('a');
    fileLink.href = fileURL;
    fileLink.setAttribute('download', is_zipped ? fileName : `${fName}_${new Date().getTime()}.${extension}`);
    document.body.appendChild(fileLink);
    fileLink.click();
    document.body.removeChild(fileLink);
  }
  getQuoteCancelReasons() {
    const URL = `quotes/cancel-reasons`;
    return this.apiBaseService.get(URL);
  }
  cancelQuote(quote_uuid: string, payload) {
    const URL = `quotes/${quote_uuid}/cancel`;
    return this.apiBaseService.post(URL, payload);
  }
  getPaymentTypes(quoteUUID: string) {
    const URL = `quotes/${quoteUUID}/payment-types`;
    return this.apiBaseService.get(URL);
  }
  updatePaymentType(quoteUUID: string, payload: any) {
    const URI = `quotes/${quoteUUID}/payment-types`;
    return this.apiBaseService.put(URI, payload);
  }

}
