<div class="row align-items-center">

  <div class="col-md-6">
    <div class="kpn-form-group default-search-field select-list-inquote">
      <div class="ng-autocomplete">
        <ng-autocomplete
          [disabled]="activeDropdown"
          [placeholder] = "'PROFILE_DROPDOWN.SELECT_ITEM'| transloco"
          [data]="searchData"
          [searchKeyword]="keyword"
          [initialValue]='initialValue'
          (selected)='selectEvent($event)'
          (inputChanged)='onChangeSearch($event)'
          (inputFocused)='onFocused()'
          (focusin)='onFocused()'
          (focusout)="onFocusedOut()"
          (inputCleared)="onInputCleared()"
          [itemTemplate]="itemTemplate">
        </ng-autocomplete>

        <ng-template #itemTemplate let-item>
          <a [innerHTML]="item.name"></a>
        </ng-template>

        <ng-template #notFoundTemplate let-notFound>
        <div [innerHTML]="notFound"></div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
