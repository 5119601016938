import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'salesPriceFilter'
  })
  export class SalesPriceFilterPipe implements PipeTransform {
    transform(value: any, str: string) {
      if (value) {
          if (str === 'price1') {
              return value.split('.')[0];
          }
  
          if (str === 'price2') {
              return value.split('.')[1];
          }
      }
      return '';
    }
  }
