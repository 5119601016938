import { Component, OnDestroy, OnInit, ViewChild, forwardRef } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  UntypedFormArray,
  Validators,
  FormControl,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { Observable, timer } from 'rxjs';

import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { AddressService } from 'src/app/shared/services/address.service';
import { EAddressPrecision } from 'src/app/shared/enums/order-status.enums';
import { StorageService } from 'src/app/shared/storage.service';
import { ErrorHandleService } from 'src/app/shared/error-handle.service';
import { AddCustomerService } from '../../add-customer/add-customer.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { IAppState } from 'src/app/app.state';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { CustomerService } from '../../customer/customer.service';
import { TradeInService } from '../trade-in.service';
import { TranslocoService } from '@ngneat/transloco';

export class TradeInCustomer {
  constructor(is_new = false) {
    if (is_new) {
      this.is_new = is_new;
      this.initials = '';
    }
  }
  initials = '';
  surname_prefix = '';
  last_name = '';
  birth_name_prefix = '';
  birth_name = '';
  gender = '';
  birthday = null;
  email = '';
  mobile_phone_number = '';
  bank_account_number = '';
  // id = new IdType();
  is_new = false;
  uuid = '';
}

export class IdType {
  bpr_proof = false;
  country = null;
  id_type = null;
  id_number = '';
  issue_date = '';
  expiration_date = '';
}




@Component({
  selector: 'app-customer',
  templateUrl: './customer.component.html',
  styleUrls: ['./customer.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TradeInCustomerComponent),
      multi: true
    }
  ]
})
export class TradeInCustomerComponent extends BasePermission implements OnInit, OnDestroy {
  customer$: Observable<any>;
  customer: any;
  selectedCompany: any;
  customerUuid: any;
  isContactPersonSelected = false;
  isContactedPersonDataUpdated = false;
  shippingForm: UntypedFormGroup;
  editShippingForm: UntypedFormGroup;

  basicForm: UntypedFormGroup;
  contactPersonForm: UntypedFormGroup;

  formCtrl: UntypedFormControl;

  selectedContactPersonUUID: '';
  isLoading = false;
  errors = null;
  modalReference: any;
  contactAddress = [];
  voucherProfileShippingAddress = [];
  countryList = [];
  selectedShippingId = 0;
  contactAddressUuid = '';
  shippingUuid = null;
  shippingIndex = 0;
  shippingId = 0;
  isShippingAddressesLoading = false;
  isAddressFetching = false;
  addressCombinationMsg = null;
  contactPersonUUID = null;
  isContactPersonEmpty = false;
  shipBtnDisable = false;
  shipTimeOut = null;

  public contact_persons = [];
  public contact_addresses = [];
  public selectedContactAddress: any = null;
  public selectedContactPerson: any = null;
  public trackAndTraceEmail: string = '';
  public trackAndTraceEmailError: [] = [];
  public c_gender = 'M';
  public newContactPerson = {
    initials: '',
    first_name: '',
    last_name: '',
    surname_prefix: '',
    birth_name_prefix: '',
    birth_name: '',
    gender: 'X',
    birthday: null,
    email: '',
    mobile_phone_number: ''
  };
  public activeStep = 'customer_select';
  public cart = null;
  public user = null;
  public shippingAddressCreate = {
    id: null,
    initials: '',
    first_name: '',
    surname_prefix: '',
    last_name: '',
    company_name: '',
    street: '',
    house_number: '',
    house_number_extension: '',
    zip_code: '',
    city: '',
    country: 'NL',
    isNew: true
  };

  public newContactPersonCreate = {
    status: 'new',
  };

  public createAddressLoader = false;
  public createContactPersonLoader = false;
  public deleteContactPersonLoader = false;
  public contactPersonUpdateError = null;
  public contactPersonCreateError = null;
  public zipCodePattern = null;
  public isNewQuote = false;
  public isImportedQuote = false;
  private siteProfile = null;
  public country = [];
  public NL_CODE = 'NL';
  public selectedCountry = this.NL_CODE;
  keyword = 'value';
  searchFocused = false;
  addressList: any[] = [];
  foreignAddressSearchError = null;
  testModel = '1';
  searchValue = '';
  addressCreateValue = '';
  addressFound = {
    zipcode: false,
    house_number: false,
    house_number_ext: false,
    city: false,
    street: false,
  };

  totalQtyInCart = 0;
  public tradeInBusinessPartner = null;
  public businessCustomerAddress = {
    company_name: '',
    street: '',
    city: '',
    zip_code: '',
    house_number: '',
    house_number_extension: '',
    country: 'NL',
  }
  public businessCustomerSelectedCountry = this.NL_CODE;
  public businessCustomerAddressList = [];
  public businessCustomerAddressFound = {
    zip_code: false,
    house_number: false,
    house_number_ext: false,
    city: false,
    street: false,
  }
  public businessCustomerAddressCreateValue = '';
  public businessCustomerForeignAddressSearchError = null;
  public businessCustomerContactPerson = {
    initials: '',
    first_name: '',
    last_name: '',
    surname_prefix: '',
    email: '',
    mobile_phone_number: '',
  }
  addressCreateOrUpdateError = {
    zip_code: false,
    house_number: false,
    city: false,
    street: false,
    company_name: false,
  };
  feContactPersonCreateError = {
    email: false,
    initials: false,
    first_name: false,
    last_name: false,
    surname_prefix: false,
    birth_name_prefix: false,
    birth_name: false,
  }
  businessCustomerContactPersonCreateError = {
    email: false,
    initials: false,
    first_name: false,
    last_name: false,
  };
  public sameAsDeliveryAddress = true;
  public saveAsNewAddress = false;

  @ViewChild('auto') auto;
  @ViewChild('businessCustomerWithoutNlAddressSearchDropdown') businessCustomerWithoutNlAddressSearchDropdown;

  constructor(
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private customerService: CustomerService,
    private modalService: NgbModal,
    private router: Router,
    private toastrService: ToastrService,
    private storageService: StorageService,
    private errorHandleService: ErrorHandleService,
    private addCustomerService: AddCustomerService,
    private utilService: UtilityService,
    public store: Store<IAppState>,
    private addressService: AddressService,
    private tradeInService: TradeInService,
    private toaster: ToastrService,
    private translocoService: TranslocoService,
  ) {
    super(store);
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
    this.zipCodePattern = this.utilService.zipCodePattern();
    this.user = this.storageService.getCurrentUser();
    this.router.events
      .subscribe((event: NavigationStart) => {
        if (
          event instanceof NavigationStart &&
          event.navigationTrigger == "popstate" &&
          event.restoredState
        ) {
          timer(100).subscribe(time => {
            this.goTocustomersPage();
          })
        }
      });

    this.route.queryParams.subscribe(params => {
      this.isNewQuote = params?.newQuote || false;
      this.isImportedQuote = params?.importedQuote || false;
    });
  }

  get eAddressPrecision() {
    return EAddressPrecision;
  }

  get isNextStepDisabled() {
    if (this.tradeInBusinessPartner && this.tradeInBusinessPartner === 'customer') {
      if (
        (!this.customer?.is_active) || (this.customer?.order_blocked) || (this.isLoading)
        ||
        (!this.selectedContactPerson?.uuid || !this.contact_persons?.length)
        ||
        (
          !this.businessCustomerContactPerson?.first_name?.trim()?.length ||
          !this.businessCustomerContactPerson?.last_name?.trim()?.length ||
          !this.businessCustomerContactPerson?.email?.trim()?.length ||
          !this.validateEmail(this.businessCustomerContactPerson?.email) ||
          !this.businessCustomerContactPerson?.initials?.trim()?.length
        )
        ||
        (
          !this.businessCustomerAddress?.company_name?.trim()?.length ||
          !this.businessCustomerAddress?.street?.trim()?.length ||
          !this.businessCustomerAddress?.city?.trim()?.length ||
          !this.businessCustomerAddress?.zip_code?.trim()?.length ||
          // !this.businessCustomerAddress?.house_number?.trim()?.length ||
          !(this.businessCustomerAddress?.house_number ? this.businessCustomerAddress.house_number + ''.trim()?.length : false) ||

          !this.businessCustomerAddressFound.street || !this.businessCustomerAddressFound.city || !this.businessCustomerAddressFound.zip_code || !this.businessCustomerAddressFound.house_number
        )

      ) {
        return true;
      }
    }
    else if (this.tradeInBusinessPartner && this.tradeInBusinessPartner === 'own') {
      if (
        (!this.customer?.is_active) || (this.customer?.order_blocked) || (this.isLoading)
        ||
        ((this.selectedContactPerson && this.selectedContactPerson?.uuid === undefined) && (
          !this.newContactPerson?.first_name?.trim()?.length ||
          !this.newContactPerson?.last_name?.trim()?.length ||
          !this.newContactPerson?.email?.trim()?.length ||
          !this.newContactPerson?.initials?.trim()?.length
        ))
        ||
        (!this.selectedContactPerson || !this.contact_persons?.length)
        ||
        (this.saveAsNewAddress && (
          !this.selectedContactAddress?.city?.trim()?.length ||
          !this.selectedContactAddress?.street?.trim()?.length ||
          !this.selectedContactAddress?.zip_code?.trim()?.length ||
          // !this.selectedContactAddress?.house_number?.trim()?.length ||
          !(this.selectedContactAddress?.house_number ? this.selectedContactAddress.house_number + ''.trim()?.length : false) ||

          !this.selectedContactAddress?.company_name?.trim()?.length ||
          !this.addressFound.street ||
          !this.addressFound.city ||
          !this.addressFound.zipcode ||
          !this.addressFound.house_number))
        ||
        ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && (this.selectedContactAddress && !this.selectedContactAddress?.id) && (
          !this.selectedContactAddress?.city?.trim()?.length ||
          !this.selectedContactAddress?.street?.trim()?.length ||
          !this.selectedContactAddress?.zip_code?.trim()?.length ||
          !(this.selectedContactAddress?.house_number ? this.selectedContactAddress.house_number + ''.trim()?.length : false) ||
          !this.selectedContactAddress?.company_name?.trim()?.length ||
          !this.addressFound.street ||
          !this.addressFound.city ||
          !this.addressFound.zipcode ||
          !this.addressFound.house_number))
        ||
        ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && !this.selectedContactAddress)
        ||
        ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && this.selectedContactAddress?.id && (!this.addressFound.street ||
          !this.addressFound.city ||
          !this.addressFound.zipcode ||
          !this.addressFound.house_number))

        ||

        (this.sameAsDeliveryAddress && !this.contactAddress?.length)



      ) {
        return true;
      }
    }
    else {
      if (
        (!this.customer?.is_active) || (this.customer?.order_blocked) || (this.isLoading)
        ||
        ((this.selectedContactPerson && this.selectedContactPerson?.uuid === undefined) && (
          !this.newContactPerson?.first_name?.trim()?.length ||
          !this.newContactPerson?.last_name?.trim()?.length ||
          !this.newContactPerson?.email?.trim()?.length ||
          !this.newContactPerson?.initials?.trim()?.length
        ))
        ||
        (!this.selectedContactPerson || !this.contact_persons?.length)
        ||
        (this.saveAsNewAddress && (
          !this.selectedContactAddress?.city?.trim()?.length ||
          !this.selectedContactAddress?.street?.trim()?.length ||
          !this.selectedContactAddress?.zip_code?.trim()?.length ||
          // !this.selectedContactAddress?.house_number?.trim()?.length ||
          !(this.selectedContactAddress?.house_number ? this.selectedContactAddress.house_number + ''.trim()?.length : false) ||

          !this.selectedContactAddress?.company_name?.trim()?.length ||
          !this.addressFound.street ||
          !this.addressFound.city ||
          !this.addressFound.zipcode ||
          !this.addressFound.house_number))
        ||
        ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && (this.selectedContactAddress && !this.selectedContactAddress?.id) && (
          !this.selectedContactAddress?.city?.trim()?.length ||
          !this.selectedContactAddress?.street?.trim()?.length ||
          !this.selectedContactAddress?.zip_code?.trim()?.length ||
          !(this.selectedContactAddress?.house_number ? this.selectedContactAddress.house_number + ''.trim()?.length : false) ||
          !this.selectedContactAddress?.company_name?.trim()?.length ||
          !this.addressFound.street ||
          !this.addressFound.city ||
          !this.addressFound.zipcode ||
          !this.addressFound.house_number))
        ||
        ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && !this.selectedContactAddress)
        ||
        ((this.sameAsDeliveryAddress === false) && (this.saveAsNewAddress === false) && this.selectedContactAddress?.id && (!this.addressFound.street ||
          !this.addressFound.city ||
          !this.addressFound.zipcode ||
          !this.addressFound.house_number))


        ||

        (this.sameAsDeliveryAddress && !this.contactAddress?.length)


      ) {
        return true;
      }
    }
    // !customer?.is_active || customer?.order_blocked || !selectedContactPerson || isLoading ||
    //             !contactAddress?.length || !selectedContactAddress

  }

  ngOnInit() {
    this.storageService.getTradeInCart().subscribe(cart => {
      if (cart) {
        this.cart = JSON.parse(cart);

        this.totalQtyInCart = this.cart?.lines.reduce((sum, item) => sum + item.qty, 0);

      }
    });
    this.initializeBasicForm();
    // this.initContactPersonFormArray();
    this.getCountry();
    this.getCustomerDetails();
    this.selectedShippingId = this.storageService.getShippingAddressID();
    this.setTradeInBusinessPartner();
  }

  setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
  }
  setGender(contactPerson, value) {
    contactPerson.gender = this.c_gender;
  }

  getCountry() {
    if (this.country?.length) {
      return;
    }
    this.addressService.getCountry().subscribe(res => {
      this.country = res?.data?.results;
    });
  }

  fieldsValueAssignment(customer: any) {
    this.basicForm.controls.company_name.setValue(customer.company_name || this.siteProfile.title);
    this.basicForm.controls.coc_number.setValue(customer.coc_number);
    this.basicForm.controls.krn_number.setValue(customer.external_id1);
    this.basicForm.controls.bank_account_no.setValue(
      customer.bank_account_no
    );

    const contactAddress = customer?.contact_addresses.find(element => element?.address_type === 'contact');

    if (contactAddress) {
      this.contactAddressUuid = contactAddress.uuid;// this code is not using anywhere
    }

    if (customer?.contact_addresses?.length) {
      this.basicForm.controls['address'].setValue({
        zipcode: contactAddress ? contactAddress?.zip_code : customer?.contact_addresses[0]?.zip_code,
        house_number: contactAddress ? contactAddress?.house_number : customer?.contact_addresses[0]?.house_number,
        house_number_ext: contactAddress ? contactAddress?.house_number_extension : customer?.contact_addresses[0]?.house_number_extension,
        city: contactAddress ? contactAddress?.city : customer?.contact_addresses[0]?.city,
        street: contactAddress ? contactAddress?.street : customer?.contact_addresses[0]?.street,
      });
      this.checkSearchedAddressField(this.selectedContactAddress);
    }

  }

  private setCurrentFormControl() {
    const formCtrls = this.contactPersonForm.controls['contactPersonForms'][
      'controls'
    ];
    this.formCtrl = formCtrls.find(
      (el: any) => el.value.uuid === this.selectedContactPersonUUID
    );
  }


  async updateContactPersonData() {
    const selectedContactPerson = this.contact_persons.find(
      (el: any) => el.uuid === this.selectedContactPersonUUID
    );
    const formGroupData = {
      ...(this.formCtrl?.value || this.selectedContactPerson),
      email: this.selectedContactPerson?.email,
      mobile_phone_number: this.selectedContactPerson?.mobile_phone_number
    };

    const updateData = {
      contact_person: {
        ...selectedContactPerson,
        gender: 'X', // formGroupData.gender || selectedContactPerson?.gender || 'M',
        birth_name: formGroupData.birth_name,
        birth_name_prefix: formGroupData.birth_name_prefix,
        birthday: formGroupData.birthday,
        email: formGroupData.email,
        mobile_phone_number: formGroupData.mobile_phone_number,
        surname_prefix: formGroupData.surname_prefix,
        // tslint:disable-next-line:max-line-length
        first_name: selectedContactPerson?.first_name || selectedContactPerson?.initials || selectedContactPerson?.surname_prefix || 'anonymous'
      }
    };

    if (!updateData.contact_person.birthday) {
      delete updateData.contact_person.birthday;
    }
    if (!updateData.contact_person.bank_account_number) {
      delete updateData.contact_person.bank_account_number;
    }
    if (!updateData.contact_person.email) {
      delete updateData.contact_person.email;
    }
    if (!updateData.contact_person.mobile_phone_number) {
      delete updateData.contact_person.mobile_phone_number;
    }

    await this.customerService
      .updateContactPerson(this.customerUuid, this.selectedContactPersonUUID, updateData)
      .toPromise()
      .then(res => {
        this.isLoading = false;
        this.showToaster(`${this.translocoService.translate('CUSTOMER.CONTACT_UPDATED')}`);
        this.isContactedPersonDataUpdated = true;
      })
      .catch(error => {
        this.isLoading = false;
        this.showErrorToaster(`${this.translocoService.translate('CUSTOMER.CONTACT_PERSON_UPDATE_FAILED')}`);
        this.isContactedPersonDataUpdated = false;
        this.contactPersonUpdateError = error?.error?.error?.contact_person;
      });
  }


  navigateNext(cart) {
    if (cart?.lines?.length) {
      const hasUnsetAccountType = cart?.lines?.some(line => {
        return line?.account_type_required && !line?.extra?.contact_account_type_id;
      });
      if (hasUnsetAccountType) {
        this.router.navigate(['/cart', `${cart.uuid}`]);
      } else {
        this.router.navigate(['/order-overview', `${cart.uuid}`]);
      }
    } else {
      this.router.navigateByUrl('/home');
    }
  }

  initializeBasicForm() {
    this.basicForm = this.fb.group({
      company_name: new UntypedFormControl({ value: '', disabled: true }),
      coc_number: new UntypedFormControl({ value: '', disabled: true }),
      bank_account_no: new UntypedFormControl({ value: '', disabled: true }),
      krn_number: new UntypedFormControl({ value: '', disabled: true }),
      address: this.fb.group({
        zipcode: new UntypedFormControl({ value: '', disabled: true }),
        house_number: new UntypedFormControl({ value: '', disabled: true }),
        house_number_ext: new UntypedFormControl({ value: '', disabled: true }),
        city: new UntypedFormControl({ value: '', disabled: true }),
        street: new UntypedFormControl({ value: '', disabled: true }),
      }),
    });
  }



  saveTradeInCart(cart) {
    // this.toaster.success(`${this.translocoService.translate('TRADE_CUSTOMER.ADDED_CONTACT_SUCCESS')}`);
    this.storageService.setReComCart(cart);
  }

  errorShowTradeInCart(error) {
    this.toaster.error(error || `${this.translocoService.translate('TRADE_CUSTOMER.CART_UPDATED_FAILED_CART')}`);
  }

  updateTradeInCart() {
    const payloadWithContact = {
      shipping_address_uuid: this.selectedContactAddress?.uuid || '',
      contact_person_uuid: this.selectedContactPerson?.uuid || null,
      contact_uuid: this.customerUuid
    }

    if (!this.selectedContactPerson?.uuid) {
      delete payloadWithContact.contact_person_uuid
    }

    if (!this.selectedContactAddress?.uuid) {
      delete payloadWithContact.shipping_address_uuid
    }

    if (this.cart?.contact_uuid) {
      delete payloadWithContact.contact_uuid
    }
    return this.tradeInService.tradeInCartUpdate(payloadWithContact, this.cart?.uuid).subscribe(res => {
      this.saveTradeInCart(res?.data);
      this.isLoading = false;
    }, error => {
      this.errorShowTradeInCart(error?.error?.message);
      this.isLoading = false;
    });
  }

  onSelectContactAddress(selectedContactAddressUuid: string) {
    this.updateTradeInCart();
  }

  onChangeContactAddress(event) {
    this.addressCombinationMsg = null;
    this.errors = {};
    this.addressList = [];
    this.selectedCountry = this.selectedContactAddress.id ? this.selectedContactAddress.country : this.NL_CODE;
    this.selectedContactAddress.country = '';
    this.addressCreateValue = '';
    this.resetAddressFound();
    if (this.selectedContactAddress?.id) {
      timer(300).subscribe(time => {
        this.selectedContactAddress.country = this.selectedCountry;
        this.updateTradeInCart();
        this.checkSearchedAddressField(this.selectedContactAddress)
      })
    } else if (this.selectedContactAddress && !this.selectedContactAddress?.id) {
      this.selectedContactAddress = {
        first_name: '',
        surname_prefix: '',
        last_name: '',
        company_name: '',
        street: '',
        house_number: '',
        house_number_extension: '',
        zip_code: '',
        city: '',
        country: 'NL'
      };
      this.shippingAddressCreate = this.selectedContactAddress;

    } else {
      this.selectedShippingId = 0;
      this.storageService.setShippingAddressID(this.selectedShippingId);
    }
  }



  initContactPersonFormArray() {
    this.contactPersonForm = this.fb.group({
      contactPersonForms: new UntypedFormArray([]),
    });
  }

  get contactPersonForms() {
    return this.contactPersonForm.get('contactPersonForms') as UntypedFormArray;
  }

  goTocustomersPage() {
    if (this.tradeInBusinessPartner) {
      this.router.navigate([`trade-in/cart-details`], {
        queryParams: {},
        queryParamsHandling: 'merge'
      });
      return;
    }
    if (this.user?.contact_group?.is_external) {
      this.router.navigate([`trade-in/cart-details`], {
        queryParams: {},
        queryParamsHandling: 'merge'
      });
    } else {
      this.router.navigate(['/trade-in/customers'], {
        queryParams: {
          company_name: this.customer?.company_name,
          coc_number: this.customer?.coc_number,
          customer_uuid: this.customer?.uuid,
          is_trade_in: true,
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  createContactPersonForms(contactPerson): UntypedFormGroup {
    const formGroup = this.fb.group({
      gender: [
        {
          value: 'X', // contactPerson.gender,
          disabled: contactPerson.gender ? true : false,
        },
        Validators.required,
      ],
      initials: [
        {
          value: contactPerson.initials,
          disabled: contactPerson.initials ? true : false,
        },
        Validators.required,
      ],
      surname_prefix: [
        {
          value: contactPerson.surname_prefix,
          disabled: contactPerson.surname_prefix ? true : false,
        },
      ],
      last_name: [
        {
          value: contactPerson.last_name,
          disabled: contactPerson.last_name ? true : false,
        },
        Validators.required,
      ],
      birthday: [
        {
          value: contactPerson.birthday,
          disabled: contactPerson.birthday ? true : false,
        },
        Validators.required,
      ],
      birth_name_prefix: [
        {
          value: contactPerson.birth_name_prefix,
          disabled:
            this.selectedCompany &&
              (this.selectedCompany.export_id ||
                this.selectedCompany.external_id1)
              ? true
              : false,
        },
      ],
      birth_name: [
        {
          value: contactPerson.birth_name,
          disabled:
            this.selectedCompany &&
              (this.selectedCompany.export_id ||
                this.selectedCompany.external_id1)
              ? true
              : false,
        },
      ],
      mobile_phone_number: [
        contactPerson.mobile_phone_number,
        [Validators.required, Validators.minLength(10)]
      ],
      email: [contactPerson.email, [Validators.required, Validators.email]],
      uuid: [contactPerson.uuid],
    });
    return formGroup;
  }

  getCustomerDetails() {
    this.route.params.subscribe(
      (params) => {
        this.isLoading = true;
        this.customerUuid = params.customer_uuid;
        this.customerService.getCustomer(params.customer_uuid).subscribe(
          (res) => {
            this.customer = res.data;
            this.storageService.setCustomer(this.customer);
            this.cart = this.storageService.getReComCart();
            this.contactAddress = [...this.customer?.contact_addresses.filter(element => element?.address_type === 'shipping')];
            if (this.cart?.shipping_address_uuid) {
              this.selectedContactAddress = this.contactAddress?.find(cad => {
                return cad.uuid === this.cart?.shipping_address_uuid;
              }) || null;

              if (this.selectedContactAddress) {
                this.selectedShippingId = this.selectedContactAddress?.id;
                this.isContactedPersonDataUpdated = true;
              }
            }
            this.fieldsValueAssignment(res.data);

            if (this.user?.contact_group?.is_external) {

              if (!this.tradeInBusinessPartner) {
                this.contact_persons = res.data.contact_persons?.filter(cp => cp?.uuid === this.user?.contact_person?.uuid);
              }
              if (!this.contact_persons?.length) {
                this.contact_persons = res.data.contact_persons;
              }
              if (!this.tradeInBusinessPartner) {
                this.selectedContactPerson = this.contact_persons?.find(cp => {
                  return cp.uuid === this.user?.contact_person?.uuid;
                }) || null;
              }

              if (this.cart?.contact_person_uuid) {
                this.selectedContactPerson = this.contact_persons?.find(cp => {
                  return cp.uuid === this.cart?.contact_person_uuid;
                }) || null;
              }

              if (this.selectedContactPerson) {
                this.isContactPersonSelected = true;
                this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
                this.isContactedPersonDataUpdated = true;
              }
            } else {
              this.contact_persons = res.data.contact_persons;
              this.selectedContactPerson = this.contact_persons?.find(cp => {
                return cp.uuid === this.cart?.contact_person_uuid;
              }) || null;
            }
            this.isContactPersonEmpty = this.contact_persons.length > 0 ? false : true;
            // for (const contactPerson of this.contact_persons) {
            //   this.contactPersonForms.push(
            //     this.createContactPersonForms(contactPerson)
            //   );
            // }

            this.contact_addresses = res.data.contact_addresses.find(item => item.address_type === 'contact');// this code is not using anywhere
            this.retrieveSelectedItem();

            // this is for business partner customer
            if (this.tradeInBusinessPartner && this.tradeInBusinessPartner === 'customer' && this.cart?.cart_extra?.end_customer) {
              this.businessCustomerAddress.city = this.cart?.shipping_address_city ?? '';
              this.businessCustomerAddress.street = this.cart?.shipping_address_street ?? '';
              this.businessCustomerAddress.zip_code = this.cart?.shipping_address_zip_code ?? '';
              this.businessCustomerAddress.house_number = this.cart?.shipping_address_house_number ?? '';
              this.businessCustomerAddress.house_number_extension = this.cart?.shipping_address_house_number_extension ?? '';
              this.businessCustomerAddress.company_name = this.cart?.shipping_address_company_name ?? '';
              this.businessCustomerAddress.country = this.cart?.shipping_address_country || 'NL';
              this.businessCustomerSelectedCountry = this.cart?.shipping_address_country || 'NL';

              this.businessCustomerContactPerson.first_name = this.cart?.shipping_address_first_name ?? '';
              this.businessCustomerContactPerson.last_name = this.cart?.shipping_address_last_name ?? '';
              this.businessCustomerContactPerson.email = this.cart?.shipping_address_email ?? '';
              this.businessCustomerContactPerson.initials = this.cart?.shipping_address_initials ?? '';
              this.businessCustomerContactPerson.mobile_phone_number = this.cart?.shipping_address_phone_number ?? '';
              this.businessCustomerContactPerson.surname_prefix = this.cart?.shipping_address_surname_prefix ?? '';
              this.checkSearchedBusinessCustomerAddressField(this.businessCustomerAddress);
            }

            if (this.tradeInBusinessPartner && this.cart?.cart_extra?.end_customer === false && this.tradeInBusinessPartner === 'own' && !this.cart?.shipping_address_uuid && (
              !this.cart?.shipping_address_company_name &&
              !this.cart?.shipping_address_initials &&
              !this.cart?.shipping_address_first_name &&
              !this.cart?.shipping_address_last_name &&
              !this.cart?.shipping_address_surname_prefix &&
              !this.cart?.shipping_address_street &&
              !this.cart?.shipping_address_house_number &&
              !this.cart?.shipping_address_house_number_extension &&
              !this.cart?.shipping_address_zip_code &&
              !this.cart?.shipping_address_city &&
              !this.cart?.shipping_address_country
            )) {
              this.sameAsDeliveryAddress = true;
              this.saveAsNewAddress = false;
            }
            if (this.tradeInBusinessPartner && this.cart?.cart_extra?.end_customer === false && this.tradeInBusinessPartner === 'own' && !this.cart?.shipping_address_uuid && (
              this.cart?.shipping_address_company_name ||
              this.cart?.shipping_address_initials ||
              this.cart?.shipping_address_first_name ||
              this.cart?.shipping_address_last_name ||
              this.cart?.shipping_address_surname_prefix ||
              this.cart?.shipping_address_street ||
              this.cart?.shipping_address_house_number ||
              this.cart?.shipping_address_house_number_extension ||
              this.cart?.shipping_address_zip_code ||
              this.cart?.shipping_address_city ||
              this.cart?.shipping_address_country
            )) {
              this.selectedContactAddress = {
                id: null,
                initials: this.cart?.shipping_address_initials || '',
                first_name: this.cart?.shipping_address_first_name || '',
                surname_prefix: this.cart?.shipping_address_surname_prefix || '',
                last_name: this.cart?.shipping_address_last_name || '',
                company_name: this.cart?.shipping_address_company_name || '',
                street: this.cart?.shipping_address_street || '',
                house_number: this.cart?.shipping_address_house_number || '',
                house_number_extension: this.cart?.shipping_address_house_number_extension || '',
                zip_code: this.cart?.shipping_address_zip_code || '',
                city: this.cart?.shipping_address_city || '',
                country: this.cart?.shipping_address_country || 'NL',
                isNew: true
              }
              if (this.cart?.shipping_address_country) {
                this.selectedCountry = this.cart?.shipping_address_country ?? 'NL';
              }
              this.shippingAddressCreate = this.selectedContactAddress;
              this.saveAsNewAddress = false;
              this.sameAsDeliveryAddress = false;
              this.checkSearchedAddressField(this.selectedContactAddress);
            }
            if (this.tradeInBusinessPartner && this.cart?.cart_extra?.end_customer === false && this.cart?.shipping_address_uuid) {
              this.selectedContactAddress = this.contactAddress?.find(cad => {
                return cad.uuid === this.cart?.shipping_address_uuid;
              }) || null;
              if (this.selectedContactAddress) {
                this.sameAsDeliveryAddress = false;
                this.saveAsNewAddress = false;
                this.checkSearchedAddressField(this.selectedContactAddress);
              }
            }
            if (!this.tradeInBusinessPartner && this.cart?.shipping_address_uuid) {
              this.selectedContactAddress = this.contactAddress?.find(cad => {
                return cad.uuid === this.cart?.shipping_address_uuid;
              }) || null;
              if (this.selectedContactAddress) {
                this.sameAsDeliveryAddress = false;
                this.saveAsNewAddress = false;
              }
            }
            if (!this.tradeInBusinessPartner && !this.cart?.shipping_address_uuid && (
              this.cart?.shipping_address_company_name ||
              this.cart?.shipping_address_initials ||
              this.cart?.shipping_address_first_name ||
              this.cart?.shipping_address_last_name ||
              this.cart?.shipping_address_surname_prefix ||
              this.cart?.shipping_address_street ||
              this.cart?.shipping_address_house_number ||
              this.cart?.shipping_address_house_number_extension ||
              this.cart?.shipping_address_zip_code ||
              this.cart?.shipping_address_city ||
              this.cart?.shipping_address_country
            )) {
              this.selectedContactAddress = {
                id: null,
                initials: this.cart?.shipping_address_initials || '',
                first_name: this.cart?.shipping_address_first_name || '',
                surname_prefix: this.cart?.shipping_address_surname_prefix || '',
                last_name: this.cart?.shipping_address_last_name || '',
                company_name: this.cart?.shipping_address_company_name || '',
                street: this.cart?.shipping_address_street || '',
                house_number: this.cart?.shipping_address_house_number || '',
                house_number_extension: this.cart?.shipping_address_house_number_extension || '',
                zip_code: this.cart?.shipping_address_zip_code || '',
                city: this.cart?.shipping_address_city || '',
                country: this.cart?.shipping_address_country || 'NL',
                isNew: true
              }
              if (this.cart?.shipping_address_country) {
                this.selectedCountry = this.cart?.shipping_address_country ?? 'NL';
              }
              this.shippingAddressCreate = this.selectedContactAddress;
              this.saveAsNewAddress = false;
              this.sameAsDeliveryAddress = false;
              this.checkSearchedAddressField(this.selectedContactAddress);
            }
            if (!this.tradeInBusinessPartner && !this.cart?.shipping_address_uuid && (
              !this.cart?.shipping_address_company_name &&
              !this.cart?.shipping_address_initials &&
              !this.cart?.shipping_address_first_name &&
              !this.cart?.shipping_address_last_name &&
              !this.cart?.shipping_address_surname_prefix &&
              !this.cart?.shipping_address_street &&
              !this.cart?.shipping_address_house_number &&
              !this.cart?.shipping_address_house_number_extension &&
              !this.cart?.shipping_address_zip_code &&
              !this.cart?.shipping_address_city &&
              !this.cart?.shipping_address_country
            )) {
              this.sameAsDeliveryAddress = true;
              this.saveAsNewAddress = false;
            }


            this.isLoading = false;
          },
          (err) => {
            console.log('err: ', err);
            this.isLoading = false;
          }
        );
      },
      (err) => {
        console.log('err: ', err);
      }
    );
  }

  retrieveSelectedItem() {
    this.route.queryParams.subscribe(qp => {
      if (qp?.contact_person) {
        this.selectedContactPerson = this.contact_persons?.find(cp => {
          return cp.uuid === qp?.contact_person;
        }) || null;

        if (this.selectedContactPerson) {
          this.isContactPersonSelected = true;
          this.selectedContactPersonUUID = qp?.contact_person;
          this.isContactedPersonDataUpdated = true;
        }
      }

      if (qp?.shipping_address) {
        this.selectedContactAddress = this.contactAddress?.find(cp => {
          return cp.uuid === qp?.shipping_address;
        }) || null;

        if (this.selectedContactAddress) {
          this.selectedShippingId = this.selectedContactAddress?.id;
          this.isContactedPersonDataUpdated = true;
          this.checkSearchedAddressField(this.selectedContactAddress);
        }
      }
    });
  }

  onSelectContact(status) {
    if (status) {
      this.contactPersonUUID = this.selectedContactPersonUUID;
    }
  }



  onChangeContactPerson() {
    this.contactPersonCreateError = null;

    if (this.selectedContactPerson?.uuid) {
      this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
      if (this.cart) {
        this.updateTradeInCart();
      } else {
        this.isContactPersonSelected = true;
      }
    } else {
      this.contactPersonUUID = '';
    }

    /*

    if (this.selectedContactPerson && !this.selectedContactPerson?.status) {
      this.onContactPersonChecked(this.selectedContactPerson, true);
    } else if (this.selectedContactPerson && !this.selectedContactPerson?.status) {
      this.selectedContactPerson = { ...this.newContactPerson };
    } else {
      this.contactPersonUUID = this.selectedContactPersonUUID = '';// this code is not using anywhere
    }
    */
  }




  onContactPersonChecked(contactPersonFormGroup: any, status = false) {
    this.isLoading = true;
    this.selectedContactPersonUUID = contactPersonFormGroup?.value?.uuid || contactPersonFormGroup?.uuid;
    if (status) {
      this.onSelectContact(status);
    }

    // this.setCurrentFormControl();
    if (this.cart) {
      this.updateTradeInCart();
    } else {
      this.isContactPersonSelected = true;
      this.isLoading = false;
    }
  }


  onUpdateShippingAddress() {
    this.isShippingAddressesLoading = true;
    this.shipBtnDisable = true;
    const formValue = this.editShippingForm.controls;
    const data = Object.assign({});
    for (const key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        data[key] = formValue[key].value;
      }
    }
    data.country = data.country ? formValue.country.value : 'NL';
    data.address_type = 'contact';

    this.addCustomerService.updateNewAddress(data, this.customerUuid, this.shippingUuid)
      .subscribe(
        (response) => {
          if (response?.data) {
            const value = response?.data;
            this.contactAddress[this.shippingIndex] = {
              first_name: value.first_name,
              surname_prefix: value.surname_prefix,
              last_name: value.last_name,
              company_name: value.company_name,
              street: value.street,
              house_number: value.house_number,
              house_number_extension: value.house_number_extension,
              zip_code: value.zip_code,
              city: value.city,
              country: value.country,
              uuid: this.shippingUuid,
              id: this.shippingId
            };
          }
          this.modalReference.close();
          this.shipBtnDisable = false;
          this.isShippingAddressesLoading = false;
        },
        error => {
          this.shipBtnDisable = false;
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.isShippingAddressesLoading = false;
        }
      );
  }

  async onAddShippingAddress() {
    this.shipBtnDisable = true;
    const formValue = this.shippingForm.controls;
    const value = Object.assign({});

    for (const key in formValue) {
      if (formValue.hasOwnProperty(key)) {
        value[key] = formValue[key].value;
      }
    }

    value.country = value.country ? formValue.country.value : 'NL';
    value.address_type = 'contact';

    this.addCustomerService.submitNewAddress(value, this.customerUuid)
      .subscribe(
        (response) => {
          if (response?.data) {
            this.contactAddress.push(response?.data);
          }
          this.shipBtnDisable = false;
          this.modalReference.close();
        },
        error => {
          this.shipBtnDisable = false;
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
        }
      );
  }


  createContactAddress() {
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.selectedContactAddress.address_type = 'shipping';
    this.errors = {};
    this.addCustomerService.submitNewAddress(this.selectedContactAddress, this.customerUuid)
      .subscribe((response) => {
        if (response?.data) {
          this.contactAddress.unshift(response?.data);
          timer(500).subscribe(time => {
            this.selectedContactAddress = this.contactAddress?.find(item => item?.id === response?.data?.id) || null;
            this.updateTradeInCart()
          });
        }
        this.createAddressLoader = false;
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.createAddressLoader = false;
        }
      );
  }

  updateContactAddress() {
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.errors = {};
    this.selectedContactAddress.address_type = 'shipping';
    this.addCustomerService.updateNewAddress(this.selectedContactAddress, this.customerUuid, this.selectedContactAddress?.uuid)
      .subscribe((response) => {
        if (response?.data) {
          this.contactAddress = this.contactAddress.map(u => {
            return u.id === response?.data?.id ? { ...this.selectedContactAddress, ...response?.data } : u;
          });
          this.showToaster(`${this.translocoService.translate('TRADE_CUSTOMER.CONTACT_ADDRESS_UPDATED_SUCCESSFULLY')}`);

          timer(500).subscribe(time => {
            this.selectedContactAddress = this.contactAddress?.find(item => item?.id === this.selectedContactAddress?.id) || null;
            this.selectedShippingId = this.selectedContactAddress?.id;
            this.updateTradeInCart();
          });
        }
        this.createAddressLoader = false;
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.createAddressLoader = false;
        }
      );
  }

  createContactPerson() {
    this.createContactPersonLoader = true;
    this.contactPersonCreateError = null;
    this.addCustomerService.createContactPerson(this.customerUuid, this.newContactPerson)
      .subscribe((response) => {
        if (response?.data) {
          this.contact_persons.unshift(response?.data);
          timer(500).subscribe(time => {
            this.selectedContactPerson = this.contact_persons?.find(item => item?.uuid === response?.data?.uuid) || null;
            this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
            this.setCurrentFormControl();
            if (!this.cart?.contact_person_uuid) {
              this.updateTradeInCart()
            }
          });
        }
        this.createContactPersonLoader = false;
      },
        error => {
          this.createContactPersonLoader = false;
          this.showErrorToaster(`${this.translocoService.translate('CUSTOMER.CONTACT_PERSON_DELETE_FAILED')}`);
          this.contactPersonCreateError = error?.error?.error;
        }
      );
  }

  deleteContactPerson(contact_person_uuid: string) {
    this.deleteContactPersonLoader = true;
    this.addCustomerService.deleteContactPerson(this.customerUuid, contact_person_uuid)
      .subscribe((response) => {
        if (response?.data) {
          this.contact_persons = this.contact_persons.filter(item => {
            return item?.uuid !== contact_person_uuid;
          });
          timer(500).subscribe(time => {
            this.selectedContactPerson = null;
            this.selectedContactPersonUUID = '';
            this.isContactPersonSelected = false;
          });
        }
        this.deleteContactPersonLoader = false;
      },
        error => {
          this.deleteContactPersonLoader = false;
          this.showErrorToaster(`${this.translocoService.translate('CUSTOMER.CONTACT_PERSON_DELETE_FAILED')}`);
        }
      );
  }

  createContactAddressForOwnUser() {
    this.selectedContactAddress.address_type = 'contact';
    this.contactAddress = [...this.voucherProfileShippingAddress];
    this.addCustomerService.submitNewAddress(this.selectedContactAddress, this.customer?.uuid)
      .subscribe((response) => {
        this.contactAddress.unshift(response?.data);
        this.selectedContactAddress = response?.data;
        this.selectedShippingId = response?.data?.id;
        this.updateTradeInCart();
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
        }
      );
  }

  handleError(error: any) {
    this.errors = {};
    this.errors['city'] = error?.city;
    this.errors['country'] = error?.country;
    this.errors['house_number'] = error?.house_number;
    this.errors['company_name'] = error?.company_name;
    this.errors['street'] = error?.street;
    this.errors['zip_code'] = error?.zip_code;
    this.errors['surname_prefix'] = error?.surname_prefix;
    this.errors['initials'] = error?.initials;
    this.errors['first_name'] = error?.first_name;
    this.errors['last_name'] = error?.last_name;
  }

  showErrorToaster(msg) {
    this.toastrService.error(`${msg}`, 'Error', {
      timeOut: 4000
    });
  }

  showToaster(msg) {
    this.toastrService.success(`${msg}`, 'Success', {
      timeOut: 2000
    });
  }

  showInfoToaster(msg) {
    this.toastrService.success(`${msg}`, 'Info', {
      timeOut: 2000
    });
  }

  onAddressSearch() {
    if (this.selectedContactAddress?.zip_code && this.selectedContactAddress?.house_number) {
      this.searchAddress(
        {
          zipCode: this.selectedContactAddress?.zip_code,
          houseNumber: this.selectedContactAddress?.house_number,
          houseNumberExt: this.selectedContactAddress?.house_number_extension
        }
      );
    } else if (this.shippingForm?.value?.zip_code || this.shippingForm?.value?.house_number) {
      if (this.shippingForm?.value?.zip_code && this.shippingForm?.value?.house_number) {
        this.onSearchAddress(this.shippingForm);

      } else {
        this.shippingForm.controls['street'].setValue('');
        this.shippingForm.controls['city'].setValue('');
      }
    }
  }

  onEditAddressSearch() {
    if (this.editShippingForm.value.zip_code && this.editShippingForm.value.house_number) {
      this.onSearchAddress(this.editShippingForm);
    }
  }

  onSearchAddress(shippingForm) {
    this.errors = {}; this.addressCombinationMsg = null;
    shippingForm.controls['street'].setValue('');
    shippingForm.controls['city'].setValue('');

    clearTimeout(this.shipTimeOut);
    this.shipTimeOut = setTimeout(() => {
      this.isAddressFetching = true;
      this.shipBtnDisable = true;
      this.addressService.getNlAddress({
        zipCode: shippingForm.value.zip_code,
        houseNumber: shippingForm.value.house_number,
        houseNumberExt: shippingForm.value.house_number_extension
      }).subscribe(resp => {
        if (resp.data) {
          shippingForm.controls['street'].setValue(resp.data.street);
          shippingForm.controls['city'].setValue(resp.data.city);
          this.selectedContactAddress.street = resp.data.street;
          this.selectedContactAddress.city = resp.data.city;
          this.checkSearchedAddressField(resp.data);
        }
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
      }, error => {
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
        if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'CONTACT_NO_LOCATION_DATA') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error) {
          const errors = this.errorHandleService.errorHandle(error.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
        } else {
          this.addressCombinationMsg = `${this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG')}`;
        }
      });
    }, 1000);
  }

  searchAddress(value) {
    this.resetAddressFound();
    this.errors = {}; this.addressCombinationMsg = null;
    clearTimeout(this.shipTimeOut);
    this.shipTimeOut = setTimeout(() => {
      this.isAddressFetching = true;
      this.shipBtnDisable = true;
      this.addressService.getNlAddress(value).subscribe(resp => {
        if (resp.data) {
          this.selectedContactAddress.street = resp.data.street;
          this.selectedContactAddress.city = resp.data.city;
          this.checkSearchedAddressField(resp.data);
        }
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
      }, error => {
        this.selectedContactAddress.street = null;
        this.selectedContactAddress.city = null;
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
        if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'CONTACT_NO_LOCATION_DATA') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'ADDRESS_LOOKUP_NO_ADDRESS_DATA_FOUND') {
          this.addressCombinationMsg = error.error.error.message;
        }
        else if (error?.error?.error) {
          const errors = this.errorHandleService.errorHandle(error?.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
        } else {
          this.addressCombinationMsg = `${this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG')}`;
        }
      });
    }, 1000);
  }


  identify(index, item) {
    return item.id;
  }

  onSelectCountry(event, update = false) {
    if (update) {
      this.selectedCountry = event?.country_code || '';
    }
    this.addressList = [];
    this.resetForm();
    this.resetAddressFound(this.selectedCountry === this.NL_CODE ? false : true);
  }
  onSelectBusinessCustomerCountry(event, update = false) {
    this.businessCustomerSelectedCountry = event?.country_code || '';
    this.businessCustomerAddressList = [];
    this.resetBusinessCustomerAddress();
    this.resetBusinessAddressFound(this.businessCustomerSelectedCountry === this.NL_CODE ? false : true);
  }
  resetBusinessCustomerAddress() {
    this.businessCustomerAddress.company_name = '';
    this.businessCustomerAddress.street = '';
    this.businessCustomerAddress.city = '';
    this.businessCustomerAddress.zip_code = '';
    this.businessCustomerAddress.house_number = '';
    this.businessCustomerAddress.house_number_extension = '';
    this.businessCustomerAddress.country = this.businessCustomerSelectedCountry;
    this.addressCombinationMsg = null;
  }
  resetBusinessAddressFound(value: boolean = false) {
    for (const key in this.businessCustomerAddressFound) {
      if (Object.prototype.hasOwnProperty.call(this.businessCustomerAddressFound, key)) {
        this.businessCustomerAddressFound[key] = value;
      }
    }
  }
  onBusinessCustomerAddressSearch() {

    if (this.businessCustomerAddress?.zip_code && this.businessCustomerAddress?.house_number) {
      this.businessCustomerSelectedCountry = this.NL_CODE
      this.businessCustomerSearchAddress(
        {
          zipCode: this.businessCustomerAddress?.zip_code,
          houseNumber: this.businessCustomerAddress?.house_number,
          houseNumberExt: this.businessCustomerAddress?.house_number_extension
        }
      );
    }
  }

  checkSearchedBusinessCustomerAddressField(address) {
    this.businessCustomerAddressFound.house_number_ext = true;
    if (address?.street) {
      this.businessCustomerAddressFound.street = true;
    }
    if (address?.locality || address?.city) {
      this.businessCustomerAddressFound.city = true;
    }

    if (address?.postcode || address?.zip_code) {
      this.businessCustomerAddressFound.zip_code = true;
    }
    if (address?.buildingNumber || address?.house_number || address?.houseNumber) {
      this.businessCustomerAddressFound.house_number = true;
    }
  }

  businessCustomerSearchAddress(value) {

    this.resetBusinessAddressFound();
    this.errors = {}; this.addressCombinationMsg = null;
    clearTimeout(this.shipTimeOut);
    this.shipTimeOut = setTimeout(() => {
      this.isAddressFetching = true;
      this.shipBtnDisable = true;
      this.addressService.getNlAddress(value).subscribe(resp => {
        if (resp.data) {
          this.businessCustomerAddress.street = resp.data.street;
          this.businessCustomerAddress.city = resp.data.city;
          this.checkSearchedBusinessCustomerAddressField(resp.data);
        }
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        // this.onAddressStreetChange();
        // this.onAddressCityChange();
        // this.onAddressPostCodeChange();
        // this.onAddressHouseNoChange();
      }, error => {
        this.businessCustomerAddress.street = null;
        this.businessCustomerAddress.city = null;
        // this.selectedShippingAddress = {
        //   ...this.selectedShippingAddress,
        //   street: null,
        //   city: null
        // };
        this.isAddressFetching = false;
        this.shipBtnDisable = false;
        // this.onAddressStreetChange();
        // this.onAddressCityChange();
        // this.onAddressPostCodeChange();
        // this.onAddressHouseNoChange();
        if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'CONTACT_NO_LOCATION_DATA') {
          this.addressCombinationMsg = error.error.error.message;
        } else if (error.error.error.error_code === 'ADDRESS_LOOKUP_NO_ADDRESS_DATA_FOUND') {
          this.addressCombinationMsg = error.error.error.message;
        }
        else if (error?.error?.error) {
          const errors = this.errorHandleService.errorHandle(error?.error);
          errors['type'] = 'shipping_address';
          this.handleError(errors);
        } else {
          this.addressCombinationMsg = this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG');
        }
      });
    }, 1000);
  }

  businessCustomerSearchAddressForeign(searchString: string, context = '') {
    if (searchString && searchString.length >= 3) {
      let term = `term=${searchString}`;
      term = context ? `context=${context}&term=${searchString}` : term;
      this.getBusinessCustomerAddress(term);
    }
  }
  getBusinessCustomerAddress(term) {
    this.businessCustomerForeignAddressSearchError = '';
    this.isLoading = true;
    this.addressService.getAddress(this.businessCustomerSelectedCountry, term).subscribe(res => {
      this.businessCustomerAddressList = res?.data?.matches;
      const splitTerm = term?.split('term=');
      const n_term = splitTerm?.length > 1 ? splitTerm[1] : splitTerm[0];
      const find_src = this.businessCustomerAddressList.some(item => {
        return item?.value?.includes(n_term);
      });
      if (!find_src) {
        this.businessCustomerWithoutNlAddressSearchDropdown.clear();
        timer(50).subscribe(time => {
          this.businessCustomerAddressCreateValue = n_term;
        })
      }
      this.businessCustomerWithoutNlAddressSearchDropdown.open();
      this.isLoading = false;
    }, error => {
      this.businessCustomerForeignAddressSearchError = error?.error?.message;
      this.isLoading = false;
    });
  }

  onSelectedBusinessCustomerAddress(address) {
    timer(50).subscribe(time => {
      this.resetBusinessCustomerAddress();
      if (address?.context && address?.precision === EAddressPrecision.ADDRESS) {
        // this.searchValue = address.value;
        this.getBusinessCustomerAddressDetails(address?.context);
      } else {
        // this.searchValue = address.value;
        this.businessCustomerWithoutNlAddressSearchDropdown.close();
        this.businessCustomerSearchAddressForeign(address.value, address?.context || '');
      }
    });
  }

  getBusinessCustomerAddressDetails(context) {
    const term = `context=${context}`;
    this.resetBusinessCustomerAddress();
    this.resetBusinessAddressFound();
    this.addressService.getAddressDetails(this.selectedCountry, term).subscribe(res => {
      if (res?.data?.address) {
        this.businessCustomerAddress.street = res?.data?.address?.street;
        this.businessCustomerAddress.city = res?.data?.address?.locality;
        this.businessCustomerAddress.zip_code = res?.data?.address?.postcode;
        this.businessCustomerAddress.house_number = `${res?.data?.address?.buildingNumber ?? ''}`;
        this.businessCustomerAddress.house_number_extension = res?.data?.address?.buildingNumberAddition;
        this.businessCustomerAddress.country = this.selectedCountry;
        this.checkSearchedBusinessCustomerAddressField(res?.data?.address);

        // this.onAddressStreetChange();
        // this.onAddressCityChange();
        // this.onAddressPostCodeChange();
        // this.onAddressHouseNoChange();
      }
    }, error => {
      // this.onAddressStreetChange();
      // this.onAddressCityChange();
      // this.onAddressPostCodeChange();
      // this.onAddressHouseNoChange();
    });
  }
  businessCustomerAddressSearchClear() {
    this.resetBusinessCustomerAddress();
  }

  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }

  searchCleared() {
    this.resetForm();
  }

  onSelectedAddress(address) {
    timer(50).subscribe(time => {
      this.resetForm();
      if (address?.context && address?.precision === EAddressPrecision.ADDRESS) {
        this.searchValue = address.value;
        this.getAddressDetails(address?.context);
      } else {
        this.searchValue = address.value;
        this.auto.close();
        this.searchAddressForeign(this.searchValue, address?.context || '');
      }
    });
  }

  searchAddressForeign(searchString: string, context = '') {
    if (searchString && searchString.length >= 3) {
      let term = `term=${searchString}`;
      term = context ? `context=${context}&term=${searchString}` : term;
      this.getAddress(term);
    }
  }

  getAddress(term) {
    this.foreignAddressSearchError = '';
    this.isLoading = true;
    this.addressService.getAddress(this.selectedCountry, term).subscribe(res => {
      this.addressList = res?.data?.matches;
      const splitTerm = term?.split('term=');
      const n_term = splitTerm?.length > 1 ? splitTerm[1] : splitTerm[0];
      const find_src = this.addressList.some(item => {
        return item?.value?.includes(n_term);
      });
      if (!find_src) {
        this.auto.clear();
        timer(50).subscribe(time => {
          this.addressCreateValue = n_term;
        })
      }
      this.auto.open();
      this.isLoading = false;
    }, error => {
      this.foreignAddressSearchError = error?.error?.message;
      this.isLoading = false;
    });
  }

  getAddressDetails(context) {
    const term = `context=${context}`;
    this.resetForm();
    this.resetAddressFound();
    this.addressService.getAddressDetails(this.selectedCountry, term).subscribe(res => {
      if (res?.data?.address) {
        this.selectedContactAddress.street = res?.data?.address?.street;
        this.selectedContactAddress.city = res?.data?.address?.locality;
        this.selectedContactAddress.zip_code = res?.data?.address?.postcode;
        this.selectedContactAddress.house_number = `${res?.data?.address?.buildingNumber}`;
        this.selectedContactAddress.house_number_extension = res?.data?.address?.buildingNumberAddition;
        this.selectedContactAddress.country = this.selectedCountry;
        this.checkSearchedAddressField(res?.data?.address);

        this.onAddressStreetChange();
        this.onAddressCityChange();
        this.onAddressPostCodeChange();
        this.onAddressHouseNoChange();
      }
    }, error => {
      this.onAddressStreetChange();
      this.onAddressCityChange();
      this.onAddressPostCodeChange();
      this.onAddressHouseNoChange();
    });
  }

  resetForm() {
    this.selectedContactAddress.street = '';
    this.selectedContactAddress.city = '';
    this.selectedContactAddress.zip_code = '';
    this.selectedContactAddress.house_number = '';
    this.selectedContactAddress.house_number_extension = '';
    this.selectedContactAddress.country = this.selectedCountry;
    this.addressCombinationMsg = null;
  }

  resetAddressFound(value: boolean = false) {
    for (const key in this.addressFound) {
      if (Object.prototype.hasOwnProperty.call(this.addressFound, key)) {
        this.addressFound[key] = value;
      }
    }
  }

  checkSearchedAddressField(address) {
    this.addressFound.house_number_ext = true;
    if (address?.street) {
      this.addressFound.street = true;
    }
    if (address?.locality || address?.city) {
      this.addressFound.city = true;
    }

    if (address?.postcode || address?.zip_code) {
      this.addressFound.zipcode = true;
    }
    if (address?.buildingNumber || address?.house_number || address?.houseNumber) {
      this.addressFound.house_number = true;
    }
  }
  onAddressCityChange() {
    if (!this.selectedContactAddress?.city?.trim()?.length) {
      this.addressCreateOrUpdateError.city = true;
    } else {
      this.addressCreateOrUpdateError.city = false;
    }
  }
  onAddressStreetChange() {
    if (!this.selectedContactAddress?.street?.trim()?.length) {
      this.addressCreateOrUpdateError.street = true;
    } else {
      this.addressCreateOrUpdateError.street = false;
    }
  }
  onAddressHouseNoChange() {
    if (!this.selectedContactAddress?.house_number?.toString()?.trim()?.length) {
      this.addressCreateOrUpdateError.house_number = true;
    } else {
      this.addressCreateOrUpdateError.house_number = false;
    }
  }
  onAddressPostCodeChange() {
    if (!this.selectedContactAddress?.zip_code?.trim()?.length) {
      this.addressCreateOrUpdateError.zip_code = true;
    } else {
      this.addressCreateOrUpdateError.zip_code = false;
    }
  }
  onAddressCompanyChange() {
    if (!this.selectedContactAddress?.company_name?.trim()?.length) {
      this.addressCreateOrUpdateError.company_name = true;
    } else {
      this.addressCreateOrUpdateError.company_name = false;
    }
  }

  onContactPersonFirstNameChange() {
    if (!this.newContactPerson?.first_name?.trim()?.length) {
      this.feContactPersonCreateError.first_name = true;
    } else {
      this.feContactPersonCreateError.first_name = false;
    }
  }
  onContactPersonEmailChange() {
    if (!this.newContactPerson?.email?.trim()?.length) {
      this.feContactPersonCreateError.email = true;
    } else {
      this.feContactPersonCreateError.email = false;
    }
  }
  onContactPersonLastNameChange() {
    if (!this.newContactPerson?.last_name?.trim()?.length) {
      this.feContactPersonCreateError.last_name = true;
    } else {
      this.feContactPersonCreateError.last_name = false;
    }
  }
  onContactPersonInitialChange() {
    if (!this.newContactPerson?.initials?.trim()?.length) {
      this.feContactPersonCreateError.initials = true;
    } else {
      this.feContactPersonCreateError.initials = false;
    }
  }


  async nextStep() {
    if (!this.cart) {
      return;
    }

    // normal flow
    if (!this.tradeInBusinessPartner) {
      if (!this.selectedContactPerson?.uuid) {
        // create new contact person
        if (!(await this.contactPersonCreate())) return;
      } else {
        if (this.contactPersonUpdatePermission) {
          // update contact person
          if (!(await this.contactPersonUpdate())) return;
        }
      }

      //  address

      if (this.sameAsDeliveryAddress) {
        const updatePayLoad = {

          contact_uuid: this.customerUuid,
          shipping_address_uuid: null,
          contact_person_uuid: this.selectedContactPerson?.uuid,

          shipping_address_company_name: "",
          shipping_address_street: "",
          shipping_address_house_number: "",
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_email: "",
          shipping_address_phone_number: "",

        };
        if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
      }
      if (this.sameAsDeliveryAddress === false && (this.selectedContactAddress?.id)) {
        // update address
        if (this.hasContactAddressUpdatePermission) {
          if (!(await this.updateShippingAddress())) return;
          let updatePayLoad = {

            contact_uuid: this.customerUuid,
            shipping_address_uuid: this.selectedContactAddress?.uuid,

            shipping_address_company_name: "",
            shipping_address_street: "",
            shipping_address_house_number: "",
            shipping_address_house_number_extension: "",
            shipping_address_zip_code: "",
            shipping_address_city: "",
            shipping_address_country: "",
            shipping_address_initials: "",
            shipping_address_first_name: "",
            shipping_address_last_name: "",
            shipping_address_surname_prefix: "",
            shipping_address_email: "",
            shipping_address_phone_number: "",
          };
          if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
        }
      }
      if (this.sameAsDeliveryAddress === false && this.saveAsNewAddress === false && (!this.selectedContactAddress?.id)) {
        let updatePayLoad = {

          contact_uuid: this.customerUuid,
          shipping_address_uuid: null,

          shipping_address_company_name: this.selectedContactAddress?.company_name || "",
          shipping_address_street: this.selectedContactAddress?.street || "",
          shipping_address_house_number: this.selectedContactAddress?.house_number || "",
          shipping_address_house_number_extension: this.selectedContactAddress?.house_number_extension || "",
          shipping_address_zip_code: this.selectedContactAddress?.zip_code || "",
          shipping_address_city: this.selectedContactAddress?.city || "",
          shipping_address_country: this.selectedCountry,
          shipping_address_initials: this.selectedContactAddress?.initials || "",
          shipping_address_first_name: this.selectedContactAddress?.first_name || "",
          shipping_address_last_name: this.selectedContactAddress?.last_name || "",
          shipping_address_surname_prefix: this.selectedContactAddress?.surname_prefix || "",

          shipping_address_email: "",
          shipping_address_phone_number: "",
        };
        if (!(await this.tradeInCartUpdate(updatePayLoad))) return;

      }
      if (this.sameAsDeliveryAddress === false && this.saveAsNewAddress === true && (!this.selectedContactAddress?.id)) {
        // create new address
        if (!(await this.createShippingAddress())) return;
        let updatePayLoad = {

          contact_uuid: this.customerUuid,
          shipping_address_uuid: this.selectedContactAddress?.uuid,

          shipping_address_company_name: "",
          shipping_address_street: "",
          shipping_address_house_number: "",
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_email: "",
          shipping_address_phone_number: "",
        };
        if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
      }

    }


    // business customer flow
    if (this.tradeInBusinessPartner && this.tradeInBusinessPartner === 'customer') {
      const updatePayLoad = {
        trade_in_for_end_customer: true,

        shipping_address_initials: this.businessCustomerContactPerson.initials,
        shipping_address_first_name: this.businessCustomerContactPerson.first_name,
        shipping_address_last_name: this.businessCustomerContactPerson.last_name,
        shipping_address_surname_prefix: this.businessCustomerContactPerson.surname_prefix,
        shipping_address_email: this.businessCustomerContactPerson.email,
        shipping_address_phone_number: this.businessCustomerContactPerson.mobile_phone_number,

        shipping_address_company_name: this.businessCustomerAddress.company_name,
        shipping_address_street: this.businessCustomerAddress?.street || "",
        shipping_address_house_number: this.businessCustomerAddress?.house_number || "",
        shipping_address_house_number_extension: this.businessCustomerAddress?.house_number_extension || "",
        shipping_address_zip_code: this.businessCustomerAddress?.zip_code || "",
        shipping_address_city: this.businessCustomerAddress?.city || "",
        shipping_address_country: this.businessCustomerSelectedCountry,

        contact_uuid: this.customerUuid,
        contact_person_uuid: this.selectedContactPerson?.uuid,
        shipping_address_uuid: null,

      };
      if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
    }


    if (this.tradeInBusinessPartner && this.tradeInBusinessPartner === 'own') {

      if (!this.selectedContactPerson?.uuid) {
        // create new contact person
        if (!(await this.contactPersonCreate())) return;
      }
      /*
      // currently this option is disabled in UI
       else {
        if (this.contactPersonUpdatePermission) {
          // update contact person
          if (!(await this.contactPersonUpdate())) return;
        }
      }
      */
      if (this.sameAsDeliveryAddress) {
        const updatePayLoad = {
          trade_in_for_end_customer: false,

          contact_uuid: this.customerUuid,
          shipping_address_uuid: null,
          contact_person_uuid: this.selectedContactPerson?.uuid,

          shipping_address_company_name: "",
          shipping_address_street: "",
          shipping_address_house_number: "",
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_email: "",
          shipping_address_phone_number: "",

        };
        if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
      }
      if (this.sameAsDeliveryAddress === false && (this.selectedContactAddress?.id)) {
        // update address
        if (this.hasContactAddressUpdatePermission) {
          if (!(await this.updateShippingAddress())) return;
          let updatePayLoad = {
            trade_in_for_end_customer: false,

            contact_uuid: this.customerUuid,
            shipping_address_uuid: this.selectedContactAddress?.uuid,

            shipping_address_company_name: "",
            shipping_address_street: "",
            shipping_address_house_number: "",
            shipping_address_house_number_extension: "",
            shipping_address_zip_code: "",
            shipping_address_city: "",
            shipping_address_country: "",
            shipping_address_initials: "",
            shipping_address_first_name: "",
            shipping_address_last_name: "",
            shipping_address_surname_prefix: "",
            shipping_address_email: "",
            shipping_address_phone_number: "",
          };
          if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
        }
      }
      if (this.sameAsDeliveryAddress === false && this.saveAsNewAddress === false && (!this.selectedContactAddress?.id)) {

        let updatePayLoad = {
          trade_in_for_end_customer: false,

          contact_uuid: this.customerUuid,
          shipping_address_uuid: null,

          shipping_address_company_name: this.selectedContactAddress?.company_name || "",
          shipping_address_street: this.selectedContactAddress?.street || "",
          shipping_address_house_number: this.selectedContactAddress?.house_number || "",
          shipping_address_house_number_extension: this.selectedContactAddress?.house_number_extension || "",
          shipping_address_zip_code: this.selectedContactAddress?.zip_code || "",
          shipping_address_city: this.selectedContactAddress?.city || "",
          shipping_address_country: this.selectedCountry,
          shipping_address_initials: this.selectedContactAddress?.initials || "",
          shipping_address_first_name: this.selectedContactAddress?.first_name || "",
          shipping_address_last_name: this.selectedContactAddress?.last_name || "",
          shipping_address_surname_prefix: this.selectedContactAddress?.surname_prefix || "",

          shipping_address_email: "",
          shipping_address_phone_number: "",
        };
        if (!(await this.tradeInCartUpdate(updatePayLoad))) return;

      }
      if (this.sameAsDeliveryAddress === false && this.saveAsNewAddress === true && (!this.selectedContactAddress?.id)) {
        // create new address
        if (!(await this.createShippingAddress())) return;
        let updatePayLoad = {
          trade_in_for_end_customer: false,

          contact_uuid: this.customerUuid,
          shipping_address_uuid: this.selectedContactAddress?.uuid,

          shipping_address_company_name: "",
          shipping_address_street: "",
          shipping_address_house_number: "",
          shipping_address_house_number_extension: "",
          shipping_address_zip_code: "",
          shipping_address_city: "",
          shipping_address_country: "",
          shipping_address_initials: "",
          shipping_address_first_name: "",
          shipping_address_last_name: "",
          shipping_address_surname_prefix: "",
          shipping_address_email: "",
          shipping_address_phone_number: "",
        };
        if (!(await this.tradeInCartUpdate(updatePayLoad))) return;
      }


    }
    // this.router.navigate(['trade-in/cart', this.cart?.uuid]);

    if (this.tradeInBusinessPartner) {
      this.router.navigate(['trade-in/shipping-method']);
    } else {
      this.router.navigate(['trade-in/cart', this.cart?.uuid]);
    }

  }
  async updateShippingAddress() {
    this.isLoading = true;
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.errors = {};
    this.selectedContactAddress.address_type = 'shipping';
    return this.addCustomerService.updateNewAddress(this.selectedContactAddress, this.customerUuid, this.selectedContactAddress?.uuid).toPromise().then(async (response) => {
      this.createAddressLoader = false;
      if (response?.data) {
        this.contactAddress = this.contactAddress.map(u => {
          return u.id === response?.data?.id ? { ...this.selectedContactAddress, ...response?.data } : u;
        });
        this.selectedContactAddress = this.contactAddress?.find(item => item?.id === this.selectedContactAddress?.id) || null;
        this.selectedShippingId = this.selectedContactAddress?.id;
        return true;
      }
    }).catch(error => {
      const errors = this.errorHandleService.errorHandle(error.error);
      this.handleError(errors);
      this.createAddressLoader = false;
      this.isLoading = false;
      this.showErrorToaster(this.translocoService.translate('CUSTOMER.ADDRESS_UPDATE_FAILED'));
      return false;
    });
    /*
      .subscribe((response) => {
        if (response?.data) {
          this.contactAddress = this.contactAddress.map(u => {
            return u.id === response?.data?.id ? { ...this.selectedContactAddress, ...response?.data } : u;
          });
          this.showToaster(`${this.translocoService.translate('TRADE_CUSTOMER.CONTACT_ADDRESS_UPDATED_SUCCESSFULLY')}`);

          timer(500).subscribe(time => {
            this.selectedContactAddress = this.contactAddress?.find(item => item?.id === this.selectedContactAddress?.id) || null;
            this.selectedShippingId = this.selectedContactAddress?.id;
            this.updateTradeInCart();
          });
        }
        this.createAddressLoader = false;
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.createAddressLoader = false;
        }
      );
      */
  }
  async createShippingAddress() {
    this.isLoading = true;
    this.addressCombinationMsg = null;
    this.createAddressLoader = true;
    this.selectedContactAddress.address_type = 'shipping';
    this.errors = {};
    return this.addCustomerService.submitNewAddress(this.selectedContactAddress, this.customerUuid).toPromise().then(async (response) => {
      this.contactAddress = [response?.data, ...this.contactAddress];
      this.selectedContactAddress = this.contactAddress?.find(item => item?.id === response?.data?.id) || null;
      this.selectedShippingId = this.selectedContactAddress?.id;
      return true;

    }).catch(error => {
      const errors = this.errorHandleService.errorHandle(error.error);
      this.handleError(errors);
      this.createAddressLoader = false;
      this.isLoading = false;
      this.showErrorToaster(this.translocoService.translate('CUSTOMER.ADDRESS_CREATE_FAILED'));
      return false;
    });
    /*
      .subscribe((response) => {
        if (response?.data) {
          this.contactAddress.unshift(response?.data);
          timer(500).subscribe(time => {
            this.selectedContactAddress = this.contactAddress?.find(item => item?.id === response?.data?.id) || null;
            this.updateTradeInCart()
          });
        }
        this.createAddressLoader = false;
      },
        error => {
          const errors = this.errorHandleService.errorHandle(error.error);
          this.handleError(errors);
          this.createAddressLoader = false;
        }
      );
      */
  }
  async tradeInCartUpdate(payload) {
    this.isLoading = true;
    return this.tradeInService.tradeInCartUpdate(payload, this.cart?.uuid).toPromise().then(res => {
      this.cart = res?.data;
      this.saveTradeInCart(res?.data);
      return true;
    }).catch(error => {
      this.errorShowTradeInCart(error?.error?.message);
      this.isLoading = false;
      return false;
    });
    /*
    subscribe(res => {
      this.saveTradeInCart(res?.data);
      this.isLoading = false;
    }, error => {
      this.errorShowTradeInCart(error?.error?.message);
      this.isLoading = false;
    });
    */
  }
  async contactPersonCreate() {
    this.isLoading = true;
    this.createContactPersonLoader = true;
    this.contactPersonCreateError = null;
    return this.addCustomerService.createContactPerson(this.customerUuid, this.newContactPerson).toPromise().
      then(async (response) => {
        if (response?.data) {
          this.contact_persons = [response?.data, ...this.contact_persons];
          this.selectedContactPerson = this.contact_persons?.find(item => item?.uuid === response?.data?.uuid) || null;
          this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
          const updatePayLoad = {
            contact_uuid: this.customerUuid,
            contact_person_uuid: this.selectedContactPerson?.uuid
          }
          return await this.tradeInCartUpdate(updatePayLoad);
        }
        return false;
      }).catch((error) => {
        this.isLoading = false;
        this.contactPersonCreateError = error?.error?.error;
        this.showErrorToaster(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_CREATE_FAILED'));
        return false;
      });
    /*
    .subscribe((response) => {
        if (response?.data) {
          this.contact_persons.unshift(response?.data);
          timer(500).subscribe(time => {
            this.selectedContactPerson = this.contact_persons?.find(item => item?.uuid === response?.data?.uuid) || null;
            this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
            this.setCurrentFormControl();
            if (!this.cart?.contact_person_uuid) {
              this.updateTradeInCart()
            }
          });
        }
        this.createContactPersonLoader = false;
      },
        error => {
          this.createContactPersonLoader = false;
          this.showErrorToaster(`${this.translocoService.translate('CUSTOMER.CONTACT_PERSON_DELETE_FAILED')}`);
          this.contactPersonCreateError = error?.error?.error;
        }
      );
      */
  }
  async contactPersonUpdate() {
    this.isLoading = true;
    this.createContactPersonLoader = true;
    this.contactPersonCreateError = null;
    const updateData = {
      contact_person: {
        ...this.selectedContactPerson,
        first_name: this.selectedContactPerson?.first_name || this.selectedContactPerson?.initials || this.selectedContactPerson?.surname_prefix || 'anonymous'
      }
    };
    if (!updateData.contact_person.birthday) {
      delete updateData.contact_person.birthday;
    }
    if (!updateData.contact_person.bank_account_number) {
      delete updateData.contact_person.bank_account_number;
    }
    if (!updateData.contact_person.email) {
      delete updateData.contact_person.email;
    }
    if (!updateData.contact_person.mobile_phone_number) {
      delete updateData.contact_person.mobile_phone_number;
    }

    return this.customerService.updateContactPerson(this.customerUuid, this.selectedContactPerson.uuid, updateData).toPromise().
      then(async (response) => {
        if (response?.data) {
          this.selectedContactPersonUUID = this.selectedContactPerson?.uuid;
          const updatePayLoad = {
            contact_uuid: this.customerUuid,
            contact_person_uuid: this.selectedContactPerson?.uuid
          }
          return await this.tradeInCartUpdate(updatePayLoad);
        }
        return false;
      }).catch((error) => {
        this.isLoading = false;
        this.contactPersonCreateError = error?.error?.error;
        this.showErrorToaster(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_UPDATE_FAILED'));
        return false;
      });
  }
  onChangeSameAsDeliveryAddress() {
    if (this.sameAsDeliveryAddress) {
      this.saveAsNewAddress = false;
    }
  }
  validateEmail(emailInput = ''): boolean {

    // Regular expression for validating an email address
    // const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;


    // Check if the email matches the regex pattern
    if (emailRegex.test(emailInput)) {
      return true;
    } else {
      return false;
    }
  }
  onEmailChangeBusinessCustomerContactPerson() {
    if (!this.validateEmail(this.businessCustomerContactPerson?.email)) {
      this.businessCustomerContactPersonCreateError.email = true;
    } else {
      this.businessCustomerContactPersonCreateError.email = false;
    }
  }


  ngOnDestroy(): void {
    if (this.shipTimeOut) {
      clearTimeout(this.shipTimeOut);
    }
  }
}
