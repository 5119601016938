import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiBaseService } from 'src/app/shared/api-base.service';
import { EProductTypes } from 'src/app/shared/enums/product-type.enums';
import { StorageService } from 'src/app/shared/storage.service';

@Injectable({
  providedIn: 'root'
})

export class CartService {

  private cart$ = new BehaviorSubject(null);
  currentCart$ = this.cart$.asObservable();

  private cartURI = 'validate/cart';
  // private cartURI = 'carts';
  // private preCartURI = 'carts/precart/enrich';
  private preCartURI = 'validate/precart';
  public hideDeliveryOptionProductTypes = [
    EProductTypes.ADDITIONAL_COST,
    EProductTypes.VOUCHER
  ];

  constructor(
    private apiBaseService: ApiBaseService,
    private storageService: StorageService,
  ) { }

  preCartEnrich(product: any) {
    return this.apiBaseService.post(this.preCartURI, product);
  }

  createCart(cart: any) {
    return this.apiBaseService.post(this.cartURI, cart);
  }

  getCart(cartUuid: string) {
    const cartDetailsURI = `${this.cartURI}/${cartUuid}`;
    return this.apiBaseService.get(cartDetailsURI);
  }

  updateCart(cartUuid: string, data: any) {
    const cartDetailsURI = `${this.cartURI}/${cartUuid}`;
    return this.apiBaseService.post(`${cartDetailsURI}/lines`, data);
  }

  updateCartMultiple(cartUuid: string, data: any) {
    const cartDetailsURI = `${this.cartURI}/${cartUuid}`;
    return this.apiBaseService.put(`${cartDetailsURI}`, data);
  }

  deleteCartItem(cartUuid: string, cartItem: string) {
    const cartDeleteURI = `${this.cartURI}/${cartUuid}/lines/${cartItem}`;
    return this.apiBaseService.delete(`${cartDeleteURI}`);
  }

  updateCartLine(cartUuid: string, lineUuid: string, payload: any) {
    const cartDeleteURI = `${this.cartURI}/${cartUuid}/lines/${lineUuid}`;
    return this.apiBaseService.put(`${cartDeleteURI}`, payload);
  }

  deleteCart(uuid: string) {
    return this.apiBaseService.delete(`${this.cartURI}/${uuid}`);
  }

  changeCurrentCart(cart: any) {
    this.cart$.next(cart);
  }

  createQuote(payload: any) {
    const cartToQuoteURI = 'quotes/create';
    return this.apiBaseService.post(cartToQuoteURI, payload);
  }

  createOrderFromQuote(payload: any) {
    const url = 'orders/create';
    return this.apiBaseService.post(url, payload);
  }

  createOrder(payload: any): Observable<any> {
    return this.apiBaseService.post('orders/create', payload);
  }

  saveOrderUnitOfCartProducts(product: any, isDelete: boolean) {
    const products = this.storageService.getDynamicValue('cartProduct') || [];
    const index = products.findIndex(data => data.code === product.product_code);
    if (isDelete && (index > -1)) {
      products.splice(index, 1);
    } else if (index === -1) {
      products.push({
        code: product.product_code,
        order_unit: product.order_unit
      });
    }

    this.storageService.setDynamicValue('cartProduct', JSON.stringify(products));
  }

  getOrderUnitOfCartProducts(code: string) {
    const products = this.storageService.getDynamicValue('cartProduct') || [];
    return products.find(data => data.code === code)?.order_unit || null;
  }

  addVoucher(cartUuid: string, payload: any) {
    const URI = `${this.cartURI}/${cartUuid}/voucher`;
    return this.apiBaseService.post(URI, payload);
  }


  getVoucherProfileDeliveryAddress(cartUuid: string) {
    const URI = `${this.cartURI}/${cartUuid}/delivery-addresses`;
    return this.apiBaseService.get(URI);
  }

  getProjectList(contactUuid: string) {
    const URI = `project-prices/contact/${contactUuid}`;
    return this.apiBaseService.get(URI);
  }

  addProjectPriceToCart(cartUuid, payload) {
    const URI = `${this.cartURI}/${cartUuid}`;
    return this.apiBaseService.put(URI, payload);
  }

  getDeliveryOptions() {
    const URI = `${this.cartURI}/delivery-options`;
    return this.apiBaseService.get(URI);
  }

  setDefaultDeliveryToCart(cartUuid, payload) {
    const URI = `${this.cartURI}/${cartUuid}/default-delivery`;
    return this.apiBaseService.put(URI, payload);
  }

  setDeliveryToCartLine(cartUuid, lineUuid, payload) {
    const URI = `${this.cartURI}/${cartUuid}/lines/${lineUuid}`;
    return this.apiBaseService.put(URI, payload);
  }

  getPaymentTypes(cartUuid) {
    const URI = `${this.cartURI}/${cartUuid}/payment-types`;
    return this.apiBaseService.get(URI);
  }

  updatePaymentType(cartUuid: string, payload: any) {
    const URI = `${this.cartURI}/${cartUuid}/payment-types`;
    return this.apiBaseService.put(URI, payload);
  }

  getCartTermAndCondition(cartUuid: string) {
    const URI = `${this.cartURI}/${cartUuid}/terms-and-conditions`;
    return this.apiBaseService.get(URI);
  }

  addCartTermAndCondition(cartUuid: string, payload: {
    code: string,
    is_accepted: boolean
  }) {
    const URI = `${this.cartURI}/${cartUuid}/terms-and-conditions/update`;
    return this.apiBaseService.patch(URI, payload);
  }

  getAccountType(cartUuid, lineUuid) {
    const URI = `carts/${cartUuid}/lines/${lineUuid}/account-types`;
    return this.apiBaseService.get(URI, null, 'v2').pipe(
      map(item => {
        return { ...item, line_uuid: lineUuid };
      })
    );
  }

  updateCartWithInfo(cartUuid, payload) {
    const URI = `${this.cartURI}/${cartUuid}`;
    return this.apiBaseService.put(URI, payload);
  }
  getCartSummary(cartUuid: string) {
    const URI = `${this.cartURI}/${cartUuid}/summary`;
    return this.apiBaseService.get(URI);
  }
}
