import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

import { Observable, timer } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { CustomersService } from './customers.service';
import { StorageService } from 'src/app/shared/storage.service';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { EAddressTypes } from 'src/app/shared/enums/flow-type.enums';
import { TradeInService } from '../trade-in/trade-in.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.scss']
})
export class CustomersComponent implements OnInit, AfterViewInit {

  public searchForm: UntypedFormGroup;
  customers: [];
  isLoading = false;

  searchCompanyList: [];
  selectedCompanyName = '';
  showDropDownItemList = false;
  keyword = 'company_name';
  public selectedCustomer = null;
  public activeStep = 'customer_select';
  public cart = null;
  public cocNumber = '';
  public user = null;
  public customer_uuid = null;
  public zipCodePattern = null;
  public isNewQuote = false;
  public isImported = false;
  public isTradeIn = false;


  @ViewChild('customerSearch') customerSearch;
  constructor(
    private formBuilder: UntypedFormBuilder,
    private customersService: CustomersService,
    private storageService: StorageService,
    private router: Router,
    public activateRoute: ActivatedRoute,
    private toastrService: ToastrService,
    private utilService: UtilityService,
    private tradeInService: TradeInService,
    public translocoService: TranslocoService,

  ) {
    this.zipCodePattern = this.utilService.zipCodePattern();
    this.cart = this.storageService.getCart() || null;
    this.user = this.storageService.getCurrentUser() || null;

    this.activateRoute.queryParams.subscribe(params => {
      this.selectedCompanyName = params?.company_name || '';
      this.cocNumber = params?.coc_number || '';
      this.customer_uuid = params?.customer_uuid || null;
      this.isNewQuote = params?.newQuote || false;
      this.isImported = params?.importedQuote || false;
      this.isTradeIn = params?.is_trade_in || false;

      if (this.selectedCompanyName) {
        this.searchCompanies(this.selectedCompanyName);
      }
    });

  }

  ngOnInit() {
    this.initializeSearchForm();
    // this.getCustomers();
  }

  ngAfterViewInit(): void {
    if (this.user?.contact_group?.is_external) {
      this.router.navigate([`/customer/${this.user?.contact?.uuid}`]);
    }
  }

  initializeSearchForm() {
    this.searchForm = this.formBuilder.group({
      zipcode: [''],
      house_number: [''],
      house_number_ext: [''],
      coc_no: [this.cocNumber || ''],
      krn_no: [''],
      is_business: [true]
    });

    if (this.cocNumber) {
      this.searchByParams();
    }
  }

  prepareParamString() {

    const formValue = this.searchForm.value;
    let params: any;

    if (formValue.krn_no) {
      params = formValue.krn_no;
    } else if (formValue.coc_no) {
      params = formValue.coc_no;
    } else {
      let paramsString = '';

      if (formValue.zipcode) {
        paramsString += `zip_code=${formValue.zipcode}`;
      }
      if (formValue.zipcode && formValue.house_number) {
        paramsString += `&house_number=${formValue.house_number}`;
      }
      if (formValue.zipcode && formValue.house_number && formValue.house_number_ext) {
        paramsString += `&house_number_ext=${formValue.house_number_ext}`;
      }

      params = new HttpParams({
        fromString: paramsString
      });
    }

    return params;
  }

  searchType() {
    let searchType = '';
    const formValue = this.searchForm.value;

    if (formValue.krn_no) {
      searchType = 'krn';
    } else if (formValue.coc_no) {
      searchType = 'coc';
    } else {
      searchType = 'address';
    }

    return searchType;
  }


  onSearchCustomer() {
    this.isLoading = true;
    this.customersService.getCustomers(this.prepareParamString(), this.searchType())
      .subscribe(res => {
        this.customers = res.data.results?.map(item => {
          return {
            ...item,
            firstContactAddress: item?.contact_addresses?.find(address => {
              return address?.address_type === EAddressTypes.CONTACT;
            })
          };
        });
        if (this.customer_uuid) {
          this.selectedCustomer = this.customers?.find((item: any) => item?.uuid === this.customer_uuid);
        }
        this.isLoading = false;
      }, err => {
        this.isLoading = false;
      });
  }

  searchByParams() {
    this.onSearchCustomer();
    // this.selectedCompanyName = '';
  }


  onSelectCustomer(customer: any) {
    this.selectedCustomer = customer;
    this.storageService.setCustomer(customer);
  }

  setCustomerToTradeInCart(customer) {
    const tradeInCart = this.storageService.getReComCart() || null;
    if (this.isTradeIn && tradeInCart) {
      let payload = {
        contact_uuid: customer?.uuid
      }
      this.tradeInService.tradeInCartUpdate(payload, tradeInCart?.uuid).subscribe(res => {
        this.storageService.setReComCart(res?.data);
      })
    }
  }

  onCustomerSelect(customer: any) {
    this.isLoading = true;
    this.selectedCustomer = customer;

    if (customer?.uuid) {
      this.isLoading = false;
      this.setCustomerToTradeInCart(customer);
      this.storageService.setCustomer(customer);
      this.customersService.goToCustomerDetails(customer?.uuid, this.isNewQuote, this.isImported, this.isTradeIn);
    } else if (customer.external_id1) {
      this.customersService.getCustomerUuid(customer.external_id1).subscribe(params => {
        this.isLoading = false;
        if (params.data?.results?.length) {
          this.setCustomerToTradeInCart(customer);
          this.storageService.setCustomer(customer);
          this.customersService.goToCustomerDetails(params.data.results[0]?.uuid, this.isNewQuote, this.isImported, this.isTradeIn);
        } else {
          this.showInfoToaster(this.translocoService.translate('CUSTOMERS.EMPTY_CUSTOMER_DATA'));
        }
      });
    }
  }

  showInfoToaster(msg) {
    this.toastrService.info(`${msg}`, 'Info', {
      timeOut: 2000
    });
  }

  onSelectedCompany(company: object | any) {
    this.showDropDownItemList = false;
    this.searchForm = this.formBuilder.group({
      zipcode: [''],
      house_number: [''],
      house_number_ext: [''],
      coc_no: [company?.coc_number],
      krn_no: [''],
      is_business: [true]
    });
    this.onSearchCustomer();
  }

  searchCompanies(searchString: string) {
    if (searchString.length >= 3) {
      const companyName = searchString;
      this.selectedCompanyName = companyName;
      this.customersService.getCompanies(companyName).subscribe(res => {
        this.showDropDownItemList = true;
        if (res?.data?.results) {
          this.searchCompanyList = res.data.results?.map(item => {
            return {
              ...item,
              company_name: `${item?.company_name} (${item?.coc_number})`
            };
          });

          const find_src = this.searchCompanyList.some((item: any) => {
            return item?.company_name?.toLowerCase()?.includes(companyName?.toLowerCase());
          });
          if (this.searchCompanyList?.length && !find_src) {
            this.customerSearch.clear();
            timer(50).subscribe(time => {
              this.selectedCompanyName = companyName;
            });
          }
        }
      }, error => { });
    }
  }

  onFocused(e) {
    // do something when input is focused
  }

  searchCleared() {
    this.showDropDownItemList = false;
  }

  backToPrevious() {
    const cart = this.storageService.getCart() || null;
    if (cart) {
      this.router.navigate([`/cart/${cart?.uuid}`]);
    } else {
      this.router.navigate([`/home`]);
    }
  }

}
