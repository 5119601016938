<div class="auth-wrapper">
  <div class="auth-gradient">
    <div class="auth-box">
      <div class="auth-logo text-center">
        <img [src]="logoSrc" alt="">
      </div>

      <div class="auth-form-box" *ngIf="!isInAccountLoginScreen">
        <h2 class="title-2 green-text center-align mb-3">{{'LOGIN.LOGIN' | transloco}} {{siteProfile?.title}}
        </h2>
        <div *ngIf="gripLogin" class="grip-logo text-center">
          <img src="/assets/images/grip.png" alt="">
        </div>
        <button *ngIf="gripLogin" class="btn btn-primary btn-block rounded-btn" (click)="loginWithGrip()">{{'LOGIN.LOG_IN_WITH_GRIP' | transloco}} </button>
        <p *ngIf="msLogin && gripLogin" class="text-center pt-4 sub-title-light-5 grey-text">{{'LOGIN.OR' | transloco}}</p>
        <button *ngIf="msLogin" class="btn btn-outline-primary btn-block d-flex justify-content-center" (click)="loginWithMicrosoft()">
            <span>
              <img class="mr-2"  style="width: 20px;height: auto;" src="../../../assets/images/microsoft_logo.png" alt="">
              <span>Login with Microsoft</span>
            </span>
         </button>
        <p *ngIf="accountLogin &&(msLogin || gripLogin)" class="text-center pt-4 sub-title-light-5 grey-text">{{'LOGIN.OR' | transloco}}</p>
        <button *ngIf="accountLogin" class="btn btn-outline-primary btn-block rounded-btn" (click)="switchToAccountLogin()">
          <span>{{'LOGIN.LOGIN_WITH_USERNAME' | transloco}}</span>
        </button>
      </div>

      <div class="auth-form-box" *ngIf="isInAccountLoginScreen && !showForgotPassword">
        <form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
          <h2 class="title-2 green-text">{{'LOGIN.LOGIN' | transloco}} {{siteProfile?.title}}</h2>
          <p class="sub-title-light-4 green-text">{{'LOGIN.ENTER_USERNAME_PASSWORD' | transloco}}.</p>
          <div class="row align-items-center">
            <div class="col-md-12 col-12 col-gap-reduce">
              <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                <p>
                  <span class="kphicon-uniE952"></span>
                  <small> {{'LOGIN.NOTE_USERNAME_AND_PASS_RESET' | transloco}}.</small>
                </p>
              </div>
            </div>
            <div class="col-md-12 col-12 col-gap-reduce" *ngIf="isInvited">
              <div class="info-bar-yellow mb-1 d-flex align-items-center justify-content-between">
                <p>
                  <span class="kphicon-uniE952"></span>
                  <small> {{'LOGIN.HAVE_ACCOUNT_FOR_VOUCHER_SHOP' | transloco}}.</small>
                </p>
              </div>
            </div>
          </div>
          <div class="form-group kpn-form-group">
            <input type="text" formControlName="username" class="form-control kpn-form-control"
              [placeholder]="'LOGIN.USER_NAME'| transloco" />
            <div class="invalid-feedback">{{'LOGIN.ENTER_YOUR_USERNAME' | transloco}}</div>
          </div>
          <div class="form-group kpn-form-group">
            <input type="password" formControlName="password" class="form-control kpn-form-control"
              [placeholder]="'LOGIN.PASSWORD' | transloco" />
            <div class="invalid-feedback">{{'LOGIN.ENTER_YOUR_PASSWORD' | transloco}}</div>
          </div>
          <p *ngIf="loginErrorMessage" class="login-error">
            {{loginErrorMessage}}
          </p>
          <p>
            <a href="javascript:void(0)" (click)="forgotPassword()" class="blue-link">{{'LOGIN.FORGOT_YOUR_PASSWORD' | transloco}}?</a>
          </p>
          <button type="submit" disabled class="btn btn-primary btn-block rounded-btn"
            [disabled]="!loginForm.get('username').value || !loginForm.get('password').value">
            <span>{{'LOGIN.LOGIN' | transloco}}</span>
          </button>
        </form>
        <p *ngIf="gripLogin" class="text-center pt-4 sub-title-light-5 grey-text">{{'LOGIN.OR' | transloco}}</p>
        <button *ngIf="gripLogin" class="btn btn-outline-primary btn-block rounded-btn" (click)="loginWithGrip()">
          {{'LOGIN.LOG_IN_WITH_GRIP' | transloco}}
        </button>
        <p *ngIf="msLogin " class="text-center pt-4 sub-title-light-5 grey-text">{{'LOGIN.OR' | transloco}}</p>
        <button *ngIf="msLogin" class="btn btn-outline-primary btn-block d-flex justify-content-center" (click)="loginWithMicrosoft()">
            <span>
              <img class="mr-2"  style="width: 20px;height: auto;" src="../../../assets/images/microsoft_logo.png" alt="">
              <span>Login with Microsoft</span>
            </span>
         </button>
      </div>
      <div class="auth-form-box auth-form-box-forgot" *ngIf="showForgotPassword">
        <div *ngIf="!hasResetPasswordRequestSent">
          <h2 class="title-2 green-text" i18n="@@arm.login.forgot-password">{{'LOGIN.FORGOT_YOUR_PASSWORD' | transloco}}</h2>
          <p class="sub-title-light-4 green-text" i18n="@@arm.login.forgot-password-hints">{{'LOGIN.SEND_EMAIL_TO_CREATE_A_NEW_PASS' | transloco}}.</p>
          <div class="form-group kpn-form-group">
            <label i18n="@@arm.login.email-address">{{'LOGIN.USER_NAME' | transloco}}</label>
            <input type="text" [(ngModel)]="username" (ngModelChange)="checkUserName()"
              class="form-control kpn-form-control" [placeholder]="'LOGIN.USER_NAME' | transloco">
            <div class="invalid-feedback" i18n="@@arm.login.forgot-password-invalid-msg">
              {{'LOGIN.ENTER_A_CORRECT_USERNAME' | transloco}}.
            </div>
          </div>
          <div class="pt-2 text-center">
            <button class="btn flat-silver-bordered rounded-btn px-5 m-1" aria-label="Close"
              (click)="closeForgotPassword()">
              {{'LOGIN.CANCEL' | transloco}}
            </button>
            <button (click)="onSubmitForgotPassword()" [disabled]="!username"
              class="btn btn-primary rounded-btn px-5 m-1" i18n="@@arm.login.submit">{{'LOGIN.SEND' | transloco}}</button>
            <p *ngIf="passResetError" class="green-text mb-0">
              {{passResetError}}</p>
          </div>
        </div>
        <div *ngIf="hasResetPasswordRequestSent">
          <p class="sub-title-light-4 green-text text-center">{{passResetEmailMsg}}</p>
          <div class="pt-2 text-center">
            <button class="btn flat-silver-bordered rounded-btn px-5 m-1" aria-label="Close"
              (click)="closeForgotPassword()">
              {{'LOGIN.CANCEL' | transloco}}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- <ng-template #forgotPasswordModal let-modal>
    <div class="modal-body app-modal-body">
      <div class="auth-form-box auth-form-box-forgot">
        <div *ngIf="!hasResetPasswordRequestSent">
          <h2 class="title-2 green-text" i18n="@@arm.login.forgot-password">Wachtwoord vergeten</h2>
          <p class="sub-title-light-4 green-text" i18n="@@arm.login.forgot-password-hints">Voer hieronder uw
            gebruikersnaam in. We sturen dan binnen enkele minuten een e-mail naar het bijbehorende e-mailadres van de
            gebruikersnaam waarmee een nieuw wachtwoord kan worden aangemaakt.</p>
          <div class="form-group kpn-form-group">
            <label i18n="@@arm.login.email-address">Username</label>
            <input type="text" [(ngModel)]="username" (ngModelChange)="checkUserName()"
              class="form-control kpn-form-control" placeholder="username">
            <div class="invalid-feedback" i18n="@@arm.login.forgot-password-invalid-msg">
              Geef een correct username.
            </div>
          </div>
          <div class="pt-2 text-center">
            <button class="btn flat-silver-bordered rounded-btn px-5 m-1" aria-label="Close" (click)="modal.close()">
              Cancel
            </button>
            <button (click)="onSubmitForgotPassword(forgotPasswordModal)"  [disabled]="!username"
              class="btn btn-primary rounded-btn px-5 m-1" i18n="@@arm.login.submit">Verzenden</button>
              <p *ngIf="passResetError" class="danger-text mb-0">
                {{passResetError}}</p>
          </div>
        </div>
        <div *ngIf="hasResetPasswordRequestSent">
          <p class="sub-title-light-4 green-text text-center">{{passResetEmailMsg}}</p>
          <div class="pt-2 text-center">
            <button class="btn flat-silver-bordered rounded-btn px-5 m-1" aria-label="Close" (click)="modal.close()">
              Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
</ng-template> -->
