import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SignUpService } from '../sign-up/sign-up.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-user-activation',
  templateUrl: './user-activation.component.html',
  styleUrls: ['./user-activation.component.scss']
})
export class UserActivationComponent implements OnInit {

  private token = '';
  private uid = ''
  public activationSuccessMsg = ''
  public activationErrorMsg = '';
  public showReSend = false;
  public invitationResendMsg = '';

  constructor(
    private router: Router,
    private activateRouter: ActivatedRoute,
    private signUpService: SignUpService,
    private toaster: ToastrService

  ) {
    this.activateRouter.queryParams.subscribe({
      next: (params: any) => {
        this.token = params?.token || '';
        this.uid = params?.uid || '';
        if (!this.token || !this.uid) {
          this.backToLogin();
        } else {
          this.activateUser();
        }
      }
    });
  }

  backToLogin() {
    this.router.navigate(['/login'],
      {
        queryParams: {
        }, queryParamsHandling: 'merge'
      }
    );
  }

  activateUser() {
    this.activationErrorMsg = '';
    this.activationSuccessMsg = '';
    this.invitationResendMsg = '';
    this.signUpService.activateUser(this.uid, this.token).subscribe(response => {
      this.activationSuccessMsg = `${response?.data?.message}, ga naar de <a href=\"/login?invited=true\">login</a> pagina en meld u aan`;
    }, error => {
      this.activationErrorMsg = error?.error?.message || error?.error?.error?.error || error?.error?.error || error?.error || 'activation failed';
      if (['CONTACT_USER_ACTIVATION_TOKEN_EXPIRED']?.includes(error?.error?.error?.error || error?.error?.error || error?.error)) {
        this.showReSend = true;
      }
    });
  }

  reSendInvitation() {
    this.invitationResendMsg = '';
    this.signUpService.reSendActivation(this.uid, this.token).subscribe(res => {
      this.showReSend = false;
      this.activationErrorMsg = '';
      this.invitationResendMsg = 'De uitnodigingslink is naar uw e-mailadres verzonden. Controleer uw inbox';
      this.toaster.success(this.invitationResendMsg);
    }, error => {
      this.toaster.error(error?.error?.error?.message || `Uitnodiging opnieuw verzenden mislukt`, error?.error?.error?.code);
    })
  }

  ngOnInit(): void {
  }

}
