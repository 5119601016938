import * as ProductGroupActions from '../actions/productGroup.actions';

export function childGroupReducer(state: any, action: ProductGroupActions.ChildGroupActionsList) {
    switch (action.type) {
        case ProductGroupActions.EProductActions.CHILD_GROUP_LOAD_SUCCESS:
            return action.childGroup;
        default:
            return state;
    }
}

export function selectedChildGroupReducer(state: any, action: ProductGroupActions.ChildGroupActionsList) {
    switch (action.type) {
        case ProductGroupActions.EProductActions.SELECT_A_CHILD_GROUP:
            return action.childGroup;
        case ProductGroupActions.EProductActions.CLEAR_A_CHILD_GROUP:
            return action.childGroup;
        default:
            return state;
    }
}
