import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ForecastService {

  constructor(
    private apiBaseService: ApiBaseService
  ) { }


  getContactForecastList(contact_uuid: string, page = 1) {
    const paramsString = `page=${page}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(`sales-forecasts/contact/${contact_uuid}`, params);
  }

  getForecastHeader(contact_uuid: string, catalog_code: string, date) {
    const paramsString = `prev_date=${date.prev_date}&next_date=${date.next_date}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(`sales-forecasts/product/planning/contact/${contact_uuid}/catalog/${catalog_code}`, params);
  }

  getForecastProduct(contact_uuid: string, catalog_code: string, page = 1, date) {
    const paramsString = `page=${page}&prev_date=${date.prev_date}&next_date=${date.next_date}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(`sales-forecasts/product/contact/${contact_uuid}/catalog/${catalog_code}`, params);
  }

  addSingleForecastProduct(payload) {
    const { contact_uuid, catalog_code, ...rest } = payload;
    return this.apiBaseService.post(`sales-forecasts/product/contact/${contact_uuid}/catalog/${catalog_code}`, rest);
  }


}
