import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {



  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  getPaymentMethods(orderUuid: string) {
    const url = `orders/${orderUuid}/available-payment-methods`;
    return this.apiBaseService.get(url);
  }

  initiatePayment(payload) {
    const url = `payments/initiate-payment`;
    return this.apiBaseService.post(url, payload);
  }

  checkPaymentStatus(payload) {
    const url = `payments/status`;
    return this.apiBaseService.post(url, payload);

  }

  checkDepotEligibility(contact_uuid: string) {
    const url = `contacts/${contact_uuid}/depot/current-value`;
    return this.apiBaseService.get(url);
  }
}
