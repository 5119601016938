<div class="pr-3">
    <ng-autocomplete class="" [searchKeyword]="keyword" [data]="searchProductList" placeholder=''
        [(ngModel)]='selectedProduct' (selected)='onSelectProduct($event, index)' (inputChanged)='searchProduct($event)'
        [itemTemplate]="itemTemplate" [debounceTime]="600" [notFoundTemplate]="notFoundTemplate"
        (inputCleared)="clearSearch()">
    </ng-autocomplete>

    <ng-template #itemTemplate let-item>
        <a [innerHTML]="item.name"></a>
    </ng-template>

    <ng-template #notFoundTemplate let-notFound>
        <div>{{searchingText}}</div>
    </ng-template>
</div>