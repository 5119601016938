<app-loader [isLoading]="isLoading"></app-loader>
<section class="pb-4 bg-white">
  <div class="container">

<!-- Breadcrumb -->
 <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-3" >
  <li class="back-breadcrumb-btn">
    <a class="cursor-pointer" [routerLink]="['/quotes']" >
      <i class="kpnew-icon-arrow-left"></i> Terug
    </a>
  </li>
  <li class="home-btn ml-2 px-1 pb-1">
    <a routerLink="/">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="UI Icon">
          <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
            d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
            fill="#939393" />
        </g>
      </svg>
    </a>
  </li>
  <li  role="button"> <a class="grey-text" >Offerte overzicht</a></li>
  <li> <a> Offerte #{{ quote?.reference }}</a></li>
</ul>
<div class="pt-2" *ngIf="eQuoteStatus.APPROVED === quote?.status">
  <app-customer-quote-steps [showConfigTab]="quote?.is_contact_account_type_required || hasCpaas" [activeStep]="1" [completedSteps]="[]" />
</div>
<h3 class="font-kpn-black green-text fs-40 mb-3 pt-3">{{ quote?.status === eQuoteStatus.APPROVED ? 'Controleer de offerte' : 'Offerte #'+(quote ? quote?.reference :'')  }}</h3>

<div class="row mb-4">
  <div class="col-md" [ngClass]="{'pr-md-0' : (quote?.validity_in_days && quote?.status !== eQuoteStatus.PROCESSED)}">
    <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24">
      <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">Offertegegevens</h4>
      <div class="d-flex mb-2 offer-detial-item align-items-center">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Type offerte</span>
        <span class="font-kpn-extended">Offerte</span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center" *ngIf="quote?.status == eQuoteStatus.APPROVED">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Referentie</span>
        <span class="font-kpn-extended">#{{ quote?.reference }}</span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Status</span>
        <span class="font-kpn-extended">
          <span class="d-flex font-kpn-extended fs-14 align-items-center">
            <i class="fs-18  mr-1" [ngClass]="getQuoteStatusIcon(quote?.status)"></i>
            {{ getModifiedStatusName(quote?.status) }}
          </span>
        </span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Aanmaakdatum</span>
        <span class="font-kpn-extended">{{ quote?.created_at | date: 'dd-MM-yyyy' }}</span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center" *ngIf="quote?.expire_date">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Verloopt op</span>
        <span class="font-kpn-extended">{{ quote?.expire_date | date: 'dd-MM-yyyy' }}</span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Aangemaakt door</span>
        <span class="font-kpn-extended">{{ quote?.user }}</span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center" *ngIf="quote?.po_number">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">PO Nummer</span>
        <span class="font-kpn-extended">{{ quote?.po_number }}</span>
      </div>
      <div class="d-flex mb-2 offer-detial-item align-items-center" *ngIf="quote?.customer_reference">
        <span class="font-kpn-extended kpn-extended-semibold item-label letter-spacing-normal">Referentie</span>
        <span class="font-kpn-extended">{{ quote?.customer_reference }}</span>
      </div>

      <!-- <p class="mb-2 pt-2">
        <a href="javascript:void(0)" class="fs-16 font-kpn-extended link-btn-blue">
          Bekijk mijn bestelling <i class="kphicon-uniE907 fs-18 align-middle"></i></a>
      </p> -->
    </div>
  </div>
  <div class="col-md-auto" *ngIf="quote?.validity_in_days && quote?.status !== eQuoteStatus.PROCESSED">
    <div class="tri-shadow-xl text-center h-100 quote-hourglass quote-days-left tri-rounded-20 bg-white py-3 c-px-24 mb-3">
      <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pb-2 pt-1">Verloopt over</h4>
      <div>
        <span class="expiry-day-outline font-kpn-extended kpn-font-color d-inline-flex align-items-center mb-4"><i class="kpnew-icon-clock-light grey-text fs-24 mr-1"></i> {{ quote?.validity_in_days }} dagen </span>
      </div>
      <img class="hourglass-img mb-3" src="../../../assets/images/sti-hourglass.png" alt="">
    </div>
  </div>
</div>

<!-- <app-address-and-contact /> -->
<div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 c-px-24 mb-4" *ngIf=" quote !== null && (!hasCpaas && !isCpassDataAdded) && (quote?.status != eQuoteStatus.APPROVED &&  quote?.status != eQuoteStatus.NEW &&  quote?.status != eQuoteStatus.EXPIRED &&  quote?.status != eQuoteStatus.REJECTED &&  quote?.status != eQuoteStatus.CANCELLED)">
  <h4
    class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">
    Levering & contactpersoon </h4>

  <div class="row gutter-row-24" >
    <div class="col-md-6 mb-4 gutter-col-24">
      <app-customer-quote-address/>
    </div>
    <div class="col-md-6 mb-4 gutter-col-24">
      <app-customer-quote-contact-person/>

    </div>
  </div>
</div>
<div class="row gutter-row-24" *ngIf="quote !== null && hasCpaas && isCpassDataAdded &&  (quote?.status != eQuoteStatus.APPROVED &&  quote?.status != eQuoteStatus.NEW &&  quote?.status != eQuoteStatus.EXPIRED &&  quote?.status != eQuoteStatus.REJECTED &&  quote?.status != eQuoteStatus.CANCELLED)">
  <div class="col-md pb-4 gutter-col-24">
    <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 pb-4  c-px-24 h-100" >
      <h4
        class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">
        Levering & contactpersoon </h4>

      <div class="row gutter-row-24" >
        <div class="col-md-6  gutter-col-24">
          <app-customer-quote-address/>
        </div>
        <div class="col-md-6  gutter-col-24">
          <app-customer-quote-contact-person/>
        </div>
      </div>
    </div>
  </div>
  <div class="col-auto pb-4 gutter-col-24">
    <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 pb-4  h-100" *ngIf=" quote !== null ">
      <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1 c-px-24">
        CPaaS Hoofdgebruiker </h4>
        <div class="">
          <app-customer-quote-cpass [showEditButtonInBottom]="false" [applyMinHight]="true"  (updateCpassDataEventEmitter)="updateCpassDataFromChild($event)" />
        </div>
    </div>
  </div>
</div>
<div *ngIf="quote?.is_contact_account_type_required && quote?.contact_account_types?.length && (hasViewContactAccountTypeCustomer ? (contactAccountTypesCustomers?.length) : true) && ((quote?.status != eQuoteStatus.APPROVED &&  quote?.status != eQuoteStatus.NEW &&  quote?.status != eQuoteStatus.EXPIRED &&  quote?.status != eQuoteStatus.REJECTED &&  quote?.status != eQuoteStatus.CANCELLED))">
  <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 mb-4" >
    <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1 c-px-24">
      Enrollment
    </h4>
    <!-- <p class="font-kpn-extended c-px-24">Enrollment koppelt je apparaten aan je onderneming, waardoor registratie en beheer eenvoudiger worden. </p> -->
    <div>
      <app-customer-quote-enrollment [colSizeFour]="true" [sowAddRemoveEnrollmentBtn]="false" [onlyShowEnrolledItem]="true" [showEditBtn]="false" />
    </div>
  </div>
</div>
<app-quote-details-summary (openRejectModal)="rejectOffer()"  [showRejectButton]="true" [showPlaceOrderButton]="true" [showHeader]="true" ></app-quote-details-summary>


<ng-template #rejectOfferModal let-modal>
  <div class="p-x-24">
    <div class="modal-header tri-modal-header px-0">
        <h6 class="modal-title green-text font-kpn-black fs-40" id="quoteListFilter">
          Offerte afwijzen
        </h6>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click')" aria-label="Close">
            <span class="kphicon-uniE926"></span>
        </button>
    </div>
  </div>

  <div class="modal-body p-24">
      <div class="row align-items-center px-0 pb-2">
          <div class="col-md-12">
              <div class="p-0">
                <p class="font-kpn-extended mb-4">Laat ons weten waarom je de offerte hebt afgewezen, zodat we onze service kunnen verbeteren. </p>
                  <div>
                      <div class="mb-3">
                        <h3 class="font-kpn-extended kpn-extended-bold fs-18 mb-1 pb-1 letter-spacing-normal">Wat is de reden?</h3>
                        <div class="custom-select-option">
                          <select [(ngModel)]="selectedQuoteCancelReason" class="form-control tri-form-control" name="" id="">
                            <option value="null" selected disabled>Selecteer een optie</option>
                            <option *ngFor="let item of quoteCancelReasons" [value]="item?.code">{{ item?.title }}</option>
                          </select>
                        </div>
                      </div>

                      <div class="mb-3" *ngIf="selectedQuoteCancelReason">
                        <h3 class="mb-1 pb-1">
                          <span class="font-kpn-extended kpn-extended-bold fs-18 letter-spacing-normal">Toelichting</span>
                          <span class="font-kpn-extended fs-18 letter-spacing-normal"> (optioneel)</span></h3>
                        <textarea [(ngModel)]="quoteCancelReasonText" class="form-control h-auto tri-form-control" placeholder="Vul hier je toelichting in..." rows="3" name="" id=""></textarea>
                      </div>

                      <div class="pt-2">
                          <button (click)="cancelQuote()"
                              class="btn px-4 btn-primary mt-2 rounded-btn" [disabled]="!selectedQuoteCancelReason">
                              <span class="d-inline-block px-2">Bevestigen</span>
                          </button>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</ng-template>



<p class="mt-3 pt-2">
  <a [routerLink]="['/quotes']"  class="fs-16 pl-0 font-kpn-extended link-btn-blue">
    <i class="kpnew-icon-arrow-lingt-2 fs-18 align-middle"></i> Terug naar offerte overzicht</a>
</p>
  </div>
</section>

