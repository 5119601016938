export enum EMobileFlowTypes {
    NEW = 'new',
    RENEWAL = 'renewal',
    NEW_MOBILE = 'new_mobile',
    RENEW = 'renew'
}

export enum EAddressTypes {
    CONTACT = 'contact',
    BILLING = 'billing',
    SHIPPING = 'shipping'
}

export enum ERmaFlowTypes {
    DOA = 'dead_on_arrival',
    RMA_WITHOUT_ORIGINAL_ORDER = 'rma_without_original_order',
}

export const ExportId = ['bert'];
