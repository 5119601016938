<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
      <div class="container" *ngIf="!tradeInOrder && error">
          <div class="col-md-12 col-12 col-gap-reduce">
              <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                      <span class="kphicon-uniE952"></span>
                      <small> {{error}}</small>
                  </p>
              </div>
          </div>
          <button class="btn btn-outline-primary" (click)="back()">{{'QUOTES.BACK' | transloco}}</button>
      </div>
      <div class="container" *ngIf="tradeInOrder">
          <div class="new-cart-box-overview pt-2 mb-1">
              <div class="row pt-4">
                  <div class="col">
                      <h3 class="title-2 mb-1 green-text">{{'TRADE_ORDER_DETAILS.DETAILS_OF_YOUR_REQUEST' | transloco}}</h3>
                      <h5 class="title-4 pt-3">{{'ORDER_OVERVIEW.THESE_ARE_YOUR_DETAILS' | transloco }}</h5>
                      <div class="row">
                          <div class="col-md-4">
                              <div class="pt-3">
                                  <h5 class="title-5 mb-1 dark-grey-text">{{'CUSTOMER.COMPANY_NAME' | transloco }}</h5>
                                  <p class="body-text-1 mb-3">
                                      {{ contact?.company_name }}
                                  </p>

                                  <h5 *ngIf="contact?.coc_number" class="title-5 mb-1 dark-grey-text">
                                      KVK {{'CUSTOMER.NUMBER' | transloco }}
                                  </h5>
                                  <p class="body-text-1 mb-3">{{ contact?.coc_number }}</p>
                              </div>
                              <div class="pt-0" *ngIf="contactPerson">
                                  <h5 class="title-5 mb-1 dark-grey-text">{{'ORDER_OVERVIEW.CONTACT_PERSON' | transloco }}</h5>
                                  <p class="body-text-1 m-0">
                                      <span class="title-6 dark-grey-text text-capitalize">{{'ORDER_CONFIRMATION.NAME' | transloco }}: </span>
                                      {{ contactPerson?.initials }}
                                      {{ contactPerson?.last_name }}
                                  </p>

                                  <p class="body-text-1 m-0" *ngIf="contactPerson?.mobile_phone_number">
                                      <span class="title-6 dark-grey-text">{{'ORDER_CONFIRMATION.TELEPHONE' | transloco }}: </span>
                                      {{ contactPerson?.mobile_phone_number }}
                                  </p>

                                  <p class="body-text-1 mb-1">
                                      <span class="title-6 dark-grey-text">{{'ORDER_STATUS.E_MAIL' | transloco }}: </span>
                                      {{ contactPerson?.email }}
                                  </p>
                              </div>
                          </div>
                          <div class="col-md-4" *ngIf="contactAddress?.uuid">
                              <div class="pt-0">
                                  <h5 class="title-5 mb-1 dark-grey-text">{{'TRADE_CUSTOMER.SHIPPING_ADDRESS' | transloco}}</h5>
                                  <p class="body-text-1 mb-0" *ngIf="contactAddress?.company_name">
                                      {{ contactAddress?.company_name }}
                                  </p>
                                  <p class="body-text-1 mb-0">
                                      {{ contactAddress?.initials || contactAddress?.first_name }}
                                      {{ contactAddress?.surname_prefix }}
                                      {{ contactAddress?.last_name }}
                                  </p>
                                  <p class="body-text-1 mb-0">
                                      {{ contactAddress?.street }}
                                      {{ contactAddress?.house_number }}
                                      {{ contactAddress?.house_number_extension }}
                                  </p>
                                  <p class="body-text-1 mb-3">
                                      {{ contactAddress?.zip_code }} {{ contactAddress?.city }}
                                  </p>
                              </div>
                          </div>
                          <div class="col-md-4" *ngIf="tradeInOrder?.has_one_time_shipping_address">
                              <div class="pt-0">
                                  <h5 class="title-5 mb-1 dark-grey-text">{{'TRADE_CUSTOMER.SHIPPING_ADDRESS' | transloco}}</h5>
                                  <p class="body-text-1 mb-0" *ngIf="tradeInOrder?.shipping_address_company_name">
                                      {{tradeInOrder?.shipping_address_company_name }}
                                  </p>

                                  <p class="body-text-1 mb-0">
                                    {{ tradeInOrder?.shipping_address_initials || tradeInOrder?.shipping_address_first_name }}
                                    {{ tradeInOrder?.shipping_address_surname_prefix }}
                                    {{ tradeInOrder?.shipping_address_last_name }}
                                  </p>
                                  <p class="body-text-1 mb-0">
                                    {{ tradeInOrder?.shipping_address_city }}
                                    {{ tradeInOrder?.shipping_address_house_number }}
                                    {{ tradeInOrder?.shipping_address_house_number_extension }}

                                  </p>
                                  <p class="body-text-1 mb-3">
                                    {{ tradeInOrder?.shipping_address_zip_code }} {{ tradeInOrder?.shipping_address_city }}
                                  </p>
                              </div>
                          </div>
                          <div class="col-md-4" *ngIf="tradeInOrder?.has_business_partner_customer_shipping_address">
                              <div class="pt-0">
                                  <h5 class="title-5 mb-1 dark-grey-text">{{'SIGN_UP.CUSTOMER_INFORMATION' | transloco}}</h5>
                                  <p class="body-text-1 mb-0" *ngIf="tradeInOrder?.shipping_address_company_name">
                                    <span class="title-6 dark-grey-text text-capitalize">{{'CUSTOMER.COMPANY_NAME' | transloco }}: </span>
                                    {{ tradeInOrder?.shipping_address_company_name }}
                                  </p>
                                  <p class="body-text-1 mb-0">
                                    <span class="title-6 dark-grey-text text-capitalize">{{'ORDER_CONFIRMATION.NAME' | transloco }}: </span>

                                      {{ tradeInOrder?.shipping_address_initials || tradeInOrder?.shipping_address_first_name }}
                                      {{ tradeInOrder?.shipping_address_surname_prefix }}
                                      {{ tradeInOrder?.shipping_address_last_name }}
                                  </p>
                                  <p class="body-text-1 mb-0" *ngIf="tradeInOrder?.shipping_address_email">
                                    <span class="title-6 dark-grey-text">{{'ORDER_STATUS.E_MAIL' | transloco }}: </span>
                                    {{ tradeInOrder?.shipping_address_email }}
                                  </p>
                                  <p class="body-text-1 mb-0" *ngIf="tradeInOrder?.shipping_address_phone_number">
                                      <span class="title-6 dark-grey-text">{{'ORDER_STATUS.MOBILE_NO' | transloco }}: </span>
                                      {{ tradeInOrder?.shipping_address_phone_number }}
                                  </p>
                                  <p class="body-text-1 mb-0">
                                      <span class="title-6 dark-grey-text">{{'CUSTOMER.ADDRESS' | transloco }}: </span>

                                      {{ tradeInOrder?.shipping_address_city }}
                                      {{ tradeInOrder?.shipping_address_house_number }}
                                      {{ tradeInOrder?.shipping_address_house_number_extension }}
                                      {{ tradeInOrder?.shipping_address_zip_code }} {{ tradeInOrder?.shipping_address_city }}

                                  </p>
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
          <div class="bg-white new-cart-box-overview pt-2 mb-1">
              <div class="row mb-2">
                  <div class="col-md-12">
                      <div class="flat-table" *ngIf="tradeInOrder">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th>Model</th>
                                      <th class="pl-5">{{'ORDER_STATUS.QTY' | transloco }}</th>
                                      <th></th>
                                    <th class="text-right">{{'TRADE_CART.UNIT_PRICE' | transloco}}</th>
                                    <th class="text-right">{{'TRADE_CART.TOTAL_PRICE' | transloco}}</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let item of tradeInOrder?.lines">
                                      <td>{{item?.product?.brand?.name}} {{item?.product?.name}}</td>
                                      <td>{{item?.qty}}</td>
                                      <td>{{tradeInService?.getQuality(item?.product_quality) | titlecase}}</td>
                                      <td class="text-right">{{item?.price_ex_vat | currencyFormat}}</td>
                                      <td class="text-right">{{item?.total_price_ex_vat | currencyFormat}}</td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <p class="d-flex justify-content-between bg-kpn-blue p-3  text-white rounded-lg">
                          <b>{{'TRADE_CART.TOTAL_PAYABLE' | transloco}}</b>
                          <b>{{tradeInOrder?.total_price_ex_vat | currencyFormat}}</b>
                      </p>
                      <p class="body-text-1 mb-3">
                          {{'TRADE_ORDER_DETAILS.THANK_YOU_FOR_YOUR_REQUEST' | transloco}}.
                      </p>
                  </div>
              </div>
              <div class="row justify-content-between pt-1">
                  <div class="col-auto">
                      <button ngbTooltip="Klik om naar bestellingen te gaan" type="button" (click)="back()"
                          class="btn btn-sm btn-link nounderline">
                          <i class="kphicon-uniE9B1 icon-size-md mr-2"></i>{{'TRADE_ORDER_DETAILS.BACK_TO_ORDERS' | transloco}}
                      </button>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>
