import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { CustomersService } from '../customers/customers.service';
import { DepotService } from './depot.service';

@Component({
  selector: 'app-depot-history',
  templateUrl: './depot-history.component.html',
  styleUrls: ['./depot-history.component.scss']
})
export class DepotHistoryComponent implements OnInit {
  public loggedInUser = null;
  public isLoading = false;
  public depotHistories = [];
  public depotError = null;
  public config = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  public searchCompanyList = [];
  public selectedCompanyName = '';
  public showDropDownItemList = false;
  public keyword = 'company_name';
  public searchObject = {
    company_name: '',
    coc_no: '',
    krn_no: ''
  };
  public selectedContact = null;
  public customerSelectionMessage = '';
  public currentValue = 0.0;

  constructor(
    private storageService: StorageService,
    private depotService: DepotService,
    private router: Router,
    private customersService: CustomersService
  ) {
    this.loggedInUser = this.storageService.getCurrentUser();
  }

  ngOnInit(): void {
    if (this.loggedInUser?.is_external) {
      this.selectedContact = this.loggedInUser?.contact;
      this.getDepotUsageHistory();
    } else {
      this.selectedContact = this.storageService.getCustomer() || null;
      if (this.selectedContact) {
        this.getDepotUsageHistory();
      } else {
        this.resetHistory();
      }
    }

    this.depotAvailableAmount();
  }


  resetHistory(msg: string = 'There are no selected contact') {
    this.depotHistories = [];
    this.isLoading = false;
    this.config.totalItems = 0;
    this.depotError = null;
    this.customerSelectionMessage = msg;
  }

  getDepotUsageHistory() {
    this.isLoading = true;
    this.depotError = null;
    if (!this.selectedContact) {
      this.resetHistory();
      return;
    }
    this.depotService.getDepotUsagesHistory(this.selectedContact?.uuid, this.config.currentPage).subscribe(response => {
      this.config.totalItems = response?.data?.count;
      this.depotHistories = response?.data?.results;
      this.isLoading = false;
      this.customerSelectionMessage = '';
    }, error => {
      this.isLoading = false;
      this.depotError = error?.error?.message;

    });
  }

  depotAvailableAmount() {
    if (this.selectedContact) {
      this.depotService.checkAvailableDepot(this.selectedContact?.uuid).subscribe(response => {
        this.currentValue = response?.data?.current_value;
      }, error => {
        this.depotError = error?.error?.message;
      });
    }
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getDepotUsageHistory();
  }

  orderDetails(order) {
    if (order) {
      this.router.navigate(['/order-confirmation', `${order.uuid}`]);
    }
  }

  searchCompanies(searchString: string) {
    if (searchString.length >= 3) {
      const companyName = searchString;
      this.customersService.getCompanies(companyName).subscribe(res => {
        this.showDropDownItemList = true;
        if (res.data && res.data.results) {
          this.searchCompanyList = res.data.results;
        }
      }, error => { });
    }
  }

  onFocused(e) {
    // do something when input is focused
  }

  searchCleared() {
    this.showDropDownItemList = false;
  }

  onSelectedCompany(company: object | any) {
    this.showDropDownItemList = false;
    this.searchObject.coc_no = company?.coc_number,
      this.onSearchCustomer();
  }

  prepareParamString() {

    let params: any;

    if (this.searchObject.krn_no) {
      params = this.searchObject.krn_no;
    } else if (this.searchObject.coc_no) {
      params = this.searchObject.coc_no;
    }
    return params;
  }

  searchType() {
    let searchType = '';
    if (this.searchObject.krn_no) {
      searchType = 'krn';
    } else if (this.searchObject.coc_no) {
      searchType = 'coc';
    }

    return searchType;
  }

  onSearchCustomer() {
    this.customersService.getCustomers(this.prepareParamString(), this.searchType())
      .subscribe(res => {
        this.selectedContact = res?.data?.results?.find(item => true);
        if (this.selectedContact) {
          this.getDepotUsageHistory();
        } else {
          const msg = 'There are no contact found of selected company';
          this.resetHistory(msg);
        }
      }, err => {
        // this.isLoading = false;
      });
  }

}
