<section class="bg-kpn-subtle-grey">
  <section class="py-5">
    <div class="container">
      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
        <li class="back-breadcrumb-btn border-0">
          <a href="javascript:void(0)" class="blue-link" (click)="backToPrevious()">
            <i class="kphicon-uniE906"></i> <span class="font-kpn-extended">Terug</span>
          </a>
        </li>
        <!-- <li class="home-btn ml-2 px-1 pb-1">
          <a routerLink="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                  fill="#939393" />
              </g>
            </svg>
          </a>
        </li>
        <li><a routerLink="/">Alle apparaten</a></li> -->
      </ul>

      <div class="pt-2">
        <app-trade-in-steps [activeStep]="'overview'" [activeStep]="'customer_select'"></app-trade-in-steps>
      </div>

      <!-- <app-steps *ngIf="cart" [activeStep]='activeStep' [completeStepOne]="'true'" [completeStepTwo]="'true'"></app-steps> -->

      <h2 class="font-kpn-bold tri-title-40 green-text mb-3 mt-n3">Gegevens</h2>

      <div class="facts-box">
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-32">
          <h3 class="green-text font-kpn-bold tri-title-32 mb-4">Bedrijfsgegevens </h3>

          <div class="row mb-5">
            <div class="col-md-12">
              <div class="row mb-3 pb-1 gutter-row-24">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group tri-autocomplete-form">
                    <label for="" class="field-label"> {{'CUSTOMERS.COMPANY_NAME' | transloco }} </label>
                    <!-- <input type="text" class="form-control rounded-0"> -->
                    <ng-autocomplete #customerSearch [data]="searchCompanyList"
                      [placeholder]="'CUSTOMERS.SEARCH_BY_COMPANY_NAME_OR_CHAMBER_OF_COMMERCE_NUMBER' | transloco" [(ngModel)]='selectedCompanyName'
                      [searchKeyword]="keyword" (selected)='onSelectedCompany($event)'
                      (inputChanged)='searchCompanies($event)' (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplate" focusFirst="true" historyIdentifier="searchCompanyList"
                      isHistoryListVisible="true" (inputCleared)="searchCleared()" [debounceTime]="800"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>

                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.company_name"></a>
                    </ng-template>

                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <form [formGroup]="searchForm" (ngSubmit)="onSearchCustomer()" class="in-active-form mt-4">
                <div class="row gutter-row-24 mb-3 pb-1">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">KVK-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                      <input type="text" formControlName="coc_no" class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">KRN-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                      <input type="text" formControlName="krn_no" class="form-control tri-form-control ">
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3 pb-1">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.POSTCODE' | transloco}}</label>
                      <input type="text" class="form-control tri-form-control" oninput="this.value = this.value.toUpperCase()"
                        formControlName="zipcode" [patterns]="zipCodePattern" mask="111100" placeholder="1234AB">
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">
                        {{'CUSTOMERS.HOUSE_NUMBER' | transloco}}
                      </label>
                      <input type="text" formControlName="house_number" class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">
                        {{'CUSTOMERS.ADDITION' | transloco}}
                      </label>
                      <input type="text" formControlName="house_number_ext" class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
                <!-- <div class="row mb-3 gutter-row-24">
                  <div class="col gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">Straat</label>
                      <input disabled type="text" formControlName="house_number_ext" class="form-control rounded-0">
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">Plaats</label>
                      <input disabled type="text" formControlName="house_number_ext" class="form-control rounded-0">
                    </div>
                  </div>
                </div> -->

                <!-- <div class="pt-2 mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input type="checkbox">
                      <span class="checkBoxCheckmark"></span>
                    </div>
                    <div class="checkbox-label ml-2">
                      <span>Afleveradres wijkt af van organisatie gegevens </span>
                    </div>
                  </label>
                </div> -->

                <div class="pt-2 text-right">
                  <button class="btn btn-primary rounded-btn px-4 font-kpn-extended"
                    [disabled]="!searchForm.controls.krn_no?.value  && !searchForm.controls.coc_no?.value && !searchForm.controls.zipcode?.value && !searchForm.controls.house_number?.value">{{'CUSTOMERS.SEARCH' | transloco}}</button>
                </div>
              </form>
            </div>
          </div>


          <div class="section-loader" *ngIf="isLoading">
            <div class="kpn-spinner">
              <img src="/assets/images/kpn-loader.gif" alt="Loading" />
            </div>
          </div>

          <div *ngIf="customers && !isLoading">
            <h3 class="title-3 mb-4">{{customers?.length}}
              {{'CUSTOMERS.RESULTS' | transloco}}
            </h3>
            <div *ngIf="customers?.length" class="row gutter-row-24 mb-3">
              <div class="col-md-6 gutter-col-24 m-1" *ngFor="let customer of customers">
                <div class="customer-box" [ngClass]="{'selected-customer' : selectedCustomer?.uuid === customer?.uuid, 'disabled-div' :
                  !customer?.is_active }" (click)="onSelectCustomer(customer)">
                  <span *ngIf="!customer?.is_active" class="dark-orange-tag-pill">  {{'CUSTOMERS.INACTIVE' | transloco}} </span>
                  <p class="customer-info-line">
                    <label for=""> {{'CUSTOMERS.COMPANY_NAME' | transloco}}</label>
                    <span>{{customer.company_name}}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">KVK-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                    <span>{{ customer.coc_number }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">KRN-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                    <span>{{ customer.external_id1 }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.STREET' | transloco}}</label>
                    <span>{{ customer.firstContactAddress?.street }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.HOUSE_NUMBER' | transloco}} </label>
                    <span>{{ customer.firstContactAddress?.house_number }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.ADDITION' | transloco}} </label>
                    <span>-</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">Postcode</label>
                    <span>{{customer.firstContactAddress?.zip_code}}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.CITY' | transloco}}</label>
                    <span>{{customer.firstContactAddress?.city}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center justify-content-between pt-4">
          <div class="col-auto d-flex">
            <button (click)="backToPrevious()" class="btn btn-link d-flex align-items-center">
              <i class="kphicon-uniE906 fs-22"></i> Vorige
            </button>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary rounded-btn mb-2 px-5" [disabled]="!selectedCustomer"
              (click)="onCustomerSelect(selectedCustomer)">
              Volgende
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- New Design End -->
  </section>
</section>
