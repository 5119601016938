<div class="body-container">
  <section class="new-product-list pt-5 m-4">
    <div class="product-table">
      <h5 class="page-title-green">
        {{ wishlistData?.name }}
      </h5>


      <div class="row align-items-center p-2" *ngIf="cartError">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              {{cartError}}
            </p>
          </div>
        </div>
      </div>


      <div class="row align-items-center p-2" *ngIf="!wishlistData?.items?.length">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              <b> {{'WISH_LIST.EMPTY_WISH_LIST' | transloco}}</b>
            </p>
          </div>
        </div>
      </div>

      <table class="table" *ngIf="wishlistData?.items?.length">
        <thead>
          <tr>
            <th></th>
            <th>
              <div class="sortable-th">{{'WISH_LIST.ITEM_CODE' | transloco}}</div>
            </th>
            <th>
              <div class="sortable-th">{{'WISH_LIST.PRODUCT_NAME' | transloco}}</div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr class="item-selected" *ngFor="let item of wishlistData?.items; let i = index">
            <td>
              <!-- <button [disabled]="!hasWishListDeleteItemPermission" ngbTooltip="Verwijderen uit favoriet"
                type="button" (click)="removeFromFavorite(item)" class="btn btn-sm btn-link">
                <i class="kphicon-uniE9A7 icon-size-md mr-2 favorite-item-logo"></i>
              </button> -->
              <button  [disabled]="!hasWishListDeleteItemPermission" ngbTooltip="Verwijderen uit favoriet" (click)="removeFromFavorite(item)" class="btn wishlist-btn active btn-outline-primary border-2 px-3 my-2 rounded-btn">
                <i class="kphicon-uniE94A"></i>
              </button>
            </td>
            <td>{{ item?.product?.code }}</td>
            <td>
              <button type="button" (click)="gotoProductDetails(item?.product?.code)" class="btn btn-sm btn-link">
                {{ item?.product?.name }}
                <i class="kphicon-uniE938 icon-size-md mr-2" style="color: #5cb85c"></i>
              </button>
            </td>
            <td class="text-right">
              <button class="btn btn-sm"
                [ngClass]="checkProductInCart(item?.product?.code)  ? 'btn-success' : 'btn-primary'"
                [disabled]="item?.product?.showSpinner" (click)="onAddToCart(item?.product)">
                <i class="kpn-ui-icon ui-shoppingbasket v-text-bottom mr-2"></i> {{'WISH_LIST.IN_SHOPING_CART' | transloco}}
                <span *ngIf="checkProductInCart(item?.product?.code)"><b>
                    ({{checkProductInCart(item?.product?.code)?.quantity}})</b></span>
              </button>

            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </section>
</div>
