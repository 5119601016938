<section class="pb-4 bg-white">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div class="container">
    <!-- Breadcrumb -->
 <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-4" >
  <li class="back-breadcrumb-btn">
    <a class="cursor-pointer" (click)="backToPreviousPage()" >
      <i class="kphicon-uniE906"></i> Terug
    </a>
  </li>
  <li class="home-btn ml-2 px-1 pb-1">
    <a routerLink="/">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="UI Icon">
          <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
            d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
            fill="#939393" />
        </g>
      </svg>
    </a>
  </li>
  <li  role="button"> <a class="grey-text" >Offerte overzicht</a></li>
  <li> <a> Offerte #{{ quote?.reference }}</a></li>
</ul>

  <div class="mb-1" *ngIf="eQuoteStatus.APPROVED === quote?.status">
    <app-customer-quote-steps [showConfigTab]="quote?.is_contact_account_type_required || hasCpaas"  [activeStep]="3" [completedSteps]="[1,2,3]" />
  </div>

    <h3 class="font-kpn-black green-text fs-40 mb-3 pt-2">Voorkeuren aanpassen</h3>
    <div class="row mb-3">
      <div class="col-md pr-md-0">

        <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 mb-4 pb-1" *ngIf="hasCpaas && isCpassDataAdded">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pt-1 c-px-24">
            CPaaS hoofdgebruiker </h4>

          <p class="font-kpn-extended mb-3 pb-1 c-px-24">
            De onderstaande CPaaS hoofdgebruiker is bij ons bekend. Klopt deze niet? Dan kun je deze hieronder wijzigen.
          </p>

          <div class="row gutter-row-24">
            <div class="col-md-12 mb-4 gutter-col-24" >
              <app-customer-quote-cpass [showEditButtonInBottom]="true" (updateCpassDataEventEmitter)="updateCpassDataFromChild($event)"  />
            </div>
          </div>
        </div>

        <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24 mb-4" *ngIf="hasCpaas && (!isCpassDataAdded)">
          <h4 class="mb-2 pt-1">
            <span class="font-kpn-extended kpn-extended-black letter-spacing-normal fs-35 green-text">
              CPaas hoofdgebruiker
            </span>
          </h4>
          <p class="font-kpn-extended mb-3 pb-1">Om ervoor te zorgen dat je gebruik kunt maken van de CPaaS-dienst, is het belangrijk dat er een hoofdgebruiker is aangewezen. Deze persoon is verantwoordelijk voor het beheer en configuratie binnen jouw organisatie.</p>
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-6 gutter-col-15 mb-3">
              <label for="" class="font-kpn-extended"><span
                  class="font-kpn-extended kpn-extended-bold">Voornaam</span><span class="text-danger">*</span></label>
              <input type="text" class="form-control tri-form-control is-invalid "
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_first_name }"
                [(ngModel)]="cPassData.cpaas_user_first_name" (keyup)="onCpassFirstNameChange($event)"
                placeholder="Voornaam">
              <span *ngIf="feRequiredFieldErrors.cpaas_user_first_name"
                class="invalid-feedback fs-16 font-kpn-extended">
                {{ feRequiredFieldErrors.cpaas_user_first_name }}
              </span>
            </div>
            <div class="col-md-6 gutter-col-15 mb-3">
              <label for="" class="font-kpn-extended"><span
                  class="font-kpn-extended kpn-extended-bold">Achternaam</span><span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control tri-form-control is-invalid invalid-bg-size-23"
                [(ngModel)]="cPassData.cpaas_user_last_name" (keyup)="onCpassLastNameChange($event)"
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_last_name}"
                placeholder="Achternaam">
              <span *ngIf="feRequiredFieldErrors.cpaas_user_last_name" class="invalid-feedback fs-16 font-kpn-extended">
                {{ feRequiredFieldErrors.cpaas_user_last_name }}
              </span>

            </div>
            <div class="col-md-6 gutter-col-15">
              <label for="" class="font-kpn-extended"><span
                  class="font-kpn-extended kpn-extended-bold">E-mailadres</span><span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control tri-form-control is-invalid invalid-bg-size-23"
                [(ngModel)]="cPassData.cpaas_user_email" (keyup)="onCpassEmailChange($event)"
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_email}"
                placeholder="E-mailadres">
              <span *ngIf="feRequiredFieldErrors.cpaas_user_email" class="invalid-feedback fs-16 font-kpn-extended">
                {{ feRequiredFieldErrors.cpaas_user_email }}
              </span>
            </div>
          </div>
        </div>


        <div *ngIf="quote?.is_contact_account_type_required && (hasViewContactAccountTypeCustomer ? (contactAccountTypesCustomers?.length) : true)">
          <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 pb-3 mb-4" >
            <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pt-1 c-px-24">
              Enrollment
            </h4>
            <p class="font-kpn-extended c-px-24">
              Met enrollment worden je apparaten aan je onderneming gekoppeld. Hierdoor wordt het registreren en beheren van je apparaten makkelijker. Enrollment wordt automatisch toegevoegd op basis van de telefoons die je gaat bestellen. Wil je dit niet? Dan kun je dat hieronder aanpassen.
            </p>
            <div>
              <app-customer-quote-enrollment [sowAddRemoveEnrollmentBtn]="true" [onlyShowEnrolledItem]="false"  [showEditBtn]="false" />
            </div>
          </div>
        </div>

        <p class="pt-0">
          <button (click)="backToPreviousPage()" class="fs-16 pl-0 font-kpn-extended link-btn-blue">
            <i class="kpnew-icon-arrow-lingt-2 fs-18 align-middle"></i> Terug naar gegevens</button>
        </p>
      </div>
      <div class="col-md-auto">
        <div
          class="tri-shadow-xl quote-details-cart-overview quote-days-left tri-rounded-20 bg-white py-3 c-px-20 mb-3">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pb-2 pt-2">
            Overzicht</h4>
          <div class="bg-gray-1 py-2 px-3 tri-rounded-16 mb-4">
            <app-customer-quote-side-bar-price-summary/>
          </div>

          <div class="pb-2">
            <button class="btn btn-outline-primary btn-block border-2 px-4 mr-md-3 rounded-btn mt-3"
              (click)="openQuoteDetailsSummeryModal(quoteDetailsSummery)"> Bekijk offerte
              details</button>
            <button class="btn btn-primary btn-block rounded-btn px-4 mt-2" (click)="gotoSummaryPage()">Betaal op
              rekening</button>
          </div>

        </div>
      </div>

    </div>

  </div>



  <ng-template #quoteDetailsSummery let-modal>
    <div class="px-4 pt-4">
      <div class="modal-header tri-modal-header p-0 pb-4 border-0">
        <h3 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
          Offertedetails
        </h3>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <app-quote-details-summary  [showRejectButton]="false" [showPlaceOrderButton]="false"
        [showHeader]="false" [bottomZero]="true"></app-quote-details-summary>
    </div>
  </ng-template>



</section>
