<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="loader-wrapper box-shadow-sm" [ngClass]="{'spinner-wrapper-height': isLoading}">
        <div class="table-responsive custom-table">
          <h3 class="title-3 green-text ml-2 pt-3">{{'FORECAST.LIST_OF_CUSTOMER_FORECASTS' | transloco}}:</h3>
          <div class="kpn-spinner" *ngIf="isLoading;else table_content">
            <div class="text-center">
              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
              <p>{{'FORECAST.TO_SEARCH' | transloco}}…</p>
            </div>
          </div>
          <ng-template #table_content>
            <table class="table mb-5" *ngIf="customerForecasts?.length">
              <thead>
                <tr>
                  <th>{{'FORECAST.CATALOG' | transloco}}</th>
                  <th>{{'FORECAST.FORECAST_PERIOD' | transloco}}</th>
                  <th>{{'FORECAST.ROLLING_PERIOD_PERIOD' | transloco}}</th>
                  <th>{{'FORECAST.FORECAST_DURATION' | transloco}}</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let forecast of customerForecasts | paginate: config">
                  <td>{{forecast?.catalog?.name}}</td>
                  <td>{{forecast?.forecast_period}}</td>
                  <td>{{forecast?.rolling_period}}</td>
                  <td>{{forecast?.forecast_duration}}</td>
                  <td class="text-right">
                    <button class="btn btn-outline-primary outline-icon-btn pt-0 pb-0 rounded-btn"
                      (click)="navigateToConfigForecast(forecast)">
                      {{'FORECAST.CONFIGURE_SALES_FORECAST' | transloco}}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new px-2"
              *ngIf="(currentUser?.is_external || (!currentUser?.is_external && customer) ) && !customerForecasts?.length">
              <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                <h6>
                  <span class="kphicon-uniE952"></span>
                  {{'FORECAST.NO_CUSTOMER_FORECASTS' | transloco}}.
                </h6>
              </div>
            </div>

            <div class="pagination-new px-2" *ngIf="!currentUser?.is_external && !customer">
              <div class="info-bar-yellow mb-3 d-flex align-items-center justify-content-between">
                <h6>
                  <span class="kphicon-uniE952"></span>
                   {{'FORECAST.NO_CUSTOMER_SELECTED' | transloco}}
                </h6>
              </div>
            </div>
            <div class="pagination-new" *ngIf="config.totalItems">
              <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                (pageChange)="onPageClick($event)"></pagination-controls>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>
