<div class="bg-kpn-subtle-grey body-container">
  <div>
    <app-business-partner-selection [showEdit]="true"></app-business-partner-selection>
  </div>
  <div class="pt-4 pb-5">
    <div class="container">
      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
        <li class="back-breadcrumb-btn">
          <a  (click)="goBack()"  href="javascript:void(0)">
            <i class="kphicon-uniE906"></i> Terug
          </a>
        </li>
        <li class="home-btn ml-2 px-1 pb-1">
          <a routerLink="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                  fill="#939393" />
              </g>
            </svg>
          </a>
        </li>
        <li><a (click)="goBack()" href="javascript:void(0)">Alle apparaten</a></li>
        <li><a routerLink="/trade-in/cart-details">Verkoopmand</a></li>
        <li><a style="color: #737373;" >Jouw overzicht</a></li>

      </ul>

      <div class="pt-2">
        <app-trade-in-steps [activeStep]="'overview'"></app-trade-in-steps>
      </div>

      <h3 class="font-kpn-black h1 mb-0 green-text mb-n1">Jouw overzicht</h3>
      <p class="font-kpn-extended line-height-24">{{ tradeInCart?.lines?.length }} producten</p>

      <div class="row">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-32">
            <div class="add-cart-prd mb-4" *ngIf="functionalCart?.length">
              <h3 class="green-text font-kpn-black h4 mb-1">Werkende apparaten</h3>
              <p class="fs-20 font-kpn-matricregular line-height-24">
                Apparaten die naar behoren werken.
              </p>
              <div class="add-cart-prd-item last-item-m-0 mb-3" *ngFor="let item of functionalCart;let i = index">
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-4">
                      <span class="font-kpn-extended">{{ item?.product?.brand?.name }}</span>
                      <h4 class="h4 font-kpn-bold pt-1 mb-0">{{ item?.product?.name }}</h4>
                      <span class="font-kpn-extended text-black-50">Geheugen: {{ item?.product?.memory_size }}</span>
                    </div>

                    <div class="qty-in-di-box d-inline-flex align-items-center mb-4">
                      <button (click)="decrementQuantity(item,i,'functional')" [disabled]="item.qty == 1 || (!item?.qty)" class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                      <input (keypress)="restrictInput($event)" (keyup)="onChangeQty($event,i,'functional',item)" class="in-di-input font-kpn-extended bg-white" type="text"  [(ngModel)]="item.qty">
                      <button (click)="incrementQuantity(item,i,'functional')" class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button (click)="deleteCartLine(item)" class="add-cart-remove-btn mb-3"><i class="kphicon-uniE92A"></i></button>
                    <div class="add-cart-pr-img" *ngIf="item?.product?.image">
                      <img [src]="item?.product?.image" alt="">
                    </div>
                  </div>
                </div>
                <hr class="mt-0 border-color-tri">
                <div class="d-flex justify-content-between">
                  <span class="font-kpn-extended">Totaal</span>
                  <h4 class="h4 font-kpn-black green-text mb-0">{{ item?.total_price_ex_vat | currencyFormat }}</h4>
                </div>
              </div>

            </div>

            <div class="add-cart-prd mb-4 pb-1" *ngIf="nonFunctionalCart?.length">
              <h3 class="green-text font-kpn-black h4 mb-1">Niet werkende apparaten</h3>
              <p class="fs-20 font-kpn-matricregular line-height-24">
                Apparaten die niet naar behoren werken.
              </p>

              <div class="add-cart-prd-item last-item-m-0 mb-3" *ngFor="let item of nonFunctionalCart;let i = index">
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-4">
                      <span class="font-kpn-extended">{{ item?.product?.brand?.name }}</span>
                      <h4 class="h4 font-kpn-bold pt-1 mb-0">{{ item?.product?.name }}</h4>
                      <span class="font-kpn-extended text-black-50">Geheugen: {{ item?.product?.memory_size }}</span>
                    </div>

                    <div class="qty-in-di-box d-inline-flex align-items-center mb-4">
                      <button (click)="decrementQuantity(item,i,'nonFunctional')" [disabled]="item.qty == 1 || (!item?.qty)" class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                      <input class="in-di-input font-kpn-extended bg-white" (keypress)="restrictInput($event)"  (keyup)="onChangeQty($event,i,'nonFunctional',item)" type="text"  [(ngModel)]="item.qty">
                      <button (click)="incrementQuantity(item,i,'nonFunctional')" class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <button (click)="deleteCartLine(item)" class="add-cart-remove-btn mb-3"><i class="kphicon-uniE92A"></i></button>
                    <div class="add-cart-pr-img" *ngIf="item?.product?.image">
                      <img [src]="item?.product?.image" alt="">
                    </div>
                  </div>
                </div>
                <hr class="mt-0 border-color-tri">
                <div class="d-flex justify-content-between">
                  <span class="font-kpn-extended">Totaal</span>
                  <h4 class="h4 font-kpn-black green-text mb-0">{{ item?.total_price_ex_vat | currencyFormat }}</h4>
                </div>
              </div> 
            </div>

            <div class="text-center pt-0 mb-4 pb-3">
              <button (click)="back()"
                class="btn btn-outline-primary border-2 mt-0 py-0 px-4 rounded-btn font-kpn-extended d-inline-flex align-items-center">
                <i class="kphicon-uniE985 h3 mb-0 mr-1"></i> Toestel toevoegen</button>
            </div>

            <div class="pt-1" *ngIf="productPromotionList?.length">

              <h3 class="green-text font-kpn-black h4 mb-1">Extra inruilwaarde</h3>
              <p class="fs-20 font-kpn-matricregular line-height-24">
                Geef aan welk toestel je van plan bent aan te schaffen of hebt aangeschaft en krijg een indicatie hoeveel
                extra inruilwaarde je ontvangt.
              </p>

              <div class="row gutter-row-15">
                <div class="col-md-6 gutter-col-15 last-item-m-0 mb-3" *ngFor="let item of productPromotionList">
                  <div class="add-action-prd p-3 " [ngClass]="{'item-added': item?.cart}">
                    <div class="d-flex align-content-center justify-content-between mb-1">
                      <h6 class="font-sf-pro font-weight-bolder mb-1">{{ item?.name }}</h6>
                      <span class="font-kpn-matricbold green-text fs-20">{{ (item?.cart ? item?.cart?.total_price_ex_vat : item?.price[0]?.price_ex_vat ) | currencyFormat}}</span>
                    </div>
                    <div class="d-flex align-items-end justify-content-between">
                      <div class="mr-2">
                        <p class="font-sf-pro text-black-50 mb-0">{{ item?.description }}
                          <span *ngIf="item?.long_description">
                            <button type="button" (click)="aboutPromoProduct(aboutPromoProductLongDescriptionModal,item)" class="btn btn-link btn-sm p-0 m-0 font-kpn-extended d-inline-flex align-items-center">Meer zien...</button>
                          </span>
                        </p>
                        <a href="javascript:void(0)" (click)="aboutPromoProduct(aboutPromoProductModal,item)"
                          class="nav-link p-0 m-0 font-kpn-extended d-inline-flex align-items-center">{{item?.cart ? 'Wijzigen' : 'Meer informatie'}}  <i
                            class="kphicon-uniE907"></i></a>
                      </div>
                      <button (click)="deleteCartLine(item?.cart)" *ngIf="item?.cart" class="kphicon-uniE972 add-action-addmore-btn"></button>
                      <button (click)="aboutPromoProduct(aboutPromoProductModal,item)" *ngIf="!item?.cart" class="kphicon-uniE985 add-action-addmore-btn"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <ng-template #aboutPromoProductModal let-modal>
              <div class="modal-header tri-modal-header">
                <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
                  {{ selectedPromotionProduct?.name }}
                </h6>
                <button type="button" class="btn close" aria-label="Close" (click)="closePromotionProductModal()">
                  <span class="kphicon-uniE926"></span>
                </button>
              </div>

              <div class="modal-body custom-scrollar">
                <div class="p-3 font-kpn-extended">
                  <h4 class="font-kpn-black green-text mb-3">Hoe werkt de actie?</h4>
                  <p>We hebben een speciale aanbieding voor de aanschaf van de nieuwe iPhone 15. Bij het inleveren van
                    je oude toestel en bij aankoop van de iPhone 15, ontvang je extra trade-in waarde van €100,- per
                    ingeleverd apparaat.</p>
                  <p>Er zijn een aantal voorwaarden. Het aantal extra kortingen dat je ontvangt, kan niet hoger zijn dan
                    het aantal apparaten dat je wilt inruilen. Bijvoorbeeld, als je 10 nieuwe iPhone 15 Pro
                    Max-apparaten aanschaft en je wilt in totaal 100 apparaten inruilen, kun je slechts 10 keer de
                    extra inruilwaarde van 100,- euro krijgen.</p>
                  <p>Om van deze aanbieding te profiteren, dien je een factuur te sturen naar Recommerce, waarin
                    duidelijk vermeld staat dat je één of meerdere iPhone 15's hebt aangeschaft. Zonder dit
                    factuurbewijs kan het extra bedrag niet worden uitgekeerd.</p>
                  <p>Na het afronden van de checkout ontvang je een e-mail met verdere instructies over het indienen van
                    je factuur.</p>
                </div>
                <div class="px-3">
                  <div class="add-cart-prd">
                    <div class="add-cart-prd-item mb-3 p-32">
                      <h3 class="green-text font-kpn-black h4 mb-1">Maak een keuze</h3>
                      <p class="fs-20 font-kpn-matricregular line-height-24" >
                        <span *ngIf="selectedPromotionProduct?.help_text?.length">
                          {{selectedPromotionProduct?.help_text}}
                        </span>
                        <span *ngIf="!selectedPromotionProduct?.help_text?.length">
                          Hoeveel {{ selectedPromotionProduct?.name }}  heb je besteld of ben je van plan te gaan bestellen?
                          <strong>Let op!</strong> Je kunt maximaal het {{ availableQty }} aantal apparaten selecteren welke je ook in gaat leveren.
                        </span>
                      </p>
                      <div class="d-flex justify-content-between align-items-center">
                        <div class="qty-in-di-box d-inline-flex align-items-center">
                          <button (click)="updatePromotionQty(selectedPromotionProduct?.qty -1)"  [disabled]="selectedPromotionProduct?.qty == 1 || (!selectedPromotionProduct?.qty)" class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                          <input   (keypress)="restrictInput($event)"  (keyup)="onChangePromotionQty($event)" class="in-di-input font-kpn-extended bg-white" type="text" [(ngModel)]="selectedPromotionProduct.qty" >
                          <button (click)="updatePromotionQty(selectedPromotionProduct?.qty +1)" class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                        </div>

                        <div class="text-right">
                          <span class="font-kpn-extended small text-black-50">Totaal</span>
                          <h4 class="h6 font-kpn-black green-text mb-0">{{ selectedPromotionProduct?.totalPrice | currencyFormat }}</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row justify-content-center py-2 mb-3">
                    <div class="col-md-9">
                      <button (click)="selectedPromotionProduct?.cart ?  updatePromotionProductQuantity(selectedPromotionProduct?.cart,selectedPromotionProduct?.qty) : addPromotionToCart()" class="btn btn-block btn-primary mt-2 rounded-btn">Keuze opslaan</button>
                      <button (click)="deletePromotionProductFromCart(selectedPromotionProduct?.cart)" *ngIf="selectedPromotionProduct?.qty && selectedPromotionProduct?.cart"
                        class="btn btn-block btn-outline-primary bg border-2 mt-2 py-1 px-4 rounded-btn font-kpn-extended d-flex justify-content-center align-items-center">
                        <i class="kphicon-uniE92A h4 mb-0 mr-1"></i> Verwijder selectie</button>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <ng-template #aboutPromoProductLongDescriptionModal let-modal>
              <div class="modal-header tri-modal-header">
                <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
                  {{ selectedPromotionProduct?.name }}
                </h6>
                <button type="button" class="btn close" aria-label="Close" (click)="closePromotionProductModal()">
                  <span class="kphicon-uniE926"></span>
                </button>
              </div>

              <div class="modal-body custom-scrollar">
                <div class="p-3 font-kpn-extended">
                  <p class="font-sf-pro text-black-50 mb-0">{{ selectedPromotionProduct?.long_description}}</p>
                </div>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="col-md-4 pl-md-0">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-4">
            <h3 class="h3 green-text font-kpn-black">Verkoopprijs</h3>
            <div class="blue-warning-qoute d-flex mb-4">
              <i class="kphicon-uniE952 note-icon mr-3 "></i>
              <p class="font-kpn-extended m-0 line-height-24">Dit is je geschatte verkoopprijs. De geschatte prijs kan
                afwijken van de uiteindelijke verkoopprijs na controle.</p>
            </div>
            <hr>
            <h6 class="font-kpn-bold">inruilwaarde</h6>
            <p class="d-flex align-items-center justify-content-between mb-1" *ngIf="functionalCart?.length">
              <span class="font-kpn-extended">Werkende apparaten ({{ functionTotalQty }})</span>
              <strong class="font-kpn-bold green-text">{{ functionTotalPrice | currencyFormat }}</strong>
            </p>
            <p class="d-flex align-items-center justify-content-between" *ngIf="nonFunctionalCart?.length">
              <span class="font-kpn-extended">Niet werkende apparaten ({{ nonFunctionTotalQty }})</span>
              <strong class="font-kpn-bold green-text">{{ nonFunctionTotalPrice | currencyFormat}}</strong>
            </p>
            <hr *ngIf="promotionCart?.length" class="mb-3">
            <div *ngIf="promotionCart?.length">
              <h6 class="font-kpn-bold">Extra inruilwaarde</h6>
              <p class="d-flex align-items-center justify-content-between mb-1"*ngFor="let item of promotionCart">
                <span class="font-kpn-extended">{{ item?.product?.name }} ({{item?.qty}})</span>
                <strong class="font-kpn-bold green-text">{{item?.total_price_ex_vat | currencyFormat}}</strong>
              </p>
            </div>
            <hr class="mb-3">
            <p class="d-flex align-items-center justify-content-between mb-2">
              <span class="font-kpn-extended">Voorlopig totaal</span>
              <strong class="font-kpn-black green-text h4 mb-0">{{ tradeInCart?.total_price_ex_vat | currencyFormat }}</strong>
            </p>
            <p class="d-flex align-items-center justify-content-between">
              <span class="font-kpn-extended text-black-50">
                Verzending
                <i *ngIf="!isCollapsedInfo" class="tri-tooltip-btn trade-in-btn-ttb-sm ml-1" (click)="isCollapsedInfo=!isCollapsedInfo"
              aria-controls="collapseExample">i</i>
                <i *ngIf="isCollapsedInfo" (click)="isCollapsedInfo=!isCollapsedInfo" class="ml-1" role="button">
                  <img src="../../../../assets/images/close-trans.svg" width="14px" height="14px" alt="">
                </i>
              </span>
              <strong  class="font-kpn-bold green-text">Gratis</strong>
            </p>
            <div *ngIf="isCollapsedInfo"
              class="blue-warning-qoute p-2 bg-white border-right border-top border-bottom border-color-tri  d-flex mb-3">
              <p *ngIf="totalQty<=10;else moreThan10" class="font-kpn-extended m-0 line-height-24">Je ontvangt binnen <strong>2</strong> dagen een retourlabel waarmee je je apparaten gratis kan verzenden naar ons.</p>
              <ng-template #moreThan10>
                <p  class="font-kpn-extended m-0 line-height-24">We nemen binnen <strong>2</strong> werkdagen contact met je op om een afspraak in te plannen om je apparatuur gratis op te halen.</p>
              </ng-template>
            </div>
            <div>
              <button (click)="next()" [disabled]="!tradeInCart?.lines?.length" class="btn btn-block btn-primary mt-2 rounded-btn">Doorgaan</button>
            </div>
          </div>

          <div class="py-4">
            <p class="font-kpn-extended mb-2">
              <img class="mr-2" src="../../../../assets/images/UI-Icon.svg" alt="">
              Meest duurzame netwerk
            </p>
            <p class="font-kpn-extended mb-2">
              <img class="mr-2" src="../../../../assets/images/UI-euro-Icon.svg" alt="">
              Verantwoord ondernemen
            </p>
            <p class="font-kpn-extended mb-2">
              <img class="mr-2" src="../../../../assets/images/UI-equlizer-Icon.svg" alt="">
              Kostenbesparing
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
</div>
