<div class="mb-3" >
  <div class="checkout-process-stepbar checkout-process-stepbar-quote mb-0 mt-1">
    <div class="step-item" (click)="goToQuoteDetails()" [ngClass]="{'active-step': activeStep == 1,'completed-step': isCompleted(1)}"  >
      <h6 class="step-name font-kpn-extended kpn-extended-bold">
        <i class="">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.75 1.5H5.25C3.18 1.5 1.5 3.18 1.5 5.25V18.75C1.5 20.82 3.18 22.5 5.25 22.5H18.75C20.82 22.5 22.5 20.82 22.5 18.75V5.25C22.5 3.18 20.82 1.5 18.75 1.5ZM21 18.75C21 19.995 19.995 21 18.75 21H5.25C4.005 21 3 19.995 3 18.75V5.25C3 4.005 4.005 3 5.25 3H18.75C19.995 3 21 4.005 21 5.25V18.75ZM18 6.75C18 7.17 17.67 7.5 17.25 7.5H6.75C6.33 7.5 6 7.17 6 6.75C6 6.33 6.33 6 6.75 6H17.25C17.67 6 18 6.33 18 6.75ZM18 11.25C18 11.67 17.67 12 17.25 12H6.75C6.33 12 6 11.67 6 11.25C6 10.83 6.33 10.5 6.75 10.5H17.25C17.67 10.5 18 10.83 18 11.25ZM12.75 15.75C12.75 16.17 12.42 16.5 12 16.5H6.75C6.33 16.5 6 16.17 6 15.75C6 15.33 6.33 15 6.75 15H12C12.42 15 12.75 15.33 12.75 15.75Z"/>
          </svg>
        </i>
        Offertedetails</h6>
    </div>
    <div class="step-item " (click)="goToQuoteInformation()" [ngClass]="{'active-step': activeStep == 2,'completed-step': isCompleted(2)}" >
      <h6 class="step-name font-kpn-extended kpn-extended-bold">
        <i>
          <svg width="19" height="22" viewBox="0 0 19 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.2499 5.75C14.2499 2.855 11.8949 0.5 8.99993 0.5C6.10493 0.5 3.74993 2.855 3.74993 5.75C3.74993 8.645 6.10493 11 8.99993 11C11.8949 11 14.2499 8.645 14.2499 5.75ZM8.99993 9.5C6.92993 9.5 5.24993 7.82 5.24993 5.75C5.24993 3.68 6.92993 2 8.99993 2C11.0699 2 12.7499 3.68 12.7499 5.75C12.7499 7.82 11.0699 9.5 8.99993 9.5ZM17.9699 18.86L17.8199 17.945C17.1299 13.925 13.6049 11 9.44993 11H8.56493C4.40993 11 0.884926 13.925 0.194926 17.945L0.0449258 18.86C-0.0600742 19.505 0.119926 20.18 0.539926 20.69C0.974926 21.215 1.61993 21.5 2.30993 21.5H15.7199C16.4099 21.5 17.0399 21.2 17.4899 20.69C17.9249 20.18 18.1049 19.52 17.9849 18.86H17.9699ZM16.3199 19.715C16.1699 19.895 15.9449 20 15.7049 20H2.29493C2.05493 20 1.82993 19.895 1.67993 19.715C1.52993 19.535 1.46993 19.325 1.51493 19.115L1.66493 18.2C2.23493 14.9 5.12993 12.5 8.54993 12.5H9.43493C12.8549 12.5 15.7649 14.9 16.3199 18.2L16.4699 19.115C16.4999 19.34 16.4549 19.55 16.3049 19.715H16.3199Z" />
            </svg>


        </i>
        Gegevens</h6>
    </div>
    <div (click)="goToQuoteConfiguration()" *ngIf="showConfigTab" class="step-item " [ngClass]="{'active-step': activeStep == 3,'completed-step': isCompleted(3)}">
      <h6 class="step-name font-kpn-extended kpn-extended-bold">
        <i>
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M22.1403 5.52009C22.0503 5.28009 21.8553 5.10009 21.6003 5.04009C21.3453 4.98009 21.0903 5.05509 20.9103 5.23509L18.0453 8.10009L16.3653 7.65009L15.9153 5.97009L18.7953 3.09009C18.9753 2.91009 19.0503 2.64009 18.9903 2.40009C18.9303 2.14509 18.7503 1.95009 18.5103 1.86009C14.3403 0.36009 10.5003 3.52509 10.5003 7.48509C10.5003 7.99509 10.5753 8.50509 10.7253 9.03009L2.38531 17.3701C1.21531 18.5401 1.21531 20.4451 2.38531 21.6151C2.97031 22.2001 3.73531 22.4851 4.50031 22.4851C5.26531 22.4851 6.03031 22.2001 6.61531 21.6151L14.9553 13.2751C18.9003 14.3701 22.4853 11.3101 22.4853 7.50009C22.4853 6.85509 22.3653 6.21009 22.1253 5.52009H22.1403ZM16.5003 12.0001C16.0203 12.0001 15.5253 11.9101 15.0003 11.7301C14.7303 11.6401 14.4303 11.7001 14.2203 11.9101L5.56531 20.5651C4.98031 21.1501 4.03531 21.1501 3.45031 20.5651C2.86531 19.9801 2.86531 19.0351 3.45031 18.4501L12.1053 9.79509C12.3153 9.58509 12.3753 9.28509 12.2853 9.01509C12.1053 8.49009 12.0003 7.99509 12.0003 7.51509C12.0003 4.95009 14.2053 2.85009 16.7703 3.03009L14.5503 5.25009C14.3553 5.44509 14.2803 5.71509 14.3553 5.97009L15.0303 8.47509C15.1053 8.73009 15.3003 8.94009 15.5553 9.00009L18.0603 9.67509C18.3153 9.75009 18.6003 9.67509 18.7803 9.48009L20.9853 7.27509C20.9853 7.35009 20.9853 7.42509 20.9853 7.50009C20.9853 9.97509 18.9603 12.0001 16.4853 12.0001H16.5003Z" />
            </svg>
        </i>
        Voorkeuren</h6>
    </div>
    <div class="step-item " [ngClass]="{'active-step': activeStep == 4,'completed-step': isCompleted(4)}" >
      <h6 class="step-name font-kpn-extended kpn-extended-bold">
        <i>
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.75 1.5H5.25C3.18 1.5 1.5 3.18 1.5 5.25V18.75C1.5 20.82 3.18 22.5 5.25 22.5H18.75C20.82 22.5 22.5 20.82 22.5 18.75V5.25C22.5 3.18 20.82 1.5 18.75 1.5ZM21 18.75C21 19.995 19.995 21 18.75 21H5.25C4.005 21 3 19.995 3 18.75V5.25C3 4.005 4.005 3 5.25 3H18.75C19.995 3 21 4.005 21 5.25V18.75ZM18 6.75C18 7.17 17.67 7.5 17.25 7.5H6.75C6.33 7.5 6 7.17 6 6.75C6 6.33 6.33 6 6.75 6H17.25C17.67 6 18 6.33 18 6.75ZM18 11.25C18 11.67 17.67 12 17.25 12H6.75C6.33 12 6 11.67 6 11.25C6 10.83 6.33 10.5 6.75 10.5H17.25C17.67 10.5 18 10.83 18 11.25ZM12.75 15.75C12.75 16.17 12.42 16.5 12 16.5H6.75C6.33 16.5 6 16.17 6 15.75C6 15.33 6.33 15 6.75 15H12C12.42 15 12.75 15.33 12.75 15.75Z"/>
          </svg>
        </i>
        Samenvatting</h6>
    </div>
    <div class="step-item " [ngClass]="{'active-step': activeStep == 5,'completed-step': isCompleted(5)}" >
      <h6 class="step-name font-kpn-extended kpn-extended-bold">
        <i>
          <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M17.8808 1.15503L7.77085 17.295C7.48585 17.745 7.02085 18 6.51085 18C5.98585 18 5.52085 17.715 5.23585 17.265L0.105849 8.71503C-0.104151 8.35503 0.0158492 7.90503 0.360849 7.68003C0.720849 7.47003 1.17085 7.57503 1.39585 7.93503L6.52585 16.485L16.6208 0.345028C16.8458 2.80077e-05 17.3108 -0.104972 17.6558 0.105028C18.0008 0.330028 18.1208 0.795028 17.8958 1.14003L17.8808 1.15503Z" />
            </svg>

        </i>
        Bedankt</h6>
    </div>
  </div>
</div>
