import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { CartService } from 'src/app/pages/cart/cart.service';
import { ProductListService } from 'src/app/pages/product-list/product-list.service';
import { selectCatalog } from 'src/app/store/selectors/catalog.selectors';
import { StorageService } from '../../storage.service';

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit, OnChanges, OnDestroy {

  catalog = null;
  group = null;
  public catalogSubs$: Subscription = null;
  private catalogs = [];


  @Input() catalogCode = '';
  @Input() groupCode = '';
  @Input() showTitle = false;
  @Input() layout = '';

  constructor(
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private storageService: StorageService,
    private productListService: ProductListService,
    private cartService: CartService,
    private router: Router,
    public store: Store<IAppState>,
  ) { }

  ngOnInit(): void {
    this.getCataLogList();
  }

  ngOnDestroy(): void {
    this.catalogSubs$?.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    this.catalogCode = changes?.catalogCode?.currentValue || this.catalogCode;
    if (!this.catalogCode) this.catalog = null;

    this.groupCode = changes?.groupCode?.currentValue || this.groupCode;
    if (!this.groupCode) this.group = null;

    if (changes?.catalogCode?.currentValue || changes?.groupCode?.currentValue) {
      this.findCatalog();
    }
  }

  getCataLogList() {
    this.catalogSubs$ = this.store.select(selectCatalog).subscribe(catalogs => {
      this.catalogs = catalogs;
      this.findCatalog();
    });
  }


  findCatalog() {
    this.catalog = this.catalogs?.find(catalog => catalog?.code === this.catalogCode) || null;
    if (this.catalog) {
      this.group = this.catalog?.product_groups?.find(item => item?.code === this.groupCode) || null;
      if (!this.group) {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.catalog?.product_groups.length; index++) {
          const element = this.catalog?.product_groups?.[index];
          const grp = this.findGroup(this.groupCode, element?.child_groups);
          if (grp) {
            this.group = grp;
            break;
          }
        }
      }
    }
  }


  findGroup(code, arr: any[]) {
    return arr.reduce((acc, item) => {
      if (acc) {
        return acc;
      }
      if (item.code === code) {
        return item;
      }
      if (item.child_groups?.length) {
        return this.findGroup(this.groupCode, item.child_groups);
      }
    }, null);
  }

  gotoGroupCode() {
    this.router.navigate(['product-list'], {
      queryParams: {
        catalogCode: this.catalogCode,
        groupCode: this.catalog?.product_groups?.find(item => true)?.code || ''
      },
      queryParamsHandling: 'merge'
    });
  }

}
