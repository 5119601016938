<div class="body-container">
  <section class="new-product-list pt-5" *ngIf="pageLayout == 'compact'">
    <div class="container">
      <app-breadcrumb [catalogCode]="catalogCode" [groupCode]="groupCode" [layout]="pageLayout"></app-breadcrumb>
    </div>
    <div class="section-loader home-new-section-loader" *ngIf="isLoading; else productNewView">
      <div class="kpn-spinner">
        <img src="/assets/images/kpn-loader.gif" alt="Loading" />
      </div>
    </div>
    <ng-template #productNewView>
      <div class="container">
        <div class="new-product-list-box">
          <!-- <div class="new-carttop-info-bar d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE91D"></span>
              1x Afsluiter 34 mm in uw winkelwagen
            </p>
            <button class="kphicon-uniE926"></button>
          </div> -->
          <div class="search-section-new mb-4">
            <div class="row justify-content-between" *ngIf="searchKeyword">
              <div class="col-md-5">
                <h4> {{'PRODUCT_LIST.SEARCH_ON' | transloco }}: {{ searchKeyword }}</h4>
              </div>
            </div>
            <div class="row justify-content-between">
              <div class="col-md-5">
                <div class="new-search-box">
                  <input class="form-control" [(ngModel)]="internalSearch"
                    [placeholder]="'CUSTOMERS.SEARCH' | transloco" type="text" (keyup.enter)="onSearchProduct()" name=""
                    id="" />
                  <button [disabled]="!internalSearch?.trim()" (click)="onClearSearch()"
                    class="search-btn-new kpn-ui-icon ui-close"></button>
                  <button [disabled]="!internalSearch?.trim()" (click)="onSearchProduct()"
                    class="search-btn-new kpn-ui-icon ui-search"></button>
                  <button *ngIf="
                      internalSearch?.trim() ||
                      searchKeyword?.trim() ||
                      isInternalSearched
                    " (click)="onClearSearch(true)" class="search-btn-new">
                    {{'PRODUCT_LIST.RESET_BUTTON' | transloco }}
                  </button>
                </div>
              </div>
              <div class="col-md-auto pl-md-0">
                <div class="d-flex">
                  <button [disabled]="!this.cart" class="btn btn-primary rounded-btn d-flex align-items-center"
                    (click)="showDrawer()">
                    <span class="spinner-border spinner-border-sm mr-2" *ngIf="bulkAddLoading" role="status"
                      aria-hidden="true"></span>
                    <i class="kphicon-uniE9A0 icon-size-md mr-2"></i>
                    <!-- Toevoegen aan winkelmand -->
                    {{'PRODUCT_LIST.VIEW_IN_THE_SHOPPING_CART' | transloco }}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="product-table">
            <table class="table">
              <thead>
                <tr>
                  <th></th>
                  <th>
                    <div class="sortable-th" (click)="sortProduct('code', codeSort)">
                      {{'PRODUCT_LIST.ITEM_CODE' | transloco }}
                      <span>
                        <i class="kphicon-uniE9A4" [ngClass]="{ 'sorted-by': codeSort }"></i>
                        <i class="kphicon-uniE9A6" [ngClass]="{ 'sorted-by': !codeSort }"></i>
                      </span>
                    </div>
                  </th>
                  <th>
                    <div class="sortable-th" (click)="sortProduct('name', nameSort)">
                      {{'PRODUCT_LIST.PRODUCT_NAME' | transloco }}
                      <span>
                        <i class="kphicon-uniE9A4" [ngClass]="{ 'sorted-by': nameSort }"></i>
                        <i class="kphicon-uniE9A6" [ngClass]="{ 'sorted-by': !nameSort }"></i>
                      </span>
                    </div>
                  </th>
                  <th class="text-left">
                    <div class="sortable-th" (click)="sortProduct(utilityService.isInclVAT() ? 'sales_price' : 'sales_price_ex_vat', priceSort)">
                      {{'PRODUCT_LIST.PRICE' | transloco }}
                      <span>
                        <i class="kphicon-uniE9A4" [ngClass]="{ 'sorted-by': priceSort }"></i>
                        <i class="kphicon-uniE9A6" [ngClass]="{ 'sorted-by': !priceSort }"></i>
                      </span>
                    </div>
                  </th>

                  <th width="100" class="text-center">{{'PRODUCT_LIST.NUMBER' | transloco}}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="item-selected" *ngFor="
                    let product of productList | paginate : config;
                    let i = index
                  ">
                  <td>
                    <button ngbTooltip="Toevoegen aan favoriet"
                      *ngIf="!product?.is_favorite && wishlistPermission.add" type="button"
                      (click)="addToFavorite(product?.code)" class="btn btn-sm btn-link">
                      <i class="kphicon-uniE9A8 icon-size-md mr-2" style="color: #67a9a6"></i>
                    </button>
                    <button *ngIf="product?.is_favorite && wishlistPermission.delete"
                      ngbTooltip="Verwijderen uit favoriet" type="button"
                      (click)="removeFromFavorite(product?.code)" class="btn btn-sm btn-link">
                      <i class="kphicon-uniE9A7 icon-size-md mr-2 favorite-item-logo"></i>
                    </button>
                  </td>
                  <td>{{ product.code }}</td>
                  <td>{{ product.name }}</td>
                  <td class="text-left">
                    <span *ngIf="!utilityService.isInclVAT() && product?.sales_price_ex_vat > 0
                      ">
                      {{  product?.sales_price_ex_vat | currencyFormat }}
                    </span>
                    <span *ngIf="utilityService.isInclVAT() &&
                        product?.sales_price > 0
                      ">
                      {{  product?.sales_price | currencyFormat }}
                    </span>
                  </td>
                  <td>
                    <div class="loader-wrap center-align" *ngIf="product?.showSpinner; else addToCartNew">
                      <div class="spinner-border" style="width: 2.2rem; height: 2.2rem" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <ng-template #addToCartNew>
                      <div class="item-add-remove-product">
                        <div class="d-flex align-items-center justify-content-center">
                          <button class="kphicon-uniE972 add-remove-btn dicrement-btn" tabindex="-1"
                            [disabled]="product.cartQuantity <= 0" (click)="decrementQuantity(product)"></button>
                          <div class="add-remove-input">
                            <input appPositiveInteger type="number" [(ngModel)]="product.cartQuantity"
                              (ngModelChange)="changeQuantity($event, product)" min="1" (keyup.enter)="onEnter(product)"
                              (keydown.tab)="onEnter(product)" (focusout)="onFocusout(product)" tabindex="0" />
                          </div>
                          <button class="kphicon-uniE985 add-remove-btn incriment-btn" tabindex="-1"
                            (click)="incrementQuantity(product)"></button>
                        </div>
                      </div>
                    </ng-template>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="pagination-new-inlist pt-2">
            <div class="row justify-content-between">
              <div class="col-md-auto">
                <p class="product-in-list">{{ totalCount }} {{'PRODUCT_LIST.PRODUCTS' | transloco }}</p>
              </div>
              <div class="col-md-auto">
                <div class="pagination-new pt-0" *ngIf="showPagination">
                  <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}"
                    nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                    (pageChange)="onPageClick($event)"></pagination-controls>
                </div>
              </div>
              <div class="col-md-auto">
                <label for="" class="m-0">{{'PRODUCT_LIST.ROWS_PER_PAGE' | transloco }} : </label>
                <select name="" id="" [(ngModel)]="pageSize" (ngModelChange)="onPageRangeChange()"
                  class="flat-select-option">
                  <option value="10">10</option>
                  <option value="15">15</option>
                  <option value="20">20</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </section>
  <section class="pt-5" *ngIf="pageLayout != 'compact'">
    <div class="container">
      <app-breadcrumb [catalogCode]="catalogCode" [groupCode]="groupCode" [showTitle]="'true'" [layout]="''"
        *ngIf="catalogCode || groupCode"></app-breadcrumb> 

      <!-- <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
        <li class="back-breadcrumb-btn">
          <a href="">
            <i class="kphicon-uniE906"></i> Terug
          </a>
        </li>
        <li class="home-btn ml-2 px-1 pb-1">
          <a href="">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd" d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z" fill="#939393"/>
                </g>
            </svg>
          </a>
        </li>
        <li><a href="">{{catalogCode | titlecase}}</a></li>
        <li><a href="">{{group?.name}}</a></li>
      </ul> -->


      <!-- <div class="row pt-3 mb-4 advanced-filter" *ngIf="!global">
        <div class="col-md-auto pr-md-1" *ngIf="brandList?.length">
          <div ngbDropdown #vDropBrand="ngbDropdown" on-mouseover="$event.stopPropagation(); vDropBrand.open()"
            on-mouseleave="$event.stopPropagation(); vDropBrand.close()"
            class="kpn-custom-dropdown custom-dropdown-filter dropdown-init-fix">
            <button class="btn btn-block text-left btn-toggler pl-3" id="barndDropdown" ngbDropdownToggle>
              <span class="dropdown-placeholder">{{'PRODUCT_LIST.BRAND' | transloco }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="barndDropdown" class="kpn-custom-dropdown-list">
              <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
                <label ngbButtonLabel class="checkbox-item d-flex align-items-center" *ngFor="let brand of brandList">
                  <div class="checkbox-btn checkbox-btn-outline">
                    <input ngbButton type="checkbox" [disabled]="isLoading || !!searchKeyword" [value]="brand.code"
                      [(ngModel)]="brand.selected" (change)="onSelectBrand($event)" />
                    <span class="checkBoxCheckmark"></span>
                  </div>
                  <div class="checkbox-label ml-2">
                    <span>
                      <small> {{ brand?.name }} </small></span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-auto pl-md-1 pr-md-1" *ngFor="let item of attrValue">
          <div ngbDropdown #vDropColor="ngbDropdown" on-mouseover="$event.stopPropagation(); vDropColor.open()"
            on-mouseleave="$event.stopPropagation(); vDropColor.close()"
            class="kpn-custom-dropdown custom-dropdown-filter dropdown-init-fix" *ngIf="item?.attrValue?.length">
            <button class="btn btn-block text-left btn-toggler pl-3" id="colorDropdown" ngbDropdownToggle>
              <span class="dropdown-placeholder">{{ item?.attrName }}</span>
            </button>
            <div ngbDropdownMenu aria-labelledby="colorDropdown" class="kpn-custom-dropdown-list">
              <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
                <label ngbButtonLabel class="checkbox-item d-flex align-items-center"
                  *ngFor="let list of item?.attrValue; let i=index" for="{{item?.attrCode}}_{{list?.value}}_{{i}}"
                  [ngClass]="{ 'active': list?.selected  }">
                  <div class="checkbox-btn checkbox-btn-outline">
                    <input ngbButton id="{{item?.attrCode}}_{{list?.value}}_{{i}}" type="checkbox"
                      [disabled]="isLoading || !!searchKeyword" [value]="list.value" [(ngModel)]="list.selected"
                      (change)="
                        onselectAttribute(
                          $event,
                          item?.attrCode,
                          item?.attrName,
                          item?.attrType
                        )
                      " />
                    <span class="checkBoxCheckmark"></span>
                  </div>
                  <div class="checkbox-label ml-2">
                    <span>
                      <small>
                        {{ list?.displayName}}
                      </small></span>
                  </div>
                </label>
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Product Category Button -->
      <div class="d-flex mb-4 pt-2 flex-wrap" *ngIf="subgroups">
        <button class="btn btn-light ctg-btn-overview font-kpn-extended px-3 mr-2 mb-2"
          [ngClass]="{selected: child_group.code === groupCode}" *ngFor="let child_group of subgroups?.child_groups"
          (click)="onClickSubGroup(child_group)">
          <!-- <img src="/assets/images/filters/mobile-filter.png" alt=""> -->
          {{child_group?.name}}
        </button>
        <!-- <button class="btn btn-light ctg-btn-overview px-3 mr-2 mb-2">
          <img src="../../../assets/images/tablet-filter.png" alt="">
          Tablet
        </button>
        <button class="btn btn-light ctg-btn-overview px-3 mr-2 mb-2">
          <img src="../../../assets/images/wearables-filter.png" alt="">
          Wearables
        </button>
        <button class="btn btn-light ctg-btn-overview px-3 mr-2 mb-2">
          <img src="../../../assets/images/mobile-accessories.png" alt="">
          Mobiele accessoires
        </button>
        <button class="btn btn-light ctg-btn-overview px-3 mr-2 mb-2">
          Overig
        </button> -->
      </div>
      <div class="row">
        <!-- New Filter  -->
        <div class="col-md-auto" *ngIf="!global && (attrValue?.length || brandList?.length)">
          <div class="product-filter-sidebar pt-2">
            <div class="product-filtered-options">

              <span class="tri-selected-filter mr-2 mb-2 font-kpn-extended" *ngFor="let item of selectedBrands; let i = index">
                {{ item }}
                  <button class="kphicon-uniE926 filter-close" (click)="removeBrandTag(item, i)"
                  [disabled]="isLoading"></button>
              </span>
              <span class="tri-selected-filter mr-2 mb-2 font-kpn-extended" *ngFor="let item of selectedAttr; let i = index">
                {{ item?.display}}
                <button class="kphicon-uniE926 filter-close" (click)="removeAttrTag(item, i)"
                  [disabled]="isLoading"></button>
              </span>
              <span class="tri-selected-filter mr-2 mb-2 font-kpn-extended" *ngIf="searchKeyword">
                {{ searchKeyword }}
                <button class="kphicon-uniE926 filter-close" (click)="removeProductSearchTag()"
                  [disabled]="isLoading"></button>
              </span>
            </div>
            <div class="text-right " (click)="clearFilter()" *ngIf="selectedBrands?.length || selectedAttr?.length">
              <button class="filter-clear-new small mb-0"> {{'PRODUCT_LIST.REMOVE_FILTERS' | transloco }} </button>
              <hr class="mb-3">
            </div>
            <div class="filter-accordion">
              <div ngbAccordion #accordion="ngbAccordion" *ngIf="brandList?.length">
                <div ngbAccordionItem="first" [collapsed]="false">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="d-flex align-items-center justify-content-between btn p-0 w-100">
                      <span class="faq-ac-title">{{'PRODUCT_LIST.BRAND' | transloco }}</span>
                      <i class="kpn-ui-icon ui-chevron-up ac-arrow"></i>
                    </button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <ul class="check-filter">
                          <li *ngFor="let brand of brandList">
                            <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products"
                              ngbRadioGroup name="multiselect-items">
                              <label ngbButtonLabel class="checkbox-item d-flex align-items-center">
                                <div class="checkbox-btn checkbox-btn-outline">
                                  <input ngbButton type="checkbox" class="position-absolute opacity-0"
                                    [disabled]="isLoading || !!searchKeyword" [value]="brand.code"
                                    [(ngModel)]="brand.selected" (change)="onSelectBrand($event)">
                                  <span class="checkBoxCheckmark"></span>
                                </div>
                                <div
                                  class="checkbox-label font-kpn-extended ml-2 pl-1 d-flex align-items-center justify-content-between w-100">
                                  {{brand?.name}}
                                  <span class="selected-price">{{brand?.count}}</span>
                                </div>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div ngbAccordion #accordion="ngbAccordion" *ngFor="let item of attrValue; let i=index">
                <div ngbAccordionItem="attr_{{i}}" [collapsed]="false" *ngIf="item?.attrValue?.length">
                  <h2 ngbAccordionHeader>
                    <button ngbAccordionButton class="d-flex align-items-center justify-content-between btn p-0 w-100">
                      <span class="faq-ac-title">{{item?.attrName }}</span>
                      <i class="kpn-ui-icon ui-chevron-up ac-arrow"></i>
                    </button>
                  </h2>
                  <div ngbAccordionCollapse>
                    <div ngbAccordionBody>
                      <ng-template>
                        <ul class="check-filter">
                          <li *ngFor="let list of item?.attrValue; let i=index"
                            [ngClass]="{ 'active': list?.selected  }">
                            <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products"
                              ngbRadioGroup name="multiselect-items">
                              <label ngbButtonLabel class="checkbox-item d-flex align-items-center"
                                for="{{item?.attrCode}}_{{list?.value}}_{{i}}">
                                <div class="checkbox-btn checkbox-btn-outline">
                                  <input ngbButton type="checkbox" class="position-absolute opacity-0"
                                    id="{{item?.attrCode}}_{{list?.value}}_{{i}}" type="checkbox"
                                    [disabled]="isLoading || !!searchKeyword" [value]="list.value"
                                    [(ngModel)]="list.selected" (change)="
                                    onselectAttribute(
                                      $event,
                                      item?.attrCode,
                                      item?.attrName,
                                      item?.attrType
                                    )
                                  ">
                                  <span class="checkBoxCheckmark"></span>
                                </div>
                                <div
                                  class="checkbox-label font-kpn-extended ml-2 pl-1 d-flex align-items-center justify-content-between w-100">
                                  {{ list?.displayName}}
                                  <span class="selected-price">{{list?.count}}</span>
                                </div>
                              </label>
                            </div>
                          </li>
                        </ul>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md">
          <h3 class="item-count-title mb-2 pt-1" *ngIf="!isLoading">
            <span class="font-kpn-extended">
              <span class="font-weight-bold">{{ totalCount }}</span>
              <span *ngIf="!global" class="font-weight-normal"> {{'PRODUCT_LIST.FILTER_RESULT' | transloco}}</span>
              <span *ngIf="global" class="font-weight-normal"> {{ searchKeyword }} </span>
            </span>
          </h3>
          <!-- <div class="row align-items-center">
            <div class="col-md">
              <div class="filtered-options">
                <div class="filter-option" *ngFor="let item of selectedBrands; let i = index">
                  {{ item }}
                  <button class="kphicon-uniE926" (click)="removeBrandTag(item, i)" [disabled]="isLoading"></button>
                </div>
                <div class="filter-option" *ngFor="let item of selectedAttr; let i = index">
                  {{ item?.display}}
                  <button class="kphicon-uniE926" (click)="removeAttrTag(item, i)" [disabled]="isLoading"></button>
                </div>
                <div class="filter-option" *ngIf="searchKeyword">
                  {{ searchKeyword }}
                  <button class="kphicon-uniE926" (click)="removeProductSearchTag()" [disabled]="isLoading"></button>
                </div>
              </div>
            </div>
            <div class="col-md-auto" (click)="clearFilter()" *ngIf="selectedBrands?.length || selectedAttr?.length">
              <button class="filter-clear-new"> {{'PRODUCT_LIST.REMOVE_FILTERS' | transloco }} </button>
            </div>
          </div> -->
          <div class="section-loader home-new-section-loader" *ngIf="isLoading; else productView">
            <div class="kpn-spinner">
              <img src="/assets/images/kpn-loader.gif" alt="Loading" />
            </div>
          </div>
          <ng-template #productView>
            <div class="product-section-new">
              <div class="row" *ngIf="pageLayout != 'compact'; else easyOrder">
                <div class="col-md-4" *ngFor="
                    let product of productList | paginate : config;
                    let i = index
                  ">
                  <div class="product-box-new">
                    <div class="product-img-new">
                      <div class="size-items-new">
                        <span class="size-item msize-auto" *ngFor="let attr of product?.memory_size">
                          {{ attr }}
                        </span>
                      </div>
                      <a (click)="onProductDetail(product, i)">
                        <span *ngIf="
                            product?.selectedImg || product?.image || product?.document_image;
                            else noImg
                          ">
                          <img src="{{ product?.selectedImg }}" alt="{{ product?.name }}"
                            *ngIf="product?.selectedImg || product?.document_image" />
                        </span>
                        <ng-template #noImg>
                          <img src="/assets/images/image-not-available.png" alt="{{ product.name }}" />
                        </ng-template>
                      </a>
                      <div class="product-brand-logo" *ngIf="product?.logos?.length">
                        <img *ngFor="let logo of product?.logos" [src]="logo?.logo" alt="">
                      </div>
                      <div class="color-items-new">
                        <span class="color-item" *ngFor="let attr of product?.uniqueColorChildProducts"
                          [ngStyle]="{ 'background-color': attr?.color_code }"
                          [ngClass]="{ 'selected-color': attr?.selected,  'disable-link' : !attr?.selectable }"
                          (click)="changeImage(product, product?.uniqueColorChildProducts, attr)">
                        </span>
                      </div>
                      <span class="floating-tag" *ngIf="product?.pre_order">
                        {{'PRODUCT_LIST.PRE_ORDER' | transloco}}
                      </span>
                    </div>
                    <div class="product-new-content">
                      <div class="row">
                        <!-- <div class="col-md-12 col-12 px-2 col-gap-reduce" *ngIf="product?.specification">
                          <div
                            class="info-bar-blue tiles-info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                            <p>
                              <span class="kphicon-uniE952"></span>
                              <small> {{product?.specification}} </small>
                            </p>
                          </div>
                        </div> -->
                        <div class="col-12 pl-2">
                          <p class="item-title">{{ product?.brand?.name }} &nbsp;</p>
                          <h2 class="item-att" (click)="onProductDetail(product, i)" [ngbTooltip]="product?.name">
                            <span *ngIf="!product?.child_products?.length">
                            </span>
                            <span>{{ product?.name | textTrim : "34" }}</span>
                          </h2>
                        </div>
                        <div class="col-12 pl-2 mb-2">
                          <div class="d-flex flex-nowrap justify-content-between align-items-center">
                            <div class="pr-1">
                              <h5 class="item-ac-price mb-0">
                                {{ (utilityService.isInclVAT() ? product?.sales_price : product?.sales_price_ex_vat) | currencyFormat }}
                              </h5>
                              <div  *ngFor="let attr of product?.uniqueColorChildProducts">
                                <div *ngIf="attr?.selected">
                                  <span *ngIf="attr?.stock" class="green-text fs-12 d-block line-height-14">Op voorraad</span>
                                  <span *ngIf="!attr?.stock"  class="orange-text fs-12 d-block line-height-14">Levertijd onbekend</span>
                                </div>
                              </div>
                              <div *ngIf="!product?.uniqueColorChildProducts?.length">
                                <span *ngIf="product?.stock" class="green-text fs-12 d-block line-height-14">Op voorraad</span>
                                <span *ngIf="!product?.stock"  class="orange-text fs-12 d-block line-height-14">Levertijd onbekend</span>
                              </div>
                            </div>
                            <div class="pl-1">
                              <button class="btn px-4 btn-h-40 btn-primary rounded-btn" (click)="onProductDetail(product, i)">
                                <span class="d-block px-2">{{'PRODUCT_LIST.CHOOSE' | transloco }}</span>
                              </button>
                            </div>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #easyOrder>
                <div class="bg-kpn-light pl-4 box-shadow-sm">
                  <div class="table-responsive custom-table">
                    <table class="table">
                      <thead>
                        <tr>
                          <th>Code</th>
                          <th>{{'PRODUCT_LIST.NAME' | transloco }}</th>
                          <th class="text-right">{{'PRODUCT_LIST.PRICE' | transloco }}</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="
                            let product of productList | paginate : config;
                            let i = index
                          ">
                          <td>{{ product.code }}</td>
                          <td>{{ product.name }}</td>
                          <td class="text-right">
                            <span *ngIf="!utilityService.isInclVAT() && product?.sales_price_ex_vat > 0 ">
                              {{  product?.sales_price_ex_vat | currencyFormat }}
                            </span>
                            <span *ngIf="utilityService.isInclVAT() &&  product?.sales_price > 0 ">
                              {{  product?.sales_price | currencyFormat }}
                            </span>
                          </td>
                          <td class="text-center">
                            <div class="loader-wrap" *ngIf="product?.showSpinner; else addToCart">
                              <div class="spinner-border" style="width: 2.2rem; height: 2.2rem" role="status">
                                <span class="sr-only">Loading...</span>
                              </div>
                            </div>
                            <ng-template #addToCart>
                              <div
                                class="item-update-btn item-update-btn-table d-inline-flex mb-0 qty-update-btn-in-cart"
                                style="cursor: pointer" *ngIf="product?.cartQuantity">
                                <div class="item-add-remove">
                                  <button class="kphicon-uniE972 add-remove-btn dicrement-btn"
                                    (click)="decrementQuantity(product)"></button>
                                  <div class="add-remove-input">
                                    <input appPositiveInteger type="number" [(ngModel)]="product.cartQuantity"
                                      (ngModelChange)="
                                        changeQuantity($event, product)
                                      " min="1" (keyup.enter)="onEnter(product)" (focusout)="onFocusout(product)"
                                      (keydown.tab)="onEnter(product)" tabindex="0" />
                                  </div>
                                  <button class="kphicon-uniE985 add-remove-btn incriment-btn"
                                    (click)="incrementQuantity(product)"></button>
                                </div>
                              </div>
                              <button
                                class="btn btn-outline-primary outline-icon-btn rounded-btn add-to-cart-btn no-wrap"
                                (click)="onAddToCart(product)" *ngIf="!product?.cartQuantity">
                                <i class="kpn-ui-icon ui-shoppingbasket"></i>
                                {{'PRODUCT_LIST.ADD_TO_CART' | transloco }}
                              </button>
                            </ng-template>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </ng-template>
              <div class="pagination-new mb-5" *ngIf="showPagination">
                <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}"
                  nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                  (pageChange)="onPageClick($event)"></pagination-controls>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>
