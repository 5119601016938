import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { NgbModalConfig, NgbModal, NgbModalRef, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { IAppState } from 'src/app/app.state';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { EMobileFlowTypes, ExportId } from 'src/app/shared/enums/flow-type.enums';
import { EMarketTypes } from 'src/app/shared/enums/market-type.enums';
import { EProductTypes, ESupplierTypes } from 'src/app/shared/enums/product-type.enums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { StorageService } from 'src/app/shared/storage.service';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { CartService } from '../cart/cart.service';
import { CustomerService } from '../customer/customer.service';
import { FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EMessages } from 'src/app/shared/enums/messages.enums';
import { AccountTypeService } from '../account-type/account-type.service';
import { TranslocoService } from '@ngneat/transloco';
import { concatMap, scan, skip } from 'rxjs/operators';
import * as moment from 'moment';
import { forkJoin, from } from 'rxjs';


enum OperationType {
  Add = 'Add',
  Remove = 'Remove'
};
@Component({
  selector: 'app-order-overview',
  templateUrl: './order-overview.component.html',
  styleUrls: ['./order-overview.component.scss']
})
export class OrderOverviewComponent extends BasePermission implements OnInit {
  cartUuid = null;
  public enrichedCart = null;
  public cart = null;
  public contactPerson = null;
  isExternal = false;
  isLoading = false;
  shouldShowSpinner = false;
  referenceSpinner = false;
  shippingAddress = null;
  contactAddress = null;
  errorFound = false;
  poNumber = null;
  customer_reference = null;
  isOffer = false;
  addedVouchers = [];
  public productType = [
    EProductTypes.HANDSET,
    EProductTypes.TABLET
  ];

  isDeliveryOptionVisible = true;
  selectedDeliveryOption = null;
  deliveryOptions = [];
  public siteProfile = null;
  paymentOptions: any[] = [];
  public orderError = null;
  public restrictExportId = ExportId;
  public termAndConditions: any[] = [];
  public termAccepted = false;
  public quoteCreateMessage = '';
  public isQuoteSelected = this.storageService.isQuoteSelected();
  public hasCpaas = null;
  public cPaasForm: UntypedFormGroup;
  public cPassAddLoader = false;
  public cPassAddError = null;
  public cPassAdded = false;
  public accountTypes = [];
  public contactAccountTypesCustomers = [];
  public selectedContactAccountTypesCustomer = null;
  public contactAccountTypeCustomersLoading = false;
  public hasExistingSelectedContactAccountTypesCustomer = false;
  public user = null;
  private langChanges$ = null;
  public special_discount_reason = "";
  public special_discount_requested = false;
  public selectedPaymentOption: string;
  public termsAndConditionsLoading = false;
  public additionalCostLies = [];
  selectedIdealIssuer = 'Kies je bank';
  public issuerList = [

    {
      name: 'ABN AMRO Bank',
      value: 'ABNANL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'ING',
      value: 'INGBNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Rabobank',
      value: 'RABONL2U',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'SNS Bank',
      value: 'SNSBNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'ASN Bank',
      value: 'ASNBNL21',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Knab',
      value: 'KNABNL2H',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'RegioBank',
      value: 'RBRBNL21',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Triodos Bank',
      value: 'TRIONL2U',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Van Lanschot Bankiers',
      value: 'FVLBNL22',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Bunq',
      value: 'BUNQNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Revolut',
      value: 'REVOLT21',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Yoursafe',
      value: 'BITSNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'N26',
      value: 'NTSBDEB1',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Nationale-Nederlanden',
      value: 'NNBANL2G',
      group: 'Nederland',
      is_default: false
    },
  ].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
  public requestedDateMin = null;
  public requested_delivery_date = '';

  constructor(
    public cartService: CartService,
    private route: ActivatedRoute,
    private router: Router,
    private customerService: CustomerService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    public utilityService: UtilityService,
    public store: Store<IAppState>,
    private fb: UntypedFormBuilder,
    private accountTypeService: AccountTypeService,
    private translocoService: TranslocoService

  ) {
    super(store);
    this.user = this.storageService.getCurrentUser();
    this.requestedDateMin = this.utilityService.getNextDay(new Date());
    this.cartUuid = this.route.snapshot.params.cartUuid;
    this.router.events.forEach((event) => {
      if (event instanceof NavigationStart) {
        if (event.navigationTrigger === 'popstate') {
          this.onBack();
        }
      }
    });

    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });

    // subscribing to language change event and skipping the inistail subsription calls
    this.langChanges$ = translocoService.langChanges$.pipe(skip(1)).subscribe((lang) => {
      this.getTermAndConditions();
    });
  }

  get eMarketTypes() { return EMarketTypes; }
  get eProductTypes() { return EProductTypes; }
  get eMobileFlowTypes() { return EMobileFlowTypes; }
  get eMessages() { return EMessages; }
  get isAccountTypeClientSelected() {
    if (this.hasViewContactAccountTypeCustomer && this.cart?.is_contact_account_type_required && this.contactAccountTypesCustomers?.length) {
      return this.selectedContactAccountTypesCustomer ? false : true;
    }
    else {
      return false;
    }
  }
  get hasSelectedContactAccountTypesCustomer() {
    return this.selectedContactAccountTypesCustomer ? true : false;
  }


  ngOnInit() {
    this.router.events.subscribe(x => {
      if (x instanceof NavigationEnd) {
        window.scrollTo(0, 0);
      }
    });
    this.getDeliveryOptions();
    const hasUser = this.storageService.getCurrentUser();
    if (hasUser) {
      this.isExternal = hasUser.is_external || false;
    }
    if (this.cartUuid) {
      this.getCart();
    }
  }

  // addCpassData() {
  //   this.cPassAddError = null;
  //   if (!this.cPaasForm.valid) {
  //     return;
  //   }
  //   this.cPassAddLoader = true;
  //   let payload = {
  //     quantity: this.hasCpaas?.quantity,
  //     extra: {
  //       ...this.cPaasForm.value
  //     }
  //   }
  //   this.cartService.updateCartLine(this.cart?.uuid, this.hasCpaas?.uuid, payload).subscribe(response => {
  //     this.formatCartData(response?.data);
  //     this.cPassAddLoader = false;
  //     this.showSuccessToaster(`${this.translocoService.translate('ORDER_OVERVIEW.CPASS_DATA_ADDED_SUCCESSFULLY')}`);
  //   }, error => {
  //     this.cPassAddLoader = false;
  //     this.cPassAddError = error?.error?.message || `${this.translocoService.translate('ORDER_OVERVIEW.CANNOT_ADD_CPASS_DATA')}`;
  //     this.showErrorToaster(this.cPassAddError);
  //   })
  // }

  addCpassData() {
    this.cPassAddError = null;
    if (!this.cPaasForm.valid) {
      return;
    }
    this.cPassAddLoader = true;
    let payload = {
      // quantity: this.hasCpaas?.quantity,
      extra: {
        ...this.cPaasForm.value
      }
    }
    const lines = this.cart?.lines?.filter(line => {
      if (line?.supplier_export_id === ESupplierTypes.CPAAS) {
        return {
          ...line,
        };
      }
    });
    from(lines).pipe(
      concatMap((line: any) => {
        return this.cartService.updateCartLine(this.cart?.uuid, line?.uuid, {
          ...payload,
          quantity: line?.quantity
        }).pipe(
          // Map each response to the line and response for further processing
          scan((acc: any[], response) => [...acc, response], [])
        );
      }),

    ).subscribe((response: any[]) => {
      this.formatCartData(response?.[response?.length - 1]?.data);
    }, error => {
      this.cPassAddLoader = false;
      this.cPassAddError = error?.error?.message || `${this.translocoService.translate('ORDER_OVERVIEW.CANNOT_ADD_CPASS_DATA')}`;
      this.showErrorToaster(this.cPassAddError);
    }, () => {
      this.cPassAddLoader = false;
      this.showSuccessToaster(`${this.translocoService.translate('ORDER_OVERVIEW.CPASS_DATA_ADDED_SUCCESSFULLY')}`);
      // Code to execute when all requests have completed
    })
  }

  private initiateCpaasForm(initValue = {
    cpaas_user_email: null,
    cpaas_user_first_name: null,
    cpaas_user_last_name: null
  }) {
    this.cPaasForm = this.fb.group({
      cpaas_user_email: new UntypedFormControl(initValue.cpaas_user_email, [Validators.required, Validators.email, this.noTailingSpaceValidator],),
      cpaas_user_first_name: new UntypedFormControl(initValue.cpaas_user_first_name, [Validators.required]),
      cpaas_user_last_name: new UntypedFormControl(initValue.cpaas_user_last_name, [Validators.required]),
    });
  }

  public noTailingSpaceValidator(control: FormControl) {
    if (control?.value?.startsWith(' ')) {
      return {
        'trimError': { value: `E-mail begint met spaties` }
      };
    }
    if (control?.value?.endsWith(' ')) {
      return {
        'trimError': { value: `E-mail eindigt met spaties` }
      };
    }

    return null;
  }

  getDeliveryOptions() {
    this.cartService.getDeliveryOptions().subscribe(response => {
      this.deliveryOptions = response.data.results;
    });
  }

  getPaymentOptions() {
    this.cartService.getPaymentTypes(this.cartUuid).subscribe((res) => {
      this.paymentOptions = [...res?.data?.results];
      if (this.cart?.lines?.length) {
        this.selectedPaymentOption = this.cart?.lines?.[0]?.payment_type?.code ?? '';
        if (this.selectedPaymentOption === 'ideal_talos') {
          const previouslySelectedPayment = this.storageService.getSelectedPaymentData();
          if (previouslySelectedPayment?.payment_type === 'ideal_talos') {
            this.selectedIdealIssuer = previouslySelectedPayment?.issuer ?? 'Kies je bank';
          }
        }
      }
    }, (error) => { });
  }

  changePaymentType(payment: any) {

    if (payment?.code !== 'ideal_talos') {
      this.storageService.setSelectedPaymentData({
        payment_type: payment?.code,
        issuer: null
      });
    } else {
      this.storageService.setSelectedPaymentData({
        issuer: this.selectedIdealIssuer ?? '',
        payment_type: 'ideal_talos'
      });
    }
    this.selectedPaymentOption = payment?.code;
    this.cartService.updatePaymentType(this.cartUuid, {
      payment_type: payment?.code
    }).subscribe((res) => {
      this.formatCartData(res?.data);
      this.addedVouchers = this.cart?.lines?.filter(line => line?.voucher_code).map(item => item?.voucher_code);
    }, (error) => { });
  }

  getCart() {
    this.isLoading = true;
    this.cartService.getCart(this.cartUuid).subscribe(res => {
      this.special_discount_requested = res?.data?.special_discount_requested ?? false;
      this.isOffer = res?.data?.requires_quote;
      this.formatCartData(res?.data);
      this.addedVouchers = this.cart?.lines?.filter(line => line?.voucher_code).map(item => item?.voucher_code);
      this.getContact(this.cart?.contact_person_uuid);
      this.getCartDeliveryOption();
      this.getTermAndConditions();
      this.getPaymentOptions();


      if (this.deliveryOptions?.length === 1 && !this.cart.delivery_option) {
        this.selectedDeliveryOption = this.deliveryOptions?.find(item => true);
        this.addDefaultDelivery();
      }

      // if (!this.accountTypes?.length && this.cart.is_contact_account_type_required) {
      //   this.getAccountTypes();
      // }

      if (!this.contactAccountTypesCustomers?.length && this.cart.is_contact_account_type_required) {

        if (this.hasViewContactAccountTypeCustomer) {
          this.getContactAccountTypesCustomers();
        } else {
          this.getAccountTypes();
        }
      }

    }, error => {
      this.isLoading = false;
      this.errorFound = true;
      this.showErrorToaster(error?.error?.error);
      this.router.navigateByUrl('/');
    });
  }

  arraysHaveSameElements(arr1 = [], arr2 = []) {
    return arr1.length === arr2.length && arr1.sort().every((value, index) => value === arr2.sort()[index]);
  }

  getAccountTypes(contactAccountTypeCustomerUUID = null) {
    this.contactAccountTypeCustomersLoading = true;
    let paramsString = `page=1`;
    if (contactAccountTypeCustomerUUID) {
      paramsString = `page=1&contact_account_type_customer_uuid=${contactAccountTypeCustomerUUID}`;
    }
    this.accountTypeService.getCartAccountTypes(this.cart?.uuid, paramsString).subscribe(res => {
      this.accountTypes = res?.data?.results?.map(item => ({ ...item, checked: true }));
      this.formatAccountTypeData(this.cart);
      this.contactAccountTypeCustomersLoading = false;
      let accountTypeIds = this.accountTypes.map(item => item.id);
      if (this.hasViewContactAccountTypeCustomer && this.cart?.is_contact_account_type_required) {
        if (!this.arraysHaveSameElements(accountTypeIds, this.cart?.contact_account_types)) {
          this.updateAccountTypeToCart(accountTypeIds);
        }
        this.hasExistingSelectedContactAccountTypesCustomer = false;
      } else if (!this.hasViewContactAccountTypeCustomer && this.cart?.is_contact_account_type_required) {
        this.updateAccountTypeToCart(accountTypeIds);
      }

    }, error => {
      this.contactAccountTypeCustomersLoading = false;
    })
  }

  getContactAccountTypesCustomers() {
    this.accountTypeService.getContactAccountTypesCustomers().subscribe(res => {
      this.contactAccountTypesCustomers = res?.data?.results ?? [];
      const existingSelectedContactAccountTypesCustomer = window.localStorage.getItem('selectedContactAccountTypesCustomer') || null;

      if (existingSelectedContactAccountTypesCustomer) {
        this.hasExistingSelectedContactAccountTypesCustomer = true;
        this.selectedContactAccountTypesCustomer = this.contactAccountTypesCustomers.find(item => item?.uuid === existingSelectedContactAccountTypesCustomer)?.uuid || null;

        this.onContactAccountTypesCustomerChange(existingSelectedContactAccountTypesCustomer);
      }
    }, error => {
    })
  }

  onContactAccountTypesCustomerChange(event) {
    if (event) {
      this.getAccountTypes(event);
    } else {
      this.accountTypes = [];
      this.updateAccountTypeToCart([]);
    }
  }

  addOrRemoveAccountTypeToCart(obj, type) {
    let ids = [];
    if (type == OperationType.Add) {

      ids = this.accountTypes.filter(item => item?.checked).map(item => item.id);
      ids.push(obj?.id);

    } else {
      ids = this.accountTypes.filter(item => item?.checked).filter(item => item.id !== obj?.id).map(item => item.id);
    }
    this.updateAccountTypeToCart(ids);

  }
  updateAccountTypeToCart(ids) {
    this.contactAccountTypeCustomersLoading = true;
    this.customerService.validateCart(this.cart?.uuid, { contact_account_types: ids }).subscribe(res => {
      this.formatAccountTypeData(res?.data);
      this.formatCartData(res?.data);
      this.toastrService.success(``, 'Success', {
        timeOut: 2000
      });
      this.contactAccountTypeCustomersLoading = false;
    }, error => {
      this.contactAccountTypeCustomersLoading = false;
      this.showErrorToaster(error?.error?.message || error?.error?.error);
    })
  }

  formatAccountTypeData(data) {
    this.accountTypes = this.accountTypes.map(item => {
      const isChecked = data?.contact_account_types?.find(contactAccountTypeId => contactAccountTypeId === item?.id);
      return {
        ...item,
        checked: isChecked ? true : false
      };
    })
  }



  formatCartData(cart) {
    this.quoteCreateMessage = '';
    this.cPassAdded = true;
    this.cart = JSON.parse(JSON.stringify(cart));
    if (this.cart?.lines?.length) {
      this.cart.lines = this.cart.lines.filter(line => line?.product_type !== "additional_cost");
      this.cart.lines = this.cart.lines.map(line => {
        const memory_size = line?.dimentional_attributes?.find(item => item?.attribute_code === 'memory_size')?.attribute_value ?? null;
        return {
          ...line,
          memory_size: memory_size
        }
      });
    }
    this.additionalCostLies = cart?.lines?.filter(line => line?.product_type === "additional_cost");
    if (this.isOffer) {
      const product_names = this.cart?.lines?.filter(line => line?.requires_quote)?.map(item => item?.product_code);
      // if (product_names?.length) {
      //   this.quoteCreateMessage = !this.hasQuoteCreationPermission ? `Je moet een offerte genereren voor het product ${product_names?.join()?.split(', ')} in de winkelwagen, maar helaas heb je geen toegang tot het offertemodule` : `Het plaatsen van product ${product_names?.join()?.split(', ')} in de winkelwagen vereist een offerte. Je dient een offerte aan te maken`;
      // }
      if (product_names?.length) {
        this.quoteCreateMessage = !this.hasQuoteCreationPermission ? `${this.translocoService.translate('ORDER_OVERVIEW.YOU_NEED_TO_GENERATE_A_QUOTE_FOR_THE_PRODUCT')} ${product_names?.join()?.split(', ')} ${this.translocoService.translate('ORDER_OVERVIEW.IN_CART_YOU_NOT_HAVE_ACCESS_TO_QUOTATION')}` : `${this.translocoService.translate('ORDER_OVERVIEW.PLACING_PRODUCT')} ${product_names?.join()?.split(', ')} ${this.translocoService.translate('ORDER_OVERVIEW.IN_THE_SHOPPING_CART_REQUIRES_QUOTE_YOU_MUST_CREATE_QUOTE')}`;
      }
    }
    this.customer_reference = cart?.customer_reference;
    this.poNumber = cart?.po_number || '';
    this.cart.lines = this.cart?.lines?.map(line => {
      return {
        ...line,
        deliveryOptions: this.deliveryOptions,
        selectedDeliveryOption: this.getCartLineDeliveryOption(line?.delivery_option?.code)
      };
    });

    this.hasCpaas = this.cart.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS) || null;
    if (this.hasCpaas) {
      this.checkCpassForm();
    }

    this.requested_delivery_date = this.cart?.requested_delivery_date;

    this.restoreCart(this.cart);
  }


  checkCpassForm() {
    if (this.hasCpaas?.extra?.extra_info?.cpaas_user_email) {
      let cPassData = {
        cpaas_user_email: this.hasCpaas?.extra?.extra_info?.cpaas_user_email,
        cpaas_user_first_name: this.hasCpaas?.extra?.extra_info?.cpaas_user_first_name,
        cpaas_user_last_name: this.hasCpaas?.extra?.extra_info?.cpaas_user_last_name,
      }
      this.initiateCpaasForm(cPassData);
      this.cPassAdded = true;
    } else {
      this.initiateCpaasForm();
      this.cPassAdded = false;
    }
  }

  getTermAndConditions() {
    this.checkAllTermAccepted();
    if (!this.hasTnCViewPermission || this.isQuoteSelected) {
      return;
    }
    this.cartService.getCartTermAndCondition(this.cartUuid).subscribe(response => {
      this.formatTnCData(this.cart?.lines, response?.data?.results);
    }, error => { });
  }

  formatTnCData(cartLines = [], termAndConditions = []) {
    this.termAndConditions = termAndConditions?.map(item => {
      return { ...item, checked: this.getCheckedValueInCart(cartLines, item.code) };
    });
    this.checkAllTermAccepted();
  }

  getCheckedValueInCart(cart_lines, code) {
    return cart_lines.every(line => {
      return line?.extra?.extra_info?.terms_and_conditions?.[code];
    });
  }

  checkAllTermAccepted() {
    this.termAccepted = !this.termAndConditions?.length ? true : this.termAndConditions.every(item => item.checked);
  }

  onChangeTermAndCondition(term) {
    this.termsAndConditionsLoading = true;
    const payload = {
      code: term?.code,
      is_accepted: term?.checked
    };
    this.cartService.addCartTermAndCondition(this.cartUuid, payload).subscribe(response => {
      this.termsAndConditionsLoading = false;
      this.formatTnCData(response?.data?.lines, this.termAndConditions);
    }, error => {
      this.termsAndConditionsLoading = false;
      term.checked = !term.checked;
      this.checkAllTermAccepted();
    });

  }

  getCartDeliveryOption() {
    this.selectedDeliveryOption = this.deliveryOptions.find(option => {
      return option.code === this.cart.delivery_option?.code;
    }) || null;
    this.isDeliveryOptionVisible = true;
  }

  getCartLineDeliveryOption(code) {
    return this.deliveryOptions.find(option => {
      return option.code === code;
    }) || null;
  }

  closeDeliveryOptionSelect() {
    this.selectedDeliveryOption = null;
    this.isDeliveryOptionVisible = false;
  }

  addDefaultDelivery() {
    if (this.selectedDeliveryOption) {
      const delivery_option = this.selectedDeliveryOption?.code;
      this.cartService.setDefaultDeliveryToCart(this.cart?.uuid, { delivery_option }).subscribe(response => {
        this.formatCartData(response?.data);
        this.showSuccessToaster(this.translocoService.translate('ORDER_OVERVIEW.STD_DELIVERY_METHOD_TO_CART'));
        this.isDeliveryOptionVisible = true;
      });
    }
  }

  addLineDelivery(line) {
    if (line.selectedDeliveryOption) {
      const delivery_option = line.selectedDeliveryOption?.code;
      this.cartService.setDeliveryToCartLine(this.cart?.uuid, line?.uuid,
        { delivery_option, quantity: line?.quantity }).subscribe(response => {
          this.formatCartData(response.data);
          // this.showSuccessToaster('Leverwijze toegekend aan regel.');
          this.showSuccessToaster(`${this.translocoService.translate('ORDER_OVERVIEW.DELIVERY_METHOD_ASSIGNED_TO_LINE')}.`);
        });
    }
  }

  restoreCart(cart) {
    this.cartService.changeCurrentCart(cart);
    this.storageService.setCart(cart);
    this.checkAllTermAccepted();
  }

  getContact(personUuid) {
    const selectedContactID = this.storageService.getContactPersonUUID();
    const shippingAddressId = this.storageService.getShippingAddressID();
    this.customerService.getContact(personUuid).subscribe(
      (response) => {
        this.contactPerson = response.data;
        // tslint:disable-next-line:max-line-length
        this.contactAddress = this.contactPerson?.contact_person.find(element => element?.uuid === selectedContactID);

        if (this.hasCpaas && !this.hasCpaas?.extra?.extra_info?.cpaas_user_email) {
          let cPassData = {
            cpaas_user_email: this.contactAddress?.email,
            cpaas_user_first_name: this.contactAddress?.first_name,
            cpaas_user_last_name: this.contactAddress?.last_name,
          }
          this.initiateCpaasForm(cPassData);
        }
        // tslint:disable-next-line:max-line-length
        this.shippingAddress = this.contactPerson?.contact_addresses.find(element => element?.id === this.cart?.shipping_address_id);
        if (!this.cart?.shipping_address_id) {
          this.shippingAddress = this.contactPerson?.contact_addresses.find(element => element?.address_type === 'contact');
        }
        // when user selected one time shipping address
        if ((!this.cart?.shipping_address_id) && (
          this.cart?.shipping_address_company_name ||
          this.cart?.shipping_address_initials ||
          this.cart?.shipping_address_first_name ||
          this.cart?.shipping_address_last_name ||
          this.cart?.shipping_address_surname_prefix ||
          this.cart?.shipping_address_street ||
          this.cart?.shipping_address_house_number ||
          this.cart?.shipping_address_house_number_extension ||
          this.cart?.shipping_address_zip_code ||
          this.cart?.shipping_address_city ||
          this.cart?.shipping_address_country ||
          this.cart?.shipping_address_email
        )) {
          this.shippingAddress = {
            first_name: this.cart?.shipping_address_first_name,
            surname_prefix: this.cart?.shipping_address_surname_prefix,
            last_name: this.cart?.shipping_address_last_name,
            company_name: this.cart?.shipping_address_company_name,
            street: this.cart?.shipping_address_street,
            house_number: this.cart?.shipping_address_house_number + '',
            house_number_extension: this.cart?.shipping_address_house_number_extension + '',
            zip_code: this.cart?.shipping_address_zip_code,
            city: this.cart?.shipping_address_city,
            country: this.cart?.shipping_address_country,
          }
        }
        this.isLoading = false;
      }, (err) => {
        this.contactPerson = null;
        this.isLoading = false;
      });
  }

  createOrder() {
    this.shouldShowSpinner = true;
    this.orderError = null;
    if (this.cart.contact_person_uuid) {
      // if (this.poNumber) {
      //   this.customerService.updateCart(this.cartUuid, { po_number: this.poNumber }).subscribe(resp => {
      //     this.toastrService.success(`PO Number Added`, 'Succes', {
      //       timeOut: 2000
      //     });
      //     this.submitOrder();
      //   }, error => {
      //     this.showErrorToaster('PO number failed !');
      //   });
      // } else {
      // }
      this.submitOrder();
    } else {
      if (this.isExternal) {
        this.router.navigateByUrl('/add-customer');
      } else {
        this.router.navigateByUrl('/customers');
      }
    }
  }

  addPONumber() {
    this.orderError = null;
    if (!this.poNumber?.trim()?.length || (this.poNumber?.trim()?.length && this.poNumber === this.cart?.po_number)) {
      return;
    }
    if (this.poNumber?.trim()?.length <= 14) {
      this.shouldShowSpinner = true;
      this.customerService.validateCart(this.cartUuid, { po_number: this.poNumber }).subscribe(res => {
        this.formatCartData(res?.data);
        this.toastrService.success(`${this.siteProfile?.po_number_label || `PO ${this.translocoService.translate('ORDER_OVERVIEW.NUMBER')}`} ${this.translocoService.translate('ORDER_OVERVIEW.ADDED')}`, 'Success', {
          timeOut: 2000
        });
        this.shouldShowSpinner = false;
      }, error => {
        // this.showErrorToaster(error?.error?.error?.po_number?.error || `${this.siteProfile?.po_number_label} bijwerken mislukt!`, `${error?.status}-${error?.statusText}`);
        this.showErrorToaster(error?.error?.error?.po_number?.error || `${this.siteProfile?.po_number_label} ${this.translocoService.translate('ORDER_OVERVIEW.UPDATE_FAILED')}!`, `${error?.status}-${error?.statusText}`);
        this.shouldShowSpinner = false;
      });
    }
  }

  addReference() {
    this.orderError = null;
    if (!this.customer_reference?.trim()?.length || (this.customer_reference?.trim()?.length && this.customer_reference === this.cart?.customer_reference)) {
      return;
    }
    if (this.customer_reference?.trim()?.length <= 256) {
      this.referenceSpinner = true;
      this.customerService.validateCart(this.cartUuid, { customer_reference: this.customer_reference }).subscribe(res => {
        this.formatCartData(res?.data);
        this.toastrService.success(`${this.siteProfile?.reference_label || this.translocoService.translate('ORDER_OVERVIEW.REFERENCE_NUMBER')} ${this.translocoService.translate('ORDER_OVERVIEW.ADDED')}.`, 'Success', {
          timeOut: 2000
        });
        this.referenceSpinner = false;
      }, error => {
        // this.showErrorToaster(error?.error?.error?.customer_reference?.error || `${this.siteProfile?.reference_label} bijwerken mislukt!`, `${error?.status}-${error?.statusText}`);
        this.showErrorToaster(error?.error?.error?.customer_reference?.error || `${this.siteProfile?.reference_label} ${this.translocoService.translate('ORDER_OVERVIEW.UPDATE_FAILED')}!`, `${error?.status}-${error?.statusText}`);
        this.referenceSpinner = false;
      });
    }
  }
  async addSpecialDiscountReason() {
    if (this.special_discount_reason?.trim()?.length === 0 && this.special_discount_requested === false) {
      return true;
    }
    if (this.special_discount_reason?.trim()?.length > 0) {
      this.referenceSpinner = true;
      await this.customerService.validateCart(this.cartUuid, { special_discount_reason: this.special_discount_reason }).toPromise().
        then(res => {
          this.referenceSpinner = false;
          return true;
        }).
        catch(error => {
          this.showErrorToaster('Something went wrong!');
          this.referenceSpinner = false;
          return false;
        });
    }
  }

  async submitOrder() {
    if (this.isOffer || this.isQuoteSelected) {
      const status = await this.addSpecialDiscountReason();
      if (status === false) {
        return;
      }
      this.cartService.createQuote({ cart: this.cartUuid }).subscribe(res => {
        this.storageService.removeCart();
        this.storageService.deleteSelectedQuote();
        this.cartService.changeCurrentCart(null);
        this.isLoading = false;
        this.storageService.removeShippingAddressID();
        this.router.navigateByUrl('/quotes');
        // this.router.navigate(['/order-success', `${res?.data?.uuid}`], {
        //   queryParams: {
        //     quoteRef: res?.data?.reference,
        //     orderId: null,
        //     orderRef: null,
        //   },
        //   queryParamsHandling: 'merge'
        // });

      }, error => {
        this.isLoading = false;
        this.shouldShowSpinner = false;
        this.orderError = error?.error?.message || error?.error?.error?.message;
        this.showErrorToaster(this.orderError);
      });
    } else if (!this.isOffer && !this.isQuoteSelected) {
      this.cartService.createOrder({ cart_uuid: this.cartUuid }).subscribe(res => {
        const orderUUID = res?.data?.order?.uuid;
        this.storageService.deleteSelectedQuote();
        this.storageService.removeCart();
        this.cartService.changeCurrentCart(null);
        this.isLoading = false;
        this.storageService.removeShippingAddressID();
        this.shouldShowSpinner = false;
        this.router.navigate(['/order-success', `${orderUUID}`], {
          queryParams: this.utilityService.getOrderPaymentParameters(res?.data),
          queryParamsHandling: 'merge'
        });
      }, error => {
        this.isLoading = false;
        this.shouldShowSpinner = false;
        this.orderError = error?.error?.error?.message || error?.error?.message || error?.message || `${this.translocoService.translate('ORDER_OVERVIEW.ORDER_CREATION_FAILED')}`;
        if (this.orderError?.includes('PO Number')) {
          this.orderError = this.orderError?.replace('PO Number', this.siteProfile?.po_number_label);
        }
        if (this.orderError?.includes(`${this.translocoService.translate('ORDER_OVERVIEW.REFERENCE')}`)) {
          this.orderError = this.orderError?.replace(`${this.translocoService.translate('ORDER_OVERVIEW.REFERENCE')}`, this.siteProfile?.reference_label);
        }

        if (error?.error?.error?.customer_reference) {
          this.orderError = `${this.siteProfile?.reference_label} : ${error?.error?.error?.customer_reference?.[0]}`;
        }
        this.showErrorToaster(this.orderError);
      });
    }
  }


  updateAccountId() {
    this.customerService.validateCart(this.cart?.uuid, { contact_account_type_id: this.cart?.contact_account_type_id }).subscribe(res => {
      this.formatCartData(res?.data);
      this.toastrService.success(`${this.translocoService.translate('ORDER_OVERVIEW.CONTACT_ACCOUNT_ID_IS_ADDED_TO_THE_CART')}`, 'Success', {
        timeOut: 2000
      });
    }, error => {
      this.showErrorToaster(error?.error?.message || error?.error?.error);
    })
  }

  deleteCartItem(cartLine) {
    this.cartService.deleteCartItem(this.cart.uuid, cartLine.uuid).subscribe(res => {
      this.formatCartData(res?.data);
      this.toastrService.success(`${this.translocoService.translate('ORDER_OVERVIEW.THE_SHOPPING_CART_ITEM_HAS_BEEN_SUCCESSFULLY_DELETED')}`, 'Success', {
        timeOut: 2000
      });
    }, err => {
    });
  }

  onBack() {
    const person = this.storageService.getCurrentUser();
    if (person.is_external) {
      if (this.cart.contact_person_uuid) {
        this.utilityService.backToCustomer();
      } else {
        this.router.navigateByUrl('/add-customer');
      }
    } else {
      // this.router.navigateByUrl('/customers');
      this.utilityService.backToCustomer();
    }

  }

  showErrorToaster(msg, title = 'Error') {
    this.toastrService.error(`${msg} `, title, {
      timeOut: 4000
    });
  }

  showSuccessToaster(msg) {
    this.toastrService.success(`${msg} `, 'Succes', {
      timeOut: 4000
    });
  }

  reFetchCart(event) {
    if (event) {
      this.getCart();
    }
  }
  onIdealIssuerChange(event) {
    this.storageService.setSelectedPaymentData({
      issuer: event,
      payment_type: 'ideal_talos'
    });
  }

  onChangeRequestedDateDate(event) {
    let momentDate = moment(event).format('YYYY-MM-DD')
    if (event && momentDate !== 'Invalid date' && moment(event).isAfter(moment())) {
      this.requested_delivery_date = event;
      this.updateRequestedDeliveryDateToCart();
    } else {
      this.showErrorToaster('Entered Date is Invalid');
    }
  }

  updateRequestedDeliveryDateToCart() {
    let requested_delivery_date = moment(this.requested_delivery_date).format('YYYY-MM-DD');
    this.customerService.validateCart(this.cart?.uuid, { requested_delivery_date }).subscribe(res => {
      this.formatCartData(res?.data);
      this.toastrService.success(``, 'Success', {
        timeOut: 2000
      });
    }, error => {
      this.showErrorToaster(error?.error?.message || error?.error?.error);
    })
  }

  preventKeyEvent(event: KeyboardEvent) {
    // Add the keys you want to prevent here
    event.preventDefault();
    return false;
  }

  ngOnDestroy(): void {
    if (this.selectedContactAccountTypesCustomer) {
      window.localStorage.setItem('selectedContactAccountTypesCustomer', this.selectedContactAccountTypesCustomer);
    }
    else {
      window.localStorage.removeItem('selectedContactAccountTypesCustomer');
    }
    this.langChanges$?.unsubscribe();
  }

}
