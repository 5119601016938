import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../shared/auth.service';
import { EUrl } from '../shared/enums/messages.enums';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,

  ) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.authService.isLoggedIn() && this.authService.hasUser()) {
      if (state.url === '/') {
        this.router.navigate(['/home']);
      }
      return true;
    } else {
      if (state.url?.includes(EUrl.SALES_FORCE)) {
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url } });
      } else {
        this.router.navigate(['/login']);
      }
      return false;
    }
  }
}
