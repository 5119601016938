import { Component } from '@angular/core';
import { QuoteDetailsService } from '../../../quote-details.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-quote-side-bar-price-summary',
  templateUrl: './customer-quote-side-bar-price-summary.component.html',
  styleUrls: ['./customer-quote-side-bar-price-summary.component.scss']
})
export class CustomerQuoteSideBarPriceSummaryComponent {
  quote = null;
  additionalCosts = [];
  currentCustomerDetailsQuoteSubscription$: Subscription;
  constructor(
    private quoteDetailsService: QuoteDetailsService,
  ) {
   this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe((quote) => {
      this.quote = quote;
      let sysTemTypes = [];
      this.additionalCosts = [];
      if (quote) {
        this.quote.lines = quote.lines?.map((line) => {

          if (line.product_type?.system_type === "additional_cost") {
            this.additionalCosts.push(line);
            return {
              ...line,
              showSystemTypeInFrontend: false,
              showLine: false
            };
          }

          if (sysTemTypes.includes(line.product_type.system_type)) {
            return {
              ...line,
              showSystemTypeInFrontend: false,
              showLine: true
            };
          }
          else {
            sysTemTypes.push(line.product_type.system_type);
            return {
              ...line,
              showSystemTypeInFrontend: true,
              showLine: true
            };
          }
        });
        if (this.quote?.subscription?.lines?.length) {
          let subscriptionSystemTypes = [];
          this.quote.subscription.lines = quote.subscription?.lines?.map((line) => {
            if (subscriptionSystemTypes.includes(line.product_type.system_type)) {
              return {
                ...line,
                showSystemTypeInFrontend: false,
              };
            }
            else {
              subscriptionSystemTypes.push(line.product_type.system_type);
              return {
                ...line,
                showSystemTypeInFrontend: true,
              };
            }
          });
        }
      }
    });
  }


  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
  }

}
