import { EMobileFlowTypes } from "src/app/shared/enums/flow-type.enums";

export class RMA {
    constructor(
        public contact_uuid = null,
        public contact_person_uuid = null,
        public market_type_code = null,
        public channel_code = null,
        public rma_flow_type = 'rma_without_original_order',
        public original_order_number = '',
        public partner_number = '',
        public po_number = '',
        public customer_reference = '',
        public lines: RMALine[] = []
    ) { }
}

export class RMALine {
    constructor(
        public product_code = null,
        public product_name = null,
        public rma_type = null,
        public return_reason_type = null,
        public quantity = 1,
        public flow_type_code = EMobileFlowTypes.NEW,
        public price = 0.00,
        public user_reason = '',
        public serial_number = ''
    ) { }
}

