import { TranslocoService } from '@ngneat/transloco';
import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpParams } from '@angular/common/http';

import { Observable } from 'rxjs';
import { StorageService } from './storage.service';
import { environment } from '../../environments/environment';
import { HostCheckService } from './hostcheck.service';

@Injectable({
    providedIn: 'root'
})
export class ApiBaseService extends HostCheckService {
    // public apiBaseUrl = environment.baseApiUrl;
    private openUrls = [
        'accounts/password/reset',
        'accounts/login',
        'accounts/password/reset/confirm',
        'accounts/refresh-token',
        'tenants/site-profile',
        'invitations/user-invitation/verify',
        'tenants/countries/',
        'vouchers/renew-invitation',
        'contacts/registration/'
    ];
    private EMPTY_CONTENT_TYPE = `empty-content-type`;

    constructor(
        private http: HttpClient,
        private storageService: StorageService,
        private translocoService: TranslocoService,
    ) {
        super();
    }

    private getHeaders(contentType = 'application/json') {
        const headerContent = new HttpHeaders({
            'Content-Type': contentType,
            Authorization: this.storageService.getToken()
        });
        return headerContent;
    }

    private getHeadersWithoutAuthorization(contentType = 'application/json') {
        const headerContent = new HttpHeaders({
            'Content-Type': contentType
        });
        return headerContent;
    }

    private getSelectedLanguage() {
        return this.translocoService.getActiveLang();
    }

    get(path: string, params: HttpParams = new HttpParams(), version: string = ''): Observable<any> {
        const selectedLanguage = this.translocoService.getActiveLang();
        if (params) {
            params = params.set('language', selectedLanguage);
        }
        const headers = this.setHeader(path);
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        return this.http.get(`${baseUrl}${path}/`, { params, headers });
    }


    getFile(path: string, params: HttpParams = new HttpParams(), version: string = ''): Observable<any> {
        const headers = this.setHeader(path);
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        params = params.set('language', this.getSelectedLanguage());
        return this.http.get(`${baseUrl}${path}/`, { params, headers, responseType: 'blob' });
    }

    getFileAsObserve(path: string, params: HttpParams = new HttpParams(), version: string = ''): Observable<any> {
        const headers = this.setHeader(path);
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        params = params.set('language', this.getSelectedLanguage());
        return this.http.get(`${baseUrl}${path}/`, { params, headers, responseType: 'blob', observe: 'response' });
    }

    download(path: string, params: HttpParams = new HttpParams()): Observable<any> {
        const headers = this.getHeaders();
        params = params.set('language', this.getSelectedLanguage());
        return this.http.get(`${this.apiBaseUrl}${path}/`, { params, headers, responseType: 'blob' });
    }

    put(path: string, body: object = {}): Observable<any> {
        const headers = this.getHeaders();
        let params = new HttpParams();
        params = params.set('language', this.getSelectedLanguage());
        return this.http.put(`${this.apiBaseUrl}${path}/`, JSON.stringify(body), {
            headers,
            params
        });
    }

    patch(path: string, body: object = {}): Observable<any> {
        const headers = this.getHeaders();
        let params = new HttpParams();
        params = params.set('language', this.getSelectedLanguage());
        return this.http.patch(`${this.apiBaseUrl}${path}/`, JSON.stringify(body), {
            headers,
            params
        });
    }

    post(path: string, body: object = {}, version: string = '', params = new HttpParams()): Observable<any> {
        // tslint:disable-next-line:max-line-length
        const headers = this.setHeader(path);
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        params = params.set('language', this.getSelectedLanguage());
        return this.http.post(`${baseUrl}${path}/`, JSON.stringify(body), {
            headers,
            params
        });
    }

    postFile(path: string, formData: FormData, version: string = '', params = new HttpParams()): Observable<any> {
        const headers = new HttpHeaders({
            "form-data": 'true'
        });
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        params = params.set('language', this.getSelectedLanguage());
        return this.http.post(`${baseUrl}${path}/`, formData, {
            headers,
            params
        });
    }

    putFile(path: string, formData: FormData, version: string = ''): Observable<any> {
        const headers = new HttpHeaders({
            "form-data": 'true'
        });
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        let params = new HttpParams();
        params = params.set('language', this.getSelectedLanguage());
        return this.http.put(`${baseUrl}${path}/`, formData, {
            headers,
            params
        });
    }

    patchFile(path: string, formData: FormData, version: string = ''): Observable<any> {
        const headers = new HttpHeaders({
            "form-data": 'true'
        });
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        let params = new HttpParams();
        params = params.set('language', this.getSelectedLanguage());
        return this.http.patch(`${baseUrl}${path}/`, formData, {
            headers,
            params
        });
    }

    delete(path, version: string = ''): Observable<any> {
        const headers = this.getHeaders();
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        return this.http.delete(`${baseUrl}${path}/`, { headers });
    }

    deleteWithPayload(path, payload = null, version: string = ''): Observable<any> {
        const headers = this.getHeaders();
        const v2Url = this.apiBaseUrl;
        const baseUrl = version ? v2Url.replace('v1', version) : this.apiBaseUrl;
        return this.http.request('delete', `${baseUrl}${path}/`, { headers, body: payload });
    }

    private setHeader(path, contentType = 'application/json') {
        if (this.storageService.getToken() && !this.openUrls.includes(path)) {
            return this.getHeaders(contentType);
        } else {
            return this.getHeadersWithoutAuthorization(contentType);
        }
    }
}
