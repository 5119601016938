import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { StorageService } from 'src/app/shared/storage.service';
import { ESiteProfileActions, SiteProfileActions, SiteProfileSuccessAction } from '../actions/siteProfile.actions';

@Injectable()
export class SiteProfileEffects {
    constructor(
        private storageService: StorageService,
        private permissionService: PermissionService,
        private actions$: Actions
    ) { }

    loadSiteProfile$ = createEffect(
        () => this.actions$.pipe(
            ofType<SiteProfileActions>(ESiteProfileActions.SITE_PROFILE_LOAD),
            switchMap(() => {
                return this.permissionService.getSiteProfile();
            }),
            switchMap((resp: any) => {
                const siteProfile = resp.data;
                this.storageService.setSiteProfile(siteProfile);
                return of(new SiteProfileSuccessAction(siteProfile));
            }),
            catchError((error, caught) => {
                return caught;
            })
        )
    );
}
