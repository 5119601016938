<div>
  <app-loader [isLoading]="isLoading"></app-loader>

  <div >
    <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 mb-4">
      <div class="c-px-24">
        <h4 class="pt-1" [ngClass]="{ 'mb-3 pb-1' : !showHeader, 'mb-2' : showHeader }">
          <span class="font-kpn-extended kpn-extended-black letter-spacing-normal fs-35 green-text">
            Bestelgegevens
          </span>
        </h4>
        <p *ngIf="showHeader" class="font-kpn-extended mb-4"> Controleer onderstaande gegevens. Kloppen je gegevens niet? Pas deze dan aan. Deze zie je ook op de factuur. </p>

        <div class="bg-gray-1 tri-rounded-20 p-24 h-100">
          <label for=""
            class="font-kpn-extended kpn-extended-bold mb-0">{{ siteProfile?.po_number_label || 'PO-nummer'  }} </label>
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="">
                <p class="font-kpn-extended mb-2">
                  <span class="d-block"> {{ po_number }} </span>
                </p>
              </div>
            </div>
          </div>
          <label for=""
            class="font-kpn-extended kpn-extended-bold mb-0 pt-1"> {{ siteProfile?.reference_label ||  'Referentie' }} </label>
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="">
                <p class="font-kpn-extended mb-0">
                  <span class="d-block"> {{ customer_reference }} </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr class="mt-4 mb-3">
      <p class="m-0 c-px-24" >
        <a href="javascript:void(0)" (click)="openPoAndReferenceEditModal(customerPoReferenceUpdateModal)"
          class="fs-16 font-kpn-extended link-btn-blue">
          Wijzigen <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
      </p>
    </div>

  </div>

  <ng-template #customerPoReferenceUpdateModal let-modal>
    <div class="px-4">
      <div class="px-1 modal-header tri-modal-header">
        <h6 class="modal-title green-text font-kpn-black fs-40" id="cpaasMainUserModal">
          Bestelgegevens wijzigen
        </h6>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click');resetData()" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <div>
        <div class="pl-4 pt-4 pr-4">
          <div class="custom-inner-modal-scroll overflow-x-hidden">
            <div class="row gutter-row-15 mb-2">
              <div class="col-md-12 gutter-col-15 mb-3">
                <label for="" class="font-kpn-extended"><span
                    class="font-kpn-extended kpn-extended-bold">{{ siteProfile?.po_number_label || 'PO-nummer'  }}</span><span
                    class="text-danger" *ngIf="siteProfile?.is_po_number_required">*</span></label>
                <input type="text" class="form-control tri-form-control  "

                  [(ngModel)]="poAndReferenceUpdateModalData.po_number"
                  placeholder="PO-nummer">
                <!-- <span *ngIf="feRequiredFieldErrors.cpaas_user_first_name"
                  class="invalid-feedback fs-16 font-kpn-extended">
                  {{ feRequiredFieldErrors.cpaas_user_first_name }}
                </span> -->
              </div>
              <div class="col-md-12 gutter-col-15 mb-0">
                <label for="" class="font-kpn-extended"><span
                    class="font-kpn-extended kpn-extended-bold">{{ siteProfile?.reference_label ||  'Referentie' }}</span><span
                    class="text-danger" *ngIf="siteProfile?.is_reference_required">*</span></label>
                <input type="text" class="form-control tri-form-control  invalid-bg-size-23"
                  [(ngModel)]="poAndReferenceUpdateModalData.customer_reference"

                  placeholder="Referentie">
                <!-- <span *ngIf="feRequiredFieldErrors.cpaas_user_last_name"
                  class="invalid-feedback fs-16 font-kpn-extended">
                  {{ feRequiredFieldErrors.cpaas_user_last_name }}
                </span> -->

              </div>
            </div>
          </div>
        </div>

        <div class="px-4 pt-0 pb-0" *ngIf="quoteUpdateError">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <div >
                <p class="text-danger">{{ quoteUpdateError }}</p>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <button (click)="updateQuotePoReference()" [disabled]="(siteProfile?.is_po_number_required && (!poAndReferenceUpdateModalData.po_number.trim())) ||  (siteProfile?.is_reference_required &&(!poAndReferenceUpdateModalData.customer_reference.trim()))"
                class="btn px-4 btn-primary rounded-btn">
                <span class="d-inline-block px-2">Wijzigen</span>
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </ng-template>
</div>
