import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../../shared/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class VoucherListService {
    constructor(
        private apiBaseService: ApiBaseService
    ) { }

    getVoucherProfile(contactUuid: string, page: number, pageSize: number): Observable<any> {
        const paramsString = `page=${page}&page_size=${pageSize}`;
        const params = new HttpParams({
            fromString: paramsString
        });
        return this.apiBaseService.get(`vouchers/profiles`, params);
    }

    getVoucherList(profileCode: string, page: number = 1, pageSize: number = 30, search:string = null): Observable<any> {
        let paramsString = `page=${page}&page_size=${pageSize}`;
        if (search) {
            paramsString += `&search=${search}`;
        }
        const params = new HttpParams({
            fromString: paramsString
        });
        return this.apiBaseService.get(`vouchers/profiles/${profileCode}/vouchers`, params);
    }

    submitVoucher(profileCode: string, payload: any) {
        return this.apiBaseService.post(`vouchers/profiles/${profileCode}/vouchers`, payload);
    }

    sendInvitation(profileCode: string, voucherCode: any) {
        return this.apiBaseService.post(`vouchers/profiles/${profileCode}/${voucherCode}/resend-invitation`, {});
    }

    generateAnonymousVoucher(profileCode: string, payload) {
        return this.apiBaseService.post(`vouchers/profiles/${profileCode}/generate`, payload);
    }

    downloadAnonymousVoucher(url) {
        return this.apiBaseService.download(url);
    }

    lockVoucher(profileCode: string, voucherCode: string) {
        return this.apiBaseService.put(`vouchers/profile/${profileCode}/vouchers/${voucherCode}/lock`);
    }


}
