<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="loader-wrapper box-shadow-sm" [ngClass]="{'spinner-wrapper-height': isLoading}">
        <div class="table-responsive custom-table">
          <h3 class="title-3 green-text ml-4 pt-3" i18n="@@arm.order-status.order-list">{{'RETURN_ORDER.RETURN_ORDERS_LIST' | transloco }}:
          </h3>
          <div class="kpn-spinner" *ngIf="isLoading;else table_content">
            <div class="text-center">
              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
              <p>Zoeken…</p>
            </div>
          </div>
          <ng-template #table_content>
            <table class="table mb-5">
              <thead>
                <tr>
                  <th i18n="@@arm.order-status.mobile">{{'RETURN_ORDER.MOBILE' | transloco }}</th>
                  <th i18n="@@arm.order-status.order-num">{{'RETURN_ORDER.ORDER_NO' | transloco }}</th>
                  <th i18n="@@arm.order-status.client-num">{{'RETURN_ORDER.CUSTOMER_NO' | transloco }}</th>
                  <th i18n="@@arm.order-status.order-date">{{'RETURN_ORDER.ORDER_DATE' | transloco }}</th>
                  <th i18n="@@arm.order-status.client-name">{{'RETURN_ORDER.CUSTOMER_NAME' | transloco }}</th>
                  <th i18n="@@arm.order-status.company-name">{{'RETURN_ORDER.COMPANY_NAME' | transloco }}</th>
                  <th i18n="@@arm.order-status.status">Status</th>
                  <th i18n="@@arm.order-status.total_amount" class="text-right">{{'RETURN_ORDER.TOTAL_AMOUNT' | transloco }}</th>
                  <th>{{'RETURN_ORDER.CONTACT_GROUP' | transloco }}</th>
                  <th>{{'RETURN_ORDER.PLACED_BY' | transloco }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let order of rmaOrders | paginate: config">
                  <td>{{order.contact_person?.mobile_phone_number}}</td>
                  <td><a href="javascript:void(0)" (click)="gotoRMADetails(order?.uuid)"
                      class="blue-link">{{order.reference}}</a>
                  </td>
                  <td>{{order.contact?.external_id1}}
                    <span *ngIf="order.contact?.export_id">
                      ({{order.contact?.export_id}})
                    </span>
                  </td>
                  <td>{{order.created_at | date: 'dd-MM-yyyy'}}</td>
                  <td>{{order.contact_person?.initials}} {{order.contact_person?.surname_prefix}}
                    {{order.contact_person?.last_name}}</td>
                  <td>{{order?.shipping_address?.company_name}}</td>
                  <td>{{ order?.status}}
                  </td>
                  <td class="text-right">
                    {{ order?.total_amount | currencyFormat }}
                  </td>
                  <td>
                    <span>{{order?.contact_group}}</span>
                  </td>
                  <td>
                    <span>{{order?.placed_by}}</span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new">
              <pagination-controls previousLabel="{{'RETURN_ORDER.PREVIOUS' | transloco }}" nextLabel="{{'RETURN_ORDER.NEXT' | transloco }}" responsive="true"  class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>
