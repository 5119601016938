import { TranslocoService } from '@ngneat/transloco';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TradeInService } from '../trade-in.service';
import { StorageService } from 'src/app/shared/storage.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-trade-in-cart',
  templateUrl: './trade-in-cart.component.html',
  styleUrls: ['./trade-in-cart.component.scss']
})
export class TradeInCartComponent implements OnInit {

  @Input() tradeInCart;
  @Output() deleteCartItem = new EventEmitter<any>();

  constructor(
    public tradeInService: TradeInService,
    private storageService: StorageService,
    private toaster: ToastrService,
    private translocoService: TranslocoService
  ) { }

  ngOnInit(): void {
  }

  deleteCartLine(event) {
    this.deleteCartItem.emit(event);
  }

  onEnter(line) {
    if (line.qty < 1) {
      return;
    }
    this.updateQuantity(line);
  }


  incrementQuantity(line) {
    line.qty = ++line.qty;
    this.updateQuantity(line);
  }
  decrementQuantity(line) {
    if (line.qty < 1) {
      return;
    }

    line.qty = --line.qty;
    this.updateQuantity(line);
  }

  updateQuantity(line) {
    this.tradeInService.updateTradeInCartLine(this.tradeInCart?.uuid, line?.uuid, { qty: line?.qty }).subscribe(res => {
      this.saveTradeInCart(res?.data);
    }, error => {
      this.errorShowTradeInCart(error?.error?.message);
    });
  }

  saveTradeInCart(cart) {
    this.toaster.success(`${this.translocoService.translate('TRADE_CART.PRODUCT_QUANTITY_UPDATED')}`);
    this.storageService.setReComCart(cart);
  }

  errorShowTradeInCart(error) {
    this.toaster.error(error || `${this.translocoService.translate('TRADE_CART.PRODUCT_QUANTITY_UPDATE_FAILED')}`);
  }
}
