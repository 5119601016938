import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from '../../shared/storage.service';
import { EProductTypes } from '../../shared/enums/product-type.enums';
import { CartService } from '../cart/cart.service';
import { ImportOrderService } from './import-order.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-import-order',
  templateUrl: './import-order.component.html',
  styleUrls: ['./import-order.component.scss']
})
export class ImportOrderComponent implements OnInit {
  isLoading = false;
  cartImportForm: UntypedFormGroup;
  isImporting = false;
  isSubmitting = false;
  importError = null;
  cartList: any[] = [];
  termsAndConditions: any[] = [];
  allTermAccepted = false;
  allTermAcceptedLoader = false;

  public productType = [
    EProductTypes.HANDSET,
    EProductTypes.TABLET
  ];

  constructor(
    private fb: UntypedFormBuilder,
    private importOrderService: ImportOrderService,
    public cartService: CartService,
    private toastrService: ToastrService,
    private router: Router,
    private storageService: StorageService,
    public translocoService: TranslocoService,

  ) { }

  ngOnInit(): void {
    this.initializeQuoteImportForm();
  }

  initializeQuoteImportForm() {
    this.cartImportForm = this.fb.group({
      import_file: new UntypedFormControl('', [Validators.required]),
      source_file: new UntypedFormControl('', [Validators.required]),
    });
    this.storageService.removeDynamicValue('bulk_cart');
  }

  importCart() {
    const formData: FormData = new FormData();
    for (const key in this.cartImportForm.value) {
      if (key !== 'source_file') {
        formData.append(key, this.cartImportForm.get(key).value);
      }
    }

    this.isImporting = true;
    this.importError = null;
    this.importOrderService.importCart(formData).subscribe(
      (res) => {
        this.cartImportForm.reset();
        this.cartList = res?.data?.carts;
        this.getTermsAndCondition();
        this.storageService.setDynamicValue('bulk_cart', JSON.stringify(res?.data?.carts));
        this.isImporting = false;
        this.toastrService.success(res?.data?.message, 'Success', {
          timeOut: 5000
        });

      },
      (error) => {
        this.isImporting = false;
        if (error.error?.message) {
          this.importError = error.error?.message || error.error?.message[0];
          this.toastrService.error(this.importError, 'Error', {
            timeOut: 5000
          });
        }
      }
    );
  }

  getTermsAndCondition() {
    this.cartList.forEach(cart => {
      this.termsAndConditions.push(...cart?.terms_and_conditions);
    });
    this.termsAndConditions = [...new Map(this.termsAndConditions.map(item => [item['code'], item])).values()];
    this.allTermAccepted = this.termsAndConditions?.length ? false : true;
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.cartImportForm.patchValue({
        import_file: file
      });
    }
  }

  incrementQuantity(cartUuid, line) {
    line.quantity = line.quantity + 1;
    this.updateCartCartItem(cartUuid, line);
  }

  decrementQuantity(cartUuid, line) {
    if (line.quantity < 1) {
      return;
    }

    line.quantity = line.quantity - 1;
    this.updateCartCartItem(cartUuid, line);
  }

  onEnter(cartUuid, line) {
    if (line.quantity < 1) {
      return;
    }
    this.updateCartCartItem(cartUuid, line);
  }

  updateCartCartItem(cartUuid, line) {
    this.isLoading = true;
    const payload = {
      quantity: line.quantity
    };
    this.cartService.updateCartLine(cartUuid, line.uuid, payload).subscribe(
      res => {
        this.isLoading = false;
        const index = this.findIndexInCartList(cartUuid);
        this.cartList[index] = {
          ...this.cartList[index],
          ...res.data
        };
        this.showSuccessToaster(this.translocoService.translate('IMPORT_ORDER.UPDATED_SUCCESSFULLY'));
      }, err => {
        this.getCachedCart(cartUuid, line);
        this.isLoading = false;
        this.showErrorToaster(err?.error?.message);
      });
  }

  deleteCartItem(cartUuid, cartLine) {
    this.isLoading = true;
    this.cartService.deleteCartItem(cartUuid, cartLine.uuid).subscribe(res => {
      const index = this.findIndexInCartList(cartUuid);
      if (res?.data?.lines?.length) {
        this.cartList[index] = res.data;
      } else {
        this.cartList.splice(index, 1);
      }

      this.storageService.setDynamicValue('bulk_cart', JSON.stringify(this.cartList));
      this.isLoading = false;
      this.showSuccessToaster(this.translocoService.translate('IMPORT_ORDER.REMOVED_SUCCESSFULLY'));
    }, err => {
      this.isLoading = false;
    });
  }

  submitCart() {
    this.isSubmitting = true;
    const carts = {
      carts_uuid: this.cartList.map(data => data.uuid)
    };
    this.importOrderService.createOrder(carts).subscribe(response => {
      this.isSubmitting = false;
      this.storageService.removeDynamicValue('bulk_cart');
      this.showSuccessToaster(this.translocoService.translate('IMPORT_ORDER.SUCCESSFULLY_CREATED'));
      this.router.navigateByUrl('/order-status');
    }, error => {
      this.isSubmitting = false;
      this.showErrorToaster(error?.error?.message);
    });
  }

  private findIndexInCartList(cartUuid): number {
    return this.cartList.findIndex(data => data.uuid === cartUuid);
  }

  private getCachedCart(cartUuid, line) {
    const cart = this.storageService.getDynamicValue('bulk_cart');
    if (cart && cart.length) {
      const cartIdx = cart.findIndex(data => data.uuid === cartUuid);
      const lineIdx = cart[cartIdx].lines.findIndex(data => data.uuid === line?.uuid);
      const qty = cart[cartIdx]?.lines[lineIdx]?.quantity ?? 0;
      this.cartList[cartIdx].lines[lineIdx].quantity = qty;
    }
  }

  showErrorToaster(msg) {
    if (msg) {
      this.toastrService.error(`${msg}`, 'Error', {
        timeOut: 4000
      });
    }
  }

  showSuccessToaster(msg) {
    this.toastrService.success(`${msg}`, 'Success', {
      timeOut: 4000
    });
  }

  onChangeTermAndCondition(event) {
    const payload = {
      cart_uuids: this.cartList.map(data => data.uuid),
      terms_and_conditions: this.termsAndConditions?.map(item => {
        return {
          code: item?.code,
          is_accepted: event
        };
      })
    };
    this.allTermAcceptedLoader = true;
    this.importOrderService.updateCartsTermsAndConditions(payload).subscribe(response => {
      this.allTermAccepted = event;
      this.allTermAcceptedLoader = false;
    }, error => {
      this.allTermAccepted = !this.allTermAccepted;
      this.allTermAcceptedLoader = false;
    });

  }
}
