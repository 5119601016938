import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class GenericToasterService {
    constructor(
        private toastrService: ToastrService
    ) { }

    public genericToaster(status: string = null, message: string = null, subMessage: string = null) {
        switch (status) {
          case 'success':
            this.toastrService.success(message, subMessage);
            break;
          case 'error':
            this.toastrService.error(message, subMessage);
            break;
          case 'info':
            this.toastrService.info(message, subMessage);
            break;
          default:
            this.toastrService.info('Hello! this is your default message', 'Info');
            break;
        }
    }
}
