import { TranslocoService } from '@ngneat/transloco';
import { Component, OnDestroy, OnInit } from "@angular/core";
import { forkJoin, Observable, Subject } from "rxjs";
import { QuoteDetailsService } from "./quote-details.service";
import { ActivatedRoute, Router } from "@angular/router";
import { StorageService } from "src/app/shared/storage.service";
import { ToastrService } from "ngx-toastr";
import * as _ from "lodash";
import { NotificationService } from 'src/app/component/notification/notification.service';
import { ErrorHandleService } from "src/app/shared/error-handle.service";
import { EQuoteStatus, EQuoteSystemType } from "src/app/shared/enums/order-status.enums";
import { CartService } from '../cart/cart.service';
import { systemTypeList } from "../../shared/utilities/static_variables";
import { BasePermission } from "src/app/shared/base/base-permission";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/app.state";
import { FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { QuotesService } from "../quotes/quotes.service";
import { NgbModal, NgbModalRef } from "@ng-bootstrap/ng-bootstrap";
import { selectSiteProfile } from "src/app/store/selectors/siteProfile.selectors";
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { selectPermissions } from 'src/app/store/selectors/permission.selectors';
import * as moment from 'moment';
import { CustomerService } from '../customer/customer.service';
import { EProductTypes, EQuoteProductType, ESupplierTypes } from 'src/app/shared/enums/product-type.enums';



@Component({
  selector: "app-quote-details",
  templateUrl: "./quote-details.component.html",
  styleUrls: ["./quote-details.component.scss"],
})
export class QuoteDetailsComponent extends BasePermission implements OnInit, OnDestroy {
  quote: any;
  previousQuote = null;
  isLoading = false;
  defaultCustomer = false;

  discountType = "In amount";
  showUpdate = false;
  quoteUuid = null;
  quoteUpdateError = null;

  isEmailButtonLoading = false;
  emailButtonText = "Stuur e-mail";
  isEmailSent = false;
  public contactPersons = [];
  public accountManagerList = [];
  public signingManagerList = [];
  public templates = [];
  referanceID = null;
  approve = false;
  quoteStatus = "";
  public serviceTypeList = [
    { name: '8/5', value: 1 },
    { name: '24/7', value: 2 }
  ];
  errors = null;

  disableUpdate = true;
  user = null;
  orderCreateLoading = false;
  quoteLine: any[] = [];
  // canUpdatePPSystemTypeList: any[] = [
  //   { key: 'ingram_micro_hp', value: 'INGRAM MICRO HP' },
  //   { key: 'exclusive_networks', value: 'Exclusive Networks' },
  // ];
  canUpdatePP = true;
  isSubmitting = false;
  isQuoteGenerating = false;
  creatingNewQuote = false;
  isImported = false;
  quoteImportForm: UntypedFormGroup;
  isImporting = false;
  systemType = [
    {
      name: "GENERIC",
      code: "generic"
    },
    {
      name: "INGRAM",
      code: "ingram"
    }, {
      name: "EXCLUSIVE",
      code: "exclusive"
    },
  ];

  disabledApproveStatus = [
    'approved',
    'processed'
  ];

  public selectedQuoteLine = null;
  public modalReference: NgbModalRef;
  public priceType = [
    {
      name: "USAGES",
      code: "usages"
    },
    {
      name: "DISCOUNT",
      code: "discount"
    }, {
      name: "PRICE",
      code: "price"
    },
  ];
  public usagesPriceError = '';
  public usagesPriceSavingLoader = false;
  public quoteDownloading = false;
  public quoteZippedDownloading = false;
  public siteProfile = null;
  public po_number = '';
  public reference = '';
  public customer_reference = '';
  private debounceTimer: any;
  public contactFreeFields = [];
  public freeFields = [];
  public freeFieldsPayload = {
    user_defined_field_code: '',
    value: ''
  };

  priceRequestLoader = false;

  public termsAndConditions = [];
  public termAccepted = false;
  public documentUploadForm: UntypedFormGroup;
  public isDocumentUploading = false;
  public qutoteBiddingDocuments = null;
  public attachmentDocuments = null;
  public expiryDateMin = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
  public disableExpiryDateUpdate = true;
  config = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0,
    id: 'bidding_documents_pagination'
  };

  attachmentConfig = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0,
    id: 'attachment_documents_pagination'
  }
  approveRemarkForm: FormGroup;

  approving = false;
  remarksError = null;
  departments = [];
  public departmentPayload = {
    department_code: '',
  };
  public wordContext = null;
  tableKeys: string[] = [];

  public requestedDateMin = null;
  public requested_delivery_date = '';
  public quoteContactPersons = [];
  public quoteShippingAddresses = [];

  public selectedQuoteContactPerson = null;
  public selectedQuoteContactPersonTitle = "";
  public selectedQuoteShippingAddress = null;
  public hasCpaas = null;
  public isCpassDataAdded = false;
  public cPaasForm: UntypedFormGroup;
  public validity_in_days = "";
  public termsAndConditionLoading = false;

  constructor(
    private quoteDetailsService: QuoteDetailsService,
    private customerService: CustomerService,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private notificationService: NotificationService,
    private errorHandleService: ErrorHandleService,
    private cartService: CartService,
    public store: Store<IAppState>,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private sanitizer: DomSanitizer,
    public utilityService: UtilityService,

  ) {
    super(store);
    this.quoteUuid = this.route.snapshot.params.quote_uuid;
    this.user = this.storageService.getCurrentUser();
    this.requestedDateMin = this.utilityService.getNextDay(new Date())

    this.approveRemarkForm = this.fb.group({
      special_bidding_remarks: [''],
      buyer_email: ['', Validators.email],
      approve: [true],
      dont_send_approve_email: [false]
    });

  }

  get eQuoteStatus() { return EQuoteStatus; }
  get eQuoteSystemType() { return EQuoteSystemType; }
  get eQuoteProductType() { return EQuoteProductType; }
  get eProductType() { return EProductTypes; }
  get file() { return this.documentUploadForm.get('file'); }
  get hidePrice(): boolean {
    return ((this.user?.contact_group?.is_external) && (this.quote?.status === this.eQuoteStatus?.PENDING))
  }
  ngOnInit() {
    if (this.quoteUuid) {
      this.getQuoteDetails();
    }
    this.getManagerList();
    this.permissionSubs$ = this.store.select(selectPermissions).subscribe((permission) => {
      if (this.hasViewQuotationTemplate) {
        this.getTemplateList();
      }
      if (this.hasViewContactDepartment) {
        this.getDepartments();
      }
    });


    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
    this.documentUploadForm = this.fb.group({ file: ['', Validators.required] });
  }

  getDepartments() {
    this.quoteDetailsService.getDepartments().subscribe(res => {
      this.departments = res?.data?.results;
    })
  }

  initializeQuoteImportForm() {
    this.quoteImportForm = this.fb.group({
      'external_system': new UntypedFormControl(this.systemType[0]?.code, [Validators.required]),
      'import_file': new UntypedFormControl('', [Validators.required]),
      'source_file': new UntypedFormControl('', [Validators.required]),
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.quoteImportForm.patchValue({
        import_file: file
      });
    }
  }

  importQuote(importType = 'new') {
    const formData: FormData = new FormData();
    for (const key in this.quoteImportForm.value) {
      if (key !== 'source_file') {
        formData.append(key, this.quoteImportForm.get(key).value);
      }
    }
    this.isImporting = true;
    if (importType === 'update') {
      formData.delete('external_system');
    }
    this.quoteDetailsService.importQuote(this.quoteUuid, formData, importType).subscribe(
      (res) => {
        this.isImporting = false;
        this.toastrService.success(res?.data?.message, `${this.translocoService.translate('QUOTE_DETAILS.THE_QUOTATION_WAS_IMPORTED_SUCCESSFULLY')}`, {
          timeOut: 5000
        });
        this.quoteUuid = res?.data?.uuid;
        this.router.navigate(['/quote-details', `${this.quoteUuid}`]);
        this.getQuoteDetails();
      },
      (error) => {
        this.isImporting = false;
        let errMsg = error.error?.message || error.error?.error?.message || error.error?.message[0]
        this.toastrService.error(errMsg, `${error?.status}`, {
          timeOut: 5000
        });
      }
    )
  }

  enableUpdate() {
    this.disableUpdate = false;
    this.enableExpiryDateUpdate();
    this.showToaster(`${this.translocoService.translate('QUOTE_DETAILS.UPDATE_OPTION_ENABLED')}`);
  }

  enableExpiryDateUpdate() {
    this.disableExpiryDateUpdate = false;
  }
  cancelExpiryDateUpdate() {
    this.removeErrorMessages();
    this.disableExpiryDateUpdate = true;
  }
  removeErrorMessages() {
    this.quoteUpdateError = null;
    this.errors = {};
  }
  cancleUpdate() {
    this.removeErrorMessages();
    this.disableUpdate = true;
    this.cancelExpiryDateUpdate();
    this.getQuoteDetails();
  }

  onApprove() {
    this.quoteDetailsService.getApproval(this.quote.uuid).subscribe(
      (res) => {
        this.getQuoteDetails();
        this.modalService?.dismissAll();
      },
      (err) => {
        this.remarksError = err?.error?.error;
        this.getQuoteDetails();
      }
    );
  }

  onApproveModal(approveQuoteModal) {
    this.remarksError = null;
    this.modalReference = this.modalService.open(approveQuoteModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
    }).catch(e => {
      console.log('e: ', e);
    });
  }

  updateQuoteWithRemarks() {
    this.approving = true;
    this.remarksError = null;
    let payload = this.approveRemarkForm.getRawValue();
    if (payload?.approve && !payload?.buyer_email && !payload?.special_bidding_remarks) {
      this.approving = false;
      this.onApprove();
    } else {

      if (!payload?.buyer_email) {
        delete payload.buyer_email
      }

      if (!payload?.special_bidding_remarks) {
        delete payload.special_bidding_remarks
      }
      this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, { ...payload }).subscribe(res => {
        if (payload?.approve) {
          this.approving = false;
          this.onApprove();
        } else {
          this.approve = false;
          this.approving = false;
          this.modalService?.dismissAll();
        }

        this.approveRemarkForm?.reset();
      }, error => {
        this.approving = false;
        this.remarksError = error?.error?.error;
      })
    }

  }

  hideQuoteLine(event: any, idx: number) {
    this.quote.lines[idx] = {
      ...this.quote.lines[idx],
      extra_data: {
        ...this.quote.lines[idx].extra_data,
        is_hidden: event
      }
    };
    this.checkQuoteChange(this.quote);
  }

  checkApproval(status) {
    if (this.disabledApproveStatus?.includes(status)) {
      this.quoteStatus = status;
      this.approve = true;
      this.emailButtonText = `${this.translocoService.translate('QUOTE_DETAILS.SEND_EMAIL')}`;
      this.initializeQuoteImportForm();
    } else if (status === "new") {
      this.quoteStatus = "new";
      this.approve = false;
      this.emailButtonText = `${this.translocoService.translate('QUOTE_DETAILS.SEND_DRAFT_EMAIL')}`;
      if (this.quote?.is_external_quote) {
        this.initializeQuoteImportForm();
      }
    }
    else if (status === this.eQuoteStatus.PENDING) {
      this.quoteStatus = status;
      this.approve = false;
      this.emailButtonText = `${this.translocoService.translate('QUOTE_DETAILS.SEND_DRAFT_EMAIL')}`;
      if (this.quote?.is_external_quote) {
        this.initializeQuoteImportForm();
      }
    }
  }

  getQuoteDetails() {
    this.isLoading = true;
    this.quoteDetailsService.getQuote(this.quoteUuid).subscribe(
      (res) => {
        this.checkSystemCode(res.data);
        this.checkApproval(res.data.status);
        this.getQuoteContactDetails();
        if (this.freeFieldsPermission?.view_contact_free_field && res?.data?.contact?.uuid) {
          this.getAvailableFreeFieldOfContact(res?.data?.contact?.uuid);
        }

        if (this.freeFieldsPermission?.view_free_field && res?.data?.contact?.uuid) {
          this.getAvailableFreeField();
        }


        if (res.data.status === 'approved' && !this.termsAndConditions?.length) {
          this.getQuoteTermsAndConditions();
        }

        // if user is not customer
        if (this.user?.is_external === false) {
          this.getQuoteBiddingDocuments(this.quoteUuid);
        }
        this.getAttachments(this.quoteUuid);
        // this.processQuote(res.data);
      },
      (err) => {
        this.isLoading = false;
        console.log("err: ", err);
      }
    );
  }

  getQuoteWordContext(quoteUuid: string, wordContextModal) {
    this.quoteDetailsService.getQuoteWordContext(quoteUuid).subscribe(res => {
      this.wordContext = res?.data ?? null;
      if (this.wordContext && Object.keys(this.wordContext)?.length) {
        this.tableKeys = this.getKeys(res?.data);
        this.modalService.open(wordContextModal, {
          ariaLabelledBy: 'modal-basic-title',
          centered: true,
          size: 'xl',
          scrollable: true,
          backdrop: 'static',
        });
      }
      else {
        this.toastrService.info('No word context found', 'Info');
      }
    }, error => {
      this.toastrService.error(error?.message, error?.status)
    })
  }



  getAvailableFreeFieldOfContact(contact_uuid: string) {
    this.quoteDetailsService.availableFreeFieldOfContact(contact_uuid).subscribe(res => {
      this.contactFreeFields = res?.data?.results?.map(item => {
        return {
          ...item,
          name: item?.user_defined_field_code?.replace(/[_]/g, ' ')
        }
      });
    }, error => {
      console.log('error: ', error);
    })
  }

  getAvailableFreeField() {
    this.quoteDetailsService.availableFreeField().subscribe(res => {
      this.freeFields = res?.data?.results?.map(item => {
        return {
          ...item,
          name: item?.description || item?.code
        }
      })
    }, error => {
      console.log('error: ', error);
    })
  }

  addFreeFieldToContact() {
    if (!this.freeFieldsPermission.add_contact_free_field || !this.freeFieldsPayload?.value?.trim()?.length) {
      return;
    }
    this.quoteDetailsService.addFreeFieldToContact(this.quote.contact?.uuid, this.freeFieldsPayload).subscribe(res => {
      let addedField = {
        ...res?.data,
        name: res?.data?.user_defined_field_code?.replace(/[_]/g, ' ')
      }
      this.contactFreeFields.unshift(addedField)
      this.onSelectFreeFields(null);
    }, error => {
      this.toastrService.error(error?.error?.message, error.status)
    });
  }

  private checkSystemCode(data: any) {
    this.canUpdatePP = systemTypeList.findIndex(obj => obj.key === data?.system_code) < 0;
    this.isImported = !this.canUpdatePP;
    this.processQuote(data);
  }


  sortPrice(prices = []) {
    const sort_price = prices.sort((a, b) => {
      const nameA = a.currency.toUpperCase();
      const nameB = b.currency.toUpperCase();
      if (nameA > nameB) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    });
    return sort_price;
  }

  formatPrice(line_prices) {
    if (line_prices) {
      for (const key in line_prices) {
        if (Object.prototype.hasOwnProperty.call(line_prices, key)) {
          line_prices[key] = this.sortPrice(line_prices[key]);
        }
      }
    }
    return line_prices;
  }

  processQuote(data) {
    this.isLoading = false;
    this.quote = data;
    this.requested_delivery_date = this.quote?.requested_delivery_date;
    this.reference = this.siteProfile?.is_reference_required ? this.quote?.reference : '';
    this.po_number = this.siteProfile?.is_po_number_required ? this.quote?.po_number : '';
    this.customer_reference = this.quote?.customer_reference ?? '';

    this.quote.lines = [...data?.lines.map(line => {
      const can_update_sp_usd = line?.line_prices?.sales_price_ex_vat?.some(item => {
        return item?.currency === 'USD';
      });
      return {
        ...line,
        can_update_pp: this?.quote?.has_special_bidding ? true : false,
        can_update_sp: line.extra_data?.is_custom ? true : !this.canUpdatePP,
        sp_unit_price: line?.price_ex_vat,
        pp_unit_price: line?.purchase_price_ex_vat,
        can_update_sp_usd,
        line_prices: this.formatPrice(line?.line_prices),
        ...(can_update_sp_usd && {
          sales_price_ex_vat_in_usd: line?.line_prices?.sales_price_ex_vat?.find(item => {
            return item?.currency === 'USD';
          })?.price_ex_vat || '0.00'
        }),
        sp_lock_loader: false,
        is_change_name: false,
        new_product_name: line?.product_name,
        product_quote_questions: line?.product_quote_questions?.map(item => {
          const isFoundInExtraData = line.extra_data?.product_quote_questions?.find(extraItem => extraItem?.attribute_code === item?.attribute_code);
          if (isFoundInExtraData) {
            return {
              ...item,
              value_boolean: isFoundInExtraData?.value_boolean,
              value_date: isFoundInExtraData?.value_date,
              value_float: isFoundInExtraData?.value_float,
              value_image: isFoundInExtraData?.value_image,
              value_integer: isFoundInExtraData?.value_integer,
              value_text: isFoundInExtraData?.value_text,
            };
          }
          else {
            return item;
          }
        }) || [],

      };
    }
    )];

    this.hasCpaas = this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS) || null;
    if (this.hasCpaas) {
      let cPassData = {
        cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
        cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
        cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
      }
      this.isCpassDataAdded = (cPassData.cpaas_user_email?.trim() && cPassData.cpaas_user_first_name?.trim() && cPassData.cpaas_user_last_name?.trim()) ? true : false;
      this.cPaasForm = this.fb.group({
        cpaas_user_email: new UntypedFormControl(cPassData.cpaas_user_email, [Validators.required, Validators.email, this.noTailingSpaceValidator],),
        cpaas_user_first_name: new UntypedFormControl(cPassData.cpaas_user_first_name, [Validators.required]),
        cpaas_user_last_name: new UntypedFormControl(cPassData.cpaas_user_last_name, [Validators.required]),
      });
    }

    this.referanceID = data.extra_data.reference_extra
      ? data.extra_data.reference_extra
      : "";
    this.previousQuote = JSON.parse(JSON.stringify(data));
    if (data.contact.company_name === "default") {
      this.defaultCustomer = true;
      this.notificationService.setNotification('warning', `${this.translocoService.translate('QUOTE_DETAILS.NO_CUSTOMER_EXIT')}.`);
    }
    this.checkSelectedCustomer(data.contact);

    this.checkAllTermAccepted();
    //this.quote?.lines?.every(line => line?.quotation_line_extra?.terms_accepted);
    this.departmentPayload.department_code = data?.department?.code || ''
  }
  public noTailingSpaceValidator(control: UntypedFormControl) {
    if (control?.value?.startsWith(' ')) {
      return {
        'trimError': { value: `E-mail begint met spaties` }
      };
    }
    if (control?.value?.endsWith(' ')) {
      return {
        'trimError': { value: `E-mail eindigt met spaties` }
      };
    }

    return null;
  }


  saveCpassData() {
    this.updateQuotation(this.quote);
  }
  saveCpassDataOnly() {
    this.errors = {};
    this.quoteUpdateError = null;
    this.isLoading = true;
    const payload = {
      cpaas_user_email: this.cPaasForm.get('cpaas_user_email').value,
      cpaas_user_first_name: this.cPaasForm.get('cpaas_user_first_name').value,
      cpaas_user_last_name: this.cPaasForm.get('cpaas_user_last_name').value
    };
    this.quoteDetailsService.updateCpaasUserInfo(this.quoteUuid, payload).subscribe(res => {
      this.disableUpdate = true;
      this.processQuote(res.data);
      this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTATION_SUCCESSFULLY_UPDATED')}`);
    }, error => {
      this.processError(error);
    })
  }

  checkTermsCondition(quoteTermAndAccepted) {
    if (this.termAccepted) {
      this.createOrder();
    } else {
      this.modalReference = this.modalService.open(quoteTermAndAccepted, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'xl',
        scrollable: true,
        backdrop: 'static',
      });
      this.modalReference.result.then((result) => {
        // this.selectedQuoteLine = null;
      }).catch(e => {

      });
    }
  }

  getQuoteTermsAndConditions() {
    this.quoteDetailsService.getQuoteTermsAndConditions(this.quoteUuid).subscribe(res => {
      this.termsAndConditions = res?.data?.results?.map(item => {
        return {
          ...item,
          description: this.getSafeHtml(item?.description?.replace(/\/A/gi, '\/a') || item?.name)
        }
      });
      this.formatTnCData(this.quote?.lines, this.termsAndConditions);
    })
  }

  createOrder() {
    const payload = {
      quote_uuid: this.quote?.uuid
    };
    this.orderCreateLoading = true;
    this.cartService.createOrderFromQuote(payload).subscribe((res) => {
      this.orderCreateLoading = false;
      this.quote.status = EQuoteStatus.PROCESSED;
      // this.notificationService.setNotification('success', `${this.translocoService.translate('QUOTE_DETAILS.ORDER_CREATION_SUCCESS')}`);
      this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.ORDER_CREATION_SUCCESS')}`);
      this.closeModal();
    }, error => {
      this.orderCreateLoading = false;
      // this.notificationService.setNotification('error', `${this.translocoService.translate('QUOTE_DETAILS.ORDER_CREATION_FAILED')}`);
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.ORDER_CREATION_FAILED')}`);
      this.closeModal();
    });
  }

  closeModal() {
    if (this.modalService.hasOpenModals()) {
      this.modalService?.dismissAll()
    }
  }


  formatTnCData(quoteLines = [], termAndConditions = []) {
    this.termsAndConditions = termAndConditions?.map(item => {
      return { ...item, checked: this.getCheckedValueInQuote(quoteLines, item.code) };
    });
    this.checkAllTermAccepted();
  }

  getCheckedValueInQuote(quote_lines, code) {
    return quote_lines.every(line => {
      return line?.quotation_line_extra?.extra_info?.terms_and_conditions?.[code];
    });
  }

  checkAllTermAccepted() {
    this.termAccepted = !this.termsAndConditions?.length ? true : this.termsAndConditions.every(item => item.checked);
  }

  onChangeTermAndCondition(term) {
    this.termsAndConditionLoading = true;
    const payload = {
      code: term?.code,
      is_accepted: term?.checked
    };
    this.quoteDetailsService.updateQuoteTermsAndConditions(this.quoteUuid, payload).subscribe(response => {
      this.formatTnCData(response?.data?.lines, this.termsAndConditions);
      this.termsAndConditionLoading = false;
    }, error => {
      this.termsAndConditionLoading = false;
      term.checked = !term.checked;
      this.checkAllTermAccepted();
    });

  }

  checkSelectedCustomer(contact) {
    const selectedCustomerExternalId1 = this.getSelectedCustomer() ? this.getSelectedCustomer().external_id1 : null;
    const quoteDetailsCustomerExternalId1 = contact ? contact.external_id1 : null;

    if (selectedCustomerExternalId1 && selectedCustomerExternalId1 !== quoteDetailsCustomerExternalId1) {
      this.notificationService.setNotification('warning', `${this.translocoService.translate('QUOTE_DETAILS.CUSTOMER_DOES_NOT_MATCH_THE_SELECTED_CUSTOMER')}`);
    }
  }

  getSelectedCustomer() {
    return this.storageService.getCustomer() || null;
  }

  selectCustomer() {
    this.storageService.setQuoteUuid(this.quote.uuid);
    const queryParams = this.isImported ? { importedQuote: this.isImported } : {};
    this.router.navigate(['/customers'], { queryParams });
  }

  createNewQuote(uuid: string) {
    this.creatingNewQuote = true;
    this.quoteDetailsService.createNewQuote(uuid).subscribe(
      (res) => {
        this.toastrService.success(res?.data?.message, 'Success');
        this.quoteUuid = res?.data?.uuid;
        this.getQuoteDetails();
        this.creatingNewQuote = false;
        this.router.navigate(['/quote-details', `${res?.data?.uuid}`]);
      }, error => {
        this.creatingNewQuote = false;
        this.toastrService.error(error?.error?.message, 'Error');
      }
    );
  }

  gotoPreviousPage() {
    this.router.navigate(["/quotes"]);
  }

  updateQuoteReference() {
    if (this.reference?.trim()?.length) {
      let payload = {
        uuid: this.quote.uuid,
        reference: this.reference
      }
      this.updateQuotePoReference(payload);
    }
  }

  updateQuotePoNumber(event) {
    const value = event.target.value;
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      let payload = {
        uuid: this.quote.uuid,
        po_number: value || '',
        customer_reference: this.customer_reference || ''
      }
      this.updateQuotePoReference(payload);

    }, 1000);
  }
  onKeyEnterUpdateQuotePoNumber(event) {
    const value = event.target.value;
    let payload = {
      uuid: this.quote.uuid,
      po_number: value || '',
      customer_reference: this.customer_reference || ''
    }
    this.updateQuotePoReference(payload);
  }

  updateQuoteCustomerReference(event) {
    const value = event.target.value;
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    this.debounceTimer = setTimeout(() => {
      let payload = {
        uuid: this.quote.uuid,
        customer_reference: value || '',
        po_number: this.po_number || ''
      }
      this.updateQuotePoReference(payload);

    }, 1000);

  }
  onKeyEnterUpdateQuoteCustomerReference(event) {
    const value = event.target.value;
    let payload = {
      uuid: this.quote.uuid,
      customer_reference: value || '',
      po_number: this.po_number || ''
    }
    this.updateQuotePoReference(payload);
  }

  updateQuotation(quote) {
    this.errors = {};
    this.quoteUpdateError = null;
    this.isLoading = true;
    const quoteObj = JSON.parse(JSON.stringify(quote));
    this.quoteDetailsService.updateQuote(this.quotePreProses(quoteObj)).subscribe(
      (res) => {
        this.disableUpdate = true;
        this.cancelExpiryDateUpdate();
        this.showUpdate = false;
        this.processQuote(res.data);
      },
      (error) => {
        this.processError(error)
      }
    );
  }

  processError(error) {
    this.isLoading = false;
    this.showUpdate = true;
    this.quoteUpdateError = error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
    this.toastrService.error(this.quoteUpdateError, 'Error');
    const errors = this.errorHandleService.errorHandle(error?.error);
    this.handleErrors(errors);
  }

  updateQuotePoReference(payload) {
    this.errors = {};
    this.quoteUpdateError = null;
    this.isLoading = true;
    this.quoteDetailsService.updateQuoteWithPoReference(payload).subscribe(
      (res) => {
        this.disableUpdate = true;
        this.showUpdate = false;
        this.processQuote(res.data);
        this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTATION_SUCCESSFULLY_UPDATED')}`);
      },
      (error) => {
        this.processError(error)
      }
    );
  }

  handleErrors(error) {
    this.errors = {};
    this.errors['account_manager'] = error?.account_manager;
    this.errors['signing_manager'] = error?.signing_manager;
    // this.errors['inside_sales_manager'] = error?.inside_sales_manager;
    this.errors['template'] = error?.template;
    this.errors['expire_date'] = error?.expire_date;
  }

  applyActiveExchangeRate(quote) {
    quote.exchange_rate = quote.active_exchange_rate.id;
    this.updateQuotation(quote);
  }

  reCalculateQuote(quote) {
    this.quoteUpdateError = null;
    this.isLoading = true;
    this.quoteDetailsService.getRecalculation(this.quoteUuid).subscribe(
      (res) => {
        this.disableUpdate = true;
        this.showUpdate = false;
        this.getQuoteDetails();
      },
      (error) => {
        this.isLoading = false;
        this.quoteUpdateError = `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
      }
    );
  }

  quotePreProses(quote) {
    if (quote?.inside_sales_manager) {
      delete quote.inside_sales_manager;
    }
    if (quote?.expire_date) {
      quote.expire_date = moment(quote.expire_date).format('YYYY-MM-DDTHH:mm:ss')
    }
    if (quote.validity_in_days) {
      quote.validity_in_days = parseInt(quote.validity_in_days);
    }

    return {
      ...quote,
      lines: quote.lines.map((line) => {
        if (line?.discount_percentage + ''?.trim() === "") {
          line.discount_percentage = 0;
        }
        line.extra_data = {
          ...line.extra_data,
          product_quote_questions: line?.product_quote_questions ?? [],
        };
        if (this.hasCpaas && (line?.supplier_export_id === ESupplierTypes.CPAAS)) {
          line.quotation_line_extra.extra_info.cpaas_user_email = this.cPaasForm.get('cpaas_user_email').value;
          line.quotation_line_extra.extra_info.cpaas_user_first_name = this.cPaasForm.get('cpaas_user_first_name').value;
          line.quotation_line_extra.extra_info.cpaas_user_last_name = this.cPaasForm.get('cpaas_user_last_name').value
        }
        return line;
      }),
      account_manager: quote?.account_manager?.uuid || null,
      // inside_sales_manager: quote?.inside_sales_manager?.uuid || null,
      signing_manager: quote?.signing_manager?.uuid || null,
      quote_template: quote?.quote_template?.code || null,
      template: quote?.template?.uuid ? quote?.template?.uuid : (quote?.template || null),
    };
  }

  checkQuoteChange(quote) {
    const updated = JSON.stringify(quote);
    const previousQuote = JSON.stringify(this.previousQuote);
    if (updated === previousQuote) {
      this.showUpdate = false;
    } else {
      this.showUpdate = true;
    }
  }


  onChangeExpiryDateDate(event) {
    this.quote.expire_date = event;
    this.checkQuoteChange(this.quote);
  }

  onValidityDaysChange(event) {
    this.quote.validity_in_days = parseInt(event);
    this.checkQuoteChange(this.quote);
  }

  updateQtyInQuote(line) {
    line.qty = +line.qty;
    this.checkQuoteChange(this.quote);
  }

  incrementQty(event: EventListener, line) {
    line.qty++;
    this.checkQuoteChange(this.quote);
  }

  updatePP() {
    this.checkQuoteChange(this.quote);
  }

  decrementQty(event: EventListener, line) {
    line.qty--;
    if (line.qty <= 0) {
      line.qty = 1;
    }
    this.checkQuoteChange(this.quote);
  }

  backtoPreviousState() {
    this.isEmailSent = false;
    this.checkApproval(this.quoteStatus);
  }

  sendEmail() {
    if (!this.isEmailSent) {
      this.isEmailButtonLoading = true;
      this.emailButtonText = `${this.translocoService.translate('QUOTE_DETAILS.TO_STEER')}`;
      this.quoteDetailsService.sendEmail(this.quote.uuid).subscribe(
        (res) => {
          this.emailButtonText = `${this.translocoService.translate('QUOTE_DETAILS.SENT')}`;
          this.isEmailButtonLoading = false;
          this.isEmailSent = true;
          setTimeout(() => this.backtoPreviousState(), 3000);
        },
        (error) => {
          this.emailButtonText = `${this.translocoService.translate('QUOTE_DETAILS.SEND_EMAIL')}`;
          this.isEmailButtonLoading = false;
          this.isEmailSent = false;
        }
      );
    }
  }

  setAccountManager(data) {
    this.quote.account_manager = data;
    this.checkQuoteChange(this.quote);
  }

  setContactPersons(data) {
    if (data && [EQuoteStatus.NEW, EQuoteStatus.PENDING]?.includes(this.quote?.status) && data?.uuid !== this.quote?.contact_person?.uuid) {
      this.updateQuoteFields({ contact_person: data?.uuid }, 'Contact person');
    }
  }

  updateContactPersonTitle(data) {
    this.errors = {};
    this.isLoading = true;
    this.customerService.updateContactPerson(this.quote?.contact?.uuid, this.selectedQuoteContactPerson.uuid, {
      contact_person: {
        title: data
      }
    }).subscribe(res => {
      this.isLoading = false;
      this.disableUpdate = true;
      this.cancelExpiryDateUpdate();
      this.showUpdate = false;
      this.toastrService.success('Contact person title updated', 'Success');
    }, error => {
      this.isLoading = false;
      this.errors['contact_person_title'] = error?.error?.error?.contact_person?.title?.message;
      this.toastrService.error(`${error?.error?.error?.contact_person?.title?.message ?? 'Contact person title updated failed'}`, 'Error', {
        timeOut: 5000
      });
    })
  }
  clearErrors() {
    if (this.errors?.contact_person_title) {
      this.errors.contact_person_title = '';
    }
  }

  setShippingAddress(data) {
    if (data && [EQuoteStatus.NEW, EQuoteStatus.PENDING]?.includes(this.quote?.status) && data?.id !== this.quote?.shipping_address_details?.id) {
      this.updateQuoteFields({ shipping_address_id: data?.id }, 'Shipping address');
    }
  }

  updateQuoteFields(payload = {}, message: string) {
    this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, payload).subscribe(res => {
      this.showUpdate = false;
      this.processQuote(res.data);
      this.toastrService.success(`${message} updated`, 'Success');
    }, error => {
      this.toastrService.error(`${message} updated failed`, 'Error');
    });
  }

  setSigningManager(data) {
    this.quote.signing_manager = data;
    this.checkQuoteChange(this.quote);
  }

  setServiceType(line, data, quote) {
    line.service_type = data;
    this.checkQuoteChange(quote);
  }

  // setInsideSalesManager(data) {
  //   this.quote.inside_sales_manager = data;
  //   this.checkQuoteChange(this.quote);
  // }

  setQuotePageTemplate(data) {
    this.quote.template = data?.uuid;
    this.checkQuoteChange(this.quote);
  }

  getManagerList() {
    forkJoin({
      accountManagers: this.quoteDetailsService.getContactPerson('sales_person'),
      signingManagers: this.quoteDetailsService.getContactPerson('manager'),
    }).subscribe(
      ({ accountManagers, signingManagers }) => {
        // Process account managers
        this.accountManagerList = accountManagers.data.results.map((data) => {
          return {
            name: `${data.initials} ${data.surname_prefix ? data.surname_prefix + ' ' : ''}${data.last_name}`,
            uuid: data.uuid,
          };
        });

        // Process signing managers
        this.signingManagerList = signingManagers.data.results.map((data) => {
          return {
            name: `${data.initials} ${data.surname_prefix ? data.surname_prefix + ' ' : ''}${data.last_name}`,
            uuid: data.uuid,
          };
        });
      },
      (error) => {
        console.log('error', error);
      }
    );
  }


  getQuoteContactDetails() {
    this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
      this.quoteShippingAddresses = res?.data?.contact_addresses?.filter(address => address?.address_type === 'shipping')?.map(item => {
        return {
          ...item,
          name: `${item.zip_code} ${item.house_number} ${item.house_number_extension ? item.house_number_extension : ''} ${item.street} ${item.city} ${item.country}`,
          uuid: item.uuid,
        }
      });
      this.quoteContactPersons = res?.data?.contact_persons?.map(item => {
        return {
          ...item,
          name: `${item.initials}  ${item.first_name} ${item.last_name}`,
          uuid: item.uuid,
        };
      })
      this.selectedQuoteShippingAddress = this.quoteShippingAddresses?.find(item => item?.id === this.quote?.shipping_address_details?.id) || null;
      this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid || item?.email === this.quote?.contact_person?.email) || null;
      this.selectedQuoteContactPersonTitle = this.quote?.contact_person?.title || "";
    }, error => {
      console.log('error: ', error);

    })
  }

  getTemplateList() {
    this.quoteDetailsService.getPageTemplates().subscribe(
      (res) => {
        this.templates = res.data.results.map((data) => {
          return {
            name: data.template_name,
            code: data.template_name,
            uuid: data.uuid,
            file_url: data.template_file,
          };
        });
      },
      (error) => {
        console.log("error ", error);
      }
    );
  }

  get getSelectedTemplatePreproses() {
    if (!_.isEmpty(this.quote.template)) {
      return {
        name: this.quote.template.template_name,
        code: this.quote.template.template_name,
        uuid: this.quote.template.uuid,
        file_url: this.quote.template.template_file
      };
    }
    return {};
  }

  addPrefixName(gender) {
    if (gender?.trim() === 'F' || gender?.trim() === 'f') {
      return 'Mevrouw';
    } else if (gender?.trim() === 'M' || gender?.trim() === 'm') {
      return 'De heer';
    } else {
      return '';
    }
  }

  addMoreLine() {
    this.quote.lines.push({
      product_code: '',
      product_name: '',
      qty: 1,
      price_ex_vat: 0.0,
      purchase_price_ex_vat: 0.0,
      discount_price_ex_vat: 0.00,
      discount_percentage: 0.00,
      margin_percentage: 0.00,
      agreement_discount_percent: 0.00,
      agreement_markup_percent: 0.00,
      discount_type: "percentage",
      is_editable: false,
      extra_data: {
        allow_deletion: true,
        is_custom: true,
        is_new: true,
      },
      can_update_pp: true,
      can_update_sp: true,
      can_update_sp_usd: false,
      sales_price_ex_vat_in_usd: '0.00'
    });
  }

  onSelectProduct(event: any) {
    this.isSubmitting = true;
    this.quoteDetailsService.createCustomLine({
      product_code: event?.product?.code
    }, this.quoteUuid).subscribe((res) => {
      this.quote.lines[event?.index] = {
        ...this.quote.lines[event?.index],
        product_code: event?.product?.code,
        product_name: event?.product?.name,
      };
      this.replaceLineValue(event?.index, res?.data);
    }, (error) => {
      this.isSubmitting = false;
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.ERROR_CREATING')}`, 'error');
    });
  }

  private replaceLineValue(index: number, data: any) {
    this.quote.lines[index] = {
      ...this.quote.lines[index],
      sp_unit_price: data?.price_ex_vat,
      pp_unit_price: data?.purchase_price_ex_vat,
      is_change_name: false,
      new_product_name: this.quote.lines[index]?.product_name,
      extra_data: {
        ...this.quote.lines[index]?.extra_data,
        is_new: false,
      },
      ...data
    };
    this.isSubmitting = false;
  }

  deleteLine(line: any, index: number) {
    if (line?.uuid) {
      this.isSubmitting = true;
      this.quoteDetailsService.deleteCustomLine({
        quoteUuid: this.quoteUuid,
        quoteLineUuid: line?.uuid,
      }).subscribe((res) => {
        this.removeLine(index);
        this.isSubmitting = false;
        this.toastrService.success(res?.data?.message, 'Success');
      },
        (error) => {
          this.toastrService.error(error?.data?.message || `${this.translocoService.translate('QUOTE_DETAILS.ERROR_REMOVING')}`, 'error');
        }
      );
    } else {
      this.removeLine(index);
    }

  }

  private removeLine(index: number) {
    this.quote.lines.splice(index, 1);
  }

  saveLine(line: any, index: number) {
    this.isSubmitting = true;
    this.quoteDetailsService.updateCustomLine({
      qty: line?.qty,
      price_ex_vat: line?.price_ex_vat,
      discount_price: line?.discount_price_ex_vat,
      purchase_price_ex_vat: line?.purchase_price_ex_vat,
      discount_type: line?.discount_type,
      discount_percentage: line?.discount_percentage,
    }, {
      quoteUuid: this.quoteUuid,
      quoteLineUuid: line?.uuid
    }).subscribe((res) => {
      this.replaceLineValue(index, res?.data);
      this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.AMENDED')}`, 'Success');
    },
      (error) => {
        this.isSubmitting = false;
        this.toastrService.error(error?.error?.message || error?.error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.ERROR_DURING_UPDATE')}.`, 'error');
      }
    );
  }

  changeProductName(line: any, index: number) {
    if (!line?.new_product_name?.length) {
      return;
    }
    this.isSubmitting = true;
    this.quoteDetailsService.updateCustomLine({
      product_name: line?.new_product_name,
    }, {
      quoteUuid: this.quoteUuid,
      quoteLineUuid: line?.uuid
    }).subscribe((res) => {
      this.replaceLineValue(index, res?.data);
      this.toastrService.success('De productnaam is gewijzigd!', 'Success');
    },
      (error) => {
        this.isSubmitting = false;
        this.toastrService.error(error?.error?.message || error?.error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.ERROR_DURING_UPDATE')}.`, 'error');
      }
    );
  }

  generateQuote() {
    this.isQuoteGenerating = true;
    this.quoteDetailsService.generateQuote(this.quote?.uuid).subscribe((res) => {
      this.isQuoteGenerating = false;
      this.quote = {
        ...this.quote,
        generated_quotation: res?.data?.generated_quotation
      };
    }, (error) => {
      this.isQuoteGenerating = false;
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.ERROR_CREATING_THE_QUOTATION')}.`, 'error');
    });
  }

  downloadQuote(url: string) {
    this.quoteDownloading = true;
    this.quoteDetailsService.downloadQuote(this.quote?.uuid).subscribe(response => {
      this.quoteDetailsService.processDownloadFileResponse(response)
      this.quoteDownloading = false;
      this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTE_DOWNLOAD_SUCCESS')}`, 'Quote Download');
    }, async error => {
      this.quoteDownloading = false;
      const message = JSON.parse(await error.error.text()).message;
      this.toastrService.error(message || `${this.translocoService.translate('QUOTE_DETAILS.QUOTE_DOWNLOAD_FAILED')}`, 'Quote Download');
    });
  }

  downloadQuoteZippedFile() {
    this.quoteZippedDownloading = true;
    this.quoteDetailsService.downloadQuoteZipped(this.quote?.uuid).subscribe(response => {
      this.quoteDetailsService.processDownloadFileResponse(response, true)
      this.quoteZippedDownloading = false;
      this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTE_DOWNLOAD_SUCCESS')}`, 'Quote Zipped Download');
    }, async error => {
      this.quoteZippedDownloading = false;
      const message = JSON.parse(await error.error.text()).message;
      this.toastrService.error(message || `${this.translocoService.translate('QUOTE_DETAILS.QUOTE_DOWNLOAD_FAILED')}`, 'Quote Download');
    });
  }

  onReferenceInput(e) {
    this.quote.extra_data.reference_extra = e;
    this.checkQuoteChange(this.quote);
  }

  selectDiscountType(line) {
    line.discount_type = line.discount_type === "flat" ? "percentage" : "flat";
    this.checkQuoteChange(this.quote);
  }

  selectQuoteDiscountType() {
    this.quote.discount_type =
      this.quote.discount_type === "flat" ? "percentage" : "flat";
    this.checkQuoteChange(this.quote);
  }

  showToaster(msg) {
    this.toastrService.success(`${msg}`, "Success", {
      timeOut: 2000,
    });
  }

  checkPercentageValueInLine(line, quote, is_recurring = false) {

    if (is_recurring) {
      if (line.discount_percentage_recurring_monthly_fee > 100) {
        line.discount_percentage_recurring_monthly_fee = 100;
      }
    } else {
      if (line.discount_percentage > 100) {
        line.discount_percentage = 100;
      }
    }
    this.checkQuoteChange(quote);
  }

  checkPercentageValueInTotal(quote) {
    if (quote.total_discount_percentage > 100) {
      quote.total_discount_percentage = 100;
    }
    this.checkQuoteChange(quote);
  }

  onServiceDuration(event) {
    this.quote.extra_data.total_service_duration = event;
    this.checkQuoteChange(this.quote);
  }

  lockSalesPrice(line, index) {
    line.sp_lock_loader = true;
    this.quoteDetailsService.lockSalesPrice(this.quote?.uuid, line?.uuid).subscribe(res => {
      line.extra_data = {
        ...line?.extra_data,
        lock_sales_price: true
      };

      line.sp_lock_loader = false;
      this.showToaster(res?.data?.message);
    }, error => {
      this.toastrService.error(error?.error?.message, 'Error');
      line.sp_lock_loader = false;
    });
  }

  unLockSalesPrice(line, index) {
    line.sp_lock_loader = true;
    this.quoteDetailsService.unLockSalesPrice(this.quote?.uuid, line?.uuid).subscribe(res => {
      this.toastrService.success(res?.data?.message, 'Success');
      line.extra_data = {
        ...line?.extra_data,
        lock_sales_price: false
      };
      line.sp_lock_loader = false;
      this.showToaster(res?.data?.message);
    }, error => {
      line.sp_lock_loader = false;
      this.toastrService.error(error?.error?.message, 'Error');
    });
  }


  openUsagesPriceModal(quoteLine, usagesPriceModal) {
    this.usagesPriceError = null;
    if (this.selectedQuoteLine?.uuid === quoteLine?.uuid) {
      this.selectedQuoteLine = {
        ...this.selectedQuoteLine,
        quotation_line_extra: {
          ...this.selectedQuoteLine.quotation_line_extra,
          extra_info: {
            ...this.selectedQuoteLine?.quotation_line_extra?.extra_info,
            usages_prices: this.selectedQuoteLine?.quotation_line_extra.extra_info.usages_prices?.map(item => {
              return {
                ...item,
                actual_price_ex_vat: item?.price_ex_vat,
                actual_percentage: item?.percentage,
                loading: false
              }
            })
          }
        }
      };
    } else {
      this.selectedQuoteLine = {
        ...quoteLine,
        quotation_line_extra: {
          ...quoteLine.quotation_line_extra,
          extra_info: {
            ...quoteLine?.quotation_line_extra?.extra_info,
            usages_prices: quoteLine?.quotation_line_extra.extra_info.usages_prices?.map(item => {
              return {
                ...item,
                actual_price_ex_vat: item?.price_ex_vat,
                actual_percentage: item?.percentage,
                loading: false
              }
            })
          }
        }
      };
    }
    this.modalReference = this.modalService.open(usagesPriceModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
      // this.selectedQuoteLine = null;
    }).catch(e => {

    });
  }

  closeVoucher() {
    this.removeUsagesPriceError();
    this.modalReference.close();
    this.selectedQuoteLine = null;
  }

  deleteUsagesPrice(deletedLineIndex, i) {
    this.selectedQuoteLine?.quotation_line_extra?.extra_info?.usages_prices.splice(i, 1);
    this.updateUsagesPrice();
  }
  addMoreUsagePrice() {
    this.selectedQuoteLine?.quotation_line_extra?.extra_info?.usages_prices.push({
      quantity: 0,
      price_type: 'usages',
      price_ex_vat: 0,
      percentage: 0,
      loading: false
    });
  }
  removeUsagesPriceError() {
    this.selectedQuoteLine.quotation_line_extra.extra_info.usages_prices.forEach(item => {
      if (item?.error) {
        delete item.error;
      }
    });
  }
  updateUsagesPrice(index = -1) {
    this.removeUsagesPriceError();
    if (index >= 0) {
      this.selectedQuoteLine.quotation_line_extra.extra_info.usages_prices[index].loading = true;
    } else {
      this.usagesPriceSavingLoader = true;
    }
    let payload = {
      prices: this.selectedQuoteLine?.quotation_line_extra?.extra_info?.usages_prices?.map(item => {
        return {
          quantity: item?.quantity,
          price_type: item?.price_type,
          price_ex_vat: parseFloat(item?.price_ex_vat).toFixed(2),
          percentage: item?.percentage,
        }
      })
    }
    this.quoteDetailsService.updateQuoteLineUsagesPrice(this.quote?.uuid, this.selectedQuoteLine?.uuid, payload).subscribe(res => {
      this.selectedQuoteLine = {
        ...this.selectedQuoteLine,
        quotation_line_extra: {
          ...this.selectedQuoteLine.quotation_line_extra,
          extra_info: {
            ...this.selectedQuoteLine?.quotation_line_extra?.extra_info,
            usages_prices: res?.data?.results?.map(item => {
              return {
                ...item,
                actual_price_ex_vat: item?.price_ex_vat,
                actual_percentage: item?.percentage,
                loading: false
              }
            })
          }
        }
      };
      this.usagesPriceSavingLoader = false;
    }, error => {
      if (error?.error?.error?.prices?.length) {
        error?.error?.error?.prices.forEach((item, index) => {
          if (item?.price_ex_vat || item?.quantity || item?.price_type) {
            this.selectedQuoteLine.quotation_line_extra.extra_info.usages_prices[index].error = item;
          }
        });
      }

      this.usagesPriceError = `${this.translocoService.translate('QUOTE_DETAILS.USE_PRICE_CHANGE_FAILED')}`;
      this.usagesPriceSavingLoader = false;
      if (index >= 0) {
        this.selectedQuoteLine.quotation_line_extra.extra_info.usages_prices[index].loading = false;
      } else {
        this.usagesPriceSavingLoader = false;
      }
    })

  }

  onSelectFreeFields(event) {
    this.freeFieldsPayload = {
      user_defined_field_code: event?.code || '',
      value: ""
    }
  }

  onSelectDepartment() {
    if (this.departmentPayload.department_code) {
      this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, { ...this.departmentPayload }).subscribe(res => {
        this.toastrService.success('Department updated', 'Success');
      }, error => {
        this.toastrService.error('Department updated failed', 'Error');
      })
    }
  }


  checkQuoteTotalAmount() {
    if (this.quote) {
      let total_amount_ex_vat = parseInt(this.quote?.total_amount_ex_vat);
      return total_amount_ex_vat > 10000;
    }
    return false;

  }

  requestPrice() {
    this.priceRequestLoader = true;
    this.quoteDetailsService.requestPricing(this.quote?.uuid).subscribe(res => {
      this.priceRequestLoader = false;
      this.quote = {
        ...this.quote, department: {
          code: 'sales',
          name: 'Sales'
        }
      }
      this.toastrService.success(res?.data?.message || 'Prijsaanpassing aangevraagd bij verkoopafdeling');
      window.location.reload();
    }, error => {
      this.priceRequestLoader = false;
      this.toastrService.error(error?.error?.message || 'Verzoek om prijsaanpassing is mislukt')
    })
  }

  getSafeHtml(htmlContent): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
  }

  onDocumentFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.documentUploadForm.patchValue({
        file: file
      });
    }
  }
  onDocumentSubmit() {
    if (this.documentUploadForm.valid) {
      this.isDocumentUploading = true;
      const formData = new FormData();
      formData.append('media', this.documentUploadForm.get('file').value);
      this.quoteDetailsService.uploadBiddingQuoteDocument(this.quoteUuid, formData).subscribe(res => {
        this.isDocumentUploading = false;
        this.toastrService.success(res?.data?.message, 'Success');
        this.documentUploadForm.reset();
        this.config.currentPage = 1;
        this.getQuoteBiddingDocuments(this.quoteUuid);
        this.getQuoteDetails();
      }, error => {
        this.isDocumentUploading = false;
        this.toastrService.error(error?.error?.message, 'Error');
      })

    }

  }
  getQuoteBiddingDocuments(quoteLineUuid: string) {
    this.quoteDetailsService.getQuoteBiddingDocuments(this.config.currentPage, quoteLineUuid).subscribe(res => {
      this.config.totalItems = res?.data?.count || 0;
      this.qutoteBiddingDocuments = res?.data?.results ?? [];
    }, error => {
      this.toastrService.error(error?.error?.message, 'Error');
    })
  }
  getAttachments(quoteUuid: string) {
    this.quoteDetailsService.getAttachments(quoteUuid).subscribe(res => {
      this.attachmentDocuments = res?.data?.results ?? [];
      this.attachmentConfig.totalItems = res?.data?.count || 0;
    }, error => {
      this.toastrService.error(error?.error?.message, 'Error');
    })
  }
  downloadDocument(docUUID: string) {
    this.quoteDetailsService.downloadQuoteBiddingDocument(docUUID).subscribe(response => {
      const extension = this.utilityService.getFileExtensionByBlobType(response?.body?.type);
      const fileURL = window.URL.createObjectURL(new Blob([response?.body], {
        type: response?.body?.type
      }));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${docUUID}_${new Date().getTime()}.${extension}`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.toastrService.success('Document Downloaded !', 'Success');
      document.body.removeChild(fileLink);
    }, error => {
      this.toastrService.error(error?.error?.data?.message || `Download Failed !`, 'Error');
    });
  }
  onPageClick(event) {
    this.config.currentPage = event;
    this.getQuoteBiddingDocuments(this.quoteUuid);
  }

  getKeys(obj: any): string[] {
    return Object.keys(obj);
  }

  isArray(obj: any): boolean {
    return Array.isArray(obj);
  }

  preventKeyEvent(event: KeyboardEvent) {
    // Add the keys you want to prevent here
    event.preventDefault();
    return false;
  }

  onChangeRequestedDateDate(event) {
    let momentDate = moment(event).format('YYYY-MM-DD')
    if (event && momentDate !== 'Invalid date' && moment(event).isAfter(moment())) {
      this.requested_delivery_date = event;
      this.updateQuoteRequestedDeliveryDate();
    } else {
      this.toastrService.error(`Entered Date is Invalid`, 'Invalid Date', {
        timeOut: 4000
      });
    }
  }

  updateQuoteRequestedDeliveryDate() {
    this.errors = {};
    this.quoteUpdateError = null;
    this.isLoading = true;
    let requested_delivery_date = moment(this.requested_delivery_date).format('YYYY-MM-DD');
    this.quoteDetailsService.updateQuoteWithPoReference({ uuid: this.quote?.uuid, requested_delivery_date }).subscribe(
      (res) => {
        this.disableUpdate = true;
        this.showUpdate = false;
        this.processQuote(res.data);
        this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTATION_SUCCESSFULLY_UPDATED')}`);
      },
      (error) => {
        this.processError(error)
      }
    );
  }

  ngOnDestroy(): void {
    this.notificationService.setNotification(null, null);
    if (this.permissionSubs$) {
      this.permissionSubs$.unsubscribe();
    }
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  }
}
