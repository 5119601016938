import { Injectable } from '@angular/core';

import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class QuotesService {

  constructor(private apiBaseService: ApiBaseService) { }

  getQuotes(params = null) {
    const quotesURI = `quotes`;
    if (params) {
      return this.apiBaseService.get(quotesURI, params);
    } else {
      return this.apiBaseService.get(quotesURI);
    }
  }
  getQuotesForCustomer(params = null) {
    const quotesURI = `quotes/customer`;
    if (params) {
      return this.apiBaseService.get(quotesURI, params);
    } else {
      return this.apiBaseService.get(quotesURI);
    }
  }

  getQuote(quote_uuid) {
    const quoteURI = `quotes/${quote_uuid}`;
    return this.apiBaseService.get(quoteURI);
  }

  importQuote(formData: FormData) {
    const url = 'quotes/import';
    return this.apiBaseService.postFile(url, formData);
  }

}
