import { LoginComponent } from './auth/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './pages/home/home.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { HandsetsComponent } from './pages/handsets/handsets.component';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { CartComponent } from './pages/cart/cart.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
import { QuoteDetailsComponent } from './pages/quote-details/quote-details.component';
import { TwofactorauthComponent } from './auth/two-factor-auth/two-factor-auth.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { AuthGuard } from './gaurd/auth.guard';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { OrderConfirmationComponent } from './pages/order-confirmation/order-confirmation.component';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { OrderOverviewComponent } from './pages/order-overview/order-overview.component';
import { GripRedirectComponent } from './auth/grip-redirect/grip-redirect.component';
import { ExportFileComponent } from './pages/export-file/export-file.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { VoucherListComponent } from './pages/voucher-list/voucher-list.component';
import { OrderSuccessComponent } from './pages/order-success/order-success.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { DepotHistoryComponent } from './pages/depot-history/depot-history.component';
import { DepotGuard } from './gaurd/depot.guard';
import { SalesForecastComponent } from './pages/forecast/sales-forecast/sales-forecast.component';
import { ForecastComponent } from './pages/forecast/forecast.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { ImportOrderComponent } from './pages/import-order/import-order.component';
import { OrderImportGuard } from './gaurd/order-import.guard';
import { RmaGuard } from './gaurd/rma.guard';
import { RmaOrdersComponent } from './pages/rma/rma-list/rma-orders/rma-orders.component';
import { RmaDetailsComponent } from './pages/rma/rma-list/rma-details/rma-details.component';
import { ExportProductsComponent } from './pages/export-products/export-products.component';
import { ExportProductsGuard } from './gaurd/export-products.guard';
import { RmaCreateComponent } from './pages/rma/rma-create/rma-create.component';
import { ReportGuard } from './gaurd/report.guard';
import { ReportComponent } from './pages/report/report.component';
import { UserActivationComponent } from './auth/user-activation/user-activation.component';
import { UnAuthGuard } from './gaurd/unauth.guard';
import { FavoriteProductsComponent } from './pages/favorite-products/favorite-products.component';
import { TradeInComponent } from './pages/trade-in/trade-in.component';
import { TradeInDetailsComponent } from './pages/trade-in/trade-in-details/trade-in-details.component';
import { TradeInCustomerComponent } from './pages/trade-in/customer/customer.component';
import { CartOverviewComponent } from './pages/trade-in/cart-overview/cart-overview.component';
import { TradeInOrderDetailsComponent } from './pages/trade-in/tradein-order-details/tradein-order-details.component';
import { TradeInOrdersComponent } from './pages/trade-in/trade-in-orders/trade-in-orders.component';
import { CartDetailsComponent } from './pages/trade-in/cart-details/cart-details.component';
import { TradeInGuard } from './gaurd/trade-in.guard';
import { SmsCampaignToolComponent } from './pages/sms-campaigntool/sms-campaigntool/sms-campaigntool.component';
import { SmsCampaignGuard } from './gaurd/sms-campaign.guard';
import { SalesforceComponent } from './pages/salesforce/salesforce.component';
import { AccountTypeComponent } from './pages/account-type/account-type.component';
import { AccountTypeGuard } from './gaurd/account-type.guard';
import { FaqComponent } from './pages/faq/faq.component';
import { GripStartComponent } from './auth/grip-start/grip-start.component';
import { GripStartGuard } from './gaurd/grip-start.guard';
import { CustomerSelectComponent } from './pages/trade-in/customer-select/customer-select.component';
import { TradeInFinishedComponent } from './pages/trade-in/trade-in-finished/trade-in-finished.component';
import { ExportAllCatalogsComponent } from './pages/export-all-catalogs/export-all-catalogs.component';
import { MsRedirectComponent } from './auth/ms-redirect/ms-redirect.component';
import { LoginAzureComponent } from './auth/login-azure/login-azure.component';
import { ShippingMethodComponent } from './pages/trade-in/shipping-method/shipping-method.component';
import { CustomerQuoteDetailsStepComponent } from './pages/quote-details/customer/customer-quote-details-step/customer-quote-details-step.component';
import { CustomerQuoteDetailsPlaceOrderStepComponent } from './pages/quote-details/customer/customer-quote-details-place-order-step/customer-quote-details-place-order-step.component';
import { CustomerQuoteDetailsFinalStepComponent } from './pages/quote-details/customer/customer-quote-details-final-step/customer-quote-details-final-step.component';
import { CustomerQuoteDetailsInformationStepComponent } from './pages/quote-details/customer/customer-quote-details-information-step/customer-quote-details-information-step.component';
import { CustomerQuoteDetailsConfigurationStepComponent } from './pages/quote-details/customer/customer-quote-details-configuration-step/customer-quote-details-configuration-step.component';
import { CustomerQuoteDetailsSummaryStepComponent } from './pages/quote-details/customer/customer-quote-details-summary-step/customer-quote-details-summary-step.component';

const routes: Routes = [

  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login/azure',
    component: LoginAzureComponent,
  },

  {
    path: 'customer-registration',
    component: SignUpComponent,
  },
  {
    path: 'two-factor-auth',
    component: TwofactorauthComponent
  },
  {
    path: 'reset-password/:uid/:token',
    component: ResetPasswordComponent,
  },
  {
    path: 'grip-redirect',
    component: GripRedirectComponent,
  },
  {
    path: 'grip-start',
    component: GripStartComponent,
    canActivate: [GripStartGuard]
  },
  {
    path: 'azure/authorize',
    component: MsRedirectComponent,
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'product-details/:product_code',
    component: ProductDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'quotes',
    component: QuotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'quote-details/customer/:quote_uuid',
    component: CustomerQuoteDetailsStepComponent
  },
  {
    path: 'customer/quote-details/:quote_uuid',
    component: CustomerQuoteDetailsStepComponent
  },
  {
    path: 'customer/quote-details-information/:quote_uuid',
    component: CustomerQuoteDetailsInformationStepComponent
  },
  {
    path: 'customer/quote-details-configuration/:quote_uuid',
    component: CustomerQuoteDetailsConfigurationStepComponent
  },
  {
    path: 'customer/quote-details-summary/:quote_uuid',
    component: CustomerQuoteDetailsSummaryStepComponent
  },
  {
    path: 'customer/quote-details-place-order/:quote_uuid',
    component: CustomerQuoteDetailsPlaceOrderStepComponent
  },
  {
    path: 'customer/quote-details-final',
    component: CustomerQuoteDetailsFinalStepComponent
  },
  {
    path: 'handsets',
    component: HandsetsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'product-list',
    component: ProductListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'cart/:cart_uuid',
    component: CartComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'add-customer',
    component: AddCustomerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customers',
    component: CustomersComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'customer/:customer_uuid',
    component: CustomerComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'quote-details/:quote_uuid',
    component: QuoteDetailsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-status',
    component: OrderStatusComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'voucher-list',
    component: VoucherListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-confirmation/:orderCode',
    component: OrderConfirmationComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'customer-list',
    component: CustomerListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'order-success/:orderUuid',
    component: OrderSuccessComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'order-overview/:cartUuid',
    component: OrderOverviewComponent,
    canActivate: [AuthGuard],
  },
  // {
  //   path: 'export-file',
  //   component: ExportFileComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: 'export-all-catalogs',
    component: ExportAllCatalogsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'my-account',
    component: MyAccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'depot-history',
    component: DepotHistoryComponent,
    canActivate: [AuthGuard, DepotGuard],
  },
  {
    path: 'import-order',
    component: ImportOrderComponent,
    canActivate: [AuthGuard, OrderImportGuard]
  },
  {
    path: 'forecast',
    component: ForecastComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'sales-forecast/:contact_uuid',
    component: SalesForecastComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'rma',
    component: RmaOrdersComponent,
    canActivate: [AuthGuard, RmaGuard]
  },
  {
    path: 'rma/:rma_uuid',
    component: RmaDetailsComponent,
    canActivate: [AuthGuard, RmaGuard]
  },
  {
    path: 'rma/create/:rma_type',
    component: RmaCreateComponent,
    canActivate: [AuthGuard, RmaGuard]
  },
  {
    path: 'export-product',
    component: ExportProductsComponent,
    canActivate: [AuthGuard, ExportProductsGuard],
  },
  {
    path: 'reports',
    component: ReportComponent,
    canActivate: [AuthGuard, ReportGuard]
  },
  {
    path: 'user-activation',
    component: UserActivationComponent,
    canActivate: [UnAuthGuard]
  },
  {
    path: 'favorite-products',
    component: FavoriteProductsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'trade-in',
    component: TradeInComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },
  {
    path: 'trade-in/product/:product_code/:brand_code',
    component: TradeInDetailsComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },
  {
    path: 'trade-in/customers',
    component: CustomerSelectComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'trade-in/thank-you/:order_uuid',
    component: TradeInFinishedComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'trade-in/contact/:customer_uuid',
    component: TradeInCustomerComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },

  {
    path: 'trade-in/cart/:cart_uuid',
    component: CartOverviewComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },
  {
    path: 'trade-in/shipping-method',
    component: ShippingMethodComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },
  {
    path: 'trade-in/cart-details',
    component: CartDetailsComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },
  {
    path: 'trade-in/order/:order_uuid',
    component: TradeInOrderDetailsComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },

  {
    path: 'trade-in/orders',
    component: TradeInOrdersComponent,
    canActivate: [AuthGuard, TradeInGuard],
  },
  {
    path: 'sms-campaigntool',
    component: SmsCampaignToolComponent,
    canActivate: [AuthGuard, SmsCampaignGuard],
  },

  {
    path: 'quotations/redirect/salesforce',
    component: SalesforceComponent,
    canActivate: [AuthGuard],
  },

  {
    path: 'contacts/account-types',
    component: AccountTypeComponent,
    canActivate: [AuthGuard, AccountTypeGuard],
  },

  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    // redirectTo: 'login', pathMatch: 'full',
    component: LoginComponent,
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
