// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  node_env: 'dev',
  returnUrl: 'https://fe-producthub.designed4telecom.com',
  baseApiUrl: 'https://fe-producthub.designed4telecom.com/api/v1/',
  languages: ['en', 'nl'],
  feDateFormat: 'DD-MM-YYYY',
  beDateFormat: 'YYYY-MM-DD',
  accessTokenTimeOffset: 30,
  // tslint:disable-next-line:max-line-length
  // gripUrl: 'https://auth.grip-on-it.com/v2/vaaccokz/oidc/idp/authorize?&response_type=code&client_id=0e447a12-0228-499f-a120-78c84b994ffd&redirect_uri=http://localhost:4200/grip-redirect&scope=openid',
  // tslint:disable-next-line:max-line-length
  gripUrl: 'https://auth.grip-on-it.com/v2/vaaccokz/oidc/idp/authorize?&response_type=code&client_id=0e447a12-0228-499f-a120-78c84b994ffd&redirect_uri=https://fe-producthub.designed4telecom.com/grip-redirect&scope=openid',
};
// baseApiUrl: 'https://kpnph-be.itbutton.com/api/v1/',

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
