import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { StorageService } from 'src/app/shared/storage.service';
import { SelectCatalogAndProductGroupAction } from 'src/app/store/actions/catalog.actions';

@Component({
  selector: 'app-nav-title',
  templateUrl: './nav-title.component.html',
  styleUrls: ['./nav-title.component.scss']
})
export class NavTitleComponent implements OnInit {
  @Input() navTitle;
  catalogCodeAndProductGroupSubs$: Subscription;

  constructor(
    private store: Store<IAppState>,
    private storageService: StorageService,
  ) { }

  ngOnInit(): void {
  }

  selectChildItem(childCode: string) {
    if (childCode) {
      this.store.dispatch(new SelectCatalogAndProductGroupAction({
        catalogCode: this.storageService.getCatalog(),
        productGroupCode: childCode
      }));
    }
  }
}
