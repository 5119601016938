import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class DepotService {



  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  getDepotUsagesHistory(contactUuid: string, page: number = 1) {
    const paramsString = `page=${page}`;
    const url = `contacts/${contactUuid}/depot`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(url, params);
  }

  checkAvailableDepot(contactUuid: string) {
    const url = `contacts/${contactUuid}/depot/current-value`;
    return this.apiBaseService.get(url);
  }
}
