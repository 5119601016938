<section class="inner-content-wrapper inner-content-blue-bg">
    <div class="container">
        <div class="row g-4">
            <div class="col-lg-12">
                <div class="section-loader home-new-section-loader" *ngIf="loading">
                    <div class="kpn-spinner">
                        <img src="/assets/images/kpn-loader.gif" alt="Loading" />
                    </div>
                </div>
            </div>
            <div class="col-md-12" *ngIf="quotationCreationError">
                <div class="alert alert-danger" role="alert">
                    <p [innerHTML]="quotationCreationError"></p>
                </div>
            </div>
        </div>
    </div>
</section>