import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';

export const selectCatalog = (state: IAppState) => state.catalog;
export const selectACatalogAndProductGroup = (state: IAppState) => state.selectedCatalogAndProductGroup;

export const selectedCatalog = createSelector(
    selectCatalog,
    (catalog: any, props: any) => {
        return catalog;
    }
);

export const selectedCatalogAndProductGroup = createSelector(
    selectACatalogAndProductGroup,
    (obj: any, props: any) => {
        return obj;
    }
);
