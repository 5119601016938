import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { IAppState } from 'src/app/app.state';
import { HeaderService } from 'src/app/component/header/header.service';
import { CatalogLoadAction, CatalogLoadSuccess, ECatalogActions } from '../actions/catalog.actions';
import { ChildGroupLoadAction, ChildGroupLoadSuccess, EProductActions } from '../actions/productGroup.actions';

@Injectable()
export class CatalogEffects {
    constructor(
        private actions$: Actions,
        private headerService: HeaderService,
        private store: Store<IAppState>
    ) {}

    loadCatalog$ = createEffect(
        () => this.actions$.pipe(
            ofType<CatalogLoadAction>(ECatalogActions.CATALOG_LOAD),
            exhaustMap(() => {
                return this.headerService.getCatalogs();
            }),
            switchMap((response) => {
                const data = response.data?.results;
                return of(new CatalogLoadSuccess(data));
            }),
            catchError((error, caught) => {
                return caught;
            }),
        )
    );

    // loadChildGroup$ = createEffect(
    //     () => this.actions$.pipe(
    //         ofType<ChildGroupLoadAction>(EProductActions.CHILD_GROUP_LOAD),
    //         map(action => action.productGroupCode),
    //         switchMap((productGroupCode) => {
    //             if (productGroupCode) {
    //                 return this.headerService.getChildGroups(productGroupCode);
    //             } else {
    //                 of(null);
    //             }
    //         }),
    //         switchMap((response) => {
    //             const data = response.data;
    //             return of(new ChildGroupLoadSuccess(data?.child_groups));
    //         }),
    //         catchError((error, caught) => {
    //             return caught;
    //         })
    //     )
    // );
}
