import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { catchError, mergeMap, tap } from 'rxjs/operators';
import { IAppState } from 'src/app/app.state';
import { GenericToasterService } from 'src/app/shared/generic-toaster-utils';
import { NgbDateCustomParserFormatter } from 'src/app/shared/services/ngb-date-formatter.service';
import { StorageService } from 'src/app/shared/storage.service';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { CartService } from '../cart/cart.service';
import { PaymentService } from '../payment/payment.service';
import { OrderConfirmationService } from './order-confirmation.service';
import { EPaymentStatusTypes } from 'src/app/shared/enums/order-status.enums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { OrderStatusService } from '../order-status/order-status.service';
import { BasePermission } from 'src/app/shared/base/base-permission';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss']
})
export class OrderConfirmationComponent extends BasePermission implements OnInit {
  isLoading = false;
  orderCode = null;
  user = null;
  contactPerson = null;
  shippingAddress = null;
  billingAddress = null;
  orderLines = null;
  order = null;
  contact = null;
  objectIdentifier = '';
  paymentMsg = '';
  paymentStatus = null;
  paymentParameter = null;

  public siteProfile = null;
  numberMsg = null;
  description = null;
  orderRef = null;
  direct_payment_required = false;
  handsetProvisions = [];
  public failedPayments = [
    EPaymentStatusTypes.CANCELLED,
    EPaymentStatusTypes.CANCELLED_BY_USER,
    EPaymentStatusTypes.FAILED,
    EPaymentStatusTypes.REJECTED
  ];

  constructor(
    private orderConfirmationService: OrderConfirmationService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    private ngbDateCustomParserFormatter: NgbDateCustomParserFormatter,
    private genericToasterService: GenericToasterService,
    private paymentService: PaymentService,
    public utilityService: UtilityService,
    public cartService: CartService,
    public store: Store<IAppState>,
    private orderStatusService: OrderStatusService

  ) {
    super(store);
    this.user = this.storageService.getCurrentUser();
    this.orderCode = this.route.snapshot.params['orderCode'];
    this.route.queryParams.subscribe(qp => {
      this.objectIdentifier = qp?.ObjectIdentifier || '';
    });

    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
  }

  ngOnInit() {
    if (this.hasViewHandsetProvision) {
      this.getHSProvisions();
    }
    this.getOrderDetails();
  }

  get ePaymentStatus() { return EPaymentStatusTypes; }


  getOrderDetails() {
    this.isLoading = true;
    if (this.objectIdentifier) {
      this.direct_payment_required = false;
      this.paymentMsg = '';
      const payload = {
        order_uuid: this.orderCode,
        object_identifier: this.objectIdentifier
      };
      this.paymentService.checkPaymentStatus(payload).pipe(
        tap(response => {
          this.direct_payment_required = true;
          this.paymentStatus = response?.data?.status_code;
          this.populatePaymentMsg();
        }),
        catchError(error => {
          this.direct_payment_required = true;
          return of(error);
        }),
        mergeMap(order => {
          return this.orderConfirmationService.getOrderDetails(this.orderCode);
        })
      ).subscribe((res) => {
        this.formatOrderOutput(res);
        this.isLoading = false;
      }, err => {
        // It is temporary error response. Will fix after correcting the BE
        this.genericToasterService.genericToaster('error', 'Sorry! Zoekopdracht mislukt', 'Error');
        this.isLoading = false;
      });
    } else {
      this.orderConfirmationService.getOrderDetails(this.orderCode)
        .subscribe((res) => {
          this.formatOrderOutput(res);
          this.isLoading = false;
        }, err => {
          // It is temporary error response. Will fix after correcting the BE
          this.genericToasterService.genericToaster('error', 'Sorry! Zoekopdracht mislukt', 'Error');
          this.isLoading = false;
        });
    }
  }

  populatePaymentMsg() {
    if (this.paymentStatus === EPaymentStatusTypes.SUCCESS) {
      this.paymentMsg = `Betaling succesvol gedaan`;
    } else if (this.paymentStatus === EPaymentStatusTypes.CANCELLED_BY_USER) {
      this.paymentMsg = 'De betaling is afgebroken. U kunt opnieuw de betaling starten. Indien de betaling niet afgerond wordt zal de order automatisch geannuleerd worden';
    } else if (this.paymentStatus === EPaymentStatusTypes.FAILED) {
      this.paymentMsg = `Uw betaling is helaas niet gelukt. Probeert u het later nogmaals. Uw order blijft beschikbaar om alsnog de betaling af te maken. Excuses voor het ongemak.`;
    } else if ([EPaymentStatusTypes.CANCELLED, EPaymentStatusTypes.REJECTED].includes(this.paymentStatus)) {
      this.paymentMsg = 'De order is geannuleerd. Dit komt omdat de betaling niet op tijd gedaan is.';
    }
    else {
      this.paymentMsg = `Betaling is ${this.paymentStatus}`;
    }
  }

  formatOrderOutput(res) {
    this.paymentParameter = null;
    if (res.data?.contact_person) {
      this.contactPerson = res?.data?.contact_person;
      this.contact = res?.data?.contact;
      this.contactPerson.birthday = this.ngbDateCustomParserFormatter.formatDateString(this.contactPerson?.birthday);
      this.shippingAddress = res?.data?.shipping_address;
      this.billingAddress = res?.data?.invoice_address;
      this.orderLines = res?.data?.order_line?.map(line => {
        return {
          ...line,
          provisions: this.handsetProvisions?.find(item => item?.product?.code === line?.product_code?.code) || null
        }
      })
      this.order = { ...res?.data?.order };
      this.orderRef = res?.data?.order?.reference;
      this.paymentParameter = this.utilityService.getOrderPaymentParameters(res?.data);
      const message = this.utilityService.paymentMessage(this.paymentParameter, this.orderRef);
      this.numberMsg = message.numberMsg;
      this.description = message.description;
    }
  }


  getHSProvisions() {
    this.orderStatusService.getOrderHandsetProvisions(this.orderCode).subscribe(response => {
      this.handsetProvisions = response?.data?.device_info || [];
    }, error => { });
  }

  getPaymentStatus() {
    this.paymentMsg = '';
    const payload = {
      order_uuid: this.order?.uuid,
      object_identifier: this.objectIdentifier
    };
    this.paymentService.checkPaymentStatus(payload).subscribe(response => {
      this.order.direct_payment_required = true;
      this.paymentStatus = response?.data?.status_code;
      this.populatePaymentMsg();
    }, error => {
      this.order.direct_payment_required = true;
    });
  }
}
