import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ErrorHandleService } from 'src/app/shared/error-handle.service';
import { GenericToasterService } from 'src/app/shared/generic-toaster-utils';
import { StorageService } from 'src/app/shared/storage.service';
import { OrderStatusService } from './order-status.service';

import { EOrderStatus, ERmaFlowType } from '../../shared/enums/order-status.enums';
import { NgbDateCustomParserFormatter } from 'src/app/shared/services/ngb-date-formatter.service';
import { EMarketTypes } from 'src/app/shared/enums/market-type.enums';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { concatMap, mergeMap, mergeMapTo, tap, map, switchMap, catchError } from 'rxjs/operators';
import { RmaService } from '../rma/rma.service';
import { forkJoin, of, throwError, timer } from 'rxjs';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-order-status',
  templateUrl: './order-status.component.html',
  styleUrls: ['./order-status.component.scss']
})
export class OrderStatusComponent extends BasePermission implements OnInit {
  isLoading = false;
  rmaCreateLoading = false;
  invoiceUploading = false;
  user = null;
  orders = null;
  searchForm: UntypedFormGroup;
  orderCount: number;
  orderDetail: any;
  contactPerson: any;
  contact: any;
  shippingAddress: any;
  billingAddress: any;
  orderLines: any;
  order: any;
  marketType = EMarketTypes.CONSUMER;
  public config = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  public siteProfile = null;
  public rmaLines = [];
  public shipmentDetails = [];
  public allowRma = false;
  public rmaFlowType = '';
  invoiceUploadForm: UntypedFormGroup;
  public paymentStatus = null;
  public description = '';
  public handsetProvisions = [];
  public handsetProvisionStatus = null;
  public paramOrderNumber = null;

  constructor(
    private storageService: StorageService,
    private orderStatusService: OrderStatusService,
    private errorHandleService: ErrorHandleService,
    private genericToasterService: GenericToasterService,
    private rmaService: RmaService,
    private fb: UntypedFormBuilder,
    private modalService: NgbModal,
    private ngbDateCustomParserFormatter: NgbDateCustomParserFormatter,
    public store: Store<IAppState>,
    private router: Router,
    private activateRoute: ActivatedRoute,
    private toastrService: ToastrService,
    public utilityService: UtilityService,
    public translocoService: TranslocoService,


  ) {
    super(store);
    this.user = this.storageService.getCurrentUser();
    this.allowRma = this.storageService.getSiteProfile()?.allow_rma_handling || false;
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });

    this.activateRoute.queryParams.subscribe(qp => {
      this.rmaFlowType = qp?.rma_type || '';
      if (qp?.order_number) {
        this.paramOrderNumber = qp?.order_number;
      }
    });

    this.router.events
      .subscribe((event: NavigationStart) => {
        if (
          event instanceof NavigationStart &&
          event.navigationTrigger == "popstate" &&
          event.restoredState
        ) {
          timer(100).subscribe(time => {
            this.modalService?.dismissAll();
          })
        }
      });
  }

  ngOnInit() {
    this.initializeForm();
    this.isLoading = true;
    if (this.paramOrderNumber) {
      this.searchForm.patchValue({
        external_number: this.paramOrderNumber
      });
      this.onSearchOrder();
      // Clear the query params
      this.router.navigate([], {
        queryParams: {},
      });
      return;
    }
    this.orderStatusService.getOrders(this.user?.contact_group?.is_retail_store,
      this.user.contact_group.is_outbound_user).subscribe(res => {
        this.orders = res?.data?.results?.map(order => {
          return {
            ...order,
            loading: false
          };
        });
        this.config.totalItems = res?.data?.count;
        this.isLoading = false;
      }, error => {
        this.isLoading = false;
      });
  }

  initializeForm() {
    this.searchForm = this.fb.group({
      external_number: [''],
      internal_number: [''],
      po_number: [''],
      mobile_number: [''],
      postal_code: [''],
      house_number: [''],
      own_order: this.user?.contact_group?.is_retail_store,
      is_parked: this.user?.contact_group?.is_outbound_user
    });
  }

  initializeRMAInvoiceImportForm() {
    this.invoiceUploadForm = this.fb.group({
      invoice: new UntypedFormControl('', [Validators.required]),
      source_file: new UntypedFormControl('', [Validators.required]),
      rma_uuid: new UntypedFormControl('', [Validators.required]),
    });
  }

  get eOrderStatus() {
    return EOrderStatus;
  }

  onSearchOrder(pageNumber: number = 1) {
    const {
      external_number,
      internal_number,
      po_number,
      mobile_number,
      postal_code,
      house_number,
      own_order,
      is_parked
    } = this.searchForm.value;
    this.isLoading = true;
    this.orderStatusService.searchOrders(
      pageNumber, external_number, internal_number,
      po_number, mobile_number, postal_code,
      house_number, own_order, is_parked
    ).subscribe(res => {
      this.isLoading = false;
      this.orders = res?.data?.results?.map(order => {
        return {
          ...order,
          loading: false
        };
      });
      this.config.currentPage = pageNumber;
      this.config.totalItems = res?.data?.count;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      // Temporary solution. Will fix after BE.
      const errors = this.errorHandleService.errorHandle(error?.error?.error?.errors);
      // tslint:disable-next-line:quotemark
      if (errors) {
        // tslint:disable-next-line:quotemark
        this.genericToasterService.genericToaster('error', '', 'Error');
      } else {
        this.genericToasterService.genericToaster('error', this.translocoService.translate('ORDER_STATUS.SORRY_SEARCH_FAILED'), 'Error');
      }
    });
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.onSearchOrder(event);
  }

  onOrderDetail(orderObject: any, orderDetailsModal: any) {
    this.isLoading = true;
    this.resetOrderData();
    if (this.hasViewHandsetProvision) {
      this.getHSProvisions(orderObject?.uuid);
    }
    this.orderStatusService.getOrderDetails(orderObject.uuid).pipe(
      tap(res => {
        if (res?.data?.contact_person) {
          this.contactPerson = res?.data?.contact_person;
          this.contact = res?.data?.contact;
          this.contactPerson.birthday = this.ngbDateCustomParserFormatter.formatDateString(this.contactPerson?.birthday);
          this.shippingAddress = res?.data?.shipping_address;
          this.billingAddress = res?.data?.invoice_address;
          this.orderLines = res?.data?.order_line;
          this.order = res?.data?.order;
          this.paymentStatus = this.utilityService.getOrderPaymentParameters(res?.data);
          this.description = this.utilityService.paymentMessage(this.paymentStatus, this.order?.reference).description;
        }
        return res;
      }),
      switchMap(data => {
        return this.orderStatusService.getOrderShipment(orderObject.uuid).pipe(
          tap(response => {
            this.shipmentDetails = response?.data?.results;
            return response;
          })
        );
      }),
      mergeMap(order => {
        if (this.hasRmaPermission.add && this.siteProfile?.allow_rma_handling && orderObject?.rma_allowed && this.rmaFlowType) {
          return this.rmaService.getOrderLineRmaOptions(orderObject.uuid, this.rmaFlowType);
        } else {
          return of(null);
        }
      })).subscribe(
        (res) => {
          if (res) {
            this.orderLines = this.orderLines?.map(line => {
              const rmaOptions = res?.data?.results?.find(item => line?.id === item?.main_line_id) || null;
              return {
                ...line,
                rma_options: rmaOptions,
                rma_qty: line?.qty,
                selected_rma_option: rmaOptions?.main_line_rma_options?.find(item => item) || null,
                selected_rma_reason: rmaOptions?.main_line_return_reasons?.find(item => item) || null,
                user_reason: '',
                provisions: this.handsetProvisions?.filter(item => {
                  return item?.product?.code === line?.product_code?.code
                }) || null
              };
            });
            this.checkRma();
          } else {
            this.orderLines = this.orderLines?.map(line => {
              return {
                ...line,
                provisions: this.handsetProvisions?.filter(item => {
                  return item?.product?.code === line?.product_code?.code
                }) || null
              };
            });
          }

          this.modalService.open(orderDetailsModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            size: 'xl',
            scrollable: true,
            backdrop: 'static',
          }).result.then((result) => {
            // this.closeResult = `Closed with: ${result}`;
          }, (reason) => {
            // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          });
          this.isLoading = false;
        }, error => {
          const errorMsg = error?.error?.message || error?.error?.error;

          // It is temporary error response. Will fix after correcting the BE
          this.genericToasterService.genericToaster('error', errorMsg || this.translocoService.translate('ORDER_STATUS.SORRY_SEARCH_FAILED'), 'Error');
          this.isLoading = false;
        });
  }

  checkRma() {
    this.rmaLines = this.orderLines?.filter(line => {
      return line?.selected_rma_option && line?.selected_rma_reason && (line?.rma_qty >= 1 && line?.rma_qty <= line?.qty);
    }).map(item => {
      return {
        order_main_line_id: item?.id,
        rma_type: item?.selected_rma_option?.code,
        return_reason_type: item?.selected_rma_reason?.code,
        quantity: item?.rma_qty,
        flow_type_code: item?.selected_rma_option?.code,
        user_reason: item?.user_reason
      };
    });
  }

  createRMA(rmaInvoiceUpload: any) {
    this.rmaCreateLoading = true;
    this.initializeRMAInvoiceImportForm();
    const payload = {
      order_uuid: this.order?.uuid,
      rma_flow_type: ERmaFlowType.DOA,
      lines: [...this.rmaLines]
    };
    this.rmaService.createRma(payload).subscribe(response => {
      this.invoiceUploadForm.patchValue({
        rma_uuid: response?.data?.uuid
      });
      this.rmaCreateLoading = false;
      this.modalService.open(rmaInvoiceUpload, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true,
        size: 'lg',
        scrollable: true,
        backdrop: 'static',
      }).result.then((result) => {
        // this.closeResult = `Closed with: ${result}`;
      }, (reason) => {
        // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      });
    }, error => {
      this.rmaCreateLoading = false;
    });
  }

  getHSProvisions(order_uuid: string) {
    this.handsetProvisions = [];
    this.orderStatusService.getOrderHandsetProvisions(order_uuid).subscribe(response => {
      this.handsetProvisions = response?.data?.device_info || [];
      this.handsetProvisionStatus = response?.data?.status || "";
    }, error => {
      console.log('error: ', error);
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.invoiceUploadForm.patchValue({
        invoice: file
      });
    }
  }

  uploadInvoice() {
    const formData: FormData = new FormData();
    for (const key in this.invoiceUploadForm.value) {
      if (!['source_file', 'rma_uuid']?.includes(key)) {
        formData.append(key, this.invoiceUploadForm.get(key).value);
      }
    }
    this.invoiceUploading = true;
    this.rmaService.uploadRMAInvoice(formData, this.invoiceUploadForm.get('rma_uuid').value).subscribe(
      (res) => {
        this.invoiceUploading = false;
        this.toastrService.success(this.translocoService.translate('ORDER_STATUS.INVOICE_UPLOADED'), 'Success', {
          timeOut: 5000
        });
        this.modalService.dismissAll();
        this.router.navigate([`/rma/${res?.data?.rma?.uuid}`], {
          queryParamsHandling: 'merge'
        });
      },
      (error) => {
        this.invoiceUploading = false;
        this.toastrService.error(this.translocoService.translate('ORDER_STATUS.INVOICE_UPLOADING_FAILED'), 'Error', {
          timeOut: 5000
        });
      }
    );
  }

  cancelOrder(order) {
    order.loading = true;
    this.orderStatusService.cancelOrder(order?.uuid).subscribe(response => {
      const message = response?.data?.message || this.translocoService.translate('ORDER_STATUS.ORDER_CANCELLED_SUCCESSFULLY');
      this.toastrService.success(message, 'Success', {
        timeOut: 3000
      });
      order.status = response?.data?.order_status;
      order.loading = false;
    }, error => {
      order.loading = false;
    });
  }

  getFileExtension(file) {
    let ext = file?.file_name.split(".").pop();
    return this.utilityService.iconList.find(row => {
      return row.type === file?.mime_type || row.type === ext;
    })?.icon || 'kphicon-uniE92E icon-size-md';
  }

  downloadDocuments(file) {
    this.orderStatusService.downloadDocuments(file?.uuid).subscribe(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${file?.file_name}`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.toastrService.success(`${this.translocoService.translate('ORDER_STATUS.DOCUMENT_DOWNLOADED_SUCCESSFULLY')}`, 'Success', {
        timeOut: 3000
      });
      document.body.removeChild(fileLink);
    }, error => {
      this.toastrService.error(`${this.translocoService.translate('ORDER_STATUS.DOWNLOADING_THE_DOCUMENT_FAILED')}`, 'Error', {
        timeOut: 3000
      });
    })
  }

  resetOrderData() {
    this.contactPerson = null;
    this.shippingAddress = null;
    this.orderLines = [];
    this.order = null;
    this.rmaLines = [];
    this.shipmentDetails = [];
    this.paymentStatus = null;
    this.description = null;
    this.handsetProvisions = [];
  }
}
