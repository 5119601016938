import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-order-success',
  templateUrl: './order-success.component.html',
  styleUrls: ['./order-success.component.scss']
})
export class OrderSuccessComponent implements OnInit {

  public quoteRef = null;
  public orderId = null;
  public orderRef = null;
  public uuid = null;
  public numberMsg = '';
  public description = '';
  public btnText = '';
  public order = {
    uuid: '',
    direct_payment_required: false,
    is_paid: false,
    is_total_amount_paid: false
  };
  public contact = {
    uuid: ''
  };
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private utilityService: UtilityService,
    private translocoService: TranslocoService

  ) {
    this.activateRoute.queryParams.subscribe(qp => {
      this.quoteRef = qp?.quoteRef || null;
      this.orderId = qp?.orderId || null;
      this.orderRef = qp?.orderRef || null;
      this.order.direct_payment_required = qp?.payment_required === 'true' ? true : false;
      this.contact.uuid = qp?.contact || '';
      this.order.is_paid = qp?.is_paid === 'true' ? true : false;
      this.order.is_total_amount_paid = JSON.parse(qp?.is_total_amount_paid);
      if (this.quoteRef && !this.orderId) {
        this.numberMsg = `${this.translocoService.translate('ORDER_SUCCESS.YOUR_QUOTATION_REQUEST_WITH_NUMBER')}  ${this.quoteRef} ${this.translocoService.translate('ORDER_SUCCESS.IS_ACCEPTED_BY_OUR_SYSTEM')}`;
        // tslint:disable-next-line:max-line-length
        this.description = `${this.translocoService.translate('ORDER_SUCCESS.WILL_RECEIVE_EMAIL_AS_SOON_AS_WE_HAVE_PROCESSED')}.`;
        this.btnText = `${this.translocoService.translate('ORDER_SUCCESS.QUOTATION_LIST')}`;
      } else {
        const message = this.utilityService.paymentMessage(qp, this.orderRef);
        this.numberMsg = message.numberMsg;
        this.description = message.description;
      }
      this.btnText = `${this.translocoService.translate('ORDER_SUCCESS.ORDER_DETAILS')}`;
    });

    this.activateRoute.params.subscribe(p => {
      this.uuid = p?.orderUuid || null;
      this.order.uuid = this.uuid;
    });
  }

  ngOnInit(): void {
  }

  goDetails() {
    if (this.quoteRef && !this.orderId) {
      this.router.navigateByUrl('/quotes');
    } else if (this.orderId) {
      this.router.navigate(['/order-confirmation', `${this.uuid}`]);
    }
  }

}
