import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router, NavigationEnd } from '@angular/router';

import { of, Observable, throwError } from 'rxjs';
import { catchError, tap, map, filter } from 'rxjs/operators';


import { StorageService } from './storage.service';
import { ApiBaseService } from './api-base.service';
import { environment } from '../../environments/environment';

@Injectable()
export class AuthService {

  private loggedUser: string;

  constructor(
    private http: HttpClient,
    private storageService: StorageService,
    private router: Router,
    private apiBaseService: ApiBaseService
  ) { }

  login(user: { username: string, password: string }): Observable<any> {
    return this.apiBaseService.post(`accounts/login`, user)
      .pipe(
        tap(response => {
          this.storageService.setToken(response.data.token);
          this.storageService.setTwoFactorAuthData(response.data.two_factor);
        }),
      );
  }

  twoFactorLogin(payload): Observable<any> {
    return this.apiBaseService.post(`accounts/auth/otp-verify`, payload)
      .pipe(
        tap(response => {
          const loggedInUser = response.data.user;
          const tokens = { jwt: response.data.token, refreshToken: response.data.refresh_token };
          this.doLoginUser(loggedInUser, tokens);
        }),
      );
  }

  sendSMS(otp_type): Observable<any> {
    return this.apiBaseService.post(`accounts/auth/otp-send`, { otp_type });
  }

  logout() {
    // !INFO : refresh token will not need when BE will rebase with ARMOR3.
    const payload = {
      refresh: this.storageService.getRefreshToken()
    };
    return this.apiBaseService.post(`accounts/logout`, payload).pipe(
      tap(response => {
        this.doLogoutUser();
      }),
      map(response => response),
      catchError(error => {
        return throwError(error);
      }));
  }

  loginWithGrip(code: any) {
    return this.apiBaseService.post(`accounts/grip-login`, { code })
      .pipe(
        tap(response => {
          const loggedInUser = response.data.user;
          const tokens = { jwt: response.data.token, refreshToken: response.data.refresh_token };
          this.doLoginUser(loggedInUser, tokens);
        }),
        map(response => response)
      );
  }

  isLoggedIn() {
    return !!this.storageService.getToken();
  }

  hasUser() {
    return !!this.storageService.getCurrentUser();
  }

  sendResetPasswordRequest(user: { username: string, email?: string }): Observable<any> {
    return this.apiBaseService.post(`accounts/password/reset`, user);
  }


  resetPassword(payload) {
    return this.apiBaseService.post(`accounts/password/reset/confirm/${payload.uid}/${payload.token}`, payload);
  }

  refreshToken(refreshToken: string = '') {
    return this.apiBaseService.post('accounts/refresh-token', {
      refresh: refreshToken || this.storageService.getRefreshToken()
    }).pipe(
      catchError(error => {
        console.log('error: Refresh Token Error ', error);
        return throwError(error);
      }),
      tap((res: any) => {
        if (res.data) {
          const tokens = { jwt: res.data.token, refreshToken: res.data.refresh_token };
          this.storeTokens(tokens);
          return tokens;
        }
      })
    );
  }
  setDoLoginUser(user: any, tokens) {
    this.doLoginUser(user, tokens);
  }

  private doLoginUser(user: any, tokens) {
    this.storageService.setCurrentUser(user);
    this.loggedUser = user;
    if (user?.is_external) {
      this.storageService.setContactPersonUUID(user?.contact_person?.uuid);
    }
    this.storeTokens(tokens);
  }

  private doLogoutUser() {
    this.loggedUser = null;
    this.removeTokens();
  }

  private storeTokens(tokens) {
    this.storageService.setToken(tokens.jwt);
    this.storageService.setRefreshToken(tokens.refreshToken);
  }

  private removeTokens() {
    this.storageService.removeToken();
    this.storageService.removeRefreshToken();
  }
}
