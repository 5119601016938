import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TradeInService } from '../trade-in.service';
import { StorageService } from 'src/app/shared/storage.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';

@Component({
  selector: 'app-business-partner-selection',
  templateUrl: './business-partner-selection.component.html',
  styleUrls: ['./business-partner-selection.component.scss']
})
export class BusinessPartnerSelectionComponent extends BasePermission {
  public modalReference: NgbModalRef;
  public tradeInFor = null;

  @ViewChild('businessPartnerSelectionModal') businessPartnerSelectionModal: any;
  @Input () showEdit: boolean = false;
  @Output() onBusinessPartnerSelectionAction = new EventEmitter();

  constructor(
    private modalService: NgbModal,
    private tradeInService: TradeInService,
    private storageService: StorageService,
    public store: Store<IAppState>
  ) {
    super(store);
  }
  ngOnInit() {
    const previousSelectedBusinessPartner = this.storageService.getTradeInBusinessPartner();
    if (previousSelectedBusinessPartner) {
      this.tradeInFor = previousSelectedBusinessPartner;
      this.storageService.setTradeInBusinessPartner(previousSelectedBusinessPartner);
    }
  }

  openModal() {
    this.modalReference = this.modalService.open(this.businessPartnerSelectionModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onSelectTradeInFor() {
    this.storageService.setTradeInBusinessPartner(this.tradeInFor);
    // this.modalReference.close();
  }
  openBusinessPartnerSelectionModal() {
    this.openModal();
  }

}
