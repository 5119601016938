import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-shipping-method',
  templateUrl: './shipping-method.component.html',
  styleUrls: ['./shipping-method.component.scss']
})
export class ShippingMethodComponent {
  public tradeInBusinessPartner = null;
  public tradeInCart = null;
  public totalQtyInCart = 0;
  constructor(
    private storageService : StorageService,
    private router: Router
  ) {

   }
   ngOnInit () {
    this.storageService.getTradeInCart().subscribe(cart => {
      if (cart) {
        this.tradeInCart = JSON.parse(cart);

        this.totalQtyInCart = this.tradeInCart?.lines.reduce((sum, item) => sum + item.qty, 0);



        console.log('this.cart: ', this.tradeInCart);
      }
    });
    this.setTradeInBusinessPartner();
   }
   goToOrderOverview() {
    this.router.navigate(['trade-in/cart', this.tradeInCart?.uuid]);
   }
   setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
  }

  back() {
    const q_params = {
      is_trade_in: true,
      contact_person: this.tradeInCart?.contact_person_uuid,
    };
    this.router.navigate([`trade-in/contact/${this.tradeInCart?.contact_uuid}`], {
      queryParams: q_params,
      queryParamsHandling: 'merge'
    });
  }
}
