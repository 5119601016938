import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../storage.service';

@Component({
  selector: 'app-steps',
  templateUrl: './steps.component.html',
  styleUrls: ['./steps.component.scss']
})
export class StepsComponent implements OnInit {

  @Input() activeStep = '';
  @Input() completeStepOne = false;
  @Input() completeStepTwo = false;
  @Input() completeStepThree = false;
  @Input() completeStepFour = false;

  private cart = null;
  private customer = null;
  private user = null;


  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
    this.user = this.storageService.getCurrentUser() || null;
    this.cart = this.storageService.getCart() || null;
    this.customer = this.storageService.getCustomer();
  }

  ngOnInit() {
  }

  backToCart() {
    if (this.cart && this.completeStepOne) {
      this.router.navigate([`/cart/${this.cart?.uuid}`]);
    }
  }

  backToCustomer() {
    if (this.completeStepTwo) {
      const url = `/customer/${this.customer ? this.customer?.uuid : this.user?.contact?.uuid}`;
      this.router.navigate([url], {
        queryParams: {
          contact_person: this.cart?.contact_person_uuid,
          shipping_address: this.getShippingAddressById(this.cart?.shipping_address_id)
        },
        queryParamsHandling: 'merge'
      });
    }
  }

  getShippingAddressById(id: number) {
    const cartAddress = this.customer?.contact_addresses?.find(address => {
      return address.id === id;
    }) || null;

    return cartAddress ? cartAddress?.uuid : null;
  }

}
