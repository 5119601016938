
import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ReportService } from './report.service';
import { GenericToasterService } from 'src/app/shared/generic-toaster-utils';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  public config: { itemsPerPage: number, currentPage: number, totalItems: number } = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  reports: any[] = [];
  isLoading = false;
  keyword = 'title';
  searchFocused = false;


  constructor(
    private reportService: ReportService,
    private toasterService: GenericToasterService,
    public translocoService: TranslocoService


  ) { }

  ngOnInit() {
    this.getReportList();
  }

  getReportList(search = null) {
    this.isLoading = true;
    let queryString = `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}`;
    if (search) {
      queryString = `${queryString}&search=${search}`;
    }
    const params = new HttpParams({
      fromString: queryString
    });
    this.reportService.getReports(params).subscribe(response => {
      this.reports = response?.data?.results;
      this.isLoading = false;
      this.config.totalItems = response.data.count;
    }, error => {
      this.isLoading = false;
      this.reports = [];
      this.config.totalItems = 0;
    });
  }


  downloadReport(report) {
    this.reportService.downloadReport(report?.uuid).subscribe(response => {
      const fileType = response?.type?.split('/')?.at(1) || 'csv';
      const fileURL = window.URL.createObjectURL(new Blob([response]));
      const fileLink = document.createElement('a');

      fileLink.href = fileURL;
      fileLink.setAttribute('download', `Report of ${report?.title}.${fileType}`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.toasterService.genericToaster('success', this.translocoService.translate('REPORT.REPORT_DOWNLOAD_SUCCESS'), this.translocoService.translate('REPORT.REPORT_DOWNLOAD'));
    }, error => {
      console.log('error: ', error);
      this.toasterService.genericToaster('error', error?.error?.data?.message || this.translocoService.translate('REPORT.REPORT_DOWNLOAD_FAILED'), this.translocoService.translate('REPORT.REPORT_DOWNLOAD'));
    });
  }

  onPageClick(event: any) {
    this.isLoading = true;
    this.config.currentPage = event;
    this.getReportList();
  }


  onSelectedReport(report) {
    this.getReportList(report.title);
  }

  searchReport(searchString: string) {
    if (searchString.length >= 3) {
      this.getReportList(searchString);
    }
  }



  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }

  searchCleared() {
    this.getReportList();
  }
}

