import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { ERmaStatusType } from 'src/app/shared/enums/order-status.enums';
import { StorageService } from 'src/app/shared/storage.service';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { RmaService } from '../../rma.service';

@Component({
  selector: 'app-rma-details',
  templateUrl: './rma-details.component.html',
  styleUrls: ['./rma-details.component.scss']
})
export class RmaDetailsComponent implements OnInit {

  private rma_uuid = null;
  public rmaOrder = null;
  public siteProfile = null;
  public isLoading = false;
  public approveLoading = false;
  public user = null;

  get eRmaStatusType() {
    return ERmaStatusType;
  }

  constructor(
    private rmaService: RmaService,
    private route: ActivatedRoute,
    private router: Router,
    public store: Store<IAppState>,
    private storageService: StorageService
  ) {
    this.user = this.storageService.getCurrentUser();
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
    this.route.params.subscribe(param => {
      this.rma_uuid = param?.rma_uuid || null;
      if (this.rma_uuid) {
        this.getRMADetails();
      }
    });
  }

  ngOnInit(): void {
  }

  getRMADetails() {
    this.isLoading = true;
    this.rmaService.getRMADetails(this.rma_uuid).subscribe(res => {
      this.rmaOrder = res?.data;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  backToRMAList() {
    this.router.navigate(['/rma']);
  }

  approveRma() {
    this.approveLoading = true;
    this.rmaService.approveRma(this.rma_uuid).subscribe(response => {
      this.rmaOrder = { ...this.rmaOrder, ...response?.data };
      this.approveLoading = false;
    }, error => {
      this.approveLoading = false;
    });
  }

}
