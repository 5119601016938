import { Store } from '@ngrx/store';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IAppState } from 'src/app/app.state';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { QuoteDetailsService } from '../../../quote-details.service';
import { Subscription } from 'rxjs';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer-quote-po-and-reference',
  templateUrl: './customer-quote-po-and-reference.component.html',
  styleUrls: ['./customer-quote-po-and-reference.component.scss']
})
export class CustomerQuotePoAndReferenceComponent {
  isLoading: boolean;
  quote: any;
  siteProfile = null;
  po_number = '';
  customer_reference = '';
  poAndReferenceUpdateModalData = {
    po_number: "",
    customer_reference: "",
  }
  currentCustomerDetailsQuoteSubscription$: Subscription;
  customerPoReferenceUpdateModalRef: NgbModalRef;
  quoteUpdateError = null;
  @Output() updatePoAndReferenceEventEmitter: EventEmitter<void> = new EventEmitter();
  @Input () showHeader = false;
  constructor(
    public store: Store<IAppState>,
    private quoteDetailsService: QuoteDetailsService,
    private modalService: NgbModal,
    private translocoService: TranslocoService,
    private toastrService: ToastrService,

  ) {

  }
  openPoAndReferenceEditModal(customerPoReferenceUpdateModal) {
    this.customerPoReferenceUpdateModalRef = this.modalService.open(customerPoReferenceUpdateModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-595 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });
  }
  resetData() {
    this.setData();
  }
  setData() {
    this.po_number = this.quote?.po_number;
    this.customer_reference = this.quote?.customer_reference;
    this.poAndReferenceUpdateModalData = {
      po_number: this.po_number,
      customer_reference: this.customer_reference,
    };
  }
  updateQuotePoReference() {
    const payload = {
      uuid: this.quote.uuid,
      po_number: this.poAndReferenceUpdateModalData.po_number,
      customer_reference: this.poAndReferenceUpdateModalData.customer_reference,
    };
    this.quoteUpdateError = null;
    this.isLoading = true;
    this.quoteDetailsService.updateQuoteWithPoReference(payload).subscribe(res => {
      this.quote = res.data;
      this.setData();
      this.updatePoAndReferenceEventEmitter.emit(res?.data);
      this.customerPoReferenceUpdateModalRef.close();
      this.isLoading = false;

    }, error => {
      this.isLoading = false;
      this.quoteUpdateError = error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
      this.toastrService.error(this.quoteUpdateError, 'Error');
      return false;
    })
  }

  ngOnInit() {
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
    this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe(quote => {
      this.quote = JSON.parse(JSON.stringify(quote));
      if (this.quote) {
        this.setData();
      }
    });
  }
  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
  }
}
