<div>
  <app-loader [isLoading]="isLoading"></app-loader>

  <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 c-px-24 mb-3">
    <h4 *ngIf="selectedQuoteShippingAddress || selectedQuoteContactPerson"
      class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">
      Levering & contacten </h4>

    <div class="row gutter-row-24" *ngIf="selectedQuoteShippingAddress || selectedQuoteContactPerson">
      <div class="col-md-6 mb-4 gutter-col-24">
        <div class="bg-gray-1 tri-rounded-20 p-24">
          <label for=""
            class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0">Afleveradres</label>
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="min-height-100">
                <p class="font-kpn-extended mb-2">
                  <span class="d-block">{{ selectedQuoteShippingAddress?.company_name }} </span>
                  <span class="d-block">{{ selectedQuoteShippingAddress?.street }} {{
                    selectedQuoteShippingAddress?.house_number }} </span>
                  <span class="d-block">{{ selectedQuoteShippingAddress?.zip_code }} {{
                    selectedQuoteShippingAddress?.city }}</span>
                  <span class="d-block">{{ selectedQuoteShippingAddress?.country }} </span>
                </p>
              </div>
            </div>
          </div>
          <p class="mb-0">
            <a href="javascript:void(0)" (click)="openCustomAddressModal()"
              class="fs-16 font-kpn-extended link-btn-blue">
              Wijzig afleveradres <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
          </p>
        </div>
      </div>
      <div class="col-md-6 mb-4 gutter-col-24">
        <div class="bg-gray-1 tri-rounded-20 p-24 h-100">
          <label for=""
            class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0">Contactpersoon</label>
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="min-height-100">
                <p class="font-kpn-extended mb-2">
                  <span class="d-block"> {{ selectedQuoteContactPerson?.initials }} {{
                    selectedQuoteContactPerson?.last_name }}</span>
                  <span class="d-block"> {{ selectedQuoteContactPerson?.mobile_phone_number }} </span>
                  <span class="d-block">{{ selectedQuoteContactPerson?.email }} </span>
                </p>
              </div>
            </div>
          </div>
          <p class="mb-0">
            <a href="javascript:void(0)" (click)="openContactPersonModal(contactPersonUpdateModal)"
              class="fs-16 font-kpn-extended link-btn-blue">
              Wijzig contactpersoon <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
          </p>
        </div>
      </div>
    </div>
  </div>

  <div [ngClass]="{'notVisible' : !showAddressModal}">
    <div [ngClass]="{'custom-modal-backdrop' : showAddressModal}"></div>
    <div class="">
      <div [ngClass]="{'custom-modal' : showAddressModal}">
        <div class="modal-runded my-0"  [ngClass]="{'custom-modal-dialog custom-modal-dialog-centered' : showAddressModal}">
          <div class="modal-content modal-content-tri-rouned-20 border-0">
            <div class="">
              <div class="px-4">
                <div class="px-1 modal-header tri-modal-header">
                  <h6 class="modal-title green-text font-kpn-black fs-40" id="quoteListFilter">
                    {{ isCreatingAddress ? 'Adres toevoegen' : 'Afleveradres wijzigen' }}
                  </h6>
                  <button type="button" class="btn close" (click)="closeCustomAddressModal()"  aria-label="Close">
                    <span class="kpnew-icon-close-new"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="custom-modal-body p-0">
              <div class="pl-4 pt-4 pr-4">
                <div class="custom-inner-modal-scroll pr-4">
                  <div class="address-lines pt-3" *ngIf="!isCreatingAddress">
                    <div class="quote-address-item" *ngFor="let item of quoteShippingAddresses;index as i">
                      <input type="radio" name="payment-option" [id]="i+'address'"
                        [(ngModel)]="temporalSelectedAddressIdForUpdateQuoteShippingAddress" [value]="item.id">
                      <label [for]="i+'address'" class="d-flex font-kpn-extended">
                        <span
                          class="standard-address font-kpn-extended kpn-extended-bold fs-12 letter-spacing-normal">Standaard
                          afleveradres</span>
                        <span class="radio-bullet mt-1"></span>
                        <div>
                          <span class="block">{{ item?.company_name }}</span>
                          <span class="d-block grey-text">
                            {{ item?.street }} {{ item?.house_number }}, {{ item?.zip_code }} {{ item?.city }} {{
                            item?.country }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row gutter-row-15 mb-2" *ngIf="isCreatingAddress" >
                    <div class="mb-4 col-md-12 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Bedrijfsnaam<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Bedrijf"
                        [(ngModel)]="newAddress.company_name">
                    </div>
                    <div class="col-12 gutter-col-15 mb-4">
                      <div class="kpn-form-group">
                        <label class="field-label">Select Country <span class="text-danger"> * </span></label>
                        <ng-select class="tri-ng-select" placeholder="Select Country" [items]="country" bindLabel="name"
                          bindValue="country_code" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}"
                          (change)="onSelectCountry($event)">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-12 gutter-col-15 mb-4" *ngIf="selectedCountry && selectedCountry !== NL_CODE && selectedCountryObject?.searchable" >
                      <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                        <div class="form-group kpn-form-group">
                          <label class="field-label">Zoeken</label>
                          <div
                            class="kpn-form-group tri-autocomplete-form icon-right handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                            [ngClass]="{'search-active' : searchFocused}">
                            <i class="search-icon kpn-ui-icon ui-search"></i>
                            <div class="ng-autocomplete">
                              <ng-autocomplete id="auto" #auto class="" [data]="addressList"
                                [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                                [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                                (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                                (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                                [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                                [notFoundTemplate]="notFoundTemplate">
                              </ng-autocomplete>
                              <ng-template #itemTemplate let-item>
                                <div class="auto-template">
                                  <span [innerHTML]="item.value"></span> &nbsp;
                                  <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                                </div>
                              </ng-template>
                              <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                              </ng-template>
                            </div>
                            <span class="text-muted" *ngIf="!foreignAddressSearchError">
                              <small> {{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                            </span>
                            <span class="danger-text" *ngIf="foreignAddressSearchError">
                              <small>{{foreignAddressSearchError}}</small>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 col-md-4 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Postcode<span
                          class="text-danger">*</span></label>
                      <input [disabled]="selectedCountry !== 'NL' && (selectedCountryObject?.searchable && !hasInputCustomAddressPermission)"   oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern" mask="111100"
                      [(ngModel)]="newAddress.zip_code"
                       (keyup)="onAddressSearch()" type="text" [patterns]="zipCodePattern" [mask]="selectedCountry === 'NL' ? '111100' :'AAAAAAAAAA'" class="form-control tri-form-control" placeholder="Postcode">
                      <div *ngIf="errors?.zip_code" class="text-danger">
                        {{ errors?.zip_code }}
                      </div>
                    </div>
                    <div class="mb-4 col-md-4 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Huisnr<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Huisnummer" [disabled]="selectedCountry !== 'NL' && (selectedCountryObject?.searchable && !hasInputCustomAddressPermission)" name="h_number"
                      [(ngModel)]="newAddress.house_number"
                      class="form-control tri-form-control" (keyup)="onAddressSearch()"
                     >
                     <div *ngIf="errors?.house_number" class="text-danger">
                      {{ errors?.house_number }}
                    </div>
                    </div>
                    <div class="mb-4 col-md-4 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Toevoeging</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Toevoeging"   [(ngModel)]="newAddress.house_number_extension">
                      <div *ngIf="errors?.house_number_extension" class="text-danger">
                        {{ errors?.house_number_extension }}
                      </div>
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Straat</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Straat"  [(ngModel)]="newAddress.street"  [disabled]="selectedCountry === 'NL'  ||  (selectedCountry && addressFound.street && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)">
                      <div *ngIf="errors?.street" class="text-danger">
                        {{ errors?.street }}
                      </div>
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Woonplaats</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Woonplaats"  [(ngModel)]="newAddress.city"  [disabled]="selectedCountry === 'NL'  ||  (selectedCountry && addressFound.city && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)">
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15" *ngIf="hasInputCustomAddressPermission">
                      <div class="kpn-form-group">
                        <label for="info_1" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_1' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="newAddress.extra_address_information_1"
                            class="form-control tri-form-control">
                          </textarea>

                          <div *ngIf="errors?.extra_address_information_1" class="invalid-feedback">
                            {{ errors?.extra_address_information_1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15" *ngIf="hasInputCustomAddressPermission">
                      <div class="kpn-form-group">
                        <label for="info_2" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_2' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="newAddress.extra_address_information_2"
                            class="form-control tri-form-control">
                          </textarea>

                          <div *ngIf="errors?.extra_address_information_2" class="invalid-feedback">
                            {{ errors?.extra_address_information_2 }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="note-box note-box-red mb-2  col-md-12 gutter-col-15" *ngIf="addressCombinationMsg">
                      <span class="circle-hint-icon">i</span>
                      <p class="body-text-1 mb-0">
                        {{addressCombinationMsg}}
                      </p>
                    </div>
                    <!-- <div class="col-md-12 gutter-col-15">
                      <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
                        name="multiselect-items">
                        <label ngbButtonLabel class="checkbox-item d-flex align-items-center">
                          <div class="checkbox-btn checkbox-btn-14 checkbox-btn-outline">
                            <input ngbButton type="checkbox" class="position-absolute opacity-0">
                            <span class="checkBoxCheckmark checkBoxCheckmark-14"></span>
                          </div>
                          <div class="checkbox-label font-kpn-extended fs-16 ml-2 pl-1 w-100">
                            Sla op als nieuwe adres
                          </div>
                        </label>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
              <div class="p-4">
                <div class="row gutter-row-8">
                  <div class="col-md-auto gutter-col-8">
                    <button *ngIf="!isCreatingAddress" (click)="openNewAddressCreateView()"
                      class="btn px-4 border-2 btn-outline-primary rounded-btn">
                      <span class="d-inline-block px-2"><span>Adres toevoegen</span> <i
                          class="kphicon-uniE985 align-middle fs-24 ml-1"></i></span>
                    </button>
                    <button *ngIf="isCreatingAddress"  (click)="closeNewAddressCreateView()"
                      class="btn px-4 border-2 btn-outline-primary rounded-btn">
                      <span class="d-inline-block px-2">Vorige</span>
                    </button>
                  </div>
                  <div class="col-md-auto gutter-col-8">
                    <button (click)="createAddressAndUpdateQuote()" class="btn px-4 btn-primary rounded-btn" [disabled]="newAddressCreateDisabled" *ngIf="isCreatingAddress">
                      <span class="d-inline-block px-2">Opslaan</span>
                    </button>
                    <button
                      [disabled]="temporalSelectedAddressIdForUpdateQuoteShippingAddress === selectedQuoteShippingAddress?.id"
                      (click)="updateQuoteShippingAddress(temporalSelectedAddressIdForUpdateQuoteShippingAddress)" class="btn px-4 btn-primary rounded-btn"
                      *ngIf="!isCreatingAddress">
                      <span d-inline-block px-2>Adres wijzigen</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #contactPersonUpdateModal let-modal>
    <div class="px-4">
      <div class="px-1 modal-header tri-modal-header">
        <h6 class="modal-title green-text font-kpn-black fs-40" id="quoteListFilter">
          {{ isContactPersonCreating ? 'Contact toevoegen' : ' Contactpersoon wijzigen' }}
        </h6>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click'); temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = selectedQuoteContactPerson?.uuid" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <div>
        <div class="pl-4 pt-4 pr-4">
          <div class="custom-inner-modal-scroll pr-4">
            <div class="address-lines pt-3" *ngIf="!isContactPersonCreating">
              <div class="quote-address-item" *ngFor="let item of quoteContactPersons;index as i">
                <input type="radio" name="payment-option" [id]="i+'contact-person'"
                  [(ngModel)]="temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson" [value]="item.uuid">
                <label [for]="i+'contact-person'" class="d-flex font-kpn-extended">
                  <span
                    class="standard-address font-kpn-extended kpn-extended-bold fs-12 letter-spacing-normal">Standaard
                    contactpersoon</span>
                  <span class="radio-bullet mt-1"></span>
                  <div>
                    <span class="block">{{ item?.initials }} {{ item?.last_name }}</span>
                    <span class="d-block grey-text">
                      {{ item?.email }} {{ item?.mobile_phone_number ? ' | '+item?.mobile_phone_number : '' }}
                    </span>
                  </div>
                </label>
              </div>
            </div>

            <div class="row gutter-row-15 mb-2" *ngIf="isContactPersonCreating">


              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Voorletter<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.initials"
                  placeholder="Voorletter">
                <div *ngIf="contactPersonCreateError?.initials" class="text-danger">
                  {{ contactPersonCreateError?.initials?.message || contactPersonCreateError?.initials[0] }}
                </div>
              </div>
              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Voornaam<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.first_name"
                  placeholder="Voornaam">
                <div *ngIf="contactPersonCreateError?.first_name" class="text-danger">
                  {{ contactPersonCreateError?.first_name?.message || contactPersonCreateError?.first_name[0] }}
                </div>
              </div>
              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Tussenvoegsel</label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.surname_prefix"
                  placeholder="Tussenvoegsel">
              </div>
              <div class="mb-4 col-md-6 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Achternaam<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.last_name"
                  placeholder="Achternaam">
                <div *ngIf="contactPersonCreateError?.last_name" class="text-danger">
                  {{ contactPersonCreateError?.last_name?.message || contactPersonCreateError?.last_name[0]}}
                </div>
              </div>
              <div class="mb-4 col-md-12 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">E-mailadres<span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control" [(ngModel)]="newContactPerson.email"
                  placeholder="E-mailadres">
                <div *ngIf="contactPersonCreateError?.email" class="text-danger">
                  {{ contactPersonCreateError?.email?.message || contactPersonCreateError?.email[0]}}
                </div>
              </div>
              <div class="col-md-12 gutter-col-15">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Telefoonnummer</label>
                <input type="text" class="form-control tri-form-control"
                  [(ngModel)]="newContactPerson.mobile_phone_number" placeholder="Telefoonnummer">
                <div *ngIf="contactPersonCreateError?.mobile_phone_number" class="text-danger">
                  {{ contactPersonCreateError?.mobile_phone_number?.message ||
                  contactPersonCreateError?.mobile_phone_number[0]}}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <button *ngIf="!isContactPersonCreating && contactPersonAddPermission"
                (click)="openNewContactPersonCreateView()" class="btn px-4 border-2 btn-outline-primary rounded-btn">
                <span class="d-inline-block px-2"><span>Contact toevoegen</span> <i
                    class="kphicon-uniE985 align-middle fs-24 ml-1"></i></span>
              </button>
              <button *ngIf="isContactPersonCreating" (click)="closeNewContactPersonCreateView()"
                class="btn px-4 border-2 btn-outline-primary rounded-btn">
                <span class="d-inline-block px-2">Vorige</span>
              </button>

            </div>
            <div class="col-md-auto gutter-col-8">
              <button (click)="createContactAndUpdateQuote()" class="btn px-4 btn-primary rounded-btn"
                *ngIf="isContactPersonCreating" [disabled]="
                                  !newContactPerson?.first_name?.trim()?.length ||
                                  !newContactPerson?.last_name?.trim()?.length ||
                                  !newContactPerson?.email?.trim()?.length ||
                                  !newContactPerson?.initials?.trim()?.length">
                <span class="d-inline-block px-2">Ga verder</span>
              </button>

              <button
                [disabled]="temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson === selectedQuoteContactPerson?.uuid"
                (click)="updateQuoteContactPerson()" class="btn px-4 btn-primary rounded-btn"
                *ngIf="!isContactPersonCreating">
                <span d-inline-block px-2>Contact wijzigen</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>

</div>
