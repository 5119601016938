import { BrowserModule, Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';

import { NgxPageScrollModule } from 'ngx-page-scroll';

import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';

import { AppRoutingModule } from './app-routing.module';

import { AppComponent } from './app.component';
import { HeaderComponent } from './component/header/header.component';
import { NotificationComponent } from './component/notification/notification.component';
import { SidebarComponent } from './component/sidebar/sidebar.component';
import { HomeComponent } from './pages/home/home.component';
import { ProductDetailsComponent } from './pages/product-details/product-details.component';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { ToastrModule } from 'ngx-toastr';
import { NgxSliderModule } from '@angular-slider/ngx-slider';


import { AuthService } from './shared/auth.service';
import { TokenInterceptor } from './shared/token.interceptor';
import { HandsetsComponent } from './pages/handsets/handsets.component';
import { ProductListComponent } from './pages/product-list/product-list.component';
import { CartComponent } from './pages/cart/cart.component';
import { CustomersComponent } from './pages/customers/customers.component';
import { CustomerComponent } from './pages/customer/customer.component';
import { QuotesComponent } from './pages/quotes/quotes.component';
import { QuoteDetailsComponent } from './pages/quote-details/quote-details.component';
import { SelectionOverviewComponent } from './component/selection-overview/selection-overview.component';
import { FormatPricePipe } from './shared/format-price.pipe';
import { ProductDetailsHandSetComponent } from './component/product-details-handset/product-details-handset.component';
import { ProductDetailsNoHandsetComponent } from './component/product-details-no-handset/product-details-no-handset.component';
import { LoginComponent } from './auth/login/login.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { TwofactorauthComponent } from './auth/two-factor-auth/two-factor-auth.component';
import { QRCodeModule } from 'angularx-qrcode';
import { CurrencyFormatPipe } from './shared/pipes/currency-format.pipe';
import { DropdownComponent } from './component/dropdown/dropdown.component';
import { OrderStatusComponent } from './pages/order-status/order-status.component';
import { OrderConfirmationComponent } from './pages/order-confirmation/order-confirmation.component';
import { ActionReducerMap, StoreModule } from '@ngrx/store';
import { permissionReducer } from './store/reducers/permission.reducer';
import { PermissionEffects } from './store/effects/permission.effects';
import { EffectsModule } from '@ngrx/effects';
import { AddCustomerComponent } from './pages/add-customer/add-customer.component';
import { PriceFilterPipe } from './shared/pipes/price-filter.pipe';
import { SalesPriceFilterPipe } from './shared/pipes/sales-price-filter.pipe';
import { CatalogEffects } from './store/effects/catalog.effects';
import { catalogReducer, selectedCatalogAndProductGroupReducer } from './store/reducers/catalog.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { childGroupReducer, selectedChildGroupReducer } from './store/reducers/productGroup.reducer';
import { NavMenuComponent } from './component/nav-menu/nav-menu.component';
import { NavTitleComponent } from './component/nav-title/nav-title.component';
import { OrderOverviewComponent } from './pages/order-overview/order-overview.component';
import { GripRedirectComponent } from './auth/grip-redirect/grip-redirect.component';
import { ExportFileComponent } from './pages/export-file/export-file.component';
import { MyAccountComponent } from './pages/my-account/my-account.component';
import { FooterComponent } from './component/footer/footer.component';
import { VoucherListComponent } from './pages/voucher-list/voucher-list.component';
import { TextTrimPipe } from './shared/pipes/text-trim.pipe';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { TwoDecimalInputDirective } from './shared/directives/twodecimalinput.directive';
import { PositiveIntegerDirective } from './shared/directives/positiveIntegerInput.directive';
import { StepsComponent } from './shared/components/steps/steps.component';
import { OrderSuccessComponent } from './pages/order-success/order-success.component';
import { BreadcrumbComponent } from './shared/components/breadcrumb/breadcrumb.component';
import { PaymentComponent } from './pages/payment/payment.component';
import { SignUpComponent } from './auth/sign-up/sign-up.component';
import { SiteProfileEffects } from './store/effects/siteProfile.effects';
import { siteProfileReducer } from './store/reducers/siteProfile.reducer';
import { DepotHistoryComponent } from './pages/depot-history/depot-history.component';
import { SalesForecastComponent } from './pages/forecast/sales-forecast/sales-forecast.component';
import { ForecastComponent } from './pages/forecast/forecast.component';
import { SearchProductComponent } from './pages/quote-details/search-product.component';
import { CustomerListComponent } from './pages/customer-list/customer-list.component';
import { ImportOrderComponent } from './pages/import-order/import-order.component';
import { RmaOrdersComponent } from './pages/rma/rma-list/rma-orders/rma-orders.component';
import { RmaDetailsComponent } from './pages/rma/rma-list/rma-details/rma-details.component';
import { ExportProductsComponent } from './pages/export-products/export-products.component';
import { RmaCreateComponent } from './pages/rma/rma-create/rma-create.component';
import { ReportComponent } from './pages/report/report.component';
import { UserActivationComponent } from './auth/user-activation/user-activation.component';
import { FavoriteProductsComponent } from './pages/favorite-products/favorite-products.component';
import { TradeInComponent } from './pages/trade-in/trade-in.component';
import { TradeInDetailsComponent } from './pages/trade-in/trade-in-details/trade-in-details.component';
import { TradeInCustomerComponent } from './pages/trade-in/customer/customer.component';
import { CartOverviewComponent } from './pages/trade-in/cart-overview/cart-overview.component';
import { TradeInOrderDetailsComponent } from './pages/trade-in/tradein-order-details/tradein-order-details.component';
import { TradeInOrdersComponent } from './pages/trade-in/trade-in-orders/trade-in-orders.component';
import { CartDetailsComponent } from './pages/trade-in/cart-details/cart-details.component';
import { TradeInCartComponent } from './pages/trade-in/trade-in-cart/trade-in-cart.component';
import { SmsCampaignToolComponent } from './pages/sms-campaigntool/sms-campaigntool/sms-campaigntool.component';
import { SalesforceComponent } from './pages/salesforce/salesforce.component';
import { AccountTypeComponent } from './pages/account-type/account-type.component';
import { FaqComponent } from './pages/faq/faq.component';
import { VatNumberInputComponent } from './component/vat-number-input/vat-number-input.component';

// transloco for multi language support
import { TranslocoRootModule } from './transloco-root.module';
import { GripStartComponent } from './auth/grip-start/grip-start.component';
import { CustomerSelectComponent } from './pages/trade-in/customer-select/customer-select.component';
import { TradeInStepsComponent } from './shared/components/trade-in-steps/trade-in-steps.component';
import { TradeInFinishedComponent } from './pages/trade-in/trade-in-finished/trade-in-finished.component';
import { ExportAllCatalogsComponent } from './pages/export-all-catalogs/export-all-catalogs.component';
import { MsRedirectComponent } from './auth/ms-redirect/ms-redirect.component';
import { LoginAzureComponent } from './auth/login-azure/login-azure.component';
import { PrettyjsonPipe } from './shared/pipes/prettyjson.pipe';
import { BusinessPartnerSelectionComponent } from './pages/trade-in/business-partner-selection/business-partner-selection.component';
import { LoaderComponent } from './shared/components/loader/loader.component';
import { ShippingMethodComponent } from './pages/trade-in/shipping-method/shipping-method.component';
import { CustomerQuoteDetailsStepComponent } from './pages/quote-details/customer/customer-quote-details-step/customer-quote-details-step.component';
import { CustomerQuoteDetailsPlaceOrderStepComponent } from './pages/quote-details/customer/customer-quote-details-place-order-step/customer-quote-details-place-order-step.component';
import { AddressAndContactComponent } from './pages/quote-details/customer/shared/address-and-contact/address-and-contact.component';
import { QuoteDetailsSummaryComponent } from './pages/quote-details/customer/shared/quote-details-summary/quote-details-summary.component';
import { CustomerQuoteAddressComponent } from './pages/quote-details/customer/shared/customer-quote-address/customer-quote-address.component';
import { CustomerQuoteContactPersonComponent } from './pages/quote-details/customer/shared/customer-quote-contact-person/customer-quote-contact-person.component';
import { CustomerQuoteDetailsFinalStepComponent } from './pages/quote-details/customer/customer-quote-details-final-step/customer-quote-details-final-step.component';
import { CustomerQuoteCpassComponent } from './pages/quote-details/customer/shared/customer-quote-cpass/customer-quote-cpass.component';
import { CustomerQuoteStepsComponent } from './pages/quote-details/customer/shared/customer-quote-steps/customer-quote-steps.component';
import { CustomerQuoteDetailsInformationStepComponent } from './pages/quote-details/customer/customer-quote-details-information-step/customer-quote-details-information-step.component';
import { CustomerQuotePoAndReferenceComponent } from './pages/quote-details/customer/shared/customer-quote-po-and-reference/customer-quote-po-and-reference.component';
import { CustomerQuoteDetailsConfigurationStepComponent } from './pages/quote-details/customer/customer-quote-details-configuration-step/customer-quote-details-configuration-step.component';
import { CustomerQuoteEnrollmentComponent } from './pages/quote-details/customer/shared/customer-quote-enrollment/customer-quote-enrollment.component';
import { CustomerQuoteDetailsSummaryStepComponent } from './pages/quote-details/customer/customer-quote-details-summary-step/customer-quote-details-summary-step.component';
import { CustomerQuoteSideBarPriceSummaryComponent } from './pages/quote-details/customer/shared/customer-quote-side-bar-price-summary/customer-quote-side-bar-price-summary.component';


export const options: Partial<IConfig> | (() => Partial<IConfig>) = {};

const reducers: ActionReducerMap<any> = {
  permission: permissionReducer,
  catalog: catalogReducer,
  childGroup: childGroupReducer,
  selectedChildGroup: selectedChildGroupReducer,
  selectedCatalogAndProductGroup: selectedCatalogAndProductGroupReducer,
  siteProfile: siteProfileReducer
};

const effects: any[] = [
  PermissionEffects,
  CatalogEffects,
  SiteProfileEffects
];

@NgModule({
  declarations: [
    HeaderComponent,
    NotificationComponent,
    SidebarComponent,
    AppComponent,
    HomeComponent,
    ProductDetailsComponent,
    ProductDetailsHandSetComponent,
    ProductDetailsNoHandsetComponent,
    HandsetsComponent,
    ProductListComponent,
    CartComponent,
    CustomersComponent,
    CustomerComponent,
    QuotesComponent,
    QuoteDetailsComponent,
    SelectionOverviewComponent,
    FormatPricePipe,
    LoginComponent,
    TwofactorauthComponent,
    ResetPasswordComponent,
    CurrencyFormatPipe,
    PriceFilterPipe,
    SalesPriceFilterPipe,
    TextTrimPipe,
    DropdownComponent,
    OrderStatusComponent,
    OrderConfirmationComponent,
    AddCustomerComponent,
    NavMenuComponent,
    NavTitleComponent,
    OrderOverviewComponent,
    GripRedirectComponent,
    ExportFileComponent,
    MyAccountComponent,
    FooterComponent,
    VoucherListComponent,
    TwoDecimalInputDirective,
    PositiveIntegerDirective,
    StepsComponent,
    OrderSuccessComponent,
    BreadcrumbComponent,
    PaymentComponent,
    SignUpComponent,
    DepotHistoryComponent,
    SalesForecastComponent,
    ForecastComponent,
    SearchProductComponent,
    CustomerListComponent,
    ImportOrderComponent,
    RmaOrdersComponent,
    RmaDetailsComponent,
    ExportProductsComponent,
    RmaCreateComponent,
    ReportComponent,
    UserActivationComponent,
    FavoriteProductsComponent,
    TradeInComponent,
    TradeInDetailsComponent,
    TradeInCustomerComponent,
    CartOverviewComponent,
    TradeInOrderDetailsComponent,
    TradeInOrdersComponent,
    CartDetailsComponent,
    TradeInCartComponent,
    SmsCampaignToolComponent,
    SalesforceComponent,
    AccountTypeComponent,
    FaqComponent,
    VatNumberInputComponent,
    GripStartComponent,
    CustomerSelectComponent,
    TradeInStepsComponent,
    TradeInFinishedComponent,
    ExportAllCatalogsComponent,
    MsRedirectComponent,
    LoginAzureComponent,
    PrettyjsonPipe,
    BusinessPartnerSelectionComponent,
    LoaderComponent,
    ShippingMethodComponent,
    CustomerQuoteDetailsStepComponent,
    CustomerQuoteDetailsPlaceOrderStepComponent,
    AddressAndContactComponent,
    QuoteDetailsSummaryComponent,
    CustomerQuoteAddressComponent,
    CustomerQuoteContactPersonComponent,
    CustomerQuoteDetailsFinalStepComponent,
    CustomerQuoteCpassComponent,
    CustomerQuoteStepsComponent,
    CustomerQuoteDetailsInformationStepComponent,
    CustomerQuotePoAndReferenceComponent,
    CustomerQuoteDetailsConfigurationStepComponent,
    CustomerQuoteEnrollmentComponent,
    CustomerQuoteDetailsSummaryStepComponent,
    CustomerQuoteSideBarPriceSummaryComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgxPaginationModule,
    StoreModule.forRoot(reducers),
    EffectsModule.forRoot(effects),
    NgxMaskModule.forRoot(options),
    NgxPageScrollModule,
    NgMultiSelectDropDownModule.forRoot(),
    AutocompleteLibModule,
    CommonModule,
    BrowserAnimationsModule, // required animations module
    NgxSliderModule,
    ToastrModule.forRoot({
      timeOut: 5000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      closeButton: true,
    }), // ToastrModule added
    QRCodeModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslocoRootModule
  ],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    CurrencyFormatPipe,
    PriceFilterPipe,
    SalesPriceFilterPipe,
    Title,
    { provide: OWL_DATE_TIME_LOCALE, useValue: 'nl' },
  ],
  exports: [StoreModule],
  bootstrap: [AppComponent]
})
export class AppModule { }
