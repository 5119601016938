import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { QuoteDetailsService } from '../../../quote-details.service';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { ESupplierTypes } from 'src/app/shared/enums/product-type.enums';
import { EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-quote-cpass',
  templateUrl: './customer-quote-cpass.component.html',
  styleUrls: ['./customer-quote-cpass.component.scss']
})
export class CustomerQuoteCpassComponent {
  quote = null;
  isLoading = false;
  hasCpaas = null;
  isCpassDataAdded = false;
  cPassData = {
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: "",
  };
  cpassUpdateModalData = {
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: "",
  };
  feRequiredFieldErrors = {
    po_number: "",
    customer_reference: "",
    term_and_condition: "",
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: ""
  };
  cpaasDataUpdateModalRef:NgbModalRef;
  currentCustomerDetailsQuoteSubscription$ :Subscription;
  @Output() updateCpassDataEventEmitter: EventEmitter<void> = new EventEmitter();
  @Input() showEditButtonInBottom = false;
  @Input() applyMinHight = false;
  // @Input() shoEditBtn = false;
  constructor (
    private modalService: NgbModal,
    private quoteDetailsService: QuoteDetailsService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService
  ) {

  }
  get eQuoteStatus() { return EQuoteStatus; }

  get shoEditBtn() {
    return ((this.eQuoteStatus.APPROVED == this.quote?.status) || (this.eQuoteStatus.NEW == this.quote?.status) || (this.eQuoteStatus.PENDING == this.quote?.status));
  }
  ngOnInit() {
    this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe(quote => {
      this.quote = quote;
      this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
      if (this.hasCpaas) {
        this.cPassData = {
          cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
          cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
          cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
        }
        this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
      };
    });
  }

  get isCpassUpdateDisabled() {
    return !this.cpassUpdateModalData.cpaas_user_email?.trim()?.length || !this.validateEmail(this.cpassUpdateModalData.cpaas_user_email) || !this.cpassUpdateModalData.cpaas_user_first_name?.trim()?.length || !this.cpassUpdateModalData.cpaas_user_last_name?.trim()?.length;
  }
  openCpaasDataUpdateModal(cpaasDataUpdateModal) {
    this.cpaasDataUpdateModalRef = this.modalService.open(cpaasDataUpdateModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-595 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });

    this.cpassUpdateModalData = {
      cpaas_user_email: this.cPassData.cpaas_user_email,
      cpaas_user_first_name: this.cPassData.cpaas_user_first_name,
      cpaas_user_last_name: this.cPassData.cpaas_user_last_name,
    }

    this.cpaasDataUpdateModalRef.result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  async saveCpassData(payload) {
    this.isLoading = true;
    return this.quoteDetailsService.updateCpaasUserInfo(this.quote.uuid, payload).toPromise().then(res => {
      this.isLoading = false;
      this.quote = res.data;
      this.setCpassData();
      if (this.cpaasDataUpdateModalRef) {
        this.cpaasDataUpdateModalRef.close();
      }
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`);
      return false;
    });
  }
  setCpassData() {
    this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
    if (this.hasCpaas) {
      this.cPassData = {
        cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
        cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
        cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
      }
      this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
    }
    this.cpassUpdateModalData = {
      ...this.cPassData
    }
    this.updateCpassDataEventEmitter.emit(this.quote);
  }
  validateEmail(emailInput = ''): boolean {

    // Regular expression for validating an email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
    // Check if the email matches the regex pattern
    if (emailRegex.test(emailInput)) {
      return true;
    } else {
      return false;
    }
  }
  onCpassLastNameChange(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.cpaas_user_last_name = '';
    }
  }
  onCpassFirstNameChange(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.cpaas_user_first_name = '';
    }
  }
  onCpassEmailChange(event) {
    const value = event.target.value;
    if (value) {
      if (!this.validateEmail(value)) {
        this.feRequiredFieldErrors.cpaas_user_email = 'Ongeldig e-mailadres';
      } else {
        this.feRequiredFieldErrors.cpaas_user_email = '';
      }
    }
  }
  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
  }
}
