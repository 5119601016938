<!-- <header>
  <div class="header-top">
    <div class="container">
      <div class="row">
        <div class="col">
          <ul class="user-top-nav">
            <li placement="bottom" [ngbTooltip]="name"><span>Ingelogde gebruiker: </span> {{ userName }} </li>
            <li><span>Klant: </span> {{ customerName }} </li>
            <li class="mr-1"><span>Catalogus:</span></li>
            <li class="py-1">
              <div class="custom-select-option custom-selectc-cataloge"
                *ngIf="hasCatalogPermission;else catalogContent">
                <select name="" id="" [(ngModel)]="selectedCatalogCode" (ngModelChange)="getSelectedCatalog()"
                  class="form-control">
                  <option *ngFor="let item of catalogList" [ngValue]="item?.code">{{item?.name}}
                  </option>
                </select>
              </div>
              <ng-template #catalogContent>
                Permission Denied
              </ng-template>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="header-first-part">
    <div class="container">
      <div class="row align-items-center row-fix-mobile">
        <div class="col-auto brand-col">
          <div class="brand-logo">
            <img src="../../../assets/images/logo.png" alt="" />
          </div>
        </div>

        <div class="col hide-on-pad">
          <div class="row align-items-center">
            <div class="col-auto">
              <div class="top-right-order-status text-left d-flex align-items-center">
                <a routerLink="home" (click)="resetPagination('home')" routerLinkActive="active" title="Product">Product</a>
                <a *ngIf="hasOrderStatusPermission" (click)="resetPagination('orders')" routerLink="order-status" routerLinkActive="active"
                  title="Order Status">Orders</a>
                <a *ngIf="hasQuotePermission" (click)="resetPagination('quotes')" routerLink="quotes" routerLinkActive="active" title="Quotes">Quotes</a>

                <div ngbDropdown class="d-inline-block make-estimation-dropdown make-estimation-dark" *ngIf="!is_external">
                  <button id="ciscoPassword" ngbDropdownToggle class="btn icon-btn-flat btn-sm mr-2"
                    [disabled]="!isCiscoSystem" (click)="validationOfSystem()">
                    Cisco Quotes
                  </button>
                  <div ngbDropdownMenu aria-labelledby="ciscoPassword">
                    <div class="px-2">
                      <p class="body-text-18-light text-center mb-2">
                        Cisco password
                      </p>
                      <div class="form-group d-flex align-items-center px-1 mb-1">
                        <input type="password" class="form-control" [(ngModel)]="passwordOfMakeQutation"
                          placeholder="Wachtwoord" />
                        <button class="btn icon-btn-flat icon-btn-flat-green btn-sm px-0" (click)="setPassword()">
                          <i class="kphicon-uniE907"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <a style="cursor: pointer;" *ngIf="!is_external" title="Klant kiezen"
                  (click)="goToExistingCustomer()">Klant kiezen</a>
                <a style="cursor: pointer;" *ngIf="hasVoucherPermission" title="Voucherlijst"
                (click)="resetPagination('vouchers')" routerLink="voucher-list" routerLinkActive="active" >Vouchers</a>
              </div>
            </div>
          </div>
        </div>

        <div class="col-auto">
          <div class="cart-with-linkd">
            <div class="logout-mobile-view show-on-pad">
              <button class="btn circle-icon-btn" (click)="showMobileMenu()">
                <i class="fas fa-bars"></i>
              </button>
            </div>

            <button ngbDropdown class="btn circle-icon-btn user-action-tray" placement="bottom-right">
              <i class="kphicon-uniE903 icon-size-md" id="dropdownBasic1" ngbDropdownToggle></i>
              <div class="app-dropdown" ngbDropdownMenu aria-labelledby="dropdownBasic1">
                <button ngbDropdownItem (click)="jumpToPage('my-account')">My account</button>
                <button ngbDropdownItem (click)="jumpToPage('export-file')">Export Prijslijst</button>
              </div>
            </button>

            <div class="short-cart-overview ml-0">
              <button class="btn circle-icon-btn" (click)="goToCartOverview()">
                <i class="kphicon-uniE9A0 icon-size-md"></i>
                <span class="cart-item-amount" *ngIf="cart?.lines?.length">{{
                  cart?.lines?.length
                  }}</span>
                <span class="cart-item-amount" *ngIf="cart && !cart?.lines?.length">0</span>
              </button>

              <div class="cart-lines-dropdown">
                <p *ngIf="!cart?.lines?.length" class="sub-title-light-5 dark-grey-text text-center px-3 py-3">
                  Geen items in winkelwagen.
                </p>
                <div *ngIf="cart?.lines?.length">
                  <div class="cart-item-wrap">
                    <ul class="cart-item">
                      <li *ngFor="let line of cart?.lines; let i = index">
                        <div class="main-line default-line">
                          <div class="line-name">
                            <span class="short-cart-img"><img *ngIf="line?.image" src="{{line?.image}}"
                                alt="{{ line?.product_name }}" />
                              <img *ngIf="!line?.image && !line.brand?.includes('Cisco')" src="../../../assets/images/noimage.png"
                                alt="{{ line?.product_name }}">
                                <img *ngIf="!line?.image && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                                src="../../../assets/images/cisco-networking.png" alt="">
                            </span>
                            <p>
                              {{ line?.product_name }}
                              <i class="line-amount ml-1 green-text">({{ line?.quantity }})</i>
                              <label class="subline-item" *ngFor="let subline of line?.sub_lines">
                                {{subline?.product_name}}
                              </label>
                            </p>
                          </div>
                          <span class="line-price pt-1">{{
                            !isBusiness
                            ? (line?.total_amount | currencyFormat)
                            : (line?.total_amount_ex_vat | currencyFormat)
                            }}
                          </span>
                          <span class="line-price pt-1">
                            {{line?.total_amount_ex_vat | currencyFormat}}
                          </span>
                        </div>
                        <div class="main-line main-line-bwt default-line" *ngIf="isBusiness">
                          <p>B.T.W</p>
                          <span class="line-price">{{
                            line?.total_amount_vat | currencyFormat
                            }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="cart-overview-action text-right">
                    <div class="shopping-cart-total text-right">
                      <p class="mb-0 total-per-month">
                        Totaal per maand :
                        <strong>{{
                          cart?.total_recurring_monthly_fee | currencyFormat
                          }}
                        </strong>
                      </p>
                      <p class="mb-0">
                        Totaal eenmalig :
                        <strong>{{ cart?.total_amount_ex_vat | currencyFormat }}
                        </strong>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="short-cart-overview ml-0">
              <button class="btn circle-icon-btn">
                <i class="kpn-ui-icon ui-search icon-size-md"></i>
              </button>
              <div class="cart-lines-dropdown search-dropdown">
                <div class="form-group mb-0 kpn-form-group auto-complete-header">
                  <i class="kpn-ui-icon ui-search search-icon"></i>
                  <input type="search" autocomplete="off" class="form-control kpn-form-control"
                    placeholder="Zoeken..." (keyup.enter)="onSearchProduct($event)" />
                  <ng-template #searchAndSelect>
                    <div class="ng-autocomplete">
                      <ng-autocomplete [data]="searchProductList" [placeHolder]="placeholder"
                        (inputChanged)='searchProduct($event)' [itemTemplate]="itemTemplate" [notFoundTemplate]="notFoundTemplate" [searchKeyword]="keyword" (selected)='onSelectedProduct($event)'></ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a [innerHTML]="item.name"></a>
                      </ng-template>
                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>

            <button (click)="onLogOut()" class="btn circle-icon-btn">
              <i class="kphicon-uniE967 icon-size-md"></i>
            </button>

            <div class="user-account-overview show-on-pad">
              <button class="btn circle-icon-btn">
                <i class="kpn-ui-icon ui-account"></i>
              </button>

              <div class="menu-dropdown">
                <div class="cart-item-wrap cart-item-wrap-account">
                  <div class="selected-user-name">
                    <span>Ingelogde gebruiker:</span>
                    <p>
                      <label class="user-name"> {{ userName }}</label>
                    </p>
                  </div>
                  <div class="selected-user-name">
                    <span> Klant:</span>
                    <p>
                      <label class="user-coc">{{ customerName }}</label>
                    </p>
                  </div>
                </div>

                <div class="user-action text-right">
                  <button class="btn btn-primary btn-sm" type="button">
                    Uitloggen
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="menu-wrapper">
    <div class="menu-body">
      <div class="container">
        <div class="row align-items-center justify-content-between pt-1 pb-1">
          <div class="col">
            <ul class="main-nav pl-0">
              <li *ngFor="let list of parentGroupList">
                <a [title]="list.name" style="cursor: pointer"
                  [ngClass]="{'active': (selectedProductGroupCode === list.code) }"
                  (click)="selectProductGroup(list)">{{ list.name }}</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
</header> -->

<div class="new-header">
  <div class="top-green-cta">
    <div class="container text-right">
      <a routerLink="/faq" class="button btn client-service-btn"><img
          src="/assets/images/user-vector.svg" alt=""> <b> Klantenservice </b></a>
    </div>
  </div>
  <div class="header-top-new d-none">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto">
          <a routerLink="home" class="brand-logo-new">
            <img [src]="logoSrc" alt="" />
          </a>
        </div>
        <div class="col">
          <div class="row align-items-center">
            <div class="col">
              <ul class="new-user-info">
                <!-- <li><span>Ingelogde gebruiker: </span> {{ userName }} </li> -->
                <li *ngIf="!is_external && customerName">
                  <span>{{'HEADER.CUSTOMER' | transloco}}: </span> {{ customerName || siteProfile?.title }}
                  <button *ngIf="customerName" (click)="gotoCustomer()" ngbTooltip="Klik om BTW-nummer toe te voegen"
                    type="button" class="btn btn-sm btn-link ">
                    <i class="kphicon-uniE931 ml-2 blue-text"></i>
                  </button>
                </li>
                <li *ngIf="
                    !is_external && customerName && hasCreditShowPermission
                  ">
                  <span>{{'HEADER.CREDIT_LIMIT' | transloco}}: </span>
                  {{ credit | currencyFormat }}
                </li>
                <li *ngIf="is_external && customer">
                  <span>{{'HEADER.CUSTOMER' | transloco}}: </span>
                  {{ customer?.company_name || siteProfile?.title }}
                  <button *ngIf="customer" (click)="gotoCustomer()" ngbTooltip="Klik om BTW-nummer toe te voegen"
                    type="button" class="btn btn-sm btn-link ">
                    <i class="kphicon-uniE931 ml-2 blue-text"></i>
                  </button>
                </li>
                <li *ngIf="is_external && customer && hasCreditShowPermission">
                  <span>{{'HEADER.CREDIT_LIMIT' | transloco}}: </span>
                  {{ customer?.credit_limit | currencyFormat }}
                </li>
              </ul>
            </div>
            <!-- <div class="col-md-2" *ngIf="hasRmaPermission.add && siteProfile?.allow_rma_handling && rmaTypes?.length">
              <div class="form-group kpn-form-group">
                <div class="custom-select-option custom-option-align-height">
                  <select name="" class="full-width form-control kpn-form-control" id="mobile-rma-types"
                    [(ngModel)]="selectedRmaType" (ngModelChange)="onChangeRmaTypes($event)">
                    <option [ngValue]="null">
                      Selecteer RMA Type
                    </option>
                    <option *ngFor="let item of rmaTypes" [ngValue]="item">
                      {{item?.name}} ({{item?.rma_flow_type}})
                    </option>
                  </select>
                </div>
              </div>
            </div> -->
            <div class="col-md-auto">
              <a class="help-an-service" [href]="helpLink" target="_blank">
                <img src="/assets/images/help-an-service-top.png" alt="" />
                <div class="help-an-service-content">
                  <h3>{{'HELP_AND_SERVICE.TITLE' | transloco }}</h3>
                  <p>{{'HELP_AND_SERVICE.SUB_TITLE' | transloco }}?</p>
                  <p class="extra-comment">
                    {{'HELP_AND_SERVICE.DESCRIPTION' | transloco }}
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="header-bottom-new">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-auto pl-0">
          <a routerLink="home" class="brand-logo-new">
            <img [src]="logoSrc" alt="" />
          </a>
        </div>
        <div class="col" >
          <div class="top-nav">
            <ul *ngIf="!hideCatalog">
              <app-nav-menu *ngIf="catalogList?.length || hasTradeInPermission" [showTradeIn]="hasTradeInPermission"
                [navItems]="catalogList"></app-nav-menu>
            </ul>
          </div>
        </div>
        <div class="col-auto" *ngIf="isActive">
          <ng-template #content let-modal>
            <div class="modal-header">
              <h4 class="modal-title font-matric-bold">Cisco {{'QUOTES.QUOTES' | transloco}}</h4>
            </div>
            <div class="modal-body">
              <div class="form-group kpn-form-group">
                <label for="">{{'HEADER.PASSWORD' | transloco}}</label>
                <input type="password" placeholder="{{'HEADER.PASSWORD' | transloco}}"
                  class="form-control kpn-form-control" [(ngModel)]="passwordOfMakeQutation" />
              </div>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-new btn-dark rounded-btn px-4" (click)="
                  modal.close('Close click'); passwordOfMakeQutation = null
                ">
                {{'HEADER.CANCEL' | transloco}}
              </button>
              <button type="button" class="btn btn-new btn-primary-dark rounded-btn px-4" (click)="setPassword()">
                {{'HEADER.SEND' | transloco}}
              </button>
            </div>
          </ng-template>
          <!-- <button class="btn btn-outline-primary mb-2 me-2">Small modal</button> -->
          <div class="d-flex align-items-center">
            <div class="new-user-setting lang-selection  mr-3" ngbDropdown placement="bottom-right">
              <button ngbDropdownToggle id="language-setting-dropwon" class="new-user-toggler">
                <img *ngIf="translocoService.getActiveLang() === 'nl'" class="lang-flag"
                  src="../../../assets/images/nl.png" alt="NL">
                <img *ngIf="translocoService.getActiveLang() === 'en'" class="lang-flag"
                  src="../../../assets/images/uk.png" alt="EN">
                {{translocoService.getActiveLang() | uppercase}}
              </button>
              <div class="app-dropdown" ngbDropdownMenu aria-labelledby="language-setting-dropwon">
                <button ngbDropdownItem (click)="changeLanguage('en')"><img class="lang-flag"
                    src="../../../assets/images/uk.png" alt="EN"> EN</button>
                <button ngbDropdownItem (click)="changeLanguage('nl')"><img class="lang-flag"
                    src="../../../assets/images/nl.png" alt="NL"> NL</button>
              </div>
            </div>
            <div class="new-user-setting" ngbDropdown placement="bottom-right">
              <button ngbDropdownToggle id="userdropdown-new" class="new-user-toggler">
                <i class="kphicon-uniE903 green-text">
                  <em class="notification-bullet"></em>
                </i>
                {{ userName }}
              </button>
              <div class="app-dropdown" ngbDropdownMenu aria-labelledby="userdropdown-new">
                <button ngbDropdownItem (click)="resetPagination('my-account')" routerLink="my-account">
                  {{'PROFILE_DROPDOWN.ACCOUNT_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="
                    !is_external ||
                    (is_external && chooseCustomerPermission.view)
                  " (click)="goToExistingCustomer()" title="Klant kiezen">
                  {{'PROFILE_DROPDOWN.CHOOSE_CUSTOMER_LINK_TEXT' | transloco }}
                </button>
                <button *ngIf="
                    hasDepotViewPermission &&
                    (is_external || (!is_external && customerName))
                  " ngbDropdownItem (click)="resetPagination('depot-history')" title="Klant kiezen"
                  routerLink="depot-history">
                  {{'PROFILE_DROPDOWN.DEPOT_HISTORY_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="hasCartImportPermission" (click)="resetPagination('import-order')"
                  routerLink="import-order">
                  {{'PROFILE_DROPDOWN.IMPORT_ORDER_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="hasOrderStatusPermission" (click)="gotoOrders()"
                  routerLink="order-status">
                  {{'PROFILE_DROPDOWN.ORDERS_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="
                    hasRmaPermission.view && siteProfile?.allow_rma_handling
                  " (click)="resetPagination('rma')" routerLink="rma">
                  {{'PROFILE_DROPDOWN.RETURN_ORDERS_LINK_TEXT' | transloco }}
                </button>
                <span *ngIf="
                    hasRmaPermission.add &&
                    siteProfile?.allow_rma_handling &&
                    rmaTypes?.length
                  ">
                  <button *ngFor="let item of rmaTypes" ngbDropdownItem (click)="onChangeRmaTypes(item)">
                    {{ item?.name }}
                  </button>
                </span>
                <button ngbDropdownItem *ngIf="hasQuotePermission" (click)="resetPagination('quotes')"
                  routerLink="quotes">
                  {{'PROFILE_DROPDOWN.OFFERED_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="hasVoucherPermission" (click)="resetPagination('voucher-list')"
                  title="Voucherlijst" routerLink="voucher-list">
                  {{'PROFILE_DROPDOWN.VOUCHER_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="hasReportPermission" (click)="resetPagination('reports')"
                  title="Rapporten" routerLink="reports">
                  {{'PROFILE_DROPDOWN.REPORT_LINK_TEXT' | transloco }}
                </button>
                <!-- <button ngbDropdownItem *ngIf="priceListPermission" (click)="resetPagination('export-file')"
                  routerLink="export-file">
                  {{'PROFILE_DROPDOWN.EXPORT_PRICE_LIST_LINK_TEXT' | transloco }}
                </button> -->
                <button ngbDropdownItem *ngIf="priceListPermission" (click)="resetPagination('export-all-catalogs')"
                  routerLink="export-all-catalogs">
                  {{'PROFILE_DROPDOWN.EXPORT_PRICE_LIST_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="hasProductExportPermission" (click)="resetPagination('export-product')"
                  routerLink="export-product">
                  {{'PROFILE_DROPDOWN.EXPORT_PRODUCTS_LINK_TEXT' | transloco }}
                </button>

                <button ngbDropdownItem [disabled]="!isCiscoSystem" (click)="openSm(content)" *ngIf="!is_external">
                  {{'PROFILE_DROPDOWN.CISCO_OFFERS_LINK_TEXT' | transloco }}
                </button>
                <button ngbDropdownItem *ngIf="forecastPermission.view" (click)="navigateToPage('forecast')"
                  title="Forecast" routerLink="customers">
                  {{'PROFILE_DROPDOWN.FORECAST_LINK_TEXT' | transloco }}
                </button>

                <button ngbDropdownItem *ngIf="accountTypePermission.view"
                  (click)="navigateToPage('contacts/account-types')" title="Enrollment"
                  routerLink="contacts/account-types">
                  Enrollment
                </button>

                <button *ngIf="wishlistPermission.view" ngbDropdownItem class="icon-btn-dropdown"
                  (click)="navigateToPage('favorite-products')" title="Wishlist" routerLink="favorite-products">
                  {{'WISH_LIST.WISHLIST' | transloco}}
                </button>
                <button *ngIf="hasTradeInPermission" ngbDropdownItem class="icon-btn-dropdown"
                  (click)="navigateToPage('trade-in/orders')" title="TradeIn Orders" routerLink="favorite-products">
                  TradeIn Orders
                </button>
                <button *ngIf="SMSCampaignPermission.view" ngbDropdownItem class="icon-btn-dropdown"
                  (click)="navigateToPage('sms-campaigntool')" title="SMS Campaign Tool" routerLink="sms-campaigntool">
                  SMS Campaign Tool
                </button>
                <button ngbDropdownItem class="icon-btn-dropdown" (click)="navigateToPage('faq')" title="FAQ"
                  routerLink="faq">
                  FAQ
                </button>
                <hr class="m-0" />
                <button ngbDropdownItem class="icon-btn-dropdown" (click)="onLogOut()">
                  {{'PROFILE_DROPDOWN.LOG_OUT_LINK_TEXT' | transloco }} <i class="kphicon-uniE987"></i>
                </button>
              </div>
            </div>

            <div class="short-cart-overview ml-2">
              <button class="btn circle-icon-btn py-1" (click)="goToCartOverview()">
                <i class="kphicon-uniEd icon-size-md">
                  <img src="../../../assets/images/cart.svg" alt="">
                </i>
                <span class="cart-item-amount" *ngIf="cart?.lines?.length">{{
                  cart?.lines?.length
                  }}</span>
                <span class="cart-item-amount" *ngIf="cart && !cart?.lines?.length">0</span>
              </button>

              <div class="cart-lines-dropdown">
                <p *ngIf="!cart?.lines?.length" class="sub-title-light-5 dark-grey-text text-center px-3 py-3">
                  {{'NAV_BAR_SHOPPING_CART.EMPTY_SHOPPING_CART' | transloco }}
                </p>
                <div *ngIf="cart?.lines?.length">
                  <div class="cart-item-wrap">
                    <ul class="cart-item">
                      <li *ngFor="let line of cart?.lines; let i = index">
                        <div class="main-line default-line">
                          <div class="line-name">
                            <span class="short-cart-img"  *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                              <img *ngIf="line?.image" src="{{ line?.image }}"
                                alt="{{ line?.product_name }}" />
                              <img *ngIf="
                                  !line?.image && !line.brand?.includes('Cisco')
                                " src="/assets/images/noimage.png" alt="{{ line?.product_name }}" />
                              <img *ngIf="
                                  !line?.image &&
                                  (line.brand?.includes('cisco') ||
                                    line.brand?.includes('Cisco'))
                                " src="/assets/images/cisco-networking.png" alt="" />
                            </span>
                            <p>
                              {{ line?.product_name }}
                              <i class="line-amount ml-1 green-text" *ngIf="!line?.voucher_code">({{ line?.quantity
                                }})</i>
                              <label class="subline-item" *ngFor="let subline of line?.sub_lines">
                                {{ subline?.product_name }}
                              </label>
                            </p>
                          </div>
                          <span class="line-price pt-1" *ngIf="!line?.voucher_code">
                            {{(utilityService.isInclVAT()? line?.total_amount: line?.total_amount_ex_vat) | currencyFormat}}
                          </span>
                          <span class="line-price pt-1" *ngIf="!line?.voucher_code">
                            {{(utilityService.isInclVAT() ? line?.amount :  line?.amount_ex_vat) | currencyFormat  }}
                          </span>
                          <span class="line-price pt-1" *ngIf="line?.voucher_code">
                            {{ (line?.total_amount | currencyFormat) }}
                          </span>
                        </div>
                        <div class="main-line main-line-bwt default-line" *ngIf="isBusiness">
                          <p>B.T.W</p>
                          <span class="line-price">{{
                            line?.total_amount_vat | currencyFormat
                            }}</span>
                        </div>
                      </li>
                    </ul>
                  </div>

                  <div class="cart-overview-action text-right">
                    <div class="shopping-cart-total text-right">
                      <p class="mb-0">
                        {{'NAV_BAR_SHOPPING_CART.TOTAL_ONE_TIME' | transloco }} :
                        <strong>{{ cart?.total_amount | currencyFormat }}
                        </strong>
                      </p>
                    </div>
                  </div>
                  <div class="row align-items-center p-3 mb-2" *ngIf="
                      hasCreditShowPermission && cart?.credit_limit_details
                    ">
                    <div class="col-md-12 col-12 col-gap-reduce">
                      <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                        <p>
                          <span>
                            <small>
                              {{'NAV_BAR_SHOPPING_CART.AVAILABLE_CREDIT' | transloco }}:
                              {{
                              cart?.credit_limit_details?.available_credit
                              | currencyFormat
                              }}</small> </span><br />
                          <span>
                            <small>
                              {{'NAV_BAR_SHOPPING_CART.CREDIT_LIMIT' | transloco }}:
                              {{
                              cart?.credit_limit_details?.credit_limit
                              | currencyFormat
                              }}</small>
                          </span>
                          <br />
                          <span class="kphicon-uniE952"></span>
                          <b> {{ cart?.credit_limit_details?.message }}</b>
                        </p>
                        <button class="kphicon-uniE926"></button>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="cart" class="product-subline-details text-right mr-2">
                    <a class="cart-delete" href="javascript:void(0)" (click)="deleteCart()">
                      <i class="ui-delete kpn-ui-icon icon-size-md"></i>
                      {{'NAV_BAR_SHOPPING_CART.REMOVE_CART' | transloco }}
                    </a>
                    <a class="cart-view ml-2" href="javascript:void(0)" (click)="goToCartOverview()">
                      <i class="ui-shoppingbasket kpn-ui-icon icon-size-md"></i>
                      {{'NAV_BAR_SHOPPING_CART.TO_SHOPPING_CART' | transloco }}
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <button  class="kpn-ui-icon ui-search shopping-cart-new green-text search-toggler-new ml-0"
              (click)="togglerSearch()"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="search-section-new"  [ngClass]="{ 'show-search-section': searchToggler }">
    <div class="container">
      <div class="search-box-top-new">
        <button (click)="onSearchProduct()" [disabled]="!searchValue?.trim()?.length"
          class="searc-btn-new kpn-ui-icon ui-search"></button>
        <input type="text" class="form-control" [(ngModel)]="searchValue"
          [placeholder]="'NAV_BAR_SEARCH_PLACEHOLDER' | transloco" name="" id="" (keyup.enter)="onSearchProduct()" />
      </div>
    </div>
  </div>

  <div class="added-to-cart-pannel" [ngClass]="{ 'show-cart-pannel': cartToggler }">
    <div class="cart-pannel-overlay" (click)="toggleCartPannel()"></div>
    <div class="added-cart-wrap">
      <div class="d-flex justify-content-between mb-4">
        <h4 class="green-text tri-title-32 font-kpn-black pr-3">
          Toegevoegd aan jouw winkelmand
        </h4>
        <button class="close-cart-pannel" (click)="toggleCartPannel()">
          <span class="kphicon-uniE926"></span>
        </button>
      </div>
      <div class="cart-product">
        <!-- <div class="d-flex align-items-center cart-line-added mb-3" *ngFor="let item of selectedProduct">
          <img *ngIf="item?.image" [src]="item?.image || 'src/assets/images/noimage.png'" />
          <p class="m-0">
            {{ item?.quantity }} x {{ item?.name }}
            <span *ngIf="item?.color || item?.memory_size">{{ item?.color }} - {{ item?.memory_size }}</span>
          </p>
        </div> -->

        <div class="row align-items-center shopping-cart-line" *ngFor="let item of selectedProduct">
          <div class="col-auto">
            <div class="cart-img">
              <img *ngIf="item?.image" [src]="item?.image || 'src/assets/images/noimage.png'" />
            </div>
          </div>
          <div class="col pl-0"  >
            <h4 class="font-kpn-extended fs-17 kpn-extended-bold letter-spacing-normal line-height-24">{{ item?.name }}</h4>
            <h4 class="green-text fs-24 font-kpn-black mb-0">
              <span class="line-price font-kpn-extended kpn-extended-black pt-1">
                {{(utilityService.isInclVAT() ?  item?.amount : item?.amount_ex_vat)  | currencyFormat }}
              </span>
            </h4>
          </div>
        </div>
        <!-- <div class="row align-items-center shopping-cart-line" *ngFor="let item of cart?.lines">
          <div class="col-auto" *ngIf="item?.product_type !== 'additional_cost'">
            <div class="cart-img">
              <img *ngIf="item?.image" [src]="item?.image || 'src/assets/images/noimage.png'" />
            </div>
          </div>
          <div class="col pl-0"  *ngIf="item?.product_type !== 'additional_cost'">
            <h4 class="font-kpn-extended fs-17 kpn-extended-bold letter-spacing-normal line-height-24">{{ item?.product_name }}</h4>
            <h4 class="green-text fs-24 font-kpn-black mb-0">
              <span class="line-price font-kpn-extended kpn-extended-black pt-1">
                {{
                !item?.voucher_code
                ? (item?.amount_ex_vat | currencyFormat)
                : (item?.total_amount | currencyFormat)
                }}
              </span>
            </h4>
          </div>
        </div> -->

      </div>
      <!-- <div class="cart-product">
        <div class="cart-line-added mb-3">
          <p class="mb-2">1 x Aflsuiter 34 mm enkel</p>
          <p class="mb-3">1 x Moffel groot</p>
        </div>
      </div> -->

      <div class="pt-3">
        <button class="btn btn-block btn-primary rounded-btn px-2 mb-2" (click)="jumpToCart()">
          <img class="mr-1" src="../../../assets/images/cart-white.svg" alt="">
          Verder naar bestellen
        </button>
        <button class="btn btn-block btn-outline-primary border-2 mt-2 rounded-btn" (click)="toggleCartPannel()">
          Verder winkelen</button>
      </div>
    </div>
  </div>
</div>
