import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'priceFilter'
})
export class PriceFilterPipe implements PipeTransform {
  transform(value: any, str: string) {
        if (value.length) {
            const today = new Date();
            const dateType = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

            // tslint:disable-next-line:max-line-length
            const prices = value.filter(price => price.price_type === 'Sales Price' && price.valid_from <= dateType);

            if (prices.length) {
                if (str === 'price1') {
                    return prices[0]?.price.toString().split('.')[0];
                }

                if (str === 'price2') {
                    return prices[0]?.price.toString().split('.')[1];
                }
            }
            return '';
        }
  }
}
