<section class="bg-kpn-subtle-grey">
  <section class="pb-5">
    <!-- Old Design Start -->
    <!-- <div class="container">
      <app-steps *ngIf="cart" [activeStep]='activeStep'></app-steps>
      <div class="bg-kpn-light cp-4 box-shadow-sm">
        <h4 class="title-3 mb-0 green-text">Bestaande klant opzoeken</h4>
        <p class="body-text-18 mb-4 pb-3 grey-text">
          Voer postcode en huisnummer van de klant in
        </p>
        <div class="col-md-6 m-0 p-0">
          <label for="" class="form-label-bold mid-grey-text mb-1">Zoek op bedrijfsnaam</label>
          <div class="kpn-form-group default-search-field select-list-inquote">
            <div class="ng-autocomplete">
              <ng-autocomplete class="" [data]="searchCompanyList" placeHolder='' [(ngModel)]='selectedCompanyName'
                [searchKeyword]="keyword" (selected)='onSelectedCompany($event)'
                (inputChanged)='searchCompanies($event)' (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate" (inputCleared)="searchCleared()" [debounceTime]="1000"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.company_name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div>
        <form [formGroup]="searchForm" (ngSubmit)="searchByParams()" class="in-active-form mt-4">
          <div class="row gutter-row-15 align-items-end mb-3">
            <div class="col-md-2 gutter-col-15">
              <label for="" class="form-label-bold green-text mb-1">Postcode</label>
              <div class="form-group mb-0 kpn-form-group">
                <input type="text" oninput="this.value = this.value.toUpperCase()" formControlName="zipcode"
                  class="form-control kpn-form-control" placeholder="Bijv, 1234AB" />
              </div>
            </div>
            <div class="col-md-2 gutter-col-15">
              <label for="" class="form-label-bold green-text mb-1">Huisnummer</label>
              <div class="form-group mb-0 kpn-form-group">
                <input type="text" formControlName="house_number" class="form-control kpn-form-control"
                  placeholder="Bijv. 36" />
              </div>
            </div>
            <div class="col-md-auto gutter-col-15">
              <label for="" class="form-label-bold green-text mb-1">Toevoeging?</label>
              <div class="form-group mb-0 kpn-form-group">
                <input type="text" formControlName="house_number_ext"
                  class="form-control form-control-sm-width-web kpn-form-control" placeholder="Bijv. A" />
              </div>
            </div>
            <div class="col-md-2 gutter-col-15">
              <label for="" class="form-label-bold green-text mb-1">KVK Nummer</label>
              <div class="form-group mb-0 kpn-form-group">
                <input type="text" formControlName="coc_no" class="form-control kpn-form-control"
                  placeholder="Bijv. A" />
              </div>
            </div>
            <div class="col-md-2 gutter-col-15">
              <label for="" class="form-label-bold green-text mb-1">KRN Nummer</label>
              <div class="form-group mb-0 kpn-form-group">
                <input type="text" formControlName="krn_no" class="form-control kpn-form-control"
                  placeholder="Bijv. A" />
              </div>
            </div>
            <div class="col gutter-col-15 text-right">
              <button type="submit" class="btn px-5 btn-primary mb-0 rounded-btn">
                Zoeken
              </button>
            </div>
          </div>
        </form>

        <div class="section-loader" *ngIf="isLoading">
          <div class="kpn-spinner">
            <img src="../../../assets/images/kpn-loader.gif" alt="Loading" />
          </div>
        </div>

        <div *ngIf="customers && !isLoading" class="table-responsive custom-table">
          <table class="table">
            <thead>
              <tr>
                <th>Bedrijfsnaam</th>
                <th>KvK nummer</th>
                <th>Krn nummer</th>
                <th>Initialen</th>
                <th>tussenvoegsel</th>
                <th>Achternaam</th>
                <th>Postcode</th>
                <th>Stad</th>
                <th>Selecteer</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let customer of customers">
                <td>{{ customer.company_name }}</td>
                <td>{{ customer.coc_number }}</td>
                <td>{{ customer.external_id1 }}</td>
                <td>{{ customer.contact_persons[0]?.initials }}</td>
                <td>{{ customer.contact_persons[0]?.surname_prefix }}</td>
                <td>{{ customer.contact_persons[0]?.last_name }}</td>
                <td>{{ customer.contact_addresses[0]?.zip_code }}</td>
                <td>{{ customer.contact_addresses[0]?.city }}</td>
                <td>
                  <button (click)="onCustomerSelect(customer)" type="button"
                    class="btn btn-sm btn-block btn-primary rounded-btn" i18n="@@arm.cuma.select">
                    Kies
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div> -->
    <!-- Old Design End -->
    <br>

    <!-- New Design Start -->

    <div class="container">
      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4 pb-3">
        <li class="back-breadcrumb-btn border-0">
          <a href="javascript:void(0)">
            <i class="kphicon-uniE906"></i> Terug
          </a>
        </li>
        <li class="home-btn ml-2 px-1 pb-1">
          <a routerLink="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                  fill="#939393" />
              </g>
            </svg>
          </a>
        </li>
        <li><a routerLink="/">Alle apparaten</a></li>
      </ul>

      <app-steps *ngIf="cart" [activeStep]='activeStep' [completeStepOne]="'true'"></app-steps>

      <div class="facts-box">
        <h2 class="font-kpn-black tri-title-40 green-text mb-3 mt-n3">Verzend gegevens</h2>
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-32 mb-4">
          <h3 class="green-text tri-title-32 font-kpn-black mb-3">
            <!-- {{'CUSTOMERS.ORGANIZATIONAL_DATA' | transloco }} -->
            KVK Bedrijfsgegevens
          </h3>
          <div class="row mb-5">
            <div class="col-md-6">
              <div class="row gutter-row-24">
                <div class="col gutter-col-24">
                  <div class="kpn-form-group tri-autocomplete-form">
                    <label for="" class="field-label"> {{'CUSTOMERS.COMPANY_NAME' | transloco }} </label>
                    <!-- <input type="text" class="form-control rounded-0"> -->
                    <ng-autocomplete #customerSearch [data]="searchCompanyList"
                      [placeholder]="'CUSTOMERS.SEARCH_BY_COMPANY_NAME_OR_CHAMBER_OF_COMMERCE_NUMBER' | transloco" [(ngModel)]='selectedCompanyName'
                      [searchKeyword]="keyword" (selected)='onSelectedCompany($event)'
                      (inputChanged)='searchCompanies($event)' (inputFocused)='onFocused($event)'
                      [itemTemplate]="itemTemplate" focusFirst="true" historyIdentifier="searchCompanyList"
                      isHistoryListVisible="true" (inputCleared)="searchCleared()" [debounceTime]="800"
                      [notFoundTemplate]="notFoundTemplate">
                    </ng-autocomplete>
  
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.company_name"></a>
                    </ng-template>
  
                    <ng-template #notFoundTemplate let-notFound>
                      <div [innerHTML]="notFound"></div>
                    </ng-template>
                  </div>
                </div>
              </div> 
            </div>
            <div class="col-md-12">
              <form [formGroup]="searchForm" (ngSubmit)="onSearchCustomer()" class="in-active-form mt-4">
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">KVK-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                      <input type="text" formControlName="coc_no" class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">KRN-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                      <input type="text" formControlName="krn_no" class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.POSTCODE' | transloco}}</label>
                      <input type="text" class="form-control tri-form-control" oninput="this.value = this.value.toUpperCase()"
                        formControlName="zipcode" [patterns]="zipCodePattern" mask="111100" placeholder="1234AB">
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">
                        {{'CUSTOMERS.HOUSE_NUMBER' | transloco}}
                      </label>
                      <input type="text" formControlName="house_number" class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">
                        {{'CUSTOMERS.ADDITION' | transloco}}
                      </label>
                      <input type="text" formControlName="house_number_ext" class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
                <!-- <div class="row mb-3 gutter-row-24">
                  <div class="col gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">Straat</label>
                      <input disabled type="text" formControlName="house_number_ext" class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">Plaats</label>
                      <input disabled type="text" formControlName="house_number_ext" class="form-control tri-form-control">
                    </div>
                  </div>
                </div> -->
  
                <!-- <div class="pt-2 mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input type="checkbox">
                      <span class="checkBoxCheckmark"></span>
                    </div>
                    <div class="checkbox-label ml-2">
                      <span>Afleveradres wijkt af van organisatie gegevens </span>
                    </div>
                  </label>
                </div> -->
  
                <div class="pt-2">
                  <button class="btn btn-primary rounded-btn px-4"
                    [disabled]="!searchForm.controls.krn_no?.value  && !searchForm.controls.coc_no?.value && !searchForm.controls.zipcode?.value && !searchForm.controls.house_number?.value">{{'CUSTOMERS.SEARCH' | transloco}}</button>
                </div>
              </form>
            </div>
          </div>
  
  
          <div class="section-loader" *ngIf="isLoading">
            <div class="kpn-spinner">
              <img src="/assets/images/kpn-loader.gif" alt="Loading" />
            </div>
          </div>
  
          <div *ngIf="customers && !isLoading">
            <h3 class="title-3 mb-4">{{customers?.length}}
              {{'CUSTOMERS.RESULTS' | transloco}}
            </h3>
            <div *ngIf="customers?.length" class="row gutter-row-15 mb-3">
              <div class="col-md-5 gutter-col-15 m-1" *ngFor="let customer of customers">
                <div class="customer-box" [ngClass]="{'selected-customer' : selectedCustomer?.uuid === customer?.uuid, 'disabled-div' :
                  !customer?.is_active }" (click)="onSelectCustomer(customer)">
                  <span *ngIf="!customer?.is_active" class="dark-orange-tag-pill">  {{'CUSTOMERS.INACTIVE' | transloco}} </span>
                  <p class="customer-info-line">
                    <label for=""> {{'CUSTOMERS.COMPANY_NAME' | transloco}}</label>
                    <span>{{customer.company_name}}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">KVK-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                    <span>{{ customer.coc_number }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">KRN-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                    <span>{{ customer.external_id1 }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.STREET' | transloco}}</label>
                    <span>{{ customer.firstContactAddress?.street }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.HOUSE_NUMBER' | transloco}} </label>
                    <span>{{ customer.firstContactAddress?.house_number }}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.ADDITION' | transloco}} </label>
                    <span>-</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">Postcode</label>
                    <span>{{customer.firstContactAddress?.zip_code}}</span>
                  </p>
                  <p class="customer-info-line">
                    <label for="">{{'CUSTOMERS.CITY' | transloco}}</label>
                    <span>{{customer.firstContactAddress?.city}}</span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row align-items-center justify-content-between pt-0">
          <div class="col-auto d-flex">
            <button (click)="backToPrevious()" class="btn btn-link d-flex align-items-center px-0">
              <i class="kphicon-uniE906 fs-22"></i>Vorige</button>
          </div>
          <div class="col-auto">
            <button class="btn btn-primary rounded-btn px-4 mb-2 tri-btn-disabled" [disabled]="!selectedCustomer"
              (click)="onCustomerSelect(selectedCustomer)">
              <!-- {{'CUSTOMERS.NEXT_STEP' | transloco}} -->
              Naar samenvatting
            </button>
          </div>
        </div>
      </div>

      
    </div>
    <!-- New Design End -->
  </section>
</section>
