<section class="pb-4 bg-white">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div class="container">
    <!-- Breadcrumb -->
 <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-3 pb-1" >
  <li class="back-breadcrumb-btn">
    <a class="cursor-pointer" (click)="backToPreviousPage()" >
      <i class="kphicon-uniE906"></i> Terug
    </a>
  </li>
  <li class="home-btn ml-2 px-1 pb-1">
    <a routerLink="/">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="UI Icon">
          <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
            d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
            fill="#939393" />
        </g>
      </svg>
    </a>
  </li>
  <li  role="button"> <a class="grey-text" >Bestelling controleren</a></li>
  <li> <a> Offerte #{{ quote?.reference }}</a></li>
</ul>

    <div class="pt-2" *ngIf="eQuoteStatus.APPROVED === quote?.status">
      <app-customer-quote-steps [showConfigTab]="quote?.is_contact_account_type_required || hasCpaas"   [activeStep]="4" [completedSteps]="[1,2,3]" />
    </div>

    <div class="row">
      <div class="col-md-12">
        <h3 class="font-kpn-black green-text fs-40 mb-3 pt-3">Bestelling controleren</h3>
      </div>
    </div>

    <div class="row mb-3">
      <div class="col-md pr-md-0">

        <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 c-px-24 mb-4" *ngIf=" quote !== null">
          <h4
            class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">
            Levering & contactpersoon </h4>

          <div class="row gutter-row-24" >
            <div class="col-md-6 mb-4 gutter-col-24">
              <app-customer-quote-address/>
            </div>
            <div class="col-md-6 mb-4 gutter-col-24">
              <app-customer-quote-contact-person/>

            </div>
          </div>
        </div>
        <div >
          <app-customer-quote-po-and-reference  (updatePoAndReferenceEventEmitter)="updatePoAndReferenceDataFromChild($event)" />
        </div>

        <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3  mb-4" *ngIf="hasCpaas && isCpassDataAdded">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1 c-px-24">
            CPaaS hoofdgebruiker  </h4>

          <div class="row gutter-row-24">
            <div class="col-md-12 mb-4 gutter-col-24" >
              <app-customer-quote-cpass [showEditButtonInBottom]="true"  (updateCpassDataEventEmitter)="updateCpassDataFromChild($event)"  />
            </div>
          </div>
        </div>




        <div *ngIf="quote?.is_contact_account_type_required && (hasViewContactAccountTypeCustomer ? (contactAccountTypesCustomers?.length) : true)">
          <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 mb-4" >
            <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1 c-px-24">
              Enrollment overzicht
            </h4>
            <div>
              <app-customer-quote-enrollment [sowAddRemoveEnrollmentBtn]="false" [onlyShowEnrolledItem]="true"  [showEditBtn]="true" />
            </div>
          </div>
        </div>
        <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 pb-2 c-px-24 mb-4"  *ngIf="paymentOptions.length">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-1 pt-1">Betaalmethode </h4>
          <div class="address-lines pt-3">
            <div class="quote-address-item quote-payment-option-item" *ngFor="let payment of paymentOptions;let i = index">
              <input type="radio" name="payment-option" [id]="i+'payment-type'"  [value]="payment.code" [disabled]="((payment?.future_amount < 0) &&  ( payment?.code === 'depot'))" [(ngModel)]="selectedPaymentOption"  (change)="changePaymentType(payment)">
              <label [for]="i+'payment-type'" class="d-flex font-kpn-extended" [ngClass]="{'payment-disabled-bg' : ((payment?.future_amount < 0) &&  ( payment?.code === 'depot')) }" >
                <span class="radio-bullet"></span>
                <div class="flex-grow-1 ">
                  <span class="block" [ngClass]="{'grey-text' : ((payment?.future_amount < 0) &&  ( payment?.code === 'depot')) }">{{ payment?.name }}</span>
                  <span class="d-block grey-text" *ngIf="(payment?.future_amount < 0) &&  ( payment?.code === 'depot')">Er is geen beschikbaar saldo in het depot.</span>
                  <div class="pt-3" *ngIf="((payment?.current_amount) || (payment?.future_amount)) && (payment?.code === 'depot') && (payment?.future_amount > 0)">
                    <div class="">
                      <p class="font-kpn-extended m-0 line-height-24 d-flex justify-content-between">
                        <span class="d-block font-kpn-extended grey-text">Huidig saldo in depot </span>
                        <span class="d-block font-kpn-extended grey-text">{{ payment?.current_amount | currencyFormat }}</span>
                      </p>
                      <hr class="my-2 nr-border-color">
                      <p class="font-kpn-extended m-0 line-height-24 d-flex justify-content-between">
                        <span class="d-block font-kpn-extended grey-text">Saldo na betaling</span>
                        <span class="d-block font-kpn-extended grey-text">{{ payment?.current_amount | currencyFormat }}</span>
                      </p>
                    </div>
                  </div>
                </div>

              </label>

            </div>

          </div>
        </div>
        <!-- <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4" *ngIf="paymentOptions.length">
          <h3 class="tri-title-40 font-kpn-black green-text mb-3 pb-2">Betaalmethode</h3>
          <div class="payment-option mb-2" *ngFor="let payment of paymentOptions;let i = index">
            <div class="d-flex align-items-center payment-option-radio">
              <input type="radio" name="payment-option" [id]="i+'payment-type'" [value]="payment.code" [(ngModel)]="selectedPaymentOption">
              <label [for]="i+'payment-type'" class="d-flex align-items-center font-kpn-extended fs-20">
                <span class="radio-bullet"></span>
                {{ payment?.name }} </label>
              <span class="payment-logo" *ngIf="payment?.code === 'ideal_talos'">
                <img src="../../../assets/images/ideal-payment-icon.png" alt="iDeal">
              </span>
            </div>

            <div class="pt-3" *ngIf="((payment?.current_amount) || (payment?.future_amount)) && (selectedPaymentOption === payment?.code)">
              <div class="blue-warning-qoute d-flex">
                <i class="kphicon-uniE952 note-icon mr-3"></i>
                <p class="font-kpn-extended m-0 line-height-24">
                  <span class="d-block font-kpn-bold fs-20">Betaal uit depot</span>
                  <span class="d-block font-kpn-extended">Beschikbare bedrag in depot: {{ payment?.current_amount |
                    currencyFormat }}</span>
                  <span class="d-block font-kpn-extended">Over na betaling: {{ payment?.future_amount | currencyFormat
                    }}</span>
                </p>
              </div>
            </div>
          </div>
        </div> -->
        <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24 mb-4" *ngIf="termsAndConditions?.length">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-1 pt-1">
            De voorwaarden</h4>
          <div class="pt-3">
            <div *ngFor="let item of termsAndConditions"
              class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
              name="multiselect-items">
              <label ngbButtonLabel class="checkbox-item checkbox-item-tri d-inline-flex mb-1">
                <div class="checkbox-btn checkbox-btn-14 checkbox-btn-outline pt-1">
                  <input ngbButton type="checkbox" class="position-absolute opacity-0" [value]="item.code"
                    [(ngModel)]="item.checked" (ngModelChange)="onChangeTermAndCondition(item)">
                  <span class="checkBoxCheckmark checkBoxCheckmark-14"></span>
                </div>
                <div class="checkbox-label font-kpn-extended ml-2 pl-1 w-100" [innerHTML]="item?.description"></div>
              </label>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <p class="">
            <button class="fs-16 pl-0 font-kpn-extended link-btn-blue" (click)="backToPreviousPage()">
              <i class="kpnew-icon-arrow-lingt-2 fs-18 align-middle"></i> Terug naar gegevens</button>
          </p>
          <button class="btn btn-primary rounded-btn px-4 " (click)="placeOrder()" >Bestelling plaatsen</button>
        </div>
      </div>
      <div class="col-md-auto">
        <div
          class="tri-shadow-xl quote-details-cart-overview quote-days-left tri-rounded-20 bg-white py-3 c-px-20 mb-4">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pb-2 pt-2">
            Overzicht</h4>
          <div class="bg-gray-1 py-2 px-3 tri-rounded-16 mb-4">
            <app-customer-quote-side-bar-price-summary/>
          </div>

          <div class="pb-2">
            <button class="btn btn-outline-primary btn-block border-2 px-4 mr-md-3 rounded-btn mt-3"
              (click)="openQuoteDetailsSummeryModal(quoteDetailsSummery)">Offertedetails bekijken</button>
            <button class="btn btn-primary btn-block rounded-btn px-4 mt-2" (click)="placeOrder()">Bestelling plaatsen</button>
          </div>

        </div>
      </div>

    </div>



  </div>



  <ng-template #quoteDetailsSummery let-modal>
    <div class="px-4 pt-4">
      <div class="modal-header tri-modal-header p-0 pb-4 border-0">
        <h3 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
          Offertedetails
        </h3>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <app-quote-details-summary [showRejectButton]="false" [showPlaceOrderButton]="false"
        [showHeader]="false" [bottomZero]="true"></app-quote-details-summary>
    </div>
  </ng-template>

  <ng-template #creditLimitModal let-modal>
    <div class="px-4">
        <div class="px-1 modal-header tri-modal-header">
          <h6 class="modal-title green-text font-kpn-black fs-40" id="creditLimitModal">
            Kredietlimiet bereikt
          </h6>
        </div>
    </div>

    <div class="modal-body p-0">
      <div class="pl-4 pt-4">
        <div class="custom-inner-modal-scroll pr-4">
          <div class="error-qoute d-flex">
            <i class="kphicon-uniE952 note-icon mt-1 mr-3 "></i>
            <div>
              <label class="font-kpn-extended kpn-extended-bold mb-0 fs-20 letter-spacing-normal" for="">Je betaling is mislukt</label>
              <p class="font-kpn-extended m-0">
                Je hebt je kredietlimiet bereikt en kunt geen verdere bestellingen op rekening plaatsen. Neem contact op met onze klantenservice om je limiet te verhogen.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <a [routerLink]="['/quotes']"  class="btn btn-block btn-outline-primary border-2 rounded-btn px-4">
                <span>Ga terug naar offerte overzicht</span>
              </a>
            </div>
            <div class="col-md-auto gutter-col-8">
              <a [href]="helpLink"  (click)="modal.dismiss('Cross click')" class="btn btn-block btn-primary rounded-btn px-4">
                <span>Contact opnemen</span>
              </a>
            </div>
          </div>
      </div>
    </div>
  </ng-template>



</section>
