import { Component, Input } from '@angular/core';
import { QuoteDetailsService } from '../../../quote-details.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { AccountTypeService } from 'src/app/pages/account-type/account-type.service';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';

enum OperationType {
  Add = 'Add',
  Remove = 'Remove'
};

@Component({
  selector: 'app-customer-quote-enrollment',
  templateUrl: './customer-quote-enrollment.component.html',
  styleUrls: ['./customer-quote-enrollment.component.scss']
})
export class CustomerQuoteEnrollmentComponent extends BasePermission {
  isLoading = false;
  quote = null;
  contactAccountTypesCustomers = [];
  selectedContactAccountTypesCustomer = null;
  accountTypes = [];
  currentCustomerDetailsQuoteSubscription$ :Subscription;
  @Input() sowAddRemoveEnrollmentBtn = false;
  @Input() showEditBtn = false;
  @Input() onlyShowEnrolledItem = false;
  @Input() colSizeFour = false;
  constructor(
    public store: Store<IAppState>,
    private quoteDetailsService: QuoteDetailsService,
    private accountTypeService: AccountTypeService,
    private toastrService: ToastrService,
    private modalService: NgbModal
  ) {
    super(store);

  }

  ngOnInit() {
    this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe(quote => {
      this.quote = quote;
      if (this.quote?.is_contact_account_type_required) {
        if (this.hasViewContactAccountTypeCustomer) {
          this.getContactAccountTypesCustomers();
        } else {
          if (this.quote?.contact_account_types?.length) {
            this.getPreviouslySelectedAccountTypes();
          } else {
            this.getAccountTypes();
          }
        }
      }
    });
  }


  getContactAccountTypesCustomers() {
    this.accountTypeService.getContactAccountTypesCustomers().subscribe(res => {
      this.contactAccountTypesCustomers = res?.data?.results ?? [];
      if (this.quote?.contact_account_types?.length) {
        const existingFirstAccountType = this.quote?.contact_account_types[0];
        for (let i = 0; i < this.contactAccountTypesCustomers.length; i++) {
          const client = this.contactAccountTypesCustomers[i];
          if (client?.account_types?.find(at => at.id === existingFirstAccountType)) {
            this.selectedContactAccountTypesCustomer = client?.uuid;
            // this.accountTypes = client?.account_types?.map(item => ({ ...item, checked: true }));
            // this.formatAccountTypeData(this.quote);
            break;
          }
        }
        if (this.selectedContactAccountTypesCustomer) {
          let paramsString = `page=1&contact_account_type_customer_uuid=${this.selectedContactAccountTypesCustomer}`;
          this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
            this.accountTypes = res?.data?.results?.map(item => ({ ...item, checked: true }));
            this.formatAccountTypeData(this.quote);
            this.isLoading = false;
          }, error => {
            this.isLoading = false;
          })

        }
      }
    }, error => {
    })
  }
  getAccountTypes(contactAccountTypeCustomerUUID = null) {
    let paramsString = `page=1`;
    if (contactAccountTypeCustomerUUID) {
      paramsString = `page=1&contact_account_type_customer_uuid=${contactAccountTypeCustomerUUID}`;
    }
    this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      this.accountTypes = res?.data?.results?.map(item => ({ ...item, checked: true }));
      this.formatAccountTypeData(this.quote);
      this.isLoading = false;
      let accountTypeIds = this.accountTypes.map(item => item.id);
      if (this.hasViewContactAccountTypeCustomer && this.quote?.is_contact_account_type_required) {
        this.updateAccountTypeInQuote(accountTypeIds);

        // if (!this.arraysHaveSameElements(accountTypeIds, this.quote?.contact_account_types)) {
        //   this.updateAccountTypeInQuote(accountTypeIds);
        // }
      } else if (!this.hasViewContactAccountTypeCustomer && this.quote?.is_contact_account_type_required) {
        this.updateAccountTypeInQuote(accountTypeIds);
      }

    }, error => {
      this.isLoading = false;
    })
  }
  getPreviouslySelectedAccountTypes() {
    let paramsString = `page=1`;
    this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      this.accountTypes = res?.data?.results?.map(item => ({ ...item, checked: true }));
      this.formatAccountTypeData(this.quote);
    }, error => {
    })
  }
  arraysHaveSameElements(arr1 = [], arr2 = []) {
    return arr1.length === arr2.length && arr1.sort().every((value, index) => value === arr2.sort()[index]);
  }
  onContactAccountTypesCustomerChange(event) {
    if (event) {
    this.isLoading = true;
      this.getAccountTypes(event);
    } else {
      this.accountTypes = [];
      this.updateAccountTypeInQuote([]);
    }
  }

  updateAccountTypeInQuote(ids = []) {
    this.isLoading = true;
    this.accountTypeService.updateQuoteAccountTypes(this.quote?.uuid, { contact_account_types: ids }).subscribe(res => {
      this.formatAccountTypeData(res?.data);
      this.toastrService.success(``, 'Success', {
        timeOut: 2000
      });
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.toastrService.error(error?.error?.message || error?.error?.error);
    })
  }

  addOrRemoveAccountTypeToQuote(obj, type) {
    let ids = [];
    if (type == OperationType.Add) {

      ids = this.accountTypes.filter(item => item?.checked).map(item => item.id);
      ids.push(obj?.id);

    } else {
      ids = this.accountTypes.filter(item => item?.checked).filter(item => item.id !== obj?.id).map(item => item.id);
    }
    this.updateAccountTypeInQuote(ids);

  }
  formatAccountTypeData(data) {
    this.accountTypes = this.accountTypes.map(item => {
      const isChecked = data?.contact_account_types?.find(contactAccountTypeId => contactAccountTypeId === item?.id);
      return {
        ...item,
        checked: isChecked ? true : false
      };
    })
  }
  openEnrollmentUpdateModal(enrollmentUpdateModal) {
    this.modalService.open(enrollmentUpdateModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-890 modal-runded',
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
  }

  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
  }

}
