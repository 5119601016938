import { HostListener } from '@angular/core';
import { LocationStrategy } from '@angular/common';


import { Router } from '@angular/router';

import * as _ from 'lodash';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProductDetailService } from './product-detail.service';
import { TranslocoService } from '@ngneat/transloco';
import { skip } from 'rxjs/operators';

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.scss']
})
export class ProductDetailsComponent implements OnInit {
  public product: any;
  public productType: string;

  public viewName: string = null;
  public position: string = null;
  public catalogCode = '';
  public loader = false;
  private langChanges$ = null;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private productDetailService: ProductDetailService,
    private location: LocationStrategy,
    public translocoService: TranslocoService) {
    this.route.queryParams.subscribe(p => {
      this.catalogCode = p?.catalogCode;
    });
    this.viewName = this.route.snapshot.queryParams.view || 'grid';
    this.position = this.route.snapshot.queryParams.position || null;

    this.langChanges$=  translocoService.langChanges$.pipe(skip(1)).subscribe((lang) => { 
      console.log('calling product details again due to lang change: ', lang);
      this.getProduct();
    
    });
  }

  ngOnInit() {
    this.getProduct();
  }


  // history.pushState(null, null, window.location.href);

  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    const path = event.currentTarget.location.pathname;
    this.router.navigate([path], { queryParams: { view: this.viewName, position: this.position } });
  }

  getProduct() {
    this.route.params.subscribe(params => {
      this.loader = true;
      this.productDetailService.getProduct(params.product_code, this.catalogCode)
        .subscribe(res => {
          this.product = res?.data;
          this.productDetailService.setSelectedProduct(this.product);
          this.productType = res.data.product_type ? res.data.product_type.code : '';
          this.loader = false;
        }, error => {
          this.productDetailService.setSelectedProduct(null);
          this.loader = false;
        });
    }, err => {
      this.productDetailService.setSelectedProduct(null);
      this.loader = false;
    });
  }
  ngOnDestroy(): void {
    this.langChanges$?.unsubscribe();
  }
}
