import { Component, OnInit } from '@angular/core';
import { NotificationService } from './notification.service';


@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
export class NotificationComponent implements OnInit {
  public notificationType = null;
  public notificationMsg = null;

  constructor(
    private notificationService: NotificationService
  ) { }

  ngOnInit() {
    this.closeNotification();
    this.notificationService.currentNotification$
      .subscribe(res => {
        if (res) {
          this.setNotification(res);
        }
      });
  }

  closeNotification() {
    this.notificationService.setNotification(null, null);
  }

  setNotification(notification) {
    this.notificationType = notification.type ;
    this.notificationMsg = notification.msg ;

    if (notification.type === 'success') {
      setTimeout(() => {
        this.closeNotification();
      }, 5000);
    }
  }
}
