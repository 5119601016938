<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="customer-search kpn-card bg-kpn-light">
        <div class="mb-3">
          <h4 class="title-4 green-text p-2">
            {{'EXPORT_PRODUCT.EXPORT_PRODUCTS' | transloco}}
          </h4>
        </div>
        <div class="row align-items-center p-2">
          <div class="col-md-4 col-6 col-gap-reduce">
            <div class="form-group kpn-form-group">
              <label i18n="@@arm.order-status.ext-num">{{'EXPORT_PRODUCT.CATALOG' | transloco}}</label>
              <select name="catalog" id="catalog" [(ngModel)]="selectedCatalogCode" (ngModelChange)="onChangeOptions()"
                class="form-control" [disabled]="isLoading">
                <option *ngFor="let item of catalogList" [ngValue]="item">{{item?.name}}
                </option>
              </select>
            </div>
          </div>
          <div class="col-md-4 col-6 col-gap-reduce">
            <div class="form-group kpn-form-group">
              <label>{{'EXPORT_PRODUCT.OPTIONS' | transloco}}</label>
              <select name="options" id="options" [(ngModel)]="selectedFilterOption" (ngModelChange)="onChangeOptions()"
                class="form-control" [disabled]="isLoading">
                <option *ngFor="let item of filterOptions" [ngValue]="item">{{item?.name}}
                </option>
              </select>
            </div>
          </div>

          <div class="col-md-4 col-6 col-gap-reduce">
            <div class="form-group kpn-form-group">
              <label>{{'EXPORT_PRODUCT.EXPORT' | transloco}}</label>
              <div class="row">
                <div class="col-md-6 col-6 col-gap-reduce">
                  <button [disabled]='csvLoading || !products?.length' (click)="exportProduct('csv')"
                    class="btn btn-sm btn-block btn-primary mt-2 rounded-btn">
                    <span *ngIf="!csvLoading; else showSpinner">CSV</span>
                    <ng-template #showSpinner>
                      <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                    </ng-template>
                  </button>
                </div>
                <div class="col-md-6 col-6 col-gap-reduce">
                  <button [disabled]='xlsLoading || !products?.length' (click)="exportProduct('xlsx')"
                    class="btn btn-sm btn-block btn-primary mt-2 rounded-btn">
                    <span *ngIf="!xlsLoading; else showSpinner">XLSX</span>
                    <ng-template #showSpinner>
                      <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                    </ng-template>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="p-3" style="height: 15px;">
          <div class="progress" *ngIf="exportPercentage">
            <div class="progress-bar" role="progressbar" [ngStyle]="{'width': exportPercentage + '%'}"
              aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{exportPercentage}}%</div>
          </div>
        </div>
      </div>
      <div class="loader-wrapper box-shadow-sm" [ngClass]="{'spinner-wrapper-height': isLoading}">
        <div class="table-responsive custom-table">
          <h3 class="title-3 green-text ml-4 pt-3" i18n="@@arm.order-status.order-list">Product {{'EXPORT_PRODUCT.LIST' | transloco}}:</h3>
          <div class="kpn-spinner" *ngIf="isLoading;else table_content">
            <div class="text-center">
              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
              <p>{{'EXPORT_PRODUCT.TO_SEARCH' | transloco}}…</p>
            </div>
          </div>
          <ng-template #table_content>
            <div *ngIf="products?.length">
              <table class="table mb-5">
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>{{'EXPORT_PRODUCT.BRAND' | transloco}}</th>
                    <th>EAN</th>
                    <th class="text-right" *ngIf="selectedFilterOption?.code === filterOptions[1]?.code">{{'EXPORT_PRODUCT.OLD_PRICE' | transloco}}</th>
                    <th class="text-right">{{'EXPORT_PRODUCT.CURRENT_PRICE' | transloco}}</th>
                    <th>Toestand</th>
                    <th>Product {{'EXPORT_PRODUCT.VALIDITY' | transloco}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let product of products | paginate: config">
                    <td>
                      <a (click)="goToProductDetails(product)" class="blue-link">{{product.name | slice:0:80}}{{'
                        '}}({{product?.code}})
                      </a>
                    </td>
                    <td>
                      {{product?.brand?.name}}
                    </td>
                    <td>
                      {{product.ean_code}}
                    </td>
                    <td class="text-right">
                      {{product.old_price?.price | currencyFormat}}
                    </td>
                    <td class="text-right">
                      {{product.price?.price | currencyFormat}}
                    </td>
                    <td class="text-center">
                      <samp>
                        <span class="badge badge-info">
                          {{product?.status?.replace('_', " ") | titlecase}}
                        </span>
                      </samp>
                    </td>
                    <td>{{product.valid_from | date: 'dd-MM-yyyy'}} <b> to </b>
                      {{product.valid_until | date: 'dd-MM-yyyy'}}</td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-new">
                <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                  (pageChange)="onPageClick($event)"></pagination-controls>
              </div>
            </div>
            <div class="row g-4 mt-4 p-3" *ngIf="!products?.length">
              <div class="col-md-12">
                <div class="alert alert-info" role="alert">
                  {{'EXPORT_PRODUCT.NO_PRODUCT_IN_CATALOG' | transloco}} <b> {{selectedCatalogCode?.name}}</b>
                  <span *ngIf="selectedFilterOption?.code"> of <b> {{selectedFilterOption?.name}}</b></span>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>
