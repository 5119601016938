import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class TradeInService {

  constructor(private baseApiService: ApiBaseService) { }


  searchTradeInProduct(searchString: string): Observable<any> {
    const params = new HttpParams({
      fromString: searchString
    });
    let url = `search/ti-products/family`
    return this.baseApiService.get(url, params);
  }

  getTradeInProductByCode(product_code: string,brand_code:string): Observable<any> {
    // const params = new HttpParams({
    //   family_uuid: product_code,
    //   brand_code: brand_code
    // });
    const params = new HttpParams({ fromString: `family_uuid=${product_code}&brand_code=${brand_code}` });

    // http://0.0.0.0:8001/api/v1/search/ti-products/?family_uuid=385c2aa6-e407-4bef-9149-c8e039ea5f01&brand_code=oneplus
    let url = `search/ti-products`
    return this.baseApiService.get(url, params);
  }

  tradeInPreCart(payload): Observable<any> {
    let url = `trade-in/pre-cart`
    return this.baseApiService.post(url, payload);
  }

  tradeInCart(payload): Observable<any> {
    let url = `trade-in/cart`
    return this.baseApiService.post(url, payload);
  }

  tradeInCartUpdate(payload, cart_uuid: string): Observable<any> {
    let url = `trade-in/cart/${cart_uuid}`
    return this.baseApiService.put(url, payload);
  }

  addLineTradeInCart(cart_uuid, payload): Observable<any> {
    let url = `trade-in/cart/${cart_uuid}/lines`
    return this.baseApiService.post(url, payload);
  }

  createTradeInOrder(cart_uuid: string): Observable<any> {
    let url = `trade-in/orders`;
    return this.baseApiService.post(url, { cart_uuid });
  }

  getTradeInOrder(order_uuid: string): Observable<any> {
    let url = `trade-in/orders/${order_uuid}`;
    return this.baseApiService.get(url);
  }

  getTradeInOrders(searchString): Observable<any> {
    const params = new HttpParams({
      fromString: searchString
    });
    let url = `trade-in/orders`;
    return this.baseApiService.get(url, params);
  }

  deleteTradeInCartLine(cart_uuid: string, line_uuid: string): Observable<any> {
    let url = `trade-in/cart/${cart_uuid}/lines/${line_uuid}`;
    return this.baseApiService.delete(url);
  }

  deleteTradeInCart(cart_uuid: string): Observable<any> {
    let url = `trade-in/cart/${cart_uuid}`;
    return this.baseApiService.delete(url);
  }

  updateTradeInCartLine(cart_uuid: string, line_uuid: string, payload): Observable<any> {
    let url = `trade-in/cart/${cart_uuid}/lines/${line_uuid}`;
    return this.baseApiService.put(url, payload);
  }

  getQuality(quality: string) {
    let quality_text = '';
    switch (quality) {
      case 'functional':
        quality_text = 'functioneel'
        break;
      case 'non_functional':
        quality_text = 'niet functioneel'
        break;

      default:
        break;
    }
    return quality_text;
  }

  getBrandFilterOptions(): Observable<any> {
    let url = `search/ti-products/filter-options`
    return this.baseApiService.get(url);
  }
  getProductsPromotions() : Observable<any> {
    let url = `search/ti-products/promotions`
    return this.baseApiService.get(url);
  }

}
