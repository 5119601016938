import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class ExportProductService {
    constructor(
        private apiBaseService: ApiBaseService
    ) { }

    getProducts(catalog_code: string, filter_products: string = null, page = 1) {
        if (page === 0) {
            page = 1;
        }
        let paramsString = `page=${page}`;
        if (filter_products) {
            paramsString = paramsString + `&filter_products=${filter_products.trim()}`;
        }
        const params = new HttpParams({
            fromString: paramsString
        });
        const url = `catalog/${catalog_code}/dealer-products`;
        return this.apiBaseService.get(url, params);
    }

    exportProduct(catalog_code: string, filter_products: string = null, report_format = 'csv') {
        let paramsString = `report_format=${report_format}`;
        if (filter_products) {
            paramsString = paramsString + `&filter_products=${filter_products.trim()}`;
        }
        const params = new HttpParams({
            fromString: paramsString
        });
        const url = `catalog/${catalog_code}/dealer-products/export`;
        return this.apiBaseService.post(url, {}, '', params);
    }

    checkExportStatus(taskId: string) {
        return this.apiBaseService.get(`tasks/${taskId}`);
    }

    downloadFile(id: number, title: string, fileType: string) {
        return this.apiBaseService.download(`export-reports/${id}/download`).subscribe((res) => {
            const fileURL = window.URL.createObjectURL(new Blob([res]));
            const fileLink = document.createElement('a');

            fileLink.href = fileURL;
            fileLink.setAttribute('download', `report of ${title}.${fileType}`);
            document.body.appendChild(fileLink);

            fileLink.click();
        }, (err) => { });
    }
}
