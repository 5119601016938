import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from '../../shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ReportService {

  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  getReports(params: HttpParams): Observable<any> {
    return this.apiBaseService.get(`export-reports`, params);
  }

  downloadReport(reportUUID) {
    const url = `export-reports/${reportUUID}/download`;
    return this.apiBaseService.getFile(url);
  }
}
