export enum EProductTypes {
    HARDWARE = 'hardware',
    HANDSET = 'handset',
    ACCESSORY = 'accessory',
    TABLET = 'tablet',
    ADDITIONAL_COST = 'additional_cost',
    VOUCHER = 'voucher',
    SUBSCRIPTION = 'subscription',
    ADD_ON = 'add_on',
    SIMCARD = 'simcard',
    DIGITAL = 'digital',
    CREDIT = 'credit',
    PREPAID = 'prepaid',
    PROMOTION = 'promotion',
    INSURANCE = 'insurance',
    DISCOUNT = 'discount',
    PREPAID_TOPUP = 'prepaid_topup',
    COUPON = 'coupon'
}

export enum ESupplierTypes {
    CPAAS = 'cpaas',
}

export enum EProductQualityTypes {
    FUNCTIONAL = 'functional',
    NON_FUNCTIONAL = 'non_functional',
}



export enum EProductAttributes {
    CISCO = 'search_cisco_ready',
    KPN_EEN = 'search_kpn_een',
    TEAMS = 'search_teams_ready',
    GENESYS = 'search_genesys_ready',
    MITEL = 'search_mitel_ready'
}

export enum EProductAttributesGroup {
    KPN_EEN = 'KPN_EEN',
}


export enum EQuoteProductType {
    HARDWARE_MONTHLY = "hw_monthly",
    SOFTWARE_MONTHLY = "sw_monthly"
}


export const EProductAttributeCodes = Object.keys(EProductAttributes).map(key => EProductAttributes[key]);

