import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StorageService } from 'src/app/shared/storage.service';

import { ApiBaseService } from '../../shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductDetailService {

  private selectedProdut$ = new BehaviorSubject<any>('');

  constructor(
    private apiBaseService: ApiBaseService,
    private storageService: StorageService
  ) { }

  getProduct(productCode: string, catalog_code?: string) {
    // tslint:disable-next-line:max-line-length
    const contactUuid = !this.storageService.getCurrentUser()?.is_external && this.storageService.getContactUuid() ? this.storageService.getContactUuid() : '';
    let query_string = contactUuid ? `contact_uuid=${contactUuid}` : '';
    query_string = catalog_code ? `catalog_code=${catalog_code}${query_string ? '&' + query_string : ''
      }` : query_string;
    const params = new HttpParams({
      fromString: query_string
    });

    const productDetailURI = `products/${productCode}`;
    return this.apiBaseService.get(productDetailURI, params);
  }

  setSelectedProduct(selectedProdut: any) {
    this.selectedProdut$.next(selectedProdut);
  }

  getSelectedProduct(): Observable<any> {
    return this.selectedProdut$.asObservable();
  }

  getProductAttributes(productCode: string) {
    const URI = `products / ${productCode} /product-attributes`;
    return this.apiBaseService.get(URI);
  }

  getRelatedAccessoryProducts(productCode: string) {
    const params = new HttpParams({
      fromString: `group-response=true`
    });
    const URI = `products/${productCode}/related-products`;
    return this.apiBaseService.get(URI, params);
  }

  getRelatedProducts(productCode: string) {
    const params = new HttpParams({
      fromString: `group-response=true`
    });
    const URI = `products/${productCode}/mandatory-products`;
    return this.apiBaseService.get(URI, params);
  }


  getPromotionalProducts(productCode: string, contact_uuid = '') {
    const params = new HttpParams({
      fromString: contact_uuid ? `contact_uuid=${contact_uuid}` : ''
    });

    const URI = `products/${productCode}/promotions`;
    return this.apiBaseService.get(URI, params);
  }
}
