<section class="inner-content-wrapper">
  <div class="container">
    <div class="handset-item-wrapper">
      <div class="section-loader" *ngIf="isLoading">
        <div class="kpn-spinner">
          <img src="/assets/images/kpn-loader.gif" alt="Loading">
        </div>
      </div>

      <div class="row">
        <div class="col-6 col-md-4" *ngFor="let product of (products$ | async)">
          <div class="product-item">
            <div class="product-item-wrap">
              <div class="product-img">
                <a routerLink="/product-details">
                  <img src="/assets/images/{{product?.image}}" alt="{{product.name}}" *ngIf="product.image">
                  <img src="/assets/images/image-not-available.png" alt="{{product.name}}"
                    *ngIf="!product.image">
                </a>
              </div>

              <div class="row justify-content-center mb-3">
                <div class="col-md-6 pr-1 pl-0">
                  <button class="btn btn-outline-primary btn-block rounded-btn" (click)="onProductDetail(product)">
                    <span>Details</span>
                  </button>
                </div>
                <!-- <div class="col-md-6 pl-1 pr-0" *ngIf="!product.child_products.length"> -->
                <div class="col-md-6 pl-1 pr-0">
                  <button class="btn btn-outline-primary btn-block rounded-btn" (click)="onAddToCart(product)">
                    <span>{{'PRODUCT_LIST.ADD_TO_CART' | transloco }}</span>
                  </button>
                </div>
              </div>
              <div class="product-title-offer">
                <h4 class="product-name">
                  <span class="product-name" (click)="onProductDetail(product)">{{product.name}}</span>
                </h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
