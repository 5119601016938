<section class="bg-kpn-subtle-grey">
  <div *ngIf="isCustomerDataLoading" class="full-screen-loader" id="fullScreenLoader">
    <div class="loader">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  </div>
  <section class="pb-5 pt-4">
    <div class="container">

      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4 pb-3">
        <li class="back-breadcrumb-btn border-0">
          <a style="cursor: pointer;" class="text-primary" (click)="backToPrevious()">
            <i class="kphicon-uniE906"></i> Terug
          </a>
        </li>
      </ul>

      <app-steps *ngIf="cart" [activeStep]='activeStep' [completeStepOne]="'true'"></app-steps>

      <h3 class="font-kpn-black tri-title-40 green-text mb-3 mt-n3">Verzend gegevens</h3>

      <div class="facts-box">
        <!-- <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4">
          <h3 class="green-text tri-title-32 font-kpn-black mb-4 pb-2">
            KVK Bedrijfsgegevens
          </h3>
          <form [formGroup]="basicForm">
            <div class="row mb-2">
              <div class="col-md-11">
                <div class="row mb-3 pb-1 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }}</label>
                      <input type="text" formControlName="company_name" class="form-control tri-form-control" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-11">
                <div class="row gutter-row-24 mb-3 pb-1">
                  <div class="col-md-6 gutter-col-24" [hidden]="!basicForm.controls.coc_number.value">
                    <div class="kpn-form-group">
                      <label for="coc_number" class="field-label">KVK-{{'CUSTOMER.NUMBER' | transloco }}</label>
                      <input type="text" name="coc_number" formControlName="coc_number" class="form-control tri-form-control" />
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24" [hidden]="!basicForm.controls.krn_number.value">
                    <div class="kpn-form-group">
                      <label for="krn_number" class="field-label">CKR-{{'CUSTOMER.NUMBER' | transloco }} <span>({{'CUSTOMER.OPTIONAL' | transloco }})</span></label>
                      <input type="text" name="krn_number" formControlName="krn_number" class="form-control tri-form-control" />
                    </div>
                  </div>
                </div>
                <div formGroupName="address">
                  <div class="row gutter-row-24 mb-3 pb-1">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="zipcode" class="field-label">{{'SIGN_UP.POSTCODE' | transloco}}</label>
                        <input type="text" name="zipcode" [patterns]="zipCodePattern" mask="111100"
                          class="form-control tri-form-control" formControlName="zipcode" placeholder="1234AB">
                      </div>
                    </div>
                    <div class="col-md-3 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="house_number" class="field-label">{{'CUSTOMER.HOUSE_NUMBER' | transloco }}</label>
                        <input type="text" name="house_number" class="form-control tri-form-control"
                          formControlName="house_number" placeholder="1234AB">
                      </div>
                    </div>
                    <div class="col-md-3 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="house_number_ext" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                        <input type="text" name="house_number_ext" class="form-control tri-form-control"
                          formControlName="house_number_ext">
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-1 gutter-row-24">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="street" class="field-label">{{'CUSTOMER.STREET' | transloco }}</label>
                        <input type="text" name="street" formControlName="street" class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-1 gutter-row-24">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="city" class="field-label">{{'CUSTOMER.CITY' | transloco }}</label>
                        <input type="text" name="city" formControlName="city" class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="row mb-4">
            <div class="col-md-11">
              <div class="row">
                <div class="col-md-6">
                  <div class="kpn-form-group">
                    <label for="vat_number" class="field-label">BTW nummer</label>
                    <div class="field-with-spinner">
                      <input disabled type="text" name="vat_number" [(ngModel)]="vat_number" class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row mt-2" *ngIf="customer">
            <div class="col-md-6">
              <div class="note-box note-box-red mb-2" *ngIf="!customer?.contact_addresses?.length">
                <span class="circle-hint-icon">i</span>
                <p class="body-text-1 mb-0">
                  {{'CUSTOMER.NO_BILLING_ADDRESS' | transloco }}
                </p>
              </div>
            </div>
          </div>
        </div> -->

        <div class="bg-white tri-shadow-xl tri-rounded-20 pb-4 p-40 mb-4">
          <h4 class="green-text tri-title-32 font-kpn-black mb-4 pb-2">
            <!-- {{'CUSTOMERS.ORGANIZATIONAL_DATA' | transloco }} -->
            KVK Bedrijfsgegevens
          </h4>
          <div class="pr-50">
            <div class="row">
              <div class="col-md-6">
                <div class="row gutter-row-24">
                  <div class="col gutter-col-24">
                    <div class="kpn-form-group tri-autocomplete-form">
                      <label for="" class="field-label">  Bedrijfsnaam of KvK-nummer </label>
                      <!-- <input type="text" class="form-control rounded-0"> -->
                      <ng-autocomplete #customerSearch [data]="searchCompanyList"
                        [disabled]="user?.is_external"
                        [placeholder]="'CUSTOMERS.SEARCH_BY_COMPANY_NAME_OR_CHAMBER_OF_COMMERCE_NUMBER' | transloco" [(ngModel)]='selectedCompanyName'
                        [searchKeyword]="companySearchKeyword" (selected)='onSelectedCompany($event)'
                        (inputChanged)='searchCompanies($event)'
                        [itemTemplate]="companySearchItemTemplate" focusFirst="true" historyIdentifier="searchCompanyList"
                        isHistoryListVisible="true" (inputCleared)="companySearchCleared()" [debounceTime]="800"
                        [notFoundTemplate]="companySearchNotFoundTemplate">
                      </ng-autocomplete>

                      <ng-template #companySearchItemTemplate let-item>
                        <a [innerHTML]="item.company_name"></a>
                      </ng-template>

                      <ng-template #companySearchNotFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <form [formGroup]="basicForm"  class="in-active-form mt-4">
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">KVK-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                        <input type="text" formControlName="coc_number" class="form-control tri-form-control">
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">KRN-{{'CUSTOMERS.NUMBER' | transloco}}</label>
                        <input type="text" formControlName="krn_number" class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>
                  <div formGroupName="address">
                    <div class="row gutter-row-24 mb-3 pb-2">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="zipcode" class="field-label">{{'SIGN_UP.POSTCODE' | transloco}}</label>
                          <input type="text" name="zipcode" [patterns]="zipCodePattern" mask="111100"
                            class="form-control tri-form-control" formControlName="zipcode">
                        </div>
                      </div>
                      <div class="col-md-3 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="house_number" class="field-label">{{'CUSTOMER.HOUSE_NUMBER' | transloco }}</label>
                          <input type="text" name="house_number" class="form-control tri-form-control"
                            formControlName="house_number" >
                        </div>
                      </div>
                      <div class="col-md-3 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="house_number_ext" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                          <input type="text" name="house_number_ext" class="form-control tri-form-control"
                            formControlName="house_number_ext">
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 pb-2 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="street" class="field-label">{{'CUSTOMER.STREET' | transloco }}</label>
                          <input type="text" name="street" formControlName="street" class="form-control tri-form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 pb-2 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="city" class="field-label">{{'CUSTOMER.CITY' | transloco }}</label>
                          <input type="text" name="city" formControlName="city" class="form-control tri-form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 pb-2 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="country" class="field-label">Land</label>
                          <input type="text" name="country" formControlName="country" class="form-control tri-form-control">
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 pb-4 p-40 mb-4">
          <h4 class="green-text tri-title-32 font-kpn-black mb-3">{{'CUSTOMER.DELIVERY_ADDRESS' | transloco }}</h4>
          <div class="pr-50">
            <div class="row" *ngIf="((!cart?.allow_delivery_address_from_contact) && this.cart && ((isNewQuote || isImportedQuote) ? false : true))">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input type="checkbox" [(ngModel)]="sameAsDeliveryAddress" (ngModelChange)="onChangeSameAsDeliveryAddress">
                      <span class="checkBoxCheckmark" ></span>
                    </div>
                    <div class="checkbox-label font-kpn-extended ml-2">
                      <span>Hetzelfde als bedrijfsadres</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="sameAsDeliveryAddress === false">
              <div class="col-md-12">
                <div class="row mb-3 pb-2 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <!-- [ngClass]="{ 'is-invalid': cart && !cart?.shipping_address_id }" -->
                      <label for="" class="field-label">Selecteer adres</label>
                      <select name=""
                        [(ngModel)]="selectedShippingAddress" (ngModelChange)="onChangeShippingAddress($event)"
                        class="form-control tri-form-control tri-form-control-select-op" id="">
                        <option disabled [ngValue]="null">{{'CUSTOMER.SELECT_ADDRESS' | transloco }}</option>
                        <option  class="create-option" *ngIf="hasContactAddressCreatePermission &&
                          (!cart?.allow_delivery_address_from_contact ||
                          (cart?.allow_delivery_address_from_contact && !cart?.block_delivery_address))
                          " [ngValue]="shippingAddressCreate">{{'CUSTOMER.ADD_MANUALLY' | transloco }} +
                        </option>
                        <option *ngFor="let ship of shippingAddress; trackBy:identify" [ngValue]="ship">
                          <span *ngIf="ship?.company_name">{{ship?.company_name}}&nbsp;</span>
                          <span>{{ship?.zip_code}}&nbsp;</span>
                          <span>{{ship?.house_number}}&nbsp;</span>
                          <span>{{ship?.house_number_extension}}&nbsp;</span>
                          <span>{{ship?.street}}&nbsp;</span>
                          <span>{{ship?.city}}&nbsp;</span>
                        </option>
                      </select>
                      <div class="note-box note-box-red mb-2 mt-1"
                        *ngIf="(isNewQuote || isImportedQuote) && !selectedShippingAddress">
                        <span class="circle-hint-icon">i</span>
                        <p class="body-text-1 mb-0">
                          {{'CUSTOMER.SHIPPING_ADDRESS_IS_REQUIRED' | transloco }}
                        </p>
                      </div>
                      <div *ngIf="cart && !cart?.shipping_address_id && (!selectedShippingAddress?.isNew)" class="invalid-feedback">
                        {{'CUSTOMER.CART_HAS_NO_SHIPPING_ADDRESS' | transloco }}.
                      </div>

                      <div class="note-box note-box-red mb-2"
                        *ngIf="cart?.allow_delivery_address_from_contact && !shippingAddress?.length">
                        <span class="circle-hint-icon">i</span>
                        <p class="body-text-1 mb-0">
                           {{'CUSTOMER.VOUCHER_NOT_CORRECT_NO_DELIVERY_ADDRESSES' | transloco }}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div *ngIf="selectedShippingAddress?.id">
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }} <span class="text-danger"> * </span></label>
                        <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                          [(ngModel)]="selectedShippingAddress.company_name" class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>

                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.INITIALS' | transloco }}</label>
                        <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                          [(ngModel)]="selectedShippingAddress.initials" [ngClass]="{ 'is-invalid': errors?.initials }"
                          class="form-control tri-form-control">
                        <div *ngIf="errors?.initials" class="invalid-feedback">
                          {{ errors?.initials }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                        <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                          [ngClass]="{ 'is-invalid': errors?.first_name }" [(ngModel)]="selectedShippingAddress.first_name"
                          class="form-control tri-form-control">
                        <div *ngIf="errors?.first_name" class="invalid-feedback">
                          {{ errors?.first_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.MIDDLE_NAME' | transloco }}</label>
                        <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                          [(ngModel)]="selectedShippingAddress.surname_prefix"
                          [ngClass]="{ 'is-invalid': errors?.surname_prefix }" class="form-control tri-form-control">
                        <div *ngIf="errors?.surname_prefix" class="invalid-feedback">
                          {{ errors?.surname_prefix }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.LAST_NAME' | transloco }}</label>
                        <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                          [(ngModel)]="selectedShippingAddress.last_name" class="form-control tri-form-control" [ngClass]="{
                          'is-invalid': errors?.last_name }">
                        <div *ngIf="errors?.last_name" class="invalid-feedback">
                          {{ errors?.last_name }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="street_n" class="field-label">Land  <span class="text-danger"> * </span></label>
                        <ng-select class="tri-ng-select" [placeholder]="'SIGN_UP.SELECT_COUNTRY' | transloco" [disabled]="selectedShippingAddress.country" [items]="country"
                          bindLabel="name" bindValue="country_code" [(ngModel)]="selectedShippingAddress.country"
                          [ngModelOptions]="{standalone: true}" (change)="onSelectCountry($event, true)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row"
                    *ngIf="selectedShippingAddress?.country && selectedShippingAddress?.country !== NL_CODE && selectedCountryObject?.searchable">
                    <div class="col-12">
                      <div class="autocomplete-search-filter select-list-inquote block-width my-0">
                        <div class="form-group kpn-form-group">
                          <div
                            class="kpn-form-group tri-autocomplete-form handset-search-field auto-complete-border default-search-field mb-0"
                            [ngClass]="{'search-active' : searchFocused}">
                            <i class="search-icon kpn-ui-icon ui-search"></i>
                            <div class="ng-autocomplete">
                              <ng-autocomplete #auto class="" [disabled]="selectedShippingAddress.country"
                                [data]="addressList"  [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                                [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                                (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                                (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                                [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                                [notFoundTemplate]="notFoundTemplate">
                              </ng-autocomplete>

                              <ng-template #itemTemplate let-item>
                                <div class="auto-template">
                                  <span [innerHTML]="item.value"></span> &nbsp;
                                  <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                                </div>
                              </ng-template>

                              <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                              </ng-template>
                            </div>
                            <span class="text-muted text-muted" *ngIf="!foreignAddressSearchError">
                              <small>{{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                            </span>
                            <span class="danger-text" *ngIf="foreignAddressSearchError">
                              <small>{{foreignAddressSearchError}}</small>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'SIGN_UP.POSTCODE' | transloco}} <span class="text-danger"> * </span></label>
                        <input type="text" oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern"
                          [mask]="selectedCountry === 'NL' ? '111100' :'AAAAAAAAAA'"
                          [disabled]="!hasContactAddressUpdatePermission || (selectedShippingAddress.country && addressFound.zipcode && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)"
                          [(ngModel)]="selectedShippingAddress.zip_code" (keyup)="onAddressSearch()"
                          [ngClass]="{ 'is-invalid': errors?.zip_code || !selectedShippingAddress.zip_code?.trim()?.length }"
                          class="form-control tri-form-control">
                        <div *ngIf="errors?.zip_code" class="invalid-feedback">
                          {{ errors?.zip_code }}
                        </div>
                        <div *ngIf="!selectedShippingAddress.zip_code?.trim()?.length" class="invalid-feedback">
                          {{'CUSTOMER.POST_CODE_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>

                    <div class="col-md-3 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.HOUSE_NUMBER' | transloco }} <span class="text-danger"> * </span></label>
                        <input type="text"
                          [disabled]="!hasContactAddressUpdatePermission || (selectedShippingAddress.country && addressFound.house_number && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)"
                          [(ngModel)]="selectedShippingAddress.house_number" class="form-control tri-form-control"
                          (keyup)="onAddressSearch()"
                          [ngClass]="{ 'is-invalid': errors?.house_number || !selectedShippingAddress?.zip_code?.trim()?.length }">
                        <div *ngIf="errors?.house_number" class="invalid-feedback">
                          {{ errors?.house_number }}
                        </div>
                        <div *ngIf="!selectedShippingAddress?.house_number?.toString()?.trim()?.length"
                          class="invalid-feedback">
                          {{'CUSTOMER.HOUSE_NUMBER_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                        <input type="text"
                          [disabled]="!hasContactAddressUpdatePermission"
                          [(ngModel)]="selectedShippingAddress.house_number_extension" class="form-control tri-form-control"
                          (keyup)="onAddressSearch()" [ngClass]="{ 'is-invalid': errors?.house_number_extension }">
                        <div *ngIf="errors?.house_number_extension" class="invalid-feedback">
                          {{ errors?.house_number_extension }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.STREET' | transloco }} <span class="text-danger"> * </span></label>
                        <div class="field-with-spinner">
                          <input type="text"
                            [disabled]="!hasContactAddressUpdatePermission || (selectedShippingAddress.country  && addressFound.street && selectedCountryObject?.searchable && !hasInputCustomAddressPermission) || (selectedShippingAddress.country === NL_CODE)"
                            [ngClass]="{ 'is-invalid': errors?.street || !selectedShippingAddress.street?.trim()?.length }"
                            [(ngModel)]="selectedShippingAddress.street" class="form-control tri-form-control">
                          <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                            role="status">
                          </div>
                          <div *ngIf="errors?.street" class="invalid-feedback">
                            {{ errors?.street }}
                          </div>
                          <div *ngIf="!selectedShippingAddress.street?.trim()?.length" class="invalid-feedback">
                            {{'CUSTOMER.STREET_IS_REQUIRED' | transloco }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.CITY' | transloco }} <span class="text-danger"> * </span></label>
                        <div class="field-with-spinner">
                          <input type="text"
                            [disabled]="!hasContactAddressUpdatePermission || (selectedShippingAddress.country && addressFound.city && selectedCountryObject?.searchable && !hasInputCustomAddressPermission) || (selectedShippingAddress.country === NL_CODE)"
                            [(ngModel)]="selectedShippingAddress.city"
                            [ngClass]="{ 'is-invalid': errors?.city || !selectedShippingAddress.city?.trim()?.length }"
                            class="form-control tri-form-control">
                          <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                            role="status">
                          </div>

                          <div *ngIf="errors?.city" class="invalid-feedback">
                            {{ errors?.city }}
                          </div>
                          <div *ngIf="!selectedShippingAddress.city?.trim()?.length" class="invalid-feedback">
                            {{'CUSTOMER.CITY_NAME_IS_REQUIRED' | transloco }}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24" *ngIf="hasInputCustomAddressPermission">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="info_1" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_1' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="selectedShippingAddress.extra_address_information_1"
                            class="form-control tri-form-control">
                          </textarea>
                          <div *ngIf="errors?.extra_address_information_1" class="invalid-feedback">
                            {{ errors?.extra_address_information_1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="info_2" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_2' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="selectedShippingAddress.extra_address_information_2"
                            class="form-control tri-form-control">
                          </textarea>
                          <div *ngIf="errors?.extra_address_information_2" class="invalid-feedback">
                            {{ errors?.extra_address_information_2 }}
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                  <div class="row">
                    <div class="col-md-12 gutter-col-24">
                      <div class="mb-3">
                        <label class="checkbox-item d-flex align-items-center">
                          <div class="checkbox-btn checkbox-btn-outline">
                            <input type="checkbox" [(ngModel)]="selectedShippingAddress.is_default">
                            <span class="checkBoxCheckmark" ></span>
                          </div>
                          <div class="checkbox-label font-kpn-extended ml-2">
                            <span>Is standaard ?</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="text-right mb-4 pt-1"
                    *ngIf="hasContactAddressUpdatePermission && !cart?.block_delivery_address">
                    <button [disabled]="
                    createAddressLoader ||
                    cart?.block_delivery_address ||
                    !selectedShippingAddress?.city?.trim()?.length ||
                    !selectedShippingAddress?.street?.trim()?.length||
                    !selectedShippingAddress?.zip_code?.trim()?.length ||
                    !selectedShippingAddress?.house_number?.toString()?.trim()?.length ||
                    !selectedShippingAddress?.company_name?.trim()?.length" class="btn px-5 btn-primary mb-0 rounded-btn"
                      (click)="updateShippingAddress()">
                      <span class="spinner-border spinner-border-sm mr-2" *ngIf="createAddressLoader" role="status"
                        aria-hidden="true"></span> Update
                    </button>
                  </div> -->
                </div>
                <!-- Create Address -->
                <div *ngIf="selectedShippingAddress && !selectedShippingAddress?.id">
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="c_name" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }}  <span class="text-danger"> * </span></label>
                        <input (blur)="onAddressCompanyChange()" (ngModelChange)="onAddressCompanyChange()" type="text" name="c_name" [(ngModel)]="selectedShippingAddress.company_name"
                          class="form-control tri-form-control" placeholder="Bedrijf"
                          [ngClass]="{ 'is-invalid': addressCreateError?.company_name }">
                        <div *ngIf="addressCreateError?.company_name" class="invalid-feedback">
                          {{'CUSTOMER.COMPANY_NAME_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="v_letter" class="field-label">{{'CUSTOMER.INITIALS' | transloco }}</label>
                        <input type="text" name="v_letter" [(ngModel)]="selectedShippingAddress.initials" placeholder="Voorletters"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="vo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                        <input type="text" name="vo_name" [(ngModel)]="selectedShippingAddress.first_name" placeholder="Voornaam"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="sp" class="field-label">{{'CUSTOMER.MIDDLE_NAME' | transloco }}</label>
                        <input type="text" name="sp" [(ngModel)]="selectedShippingAddress.surname_prefix" placeholder="Tussenvoegsel"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="l_name" class="field-label">{{'CUSTOMER.LAST_NAME' | transloco }}</label>
                        <input type="text" name="l_name" [(ngModel)]="selectedShippingAddress.last_name" placeholder="Achternaam"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="street_n" class="field-label">Land <span class="text-danger"> * </span></label>
                        <ng-select class="tri-ng-select" [placeholder]="'SIGN_UP.SELECT_COUNTRY' | transloco" [items]="country" bindLabel="name" bindValue="country_code"
                          [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}"
                          (change)="onSelectCountry($event)">
                        </ng-select>
                      </div>
                    </div>
                  </div>
                  <div class="row" *ngIf="selectedCountry && selectedCountry !== NL_CODE && selectedCountryObject?.searchable">
                    <div class="col-12">
                      <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                        <div class="form-group kpn-form-group">
                          <div
                            class="kpn-form-group tri-autocomplete-form handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                            [ngClass]="{'search-active' : searchFocused}">
                            <i class="search-icon kpn-ui-icon ui-search"></i>
                            <div class="ng-autocomplete">
                              <ng-autocomplete #auto class="" [data]="addressList"
                                 [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                                [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                                (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                                (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                                [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                                [notFoundTemplate]="notFoundTemplate">
                              </ng-autocomplete>

                              <ng-template #itemTemplate let-item>
                                <div class="auto-template">
                                  <span [innerHTML]="item.value"></span> &nbsp;
                                  <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                                </div>
                              </ng-template>

                              <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                              </ng-template>
                            </div>
                            <span class="text-muted" *ngIf="!foreignAddressSearchError">
                              <small>{{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                            </span>
                            <span class="danger-text" *ngIf="foreignAddressSearchError">
                              <small>{{foreignAddressSearchError}}</small>
                            </span>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="p_code" class="field-label">{{'SIGN_UP.POSTCODE' | transloco}} <span class="text-danger"> * </span></label>
                        <input (ngModelChange)="onAddressPostCodeChange()" (blur)="onAddressPostCodeChange()" [disabled]="selectedCountry !== 'NL' && (selectedCountryObject?.searchable && !hasInputCustomAddressPermission)" type="text" name="p_code"
                          oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern" [mask]="selectedCountry === 'NL' ? '111100' :'AAAAAAAAAA'"
                          [(ngModel)]="selectedShippingAddress.zip_code" (keyup)="onAddressSearch()" placeholder="Postcode"
                          [ngClass]="{ 'is-invalid': errors?.zip_code || addressCreateError.zip_code }"
                          class="form-control tri-form-control">
                        <div *ngIf="errors?.zip_code" class="invalid-feedback">
                          {{ errors?.zip_code }}
                        </div>
                        <div *ngIf="addressCreateError.zip_code" class="invalid-feedback">
                          {{'CUSTOMER.POST_CODE_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="h_number" class="field-label">{{'CUSTOMER.HOUSE_NUMBER' | transloco }} <span class="text-danger"> * </span></label>
                        <input (ngModelChange)="onAddressHouseNoChange()" (blur)="onAddressHouseNoChange()" type="text" [disabled]="(selectedCountry !== 'NL' && (selectedCountryObject?.searchable && !hasInputCustomAddressPermission))" name="h_number" placeholder="Huisnummer"
                          [(ngModel)]="selectedShippingAddress.house_number" class="form-control tri-form-control"
                          (keyup)="onAddressSearch()" [ngClass]="{ 'is-invalid': errors?.house_number ||
                          addressCreateError?.house_number }">
                        <div *ngIf="errors?.house_number" class="invalid-feedback">
                          {{ errors?.house_number }}
                        </div>
                        <div *ngIf="addressCreateError?.house_number" class="invalid-feedback">
                          {{'CUSTOMER.HOUSE_NUMBER_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                    <div class="col-md-3 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="hext" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                        <input type="text"  name="hext" placeholder="Toevoeging"
                          [(ngModel)]="selectedShippingAddress.house_number_extension" class="form-control tri-form-control"
                          (keyup)="onAddressSearch()" [ngClass]="{ 'is-invalid': errors?.house_number_extension }">
                        <div *ngIf="errors?.house_number_extension" class="invalid-feedback">
                          {{ errors?.house_number_extension }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="street_n" class="field-label">{{'CUSTOMER.STREET' | transloco }} <span class="text-danger"> * </span></label>
                        <div class="field-with-spinner">
                          <input (ngModelChange)="onAddressStreetChange()" (blur)="onAddressStreetChange()" type="text" name="street_n" [disabled]="selectedCountry === 'NL'  ||  (selectedCountry && addressFound.street && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)" placeholder="Straat"
                            [ngClass]="{ 'is-invalid': errors?.street || addressCreateError?.street }"
                            [(ngModel)]="selectedShippingAddress.street" class="form-control tri-form-control">
                          <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                            role="status">
                          </div>
                          <div *ngIf="errors?.street" class="invalid-feedback">
                            {{ errors?.street }}
                          </div>
                          <div *ngIf="addressCreateError?.street" class="invalid-feedback">
                            {{'CUSTOMER.STREET_IS_REQUIRED' | transloco }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="city_n" class="field-label">{{'CUSTOMER.CITY' | transloco }} <span class="text-danger"> * </span></label>
                        <div class="field-with-spinner">
                          <input  (ngModelChange)="onAddressCityChange()" (blur)="onAddressCityChange()" type="text" [disabled]="selectedCountry === 'NL'  ||  (selectedCountry && addressFound.city && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)" name="city_n" placeholder="Woonplaats"
                            [(ngModel)]="selectedShippingAddress.city"
                            [ngClass]="{ 'is-invalid': errors?.city || addressCreateError?.city }"
                            class="form-control tri-form-control">
                          <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                            role="status">
                          </div>

                          <div *ngIf="errors?.city" class="invalid-feedback">
                            {{ errors?.city }}
                          </div>
                          <div *ngIf="addressCreateError?.city" class="invalid-feedback">
                            {{'CUSTOMER.CITY_NAME_IS_REQUIRED' | transloco }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24" *ngIf="hasInputCustomAddressPermission">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="info_1" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_1' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="selectedShippingAddress.extra_address_information_1"
                            class="form-control tri-form-control">
                          </textarea>

                          <div *ngIf="errors?.extra_address_information_1" class="invalid-feedback">
                            {{ errors?.extra_address_information_1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="info_2" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_2' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="selectedShippingAddress.extra_address_information_2"
                            class="form-control tri-form-control">
                          </textarea>

                          <div *ngIf="errors?.extra_address_information_2" class="invalid-feedback">
                            {{ errors?.extra_address_information_2 }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                    <span class="circle-hint-icon">i</span>
                    <p class="body-text-1 mb-0">
                      {{addressCombinationMsg}}
                    </p>
                  </div>
                  <!-- <div class="text-right mb-4 pt-4">
                    <button [disabled]="
                    createAddressLoader ||
                    !selectedShippingAddress?.city?.trim()?.length ||
                    !selectedShippingAddress?.street?.trim()?.length||
                    !selectedShippingAddress?.zip_code?.trim()?.length ||
                    !selectedShippingAddress?.house_number?.trim()?.length ||
                    !selectedShippingAddress?.company_name?.trim()?.length
                    " class="btn px-5 btn-primary mb-0 rounded-btn" (click)="createShippingAddress()">
                      <span class="spinner-border spinner-border-sm mr-2" *ngIf="createAddressLoader" role="status"
                        aria-hidden="true"></span> {{'CUSTOMER.CREATE' | transloco }}
                    </button>
                  </div> -->
                </div>

              </div>
            </div>
            <div class="row" *ngIf="(!cart?.allow_delivery_address_from_contact) && (sameAsDeliveryAddress === false) && (!cart?.block_delivery_address) && (!selectedShippingAddress?.id) && (selectedShippingAddress?.isNew) && (this.cart) && ((isNewQuote || isImportedQuote) ? false : true)">
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input [disabled]=" createAddressLoader ||
                      !selectedShippingAddress?.city?.trim()?.length ||
                      !selectedShippingAddress?.street?.trim()?.length||
                      !selectedShippingAddress?.zip_code?.trim()?.length ||
                      !selectedShippingAddress?.house_number?.trim()?.length ||
                      !selectedShippingAddress?.company_name?.trim()?.length" type="checkbox" [(ngModel)]="saveAsNewAddress">
                      <span class="checkBoxCheckmark"></span>
                    </div>
                    <div class="checkbox-label font-kpn-extended ml-2">
                      <span>Sla op als nieuw adres</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row" *ngIf="saveAsNewAddress">
              <div class="col-md-12 gutter-col-24">
                <div class="mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input type="checkbox" [(ngModel)]="selectedShippingAddress.is_default">
                      <span class="checkBoxCheckmark" ></span>
                    </div>
                    <div class="checkbox-label font-kpn-extended ml-2">
                      <span>Is standaard ?</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 pb-4 p-40 mb-4">
          <h4 class="green-text tri-title-32 font-kpn-black mb-3"> {{'CUSTOMER.APPLICANT' | transloco }}</h4>
          <div class="pr-50">
            <div class="row">
              <div class="col-md-12">
                <div class="row mb-3 pb-2 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label"> {{'CUSTOMER.CONTACT_PERSON' | transloco }}</label>
                      <select name="" [(ngModel)]="selectedContactPerson" (ngModelChange)="onChangeContactPerson()"
                        class="form-control tri-form-control" id="">
                        <option disabled [ngValue]="null">Selecteer contactpersoon</option>
                        <option class="create-option" *ngIf="contactPersonAddPermission" [ngValue]="newContactPersonCreate">
                          Handmatig toevoegen +
                        </option>
                        <option [ngValue]="item" *ngFor="let item of contact_persons; let i = index;">
                          <span>{{item?.initials ? item?.initials + ' ' : ''}} {{item?.surname_prefix ? item?.surname_prefix + ' ' : ''}} {{item?.first_name ? item?.first_name + ' ' : ''}} {{item?.last_name ? item?.last_name : ''}}</span>
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div *ngIf="selectedContactPerson && !selectedContactPerson?.status">
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <!-- <div class="col-md-12">
                      <label for="s_gender" class="field-label">Geslacht</label>
                      <div ngbRadioGroup id="s_gender" name="s_gender" ngDefaultControl
                        [(ngModel)]="selectedContactPerson.gender"
                        class="radio_group radio-row-wrap box-radio-group-sim-only box-radio-group row mb-0 pb-4">
                        <div class="col-auto">
                          <div class="radio-group-col radio-col-auto px-0">
                            <label ngbButtonLabel class="default-radio mb-2">
                              <div class="radio-btn">
                                <input ngbButton type="radio" value="M">
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">Mannelijk</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="radio-group-col radio-col-auto px-0">
                            <label ngbButtonLabel class="default-radio mb-2">
                              <div class="radio-btn">
                                <input ngbButton type="radio" value="F">
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">Vrouwelijk</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.INITIALS' | transloco }} <span class="text-danger"> * </span></label>
                        <input type="text" [disabled]="!contactPersonUpdatePermission" [(ngModel)]="selectedContactPerson.initials"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                        <input type="text" [ngClass]="{ 'is-invalid': contactPersonUpdateError?.first_name }" [disabled]="!contactPersonUpdatePermission"
                          [(ngModel)]="selectedContactPerson.first_name" class="form-control tri-form-control">
                        <div *ngIf="contactPersonUpdateError?.first_name" class="invalid-feedback">
                          {{ contactPersonUpdateError?.first_name?.message}}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.MIDDLE_NAME' | transloco }}</label>
                        <input type="text" [disabled]="!contactPersonUpdatePermission" [(ngModel)]="selectedContactPerson.surname_prefix"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.LAST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                        <input type="text" [disabled]="!contactPersonUpdatePermission" [(ngModel)]="selectedContactPerson.last_name"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24">
                    <div class="col gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.PHONE_NUMBER' | transloco }}</label>
                        <input type="text" maxlength="12"
                        [disabled]="!contactPersonUpdatePermission"
                          [ngClass]="{ 'is-invalid': contactPersonUpdateError?.mobile_phone_number }"
                          [(ngModel)]="selectedContactPerson.mobile_phone_number" class="form-control tri-form-control">
                        <div *ngIf="contactPersonUpdateError?.mobile_phone_number" class="invalid-feedback">
                          {{ contactPersonUpdateError?.mobile_phone_number?.message}}
                        </div>
                      </div>
                      <small class="text-muted line-height-20">{{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'SIGN_UP.PATTERN' | transloco}} +31612345678 {{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'LOGIN.OR' | transloco}} 0612345678</small>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24">
                    <div class="col gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'CUSTOMER.E_MAIL_ADDRESS' | transloco }} <span class="text-danger"> * </span></label>
                        <input type="email" [ngClass]="{ 'is-invalid': contactPersonUpdateError?.email }"
                          [(ngModel)]="selectedContactPerson.email" [disabled]="notAllowedEmailChangePermission"
                          class="form-control tri-form-control">
                        <div *ngIf="contactPersonUpdateError?.email" class="invalid-feedback">
                          {{ contactPersonUpdateError?.email[0]}}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 gutter-col-24">
                      <div class="mb-3">
                        <label class="checkbox-item d-flex align-items-center">
                          <div class="checkbox-btn checkbox-btn-outline">
                            <input type="checkbox" [(ngModel)]="selectedContactPerson.is_default">
                            <span class="checkBoxCheckmark" ></span>
                          </div>
                          <div class="checkbox-label font-kpn-extended ml-2">
                            <span>Is standaard ?</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="text-right mb-4 pt-1"
                    *ngIf="contactPersonDeletePermission && selectedContactPerson?.can_delete">
                    <button [disabled]="deleteContactPersonLoader" class="btn px-5 btn-primary mb-0 rounded-btn"
                      (click)="deleteContactPerson(selectedContactPerson?.uuid)">
                      <span class="spinner-border spinner-border-sm mr-2" *ngIf="deleteContactPersonLoader" role="status"
                        aria-hidden="true"></span>  {{'CUSTOMER.DELETE' | transloco }}
                    </button>
                  </div> -->
                </div>
                <div *ngIf="contactPersonAddPermission && selectedContactPerson && selectedContactPerson?.status">
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <!-- <div class="col-md-12">
                      <label for="gender" class="field-label">Geslacht</label>
                      <div ngbRadioGroup name="gender" ngDefaultControl [ngModelOptions]="{standalone: true}"
                        [(ngModel)]="newContactPerson.gender"
                        class="radio_group radio-row-wrap box-radio-group-sim-only box-radio-group row mb-0 pb-4">
                        <div class="col-auto">
                          <div class="radio-group-col radio-col-auto px-0">
                            <label ngbButtonLabel class="default-radio mb-2">
                              <div class="radio-btn">
                                <input ngbButton type="radio" value="M">
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">Mannelijk</span>
                            </label>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="radio-group-col radio-col-auto px-0">
                            <label ngbButtonLabel class="default-radio mb-2">
                              <div class="radio-btn">
                                <input ngbButton type="radio" value="F">
                                <span class="radioBullet"></span>
                              </div>
                              <span class="label">Vrouwelijk</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div> -->
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="cv_letter" class="field-label">{{'CUSTOMER.INITIALS' | transloco }} <span class="text-danger"> * </span></label>
                        <input  (blur)="onContactPersonInitialChange()" (ngModelChange)="onContactPersonInitialChange()" type="text" name="cv_letter" [(ngModel)]="newContactPerson.initials" placeholder="Voorletters"
                          [ngClass]="{ 'is-invalid': contactPersonCreateError?.initials || (feContactPersonCreateError?.initials)  }" class="form-control tri-form-control">
                        <div *ngIf="contactPersonCreateError?.initials" class="invalid-feedback">
                          {{ contactPersonCreateError?.initials[0]}}
                        </div>
                        <div *ngIf="contactPersonCreateError?.initials?.message" class="invalid-feedback">
                          {{ contactPersonCreateError?.initials?.message }}
                        </div>
                        <div *ngIf="feContactPersonCreateError?.initials" class="invalid-feedback">
                          This field may not be blank.
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="cvo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                        <input  (blur)="onContactPersonFirstNameChange()" (ngModelChange)="onContactPersonFirstNameChange()" type="text" name="cvo_name" placeholder="Voornaam"
                          [ngClass]="{ 'is-invalid': contactPersonCreateError?.first_name || (feContactPersonCreateError?.first_name) }"
                          [(ngModel)]="newContactPerson.first_name" class="form-control tri-form-control">
                        <div *ngIf="contactPersonCreateError?.first_name" class="invalid-feedback">
                          {{ contactPersonCreateError?.first_name?.message || contactPersonCreateError?.first_name[0] }}
                        </div>
                        <div *ngIf="feContactPersonCreateError?.first_name" class="invalid-feedback">
                          This field may not be blank.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row gutter-row-24 mb-3 pb-2">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="snpfx" class="field-label">{{'CUSTOMER.MIDDLE_NAME' | transloco }}</label>
                        <input type="text" name="snpfx" [(ngModel)]="newContactPerson.surname_prefix" placeholder="Tussenvoegsel"
                          class="form-control tri-form-control">
                      </div>
                    </div>
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="cp_l_name" class="field-label">{{'CUSTOMER.LAST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                        <input (blur)="onContactPersonLastNameChange()" (ngModelChange)="onContactPersonLastNameChange()" type="text" name="cp_l_name" [(ngModel)]="newContactPerson.last_name" placeholder="Achternaam"
                          [ngClass]="{ 'is-invalid': contactPersonCreateError?.last_name || (feContactPersonCreateError?.last_name) }" class="form-control tri-form-control">
                        <div *ngIf="contactPersonCreateError?.last_name" class="invalid-feedback">
                          {{ contactPersonCreateError?.last_name[0]}}
                        </div>
                        <div *ngIf="feContactPersonCreateError?.last_name " class="invalid-feedback">
                          This field may not be blank.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24">
                    <div class="col-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="c_phone" class="field-label">{{'CUSTOMER.PHONE_NUMBER' | transloco }}</label>
                        <input type="text" name="c_phone" maxlength="12"
                          [ngClass]="{ 'is-invalid': contactPersonCreateError?.mobile_phone_number }" placeholder="Telefoonnummer"
                          [(ngModel)]="newContactPerson.mobile_phone_number" class="form-control tri-form-control">
                        <div *ngIf="contactPersonCreateError?.mobile_phone_number" class="invalid-feedback">
                          {{ contactPersonCreateError?.mobile_phone_number?.message ||
                          contactPersonCreateError?.mobile_phone_number[0]}}
                        </div>
                      </div>
                      <small class="text-muted line-height-20">{{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'SIGN_UP.PATTERN' | transloco}} +31612345678 {{'LOGIN.OR' | transloco}} 0612345678</small>
                    </div>
                  </div>
                  <div class="row mb-3 pb-2 gutter-row-24">
                    <div class="col-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="c_email" class="field-label">{{'CUSTOMER.E_MAIL_ADDRESS' | transloco }} <span class="text-danger"> * </span></label>
                        <input (blur)="onContactPersonEmailChange()" (ngModelChange)="onContactPersonEmailChange()" placeholder="E-mailaders" type="email" name="c_email" [ngClass]="{ 'is-invalid': contactPersonCreateError?.email || (feContactPersonCreateError?.email)  }"
                          [(ngModel)]="newContactPerson.email" class="form-control tri-form-control">
                        <div *ngIf="contactPersonCreateError?.email" class="invalid-feedback">
                          {{ contactPersonCreateError?.email[0]}}
                        </div>
                        <div *ngIf="feContactPersonCreateError?.email" class="invalid-feedback">
                          This field may not be blank.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 gutter-col-24">
                      <div class="mb-3">
                        <label class="checkbox-item d-flex align-items-center">
                          <div class="checkbox-btn checkbox-btn-outline">
                            <input type="checkbox" [(ngModel)]="newContactPerson.is_default">
                            <span class="checkBoxCheckmark" ></span>
                          </div>
                          <div class="checkbox-label font-kpn-extended ml-2">
                            <span>Is standaard ?</span>
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="text-right mb-4 pt-1">

                    <button [disabled]="createContactPersonLoader" class="btn px-5 btn-primary mb-0 rounded-btn"
                      (click)="createContactPerson()">
                      <span class="spinner-border spinner-border-sm mr-2" *ngIf="createContactPersonLoader" role="status"
                        aria-hidden="true"></span> {{'CUSTOMER.CREATE' | transloco }}
                    </button>
                  </div> -->
                </div>
                <div class="row mb-3 pb-2 gutter-row-24" *ngIf="trackAndTracePermission && cart">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="a_email" class="field-label">{{'CUSTOMER.E_MAIL_ADDRESS_FOR_THE_TRACK_TRACE_MAIL' | transloco}}</label>
                      <input placeholder="E-mailadres voor de Track&Trace mail" type="email" name="a_email" [ngClass]="{ 'is-invalid': trackAndTraceEmailError.length }"
                        [(ngModel)]="trackAndTraceEmail" class="form-control tri-form-control">
                      <div *ngIf="trackAndTraceEmailError.length" class="invalid-feedback">
                        <div *ngFor="let trackAndTraceErr of trackAndTraceEmailError">
                          {{ trackAndTraceErr }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="note-box note-box-red mb-2"
                  *ngIf="(cart && cart?.allow_delivery_address_from_contact && !shippingAddress?.length) || (customer && !customer?.contact_addresses?.length)">
                  <span class="circle-hint-icon">i</span>
                  <p class="body-text-1 mb-0">
                    {{'CUSTOMER.CONTACT_WEBSHOP_MANAGER_YOU_CANNOT_CONTINUE_ORDER' | transloco }}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4" *ngIf="hasAddEmailConFigPermission">
          <h4 class="green-text tri-title-32 font-kpn-black mb-3">E-mailconfiguraties CC </h4>
          <div class="pr-50">
            <div class="row gutter-row-24 mb-4">
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="a_email" class="field-label">E-mail configurations</label>
                  <ng-select class="tri-ng-select" [multiple]="false" [(ngModel)]="selectedEmailConfig" (ngModelChange)="onSelectEmailConfig()">
                    <ng-option *ngFor="let emailConfiguration of emailConfigurations" [value]="emailConfiguration.code">
                      <span>{{emailConfiguration?.title}}</span>
                    </ng-option>
                  </ng-select>
                </div>
              </div>
            </div>
            <div class="row gutter-row-24">
              <div class="col-md-6 gutter-col-24">
                <ng-select class="tri-ng-select" [multiple]="true" [(ngModel)]="selectedCPs" (ngModelChange)="onSelectCP()">
                  <ng-option *ngFor="let contact_person of cc_contact_persons" [value]="contact_person.uuid">
                    <span>{{contact_person?.initials}}&nbsp;</span>
                    <span>{{contact_person?.surname_prefix}}&nbsp;</span>
                    <span>{{contact_person?.last_name}}</span>
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row mt-2 pt-3">
            <div class="col-md-12">
              <div class="text-right pt-1" *ngIf="!hasSelectedConfig">
                <button [disabled]="emailConfigLoader || !selectedEmailConfig"
                  class="btn px-4 btn-primary mb-0 rounded-btn" (click)="addEmailConfig()">
                  <span class="spinner-border spinner-border-sm mr-2" *ngIf="emailConfigLoader" role="status"
                    aria-hidden="true"></span>
                  Toevoegen
                </button>
              </div>
              <div class="text-right pt-1" *ngIf="hasSelectedConfig">
                <button [disabled]="emailConfigLoader || !selectedEmailConfig"
                  class="btn px-4 btn-primary mb-0 rounded-btn" (click)="updateEmailConfig()">
                  <span class="spinner-border spinner-border-sm mr-2" *ngIf="emailConfigLoader" role="status"
                    aria-hidden="true"></span> Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-3" *ngIf="customer?.order_blocked">
          <div class="pr-50">
            <div class="row align-items-center" >
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{customer?.order_blocked_reason}}
                  </p>
                  <button class="kphicon-uniE926"></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="row align-items-center" *ngIf="customer?.order_blocked">
          <div class="col-md-12 col-12 col-gap-reduce">
            <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
              <p>
                <span class="kphicon-uniE952"></span>
                {{customer?.order_blocked_reason}}
              </p>
              <button class="kphicon-uniE926"></button>
            </div>
          </div>
        </div> -->

        <div class="row align-items-center justify-content-between mb-3">
          <div class="col-auto d-flex">
            <button (click)="backToPrevious()" class="btn btn-link d-flex align-items-center px-0">
              <i class="kphicon-uniE906 fs-22"></i> Vorige
            </button>
          </div>
          <div class="col-auto">
            <ng-template #tipContent>{{'CUSTOMER.SELECT_APPLICANT_AND_SHIPPING_ADDRESS' | transloco }}</ng-template>
            <span placement="top" [ngbTooltip]="tipContent">
              <button (click)="onSubmitCustomer()" class="btn btn-primary rounded-btn px-4 tri-btn-disabled"
                [disabled]="isNextStepDisabled">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                  aria-hidden="true"></span>
                  <!-- {{'CUSTOMER.NEXT_STEP' | transloco }} -->
                  Naar samenvatting
              </button>
              <!-- <button (click)="onSubmitCustomer()" class="btn btn-primary rounded-btn px-4 tri-btn-disabled mb-2"
                [disabled]="!customer?.is_active || customer?.order_blocked || !isContactPersonSelected || isLoading ||
                (cart?.allow_delivery_address_from_contact && (!cart?.shipping_address_id || !shippingAddress?.length )) || ((isNewQuote || isImportedQuote) && !selectedShippingId)">
                <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                  aria-hidden="true"></span>
                  Naar samenvatting
              </button> -->
            </span>
          </div>
        </div>
        <!-- New Design End -->
      </div>

    </div>
  </section>
</section>


<ng-template #createAddressModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle" i18n="@@arm.order-status.order-details">
      {{'CUSTOMER.DELIVERY_ADDRESS' | transloco }}</h5>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="kpn-card confirmation-cart-overview">
      <div class="px-5 pt-4">
        <form [formGroup]="shippingForm">
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0"> {{'CUSTOMER.COMPANY_NAME' | transloco }}</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="company_name" />
                </div>
              </div>
            </div>
          </div>
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0"> {{'CUSTOMER.FIRST_NAME' | transloco }}</label>
              <div class="kpn-form-group">
                <input type="text" class="form-control kpn-form-control" formControlName="first_name" />
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.LAST_NAME_PREFIX' | transloco }}</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="surname_prefix" />
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.LAST_NAME' | transloco }}</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="last_name" />
                </div>
              </div>
            </div>
          </div>

          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.POSTCODE' | transloco}}<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <input type="text" class="form-control kpn-form-control" [patterns]="zipCodePattern" mask="111100"
                  formControlName="zip_code" [ngClass]="{ 'is-invalid': errors?.zip_code }"
                  (keyup)="onAddressSearch()" />
                <div *ngIf="errors?.zip_code" class="invalid-feedback">
                  {{ errors?.zip_code }}
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}.<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="house_number"
                    [ngClass]="{ 'is-invalid': errors?.house_number }" (keyup)="onAddressSearch()" />
                  <div *ngIf="errors?.house_number" class="invalid-feedback">
                    {{ errors?.house_number }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}. {{'ADD_CUSTOMER.ADD' | transloco}}.</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="house_number_extension"
                    (keyup)="onAddressSearch()" />
                </div>
              </div>
            </div>
          </div>
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.STREET' | transloco }}<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="street"
                    [ngClass]="{ 'is-invalid': errors?.street || addressCombinationMsg }" />
                  <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner" role="status">
                  </div>
                  <div *ngIf="errors?.street" class="invalid-feedback">
                    {{ errors?.street }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.CITY' | transloco }} 333<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="city"
                    [ngClass]="{ 'is-invalid': errors?.city || addressCombinationMsg }" />
                  <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner" role="status">
                  </div>
                  <div *ngIf="errors?.city" class="invalid-feedback">
                    {{ errors?.city }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">Land</label>
              <div class="kpn-form-group">
                <select class="form-control kpn-form-control" formControlName="country"
                  [ngClass]="{ 'is-invalid': errors?.country }">
                  <option *ngFor="let country of countryList" [ngValue]="country.value">
                    {{ country.label }}
                  </option>
                </select>
                <div *ngIf="errors?.country" class="invalid-feedback">
                  {{ errors?.country }}
                </div>
              </div>
            </div>
          </div>
          <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
            <span class="circle-hint-icon">i</span>
            <p class="body-text-1 mb-0">
              {{addressCombinationMsg}}
            </p>
          </div>
          <div class="text-right mb-4 pt-4">
            <button class="btn px-5 btn-primary mb-0 rounded-btn" [disabled]="shipBtnDisable"
              (click)="onAddShippingAddress()">
              <span>{{'CUSTOMER.CREATE' | transloco }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

</ng-template>

<ng-template #editAddressModal let-modal>
  <div class="modal-header">
    <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle" i18n="@@arm.order-status.order-details">
      {{'CUSTOMER.CHANGE_DELIVERY_ADDRESS' | transloco }}</h5>
    <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <div class="modal-body">
    <div class="kpn-card confirmation-cart-overview">
      <div class="px-5 pt-4">
        <form [formGroup]="editShippingForm">
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">  {{'CUSTOMER.COMPANY_NAME' | transloco }}</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="company_name" />
                </div>
              </div>
            </div>
          </div>
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
              <div class="kpn-form-group">
                <input type="text" class="form-control kpn-form-control" formControlName="first_name" />
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0"> {{'CUSTOMER.LAST_NAME_PREFIX' | transloco }}</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="surname_prefix" />
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0"> {{'CUSTOMER.LAST_NAME' | transloco }}</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="last_name" />
                </div>
              </div>
            </div>
          </div>

          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'SIGN_UP.POSTCODE' | transloco}}<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <input type="text" class="form-control kpn-form-control" formControlName="zip_code"
                  [patterns]="zipCodePattern" mask="111100" [ngClass]="{ 'is-invalid': errors?.zip_code }"
                  (keyup)="onEditAddressSearch()" />
                <div *ngIf="errors?.zip_code" class="invalid-feedback">
                  {{ errors?.zip_code }}
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}.<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="house_number"
                    [ngClass]="{ 'is-invalid': errors?.house_number }" (keyup)="onEditAddressSearch()" />
                  <div *ngIf="errors?.house_number" class="invalid-feedback">
                    {{ errors?.house_number }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.HOUSE_NO' | transloco }}. {{'ADD_CUSTOMER.ADD' | transloco}}.</label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="house_number_extension"
                    (keyup)="onEditAddressSearch()" />
                </div>
              </div>
            </div>
          </div>

          <div class="row gutter-row-15 mb-2">
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.STREET' | transloco }}<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="street"
                    [ngClass]="{ 'is-invalid': errors?.street || addressCombinationMsg }" />
                  <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner" role="status">
                  </div>
                  <div *ngIf="errors?.street" class="invalid-feedback">
                    {{ errors?.street }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">{{'CUSTOMER.CITY' | transloco }}<span class="danger-text">*</span></label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <input type="text" class="form-control kpn-form-control" formControlName="city"
                    [ngClass]="{ 'is-invalid': errors?.city || addressCombinationMsg }" />
                  <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner" role="status">
                  </div>
                  <div *ngIf="errors?.city" class="invalid-feedback">
                    {{ errors?.city }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4 gutter-col-15 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">Land</label>
              <div class="kpn-form-group">
                <select class="form-control kpn-form-control" formControlName="country"
                  [ngClass]="{ 'is-invalid': errors?.country }">
                  <option value="NL">Netherlands</option>
                  <option *ngFor="let country of countryList" [ngValue]="country.value">
                    {{ country.label }}
                  </option>
                </select>
                <div *ngIf="errors?.country" class="invalid-feedback">
                  {{ errors?.country }}
                </div>
              </div>
            </div>
          </div>
          <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
            <span class="circle-hint-icon">i</span>
            <p class="body-text-1 mb-0">
              {{addressCombinationMsg}}
            </p>
          </div>
          <div class="text-right mb-4 pt-4">
            <button class="btn px-5 btn-primary mb-0 rounded-btn" [disabled]="shipBtnDisable"
              (click)="onUpdateShippingAddress()">
              <span>{{'CUSTOMER.UPDATE' | transloco }}</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>

</ng-template>
