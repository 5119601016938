<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="customer-search kpn-card bg-kpn-light mb-4">
        <div class="card text-center">
          <div class="card-header">
          </div>
          <div class="card-body">
            <h5 class="card-title">Exporteer prijslijst</h5>
            <button (click)="exportAll()" class="btn btn-primary">Export</button>
          </div>
          <div class="card-footer text-muted">
          </div>
        </div>

        <div class="row mt-3" *ngIf="priceListReports?.length">
          <div class="col-md-12">
            <h3 class="title-3 green-text pt-1" i18n="@@arm.order-status.order-list">Export bestanden:</h3>
            <div class="table-responsive custom-table">
              <table class="table mb-5">
                <thead>
                  <tr>
                    <th class="">Title</th>
                    <th>Aanmaak datum </th>
                    <th>Start Date </th>
                    <th>End Date </th>
                    <th class="text-center">File</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let report of priceListReports | paginate: config">
                    <td>{{report?.title}}</td>
                    <td>
                      {{report?.created_at | date:'dd-MM-yyyy HH:mm'}}
                    </td>
                    <td>
                      {{report?.start_date}}
                    </td>
                    <td>
                      {{report?.end_date}}
                    </td>
                    <td class="text-center">
                      <button type="button" class="btn btn-sm btn-link" (click)="downloadDocument(report?.uuid)">
                        <i class="kpn-ui-icon ui-download v-text-bottom mr-2" style="color: #5cb85c"></i>
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-new">
                <pagination-controls id="price_list_report_pagination"
                  previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}"
                  responsive="true" class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>


<ng-template #exportSuccessModal let-modal>
  <div class="modal-header tri-modal-header d-flex justify-content-end">
    <button type="button" class="btn close" aria-label="Close" (click)="closeModal()">
      <span class="kphicon-uniE926"></span>
    </button>
  </div>
  <div class="modal-body">
    <div class="p-3">
      <div class="d-flex justify-content-center mb-3" *ngIf="!downloadUUID">
        <div class="spinner-border text-success" role="status" style="width: 3rem; height: 3rem;">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="alert alert-success" role="alert" >
        <h4 class="alert-heading">Export is {{ downloadUUID ? 'gereed' : 'in uitvoering' }} !</h4>
        <p *ngIf="!downloadUUID">Je ontvangt een e-mail als het bestand klaar is. Je kunt dit scherm sluiten.</p>
      </div>
      <button class="btn btn-success" (click)="downloadDocument(downloadUUID)" *ngIf="downloadUUID">Download</button>
    </div>
  </div>
  <div class="modal-footer border-0">
    <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button>
  </div>
</ng-template>
