import { Component, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/app/app.state';

import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { selectPermissions } from 'src/app/store/selectors/permission.selectors';
import { ExportFileService } from '../export-file/export-file.service';
@Component({
  selector: 'app-export-all-catalogs',
  templateUrl: './export-all-catalogs.component.html',
  styleUrls: ['./export-all-catalogs.component.scss']
})
export class ExportAllCatalogsComponent extends BasePermission implements OnInit, OnDestroy {
  @ViewChild('exportSuccessModal') exportSuccessModal: TemplateRef<any>;
  isLoading = false;
  downloadUUID: string;
  timer: any;
  fileName: string;
  exportSuccessModalReference: NgbModalRef;
  config = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0,
    id: 'price_list_report_pagination'
  };
  priceListReports = [];
  public permissionSubs$: Subscription;
  constructor(
    public store: Store<IAppState>,
    private exportFileService: ExportFileService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    public utilityService: UtilityService,


  ) {
    super(store);
  }

  ngOnInit() {
    this.permissionSubs$ = this.store.select(selectPermissions).subscribe((permission) => {
      this.getReports();
    });


  }
  exportAll() {
    this.downloadUUID = null;
    this.isLoading = true;
    this.exportFileService.catalogExport().subscribe(
      (res) => {
        this.isLoading = false;
        if (res?.data?.task_id) {
          this.exportStatus(res?.data?.task_id);
          this.exportSuccessModalReference = this.modalService.open(this.exportSuccessModal, {
            ariaLabelledBy: 'modal-basic-title',
            centered: true,
            modalDialogClass: 'tri-modal-677 modal-runded',
            size: 'md',
            scrollable: true,
            backdrop: 'static',
          });
          this.exportSuccessModalReference.result.then((result) => { }, (reason) => { });
        }
      },
      (err) => {
        this.isLoading = false;
        this.toaster.error('Failed to export catalog. Please try again later');
      }

    );
  }

  exportStatus(taskId: string) {
    const delay = 5;
     this.timer = null;
    this.exportFileService.checkExportStatus(taskId).subscribe(
      (res) => {
        const current = res?.data?.current;
        if (current === 0) {
          this.timer = setTimeout(() => {
            this.exportStatus(taskId);
          }, delay * 1000);
        } else if (current === 100) {
          clearTimeout(this.timer);
          this.downloadUUID = res?.data?.report ? res?.data?.report.split('/')[4] : null;
        }
      },
      (err) => {
        this.isLoading = false;
        this.downloadUUID = null;
      }
    );
  }

  closeModal() {
    this.exportSuccessModalReference.close();
    this.downloadUUID = null;
    this.isLoading = false;
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  getReports() {
    if (this.hasReportPermission) {
      this.exportFileService.getReports().subscribe(
        (res) => {
          this.priceListReports = res?.data?.results;
          this.config.totalItems = res?.data?.count;
        },
        (err) => {
          this.toaster.error('Failed to fetch reports');
        }
      );
    }

  }
  onPageClick(event) {
    this.config.currentPage = event;
    this.getReports();
  }
  downloadDocument(docUUID: string) {
    this.exportFileService.downloadReport(docUUID).subscribe(response => {
      const extension = this.utilityService.getFileExtensionByBlobType(response?.body?.type);
      const fileURL = window.URL.createObjectURL(new Blob([response?.body], {
        type: response?.body?.type
      }));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${docUUID}_${new Date().getTime()}.${extension}`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.toaster.success('Document Downloaded !', 'Succes');
      document.body.removeChild(fileLink);
      this.getReports();
    }, error => {
      this.toaster.error(error?.error?.data?.message || `Download Failed !`, 'Error');
    });
  }

  ngOnDestroy() {
    if (this.permissionSubs$) {
      this.permissionSubs$.unsubscribe();
    }
  }
}
