<div  *ngIf="selectedQuoteShippingAddress">
  <app-loader [isLoading]="isLoading"></app-loader>

  <div >
    <div class="bg-gray-1 tri-rounded-20 p-24">
      <label for=""
        class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0">Afleveradres</label>
      <div class="row justify-content-between align-items-center">
        <div class="col-md">
          <div class="min-height-100">
            <p class="font-kpn-extended mb-2">
              <span class="d-block">{{ selectedQuoteShippingAddress?.company_name }} </span>
              <span class="d-block">{{ selectedQuoteShippingAddress?.street }} {{
                selectedQuoteShippingAddress?.house_number }} </span>
              <span class="d-block">{{ selectedQuoteShippingAddress?.zip_code }} {{
                selectedQuoteShippingAddress?.city }}</span>
              <span class="d-block">{{ selectedQuoteShippingAddress?.country }} </span>
            </p>
          </div>
        </div>
      </div>
      <p class="mb-0" *ngIf="eQuoteStatus.APPROVED === quote?.status ||  eQuoteStatus.PENDING === quote?.status">
        <a href="javascript:void(0)" (click)="openCustomAddressModal()"
          class="fs-16 font-kpn-extended link-btn-blue">
          Wijzigen <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
      </p>
    </div>
  </div>
  <div [ngClass]="{'notVisible' : !showAddressModal}">
    <div [ngClass]="{'custom-modal-backdrop' : showAddressModal}"></div>
    <div class="">
      <div [ngClass]="{'custom-modal' : showAddressModal}">
        <div class="modal-runded my-0"  [ngClass]="{'custom-modal-dialog custom-modal-dialog-centered' : showAddressModal}">
          <div class="modal-content modal-content-tri-rouned-20 border-0">
            <div class="">
              <div class="px-4">
                <div class="px-1 modal-header tri-modal-header">
                  <h6 class="modal-title green-text font-kpn-black fs-40" id="quoteListFilter">
                    {{ isCreatingAddress ? 'Adres toevoegen' : 'Afleveradres wijzigen' }}
                  </h6>
                  <button type="button" class="btn close" (click)="closeCustomAddressModal()"  aria-label="Close">
                    <span class="kpnew-icon-close-new"></span>
                  </button>
                </div>
              </div>
            </div>
            <div class="custom-modal-body p-0">
              <div class="pl-4 pt-4 pr-4">
                <div class="custom-inner-modal-scroll pr-4">
                  <div class="address-lines pt-3" *ngIf="!isCreatingAddress">
                    <div class="quote-address-item" *ngFor="let item of quoteShippingAddresses;index as i">
                      <input type="radio" name="shipping-address-option" [id]="i+'address'"
                        [(ngModel)]="temporalSelectedAddressIdForUpdateQuoteShippingAddress"  [value]="item.id">
                      <label [for]="i+'address'" class="d-flex font-kpn-extended">
                        <span *ngIf="item?.is_default"
                          class="standard-address font-kpn-extended kpn-extended-bold fs-12 letter-spacing-normal">Standaard
                          afleveradres</span>
                        <span class="radio-bullet mt-1"></span>
                        <div>
                          <span class="block">{{ item?.company_name }}</span>
                          <span class="d-block grey-text">
                            {{ item?.street }} {{ item?.house_number }}, {{ item?.zip_code }} {{ item?.city }} {{
                            item?.country }}
                          </span>
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="row gutter-row-15 mb-0" *ngIf="isCreatingAddress" >
                    <div class="mb-4 col-md-12 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Bedrijfsnaam<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Bedrijf"
                        [(ngModel)]="newAddress.company_name">
                    </div>
                    <div class="col-12 gutter-col-15 mb-4">
                      <div class="kpn-form-group">
                        <label class="field-label">Select Country <span class="text-danger"> * </span></label>
                        <ng-select class="tri-ng-select" placeholder="Select Country" [items]="country" bindLabel="name"
                          bindValue="country_code" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}"
                          (change)="onSelectCountry($event)">
                        </ng-select>
                      </div>
                    </div>
                    <div class="col-12 gutter-col-15 mb-4" *ngIf="selectedCountry && selectedCountry !== NL_CODE && selectedCountryObject?.searchable" >
                      <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                        <div class="form-group kpn-form-group">
                          <label class="field-label">Zoeken</label>
                          <div
                            class="kpn-form-group tri-autocomplete-form icon-right handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                            [ngClass]="{'search-active' : searchFocused}">
                            <i class="search-icon kpn-ui-icon ui-search"></i>
                            <div class="ng-autocomplete">
                              <ng-autocomplete id="auto" #auto class="" [data]="addressList"
                                [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                                [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                                (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                                (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                                [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                                [notFoundTemplate]="notFoundTemplate">
                              </ng-autocomplete>
                              <ng-template #itemTemplate let-item>
                                <div class="auto-template">
                                  <span [innerHTML]="item.value"></span> &nbsp;
                                  <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                                </div>
                              </ng-template>
                              <ng-template #notFoundTemplate let-notFound>
                                <div [innerHTML]="notFound"></div>
                              </ng-template>
                            </div>
                            <span class="text-muted" *ngIf="!foreignAddressSearchError">
                              <small> {{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                            </span>
                            <span class="danger-text" *ngIf="foreignAddressSearchError">
                              <small>{{foreignAddressSearchError}}</small>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 col-md-4 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Postcode<span
                          class="text-danger">*</span></label>
                      <input [disabled]="selectedCountry !== 'NL' && (selectedCountryObject?.searchable && !hasInputCustomAddressPermission)"   oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern" mask="111100"
                      [(ngModel)]="newAddress.zip_code"
                       (keyup)="onAddressSearch()" type="text" [patterns]="zipCodePattern" [mask]="selectedCountry === 'NL' ? '111100' :'AAAAAAAAAA'" class="form-control tri-form-control" placeholder="Postcode">
                      <div *ngIf="errors?.zip_code" class="text-danger">
                        {{ errors?.zip_code }}
                      </div>
                    </div>
                    <div class="mb-4 col-md-4 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Huisnr<span
                          class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Huisnummer" [disabled]="selectedCountry !== 'NL' && (selectedCountryObject?.searchable && !hasInputCustomAddressPermission)" name="h_number"
                      [(ngModel)]="newAddress.house_number"
                      class="form-control tri-form-control" (keyup)="onAddressSearch()"
                     >
                     <div *ngIf="errors?.house_number" class="text-danger">
                      {{ errors?.house_number }}
                    </div>
                    </div>
                    <div class="mb-4 col-md-4 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Toevoeging</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Toevoeging"   [(ngModel)]="newAddress.house_number_extension">
                      <div *ngIf="errors?.house_number_extension" class="text-danger">
                        {{ errors?.house_number_extension }}
                      </div>
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Straat</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Straat"  [(ngModel)]="newAddress.street"  [disabled]="selectedCountry === 'NL'  ||  (selectedCountry && addressFound.street && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)">
                      <div *ngIf="errors?.street" class="text-danger">
                        {{ errors?.street }}
                      </div>
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15">
                      <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Woonplaats</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Woonplaats"  [(ngModel)]="newAddress.city"  [disabled]="selectedCountry === 'NL'  ||  (selectedCountry && addressFound.city && selectedCountryObject?.searchable && !hasInputCustomAddressPermission)">
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15" *ngIf="hasInputCustomAddressPermission">
                      <div class="kpn-form-group">
                        <label for="info_1" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_1' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="newAddress.extra_address_information_1"
                            class="form-control tri-form-control">
                          </textarea>

                          <div *ngIf="errors?.extra_address_information_1" class="invalid-feedback">
                            {{ errors?.extra_address_information_1 }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="mb-4 col-md-12 gutter-col-15" *ngIf="hasInputCustomAddressPermission">
                      <div class="kpn-form-group">
                        <label for="info_2" class="field-label">{{'CUSTOMER.ADDRESS_INFORMATION_2' | transloco }} </label>
                        <div class="field-with-spinner">
                          <textarea  type="text"  name="info_1" rows="10" [maxLength]="128" placeholder="Adresgegevens 1"
                            [(ngModel)]="newAddress.extra_address_information_2"
                            class="form-control tri-form-control">
                          </textarea>

                          <div *ngIf="errors?.extra_address_information_2" class="invalid-feedback">
                            {{ errors?.extra_address_information_2 }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="note-box note-box-red mb-2  col-md-12 gutter-col-15" *ngIf="addressCombinationMsg">
                      <span class="circle-hint-icon">i</span>
                      <p class="body-text-1 mb-0">
                        {{addressCombinationMsg}}
                      </p>
                    </div>
                    <div class="col-md-12 gutter-col-15">
                      <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
                        name="multiselect-items">
                        <label ngbButtonLabel class="checkbox-item d-flex align-items-center mb-0">
                          <div class="checkbox-btn checkbox-btn-14 checkbox-btn-outline">
                            <input ngbButton [(ngModel)]="saveAsNewAddress" type="checkbox" class="position-absolute opacity-0">
                            <span class="checkBoxCheckmark checkBoxCheckmark-14"></span>
                          </div>
                          <div class="checkbox-label font-kpn-extended fs-16 ml-2 pl-1 w-100">
                            Sla op als nieuwe adres
                          </div>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="p-4">
                <div class="row gutter-row-8">
                  <div class="col-md-auto gutter-col-8">
                    <button *ngIf="!isCreatingAddress" (click)="openNewAddressCreateView()"
                      class="btn px-4 border-2 btn-outline-primary rounded-btn">
                      <span class="d-inline-block px-2"><span>Adres toevoegen</span> <i
                          class="kphicon-uniE985 align-middle fs-24 ml-1"></i></span>
                    </button>
                    <button *ngIf="isCreatingAddress"  (click)="closeNewAddressCreateView()"
                      class="btn px-4 border-2 btn-outline-primary rounded-btn">
                      <span class="d-inline-block px-2">Vorige</span>
                    </button>
                  </div>
                  <div class="col-md-auto gutter-col-8">
                    <button (click)="saveAsNewAddress ? createAddressAndUpdateQuote() : saveOneTimeAddressToQuote()" class="btn px-4 btn-primary rounded-btn" [disabled]="newAddressCreateDisabled" *ngIf="isCreatingAddress">
                      <span class="d-inline-block px-2">Opslaan</span>
                    </button>
                    <button
                      [disabled]="temporalSelectedAddressIdForUpdateQuoteShippingAddress === selectedQuoteShippingAddress?.id"
                      (click)="temporalSelectedAddressIdForUpdateQuoteShippingAddress ? updateQuoteShippingAddress(temporalSelectedAddressIdForUpdateQuoteShippingAddress) : saveOneTimeAddressToQuote()" class="btn px-4 btn-primary rounded-btn"
                      *ngIf="!isCreatingAddress">
                      <span d-inline-block px-2>Adres wijzigen</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
