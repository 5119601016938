import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from '../../storage.service';
@Component({
  selector: 'app-trade-in-steps',
  templateUrl: './trade-in-steps.component.html',
  styleUrls: ['./trade-in-steps.component.scss']
})
export class TradeInStepsComponent {

  @Input() activeStep = '';
  @Input() completeStepOne = false;
  @Input() completeStepTwo = false;
  @Input() completeStepThree = false;
  @Input() completeStepFour = false;

  private cart = null;
  private tradeInCart = null;
  private customer = null;
  private user = null;
  public  tradeInBusinessPartner = null;

  constructor(
    private router: Router,
    private storageService: StorageService
  ) {
    this.user = this.storageService.getCurrentUser() || null;
    this.cart = this.storageService.getCart() || null;
    this.storageService.getTradeInCart().subscribe(cart => {
      this.tradeInCart =  JSON.parse(cart);
    });
    this.customer = this.storageService.getCustomer();
  }

  ngOnInit() {
    this.setTradeInBusinessPartner();

  }

  setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
  }

  backToCart() {
    if (this.completeStepFour) {
      return;
    }
    this.router.navigate([`/trade-in/cart-details`]);
  }
  backToShippingMethod() {
    if (this.completeStepFour) {
      return;
    }
    this.router.navigate([`/trade-in/shipping-method`]);
  }

  backToCustomer() {
    if (this.completeStepFour) {
      return;
    }
    if (!this.completeStepThree) {
      return;
    }
      if (this.tradeInCart?.contact_uuid) {
        this.router.navigate([`trade-in/contact/${this.tradeInCart?.contact_uuid}`], {
          queryParams: {
            is_trade_in: true,
            contact_person: this.tradeInCart?.contact_person_uuid || null,
          },
          queryParamsHandling: 'merge'
        });
      } else {
        const customer = this.storageService?.getCustomer();
        const q_params = {
          is_trade_in: true,
          contact_person: this.user?.is_external ? this.user?.contact_person?.uuid : this.storageService.getContactPersonUUID() || null,
        };
        if (customer || this.user?.is_external) {
          if (customer) {
            this.router.navigate(['/trade-in/customers'], {
              queryParams: {
                company_name: customer?.company_name,
                coc_number: customer?.coc_number,
                customer_uuid: customer?.uuid,
                is_trade_in: true,
              },
              queryParamsHandling: 'merge'
            });
          } else {
            this.router.navigate([`trade-in/contact/${customer?.uuid || this?.user?.contact?.uuid}`], {
              queryParams: q_params,
              queryParamsHandling: 'merge'
            });
          }
        } else {
          this.router.navigate([`/trade-in/customers`], {
            queryParams: q_params,
            queryParamsHandling: 'merge'
          });
        }
      }


  }


  getShippingAddressById(id: number) {
    const cartAddress = this.customer?.contact_addresses?.find(address => {
      return address.id === id;
    }) || null;

    return cartAddress ? cartAddress?.uuid : null;
  }
}
