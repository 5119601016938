import { NgbDateParserFormatter, NgbDateStruct, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';
import { isNumber, toInteger, padNumber } from '../utilities/ngb.utility';

@Injectable({
  providedIn: 'root'
})
export class NgbDateCustomParserFormatter extends NgbDateParserFormatter {
  parse(value: string): NgbDate {
    if (value) {
      const dateParts = value.trim().split('-');
      return new NgbDate(toInteger(dateParts[0]), toInteger(dateParts[1]), toInteger(dateParts[2]));
    }
    return null;
  }

  format(date: NgbDateStruct): string {
    return date ?
      `${isNumber(date.day) ? padNumber(date.day) : ''}-${isNumber(date.month) ? padNumber(date.month) : ''}-${date.year}` :
      '';
  }

  formatDateToDB(date: NgbDateStruct): string {
    return date ? `${date.year}-${isNumber(date.month) ? padNumber(date.month) : ''}-${isNumber(date.day) ? padNumber(date.day) : ''}` : '';
  }

  formatDateString(value: string): string {
    if (value) {
      const dateParts = value.trim().split('-');
      return `${dateParts[2]}-${dateParts[1]}-${dateParts[0]}`;
    }
    return null;
  }

}
