<!-- <div class="bg-kpn-subtle-grey body-container">
    <section class="res-search-section res-search-section-calc pt-5">
        <div class="container">
            <div class="loader-wrapper box-shadow-sm" [ngClass]="{'spinner-wrapper-height': isLoading}">
                <div class="table-responsive custom-table">
                    <div class="p-3 d-flex justify-content-between">
                        <h3 class="title-3 green-text pt-3">{{'ORDER_OVERVIEW.ACCOUNT_TYPE' | transloco}} {{'EXPORT_PRODUCT.LIST' | transloco}} :</h3>
                        <button *ngIf="accountTypePermission.add" type="button"
                            class="btn btn-sm btn-info d-inline-flex align-items-center py-1"
                            (click)="onSetAccountType(setAccountTypeModal)">
                            <i class="kphicon-uniE9041 icon-size-sm mr-2"></i>
                            <span> {{'ACCOUNT_TYPE.ADD_ACCOUNT_TYPE' | transloco}}</span>
                        </button>
                    </div>
                    <div class="kpn-spinner" *ngIf="isLoading;else table_content">
                        <div class="text-center">
                            <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
                            <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
                        </div>
                    </div>
                    <ng-template #table_content>

                        <div *ngIf="accountTypes?.length">
                            <table class="table mb-5">
                                <thead>
                                    <tr>
                                        <th>{{'ACCOUNT_TYPE.ACCOUNT_NAME' | transloco}}</th>
                                        <th>Account Id</th>
                                        <th>Account Type Code</th>
                                        <th>Contact</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let campaign of accountTypes | paginate: config">
                                        <td>{{campaign.account_name|slice:0:80}}</td>
                                        <td>{{campaign.account_id}}</td>
                                        <td>
                                            {{campaign?.account_type_code}}
                                        </td>
                                        <td>
                                            {{campaign?.contact?.contact_name}}
                                        </td>
                                        <td class="text-right">
                                            <button *ngIf="accountTypePermission.edit"
                                                ngbTooltip="Klik om het accounttype te bewerken" type="button"
                                                (click)="onEditAccountType(campaign, editAccountTypeModal)"
                                                class="btn btn-sm btn-link nounderline">
                                                <i class="kphicon-uniE93A1 icon-size-md green-text"></i>
                                            </button>
                                            <button *ngIf="accountTypePermission.delete"
                                                ngbTooltip="Klik om het accounttype te verwijderen" type="button"
                                                (click)="deleteAccountType(campaign)"
                                                class="btn btn-sm btn-link nounderline">
                                                <i class="kphicon-uniE92A icon-size-md danger-text"></i>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="pagination-new">
                                <pagination-controls id="account_type_pagination" previousLabel="Vorige"
                                    nextLabel="Volgende" responsive="true" class="pagination"
                                    (pageChange)="onPageClick($event)"></pagination-controls>
                            </div>
                        </div>
                        <div class="row g-4 mt-4 p-3" *ngIf="!accountTypes?.length">
                            <div class="col-md-11">
                                <div class="alert alert-info" role="alert">
                                    <span> {{ 'SMS_CAMPAIGN.NO_SMS_CAMPAIGN_AVAIABLE' | transloco }}. </span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </section>

    <ng-template #setAccountTypeModal let-modal>
        <div class="modal-header">
            <h6 class="modal-title title-2 green-text" id="setAccountType" i18n="@@arm.order-status.order-details">
                {{ 'SMS_CAMPAIGN.SET_ACCOUNT_TYPE' | transloco }}
            </h6>
            <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row align-items-center p-2">
                <div class="col-md-12">
                    <div class="mb-3 p-4">
                        <form [formGroup]="accountTypeForm">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{'SMS_CAMPAIGN.ACCOUNT_TYPE_NAME' | transloco}}</label>
                                        <input type="text" class="form-control" formControlName="account_name">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>Account Type code <b class="danger-text">*</b></label>
                                        <select class="form-control" formControlName="account_type_code">
                                            <option *ngFor="let item of accountTypeCodes" [ngValue]="item">
                                                {{ item }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{ 'SMS_CAMPAIGN.ACCOUNT_TYPE_ID' | transloco}} <b class="danger-text">*</b> </label>
                                        <input type="text" class="form-control" formControlName="account_id">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{'SMS_CAMPAIGN.ACCOUNT_TYPE_SECRET' | transloco}}</label>
                                        <input type="text" class="form-control" formControlName="account_secret">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <button (click)="addAccountType()"
                                        [disabled]='addingAccountType || !accountTypeForm?.valid || !accountTypeForm?.dirty'
                                        class="btn btn-block btn-primary mt-2 rounded-btn">
                                        <div *ngIf="addingAccountType"
                                            class="spinner-border spinner-border-sm field-spinner mr-1" role="status">
                                        </div>
                                        <span>{{'RMA_CREATE.ADD' | transloco }}</span>
                                    </button>
                                </div>

                                <div class="col-md-12 col-12 col-gap-reduce pt-2" *ngIf="accountTypeAddError">
                                    <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                                        <p>
                                            <span class="kphicon-uniE952"></span>
                                            {{accountTypeAddError}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template #editAccountTypeModal let-modal>
        <div class="modal-header">
            <h6 class="modal-title title-2 green-text" id="editAccountTypeModal">
                {{'ACCOUNT_TYPE.EDIT_ACCOUNT_TYPE' | transloco}}
            </h6>
            <button type="button" class="btn close" aria-label="Close"
                (click)="modal.close(); selectedAccountType = null">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row align-items-center p-2">
                <div class="col-md-12">
                    <div class="mb-3 p-4">
                        <form [formGroup]="accountTypeEditForm">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{'SMS_CAMPAIGN.ACCOUNT_TYPE_NAME' | transloco}}</label>
                                        <input type="text" class="form-control" formControlName="account_name">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>Account Type code <b class="danger-text">*</b></label>
                                        <select class="form-control" formControlName="account_type_code">
                                            <option *ngFor="let item of accountTypeCodes" [ngValue]="item">
                                                {{ item }}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{ 'SMS_CAMPAIGN.ACCOUNT_TYPE_ID' | transloco}} <b class="danger-text">*</b> </label>
                                        <input type="text" class="form-control" formControlName="account_id">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{'SMS_CAMPAIGN.ACCOUNT_TYPE_SECRET' | transloco}}</label>
                                        <input type="text" class="form-control" formControlName="account_secret">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <button (click)="updateAccountType()"
                                        [disabled]='addingAccountType || !accountTypeEditForm?.valid'
                                        class="btn btn-block btn-primary mt-2 rounded-btn">
                                        <div *ngIf="addingAccountType"
                                            class="spinner-border spinner-border-sm field-spinner mr-1" role="status">
                                        </div>
                                        <span>{{'ACCOUNT_TYPE.EDITING' | transloco}}</span>
                                    </button>
                                </div>

                                <div class="col-md-12 col-12 col-gap-reduce pt-2" *ngIf="accountTypeAddError">
                                    <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                                        <p>
                                            <span class="kphicon-uniE952"></span>
                                            {{accountTypeAddError}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div> -->


<div class="container">
    <div class="pt-3">
        <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">  
            <li><a routerLink="/">Home</a></li>
            <li><a >Enrollment</a></li>
        </ul> 
    </div>
    <div class="loader-wrapper" [ngClass]="{'spinner-wrapper-height': isLoading}">
        <div class="kpn-spinner" *ngIf="isLoading;else enrollment_content">
            <div class="text-center">
                <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
                <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
            </div>
        </div>
    </div>
    <ng-template #enrollment_content>
        <div class="" >
            <h3 class="tri-title-40 font-kpn-black green-text mb-3">Enrollment</h3>
            <div class="tri-shadow-xl bg-white tri-rounded-20 p-24 pb-0 mb-4" *ngIf="!hasViewContactAccountTypeCustomer">
                <div class="row align-items-center justify-content-between mb-3">
                    <div class="col-md-auto d-flex align-items-center">
                        <h4 class="tri-title-32 font-kpn-extended kpn-extended-black green-text d-inline-block letter-spacing-normal">Bekijk en beheer je enrollment 
                        </h4>
                        <div class="tooltip-wrapper align-middle mb-1 ml-1">
                            <i class="kphicon-uniE952 align-middle fs-26 blue-text cursor-pointer"></i>
                            <span class="placement-bottom font-weight-normal custom-tooltip-content font-kpn-extended w-343">
                                Voeg enrollment toe met de enrollment ID. Slechts één ABM ID en één KNOX ID per account mogelijk.
                            </span>
                        </div>
                    </div>
                    <div class="col-md-auto">
                        <p class="m-0 font-kpn-extended">Heb je vragen? Bekijk onze <a routerLink="/faq" class="blue-link font-kpn-extended">FAQ-pagina</a>.</p>
                    </div>
                </div>
                <div class="row gutter-row-24">
                    <div class="col-md-6 gutter-col-24" *ngFor="let item of accountTypes">
                        <div class="border bg-grey-nr border-0 mb-4 pb-5 nr-border-color tri-rounded-20 min-h-205 p-24 position-relative">
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="font-kpn-extended kpn-extended-bold letter-spacing-normal"  *ngIf="item?.account_type_code === eAccountTypes.ABM">Apple Business Manager</span>
                                <span class="font-kpn-extended kpn-extended-bold letter-spacing-normal"  *ngIf="item?.account_type_code === eAccountTypes.KNOX">Samsung Knox Mobile Enrollment</span>
                                <span class="font-kpn-extended text-white enr-tag-btn blue-bg d-inline-block">Geactiveerd</span>
                            </div>
                            <div class="d-flex grey-text pt-5 pb-1 font-kpn-extended align-items-center justify-content-between">
                                <span class="kpn-extended-bold letter-spacing-normal">Enrollment ID</span>
                                <span>{{item?.account_id}}</span>
                            </div>
                            <hr class="mt-2 nr-border-color">
                            <div class="position-absolute left-0 bottom-0 w-100 p-24">
                                    <button type="button" (click)="editEnrollment(enrollmentEditModal, item)" class="blue-link border-0 hover-underline  p-0 m-0 font-kpn-extended d-inline-flex align-items-center">Wijzigen <i class="kphicon-uniE931 pl-1"></i></button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 gutter-col-24" *ngIf="!availableType.KNOX">
                        <div class="border mb-4 enr-pb-70 pb-5 nr-border-color tri-rounded-20 min-h-205 p-24 position-relative">
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Samsung Knox Mobile Enrollment</span>
                                <span class="font-kpn-extended grey-text enr-tag-btn d-inline-block">Niet actief</span>
                            </div>
                            <div class="position-absolute left-0 bottom-0 w-100 p-24">
                                <button 
                                    (click)="createEnrollment(enrollmentModal, eAccountTypes.KNOX)"
                                    class="btn px-4 btn-h-40 btn-primary rounded-btn">
                                    <span class="d-inline-block px-2">Voeg enrollment toe <i class="kphicon-uniE985 fs-24 align-middle"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-6 gutter-col-24" *ngIf="!availableType.ABM">
                        <div class="border mb-4 enr-pb-70 pb-5 nr-border-color tri-rounded-20 min-h-205 p-24 position-relative">
                            <div class="d-flex align-items-center justify-content-between">
                                <span class="font-kpn-extended kpn-extended-bold letter-spacing-normal">Apple Business Manager</span>
                                <span class="font-kpn-extended grey-text enr-tag-btn d-inline-block">Niet actief</span>
                            </div>
                            <div class="position-absolute left-0 bottom-0 w-100 p-24">
                                <button 
                                    (click)="createEnrollment(enrollmentModal, eAccountTypes.ABM)"
                                    class="btn px-4 btn-h-40 btn-primary rounded-btn">
                                    <span class="d-inline-block px-2">Voeg enrollment toe <i class="kphicon-uniE985 fs-24 align-middle"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <ng-template #enrollmentModal let-modal>
                        <div class="px-4">
                            <div class="modal-header px-0 pt-4 tri-modal-header">
                                <h6 class="tri-title-40 letter-spacing-normal mb-0 green-text font-kpn-extended kpn-extended-black" id="enrollmentModal">
                                    Voeg enrollment toe 
                                </h6>
                                <button type="button" (click)="modal.close(); createAccountType= ''" class="btn close" aria-label="Close">
                                    <span class="kphicon-uniE926"></span>
                                </button>
                            </div>
                        </div>
    
                        <div class="modal-body p-0">
                            <div class="p-24">
                                <form [formGroup]="accountTypeForm">
                                    <h4 *ngIf="createAccountType === eAccountTypes.ABM" class="font-kpn-extended fs-16 mb-2 kpn-extended-bold letter-spacing-normal">Apple Business Manager Enrollment ID</h4>
                                    <h4 *ngIf="createAccountType === eAccountTypes.KNOX" class="font-kpn-extended fs-16 mb-2 kpn-extended-bold letter-spacing-normal">Samsung Knox Mobile Enrollment ID</h4>
                                    <div class="mb-2">
                                        <input type="text" formControlName="account_id" class="form-control tri-form-control invalid-bg-size-23" [ngClass]="{ 'is-invalid': accountTypeForm?.get('account_id')?.hasError('required') && accountTypeForm?.get('account_id').touched}" placeholder="Enrollment ID">
                                        <span *ngIf="accountTypeForm?.get('account_id')?.hasError('required') && accountTypeForm?.get('account_id').touched"  class="feedback-message text-danger">Voeg een enrollment ID toe</span>
                                    </div>
                                    <div class="col-md-12 col-12 col-gap-reduce pt-2" *ngIf="accountTypeAddError">
                                        <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                                            <p>
                                                <span class="kphicon-uniE952"></span>
                                                {{accountTypeAddError}}
                                            </p>
                                        </div>
                                    </div>
                                    <div class="pt-2 row gutter-row-15"> 
                                        <div class="col-md-6 gutter-col-15">
                                            <button type="button" (click)="modal.close(); createAccountType=''"
                                                class="btn btn-block btn-outline-primary border-2 mt-2 rounded-btn">
                                                <span>Annuleren</span>
                                            </button>
                                        </div>
                                        <div class="col-md-6 gutter-col-15">
                                            <button type="submit" (click)="addAccountType()"[disabled]='addingAccountType'
                                                class="btn btn-block btn-primary mt-2 rounded-btn">
                                                <div *ngIf="addingAccountType"
                                                    class="spinner-border spinner-border-sm field-spinner mr-1" role="status">
                                                </div>
                                                <span>Toevoegen</span>
                                            </button>
                                        </div>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </ng-template>
    
                    <ng-template #enrollmentEditModal let-modal>
                        <div class="px-4">
                            <div class="modal-header px-0 pt-4 tri-modal-header">
                                <h6 class="tri-title-40 letter-spacing-normal mb-0 green-text font-kpn-extended kpn-extended-black" id="enrollmentModal">
                                    Wijzig enrollment 
                                </h6>
                                <button type="button" (click)="modal.close(); selectedAccountType = null" class="btn close" aria-label="Close">
                                    <span class="kphicon-uniE926"></span>
                                </button>
                            </div>
                        </div>
    
                        <div class="modal-body p-0">
                            <div class="p-24">
                                <form [formGroup]="accountTypeEditForm">
                                    <h4 *ngIf="selectedAccountType?.account_type_code === eAccountTypes.ABM" class="font-kpn-extended fs-16 mb-2 kpn-extended-bold letter-spacing-normal">Apple Business Manager Enrollment ID</h4>
                                    <h4 *ngIf="selectedAccountType?.account_type_code === eAccountTypes.KNOX " class="font-kpn-extended fs-16 mb-2 kpn-extended-bold letter-spacing-normal">Samsung Knox Mobile Enrollment ID</h4>
                                    <div class="mb-2">
                                        <input type="text" formControlName="account_id" class="form-control tri-form-control invalid-bg-size-23"  [ngClass]="{ 'is-invalid': (accountTypeEditForm?.get('account_id')?.hasError('required') && accountTypeEditForm?.get('account_id').touched)}" placeholder="Enrollment ID">
                                        <span *ngIf="accountTypeEditForm?.get('account_id')?.hasError('required') && accountTypeEditForm?.get('account_id').touched"  class="feedback-message text-danger">Voeg een enrollment ID toe</span>
                                    </div>
                                    <div class="pt-2 row gutter-row-15"> 
                                        <div class="col-md-6 gutter-col-15">
                                            <button type="button" disabled (click)="modal.close(); selectedAccountType = null"
                                                class="btn btn-block btn-outline-danger border-2 red-outline-disabled border-2 mt-2 rounded-btn">
                                                <span class="dlt-btn-text-color">Verwijderen</span>
                                            </button>
                                        </div>
                                        <div class="col-md-6 gutter-col-15">
                                            <button (click)="updateAccountType()"
                                            [disabled]="selectedAccountType?.account_id === accountTypeEditForm?.get('account_id')?.value ||  addingAccountType || !accountTypeEditForm?.valid"
                                            class="btn btn-block btn-primary mt-2 rounded-btn">
                                            <div *ngIf="addingAccountType"
                                                class="spinner-border spinner-border-sm field-spinner mr-1" role="status">
                                            </div>
                                            <span>Enrollment ID wijzigen</span>
                                        </button>
                                        </div>
                                    </div>
                                </form> 
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="hasViewContactAccountTypeCustomer" class="tri-shadow-xl bg-white tri-rounded-20 p-24  mb-4">
                <div class="row align-items-center justify-content-between mb-3">
                    <div class="col-md-auto">
                        <h4 class="tri-title-32 font-kpn-extended kpn-extended-black green-text letter-spacing-normal">Enrollment overzicht</h4>
                    </div> 
                </div> 
    
                <div class="expandable-list-items">
                    <div class="row m-0 p-0 ex expandable-list-header">
                        <div class="col-4 m-0 px-4 head-cell">
                            <button class="font-kpn-extended kpn-extended-bold letter-spacing-normal active px-0">Klant
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="UI Icon">
                                        <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6.31067 9.28994C6.02693 9.5775 5.94408 10.0075 6.10067 10.3799C6.25408 10.7534 6.61697 10.9979 7.02067 10.9999H17.0207C17.4244 10.9979 17.7873 10.7534 17.9407 10.3799C18.0973 10.0075 18.0144 9.5775 17.7307 9.28994L12.7307 4.28994C12.5429 4.10063 12.2873 3.99414 12.0207 3.99414C11.754 3.99414 11.4984 4.10063 11.3107 4.28994L6.31067 9.28994ZM6.10067 13.6199C6.25408 13.2465 6.61697 13.002 7.02067 12.9999H17.0207C17.4244 13.002 17.7873 13.2465 17.9407 13.6199C18.0973 13.9923 18.0144 14.4224 17.7307 14.7099L12.7307 19.7099C12.5429 19.8993 12.2873 20.0057 12.0207 20.0057C11.754 20.0057 11.4984 19.8993 11.3107 19.7099L6.31067 14.7099C6.02693 14.4224 5.94408 13.9923 6.10067 13.6199Z"
                                            />
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div class="col-4 m-0 px-4 head-cell">
                            <button class="font-kpn-extended kpn-extended-bold letter-spacing-normal px-0">Enrollment type
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="UI Icon">
                                        <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6.31067 9.28994C6.02693 9.5775 5.94408 10.0075 6.10067 10.3799C6.25408 10.7534 6.61697 10.9979 7.02067 10.9999H17.0207C17.4244 10.9979 17.7873 10.7534 17.9407 10.3799C18.0973 10.0075 18.0144 9.5775 17.7307 9.28994L12.7307 4.28994C12.5429 4.10063 12.2873 3.99414 12.0207 3.99414C11.754 3.99414 11.4984 4.10063 11.3107 4.28994L6.31067 9.28994ZM6.10067 13.6199C6.25408 13.2465 6.61697 13.002 7.02067 12.9999H17.0207C17.4244 13.002 17.7873 13.2465 17.9407 13.6199C18.0973 13.9923 18.0144 14.4224 17.7307 14.7099L12.7307 19.7099C12.5429 19.8993 12.2873 20.0057 12.0207 20.0057C11.754 20.0057 11.4984 19.8993 11.3107 19.7099L6.31067 14.7099C6.02693 14.4224 5.94408 13.9923 6.10067 13.6199Z"
                                            />
                                    </g>
                                </svg>
                            </button>
                        </div>
                        <div class="col-4 m-0 px-4 head-cell">
                            <button class="font-kpn-extended kpn-extended-bold letter-spacing-normal px-0">Enrollment ID
                                <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                    xmlns="http://www.w3.org/2000/svg">
                                    <g id="UI Icon">
                                        <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                            d="M6.31067 9.28994C6.02693 9.5775 5.94408 10.0075 6.10067 10.3799C6.25408 10.7534 6.61697 10.9979 7.02067 10.9999H17.0207C17.4244 10.9979 17.7873 10.7534 17.9407 10.3799C18.0973 10.0075 18.0144 9.5775 17.7307 9.28994L12.7307 4.28994C12.5429 4.10063 12.2873 3.99414 12.0207 3.99414C11.754 3.99414 11.4984 4.10063 11.3107 4.28994L6.31067 9.28994ZM6.10067 13.6199C6.25408 13.2465 6.61697 13.002 7.02067 12.9999H17.0207C17.4244 13.002 17.7873 13.2465 17.9407 13.6199C18.0973 13.9923 18.0144 14.4224 17.7307 14.7099L12.7307 19.7099C12.5429 19.8993 12.2873 20.0057 12.0207 20.0057C11.754 20.0057 11.4984 19.8993 11.3107 19.7099L6.31067 14.7099C6.02693 14.4224 5.94408 13.9923 6.10067 13.6199Z"
                                            />
                                    </g>
                                </svg>
                            </button>
                        </div>
                    </div> 
                    <div class="subline-table-list">
                        <ngb-accordion #acc="ngbAccordion" activeIds="ngb-panel-0">
                            <ngb-panel *ngFor="let item of accountTypeCustomerList; let i = index" [id]="'ngb-panel-' + i" [disabled]="item?.account_types?.length ===1">
                              <ng-template ngbPanelTitle>
                                <div class="expandable-list-row-1">
                                    <div class="row bg-white m-0 p-0 w-100"> 
                                        <div class="col-4 td-cell m-0 px-4 text-left">
                                            <span>{{ item.name }}</span>
                                        </div>
                                        <div class="col-4 td-cell m-0 px-4 text-left">
                                            
                                            <span *ngIf="item?.account_types?.length ===1">{{item?.account_types[0]?.account_type_code}}</span>
                                            <span *ngIf="item?.account_types?.length >1">Meerdere enrollments</span>
                                        </div>
                                        <div class="col-4 td-cell m-0 px-4 text-left">
                                            <span *ngIf="item?.account_types?.length ===1">{{item?.account_types[0]?.account_id}}</span>
                                                &nbsp;
                                        </div> 
                                        <i *ngIf="item?.account_types?.length > 1"  class="ac-arrow">
                                            <img src="../../../assets/images/chevron-down.svg" alt="">
                                        </i>

                                    </div>
                                </div>
                              </ng-template>
                              <ng-template ngbPanelContent>
                                <div class="expandable-list-row-2" *ngIf="item?.account_types?.length > 1">
                                    <div class="row m-0 p-0 sub-items" *ngFor="let type of item?.account_types">
                                        <div class="col-4 td-cell m-0 px-4">
                                            <span>{{type?.account_name}}</span>
                                        </div>
                                        <div class="col-4 td-cell m-0 px-4">
                                            <span>{{type?.account_type_code}}</span>
                                        </div>
                                        <div class="col-4 td-cell m-0 px-4">
                                            <span>{{type?.account_id}}</span>
                                        </div> 
                                    </div> 
                                </div>
                                </ng-template>
                            </ngb-panel>
                        </ngb-accordion>
                    </div>
                </div> 
            </div>
        </div>
    </ng-template>
</div> 