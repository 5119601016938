import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ProductListService {
  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  getProducts(
    catalogCode: string,
    productGroupCode: string,
    page: number = 1,
    pageSize: number,
    contactUuid: string,
    filterQueryString?: string,
    searchedProductKeyword?: string,
    global: boolean = false
  ) {
    let paramsString = `page=${page}&page_size=${pageSize}`;
    paramsString = paramsString.concat(productGroupCode ? `&product_group=${productGroupCode}` : '');
    paramsString = paramsString.concat(filterQueryString ? filterQueryString : '');
    paramsString = paramsString.concat(contactUuid ? `&contact_uuid=${contactUuid}` : '');
    paramsString = paramsString.concat(searchedProductKeyword ? `&keyword=${searchedProductKeyword}` : `&parent_product=True`);
    let productListURI = `search/catalogs/${catalogCode}/products`;
    if (global) {
      paramsString = `page=${page}&page_size=${pageSize}`;
      paramsString = paramsString.concat(searchedProductKeyword ? `&keyword=${searchedProductKeyword}` : '');
      productListURI = `search/products/list`;
    }
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(productListURI, params);
  }

  getDynamicSearchItems(catalogCode: string, productGroupCode: string) {
    const URI = `search/catalogs/${catalogCode}/product-groups/${productGroupCode}/filter-options`;
    return this.apiBaseService.get(URI);
  }


  getProductGroup(productGroupCode: string = 'base') {
    const URI = `products/types/${productGroupCode}/groups`;
    return this.apiBaseService.get(URI);
  }

}
