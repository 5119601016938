



<div *ngIf='productType'>
  <!-- <app-product-details-handset *ngIf='productType === "handset";else no_handset' [product]="product"></app-product-details-handset> -->
  <app-product-details-handset [product]="product"></app-product-details-handset>
  <!-- <ng-template #no_handset>
    <app-product-details-no-handset [product]="product"></app-product-details-no-handset>
  </ng-template> -->
</div>

<div class="section-loader" *ngIf="loader">
  <div class="kpn-spinner">
    <img src="/assets/images/kpn-loader.gif" alt="Loading">
  </div>
</div>

<div class="row g-4 mt-4" *ngIf="!loader && !product">
  <div class="col-md-10 offset-1">
    <div class="alert alert-info" role="alert">
      <h6>product niet gevonden</h6>
    </div>
  </div>
</div>