import { Component, OnInit } from '@angular/core';
import { AddressService } from 'src/app/shared/services/address.service';

@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent implements OnInit {

  faqItems = [];
  loading = false;
  constructor(private addressService: AddressService) {

  }

  ngOnInit(): void {
    this.getFaq();
  }


  getFaq(page = 1) {
    this.loading = true;
    this.addressService.getFAQ(`page=${page}`).subscribe(res => {
      this.loading = false;
      this.faqItems = res?.data?.results;
    }, error => {
      this.loading = false;
    })
  }

}
