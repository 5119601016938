<section class="product-details-new">
  <div *ngIf="showLoding" class="full-screen-loader" id="fullScreenLoader">
    <div class="loader">
        <div class="lds-spinner">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>
  </div>
  <div class="container">
    <!-- Breadcrumb -->
    <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-5 pb-2">
      <li class="back-breadcrumb-btn">
        <a class="cursor-pointer" (click)="back()">
          <i class="kphicon-uniE906"></i> Terug
        </a>
      </li>
      <li class="home-btn ml-2 px-1 pb-1">
        <a routerLink="/">
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="UI Icon">
              <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                fill="#939393" />
            </g>
          </svg>
        </a>
      </li>
      <li  role="button" *ngIf="catalogCode"> <a (click)="gotoGroupCode()" class="grey-text" >{{ catalogCode | titlecase }} </a></li>
      <li  role="button" *ngIf="group"> <a (click)="gotoGroupCode()" class="grey-text" >{{ group?.name }} </a></li>
      <li > <a> {{  currentHandset?.name }}</a></li>
    </ul>

    <div class="row">
      <div class="col-md pr-md-4">
        <div class="pr-md-3">
          <!-- <h2 class="green-text fs-35 font-kpn-bold mb-1">Lenovo ThinkPad P15v Gen 3 21D8</h2>
          <p class="fs-26 font-kpn-extended kpn-font-color">16 GB DDR5 (1 x 16 GB) - Zwart</p> -->
          <h2 class="green-text fs-39 font-kpn-extended kpn-extended-bold mb-1">{{ currentHandset?.name }}</h2>
          <p class="fs-26 font-kpn-extended kpn-font-color">{{ currentHandset?.memory_size?.attribute_value }} {{ (currentHandset?.memory_size?.attribute_value && currentHandset?.color?.attribute_value) ? '-' : ''  }} {{ currentHandset?.color?.attribute_value }}</p>
          <div class="pdp-img-gallery mb-0 pt-3">
            <div class="row">
              <div class="col-sm">
                <div class="pdp-large-img">
                  <!-- <img src="../../../assets/images/lenevo-laptop.png" alt=""> -->
                  <img [src]="productImg[selectedProductImageIndex]?.images" alt="">
                </div>
              </div>
              <div class="col-sm-auto">
                <div class="pdp-thumb-img custom-scrollar">
                  <!-- <div class="pdp-thumb-item active">
                    <img src="../../../assets/images/lenevo-laptop.png" alt="">
                  </div>
                  <div class="pdp-thumb-item">
                    <img src="../../../assets/images/lenevo-laptop-2.png" alt="">
                  </div>
                  <div class="pdp-thumb-item">
                    <img src="../../../assets/images/lenevo-laptop-3.png" alt="">
                  </div> -->
                  <div class="pdp-thumb-item" (click)="selectedProductImageIndex=i" [ngClass]="{'active': (i === selectedProductImageIndex)}"  *ngFor="let item of productImg;index as i">
                    <img [src]="item?.images" alt="">
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="" *ngIf="siblingProducts?.length">
            <h3 class="fs-26 green-text font-kpn-extended kpn-extended-black mb-1">{{'PRODUCT_DETAILS.SIBLING_PRODUCTS' | transloco }}</h3>
            <hr />
            <div class="product-section-new px-3 scroll-able-3row">
              <div class="row">
                <div class="col-md-4" *ngFor="let product of siblingProducts; let i = index">
                  <div class="product-box-new related-product-right">
                    <div class="product-img-new">
                      <a (click)="onProductDetail(product, i)" class="d-flex justify-content-center">
                        <span *ngIf="product?.image; else noImg">
                          <img [src]="product?.image" [alt]="product?.name" />
                        </span>
                        <ng-template #noImg>
                          <img src="/assets/images/image-not-available.png" [alt]="product?.name" />
                        </ng-template>
                      </a>
                    </div>
                    <div class="product-new-content product-new-indetails">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="item-att" (click)="onProductDetail(product, i)" [title]="product?.name">
                            <small>
                              <b>{{ product?.code }} -
                                {{ product?.name | textTrim : "40" }}
                              </b></small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

            <div class="row align-items-center p-2"  *ngIf="selectedProduct?.parent_specification">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    <small> {{selectedProduct?.parent_specification}} </small>
                  </p>
                </div>
              </div>
            </div>

            <div class="row align-items-center p-2" *ngIf="selectedProduct?.pre_order">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-yellow mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{'PRODUCT_DETAILS.THIS_PRODUCT_IS_A_PRE_ORDER_PRODUCT_AND_AVAILABLE_FROM' | transloco}}
                    <small *ngIf="selectedProduct?.release_date"> <b> {{selectedProduct?.release_date |
                        date:'d-MM-yyyy'}}</b> </small>
                  </p>
                </div>
              </div>
            </div>


          <!-- <div class="pt-5 mb-3">
            <div class="shadow-kpnew-md tri-rounded-20 p-26">
              <h4 class="fs-28 font-kpn-bold green-text">Dit krijg je erbij</h4>
              <hr class="mb-2">
              <p class="font-kpn-extended mb-1">Bij aankoop van een Lenovo ThinkPad P15v Gen 3 21D8 kan je een van deze producten gratis toevoegen.</p>

              <div class="row gutter-row-20">
                <div class="col-md-6 gutter-col-20 mt-3">
                  <div class="offer-card">
                    <input type="radio" id="offer-item-1" checked name="offer-product">
                    <label class="offer-item-label selected" for="offer-item-1">
                      <h4 class="font-kpn-bold fs-16 line-height-24">iPhone 15 Pro Max 5G 256GB Black Titanium</h4>
                      <p class="font-kpn-extended green-text mb-0">100% korting</p>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <span class="gray-text fs-16 line-through mr-2">299,00</span>
                          <span class="green-text fs-24 font-kpn-bold">Gratis</span>
                        </div>
                        <div>
                          <div class="custom-radio-bullet">
                            <label><span class="radio-bullet"></span></label>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
                <div class="col-md-6 gutter-col-20 mt-3">
                  <div class="offer-card">
                    <input type="radio" id="offer-item-2" name="offer-product">
                    <label class="offer-item-label selected" for="offer-item-2">
                      <h4 class="font-kpn-bold fs-16 line-height-24">APPLE USB-C-NAAR-LIGHTNING-KABEL</h4>
                      <p class="font-kpn-extended green-text mb-0">100% korting</p>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <span class="gray-text fs-16 line-through mr-2">299,00</span>
                          <span class="green-text fs-24 font-kpn-bold">Gratis</span>
                        </div>
                        <div>
                          <div class="custom-radio-bullet">
                            <label><span class="radio-bullet"></span></label>
                          </div>
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div> -->

          <div class="pt-5 mb-3" *ngIf="promotionalProducts?.length">
            <div class="">


              <div *ngFor="let promotion of promotionalProducts; let i = index" class="shadow-kpnew-md tri-rounded-20 p-26 mt-4">

                <div class="row">
                  <div class="col-md-12">
                    <h4 class="fs-28 font-kpn-extended kpn-extended-bold green-text">{{ promotion?.max_promotional_products === 1 ? 'Dit krijg je erbij' : 'Combivoordeel' }} </h4>
                    <hr class="mb-2">
                    <p class="font-kpn-extended mb-1" [innerHTML]="promotion?.description"></p>
                    <!-- <span><b [innerHTML]="promotion?.description"> </b></span> -->
                  </div>
                </div>

                <div class="row gutter-row-20">
                  <div class="col-md-6 gutter-col-20 mt-3"  *ngFor="let product of promotion?.mandatory_products;let index = index">
                    <div class="offer-card h-100"  *ngIf="promotion?.max_promotional_products === 1">

                      <input type="radio" [checked]="product.selected == true" [value]="product.selected" (click)="onChangeMaxOnePromotionalMandatoryProduct((!product.selected), product, promotion)" [id]="index+'promotion-mandatory'"  [name]="promotion.code+'promotion-offer-product'">
                      <label class="offer-item-label selected h-100" [for]="index+'promotion-mandatory'">
                        <h4 class="font-kpn-bold fs-16 line-height-24">{{product?.name}}</h4>
                        <p class="font-kpn-extended green-text mb-0" *ngIf="promotion?.discount_type === 'percentage'">{{ promotion?.discount_percentage }}% korting</p>
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            {{utilityService.isInclVAT()}}
                            <span class="gray-text fs-16 line-through mr-2" *ngIf="parsePrice( utilityService.isInclVAT() ? product?.price?.previous_price: product?.price?.previous_price_ex_vat) >= 0" >
                              {{(utilityService.isInclVAT() ? product?.price?.previous_price : product?.price?.previous_price_ex_vat )|currencyFormat }}
                            </span>
                            <span class="green-text fs-24 font-kpn-bold"  *ngIf="parsePrice(utilityService.isInclVAT() ?  product?.price?.price :  product?.price?.price_ex_vat) >=0">
                              {{(utilityService.isInclVAT() ? product?.price?.price : product?.price?.price_ex_vat )| currencyFormat }}
                            </span>
                            <!-- <span class="green-text fs-24 font-kpn-bold">Gratis</span> -->
                          </div>
                          <div>
                            <div class="custom-radio-bullet">
                              <label><span class="radio-bullet"></span></label>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div *ngIf="promotion?.max_promotional_products != 1" class="offer-product-item h-100" [class]="product.selected ? 'selected' :''" >
                      <div class="offer-product-item-img" *ngIf="product?.image?.length;">
                        <span >
                          <img [src]="product?.image" [alt]="product?.name">
                        </span>

                        <!-- <img src="../../../assets/images/product-02.png" alt=""> -->
                      </div>
                      <div class="offer-product-item-content h-100">
                        <h4 class="font-kpn-bold fs-16 line-height-24">{{product?.name}}</h4>
                        <p class="font-kpn-extended green-text mb-0" *ngIf="promotion?.discount_type === 'percentage'">{{ promotion?.discount_percentage }}% korting</p>
                        <div class="offer-pr-btn d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <span class="grey-text fs-16 line-through mr-2" *ngIf="parsePrice(utilityService.isInclVAT() ?  product?.price?.previous_price :  product?.price?.previous_price_ex_vat) >= 0" >
                              {{( utilityService.isInclVAT() ?  product?.price?.previous_price : product?.price?.previous_price_ex_vat) |currencyFormat }}
                            </span>
                            <span class="green-text font-kpn-extended kpn-extended-bold fs-28"  *ngIf="parsePrice(utilityService.isInclVAT() ? product?.price?.price : product?.price?.price_ex_vat) >=0">
                              {{(utilityService.isInclVAT() ? product?.price?.price :  product?.price?.price_ex_vat ) | currencyFormat }}
                            </span>
                          </div>
                          <div>
                            <button (click)="onChangePromotionalMandatoryProduct((!product.selected), product, promotion)" class="btn btn-block d-inline-flex text-white align-items-center btn-primary-dark-2 rounded-btn px-3">
                              <span *ngIf="product?.selected" class="kphicon-uniE972"></span>
                              <span *ngIf="!product?.selected" class="kphicon-uniE985"></span>
                              <img *ngIf="product?.selected" src="../../../assets/images/cart-dark-blue.svg" alt="">
                              <!-- <img src="../../../assets/images/cart-dark-blue.svg" alt=""> -->
                              <img *ngIf="!product?.selected" src="../../../assets/images/cart-white.svg" alt="">
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-20 mt-3"  *ngFor="let product of promotion?.optional_products;let index = index">
                    <div class="offer-card offer-card-radio-b h-100" *ngIf="promotion?.max_promotional_products === 1">
                      <input type="radio" [checked]="product?.selected === true" [value]="product?.selected"  (click)="onChangeMaxOnePromotionalOptionalProduct((!product.selected), product?.product, promotion)" [id]="index+'promotion-optional'"  [name]="promotion.code+'promotion-offer-product'">
                      <label class="offer-item-label d-block selected h-100" [for]="index+'promotion-optional'">
                        <h4 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-17 line-height-24">{{product?.product?.name}}</h4>
                        <p class="font-kpn-extended green-text mb-0" *ngIf="promotion?.discount_type === 'percentage'">{{ promotion?.discount_percentage }}% korting</p>
                        <div class="promotin-price-radio d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <span class="grey-text fs-16 line-through mr-2" *ngIf="parsePrice(utilityService.isInclVAT() ? product?.product?.price?.previous_price : product?.product?.price?.previous_price_ex_vat) > 0" >
                              {{(utilityService.isInclVAT() ? product?.product?.price?.previous_price : product?.product?.price?.previous_price_ex_vat) |currencyFormat }}
                            </span>
                            <span class="green-text fs-24 font-kpn-bold" *ngIf="parsePrice(utilityService.isInclVAT() ? product?.product?.price?.price :  product?.product?.price?.price_ex_vat) >0">
                              {{( utilityService.isInclVAT() ? product?.product?.price?.price  : product?.product?.price?.price_ex_vat ) |currencyFormat }}
                            </span>
                            <h5 class="green-text font-kpn-extended kpn-extended-bold fs-28 mb-0 pb-0" *ngIf="promotion?.discount_percentage == 100">
                              Gratis
                            </h5>
                          </div>
                          <div>
                            <div class="custom-radio-bullet"  (click)="onChangeMaxOnePromotionalOptionalProduct((!product.selected), product?.product, promotion)" [id]="index+'promotion-optional'">
                              <label style="cursor: pointer;"><span class="radio-bullet"  ></span></label>
                            </div>
                          </div>
                        </div>
                      </label>
                    </div>
                    <div  *ngIf="promotion?.max_promotional_products != 1" class="offer-product-item h-100" [class]="product?.selected ? 'selected' :''">
                      <div class="offer-product-item-img" *ngIf="product?.product?.image?.length;">
                        <span >
                          <img [src]="product?.product?.image" [alt]="product?.product?.name">
                        </span>

                      </div>
                      <div class="offer-product-item-content">
                        <h4 class="font-kpn-bold fs-16 line-height-24">{{product?.product?.name}} {{ product?.selected }}</h4>
                        <p class="font-kpn-extended green-text mb-0" *ngIf="product?.discount_type === 'percentage'">{{ product?.discount_percentage }}% korting</p>
                        <!-- <p class="font-kpn-extended green-text mb-0">30% korting</p> -->
                        <div class="d-flex align-items-center justify-content-between">
                          <div class="d-flex align-items-center">
                            <span class="grey-text fs-16 line-through mr-2" *ngIf="parsePrice(utilityService.isInclVAT() ? product?.product?.price?.previous_price :  product?.product?.price?.previous_price_ex_vat) >= 0" >
                              {{(utilityService.isInclVAT() ? product?.product?.price?.previous_price : product?.product?.price?.previous_price_ex_vat) |currencyFormat }}
                            </span>
                            <span class="green-text fs-24 font-kpn-bold" *ngIf="parsePrice(utilityService.isInclVAT() ? product?.product?.price?.price : product?.product?.price?.price_ex_vat) >=0">
                              {{(utilityService.isInclVAT() ? product?.product?.price?.price :  product?.product?.price?.price_ex_vat)  |currencyFormat }}
                            </span>
                          </div>
                          <div>
                            <button (click)="onChangePromotionalOptionalProduct((!product?.selected), product?.product, promotion)" class="btn btn-block d-inline-flex text-white align-items-center btn-primary-dark-2 rounded-btn px-3">
                              <!-- <span class="fs-20 mr-1">-</span> -->
                              <span *ngIf="product?.selected" class="kphicon-uniE972"></span>
                              <span *ngIf="!product?.selected" class="kphicon-uniE985"></span>
                              <img *ngIf="product?.selected" src="../../../assets/images/cart-dark-blue.svg" alt="">
                              <img *ngIf="!product?.selected" src="../../../assets/images/cart-white.svg" alt="">
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>



                <!-- <div class="col-md-6 gutter-col-20 mt-3">
                  <div class="offer-product-item">
                    <div class="offer-product-item-img">
                      <img src="../../../assets/images/mouse.png" alt="">
                    </div>
                    <div class="offer-product-item-content">
                      <h4 class="font-kpn-bold fs-16 line-height-24">APPLE USB-C-NAAR-LIGHTNING-KABEL</h4>
                      <p class="font-kpn-extended green-text mb-0">10% korting</p>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <span class="gray-text fs-16 line-through mr-2">44,00</span>
                          <span class="green-text fs-24 font-kpn-bold">30,10</span>
                        </div>
                        <div>
                          <button class="btn btn-block d-inline-flex align-items-center btn-primary-dark-2 rounded-btn px-3">
                            <span class="fs-20 text-white mr-1">+</span> <img src="../../../assets/images/cart-white.svg" alt="">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-6 gutter-col-20 mt-3">
                  <div class="offer-product-item">

                    <div class="offer-product-item-content">
                      <h4 class="font-kpn-bold fs-16 line-height-24">APPLE USB-C-NAAR-LIGHTNING-KABEL</h4>
                      <p class="font-kpn-extended green-text mb-0">10% korting</p>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <span class="gray-text fs-16 line-through mr-2">44,00</span>
                          <span class="green-text fs-24 font-kpn-bold">30,10</span>
                        </div>
                        <div>
                          <button class="btn btn-block d-inline-flex align-items-center btn-primary-dark-2 rounded-btn px-3">
                            <span class="fs-20 text-white mr-1">+</span> <img src="../../../assets/images/cart-white.svg" alt="">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6 gutter-col-20 mt-3">

                    <div class="offer-product-item-content">
                      <h4 class="font-kpn-bold fs-16 line-height-24">APPLE USB-C-NAAR-LIGHTNING-KABEL</h4>
                      <p class="font-kpn-extended green-text mb-0">10% korting</p>
                      <div class="d-flex align-items-center justify-content-between">
                        <div class="d-flex align-items-center">
                          <span class="gray-text fs-16 line-through mr-2">44,00</span>
                          <span class="green-text fs-24 font-kpn-bold">30,10</span>
                        </div>
                        <div>
                          <button class="btn btn-block d-inline-flex align-items-center btn-primary-dark-2 rounded-btn px-3">
                            <span class="fs-20 text-white mr-1">+</span> <img src="../../../assets/images/cart-white.svg" alt="">
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>

          <div class="pt-4">
            <div class="mb-3 pb-3" *ngIf="currentHandset?.long_description?.length">
              <h3 class="fs-26 green-text font-kpn-extended kpn-extended-black">Productomschrijving</h3>
              <!-- <p class="fs-16 font-kpn-extended mb-2">De Lenovo ThinkPad E16-laptop (16" Intel) straalt niet alleen kracht uit, maar biedt ook betrouwbare prestaties en robuuste beveiliging voor al je zakelijke vereisten. De laptop is ontworpen om zware workloads aan te kunnen en is perfect voor gegevenstabellen, snelle ontwerpprojecten, onderzoek en het beoordelen van inhoud. De razendsnelle E16 is uitgerust met maximaal een Intel® Core™....</p> -->
              <p class="fs-16 font-kpn-extended mb-2 new-description" [innerHTML]="isExpanded ?  currentHandset?.long_description:truncatedDescription"></p>
              <a class="fs-16 font-kpn-extended link-btn-blue" href="javascript:void(0)"
              *ngIf="currentHandset?.long_description && (currentHandset?.long_description?.length > maxLength)" (click)="toggleDescription()">
              <span *ngIf="!isExpanded"> Bekijk volledige omschrijving</span>
              <span *ngIf="isExpanded"> Bekijk minder</span>
                <i class="kphicon-uniE907 fs-26 align-middle"></i>
              </a>
            </div>

            <ng-template #productDescriptionModal let-modal>
              <div class="modal-header tri-modal-header">
                  <h6 class="modal-title fs-35 font-kpn-extended kpn-extended-bold letter-spacing-normal green-text" id="aboutTradeIn">
                    Tot €100 cashback
                  </h6>
                  <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                      <span class="kphicon-uniE926"></span>
                  </button>
              </div>

              <div class="modal-body custom-scrollar">
                  <div class="p-3">
                    <h4 class="font-kpn-black green-text mb-2 pt-1">Hoe werkt de actie?</h4>
                    <p class="mb-2 font-kpn-extended">Bij aanschaf van de in aanmerking komende Jabra producten kan je tot €100 cashback krijgen.</p>
                    <p class="mb-2 font-kpn-extended">
                    Er zijn een aantal voorwaarden. Koop tussen <b>15 februari en 30 april 2024</b> een van de in aanmerking komende Jabra producten via KPN. Vul het claimformulier online in vóór de sluitingsdatum van 15 mei 2024 via dit
                    <a class="link-btn-blue" href="">formulier</a>. </p>
                    <p class="mb-2 font-kpn-extended"><b>Let op!</b> je aankoopfactuur is hierbij vereist</p>
                    <p class="mb-2 font-kpn-extended">De terugbetaling vindt vanuit Jabra plaats binnen 60 dagen (na goedkeuring van je online aanvraag).</p>
                    <p class="mb-2 font-kpn-extended">{{'Mocht je vragen of problemen hebben met het claimen van je cashback, neem dan contact op met claims@jabra-promotions.com'}} </p>
                    </div>
              </div>
            </ng-template>

            <div class="mb-4 pb-3" *ngIf="currentHandset?.product_attributes?.length">
              <h3 class="fs-26 green-text font-kpn-extended kpn-extended-black">Specificaties</h3>
              <p class="fs-16 font-kpn-extended mb-2">De belangrijkste specificaties van dit toestel</p>
              <a class="fs-16 font-kpn-extended link-btn-blue" href="javascript:void(0)"
                (click)="productSpecification(productSpecificationModal)">
                Bekijk alle specificaties <i class="kphicon-uniE907 fs-26 align-middle"></i>
              </a>
            </div>
            <div class="mb-5" *ngIf="selectedProduct?.pdf_documents?.length">
              <h3 class="fs-26 green-text font-kpn-extended kpn-extended-black">Handleidingen</h3>
              <a class="fs-16 font-kpn-extended link-btn-blue d-block"
               *ngFor="let pdf of selectedProduct?.pdf_documents;let i = index" href="javascript:void(0)"
                (click)="downloadPDF(pdf?.document)" target="_blank">
                {{  pdf?.document_name }} <i class="kphicon-uniE907 fs-26 align-middle"></i>
              </a>
            </div>

            <ng-template #productSpecificationModal let-modal>
              <div class="modal-header tri-modal-header">
                  <h6 class="modal-title fs-35 font-kpn-extended kpn-extended-bold green-text" id="aboutTradeIn">
                    Specificaties
                  </h6>
                  <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                      <span class="kphicon-uniE926"></span>
                  </button>
              </div>

              <div class="modal-body custom-scrollar px-0 pt-0">
                  <div class="">
                      <div class="striped-table font-kpn-extended" *ngFor="let attr of currentHandset?.product_attributes">
                        <table  >
                          <thead>
                            <tr>
                              <th colspan="2" class="font-kpn-extended fs-17 kpn-extended-semibold inner-head">{{attr.group }}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngFor="let item of attr?.attributes">
                              <td>
                                <div class="col-auto px-0" *ngIf="item?.attribute_name">
                                  <p class="mb-0 font-kpn-extended">
                                    {{ item?.attribute_name }}
                                  </p>
                                </div>
                              </td>
                              <td class="text-right">

                                      <div class="col-auto px-0" *ngIf="
                                      item?.attribute_value !== 'Ja' &&
                                      item?.attribute_value !== 'Nee' &&
                                      item?.attribute_value !== false &&
                                      item?.attribute_value !== true
                                    ">
                                    <p class="mb-0 font-kpn-extended">
                                      {{ item?.attribute_value }}
                                    </p>
                                  </div>
                                  <div class="col-auto px-0" *ngIf="
                                      item?.attribute_value === 'Ja' ||
                                      item?.attribute_value === true
                                    ">
                                    <i class="kpn-ui-icon ui-check is_available green-text"></i>
                                  </div>
                                  <div class="col-auto px-0" *ngIf="
                                      item?.attribute_value === 'Nee' ||
                                      item?.attribute_value === false
                                    ">
                                    <i class="kpn-icon kpn-times danger-text"></i>
                                  </div>
                              </td>
                            </tr>

                          </tbody>

                        </table>
                      </div>
                  </div>
              </div>
            </ng-template>

          </div>
        </div>
      </div>
      <div class="col-md-auto">
        <div class="pd-new-cart">
          <div  *ngIf="textPromotionalProducts?.length">
            <div class="shadow-kpnew-md tri-rounded-16 py-3 c-px-24 mb-3" *ngFor="let promotion of textPromotionalProducts">
              <h4 class="green-text font-kpn-bold fs-20 pt-1">{{promotion?.description}}</h4>
              <p class="font-kpn-extended fs-15 mb-1" [innerHTML]="truncateHTML(promotion?.long_description || promotion?.description)"></p>
              <a *ngIf="(promotion?.long_description || promotion?.description)?.length > 110" class="fs-16 font-kpn-extended link-btn-blue mb-1" href="javascript:void(0)"
                (click)="productDealDescription(productDealDisModal,promotion)">
                Meer informatie <i class="kphicon-uniE907 fs-26 align-middle"></i>
              </a>
            </div>
          </div>

          <ng-template #productDealDisModal let-modal>
            <div class="modal-header tri-modal-header">
              <h6 class="modal-title fs-35 font-kpn-extended kpn-extended-bold letter-spacing-normal green-text pr-5" id="aboutTradeIn">
               {{ selectedTextPromotion?.description }}
              </h6>
              <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                <span class="kphicon-uniE926"></span>
              </button>
            </div>

            <div class="modal-body custom-scrollar">
              <div class="p-3">
                <p class="font-kpn-extended fs-15 mb-1" [innerHTML]="selectedTextPromotion?.long_description || selectedTextPromotion?.description"></p>
                  <div>
                    <img [src]="selectedTextPromotion?.document" class="card-img-top" [alt]="selectedTextPromotion?.code">
                  </div>
              </div>
            </div>
          </ng-template>

          <div class="tri-shadow-xl tri-rounded-20 py-3 c-px-24 mb-3">
            <div class="d-flex align-items-center justify-content-between pt-1 mb-4">
              <h4 class="green-text font-kpn-black tri-title-32 mb-0">Jouw keuze</h4>
              <span class="green-light-tag green-text" *ngIf="currentHandset?.stock">Op voorraad</span>
              <span class="red-light-tag danger-text" *ngIf="!currentHandset?.stock">Levertijd onbekend</span>
            </div>

            <div class="color-new-pd">
              <div *ngIf="colorOptions?.length">
                <label for="" class="font-kpn-extended kpn-extended-semibold fs-17">Kleur</label>
                <div class="color-action-btn mb-0 d-flex flex-wrap" >
                  <div class="mb-3 mr-1" *ngFor="let color of colorOptions">
                    <button class="color-item-new " (click)="onColorChange(color?.attribute_value)"
                      [disabled]="!color?.attribute_value" [ngClass]="{
                                                active: (selectedColor?.length ? (selectedColor?.trim()?.toLowerCase()) : selectedColor) == (color?.attribute_value?.length ?  (color?.attribute_value?.trim()?.toLowerCase()) : color?.attribute_value),
                                              }" [ngStyle]="{
                                                'background-color':
                                                  color?.color_code || color?.attribute_value
                                              }">
                    </button>
                  </div>
                  <span>{{ currentHandset?.color?.attribute_value }}</span>
                </div>
              </div>

              <div class="pd-action-att mb-3" *ngIf="memorySizeOptions?.length">
                <label for="" class="font-kpn-extended kpn-extended-semibold fs-17">{{'PRODUCT_DETAILS.MEMORY' | transloco }}</label>
                <div class="mr-n2">
                  <div class="pd-action-btn pd-action-btn-auto-h w-100 memory-size-long-text m-0">
                    <div class="row w-100 mx-n1">
                      <div class="col-6 mb-2 px-1" *ngFor="let memory of memorySizeOptions">
                        <button  [ngbTooltip]="(memory?.attribute_value?.length > 16) ? memory?.attribute_value : ''" class="action-btn w-100 font-kpn-extended m-0 button-truncate" (click)="onMemoryChange(memory?.attribute_value)"
                        [disabled]="!memory?.canSelect" [ngClass]="{
                                          active:
                                            selectedMemorySize === memory?.attribute_value,
                                          'disabled-div': !memory?.canSelect
                                        }" >
                        {{memory?.attribute_value}}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="pd-action-att mb-3 pb-1"
                *ngIf="(!restrictExportId?.includes( siteProfile?.export_id ) && relatedProducts?.length)">
                <div class="mb-2" *ngFor="let group of relatedProducts; let i = index">
                  <label for="" class="font-kpn-extended kpn-extended-semibold fs-17 mb-1">{{group?.product_group}}

                    <!-- <small *ngIf="group?.min_required === 1 && group?.max_required === 1"> ({{'PRODUCT_DETAILS.OBLIGED' |
                      transloco}})</small>
                    <small *ngIf="group?.min_required === 0 && group?.max_required === 1"> ({{'CUSTOMER.OPTIONAL' |
                      transloco}})</small>
                    <small *ngIf="group?.min_required >= 1 && group?.max_required >= 2"> (Min: {{group?.min_required}} , Max:
                      {{group?.max_required}})
                    </small> -->
                  </label>
                  <div class="pd-action-btn memory-size-long-text">
                    <button [disabled]="(group?.min_required === 1 && group?.products?.length === 1)"
                      class="action-btn btn-block font-kpn-extended mb-0" [ngClass]="{'active': (product.selected === true),'disable-bg':(group?.min_required === 1 && group?.products?.length === 1)}"
                      (click)="onChangeRelatedProduct(!product.selected, product, group)"
                      *ngFor="let product of group?.products">{{product?.name}}</button>
                    <!-- <button class="action-btn btn-block font-kpn-extended mb-1 active" > Windows 11 Pro </button> -->
                  </div>
                </div>
              </div>

              <!-- <div >
                <button ngbTooltip="Toevoegen aan Favoriet" *ngIf="!isFavorite && wishlistPermission.add"
                  type="button" (click)="addToFavorite()" class="btn btn-sm btn-link">
                  <i class="kphicon-uniE9A8 icon-size-md mr-2" style="color: #67a9a6"></i>
                </button>
                <button *ngIf="isFavorite && wishlistPermission.delete" ngbTooltip="Verwijderen uit verlanglijst"
                  type="button" (click)="removeFromFavorite()" class="btn btn-sm btn-link">
                  <i class="kphicon-uniE9A7 icon-size-md mr-2 favorite-item-logo"></i>
                </button>
              </div> -->
              <hr class="mb-4">
              <div class="row justify-content-between pt-1 mb-2">
                <div class="col-md-auto">
                  <div class="qty-in-di-box d-inline-flex align-items-center mb-4">
                    <button [disabled]="preCartError" [ngClass]="{'disable-link': preCartError}" (click)="decreaseQuantity()"
                      class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                    <input [disabled]="preCartError" class="in-di-input font-kpn-extended bg-white" placeholder="0"
                      appPositiveInteger [(ngModel)]="quantity" (keyup.enter)="onEnter()">
                    <button [disabled]="preCartError" [ngClass]="{'disable-link': preCartError}" (click)="increaseQuantity()"
                      class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                  </div>
                </div>
                <div class="col-md-auto">
                  <h3 *ngFor="let line of preCartLines; let i = index" class="green-text fs-35 font-kpn-extended kpn-extended-black mb-0">
                    {{(utilityService.isInclVAT() ? preCart?.amount:  preCart?.amount_ex_vat )| currencyFormat: ''}}</h3>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col">
                <button  class="btn btn-block btn-primary rounded-btn px-2 mb-3"
                         (click)="beforeAddToCart(selectedProduct)">
                  <img class="mr-1" src="../../../assets/images/cart-white.svg" alt="">
                  In winkelwagen
                </button>
              </div>
              <div class="col-auto pl-0">
                <button *ngIf="!isFavorite && wishlistPermission.add"  (click)="addToFavorite()" class="btn wishlist-btn btn-outline-primary border-2 px-3 rounded-btn">
                  <i class="kphicon-uniE94A"></i>
                </button>
                <button *ngIf="isFavorite && wishlistPermission.delete"  (click)="removeFromFavorite()" class="btn wishlist-btn active btn-outline-primary border-2 px-3 rounded-btn">
                  <i class="kphicon-uniE94A"></i>
                </button>
              </div>
            </div>

            <div class="row align-items-center p-2" *ngIf="preCartError">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{preCartError}}
                  </p>
                </div>
              </div>
            </div>
            <div class="row align-items-center p-2" *ngIf="cartError">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{cartError}}
                  </p>
                </div>
              </div>
            </div>
            <p class="cart-hints-info"><i class="kphicon-uniE90B"></i>{{'PRODUCT_DETAILS.DO_YOU_HAVE_ANY_VOUCHER_CODE' |
              transloco }}.</p>

            <button ngbTooltip="Klik om naar Inruil te gaan"
              *ngIf="hasTradeInPermission && ['handset','tablet']?.includes(product?.product_type?.code)" type="button"
              [routerLink]="'/trade-in'" class="btn btn-sm btn-link nounderline">
              <i class="kphicon-uniE90B"></i>{{'PRODUCT_DETAILS.DID_YOU_KNOW_THAT_YOU_CAN_ALSO_HAND_IN_OLD_EQUIPMENT' |
              transloco}}?
            </button>
          </div>


          <!-- <ng-template #shoppingCartModal let-modal>
            <div class="modal-header tri-modal-header">
              <h6 class="modal-title font-kpn-bold green-text" id="aboutTradeIn">
                Toegevoegd aan jouw winkelmand
              </h6>
              <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                <span class="kphicon-uniE926"></span>
              </button>
            </div>

            <div class="modal-body custom-scrollar">
              <div class="px-3">
                <div class="row align-items-center shopping-cart-line">
                  <div class="col-auto">
                    <div class="cart-img">
                      <img src="../../../assets/images/lenevo-laptop.png" alt="">
                    </div>
                  </div>
                  <div class="col">
                    <h4 class="font-kpn-bold fs-16 line-height-24">Lenovo 314160 - P15v G3 i7 16GB 512SSD W11P T600</h4>
                    <h4 class="green-text fs-24 font-kpn-black">1.664,74</h4>
                  </div>
                </div>
                <div class="row align-items-center shopping-cart-line">
                  <div class="col-auto">
                    <div class="cart-img">
                    </div>
                  </div>
                  <div class="col">
                    <h4 class="font-kpn-bold fs-16 line-height-24">APPLE USB-C-NAAR-LIGHTNING-KABEL</h4>
                    <h4 class="green-text fs-24 font-kpn-black">Gratis</h4>
                  </div>
                </div>

                <div class="pt-3">
                  <button class="btn btn-block btn-primary rounded-btn px-2 mb-2">
                    <img class="mr-1" src="../../../assets/images/cart-white.svg" alt="">
                    In winkelwagen
                  </button>
                  <button class="btn btn-block btn-outline-primary border-2 mt-2 rounded-btn">Verder winkelen</button>
                </div>
              </div>
            </div>
          </ng-template> -->

          <div class="shadow-kpnew-md bg-white tri-rounded-16 py-3 c-px-24 mb-3"
            *ngIf="selectedProduct?.tier_prices?.length > 1">
            <div class="row align-items-end mb-4">
              <div class="col-md-12">
                <h6>{{'PRODUCT_DETAILS.TIER_PRICES' | transloco }}</h6>
                <div class="table-responsive custom-table">
                  <table class="table mb-5">
                    <thead>
                      <tr>
                        <th class="text-right"> {{'PRODUCT_DETAILS.QUANTITY' | transloco }}</th>
                        <th class="text-right">{{'PRODUCT_DETAILS.PRICE' | transloco }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let tier of selectedProduct?.tier_prices">
                        <td class="text-right">{{ tier?.quantity }}</td>
                        <td class="text-right">{{ (utilityService.isInclVAT() ?  tier?.price : tier?.price_ex_vat) | currencyFormat: '€':
                          getDecimalLength(utilityService.isInclVAT() ?  tier?.price : tier?.price_ex_vat) || 2 }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="shadow-kpnew-md bg-white tri-rounded-16 py-3 c-px-24 mb-3"
            *ngIf="selectedProduct?.usages_prices?.length">
            <div class="row align-items-end mb-4">
              <div class="col-md-12">
                <h6>{{'PRODUCT_DETAILS.USAGE_PRICES' | transloco}}</h6>
                <div class="table-responsive custom-table">
                  <table class="table mb-5">
                    <thead>
                      <tr>
                        <th class="text-right">{{'PRODUCT_DETAILS.QUANTITY' | transloco }}</th>
                        <th class="text-right">{{'PRODUCT_DETAILS.PRICE' | transloco }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let usages_price of selectedProduct?.usages_prices">
                        <td class="text-right">{{ usages_price?.quantity }}</td>
                        <td class="text-right">{{ (utilityService.isInclVAT() ?  usages_price?.price : usages_price?.price_ex_vat) | currencyFormat: '€':
                          getDecimalLength(utilityService.isInclVAT() ?  usages_price?.price : usages_price?.price_ex_vat) || 2 }}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div class="shadow-kpnew-md bg-white tri-rounded-16 py-3 c-px-24 mb-3" *ngIf="show_een_message">
            <h4 class="font-kpn-bold fs-15 pt-1">Compatibiliteit van product</h4>
            <p class="font-kpn-extended fs-15 mb-1">
              Let op: Verschillende merken basisstations en handsets werken niet altijd goed samen. Is je hardware toe aan
              vervanging, maar vind je in onze shop niet jouw huidige merk? Bel dan naar 0800-0403. Wij helpen je graag
              verder.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="related-product" *ngIf="productGroup?.length">
      <h3 class="fs-35 green-text mb-2 font-kpn-extended kpn-extended-black">Gerelateerde producten</h3>
      <div class="d-flex mb-3 pb-1 pt-2 flex-wrap">
        <button *ngFor="let item of productGroup;index as i" (click)="onGroupChange(item)"  class="btn btn-light ctg-btn-overview font-kpn-extended px-3 mr-2 mb-2 "  [ngClass]="{'selected selected-related-product-bg': (item?.value === selectedProductGroup?.value)}">{{ item?.name }}</button>
        <!-- <button class="btn btn-light ctg-btn-overview font-kpn-extended px-3 mr-2 mb-2 ml-1">Oortjes</button>
        <button class="btn btn-light ctg-btn-overview font-kpn-extended px-3 mr-2 mb-2 ml-1">Laders</button> -->
      </div>

      <div class="pb-3">
        <div class="d-sm-flex flex-wrap ml-n2 mr-n2 mb-3">
          <div class="col-5-row mb-3 px-2" *ngFor="let product of relatedGroupProducts | slice:0:selectedProductGroup.limit; let i = index">
            <div class="related-prd-card h-100">
              <div class="cursor-pointer" (click)="goToDetails(product)">
                <div class="related-prd-img mb-2">
                  <span *ngIf="product?.image?.length; else noImg">
                    <img [src]="product?.image" [alt]="product?.name">
                  </span>
                  <ng-template #noImg>
                    <img src="/assets/images/image-not-available.png" [alt]="product?.name">
                  </ng-template>
                  <!-- <img src="../../../assets/images/product-01.png" alt=""> -->
                </div>
                <h4 class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text fs-17 related-pr-name">{{ product?.name | textTrim:'40' }} - {{ product?.color }}</h4>
              </div>
              <h5 class="fs-24 font-kpn-black mb-2 pb-1">{{ (utilityService.isInclVAT() ? product?.price?.price :  product?.price?.price_ex_vat) | currencyFormat }}</h5>
              <div class="action-button-wrap">
                <button [disabled]="product.showSpinner" (click)="onAddToCart(product, product?.addedNumber || 1, false, true)" class="btn d-flex align-items-center justify-content-center w-100 rounded-btn px-1" [ngClass]="product?.addedNumber  ? 'btn-success' : 'btn-primary'">
                  <img class="mr-2" src="../../../assets/images/cart-white.svg" alt="">
                  In winkelwagen
                  <span *ngIf="product?.addedNumber">({{product?.addedNumber}})</span>

                </button>
              </div>
            </div>
          </div>
        </div>
        <a *ngIf="relatedGroupProducts?.length > 5" (click)="changeRelatedProdLimit()" class="fs-16 font-kpn-extended link-btn-blue backword-link d-block pb-5" >
          Toon  {{ selectedProductGroup?.showExpandButton ? 'alle' :  'minder'}} "category"
          <i *ngIf="selectedProductGroup?.showExpandButton" class="kphicon-uniE91E fs-26 align-middle"></i>
          <i *ngIf="!selectedProductGroup?.showExpandButton" class="kphicon-uniE921 fs-26 align-middle"></i>
        </a>
      </div>
    </div>
  </div>


  <!-- <div class="container">


    <div class="row">
      <div class="col-md-7">
        <div class="loader-wrapper-static">
          <div class="kpn-spinner" *ngIf="showLoding">
            <div class="spinner-border" style="width: 2.5rem; height: 2.5rem" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <div class="product-details-new-left mb-3">
            <div class="product-details-new-title pl-0">
              <a (click)="backToProductList()" class="backword-link"><i
                  class="kphicon-uniE906"></i>{{'PRODUCT_DETAILS.ALL_APPLIANCES' | transloco }}</a>
              <h2 class="pt-2">
                {{ currentHandset?.code }} - {{ currentHandset?.name }}
              </h2>
              <p class="mb-0">
                {{ currentHandset?.memory_size?.attribute_value }} -
                {{ currentHandset?.color?.attribute_value }}
                <span class="dark-green-tag-pill mr-3 ml-2" *ngIf="currentHandset?.stock"> <b> Op voorraad</b></span>

              </p>
            </div>
            <div class="product-img-viewer-new">
              <div class="product-brand-logo-details" *ngIf="logos?.length">
                <img *ngFor="let logo of logos" [src]="logo?.logo" alt="">
              </div>
              <div class="navigation-wrapper">
                <div class="keen-slider keen-slider--vertical" #sliderRef>
                  <div class="keen-slider__slide number-slide1" *ngFor="let item of productImg">
                    <img [src]="item?.images" />
                  </div>
                </div>

                <button [ngClass]="
                    'arrow arrow--left ' +
                    (currentSlide === 0 ? 'arrow--disabled' : '')
                  " (click)="slider.prev()">
                  <i class="kphicon-uniE91F"></i>
                </button>
                <button *ngIf="slider" [ngClass]="
                    'arrow arrow--right ' +
                    (slider?.track?.details?.slides?.length - 1 === currentSlide
                      ? 'arrow--disabled'
                      : '')
                  " (click)="slider.next()">
                  <i class="kphicon-uniE920"></i>
                </button>
              </div>
              <div class="dots">
                <button (click)="slider.moveToIdx(i)" *ngFor="let slide of dotHelper; let i = index"
                  [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
              </div>
            </div>
            <div class="card-deck" *ngIf="textPromotions?.length">
              <div class="card promo-card" *ngFor="let promotion of textPromotions">
                <img [src]="promotion?.document" class="card-img-top" [alt]="promotion?.code">
                <div class="card-body">
                  <h5 class="card-title">{{promotion?.description}}</h5>
                  <p class="card-text" [innerHTML]="promotion?.long_description || promotion?.description"></p>

                </div>
                <div class="card-footer">
                  <span> <small class="text-muted"> From: <b>{{promotion?.start_datetime |  date: 'dd-MM-yyyy HH:mm'}}</b></small></span> &nbsp;
                  <span><small class="text-muted">To: <b> {{promotion?.end_datetime | date: 'dd-MM-yyyy HH:mm'}}</b></small></span>
                </div>
              </div>
            </div>
            <div class="row align-items-center p-2" *ngIf="selectedProduct?.parent_specification">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    <small> {{selectedProduct?.parent_specification}} </small>
                  </p>
                </div>
              </div>
            </div>
            <div class="row align-items-center p-2" *ngIf="selectedProduct?.pre_order">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-yellow mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{'PRODUCT_DETAILS.THIS_PRODUCT_IS_A_PRE_ORDER_PRODUCT_AND_AVAILABLE_FROM' | transloco}}
                    <small *ngIf="selectedProduct?.release_date"> <b> {{selectedProduct?.release_date |
                        date:'d-MM-yyyy'}}</b> </small>
                  </p>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 mb-2 product-section-new px-3" *ngFor="
                  let pdf of selectedProduct?.pdf_documents;
                  let i = index
                ">
                <div class="product-box-new related-product-right">
                  <div class="product-img-new">
                    <a (click)="downloadPDF(pdf?.document)" target="_blank">
                      {{ pdf?.document_name }}
                    </a>
                  </div>
                  <div class="product-new-content product-new-indetails document-box-item">
                    <div class="row">
                      <div class="col-md-12">
                        <button
                          class="btn d-flex align-items-center btn-block btn-sm btn-success justify-content-center"
                          (click)="downloadPDF(pdf?.document)">
                          <i class="kpn-ui-icon ui-download v-text-bottom mr-2"></i>
                          {{'PRODUCT_DETAILS.DOWNLOAD' | transloco }}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="color-storage-new pl-0">
              <div class="row">
                <div class="col-md-10">
                  <div class="color-new-pd mb-4">
                    <h4 class="mb-1 action-right-title" *ngIf="colorOptions?.length">
                      {{'PRODUCT_DETAILS.COLOR' | transloco }}
                      <span>{{ selectedColor }}</span>
                    </h4>
                    <div class="color-action-btn mb-4" *ngIf="colorOptions?.length">
                      <button class="color-item-new" (click)="onColorChange(color?.attribute_value)"
                        [disabled]="!color?.attribute_value" [ngClass]="{
                          active: selectedColor === color?.attribute_value
                        }" *ngFor="let color of colorOptions" [ngStyle]="{
                          'background-color':
                            color?.color_code || color?.attribute_value
                        }"></button>
                    </div>

                    <div class="pd-action-att mb-4" *ngIf="memorySizeOptions?.length">
                      <h4 class="mb-2 action-right-title">{{'PRODUCT_DETAILS.MEMORY' | transloco }}</h4>
                      <div class="pd-action-btn memory-size-long-text">
                        <button class="action-btn" (click)="onMemoryChange(memory?.attribute_value)"
                          [disabled]="!memory?.canSelect" [ngClass]="{
                            active:
                              selectedMemorySize === memory?.attribute_value,
                            'disabled-div': !memory?.canSelect
                          }" *ngFor="let memory of memorySizeOptions">
                          <small> {{memory?.attribute_value}}</small>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-2">
                  <button ngbTooltip="Toevoegen aan Favoriet" *ngIf="!isFavorite && wishlistPermission.add"
                    type="button" (click)="addToFavorite()" class="btn btn-sm btn-link">
                    <i class="kphicon-uniE9A8 icon-size-md mr-2" style="color: #67a9a6"></i>
                  </button>
                  <button *ngIf="isFavorite && wishlistPermission.delete" ngbTooltip="Verwijderen uit favoriet"
                    type="button" (click)="removeFromFavorite()" class="btn btn-sm btn-link">
                    <i class="kphicon-uniE9A7 icon-size-md mr-2 favorite-item-logo"></i>
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="product-details-new-left mb-3">
            <div class="sibling-carousel" *ngIf="
                product?.product_group?.page_layout?.code ===
                'show_iot_siblings'
              ">
              <div class="row align-items-center justify-content-between">
                <div class="col-auto">
                  <h3 class="description-title-new mb-3">
                    {{'PRODUCT_DETAILS.SIBLINGS' | transloco }} ({{ siblingList?.length }})
                  </h3>
                </div>
                <div class="col-auto">
                  <div class="text-right mb-2">
                    <button [ngClass]="
                        'arrow arrow--left ' +
                        (currentSlideSibling === 0 ? 'arrow--disabled' : '')
                      " (click)="sliderSibling.prev()">
                      <i class="kphicon-uniE91F"></i>
                    </button>
                    <button *ngIf="sliderSibling" [ngClass]="
                        'arrow arrow--right ' +
                        (sliderSibling?.track?.details?.slides?.length - 1 ===
                        currentSlideSibling
                          ? 'arrow--disabled'
                          : '')
                      " (click)="sliderSibling.next()">
                      <i class="kphicon-uniE920"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="product-section-new">
                <div class="keen-slider" #sliderSiblingRef>
                  <div class="keen-slider__slide" *ngFor="let sibling of siblingList; let i = index">
                    <div class="product-box-new related-product-right">
                      <div class="product-img-new">
                        <a (click)="onProductDetail(sibling, i)">
                          <span *ngIf="sibling?.image; else noImg">
                            <img [src]="sibling?.image" [alt]="sibling?.name" />
                          </span>
                          <ng-template #noImg>
                            <img src="/assets/images/image-not-available.png" [alt]="sibling?.name" />
                          </ng-template>
                        </a>
                      </div>
                      <div class="product-new-content product-new-indetails">
                        <div class="row">
                          <div class="col-md-12">
                            <p class="item-att" (click)="onProductDetail(sibling, i)" [title]="sibling?.name">
                              <b>{{ sibling?.code }} -
                                {{ sibling?.name | textTrim : "40" }}</b>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-able-3row" *ngIf="siblingProducts?.length">
            <p class="item-att mb-1 ml-3">
              <b> {{'PRODUCT_DETAILS.SIBLING_PRODUCTS' | transloco }} </b>
            </p>
            <hr />
            <div class="product-section-new px-3">
              <div class="row">
                <div class="col-md-4" *ngFor="let product of siblingProducts; let i = index">
                  <div class="product-box-new related-product-right">
                    <div class="product-img-new">
                      <a (click)="onProductDetail(product, i)" class="d-flex justify-content-center">
                        <span *ngIf="product?.image; else noImg">
                          <img [src]="product?.image" [alt]="product?.name" />
                        </span>
                        <ng-template #noImg>
                          <img src="/assets/images/image-not-available.png" [alt]="product?.name" />
                        </ng-template>
                      </a>
                    </div>
                    <div class="product-new-content product-new-indetails">
                      <div class="row">
                        <div class="col-md-12">
                          <p class="item-att" (click)="onProductDetail(product, i)" [title]="product?.name">
                            <small>
                              <b>{{ product?.code }} -
                                {{ product?.name | textTrim : "40" }}
                              </b></small>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="product-details-new-left pl-0 product-description-new specification-new mb-3">
            <div class="product-description-new" [ngClass]="{ 'product-description-new-all': moreSpecification }">
              <h3 class="description-title-new">{{ currentHandset?.name }}</h3>
              <div class="new-description">
                <p class="multi_lines_text" [innerHTML]="currentHandset?.long_description"></p>
              </div>
              <a *ngIf="currentHandset?.long_description" (click)="readMoreSpecification()" class="view-more-new">
                <span *ngIf="!moreSpecification">{{'PRODUCT_DETAILS.MORE_INFORMATION' | transloco }}</span>
                <span *ngIf="moreSpecification">{{'PRODUCT_DETAILS.VIEW_LESS' | transloco }}</span>
              </a>
            </div>
          </div>

          <div class="product-details-new-left pl-0 product-description-new specification-new mb-3">
            <div class="row">
              <div class="col-md-6 mb-5" *ngFor="let attr of currentHandset?.product_attributes">
                <h5 class="description-title-new mb-3 dark-grey-text">
                  {{ attr.group }}
                </h5>
                <div *ngFor="let item of attr?.attributes">
                  <div class="row align-items-center mx-0 my-2" *ngIf="item?.attribute_value">
                    <div class="col-auto px-0" *ngIf="item?.attribute_name">
                      <p class="mb-0 body-text-light-1">
                        {{ item?.attribute_name }}
                      </p>
                    </div>
                    <div class="col px-0 inline-saperator-col" *ngIf="item?.attribute_name">
                      <div class="inline-saperator"></div>
                    </div>
                    <div class="col-auto px-0" *ngIf="
                        item?.attribute_value !== 'Ja' &&
                        item?.attribute_value !== 'Nee' &&
                        item?.attribute_value !== false &&
                        item?.attribute_value !== true
                      ">
                      <p class="mb-0 body-text-light-1">
                        {{ item?.attribute_value }}
                      </p>
                    </div>
                    <div class="col-auto px-0" *ngIf="
                        item?.attribute_value === 'Ja' ||
                        item?.attribute_value === true
                      ">
                      <i class="kpn-ui-icon ui-check is_available green-text"></i>
                    </div>
                    <div class="col-auto px-0" *ngIf="
                        item?.attribute_value === 'Nee' ||
                        item?.attribute_value === false
                      ">
                      <i class="kpn-icon kpn-times danger-text"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col">
        <app-selection-overview [product]="product"
          [haveTradeInPermission]="hasTradeInPermission" (onTextPromotions)="onTextPromotionalProduct($event)"></app-selection-overview>
      </div>
    </div>
  </div> -->
</section>
