<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
      <div class="container" *ngIf="!tradeInOrders?.length && error">
          <div class="col-md-12 col-12 col-gap-reduce">
              <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                      <span class="kphicon-uniE952"></span>
                      <small> {{error}}</small>
                  </p>
              </div>
          </div>
      </div>
      <div class="container">
          <div class="new-cart-box-overview pt-2 mb-1">
              <div class="row pt-4">
                  <div class="col">
                      <h3 class="title-2 mb-1 green-text">{{'TRADE_ORDERS.TRADE_IN_ORDERS' | transloco}}</h3>
                      <h5 class="title-4 pt-3">{{'ORDER_OVERVIEW.THESE_ARE_YOUR_DETAILS' | transloco }}</h5>
                      <div class="row">
                      </div>
                  </div>
              </div>
          </div>
          <div class="bg-white new-cart-box-overview pt-2 mb-1">
              <div class="row mb-2">
                  <div class="col-md-12">
                      <div class="flat-table" *ngIf="tradeInOrders">
                          <table class="table">
                              <thead>
                                  <tr>
                                      <th>Order #</th>
                                      <th>{{'TRADE_ORDERS.CREATED_AT' | transloco}}</th>
                                      <th>{{'ORDER_STATUS.CUSTOMER_NAME' | transloco }}</th>
                                      <th>{{'CUSTOMER.COMPANY_NAME' | transloco }}</th>
                                      <th class="text-right">{{'TRADE_CART.TOTAL_PRICE' | transloco}}</th>
                                      <th></th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr *ngFor="let item of tradeInOrders | paginate: config">
                                      <td>
                                          <button ngbTooltip="Bekijk details van de bestelling" type="button"
                                              (click)="goToOrderDetails(item)"
                                              class="btn btn-sm btn-link py-0 nounderline">
                                              <small>{{item?.code}}</small>
                                          </button>
                                      </td>
                                      <td><small>{{item?.created_at | date: 'YYYY-MM-dd HH:mm'}}</small></td>
                                      <td><small>{{item?.contact_person?.first_name}} &nbsp;
                                              {{item?.contact_person?.last_name}}
                                          </small>
                                      </td>
                                      <td><small>{{item?.contact?.company_name}}</small></td>
                                      <td class="text-right"><small>{{item?.total_price_ex_vat |
                                              currencyFormat}}</small></td>
                                      <td class="text-right">
                                          <button ngbTooltip="Bekijk details van de bestelling" type="button"
                                              (click)="goToOrderDetails(item)"
                                              class="btn btn-sm btn-link py-0 nounderline">
                                              <i class="kphicon-uniE9A1 icon-size-md mr-2 favorite-item-logo"></i>
                                          </button>
                                      </td>
                                  </tr>
                              </tbody>
                          </table>
                          <div class="pagination-new" *ngIf="config.totalItems">
                              <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true"
                                  class="pagination d-flex justify-content-center" (pageChange)="onPageClick($event)">
                              </pagination-controls>
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>
