import { QuoteDetailsService } from './../../quote-details.service';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-customer-quote-details-final-step',
  templateUrl: './customer-quote-details-final-step.component.html',
  styleUrls: ['./customer-quote-details-final-step.component.scss']
})
export class CustomerQuoteDetailsFinalStepComponent {
  public quote = null;
  private currentCustomerDetailsQuoteSubscription$: Subscription;
  orderDate = null;
  orderNumber = null;
  orderUUID = null;
  quoteUUID = null;
  showConfigTab = false;
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private quoteDetailsService: QuoteDetailsService
  ) {
    this.route.queryParams.subscribe(params => {
      // order_date: res?.data?.order?.original_order_date, order_uuid:res?.data?.order?.uuid,quote_uuid: this.quote?.uuid
      this.orderDate = params['order_date'];
      this.orderUUID = params['order_uuid'];
      this.quoteUUID = params['quote_uuid'];
      this.showConfigTab = params['showConfigTab'];
    });
    this.orderNumber = this.route.snapshot.params.order_reference;
  }
  navigateToQuoteDetails() {
    this.router.navigate(['/customer/quote-details', `${this.quoteUUID}`]);
  }
  goToOrderDetails(){
    this.router.navigate(['/order-confirmation', `${this.orderUUID}`]);

  }
  ngOnDestroy() {
    if (this.currentCustomerDetailsQuoteSubscription$) {
      this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
    }
  }
}
