<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
      <div class="container">
        <!-- <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
          <li class="back-breadcrumb-btn border-0">
            <a routerLink="/trade-in">
              <i class="kphicon-uniE906"></i> Terug
            </a>
          </li>

        </ul> -->

        <div class="pt-2">
          <app-trade-in-steps [completeStepFour]="true" [activeStep]="'order-success'" ></app-trade-in-steps>
        </div>
        <h2 class="font-kpn-bold tri-title-40 green-text mb-3 mt-n3">Klaar!</h2>
        <div class="facts-box">
          <div class="what-is-trade-in bg-white p-40 mb-0">
            <h4 class="tri-title-32 font-kpn-black green-text mb-3">Bedankt voor je aanvraag</h4>
            <h6 class="font-kpn-bold">De aanvraag is succesvol geplaatst.</h6>
            <p class="kpn-font-color font-kpn-extended mb-2" *ngIf="tradeInBusinessPartner === 'customer'; else otherUser">Recommerce zal binnen 3 werkdagen contact opnemen met de klant vanuit <a href="mailto:nederland@recommerce.com">nederland@recommerce.com</a>m om de vervolgstappen door te nemen. Voertaal van Recommerce is Engels.
              Je ontvangt een kopie van de bevestigingsmail naar jouw klant. </p>
            <ng-template #otherUser>
              <p class="kpn-font-color font-kpn-extended mb-2" *ngIf="tradeInBusinessPartner">Recommerce zal binnen 3 werkdagen contact opnemen vanuit <a href="mailto:nederland@recommerce.com">nederland@recommerce.com</a> om de vervolgstappen door te nemen. Voertaal van Recommerce is Engels. </p>
            </ng-template>

            <!-- <div class="pt-4">
              <button (click)="goToOrderDetails()"  class="btn btn-primary rounded-btn px-5">Bekijk je bestelling</button>
            </div>  -->
          </div>

          <div class="row align-items-center justify-content-between pt-3 mb-5">
            <button (click)="goToHome()" class="btn btn-link d-flex align-items-center">Terug naar homepage </button>
          </div>
        </div>


      </div>
  </section>
</div>
