import { Injectable } from '@angular/core';
import { ApiBaseService } from '../api-base.service';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AddressService {
  constructor(private apiBaseService: ApiBaseService
  ) { }

  getCountry() {
    return this.apiBaseService.get('tenants/countries');
  }

  getAddress(country_code: string, paramsString: string) {
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(`address-lookup/${country_code}/search`, params);
  }

  getAddressDetails(country_code: string, paramsString: string) {
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(`address-lookup/${country_code}/details`, params);
  }

  getNlAddress(payload: any) {
    const addressSearchURI = `address-lookup/netherlands/search`;
    let params = new HttpParams({ fromString: '' });
    if (payload.houseNumberExt) {
      params = new HttpParams({ fromString: `postcode=${payload.zipCode}&house_number=${payload.houseNumber}&house_number_ext=${payload.houseNumberExt}` });
    } else {
      params = new HttpParams({ fromString: `postcode=${payload.zipCode}&house_number=${payload.houseNumber}` });
    }
    return this.apiBaseService.get(addressSearchURI, params);
  }

  getFAQ(paramsString: string) {
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(`faq/available-faqs`, params);
  }

}
