import { Injectable } from '@angular/core';
import { ApiBaseService } from '../../shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class ImportOrderService {
  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  importCart(formData: FormData) {
    const url = 'contacts/import-orders';
    return this.apiBaseService.postFile(url, formData, 'v2');
  }

  createOrder(payload: any) {
    const url = 'contacts/create-orders';
    return this.apiBaseService.post(url, payload, 'v2');
  }


  updateCartsTermsAndConditions(payload) {
    const url = 'validate/carts/terms-and-conditions/update';
    return this.apiBaseService.patch(url, payload);
  }
}
