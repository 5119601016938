
import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable, timer } from "rxjs";
import { StorageService } from "../shared/storage.service";

@Injectable({
  providedIn: 'root'
})
export class ExportProductsGuard implements CanActivate {

  constructor(
    private router: Router,
    public storageService: StorageService
  ) {
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
    const permissions = this.storageService.getPermission();
    const hasProductExportPermission = permissions.some(permission => {
      return permission.code === 'catalog.can_export_catalog_products';
    });
    if (hasProductExportPermission) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }
}
