import { UtilityService } from 'src/app/shared/services/utility.service';
import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/storage.service';
import { QuoteDetailsService } from '../../quote-details.service';
import { EAddressPrecision, EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { AccountTypeService } from 'src/app/pages/account-type/account-type.service';
import { ESupplierTypes } from 'src/app/shared/enums/product-type.enums';

@Component({
  selector: 'app-customer-quote-details-step',
  templateUrl: './customer-quote-details-step.component.html',
  styleUrls: ['./customer-quote-details-step.component.scss']
})
export class CustomerQuoteDetailsStepComponent extends BasePermission {
  private quoteUuid: string;
  private user: any;
  isLoading: boolean;
  quote = null;
  removeUnderscoreMethod = this.utilityService.removeUnderscoreFromString;
  quoteCancelReasons = [];
  selectedQuoteCancelReason = null;
  quoteCancelReasonText = '';
  contactAccountTypesCustomers = [];
  hasCpaas = null;
  cPassData = null;
  isCpassDataAdded = false;
  @ViewChild ('rejectOfferModal') rejectOfferModal;
  constructor(
    private modalService: NgbModal,
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private quoteDetailsService: QuoteDetailsService,
    private utilityService: UtilityService,
    public store: Store<IAppState>,
    private toastrService: ToastrService,
    private customerService: CustomerService,
    private accountTypeService: AccountTypeService

  ) {
    super(store);
    this.quoteUuid = this.route.snapshot.params.quote_uuid;
    this.user = this.storageService.getCurrentUser();
    this.route.paramMap.subscribe((params) => {
      this.quoteUuid = params.get('quote_uuid');
      if (this.quoteUuid) {
        this.getQuoteDetails();
      }
    });
   }

  get eQuoteStatus() { return EQuoteStatus; }
  get eAddressPrecision() {
    return EAddressPrecision;
  }
  get canViewContactAndAddressSection() {
   return (this.eQuoteStatus.APPROVED !== this.quote?.status) && (this.eQuoteStatus.CANCELLED !== this.quote?.status) && (this.eQuoteStatus.REJECTED !== this.quote?.status) && (this.eQuoteStatus.EXPIRED !== this.quote?.status)
  }
  get canEnroll () {
    return this.eQuoteStatus.APPROVED == this.quote?.status || this.eQuoteStatus.NEW == this.quote?.status || this.eQuoteStatus.PENDING == this.quote?.status;
  }
   ngOnInit() {
    // if (this.quoteUuid) {
    //   this.getQuoteDetails();
    // }
  }

  getQuoteCancelReasons() {
    this.quoteDetailsService.getQuoteCancelReasons().subscribe(
      (res) => {
        this.quoteCancelReasons = res?.data?.results ?? [];
      }
    );
  }
  getQuoteDetails() {
    this.isLoading = true;
    this.quoteDetailsService.getQuote(this.quoteUuid).subscribe(
      (res) => {
        this.isLoading = false;
        this.quote = res.data;
        if (this.quote?.is_contact_account_type_required) {
          if (this.hasViewContactAccountTypeCustomer) {
            this.getContactAccountTypesCustomers();
          }
        }
        this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
        if (this.hasCpaas) {
          this.cPassData = {
            cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
            cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
            cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
          }
          this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
        };
        this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
        this.getQuoteContactDetails();
        this.getQuoteCancelReasons();
      },
      (err) => {
        this.isLoading = false;
      }
    );
  }
  getContactAccountTypesCustomers() {
    this.accountTypeService.getContactAccountTypesCustomers().subscribe(res => {
      this.contactAccountTypesCustomers = res?.data?.results ?? [];

      // this.isLoading = true;
      // let paramsString = `page=1`;

      // this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      //   const allAccountTypes = res?.data?.results ?? [];
      //   this.accountTypes = allAccountTypes.filter(item => {
      //     if (this?.quote?.contact_account_types?.find(at=> at === item.id)) {
      //       return item;
      //     }
      //   }).map(item => ({ ...item, checked: true }));
      // }, error => {
      //   this.isLoading = false;
      // })
      // if (existingSelectedContactAccountTypesCustomer) {
      //   this.selectedContactAccountTypesCustomer = this.contactAccountTypesCustomers.find(item => item?.uuid === existingSelectedContactAccountTypesCustomer)?.uuid || null;

      //   this.onContactAccountTypesCustomerChange(existingSelectedContactAccountTypesCustomer);
      // }
    }, error => {
    })
  }
  getQuoteContactDetails() {
    this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
      this.isLoading = false;
      this.quoteDetailsService.currentCustomerQuoteDetailsContactInfo$.next(res.data);

    }, error => {
      this.isLoading = false;

    })
  }
  countTotalProductsQty() {
    return this.quote?.lines?.reduce((acc, line) => {
      const lineQty = line.qty || 0;
      const subLineQty = line.sub_lines?.reduce((acc, subLine) => {
        return acc + subLine.qty;
      }, 0);
      return acc + lineQty + subLineQty;
    }, 0);
  }
  goToPlaceOrderPage() {
    this.router.navigate(['/customer/quote-details-place-order/', `${this.quote.uuid}`]);
  }
  editDeleveryAddress(deleveryAddressModal) {
    this.modalService.open(deleveryAddressModal, {
     ariaLabelledBy: 'modal-basic-title',
     centered: true,
     modalDialogClass: 'tri-modal-595 modal-runded',
     size: 'md',
     scrollable: true,
     backdrop: 'static',
   });

   // this.result.then((result) => {
   //   this.selectedQuoteLine = null;
   // }).catch(e => {

   // });
  }

  rejectOffer() {
    this.modalService.open(this.rejectOfferModal, {
     ariaLabelledBy: 'modal-basic-title',
     centered: true,
     modalDialogClass: 'tri-modal-550 modal-runded',
     size: 'md',
     scrollable: true,
     backdrop: 'static',
   });
   // this.result.then((result) => {
   //   this.selectedQuoteLine = null;
   // }).catch(e => {

   // });
  }
  getModifiedStatusName(status: string) {
    return this.utilityService.getModifiedStatusName(status ? status : '');
  }
  getQuoteStatusIcon(status: string) {
    return this.utilityService.getQuoteStatusIcon(status);
  }

  updateCpassDataFromChild(quote) {
    this.quote = quote;
    this.setCpassData();
  }
  setCpassData() {
    this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
    if (this.hasCpaas) {
      this.cPassData = {
        cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
        cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
        cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
      }
      this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
    }

  }



  cancelQuote() {
    this.isLoading = true;
    this.quoteDetailsService.cancelQuote(this.quote.uuid, {cancel_reason_code: this.selectedQuoteCancelReason,cancel_reason_text:this.quoteCancelReasonText}).subscribe(res => {
      this.isLoading = false;
      this.toastrService.success(res?.data?.message ?? 'Quote cancelled successfully');
      this.getQuoteDetails();
      this.modalService.dismissAll();
      this.quote = res.data;
    }, error => {
      this.isLoading = false;
      this.toastrService.error(error?.error?.message);
    });
  }


}


