import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class OrderStatusService {
  private orderURI = 'orders';
  private orderSearchApi = 'search/orders/list'

  constructor(
    private apiBaseService: ApiBaseService
  ) { }

  getOrders(ownOrder: boolean = false, isParked = false): Observable<any> {
    let paramsString = `own_order=${ownOrder}`;
    if (isParked) {
      paramsString = paramsString + `&status=parked`;
    }
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(this.orderURI);
  }

  searchOrders(
    page: number = 1,
    externalNumber: string,
    internalNumber: string,
    po_number: string,
    mobileNumber: string,
    postalCode: string,
    houseNumber: number,
    ownOrder: boolean,
    isParked: boolean
  ) {

    let paramsString = `page=${page}`;
    if (externalNumber.trim()) {
      paramsString = paramsString + `&external_number=${externalNumber.trim()}`;
    }
    if (internalNumber.trim()) {
      paramsString = paramsString + `&internal_number=${internalNumber.trim()}`;
    }
    if (po_number.trim()) {
      paramsString = paramsString + `&po_number=${po_number.trim()}`;
    }
    if (mobileNumber.trim()) {
      paramsString = paramsString + `&mobile_number=${mobileNumber.trim()}`;
    }
    if (postalCode) {
      paramsString = paramsString + `&postal_code=${postalCode.trim()}`;
    }
    if (houseNumber) {
      paramsString = paramsString + `&house_number=${houseNumber}`;
    }

    if (isParked) {
      paramsString = paramsString + `&status=parked`;
    }

    paramsString = paramsString + `&own_order=${ownOrder}`;

    const params = new HttpParams({
      fromString: paramsString
    });
    let checkParams = paramsString?.split('&');
    console.log('checkParams: ', checkParams);
    return this.apiBaseService.get(checkParams?.length === 2 ? this.orderURI : this.orderSearchApi, params);
  }

  getOrderDetails(orderUUID: string): Observable<any> {
    return this.apiBaseService.get(`orders/${orderUUID}`);
  }

  cancelOrder(orderUUID: string): Observable<any> {
    return this.apiBaseService.put(`orders/${orderUUID}/cancel`, {});
  }

  getOrderShipment(orderUUID: string) {
    return this.apiBaseService.get(`orders/${orderUUID}/shipments`);
  }

  downloadDocuments(document_uuid: string) {
    return this.apiBaseService.getFile(`documents/${document_uuid}/download`);
  }


  getOrderHandsetProvisions(orderUUID: string) {
    return this.apiBaseService.get(`orders/${orderUUID}/handset-provisions`);
  }


}
