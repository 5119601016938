  <!-- New Design Start 3 -->
    <section class="bg-kpn-subtle-grey">
      <div>
        <app-business-partner-selection [showEdit]="false"></app-business-partner-selection>
      </div>

        <section class="pt-4 pb-5"  >
          <div class="container">
            <div class="pt-2">
              <!-- <app-steps [activeStep]="'overview'" [activeStep]="'customer_select'"></app-steps> -->
              <app-trade-in-steps [activeStep]="'shipping-method'" [completeStepThree]="true" ></app-trade-in-steps>
            </div>
            <h2 class="tri-title-40 font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-3 mt-n3">
              {{ (totalQtyInCart<10)  ? 'Stuur je producten naar ons op'  : 'We komen de producten bij je ophalen' }}
            </h2>
            <div class="facts-box">
              <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4">
                <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black letter-spacing-normal mb-3 pb-1">Verzendmethode </h3>
                <div class="radio-check-box-type mb-3">
                  <input type="radio" id="playing-for-1" [checked]="totalQtyInCart<10" [disabled]="totalQtyInCart>=10"  name="playing-for" >
                    <label for="playing-for-1" class="d-flex w-100 font-kpn-extended fs-16 mb-0">
                      <span class="radio-bullet mt-1"></span>
                      <div>
                        Verzenden
                        <span class="d-block grey-text">Stuur je producten naar ons op als je er minder dan 10 hebt. </span>
                      </div>
                    </label>
                </div>
                <div class="radio-check-box-type">
                  <input type="radio"  id="playing-for-2" [checked]="totalQtyInCart>=10" name="playing-for" [disabled]="totalQtyInCart<10"  >
                    <label for="playing-for-2" class="d-flex w-100 font-kpn-extended fs-16 mb-0">
                      <span class="radio-bullet mt-1"></span>
                      <div>
                        Ophalen
                        <span class="d-block grey-text">Bij 10 of meer producten halen wij ze bij je op.</span>
                      </div>
                    </label>
                </div>
              </div>
              <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4" *ngIf="(totalQtyInCart<10)">
                <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black letter-spacing-normal mb-3 pb-1">Verzendinstructies </h3>
                <h4 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-16 mb-3">In het kort</h4>
                <div class="mb-4">
                  <div class="d-flex shipping-instruction-step">
                    <div class="instruction-step mr-3">
                      <span class="font-kpn-extended fs-12">1</span>
                    </div>
                    <p class="font-kpn-extended mb-0 pt-1">Wij ontvangen in totaal {{ totalQtyInCart }} producten van je</p>
                  </div>
                  <div class="d-flex shipping-instruction-step">
                    <div class="instruction-step mr-3">
                      <span class="font-kpn-extended fs-12">2</span>
                    </div>
                    <p class="font-kpn-extended mb-0 pt-21">Van Recommerce ontvang je de verzendinstructies en een verzendlabel per e-mail </p>
                  </div>
                  <div class="d-flex shipping-instruction-step">
                    <div class="instruction-step mr-3">
                      <span class="font-kpn-extended fs-12">3</span>
                    </div>
                    <p class="font-kpn-extended mb-0 pt-21">Stuur je producten binnen 10 werkdagen naar ons op</p>
                  </div>
                </div>
                <p class="font-kpn-extended mb-0 pt-21">Heb je nog vragen? Bekijk onze <a href="/faq" target="_blank" class="blue-link">FAQ-pagina</a>.</p>
              </div>
              <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4" *ngIf="(totalQtyInCart>=10)">
                <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black letter-spacing-normal mb-3 pb-1">Ophaalinstructies </h3>
                <h4 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-16 mb-3">In het kort</h4>
                <div class="mb-4">
                  <div class="d-flex shipping-instruction-step">
                    <div class="instruction-step mr-3">
                      <span class="font-kpn-extended fs-12">1</span>
                    </div>
                    <p class="font-kpn-extended mb-0 pt-1">Wij komen in totaal {{ totalQtyInCart }} producten bij je ophalen</p>
                  </div>
                  <div class="d-flex shipping-instruction-step">
                    <div class="instruction-step mr-3">
                      <span class="font-kpn-extended fs-12">2</span>
                    </div>
                    <p class="font-kpn-extended mb-0 pt-21">Van Recommerce ontvang je de ophaalinstructies per e-mail  </p>
                  </div>
                  <div class="d-flex shipping-instruction-step">
                    <div class="instruction-step mr-3">
                      <span class="font-kpn-extended fs-12">3</span>
                    </div>
                    <p class="font-kpn-extended mb-0 pt-21">Maak een ophaalafspraak via de mail van Recommerce</p>
                  </div>
                </div>
                <p class="font-kpn-extended mb-0 pt-21">Heb je nog vragen? Bekijk onze <a href="/faq" target="_blank" class="blue-link">FAQ-pagina</a>.</p>
              </div>
              <div class="row align-items-center justify-content-between pt-0">
                <div class="col-auto d-flex">
                  <button (click)="back()" class="fs-16 pl-0 font-kpn-extended link-btn-blue d-inline-flex align-items-center">
                    <i class="kpnew-icon-arrow-lingt-2 fs-18 align-middle"></i>Terug naar gegevens</button>
                </div>
                <div class="col-auto">
                  <button (click)="goToOrderOverview()" class="btn btn-primary px-5 rounded-btn mb-2">
                    Volgende stap
                </button>
                </div>
              </div>
            </div>
          </div>
        </section>
    </section>
