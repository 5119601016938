<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="customer-search kpn-card bg-kpn-light mb-4">
        <div class="mb-3">
          <div class="row align-items-center">
            <div class="col-md">
              <h4 class="title-4 green-text pt-1 mb-0" i18n="@@arm.order-status.serc">
                {{'ACCOUNT.GENERAL_INFORMATION' | transloco }}
              </h4>
            </div>
            <div class="col-md-auto">
              <button class="btn icon-btn mb-1" (click)="openEditForm()">
                {{ formDisable ? ('ACCOUNT.EDIT' | transloco ) : ('ACCOUNT.UNDO' | transloco )  }} <i class="kphicon-uniE931" *ngIf="formDisable; else undoIcon"></i>
                <ng-template #undoIcon>
                  <i class="kphicon-uniE9B1"></i>
                </ng-template>
              </button>
            </div>
          </div>
        </div>

        <form [formGroup]="basicForm">
          <div class="row">
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">
                  {{'ACCOUNT.USERNAME' | transloco }}
                </label>
                <input type="text" class="form-control kpn-form-control" readonly [value]="username">
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'ACCOUNT.INITIALS' | transloco }}
                  <span class="danger-text">*</span></label>
                <input type="text" class="form-control kpn-form-control" formControlName="initials"
                  [readonly]="formDisable" [ngClass]="{'is-invalid': !!errors?.initials}">
                <div *ngIf="errors?.initials" class="invalid-feedback">
                  {{ errors?.initials }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'ACCOUNT.FIRST_NAME' | transloco }}</label>
                <input type="text" class="form-control kpn-form-control" formControlName="first_name"
                  [readonly]="formDisable">
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                <input type="text" class="form-control kpn-form-control" formControlName="surname_prefix"
                  [readonly]="formDisable">
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.int-num">{{'ACCOUNT.LAST_NAME' | transloco }} <span class="danger-text">*</span></label>
                <input type="text" class="form-control kpn-form-control" formControlName="last_name"
                  [readonly]="formDisable" [ngClass]="{'is-invalid': !!errors?.last_name}">
                <div *ngIf="errors?.last_name" class="invalid-feedback">
                  {{ errors?.last_name }}
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.client-num">{{'ACCOUNT.BIRTH_NAME' | transloco }}</label>
                <input type="text" class="form-control kpn-form-control" formControlName="birth_name"
                  [readonly]="formDisable">
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.mob-num">{{'SIGN_UP.PHONE_NUMBER' | transloco}} <span class="danger-text">*</span></label>
                <div class="input-group phone-number-group">
                  <input type="text" class="form-control phone-code kpn-form-control" value="+31" disabled>
                  <input type="text" class="form-control kpn-form-control" formControlName="mobile_phone_number"
                    [readonly]="formDisable" [ngClass]="{'is-invalid': !!errors?.mobile_phone_number}">
                  <div *ngIf="errors?.mobile_phone_number" class="invalid-feedback">
                    {{ errors?.mobile_phone_number }}
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.mob-num">{{'ACCOUNT.EMAIL_ADDRESS' | transloco}}</label>
                <input type="text" class="form-control kpn-form-control" formControlName="email"
                  [readonly]="formDisable || notAllowedEmailChangePermission">
              </div>
            </div>
          </div>

          <div class="row justify-content-end mb-4">
            <div class="col-md-auto col-6 col-gap-reduce">
              <button type="button" class="btn px-3 mt-2 rounded-btn" i18n="@@arm.order-status.search-btn"
                (click)="openEditForm()" [disabled]="!basicForm.touched">{{'ACCOUNT.CANCEL' | transloco}}</button>
            </div>
            <div class="col-md-2 col-gap-reduce">
              <button type="button" class="btn btn-block btn-primary mt-2 rounded-btn"
                i18n="@@arm.order-status.search-btn" (click)="updateProfile()"
                [disabled]="!basicForm.touched">{{'ACCOUNT.SAVE_CHANGES' | transloco}}</button>
            </div>
          </div>
        </form>

      </div>
      <div class="customer-search kpn-card bg-kpn-light mb-4">
        <form [formGroup]="passwordForm">
          <h4 class="title-4 green-text pt-1 mb-3" i18n="@@arm.order-status.serc">
            {{'ACCOUNT.CHANGE_PASSWORD' | transloco}}
          </h4>

          <div class="row mb-3">
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.post-code">
                  {{'ACCOUNT.OLD_PASSWORD' | transloco}}
                </label>
                <div class="field-with-btn">
                  <input [type]="seeOldPwd" class="form-control kpn-form-control" formControlName="old_password">
                  <button class="btn kphicon-uniE94C pw-hide-show" *ngIf="seeOldPwd == 'password'; else showBtn"
                    (click)="seeOldPwd = 'text'"></button>
                  <ng-template #showBtn>
                    <button class="btn kphicon-uniE9A1 pw-hide-show" (click)="seeOldPwd = 'password'"></button>
                  </ng-template>
                  <div class="danger-text" *ngIf="passwordForm?.get('old_password')?.hasError('required') && passwordForm?.get('old_password').touched">
                    Current Password is required
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.house-num">
                  {{'ACCOUNT.PASSWORD' | transloco}}
                </label>
                <div class="field-with-btn">
                  <input [type]="seePwd" class="form-control kpn-form-control" formControlName="new_password">
                  <button class="btn kphicon-uniE94C pw-hide-show" *ngIf="seePwd == 'password'; else showBtn1"
                    (click)="seePwd = 'text'"></button>
                  <ng-template #showBtn1>
                    <button class="btn kphicon-uniE9A1 pw-hide-show" (click)="seePwd = 'password'"></button>
                  </ng-template>
                  <div class="danger-text" *ngIf="passwordForm?.get('new_password')?.hasError('required') && passwordForm?.get('new_password').touched">
                    Password is required
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.get('new_password')?.hasError('length') && passwordForm?.get('new_password').touched">
                    You password must be at least 10 characters.
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.get('new_password')?.hasError('numberError') && passwordForm?.get('new_password').touched">
                    You password must contain at least 1 number.
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.get('new_password')?.hasError('upperCaseError') && passwordForm?.get('new_password').touched">
                    You password must contain at least 1 Uppercase Character.
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.get('new_password')?.hasError('lowerCaseError') && passwordForm?.get('new_password').touched">
                    You password must contain at least 1 Lowercase Character.
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.get('new_password')?.hasError('specialCharacterError') && passwordForm?.get('new_password').touched">
                    You password must contain at least 1 special character.
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.house-num">
                    {{'ACCOUNT.RE_TYPE_PASSWORD' | transloco}}
                </label>
                <div class="field-with-btn">
                  <input [type]="seeCnfrmPwd" class="form-control kpn-form-control" formControlName="confirm_password">
                  <button class="btn kphicon-uniE94C pw-hide-show" *ngIf="seeCnfrmPwd == 'password'; else showBtn2"
                    (click)="seeCnfrmPwd = 'text'"></button>
                  <ng-template #showBtn2>
                    <button class="btn kphicon-uniE9A1 pw-hide-show" (click)="seeCnfrmPwd = 'password'"></button>
                  </ng-template>
                  <div *ngIf="pwdErrors?.confirm_pwd" class="danger-text">
                    {{ pwdErrors?.confirm_pwd }}
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.get('confirm_password')?.hasError('required') && passwordForm?.get('confirm_password').touched">
                    Confirm Password is required
                  </div>
                  <div class="danger-text" *ngIf="passwordForm?.hasError('passwordsMismatch') && passwordForm?.get('confirm_password').touched">
                    Passwords do not match
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mb-3 danger-text" *ngIf="pwdErrors?.old_password">
            <div class="col-md-12 col-6 col-gap-reduce">
              {{ pwdErrors?.old_password }}
            </div>
          </div>
          <div class="row align-items-center mb-3">
            <ul>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_1' | transloco}}.</li>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_2' | transloco}}.</li>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_3' | transloco}}.</li>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_4' | transloco}}.</li>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_5' | transloco}}.</li>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_6' | transloco}}.</li>
              <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_7' | transloco}}.</li>
            </ul>
          </div>
          <div class="row justify-content-end mb-4">
            <div class="col-md-auto col-6 col-gap-reduce">
              <button type="button" class="btn px-3 mt-2 rounded-btn" i18n="@@arm.order-status.search-btn"
                (click)="setPasswordForm()" [disabled]="passwordForm.invalid"> {{'ACCOUNT.CANCEL' | transloco}}</button>
            </div>
            <div class="col-md-2 col-gap-reduce">
              <button type="button" class="btn btn-block btn-primary mt-2 rounded-btn"
                i18n="@@arm.order-status.search-btn" (click)="updatePassword()"
                [disabled]="passwordForm.invalid">{{'ACCOUNT.CHANGE_PASSWORD' | transloco}}</button>
            </div>
          </div>
        </form>
      </div>
      <!-- <div class="customer-search kpn-card bg-kpn-light mb-4">
        <div class="custom-select-option custom-selectc-cataloge">
          <h4 class="title-4 green-text pt-1 mb-3" i18n="@@arm.order-status.serc">
            {{'ACCOUNT.DEFAULT_CATALOG_SET' | transloco}}
          </h4>
          <div class="row align-items-center">
            <div class="col-md-5 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">
                  {{'ACCOUNT.CATALOG' | transloco}}
                </label>
                <select [(ngModel)]="defaultCatalogCode" (ngModelChange)="checkModelChanged($event)"
                  class="form-control">
                  <option *ngFor="let item of catalogList" [ngValue]="item?.code">{{item?.name}}
                  </option>
                </select>
              </div>
            </div>
            <div class="col-md-2 col-6 col-gap-reduce">
              <button (click)="setDefaultCatalog()" [disabled]="!isCatalogChanged || isUpdating"
                class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                <span *ngIf="!isUpdating; else showSpinner">
                  {{'ACCOUNT.SUBMIT' | transloco}}
                </span>
                <ng-template #showSpinner>
                  <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                </ng-template>
              </button>
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </section>
</div>
