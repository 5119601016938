import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { IAppState } from 'src/app/app.state';
import { ApiBaseService } from 'src/app/shared/api-base.service';
import { AuthService } from 'src/app/shared/auth.service';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';

@Component({
  selector: 'app-login-azure',
  templateUrl: './login-azure.component.html',
  styleUrls: ['./login-azure.component.scss']
})
export class LoginAzureComponent {
  constructor(
    private apiBaseService: ApiBaseService,
    private authService: AuthService,
    private router: Router,
    private toaster: ToastrService,
    private store: Store<IAppState>
  ) {
    this.store.select(selectSiteProfile).subscribe(data => {
      const siteProfileLoaded =   data !== null && typeof data === 'object' && !Array.isArray(data);
      if (siteProfileLoaded && (!data?.allow_azure_login)) {
        this.toaster.error('Azure login is not allowed in this site profile', 'Error', { timeOut: 3000 });
        return this.router.navigate(['/login']);
      }
    });
  }

  ngOnInit(): void {
    if (this.authService.isLoggedIn() && this.authService.hasUser()) {
      this.router.navigate(['/home']);
    }else {
      this.loginWithMicrosoft();
    }
  }
  loginWithMicrosoft() {
    const url = 'accounts/azure/authorization-url/';
    this.apiBaseService.get(url).subscribe((res) => {
      window.open(res.data.url, '_self');
    }, (error) => {
      this.router.navigate(['/login']);
      this.toaster.error(`${error?.error?.message || 'Error while login with Microsoft'}`, 'Error', { timeOut: 3000 });
    });
  }

}
