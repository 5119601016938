import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-grip-start',
  templateUrl: './grip-start.component.html',
  styleUrls: ['./grip-start.component.scss']
})
export class GripStartComponent implements OnInit, AfterViewInit, OnDestroy {

  public redirect_url = '';


  constructor(private route: ActivatedRoute) {
    this.route?.queryParams.subscribe(qp => {
      this.redirect_url = qp?.redirect_url || environment.gripUrl;
    })
  }


  ngOnInit(): void {
    if (this.redirect_url) {
      this.loginWithGrip();
    }
  }

  ngAfterViewInit(): void { }

  loginWithGrip() {
    window.open(this.redirect_url, '_self');
  }

  ngOnDestroy(): void {
  }

}
