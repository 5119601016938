<div class="bg-kpn-subtle-grey body-container">
    <section class="res-search-section res-search-section-calc pt-5">
        <div class="container">
            <div class="customer-search kpn-card bg-kpn-light" *ngIf="SMSCampaignPermission.add">
                <div class="row align-items-center p-2">
                    <div class="col-md-10">
                        <div class="mb-3 p-4">
                            <h4 class="title-4 mb-3 green-text">{{'QUOTES.IMPORT' | transloco}} {{'ORDER_STATUS.FILE' | transloco }}</h4>
                            <form [formGroup]="smsCampaignForm">
                                <div class="row align-items-center">
                                    <div class="col-md-5 col-5 col-gap-reduce">
                                        <div class="form-group kpn-form-group">
                                            <label>{{'REPORT.TITLE' | transloco}}</label>
                                            <input type="text" class="form-control" formControlName="title">
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-4 col-gap-reduce">
                                        <div class="form-group kpn-form-group">
                                            <label>File</label>
                                            <input #fileInput type="file"
                                                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                                                (change)="onFileChange($event)">
                                        </div>
                                    </div>
                                    <div class="col-md-2 col-2 col-gap-reduce">
                                        <button (click)="importMobileNumber()"
                                            [disabled]='validatingFile || !smsCampaignForm?.valid || !smsCampaignForm?.dirty'
                                            class="btn btn-block btn-primary mt-2 rounded-btn">
                                            <div *ngIf="validatingFile"
                                                class="spinner-border spinner-border-sm field-spinner mr-1"
                                                role="status">
                                            </div>
                                            <span>{{'QUOTES.IMPORT' | transloco}}</span>
                                        </button>
                                    </div>
                                    <div class="col-md-12 col-12 col-gap-reduce" *ngIf="importError">
                                        <div
                                            class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                                            <p>
                                                <span class="kphicon-uniE952"></span>
                                                {{importError}}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div class="loader-wrapper box-shadow-sm" [ngClass]="{'spinner-wrapper-height': isLoading}">
                <div class="table-responsive custom-table">
                    <h3 class="title-3 green-text ml-4 pt-3" i18n="@@arm.order-status.order-list">{{'SMS_CAMPAIGN.PRODUCT_LIST' | transloco}}:</h3>
                    <div class="kpn-spinner" *ngIf="isLoading;else table_content">
                        <div class="text-center">
                            <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
                            <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
                        </div>
                    </div>
                    <ng-template #table_content>
                        <div *ngIf="campaigns?.length">
                            <table class="table mb-5">
                                <thead>
                                    <tr>
                                        <th>{{'REPORT.TITLE' | transloco}}</th>
                                        <th>Contact</th>
                                        <th>{{'SMS_CAMPAIGN.CREATED' | transloco}}</th>
                                        <th>{{'SMS_CAMPAIGN.UPDATED' | transloco}}</th>
                                        <th class="text-center">File</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let campaign of campaigns | paginate: config">
                                        <td>{{campaign.title|slice:0:80}}</td>
                                        <td>{{campaign.contact?.name}}</td>
                                        <td>
                                            {{campaign?.created_by}} ({{campaign.created_at | date: 'dd-MM-yyyy HH:mm'}})
                                        </td>
                                        <td>
                                            {{campaign?.updated_by}} ({{campaign.updated_at | date: 'dd-MM-yyyy HH:mm'}})
                                        </td>
                                        <td class="text-center">
                                            <button type="button" class="btn btn-sm btn-link"
                                                (click)="downloadDocuments(campaign)">
                                                <i class="{{ utilityService?.getFileExtension(campaign?.file) }}"
                                                    style="color: #5cb85c" aria-hidden="true"></i>
                                                <i class="kpn-ui-icon ui-download v-text-bottom mr-2"
                                                    style="color: #5cb85c"></i>
                                            </button>
                                        </td>
                                        <td class="text-right">
                                            <button *ngIf="!campaign?.is_kpn_dev_credentials_added" type="button"
                                                class="btn btn-sm btn-primary"
                                                (click)="onSetAccountType(campaign, setAccountTypeModal)">
                                                {{ 'SMS_CAMPAIGN.SET_ACCOUNT_TYPE' | transloco }}
                                            </button>

                                            <button
                                                *ngIf="campaign?.is_kpn_dev_credentials_added && !campaign?.is_all_sms_sent_successful"
                                                type="button" class="btn btn-sm btn-info mr-1"
                                                [disabled]='campaign?.sendingSms' (click)="SendSMS(campaign)">
                                                <div *ngIf="campaign?.sendingSms"
                                                    class="spinner-border spinner-border-sm field-spinner mr-1"
                                                    role="status">
                                                </div>
                                                <span><small> {{'HEADER.SEND' | transloco}} SMS </small></span>
                                            </button>

                                            <button
                                                *ngIf="SMSCampaignPermission?.view_sms && campaign?.is_kpn_dev_credentials_added"
                                                type="button" class="btn btn-sm btn-success mr-1"
                                                (click)="viewSMS(campaign, viewSMSModal)">
                                                <span><small> {{'QUOTES.TO_LOOK_AT' | transloco}} SMS </small></span>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <div class="pagination-new">
                                <pagination-controls id="campaign_pagination" previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                                    (pageChange)="onPageClick($event)"></pagination-controls>
                            </div>
                        </div>
                        <div class="row g-4 mt-4 p-3" *ngIf="!campaigns?.length">
                            <div class="col-md-11">
                                <div class="alert alert-info" role="alert">
                                    <span> {{ 'SMS_CAMPAIGN.NO_SMS_CAMPAIGN_AVAIABLE' | transloco }}. </span>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                </div>
            </div>
        </div>
    </section>
    <ng-template #setAccountTypeModal let-modal>
        <div class="modal-header">
            <h6 class="modal-title title-2 green-text" id="setAccountType" i18n="@@arm.order-status.order-details">
              {{ 'SMS_CAMPAIGN.SET_ACCOUNT_TYPE' | transloco }}
            </h6>
            <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="row align-items-center p-2">
                <div class="col-md-12">
                    <div class="mb-3 p-4">
                        <form [formGroup]="accountTypeForm">
                            <div class="row align-items-center">
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{'SMS_CAMPAIGN.ACCOUNT_TYPE_NAME' | transloco}}</label>
                                        <input type="text" class="form-control" formControlName="account_name">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{ 'SMS_CAMPAIGN.ACCOUNT_TYPE_ID' | transloco}}<b class="danger-text">*</b> </label>
                                        <input type="text" class="form-control" formControlName="account_id">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <div class="form-group kpn-form-group">
                                        <label>{{'SMS_CAMPAIGN.ACCOUNT_TYPE_SECRET' | transloco}}<b class="danger-text">*</b></label>
                                        <input type="text" class="form-control" formControlName="account_secret">
                                    </div>
                                </div>
                                <div class="col-md-12 col-12 col-gap-reduce">
                                    <button (click)="addAccountType()"
                                        [disabled]='addingAccountType || !accountTypeForm?.valid || !accountTypeForm?.dirty'
                                        class="btn btn-block btn-primary mt-2 rounded-btn">
                                        <div *ngIf="addingAccountType"
                                            class="spinner-border spinner-border-sm field-spinner mr-1" role="status">
                                        </div>
                                        <span>{{'ADD_CUSTOMER.ADD' | transloco}}</span>
                                    </button>
                                </div>

                                <div class="col-md-12 col-12 col-gap-reduce pt-2" *ngIf="accountTypeAddError">
                                    <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                                        <p>
                                            <span class="kphicon-uniE952"></span>
                                            {{accountTypeAddError}}
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #viewSMSModal let-modal>
        <div class="modal-header">
            <h6 class="modal-title title-2 green-text" id="viewSms">
                SMS {{'SMS_CAMPAIGN.FOR' | transloco}} {{selectedCampaign?.title}}
            </h6>
            <button type="button" class="btn close" aria-label="Close" (click)="modal.close(); selectedCampaign =null">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>

        <div class="modal-body">
            <div class="table-responsive custom-table">
                <div class="row align-items-center p-2">
                    <div class="col-md-12">
                        <div class="mb-3 p-4">
                            <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                                <div class="kpn-form-group row trade-in-search">
                                    <div class="col-md-6 mb-3 mb-md-0 input-group">
                                        <input type="text" [(ngModel)]="search"
                                            class="form-control kpn-form-control px-4"
                                            placeholder="Zoeken met mobiel nummer" (input)="onInputChange()"
                                            (keyup.enter)="searchSMS(search, viewSMSModal)">
                                        <button type="button" (click)="clearInput(viewSMSModal)"
                                            class="btn bg-transparent" style="margin-left: -40px; z-index: 100;">
                                            <i *ngIf="search?.trim()?.length" class="kphicon-uniE993 icon-size-md"
                                                style="color: #1f211f" aria-hidden="true"></i>
                                        </button>
                                    </div>
                                    <div class="col-md-auto">
                                        <button [disabled]="!search?.trim()?.length"
                                            class="btn px-5 btn-block btn-sm btn-info rounded-btn"
                                            (click)="searchSMS(search, viewSMSModal)">{{'CUSTOMERS.SEARCH' | transloco}}</button>
                                    </div>
                                </div>
                                <div class="kpn-form-group row trade-in-search pl-3">
                                    <small class="text-muted pt-0"> {{'SMS_CAMPAIGN.ENTER_THE_MOBILE_NUMBER_AND_PRESS' | transloco}} <b>
                                      {{'ORDER_OVERVIEW.GIVE' | transloco }}</b>
                                    </small>
                                </div>
                            </div>
                            <div *ngIf="campaignSMS?.length">
                                <table class="table mb-5">
                                    <thead>
                                        <tr>
                                            <th>{{'ORDER_STATUS.MOBILE' | transloco }} #</th>
                                            <th>{{'SMS_CAMPAIGN.MESSAGE' | transloco}}</th>
                                            <th>{{'SMS_CAMPAIGN.CREATED' | transloco}}</th>
                                            <th>{{'QUOTE_DETAILS.STATUS' | transloco}}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let sms of campaignSMS | paginate: smsConfig">
                                            <td>
                                                <p>
                                                    <small>
                                                        <samp>
                                                            {{sms.number}}
                                                        </samp>
                                                    </small>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <small>
                                                        <samp>
                                                            {{sms.message}}
                                                        </samp>
                                                    </small>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <small>
                                                        <samp>
                                                            {{sms?.created_by}} ({{sms.created_at | date:
                                                            'dd-MM-yyyy:HH:mm'}})
                                                        </samp>
                                                    </small>
                                                </p>
                                            </td>
                                            <td>
                                                <p>
                                                    <samp>
                                                        <span class="badge"
                                                            [ngClass]="sms.status == 'sent' ? 'badge-success' : 'badge-primary'">
                                                            {{sms.status | titlecase}}
                                                        </span>
                                                    </samp>
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="pagination-new">
                                    <pagination-controls id="campaign_sms_pagination" previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination" (pageChange)="onSmsPageClick($event, viewSMSModal)"></pagination-controls>
                                </div>
                            </div>
                            <div class="row g-4 mt-4 p-3" *ngIf="!smsLoading && !campaignSMS?.length">
                                <div class="col-md-12">
                                    <div class="alert alert-info" role="alert">
                                        <span> {{'SMS_CAMPAIGN.NO_PRODUCT_WAS_FOUND_IN_THE_CATALOGUE' | transloco}} </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>
</div>
