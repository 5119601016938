<div class="auth-wrapper">
  <div class="auth-gradient">
    <div class="auth-box">
      <div class="auth-logo text-center">
        <img [src]="logoSrc" alt="logo">
      </div>
      <div class="auth-form-box">
        <div class="form-group kpn-form-group text-center">
          <div *ngIf="qrData">
            <p style="font-size: 15px">
              {{'TWO_FACTOR_AUTH.USE_MOBILE_DEVICES_CAMERA_TO_SCAN_AND_DOWNLOAD_THE_AUTHENTICATOR' | transloco}}.
            </p>
            <qrcode [qrdata]="qrData" [width]="300" [errorCorrectionLevel]="'M'"></qrcode>
            <p>{{ secretCode }}</p>
          </div>
          <input type="code" class="form-control kpn-form-control" [placeholder]="otpPlaceHolder"
            [(ngModel)]="securityCode" (keyup.enter)="handleKeyUp()" />
          <p *ngIf="errorMsg" class="login-error">
            {{ errorMsg }}
          </p>
        </div>
        <div class="row">
          <div class="col-sm-5">
            <button [disabled]="isLoading" (click)="submitSecurityCode()"
              class="btn btn-primary btn-block rounded-btn px-3">
              <span>{{'TWO_FACTOR_AUTH.SEND' | transloco}}</span>
            </button>
          </div>
          <!-- !INFO : This button is disabled now due to BE dependency  -->
          <div class="col-sm pl-md-0">
            <div class="btn-group btn-group-toggle toggle-pill-btns">
              <label class="btn" [ngClass]="{ active: selectedOtpType === 'sms' }">
                <input type="radio" [disabled]="isLoading" name="two-fa-option" id="option-sms" value="sms"
                  autocomplete="off" [checked]="selectedOtpType === 'sms'" (click)="sendSMS('sms')" />
                {{'TWO_FACTOR_AUTH.SEND_SMS' | transloco}}
              </label>
              <label class="btn" [ngClass]="{ active: selectedOtpType === 'email' }">
                <input type="radio" [disabled]="isLoading" name="two-fa-option" value="email" id="option-mail"
                  [checked]="selectedOtpType === 'sms'" autocomplete="off" (click)="sendSMS('email')" />
                {{'TWO_FACTOR_AUTH.SEND_EMAIL' | transloco}}
              </label>
            </div>
          </div>
        </div>
        <div class="text-center pt-4" *ngIf="msg">
          <h6>{{ msg }}</h6>
        </div>
      </div>
    </div>
  </div>
</div>
