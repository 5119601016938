<section class="pb-4 bg-white">
<div class="container">
  <!-- Breadcrumb -->
 <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-2" >
  <!-- <li class="back-breadcrumb-btn">
    <a class="cursor-pointer" [routerLink]="['/quotes']" >
      <i class="kphicon-uniE906"></i> Terug
    </a>
  </li> -->
  <li class="home-btn ml-0 pr-1 pb-1">
    <a routerLink="/">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="UI Icon">
          <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
            d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
            fill="#939393" />
        </g>
      </svg>
    </a>
  </li>
  <li  role="button"> <a class="grey-text" >Offerte overzicht</a></li>
  <li> <a> Bedankt voor je bestelling</a></li>
</ul>

  <div class="pt-3">
    <app-customer-quote-steps [showConfigTab]="showConfigTab"   [activeStep]="5" [completedSteps]="[1,2,3,4]" />
  </div>

  <h3 class="font-kpn-black green-text fs-40 mb-3 pt-3">Bedankt voor je bestelling</h3>
  <div class="row mb-3">
    <div class="col-md-8">
      <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24 mb-3">
        <h4 class="font-kpn-extended kpn-extended-black fs-32 green-text letter-spacing-normal mb-3 pt-3">We gaan gelijk voor je aan de slag! </h4>
        <h5 class="font-kpn-extended kpn-extended-bold fs-18 letter-spacing-normal mb-1">Je bestelling met ordernummer #{{orderNumber }} is succesvol geplaatst.</h5>
        <p class="mb-0 font-kpn-extended">We verwerken je bestelling zo snel mogelijk. Heb je vragen? Bekijk onze <a [routerLink]="['/faq']" class="fs-16 font-kpn-extended link-btn-blue">FAQ-pagina</a>.</p>
        <p class="pt-1 mb-3">
          <a (click)="goToOrderDetails()" class="fs-16 font-kpn-extended link-btn-blue cursor-pointer">
            Mijn bestelling bekijken <i class="kpnew-icon-arrow-new-right-2 fs-18 align-middle"></i></a>
        </p>

        <h5 class="font-kpn-extended kpn-extended-bold fs-18 letter-spacing-normal mb-3">Vervolgstappen</h5>
        <div class="pb-3">
          <div class="d-flex position-relative step-vr-line-item">
            <div class="step-vr-line">
              <span class="bg-green text-white step-line-circle active">
                <i class="kphicon-uniE91D fs-16"></i>
              </span>
            </div>
            <div>
                <label for="" class="d-block font-kpn-extended">Bestelling is geplaatst</label>
              <span class="fs-12 grey-text d-block">{{ orderDate | date : "dd-MM-yyyy" }}</span>
            </div>
          </div>
          <div class="d-flex position-relative step-vr-line-item">
            <div class="step-vr-line">
              <span class="bg-green text-white step-line-circle">
                <!-- <i class="kphicon-uniE91D fs-16"></i> -->
              </span>
            </div>
            <div>
              <label for="" class="d-block font-kpn-extended mb-0 pr-md-5">Je bestelling wordt verwerkt. Je ontvangt een e-mail met een track & trace-code om je pakket te volgen.</label>
              <!-- <span class="fs-12 grey-text d-block">05-04-2024</span> -->
            </div>
          </div>
        </div>

      </div>

      <p class="pt-2">
        <a (click)="navigateToQuoteDetails()" class="fs-16 pl-0 font-kpn-extended link-btn-blue">
          <i class="kpnew-icon-arrow-lingt-2 fs-18 align-middle"></i> Terug naar offerte overzicht</a>
      </p>
    </div>
  </div>
</div>

</section>
