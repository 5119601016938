import { Injectable } from '@angular/core';
import { HttpParams } from "@angular/common/http";
import { ApiBaseService } from '../../shared/api-base.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class HandsetsService {

  handSetUri = `products`;
  productType = `handset`;

  constructor(private apiBaseService: ApiBaseService, private router: Router) { }

  getHandSets() {
    let paramsString = `product_type=${this.productType}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(this.handSetUri, params);
  }

  goToProductDetails(product_code: string) {
    this.router.navigate(['/product-details', `${product_code}`]);
  }

}