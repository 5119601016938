<section class="inner-content-wrapper inner-content-blue-bg">
  <div class="container">
    <div class="new-cart-box p-0 mb-3">
      <div class="item-row-head">
        <!-- <div class="row mb-3 gutter-row-15" *ngIf="!loggedInUser?.is_external">
          <div class="col gutter-col-15">
            <div class="kpn-form-group">
              <label for="" class="field-label">Bedrijfsnaam</label>
              <ng-autocomplete class="" [data]="searchCompanyList" placeHolder='Search Klant' [(ngModel)]='selectedCompanyName'
                [searchKeyword]="keyword" (selected)='onSelectedCompany($event)'
                (inputChanged)='searchCompanies($event)' (inputFocused)='onFocused($event)'
                [itemTemplate]="itemTemplate" (inputCleared)="searchCleared()" [debounceTime]="600"
                [notFoundTemplate]="notFoundTemplate">
              </ng-autocomplete>

              <ng-template #itemTemplate let-item>
                <a [innerHTML]="item.company_name"></a>
              </ng-template>

              <ng-template #notFoundTemplate let-notFound>
                <div [innerHTML]="notFound"></div>
              </ng-template>
            </div>
          </div>
        </div> -->
        <div class="loader-wrapper" [ngClass]="{'spinner-wrapper-height': isLoading}">
          <div class="table-responsive custom-table">
            <h3 class="title-3 green-text ml-1 pt-3">{{'DEPOT_HISTORY.CONSUMPTION_OVERVIEW_DEPOT' | transloco}}:</h3>
            <p class="title-5 ml-1 pt-3">{{'DEPOT_HISTORY.AVAILABLE_AMOUNT' | transloco}}: {{ currentValue | currencyFormat }}</p>
            <div class="kpn-spinner" *ngIf="isLoading;else table_content">
              <div class="text-center">
                <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
                <p>{{'DEPOT_HISTORY.SEARCH' | transloco}}…</p>
              </div>
            </div>
            <ng-template #table_content>
              <table class="table mb-5" *ngIf="!depotError && selectedContact">
                <thead>
                  <tr>
                    <th>{{'DEPOT_HISTORY.DATE' | transloco}}</th>
                    <th>{{'DEPOT_HISTORY.ORDER_REFERENCE' | transloco}}</th>
                    <th>{{'DEPOT_HISTORY.REASON' | transloco}}</th>
                    <th>{{'DEPOT_HISTORY.VERIFIED_BY' | transloco}}</th>
                    <th>{{'DEPOT_HISTORY.VERIFY_REMARK' | transloco}}</th>
                    <th class="text-right">{{'DEPOT_HISTORY.AMOUNT' | transloco}}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let depot of depotHistories | paginate: config">
                    <td>
                      {{depot?.created_at | date:'d-M-yyyy HH:mm'}}
                    </td>
                    <td>
                      <a *ngIf="depot?.order" href="javascript:void(0)" (click)="orderDetails(depot?.order)"
                        class="blue-link">{{depot.order?.reference}}</a>
                    </td>
                    <td>
                      {{depot.reason}}
                    </td>
                    <td>
                      {{depot.verified_by}}
                    </td>
                    <td>
                      {{depot.verify_remark}}
                    </td>
                    <td class="text-right">
                      {{depot.value | currencyFormat}}
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="pagination-new" *ngIf="!depotError && selectedContact">
                <pagination-controls  previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true"  class="pagination" (pageChange)="onPageClick($event)"></pagination-controls>
              </div>
              <div class="pagination-new" *ngIf="depotError">
                <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                  <h6>
                    <span class="kphicon-uniE952"></span>
                    {{depotError}}
                  </h6>
                </div>
              </div>
              <div class="pagination-new" *ngIf="!selectedContact">
                <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                  <h6>
                    <span class="kphicon-uniE952"></span>
                    {{customerSelectionMessage}}
                  </h6>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
