<section class="pb-4 bg-white">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div class="container">
    <!-- Breadcrumb -->
 <ul class="tri-breadcrumb font-kpn-extended m-0 px-0 pt-3 mb-2" >
  <li class="back-breadcrumb-btn">
    <a class="cursor-pointer" [routerLink]="['/quotes']" >
      <i class="kphicon-uniE906"></i> Terug
    </a>
  </li>
  <li class="home-btn ml-2 px-1 pb-1">
    <a routerLink="/">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="UI Icon">
          <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
            d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
            fill="#939393" />
        </g>
      </svg>
    </a>
  </li>
  <li  role="button"> <a class="grey-text" >Offerte overzicht</a></li>
  <li> <a> Offerte #{{ quote?.reference }}</a></li>
</ul>

      <div class="mb-4 pb-1" *ngIf="eQuoteStatus.APPROVED === quote?.status">
        <app-customer-quote-steps [activeStep]="2" [completedSteps]="[1]" />
      </div>



    <div class="row">
      <div class="col-md-12">
        <h3 class="font-kpn-black green-text fs-40 mb-3">Gegevens aanvullen & betalen</h3>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col-md pr-md-0">

        <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 c-px-24 mb-4">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">
            Levering & contacten </h4>

          <div class="row gutter-row-24">
            <div class="col-md-6 mb-4 gutter-col-24">
              <app-customer-quote-address />
            </div>
            <div class="col-md-6 mb-4 gutter-col-24">
              <app-customer-quote-contact-person />
            </div>
            <div class="col-md-12 mb-4 gutter-col-24" *ngIf="hasCpaas && isCpassDataAdded">
              <app-customer-quote-cpass (updateCpassDataEventEmitter)="updateCpassDataFromChild($event)"  />
              <!-- <div class="bg-gray-1 tri-rounded-20 p-24 h-100">
                <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0">CPaaS
                  hoofdgebruiker
                  <i class="kphicon-uniE952 align-middle fs-20 blue-text mr-1"></i>
                </label>
                <div class="row justify-content-between align-items-center">
                  <div class="col-md">
                    <div class="min-height-100">
                      <p class="font-kpn-extended mb-2">
                        <span class="d-block">{{ cPassData?.cpaas_user_first_name }} {{ cPassData?.cpaas_user_last_name
                          }}</span>
                        <span class="d-block"> {{ cPassData?.cpaas_user_email }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <p class="mb-0">
                  <a href="javascript:void(0)" (click)="openCpaasDataUpdateModal(cpaasDataUpdateModal)"
                    class="fs-16 font-kpn-extended link-btn-blue">
                    Wijzig CPaaS contactpersoon <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
                </p>
              </div> -->
            </div>
          </div>
        </div>



        <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24 mb-3">
          <h4 class="mb-3 pb-1 pt-1">
            <span class="font-kpn-extended kpn-extended-black letter-spacing-normal fs-35 green-text">
              Goedkeuringsgegevens
            </span>
            <div class="tooltip-wrapper">
              <i class="kphicon-uniE952 align-middle fs-24 blue-text mr-1"></i>
              <span
                class="placement-bottom-left fs-16 line-height-normal custom-tooltip-content font-kpn-extended kpn-extended-normal kpn-font-color w-315">
                Je kunt hier je organisatiegegevens invoeren. Ze worden ook op de factuur weergegeven.
              </span>
            </div>
          </h4>
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-6 gutter-col-15" *ngIf="siteProfile?.is_po_number_required">
              <label for="" class="font-kpn-extended">
                <span class="font-kpn-extended kpn-extended-bold">
                  PO Nummer<span class="text-danger font-stlyle-noraml">*</span>
                </span>(min 5/max 50 karakters)
              </label>
              <input type="text" class="form-control tri-form-control"
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.po_number }" placeholder="PO Nummer"
                (keyup)="onChangeQuotePoNumber($event)" [(ngModel)]="po_number">
              <div *ngIf="feRequiredFieldErrors.po_number" class="invalid-feedback fs-16 font-kpn-extended">{{
                feRequiredFieldErrors.po_number }}</div>
            </div>
            <div class="col-md-6 gutter-col-15" >
              <label for="" class="font-kpn-extended">
                <span class="font-kpn-extended kpn-extended-bold">
                  Referentie<span class="text-danger font-stlyle-noraml">*</span>
                </span>(min 5/max 50 karakters)
              </label>
              <input type="text" class="form-control tri-form-control" placeholder="Referentie"
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.customer_reference }"
                (keyup)="onChangeQuoteCustomerReference($event)" [(ngModel)]="customer_reference">
              <div *ngIf="feRequiredFieldErrors.customer_reference" class="invalid-feedback fs-16 font-kpn-extended">{{
                feRequiredFieldErrors.customer_reference }}</div>
            </div>
          </div>
        </div>


        <div class="tri-shadow-xl tri-rounded-20 bg-white pt-3 c-px-24 mb-4" *ngIf="quote?.is_contact_account_type_required && (hasViewContactAccountTypeCustomer ? (contactAccountTypesCustomers?.length) : true)">

          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-3 pb-1 pt-1">
            Enrollment
            <div class="tooltip-wrapper">
              <i class="kphicon-uniE952 align-middle fs-24 blue-text mr-1"></i>
              <span
                class="placement-bottom-left fs-16 line-height-normal custom-tooltip-content font-kpn-extended kpn-extended-normal kpn-font-color w-315">
                Enrollment koppelt je apparaten aan je onderneming, waardoor registratie en beheer eenvoudiger worden.
              </span>
            </div>
          </h4>
          <div class="row gutter-row-15 mb-4" *ngIf="contactAccountTypesCustomers?.length">
            <div class="col-md-6 gutter-col-15">
              <label for="" class="font-kpn-bold mb-2" *ngIf="hasViewContactAccountTypeCustomer">Selecteer een klant </label>
              <div  *ngIf="contactAccountTypesCustomers?.length">
                <ng-select [placeholder]="'Selecteer een klant'" class="tri-form-control tri-form-control-select-op" [items]="contactAccountTypesCustomers"
                  bindLabel="name" bindValue="uuid" [(ngModel)]="selectedContactAccountTypesCustomer"
                  (ngModelChange)="onContactAccountTypesCustomerChange($event)">
                </ng-select>
              </div>
            </div>
          </div>
          <div class="row gutter-row-24">
            <div class="col-md-6 mb-4 gutter-col-24" *ngFor="let item of accountTypes">
              <div class="bg-gray-1 quote-enrollment-box tri-rounded-20 p-24">
                <div class="enrollment-brand-title">
                  <h4 class="font-kpn-extended kpn-extended-bold fs-18 letter-spacing-normal">{{ item?.account_name }}
                  </h4>
                  <!-- <p class="font-kpn-extended">IT-Button B.V.</p> -->
                </div>
                <div class="mb-1">
                  <div class="d-flex align-items-center justify-content-between grey-text">
                    <span>Enrollment ID</span>
                    <span>{{ item?.account_id }}</span>
                  </div>
                  <hr class="border-color-tri my-2">
                  <div class="d-flex align-items-center justify-content-between grey-text">
                    <span>Mobiele telefoons</span>
                    <span>{{ item?.product_count }}</span>
                  </div>
                </div>
                <p class="mb-0 pt-3" *ngIf="item?.checked" >
                  <button (click)="addOrRemoveAccountTypeToQuote(item,'Remove')" class="btn btn-block btn-outline-primary border-2 rounded-btn px-4">Verwijder
                    enrollment</button>
                </p>
                <p class="mb-0 pt-3" *ngIf="!item?.checked">
                  <button (click)="addOrRemoveAccountTypeToQuote(item,'Add')" class="btn btn-block btn-primary border-2 rounded-btn px-4">
                    Voeg enrollment toe
                    <i class="kphicon-uniE985 align-middle fs-20 ml-1"></i>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>


        <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24 mb-3" *ngIf="hasCpaas && (!isCpassDataAdded)">
          <h4 class="mb-3 pb-1 pt-1">
            <span class="font-kpn-extended kpn-extended-black letter-spacing-normal fs-35 green-text">
              CPaas hoofdgebruiker
            </span>
          </h4>
          <div class="row gutter-row-15 mb-2">
            <div class="col-md-6 gutter-col-15 mb-3">
              <label for="" class="font-kpn-extended"><span
                  class="font-kpn-extended kpn-extended-bold">Voornaam</span><span class="text-danger">*</span></label>
              <input type="text" class="form-control tri-form-control is-invalid "
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_first_name }"
                [(ngModel)]="cPassData.cpaas_user_first_name" (keyup)="onCpassFirstNameChange($event)"
                placeholder="Voornaam">
              <span *ngIf="feRequiredFieldErrors.cpaas_user_first_name"
                class="invalid-feedback fs-16 font-kpn-extended">
                {{ feRequiredFieldErrors.cpaas_user_first_name }}
              </span>
            </div>
            <div class="col-md-6 gutter-col-15 mb-3">
              <label for="" class="font-kpn-extended"><span
                  class="font-kpn-extended kpn-extended-bold">Achternaam</span><span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control tri-form-control is-invalid invalid-bg-size-23"
                [(ngModel)]="cPassData.cpaas_user_last_name" (keyup)="onCpassLastNameChange($event)"
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_last_name}"
                placeholder="Achternaam">
              <span *ngIf="feRequiredFieldErrors.cpaas_user_last_name" class="invalid-feedback fs-16 font-kpn-extended">
                {{ feRequiredFieldErrors.cpaas_user_last_name }}
              </span>

            </div>
            <div class="col-md-6 gutter-col-15">
              <label for="" class="font-kpn-extended"><span
                  class="font-kpn-extended kpn-extended-bold">E-mailadres</span><span
                  class="text-danger">*</span></label>
              <input type="text" class="form-control tri-form-control is-invalid invalid-bg-size-23"
                [(ngModel)]="cPassData.cpaas_user_email" (keyup)="onCpassEmailChange($event)"
                [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_email}"
                placeholder="Tim.hermans@kpn.com">
              <span *ngIf="feRequiredFieldErrors.cpaas_user_email" class="invalid-feedback fs-16 font-kpn-extended">
                {{ feRequiredFieldErrors.cpaas_user_email }}
              </span>
            </div>
          </div>
        </div>



        <div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24 mb-3" *ngIf="termsAndConditions?.length">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-1 pt-1">De
            voorwaarden</h4>
          <div class="pt-3">
            <div *ngFor="let item of termsAndConditions"
              class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
              name="multiselect-items">
              <label ngbButtonLabel class="checkbox-item d-flex">
                <div class="checkbox-btn checkbox-btn-outline">
                  <input ngbButton type="checkbox" class="position-absolute opacity-0" [value]="item.code"
                    [(ngModel)]="item.checked" (ngModelChange)="onChangeTermAndCondition(item)">
                  <span class="checkBoxCheckmark"></span>
                </div>
                <div class="checkbox-label font-kpn-extended ml-2 pl-1 w-100" [innerHTML]="item?.description">

                </div>
              </label>
            </div>
          </div>
        </div>


      </div>
      <div class="col-md-auto">
        <div
          class="tri-shadow-xl quote-details-cart-overview quote-days-left tri-rounded-20 bg-white py-3 c-px-20 mb-3">
          <h4 class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pb-2 pt-2">
            Overzicht</h4>
          <div class="bg-gray-1 py-2 px-3 tri-rounded-16 mb-4">
            <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
              <h3 class="font-kpn-extended fs-16 mb-0 letter-spacing-normal">Totaalbedrag (excl. btw)</h3>
              <h3 class="font-kpn-extended fs-16 kpn-extended-bold mb-0 letter-spacing-normal">{{
                quote?.total_amount_ex_vat | currencyFormat }}</h3>
            </div>
            <hr class="my-2">
            <div class="pt-1">
              <div class="d-flex align-items-center pb-1 justify-content-between">
                <span class="font-kpn-extended fs-14 grey-text">Verzendkosten</span>
                <span class="font-kpn-extended fs-14 grey-text">{{ quote?.total_transport_cost_ex_vat | currencyFormat
                  }}</span>
              </div>
              <div class="d-flex align-items-center pb-1 justify-content-between">
                <span class="font-kpn-extended fs-14 grey-text">Btw</span>
                <span class="font-kpn-extended fs-14 grey-text">{{ quote?.total_amount_vat | currencyFormat }}</span>
              </div>
              <div class="d-flex align-items-center pb-1 justify-content-between">
                <span class="font-kpn-extended fs-14 grey-text">Totaal (incl. btw)</span>
                <span class="font-kpn-extended fs-14 grey-text">{{ quote?.total_amount | currencyFormat }}</span>
              </div>
            </div>
          </div>

          <div class="pb-2">
            <button class="btn btn-outline-primary btn-block border-2 px-4 mr-md-3 rounded-btn mt-3"
              (click)="openQuoteDetailsSummeryModal(quoteDetailsSummery)"> Bekijk offerte
              details</button>
            <button class="btn btn-primary btn-block rounded-btn px-4 mt-2" (click)="onPlaceOrder()">Betaal op
              rekening</button>
          </div>

        </div>
      </div>

    </div>

    <p class="pt-2">
      <a href="javascript:void(0)" [routerLink]="['/quotes']" class="fs-16 font-kpn-extended link-btn-blue">
        <i class="kphicon-uniE906 fs-26 align-middle"></i> Terug naar offerte details</a>
    </p>
    <!-- <button >show now</button> -->

  </div>



  <ng-template #quoteDetailsSummery let-modal>
    <div class="px-4 pt-4">
      <div class="modal-header tri-modal-header p-0 pb-4">
        <h3 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
          Offertedetails
        </h3>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span class="kphicon-uniE926"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <app-quote-details-summary  [showRejectButton]="false" [showPlaceOrderButton]="false"
        [showHeader]="false" [bottomZero]="true"></app-quote-details-summary>
    </div>
  </ng-template>


  <ng-template #creditLimitModal let-modal>
    <div class="px-4">
        <div class="px-1 modal-header tri-modal-header">
          <h6 class="modal-title green-text font-kpn-black fs-40" id="creditLimitModal">
            Kredietlimiet bereikt
          </h6>
        </div>
    </div>

    <div class="modal-body p-0">
      <div class="pl-4 pt-4">
        <div class="custom-inner-modal-scroll pr-4">
          <div class="error-qoute d-flex">
            <i class="kphicon-uniE952 note-icon mt-1 mr-3 "></i>
            <div>
              <label class="font-kpn-extended kpn-extended-bold mb-0 fs-20 letter-spacing-normal" for="">Je betaling is mislukt</label>
              <p class="font-kpn-extended m-0">
                Je hebt je kredietlimiet bereikt en kunt geen verdere bestellingen op rekening plaatsen. Neem contact op met onze klantenservice om je limiet te verhogen.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="p-4">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <a [routerLink]="['/quotes']"  class="btn btn-block btn-outline-primary border-2 rounded-btn px-4">
                <span>Ga terug naar offerte overzicht</span>
              </a>
            </div>
            <div class="col-md-auto gutter-col-8">
              <a [href]="helpLink"  (click)="modal.dismiss('Cross click')" class="btn btn-block btn-primary rounded-btn px-4">
                <span>Contact opnemen</span>
              </a>
            </div>
          </div>
      </div>
    </div>
  </ng-template>
</section>
