import { Component, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/storage.service';
import { QuoteDetailsService } from '../../../quote-details.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { AddressService } from 'src/app/shared/services/address.service';
import { ErrorHandleService } from 'src/app/shared/error-handle.service';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { ToastrService } from 'ngx-toastr';
import { TranslocoService } from '@ngneat/transloco';
import { AddCustomerService } from 'src/app/pages/add-customer/add-customer.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { Subscription, timer } from 'rxjs';
import { EAddressPrecision, EQuoteStatus } from 'src/app/shared/enums/order-status.enums';

@Component({
  selector: 'app-address-and-contact',
  templateUrl: './address-and-contact.component.html',
  styleUrls: ['./address-and-contact.component.scss']
})
export class AddressAndContactComponent  extends BasePermission  {
  quote = null;
  isLoading: boolean;
  removeUnderscoreMethod = this.utilityService.removeUnderscoreFromString;
  quoteCancelReasons = [];
  selectedQuoteCancelReason = null;
  quoteCancelReasonText = '';
  quoteShippingAddresses = [];
  contactAddresses = [];
  quoteContactPersons = [];
  selectedQuoteShippingAddress = null;
  selectedQuoteContactPerson = null;
  isCreatingAddress: boolean = false;
  isContactPersonCreating: boolean = false;
  temporalSelectedAddressIdForUpdateQuoteShippingAddress = null;
  temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = null;
  deliveryAddressModalRef = null;
  contactPersonModalRef = null;
  newContactPerson = {
    initials: '',
    first_name: '',
    last_name: '',
    surname_prefix: '',
    email: '',
    mobile_phone_number: ''
  };
  contactPersonCreateError = null;
  defaultNewAddress = {
    company_name: '',
    street: '',
    house_number: '',
    house_number_extension: '',
    zip_code: '',
    city: '',
    country: 'NL',
    address_type: 'shipping',
    extra_address_information_1: '',
    extra_address_information_2: ''
  }
  newAddress = { ...this.defaultNewAddress }
  NL_CODE = 'NL';
  selectedCountry = this.NL_CODE;
  public selectedCountryObject = null;
  addressCombinationMsg = null;
  addressFound = {
    zipcode: false,
    house_number: false,
    house_number_ext: false,
    city: false,
    street: false,
  };
  country = [];
  searchFocused = false;
  addressList = [];
  keyword = 'value';
  addressCreateValue = '';
  foreignAddressSearchError = null;
  searchValue = '';
  zipCodePattern = null;
  shipTimeOut = null;
  errors = null;

  showAddressModal = false;
  @ViewChild('auto') auto;
  private currentCustomerDetailsQuoteSubscription$: Subscription;
  constructor(
    private modalService: NgbModal,
    private quoteDetailsService: QuoteDetailsService,
    private utilityService: UtilityService,
    private renderer: Renderer2,
    private addressService: AddressService,
    private errorHandleService: ErrorHandleService,
    public store: Store<IAppState>,
    private toastrService: ToastrService,
    private translocoService: TranslocoService,
    private addCustomerService: AddCustomerService,
    private customerService: CustomerService,

  ) {
    super(store);
    this.zipCodePattern = this.utilityService.zipCodePattern();
   }
   get eQuoteStatus() { return EQuoteStatus; }
   get eAddressPrecision() {
     return EAddressPrecision;
   }
    ngOnInit() {
     this.getCountryList();
     this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe(quote => {
      console.log('====================================');
      console.log('quote: ', quote);
      console.log('====================================');
      this.quote = quote;
      if (quote) {
        this.getQuoteContactDetails();
      }
     });
   }
   get newAddressCreateDisabled() {
     return (
       this.isCreatingAddress && (!this.newAddress?.city?.trim()?.length ||
         !this.newAddress?.street?.trim()?.length ||
         !this.newAddress?.zip_code?.trim()?.length ||

         !(this.newAddress?.house_number ? this.newAddress.house_number + ''.trim()?.length : false) ||

         !this.newAddress?.company_name?.trim()?.length ||
         !this.addressFound.street ||
         !this.addressFound.city ||
         !this.addressFound.zipcode ||
         !this.addressFound.house_number)
     );
   }



   openNewAddressCreateView() {
     this.isCreatingAddress = true;

   }
   closeNewAddressCreateView() {
     this.isCreatingAddress = false;
     this.temporalSelectedAddressIdForUpdateQuoteShippingAddress = this.selectedQuoteShippingAddress?.id || null;

   }
   openNewContactPersonCreateView() {
     this.isContactPersonCreating = true;

   }
   closeNewContactPersonCreateView() {
     this.isContactPersonCreating = false;
   }
   onSelectCountry(event, update = false) {
     if (update) {
       this.selectedCountry = event?.country_code || '';
     }
     this.selectedCountryObject = event;
     // const newElement = document.getElementById('auto');
     // this.auto.nativeElement = newElement;
     this.resetForm();
     this.resetAddressFound(this.selectedCountry === this.NL_CODE ? false : true);
   }
   resetForm() {
     this.newAddress.street = '';
     this.newAddress.city = '';
     this.newAddress.zip_code = '';
     this.newAddress.house_number = '';
     this.newAddress.house_number_extension = '';
     this.newAddress.country = this.selectedCountry;
     this.newAddress.extra_address_information_1 = '';
     this.newAddress.extra_address_information_2 = '';
     this.addressCombinationMsg = null;
   }
   resetAddressFound(value: boolean = false) {
     for (const key in this.addressFound) {
       if (Object.prototype.hasOwnProperty.call(this.addressFound, key)) {
         this.addressFound[key] = value;
       }
     }
   }
   getCountryList() {
     if (this.country?.length) {
       return;
     }
     this.addressService.getCountry().subscribe(res => {
       this.country = res?.data?.results;
       this.setCountryObject();
     });
   }

   setCountryObject() {
     this.selectedCountryObject = this.country?.find(item => item?.country_code === this.selectedCountry) || null
   }
   searchAddressForeign(searchString: string, context = '') {
     if (searchString && searchString.length >= 3) {
       let term = `term=${searchString}`;
       term = context ? `context=${context}&term=${searchString}` : term;
       this.getAddress(term);
     }
   }
   getAddress(term) {
     this.foreignAddressSearchError = '';
     this.isLoading = true;
     this.addressService.getAddress(this.selectedCountry, term).subscribe(res => {
       this.isLoading = false;
       this.addressList = res?.data?.matches;
       const splitTerm = term?.split('term=');
       const n_term = splitTerm?.length > 1 ? splitTerm[1] : splitTerm[0];
       const find_src = this.addressList.some(item => {
         return item?.value?.includes(n_term);
       });
       if (!find_src) {

         this.auto.clear();
         timer(50).subscribe(time => {
           this.addressCreateValue = n_term;
         })
       };
       this.auto.open();
       this.isLoading = false;
     }, error => {
       this.foreignAddressSearchError = error?.error?.message;
       this.isLoading = false;
     });
   }

   onSelectedAddress(address) {
     timer(50).subscribe(time => {
       this.resetForm();
       if (address?.context && address?.precision === EAddressPrecision.ADDRESS) {
         this.searchValue = address.value;
         this.getAddressDetails(address?.context);
       } else {
         this.searchValue = address.value;
         this.auto.close();
         this.searchAddressForeign(this.searchValue, address?.context || '');
       }
     });
   }

   getAddressDetails(context) {
     const term = `context=${context}`;
     this.resetForm();
     this.resetAddressFound();
     this.addressService.getAddressDetails(this.selectedCountry, term).subscribe(res => {
       if (res?.data?.address) {
         this.newAddress.street = res?.data?.address?.street;
         this.newAddress.city = res?.data?.address?.locality;
         this.newAddress.zip_code = res?.data?.address?.postcode;
         this.newAddress.house_number = `${res?.data?.address?.buildingNumber}`;
         this.newAddress.house_number_extension = res?.data?.address?.buildingNumberAddition;
         this.newAddress.country = this.selectedCountry;
         this.checkSearchedAddressField(res?.data?.address);

         // this.onAddressStreetChange();
         // this.onAddressCityChange();
         // this.onAddressPostCodeChange();
         // this.onAddressHouseNoChange();
       }
     }, error => {
       // this.onAddressStreetChange();
       // this.onAddressCityChange();
       // this.onAddressPostCodeChange();
       // this.onAddressHouseNoChange();
     });
   }

   checkSearchedAddressField(address) {
     this.addressFound.house_number_ext = true;
     if (address?.street) {
       this.addressFound.street = true;
     }
     if (address?.locality || address?.city) {
       this.addressFound.city = true;
     }

     if (address?.postcode || address?.zip_code) {
       this.addressFound.zipcode = true;
     }
     if (address?.buildingNumber || address?.house_number || address?.houseNumber) {
       this.addressFound.house_number = true;
     }
   }

   onAddressSearch() {
     if ((this.selectedCountryObject?.country_code !== this.NL_CODE && !this.selectedCountryObject?.searchable) || (this.selectedCountryObject?.country_code !== this.NL_CODE && this.selectedCountryObject?.searchable && this.hasInputCustomAddressPermission)) {
       return
     }
     this.addressCombinationMsg = null;
     if (this.newAddress?.zip_code && this.newAddress?.house_number) {
       this.searchAddress(
         {
           zipCode: this.newAddress?.zip_code,
           houseNumber: this.newAddress?.house_number,
           houseNumberExt: this.newAddress?.house_number_extension
         }
       );
     }
   }
   searchAddress(value) {
     this.resetAddressFound();
     this.errors = {}; this.addressCombinationMsg = null;
     clearTimeout(this.shipTimeOut);
     this.shipTimeOut = setTimeout(() => {
       this.isLoading = true;
       this.addressService.getNlAddress(value).subscribe(resp => {
         if (resp.data) {
           this.isLoading = false;
           this.newAddress.street = resp.data.street;
           this.newAddress.city = resp.data.city;
           this.checkSearchedAddressField(resp.data);
         }

       }, error => {
         this.isLoading = false;

         this.newAddress.street = null;
         this.newAddress.city = null;

         if (error.error.error.error_code === 'invalid_zip_code_house_number_combination') {
           this.addressCombinationMsg = error.error.error.message;
         } else if (error.error.error.error_code === 'CONTACT_NO_LOCATION_DATA') {
           this.addressCombinationMsg = error.error.error.message;
         } else if (error.error.error.error_code === 'ADDRESS_LOOKUP_NO_ADDRESS_DATA_FOUND') {
           this.addressCombinationMsg = error.error.error.message;
         }
         else if (error?.error?.error) {
           const errors = this.errorHandleService.errorHandle(error?.error);
           errors['type'] = 'shipping_address';
           this.handleError(errors);
         } else {
           this.addressCombinationMsg = `${this.translocoService.translate('CUSTOMER.SOMETHING_WENT_WRONG')}`;
         }
       });
     }, 1000);
   }

   handleError(error: any) {
     this.errors = {};
     this.errors['city'] = error?.city;
     this.errors['country'] = error?.country;
     this.errors['house_number'] = error?.house_number;
     this.errors['company_name'] = error?.company_name;
     this.errors['street'] = error?.street;
     this.errors['zip_code'] = error?.zip_code;
     this.errors['surname_prefix'] = error?.surname_prefix;
     this.errors['initials'] = error?.initials;
     this.errors['first_name'] = error?.first_name;
     this.errors['last_name'] = error?.last_name;
     this.errors['extra_address_information_1'] = error?.extra_address_information_1;
     this.errors['extra_address_information_2'] = error?.extra_address_information_2;
   }

   onFocused() {
     this.searchFocused = true;
   }
   onFocusedOut() {
     this.searchFocused = false;
   }

   searchCleared() {
     this.resetForm();
   }
   openCustomAddressModal() {
     this.showAddressModal = true;
     this.renderer.addClass(document.body, 'overflow-hidden');
   }
   closeCustomAddressModal() {
     this.showAddressModal = false;
     this.isCreatingAddress = false;
     this.renderer.removeClass(document.body, 'overflow-hidden');
     this.temporalSelectedAddressIdForUpdateQuoteShippingAddress = this.selectedQuoteShippingAddress?.id || null;
     this.newAddress = this.resetObject(this.defaultNewAddress);
   }

   resetObject<T>(defaultValues: T): T {
     return { ...defaultValues };
   }
   updateQuoteShippingAddress(id) {
     this.isLoading = true;
     this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, { shipping_address_id: id }).subscribe(res => {
       this.toastrService.success(`Address updated`, 'Success');
       this.quote = res.data;
       this.selectedQuoteShippingAddress = this.contactAddresses?.find(item => item?.id === this.quote?.shipping_address_details?.id) || null;
       this.temporalSelectedAddressIdForUpdateQuoteShippingAddress = this.selectedQuoteShippingAddress?.id || null;
       // this.showAddressModal = false;
       this.closeCustomAddressModal();
       this.isLoading = false;
     }, error => {
       this.isLoading = false;
       this.toastrService.error(`Address updated failed`, 'Error');
     });
   }
   updateQuoteContactPerson() {
     this.isLoading = true;
     this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, { contact_person: this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson }).subscribe(res => {
       this.toastrService.success(`Contact person updated`, 'Success');
       this.quote = res.data;
       this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid || item?.email === this.quote?.contact_person?.email) || null;
       this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = this.selectedQuoteContactPerson?.uuid || null;
       this.contactPersonModalRef.close();
       this.isLoading = false;
     }, error => {
       this.isLoading = false;
       this.toastrService.error(`Contact person updated failed`, 'Error');
     });
   }
   async createContactAndUpdateQuote() {
     this.isLoading = true;
     this.contactPersonCreateError = null;
     this.addCustomerService.createContactPerson(this.quote.contact.uuid, this.newContactPerson).toPromise().then(async (response) => {
       if (response?.data) {
         this.quoteContactPersons = [response?.data, ...this.quoteContactPersons];
         this.newContactPerson = {
           initials: '',
           first_name: '',
           last_name: '',
           surname_prefix: '',
           email: '',
           mobile_phone_number: ''
         };
         this.closeNewContactPersonCreateView();
         if (await this.updateQuoteFields({ contact_person: response.data.uuid }, 'Contact person')) {
           this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid) || null;
           this.isLoading = false;
         }
       }
     }).catch(error => {
       this.isLoading = false;
       this.toastrService.error(this.translocoService.translate('CUSTOMER.CONTACT_PERSON_CREATE_FAILED'));
       this.contactPersonCreateError = error?.error?.error;
     });
   }
   openContactPersonModal(contactPersonModal) {
     this.contactPersonModalRef = this.modalService.open(contactPersonModal, {
       ariaLabelledBy: 'modal-basic-title',
       centered: true,
       modalDialogClass: 'tri-modal-595 modal-runded',
       size: 'md',
       scrollable: true,
       backdrop: 'static',
     });

     this.contactPersonModalRef.result.then((result) => {
       // this.closeResult = `Closed with: ${result}`;
     }, (reason) => {
       // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
     });
   }
   async updateQuoteFields(payload = {}, message: string) {
     return this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, payload).toPromise().then(res => {
       this.toastrService.success(`${message} updated`, 'Success');
       this.quote = res.data;
       return true;
     }).catch(error => {
       this.isLoading = false;
       this.toastrService.error(`${message} updated failed`, 'Error');
       return false;
     });
   }
   getQuoteContactDetails() {
     this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
       this.isLoading = false;
       this.quoteShippingAddresses = res?.data?.contact_addresses?.filter(address => address?.address_type === 'shipping');
       this.contactAddresses = res?.data?.contact_addresses;
       this.quoteContactPersons = res?.data?.contact_persons;
       this.selectedQuoteShippingAddress = res?.data?.contact_addresses?.find(item => item?.id === this.quote?.shipping_address_details?.id) || null;
       this.temporalSelectedAddressIdForUpdateQuoteShippingAddress = this.selectedQuoteShippingAddress?.id || null;
       this.selectedQuoteContactPerson = this.quoteContactPersons?.find(item => item?.uuid === this.quote?.contact_person?.uuid || item?.email === this.quote?.contact_person?.email) || null;
       this.temporalSelectedContactPersonUUIDForUpdateQuoteContactPerson = this.selectedQuoteContactPerson?.uuid || null;

     }, error => {
       this.isLoading = false;
       console.log('error: ', error);

     })
   }
   createAddressAndUpdateQuote() {
     this.isLoading = true;
     this.addressCombinationMsg = null;
     this.newAddress.address_type = 'shipping';
     this.errors = {};
     this.addCustomerService.submitNewAddress(this.newAddress, this.quote.contact.uuid).subscribe((response) => {
       this.contactAddresses = [response?.data, ...this.contactAddresses];
       this.quoteShippingAddresses = this.contactAddresses?.filter(address => address?.address_type === 'shipping');
       this.newAddress = this.resetObject(this.defaultNewAddress);

       console.log('this.newAddress: ', this.newAddress);
       this.updateQuoteShippingAddress(response.data.id);
       this.selectedCountry = this.NL_CODE;
       this.setCountryObject();
       setTimeout(() => {
         this.isCreatingAddress = false;
       }, 2000);

     }, error => {
       const errors = this.errorHandleService.errorHandle(error.error);
       this.handleError(errors);
       this.isLoading = false;
       this.toastrService.error(this.translocoService.translate('CUSTOMER.ADDRESS_CREATE_FAILED'));
     });
   }
    ngOnDestroy() {
      if (this.currentCustomerDetailsQuoteSubscription$) {
        this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
      }
    }
}
