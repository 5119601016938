<div class="notiication" *ngIf="notificationMsg">
  <div *ngIf="notificationType === 'success'" class="success-notiication">
    {{ notificationMsg }}
  </div>
  <div *ngIf="notificationType === 'warning'" class="warning-notiication">
    <i class="kpn-ui-icon ui-warning pt-1"> {{ notificationMsg }} </i>
    <button (click)="closeNotification()" class="btn circle-icon-btn icon-btn-dark side-nav-toggler p-0">
      <i class="kpn-ui-icon ui-close"></i>
    </button>
  </div>
  <div *ngIf="notificationType === 'error'" class="error-notiication">
    <i class="kpn-ui-icon ui-error">{{ notificationMsg }}</i>
    <button (click)="closeNotification()" class="btn circle-icon-btn icon-btn-dark side-nav-toggler p-0">
      <i class="kpn-ui-icon ui-close"></i>
    </button>
  </div>
</div>