<div>
  <div class="d-flex align-items-center justify-content-between pt-2 pb-2">
    <h3 class="font-kpn-extended fs-16 mb-0 letter-spacing-normal">Totaalbedrag (excl. btw)</h3>
    <h3 class="font-kpn-extended fs-16 kpn-extended-bold mb-0 letter-spacing-normal">{{
      quote?.total_amount_ex_vat | currencyFormat }}</h3>
  </div>
  <hr class="my-2">
  <div class="pt-1">
    <div class="d-flex align-items-center pb-1 justify-content-between" *ngFor="let additionalCost of additionalCosts">
      <span class="font-kpn-extended fs-14 grey-text">Verzendkosten</span>
      <span class="font-kpn-extended fs-14 grey-text">{{  additionalCost?.total_amount_ex_vat | currencyFormat }}</span>
    </div>
    <div class="d-flex align-items-center pb-1 justify-content-between">
      <span class="font-kpn-extended fs-14 grey-text">Btw</span>
      <span class="font-kpn-extended fs-14 grey-text">{{ quote?.total_amount_vat | currencyFormat }}</span>
    </div>
    <div class="d-flex align-items-center pb-1 justify-content-between">
      <span class="font-kpn-extended fs-14 grey-text">Totaal (incl. btw)</span>
      <span class="font-kpn-extended fs-14 grey-text">{{ quote?.total_amount | currencyFormat }}</span>
    </div>
  </div>
</div>
