import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Observable, forkJoin, of, timer } from 'rxjs';
import { tap, map, shareReplay, catchError, switchMap } from 'rxjs/operators';

import { StorageService } from '../../shared/storage.service';
import { CartService } from './cart.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { ToastrService } from 'ngx-toastr';
import { CustomerService } from '../customer/customer.service';
import { EProductTypes } from 'src/app/shared/enums/product-type.enums';
import { EMessages } from 'src/app/shared/enums/messages.enums';
import { TranslocoService } from '@ngneat/transloco';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.scss'],
  // providers : [FormatPricePipe]
})
export class CartComponent extends BasePermission implements OnInit, OnDestroy {

  cartLines$: Observable<any>;
  cart: any;

  errorMessage = '';
  voucherErrorMessage = '';
  isLoading = false;
  pageLoading = false;
  cartUuid = null;
  isExternal = false;
  private tempQty = 0;
  voucherCode = null;
  disabledBtn = false;
  isVoucherLoading = false;
  projectList = [];
  isProjectVisible = false;
  isVoucherVisible = false;
  selectedProject = null;
  selectedProjectRef = null;
  onProjectPriceLoading = false;
  allowNextWithVoucher = false;
  productLines = [];
  voucherLines = [];
  additionalCostLies = [];
  commPlanLoading = false;
  ratePlanLoading = false;
  public activeStep = 'overview';

  public user = null;
  public customer = null;
  public shippingAddress = [];
  public productType = [
    EProductTypes.HANDSET,
    EProductTypes.TABLET
  ];

  private account_type_calls = [];
  private account_type_responses = [];
  public account_type_error = null;
  public hasUnsetAccountType = false;
  public sendingSpecialRequest = false;
  public cartError = null;
  public cartSummary = {
    total_products_qty: 0,
    total_products_amount: 0.00,
    total_products_amount_ex_vat: 0.00,
    total_shipping_cost: 0.00,
    total_shipping_cost_ex_vat: 0.00,
    total_vat: 0.00,
    total_amount: 0.00,
    total_amount_ex_vat: 0.00
  };

  constructor(
    private router: Router,
    public cartService: CartService,
    private route: ActivatedRoute,
    private storageService: StorageService,
    public location: Location,
    private toastrService: ToastrService,
    public store: Store<IAppState>,
    public customerService: CustomerService,
    private translocoService: TranslocoService,
    public utilityService: UtilityService

    // public formatPricePipe: FormatPricePipe
  ) {
    super(store);
    this.cartUuid = this.route.snapshot.params.cart_uuid;
    this.user = this.storageService.getCurrentUser();
    this.customer = this.storageService.getCustomer();
    this.isVoucherVisible = this.storageService.getPermission()?.some(permission => permission.code === 'vouchers.voucher_required');
  }

  get eProductTypes() { return EProductTypes; }
  get eMessages() { return EMessages; }


  ngOnInit() {
    const hasUser = this.storageService.getCurrentUser();
    if (hasUser) {
      this.isExternal = hasUser.is_external || false;
    }
    if (this.cartUuid) {
      this.pageLoading = true;
      this.getCart();
    }

    if (this.projectPricePermission && this.user?.contact_group?.is_external) {
      this.fetchProjectList(this.user?.contact?.uuid);
    } else if (!this.user?.contact_group?.is_external && this.customer && this.projectPricePermission) {
      this.fetchProjectList(this.customer?.uuid);
    }

    this.getLatestCart();
  }

  getLatestCart() {
    this.cartService.currentCart$.subscribe((res) => {
      if (res) {
        this.cart = res;
      }
    });
  }


  getCart() {
    this.isLoading = true;
    this.account_type_error = null;
    this.cartService.getCart(this.cartUuid).pipe(
      tap(cart => {
        this.pageLoading = false;
        this.cart = cart.data;
        this.filerCartProduct(cart?.data);
        this.isLoading = false;
        const account_line = this.cart?.lines?.filter(item => {
          return item?.account_type_required;
        }).map(line => {
          return line?.uuid;
        });

        this.account_type_calls = account_line.map(item => {
          return this.cartService.getAccountType(this.cart?.uuid, item);
        });
        this.getCartSummary(false);
      }),
      switchMap(res => {
        return forkJoin(this.account_type_calls);
      })).
      subscribe((res: any[]) => {
        this.account_type_responses = res;
        this.cart = this.prepareCartResponse(this.cart);
        this.checkAccountType(this.cart);
        this.filerCartProduct(this.cart);
        this.isLoading = false;

      }, error => {
        this.isLoading = false;
        this.cart = this.prepareCartResponse(this.cart);
        this.account_type_error = error?.error?.message;
        this.checkAccountType();
        this.showErrorToaster(error?.error?.message);
        this.pageLoading = false;
      });
  }


  prepareCartLine(line, response = []) {
    return { ...line, account_types: response, selected_account: line.extra.contact_account_type_id };
  }

  // getVoucherProfileDeliveryAddress() {
  //   if (!this.shippingAddress?.length) {
  //     this.cartService.getVoucherProfileDeliveryAddress(this.cart?.uuid).subscribe(res => {
  //       this.shippingAddress = res?.data?.results;
  //       const addressFound = this.shippingAddress?.some(address => address?.id === this.cart?.shipping_address_id);
  //       if (!addressFound && this.cart?.shipping_address_id) {
  //         this.updateCart();
  //       }
  //     });
  //   }
  // }

  updateCart() {
    const updatePayload = {
      shipping_address_id: this.cart?.shipping_address_id || null,
    };
    this.customerService.updateCart(this.cart.uuid, updatePayload)
      .subscribe(
        (res) => {
          this.cart = this.prepareCartResponse(res.data);
          this.restoreCart(this.cart);
        },
        (err) => {
          // this.showErrorToaster('Something Wrong');SOMETHING_WRONG
          this.showErrorToaster(`${this.translocoService.translate('CART.SOMETHING_WRONG')}`)
        }
      );
  }

  filerCartProduct(res) {
    this.additionalCostLies = [];
    this.voucherLines = []; this.productLines = []; this.allowNextWithVoucher = true;
    res?.lines.forEach(ele => {
      const memory_size = ele?.dimentional_attributes?.find(item => item?.attribute_code === 'memory_size')?.attribute_value ?? null;
      if (ele?.voucher_code) {
        this.voucherLines.push(ele);
      }
      if (ele?.product_type !== "additional_cost") {
        this.productLines.push({ ...ele, isSubLineOpen: false, memory_size: memory_size });
      }
      else {
        this.additionalCostLies.push({ ...ele, isSubLineOpen: false, memory_size: memory_size });
      }
    });

    this.allowNextWithVoucher = this.voucherRequiredPermission && !this.voucherLines?.length ? false : true;
  }

  fetchProjectList(contactUuid: string) {
    this.selectedProjectRef = this.storageService.getDynamicValue('project_code_ref', false) || null;
    this.cartService.getProjectList(contactUuid).subscribe(res => {
      if (res?.data?.results) {
        this.projectList.push(...res?.data?.results);
        const projectPrice = this.storageService.getDynamicValue('project_code', false);
        if (projectPrice) {
          this.selectedProject = this.projectList.find(data => data.project_price.code === projectPrice);
          this.isProjectVisible = true;
        }
      }

    }, error => {
      this.showErrorToaster(error?.error?.message);
    });
  }

  deleteCartItem(cartLine) {
    this.isLoading = true;
    this.cartService.deleteCartItem(this.cart.uuid, cartLine.uuid).subscribe(res => {
      if (res.data.lines.length === 0) {
        this.storageService.removeDynamicValue('project_code');
        this.storageService.removeDynamicValue('project_code_ref');
        this.storageService.removeDynamicValue('cartProduct');
        this.goHome();
      }
      this.cart = this.prepareCartResponse(res.data);
      this.checkAccountType(this.cart);
      this.isLoading = false;
      this.restoreCart(this.cart);
      this.cartService.saveOrderUnitOfCartProducts({ product_code: cartLine.product_code }, true);
    }, err => {
      this.isLoading = false;
    });
  }

  restoreCart(cart) {
    this.getCartSummary();
    this.checkAccountType(cart);
    this.filerCartProduct(cart);
    this.cartService.changeCurrentCart(cart);
    this.storageService.setCart(cart);
  }

  checkAccountType(cart = this.cart) {
    this.hasUnsetAccountType = cart?.lines?.some(line => {
      return line?.account_type_required && !line?.extra?.contact_account_type_id;
    });

    if (!this.hasUnsetAccountType) {
      this.account_type_error = null;
    }
  }

  prepareSubLine(sub_lines = [], qty) {
    // return sub_lines?.filter(item => item?.product_code !== 'tkh_handset_ho').map(sb => {
    return sub_lines?.filter(item => !item?.product_code?.startsWith('tkh_'))?.map(sb => {
      return {
        product_code: sb?.product_code,
        quantity: qty || sb?.quantity,
        uuid: sb?.uuid,
        promotion_code: sb?.promotion_code,
      }
    })
  }

  updateCommPlan(line) {
    this.commPlanLoading = true;
    this.updateCartCartItem(line);
  }

  updateRatePlan(line) {
    this.ratePlanLoading = true;
    this.updateCartCartItem(line);
  }

  updateCartCartItem(updateLine) {
    this.cartError = null;
    this.isLoading = true;
    const payload = {
      quantity: updateLine.quantity,
      sub_lines: this.prepareSubLine(updateLine.sub_lines, updateLine.quantity),
      extra: {
        contact_account_type_id: updateLine.extra.contact_account_type_id,
        is_default_rate_plan: updateLine?.extra.is_default_rate_plan ?? true,
        comm_plan: updateLine?.extra?.comm_plan ?? "",
        rate_plan: updateLine?.extra?.rate_plan ?? ""
      }
    };

    this.cartService.updateCartLine(this.cart.uuid, updateLine.uuid, payload).subscribe(
      res => {
        this.isLoading = false;
        this.commPlanLoading = false;
        this.ratePlanLoading = false;
        // this.cart = res.data;
        this.cart = this.prepareCartResponse(res.data);
        this.restoreCart(this.cart);
        if (payload?.extra?.comm_plan?.length && payload?.extra?.rate_plan?.length) {
          this.showSuccessToaster(`Comm Plan & Rate Plan updated successfully`);
          return;
        }
        if (payload?.extra?.rate_plan?.length) {
          this.showSuccessToaster(`Rate Plan updated successfully`);
          return;
        }
        if (payload?.extra?.comm_plan?.length) {
          this.showSuccessToaster(`Comm Plan updated successfully`);
          return;
        }
      }, err => {
        updateLine.quantity = this.findProductInCart(updateLine.product_code).quantity;
        this.isLoading = false;
        this.commPlanLoading = false;
        this.ratePlanLoading = false;
        this.cartError = err?.error?.message ?? 'Something went wrong';
        this.showErrorToaster(err?.error?.message);
      });
  }
  setDefaultRatePlanToYes(line) {
    line.extra.comm_plan = "";
    line.extra.rate_plan = "";
    this.updateCartCartItem(line);
  }
  prepareCartResponse(res) {
    return {
      ...res,
      lines: res?.lines?.map(
        line => {
          const account_types = this.account_type_responses.find((item: any) => {
            return line.uuid === item.line_uuid;
          })?.data?.results || null;
          return {
            ...this.prepareCartLine(line, account_types ? account_types : [])
          };
        }
      )
    };
  }

  onEnter(line) {
    if (line.quantity < 1) {
      return;
    }
    this.updateCartCartItem(line);
  }

  backClicked() {
    this.location.back();
  }
  goHome() {
    this.router.navigateByUrl('/home');
  }

  incrementQuantity(line) {
    line.quantity = line.quantity + line?.order_unit;
    this.updateCartCartItem(line);
  }
  decrementQuantity(line) {
    if (line.quantity < 1) {
      return;
    }

    line.quantity = line.quantity - line?.order_unit;
    this.updateCartCartItem(line);
  }


  sendRequest() {
    this.sendingSpecialRequest = true;

    if (this.cart?.special_discount_requested) {
      this.onClickCartToQuote();
      return;
    }
    const updatePayload = {
      special_discount_requested: true,
    };
    this.customerService.validateCart(this.cart.uuid, updatePayload)
      .subscribe(
        (res) => {
          this.sendingSpecialRequest = false;
          this.cart = this.prepareCartResponse(res.data);
          this.restoreCart(this.cart);
          this.onClickCartToQuote();
        },
        (err) => {
          // this.showErrorToaster('Something Wrong');SOMETHING_WRONG
          this.sendingSpecialRequest = false;
          this.showErrorToaster(`${this.translocoService.translate('CART.SOMETHING_WRONG')}`)
        }
      );
  }

  onClickCartToQuote() {
    this.isLoading = true;
    this.errorMessage = '';
    this.storageService.quoteSelected();
    const customer = this.storageService?.getCustomer();
    const uuid = customer?.uuid ?? this?.user?.contact?.uuid;
    const url = (this.isExternal) ? `/customer/${uuid}` : ((this.cart.contact_person_uuid && customer?.uuid) ? `/customer/${customer?.uuid}` : '/customer/');
    this.router.navigate([`${url}`], {
      queryParams: {
        contact_person: this.cart?.contact_person_uuid || null,
      },
      queryParamsHandling: 'merge'
    });


  }

  backToProductPage() {
    this.router.navigateByUrl('home');
  }

  goToOrderOverview() {
    this.storageService.deleteSelectedQuote();
    this.storageService.deleteSelectedQuote();
    const customer = this.storageService?.getCustomer();
    const uuid = customer?.uuid ?? this?.user?.contact?.uuid;
    const url = (this.isExternal) ? `/customer/${uuid}` : ((this.cart.contact_person_uuid && customer?.uuid) ? `/customer/${customer?.uuid}` : '/customer/');
    console.log('url', url);

    this.router.navigate([`${url}`], {
      queryParams: {
        contact_person: this.cart?.contact_person_uuid || null,
      },
      queryParamsHandling: 'merge'
    });
  }

  onEnterVoucher() {
    if (this.voucherCode === null || this.voucherCode === '') { return; }

    this.voucherErrorMessage = '';
    this.disabledBtn = true;
    this.isVoucherLoading = true;
    this.cartService.addVoucher(this.cart?.uuid, { voucher_code: this.voucherCode }).subscribe(
      (response) => {
        this.cart = response?.data;
        this.restoreCart(response?.data);
        this.disabledBtn = false;
        this.isVoucherLoading = false;
        this.voucherCode = null;
        // this.showSuccessToaster('Voucher added to the cart.');
        this.showSuccessToaster(`${this.translocoService.translate('CART.VOUCHER_ADDED_TO_THE_CART')}.`);
      },
      (error) => {
        this.voucherErrorMessage = error?.error?.message || 'Er is geen geldige vouchercode ingevuld.'

        this.disabledBtn = false;
        this.isVoucherLoading = false;
        this.showErrorToaster(error?.error?.message || `${this.translocoService.translate('CART.FAILED')}!`);
      }
    );
  }

  projectSelection() {
    this.onProjectPriceLoading = true;
    const value = {
      // tslint:disable-next-line:max-line-length
      project_price_code: this.selectedProject && this.selectedProject.hasOwnProperty('project_price') ? this.selectedProject.project_price.code : '',
      project_reference: this.selectedProjectRef || '',
    };
    this.cartService.addProjectPriceToCart(this.cartUuid, value).subscribe(
      (response) => {
        this.onProjectPriceLoading = false;
        this.storageService.setDynamicValue('project_code', value.project_price_code);
        this.storageService.setDynamicValue('project_code_ref', value.project_reference);
        this.cart = response.data;
        this.restoreCart(this.cart);
        // this.showSuccessToaster('Project price added to the cart');
        this.showSuccessToaster(`${this.translocoService.translate('CART.PROJECT_PRICE_ADDED_TO_THE_CART')}`);
      },
      (error) => {
        this.onProjectPriceLoading = false;
        this.showErrorToaster(error?.error?.message);
      }
    );
  }

  removeProject() {
    this.selectedProject = null;
    this.selectedProjectRef = null;
    this.isProjectVisible = false;
    this.projectSelection();
  }

  private getProductOrderUnit(productCode: string): any {
    return this.cartService.getOrderUnitOfCartProducts(productCode);
  }

  private findProductInCart(productCode) {
    return this.storageService.getCart()?.lines.find(data => data.product_code === productCode);
  }

  showErrorToaster(msg) {
    if (msg) {
      this.toastrService.error(`${msg}`, 'Error', {
        timeOut: 4000
      });
    }
  }

  showSuccessToaster(msg) {
    this.toastrService.success(`${msg}`, 'Succes', {
      timeOut: 4000
    });
  }

  gotoCustomer() {
    const customer = this.storageService?.getCustomer();
    const uuid = customer?.uuid ?? this?.user?.contact?.uuid;
    this.router.navigate([`/customer/${uuid}`], {
      queryParams: {
        contact_person: this.cart?.contact_person_uuid || null,
      },
      queryParamsHandling: 'merge'
    });
  }


  reFetchCart(event) {
    console.log('event: ', event);

    if (event) {
      this.getCart();
    }
  }

  getCartSummary(loadingStatus = true) {
    this.isLoading = loadingStatus;
    this.cartService.getCartSummary(this.cart.uuid).subscribe(res => {
      this.cartSummary = res?.data;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
      this.showErrorToaster(error?.error?.message);
    });
  }

  ngOnDestroy() {
    if (this.permissionSubs$) {
      this.permissionSubs$.unsubscribe();
    }
  }
}
