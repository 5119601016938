<div class="bg-white">
    <div class="container">
        <div class="row align-items-center justify-content-center pt-0 pb-5">
            <div class="col-md-11">
                <div class="px-2">
                    <div class="breadcrumb-custom breadcrumb-faq mb-3">
                        <ul class="mb-6">
                          <li class="click-able"><a [routerLink]="'/'">Home</a></li>
                          <li class="font-weight-bold">Veelgestelde vragen</li>
                        </ul>
                    </div>

                    <h1 class="page-title-faq pt-5 text-capitalize">FAQ</h1>
                    <div class="faq-categroy" *ngFor="let item of faqItems; let i=index">
                        <h3 class="fq-title">{{item?.name}}</h3>
                        <div ngbAccordion class="faq-accordion">
                            <div ngbAccordionItem *ngFor="let faq of item?.faqs;let j = index">
                                <h2 ngbAccordionHeader>
                                    <button ngbAccordionButton
                                        class="d-flex align-items-center justify-content-between btn p-0 w-100">
                                        <span class="faq-ac-title">{{faq?.question}}</span>
                                        <i class="kpn-ui-icon ui-chevron-up ac-arrow"></i>
                                    </button>
                                </h2>
                                <div ngbAccordionCollapse>
                                    <div ngbAccordionBody>
                                        <ng-template>
                                            {{faq?.answer}}
                                        </ng-template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12" *ngIf="!loading && !faqItems?.length">
                        <div class="info-bar-blue mb-1 d-flex align-items-center justify-content-between">
                            <h6>
                                <span class="kphicon-uniE952"></span>
                                Sorry! Er is momenteel geen FAQ beschikbaar.
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>