import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { IAppState } from '../app.state';
import { BasePermission } from '../shared/base/base-permission';

@Injectable({
  providedIn: 'root'
})
export class DepotGuard extends BasePermission implements CanActivate {
  constructor(
    private router: Router,
    public store: Store<IAppState>
  ) {
    super(store);
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.hasDepotViewPermission) {
      return true;
    } else {
      this.router.navigate(['/home']);
      return false;
    }
  }

}
