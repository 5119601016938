<div class="product-action-right-new mb-4">
  <div class="cart-loader-new" *ngIf="showLoding">
    <div class="spinner-border" style="width: 2.5rem; height: 2.5rem;" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <h4 class="right-new-title mb-4"> {{'PRODUCT_DETAILS.YOUR_CHOICE' | transloco }}</h4>
  <div class="item-price-line" *ngFor="let line of preCartLines; let i = index">
    <div class="item-price-line-new d-flex align-items-center justify-content-between">
      <label for="">{{line?.product_name}}</label>
      <span>{{preCart?.amount_ex_vat | currencyFormat}}</span>
    </div>
    <div class="item-price-line-new d-flex align-items-center justify-content-between"
      *ngFor="let subLine of line.sub_lines; let j = index">
      <label for="">{{subLine?.product_name}}</label>
      <span *ngIf="parsePrice(subLine?.total_amount_ex_vat)">{{subLine?.total_amount_ex_vat | currencyFormat}}</span>
    </div>
  </div>


  <div class="item-add-remove-new">
    <div class="d-flex justify-content-end mb-4">
      <div class="item-add-remove">
        <button [disabled]="preCartError" [ngClass]="{'disable-link': preCartError}"
          class="kphicon-uniE972 add-remove-btn dicrement-btn" (click)="decreaseQuantity()"></button>
        <div class="add-remove-input">
          <input [disabled]="preCartError" [ngClass]="{'disable-link': preCartError}" appPositiveInteger type="number"
            [(ngModel)]="quantity" min="0" (keyup.enter)="onEnter()">
        </div>
        <button [disabled]="preCartError" [ngClass]="{'disable-link': preCartError}"
          class="kphicon-uniE985 add-remove-btn incriment-btn" (click)="increaseQuantity()"></button>
      </div>
    </div>
    <!-- <div class="pd-action-att mb-4 pb-3">
    <h4 class="mb-2 action-right-title">Accessoires</h4>
    <div class="pd-action-btn">
      <button class="action-btn active">Geen</button>
      <button class="action-btn">Hoesje + 16</button>
    </div>
  </div> -->



    <div class="price-action-cart">
      <!-- <h2 class="item-price">{{preCart?.total_amount | currencyFormat}},-</h2>
     <p class="incl-price">Incl. Gratis Galaxy Buds</p> -->
      <a class="item-update-btn qty-update-btn-in-cart click-able" (click)="onAddToCart(selectedProduct)"
        *ngIf="!preCartError">
        <i class="kpn-ui-icon ui-shoppingbasket v-text-bottom mr-2"></i>
        {{'PRODUCT_DETAILS.IN_THE_SHOPPING_CART' | transloco }}</a>
      <div class="row align-items-center p-2" *ngIf="preCartError">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              {{preCartError}}
            </p>
          </div>
        </div>
      </div>

      <button class="btn btn-block btn-outline-primary" *ngIf="cart" (click)="goToCartOverview()"> {{'ADDED_TO_CART_ALERT_SIDE_BAR.CONTINUE_TO_ORDER' | transloco }}</button>
      <p class="green-text mb-2" *ngIf="product?.stock"><small> <i class="kphicon-uniE94E mr-2"></i>Op voorraad</small></p>
      <!-- <p class="danger-text mb-2" *ngIf="!product?.stock"> <small><i class="kphicon-uniE94F mr-2"></i>Langere levertijd </small></p> -->
      <div class="row align-items-center p-2" *ngIf="cartError">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              {{cartError}}
            </p>
          </div>
        </div>
      </div>


    </div>
  </div>
</div>

<div class="shadow-kpnew-md bg-white tri-rounded-16 py-3 c-px-24 mb-3" *ngIf="show_een_message">
  <div class="p-3">
    <h6 class="font-kpn-bold fs-15 pt-1 pl-2">Compatibiliteit van product</h6>
    <p class="font-kpn-extended fs-15 mb-1 pl-2">
      Let op: Verschillende merken basisstations en handsets werken niet altijd goed samen. Is je hardware toe aan vervanging, maar vind je in onze shop niet jouw huidige merk? Bel dan naar 0800-0403. Wij helpen je graag verder.
    </p>
  </div>
</div>
<!-- <p class="cart-hints-info"><i class="kphicon-uniE9681"></i> Voor 23:00 uur besteld, morgen in huis</p> -->
<p class="cart-hints-info"><i class="kphicon-uniE90B"></i>{{'PRODUCT_DETAILS.DO_YOU_HAVE_ANY_VOUCHER_CODE' | transloco }}.</p>

<button ngbTooltip="Klik om naar Inruil te gaan"
  *ngIf="haveTradeInPermission && ['handset','tablet']?.includes(product?.product_type?.code)" type="button"
  [routerLink]="'/trade-in'" class="btn btn-sm btn-link nounderline">
  <i class="kphicon-uniE90B"></i>{{'PRODUCT_DETAILS.DID_YOU_KNOW_THAT_YOU_CAN_ALSO_HAND_IN_OLD_EQUIPMENT' | transloco}}?
</button>
<br>
<br>

<div class="row align-items-end mb-4" *ngIf="selectedProduct?.tier_prices?.length > 1">
  <div class="col-md-12">
    <h6>{{'PRODUCT_DETAILS.TIER_PRICES' | transloco }}</h6>
    <div class="table-responsive custom-table">
      <table class="table mb-5">
        <thead>
          <tr>
            <th class="text-right"> {{'PRODUCT_DETAILS.QUANTITY' | transloco }}</th>
            <th class="text-right">{{'PRODUCT_DETAILS.PRICE' | transloco }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let tier of selectedProduct?.tier_prices">
            <td class="text-right">{{ tier?.quantity }}</td>
            <td class="text-right">{{ tier?.price_ex_vat | currencyFormat: '€':
              getDecimalLength(tier?.price_ex_vat) || 2 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row align-items-end mb-4" *ngIf="selectedProduct?.usages_prices?.length">
  <div class="col-md-12">
    <h6>{{'PRODUCT_DETAILS.USAGE_PRICES' | transloco}}</h6>
    <div class="table-responsive custom-table">
      <table class="table mb-5">
        <thead>
          <tr>
            <th class="text-right">{{'PRODUCT_DETAILS.QUANTITY' | transloco }}</th>
            <th class="text-right">{{'PRODUCT_DETAILS.PRICE' | transloco }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let usages_price of selectedProduct?.usages_prices">
            <td class="text-right">{{ usages_price?.quantity }}</td>
            <td class="text-right">{{ usages_price?.price_ex_vat | currencyFormat: '€':
              getDecimalLength(usages_price?.price_ex_vat) || 2 }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

<div class="row align-items-end mb-4" *ngIf="!restrictExportId?.includes( siteProfile?.export_id )">
  <div class="scroll-able-3 col-md-12" *ngIf="relatedProducts?.length">
    <div class="product-section-new px-3">
      <div class="row">
        <div class="col-md-12" *ngFor="let group of relatedProducts; let i = index">
          <span><b> {{group?.product_group}} </b>
            <small *ngIf="group?.min_required === 1 && group?.max_required === 1"> ({{'PRODUCT_DETAILS.OBLIGED' | transloco}})</small>
            <small *ngIf="group?.min_required === 0 && group?.max_required === 1"> ({{'CUSTOMER.OPTIONAL' | transloco}})</small>
            <small *ngIf="group?.min_required >= 1 && group?.max_required >= 2"> (Min: {{group?.min_required}} , Max:
              {{group?.max_required}})
            </small>
          </span>
          <hr>
          <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
            name="multiselect-items">
            <label ngbButtonLabel class="checkbox-item d-flex" *ngFor="let product of group?.products">
              <div class="checkbox-btn checkbox-btn-outline">
                <input ngbButton type="checkbox"  class="position-absolute opacity-0"
                  [disabled]="(group?.min_required === 1 && group?.products?.length === 1)"
                  [(ngModel)]="product.selected" (ngModelChange)="onChangeRelatedProduct($event, product, group)">
                <span class="checkBoxCheckmark"></span>
              </div>
              <div class="checkbox-label ml-2 d-flex align-items-center justify-content-between">
                <small>{{product?.name}}</small>
                <i class="selected-price" *ngIf="parsePrice(product?.sales_price_ex_vat)">{{product?.sales_price_ex_vat
                  |currencyFormat }}</i>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-md-6" *ngIf="relatedGroupProducts?.length">
    <h6>{{'PRODUCT_DETAILS.RELATED_PRODUCT' | transloco }}</h6>
    <div ngbDropdown class="kpn-custom-dropdown kpn-custom-dropdown-option dropdown-init-fix mb-3">
      <button class="btn btn-block text-left btn-toggler" id="selectType" ngbDropdownToggle>
        <span class="dropdown-placeholder" *ngIf="!selectedProductGroup">
          {{'PRODUCT_DETAILS.SELECT_PRODUCT_GROUP' | transloco }}
        </span>
        <span class="selected-item" *ngIf="selectedProductGroup">{{selectedProductGroup}}</span>
      </button>
      <div ngbDropdownMenu aria-labelledby="selectType" class="kpn-custom-dropdown-list">
        <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items"
           ngDefaultControl>
          <label (click)="onGroupChange(item?.value)" ngbDropdownItem ngbButtonLabel class="default-radio mb-2" *ngFor="let item of productGroup">
            <span class="label pr-3">{{item?.name}}</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="scroll-able-3row">
    <div class="product-section-new px-3">
      <div class="row">
        <div class="col-md-6" *ngFor="let product of relatedGroupProducts; let i = index">
          <div class="product-box-new related-product-right">
            <div class="product-img-new">
              <div class="size-items-new">
                <span class="size-item" *ngFor="let attr of product?.memory_size">
                  {{ attr }}
                </span>
              </div>
              <a (click)="onProductDetail(product, i)">
                <span *ngIf="product?.image; else noImg">
                  <img [src]="product?.image" [alt]="product?.name">
                </span>
                <ng-template #noImg>
                  <img src="/assets/images/image-not-available.png" [alt]="product?.name">
                </ng-template>
              </a>
              <div class="color-items-new">
                <span class="color-item" *ngFor="let attr of product?.color" [ngStyle]="{'background-color': attr}">
                </span>
              </div>
            </div>
            <div class="product-new-content product-new-indetails">
              <div class="row">
                <div class="col-md-12">
                  <p class="item-title"><small>{{ product?.brand }}</small> </p>
                  <p class="item-att" (click)="onProductDetail(product, i)" [title]="product?.name">
                    <b>{{ product?.code }} - {{ product?.name | textTrim:'40' }} ({{ product?.price?.price_ex_vat |
                      currencyFormat }})</b>
                  </p>
                </div>
                <div class="col-md-12">
                  <button [ngClass]="product?.addedNumber  ? 'btn-success' : 'btn-primary'" class="btn btn-sm"
                    [disabled]="product.showSpinner"
                    (click)="onAddToCart(product, product?.addedNumber || 1, false, true)">
                    <i class="kpn-ui-icon ui-shoppingbasket v-text-bottom mr-2"></i> {{'PRODUCT_DETAILS.IN_THE_SHOPPING_CART' | transloco }}
                    <span *ngIf="product?.addedNumber">({{product?.addedNumber}})</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="row align-items-end mb-4" *ngIf="promotionalProducts?.length">
  <div class="scroll-able-3 col-md-12">
    <div class="product-section-new px-3">
      <div class="row">
        <h6 class="pl-3 title-4 green-text">{{'PRODUCT_DETAILS.PROMOTIONAL_PRODUCT' | transloco}}</h6>
        <div class="col-md-12" *ngFor="let promotion of promotionalProducts; let i = index">
          <span><b [innerHTML]="promotion?.description"> </b>
            <small *ngIf="promotion?.max_promotional_products === 1 && promotion?.mandatory_products?.length"> ({{'PRODUCT_DETAILS.OBLIGED' | transloco}})</small>
            <small *ngIf="promotion?.max_promotional_products === 0"> ({{'CUSTOMER.OPTIONAL' | transloco}})</small>
            <small *ngIf="promotion?.max_promotional_products > 1 && promotion?.mandatory_products?.length"> (Max:
              {{promotion?.max_promotional_products}})
            </small>
          </span>
          <hr>
          <div *ngIf="promotion?.mandatory_products?.length">
            <small><b> {{'PRODUCT_DETAILS.OBLIGED' | transloco}}</b></small>
            <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
              name="multiselect-items">
              <label ngbButtonLabel class="checkbox-item d-flex" [ngClass]="{'disable-link': product?.disable || showLoding}" *ngFor="let product of promotion?.mandatory_products">
                <div class="checkbox-btn checkbox-btn-outline">
                  <input ngbButton type="checkbox" [disabled]="product?.disable || showLoding"  class="position-absolute opacity-0"
                    [(ngModel)]="product.selected" (ngModelChange)="onChangePromotionalMandatoryProduct($event, product, promotion)">
                  <span class="checkBoxCheckmark"></span>
                </div>
                <div class="checkbox-label ml-2 d-flex align-items-center justify-content-between">
                  <small>{{product?.name}}</small>
                  <i class="selected-price prev-price mr-2" *ngIf="parsePrice(product?.price?.previous_price_ex_vat) >= 0">{{product?.price?.previous_price_ex_vat
                    |currencyFormat }}</i>
                  <i class="selected-price" *ngIf="parsePrice(product?.price?.price_ex_vat) >=0">{{product?.price?.price_ex_vat
                    |currencyFormat }}</i>
                </div>
              </label>
            </div>
          </div>
          <div *ngIf="promotion?.optional_products?.length">
            <small> <b> {{'CUSTOMER.OPTIONAL' | transloco}}</b></small>
            <div class="btn-group-toggle radio_group radio_group_list_type multiselect-products" ngbRadioGroup
            name="multiselect-items">
            <label ngbButtonLabel class="checkbox-item d-flex" [ngClass]="{'disable-link': product?.disable || showLoding}" *ngFor="let product of promotion?.optional_products">
              <div class="checkbox-btn checkbox-btn-outline">
                <input ngbButton type="checkbox" [disabled]="product?.disable || showLoding"   class="position-absolute opacity-0"
                  [(ngModel)]="product.selected" (ngModelChange)="onChangePromotionalOptionalProduct($event, product?.product, promotion)">
                <span class="checkBoxCheckmark"></span>
              </div>
              <div class="checkbox-label ml-2 d-flex align-items-center justify-content-between">
                <small>{{product?.product?.name}}</small>
                <i class="selected-price prev-price mr-2" *ngIf="parsePrice(product?.product?.price?.previous_price_ex_vat) >= 0">{{product?.product?.price?.previous_price_ex_vat
                  |currencyFormat }}</i>
                <i class="selected-price" *ngIf="parsePrice(product?.product?.price?.price_ex_vat) >=0">{{product?.product?.price?.price_ex_vat
                  |currencyFormat }}</i>
              </div>
            </label>
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
