<div class="bg-kpn-subtle-grey body-container">
  <div >
    <app-business-partner-selection [showEdit]="false"></app-business-partner-selection>
  </div>
  <section class="res-search-section res-search-section-calc pt-4 pb-5">
    <div class="container">
      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
        <li class="back-breadcrumb-btn border-0">
          <a class="blue-link cursor-pointer" (click)="back()">
            <i class="kphicon-uniE906"></i> <span class="font-kpn-extended">Terug</span>
          </a>
        </li>
        <!-- <li class="home-btn ml-2 px-1 pb-1">
          <a routerLink="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                  fill="#939393" />
              </g>
            </svg>
          </a>
        </li>
        <li><a routerLink="/">Alle apparaten</a></li> -->
      </ul>

      <div class="pt-2">
        <app-trade-in-steps [activeStep]="'order-overview'" [completeStepThree]="true"></app-trade-in-steps>
      </div>
      <h2 class="font-kpn-bold tri-title-40 green-text mb-0 mt-n3">Samenvatting</h2>

      <div class="row pt-3">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 mb-4 p-4">
            <!-- <h3 class="title-2 mb-1 green-text">{{'ORDER_OVERVIEW.CONFIRM_ORDER' | transloco }}</h3>
                  <h5 class="title-4 pt-3">{{'ORDER_OVERVIEW.THESE_ARE_YOUR_DETAILS' | transloco }}</h5> -->
            <div class="row">
              <div class="col-auto">
                <div class="pt-0">
                  <h4 class="font-kpn-bold green-text">Bedrijfsgegevens</h4>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                    {{'CUSTOMER.COMPANY_NAME' | transloco }}
                  </h5>
                  <p class="body-text-1 mb-2">
                    {{ contact?.company_name }}
                  </p>
                  <h5 *ngIf="contact?.coc_number"
                    class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                    KVK {{'CUSTOMER.NUMBER' | transloco }}
                  </h5>
                  <p class="body-text-1 mb-2 pb-1">{{ contact?.coc_number }}</p>
                </div>
              </div>

              <div class="col-md-auto" >
                <div class="pt-0" *ngIf="contactAddress && !tradeInCart?.cart_extra?.end_customer">
                  <h4 class="font-kpn-bold green-text">Contact adres</h4>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize"
                    *ngIf="contactAddress?.company_name">Adres</h5>
                  <p class="body-text-1 mb-2" *ngIf="contactAddress?.company_name">
                    {{ contactAddress?.company_name }}
                  </p>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Straat</h5>
                  <p class="body-text-1 mb-2">
                    {{ contactAddress?.street }}
                  </p>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Huisnummer</h5>
                  <p class="body-text-1 mb-2">
                    {{ contactAddress?.house_number }} {{ contactAddress?.house_number_extension }}
                  </p>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Postcode</h5>
                  <p class="body-text-1 mb-2">
                    {{ contactAddress?.zip_code }}
                  </p>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Plaats</h5>
                  <p class="body-text-1 mb-2">
                    {{ contactAddress?.city }}
                  </p>
                </div>
                <div class="col-md" *ngIf="tradeInCart?.cart_extra?.end_customer">
                  <div class="pt-0">
                    <h4 class="font-kpn-bold green-text">Contact adres</h4>
                    <div *ngIf="tradeInCart?.shipping_address_company_name">
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                        {{'CUSTOMER.COMPANY_NAME' | transloco }}
                      </h5>
                      <p class="body-text-1 mb-2">
                        {{ tradeInCart?.shipping_address_company_name }}
                      </p>
                    </div>
                    <div>
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                        {{'ORDER_CONFIRMATION.NAME' | transloco }}</h5>
                      <p class="body-text-1 mb-2">
                        <span class="title-6 dark-grey-text"> </span>
                        {{ tradeInCart?.shipping_address_initials || tradeInCart?.shipping_address_first_name }}
                        {{ tradeInCart?.shipping_address_surname_prefix }}
                        {{ tradeInCart?.shipping_address_last_name }}
                      </p>
                    </div>
                    <div *ngIf="tradeInCart?.shipping_address_email">
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                        {{'ORDER_STATUS.E_MAIL' | transloco }}</h5>
                      <p class="body-text-1 mb-2">{{ tradeInCart?.shipping_address_email }}</p>
                    </div>
                    <div *ngIf="tradeInCart?.shipping_address_phone_number">
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                        {{'ORDER_STATUS.MOBILE_NO' | transloco }}</h5>
                      <p class="body-text-1 mb-2">{{ tradeInCart?.shipping_address_phone_number }}</p>
                    </div>
                    <div>
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Straat</h5>
                      <p class="body-text-1 mb-2">
                        {{ tradeInCart?.shipping_address_street }}
                      </p>
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Huisnummer</h5>
                      <p class="body-text-1 mb-2">
                        {{ tradeInCart?.shipping_address_house_number }} {{ tradeInCart?.shipping_address_house_number_extension
                        }}

                      </p>
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Postcode</h5>
                      <p class="body-text-1 mb-2">
                        {{ tradeInCart?.shipping_address_zip_code }}
                      </p>
                      <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">Plaats</h5>
                      <p class="body-text-1 mb-2">
                        {{ tradeInCart?.shipping_address_city }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md" *ngIf="contactPerson">
                <div class="pt-0">
                  <h4 class="font-kpn-bold green-text">Contactpersoon</h4>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                    {{'ORDER_CONFIRMATION.NAME' | transloco }}</h5>
                  <p class="body-text-1 mb-2">
                    <span class="title-6 dark-grey-text"> </span>
                    {{ contactPerson?.initials }}
                    {{ contactPerson?.last_name }}
                  </p>
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-exteded font-weight-bold text-capitalize">
                    {{'ORDER_STATUS.E_MAIL' | transloco }}</h5>
                  <p class="body-text-1 mb-2">{{ contactPerson?.email }}</p>

                  <!-- <p class="body-text-1 m-0" *ngIf="contactPerson?.mobile_phone_number">
                          <span class="title-6 dark-grey-text">{{'ORDER_CONFIRMATION.TELEPHONE' |
                            transloco }}: </span>
                          {{ contactPerson?.mobile_phone_number }}
                        </p>
                        <p class="body-text-1 mb-1">
                          <span class="title-6 dark-grey-text">:
                          </span>
                          {{ contactPerson?.email }}
                        </p> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4">
            <h2 class="tri-title-40 font-kpn-black green-text mb-3 pb-0">Dit verkoop je </h2>
            <div class="add-cart-prd last-item-m-0 mb-4 pb-1" *ngIf="functionalCart?.length">
              <h3 class="green-text font-kpn-black h4 mb-2">Werkende apparaten</h3>
              <p class="fs-20 font-kpn-exteded">
                Apparaten die naar behoren werken.
              </p>
              <div class="add-cart-prd-item last-item-m-0 mb-3" *ngFor="let item of functionalCart">
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-3">
                      <span class="font-kpn-extended">{{ item?.product?.brand?.name }}</span>
                      <h4 class="h4 font-kpn-bold pt-1 mb-0">{{ item?.product?.name }}</h4>
                      <span class="font-kpn-extended text-black-50">Geheugen: {{
                        item?.product?.memory_size }}</span>
                         <div class="font-kpn-extended text-black-50">Aantal: {{
                          item?.qty }}</div>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <!-- <button (click)="deleteCartLine(item)" class="add-cart-remove-btn mb-3"><i
                        class="kphicon-uniE92A"></i></button> -->
                    <div class="add-cart-pr-img" *ngIf="item?.product?.image">
                      <!-- <img src="../../../../assets/images/add-cart-prd-img.png" alt=""> -->
                      <img [src]="item?.product?.image" alt="">
                    </div>
                  </div>
                </div>
                <hr class="mt-0 border-color-tri">
                <div class="d-flex justify-content-between">
                  <span class="font-kpn-extended">Totaal</span>
                  <h4 class="h4 font-kpn-black green-text mb-0">{{ item?.total_price_ex_vat | currencyFormat }}</h4>
                </div>
              </div> 
            </div>

            <div class="add-cart-prd last-item-m-0 pb-1" *ngIf="nonFunctionalCart?.length">
              <h3 class="green-text font-kpn-black h4 mb-1">Niet werkende apparaten</h3>
              <p class="fs-20 font-kpn-matricregular line-height-24">
                Apparaten die naar behoren werken.
              </p>

              <div class="add-cart-prd-item mb-3 last-item-m-0" *ngFor="let item of nonFunctionalCart">
                <div class="row">
                  <div class="col-md-8">
                    <div class="mb-4">
                      <span class="font-kpn-extended">{{ item?.product?.brand?.name }}</span>
                      <h4 class="h4 font-kpn-bold pt-1 mb-0">{{ item?.product?.name }}</h4>
                      <span class="font-kpn-extended text-black-50">Geheugen: {{
                        item?.product?.memory_size }}</span>
                          <div class="font-kpn-extended text-black-50">Aantal: {{
                            item?.qty }}</div>
                    </div>
                  </div>
                  <div class="col-md-4 text-right">
                    <!-- <button (click)="deleteCartLine(item)" class="add-cart-remove-btn mb-3"><i
                        class="kphicon-uniE92A"></i></button> -->
                    <div class="add-cart-pr-img" *ngIf="item?.product?.image">
                      <!-- <img src="../../../../assets/images/add-cart-prd-img.png" alt=""> -->
                      <img [src]="item?.product?.image" alt="">
                    </div>
                  </div>
                </div>
                <hr class="mt-0 border-color-tri">
                <div class="d-flex justify-content-between">
                  <span class="font-kpn-extended">Totaal</span>
                  <h4 class="h4 font-kpn-black green-text mb-0">{{ item?.total_price_ex_vat | currencyFormat}}</h4>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="row" *ngIf="productPromotionList?.length">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4">
            <div>
              <h3 class="tri-title-40 font-kpn-black green-text mb-2">Extra inruilwaarde</h3>
              <p class="fs-20 font-kpn-matricregular line-height-24">
                Geef aan welk toestel je van plan bent aan te schaffen of hebt aangeschaft en krijg een
                indicatie hoeveel
                extra inruilwaarde je ontvangt.
              </p>
              <div class="row gutter-row-15">
                <div class="col-md-6 gutter-col-15 last-item-m-0 mb-3" *ngFor="let item of productPromotionList">
                  <div class="add-action-prd p-3 " [ngClass]="{'item-added': item?.cart}">
                    <div class="d-flex align-content-center justify-content-between mb-1">
                      <h6 class="font-sf-pro font-weight-bolder mb-1">{{ item?.name }}</h6>
                      <span class="font-kpn-matricbold green-text fs-20">{{ (item?.cart ?
                        item?.cart?.total_price_ex_vat : item?.price[0]?.price_ex_vat )| currencyFormat }}</span>
                    </div>
                    <div class="d-flex align-items-end justify-content-between">
                      <div class="mr-2">
                        <p class="font-sf-pro text-black-50 mb-0">{{ item?.description }}
                          <span *ngIf="item?.long_description">
                            <button type="button" (click)="aboutPromoProduct(aboutPromoProductLongDescriptionModal,item)" class="btn btn-link btn-sm p-0 m-0 font-kpn-extended d-inline-flex align-items-center">Meer zien...</button>
                          </span>
                        </p>
                        <a href="javascript:void(0)" (click)="aboutPromoProduct(aboutPromoProductModal,item)"
                          class="nav-link p-0 m-0 font-kpn-extended d-inline-flex align-items-center">{{item?.cart ? 'Wijzigen' : 'Meer informatie'}}  <i
                            class="kphicon-uniE907"></i></a>
                      </div>
                      <button (click)="deletePromotionProductFromCart(item?.cart)" *ngIf="item?.cart"
                        class="kphicon-uniE972 add-action-addmore-btn"></button>
                      <button (click)="aboutPromoProduct(aboutPromoProductModal,item)" *ngIf="!item?.cart"
                        class="kphicon-uniE985 add-action-addmore-btn"></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4">
            <div class="">
              <h3 class="tri-title-40 green-text font-kpn-black mb-4">Verkoopprijs</h3>

              <div>
                <h6 class="font-kpn-bold">inruilwaarde</h6>
                <p class="d-flex align-items-center justify-content-between mb-1" *ngIf="functionalCart?.length">
                  <span class="font-kpn-extended">Werkende apparaten ({{ functionTotalQty }})</span>
                  <strong class="font-kpn-bold green-text">{{ functionTotalPrice | currencyFormat}}</strong>
                </p>
                <p class="d-flex align-items-center justify-content-between" *ngIf="nonFunctionalCart?.length">
                  <span class="font-kpn-extended">Niet werkende apparaten ({{ nonFunctionTotalQty }})</span>
                  <strong class="font-kpn-bold green-text">{{ nonFunctionTotalPrice  | currencyFormat}}</strong>
                </p>
                <hr *ngIf="promotionCart?.length" class="mb-3">
                <div *ngIf="promotionCart?.length">
                  <h6 class="font-kpn-bold">Extra inruilwaarde</h6>
                  <p class="d-flex align-items-center justify-content-between mb-1" *ngFor="let item of promotionCart">
                    <span class="font-kpn-extended">{{ item?.product?.name }} ({{item?.qty}})</span>
                    <strong class="font-kpn-bold green-text">{{item?.total_price_ex_vat | currencyFormat}}</strong>
                  </p>
                </div>
                <hr class="mb-3">
              </div>


              <p class="d-flex align-items-center justify-content-between mb-0">
                <span class="font-kpn-black h4">Voorlopig totaal</span>
                <strong class="font-kpn-black green-text h4 mb-0">{{ tradeInCart?.total_price_ex_vat | currencyFormat }}</strong>
              </p>
            </div>
          </div>
        </div>
      </div>

      <div class="row ">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-4">
            <div class="btn-group-toggle radio_group radio_group_list_type mb-3" ngbRadioGroup name="provider_items" *ngFor="let item of terms_and_conditions_list">
              <label ngbButtonLabel class="checkbox-item d-flex pl-0 mb-0">
                <div class="checkbox-btn checkbox-btn-outline pt-1">
                  <input ngbButton type="checkbox" [(ngModel)]="item.accepted"
                   />
                  <span class="checkBoxCheckmark"></span>
                </div>
                <div class="checkbox-label ml-2 font-kpn-extended line-height-24 text-left">
                  <span  [innerHTML]="item?.content">
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row mb-2">
        <div class="col-md-8">
          <div class="col-md-12 col-12 col-gap-reduce pt-3" *ngIf="orderCreateError">
            <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
              <p>
                <span class="kphicon-uniE952"></span>
                <small> {{orderCreateError}}</small>
              </p>
            </div>
          </div>

          <div class="col-md-12 col-12 col-gap-reduce pt-3" *ngIf="tradeInCart && !tradeInCart?.lines?.length">
            <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
              <p>
                <span class="kphicon-uniE952"></span>
                <small>{{'TRADE_CART_OVERVIEW.YOUR_SHOPPING_CART_IS_EMPTY' | transloco}}</small>
              </p>
            </div>
          </div>

          <div class="row justify-content-between pt-4">
            <div class="col-auto">
              <button class="btn btn-link d-flex align-items-center" (click)="back()">
                <i class="kphicon-uniE906 fs-22"></i> Terug naar jouw overzicht
              </button>
            </div>
            <div class="col-auto">
              <button [disabled]="loader || (!isTermsAndConditionsAccepted) || !tradeInCart?.lines?.length"
                class="btn px-5 btn-primary rounded-btn ml-3" placement="bottom" (click)="createOrder()">
                <span class="spinner-border spinner-border-sm mr-2" *ngIf="loader" role="status"
                  aria-hidden="true"></span>
                  Plaats order
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <ng-template #aboutPromoProductModal let-modal>
      <div class="modal-header tri-modal-header">
        <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
          {{ selectedPromotionProduct?.name }}
        </h6>
        <button type="button" class="btn close" aria-label="Close" (click)="closePromotionProductModal()">
          <span class="kphicon-uniE926"></span>
        </button>
      </div>

      <div class="modal-body custom-scrollar">
        <div class="p-3 font-kpn-extended">
          <h4 class="font-kpn-black green-text mb-3">Hoe werkt de actie?</h4>
          <p>We hebben een speciale aanbieding voor de aanschaf van de nieuwe iPhone 15. Bij het inleveren van
            je oude toestel en bij aankoop van de iPhone 15, ontvang je extra trade-in waarde van €100,- per
            ingeleverd apparaat.</p>
          <p>Er zijn een aantal voorwaarden. Het aantal extra kortingen dat je ontvangt, kan niet hoger zijn
            dan
            het aantal apparaten dat je wilt inruilen. Bijvoorbeeld, als je 10 nieuwe iPhone 15 Pro
            Max-apparaten aanschaft en je wilt in totaal 100 apparaten inruilen, kun je slechts 10 keer de
            extra inruilwaarde van 100,- euro krijgen.</p>
          <p>Om van deze aanbieding te profiteren, dien je een factuur te sturen naar Recommerce, waarin
            duidelijk vermeld staat dat je één of meerdere iPhone 15's hebt aangeschaft. Zonder dit
            factuurbewijs kan het extra bedrag niet worden uitgekeerd.</p>
          <p>Na het afronden van de checkout ontvang je een e-mail met verdere instructies over het indienen
            van
            je factuur.</p>
        </div>
        <div class="px-3">
          <div class="add-cart-prd">
            <div class="add-cart-prd-item mb-3 p-4">
              <h3 class="green-text font-kpn-black h4 mb-1">Maak een keuze</h3>
              <p class="fs-20 font-kpn-matricregular line-height-24" >
                <span *ngIf="selectedPromotionProduct?.help_text?.length">
                  {{selectedPromotionProduct?.help_text}}
                </span>
                <span *ngIf="!selectedPromotionProduct?.help_text?.length">
                  Hoeveel {{ selectedPromotionProduct?.name }}  heb je besteld of ben je van plan te gaan bestellen?
                  <strong>Let op!</strong> Je kunt maximaal het {{ availableQty }} aantal apparaten selecteren welke je ook in gaat leveren.
                </span>
              </p>

              <div class="d-flex justify-content-between align-items-center">
                <div class="qty-in-di-box d-inline-flex align-items-center">
                  <button (click)="updatePromotionQty(selectedPromotionProduct?.qty -1)"
                  [disabled]="selectedPromotionProduct?.qty == 1 || (!selectedPromotionProduct?.qty)"
                    class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                  <input (keypress)="restrictInput($event)"  (keyup)="onChangePromotionQty($event)"  class="in-di-input font-kpn-extended bg-white" type="text"
                    [(ngModel)]="selectedPromotionProduct.qty" >
                  <button (click)="updatePromotionQty(selectedPromotionProduct?.qty +1)"
                    class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                </div>

                <div class="text-right">
                  <span class="font-kpn-extended small text-black-50">Totaal</span>
                  <h4 class="h6 font-kpn-black green-text mb-0">{{
                    selectedPromotionProduct?.totalPrice  | currencyFormat}}</h4>
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-center py-2 mb-3">
            <div class="col-md-9">
              <button
                (click)="selectedPromotionProduct?.cart ?  updatePromotionProductQuantity(selectedPromotionProduct?.cart,selectedPromotionProduct.qty) : addPromotionToCart()"
                class="btn btn-block btn-primary mt-2 rounded-btn">Keuze opslaan</button>
              <button (click)="deletePromotionProductFromCart(selectedPromotionProduct?.cart)"
                *ngIf="selectedPromotionProduct?.qty && selectedPromotionProduct?.cart"
                class="btn btn-block btn-outline-primary bg border-2 mt-2 py-1 px-4 rounded-btn font-kpn-extended d-flex justify-content-center align-items-center">
                <i class="kphicon-uniE92A h4 mb-0 mr-1"></i> Verwijder selectie</button>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
    <ng-template #aboutPromoProductLongDescriptionModal let-modal>
      <div class="modal-header tri-modal-header">
        <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
          {{ selectedPromotionProduct?.name }}
        </h6>
        <button type="button" class="btn close" aria-label="Close" (click)="closePromotionProductModal()">
          <span class="kphicon-uniE926"></span>
        </button>
      </div>

      <div class="modal-body custom-scrollar">
        <div class="p-3 font-kpn-extended">
          <p class="font-sf-pro text-black-50 mb-0">{{ selectedPromotionProduct?.long_description}}</p>
        </div>
      </div>
    </ng-template>
  </section>
</div>
