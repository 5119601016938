import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { PaymentService } from './payment.service';
import { TranslocoService } from '@ngneat/transloco';
import { StorageService } from 'src/app/shared/storage.service';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  @Input() order: any = null;
  @Input() contact: any = null;
  @Input() redirect: any = false;


  public idealPaymentForm: UntypedFormGroup;
  public paymentMethods = [];
  public selectedPaymentMethod = null;
  public paymentInitiated = false;
  private returnUrl = 'https://fe-producthub.designed4telecom.com';
  public paymentInitiateError = null;
  public talosRedirectUrl: string = null;
  public issuerList = [
    {
      name: 'ABN AMRO Bank',
      value: 'ABNANL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'ING',
      value: 'INGBNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Rabobank',
      value: 'RABONL2U',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'SNS Bank',
      value: 'SNSBNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'ASN Bank',
      value: 'ASNBNL21',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Knab',
      value: 'KNABNL2H',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'RegioBank',
      value: 'RBRBNL21',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Triodos Bank',
      value: 'TRIONL2U',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Van Lanschot Bankiers',
      value: 'FVLBNL22',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Bunq',
      value: 'BUNQNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Revolut',
      value: 'REVOLT21',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Yoursafe',
      value: 'BITSNL2A',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'N26',
      value: 'NTSBDEB1',
      group: 'Nederland',
      is_default: false
    },
    {
      name: 'Nationale-Nederlanden',
      value: 'NNBANL2G',
      group: 'Nederland',
      is_default: false
    },
  ].sort((a, b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);

  public TALOS_PAYMENT = 'ideal_talos';
  public DEPOT = 'depot';
  public DEPOT_NOT_FOUNT = '';
  // payment_method



  constructor(
    private paymentService: PaymentService,
    private fb: UntypedFormBuilder,
    private translocoService: TranslocoService,
    private storageService: StorageService,



  ) {
    this.issuerList.unshift({
      name: 'Kies je bank',
      value: null,
      group: 'Nederland',
      is_default: false
    });
  }

  ngOnInit(): void {
    if (this.order) {
      if (window.location?.origin?.includes('localhost')) {
        if (environment.node_env === 'postnl') {
          this.returnUrl = environment.returnUrl;
        }
        this.returnUrl = `${this.returnUrl}/order-confirmation/${this.order?.uuid}`;
      } else {
        this.returnUrl = `${window.location?.origin}/order-confirmation/${this.order?.uuid}`;
      }
      this.getPaymentMethods(this.order?.uuid);
      this.initializeIdealPaymentForm();
    }

    this.idealPaymentForm.get('payment_method_code').valueChanges.subscribe(val => {
      if (val === this.TALOS_PAYMENT) {
        this.idealPaymentForm.controls['issuer'].setValidators([Validators.required]);
      } else {
        this.idealPaymentForm.controls['issuer'].clearValidators();
      }
      this.idealPaymentForm.controls['issuer'].updateValueAndValidity();
    });
  }

  initializeIdealPaymentForm() {
    this.idealPaymentForm = this.fb.group({
      order_uuid: [this.order?.uuid, Validators.required],
      return_url: [this.returnUrl, Validators.required],
      issuer: new UntypedFormControl(null),
      payment_method_code: new UntypedFormControl('', [Validators.required]),
    });
  }



  getPaymentMethods(orderUuid: string) {
    this.DEPOT_NOT_FOUNT = '';
    this.paymentService.getPaymentMethods(orderUuid).subscribe(res => {
      this.paymentMethods = res?.data?.results?.map(item => {
        return { ...item, current_value: 0, selectable: true };
      }).sort((a, b) => b.code.toLowerCase() > a.code.toLowerCase() ? 1 : -1);
      const has_depot = this.paymentMethods.some(item => {
        return item.code === this.DEPOT;
      });
      if (has_depot && this.contact?.uuid) {
        this.paymentService.checkDepotEligibility(this.contact?.uuid).subscribe(response => {
          this.paymentMethods = this.paymentMethods.map(item => {
            return { ...item, current_value: item?.code === this.DEPOT ? response?.data?.current_value : 0 };
          });
          this.initializePaymentMethod();
        }, error => {
          this.DEPOT_NOT_FOUNT = error?.error?.error?.message;
          this.paymentMethods = this.paymentMethods.map(item => {
            return { ...item, selectable: item?.code === this.DEPOT ? false : true };
          });
          this.initializePaymentMethod();
        });
      } else {
        this.initializePaymentMethod();
      }

    }, error => {
      console.log('error: ', error);

    });
  }

  initializePaymentMethod() {
    const previouslySelectedPayment = this.storageService.getSelectedPaymentData();

    if (previouslySelectedPayment?.payment_type === 'ideal_talos') {
      this.selectedPaymentMethod = this.paymentMethods?.find(item => item.code === previouslySelectedPayment?.payment_type) || null;
      if (this.selectedPaymentMethod) {
        this.idealPaymentForm.patchValue({
          payment_method_code: this.selectedPaymentMethod.code,
          issuer: previouslySelectedPayment?.issuer
        });
        if (this.redirect) {
          setTimeout(() => {
            this.initiatePayment();
          }, 300);
        }
      }
    }else if (previouslySelectedPayment?.payment_type === 'depot') {
      this.selectedPaymentMethod = this.paymentMethods?.find(item => item.code === previouslySelectedPayment?.payment_type) || null;
      if (this.selectedPaymentMethod) {
        this.idealPaymentForm.patchValue({
          payment_method_code: this.selectedPaymentMethod.code,
        });
      }
    }
    else {
      this.selectedPaymentMethod = this.paymentMethods?.find(item => item.code === this.TALOS_PAYMENT) || null;
    if (this.selectedPaymentMethod) {
      this.idealPaymentForm.patchValue({
        payment_method_code: this.selectedPaymentMethod.code
      });
    }
    }

  }



  onPaymentMethodChange(method) {
    this.selectedPaymentMethod = method;
    this.paymentInitiateError = '';
    this.idealPaymentForm.patchValue({
      payment_method_code: this.selectedPaymentMethod?.code || ''
    });
  }

  initiatePayment() {
    this.paymentInitiateError = null;
    if (this.idealPaymentForm?.status === 'INVALID') {
      return;
    }

    if (this.selectedPaymentMethod.code === this.DEPOT && !parseFloat(this.selectedPaymentMethod.current_value)) {
      this.paymentInitiateError = `${this.translocoService.translate('PAYMENT.INSUFFICIENT_FUND')}`;
      return;
    }

    this.paymentInitiated = true;
    const payload = { ...this.idealPaymentForm.value };

    if (!payload.issuer) {
      delete payload.issuer;
    }

    this.paymentService.initiatePayment(payload).subscribe(response => {
      this.paymentInitiated = false;
      this.storageService.removeSelectedPaymentData();
      window.open(response?.data?.redirect_url, "_self").focus();
    }, error => {
      this.paymentInitiated = false;
      this.paymentInitiateError = error?.error?.error?.message || `${this.translocoService.translate('PAYMENT.PAYMENT_INITIATE_FAILED')}`;
    });
  }

}
