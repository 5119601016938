import { Action } from '@ngrx/store';

export enum ECatalogActions {
    CATALOG_LOAD = '[catalog] Catalogus inlezen gestart',
    CATALOG_LOAD_SUCCESS = '[catalog] Catalogus ingelezen',
    CLEAR_CATALOG = '[catalog] Clear Catalog List',
    SELECT_CATALOG_AND_PRODUCT_GROUP = '[catalog] Selecteer catalogus en product groep'
}

export class CatalogLoadAction implements Action {
    public readonly type = ECatalogActions.CATALOG_LOAD;
    constructor() { }
}

export class CatalogLoadSuccess implements Action {
    public readonly type = ECatalogActions.CATALOG_LOAD_SUCCESS;
    constructor(public catalogs: any[]) { }
}

export class ClearCatalog implements Action {
    public readonly type = ECatalogActions.CLEAR_CATALOG;
    constructor(public catalogs: any) { }
}

export class SelectCatalogAndProductGroupAction implements Action {
    public readonly type = ECatalogActions.SELECT_CATALOG_AND_PRODUCT_GROUP;
    constructor(public obj: any) { }
}

export type CatalogActionsList = CatalogLoadAction | CatalogLoadSuccess | ClearCatalog;
export type SelectCatalogAndProductGroup = SelectCatalogAndProductGroupAction;

