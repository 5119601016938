import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { Subject } from "rxjs";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { QuoteDetailsService } from "./quote-details.service";

@Component({
    selector: "app-search-product",
    templateUrl: "./search-product.component.html",
    styleUrls: [],
})

export class SearchProductComponent implements OnInit {
    @Input() index: number = -1;
    @Output() selectedProductEvent = new EventEmitter<any>();
    keyword: string = 'name';
    searchProductList: any[] = [];
    selectedProduct: string = '';
    searchedText: Subject<string> = new Subject<string>();
    searchingText: string = '';

    constructor(
        private quoteDetailsService: QuoteDetailsService,
        private toastrService: ToastrService,
    ) {

    }

    ngOnInit(): void {
        console.log('index: ', this.index);
    }

    searchProduct(event: any) {
        if (this.searchedText.observers.length === 0) {
            this.searchedText
                .pipe(debounceTime(1000), distinctUntilChanged())
                .subscribe(filterQuery => {
                    this.getProductList(filterQuery);
                });
        }
        this.searchedText.next(event?.trim());
    }

    getProductList(filterQuery: string) {
        this.searchingText = 'Searching...';
        this.quoteDetailsService.getProductSearch(filterQuery).subscribe(
            (res) => {
                this.searchProductList = [...res?.data?.results];
                this.searchProductList.map(product => product.name = `${product.code}-${product.name}`);
                this.searchingText = this.searchProductList.length ? '' : 'Not Found';
            },
            (error) => {
                this.searchingText = 'Not Found';
                this.toastrService.error('Error on search', 'error');
            }
        )
    }

    onSelectProduct(event: any, index: number) {
        this.selectedProductEvent.emit({
            'product': event,
            'index': index
        });
    }

    clearSearch() {
        this.searchingText = '';
        this.searchProductList = [];
        this.selectedProduct = '';
    }
}