<div>
<app-loader [isLoading]="isLoading"></app-loader>

<div class="bg-white c-px-24" [ngClass]="{'py-3 tri-rounded-20 tri-shadow-xl mb-4' :  !bottomZero, 'pb-3' : bottomZero}">
  <h4 *ngIf="showHeader" class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal mb-2 pb-1 pt-1">Producten</h4>
  <div class="quote-details-list sortable-table-list">
    <table class="table w-100 mb-4">
      <thead>
        <tr>
          <th class="fs-14">Artikelnummer</th>
          <th class="fs-14">Productnaam</th>
          <th class="fs-14">Aantal</th>
          <th class="fs-14">Productprijs</th>
          <th class="fs-14">Subtotaal</th>
          <th class="fs-14 text-nowrap">Korting (%)</th>
          <th class="fs-14 text-nowrap">Korting (€)</th>
          <th class="fs-14 text-right">Totaalbedrag</th>
        </tr>
      </thead>
      <tbody >
        <ng-container  *ngFor="let line of quote?.lines; let idx = index">
          <tr *ngIf="line?.showSystemTypeInFrontend ">
            <td colspan="8" class="font-kpn-extended py-2 fs-24 kpn-extended-black letter-spacing-normal green-text quote-product-type-border-bottom">
              <span class="d-block text-capitalize">{{ removeUnderscoreMethod(line?.product_type?.system_type)  }}</span>
            </td>
          </tr>
          <tr class="main-line-tr" *ngIf="line?.showLine">
            <td>{{ line?.product_code }}</td>
            <td>{{ line?.product_name }}</td>
            <td>{{ line?.qty }}</td>
            <td>{{ line?.price_ex_vat | currencyFormat }}</td>
            <td>{{ line?.amount_ex_vat | currencyFormat }}</td>
            <td>{{ line?.discount_type === "percentage" ? ((line?.discount_percentage ? line?.discount_percentage : line?.discount_on_original_sales_price_percentage) | currencyFormat:'') : '-'   }}%</td>
            <td>{{ line?.discount_type === "flat" ? ((line?.discount_price_ex_vat ? line?.discount_price_ex_vat : line?.discount_on_original_sales_price) | currencyFormat:'' ): '-' }}</td>
            <td class="text-right">{{ line?.total_amount_ex_vat | currencyFormat }}</td>
          </tr>
          <tr *ngFor="let subLine of line.sub_lines"  class="sub-line-tr">
            <td class="grey-text pb-0 pt-2"></td>
            <td class="grey-text pb-0 pt-2">{{ subLine?.product_name }}</td>
            <td class="grey-text pb-0 pt-2">{{ subLine?.qty }}</td>
            <td class="grey-text pb-0 pt-2">{{ subLine?.price_ex_vat | currencyFormat }}</td>
            <td class="grey-text pb-0 pt-2">{{ subLine?.amount_ex_vat | currencyFormat }}</td>
            <td class="grey-text pb-0 pt-2">{{ subLine?.discount_type === "percentage" ? ((subLine?.discount_percentage ? subLine?.discount_percentage : subLine?.discount_on_original_sales_price_percentage) | currencyFormat:'') : '-'   }}%</td>
            <td class="grey-text pb-0 pt-2">{{ subLine?.discount_type === "flat" ? ((subLine?.discount_price_ex_vat ? subLine?.discount_price_ex_vat : subLine?.discount_on_original_sales_price) | currencyFormat:'' ): '-' }}</td>
            <td class="grey-text pb-0 pt-2 text-right">{{ subLine?.total_amount_ex_vat | currencyFormat }}</td>
          </tr>
          <tr *ngIf="line?.showLine">
            <td class="px-0" colspan="8">
              <hr class="m-0">
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

  <div class="bg-gray-1 py-4 c-px-24 tri-rounded-20 mb-1">
    <div class="d-flex align-items-center justify-content-between pb-2">
      <h3 class="font-kpn-extended fs-20 mb-0">Totaalbedrag (excl. btw)</h3>
      <h3 class="font-kpn-extended fs-20 kpn-extended-bold mb-0 letter-spacing-normal">{{ quote?.total_amount_ex_vat | currencyFormat }}</h3>
    </div>
    <hr class="my-2">
    <div class="pt-2">
      <div class="d-flex align-items-center pb-2 justify-content-between" *ngFor="let additionalCost of additionalCosts">
        <span class="font-kpn-extended fs-16 grey-text">Verzendkosten</span>
        <span class="font-kpn-extended fs-16 grey-text">{{  additionalCost?.total_amount_ex_vat | currencyFormat }}</span>
      </div>
      <div class="d-flex align-items-center pb-2 justify-content-between">
        <span class="font-kpn-extended fs-16 grey-text">Btw</span>
        <span class="font-kpn-extended fs-16 grey-text">{{ quote?.total_amount_vat | currencyFormat }}</span>
      </div>
      <div class="d-flex align-items-center pb-0 justify-content-between">
        <span class="font-kpn-extended fs-16 grey-text">Totaal (incl. btw)</span>
        <span class="font-kpn-extended fs-16 grey-text">{{ quote?.total_amount | currencyFormat }}</span>
      </div>
    </div>
  </div>

  <!-- subscription section  -->
  <div *ngIf="quote?.subscription?.lines?.length">
    <h4  class="font-kpn-extended kpn-extended-black fs-35 green-text letter-spacing-normal pt-4 mb-2 pb-0">Abonnementen</h4>
    <div class="quote-details-list sortable-table-list">
      <table class="table w-100 mb-4">
        <thead>
          <tr>
            <th class="fs-14">Artikelnummer</th>
            <th class="fs-14">Productnaam</th>
            <th class="fs-14">Aantal</th>
            <th class="fs-14">Productprijs</th>
            <th class="fs-14">Subtotaal</th>
            <th class="fs-14 text-nowrap">Korting (%)</th>
            <th class="fs-14 text-nowrap text-right">Totaal per maand</th>
          </tr>
        </thead>
        <tbody >
          <ng-container  *ngFor="let line of quote?.subscription?.lines; let idx = index">
            <tr *ngIf="line?.showSystemTypeInFrontend">
              <td colspan="7" class="font-kpn-extended py-2 fs-24 kpn-extended-black letter-spacing-normal green-text quote-product-type-border-bottom">
                <span class="d-block text-capitalize">{{ removeUnderscoreMethod(line?.product_type?.system_type)  }}</span>
              </td>
            </tr>
            <tr class="main-line-tr">
              <td>{{ line?.product_code }}</td>
              <td>{{ line?.product_name }}</td>
              <td>{{ line?.qty }}</td>
              <td>{{ line?.price_ex_vat | currencyFormat }}</td>
              <td>{{ line?.amount_ex_vat | currencyFormat }}</td>
              <td>{{ line?.discount_type === "percentage" ? ((line?.discount_percentage ? line?.discount_percentage : line?.discount_on_original_sales_price_percentage) | currencyFormat:'') : '-'   }}%</td>
              <td class="text-right">{{ line?.total_amount_ex_vat | currencyFormat }}</td>
            </tr>
            <tr *ngFor="let subLine of line.sub_lines"  class="sub-line-tr">
              <td class="grey-text pb-0 pt-2"></td>
              <td class="grey-text pb-0 pt-2">{{ subLine?.product_name }}</td>
              <td class="grey-text pb-0 pt-2">{{ subLine?.qty }}</td>
              <td class="grey-text pb-0 pt-2">{{ subLine?.price_ex_vat | currencyFormat }}</td>
              <td class="grey-text pb-0 pt-2">{{ subLine?.amount_ex_vat | currencyFormat }}</td>
              <td class="grey-text pb-0 pt-2">{{ subLine?.discount_type === "percentage" ? ((subLine?.discount_percentage ? subLine?.discount_percentage : subLine?.discount_on_original_sales_price_percentage) | currencyFormat:'') : '-'   }}%</td>
              <td class="grey-text pb-0 pt-2 text-right">{{ subLine?.total_amount_ex_vat | currencyFormat }}</td>
            </tr>
            <tr>
              <td class="px-0" colspan="7">
                <hr class="m-0">
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>
    <div class="bg-gray-1 py-4 c-px-24 tri-rounded-20 mb-2">
      <div class="d-flex align-items-center justify-content-between pb-2">
        <h3 class="font-kpn-extended fs-20 mb-0">Totaal per maand (excl. btw) </h3>
        <h3 class="font-kpn-extended fs-20 kpn-extended-bold mb-0 letter-spacing-normal">{{ quote?.subscription?.total_amount_ex_vat | currencyFormat }}</h3>
      </div>
      <hr class="my-2">
      <div class="pt-2">
        <!-- <div class="d-flex align-items-center pb-1 justify-content-between">
          <span class="font-kpn-extended fs-16 grey-text">Verzendkosten</span>
          <span class="font-kpn-extended fs-16 grey-text">{{  quote?.total_transport_cost_ex_vat | currencyFormat }}</span>
        </div> -->
        <div class="d-flex align-items-center pb-2 justify-content-between">
          <span class="font-kpn-extended fs-16 grey-text">Btw</span>
          <span class="font-kpn-extended fs-16 grey-text">{{ quote?.subscription?.vat | currencyFormat }}</span>
        </div>
        <div class="d-flex align-items-center pb-0 justify-content-between">
          <span class="font-kpn-extended fs-16 grey-text">Totaal per maand (incl. btw)</span>
          <span class="font-kpn-extended fs-16 grey-text">{{ quote?.subscription?.total_amount | currencyFormat }}</span>
        </div>
      </div>
    </div>
  </div>
</div>



</div>
<div class="tri-shadow-xl tri-rounded-20 bg-white py-3 c-px-24  quote-action-sticky-bottom" [ngClass]="{'bottom-0' : bottomZero}">
  <div class="d-flex align-items-center justify-content-between">
    <div class="">
      <div class="d-inline-flex align-items-center">
        <span class="product-count-icon mr-3">
          <img src="../../../assets/images/teletext.svg" alt="">
        </span>
        <span class="font-kpn-extended letter-spacing-normal">{{ countTotalProductsQty() }} Producten</span>
      </div>
    </div>
    <div class="d-flex ">
      <div class="mr-md-4" *ngIf="eQuoteStatus.APPROVED == quote?.status  || eQuoteStatus.NEW == quote?.status ||  eQuoteStatus.PENDING == quote?.status">
        <span class="font-kpn-extended d-block grey-text fs-12"> Totaal eenmaling (excl. btw)</span>
        <span class="font-kpn-extended kpn-extended-black letter-spacing-normal kpn-font-color fs-16">{{ quote?.total_amount_ex_vat | currencyFormat }}</span>
      </div>
      <div class="mr-md-4" *ngIf="eQuoteStatus.APPROVED == quote?.status  || eQuoteStatus.NEW == quote?.status ||  eQuoteStatus.PENDING == quote?.status">
        <span class="font-kpn-extended d-block grey-text fs-12"> Totaal per maand (excl. btw)</span>
        <span class="font-kpn-extended kpn-extended-black letter-spacing-normal kpn-font-color fs-16">{{ quote?.total_recurring_monthly_fee_ex_vat | currencyFormat }}</span>
      </div>
    </div>
    <!-- bottomZero property is true when it is in modal  -->
    <div class="" *ngIf="!bottomZero && ((showRejectButton && (eQuoteStatus.APPROVED === quote?.status || this.eQuoteStatus.NEW === quote?.status)) || (this.eQuoteStatus.APPROVED === quote?.status && showPlaceOrderButton) || (eQuoteStatus.APPROVED !== quote?.status && eQuoteStatus.PROCESSED !== quote?.status && eQuoteStatus.NEW !== quote?.status &&  eQuoteStatus.PENDING !== quote?.status) || (eQuoteStatus.PROCESSED == quote?.status))">
      <div class="d-flex align-items-center" >

        <div class="">
          <button *ngIf="showRejectButton && (eQuoteStatus.APPROVED === quote?.status || this.eQuoteStatus.NEW === quote?.status)" class="btn btn-outline-red border-2 px-4 mr-md-3 rounded-btn"
          (click)="rejectOffer()"> Offerte afwijzen</button>
          <button  class="btn btn-primary rounded-btn px-4" *ngIf="this.eQuoteStatus.APPROVED === quote?.status && showPlaceOrderButton" (click)="goToInformationPage()"> Volgende stap</button>
          <button  class="btn btn-primary rounded-btn px-4"
          *ngIf="eQuoteStatus.APPROVED !== quote?.status && eQuoteStatus.PROCESSED !== quote?.status && eQuoteStatus.NEW !== quote?.status &&  eQuoteStatus.PENDING !== quote?.status"
           (click)="createNewQuote(quote?.uuid)"> Offerte opnieuw aanvragen</button>
          <button  class="btn btn-primary rounded-btn px-4" (click)="goToOrderDetails()"
          *ngIf="eQuoteStatus.PROCESSED == quote?.status">Mijn bestelling bekijken</button>
        </div>
      </div>
    </div>
  </div>
</div>
