<div class="row mb-2 mt-2">
    <div class="col-md-10">
        <div class="kpn-form-group">
            <label for="vat_number" class="field-label">BTW nummer</label>
            <div class="row">
                <div class="col-md-6">
                    <div class="field-with-spinner">
                        <input [disabled]="vat_loading" type="text" oninput="this.value = this.value?.trim()"
                            name="vat_number" [(ngModel)]="vat_number" (keyup.enter)="updateCustomer()"
                            class="form-control form-control-sm rounded-1">
                        <div *ngIf="vat_loading" class="spinner-border spinner-border-sm field-spinner" role="status">
                        </div>
                    </div>
                    <small class="text-dark small">Press <b>ENTER</b> to add BTW</small>
                </div>
                <div class="col-md-auto pl-0">
                    <button [disabled]="!vat_number?.length || vat_loading"
                        class="btn btn-primary pl-3  pr-2 text-center" (click)="updateCustomer()">
                        <span class="text-white pr-0"> Add BTW</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>