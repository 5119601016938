import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import * as _ from 'lodash';


@Component({
  selector: 'app-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss']
})
export class DropdownComponent implements OnInit {
  @Input() searchData;
  @Input() selectedData;
  @Input() activeDropdown;
  // tslint:disable-next-line:no-output-on-prefix
  @Output() onDataSelect: EventEmitter<any> = new EventEmitter<any>();


  searchFocused = false;
  keyword = 'name';


  initialValue = null;

  constructor() { }

  ngOnInit(): void {
    if (!_.isEmpty(this.selectedData)) {
      this.initialValue = this.selectedData;
    }
  }

  selectEvent(item) {
    if (this.selectedData !== item) {
      this.onDataSelect.emit(item);
    }
    // tslint:disable-next-line:no-unused-expression
    this.selectedData === item;
  }

  onInputCleared() {
    this.selectedData = null;
    this.onDataSelect.emit(this.selectedData);
  }

  onChangeSearch(val: string) {
    // fetch remote data from here
    // And reassign the 'data' which is binded to 'data' property.
  }

  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }

}
