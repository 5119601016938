import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Subscription, timer } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { selectCatalog } from 'src/app/store/selectors/catalog.selectors';
import { ExportProductService } from './export-products.service';
import { TranslocoService } from '@ngneat/transloco';
import { filter, pluck } from 'rxjs/operators';

@Component({
  selector: 'app-export-products',
  templateUrl: './export-products.component.html',
  styleUrls: ['./export-products.component.scss']
})
export class ExportProductsComponent implements OnInit {

  isLoading = false;
  exportLoading = false;
  csvLoading = false;
  xlsLoading = false;
  catalogList: any[] = [];
  products: any = [];
  catalogSubs$: Subscription;
  selectedCatalogCode = null;
  selectedFilterOption = null;
  exportPercentage = 0;
  fileName: string;
  public config = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0
  };

 



  constructor(
    public store: Store<IAppState>,
    private exportProductService: ExportProductService,
    private router: Router,
    public translocoService: TranslocoService


  ) {
    this.selectedFilterOption = this.filterOptions.find(obj => obj);
  }
  public filterOptions = [
    {
      name: 'SELECT OPTION',
      code: null
    },
    {
      // name: 'RECENT PRICE UPDATED PRODUCTS',
      name: this.translocoService.translate('EXPORT_PRODUCT.RECENT_PRICE_UPDATED_PRODUCTS'),
      code: 'recent_price_updated_products'
    },
    {
      // name: 'PHASED OUT PRODUCTS',
      name: this.translocoService.translate('EXPORT_PRODUCT.PHASED_OUT_PRODUCTS'),
      code: 'phased_out_products'
    },
    {
      // name: 'NEW PRODUCTS',
      name: this.translocoService.translate('EXPORT_PRODUCT.NEW_PRODUCTS'),
      code: 'new_products'
    },
  ];

  ngOnInit(): void {
    this.getCatalogList();
    this.translocoService.events$.pipe(
      filter(e => e.type === 'langChanged'  ),
      // pluck('payload')
    ).subscribe((res) => {
      console.log(res, "scope");
      
      this.filterOptions = [
        {
          name: 'SELECT OPTION',
          code: null
        },
        {
          // name: 'RECENT PRICE UPDATED PRODUCTS',
          name: this.translocoService.translate('EXPORT_PRODUCT.RECENT_PRICE_UPDATED_PRODUCTS'),
          code: 'recent_price_updated_products'
        },
        {
          // name: 'PHASED OUT PRODUCTS',
          name: this.translocoService.translate('EXPORT_PRODUCT.PHASED_OUT_PRODUCTS'),
          code: 'phased_out_products'
        },
        {
          // name: 'NEW PRODUCTS',
          name: this.translocoService.translate('EXPORT_PRODUCT.NEW_PRODUCTS'),
          code: 'new_products'
        },
      ];
    });

  }

  getCatalogList() {
    this.catalogSubs$ = this.store.select(selectCatalog).subscribe(list => {
      if (list) {
        this.catalogList = list;
        this.selectedCatalogCode = this.catalogList.find(obj => obj);
        if (this.selectedCatalogCode) {
          this.getProducts(this.selectedCatalogCode?.code);
        }
      }
    });
  }

  getProducts(catalog_code: string, filter_products = '', page = 1) {
    this.isLoading = true;
    this.products = [];
    this.config.totalItems = 0;
    this.exportProductService.getProducts(catalog_code, filter_products, page).subscribe(response => {
      this.products = response?.data?.results;
      this.config.totalItems = response?.data?.count;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  onChangeOptions() {
    if (this.selectedCatalogCode) {
      this.getProducts(this.selectedCatalogCode?.code, this.selectedFilterOption?.code);
    }
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getProducts(this.selectedCatalogCode?.code, this.selectedFilterOption?.code, this.config.currentPage);
  }

  goToProductDetails(product) {
    this.router.navigate(['/product-details', `${product?.code}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  exportProduct(report_format) {
    this.fileName = this.selectedCatalogCode?.name + ' Prijslijst';
    if (report_format === 'csv') {
      this.csvLoading = true;
    } else if (report_format === 'xlsx') {
      this.xlsLoading = true;
    }


    this.exportProductService.exportProduct(this.selectedCatalogCode?.code, this.selectedFilterOption?.code, report_format).subscribe(
      (res) => {
        if (res?.data?.task_id) {
          this.exportStatus(res?.data?.task_id, report_format);
        } else {
          this.csvLoading = false;
          this.xlsLoading = false;
        }
      },
      (err) => {
        this.csvLoading = false;
        this.xlsLoading = false;
      }
    );
  }

  exportStatus(taskId: string, report_format) {
    const delay = 5;
    let time = null;
    this.exportProductService.checkExportStatus(taskId).subscribe(
      (res) => {
        const current = res?.data?.current;
        if (current === 0) {
          this.exportPercentage = (this.exportPercentage + delay);
          time = setTimeout(() => {
            this.exportStatus(taskId, report_format);
          }, delay * 1000);
        } else if (current === 100) {
          this.exportPercentage = current;
          clearTimeout(time);
          const id = res?.data?.report ? res?.data?.report.split('/')[4] : null;
          if (id) {
            this.downloadFile(id, report_format);
          }
        }
      },
      (err) => {
        this.csvLoading = false;
        this.xlsLoading = false;
      }
    );
  }

  async downloadFile(id: number, report_format) {
    await this.exportProductService.downloadFile(id, this.fileName, report_format === 'csv' ? report_format : 'xlsx');
    this.csvLoading = false;
    this.xlsLoading = false;
    this.fileName = null;
    timer(5000).subscribe(time => {
      this.exportPercentage = 0;
    });
  }

}
