import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/storage.service';
import { QuoteDetailsService } from '../../quote-details.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { EAddressPrecision, EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { ESupplierTypes } from 'src/app/shared/enums/product-type.enums';

enum OperationType {
  Add = 'Add',
  Remove = 'Remove'
};

@Component({
  selector: 'app-customer-quote-details-information-step',
  templateUrl: './customer-quote-details-information-step.component.html',
  styleUrls: ['./customer-quote-details-information-step.component.scss']
})
export class CustomerQuoteDetailsInformationStepComponent extends BasePermission {
  private quoteUuid: string;
  public user = null;
  isLoading: boolean;
  quote: any;
  siteProfile = null;
  po_number = '';
  customer_reference = '';
  quoteUpdateError = null;
  feRequiredFieldErrors = {
    po_number: "",
    customer_reference: "",
  }
  hasCpaas = null;

  get isPoAndReferenceAdded() {
    return (this.quote?.po_number && this.quote?.customer_reference);
  }

  @ViewChild('creditLimitModal') creditLimitModal: any;
  constructor(
    private modalService: NgbModal,
    private quoteDetailsService: QuoteDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private storageService: StorageService,
    private customerService: CustomerService,
    public store: Store<IAppState>,
    private translocoService: TranslocoService,



  ) {
    super(store);

    this.quoteUuid = this.route.snapshot.params.quote_uuid;
    this.user = this.storageService.getCurrentUser();

  }
  get eQuoteStatus() { return EQuoteStatus; }
  get eAddressPrecision() {
    return EAddressPrecision;
  }


  ngOnInit() {
    if (this.quoteUuid) {
      this.getQuoteDetails();
    }
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });

  }
  getQuoteDetails() {
    this.isLoading = true;
    this.quoteDetailsService.getQuote(this.quoteUuid).subscribe(
      (res) => {
        this.quote = res.data;
        this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
        if (this.eQuoteStatus.APPROVED !== this.quote?.status) {
          this.router.navigate(['/customer/quote-details/', `${this.quote.uuid}`]);
        }
        this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
        this.getQuoteContactDetails();
      },
      (err) => {
        this.isLoading = false;
        console.log("err: ", err);
      }
    );
  }
  updatePoAndReferenceDataFromChild(quote) {
    this.quote = quote;
    this.po_number = this.quote?.po_number;
    this.customer_reference = this.quote?.customer_reference;
  }

  getQuoteContactDetails() {
    this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
      this.isLoading = false;
      this.quoteDetailsService.currentCustomerQuoteDetailsContactInfo$.next(res.data);
      this.po_number = this.quote?.po_number || '';
      this.customer_reference = this.quote?.customer_reference || '';

    }, error => {
      this.isLoading = false;
      console.log('error: ', error);

    })
  }

  async updateQuoteFields(payload = {}, message: string) {
    return this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, payload).toPromise().then(res => {
      this.toastrService.success(`${message} updated`, 'Success');
      this.quote = res.data;
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${message} updated failed`, 'Error');
      return false;
    });
  }




  onChangeQuotePoNumber(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.po_number = '';
    }
  }
  // updateQuotePoReference(payload) {
  //   this.quoteUpdateError = null;
  //   this.isLoading = true;
  //   this.quoteDetailsService.updateQuoteWithPoReference(payload).subscribe(
  //     (res) => {
  //       this.isLoading = false;
  //       this.quote = res.data;
  //       this.toastrService.success(`${this.translocoService.translate('QUOTE_DETAILS.QUOTATION_SUCCESSFULLY_UPDATED')}`);
  //     },
  //     (error) => {
  //       this.isLoading = false;
  //       this.quoteUpdateError = error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
  //       this.toastrService.error(this.quoteUpdateError, 'Error');
  //     }
  //   );
  // }
  async updateQuotePoReference(payload) {
    this.quoteUpdateError = null;
    this.isLoading = true;
    return this.quoteDetailsService.updateQuoteWithPoReference(payload).toPromise().then(res => {
      this.quote = res.data;
      this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.quoteUpdateError = error?.error?.message || `${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`;
      this.toastrService.error(this.quoteUpdateError, 'Error');
      return false;
    });
  }
  onChangeQuoteCustomerReference(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.customer_reference = '';
    }
  }

  async gotoToConfigOrSummeryPage() {
    if (this.siteProfile?.is_po_number_required && (!this.po_number.trim())) {
      this.feRequiredFieldErrors.po_number = 'PO-nummer is verplicht';
    }
    if (this.siteProfile?.is_reference_required && (!this.customer_reference.trim())) {
      this.feRequiredFieldErrors.customer_reference = 'Klantreferentie is verplicht';
    }

    if ((this.siteProfile?.is_po_number_required && (!this.po_number.trim())) || (this.siteProfile?.is_reference_required && (!this.customer_reference.trim()))) {

      return;
    }

    const payload = {
      uuid: this.quote.uuid,
      po_number: this.po_number,
      customer_reference: this.customer_reference
    }
    if (! await this.updateQuotePoReference(payload)) {
      this.isLoading = false;
      return;
    }

    if (this.quote?.is_contact_account_type_required || this.hasCpaas) {
     this.router.navigate(['/customer/quote-details-configuration', this.quote?.uuid]);
    }
    else {
      this.router.navigate(['/customer/quote-details-summary', this.quote?.uuid]);
    }

  }









  navigateToQuoteDetails() {
    this.router.navigate(['/customer/quote-details/', `${this.quote.uuid}`]);
  }
  backToPreviousPage() {
    this.router.navigate(['/customer/quote-details', this.quote?.uuid]);

  }


  openQuoteDetailsSummeryModal(openQuoteDetailsSummeryModal) {
    this.modalService.open(openQuoteDetailsSummeryModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      modalDialogClass: 'tri-modal-1124 modal-dialog-scrollable  modal-runded',
      scrollable: true,
      backdrop: 'static',
    });
  }
}
