import { Component, OnInit, Input, ElementRef, ViewChild, OnDestroy, AfterViewInit, HostListener } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, NavigationStart, Router } from '@angular/router';

import { filter, map, shareReplay } from 'rxjs/operators';
import { Observable, of, Subscription, timer } from 'rxjs';
import * as _ from 'lodash';

import { ProductDetailService } from 'src/app/pages/product-details/product-detail.service';
import { ToastrService } from 'ngx-toastr';

import KeenSlider, { KeenSliderInstance } from 'keen-slider';
import { ColorDictionary } from 'src/app/shared/utilities/static_variables';
import { FavoriteProductsService } from 'src/app/pages/favorite-products/favorite-products.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { EProductAttributeCodes } from 'src/app/shared/enums/product-type.enums';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StorageService } from 'src/app/shared/storage.service';
import { CartService } from 'src/app/pages/cart/cart.service';
import { ExportId } from 'src/app/shared/enums/flow-type.enums';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { selectCatalog } from 'src/app/store/selectors/catalog.selectors';
import { EProductAttributesGroup } from 'src/app/shared/enums/product-type.enums';


interface ColorOptions {
  color_code: string;
  attribute_value: string;
  canSelect: boolean;
}

interface MemoryOptions {
  color_code: string;
  attribute_value: string;
  canSelect: boolean;
}

@Component({
  selector: 'app-product-details-handset',
  templateUrl: './product-details-handset.component.html',
  styleUrls: ['./product-details-handset.component.scss']
})
export class ProductDetailsHandSetComponent extends BasePermission implements OnInit, OnDestroy, AfterViewInit {
  @Input() product;

  public catalogSubs$: Subscription = null;
  private catalogs = [];
  catalog = null;
  group = null;
  public subgroups = null;

  public product$: Observable<any>;
  colorOptions: ColorOptions[];
  memorySizeOptions: MemoryOptions[];
  selectedColor: '';
  selectedMemorySize: '';

  longDescription: string;

  moreSpecification = false;

  isSubmitted = false;
  selectedProduct: any;

  currentHandset: any = {};
  loading = false;
  productAttributes = [];
  productImg = [];
  selectedAttr = 'color';
  productSubs$: Subscription;
  showLoding = false;
  public qParams = null;
  public productCode = '';

  public catalogCode = '';
  public groupCode = '';
  siblingList = [];
  siblingProducts = [];

  isFavorite = false;

  // keenSlider start

  @ViewChild('sliderRef') sliderRef: ElementRef<HTMLElement>;
  @ViewChild("sliderSiblingRef") sliderSiblingRef: ElementRef<HTMLElement>;

  currentSlide = 1;
  dotHelper: Array<number> = [];
  slider: KeenSliderInstance = null;

  currentSlideSibling = 0;
  sliderSibling: KeenSliderInstance = null;
  wishlistData = null;
  logos = [];
  textPromotions = [];


  selectedProductImageIndex: number = 0;
  maxLength: number = 100;
  isExpanded: boolean = false;
  truncatedDescription: string = '';
  public promotionalProducts = [];
  public textPromotionalProducts = [];
  public user = null;
  public cart = null;
  private tempQty = 0;
  public quantity = 1;
  private tempSublines = [];
  public cartError = null;
  public preCartError = null;
  public preCartLines: any;
  public relatedProducts = [];
  preCart: any;
  public relatedGroupProducts = [];
  public restrictExportId = ExportId;
  public relatedAccessoryProducts = [];
  public selectedProductGroup = null;
  public productGroup = [];
  public siteProfile = null;
  public selectedTextPromotion = null;
  public show_een_message = false;



  constructor(
    private productDetailService: ProductDetailService,
    private toastrService: ToastrService,
    private router: Router,
    private location: Location,
    private route: ActivatedRoute,
    private favoriteProductsService: FavoriteProductsService,
    public store: Store<IAppState>,
    private translocoService: TranslocoService,
    public utilityService: UtilityService,
    private modalService: NgbModal,
    private storageService: StorageService,
    private cartService: CartService,
  ) {

    super(store)
    this.route.params.subscribe(p => {
      this.productCode = p.product_code;
    });
    this.route.queryParams.subscribe(p => {
      if (p.hasOwnProperty('catalogCode')) {
        this.catalogCode = p.catalogCode;
        this.groupCode = p.groupCode;
        this.qParams = { ...p };
      } else {
        this.qParams = { ...p };
        if (this.catalogCode) {
          this.qParams['catalogCode'] = this.catalogCode;
        }
        if (this.groupCode) {
          this.qParams['groupCode'] = this.groupCode;
        }
      }
      this.getCataLogList();


    });


    this.router.events
      .subscribe((event: NavigationStart) => {
        if (
          event instanceof NavigationStart &&
          event.navigationTrigger == "popstate" &&
          event.restoredState
        ) {
          timer(100).subscribe(time => {
            this.backToProductList();
          })
        }
      });

    this.favoriteProductsService.favoriteProducts$.subscribe(res => {
      this.wishlistData = res;
      this.checkFavoriteList(this.productCode);
    })


    this.user = this.storageService.getCurrentUser();
    this.getCart();
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });
  }


  checkFavoriteList(productCode) {
    this.isFavorite = this.wishlistData?.items?.some(item => {
      return item?.product?.code === productCode;
    })
  }

  productDescription(productDescriptionModal) {
    this.modalService.open(productDescriptionModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {

      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  productDealDescription(productDealDisModal, textPromotionObj) {
    this.selectedTextPromotion = textPromotionObj;
    this.modalService.open(productDealDisModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {

      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  productSpecification(productSpecificationModal) {
    this.modalService.open(productSpecificationModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-702 modal-runded-sm',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {

      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addedToShoppingCart(shoppingCartModal) {
    this.modalService.open(shoppingCartModal, {
      ariaLabelledBy: 'modal-basic-title',
      modalDialogClass: 'tri-modal-shopping-cartt-448 ',
      size: 'md',
      scrollable: true,
    }).result.then((result) => {

      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  ngAfterViewInit() {
    // this.initiateSlider();
  }

  initiateSlider() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s?.track?.details?.rel || 0;
        },
      });
      this.dotHelper = [
        ...Array(this.slider?.track?.details?.slides?.length).keys(),
      ];
    }, 1000);
  }

  private siblingSliderInit() {
    setTimeout(() => {
      this.sliderSibling = new KeenSlider(this.sliderSiblingRef.nativeElement, {
        initial: this.currentSlideSibling,
        slideChanged: (s) => {
          this.currentSlideSibling = s?.track?.details?.rel;
        },
        slides: {
          perView: 3,
          spacing: 15,
        },
      });
    }, 1000);

  }

  ngOnInit() {
    // this.product$ = of(this.product).pipe(
    //   map(res => {
    //     return res;
    //   }),
    //   shareReplay()
    // );

    this.selectedProduct = this.product;
    // this.productDetailService.setSelectedProduct(this.selectedProduct);
    // this.memorySizeOptions = this.product.memory_size_options;
    // this.colorOptions = this.product.color_options;
    // this.setDimensionalAttributes();
    // this.filterChildProduct();
    // this.product$.subscribe(event => {
    //   this.currentHandset = event;
    //   this.longDescription = event?.long_description;
    // });
    // tslint:disable-next-line:max-line-length
    // const imgs = this.product?.image_documents?.length ? [...this.product?.image_documents] : this.product?.image ? [{ images: this.product?.image }] : [{ images: '/assets/images/image-not-available.png' }];
    // this.productImg.push(...imgs);
    // this.getDimentionalAttributes();
    if (this.product?.product_group?.page_layout?.code !== 'show_iot_siblings') {
      this.getRelatedAttributes();
    }
    this.getProductInfo(this.product);
    if (this.product?.siblings?.length) {
      this.selectedAttr = 'color';
      this.refineAttrOptions();
      this.filterSiblingProduct();
    }
    this.productSubscription();
    this.show_een_message = this.checkEENGroup();


  }


  populateImages() {
    const imgs = this.product?.image_documents?.length ? [...this.product?.image_documents] : this.product?.image ? [{ images: this.product?.image }] : [{ images: '/assets/images/image-not-available.png' }];
    this.productImg = [...imgs];
    this.currentSlide = this.productImg?.length > 1 ? 1 : 0;
    // this.initiateSlider();
  }

  getProductAttributes(code: string) {
    this.productDetailService.getProductAttributes(code).subscribe(
      (response) => {
        this.productAttributes.push(...response?.data?.product_attributes);
      }, (error) => {
        // this.showErrorToaster('Error on fetching product specification');
        this.showErrorToaster(`${this.translocoService.translate('PRODUCT_DETAILS.ERROR_ON_FETCHING_PRODUCT_SPECIFICATION')}`);
      }
    );
  }

  private productSubscription() {
    this.productSubs$ = this.productDetailService.getSelectedProduct().subscribe(
      (event) => {
        if (Object.keys(event).length) {
          this.currentHandset = event;
          this.selectedProduct = event;
          this.getProductAttributeForLogo();
          this.product = event;
          if (this.product?.child_products?.length) {
            const firstChild = this.product?.child_products?.find(item => item)?.code;
            this.router.navigate(['/product-details', `${firstChild}`], {
              queryParams: { ...this.qParams },
              queryParamsHandling: 'merge',
              replaceUrl: true
            });
          }
          this.populateImages();
          this.updateTruncatedDescription();
          if (this.selectedProduct?.has_promotion) {
            this.getPromotionalProducts(this.selectedProduct?.code);
          } else {
            this.promotionalProducts = [];
            this.textPromotionalProducts = [];
            // this.onTextPromotions.emit({
            //   textPromotions: this.textPromotionalProducts
            // })
          }
          this.getRelatedAccessoryProducts(this.selectedProduct?.code);
          this.getRelatedProducts(this.selectedProduct?.code);
          // this.refineAttrOptions();
        }
      }
    );
  }

  getRelatedAttributes() {
    const color = this.product?.related_attributes?.find(data =>
      data.attribute_code === 'color')?.attribute_values?.map(obj => ({ ...obj, canSelect: true }));
    this.colorOptions = color != null ? color?.map(item => {
      return { ...item, color_code: item?.color_code || this.getColor(item?.attribute_value?.trim()) };
    })?.sort((a, b) => a.attribute_value.localeCompare(b.attribute_value)) : [];
    const memory = this.product?.related_attributes?.find(data =>
      data.attribute_code === 'memory_size')?.attribute_values?.map(obj => ({ ...obj, canSelect: true }));
    this.memorySizeOptions = memory != null ? this.sortMemorySize(memory)?.map(data => {
      return {
        ...data,
        attribute_value: data?.attribute_value?.replace(/\s/g, "")
      }
    }) : [];
    this.selectedAttr = (this.colorOptions?.length) ? 'color' : 'memory_size';
  }

  sortMemorySize(memory: any[] = []) {
    return memory.sort((a, b) => {
      let num1 = parseInt(a.attribute_value.split("GB").shift(), 10);
      let num2 = parseInt(b.attribute_value.split("GB").shift(), 10);
      if (a.attribute_value?.includes("TB")) {
        num1 = parseInt((a.attribute_value.split("TB").shift() * 1000).toString(), 10);
      }

      if (b.attribute_value?.includes("TB")) {
        num2 = parseInt((b.attribute_value.split("TB").shift() * 1000).toString(), 10);
      }
      return num1 - num2;
    });
  }

  getDimentionalAttributes() {
    if (this.product?.siblings?.length) {
      const sibling = this.product?.siblings[0];
      this.selectedColor = sibling?.dimensional_attributes.find(data => data.attribute_code === 'color')?.attribute_value || null;
      this.selectedMemorySize = sibling?.dimensional_attributes.find(data =>
        data.attribute_code === 'memory_size')?.attribute_value?.replace(/\s/g, "") || null;
    } else {
      this.selectedColor = this.product?.dimensional_attributes.find(data => data.attribute_code === 'color')?.attribute_value;
      this.selectedMemorySize = this.product?.dimensional_attributes.find(data => data.attribute_code === 'memory_size')?.attribute_value?.replace(/\s/g, "");
    }
  }

  findDimensionalAttributes() {
    if (this.product?.siblings?.length) {
      const value = [];
      this.product?.siblings?.forEach(ele => {
        ele?.dimensional_attributes?.find(data => data.attribute_code === 'color');
      });
    }
  }

  onProductDetail(product, index) {
    this.router.routeReuseStrategy.shouldReuseRoute = () => {
      return false;
    };
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigate(['/product-details', `${product?.code}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  onMemoryChange(memory: any) {
    this.showLoding = true;
    this.selectedMemorySize = memory;
    this.selectedAttr = 'memory_size';
    // this.filterChildProduct();
    this.refineAttrOptions();
    this.filterSiblingProduct(true);
  }

  onColorChange(color: any) {
    this.showLoding = true;
    this.selectedColor = color;
    this.selectedAttr = 'color';
    timer(100).subscribe(time => {
      this.refineAttrOptions();
    })
    timer(200).subscribe(time => {
      this.filterSiblingProduct(true);
    })
  }

  setDimensionalAttributes() {
    if (this.selectedProduct?.child_products.find(obj => true)) {
      // tslint:disable-next-line:max-line-length
      const color = this.selectedProduct.child_products[0].dimensional_attributes.length > 0 ? _.find(this.selectedProduct.child_products[0].dimensional_attributes, (dAt) => dAt.attribute_code === 'color') : null;
      this.selectedColor = color ? color.code : '';
      // tslint:disable-next-line:max-line-length
      const memorySize = this.selectedProduct.child_products[0].dimensional_attributes.length > 0 ? _.find(this.selectedProduct.child_products[0].dimensional_attributes, (dAt) => dAt.attribute_code === 'memory_size') : null;
      this.selectedMemorySize = memorySize ? memorySize.code?.replace(/\s/g, "") : '';
    }
  }

  filterChildProduct() {
    if (this.product?.siblings.find(obj => true)) {
      this.product$ = of(this.product).pipe(
        map(res => {
          const filteredProduct = _.find(this.product.siblings, (prod) => {
            if (prod.dimensional_attributes) {
              const colorAttr = _.find(prod.dimensional_attributes, (dAt) => dAt.attribute_code === 'color');
              const memoryAttr = _.find(prod.dimensional_attributes, (dAt) => dAt.attribute_code === 'memory_size');
              return this.isColorMatched(colorAttr) && this.isMemoryMatched(memoryAttr);
            }
          });

          this.selectedAttr = (this.selectedAttr === 'color' && this.colorOptions.length) ? this.selectedAttr : 'memory_size';

          const attribute = filteredProduct || this.product.siblings[0];
          this.productDetailService.getProduct(attribute?.code, this.catalogCode).subscribe((productData) => {
            const color = attribute?.dimensional_attributes?.find(data => data.attribute_code === 'color');
            const memorySize = attribute?.dimensional_attributes?.find(data => data.attribute_code === 'memory_size');

            this.selectedProduct = filteredProduct ? filteredProduct : this.product.siblings[0];
            this.selectedProduct = { ...this.selectedProduct, color, memory_size: memorySize, order_unit: productData?.data?.order_unit };
            this.productDetailService.setSelectedProduct(this.selectedProduct);
            this.selectedColor = this.selectedProduct?.color?.attribute_value;
            this.selectedMemorySize = this.selectedProduct?.memory_size?.attribute_value?.replace(/\s/g, "");
            this.refineAttrOptions();
          }, (err) => {
            console.log('err: ', err);
          });
          return this.selectedProduct;
        }),
        shareReplay()
      );
    }
  }

  filterSiblingProduct(shouldCallInfo: boolean = false) {
    // tslint:disable-next-line:variable-name
    let product_code = null;
    if (!this.selectedColor && !this.selectedMemorySize) {
      product_code = this.product.code || this.product.siblings?.find(item => item)?.code;
    } else {
      let temp = null;
      if (this.colorOptions.length && this.memorySizeOptions.length) {
        temp = _.find(this.product.siblings, (prod) => {
          if (prod.dimensional_attributes) {
            const colorAttr = _.find(prod.dimensional_attributes, (dAt) => dAt.attribute_code === 'color');
            const memoryAttr = _.find(prod.dimensional_attributes, (dAt) => dAt.attribute_code === 'memory_size');
            const val = this.isColorMatched(colorAttr) && this.isMemoryMatched(memoryAttr);
            return val;
          }
        });
      } else {
        temp = _.find(this.product.siblings, (prod) => {
          if (prod.dimensional_attributes) {
            const colorAttr = _.find(prod.dimensional_attributes, (dAt) => dAt.attribute_code === 'color');
            const memoryAttr = _.find(prod.dimensional_attributes, (dAt) => dAt.attribute_code === 'memory_size');
            // tslint:disable-next-line:max-line-length
            return (this.selectedAttr === 'color' && this.isColorMatched(colorAttr)) || (this.selectedAttr === 'memory_size' && this.isMemoryMatched(memoryAttr));
          }
        });
      }
      product_code = temp ? temp.code : this.product.siblings?.find(item => {
        let sibling_product = item?.dimensional_attributes?.find(dat => dat?.attribute_code === 'color');
        return sibling_product?.attribute_value?.toLowerCase() === this.selectedColor?.toLowerCase()
      })?.code;
    }

    if (!product_code) {
      this.showLoding = false;
      return;
    }
    if (shouldCallInfo) {
      this.getSelectedProduct(product_code);
    }
  }

  private getSelectedProduct(productCode: string) {
    this.productDetailService.getProduct(productCode, this.catalogCode).subscribe((productData) => {
      this.getProductInfo(productData?.data);
    }, (error) => {
      this.showLoding = false;
    });
    // this.refineAttrOptions();
  }

  private getProductInfo(productData: any) {
    if (!productData) {
      this.showLoding = false;
      return;
    }
    this.siblingProducts = [];
    const color = productData?.dimensional_attributes?.find(data => data.attribute_code === 'color') || null;
    const memorySize = productData?.dimensional_attributes?.find(data => data.attribute_code === 'memory_size') || null;
    this.selectedProduct = { ...productData, color, memory_size: memorySize, order_unit: productData?.order_unit };
    this.getProductAttributeForLogo();
    this.selectedColor = this.selectedProduct?.color?.attribute_value || null;
    this.selectedMemorySize = this.selectedProduct?.memory_size?.attribute_value?.replace(/\s/g, "") || null;
    this.productDetailService.setSelectedProduct(this.selectedProduct);
    this.siblingList = [...productData?.siblings];
    if (!productData?.dimensional_attributes?.length && (productData?.siblings?.length || productData?.child_products?.length)) {
      this.siblingProducts = [...productData?.siblings, ...productData?.child_products];
    }
    if (this.siblingList.length && productData?.product_group?.page_layout?.code === 'show_iot_siblings') {
      this.siblingSliderInit();
    }
    this.selectedAttr = 'color';
    this.refineAttrOptions();
    // this.showLoding = false;
    this.checkFavoriteList(productData?.code);
    this.changeUrl();
  }

  private refineAttrOptions() {
    let arr = [];
    arr.push(this.product?.dimensional_attributes.find(data => data.attribute_code === 'memory_size')?.attribute_value?.replace(/\s/g, ""));
    if (this.selectedAttr === 'color') {
      this.product?.siblings?.forEach(ele => {
        if (ele?.dimensional_attributes?.some(data => data.attribute_value?.toLowerCase() === this.selectedColor?.toLowerCase())) {
          arr.push(ele?.dimensional_attributes?.find(data => data.attribute_code === 'memory_size')?.attribute_value?.replace(/\s/g, ""));
        }
      });
      arr = [...new Set(arr)];
      this.memorySizeOptions = this.memorySizeOptions?.map(data => {
        return {
          ...data,
          canSelect: arr.includes(data.attribute_value?.replace(/\s/g, ""))
        };
      });
      this.removeDuplicateMemory();
    } else {
      // this.product?.siblings?.forEach(ele => {
      //   if (ele?.dimensional_attributes.findIndex(data => data.attribute_value === this.selectedMemorySize) > -1) {
      //     arr.push(ele?.dimensional_attributes.find(data => data.attribute_code === 'color')?.attribute_value);
      //   }
      // });
      // console.log('arr:3333 ', arr);
      // this.colorOptions = this.colorOptions.map(data => {
      //   return arr.includes(data.attribute_value) === true ? {
      //     ...data,
      //     canSelect: true,
      //     color_code: this.getColor(data?.attribute_value)

      //   } : { ...data, canSelect: false, color_code: this.getColor(data?.attribute_value) };
      // });
    }
  }

  removeDuplicateMemory() {
    this.memorySizeOptions = Array.from(
      new Set(this.memorySizeOptions.map((obj) => obj.attribute_value))
    ).map((attribute_value) => {
      return this.memorySizeOptions.find((obj) => obj.attribute_value === attribute_value);
    });
  }

  isColorMatched(color: any) {
    if (color) {
      return color.attribute_value?.toLowerCase() === this.selectedColor?.toLowerCase();
    }
    return false;
  }

  isMemoryMatched(memory: any) {
    if (memory) {
      return memory.attribute_value?.replace(/\s/g, "") === this.selectedMemorySize?.replace(/\s/g, "");
    }
    return false;
  }

  readMoreSpecification() {
    this.moreSpecification = !this.moreSpecification;
  }

  showErrorToaster(msg) {
    this.toastrService.error(`${msg}`, 'Error', {
      timeOut: 4000
    });
  }

  changeUrl() {
    const urlTree = this.router.createUrlTree(['product-details/' + this.selectedProduct?.code], {
      queryParams: { ...this.qParams },
      queryParamsHandling: 'merge'
    });
    this.location.go(urlTree.toString());
  }

  backToProductList() {
    this.router.navigate(['/product-list'], {
      queryParams: {
        ...this.qParams
      },
      replaceUrl: true,
      queryParamsHandling: 'merge',
    });
  }
  backToHome() {
    this.router.navigate(['/home']);
  }

  downloadPDF(url: string) {
    window.open(url, '_blank');
  }

  getColor(name: string) {
    return name ? ColorDictionary[name?.replace(/[\s\-]/g, '_')] || name : ColorDictionary['NoColor'];
  }

  addToFavorite() {
    this.favoriteProductsService.addToFavorite(this.product?.code);
  }

  removeFromFavorite() {
    const wishlist_item = this.wishlistData?.items?.find(item => item?.product?.code === this.selectedProduct.code)?.uuid;
    this.favoriteProductsService.removeFromFavoriteList(wishlist_item);
  }


  getProductAttributeForLogo(group_code = 'is_search') {
    let attributes = this.selectedProduct?.product_attributes?.find(attribute => attribute?.group_code === group_code)?.attributes || [];
    this.logos = attributes.filter(attribute => EProductAttributeCodes.some(code => attribute?.attribute_value && attribute.attribute_code === code))?.map(attr => {
      return {
        ...attr,
        logo: this.utilityService.stickers?.find(sticker => sticker?.type === attr.attribute_code)?.logo
      }
    });
  }


  onTextPromotionalProduct(event) {
    this.textPromotions = event?.textPromotions || []
  }


  updateTruncatedDescription() {
    this.truncatedDescription = this.currentHandset?.long_description?.length > this.maxLength
      ? this.currentHandset?.long_description?.slice(0, this.maxLength) + '...'
      : this.currentHandset?.long_description;
  }

  toggleDescription() {
    this.isExpanded = !this.isExpanded;
  }

  getPromotionalProducts(productCode: string) {

    let contact_uuid = '';
    if (this.user?.contact_group?.is_external) {
      const customer = this.storageService?.getCustomer();
      contact_uuid = customer?.uuid ?? this?.user?.contact?.uuid;
    } else {
      const customer = this.storageService?.getCustomer();
      contact_uuid = customer?.uuid;
    }
    this.productDetailService.getPromotionalProducts(productCode, contact_uuid).subscribe(res => {
      this.textPromotionalProducts = res?.data?.results?.filter(item => item?.is_promotional_text);
      // this.onTextPromotions.next({
      //   textPromotions: this.textPromotionalProducts
      // })
      this.promotionalProducts = res?.data?.results?.filter(item => !item?.is_promotional_text)?.map(group => {
        return {
          ...group,
          mandatory_products: group?.mandatory_products?.map(product => {
            return {
              ...product,
              selected: false,
              disable: false
            }
          }),
          optional_products: group?.optional_products?.map(product => {
            return {
              ...product,
              selected: false,
              disable: false
            }
          }),
        }
      });

      this.populateCartWithSubLine();
    })
  }

  populateCartWithSubLine() {
    this.showLoding = true;
    let sub_lines = this.populateSubLine();
    this.tempSublines = sub_lines;
    if (Object.keys(this.selectedProduct).length) {
      this.getQuantity();
      setTimeout(() => {
        this.preCartEnrich(this.quantity, false, sub_lines);
      }, 300);
    } else {
      this.showLoding = false;
    }
  }

  populateSubLine() {
    let sub_lines = [];
    const productInCart = this.findProductInCart(this.selectedProduct);
    if (productInCart) {
      let cartSubLines = productInCart?.sub_lines;
      this.relatedProducts?.forEach(group => {
        group.products?.forEach(product => {
          if (cartSubLines?.some(item => item?.product_code === product?.code)) {
            product.selected = true;
          }
        });
      });

      this.populatePromotionalProductInPreCart(cartSubLines);

      sub_lines = cartSubLines?.filter(item => !item?.product_code?.startsWith('tkh_'))?.map(sub_line => {
        return {
          product_code: sub_line?.product_code,
          quantity: this.quantity || 1,
          uuid: sub_line?.uuid,
          product_name: sub_line?.product_name,
          promotion_code: sub_line?.promotion_code,
          flow_type_code: "new",
          sub_lines: []
        }
      });
    } else {
      this.relatedProducts?.forEach(product => {
        if (product?.min_required >= 1) {
          for (let index = 0; index < product?.min_required; index++) {
            const first_product = product?.products[index];
            let first_item = {
              product_code: first_product?.code,
              quantity: this.quantity || 1,
              product_name: first_product?.name,
              promotion_code: '',
              flow_type_code: "new",
              sub_lines: []
            };
            if (product.products[index]) {
              product.products[index].selected = true;
            }
            sub_lines.push(first_item);
          }
        }
      });

      sub_lines = this.populatePromotionalProductAsSelected(sub_lines)
    }

    const unique_sub_lines = sub_lines.reduce((accumulator, current) => {
      if (!accumulator.some((item) => item.product_code === current.product_code)) {
        accumulator.push(current);
      }
      return accumulator;
    }, []);
    return unique_sub_lines;
  }

  findProductInCart(product) {
    const childProductCode = product?.child_products && product?.child_products.length ? product?.child_products[0]?.code : null;
    return this.cart?.lines.find(obj => {
      return obj.product_code === product.code || obj.product_code === childProductCode;
    }) || null;
  }

  getQuantity() {
    const productInCart = this.findProductInCart(this.selectedProduct);
    if (productInCart) {
      this.quantity = productInCart.quantity ? productInCart.quantity : 1;
    } else {
      this.quantity = this.selectedProduct?.order_unit || 1;
    }
    this.tempQty = this.quantity;
  }

  preCartEnrich(amountOfProduct, isAddedToCart = false, sub_lines = [], forRelatedProduct = false) {
    this.showLoding = true;
    this.preCartError = null;
    const contactPersonUuid = this.storageService.getContactPersonUUID();
    const shippingAddressUuid = this.storageService.getShippingAddressID() || null;
    const productPayload = {
      market_type_code: 'business',
      contact_person_uuid: contactPersonUuid,
      shipping_address_id: contactPersonUuid && shippingAddressUuid ? shippingAddressUuid : '',
      lines: [
        {
          product_code: this.selectedProduct?.code,
          quantity: amountOfProduct,
          flow_type_code: 'new',
          sub_lines: sub_lines
        }
      ]
    };

    if (!productPayload.contact_person_uuid) {
      delete productPayload.contact_person_uuid;
    }

    this.cartService.preCartEnrich(productPayload).subscribe((res) => {
      this.showLoding = false;
      this.preCartLines = res.data.lines;
      let has_promotions = this.preCartLines?.find(line => line?.has_promotion);
      this.preCart = res.data.lines.length ? res.data.lines[0] : null;
      this.quantity = this.preCart ? this.preCart.quantity : (this.selectedProduct?.order_unit || 1);
      if (isAddedToCart) {
        this.onAddToCart(this.selectedProduct, 0, forRelatedProduct);
      }
    }, error => {
      this.showLoding = false;
      this.quantity = this.tempQty;
      this.preCartError = error?.error?.message;
      this.showErrorToaster(error?.error?.message);
    });
  }
  beforeAddToCart(selectedProduct) {

    if (this.quantity > 0 && this.preCartError) {
      let sub_lines = this.populateSubLine();
      this.tempSublines = sub_lines;
      this.preCartEnrich(this.quantity, true, sub_lines);
    }else {
      this.onAddToCart(selectedProduct);

    }
  }
  onAddToCart(selectedProduct, defaultQty = 0, forRelatedProduct = false, isAccessoryProduct = false) {
    if (this.quantity < 1 || this.preCartError) {
      this.showErrorToaster(`${this.translocoService.translate('PRODUCT_DETAILS.PLEASE_SELECT_AT_LEAST_ONE')}`);
      return;
    }

    const cartId = this.storageService.getCart().uuid;

    const cart = {
      market_type_code: 'business',
      lines: [
        {
          product_code: selectedProduct.code,
          product_name: selectedProduct.name,
          product_type: selectedProduct?.product_type?.code || null,
          quantity: defaultQty || this.quantity,
          flow_type_code: 'new',
          sub_lines: isAccessoryProduct ? [] : this.formatPreCartSubLine() || []
        },
      ],
    };

    const contactPersonUUID = this.storageService.getContactPersonUUID();
    if (contactPersonUUID) {
      // tslint:disable-next-line:no-string-literal
      cart['contact_person_uuid'] = contactPersonUUID;
    }

    const shippingAddressUuid = this.storageService.getShippingAddressID() || null;
    if (shippingAddressUuid) {
      // tslint:disable-next-line:no-string-literal
      cart['shipping_address_id'] = shippingAddressUuid;
    }


    if (!cartId) {
      this.createCart(cart, selectedProduct, forRelatedProduct);
    } else {
      const findProduct = this.findProductInCart(selectedProduct);
      if (findProduct) {
        this.updateCartItem(findProduct, selectedProduct, defaultQty, forRelatedProduct, isAccessoryProduct);
      } else {
        this.updateCart(cartId, cart.lines[0], selectedProduct, forRelatedProduct);
      }
    }
  }

  createCart(cart, product, forRelatedProduct = false) {
    this.showLoding = true;
    if (product.hasOwnProperty('showSpinner')) {
      product.showSpinner = true;
    }
    this.cartService.createCart(cart).subscribe(
      (res) => {
        this.cart = res.data;
        this.updateCartStore(res.data, product);
        this.onGroupChange(this.selectedProductGroup, forRelatedProduct);
        this.showToaster();
        this.showLoding = false;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      },
      (err) => {
        this.showErrorToaster(err?.error?.message || err?.error?.error?.message || `${this.translocoService.translate('PRODUCT_DETAILS.OPERATION_FAILED')}!`);
        this.showLoding = false;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      }
    );
  }

  updateCart(cartId, cartLines, product, forRelatedProduct = false) {
    this.showLoding = true;
    this.cartError = null;
    if (product.hasOwnProperty('showSpinner')) {
      product.showSpinner = true;
    }
    this.cartService.updateCart(cartId, cartLines).subscribe(
      (res) => {
        this.cart = res.data;
        this.updateCartStore(res.data, product);
        this.onGroupChange(this.selectedProductGroup, forRelatedProduct);
        this.showToaster();
        this.showLoding = false;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      },
      (err) => {
        const productInCart = this.findProductInCart(this.selectedProduct);
        this.quantity = productInCart?.quantity || this.quantity;
        this.showErrorToaster(err?.error?.message);
        this.showLoding = false;
        this.cartError = err?.error?.message;
        if (product.hasOwnProperty('showSpinner')) {
          product.showSpinner = false;
        }
      }
    );
  }

  updateCartItem(product, selectedProduct, defaultQty = 0, forRelatedProduct = false, isAccessoryProduct = false) {
    this.showLoding = true;
    this.cartError = null;
    if (selectedProduct.hasOwnProperty('showSpinner')) {
      selectedProduct.showSpinner = true;
    }

    const payload = {
      quantity: defaultQty ? defaultQty + 1 : this.quantity,
      sub_lines: isAccessoryProduct ? [] : this.formatPreCartSubLine() || []
    };

    this.cartService.updateCartLine(this.cart.uuid, product.uuid, payload).subscribe(res => {
      this.cart = res.data;
      this.tempQty = this.quantity;
      this.updateCartStore(res.data, selectedProduct);
      this.onGroupChange(this.selectedProductGroup, forRelatedProduct);
      this.showToaster();
      this.showLoding = false;
      if (selectedProduct.hasOwnProperty('showSpinner')) {
        selectedProduct.showSpinner = false;
      }
    }, err => {
      this.quantity = this.tempQty;
      this.showErrorToaster(err?.error?.message);
      this.cartError = err?.error?.message;
      this.showLoding = false;
      if (selectedProduct.hasOwnProperty('showSpinner')) {
        selectedProduct.showSpinner = false;
      }
    });
  }

  updateCartStore(data: any, product = null) {
    this.cartService.changeCurrentCart(data);
    this.storageService.setCart(data);
    const selectedProduct = data?.lines?.find(line => line?.product_code === product.code) || null;

    this.cartService.saveOrderUnitOfCartProducts({ product_code: product?.code, order_unit: product?.order_unit ?? 1 }, false);
    let selectedCartProductWithSubLine = [
      {
        ...selectedProduct,
        quantity: data?.lines?.find(obj => obj?.product_code === product?.code)?.quantity || 1,
        code: product?.code,
        image: selectedProduct?.image || '../../../assets/images/image-not-available.png',
        name: product?.name,
        color: product?.dimensional_attributes?.find(item => item.attribute_code === 'color')?.attribute_value || '',
        memory_size: product?.dimensional_attributes?.find(item => item.attribute_code === 'memory_size')?.attribute_value || '',
        price: selectedProduct?.voucher_code ? selectedProduct?.total_amount : selectedProduct?.amount_ex_vat,

      }
    ];
    selectedProduct?.sub_lines?.map(sub_line => {
      if (!this.cartService.hideDeliveryOptionProductTypes.includes(sub_line?.product_type)) {
        selectedCartProductWithSubLine.push({
          ...sub_line,
          quantity: sub_line?.quantity,
          code: sub_line?.product_code,
          image: sub_line?.image || '../../../assets/images/image-not-available.png',
          name: sub_line?.product_name,
          color: sub_line?.dimensional_attributes?.find(item => item.attribute_code === 'color')?.attribute_value || '',
          memory_size: sub_line?.dimensional_attributes?.find(item => item.attribute_code === 'memory_size')?.attribute_value || '',
          price: sub_line?.voucher_code ? sub_line?.total_amount : sub_line?.amount_ex_vat,
        });
      }
    });
    this.storageService.setSelectedCartProduct(selectedCartProductWithSubLine);
    // this.storageService.setSelectedCartProduct([
    //   {
    //     quantity: data?.lines?.find(obj => obj?.product_code === product?.code)?.quantity || 1,
    //     code: product?.code,
    //     image: selectedProduct?.image || '../../../assets/images/image-not-available.png',
    //     name: product?.name,
    //     color: product?.dimensional_attributes?.find(item => item.attribute_code === 'color')?.attribute_value || '',
    //     memory_size: product?.dimensional_attributes?.find(item => item.attribute_code === 'memory_size')?.attribute_value || ''
    //   }
    // ]);
  }

  onGroupChange(group, forRelatedProduct = false) {
    if (this.relatedGroupProducts?.length > 5) {
      this.selectedProductGroup = {
        limit: 2,
        value: group?.value ?? '',
        showExpandButton: true,
      }
    }
    else {
      this.selectedProductGroup = {
        limit: this.relatedGroupProducts?.length,
        value: group?.value ?? '',
        showExpandButton: false
      }
    }
    if (forRelatedProduct) return;
    this.relatedGroupProducts = [];
    this.relatedGroupProducts = this.relatedAccessoryProducts[group?.value]?.map(product => ({
      ...product,
      showSpinner: false,
      addedNumber: this.getProductCountOfCart(product),
    }));
    if (this.relatedGroupProducts?.length > 5) {
      this.selectedProductGroup = {
        limit: 2,
        value: group?.value ?? '',
        showExpandButton: true,
      }
    }
    else {
      this.selectedProductGroup = {
        limit: this.relatedGroupProducts?.length,
        value: group?.value ?? '',
        showExpandButton: false
      }
    }
  }
  changeRelatedProdLimit() {
    this.selectedProductGroup = {
      limit: this.selectedProductGroup?.showExpandButton ? this.relatedGroupProducts?.length : 2,
      value: this.selectedProductGroup?.value,
      showExpandButton: this.selectedProductGroup?.showExpandButton ? false : true
    }
  }

  showToaster() {
    // this.toastrService.success('de winkelwagen is bijgewerkt', 'Succes', {
    //   timeOut: 2000
    // });
    this.toastrService.success(this.translocoService.translate('PRODUCT_DETAILS.THE_SHOPPING_CART_HAS_BEEN_UPDATED'), 'Succes', {
      timeOut: 2000
    });
  }

  getProductCountOfCart(product) {
    if (this.cart) {
      const line = this.cart?.lines?.find(obj => obj.product_code === product.code);
      return line?.quantity;
    }
    return 0;
  }

  formatPreCartSubLine() {
    return this.preCart?.sub_lines?.filter(item => !item?.product_code?.startsWith('tkh_'))?.map(sub_line => {
      return {
        product_code: sub_line?.product_code,
        quantity: this.quantity || 1,
        uuid: sub_line?.uuid,
        product_name: sub_line?.product_name || '',
        flow_type_code: "new",
        sub_lines: [],
        promotion_code: sub_line?.promotion_code || '',

      }
    })
  }

  populatePromotionalProductAsSelected(sub_lines = []) {
    this.promotionalProducts?.forEach(product => {
      if (product?.mandatory_products?.length === 1 && this.getExclOrInclPrice(product?.mandatory_products[0]?.price) === 0) {
        for (let index = 0; index < product?.mandatory_products?.length; index++) {
          const first_product = product?.mandatory_products[index];
          if (first_product) {
            let first_item = {
              product_code: first_product?.code,
              quantity: this.quantity || 1,
              product_name: first_product?.name,
              flow_type_code: "new",
              sub_lines: [],
              promotion_code: product?.code
            };
            if (product.mandatory_products[index]) {
              product.mandatory_products[index].selected = true;
              product.mandatory_products[index].disable = true;
            }
            sub_lines.push(first_item);
          }
        }
      }

      if (product?.optional_products?.length === 1 && this.getExclOrInclPrice(product?.optional_products[0]?.product?.price) === 0) {
        for (let index = 0; index < product?.optional_products?.length; index++) {
          const first_product = product?.optional_products[index];
          if (first_product) {
            let first_item = {
              product_code: first_product?.product?.code,
              quantity: this.quantity || 1,
              product_name: first_product?.product?.name,
              flow_type_code: "new",
              sub_lines: [],
              promotion_code: product?.code
            };
            if (product.optional_products[index]) {
              product.optional_products[index].selected = true;
              product.optional_products[index].disable = true;
            }
            sub_lines.push(first_item);
          }
        }
      }
    });

    return sub_lines
  }


  populatePromotionalProductInPreCart(cartSubLines) {
    this.promotionalProducts?.forEach(group => {
      group.mandatory_products?.forEach(product => {
        if (cartSubLines?.some(item => item?.product_code === product?.code)) {
          product.selected = true;
          if (group.mandatory_products?.length === 1 && this.getExclOrInclPrice(product?.price) === 0) {
            product.disable = true;
          }
        }
      });

      group.optional_products?.forEach(product => {
        if (cartSubLines?.some(item => item?.product_code === product?.product?.code)) {
          product.selected = true;
          if (group.optional_products?.length === 1 && this.getExclOrInclPrice(product?.product?.price) === 0) {
            product.disable = true;
          }
        }
      });
    });
    // convert the upper code into map function
    // this.promotionalProducts = this.promotionalProducts?.map(group => {
    //   return {
    //     ...group,
    //     mandatory_products: group?.mandatory_products?.map(product => {
    //       return {
    //         ...product,
    //         selected: cartSubLines?.some(item => item?.product_code === product?.code),
    //         disable: group.mandatory_products?.length === 1 && product?.price?.price_ex_vat === 0
    //       }
    //     }),
    //     optional_products: group?.optional_products?.map(product => {
    //       return {
    //         ...product,
    //         selected: cartSubLines?.some(item => item?.product_code === product?.product?.code),
    //         disable: group.optional_products?.length === 1 && product?.product?.price?.price_ex_vat === 0
    //       }
    //     })
    //   }
    // })
  }

  getRelatedAccessoryProducts(code: string) {
    this.showLoding = true;
    this.productDetailService.getRelatedAccessoryProducts(code).subscribe((response) => {
      if (Object.keys(response.data).length) {
        this.relatedAccessoryProducts = response.data;
        this.productGroup = Object.keys(response.data).map((key) => ({ name: key, value: key }));
        // this.selectedProductGroup = this.productGroup[0]?.value ?? null;
        this.onGroupChange({ value: this.productGroup[0]?.value ?? '' });
      }
    }, (error) => {
      console.log('error: ', error);
      this.showLoding = false;

    }
    );
  }

  getRelatedProducts(code: string) {
    this.showLoding = true;
    this.productDetailService.getRelatedProducts(code).subscribe((response) => {
      if (Object.keys(response.data).length) {
        this.relatedProducts = response?.data?.results?.map(group => {
          return {
            ...group,
            products: group?.products?.map(product => {
              return {
                ...product,
                selected: false
              }
            })
          }
        });

        this.populateCartWithSubLine();
      }
    }, (error) => {
      console.log('error: ', error);
      this.showLoding = false;

    }
    );
  }

  getCart() {
    this.cartService.currentCart$.subscribe((res) => {
      this.cart = res;
    }, error => {
      console.log('error: ', error);
    });
  }

  increaseQuantity() {
    this.tempQty = this.quantity;
    this.quantity = this.quantity + this.selectedProduct?.order_unit || 1;
    const productInCart = this.findProductInCart(this.selectedProduct);
    if (productInCart) {
      this.onEnter();
    }
    else {
      this.showLoding = true;
      let sub_lines = this.populateSubLine();
      this.tempSublines = sub_lines;
      if (Object.keys(this.selectedProduct).length) {
        setTimeout(() => {
          this.preCartEnrich(this.quantity, false, sub_lines);
        }, 300);
      } else {
        this.showLoding = false;
      }
    }
    // this.preCartEnrich(this.quantity + (this.selectedProduct?.order_unit || 1), true);
  }

  decreaseQuantity() {
    if (this.quantity > 1) {
      this.tempQty = this.quantity;
      this.quantity = this.quantity - this.selectedProduct?.order_unit || 1;
      const productInCart = this.findProductInCart(this.selectedProduct);
      if (productInCart) {
        this.onEnter();
      }
      else {
        this.showLoding = true;
        let sub_lines = this.populateSubLine();
        this.tempSublines = sub_lines;
        if (Object.keys(this.selectedProduct).length) {
          setTimeout(() => {
            this.preCartEnrich(this.quantity, false, sub_lines);
          }, 300);
        } else {
          this.showLoding = false;
        }
      }
      // this.preCartEnrich(this.quantity - (this.selectedProduct?.order_unit || 1), true);
    }
  }

  onEnter() {
    if (this.quantity > 0) {
      let sub_lines = this.formatPreCartSubLine();
      this.preCartEnrich(this.quantity, true, sub_lines);
    }
  }

  onChangeRelatedProduct(event, product, group) {
    const selectedProduct = group?.products?.filter(pro => pro.selected);
    this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));
    if (event) {
      // Urgent Fix for production but logic can be optimized
      if (group?.max_required === 1 && group?.min_required > 0) {
        const relatedIdx = this.relatedProducts.findIndex(product => product.product_group === group?.product_group);
        const products = this.relatedProducts[relatedIdx].products.map(item => ({ ...item, selected: item.code === product.code }));
        this.relatedProducts[relatedIdx] = {
          ...this.relatedProducts[relatedIdx],
          products: products
        }
        const newProductCodeList = products.filter(item => item?.code != product?.code);
        if (newProductCodeList?.length) {
          newProductCodeList.forEach(product => {
            this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
          });
        }
      }
      this.tempSublines.push({
        product_code: product.code,
        quantity: this.quantity || 1,
        product_name: product.name,
        flow_type_code: 'new',
        promotion_code: '',
        sub_lines: []
      });
    } else {
      if (selectedProduct.length <= (group?.min_required - 1)) {
        this.toastrService.info(`${this.translocoService.translate('PRODUCT_DETAILS.AT_LEAST')} ${group?.min_required} ${this.translocoService.translate('PRODUCT_DETAILS.SHOULD_BE_SELECTED')} `, 'Info', {
          timeOut: 3000
        });

        timer(500).subscribe(time => {
          product.selected = true;
        })

        return;
      }
      this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
    }
    product.selected = event;
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }

  parsePrice(value = '0.0') {
    return parseFloat(value);
  }


  checkCartSubLineProduct(group) {
    let groupProduct = [...group?.mandatory_products, ...group?.optional_products];
    return this.preCart?.sub_lines?.filter(s_line => {
      return groupProduct?.some(item => item?.code === s_line?.product_code || item?.product?.code === s_line?.product_code)
    })?.length
  }

  onChangePromotionalMandatoryProduct(event, product, group) {

    this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));

    if (event) {
      let total_added = this.checkCartSubLineProduct(group);
      if (total_added < group?.max_promotional_products) {
        this.tempSublines.push({
          product_code: product.code,
          quantity: this.quantity || 1,
          product_name: product.name,
          promotion_code: group?.code,
          flow_type_code: 'new',
          sub_lines: []
        });
      } else {

        this.toastrService.info(`${this.translocoService.translate('PRODUCT_DETAILS.MAX')} ${group?.max_promotional_products} ${this.translocoService.translate('PRODUCT_DETAILS.SHOULD_BE_SELECTED')} `, 'Info', {
          timeOut: 3000
        });

        // const relatedIdx = this.promotionalProducts.findIndex(product => product.code === group?.code);
        // const products = this.promotionalProducts[relatedIdx].mandatory_products.map(item => ({ ...item, selected: item.code === product.code ? false : item?.selected }));
        // this.promotionalProducts[relatedIdx] = {
        //   ...this.promotionalProducts[relatedIdx],
        //   mandatory_products: products
        // }
        this.promotionalProducts = this.promotionalProducts.map(item => {
          if (item.code === group?.code) {
            return {
              ...item,
              mandatory_products: item.mandatory_products.map(mp => {
                if (mp.code === product.code) {
                  return {
                    ...mp,
                    selected: false
                  }
                } else {
                  return {
                    ...mp,
                  }
                }
              })
            }
          }
          return item;
        });
        return;

      }
    } else {
      this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
    }
    // product.selected = event;
    this.promotionalProducts = this.promotionalProducts.map(item => {
      if (item.code === group?.code) {
        return {
          ...item,
          mandatory_products: item.mandatory_products.map(mp => {
            if (mp.code === product.code) {
              return {
                ...mp,
                selected: event
              }
            } else {
              return {
                ...mp,
              }
            }
          })
        }
      }
      return item;
    });
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }
  resetPromotionSelection() {
    this.promotionalProducts.forEach(group => {
      group.mandatory_products.forEach(product => {
        product.selected = false;
      });
      group.optional_products.forEach(product => {
        product.selected = false;
      });
    });
  }
  onChangeMaxOnePromotionalOptionalProduct(event, product, group) {

    this.promotionalProducts = this.promotionalProducts.map(item => {
      if (item.code === group?.code) {
        item.optional_products = item.optional_products.map(op => {
          if (op.product.code === product.code) {
            return {
              ...op,
              selected: event
            }
          } else {
            return {
              ...op,
              selected: false
            }
          }
        })
      }
      return item;
    });
    let subLines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));
    subLines = subLines.filter(item => item?.promotion_code !== group?.code);
    this.tempSublines = subLines;

    // this.tempSublines = this.tempSublines.filter(item => (item.promotion_code != group?.code) || (!item?.product_code.startsWith('tkh_')));

    this.tempSublines.push({
      product_code: product.code,
      quantity: this.quantity || 1,
      product_name: product.name,
      flow_type_code: 'new',
      promotion_code: group?.code,
      sub_lines: []
    });

    // product.selected = event;
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);

    // this.onChangePromotionalOptionalProduct(event, product, group);
  }
  onChangeMaxOnePromotionalMandatoryProduct(event, product, group) {
    this.promotionalProducts = this.promotionalProducts.map(item => {
      if (item.code === group?.code) {
        item.mandatory_products = item.mandatory_products.map(mp => {
          if (mp.code === product.code) {
            return {
              ...mp,
              selected: event
            }
          } else {
            return {
              ...mp,
              selected: false
            }
          }
        })
      }
      return item;
    });
    // this.resetPromotionSelection();

    let subLines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));
    subLines = subLines.filter(item => item?.promotion_code !== group?.code);

    this.tempSublines = subLines;

    // this.onChangePromotionalMandatoryProduct(event, product, group);
    // return false;
    // this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));

    this.tempSublines.push({
      product_code: product.code,
      quantity: this.quantity || 1,
      product_name: product.name,
      promotion_code: group?.code,
      flow_type_code: 'new',
      sub_lines: []
    });

    // product.selected = event;
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }

  onChangePromotionalOptionalProduct(event, product, group) {
    this.tempSublines = this.tempSublines.filter(item => !item?.product_code.startsWith('tkh_'));
    if (event) {
      let total_added = this.checkCartSubLineProduct(group);
      if (total_added < group?.max_promotional_products) {
        this.tempSublines.push({
          product_code: product.code,
          quantity: this.quantity || 1,
          product_name: product.name,
          flow_type_code: 'new',
          promotion_code: group?.code,
          sub_lines: []
        });
      } else {
        this.toastrService.info(`${this.translocoService.translate('PRODUCT_DETAILS.MAX')} ${group?.max_promotional_products} ${this.translocoService.translate('PRODUCT_DETAILS.SHOULD_BE_SELECTED')} `, 'Info', {
          timeOut: 3000
        });

        // const relatedIdx = this.promotionalProducts.findIndex(product => product.code === group?.code);
        // const products = this.promotionalProducts[relatedIdx].optional_products.map(item => ({ ...item, selected: item?.product?.code === product.code ? false : item?.selected }));
        // this.promotionalProducts[relatedIdx] = {
        //   ...this.promotionalProducts[relatedIdx],
        //   optional_products: products
        // }

        this.promotionalProducts = this.promotionalProducts.map(item => {
          if (item.code === group?.code) {
            return {
              ...item,
              optional_products: item.optional_products.map(op => {
                if (op.product.code === product.code) {
                  return {
                    ...op,
                    selected: false
                  };
                } else {
                  return {
                    ...op
                  };
                }
              })
            };
          }
          return item;
        });


        return;
      }
    } else {
      this.tempSublines = this.tempSublines.filter(item => item?.product_code != product?.code);
    }
    // product.selected = event;
    // this.promotionalProducts = this.promotionalProducts.map(item => {
    //   if (item.code === group?.code) {
    //     item.optional_products = item.optional_products.map(op => {
    //       if (op.product.code === product.code) {
    //         return {
    //           ...op,
    //           selected: event
    //         }
    //       } else {
    //         return {
    //           ...op,
    //         }
    //       }
    //     })
    //   }
    //   return item;
    // });

    this.promotionalProducts = this.promotionalProducts.map(item => {
      if (item.code === group?.code) {
        return {
          ...item,
          optional_products: item.optional_products.map(op => {
            if (op.product.code === product.code) {
              return {
                ...op,
                selected: event
              };
            } else {
              return {
                ...op
              };
            }
          })
        };
      }
      return item;
    });
    this.preCartEnrich(this.quantity, false, this.tempSublines, true);
  }

  getDecimalLength(price = '0') {
    let floatPrice = `${parseFloat(price)}`;
    return floatPrice?.split('.')?.[1]?.length;
  }

  // getCataLogList() {
  //   this.catalogSubs$ = this.store.select(selectCatalog).subscribe(catalogs => {
  //     this.catalogs = catalogs;
  //     this.findCatalog();
  //   });
  // }


  getCataLogList() {
    this.catalogSubs$ = this.store.select(selectCatalog).subscribe(catalogs => {
      this.catalog = catalogs?.find(catalog => catalog?.code === this.catalogCode) || null;
      if (this.catalog) {
        this.group = this.catalog?.product_groups?.find(item => item?.code === this.groupCode) || null;
        if (this.group) {
          this.findSubgroup();
        }
        if (!this.group) {
          // tslint:disable-next-line:prefer-for-of
          for (let index = 0; index < this.catalog?.product_groups.length; index++) {
            const element = this.catalog?.product_groups?.[index];
            const grp = this.findGroup(this.groupCode, element?.child_groups);
            if (grp) {
              this.group = grp;
              this.findSubgroup();
              break;
            }
          }
        }
      }
    });
  }

  findGroup(code, arr: any[]) {
    return arr.reduce((acc, item) => {
      if (acc) {
        return acc;
      }
      if (item.code === code) {
        return item;
      }
      if (item.child_groups?.length) {
        return this.findGroup(this.groupCode, item.child_groups);
      }
    }, null);
  }


  findSubgroup() {
    let parent = this.getNMinusOneParent(this.group?.code, this.catalog?.product_groups, [], 2);
    if (parent) {
      this.subgroups = parent;
    } else {
      this.subgroups = this.getNMinusOneParent(this.group?.code, this.catalog?.product_groups, [], 0) || this.catalog.product_groups?.find(group => group.code === this.group?.code);
    }


  }



  getNMinusOneParent(childCode, array, parentObjects = [], label, lastParentObject = null, secondToLastParentObject = null) {
    for (const item of array) {
      if (item.child_groups && item.child_groups.length > 0) {
        if (item.child_groups.some(child => child.code === childCode)) {
          if (label > 0) {
            return parentObjects[parentObjects.length - label]; // Found the (n-1)-th parent object
          }
          return secondToLastParentObject || item;
        } else {
          const newParentObjects = [...parentObjects, item];
          const result = this.getNMinusOneParent(childCode, item.child_groups, newParentObjects, label, item, lastParentObject);
          if (result) {
            return result; // Continue searching in the children
          }
        }
      }
    }
    return null // Child not found in the array
  }


  findCatalog() {
    this.catalog = this.catalogs?.find(catalog => catalog?.code === this.catalogCode) || null;
    if (this.catalog) {
      this.group = this.catalog?.product_groups?.find(item => item?.code === this.groupCode) || null;
      if (!this.group) {
        // tslint:disable-next-line:prefer-for-of
        for (let index = 0; index < this.catalog?.product_groups.length; index++) {
          const element = this.catalog?.product_groups?.[index];
          const grp = this.findGroup(this.groupCode, element?.child_groups);
          if (grp) {
            this.group = grp;
            break;
          }
        }
      }
    }
  }



  gotoGroupCode() {
    this.router.navigate(['product-list'], {
      queryParams: {
        catalogCode: this.catalogCode,
        groupCode: this.group?.code ?? ''
      },
      queryParamsHandling: 'merge'
    });
  }

  checkEENGroup() {
    return this.selectedProduct?.fe_specials?.length ? true : false;
  }

  back() {
    if (!this.catalogCode) {
      this.gotoGroupCode();
      return;
    }
    this.location.back();
  }

  truncateHTML(text) {
    const charlimit = 110;
    if (!text || text.length <= charlimit) {
      return text;
    }

    const without_html = text.replace(/<(?:.|\n)*?>/gm, '');
    const shortened = without_html.substring(0, charlimit) + "...";
    return shortened;
  }

  goToDetails(product) {
    const url = this.router.createUrlTree(['/product-details', product.code], {
      queryParamsHandling: 'merge'
    }).toString();
    window.open(url, '_blank');
  }


  getExclOrInclPrice(price: object) {
    return this.utilityService?.isInclVAT() ? price['price'] : price['price_ex_vat']
  }



  ngOnDestroy() {
    if (this.sliderSibling) {
      this.sliderSibling.destroy();
    }
    if (this.slider) {
      this.slider.destroy();
    }
    if (this.productSubs$) {
      this.productSubs$.unsubscribe();
    }
  }
}
