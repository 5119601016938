<div class="container-fluid">
  <div class="row">
    <div class="col-md-auto p-0 sidebar-col" [ngClass]="{'open-sidebar' : isShowsideBar}">
      <app-sidebar></app-sidebar>
    </div>
    <div class="col p-0">
      <app-header></app-header>
      <app-notification></app-notification>
      <router-outlet></router-outlet> 
    </div>
  </div>
</div>
<app-footer></app-footer> 
