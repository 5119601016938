import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { IAppState } from 'src/app/app.state';
import { selectPermissions } from 'src/app/store/selectors/permission.selectors';

export class BasePermission {
    public permissionSubs$: Subscription;
    public hasOrderStatusPermission = false;
    public hasQuotePermission = false;
    public hasQuoteCreationPermission = false;
    public hasCatalogPermission = false;
    public hasVoucherPermission = false;
    public hasVoucherCRUDPermission = false;
    public hasVoucherChangePermission = false;
    public hasDepotViewPermission = false;
    public hasSupplierCustomerPermission = false;
    public hasCartImportPermission = false;
    public forecastPermission = {
        add: false,
        view: false,
        edit: false,
        delete: false
    };

    public chooseCustomerPermission = {
        add: false,
        view: false,
        edit: false,
        delete: false
    };

    public priceListPermission = false;
    public hasContactAddressCreatePermission = false;
    public hasCreditShowPermission = false;
    public projectPricePermission = false;
    public contactPersonAddPermission = false;
    public contactPersonUpdatePermission = false;
    public contactPersonDeletePermission = false;
    public hasContactAddressUpdatePermission = false;
    public hasTnCViewPermission = false;
    public notAllowedEmailChangePermission = false;
    public voucherRequiredPermission = false;
    public hasQuoteLineDeletePermission = false;
    public trackAndTracePermission = false;

    public hasRmaPermission = {
        add: false,
        view: false,
        edit: false,
        delete: false
    };


    public wishlistPermission = {
        add: false,
        view: false,
        delete: false
    };

    public SMSCampaignPermission = {
        add: false,
        view: false,
        view_sms: false
    };

    public hasProductExportPermission = false;
    public hasReportPermission = false;
    public hasAddressLookupPermission = false;
    public hasQuotationExtraPermission = false;
    public hasTradeInPermission = false;
    public hasTradeInBusinessPartner = false;

    public freeFieldsPermission = {
        add_contact_free_field: false,
        view_contact_free_field: false,
        view_free_field: false,
    };


    public accountTypePermission = {
        add: false,
        view: false,
        edit: false,
        delete: false
    };

    public hasViewHandsetProvision = false;
    public hasAddEmailConFigPermission = false;
    public hasViewEmailConFigPermission = false;

    public hasViewQuotationTemplate = false;
    public hasViewContactDepartment = false;

    public hasViewContactAccountTypeCustomer = false;
    public hasPermissionSetRequestedDeliveryDate = false;
    public hasQuoteZipDownloadPermission = false;

    public hasSystemMessagesViewPermission = false;
    public hasInputCustomAddressPermission = false;

    constructor(
        public store: Store<IAppState>
    ) {
        this.getPermission();
    }

    getPermission() {
        this.store.select(selectPermissions).subscribe(permissions => {
            this.hasOrderStatusPermission = permissions?.find(permission => {
                return permission.code === 'orders.view_order';
            }) ? true : false;
            this.hasQuotePermission = permissions?.find(permission => {
                return permission.code === 'quotes.view_quotation';
            }) ? true : false;
            this.hasQuoteCreationPermission = permissions?.find(permission => {
                return permission.code === 'quotes.add_quotation';
            }) ? true : false;
            this.hasQuoteLineDeletePermission = permissions?.some(permission => {
                return permission.code === 'quotes.delete_quotationline';
            });
            this.hasViewQuotationTemplate = permissions?.some(permission => {
                return permission.code === 'quotes.view_quotationtemplate';
            });
            this.hasCatalogPermission = permissions?.find(permission => {
                return permission.code === 'catalog.view_catalog';
            }) ? true : false;
            this.hasVoucherPermission = permissions?.find(permission => {
                return permission.code === 'vouchers.view_voucher';
            }) ? true : false;
            this.hasVoucherCRUDPermission = permissions?.find(permission => {
                return permission.code === 'vouchers.add_voucher' ||
                    permission.code === 'vouchers.change_voucher' ||
                    permission.code === 'vouchers.delete_voucher';
            }) ? true : false;
            this.hasVoucherChangePermission = permissions?.some(permission => {
                return permission.code === 'vouchers.change_voucher';
            });
            this.hasDepotViewPermission = permissions?.some(permission => {
                return permission.code === 'depot.view_depotlog';
            });

            const rmaPermission = permissions?.filter(permission => {
                return permission.code.startsWith('return_management');
            });

            this.priceListPermission = permissions?.some(permission => {
                return permission.code === 'catalog.add_catalog';
            });

            this.hasContactAddressCreatePermission = permissions?.some(permission => {
                return permission.code === 'contacts.add_contactaddress';
            });

            this.hasContactAddressUpdatePermission = permissions?.some(permission => {
                return permission.code === 'contacts.change_contactaddress';
            });

            this.contactPersonAddPermission = permissions?.some(permission => {
                return permission.code === 'contacts.add_contactperson';
            });

            this.contactPersonUpdatePermission = permissions?.some(permission => {
                return permission.code === 'contacts.change_contactperson';
            });

            this.contactPersonDeletePermission = permissions?.some(permission => {
                return permission.code === 'contacts.delete_contactperson';
            });

            this.hasCreditShowPermission = permissions?.some(permission => {
                return permission.code === 'contacts.show_credit_limit';
            });

            this.projectPricePermission = permissions?.some(permission => {
                return permission.code === 'project_prices.view_projectpricecontact';
            });

            this.notAllowedEmailChangePermission = permissions?.some(permission => {
                return permission.code === 'contacts.email_address_change_not_allowed';
            });

            this.hasViewContactDepartment = permissions?.some(permission => {
                return permission.code === 'contacts.view_department';
            });

            this.hasViewContactAccountTypeCustomer = permissions?.some(permission => {
                return permission.code === 'contacts.view_contactaccounttypecustomer';
            });

            this.trackAndTracePermission = permissions?.some(permission => {
                return permission.code === 'orders.track_and_trace_email_enabled' ? true : false;
            });

            this.voucherRequiredPermission = permissions?.some(permission => {
                return permission.code === 'vouchers.voucher_required';
            });

            const forecastPermission = permissions?.filter(permission => {
                return permission.code.startsWith('sales_forecast') && permission.code.endsWith('forecast');
            });

            const contactPermission = permissions?.filter(permission => {
                return permission.code.startsWith('contacts') && permission.code.endsWith('suppliercustomer');
            });

            const wishlistPermission = permissions?.filter(permission => {
                return permission.code.startsWith('wishlist');
            });

            const smsCampaignToolPermission = permissions?.filter(permission => {
                return permission.code.startsWith('sms_campaigntool');
            });

            const freeFieldPermission = permissions?.filter(permission => {
                return permission.code.endsWith('_userdefinedfield') || permission.code.endsWith('_contactuserfield');
            });

            const accountTypePermission = permissions?.filter(permission => {
                return permission.code.endsWith('_contactaccounttype') || permission.code.endsWith('_contactuserfield');
            });

            if (wishlistPermission?.length) {
                wishlistPermission.forEach(element => {
                    switch (element.code) {
                        case 'wishlist.view_wishlist':
                            this.wishlistPermission.view = true;
                            break;
                        case 'wishlist.add_wishlistitem':
                            this.wishlistPermission.add = true;
                            break;
                        case 'wishlist.delete_wishlistitem':
                            this.wishlistPermission.delete = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.wishlistPermission);
            }

            if (forecastPermission?.length) {
                forecastPermission.forEach(element => {
                    switch (element.code) {
                        case 'sales_forecast.view_forecast':
                            this.forecastPermission.view = true;
                            break;
                        case 'sales_forecast.add_forecast':
                            this.forecastPermission.add = true;
                            break;
                        case 'sales_forecast.change_forecast':
                            this.forecastPermission.edit = true;
                            break;
                        case 'sales_forecast.delete_forecast':
                            this.forecastPermission.delete = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.forecastPermission);
            }

            if (rmaPermission?.length) {
                rmaPermission.forEach(element => {
                    switch (element.code) {
                        case 'return_management.view_rma':
                            this.hasRmaPermission.view = true;
                            break;
                        case 'return_management.add_rma':
                            this.hasRmaPermission.add = true;
                            break;
                        case 'return_management.change_rma':
                            this.hasRmaPermission.edit = true;
                            break;
                        case 'return_management.delete_rma':
                            this.hasRmaPermission.delete = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.hasRmaPermission);
            }

            if (smsCampaignToolPermission?.length) {
                smsCampaignToolPermission.forEach(element => {
                    switch (element.code) {
                        case 'sms_campaigntool.view_smscampaign':
                            this.SMSCampaignPermission.view = true;
                            break;
                        case 'sms_campaigntool.add_smscampaign':
                            this.SMSCampaignPermission.add = true;
                            break;
                        case 'sms_campaigntool.view_smscampaignsms':
                            this.SMSCampaignPermission.view_sms = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.SMSCampaignPermission);
            }

            if (contactPermission?.length) {
                contactPermission.forEach(element => {
                    switch (element.code) {
                        case 'contacts.view_suppliercustomer':
                            this.chooseCustomerPermission.view = true;
                            break;
                        case 'contacts.add_suppliercustomer':
                            this.chooseCustomerPermission.add = true;
                            break;
                        case 'contacts.change_suppliercustomer':
                            this.chooseCustomerPermission.edit = true;
                            break;
                        case 'contacts.delete_suppliercustomer':
                            this.chooseCustomerPermission.delete = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.chooseCustomerPermission);
            }

            if (freeFieldPermission?.length) {
                freeFieldPermission.forEach(element => {
                    switch (element.code) {
                        case 'contacts.view_userdefinedfield':
                            this.freeFieldsPermission.view_free_field = true;
                            break;
                        case 'contacts.view_contactuserfield':
                            this.freeFieldsPermission.view_contact_free_field = true;
                            break;
                        case 'contacts.add_contactuserfield':
                            this.freeFieldsPermission.add_contact_free_field = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.freeFieldsPermission);
            }

            if (accountTypePermission?.length) {
                accountTypePermission.forEach(element => {
                    switch (element.code) {
                        case 'contacts.view_contactaccounttype':
                            this.accountTypePermission.view = true;
                            break;
                        case 'contacts.change_contactaccounttype':
                            this.accountTypePermission.edit = true;
                            break;
                        case 'contacts.add_contactaccounttype':
                            this.accountTypePermission.add = true;
                            break;
                        case 'contacts.delete_contactaccounttype':
                            this.accountTypePermission.delete = true;
                            break;
                        default:
                            break;
                    }
                });
            } else {
                this.resetPermissionObject(this.accountTypePermission);
            }

            this.hasSupplierCustomerPermission = permissions?.some(permission => {
                return permission.code === 'contacts.view_suppliercustomer';
            });
            this.hasProductExportPermission = permissions?.some(permission => permission.code === 'catalog.can_export_catalog_products');
            this.hasCartImportPermission = permissions?.some(permission => permission.code === 'contacts.can_import_order_records');
            this.hasTnCViewPermission = permissions?.some(permission => permission.code === 'terms_and_conditions.view_termsandconditions');
            this.hasReportPermission = permissions?.some(permission => permission.code === 'reports.view_report');
            this.hasAddressLookupPermission = permissions?.some(permission => permission.code === 'address_lookup.view_addresslookup');
            this.hasQuotationExtraPermission = permissions?.some(permission => permission.code === 'quotes.add_quotationlineextra');
            this.hasTradeInPermission = permissions?.some(permission => permission.code === 'trade_in.can_do_trade_in');
            this.hasTradeInBusinessPartner = permissions?.some(permission => permission.code === 'trade_in.can_select_end_customer');
            this.hasViewHandsetProvision = permissions?.some(permission => permission.code === 'handset_provision.view_handsetprovision');
            this.hasAddEmailConFigPermission = permissions?.some(permission => permission.code === 'contacts.add_cc_to_email');
            this.hasViewEmailConFigPermission = permissions?.some(permission => permission.code === 'emails.view_emailsiteprofile');
            this.hasPermissionSetRequestedDeliveryDate = permissions?.some(permission => permission.code === 'contacts.allow_request_delivery_date');
            this.hasQuoteZipDownloadPermission = permissions?.some(permission => permission.code === 'quotes.can_download_files_zipped');
            this.hasSystemMessagesViewPermission = permissions?.some(permission => permission.code === 'system_messages.view_usermessage');
            this.hasInputCustomAddressPermission = permissions?.some(permission => permission.code === 'contacts.can_input_custom_address');


        });
    }

    resetPermissionObject(resetObject: object) {
        for (const key in resetObject) {
            resetObject[key] = false;
        }
    }
}
