<div>
  <app-loader [isLoading]="isLoading"></app-loader>

  <div class="c-px-24">
    <div class="bg-gray-1 tri-rounded-20 p-24 h-100 ">
      <div class="">
        <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0">Voor- en achternaam
        </label>
        <div [ngClass]="{ 'min-height-100' : applyMinHight}">
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="">
                <p class="font-kpn-extended mb-2">
                  <span class="d-block">{{ cPassData?.cpaas_user_first_name }} {{ cPassData?.cpaas_user_last_name
                    }}</span>
                </p>
              </div>
            </div>
          </div>
          <label for="" class="font-kpn-extended kpn-extended-bold letter-spacing-normal mb-0 pt-1">E-mailadres
          </label>
          <div class="row justify-content-between align-items-center">
            <div class="col-md">
              <div class="">
                <p class="font-kpn-extended pb-0 mb-0">
                  <span class="d-block"> {{ cPassData?.cpaas_user_email }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p class="mb-0" *ngIf="shoEditBtn && (!showEditButtonInBottom)">
        <a href="javascript:void(0)" (click)="openCpaasDataUpdateModal(cpaasDataUpdateModal)"
          class="fs-16 font-kpn-extended link-btn-blue">
          Wijzigen <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
      </p>
    </div>
  </div>
  <hr class="mt-4 mb-3" *ngIf="showEditButtonInBottom && shoEditBtn">

  <p class="mb-0 c-px-24" *ngIf="showEditButtonInBottom && shoEditBtn">
    <a href="javascript:void(0)" (click)="openCpaasDataUpdateModal(cpaasDataUpdateModal)"
      class="fs-16 font-kpn-extended link-btn-blue ">
      Wijzigen <i class="kphicon-uniE931 fs-18 align-middle"></i></a>
  </p>



  <ng-template #cpaasDataUpdateModal let-modal>
    <div class="px-4">
      <div class="px-1 modal-header tri-modal-header">
        <h6 class="modal-title green-text font-kpn-black fs-40" id="cpaasMainUserModal">
          Hoofdgebruiker wijzigen
        </h6>
        <button type="button" class="btn close" (click)="modal.dismiss('Cross click')" aria-label="Close">
          <span class="kpnew-icon-close-new"></span>
        </button>
      </div>
    </div>

    <div class="modal-body p-0">
      <div>
        <div class="pl-4 pt-4 pr-4">
          <div class="custom-inner-modal-scroll overflow-x-hidden">
            <div class="row gutter-row-15 mb-2">
              <div class="col-md-6 gutter-col-15 mb-3">
                <label for="" class="font-kpn-extended"><span
                    class="font-kpn-extended kpn-extended-bold">Voornaam</span><span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control is-invalid "
                  [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_first_name }"
                  [(ngModel)]="cpassUpdateModalData.cpaas_user_first_name" (keyup)="onCpassFirstNameChange($event)"
                  placeholder="Voornaam">
                <span *ngIf="feRequiredFieldErrors.cpaas_user_first_name"
                  class="invalid-feedback fs-16 font-kpn-extended">
                  {{ feRequiredFieldErrors.cpaas_user_first_name }}
                </span>
              </div>
              <div class="col-md-6 gutter-col-15 mb-3">
                <label for="" class="font-kpn-extended"><span
                    class="font-kpn-extended kpn-extended-bold">Achternaam</span><span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control is-invalid invalid-bg-size-23"
                  [(ngModel)]="cpassUpdateModalData.cpaas_user_last_name" (keyup)="onCpassLastNameChange($event)"
                  [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_last_name}"
                  placeholder="Achternaam">
                <span *ngIf="feRequiredFieldErrors.cpaas_user_last_name"
                  class="invalid-feedback fs-16 font-kpn-extended">
                  {{ feRequiredFieldErrors.cpaas_user_last_name }}
                </span>

              </div>
              <div class="col-md-12 gutter-col-15">
                <label for="" class="font-kpn-extended"><span
                    class="font-kpn-extended kpn-extended-bold">E-mailadres</span><span
                    class="text-danger">*</span></label>
                <input type="text" class="form-control tri-form-control is-invalid invalid-bg-size-23"
                  [(ngModel)]="cpassUpdateModalData.cpaas_user_email" (keyup)="onCpassEmailChange($event)"
                  [ngClass]="{'is-invalid invalid-bg-size-23': feRequiredFieldErrors.cpaas_user_email}"
                  placeholder="Tim.hermans@kpn.com">
                <span *ngIf="feRequiredFieldErrors.cpaas_user_email" class="invalid-feedback fs-16 font-kpn-extended">
                  {{ feRequiredFieldErrors.cpaas_user_email }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="p-4">
          <div class="row gutter-row-8">
            <div class="col-md-auto gutter-col-8">
              <button [disabled]="isCpassUpdateDisabled" (click)="saveCpassData(cpassUpdateModalData)"
                class="btn px-4 btn-primary rounded-btn">
                <span class="d-inline-block px-2">Wijzigen</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
