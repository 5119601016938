import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatPrice'
})
export class FormatPricePipe implements PipeTransform {

  transform(
    value: number,
    decimalLength: number = 2,
    decimalDelimiter: string = ',',
    args?: any): string {

    if (!value || value === undefined) {
      value = 0;
    } else if ((typeof (value) === 'string')) {
      value = +value;
    } else {
      value = value;
    }

    const num = value.toFixed(Math.max(0, ~~decimalLength));
    return (decimalDelimiter ? num.replace('.', decimalDelimiter) : num);
  }

}
