import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';

export const selectSiteProfile = (state: IAppState) => state.siteProfile;

export const selectedSiteProfile = createSelector(
    selectSiteProfile,
    (siteProfile: any, props: any) => {
        return siteProfile;
    }
);
