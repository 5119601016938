import { UtilityService } from 'src/app/shared/services/utility.service';
import { Component, OnInit, ViewEncapsulation, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl, Validators } from '@angular/forms';
import { HttpParams } from '@angular/common/http';

import { NgbModal, NgbModalRef, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

import { Observable, Subject, Subscription } from 'rxjs';
import { tap, map, debounceTime, distinctUntilChanged, switchMap } from 'rxjs/operators';

import { QuotesService } from './quotes.service';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { ToastrService } from 'ngx-toastr';
import { systemTypeList as systemTypes } from '../../shared/utilities/static_variables';
import { EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import * as moment from 'moment';

@Component({
  selector: 'app-quotes',
  templateUrl: './quotes.component.html',
  styleUrls: ['./quotes.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class QuotesComponent implements OnInit, OnDestroy {
  public show_all = false;
  quote$: Observable<any>;
  quote = [];
  quotes: any[] = [];
  isLoading = true;
  quoteSearchForm: UntypedFormGroup;
  config = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
    from: 1,
    to: 10
  };
  paginationSub$: Subscription;
  searchKeywordSub$: Subscription;
  quoteImportForm: UntypedFormGroup;
  systemTypeList: any[] = [...systemTypes];
  isImporting: boolean = false;
  isExternal: boolean = false;
  isRunningQuoteTabActive: boolean = true;
  private debounceTimer: any;
  public searchTerm: string = '';
  public user = null;
  filterData = {
    status: [],
    dateFrom: null,
    dateTo: null,
    createdBy: []
  };
  public currentDate = moment().format('YYYY-MM-DD');
  quoteStatusList = ['pending', 'new', 'approved'];
  public createdBy = [
    {
      key: 'internal',
      value: 'Team KPN'
    }, {
      key: 'customer',
      value: 'Intern'
    }]
  private quoteListFilterModalRef: NgbModalRef;
  public sortColumn = ''; // Keeps track of the current sorted column
  public sortDirection = ''; // Keeps track of the sort direction  'asc' | 'desc' | ''
  public sortedQuotes = [];
  @ViewChild('showQuotationDetails', { static: false }) showQuotationDetails: ElementRef;

  constructor(
    private fb: UntypedFormBuilder,
    private quotesService: QuotesService,
    private modalService: NgbModal,
    config: NgbModalConfig,
    private router: Router,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private utilityService: UtilityService,
  ) {
    config.backdrop = 'static';
    config.keyboard = false;

    this.initializeForm();
    this.initializeQuoteImportForm();
    this.user = this.storageService.getCurrentUser();

  }
  get eQuoteStatus() { return EQuoteStatus; }

  ngOnInit() {
    this.getPaginationInfo();
    this.getSearchKeywordInfo();
    if (this.user?.contact_group?.is_external) {
      this.getQuotesForCustomer();
    }
    else {
      this.getQuotes();
    }
    this.getUserInfo();
  }

  onClickQuoteFilter(quoteListFilterModal) {
    this.quoteListFilterModalRef = this.modalService.open(quoteListFilterModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-464 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });

    // this.result.then((result) => {
    //   this.selectedQuoteLine = null;
    // }).catch(e => {

    // });
  }

  getUserInfo() {
    this.isExternal = this.storageService.getCurrentUser()?.is_external;
  }

  getPaginationInfo() {
    this.paginationSub$ = this.storageService.getPagination().subscribe(
      (res) => {
        if (res && res?.page_name === 'quotes') {
          this.searchTerm = res?.searchTerm || '';
          this.config.currentPage = res?.currentPage || 1;
          this.config.itemsPerPage = res?.itemsPerPage || 10;
          this.config.totalItems = res?.totalItems || 0;
          this.config.totalPages = res?.totalPages || 0;
          this.config.from = res?.from || 1;
          this.config.to = res?.to || 10;
          this.isRunningQuoteTabActive = res?.isRunningQuoteTabActive ?? true;
        }
      }
    );
  }

  getSearchKeywordInfo() {
    this.searchKeywordSub$ = this.storageService.getSearchKeyword().subscribe(
      (res) => {
        if (res?.page_name === 'quotes') {
          this.initializeForm(res?.keyword);
          this.searchTerm = res?.keyword ?? ''; // this is for customer section
        }
      }
    );
  }

  initializeForm(value = '') {
    this.quoteSearchForm = this.fb.group({
      search_data: [value],
    });
  }

  initializeQuoteImportForm() {
    this.quoteImportForm = this.fb.group({
      'external_system': new UntypedFormControl(this.systemTypeList?.length ? this.systemTypeList[0]?.key : '', [Validators.required]),
      'import_file': new UntypedFormControl('', [Validators.required]),
      'source_file': new UntypedFormControl('', [Validators.required]),
    });
  }

  onFileChange(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.quoteImportForm.patchValue({
        import_file: file
      });
    }
  }

  importQuote() {
    const formData: FormData = new FormData();
    for (const key in this.quoteImportForm.value) {
      if (key !== 'source_file') {
        formData.append(key, this.quoteImportForm.get(key).value);
      }
    }
    this.isImporting = true;
    this.quotesService.importQuote(formData).subscribe(
      (res) => {
        this.isImporting = false;
        this.toastrService.success(res?.data?.response_message, 'Success', {
          timeOut: 5000
        });

        const quote = {
          uuid: res?.data?.quotation_uuid
        };
        this.quotationDetails(quote);
      },
      (error) => {
        this.isImporting = false;
        if (error.error && error.error?.message?.length) {
          this.toastrService.error(error.error?.message[0], 'Error', {
            timeOut: 5000
          });
        }
      }
    )
  }

  onSearchQuotes() {
    this.isLoading = true;
    this.config.currentPage = 1;
    this.setPagination();
    this.getQuotes();
  }

  clearSearch() {
    this.initializeForm();
    this.setSearchKeyword(null);
    this.onSearchQuotes();
  }
  getQuotes() {
    const searchQuery = this.quoteSearchForm.value?.search_data ? `&search_data=${this.quoteSearchForm.value.search_data}` : '';
    const params = new HttpParams({
      fromString: `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}` + `${searchQuery}` + `&show_all=${this.show_all}`
    });

    this.quotesService.getQuotes(params).subscribe(
      (res) => {
        this.quotes = res.data.results;
        this.isLoading = false;
        this.config.totalItems = res.data.count;
      },
      (err) => {
        this.isLoading = false;
        this.quotes = [];
        this.config.totalItems = 0;
      });
  }
  getQuotesForCustomer() {
    const searchQuery = this.searchTerm ? `&search_data=${this.searchTerm}` : '';

    const quoteState = this.isRunningQuoteTabActive ? `&quote_state=active` : `&quote_state=inactive`;
    const statusQuery = this.filterData.status.length ? `&quote_status=${this.filterData.status.join(',')}` : '';
    const from = this.filterData.dateFrom ? `&created_from=${moment(this.filterData.dateFrom).format('YYYY-MM-DD')}` : '';
    const to = this.filterData.dateTo ? `&created_until=${moment(this.filterData.dateTo).format('YYYY-MM-DD')}` : '';
    const createdBy = this.filterData.createdBy.length ? `&create_flow=${this.filterData.createdBy.map(item=> item.key).join(',')}` : '';
    const params = new HttpParams({
      fromString: `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}` + `${searchQuery}` + `${quoteState}` + `${from}` + `${to}` + `${statusQuery}` + `${createdBy}`
    });

    this.quotesService.getQuotesForCustomer(params).subscribe(
      (res) => {
        // this.quotes = res?.data?.results ?? [];
        this.quotes = (res?.data?.results ?? []).map(quote => {
          return {
            ...quote,
            modifiedStatus : this.getModifiedStatusName(quote.status)
          }
        });
        this.sortedQuotes = [...this.quotes];
        this.isLoading = false;
        this.config.totalItems = res?.data?.count ?? 0;
        this.config.totalPages = Math.ceil(this.config.totalItems / this.config.itemsPerPage);
        this.config.to = this.config.currentPage * this.config.itemsPerPage;
        this.config.from = this.config.to - this.config.itemsPerPage + 1;
        this.config.to = this.config.currentPage * this.config.itemsPerPage;
        this.config.from = this.config.to - this.config.itemsPerPage + 1;
        if (this.sortColumn) {
          this.sortData(this.sortColumn,true);
        }

      },
      (err) => {
        this.isLoading = false;
        this.quotes = [];
        this.sortedQuotes = [];
        this.config.totalItems = 0;
      });
  }
  sortData(column: string, doNotChangeDirection = false) {
      // If the same column is clicked, toggle the direction
    if (this.sortColumn === column) {
      if (doNotChangeDirection) {
        this.sortDirection = this.sortDirection || 'asc';
      }
      else {
        this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
      }
    } else {
      // If a different column is clicked, reset to ascending
      this.sortDirection = 'asc';
    }
    this.sortColumn = column;
    this.sortedQuotes = [...this.quotes];
    if (column === 'reference') {
      return ;
    }
    // Sort the quotes
    this.sortedQuotes.sort((a, b) => {
      const valueA = this.getValue(a, column);
      const valueB = this.getValue(b, column);

      if (valueA < valueB) {
        return this.sortDirection === 'asc' ? -1 : 1;
      }
      if (valueA > valueB) {
        return this.sortDirection === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }

  getValue(quote: any, column: string) {
    // Return the value to sort by depending on the column
    switch (column) {
      case 'modifiedStatus':
      case 'user':
        return quote[column]?.toLowerCase(); // Handle string sorting
      case 'total_amount_ex_vat':
        return parseFloat(quote[column]); // Handle numerical sorting
      case 'created_at':
        return new Date(quote[column]); // Handle date sorting
      default:
        return quote[column]; // For other cases
    }
  }
  clearFilter() {
    this.isLoading = true;
    this.filterData = {
      status: [],
      dateFrom: null,
      dateTo: null,
      createdBy: []
    };
    this.getQuotesForCustomer();
    this.quoteListFilterModalRef.close();
  }

  goToProductPage() {
    this.setPagination();
    this.setSearchKeyword(null);
    this.router.navigateByUrl('home');
  }

  onPageClick(event: any) {
    this.isLoading = true;
    this.config.currentPage = event;
    this.getQuotes();
  }

  setPagination() {
    this.storageService.setPagination({
      page_name: 'quotes',
      ...this.config,
      searchTerm: this.searchTerm,
      isRunningQuoteTabActive: this.isRunningQuoteTabActive

    });
  }

  setSearchKeyword(keyword) {
    this.storageService.setSearchKeyword({
      page_name: 'quotes',
      keyword
    });
  }

  quotationDetails(quote = null) {
    this.setPagination();
    this.setSearchKeyword(this.quoteSearchForm.value.search_data);
    this.router.navigate(['/quote-details', `${quote.uuid}`]);
  }
  goToCustomerQuoteDetails(quote) {
    this.setPagination();
    this.setSearchKeyword(this.searchTerm);
    this.router.navigate(['/customer/quote-details/', `${quote.uuid}`]);
  }

  addNewQuote() {
    this.storageService.removeQuoteUuid();
    this.router.navigate(['/customers'], { queryParams: { newQuote: true } });
  }
  resetPagination() {
    this.config.currentPage = 1;
    this.config.to = this.config.currentPage * this.config.itemsPerPage;
    this.config.from = this.config.to - this.config.itemsPerPage + 1;
  }
  onChangeShowAll() {
    this.isLoading = true;
    this.resetPagination();
    this.setPagination();
    this.getQuotes();
  }
  onTabChange(isRunningQuoteTabActive: boolean) {
    this.isLoading = true;
    this.isRunningQuoteTabActive = isRunningQuoteTabActive;
    this.resetPagination();
    this.setPagination();
    this.filterData.status = [];
    this.filterData.dateFrom = null;
    this.filterData.dateTo = null;
    this.filterData.createdBy = [];

    this.getQuotesForCustomer();
    if (isRunningQuoteTabActive) {
      this.quoteStatusList = ['pending', 'new', 'approved'];
    }
    else {
      this.quoteStatusList = ['processed', 'cancelled', 'expired'];
    }
  }
  onChangeItemPerPage() {
    this.isLoading = true;
    this.config.currentPage = 1;
    this.config.to = this.config.currentPage * this.config.itemsPerPage;
    this.config.from = this.config.to - this.config.itemsPerPage + 1;
    this.getQuotesForCustomer();
  }
  onPageChange(pageNumber) {
    this.isLoading = true;
    this.config.currentPage = pageNumber;
    this.config.to = this.config.currentPage * this.config.itemsPerPage;
    this.config.from = this.config.to - this.config.itemsPerPage + 1;
    this.getQuotesForCustomer();
  }

  onSearchTermChange(): void {
    // Clear the previous timeout if the user is still typing
    clearTimeout(this.debounceTimer);

    // Set a new timeout for the debounce
    this.debounceTimer = setTimeout(() => {
      this.resetPagination();

      this.getQuotesForCustomer();
    }, 500); // 300ms debounce time
  }
  getQuoteStatusIcon(status: string) {
    return this.utilityService.getQuoteStatusIcon(status);
  }
  getModifiedStatusName(status: string) {
    return this.utilityService.getModifiedStatusName(status);
  }
  onStatusChange(checked: boolean, item: string) {
    if (checked) {
      this.filterData.status.push(item);  // Add to the array if checked
    } else {
      this.filterData.status = this.filterData.status.filter(status => status !== item); // Remove if unchecked
    }
  }
  onCreatedByChange(checked: boolean, item: string) {
    if (checked) {
      this.filterData.createdBy.push(item);  // Add to the array if checked
    } else {
      this.filterData.createdBy = this.filterData.createdBy.filter(createdBy => createdBy !== item); // Remove if unchecked
    }
  }
  onFilterQuotes() {
    this.isLoading = true;
    this.resetPagination();
    this.setPagination();
    this.getQuotesForCustomer();
    this.quoteListFilterModalRef.close();
  }
  ngOnDestroy(): void {
    if (this.paginationSub$) {
      this.paginationSub$.unsubscribe();
    }
    if (this.searchKeywordSub$) {
      this.searchKeywordSub$.unsubscribe();
    }
  }
}
