import { Injectable } from '@angular/core';

import { ApiBaseService } from 'src/app/shared/api-base.service';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class HeaderService {

  private punchOutURI = 'quotes/punch-out';


  constructor(private router: Router, private apiBaseService: ApiBaseService) { }

  punchOut(password) {
    return this.apiBaseService.post(this.punchOutURI, {
      password: `${password ? password : ''}`
    });
  }

  goToExistingCustomer() {
    this.router.navigateByUrl('/customers');
  }

  goToDepotHistory() {
    this.router.navigateByUrl('/depot-history');
  }

  navigatePage(url: string) {
    this.router.navigateByUrl(`/${url}`);
  }

  // tslint:disable-next-line:variable-name
  goToCartOverview(cart_uuid: string) {
    this.router.navigate(['/cart', `${cart_uuid}`]);
  }

  getCatalogs() {
    // catalog
    // search/catalog-product-groups
    // search/catalogs/product-groups/
    return this.apiBaseService.get('search/catalogs/product-groups');
  }

  getChildGroups(productGroupCode) {
    return this.apiBaseService.get(`search/product-groups/${productGroupCode}`);
  }

  getProductSearch(keyword: string) {
    const paramsString = `keyword=${keyword}`;
    const productListURI = `search/products/list`;
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(productListURI, params);
  }
}
