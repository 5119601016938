<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="customer-search kpn-card bg-kpn-light mb-4">
        <div class="mb-3">
          <h4 class="title-4 green-text" i18n="@@arm.order-status.serc">
            {{'ORDER_STATUS.SEARCH' | transloco }}
          </h4>
        </div>

        <form [formGroup]="searchForm" (ngSubmit)="onSearchOrder()">
          <div class="row align-items-center">
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.ext-num">{{'ORDER_STATUS.ORDER_NO' | transloco }}</label>
                <input type="text" class="form-control kpn-form-control" formControlName="external_number"
                  placeholder="" />
              </div>
            </div>
            <!-- <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.int-num">Intern nr</label>
                <input type="text" class="form-control kpn-form-control" formControlName="internal_number"
                  placeholder="">
              </div>
            </div> -->
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.client-num">PO {{'ORDER_STATUS.NO' | transloco }}</label>
                <input type="text" class="form-control kpn-form-control" formControlName="po_number" placeholder="" />
              </div>
            </div>
            <div class="col-md-3 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.mob-num">{{'ORDER_STATUS.MOBILE_NO' | transloco }}</label>
                <input type="text" [dropSpecialCharacters]="false" mask="0000000000"
                  class="form-control kpn-form-control" formControlName="mobile_number" placeholder="" />
              </div>
            </div>
          </div>

          <div class="row align-items-center mb-3">
            <div class="col-md-2 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.post-code">Postcode</label>
                <input type="text" appToUpper="true" oninput="this.value = this.value.toUpperCase()"
                  class="form-control kpn-form-control" formControlName="postal_code" placeholder="" />
              </div>
            </div>
            <div class="col-md-2 col-6 col-gap-reduce">
              <div class="form-group kpn-form-group">
                <label i18n="@@arm.order-status.house-num"> {{'ORDER_STATUS.HOUSE_NO' | transloco }}</label>
                <input type="number" class="form-control kpn-form-control" formControlName="house_number"
                  placeholder="" />
              </div>
            </div>
            <div class="col-md-2 col-6 col-gap-reduce" *ngIf="user?.contact_group?.is_retail_store">
              <div class="form-group kpn-form-group">
                <div class="checkbox-group mt-5 checkbox-group-list-type">
                  <label for="retail-order" ngbButtonLabel class="checkbox-item">
                    <div class="checkbox-btn">
                      <input ngbButton type="checkbox" id="retail-order" formControlName="own_order"
                        (ngModelChange)="onSearchOrder()" />
                      <span class="checkBoxCheckmark"></span>
                    </div>
                    <span class="checkbox-label">{{'ORDER_STATUS.OWN_ORDERS_ONLY' | transloco }} </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-6 col-gap-reduce" *ngIf="user?.contact_group?.is_outbound_user">
              <div class="form-group kpn-form-group">
                <div class="checkbox-group mt-5 checkbox-group-list-type">
                  <label for="parked-order" ngbButtonLabel class="checkbox-item">
                    <div class="checkbox-btn">
                      <input ngbButton type="checkbox" id="parked-order" formControlName="is_parked"
                        (ngModelChange)="onSearchOrder()" />
                      <span class="checkBoxCheckmark"></span>
                    </div>
                    <span class="checkbox-label">  {{'ORDER_STATUS.IS_PARKED' | transloco }} </span>
                  </label>
                </div>
              </div>
            </div>

            <div class="col-md-2 col-6 col-gap-reduce">
              <button type="submit" [disabled]="isLoading" class="btn btn-block btn-primary mt-2 rounded-btn"
                i18n="@@arm.order-status.search-btn">
                {{'ORDER_STATUS.SEARCH' | transloco }}
              </button>
            </div>
          </div>
        </form>
      </div>

      <div class="loader-wrapper box-shadow-sm" [ngClass]="{ 'spinner-wrapper-height': isLoading }">
        <div class="table-responsive custom-table">
          <h3 class="title-3 green-text ml-4 pt-3" i18n="@@arm.order-status.order-list">
            {{'ORDER_STATUS.ORDER_LIST' | transloco }} :
          </h3>
          <div class="kpn-spinner" *ngIf="isLoading; else table_content">
            <div class="text-center">
              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading" />
              <p>{{'ORDER_STATUS.TO_SEARCH' | transloco }}…</p>
            </div>
          </div>
          <ng-template #table_content>
            <table class="table mb-5">
              <thead>
                <tr>
                  <th i18n="@@arm.order-status.mobile">{{'ORDER_STATUS.MOBILE' | transloco }}</th>
                  <th i18n="@@arm.order-status.order-num">Order  {{'ORDER_STATUS.NO' | transloco }}</th>
                  <th i18n="@@arm.order-status.client-num">PO  {{'ORDER_STATUS.NO' | transloco }}</th>
                  <th i18n="@@arm.order-status.order-date"> {{'ORDER_STATUS.ORDER_DATE' | transloco }}</th>
                  <th i18n="@@arm.order-status.client-name">{{'ORDER_STATUS.CUSTOMER_NAME' | transloco }}</th>
                  <th i18n="@@arm.order-status.company-name"> {{'ORDER_STATUS.COMPANY_NAME' | transloco }}</th>
                  <th i18n="@@arm.order-status.status">Status</th>
                  <th i18n="@@arm.order-status.total_amount"> {{'ORDER_STATUS.TOTAL_AMOUNT' | transloco }}</th>
                  <th>Contact {{'ORDER_STATUS.GROUP' | transloco }}</th>
                  <th> {{'ORDER_STATUS.PLACED_BY' | transloco }}</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let order of orders | paginate : config">
                  <td>{{ order.contact_person?.mobile_phone_number }}</td>
                  <td>
                    <a href="javascript:void(0)" (click)="onOrderDetail(order, orderDetailsModal)" class="blue-link">{{
                      order.reference }}
                      <span *ngIf="
                          hasRmaPermission?.add &&
                          allowRma &&
                          order?.rma_allowed
                        " class="dark-orange-tag-pill">
                        <small> RMA {{'ORDER_STATUS.ALLOWED' | transloco }}</small></span>
                    </a>
                    <!-- (click)="onOrderDetail(order)" -->
                  </td>
                  <td>{{ order.po_number }}</td>
                  <td>{{ order.created_at | date : "dd-MM-yyyy" }}</td>
                  <td>
                    {{ order.contact_person?.initials }}
                    {{ order.contact_person?.surname_prefix }}
                    {{ order.contact_person?.last_name }}
                  </td>
                  <td>{{ order?.shipping_address?.company_name }}</td>
                  <td>
                    {{
                    order?.status === "Fraud check"
                    ? "In onderzoek"
                    : order?.status
                    }}
                    <small><i *ngIf="order.status.toLowerCase() === eOrderStatus.NEW"
                        class="green-text fas fa-check"></i></small>
                  </td>
                  <td class="text-right">
                    {{ order?.total_amount | currencyFormat }}
                  </td>
                  <td>
                    <span>{{ order?.contact_group }}</span>
                  </td>
                  <td>
                    <span>{{ order?.placed_by }} &nbsp; &nbsp;
                      <button [disabled]="order?.loading" *ngIf="
                          order.status.toLowerCase() === eOrderStatus.QUEUED
                        " (click)="cancelOrder(order)" type="button" class="btn btn-sm btn-primary rounded-btn">
                        <span *ngIf="order?.loading" class="spinner-border spinner-border-sm mr-2" role="status"
                          aria-hidden="true">
                        </span>
                        <small>{{'ORDER_STATUS.CANCEL_ORDER' | transloco }}</small>
                      </button>
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new">
              <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true" class="pagination"
                (pageChange)="onPageClick($event)"></pagination-controls>
            </div>
          </ng-template>
        </div>
      </div>

      <ng-template #orderDetailsModal let-modal>
        <div class="modal-header">
          <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle" i18n="@@arm.order-status.order-details">
            {{'ORDER_STATUS.ORDER_DETAILS' | transloco }}
          </h5>
          <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="kpn-card confirmation-cart-overview">
            <div class="row mb-2" *ngIf="!order?.is_paid && order?.direct_payment_required">
              <div class="col-md-12 mt-1 pt-1">
                <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                  <small>
                    <span class="kphicon-uniE952"></span>
                    {{ description }}
                  </small>
                </div>
              </div>
            </div>
            <app-payment *ngIf="
                !order?.is_paid &&
                order?.direct_payment_required &&
                !paymentStatus?.is_total_amount_paid
              " [order]="order" [contact]="contact">
            </app-payment>
            <div class="voicelog-overview order-confirmation-user-info">
              <div class="row mb-4">
                <div class="col-md-4 client-info client-info-order-conf">
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">
                        {{'ORDER_STATUS.COMPANY_NAME' | transloco }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ contact?.company_name }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">
                        {{'ORDER_STATUS.NAME' | transloco }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ contactPerson?.initials }}
                        {{ contactPerson?.last_name }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.phone">
                        {{'ORDER_STATUS.TELEPHONE' | transloco }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ contactPerson?.mobile_phone_number }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.email">
                        {{'ORDER_STATUS.E_MAIL' | transloco }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ contactPerson?.email }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 client-info client-info-order-conf">
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">
                        {{'ORDER_STATUS.SHIPPING_ADDRESS' | transloco }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        <span *ngIf="shippingAddress?.company_name">{{ shippingAddress?.company_name }}<br /></span>
                        {{ shippingAddress?.street }}
                        {{ shippingAddress?.house_number }}
                        {{ shippingAddress?.house_number_extension }}<br />
                        {{ shippingAddress?.zip_code }}
                        {{ shippingAddress?.city }}
                        <span *ngIf="hasInputCustomAddressPermission" class="d-block">{{ order?.shipping_extra_address_information_1 }} <br>
                          {{ order?.shipping_extra_address_information_2 }}</span>
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">
                        {{'ORDER_STATUS.BILLING_ADDRESS' | transloco }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ billingAddress?.street }}
                        {{ billingAddress?.house_number }}
                        {{ billingAddress?.house_number_extension }}<br />
                        {{ billingAddress?.zip_code }}
                        {{ billingAddress?.city }}
                      </p>
                    </div>
                  </div>
                </div>

                <div class="col-md-4 client-info client-info-order-conf">
                  <div class="row" *ngIf="shippingAddress?.bank_account_number">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{'ORDER_STATUS.ACCOUNT_NUMBER' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ shippingAddress?.bank_account_number }}
                      </p>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{'ORDER_STATUS.ORDER_NUMBER' | transloco }}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ order?.reference }}
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="order?.customer_reference">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">
                        {{
                        siteProfile?.reference_label || "Klant Referentie"
                        }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ order?.customer_reference }}
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="order?.po_number">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">
                        {{ siteProfile?.po_number_label || "PO Number" }}:
                      </p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">
                        {{ order?.po_number }}
                      </p>
                    </div>
                  </div>
                  <div class="row" *ngIf="order?.requested_delivery_date">
                    <div class="col-auto col-add-info-title pr-0">
                      <p class="body-text-1 mb-0 text-right">{{'ORDER_CONFIRMATION.REQUESTED_DELIVERY_DATE' | transloco}}:</p>
                    </div>
                    <div class="col">
                      <p class="body-text-1 info-desc mb-0">{{order?.requested_delivery_date | date: 'dd-MM-yyyy'}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="cart-items-wrap">
              <div class="cart-item cart-item-single-box" *ngIf="!['cancelled', 'rejected'].includes(order?.status)">
                {{'ORDER_STATUS.ORDER_STATUS_TEXT_1' | transloco }}
                <a href="https://my.cevalogistics.com/tracking/cl/public/" target="_blank">{{'ORDER_STATUS.TRACK_AND_TRACE' | transloco }}</a>.
                {{'ORDER_STATUS.ORDER_STATUS_TEXT_2' | transloco }}.
              </div>
              <div class="cart-item cart-item-single-box" *ngIf="['cancelled', 'rejected'].includes(order?.status)">
                {{'ORDER_STATUS.ORDER_CANCEL_TEXT' | transloco }}.
              </div>
            </div>
            <div class="cart-items-wrap">
              <div class="cart-item cart-item-single-box">
                <div class="cart-item-row mb-3 border-0">
                  <div class="row">
                    <div class="col-lg">
                      <div class="row">
                        <div class="col">
                          <b>Product</b>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="row pt-4 action-cart-price align-items-center justify-content-end">
                        <div class="col-auto">
                          <div class="cart-qty-amount text-center">
                            <b>{{'ORDER_STATUS.NUMBER' | transloco }}</b>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="price-col text-right"><b> {{'ORDER_STATUS.PRICE' | transloco }}</b></div>
                        </div>
                        <div class="col-auto">
                          <div class="price-col text-right">
                            <b>{{'ORDER_STATUS.TOTAL_PRICE' | transloco }}</b>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="cart-item-row mb-3 border-0" *ngFor="let line of orderLines; let i = index">
                  <div class="row">
                    <div class="col-lg">
                      <div class="row">
                        <div class="col-auto px-0 hide-on-pad">
                          <div class="cart-item-image">
                            <img *ngIf="
                                line?.product_code?.image ||
                                line?.product_code?.product_thumbnail
                              " src="{{
                                line?.product_code?.image ||
                                  line?.product_code?.product_thumbnail
                              }}" alt="" />
                            <img *ngIf="
                                !line?.product_code?.image &&
                                !line?.product_code?.product_thumbnail &&
                                (!line.brand?.includes('cisco') ||
                                  !line.brand?.includes('Cisco'))
                              " src="/assets/images/noimage.png" alt="" />
                            <img *ngIf="
                                !line?.product_code?.image &&
                                !line?.product_code?.product_thumbnail &&
                                (line.brand?.includes('cisco') ||
                                  line.brand?.includes('Cisco'))
                              " src="/assets/images/cisco-networking.png" alt="" />
                          </div>
                        </div>
                        <div class="col">
                          <div class="cart-item-details">
                            <h4 class="title-4 mb-0 pt-2">
                              {{ line?.product_code?.brand__name }}
                            </h4>
                            <p class="model-desc">
                              {{ line?.product_code?.code }}-{{
                              line?.product_code?.name
                              }}
                              <small *ngIf="line?.promotion_description" class="pt-0 ml-1 mr-1" >
                                <span class="badge badge-info" [innerHTML]="line?.promotion_description"></span>
                              </small>
                            </p>
                            <p class="sub-title-light-5" *ngIf="line?.voucher_code">
                              <small class="text-dark"> Voucher code: <b> {{ line?.voucher_code }}</b></small>
                            </p>
                            <p class="sub-title-light-5" *ngIf="line?.voucher_reference">
                              <small class="text-dark"> Voucher {{'ORDER_OVERVIEW.REFERENCE' | transloco}}: <b> {{ line?.voucher_reference
                                  }}</b></small>
                            </p>
                            <p class="sub-title-light-5" *ngIf="line?.provisions">
                              <span *ngIf="handsetProvisionStatus">
                                <small class="text-dark"> {{'ORDER_CONFIRMATION.FACILITY_STATUS' | transloco}}: <b> {{ handsetProvisionStatus}}</b></small>
                                <br>
                                <br>
                              </span>
                              <span *ngFor="let provision of line?.provisions">
                                <small class="text-dark d-block"> IMEI: <b> {{ provision?.imei_number }} </b></small>
                                <small class="text-dark d-block"> Status: <b> {{ provision?.status }} </b></small>
                                <br>
                              </span>
                            </p>
                            <p class="sub-title-light-5" *ngIf="line?.order_line_extra?.contact_account_type">
                              <small class="text-dark"> {{'ORDER_OVERVIEW.ACCOUNT_TYPE' | transloco}}: <b> {{
                                  line?.order_line_extra?.contact_account_type?.account_id
                                  }} <span
                                    *ngIf="line?.order_line_extra?.contact_account_type?.account_type_code">({{line?.order_line_extra?.contact_account_type?.account_type_code}})</span>
                                </b></small><br>
                            </p>
                            <p class="sub-title-light-5"
                              *ngIf="line?.order_line_extra?.comm_plan || line?.order_line_extra?.rate">
                              <small class="text-dark" *ngIf="line?.order_line_extra?.is_default_rate_plan"><b> {{'CART.DEFAULT_RATE_PLAN' | transloco}}
                                </b><br>
                              </small>
                              <small class="text-dark"
                                *ngIf="line?.order_line_extra?.comm_plan || line?.order_line_extra?.rate"> {{'CART.COMM_PLAN' | transloco}}: <b>
                                  {{line?.order_line_extra?.comm_plan}}
                                </b><br>
                              </small>
                              <small class="text-dark"
                                *ngIf="line?.order_line_extra?.comm_plan || line?.order_line_extra?.rate"> {{'CART.RATE_PLAN' | transloco}}: <b>
                                  {{line?.order_line_extra?.rate |currencyFormat}} </b>
                              </small>
                            </p>
                            <span *ngIf="line?.delivery_method" class="mb-5">
                              <button class="flat-grey-light" placement="bottom">
                                <h6 class="title-6 mb-0 mr-2">{{'ORDER_STATUS.DELIVERY_METHOD' | transloco }}:</h6>
                                <span class="dark-orange-tag-pill mr-5">
                                  <b>
                                    {{ line?.delivery_method?.name }}</b></span>
                              </button>
                            </span>
                            <div *ngIf="
                                hasRmaPermission?.add &&
                                allowRma &&
                                order?.rma_allowed &&
                                !line?.voucher_code
                              ">
                              <div class="row mt-3">
                                <div class="col-md-8">
                                  <span class="mb-3">
                                    <button class="flat-grey-light" placement="bottom">
                                      <h6 class="title-6 mb-0 mr-2">
                                        RMA {{'ORDER_STATUS.OPTIONS' | transloco }}
                                      </h6>
                                    </button>
                                    <select name="{{ line?.uuid }}" class="form-control" id="{{ line?.uuid }}"
                                      [(ngModel)]="line.selected_rma_option" (ngModelChange)="checkRma()">
                                      <option [ngValue]="null">
                                      {{'ORDER_STATUS.SELECT_RMA_OPTION' | transloco }}
                                      </option>
                                      <option *ngFor="
                                          let item of line?.rma_options
                                            ?.main_line_rma_options
                                        " [ngValue]="item">
                                        {{ item?.name }}
                                      </option>
                                    </select>
                                  </span>
                                </div>
                                <div class="col-md-4">
                                  <span class="mb-3">
                                    <button class="flat-grey-light" placement="bottom">
                                      <h6 class="title-6 mb-0 mr-2">
                                        RMA {{'ORDER_STATUS.QUANTITY' | transloco }}
                                      </h6>
                                    </button>
                                  </span>
                                  <div class="quantity d-flex align-items-center">
                                    <div class="item-add-remove">
                                      <div class="d-flex align-items-center">
                                        <div class="add-remove-input">
                                          <input appPositiveInteger type="number" [(ngModel)]="line.rma_qty" min="1"
                                            max="{{ line.qty }}" (ngModelChange)="checkRma()" />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-auto">
                                  <small *ngIf="line.rma_qty > line.qty" class="danger-text">
                                    {{'ORDER_STATUS.RMA_QUANTITY_ALERT_TEXT_1' | transloco }}({{
                                    line?.qty
                                    }})</small>
                                  <small *ngIf="line.rma_qty < 1" class="danger-text">
                                    {{'ORDER_STATUS.RMA_QUANTITY_ALERT_TEXT_2' | transloco }}
                                  </small>
                                </div>
                              </div>
                              <div class="row mt-1">
                                <div class="col-md-8">
                                  <span class="mb-1">
                                    <button class="flat-grey-light" placement="bottom">
                                      <h6 class="title-6 mb-0 mr-2">{{'ORDER_STATUS.REASONS' | transloco }}</h6>
                                    </button>
                                    <textarea name="{{ line?.uuid }}" id="{{ line?.uuid }}" cols="40" rows="3"
                                      [(ngModel)]="line.user_reason" (ngModelChange)="checkRma()"></textarea>
                                    <small *ngIf="
                                        line.selected_rma_option &&
                                        !line.user_reason?.trim()
                                      " class="danger-text">{{'ORDER_STATUS.PLEASE_WRITE_RMA_REASON' | transloco }}.</small>
                                  </span>
                                </div>
                                <div class="col-auto">
                                  <small *ngIf="
                                      line.selected_rma_option &&
                                      !line.selected_rma_reason
                                    " class="danger-text">{{'ORDER_STATUS.PLEASE_SELECT_RMA_REASON' | transloco }}.</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-5">
                      <div class="row pt-4 action-cart-price align-items-center justify-content-end">
                        <div class="col-auto">
                          <div class="cart-qty-amount text-right amount-col pt-1">
                            <span *ngIf="!line?.voucher_code">
                              {{ line?.qty }}
                            </span>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="price-col pt-2 text-right sub-title-light-4 dark-grey-text">
                            <!-- {{line?.amount_ex_vat | currencyFormat}} -->
                            <span *ngIf="!line?.voucher_code">
                              {{(utilityService.isInclVAT() ? line?.amount :  line?.amount_ex_vat )| currencyFormat }}
                            </span>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="price-col pt-2 text-right sub-title-light-4 dark-grey-text">
                            <span>
                              {{(utilityService.isInclVAT() ? line?.amount :  line?.amount_ex_vat )| currencyFormat }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-12 cart-sub-items" *ngFor="let subLine of line.sub_lines; let j = index">
                      <div class="row">
                        <div class="col">
                          <div class="row">
                            <div class="col">
                              <div class="cart-item-details">
                                <p class="model-desc text-left">
                                  {{ subLine?.product_code?.name }}
                                  <small *ngIf="subLine?.promotion_description" class="pt-0 ml-1 mr-1" >
                                    <span class="badge badge-info" [innerHTML]="subLine?.promotion_description"></span>
                                  </small>
                                  <span *ngIf="
                                      !subLine?.product_code?.code?.startsWith(
                                        'tkh_'
                                      )
                                    ">
                                    ({{ subLine?.product_code?.code }})
                                    <b>x {{ subLine?.qty }}</b></span>
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-auto">
                          <div class="row action-cart-price align-items-center justify-content-end">
                            <div class="col-auto sub-item-price-col-auto">
                              <div class="price-col text-right sub-title-light-4 pt-2 dark-grey-text">
                                {{(utilityService.isInclVAT() ? subLine?.amount :  subLine?.amount_ex_vat )| currencyFormat }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row justify-content-end item-cart-footer item-cart-footer-orderoverview pr-0 mr-0"
                  *ngIf="!isLoading">
                  <div class="col-lg cart-total pr-0">
                    <div class="row justify-content-end align-items-center">
                      <div class="col-auto pr-0">
                        <div class="amount-col">
                          <h5 class="title-3 green-text text-right pr-2">
                            excl. {{'ORDER_STATUS.VAT' | transloco }}
                          </h5>
                          <h5 class="title-3 green-text text-right pr-2">
                            {{'ORDER_STATUS.VAT' | transloco }}
                          </h5>
                          <h5 class="title-3 green-text text-right total-above-line pr-2">
                            {{'ORDER_STATUS.TOTAL_INCL' | transloco }}. {{'ORDER_STATUS.VAT' | transloco }}
                          </h5>
                        </div>
                      </div>
                      <div class="col-auto pl-0 pr-0">
                        <div class="price-col">
                          <h5 class="title-3 dark-grey-text text-right">
                            {{ order?.total_amount_ex_vat | currencyFormat }}
                          </h5>
                          <h5 class="title-3 dark-grey-text text-right">
                            {{ order?.total_amount_vat | currencyFormat }}
                          </h5>
                          <h5 class="title-3 dark-grey-text text-right total-above-line">
                            {{ order?.total_amount | currencyFormat }}
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div *ngIf="
                        hasRmaPermission?.add && allowRma && order?.rma_allowed
                      " class="row justify-content-end align-items-center">
                      <div class="col-auto pl-0 pr-0">
                        <span>
                          <button [disabled]="!rmaLines?.length" (click)="createRMA(rmaInvoiceUpload)"
                            class="btn btn-primary rounded-btn mb-2">
                            <span *ngIf="rmaCreateLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                              aria-hidden="true"></span>
                             {{'ORDER_STATUS.CREATE' | transloco }} RMA
                          </button>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" *ngIf="shipmentDetails?.length">
                <div class="col-md-12">
                  <h6 class="title-5 green-text mb-3">{{'ORDER_STATUS.SHIPMENT_DETAILS' | transloco }}</h6>
                  <table class="table mb-5 tree-view-table">
                    <thead>
                      <tr class="parent-head">
                        <th width="30%">{{'ORDER_STATUS.TRACKING_CODE' | transloco }}</th>
                        <th>{{'ORDER_STATUS.SHIPPED_DATE' | transloco }}</th>
                        <th>{{'ORDER_STATUS.ARRIVAL_DATE' | transloco }}</th>
                        <th>{{'QUOTE_DETAILS.STATUS' | transloco}}</th>
                        <th  class="text-center">{{'ORDER_STATUS.DOCUMENTS' | transloco}}</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let shipmentLine of shipmentDetails">
                      <tr>
                        <td>{{ shipmentLine?.tracking_code }}</td>
                        <td>{{ shipmentLine?.shipped_date | date: 'dd-MM-yyyy' }}</td>
                        <td>{{ shipmentLine?.arrival_date | date: 'dd-MM-yyyy' }}</td>
                        <td>
                          {{ shipmentLine?.status | uppercase }}
                        </td>
                        <td class="text-left">
                          <div *ngFor="let file of shipmentLine?.documents">
                            <div class="row">
                              <div class="col-md-12">
                                <button type="button" class="btn btn-sm btn-link" (click)="downloadDocuments(file)">
                                  <i class="{{ getFileExtension(file) }}" style="color: #5cb85c" aria-hidden="true"></i>
                                  {{ file?.file_name }}
                                  <i class="kpn-ui-icon ui-download v-text-bottom mr-2" style="color: #5cb85c"></i>
                                </button>
                              </div>
                            </div>
                          </div>
                        </td>
                      </tr>
                      <tr class="child-head">
                        <th class="py-1 pl-4">
                          <div class="pl-2">Item</div>
                        </th>
                        <th class="py-1 text-center">{{'ORDER_STATUS.QTY' | transloco }}</th>
                        <th class="py-1 text-center"></th>
                        <th class="py-1 text-center" width="30%">
                          {{'ORDER_STATUS.SERIALS' | transloco }}
                        </th>
                      </tr>
                      <tr class="child-list" *ngFor="let item of shipmentLine?.shipment_items">
                        <td class="pl-4">
                          <div class="pl-2">
                            {{ item?.name }} ({{ item?.product_code }})
                          </div>
                        </td>
                        <td class="text-center">{{ item?.qty_shipped }}</td>
                        <td class="text-center"></td>
                        <td>
                          <div class="card" *ngFor="let serial of item?.serials">
                            <div class="card-body pt-1 pb-2 px-3">
                              <span *ngIf="serial?.sequence">
                                <small>
                                   {{'ORDER_STATUS.SEQUENCE' | transloco }} :
                                  {{ serial?.sequence }} </small><br />
                              </span>
                              <span *ngIf="serial?.serial_number">
                                <small>
                                   {{'ORDER_STATUS.SERIAL' | transloco }} #:
                                  {{ serial?.serial_number }} </small><br />
                              </span>
                              <span *ngIf="serial?.imei_number">
                                <small>
                                  IMEI #:
                                  {{ serial?.imei_number }} </small><br />
                              </span>
                              <span *ngIf="serial?.iccid_number">
                                <small>
                                  iccid #:
                                  {{ serial?.iccid_number }}
                                </small>
                              </span>
                            </div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <ng-template #rmaInvoiceUpload let-modal>
        <div class="modal-header">
          <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle">
            {{'ORDER_STATUS.UPLOAD_INVOICE' | transloco }}
          </h5>
          <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>

        <div class="modal-body">
          <div class="kpn-card confirmation-cart-overview">
            <div class="voicelog-overview order-confirmation-user-info">
              <div class="row mb-4">
                <form [formGroup]="invoiceUploadForm">
                  <div class="row align-items-center">
                    <div class="col-md-8 col-6 col-gap-reduce">
                      <div class="form-group kpn-form-group">
                        <label i18n="@@arm.order-status.ext-num">  {{'ORDER_STATUS.FILE' | transloco }}</label>
                        <input type="file"
                          accept="application/pdf,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                          class="form-control" formControlName="source_file" (change)="onFileChange($event)" />
                      </div>
                    </div>
                    <div class="col-md-4 col-6 col-gap-reduce">
                      <button (click)="uploadInvoice()" [disabled]="
                          invoiceUploading ||
                          !invoiceUploadForm.valid ||
                          !invoiceUploadForm.dirty
                        " class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                        <span *ngIf="!invoiceUploading; else Uploading">{{'ORDER_STATUS.UPLOAD_INVOICE' | transloco }}</span>
                        <ng-template #Uploading>
                          <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                        </ng-template>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </ng-template>

      <!-- Order Details modal -->
      <div class="modal fade" id="showOrderDetails" tabindex="-1" role="dialog" aria-labelledby="showOrderDetailsTitle"
        aria-hidden="true">
        <div class="modal-dialog modal-xl modal-dubble-xl modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title title-2 green-text" id="showOrderDetailsTitle"
                i18n="@@arm.order-status.order-details">
                {{'ORDER_STATUS.ORDER_DETAILS' | transloco }}
              </h5>
              <button type="button" class="close" data-dismiss="modal" id="orderDetailsModalClose" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body">
              <div class="kpn-card confirmation-cart-overview">
                <div class="voicelog-overview order-confirmation-user-info">
                  <div class="row mb-4">
                    <div class="col-md-6 client-info client-info-order-conf">
                      <div class="row">
                        <div class="col-auto col-add-info-title pr-0">
                          <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">
                            {{'ORDER_STATUS.LAST_NAME' | transloco }}:
                          </p>
                        </div>
                        <div class="col">
                          <p class="body-text-1 info-desc mb-0">
                            {{ contactPerson?.last_name }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto col-add-info-title pr-0">
                          <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.full-name">
                            {{'ORDER_STATUS.FIRST_AND_LAST_NAME' | transloco }}:
                          </p>
                        </div>
                        <div class="col">
                          <p class="body-text-1 info-desc mb-0">
                            {{ contactPerson?.initials }}
                            {{ contactPerson?.last_name }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto col-add-info-title pr-0">
                          <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">
                            {{'ORDER_STATUS.ADDRESS' | transloco }}:
                          </p>
                        </div>
                        <div class="col">
                          <p class="body-text-1 info-desc mb-0">
                            {{ shippingAddress?.street }}
                            {{ shippingAddress?.house_number }}
                            {{ shippingAddress?.house_number_extension }}<br />
                            {{ shippingAddress?.zip_code }}
                            {{ shippingAddress?.city }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto col-add-info-title pr-0">
                          <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.phone">
                            {{'ORDER_STATUS.TELEPHONE' | transloco }}:
                          </p>
                        </div>
                        <div class="col">
                          <p class="body-text-1 info-desc mb-0">
                            {{ contactPerson?.mobile_phone_number }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto col-add-info-title pr-0">
                          <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.email">
                            {{'ORDER_STATUS.E_MAIL' | transloco }}:
                          </p>
                        </div>
                        <div class="col">
                          <p class="body-text-1 info-desc mb-0">
                            {{ contactPerson?.email }}
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-auto col-add-info-title pr-0">
                          <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.dob">
                            {{'ORDER_STATUS.DATE_OF_BIRTH' | transloco }}:
                          </p>
                        </div>
                        <div class="col">
                          <p class="body-text-1 info-desc mb-0">
                            {{ contactPerson?.birthday }}
                          </p>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 client-info-account client-info-account-roder-conf">
                      <div class="row mb-2">
                        <div class="col pr-0">
                          <p class="sub-title-light-4 green-text mb-0 text-right"
                            i18n="@@arm.order-confirmation.account-number">
                            {{'ORDER_STATUS.ACCOUNT_NUMBER' | transloco }}:
                          </p>
                        </div>
                        <div class="col-auto">
                          <p class="sub-title-light-4 green-text mb-0">
                            {{ shippingAddress?.bank_account_number }}
                          </p>
                        </div>
                      </div>
                      <div class="row mb-1">
                        <div class="col pr-0">
                          <p class="sub-title-light-4 dark-grey-text mb-0 text-right"
                            i18n="@@arm.order-confirmation.id-number">
                            {{'ORDER_STATUS.ORDER_NUMBER' | transloco }}:
                          </p>
                        </div>
                        <div class="col-auto">
                          <p class="sub-title-light-4 dark-grey-text mb-0">
                            {{ order?.reference }}
                          </p>
                        </div>
                      </div>
                      <div class="row mb-1" *ngIf="order?.po_number">
                        <div class="col pr-0">
                          <p class="sub-title-light-4 dark-grey-text mb-0 text-right"
                            i18n="@@arm.order-confirmation.id-number">
                            {{ siteProfile?.po_number_label }}:
                          </p>
                        </div>
                        <div class="col-auto">
                          <p class="sub-title-light-4 dark-grey-text mb-0">
                            {{ order?.po_number }}
                          </p>
                        </div>
                      </div>
                      <div class="row mb-2">
                        <div class="col pr-0">
                          <p class="sub-title-light-4 dark-grey-text mb-0 text-right"
                            i18n="@@arm.order-confirmation.id-number">
                           {{'ORDER_STATUS.ORDER_STATUS' | transloco}}:
                          </p>
                        </div>
                        <div class="col-auto">
                          <p class="sub-title-light-4 dark-grey-text mb-0">
                            {{
                            order?.status === "fraud_check"
                            ? "In onderzoek"
                            : (order?.status | titlecase)
                            }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="quote-product-item-table mb-3">
                  <table border="0" cellpadding="0" cellspacing="0">
                    <thead>
                      <tr>
                        <th></th>
                        <th></th>
                        <th class="text-center">{{'ORDER_STATUS.NUMBER' | transloco }}</th>
                        <th class="text-center">{{'ORDER_STATUS.PRICE' | transloco }}</th>
                      </tr>
                    </thead>
                    <tbody *ngFor="let line of orderLines">
                      <tr class="min-line">
                        <td>{{ line?.product_code?.code }}</td>
                        <td class="font-family-regular">
                          {{ line?.product_code?.name }}
                        </td>
                        <td class="text-center">
                          {{ line?.qty }}
                        </td>
                        <td class="text-right">
                          {{ line?.amount | currencyFormat }}
                        </td>
                      </tr>
                      <tr class="subline-line" *ngFor="let subline of line.sub_lines">
                        <td colspan="2">
                          {{ subline?.product_code?.name }}
                        </td>
                        <td class="text-center">
                          {{ subline?.qty }}
                        </td>
                        <td class="text-right">
                          {{ subline?.amount | currencyFormat }}
                        </td>
                      </tr>
                      <tr>
                        <td colspan="4">
                          <div class="quote-total mb-0 text-right">
                            <span class="body-text-18-light grey-text mr-5">{{'QUOTE_DETAILS.LINE_TOTAL' | transloco}} :</span>
                            <label class="title-mid-5 mb-0 dark-grey-text text-right">{{ line?.total_amount |
                              currencyFormat }}</label>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

                <div class="row justify-content-end">
                  <div class="mr-5">
                    <div class="row align-items-center justify-content-end mb-2 pt-2 discount-in-total"
                      *ngIf="!isLoading">
                      <div class="col-auto pr-0">
                        <h5 class="title-mid-5 mb-0">{{'ORDER_STATUS.TOTAL_AMOUNT' | transloco }}:</h5>
                      </div>
                      <div class="col-md-auto">
                        <div class="text-right line-price-label text-right mr-4 pr-1">
                          {{ order?.total_amount | currencyFormat }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Order Details modal End -->
    </div>
  </section>
</div>

