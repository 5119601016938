import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';
import { StorageService } from 'src/app/shared/storage.service';

@Injectable({
  providedIn: 'root'
})
export class SmsCampaignToolService {
  constructor(
    private apiBaseService: ApiBaseService,
    private storageService: StorageService
  ) { }

  getContact() {
    const user = this.storageService.getCurrentUser();
    const contact = this.storageService.getCustomer();
    return contact ? contact?.uuid : user?.contact?.uuid;
  }


  getCampaigns(page = 1) {
    const paramsString = `page=${page}`;
    const params = new HttpParams({
      fromString: paramsString
    });
    const uri = `sms-campaigns/contacts/${this.getContact()}/campaigns`;
    return this.apiBaseService.get(uri, params);
  }

  validateFile(formData: FormData) {
    const url = `sms-campaigns/contacts/${this.getContact()}/campaigns`;
    return this.apiBaseService.postFile(url, formData);
  }

  addAccountType(payload) {
    const url = `contacts/${this.getContact()}/account-types`;
    return this.apiBaseService.post(url, payload);
  }

  sendCampaignSms(campaign_uuid: string) {
    const url = `sms-campaigns/contacts/${this.getContact()}/campaigns/${campaign_uuid}/send`;
    return this.apiBaseService.post(url, {});
  }

  viewCampaignSMS(campaign_uuid: string, page = 1, search = '') {
    let paramsString = `page=${page}`;
    if (search?.trim()?.length) {
      paramsString = `${paramsString}&search=${search?.trim()}`
    }
    const params = new HttpParams({
      fromString: paramsString
    });
    const uri = `sms-campaigns/contacts/${this.getContact()}/campaigns/${campaign_uuid}/sms`;
    return this.apiBaseService.get(uri, params);
  }
}
