import { Component, Input, OnInit } from '@angular/core';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss']
})
export class NavMenuComponent implements OnInit {
  @Input() navItems;
  @Input() showTradeIn;
  public tradeInCart = null;
  constructor(
    private storageService: StorageService) { }

  ngOnInit(): void {
    this.storageService.getTradeInCart().subscribe(cart => {
      if (cart) {
        this.tradeInCart = JSON.parse(cart);
      } else {
        this.tradeInCart = null;
      }
    });
  }
}
