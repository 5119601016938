import { param } from 'jquery';
import { Component, OnInit, ViewChild } from '@angular/core';
import { TradeInService } from './trade-in.service';
import { BehaviorSubject, Subject, Subscription, of, throwError } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, switchMap, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/storage.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslocoService } from '@ngneat/transloco';
import { BusinessPartnerSelectionComponent } from './business-partner-selection/business-partner-selection.component';
import { Store } from '@ngrx/store';
import { IAppState } from '../../app.state';
import { selectPermissions } from 'src/app/store/selectors/permission.selectors';
import { BasePermission } from 'src/app/shared/base/base-permission';

@Component({
  selector: 'app-trade-in',
  templateUrl: './trade-in.component.html',
  styleUrls: ['./trade-in.component.scss']
})
export class TradeInComponent extends BasePermission implements OnInit {

  public config: { itemsPerPage: number, currentPage: number, totalItems: number, totalPages: number,from:number,to:number } = {
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 0,
    totalPages: 0,
    from:1,
    to:10
  };

  public search = '';
  public loader = false;
  public tradeInCart = null;

  brandList = [];
  productPromotionList = [];
  public brandFilterModalReference: NgbModalRef;
  public selectedPromotionProduct: any;
  public searchProducts = [];
  public permissionSubs$ : Subscription;

  @ViewChild(BusinessPartnerSelectionComponent) businessPartnerSelectionComponent: BusinessPartnerSelectionComponent;



  constructor(
    private tradeInService: TradeInService,
    private router: Router,
    private toaster: ToastrService,
    private storageService: StorageService,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
    public store: Store<IAppState>
  ) {
    super(store);
   }
  get selectedBrands() {
    return this.brandList.filter(brand => brand.selected);

  }
  ngOnInit(): void {
    this.storageService.getTradeInCart().subscribe(cart => {
      if (cart) {
        this.tradeInCart = JSON.parse(cart);
      } else {
        this.tradeInCart = null;
      }
    });
    this.getFilterOptions();
    this.getProductsPromotionList();
    this.applyBrandFilter();
  }
  ngAfterViewInit() {
    this.permissionSubs$ = this.store.select(selectPermissions).subscribe((permission) => {
      const previousSelectedBusinessPartner = this.storageService.getTradeInBusinessPartner();
      if (this.hasTradeInBusinessPartner && !previousSelectedBusinessPartner) {
        this.businessPartnerSelectionComponent.openModal();
      }
    });

  }


  public searchText = new BehaviorSubject<string>('');
  public searchTradeInProduct$ = this.searchText.pipe(
    debounceTime(500),
    // distinctUntilChanged(),
    switchMap(search => {

      if (!search?.length) {
        return of({
          data: {
            count: 0,
            results: []
          }
        });
      }
      return this.tradeInService.searchTradeInProduct(search).pipe(
        catchError(error => {
          this.toaster.error('Error occurred during search. Please try again.');
          return of({
            data: {
              count: 0,
              results: []
            }
          });
        })
      );
    }),

    tap(res => {
      this.loader = false;
      this.config.totalItems = res?.data?.count;
      this.config.totalPages = Math.ceil(this.config.totalItems / this.config.itemsPerPage);
      this.config.to = this.config.currentPage * this.config.itemsPerPage;
      this.config.from = this.config.to - this.config.itemsPerPage + 1;
      return of({ ...res });
    })
  );

  public searchSubscription = this.searchTradeInProduct$.subscribe(
    res => {
      this.searchProducts = res?.data?.results ?? [];
    },
    error => {
      this.loader = false;
    }
  );

  messageAfterLandTradein(afterLandTradeinModal) {
    this.modalService.open(afterLandTradeinModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onClickFilter(triListFilterModal) {
    this.brandFilterModalReference = this.modalService.open(triListFilterModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-500 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });

    this.brandFilterModalReference.result.then((result) => {
      // this.selectedQuoteLine = null;
    }).catch(e => {

    });
  }

  aboutTradeIn(aboutTradeInModal) {
    this.modalService.open(aboutTradeInModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {

      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  openPromoProductDetailsModal(aboutPromoProductModal, promotionProductObj) {
    this.selectedPromotionProduct = promotionProductObj;
    this.modalService.open(aboutPromoProductModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-677 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  searchProduct(search: string) {
    this.loader = true;
    if (!search?.trim()?.length) {
      this.clearInput();
    } else {
      const paramsString = `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}&search=${search?.trim()}`;
      this.searchText.next(paramsString);
    }
  }
  handleSearch() {
    this.config.currentPage = 1;
    this.config.from = 1;
    this.config.to = 10;
    this.applyBrandFilter();
  }


  compareModelAndMemorySize(a, b) {
    // First, compare by group
    if (a.model < b.model) return 1;
    if (a.model > b.model) return -1;

    // If the groups are the same, compare by memory size
    const sizeOrder = { MB: 0, GB: 1, TB: 2 };
    const [c_A, sizeA, unitA] = a?.memory_size?.match(/(\d+)(\w+)/);
    const [c_B, sizeB, unitB] = b?.memory_size?.match(/(\d+)(\w+)/);

    if (parseInt(sizeA) * Math.pow(1024, sizeOrder[unitA]) < parseInt(sizeB) * Math.pow(1024, sizeOrder[unitB])) {
      return -1;
    }
    if (parseInt(sizeA) * Math.pow(1024, sizeOrder[unitA]) > parseInt(sizeB) * Math.pow(1024, sizeOrder[unitB])) {
      return 1;
    }

    return 0; // If group and memory size are equal
  }

  clearInput() {
    this.search = '';
    this.config.currentPage = 1;
    this.config.totalItems = 0;
    this.config.from = 1;
    this.config.to = 10;
    this.loader = false;
    this.searchText.next(this.search);
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.searchProduct(this.search)
  }
  handlePageClick(page) {
    this.config.currentPage = page;
    this.config.to = this.config.currentPage * this.config.itemsPerPage;
    this.config.from = this.config.to - this.config.itemsPerPage + 1;
    this.applyBrandFilter();
  }

  selectProduct(product) {
    let params = {}
    this.router.navigate(['/trade-in/product/', `${product?.code}`], {
      queryParams: params,
      queryParamsHandling: 'merge'
    });
  }

  goToCart() {
    if (!this.tradeInCart) {
      return;
    }
    this.router.navigate([`trade-in/cart-details`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  gotoDetails(product) {
    this.router.navigate([`trade-in/product/${product?.ti_family?.uuid}/${product?.brand?.code}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }

  deleteTradeInCart() {
    this.tradeInService.deleteTradeInCart(this.tradeInCart?.uuid).subscribe(res => {
      this.storageService.removeReComCart();
    })
  }

  getFilterOptions() {
    this.tradeInService.getBrandFilterOptions().subscribe(res => {
      this.brandList = res?.data?.brands?.map((brand) => ({ ...brand, selected: false })) ?? [];
    }, error => {
    })
  }
  getProductsPromotionList() {
    this.tradeInService.getProductsPromotions().subscribe(res => {
      this.productPromotionList = res?.data?.results ?? [];
    }, error => {
    })
  }
  closeBrandFilterModal() {
    this.brandFilterModalReference.close();
    this.clearBrandFilter();
  }
  clearBrandFilter() {
    this.brandList = this.brandList.map((brand) => ({ ...brand, selected: false }));
  }
  applyBrandFilter() {
    if (this.brandFilterModalReference) {
      this.brandFilterModalReference.close();
    }
    // this.brandFilterModalReference.close();
    const selectedBrands = this.brandList.filter(brand => brand.selected === true).map(brand => brand.code);

    let paramsString = `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}`;
    if (this.search?.length) {
      paramsString = paramsString.concat(`&search=${this.search}`);
    }
    if (selectedBrands?.length) {
      paramsString = paramsString.concat(`&brand=${selectedBrands.join()}`);
      // &brand=${selectedBrands.join()}
    }
    this.searchText.next(paramsString);

  }
  removeFromFilter(brandCode) {
    this.config.currentPage = 1;
    this.config.from = 1;
    this.config.to = 10;
    this.brandList = this.brandList.map(brand => {
      if (brand.code === brandCode) {
        return { ...brand, selected: false };
      }
      return brand;
    });
    this.applyBrandFilter();
  }
  ngOnDestroy() {
    if (this.permissionSubs$) {
      this.permissionSubs$.unsubscribe();
    }
  }
}
