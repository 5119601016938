<section class="bg-kpn-subtle-grey">
  <app-loader [isLoading]="isLoading"></app-loader>
  <div>
    <app-business-partner-selection [showEdit]="false"></app-business-partner-selection>
  </div>

  <section class="pt-4 pb-5">
    <div class="container">
      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
        <li class="back-breadcrumb-btn border-0">
          <a href="javascript:void(0)" class="blue-link" (click)="goTocustomersPage()">
            <i class="kphicon-uniE906"></i> <span class="fs-16 font-kpn-extended">Terug</span>
          </a>
        </li>
        <!-- <li class="home-btn ml-2 px-1 pb-1">
          <a routerLink="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                  fill="#939393" />
              </g>
            </svg>
          </a>
        </li> -->
        <!-- <li><a routerLink="/">Alle apparaten</a></li> -->
      </ul>

      <div class="pt-2">
        <!-- <app-steps [activeStep]="'overview'" [activeStep]="'customer_select'"></app-steps> -->
        <app-trade-in-steps  [activeStep]="'customer_select'"></app-trade-in-steps>

      </div>
      <!-- <app-steps *ngIf="cart" [activeStep]='activeStep' [completeStepOne]="'true'" [completeStepTwo]="'true'"></app-steps> -->

      <!-- New Design Start 1 -->
      <h2 *ngIf="tradeInBusinessPartner" class="tri-title-40 font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-3 mt-n3">
        {{ tradeInBusinessPartner === 'customer' ? 'Kies en vul gegevens aan'  : 'Bekijk en vul gegevens in' }}
      </h2>
      <div class="facts-box"  *ngIf="tradeInBusinessPartner">
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4">
          <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black letter-spacing-normal mb-3">Gegevens
            business partner </h3>

          <h4 class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-3"
            *ngIf="basicForm.controls.company_name?.value || basicForm.controls.address?.value">Adresgegevens</h4>

          <div class="bg-gray-light-2 tri-rounded-20 p-4 mb-4">
            <div class="font-kpn-extended">
              <ng-container *ngIf="basicForm.controls.company_name?.value as companyName">
                <span class="d-block">{{ companyName }}</span>
              </ng-container>

              <ng-container *ngIf="basicForm.controls.address?.value as address">
                <span *ngIf="address?.street || address?.house_number" class="d-block">
                  {{ address?.street }} {{ address?.house_number }}
                </span>
                <span *ngIf="address?.zipcode || address?.city" class="d-block">
                  {{ address?.zipcode }} {{ address?.city }}
                </span>
                <span *ngIf="address?.country" class="d-block">
                  {{ address?.country }}
                </span>
              </ng-container>
            </div>
            <div *ngIf="basicForm?.controls?.krn_number?.value || basicForm?.controls?.coc_number?.value">
              <hr class="">
              <p class="font-kpn-extended mb-0">
                <span class="d-block" *ngIf="basicForm?.controls?.krn_number?.value">KVK: {{
                  basicForm?.controls?.krn_number?.value }}</span>
                <span class="d-block" *ngIf="basicForm?.controls?.coc_number?.value">CKR: {{
                  basicForm?.controls?.coc_number?.value }}</span>
              </p>
            </div>
          </div>

          <h4 class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-2 pb-1 pt-1">
            Contactpersoon</h4>
          <div class="row mb-0">
            <div class="col-md-6">
              <div class="kpn-form-group">
                <label for="" class="field-label">Contactpersoon</label>
                <select name="" [(ngModel)]="selectedContactPerson" (ngModelChange)="onChangeContactPerson()"
                  class="form-control tri-form-control" id="">
                  <option disabled [ngValue]="null">{{'CUSTOMER.SELECT_CONTACT_PERSON' | transloco }}</option>
                  <option class="create-option" *ngIf="contactPersonAddPermission && tradeInBusinessPartner === 'own'" [ngValue]="newContactPerson">
                    {{'CUSTOMER.CREATE_CONTACT_PERSON' | transloco }} +
                  </option>
                  <option [ngValue]="item" *ngFor="let item of contact_persons; let i = index;">
                    <span>{{item?.initials}}&nbsp;</span>
                    <span>{{item?.surname_prefix}}&nbsp;</span>
                    <span>{{item?.last_name}}</span>
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <div class="pt-3" *ngIf="contactPersonAddPermission && selectedContactPerson && !selectedContactPerson?.uuid">
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="cv_letter" class="field-label mb-2">{{'SIGN_UP.INITIALS' | transloco}} <span class="text-danger"> * </span></label>
                      <input type="text" name="cv_letter" (blur)="onContactPersonInitialChange()"
                        [(ngModel)]="newContactPerson.initials" (ngModelChange)="onContactPersonInitialChange()"
                        [ngClass]="{ 'is-invalid': contactPersonCreateError?.initials || (feContactPersonCreateError?.initials) }"
                        class="form-control tri-form-control">
                      <div *ngIf="contactPersonCreateError?.initials" class="text-danger">
                        {{ contactPersonCreateError?.initials[0]}}
                      </div>
                      <div *ngIf="contactPersonCreateError?.initials?.message" class="text-danger">
                        {{ contactPersonCreateError?.initials?.message }}
                      </div>
                      <div *ngIf="feContactPersonCreateError?.initials" class="text-danger">
                        This field may not be blank.
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="cvo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                      <input type="text" name="cvo_name" (blur)="onContactPersonFirstNameChange()"
                        [ngClass]="{ 'is-invalid': contactPersonCreateError?.first_name || (feContactPersonCreateError?.first_name) }"
                        [(ngModel)]="newContactPerson.first_name" (ngModelChange)="onContactPersonFirstNameChange()"
                        class="form-control tri-form-control">
                      <div *ngIf="contactPersonCreateError?.first_name" class="text-danger">
                        {{ contactPersonCreateError?.first_name?.message || contactPersonCreateError?.first_name[0]
                        }}
                      </div>
                      <div *ngIf="feContactPersonCreateError?.first_name" class="text-danger">
                        This field may not be blank.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="snpfx" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }} </label>
                      <input type="text" name="snpfx" [(ngModel)]="newContactPerson.surname_prefix"
                        class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="cp_l_name" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}} <span class="text-danger"> * </span></label>
                      <input (blur)="onContactPersonLastNameChange()" type="text" name="cp_l_name"
                        [(ngModel)]="newContactPerson.last_name" (ngModelChange)="onContactPersonLastNameChange()"
                        [ngClass]="{ 'is-invalid': contactPersonCreateError?.last_name || feContactPersonCreateError?.last_name }"
                        class="form-control tri-form-control">
                      <div *ngIf="contactPersonCreateError?.last_name" class="text-danger">
                        {{ contactPersonCreateError?.last_name[0]}}
                      </div>
                      <div *ngIf="feContactPersonCreateError?.last_name " class="text-danger">
                        This field may not be blank.
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="c_phone" class="field-label">{{'SIGN_UP.PHONE_NUMBER' | transloco}}</label>
                      <input type="text" name="c_phone" maxlength="12"
                        [ngClass]="{ 'is-invalid': contactPersonCreateError?.mobile_phone_number }"
                        [(ngModel)]="newContactPerson.mobile_phone_number" class="form-control tri-form-control">
                      <div *ngIf="contactPersonCreateError?.mobile_phone_number" class="text-danger">
                        {{ contactPersonCreateError?.mobile_phone_number?.message ||
                        contactPersonCreateError?.mobile_phone_number[0]}}
                      </div>
                    </div>
                    <small class="text-muted">{{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'SIGN_UP.PATTERN' |
                      transloco}} +31612345678 {{'SIGN_UP.OR' | transloco}} 0612345678</small>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="c_email" class="field-label">{{'SIGN_UP.E_MAIL_ADDRESS' | transloco}} <span class="text-danger"> * </span></label>
                      <input (blur)="onContactPersonEmailChange()" type="email" name="c_email"
                        [ngClass]="{ 'is-invalid': contactPersonCreateError?.email || (feContactPersonCreateError?.email) }"
                        [(ngModel)]="newContactPerson.email" (ngModelChange)="onContactPersonEmailChange()"
                        class="form-control tri-form-control">
                      <div *ngIf="contactPersonCreateError?.email" class="text-danger">
                        {{ contactPersonCreateError?.email[0]}}
                      </div>
                      <div *ngIf="feContactPersonCreateError?.email" class="text-danger">
                        This field may not be blank.
                      </div>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-right mb-4 pt-1">
                  <button [disabled]="createContactPersonLoader" class="btn px-4 btn-primary mb-0 rounded-btn d-inline-flex align-items-center"
                    (click)="createContactPerson()">
                    <span class="spinner-border spinner-border-sm mr-2" *ngIf="createContactPersonLoader" role="status"
                      aria-hidden="true"></span> <span class="fs-22 mr-1">+</span> {{'CUSTOMER.CREATE' | transloco }}
                  </button>
                </div> -->
              </div>

              <div class="note-box note-box-red mb-2"
                *ngIf="!contactAddress?.length || (customer && !customer?.contact_addresses?.length) || isLoading">
                <span class="circle-hint-icon">i</span>
                <p class="body-text-1 mb-0">
                  {{'CUSTOMER.CONTACT_WEBSHOP_MANAGER_YOU_CANNOT_CONTINUE_ORDER' | transloco }}
                </p>
              </div>
            </div>
          </div>
          <!-- <div *ngIf="contactPersonAddPermission && selectedContactPerson && !selectedContactPerson?.uuid">
            <div class="row gutter-row-24 mb-3">
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="cv_letter" class="field-label">{{'SIGN_UP.INITIALS' | transloco}}</label>
                  <input type="text" name="cv_letter" [(ngModel)]="newContactPerson.initials"
                    [ngClass]="{ 'is-invalid': contactPersonCreateError?.initials }"
                    class="form-control tri-form-control">
                  <div *ngIf="contactPersonCreateError?.initials" class="text-danger">
                    {{ contactPersonCreateError?.initials[0]}}
                  </div>
                </div>
              </div>
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="cvo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                  <input type="text" name="cvo_name" [ngClass]="{ 'is-invalid': contactPersonCreateError?.first_name }"
                    [(ngModel)]="newContactPerson.first_name" class="form-control tri-form-control">
                  <div *ngIf="contactPersonCreateError?.first_name" class="text-danger">
                    {{ contactPersonCreateError?.first_name?.message || contactPersonCreateError?.first_name[0] }}
                  </div>
                </div>
              </div>
            </div>
            <div class="row gutter-row-24 mb-3">
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="snpfx" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                  <input type="text" name="snpfx" [(ngModel)]="newContactPerson.surname_prefix"
                    class="form-control tri-form-control">
                </div>
              </div>
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="cp_l_name" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                  <input type="text" name="cp_l_name" [(ngModel)]="newContactPerson.last_name"
                    [ngClass]="{ 'is-invalid': contactPersonCreateError?.last_name }"
                    class="form-control tri-form-control">
                  <div *ngIf="contactPersonCreateError?.last_name" class="text-danger">
                    {{ contactPersonCreateError?.last_name[0]}}
                  </div>
                </div>
              </div>
            </div>
            <div class="row mb-3 gutter-row-24">
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="c_phone" class="field-label">{{'SIGN_UP.PHONE_NUMBER' | transloco}}</label>
                  <input type="text" name="c_phone" maxlength="12"
                    [ngClass]="{ 'is-invalid': contactPersonCreateError?.mobile_phone_number }"
                    [(ngModel)]="newContactPerson.mobile_phone_number" class="form-control tri-form-control">
                  <div *ngIf="contactPersonCreateError?.mobile_phone_number" class="text-danger">
                    {{ contactPersonCreateError?.mobile_phone_number?.message ||
                    contactPersonCreateError?.mobile_phone_number[0]}}
                  </div>
                </div>
                <small class="text-muted">{{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'SIGN_UP.PATTERN' | transloco}}
                  +31612345678 {{'SIGN_UP.OR' | transloco}} 0612345678</small>
              </div>
            </div>
            <div class="row mb-3 gutter-row-24">
              <div class="col-md-6 gutter-col-24">
                <div class="kpn-form-group">
                  <label for="c_email" class="field-label">{{'SIGN_UP.E_MAIL_ADDRESS' | transloco}}</label>
                  <input type="email" name="c_email" [ngClass]="{ 'is-invalid': contactPersonCreateError?.email }"
                    [(ngModel)]="newContactPerson.email" class="form-control tri-form-control">
                  <div *ngIf="contactPersonCreateError?.email" class="text-danger">
                    {{ contactPersonCreateError?.email[0]}}
                  </div>
                </div>
              </div>
            </div>

            <div class="text-right mb-4 pt-1">
              <button [disabled]="createContactPersonLoader"
                class="btn px-4 btn-primary mb-0 rounded-btn d-inline-flex align-items-center"
                (click)="createContactPerson()">
                <span class="spinner-border spinner-border-sm mr-2" *ngIf="createContactPersonLoader" role="status"
                  aria-hidden="true"></span> <span class="fs-22 mr-1">+</span> {{'CUSTOMER.CREATE' | transloco }}
              </button>
            </div>
          </div> -->


          <div class="bg-gray-light-2 tri-rounded-20 p-4 mb-0 mt-3" *ngIf="selectedContactPerson?.uuid">
            <p class="font-kpn-extended mb-0">
              <span class="d-block">{{ selectedContactPerson?.initials }} {{selectedContactPerson?.surname_prefix }}
                {{selectedContactPerson?.last_name }}</span>
              <span class="d-block">{{selectedContactPerson?.email }}</span>
              <span class="d-block">{{selectedContactPerson?.mobile_phone_number }}</span>
            </p>
          </div>

          <div class="mt-4" *ngIf="tradeInBusinessPartner === 'own'">
            <h4 *ngIf="totalQtyInCart<=10" class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-2 pb-1 pt-1">
              {{'TRADE_CUSTOMER.CONTACT_ADDRESS' | transloco}}</h4>
            <h4 *ngIf="totalQtyInCart >10" class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-2 pb-1 pt-1">
              {{'TRADE_CUSTOMER.PICK_UP_ADDRESS' | transloco}}</h4>
            <div class="row" >
              <div class="col-md-12">
                <div class="mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input type="checkbox" [(ngModel)]="sameAsDeliveryAddress" (ngModelChange)="onChangeSameAsDeliveryAddress">
                      <span class="checkBoxCheckmark" ></span>
                    </div>
                    <div class="checkbox-label font-kpn-extended ml-2">
                      <span>Hetzelfde als bedrijfsadres</span>
                    </div>
                  </label>
                </div>
              </div>
            </div>
            <div class="row mb-3 gutter-row-24" *ngIf="sameAsDeliveryAddress === false">
              <div class="col-md-6 gutter-col-24">
                <!-- <div class="pt-2 mb-3">
                  <label class="checkbox-item d-flex align-items-center">
                    <div class="checkbox-btn checkbox-btn-outline">
                      <input type="checkbox"  [(ngModel)]="sameAsDeliveryAddress" (ngModelChange)="onChangeSameAsDeliveryAddress()">
                      <span class="checkBoxCheckmark"></span>
                    </div>
                    <div class="checkbox-label font-kpn-extended ml-2">
                      <span>Hetzelfde als bedrijfsadres</span>
                    </div>
                  </label>
                </div> -->
                <div class="kpn-form-group">
                  <label for="" class="field-label">{{'CUSTOMER.ADDRESS' | transloco }}</label>
                  <select name="" [(ngModel)]="selectedContactAddress"
                    (ngModelChange)="onChangeContactAddress($event)" class="form-control tri-form-control" id="">
                    <option [ngValue]="null" disabled>{{'CUSTOMER.SELECT_ADDRESS' | transloco }}</option>
                    <option class="create-option" *ngIf="hasContactAddressCreatePermission"
                      [ngValue]="shippingAddressCreate">{{'CUSTOMER.ADD_MANUALLY' | transloco }} +
                    </option>
                    <option *ngFor="let ship of contactAddress; trackBy:identify" [ngValue]="ship">
                      <span *ngIf="ship?.company_name">{{ship?.company_name}}&nbsp;</span>
                      <span>{{ship?.zip_code}}&nbsp;</span>
                      <span>{{ship?.house_number}}&nbsp;</span>
                      <span>{{ship?.house_number_extension}}&nbsp;</span>
                      <span>{{ship?.street}}&nbsp;</span>
                      <span>{{ship?.city}}&nbsp;</span>
                    </option>
                  </select>
                  <div class="note-box note-box-red mb-2" *ngIf="!contactAddress?.length">
                    <span class="circle-hint-icon">i</span>
                    <p class="body-text-1 mb-0">
                      {{'CUSTOMER.VOUCHER_NOT_CORRECT_NO_DELIVERY_ADDRESSES' | transloco }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div *ngIf="selectedContactAddress?.id && sameAsDeliveryAddress === false">
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }} <span
                        class="text-danger"> * </span></label>
                    <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                      [(ngModel)]="selectedContactAddress.company_name" class="form-control tri-form-control">
                      <div *ngIf="errors?.company_name" class="text-danger">
                        {{ errors?.company_name }}
                      </div>
                  </div>
                </div>
              </div>
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'SIGN_UP.INITIALS' | transloco}}</label>
                    <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                      [(ngModel)]="selectedContactAddress.initials" [ngClass]="{ 'is-invalid': errors?.initials }"
                      class="form-control tri-form-control">
                    <div *ngIf="errors?.initials" class="text-danger">
                      {{ errors?.initials }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                    <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                      [ngClass]="{ 'is-invalid': errors?.first_name }" [(ngModel)]="selectedContactAddress.first_name"
                      class="form-control tri-form-control">
                    <div *ngIf="errors?.first_name" class="text-danger">
                      {{ errors?.first_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                    <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                      [(ngModel)]="selectedContactAddress.surname_prefix"
                      [ngClass]="{ 'is-invalid': errors?.surname_prefix }" class="form-control tri-form-control">
                    <div *ngIf="errors?.surname_prefix" class="text-danger">
                      {{ errors?.surname_prefix }}
                    </div>
                  </div>
                </div>
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                    <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                      [(ngModel)]="selectedContactAddress.last_name" class="form-control tri-form-control" [ngClass]="{
                      'is-invalid': errors?.last_name }">
                    <div *ngIf="errors?.last_name" class="text-danger">
                      {{ errors?.last_name }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row g-4 mb-2">
                <div class="col-md-6">
                  <ng-select class="tri-ng-select" placeholder="Select Country"
                  [disabled]="!hasContactAddressUpdatePermission" [items]="country" bindLabel="name"
                    bindValue="country_code" [(ngModel)]="selectedContactAddress.country"
                    [ngModelOptions]="{standalone: true}" (change)="onSelectCountry($event, true)">
                  </ng-select>
                </div>
              </div>
              <div class="row mb-2"
                *ngIf="selectedContactAddress?.country && selectedContactAddress?.country !== NL_CODE">
                <div class="col-md-6">
                  <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                    <div class="form-group kpn-form-group">
                      <div
                        class="kpn-form-group handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                        [ngClass]="{'search-active' : searchFocused}">
                        <i class="search-icon kpn-ui-icon ui-search"></i>
                        <div class="ng-autocomplete">
                          <ng-autocomplete #auto class="" [disabled]="selectedContactAddress.country"
                            [data]="addressList"
                            [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                            [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                            (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                            (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                            [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                            [notFoundTemplate]="notFoundTemplate">
                          </ng-autocomplete>
                          <ng-template #itemTemplate let-item>
                            <div class="auto-template">
                              <span [innerHTML]="item.value"></span> &nbsp;
                              <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                            </div>
                          </ng-template>
                          <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                          </ng-template>
                        </div>
                        <span class="text-muted" *ngIf="!foreignAddressSearchError">
                          <small>{{'SIGN_UP.ADDRESS_SEARCH_LABEL' | transloco}}</small>
                        </span>
                        <span class="danger-text" *ngIf="foreignAddressSearchError">
                          <small>{{foreignAddressSearchError}}</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">Postcode <span class="text-danger"> * </span></label>
                    <input type="text" oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern"
                      mask="111100"
                      [disabled]="!hasContactAddressUpdatePermission || (!selectedContactAddress.country) || selectedCountry !== 'NL'"
                      [(ngModel)]="selectedContactAddress.zip_code" (keyup)="onAddressSearch()"
                      [ngClass]="{ 'is-invalid': errors?.zip_code || !selectedContactAddress.zip_code?.trim()?.length }"
                      class="form-control tri-form-control">
                    <div *ngIf="errors?.zip_code" class="text-danger">
                      {{ errors?.zip_code }}
                    </div>
                    <div *ngIf="!selectedContactAddress.zip_code?.trim()?.length" class="text-danger">
                      {{'SIGN_UP.ZIPCODE_IS_REQUIRED' | transloco}}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'SIGN_UP.HOUSE_NUMBER' | transloco}} <span
                        class="text-danger"> * </span></label>
                    <input type="text"
                      [disabled]="!hasContactAddressUpdatePermission || (!selectedContactAddress.country) || selectedCountry !== 'NL'"
                      [(ngModel)]="selectedContactAddress.house_number" class="form-control tri-form-control"
                      (keyup)="onAddressSearch()"
                      [ngClass]="{ 'is-invalid': errors?.house_number || !selectedContactAddress?.house_number?.toString()?.trim()?.length }">
                    <div *ngIf="errors?.house_number" class="text-danger">
                      {{ errors?.house_number }}
                    </div>
                    <div *ngIf="!selectedContactAddress?.house_number?.toString()?.trim()?.length"
                      class="text-danger">
                      {{'SIGN_UP.HOUSE_NUMBER_REQUIRED' | transloco}}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                    <input type="text"
                      [disabled]="!hasContactAddressUpdatePermission || (!selectedContactAddress.country) || selectedCountry !== 'NL'"
                      [(ngModel)]="selectedContactAddress.house_number_extension"
                      class="form-control tri-form-control" (keyup)="onAddressSearch()"
                      [ngClass]="{ 'is-invalid': errors?.house_number_extension }">
                    <div *ngIf="errors?.house_number_extension" class="text-danger">
                      {{ errors?.house_number_extension }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 gutter-row-24">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'SIGN_UP.STREET' | transloco}} <span class="text-danger"> *
                      </span> </label>
                    <div class="field-with-spinner">
                      <input type="text"
                        [disabled]="!hasContactAddressUpdatePermission || (selectedContactAddress.country && addressFound.street)"
                        [ngClass]="{ 'is-invalid': errors?.street || !selectedContactAddress.street?.trim()?.length }"
                        [(ngModel)]="selectedContactAddress.street" class="form-control tri-form-control">
                      <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                        role="status">
                      </div>
                      <div *ngIf="errors?.street" class="text-danger">
                        {{ errors?.street }}
                      </div>
                      <div *ngIf="!selectedContactAddress.street?.trim()?.length" class="text-danger">
                        {{'CUSTOMER.STREET_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 gutter-row-24">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'SIGN_UP.CITY' | transloco}} <span class="text-danger"> *
                      </span></label>
                    <div class="field-with-spinner">
                      <input type="text"
                        [disabled]="!hasContactAddressUpdatePermission || (selectedContactAddress.country && addressFound.city)"
                        [(ngModel)]="selectedContactAddress.city"
                        [ngClass]="{ 'is-invalid': errors?.city || !selectedContactAddress.city?.trim()?.length }"
                        class="form-control tri-form-control">
                      <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                        role="status">
                      </div>
                      <div *ngIf="errors?.city" class="text-danger">
                        {{ errors?.city }}
                      </div>
                      <div *ngIf="!selectedContactAddress.city?.trim()?.length" class="text-danger">
                        {{'CUSTOMER.CITY_NAME_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                <span class="circle-hint-icon">i</span>
                <p class="body-text-1 mb-0">
                  {{addressCombinationMsg}}
                </p>
              </div>
              <!-- <div class="text-right mb-4 pt-1" *ngIf="hasContactAddressUpdatePermission">
                <button [disabled]="
                createAddressLoader ||
                cart?.block_delivery_address ||
                !selectedContactAddress?.city?.trim()?.length ||
                !selectedContactAddress?.street?.trim()?.length||
                !selectedContactAddress?.zip_code?.trim()?.length ||
                !selectedContactAddress?.house_number?.toString()?.trim()?.length ||
                !selectedContactAddress?.company_name?.trim()?.length" class="btn px-4 btn-primary mb-0 rounded-btn"
                  (click)="updateContactAddress()">
                  <span class="spinner-border spinner-border-sm mr-2" *ngIf="createAddressLoader" role="status"
                    aria-hidden="true"></span> Update
                </button>
              </div> -->
            </div>
            <div *ngIf="selectedContactAddress && !selectedContactAddress?.id && sameAsDeliveryAddress === false">
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="c_name" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }} <span
                        class="text-danger"> * </span> </label>
                    <input type="text" name="c_name" (blur)="onAddressCompanyChange()"
                      [(ngModel)]="selectedContactAddress.company_name" (ngModelChange)="onAddressCompanyChange()"
                      class="form-control tri-form-control"
                      [ngClass]="{ 'is-invalid': addressCreateOrUpdateError?.company_name }">
                    <div *ngIf="addressCreateOrUpdateError?.company_name" class="text-danger">
                      {{'CUSTOMER.COMPANY_NAME_IS_REQUIRED' | transloco }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="v_letter" class="field-label">{{'SIGN_UP.INITIALS' | transloco}}</label>
                    <input type="text" name="v_letter" [(ngModel)]="selectedContactAddress.initials"
                      class="form-control tri-form-control">
                  </div>
                </div>
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="vo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                    <input type="text" name="vo_name" [(ngModel)]="selectedContactAddress.first_name"
                      class="form-control tri-form-control">
                  </div>
                </div>
              </div>
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="sp" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                    <input type="text" name="sp" [(ngModel)]="selectedContactAddress.surname_prefix"
                      class="form-control tri-form-control">
                  </div>
                </div>
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="l_name" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                    <input type="text" name="l_name" [(ngModel)]="selectedContactAddress.last_name"
                      class="form-control tri-form-control">
                  </div>
                </div>
              </div>
              <div class="row g-4 mb-3">
                <div class="col-md-12">
                  <div class="kpn-form-group">
                    <label class="field-label">Select Country <span class="text-danger"> * </span></label>
                    <ng-select class="tri-ng-select" placeholder="Select Country" [items]="country" bindLabel="name"
                      bindValue="country_code" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}"
                      (change)="onSelectCountry($event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row mb-2" *ngIf="selectedCountry && selectedCountry !== NL_CODE">
                <div class="col-12">
                  <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                    <div class="form-group kpn-form-group">
                      <label class="field-label">Zoeken</label>
                      <div
                        class="kpn-form-group tri-autocomplete-form icon-right handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                        [ngClass]="{'search-active' : searchFocused}">
                        <i class="search-icon kpn-ui-icon ui-search"></i>
                        <div class="ng-autocomplete">
                          <ng-autocomplete #auto class="" [data]="addressList"
                            [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                            [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                            (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                            (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                            [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                            [notFoundTemplate]="notFoundTemplate">
                          </ng-autocomplete>
                          <ng-template #itemTemplate let-item>
                            <div class="auto-template">
                              <span [innerHTML]="item.value"></span> &nbsp;
                              <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                            </div>
                          </ng-template>
                          <ng-template #notFoundTemplate let-notFound>
                            <div [innerHTML]="notFound"></div>
                          </ng-template>
                        </div>
                        <span class="text-muted" *ngIf="!foreignAddressSearchError">
                          <small> {{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                        </span>
                        <span class="danger-text" *ngIf="foreignAddressSearchError">
                          <small>{{foreignAddressSearchError}}</small>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row gutter-row-24 mb-3">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="p_code" class="field-label">Postcode <span class="text-danger"> * </span></label>
                    <input [disabled]="(selectedCountry !== 'NL')" type="text" name="p_code"
                      oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern" mask="111100"
                      [(ngModel)]="selectedContactAddress.zip_code" (ngModelChange)="onAddressPostCodeChange()"
                      (blur)="onAddressPostCodeChange()" (keyup)="onAddressSearch()"
                      [ngClass]="{ 'is-invalid': errors?.zip_code || addressCreateOrUpdateError.zip_code }"
                      class="form-control tri-form-control">
                    <div *ngIf="errors?.zip_code" class="text-danger">
                      {{ errors?.zip_code }}
                    </div>
                    <div *ngIf="addressCreateOrUpdateError.zip_code" class="text-danger">
                      {{'CUSTOMER.POST_CODE_IS_REQUIRED' | transloco }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="h_number" class="field-label">{{'SIGN_UP.HOUSE_NUMBER' | transloco}} <span
                        class="text-danger"> * </span></label>
                    <input type="text" [disabled]="(selectedCountry !== 'NL')" name="h_number"
                      [(ngModel)]="selectedContactAddress.house_number" (ngModelChange)="onAddressHouseNoChange()"
                      class="form-control tri-form-control" (keyup)="onAddressSearch()"
                      (blur)="onAddressHouseNoChange()"
                      [ngClass]="{ 'is-invalid': errors?.house_number || addressCreateOrUpdateError?.house_number }">
                    <div *ngIf="errors?.house_number" class="text-danger">
                      {{ errors?.house_number }}
                    </div>
                    <div *ngIf="addressCreateOrUpdateError?.house_number" class="text-danger">
                      {{'CUSTOMER.HOUSE_NUMBER_IS_REQUIRED' | transloco }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="hext" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                    <input type="text" name="hext" [(ngModel)]="selectedContactAddress.house_number_extension"
                      class="form-control tri-form-control" (keyup)="onAddressSearch()"
                      [ngClass]="{ 'is-invalid': errors?.house_number_extension }">
                    <div *ngIf="errors?.house_number_extension" class="text-danger">
                      {{ errors?.house_number_extension }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 gutter-row-24">
                <div class="col gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="street_n" class="field-label">{{'SIGN_UP.STREET' | transloco}} <span
                        class="text-danger"> * </span></label>
                    <div class="field-with-spinner">
                      <input type="text" (blur)="onAddressStreetChange()"
                        [disabled]="(selectedCountry && addressFound.street)" name="street_n"
                        [ngClass]="{ 'is-invalid': errors?.street || addressCreateOrUpdateError?.street}"
                        [(ngModel)]="selectedContactAddress.street" (ngModelChange)="onAddressStreetChange()"
                        class="form-control tri-form-control">
                      <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                        role="status">
                      </div>
                      <div *ngIf="errors?.street" class="text-danger">
                        {{ errors?.street }}
                      </div>
                      <div *ngIf="addressCreateOrUpdateError?.street" class="text-danger">
                        {{'CUSTOMER.STREET_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mb-3 gutter-row-24">
                <div class="col-md-6 gutter-col-24">
                  <div class="kpn-form-group">
                    <label for="city_n" class="field-label">{{'SIGN_UP.CITY' | transloco}} <span class="text-danger">
                        * </span></label>
                    <div class="field-with-spinner">
                      <input [disabled]="(selectedCountry && addressFound.city)" (blur)="onAddressCityChange()"
                        type="text" name="city_n" [(ngModel)]="selectedContactAddress.city"
                        (ngModelChange)="onAddressCityChange()"
                        [ngClass]="{ 'is-invalid': errors?.city || addressCreateOrUpdateError?.city }"
                        class="form-control tri-form-control">
                      <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                        role="status">
                      </div>
                      <div *ngIf="errors?.city" class="text-danger">
                        {{ errors?.city }}
                      </div>
                      <div *ngIf="addressCreateOrUpdateError?.city" class="text-danger">
                        {{'CUSTOMER.CITY_NAME_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                <span class="circle-hint-icon">i</span>
                <p class="body-text-1 mb-0">
                  {{addressCombinationMsg}}
                </p>
              </div>
              <div class="row" *ngIf="(sameAsDeliveryAddress === false) && (!selectedContactAddress?.id) ">
                <div class="col-md-12">
                  <div class="mb-3">
                    <label class="checkbox-item d-flex align-items-center">
                      <div class="checkbox-btn checkbox-btn-outline">
                        <input [disabled]="
                        !selectedContactAddress?.city?.trim()?.length ||
                        !selectedContactAddress?.street?.trim()?.length||
                        !selectedContactAddress?.zip_code?.trim()?.length ||
                        !selectedContactAddress?.house_number?.trim()?.length ||
                        !selectedContactAddress?.company_name?.trim()?.length" type="checkbox" [(ngModel)]="saveAsNewAddress">
                        <span class="checkBoxCheckmark"></span>
                      </div>
                      <div class="checkbox-label font-kpn-extended ml-2">
                        <span>Sla op als nieuw adres</span>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
              <!-- <div class="text-right mb-4 pt-4">
                <button [disabled]="
                createAddressLoader ||
                !selectedContactAddress?.city?.trim()?.length ||
                !selectedContactAddress?.street?.trim()?.length||
                !selectedContactAddress?.zip_code?.trim()?.length ||
                !selectedContactAddress?.house_number?.trim()?.length ||
                !selectedContactAddress?.company_name?.trim()?.length
                " class="btn px-4 btn-primary mb-0 rounded-btn" (click)="createContactAddress()">
                  <span class="spinner-border spinner-border-sm mr-2" *ngIf="createAddressLoader" role="status"
                    aria-hidden="true"></span> {{'CUSTOMER.CREATE' | transloco }}
                </button>
              </div> -->
            </div>
          </div>
          <div class="note-box note-box-red mb-2"
            *ngIf="(isLoading === false) && (customer && !contactAddress?.length) && (tradeInBusinessPartner === 'own') && (sameAsDeliveryAddress)">
            <span class="circle-hint-icon">i</span>
            <p class="body-text-1 mb-0">
              {{'CUSTOMER.CONTACT_WEBSHOP_MANAGER_YOU_CANNOT_CONTINUE_ORDER' | transloco }}
            </p>
          </div>

        </div>
      </div>


      <!-- New Design Start 3 -->
      <!-- <h2 class="tri-title-40 font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-3 mt-n3">Stuur je producten naar ons op</h2>
      <div class="facts-box">
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-32 mb-4">
          <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black letter-spacing-normal mb-3 pb-1">Verzendmethode </h3>
          <div class="radio-check-box-type mb-3">
            <input type="radio" id="playing-for-1" name="playing-for" >
              <label for="playing-for-1" class="d-flex w-100 font-kpn-extended fs-16 mb-0">
                <span class="radio-bullet mt-1"></span>
                <div>
                  Verzenden
                  <span class="d-block grey-text">Stuur je producten naar ons op als je er minder dan 10 hebt. </span>
                </div>
              </label>
          </div>
          <div class="radio-check-box-type">
            <input type="radio" disabled id="playing-for-2" name="playing-for" >
              <label for="playing-for-2" class="d-flex w-100 font-kpn-extended fs-16 mb-0">
                <span class="radio-bullet mt-1"></span>
                <div>
                  Ophalen
                  <span class="d-block grey-text">Bij 10 of meer producten halen wij ze bij je op.</span>
                </div>
              </label>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 p-32 mb-4">
          <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black letter-spacing-normal mb-3 pb-1">Verzendinstructies </h3>
          <h4 class="font-kpn-extended kpn-extended-bold letter-spacing-normal fs-16 mb-3">In het kort</h4>
          <div class="mb-4">
            <div class="d-flex shipping-instruction-step">
              <div class="instruction-step mr-3">
                <span class="font-kpn-extended fs-12">1</span>
              </div>
              <p class="font-kpn-extended mb-0 pt-1">Wij ontvangen in totaal 9 producten van je</p>
            </div>
            <div class="d-flex shipping-instruction-step">
              <div class="instruction-step mr-3">
                <span class="font-kpn-extended fs-12">2</span>
              </div>
              <p class="font-kpn-extended mb-0 pt-21">Van Recommerce ontvang je de verzendinstructies en een verzendlabel per e-mail </p>
            </div>
            <div class="d-flex shipping-instruction-step">
              <div class="instruction-step mr-3">
                <span class="font-kpn-extended fs-12">3</span>
              </div>
              <p class="font-kpn-extended mb-0 pt-21">Stuur je producten binnen 10 werkdagen naar ons op</p>
            </div>
          </div>

          <p class="font-kpn-extended mb-0 pt-21">Heb je nog vragen? Bekijk onze <a href="" class="blue-link">FAQ-pagina</a>.</p>
        </div>
      </div> -->


      <!-- New Design Start 4 -->
      <div class="facts-box" *ngIf="tradeInBusinessPartner === 'customer'">
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-4 mb-4">
          <h3 class="green-text tri-title-32 font-kpn-extended kpn-extended-black mb-3">Klantgegevens </h3>

          <div>
            <h4 class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-3">Adresgegevens</h4>
            <div class="row mb-3">
              <div class="col-md-12">
                <div class="row gutter-row-15">
                  <div class="col-md-6 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Bedrijfsnaam <span class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Bedrijfsnaam"
                        [(ngModel)]="businessCustomerAddress.company_name" />
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-6 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Land <span class="text-danger">*</span></label>
                      <ng-select class="tri-ng-select" [placeholder]="'SIGN_UP.SELECT_COUNTRY' | transloco"
                        [items]="country" bindLabel="name" bindValue="country_code"
                        [(ngModel)]="businessCustomerSelectedCountry" [ngModelOptions]="{standalone: true}"
                        (change)="onSelectBusinessCustomerCountry($event)">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row" *ngIf="businessCustomerSelectedCountry && businessCustomerSelectedCountry !== NL_CODE">
                  <div class="col-12">
                    <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                      <div class="form-group kpn-form-group">
                        <div
                          class="kpn-form-group tri-autocomplete-form handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                          [ngClass]="{'search-active' : searchFocused}">
                          <i class="search-icon kpn-ui-icon ui-search"></i>
                          <div class="ng-autocomplete">
                            <ng-autocomplete #businessCustomerWithoutNlAddressSearchDropdown class=""
                              [data]="businessCustomerAddressList"
                              [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                              [searchKeyword]="keyword" [(ngModel)]='businessCustomerAddressCreateValue'
                              (selected)='onSelectedBusinessCustomerAddress($event)'
                              (inputChanged)='businessCustomerSearchAddressForeign($event)' (focusin)='onFocused()'
                              (focusout)="onFocusedOut()" [itemTemplate]="businessCustomerItemTemplate"
                              [isLoading]="isLoading" (inputCleared)="businessCustomerAddressSearchClear()"
                              [debounceTime]="600" [notFoundTemplate]="businessCustomerAddressNotFoundTemplate">
                            </ng-autocomplete>

                            <ng-template #businessCustomerItemTemplate let-item>
                              <div class="auto-template">
                                <span [innerHTML]="item.value"></span> &nbsp;
                                <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                              </div>
                            </ng-template>

                            <ng-template #businessCustomerAddressNotFoundTemplate let-notFoundAddressOfBusinessCustomer>
                              <div [innerHTML]="notFoundAddressOfBusinessCustomer"></div>
                            </ng-template>
                          </div>
                          <span class="text-muted" *ngIf="!businessCustomerForeignAddressSearchError">
                            <small>{{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                          </span>
                          <span class="danger-text" *ngIf="businessCustomerForeignAddressSearchError">
                            <small>{{businessCustomerForeignAddressSearchError}}</small>
                          </span>

                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-15">
                  <div class="col-md-4 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Postcode <span class="text-danger">*</span></label>
                      <input type="text" [disabled]="(businessCustomerSelectedCountry !== 'NL')"
                        [patterns]="zipCodePattern" mask="111100" class="form-control tri-form-control"
                        placeholder="Postcode" [(ngModel)]="businessCustomerAddress.zip_code"
                        (keyup)="onBusinessCustomerAddressSearch()" />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Huisnummer <span class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Huisnummer"
                        [disabled]="(businessCustomerSelectedCountry !== 'NL')"
                        [(ngModel)]="businessCustomerAddress.house_number"
                        (keyup)="onBusinessCustomerAddressSearch()" />
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Toevoeging</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Toevoeging"
                        [disabled]="(businessCustomerSelectedCountry !== 'NL')"
                        [(ngModel)]="businessCustomerAddress.house_number_extension"
                        (keyup)="onBusinessCustomerAddressSearch()" [disabled]="!businessCustomerSelectedCountry" />
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-15">
                  <div class="col-md-4 mb-3 gutter-col-15">
                    <div class="kpn-form-group field-with-spinner">
                      <label for="" class="field-label pb-10 mb-0">Straat <span class="text-danger">*</span></label>
                      <input disabled type="text" class="form-control tri-form-control" placeholder="Straat"
                        [(ngModel)]="businessCustomerAddress.street"
                        [disabled]="(businessCustomerSelectedCountry && businessCustomerAddressFound.street)" />
                      <div *ngIf="isAddressFetching"
                        class="spinner-border spinner-border-sm business-customer-field-spinner" role="status">
                      </div>
                    </div>
                  </div>
                  <div class="col-md-4 mb-3 gutter-col-15">
                    <div class="kpn-form-group field-with-spinner">
                      <label for="" class="field-label pb-10 mb-0">Plaats <span class="text-danger">*</span></label>
                      <input disabled type="text" class="form-control tri-form-control" placeholder="Plaats"
                        [(ngModel)]="businessCustomerAddress.city"
                        [disabled]="(businessCustomerSelectedCountry && businessCustomerAddressFound.city)" />
                      <div *ngIf="isAddressFetching"
                        class="spinner-border spinner-border-sm business-customer-field-spinner" role="status">
                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div class="col-12">
                <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                  <span class="circle-hint-icon">i</span>
                  <p class="body-text-1 mb-0">
                    {{addressCombinationMsg}}
                  </p>
                </div>
              </div>
            </div>

            <h4 class="font-kpn-extended kpn-extended-black letter-spacing-normal green-text mb-3">Contactpersoon</h4>
            <div class="row">
              <div class="col-md-12">
                <div class="row gutter-row-15">
                  <div class="col-md-6 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Voorletter <span class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Voorletter"
                        [(ngModel)]="businessCustomerContactPerson.initials" />
                    </div>
                  </div>

                  <div class="col-md-6 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Voornaam <span class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Voornaam"
                        [(ngModel)]="businessCustomerContactPerson.first_name" />
                    </div>
                  </div>

                  <div class="col-md-6 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Tussenvoegsel</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Tussenvoegsel"
                        [(ngModel)]="businessCustomerContactPerson.surname_prefix" />
                    </div>
                  </div>

                  <div class="col-md-6 mb-3 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Achternaam <span class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="Achternaam"
                        [(ngModel)]="businessCustomerContactPerson.last_name" />
                    </div>
                  </div>

                  <div class="col-md-6 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">E-mailadres <span class="text-danger">*</span></label>
                      <input type="text" class="form-control tri-form-control" placeholder="E-mailadres"
                        [(ngModel)]="businessCustomerContactPerson.email" (ngModelChange)="onEmailChangeBusinessCustomerContactPerson()" />
                        <div *ngIf="businessCustomerContactPersonCreateError.email" class="text-danger">
                          {{'TRADE_CUSTOMER.PLEASE_ENTER_A_VALID_EMAIL_ADDRESS' | transloco}}
                        </div>
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-15">
                    <div class="kpn-form-group">
                      <label for="" class="field-label pb-10 mb-0">Telefoonnummer</label>
                      <input type="text" class="form-control tri-form-control" placeholder="Telefoonnummer"
                        [(ngModel)]="businessCustomerContactPerson.mobile_phone_number" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!-- Normal user  -->
      <div>
        <h2 *ngIf="!tradeInBusinessPartner" class="font-kpn-bold tri-title-40 green-text mb-3 mt-n3">Gegevens</h2>
        <div class="facts-box">
          <div *ngIf="!tradeInBusinessPartner" class="mb-4">
            <div class="bg-white tri-shadow-xl tri-rounded-20 p-32 mt-4">
              <h3 class="green-text tri-title-32 font-kpn-bold mb-3">{{'CUSTOMER.ORGANIZATIONAL_DATA' | transloco }}
              </h3>
              <form [formGroup]="basicForm">
                <div class="row mb-5">
                  <div class="col-md-12">
                    <div class="row mb-3 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }}</label>
                          <input type="text" formControlName="company_name" class="form-control tri-form-control" />
                        </div>
                      </div>
                    </div>
                    <div class="row gutter-row-24 mb-3">
                      <div class="col-md-6 gutter-col-24" [hidden]="!basicForm.controls.coc_number.value">
                        <div class="kpn-form-group">
                          <label for="coc_number" class="field-label">KVK-{{'CUSTOMER.NUMBER' | transloco }}</label>
                          <input type="text" name="coc_number" formControlName="coc_number"
                            class="form-control tri-form-control" />
                        </div>
                      </div>
                      <div class="col-md-6 gutter-col-24" [hidden]="!basicForm.controls.krn_number.value">
                        <div class="kpn-form-group">
                          <label for="krn_number" class="field-label">CKR-{{'CUSTOMER.NUMBER' | transloco }}
                            <span>({{'CUSTOMER.OPTIONAL' | transloco}})</span></label>
                          <input type="text" name="krn_number" formControlName="krn_number"
                            class="form-control tri-form-control" />
                        </div>
                      </div>
                    </div>
                    <div formGroupName="address">
                      <div class="row gutter-row-24 mb-3">
                        <div class="col-md-6 gutter-col-24">
                          <div class="kpn-form-group">
                            <label for="zipcode" class="field-label">Postcode</label>
                            <input type="text" name="zipcode" [patterns]="zipCodePattern" mask="111100"
                              class="form-control tri-form-control" formControlName="zipcode" placeholder="1234AB">
                          </div>
                        </div>
                        <div class="col-md-3 gutter-col-24">
                          <div class="kpn-form-group">
                            <label for="house_number" class="field-label">{{'SIGN_UP.HOUSE_NUMBER' | transloco}}</label>
                            <input type="text" name="house_number" class="form-control tri-form-control"
                              formControlName="house_number" placeholder="1234AB">
                          </div>
                        </div>
                        <div class="col-md-3 gutter-col-24">
                          <div class="kpn-form-group">
                            <label for="house_number_ext" class="field-label">{{'CUSTOMER.ADDITION' | transloco
                              }}</label>
                            <input type="text" name="house_number_ext" class="form-control tri-form-control"
                              formControlName="house_number_ext">
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3 gutter-row-24">
                        <div class="col-md-6 gutter-col-24">
                          <div class="kpn-form-group">
                            <label for="street" class="field-label">{{'SIGN_UP.STREET' | transloco}}</label>
                            <input type="text" name="street" formControlName="street"
                              class="form-control tri-form-control">
                          </div>
                        </div>
                      </div>
                      <div class="row mb-3 gutter-row-24">
                        <div class="col-md-6 gutter-col-24">
                          <div class="kpn-form-group">
                            <label for="city" class="field-label">{{'SIGN_UP.CITY' | transloco}}</label>
                            <input type="text" name="city" formControlName="city" class="form-control tri-form-control">
                          </div>
                        </div>
                      </div>
                      <!-- <div class="pt-2">
                        <label class="checkbox-item d-flex align-items-center">
                          <div class="checkbox-btn checkbox-btn-outline">
                            <input type="checkbox">
                            <span class="checkBoxCheckmark"></span>
                          </div>
                          <div class="checkbox-label ml-2">
                            <span>Afleveradres wijkt af van organisatie gegevens </span>
                          </div>
                        </label>
                      </div> -->
                    </div>
                  </div>
                </div>
              </form>
              <div class="row" *ngIf="customer">
                <div class="col-md-6">
                  <div class="note-box note-box-red mb-2" *ngIf="!customer?.contact_addresses?.length">
                    <span class="circle-hint-icon">i</span>
                    <p class="body-text-1 mb-0">
                      {{'CUSTOMER.NO_BILLING_ADDRESS' | transloco }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white tri-shadow-xl tri-rounded-20 p-32 mt-4">
              <h3 *ngIf="totalQtyInCart<=10" class="green-text tri-title-32 font-kpn-bold mb-3">
                {{'TRADE_CUSTOMER.CONTACT_ADDRESS' | transloco}}</h3>
              <h3 *ngIf="totalQtyInCart >10" class="green-text tri-title-32 font-kpn-bold mb-3">
                {{'TRADE_CUSTOMER.PICK_UP_ADDRESS' | transloco}}</h3>
               <div class="row" >
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="checkbox-item d-flex align-items-center">
                        <div class="checkbox-btn checkbox-btn-outline">
                          <input type="checkbox" [(ngModel)]="sameAsDeliveryAddress" (ngModelChange)="onChangeSameAsDeliveryAddress">
                          <span class="checkBoxCheckmark" ></span>
                        </div>
                        <div class="checkbox-label font-kpn-extended ml-2">
                          <span>Hetzelfde als bedrijfsadres</span>
                        </div>
                      </label>
                    </div>
                  </div>
               </div>
              <div class="row mb-3 gutter-row-24">
                <div class="col-md-6 gutter-col-24"  *ngIf="sameAsDeliveryAddress === false">
                  <!-- <div class="pt-2 mb-3">
                    <label class="checkbox-item d-flex align-items-center">
                      <div class="checkbox-btn checkbox-btn-outline">
                        <input type="checkbox">
                        <span class="checkBoxCheckmark"></span>
                      </div>
                      <div class="checkbox-label font-kpn-extended ml-2">
                        <span>Hetzelfde als bedrijfsadres</span>
                      </div>
                    </label>
                  </div>  -->
                  <div class="kpn-form-group">
                    <label for="" class="field-label">{{'CUSTOMER.ADDRESS' | transloco }}</label>
                    <select name="" [(ngModel)]="selectedContactAddress"
                      (ngModelChange)="onChangeContactAddress($event)" class="form-control tri-form-control" id="">
                      <option [ngValue]="null" disabled>{{'CUSTOMER.SELECT_ADDRESS' | transloco }}</option>
                      <option class="create-option" *ngIf="hasContactAddressCreatePermission"
                        [ngValue]="shippingAddressCreate" >{{'CUSTOMER.ADD_MANUALLY' | transloco }} +
                      </option>
                      <option *ngFor="let ship of contactAddress; trackBy:identify" [ngValue]="ship">
                        <span *ngIf="ship?.company_name">{{ship?.company_name}}&nbsp;</span>
                        <span>{{ship?.zip_code}}&nbsp;</span>
                        <span>{{ship?.house_number}}&nbsp;</span>
                        <span>{{ship?.house_number_extension}}&nbsp;</span>
                        <span>{{ship?.street}}&nbsp;</span>
                        <span>{{ship?.city}}&nbsp;</span>
                      </option>
                    </select>
                    <div class="note-box note-box-red mb-2" *ngIf="!contactAddress?.length">
                      <span class="circle-hint-icon">i</span>
                      <p class="body-text-1 mb-0">
                        {{'CUSTOMER.VOUCHER_NOT_CORRECT_NO_DELIVERY_ADDRESSES' | transloco }}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedContactAddress?.id && sameAsDeliveryAddress === false">
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }} <span
                          class="text-danger"> * </span></label>
                      <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                        [(ngModel)]="selectedContactAddress.company_name" class="form-control tri-form-control">
                        <div *ngIf="errors?.company_name" class="text-danger">
                          {{ errors?.company_name }}
                        </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.INITIALS' | transloco}}</label>
                      <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                        [(ngModel)]="selectedContactAddress.initials" [ngClass]="{ 'is-invalid': errors?.initials }"
                        class="form-control tri-form-control">
                      <div *ngIf="errors?.initials" class="text-danger">
                        {{ errors?.initials }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                      <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                        [ngClass]="{ 'is-invalid': errors?.first_name }" [(ngModel)]="selectedContactAddress.first_name"
                        class="form-control tri-form-control">
                      <div *ngIf="errors?.first_name" class="text-danger">
                        {{ errors?.first_name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                      <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                        [(ngModel)]="selectedContactAddress.surname_prefix"
                        [ngClass]="{ 'is-invalid': errors?.surname_prefix }" class="form-control tri-form-control">
                      <div *ngIf="errors?.surname_prefix" class="text-danger">
                        {{ errors?.surname_prefix }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                      <input type="text" [disabled]="!hasContactAddressUpdatePermission"
                        [(ngModel)]="selectedContactAddress.last_name" class="form-control tri-form-control" [ngClass]="{
                        'is-invalid': errors?.last_name }">
                      <div *ngIf="errors?.last_name" class="text-danger">
                        {{ errors?.last_name }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row g-4 mb-2">
                  <div class="col-md-6">
                    <label class="field-label">Select Country <span class="text-danger"> * </span></label>
                    <ng-select class="tri-ng-select" placeholder="Select Country"
                    [disabled]="!hasContactAddressUpdatePermission" [items]="country" bindLabel="name"
                      bindValue="country_code" [(ngModel)]="selectedContactAddress.country"
                      [ngModelOptions]="{standalone: true}" (change)="onSelectCountry($event, true)">
                    </ng-select>
                  </div>
                </div>
                <div class="row mb-2"
                  *ngIf="selectedContactAddress?.country && selectedContactAddress?.country !== NL_CODE">
                  <div class="col-md-6">
                    <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                      <div class="form-group kpn-form-group">
                        <div
                          class="kpn-form-group handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                          [ngClass]="{'search-active' : searchFocused}">
                          <i class="search-icon kpn-ui-icon ui-search"></i>
                          <div class="ng-autocomplete">
                            <ng-autocomplete #auto class="" [disabled]="selectedContactAddress.country"
                              [data]="addressList"
                              [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                              [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                              (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                              (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                              [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                              [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                              <div class="auto-template">
                                <span [innerHTML]="item.value"></span> &nbsp;
                                <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                              </div>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                              <div [innerHTML]="notFound"></div>
                            </ng-template>
                          </div>
                          <span class="text-muted" *ngIf="!foreignAddressSearchError">
                            <small>{{'SIGN_UP.ADDRESS_SEARCH_LABEL' | transloco}}</small>
                          </span>
                          <span class="danger-text" *ngIf="foreignAddressSearchError">
                            <small>{{foreignAddressSearchError}}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">Postcode <span class="text-danger"> * </span></label>
                      <input type="text" oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern"
                        mask="111100"
                        [disabled]="!hasContactAddressUpdatePermission || (!selectedContactAddress.country) || selectedCountry !== 'NL'"
                        [(ngModel)]="selectedContactAddress.zip_code" (keyup)="onAddressSearch()"
                        [ngClass]="{ 'is-invalid': errors?.zip_code || !selectedContactAddress.zip_code?.trim()?.length }"
                        class="form-control tri-form-control">
                      <div *ngIf="errors?.zip_code" class="text-danger">
                        {{ errors?.zip_code }}
                      </div>
                      <div *ngIf="!selectedContactAddress.zip_code?.trim()?.length" class="text-danger">
                        {{'SIGN_UP.ZIPCODE_IS_REQUIRED' | transloco}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.HOUSE_NUMBER' | transloco}} <span
                          class="text-danger"> * </span></label>
                      <input type="text"
                        [disabled]="!hasContactAddressUpdatePermission || (!selectedContactAddress.country) || selectedCountry !== 'NL'"
                        [(ngModel)]="selectedContactAddress.house_number" class="form-control tri-form-control"
                        (keyup)="onAddressSearch()"
                        [ngClass]="{ 'is-invalid': errors?.house_number || !selectedContactAddress?.house_number?.toString()?.trim()?.length }">
                      <div *ngIf="errors?.house_number" class="text-danger">
                        {{ errors?.house_number }}
                      </div>
                      <div *ngIf="!selectedContactAddress?.house_number?.toString()?.trim()?.length"
                        class="text-danger">
                        {{'SIGN_UP.HOUSE_NUMBER_REQUIRED' | transloco}}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                      <input type="text"
                        [disabled]="!hasContactAddressUpdatePermission || (!selectedContactAddress.country) || selectedCountry !== 'NL'"
                        [(ngModel)]="selectedContactAddress.house_number_extension"
                        class="form-control tri-form-control" (keyup)="onAddressSearch()"
                        [ngClass]="{ 'is-invalid': errors?.house_number_extension }">
                      <div *ngIf="errors?.house_number_extension" class="text-danger">
                        {{ errors?.house_number_extension }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.STREET' | transloco}} <span class="text-danger"> *
                        </span> </label>
                      <div class="field-with-spinner">
                        <input type="text"
                          [disabled]="!hasContactAddressUpdatePermission || (selectedContactAddress.country && addressFound.street)"
                          [ngClass]="{ 'is-invalid': errors?.street || !selectedContactAddress.street?.trim()?.length }"
                          [(ngModel)]="selectedContactAddress.street" class="form-control tri-form-control">
                        <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                          role="status">
                        </div>
                        <div *ngIf="errors?.street" class="text-danger">
                          {{ errors?.street }}
                        </div>
                        <div *ngIf="!selectedContactAddress.street?.trim()?.length" class="text-danger">
                          {{'CUSTOMER.STREET_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="" class="field-label">{{'SIGN_UP.CITY' | transloco}} <span class="text-danger"> *
                        </span></label>
                      <div class="field-with-spinner">
                        <input type="text"
                          [disabled]="!hasContactAddressUpdatePermission || (selectedContactAddress.country && addressFound.city)"
                          [(ngModel)]="selectedContactAddress.city"
                          [ngClass]="{ 'is-invalid': errors?.city || !selectedContactAddress.city?.trim()?.length }"
                          class="form-control tri-form-control">
                        <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                          role="status">
                        </div>
                        <div *ngIf="errors?.city" class="text-danger">
                          {{ errors?.city }}
                        </div>
                        <div *ngIf="!selectedContactAddress.city?.trim()?.length" class="text-danger">
                          {{'CUSTOMER.CITY_NAME_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                  <span class="circle-hint-icon">i</span>
                  <p class="body-text-1 mb-0">
                    {{addressCombinationMsg}}
                  </p>
                </div>
                <!-- <div class="text-right mb-4 pt-1" *ngIf="hasContactAddressUpdatePermission">
                  <button [disabled]="
                  createAddressLoader ||
                  cart?.block_delivery_address ||
                  !selectedContactAddress?.city?.trim()?.length ||
                  !selectedContactAddress?.street?.trim()?.length||
                  !selectedContactAddress?.zip_code?.trim()?.length ||
                  !selectedContactAddress?.house_number?.toString()?.trim()?.length ||
                  !selectedContactAddress?.company_name?.trim()?.length" class="btn px-4 btn-primary mb-0 rounded-btn"
                    (click)="updateContactAddress()">
                    <span class="spinner-border spinner-border-sm mr-2" *ngIf="createAddressLoader" role="status"
                      aria-hidden="true"></span> Update
                  </button>
                </div> -->
              </div>
              <div *ngIf="selectedContactAddress && !selectedContactAddress?.id && sameAsDeliveryAddress === false">
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="c_name" class="field-label">{{'CUSTOMER.COMPANY_NAME' | transloco }} <span
                          class="text-danger"> * </span> </label>
                      <input type="text" name="c_name" (blur)="onAddressCompanyChange()"
                        [(ngModel)]="selectedContactAddress.company_name" (ngModelChange)="onAddressCompanyChange()"
                        class="form-control tri-form-control"
                        [ngClass]="{ 'is-invalid': addressCreateOrUpdateError?.company_name }">
                      <div *ngIf="errors?.company_name" class="text-danger">
                        {{ errors?.company_name }}
                      </div>
                      <div *ngIf="addressCreateOrUpdateError?.company_name" class="text-danger">
                        {{'CUSTOMER.COMPANY_NAME_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="v_letter" class="field-label">{{'SIGN_UP.INITIALS' | transloco}}</label>
                      <input type="text" name="v_letter" [(ngModel)]="selectedContactAddress.initials"
                        class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="vo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }}</label>
                      <input type="text" name="vo_name" [(ngModel)]="selectedContactAddress.first_name"
                        class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="sp" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                      <input type="text" name="sp" [(ngModel)]="selectedContactAddress.surname_prefix"
                        class="form-control tri-form-control">
                    </div>
                  </div>
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="l_name" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
                      <input type="text" name="l_name" [(ngModel)]="selectedContactAddress.last_name"
                        class="form-control tri-form-control">
                    </div>
                  </div>
                </div>
                <div class="row g-4 mb-3">
                  <div class="col-md-12">
                    <div class="kpn-form-group">
                      <label class="field-label">Select Country <span class="text-danger"> * </span></label>
                      <ng-select class="tri-ng-select" placeholder="Select Country" [items]="country" bindLabel="name"
                        bindValue="country_code" [(ngModel)]="selectedCountry" [ngModelOptions]="{standalone: true}"
                        (change)="onSelectCountry($event)">
                      </ng-select>
                    </div>
                  </div>
                </div>
                <div class="row mb-2" *ngIf="selectedCountry && selectedCountry !== NL_CODE">
                  <div class="col-12">
                    <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                      <div class="form-group kpn-form-group">
                        <label class="field-label">Zoeken</label>
                        <div
                          class="kpn-form-group tri-autocomplete-form icon-right handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                          [ngClass]="{'search-active' : searchFocused}">
                          <i class="search-icon kpn-ui-icon ui-search"></i>
                          <div class="ng-autocomplete">
                            <ng-autocomplete #auto class="" [data]="addressList"
                              [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"
                              [searchKeyword]="keyword" [(ngModel)]='addressCreateValue'
                              (selected)='onSelectedAddress($event)' (inputChanged)='searchAddressForeign($event)'
                              (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                              [isLoading]="isLoading" (inputCleared)="searchCleared()" [debounceTime]="600"
                              [notFoundTemplate]="notFoundTemplate">
                            </ng-autocomplete>
                            <ng-template #itemTemplate let-item>
                              <div class="auto-template">
                                <span [innerHTML]="item.value"></span> &nbsp;
                                <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                              </div>
                            </ng-template>
                            <ng-template #notFoundTemplate let-notFound>
                              <div [innerHTML]="notFound"></div>
                            </ng-template>
                          </div>
                          <span class="text-muted" *ngIf="!foreignAddressSearchError">
                            <small> {{'CUSTOMER.SEARCH_PATTERN' | transloco}}</small>
                          </span>
                          <span class="danger-text" *ngIf="foreignAddressSearchError">
                            <small>{{foreignAddressSearchError}}</small>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row gutter-row-24 mb-3">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="p_code" class="field-label">Postcode <span class="text-danger"> * </span></label>
                      <input [disabled]="(selectedCountry !== 'NL')" type="text" name="p_code"
                        oninput="this.value = this.value.toUpperCase()" [patterns]="zipCodePattern" mask="111100"
                        [(ngModel)]="selectedContactAddress.zip_code" (ngModelChange)="onAddressPostCodeChange()"
                        (blur)="onAddressPostCodeChange()" (keyup)="onAddressSearch()"
                        [ngClass]="{ 'is-invalid': errors?.zip_code || addressCreateOrUpdateError.zip_code }"
                        class="form-control tri-form-control">
                      <div *ngIf="errors?.zip_code" class="text-danger">
                        {{ errors?.zip_code }}
                      </div>
                      <div *ngIf="addressCreateOrUpdateError.zip_code" class="text-danger">
                        {{'CUSTOMER.POST_CODE_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="h_number" class="field-label">{{'SIGN_UP.HOUSE_NUMBER' | transloco}} <span
                          class="text-danger"> * </span></label>
                      <input type="text" [disabled]="(selectedCountry !== 'NL')" name="h_number"
                        [(ngModel)]="selectedContactAddress.house_number" (ngModelChange)="onAddressHouseNoChange()"
                        class="form-control tri-form-control" (keyup)="onAddressSearch()"
                        (blur)="onAddressHouseNoChange()"
                        [ngClass]="{ 'is-invalid': errors?.house_number || addressCreateOrUpdateError?.house_number }">
                      <div *ngIf="errors?.house_number" class="text-danger">
                        {{ errors?.house_number }}
                      </div>
                      <div *ngIf="addressCreateOrUpdateError?.house_number" class="text-danger">
                        {{'CUSTOMER.HOUSE_NUMBER_IS_REQUIRED' | transloco }}
                      </div>
                    </div>
                  </div>
                  <div class="col-md-3 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="hext" class="field-label">{{'CUSTOMER.ADDITION' | transloco }}</label>
                      <input type="text" name="hext" [(ngModel)]="selectedContactAddress.house_number_extension"
                        class="form-control tri-form-control" (keyup)="onAddressSearch()"
                        [ngClass]="{ 'is-invalid': errors?.house_number_extension }">
                      <div *ngIf="errors?.house_number_extension" class="text-danger">
                        {{ errors?.house_number_extension }}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="street_n" class="field-label">{{'SIGN_UP.STREET' | transloco}} <span
                          class="text-danger"> * </span></label>
                      <div class="field-with-spinner">
                        <input type="text" (blur)="onAddressStreetChange()"
                          [disabled]="(selectedCountry && addressFound.street)" name="street_n"
                          [ngClass]="{ 'is-invalid': errors?.street || addressCreateOrUpdateError?.street}"
                          [(ngModel)]="selectedContactAddress.street" (ngModelChange)="onAddressStreetChange()"
                          class="form-control tri-form-control">
                        <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                          role="status">
                        </div>
                        <div *ngIf="errors?.street" class="text-danger">
                          {{ errors?.street }}
                        </div>
                        <div *ngIf="addressCreateOrUpdateError?.street" class="text-danger">
                          {{'CUSTOMER.STREET_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3 gutter-row-24">
                  <div class="col-md-6 gutter-col-24">
                    <div class="kpn-form-group">
                      <label for="city_n" class="field-label">{{'SIGN_UP.CITY' | transloco}} <span class="text-danger">
                          * </span></label>
                      <div class="field-with-spinner">
                        <input [disabled]="(selectedCountry && addressFound.city)" (blur)="onAddressCityChange()"
                          type="text" name="city_n" [(ngModel)]="selectedContactAddress.city"
                          (ngModelChange)="onAddressCityChange()"
                          [ngClass]="{ 'is-invalid': errors?.city || addressCreateOrUpdateError?.city }"
                          class="form-control tri-form-control">
                        <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner"
                          role="status">
                        </div>
                        <div *ngIf="errors?.city" class="text-danger">
                          {{ errors?.city }}
                        </div>
                        <div *ngIf="addressCreateOrUpdateError?.city" class="text-danger">
                          {{'CUSTOMER.CITY_NAME_IS_REQUIRED' | transloco }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="note-box note-box-red mb-2" *ngIf="addressCombinationMsg">
                  <span class="circle-hint-icon">i</span>
                  <p class="body-text-1 mb-0">
                    {{addressCombinationMsg}}
                  </p>
                </div>
                <div class="row" *ngIf="(sameAsDeliveryAddress === false) && (!selectedContactAddress?.id) ">
                  <div class="col-md-12">
                    <div class="mb-3">
                      <label class="checkbox-item d-flex align-items-center">
                        <div class="checkbox-btn checkbox-btn-outline">
                          <input [disabled]="
                          !selectedContactAddress?.city?.trim()?.length ||
                          !selectedContactAddress?.street?.trim()?.length||
                          !selectedContactAddress?.zip_code?.trim()?.length ||
                          !selectedContactAddress?.house_number?.trim()?.length ||
                          !selectedContactAddress?.company_name?.trim()?.length" type="checkbox" [(ngModel)]="saveAsNewAddress">
                          <span class="checkBoxCheckmark"></span>
                        </div>
                        <div class="checkbox-label font-kpn-extended ml-2">
                          <span>Sla op als nieuw adres</span>
                        </div>
                      </label>
                    </div>
                  </div>
                </div>
                <!-- <div class="text-right mb-4 pt-4">
                  <button [disabled]="
                  createAddressLoader ||
                  !selectedContactAddress?.city?.trim()?.length ||
                  !selectedContactAddress?.street?.trim()?.length||
                  !selectedContactAddress?.zip_code?.trim()?.length ||
                  !selectedContactAddress?.house_number?.trim()?.length ||
                  !selectedContactAddress?.company_name?.trim()?.length
                  " class="btn px-4 btn-primary mb-0 rounded-btn" (click)="createContactAddress()">
                    <span class="spinner-border spinner-border-sm mr-2" *ngIf="createAddressLoader" role="status"
                      aria-hidden="true"></span> {{'CUSTOMER.CREATE' | transloco }}
                  </button>
                </div> -->
              </div>
            </div>
            <div class="bg-white tri-shadow-xl tri-rounded-20 mt-4 p-32">
              <h3 class="green-text tri-title-32 font-kpn-bold mb-3">{{'CUSTOMER.CONTACT_PERSON' | transloco }} </h3>
              <div class="row">
                <div class="col-md-12">
                  <div class="row mb-3 gutter-row-24">
                    <div class="col-md-6 gutter-col-24">
                      <div class="kpn-form-group">
                        <label for="" class="field-label">{{'ORDER_OVERVIEW.CONTACT_PERSON' | transloco }}</label>
                        <select name="" [(ngModel)]="selectedContactPerson" (ngModelChange)="onChangeContactPerson()"
                          class="form-control tri-form-control" id="">
                          <option [ngValue]="null" disabled>{{'CUSTOMER.SELECT_CONTACT_PERSON' | transloco }}</option>
                          <option class="create-option" *ngIf="contactPersonAddPermission" [ngValue]="newContactPerson">
                            {{'CUSTOMER.CREATE_CONTACT_PERSON' | transloco }} +
                          </option>
                          <option [ngValue]="item" *ngFor="let item of contact_persons; let i = index;">
                            <span>{{item?.initials}}&nbsp;</span>
                            <span>{{item?.surname_prefix}}&nbsp;</span>
                            <span>{{item?.last_name}}</span>
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div *ngIf="selectedContactPerson && selectedContactPerson?.uuid">
                    <div class="row gutter-row-24 mb-3">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'SIGN_UP.INITIALS' | transloco}} <span class="text-danger"> * </span></label>
                          <input type="text" disabled [(ngModel)]="selectedContactPerson.initials"
                            [disabled]="!contactPersonUpdatePermission" class="form-control tri-form-control">
                        </div>
                      </div>
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                          <input type="text" [ngClass]="{ 'is-invalid': contactPersonUpdateError?.first_name }"
                            [(ngModel)]="selectedContactPerson.first_name" [disabled]="!contactPersonUpdatePermission"
                            class="form-control tri-form-control">
                          <div *ngIf="contactPersonUpdateError?.first_name" class="text-danger">
                            {{ contactPersonUpdateError?.first_name?.message}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row gutter-row-24 mb-3">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                          <input type="text" disabled [(ngModel)]="selectedContactPerson.surname_prefix"
                            [disabled]="!contactPersonUpdatePermission" class="form-control tri-form-control">
                        </div>
                      </div>
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}} <span class="text-danger"> * </span></label>
                          <input type="text" disabled [(ngModel)]="selectedContactPerson.last_name"
                            [disabled]="!contactPersonUpdatePermission" class="form-control tri-form-control">
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'SIGN_UP.PHONE_NUMBER' | transloco}}</label>
                          <input type="text" maxlength="12"
                            [ngClass]="{ 'is-invalid': contactPersonUpdateError?.mobile_phone_number }"
                            [(ngModel)]="selectedContactPerson.mobile_phone_number"
                            [disabled]="!contactPersonUpdatePermission" class="form-control tri-form-control">
                          <div *ngIf="contactPersonUpdateError?.mobile_phone_number" class="text-danger">
                            {{ contactPersonUpdateError?.mobile_phone_number?.message}}
                          </div>
                        </div>
                        <small class="text-muted">{{'SIGN_UP.PATTERN' | transloco}} +31612345678 {{'SIGN_UP.OR' |
                          transloco}} 0612345678</small>
                      </div>
                    </div>
                    <div class="row mb-3 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="" class="field-label">{{'SIGN_UP.E_MAIL_ADDRESS' | transloco}} <span class="text-danger"> * </span></label>
                          <input type="email" [ngClass]="{ 'is-invalid': contactPersonUpdateError?.email }"
                            [(ngModel)]="selectedContactPerson.email" [disabled]="notAllowedEmailChangePermission"
                            class="form-control tri-form-control">
                          <div *ngIf="contactPersonUpdateError?.email" class="text-danger">
                            {{ contactPersonUpdateError?.email[0]}}
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="text-right mb-4 pt-1"
                      *ngIf="contactPersonDeletePermission && selectedContactPerson?.can_delete">
                      <button [disabled]="deleteContactPersonLoader" class="btn px-4 btn-primary mb-0 rounded-btn"
                        (click)="deleteContactPerson(selectedContactPerson?.uuid)">
                        <span class="spinner-border spinner-border-sm mr-2" *ngIf="deleteContactPersonLoader" role="status"
                          aria-hidden="true"></span> {{'CUSTOMER.DELETE' | transloco }}
                      </button>
                    </div> -->
                  </div>
                  <div *ngIf="contactPersonAddPermission && selectedContactPerson && !selectedContactPerson?.uuid">
                    <div class="row gutter-row-24 mb-3">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="cv_letter" class="field-label">{{'SIGN_UP.INITIALS' | transloco}} <span class="text-danger"> * </span></label>
                          <input type="text" name="cv_letter" (blur)="onContactPersonInitialChange()"
                            [(ngModel)]="newContactPerson.initials" (ngModelChange)="onContactPersonInitialChange()"
                            [ngClass]="{ 'is-invalid': contactPersonCreateError?.initials || (feContactPersonCreateError?.initials) }"
                            class="form-control tri-form-control">
                          <div *ngIf="contactPersonCreateError?.initials" class="text-danger">
                            {{ contactPersonCreateError?.initials[0]}}
                          </div>
                          <div *ngIf="contactPersonCreateError?.initials?.message" class="text-danger">
                            {{ contactPersonCreateError?.initials?.message }}
                          </div>
                          <div *ngIf="feContactPersonCreateError?.initials" class="text-danger">
                            This field may not be blank.
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="cvo_name" class="field-label">{{'CUSTOMER.FIRST_NAME' | transloco }} <span class="text-danger"> * </span></label>
                          <input type="text" name="cvo_name" (blur)="onContactPersonFirstNameChange()"
                            [ngClass]="{ 'is-invalid': contactPersonCreateError?.first_name || (feContactPersonCreateError?.first_name) }"
                            [(ngModel)]="newContactPerson.first_name" (ngModelChange)="onContactPersonFirstNameChange()"
                            class="form-control tri-form-control">
                          <div *ngIf="contactPersonCreateError?.first_name" class="text-danger">
                            {{ contactPersonCreateError?.first_name?.message || contactPersonCreateError?.first_name[0]
                            }}
                          </div>
                          <div *ngIf="feContactPersonCreateError?.first_name" class="text-danger">
                            This field may not be blank.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row gutter-row-24 mb-3">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="snpfx" class="field-label">{{'ACCOUNT.MIDDLE_NAME' | transloco }}</label>
                          <input type="text" name="snpfx" [(ngModel)]="newContactPerson.surname_prefix"
                            class="form-control tri-form-control">
                        </div>
                      </div>
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="cp_l_name" class="field-label">{{'SIGN_UP.LAST_NAME' | transloco}} <span class="text-danger"> * </span></label>
                          <input (blur)="onContactPersonLastNameChange()" type="text" name="cp_l_name"
                            [(ngModel)]="newContactPerson.last_name" (ngModelChange)="onContactPersonLastNameChange()"
                            [ngClass]="{ 'is-invalid': contactPersonCreateError?.last_name || feContactPersonCreateError?.last_name }"
                            class="form-control tri-form-control">
                          <div *ngIf="contactPersonCreateError?.last_name" class="text-danger">
                            {{ contactPersonCreateError?.last_name[0]}}
                          </div>
                          <div *ngIf="feContactPersonCreateError?.last_name " class="text-danger">
                            This field may not be blank.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row mb-3 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="c_phone" class="field-label">{{'SIGN_UP.PHONE_NUMBER' | transloco}}</label>
                          <input type="text" name="c_phone" maxlength="12"
                            [ngClass]="{ 'is-invalid': contactPersonCreateError?.mobile_phone_number }"
                            [(ngModel)]="newContactPerson.mobile_phone_number" class="form-control tri-form-control">
                          <div *ngIf="contactPersonCreateError?.mobile_phone_number" class="text-danger">
                            {{ contactPersonCreateError?.mobile_phone_number?.message ||
                            contactPersonCreateError?.mobile_phone_number[0]}}
                          </div>
                        </div>
                        <small class="text-muted">{{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'SIGN_UP.PATTERN' |
                          transloco}} +31612345678 {{'SIGN_UP.OR' | transloco}} 0612345678</small>
                      </div>
                    </div>
                    <div class="row mb-3 gutter-row-24">
                      <div class="col-md-6 gutter-col-24">
                        <div class="kpn-form-group">
                          <label for="c_email" class="field-label">{{'SIGN_UP.E_MAIL_ADDRESS' | transloco}} <span class="text-danger"> * </span></label>
                          <input (blur)="onContactPersonEmailChange()" type="email" name="c_email"
                            [ngClass]="{ 'is-invalid': contactPersonCreateError?.email || (feContactPersonCreateError?.email) }"
                            [(ngModel)]="newContactPerson.email" (ngModelChange)="onContactPersonEmailChange()"
                            class="form-control tri-form-control">
                          <div *ngIf="contactPersonCreateError?.email" class="text-danger">
                            {{ contactPersonCreateError?.email[0]}}
                          </div>
                          <div *ngIf="feContactPersonCreateError?.email" class="text-danger">
                            This field may not be blank.
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- <div class="text-right mb-4 pt-1">
                      <button [disabled]="createContactPersonLoader" class="btn px-4 btn-primary mb-0 rounded-btn d-inline-flex align-items-center"
                        (click)="createContactPerson()">
                        <span class="spinner-border spinner-border-sm mr-2" *ngIf="createContactPersonLoader" role="status"
                          aria-hidden="true"></span> <span class="fs-22 mr-1">+</span> {{'CUSTOMER.CREATE' | transloco }}
                      </button>
                    </div> -->
                  </div>
                  <div class="note-box note-box-red mb-2"
                    *ngIf="!contactAddress?.length || (customer && !customer?.contact_addresses?.length)">
                    <span class="circle-hint-icon">i</span>
                    <p class="body-text-1 mb-0">
                      {{'CUSTOMER.CONTACT_WEBSHOP_MANAGER_YOU_CANNOT_CONTINUE_ORDER' | transloco }}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div class="row align-items-center mt-4" *ngIf="customer?.order_blocked">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{customer?.order_blocked_reason}}
                  </p>
                  <button class="kphicon-uniE926"></button>
                </div>
              </div>
            </div>
          </div>
          <div class="row align-items-center justify-content-between">
            <div class="col-auto d-flex">
              <button (click)="goTocustomersPage()" class="btn btn-link d-flex align-items-center">
                <i class="kphicon-uniE906 fs-22"></i>Terug naar jouw overzicht</button>
            </div>
            <div class="col-auto">
              <ng-template #tipContent>{{'CUSTOMER.SELECT_APPLICANT_AND_SHIPPING_ADDRESS' | transloco }}</ng-template>
              <span placement="top" [ngbTooltip]="tipContent">
                <button (click)="nextStep()" class="btn btn-primary px-5 rounded-btn mb-2"
                  [disabled]="isNextStepDisabled">
                  <span *ngIf="isLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                    aria-hidden="true"></span>
                    Volgende stap
                </button>
              </span>
            </div>
          </div>
        </div>
      </div>

      <!-- New Design End -->
    </div>
  </section>
</section>
