import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
  providedIn: 'root'
})
export class SalesforceService {


  constructor(private baseApiService: ApiBaseService) { }
  createQuotation(payload): Observable<any> {
    let url = `quotes/salesforce/create`
    return this.baseApiService.post(url, payload);
  }


  validatePayload(payload: object) {
    const emptyProperties = [];
    Object.entries(payload).forEach(([key, value]) => {
      if (!['first_name_prefix', 'quote_name']?.includes(key) && !value) {
        emptyProperties.push(key);
      }
    });
    return emptyProperties;
  }

}
