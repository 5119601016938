import { Component, OnInit } from '@angular/core';
import { Route, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { RmaService } from '../../rma.service';

@Component({
  selector: 'app-rma-orders',
  templateUrl: './rma-orders.component.html',
  styleUrls: ['./rma-orders.component.scss']
})
export class RmaOrdersComponent extends BasePermission implements OnInit {

  public isLoading = false;
  public rmaOrders = [];

  public config = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };

  constructor(
    private rmaService: RmaService,
    private router: Router,
    public store: Store<IAppState>) {
    super(store);
  }

  ngOnInit(): void {
    this.getRMAOrders();
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getRMAOrders(event);
  }

  getRMAOrders(page = 1) {
    this.isLoading = true;
    this.rmaService.getRMAOrders(page).subscribe(res => {
      this.config.totalItems = res?.data?.count;
      this.rmaOrders = res?.data?.results;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  gotoRMADetails(rma_uuid) {
    this.router.navigate([`/rma/${rma_uuid}`], {
      queryParamsHandling: 'merge'
    });
  }

}
