import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { TradeInService } from '../trade-in.service';
import { ActivatedRoute, Router } from '@angular/router';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { StorageService } from 'src/app/shared/storage.service';
import { ToastrService } from 'ngx-toastr';
import { EProductQualityTypes } from 'src/app/shared/enums/product-type.enums';
import { TranslocoService } from '@ngneat/transloco';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-trade-in-details',
  templateUrl: './trade-in-details.component.html',
  styleUrls: ['./trade-in-details.component.scss']
})
export class TradeInDetailsComponent implements OnInit {
  public productVariantList = [];
  public selectedVariant = null;
  public preCart = null;

  public miniCartDetailsModalReference: NgbModalRef;

  private tradeInService = inject(TradeInService);
  private tradeInProductCode = '';
  private tradeInBrandCode = '';

  public productInfoError = '';
  public productInfo = null;
  public is_disabled = false;
  public is_disabled_functional = false;
  public is_disabled_non_functional = false;
  public preCartPayload = {
    product_code: '',
    product_quality: '',
    qty: 1
  };

  public functionalPreCartPayload = {
    product_code: '',
    product_quality: '',
    qty: 0
  };

  public nonFunctionalPreCartPayload = {
    product_code: '',
    product_quality: '',
    qty: 0
  };

  public functionalPreCart = null;
  public nonFunctionalPreCart = null;
  public cartError = null;
  public functionalPreCartError = null;
  public nonFunctionalPreCartError = null;
  public tradeInCart = null;
  public cartLoader = false;
  public user = this.storageService.getCurrentUser();
  public functional_qty = 0;
  public non_functional_qty = 0;

  public isCollapsedInfo = false;
  public isFunGoogleCollapsedInfo = false;
  public isNonFunGoogleCollapsedInfo = false;
  public isNonFunctionalCollapsedInfo = false;
  private debounceTimer: any;
  private readonly debounceTimeMs = 1500;
  public tradeInBusinessPartner = null;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private storageService: StorageService,
    private toaster: ToastrService,
    private translocoService: TranslocoService,
    private modalService: NgbModal,
  ) {
    this.route.params.pipe(
      tap(param => {

        this.tradeInProductCode = param.product_code;
        this.tradeInBrandCode = param.brand_code;
        return param
      }),
      switchMap(res => {
        // this.preCartPayload.product_code = this.tradeInProductCode;
        // this.functionalPreCartPayload.product_code = this.tradeInProductCode;
        // this.nonFunctionalPreCartPayload.product_code = this.tradeInProductCode;
        return this.tradeInService.getTradeInProductByCode(this.tradeInProductCode, this.tradeInBrandCode);
      }),
      catchError(error => {
        return throwError(error);
      })
    ).subscribe(response => {
      // this.productInfo = response?.data;
      // this.is_disabled = this.productInfo?.brand?.check_find_my_device_status || false;
      // this.is_disabled_functional = this.is_disabled_non_functional = this.is_disabled
      this.productVariantList = response?.data?.results?.map((item) => {
        return {
          ...item,
          preCart: null,
          functional: {
            qty: (item?.brand?.check_find_my_device_status === false || item?.brand?.check_google_account_removed) ? 0 : 0,
            find_my_device_status_disabled: item?.brand?.check_find_my_device_status === false ? true : false,
            remove_google_account_status_disabled: item?.brand?.check_google_account_removed === false ? true : false,
            totalPrice: item?.price[1]?.price_ex_vat * (item?.brand?.check_find_my_device_status === false ? 0 : 0),
            preCart: null,
            line: null
          },
          nonFunctional: {
            qty: (item?.brand?.check_find_my_device_status === false || item?.brand?.check_google_account_removed) === false  ? 0 : 0,
            find_my_device_status_disabled: item?.brand?.check_find_my_device_status === false ? true : false,
            remove_google_account_status_disabled: item?.brand?.check_google_account_removed === false ? true : false,
            totalPrice: item?.price[0]?.price_ex_vat * (item?.brand?.check_find_my_device_status === false ? 0 : 0),
            preCart: null,
            line: null
          }
        }
      }) ?? [];
      this.productVariantList = this.productVariantList.sort(this.compareMemorySizes);
      // this.productVariantList = this.removeDuplicateBasedOnMemorySize(this.productVariantList);
      const cart = localStorage.getItem('tradeInCart')
      if (cart) {
        this.tradeInCart = JSON.parse(cart);

        this.addCartLineDataToProductVariant();
      }

      this.selectedVariant = this.productVariantList?.[0] || null;



    }, error => {
      this.productInfoError = error?.error?.message;
    });
  }

  get eProductQuality() { return EProductQualityTypes; }
  get isAddToCartDisabled() {

    const selectedProductVariant = this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code);
    if (!selectedProductVariant) {
      return true;
    }
    const isAnyLineInCart = selectedProductVariant.functional.line || selectedProductVariant.nonFunctional.line;
    if (isAnyLineInCart) {
      return false;
    }
    if (selectedProductVariant?.functional?.preCart !== null || selectedProductVariant?.nonFunctional?.preCart !== null) {
      return false;
    }
    return true;
  }


  back() {
    this.router.navigateByUrl('/trade-in');
  }

  onEnter(line) {
    if (line.qty < 1) {
      return;
    }
    this.updatePreCart(line);
  }


  incrementQuantity(line) {
    line.qty = ++line.qty;
    this.updatePreCart(line);
  }
  decrementQuantity(line) {
    if (line.qty < 1) {
      return;
    }

    line.qty = --line.qty;
    this.updatePreCart(line);
  }


  addToCartAdd(addTocardAdd) {
    this.modalService.open(addTocardAdd, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-500 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }


  updatePreCart(line) {
    if (line.product_quality === EProductQualityTypes.FUNCTIONAL) {
      this.fetchFunctionalPreCart();
    } else if (line.product_quality === EProductQualityTypes.NON_FUNCTIONAL) {
      this.nonFetchFunctionalPreCart();
    }
  }



  onClickQuality(event, quality) {
    if (quality === EProductQualityTypes.FUNCTIONAL && event.target.checked) {
      this.functionalPreCartPayload.product_quality = quality;
      this.functionalPreCartPayload.qty = 1;
      this.fetchFunctionalPreCart();
    } else if (quality === EProductQualityTypes.FUNCTIONAL && !event.target.checked) {
      this.functionalPreCartPayload.product_quality = '';
      this.functionalPreCartPayload.qty = 0;
      this.functionalPreCart = null;
    } else if (quality === EProductQualityTypes.NON_FUNCTIONAL && event.target.checked) {
      this.nonFunctionalPreCartPayload.product_quality = quality;
      this.nonFunctionalPreCartPayload.qty = 1;
      this.nonFetchFunctionalPreCart();
    } else if (quality === EProductQualityTypes.NON_FUNCTIONAL && !event.target.checked) {
      this.nonFunctionalPreCartPayload.product_quality = '';
      this.nonFunctionalPreCartPayload.qty = 0;
      this.nonFunctionalPreCart = null;
    }
  }

  onClickIphoneDisable(is_disabled: boolean, quality) {
    if (quality === EProductQualityTypes.FUNCTIONAL) {
      this.is_disabled_functional = is_disabled;
      if (!is_disabled) {
        this.functionalPreCart = null;
      }
      this.fetchFunctionalPreCart();
    } else if (quality === EProductQualityTypes.NON_FUNCTIONAL) {
      this.is_disabled_non_functional = is_disabled;
      if (!is_disabled) {
        this.nonFunctionalPreCart = null;
      }
      this.nonFetchFunctionalPreCart();
    }
  }


  fetchFunctionalPreCart() {
    this.functionalPreCartError = null;
    if (!this.functionalPreCartPayload?.product_quality) {
      return;
    }
    this.tradeInService.tradeInPreCart({
      ...this.functionalPreCartPayload,
      find_my_device_status_disabled: this.is_disabled_functional
    }).subscribe(res => {
      this.functionalPreCart = res?.data;
    }, error => {
      this.functionalPreCartError = error?.error?.message;
      this.toaster.error(this.functionalPreCartError);
    })
  }

  nonFetchFunctionalPreCart() {
    this.nonFunctionalPreCartError = null;
    if (!this.nonFunctionalPreCartPayload?.product_quality) {
      return;
    }
    this.tradeInService.tradeInPreCart({
      ...this.nonFunctionalPreCartPayload,
      find_my_device_status_disabled: this.is_disabled_non_functional
    }).subscribe(res => {
      this.nonFunctionalPreCart = res?.data;
    }, error => {
      this.nonFunctionalPreCartError = error?.error?.message;
      this.toaster.error(this.nonFunctionalPreCartError);
    })
  }



  saveTradeInCart(cart, quality) {
    this.toaster.success(`${this.translocoService.translate('TRADE_DETAILS.PRODUCT_ADDED_TO_CART')}`);
    this.storageService.setReComCart(cart);
    if (this.functionalPreCart && this.nonFunctionalPreCart) {
      if (quality === EProductQualityTypes.FUNCTIONAL) {
        this.functionalPreCart = null;
        this.addToCart()
      } else if ((quality === EProductQualityTypes.NON_FUNCTIONAL)) {
        this.nonFunctionalPreCart = null;
        this.addToCart();
      }
    } else {
      this.cartLoader = false;
      this.router.navigate([`trade-in/cart-details`], {
        queryParams: {},
        queryParamsHandling: 'merge'
      });
    }
  }

  errorShowTradeInCart(error) {
    this.cartError = error || `${this.translocoService.translate('TRADE_DETAILS.PRODUCT_ADDED_FAILED_CART')}`;
    this.toaster.error(this.cartError);
    this.cartLoader = false;
  }


  addToCart() {
    let payload = {
      product_code: this.functionalPreCart?.product_code || this.nonFunctionalPreCart?.product_code,
      product_quality: this.functionalPreCart?.product_quality || this.nonFunctionalPreCart?.product_quality,
      qty: this.functionalPreCart?.qty || this.nonFunctionalPreCart?.qty,
      find_my_device_status_disabled: this.is_disabled
    };

    this.cartLoader = true;
    if (this.tradeInCart) {
      return this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
        this.saveTradeInCart(res?.data, payload.product_quality);
      }, error => {
        this.errorShowTradeInCart(error?.error?.message);
      });
    } else {
      const customer = this.storageService?.getCustomer();
      const payloadWithContact = {
        ...payload,
        contact_uuid: this.user?.is_external ? this?.user?.contact?.uuid : customer?.uuid,
        contact_person_uuid: this.user?.is_external ? this.user?.contact_person?.uuid : this.storageService.getContactPersonUUID() || '',
      }
      if (this.tradeInBusinessPartner) {
        delete payloadWithContact.contact_person_uuid;
      }
      return this.tradeInService.tradeInCart(payloadWithContact).subscribe(res => {
        this.saveTradeInCart(res?.data, payload.product_quality);
      }, error => {
        this.errorShowTradeInCart(error?.error?.message);
      });
    }
  }
  setSelectedVariant(variant) {
    this.selectedVariant = variant;
  }
  // Custom comparison function to extract memory size and compare
  compareMemorySizes(a, b) {
    const units = { gb: 1024, tb: 1024*1024, mb: 1 };

    const parseSize = (size) => {
      const trimmedSize = size.trim().toLowerCase().replace(/\s+/g, '');
      const matches = trimmedSize.match(/(\d+)\s*([A-Za-z]+)/);
      if (matches) {
        const value = parseInt(matches[1]);
        const unit = matches[2];
        return value * units[unit];
      }
      return NaN; // Return NaN for invalid size format
    };

    const sizeA = parseSize(a.memory_size);
    const sizeB = parseSize(b.memory_size);

    return sizeA - sizeB;
  }

  removeDuplicateBasedOnMemorySize(data) {
    const memorySizesSet = new Set();
    return data.filter(item => {
      const normalizedSize = item.memory_size.replace(/\s/g, '').toLowerCase();
      if (!memorySizesSet.has(normalizedSize)) {
        memorySizesSet.add(normalizedSize);
        return true;
      }
      return false;
    });
  }


  updateFindMyDevice(index, status, type, line) {

    if (type === EProductQualityTypes.FUNCTIONAL) {
      if (line && status === true) {
        // do nothing
        return;
      }
      if (line && status === false) {
        this.productVariantList[index].functional.find_my_device_status_disabled = status;
        // remove line
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].functional.line = null;
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].functional.qty = 0;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.productVariantList[index].functional.find_my_device_status_disabled = !status;
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].nonFunctional.line && status === true) {
        this.productVariantList[index].functional.find_my_device_status_disabled = status;
        if (this.productVariantList[index].functional.qty < 1) {
          this.productVariantList[index].functional.qty = 1;
        }
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          find_my_device_status_disabled: status,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].functional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {

        });
        return;
      }
      // below code is for normal pre cart flow
      this.productVariantList[index].functional.find_my_device_status_disabled = status;
      // if disabled set qty to 0
      if (status === false) {

        this.productVariantList[index].functional = {
          ...this.productVariantList[index].functional,
          qty: 0,
          totalPrice: 0,
          preCart: null
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].functional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message ?? 'Something went wrong');
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
      else {
        if (this.productVariantList[index].functional.qty < 1) {
          this.productVariantList[index].functional.qty = 1;
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          // this.productVariantList[index].nonFunctional.preCart
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
          });
        }
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          find_my_device_status_disabled: status,
        });
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].functional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message ?? 'Something went wrong');
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
    } else {
      if (line && status === true) {
        // do nothing
        return;
      }
      if (line && status === false) {
        this.productVariantList[index].nonFunctional.find_my_device_status_disabled = status;
        // remove line
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].nonFunctional.line = null;
          this.productVariantList[index].nonFunctional.qty = 0;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.productVariantList[index].nonFunctional.find_my_device_status_disabled = !status;

          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].functional.line && status === true) {
        this.productVariantList[index].nonFunctional.find_my_device_status_disabled = status;
        if (this.productVariantList[index].nonFunctional.qty < 1) {
          this.productVariantList[index].nonFunctional.qty = 1;
        }
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          find_my_device_status_disabled: status,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {

        });
        return;
      }
      this.productVariantList[index].nonFunctional.find_my_device_status_disabled = status;
      // if disabled set qty to 0
      if (status === false) {

        this.productVariantList[index].nonFunctional = {
          ...this.productVariantList[index].nonFunctional,
          qty: 0,
          totalPrice: 0,
          preCart: null
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].preCart = null;
        })

      }
      else {
        if (this.productVariantList[index].nonFunctional.qty < 1) {
          this.productVariantList[index].nonFunctional.qty = 1;
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
          });
        }
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          find_my_device_status_disabled: status,
        });
        // const payload = {
        //   product_code: this.productVariantList[index].code,
        //   product_quality: EProductQualityTypes.NON_FUNCTIONAL,
        //   qty: this.productVariantList[index].nonFunctional.qty,
        //   find_my_device_status_disabled: status,
        // }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].nonFunctional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
    }
  }
  updateGoogleAccountRemoved(index, status, type, line) {

    if (type === EProductQualityTypes.FUNCTIONAL) {
      if (line && status === true) {
        // do nothing
        return;
      }
      if (line && status === false) {
        this.productVariantList[index].functional.remove_google_account_status_disabled = status;
        // remove line
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].functional.line = null;
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].functional.qty = 0;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.productVariantList[index].functional.remove_google_account_status_disabled = !status;
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].nonFunctional.line && status === true) {
        this.productVariantList[index].functional.remove_google_account_status_disabled = status;
        if (this.productVariantList[index].functional.qty < 1) {
          this.productVariantList[index].functional.qty = 1;
        }
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          remove_google_account_status_disabled: status,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].functional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {

        });
        return;
      }
      // below code is for normal pre cart flow
      this.productVariantList[index].functional.remove_google_account_status_disabled = status;
      // if disabled set qty to 0
      if (status === false) {

        this.productVariantList[index].functional = {
          ...this.productVariantList[index].functional,
          qty: 0,
          totalPrice: 0,
          preCart: null
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].functional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message ?? 'Something went wrong');
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
      else {
        if (this.productVariantList[index].functional.qty < 1) {
          this.productVariantList[index].functional.qty = 1;
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          // this.productVariantList[index].nonFunctional.preCart
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
          });
        }
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          remove_google_account_status_disabled: status,
        });
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].functional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message ?? 'Something went wrong');
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
    } else {
      if (line && status === true) {
        // do nothing
        return;
      }
      if (line && status === false) {
        this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = status;
        // remove line
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].nonFunctional.line = null;
          this.productVariantList[index].nonFunctional.qty = 0;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = !status;

          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].functional.line && status === true) {
        this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = status;
        if (this.productVariantList[index].nonFunctional.qty < 1) {
          this.productVariantList[index].nonFunctional.qty = 1;
        }
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          remove_google_account_status_disabled: status,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {

        });
        return;
      }
      this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = status;
      // if disabled set qty to 0
      if (status === false) {

        this.productVariantList[index].nonFunctional = {
          ...this.productVariantList[index].nonFunctional,
          qty: 0,
          totalPrice: 0,
          preCart: null
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].preCart = null;
        })

      }
      else {
        if (this.productVariantList[index].nonFunctional.qty < 1) {
          this.productVariantList[index].nonFunctional.qty = 1;
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
          });
        }
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          remove_google_account_status_disabled: status,
        });
        // const payload = {
        //   product_code: this.productVariantList[index].code,
        //   product_quality: EProductQualityTypes.NON_FUNCTIONAL,
        //   qty: this.productVariantList[index].nonFunctional.qty,
        //   find_my_device_status_disabled: status,
        // }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].nonFunctional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
    }
  }

  updateFindMyDeviceAndGoogleAccountRemoved(index, status, type, line) {

    if (type === EProductQualityTypes.FUNCTIONAL) {
      if (line && status === true) {
        // do nothing
        return;
      }
      if (line && status === false) {
        this.productVariantList[index].functional.find_my_device_status_disabled = status;
        this.productVariantList[index].functional.remove_google_account_status_disabled = status;
        // remove line
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].functional.line = null;
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].functional.qty = 0;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.productVariantList[index].functional.find_my_device_status_disabled = !status;
          this.productVariantList[index].functional.remove_google_account_status_disabled = !status;
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].nonFunctional.line && status === true) {
        this.productVariantList[index].functional.find_my_device_status_disabled = status;
        this.productVariantList[index].functional.remove_google_account_status_disabled = status;
        if (this.productVariantList[index].functional.qty < 1) {
          this.productVariantList[index].functional.qty = 1;
        }
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          find_my_device_status_disabled: status,
          remove_google_account_status_disabled: status,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].functional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {

        });
        return;
      }
      // below code is for normal pre cart flow
      this.productVariantList[index].functional.find_my_device_status_disabled = status;
      this.productVariantList[index].functional.remove_google_account_status_disabled = status;
      // if disabled set qty to 0
      if (status === false) {

        this.productVariantList[index].functional = {
          ...this.productVariantList[index].functional,
          qty: 0,
          totalPrice: 0,
          preCart: null
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
            remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].functional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message ?? 'Something went wrong');
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
      else {
        if (this.productVariantList[index].functional.qty < 1) {
          this.productVariantList[index].functional.qty = 1;
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          // this.productVariantList[index].nonFunctional.preCart
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
            remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
          });
        }
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          find_my_device_status_disabled: status,
          remove_google_account_status_disabled: status,
        });
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].functional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message ?? 'Something went wrong');
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
    } else {
      if (line && status === true) {
        // do nothing
        return;
      }
      if (line && status === false) {
        this.productVariantList[index].nonFunctional.find_my_device_status_disabled = status;
        this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = status;
        // remove line
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].nonFunctional.line = null;
          this.productVariantList[index].nonFunctional.qty = 0;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.productVariantList[index].nonFunctional.find_my_device_status_disabled = !status;
          this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = !status;

          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].functional.line && status === true) {
        this.productVariantList[index].nonFunctional.find_my_device_status_disabled = status;
        this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = status;
        if (this.productVariantList[index].nonFunctional.qty < 1) {
          this.productVariantList[index].nonFunctional.qty = 1;
        }
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          find_my_device_status_disabled: status,
          remove_google_account_status_disabled: status,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {

        });
        return;
      }
      this.productVariantList[index].nonFunctional.find_my_device_status_disabled = status;
      this.productVariantList[index].nonFunctional.remove_google_account_status_disabled = status;
      // if disabled set qty to 0
      if (status === false) {

        this.productVariantList[index].nonFunctional = {
          ...this.productVariantList[index].nonFunctional,
          qty: 0,
          totalPrice: 0,
          preCart: null
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
            remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].preCart = null;
        })

      }
      else {
        if (this.productVariantList[index].nonFunctional.qty < 1) {
          this.productVariantList[index].nonFunctional.qty = 1;
        }
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
            remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
          });
        }
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          find_my_device_status_disabled: status,
          remove_google_account_status_disabled: status,
        });
        // const payload = {
        //   product_code: this.productVariantList[index].code,
        //   product_quality: EProductQualityTypes.NON_FUNCTIONAL,
        //   qty: this.productVariantList[index].nonFunctional.qty,
        //   find_my_device_status_disabled: status,
        // }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
          // this.productVariantList[index].nonFunctional.preCart = res?.data;
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
      }
    }
  }


  updateQty(index, qty, type, line) {
    if (type === EProductQualityTypes.FUNCTIONAL) {
      if (line  && qty == 0) {
        // have to delete line
        this.productVariantList[index].functional.qty = qty;
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].functional.line = null;
          this.productVariantList[index].functional.preCart = null;
          this.tradeInCart = res?.data;
          this.addCartLineDataToProductVariant();
          this.storageService.setReComCart(this.tradeInCart);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].functional.qty = this.productVariantList[index].functional.line.qty;
        });
        return;
      }
      if (line) {
        this.productVariantList[index].functional.qty = qty;
        this.tradeInService.updateTradeInCartLine(this.tradeInCart.uuid, line?.uuid, { qty: qty }).subscribe(res => {
          this.tradeInCart = res?.data;
          this.addCartLineDataToProductVariant();
          this.storageService.setReComCart(this.tradeInCart);
        }, error => {
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].nonFunctional.line && qty > 0 ) {
        this.productVariantList[index].functional.qty = qty;
        // have to add in existing cart line
        let payload = {
          lines: [
            {
              product_code: this.productVariantList[index].code,
              product_quality: EProductQualityTypes.FUNCTIONAL,
              qty: this.productVariantList[index].functional.qty,
              find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
              remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
            }
          ]
        };
        // const payload = {

        // }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].functional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (qty < 1 && (this.productVariantList[index]?.brand?.check_find_my_device_status === false  || this.productVariantList[index]?.brand?.check_google_account_removed === false)) {
        this.productVariantList[index].functional.qty = 0;
        this.productVariantList[index].functional.preCart = null;
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].nonFunctional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.NON_FUNCTIONAL,
            qty: this.productVariantList[index].nonFunctional.qty,
            find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
            remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].functional.preCart = null;
          this.productVariantList[index].preCart = null;
        })
        return;
      }
      this.productVariantList[index].functional = {
        ...this.productVariantList[index].functional,
        qty: qty,
        totalPrice: (parseFloat(this.productVariantList[index].price[1]?.price_ex_vat) * parseFloat(qty)).toFixed(2)
      }
      let payload = {
        lines: []
      };
      if (this.productVariantList[index].nonFunctional.preCart) {
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
          remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
        });
      }
      payload.lines.push({
        product_code: this.productVariantList[index].code,
        product_quality: EProductQualityTypes.FUNCTIONAL,
        qty: this.productVariantList[index].functional.qty,
        find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
        remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
      });
      // const payload = {
      //   product_code: this.productVariantList[index].code,
      //   product_quality: EProductQualityTypes.FUNCTIONAL,
      //   qty: this.productVariantList[index].functional.qty,
      //   find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
      // }
      this.tradeInService.tradeInPreCart(payload).subscribe(res => {
        // this.productVariantList[index].functional.preCart = res?.data;
        this.productVariantList[index].preCart = res?.data;
        this.addPreCartDataToProductVariant(res?.data);
      }, error => {
        this.toaster.error(error?.error?.message);
        this.productVariantList[index].functional.preCart = null;
      })
      // this.productVariantList[index].functional.qty = qty;
      // this.productVariantList[index].functional.totalPrice = (parseFloat(this.productVariantList[index].price[1]?.price_ex_vat) * parseFloat(qty)).toFixed(2);
    } else {
      if (line  && qty == 0) {
        // have to delete line
        this.productVariantList[index].nonFunctional.qty = qty;
        this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
          this.productVariantList[index].nonFunctional.line = null;
          this.productVariantList[index].nonFunctional.preCart = null;
          this.tradeInCart = res?.data;
          this.addCartLineDataToProductVariant();
          this.storageService.setReComCart(this.tradeInCart);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].nonFunctional.qty = this.productVariantList[index].nonFunctional.line.qty;
        });
        return;
      }
      if (line) {
        this.productVariantList[index].nonFunctional.qty = qty;
        this.tradeInService.updateTradeInCartLine(this.tradeInCart.uuid, line?.uuid, { qty: qty }).subscribe(res => {
          this.tradeInCart = res?.data;
          this.addCartLineDataToProductVariant();
          this.storageService.setReComCart(this.tradeInCart);
        }, error => {
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (!line && this.productVariantList[index].functional.line && qty > 0 ) {
        this.productVariantList[index].nonFunctional.qty = qty;
        // have to add in existing cart line
        const payload = {
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.NON_FUNCTIONAL,
          qty: this.productVariantList[index].nonFunctional.qty,
          find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
          remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
        }
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.productVariantList[index].nonFunctional.preCart = null;
          this.tradeInCart = res?.data;
          this.storageService.setReComCart(this.tradeInCart);
          this.addCartLineDataToProductVariant();

        }, error => {
          this.toaster.error(error?.error?.message);
        });
        return;
      }
      if (qty < 1 && (this.productVariantList[index]?.brand?.check_find_my_device_status === false || this.productVariantList[index]?.brand?.check_google_account_removed === false)) {
        this.productVariantList[index].nonFunctional.qty = 0;
        this.productVariantList[index].nonFunctional.preCart = null;
        let payload = {
          lines: []
        };
        if (this.productVariantList[index].functional.preCart) {
          payload.lines.push({
            product_code: this.productVariantList[index].code,
            product_quality: EProductQualityTypes.FUNCTIONAL,
            qty: this.productVariantList[index].functional.qty,
            find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
            remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
          });
        }
        this.tradeInService.tradeInPreCart(payload).subscribe(res => {
          this.productVariantList[index].preCart = res?.data;
          this.addPreCartDataToProductVariant(res?.data);
        }, error => {
          this.toaster.error(error?.error?.message);
          this.productVariantList[index].preCart = null;
        })
        return;
      }
      this.productVariantList[index].nonFunctional = {
        ...this.productVariantList[index].nonFunctional,
        qty: qty,
        totalPrice: (parseFloat(this.productVariantList[index].price[0]?.price_ex_vat) * parseFloat(qty)).toFixed(2)
      }
      let payload = {
        lines: []
      };
      if (this.productVariantList[index].functional.preCart) {
        payload.lines.push({
          product_code: this.productVariantList[index].code,
          product_quality: EProductQualityTypes.FUNCTIONAL,
          qty: this.productVariantList[index].functional.qty,
          find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
          remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
        });
      }
      payload.lines.push({
        product_code: this.productVariantList[index].code,
        product_quality: EProductQualityTypes.NON_FUNCTIONAL,
        qty: this.productVariantList[index].nonFunctional.qty,
        find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
        remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
      });
      // const payload = {
      //   product_code: this.productVariantList[index].code,
      //   product_quality: EProductQualityTypes.NON_FUNCTIONAL,
      //   qty: this.productVariantList[index].nonFunctional.qty,
      //   find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
      // }
      this.tradeInService.tradeInPreCart(payload).subscribe(res => {
        this.productVariantList[index].preCart = res?.data;
        this.addPreCartDataToProductVariant(res?.data);
      }, error => {
        this.toaster.error(error?.error?.message);
        this.productVariantList[index].nonFunctional.preCart = null;
        this.productVariantList[index].preCart = null;
      })
      // this.productVariantList[index].nonFunctional.qty = qty;
      // this.productVariantList[index].nonFunctional.totalPrice = (parseFloat(this.productVariantList[index].price[0]?.price_ex_vat) * parseFloat(qty)).toFixed(2);
    }
  }
  restrictNanInput(event) {
    const key = event.key;
    const controlKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'];
    // Allow digits, backspace, delete, arrow keys, and decimal point
    if (/[0-9]/.test(key) || controlKeys?.includes(key)) {
      return true;
    }

    event.preventDefault();
    return false;
  }
  onChangeQty(event, index, previousQty, type, line, findMyDeviceStatus) {

    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
    // Set a new timer
    this.debounceTimer = setTimeout(() => {
      let qty = event.target.value;
      const prevQty = previousQty ? previousQty : findMyDeviceStatus ? 1 : 0;
      if (qty < 0 || qty === '' || qty === null || qty === undefined || isNaN(qty)) {
        // if (type === EProductQualityTypes.FUNCTIONAL) {
        //   this.productVariantList[index].functional.qty = "";
        //   this.productVariantList[index].functional.line = null;
        //   this.productVariantList[index].functional.preCart = null;
        // }
        // else {
        //   this.productVariantList[index].nonFunctional.qty = "";
        //   this.productVariantList[index].nonFunctional.line = null;
        //   this.productVariantList[index].nonFunctional.preCart = null;
        // }
        // this.updateQty(index, prevQty, type, line);

        if (type === EProductQualityTypes.FUNCTIONAL) {
          // item.functional = {
          //   ...item.functional,
          //   qty: '',
          //   line: null,
          //   preCart: null
          // };
          if (line) {
            // have to delete line
            this.productVariantList[index].functional.qty = "";
            this.productVariantList[index].functional.totalPrice = 0.00;
            this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
              this.productVariantList[index].functional.line = null;
              this.productVariantList[index].functional.preCart = null;
              this.tradeInCart = res?.data;
              this.addCartLineDataToProductVariant();
              this.storageService.setReComCart(this.tradeInCart);
            }, error => {
              this.toaster.error(error?.error?.message);
              this.productVariantList[index].functional.qty = this.productVariantList[index].functional.line.qty;
            });
            return;
          }
          this.productVariantList[index].functional.qty = "";
          this.productVariantList[index].functional.totalPrice = 0.00;
          this.productVariantList[index].functional.preCart = null;
          let payload = {
            lines: []
          };
          if (this.productVariantList[index].nonFunctional.preCart) {
            payload.lines.push({
              product_code: this.productVariantList[index].code,
              product_quality: EProductQualityTypes.NON_FUNCTIONAL,
              qty: this.productVariantList[index].nonFunctional.qty,
              find_my_device_status_disabled: this.productVariantList[index].nonFunctional.find_my_device_status_disabled,
              remove_google_account_status_disabled: this.productVariantList[index].nonFunctional.remove_google_account_status_disabled,
            });
          }
          this.tradeInService.tradeInPreCart(payload).subscribe(res => {
            this.productVariantList[index].preCart = res?.data;
            this.addPreCartDataToProductVariant(res?.data);
          }, error => {
            this.toaster.error(error?.error?.message);
            this.productVariantList[index].functional.preCart = null;
            this.productVariantList[index].preCart = null;
          })
          return;

        } else {
          // item.nonFunctional = {
          //   ...item.nonFunctional,
          //   qty: '',
          //   line: null,
          //   preCart: null
          // }

          if (line) {
            // have to delete line
            this.productVariantList[index].nonFunctional.qty = "";
            this.productVariantList[index].nonFunctional.totalPrice = 0.00;
            this.tradeInService.deleteTradeInCartLine(this.tradeInCart.uuid, line?.uuid).subscribe(res => {
              this.productVariantList[index].nonFunctional.line = null;
              this.productVariantList[index].nonFunctional.preCart = null;
              this.tradeInCart = res?.data;
              this.addCartLineDataToProductVariant();
              this.storageService.setReComCart(this.tradeInCart);
            }, error => {
              this.toaster.error(error?.error?.message);
              this.productVariantList[index].nonFunctional.qty = this.productVariantList[index].nonFunctional.line.qty;
            });
            return;
          }

            this.productVariantList[index].nonFunctional.qty = "";
            this.productVariantList[index].nonFunctional.preCart = null;
            this.productVariantList[index].nonFunctional.totalPrice = 0.00;
            let payload = {
              lines: []
            };
            if (this.productVariantList[index].functional.preCart) {
              payload.lines.push({
                product_code: this.productVariantList[index].code,
                product_quality: EProductQualityTypes.FUNCTIONAL,
                qty: this.productVariantList[index].functional.qty,
                find_my_device_status_disabled: this.productVariantList[index].functional.find_my_device_status_disabled,
                remove_google_account_status_disabled: this.productVariantList[index].functional.remove_google_account_status_disabled,
              });
            }
            this.tradeInService.tradeInPreCart(payload).subscribe(res => {
              this.productVariantList[index].preCart = res?.data;
              this.addPreCartDataToProductVariant(res?.data);
            }, error => {
              this.toaster.error(error?.error?.message);
              this.productVariantList[index].preCart = null;
            })
            return;
        }

        // this.productVariantList = this.productVariantList.map((item, i) => {
        //   if (i === index) {

        //   }
        //   return item;
        // });
        return;
      }
      qty = parseInt(qty);
      this.updateQty(index, qty, type, line);
    }, this.debounceTimeMs);

  }
  onChangeNonFunctionQty(event, previousQty, index, line, findMyDeviceStatus) {
    let qty = event.target.value;
    const prevQty = previousQty ? previousQty : findMyDeviceStatus ? 1 : 0;
    if (qty < 0 || qty === '' || qty === null || qty === undefined) {
      this.productVariantList[index].nonFunctional.qty = prevQty;
      this.updateQty(index, prevQty, EProductQualityTypes.NON_FUNCTIONAL, line);
      return;
    }
    qty = parseInt(qty);
    this.updateQty(index, qty, EProductQualityTypes.NON_FUNCTIONAL, line);
  }
  openMiniCartDetailsModal(miniCartDetailsModal) {
    this.miniCartDetailsModalReference = this.modalService.open(miniCartDetailsModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-500 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });

    this.miniCartDetailsModalReference.result.then((result) => {
      // this.selectedQuoteLine = null;
    }).catch(e => {

    });
  }

  addToTradeInCart(miniCartDetailsModal) {
    let selectedProductVariant = this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code);

    // if any line is available in cart then we have to add new line in the existing cart
    const isAnyLineInCart = selectedProductVariant.functional.line || selectedProductVariant.nonFunctional.line;
    if (isAnyLineInCart) {
      this.openMiniCartDetailsModal(miniCartDetailsModal);

      return;
    }
    // for functional
    if (selectedProductVariant?.functional?.qty > 0 && ((selectedProductVariant?.functional?.find_my_device_status_disabled === true) || (selectedProductVariant?.functional?.remove_google_account_status_disabled === true))) {
      const payload = {
        product_code: selectedProductVariant?.code,
        product_quality: EProductQualityTypes.FUNCTIONAL,
        qty: selectedProductVariant?.functional?.qty,
        find_my_device_status_disabled: selectedProductVariant?.functional?.find_my_device_status_disabled,
        remove_google_account_status_disabled: selectedProductVariant?.functional?.remove_google_account_status_disabled,
      }
      if (this.tradeInCart?.uuid) {
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.saveCart(res?.data);
          this.addCartLineDataToProductVariant();
          selectedProductVariant = this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code);
          if (selectedProductVariant?.nonFunctional?.qty > 0 && selectedProductVariant?.nonFunctional?.find_my_device_status_disabled === true) {
            const payload = {
              product_code: selectedProductVariant?.code,
              product_quality: EProductQualityTypes.NON_FUNCTIONAL,
              qty: selectedProductVariant?.nonFunctional?.qty,
              find_my_device_status_disabled: selectedProductVariant?.nonFunctional?.find_my_device_status_disabled,
              remove_google_account_status_disabled: selectedProductVariant?.nonFunctional?.remove_google_account_status_disabled,
            }
            this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
              this.saveCart(res?.data);
              this.addCartLineDataToProductVariant();

              // open mini cart details modal
              this.openMiniCartDetailsModal(miniCartDetailsModal);

            }, error => {
              this.errorShowTradeInCart(error?.error?.message);
            });
          } else {
            // open mini cart details modal
            this.openMiniCartDetailsModal(miniCartDetailsModal);

          }

        }, error => {
          this.errorShowTradeInCart(error?.error?.message);
        });
        return;
      }
      else {
        let newPayload = {
          lines: [
            {
              product_code: selectedProductVariant?.code,
              product_quality: EProductQualityTypes.FUNCTIONAL,
              qty: selectedProductVariant?.functional?.qty,
              find_my_device_status_disabled: selectedProductVariant?.functional?.find_my_device_status_disabled,
              remove_google_account_status_disabled: selectedProductVariant?.functional?.remove_google_account_status_disabled,
            }
          ]
        }
        this.tradeInService.tradeInCart(newPayload).subscribe(res => {
          this.saveCart(res?.data);
          this.addCartLineDataToProductVariant();
          selectedProductVariant = this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code);
          if (selectedProductVariant?.nonFunctional?.qty > 0 && selectedProductVariant?.nonFunctional?.find_my_device_status_disabled === true) {
            const payload = {
              product_code: selectedProductVariant?.code,
              product_quality: EProductQualityTypes.NON_FUNCTIONAL,
              qty: selectedProductVariant?.nonFunctional?.qty,
              find_my_device_status_disabled: selectedProductVariant?.nonFunctional?.find_my_device_status_disabled,
              remove_google_account_status_disabled: selectedProductVariant?.nonFunctional?.remove_google_account_status_disabled,
            }
            this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
              this.saveCart(res?.data);
              this.addCartLineDataToProductVariant();

              // this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code).nonFunctional.line = res?.data;

              // open mini cart details modal
              this.openMiniCartDetailsModal(miniCartDetailsModal);

            }, error => {
              this.errorShowTradeInCart(error?.error?.message);
            });
          } else {
            // open mini cart details modal
            this.openMiniCartDetailsModal(miniCartDetailsModal);

          }

        }, error => {
          this.errorShowTradeInCart(error?.error?.message);
        });
      }

      return;
    }
    // for non functional
    if (selectedProductVariant?.nonFunctional?.qty > 0 && (selectedProductVariant?.nonFunctional?.find_my_device_status_disabled === true || selectedProductVariant?.nonFunctional?.remove_google_account_status_disabled === true)) {
      const payload = {
        product_code: selectedProductVariant?.code,
        product_quality: EProductQualityTypes.NON_FUNCTIONAL,
        qty: selectedProductVariant?.nonFunctional?.qty,
        find_my_device_status_disabled: selectedProductVariant?.nonFunctional?.find_my_device_status_disabled,
        remove_google_account_status_disabled: selectedProductVariant?.nonFunctional?.remove_google_account_status_disabled,
      }
      if (this.tradeInCart?.uuid) {
        this.tradeInService.addLineTradeInCart(this.tradeInCart?.uuid, payload).subscribe(res => {
          this.saveCart(res?.data);
          this.addCartLineDataToProductVariant();
          // this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code).nonFunctional.line = res?.data;
          // open mini cart details modal
          this.openMiniCartDetailsModal(miniCartDetailsModal);
        }, error => {
          this.errorShowTradeInCart(error?.error?.message);
        });
      }
      else {
        let newPayload = {
          lines: [
            {
              product_code: selectedProductVariant?.code,
              product_quality: EProductQualityTypes.NON_FUNCTIONAL,
              qty: selectedProductVariant?.nonFunctional?.qty,
              find_my_device_status_disabled: selectedProductVariant?.nonFunctional?.find_my_device_status_disabled,
              remove_google_account_status_disabled: selectedProductVariant?.nonFunctional?.remove_google_account_status_disabled,
            }
          ]
        }
        this.tradeInService.tradeInCart(newPayload).subscribe(res => {
          this.saveCart(res?.data);
          this.addCartLineDataToProductVariant();
          // open mini cart details modal
          this.openMiniCartDetailsModal(miniCartDetailsModal);

        }, error => {
          this.errorShowTradeInCart(error?.error?.message);
        });
      }
      return;
    }
  }

  saveCart(cart) {
    this.storageService.setReComCart(cart);
    this.tradeInCart = cart;
  }

  addCartLineDataToProductVariant() {
    this.tradeInCart?.lines?.map((line) => {
      const productCode = line?.product?.code;

      let index = this.productVariantList.findIndex(variant => {

        return variant?.code == productCode;
      });

      if (index > -1) {
        if (line?.product_quality === EProductQualityTypes.FUNCTIONAL) {
          this.productVariantList[index].functional = {
            ...this.productVariantList[index].functional,
            qty: line?.qty,
            find_my_device_status_disabled: line?.product?.brand?.check_find_my_device_status === false ? true : true,
            remove_google_account_status_disabled: line?.product?.brand?.check_google_account_removed === false ? true : true,
            line: line,
            preCart: null
          };
          this.productVariantList[index].preCart = null;

          // this.productVariantList[index] = {
          //   ...this.productVariantList[index],
          //   functional: {
          //     ...this.productVariantList[index].functional,
          //     qty: line?.qty,
          //     find_my_device_status_disabled: line?.product?.brand?.check_find_my_device_status,
          //     line: line
          //   }
          // };
          // this.productVariantList[index].functional.line = line;
        }
        if (line?.product_quality === EProductQualityTypes.NON_FUNCTIONAL) {
          this.productVariantList[index].nonFunctional = {
            ...this.productVariantList[index].nonFunctional,
            qty: line?.qty,
            find_my_device_status_disabled: line?.product?.brand?.check_find_my_device_status === false ? true : true,
            remove_google_account_status_disabled: line?.product?.brand?.check_google_account_removed === false ? true : true,
            line: line,
            preCart: null,

          };
          this.productVariantList[index].preCart = null;
          // this.productVariantList[index] = {
          //   ...this.productVariantList[index],
          //   nonFunctional: {
          //     ...this.productVariantList[index].nonFunctional,
          //     qty: line?.qty,
          //     find_my_device_status_disabled: line?.product?.brand?.check_find_my_device_status,
          //     line: line
          //   }
          // };
        }
      }
    });
    this.selectedVariant = this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code);
    // this.preCart = null;
  }
  addPreCartDataToProductVariant(preCart) {
    preCart?.lines?.map((line) => {
      const productCode = line?.product?.code;
      let index = this.productVariantList.findIndex(variant => variant?.code == productCode);
      if (index > -1) {
        if (line?.product_quality === EProductQualityTypes.FUNCTIONAL) {
          this.productVariantList[index].functional = {
            ...this.productVariantList[index].functional,
            preCart: line
          };
        }
        if (line?.product_quality === EProductQualityTypes.NON_FUNCTIONAL) {
          this.productVariantList[index].nonFunctional = {
            ...this.productVariantList[index].nonFunctional,
            preCart: line
          };
        }
      }
    });
    this.selectedVariant = this.productVariantList.find(variant => variant?.code === this.selectedVariant?.code);
  }
  navigateToCartDetails() {
    this.router.navigate([`trade-in/cart-details`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }
  getTotalPrice(first, second)  {
    const functionalTotal = first ? parseFloat(first).toFixed(2) : "0";
    const nonFunctionalTotal = second ? parseFloat(second).toFixed(2) : "0";
    return Number((parseFloat(functionalTotal) + parseFloat(nonFunctionalTotal)).toFixed(2));
  }
  ngOnDestroy() {
    // Clear the timeout when the component is destroyed to avoid memory leaks
    if (this.debounceTimer) {
      clearTimeout(this.debounceTimer);
    }
  }
  setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
  }

  ngOnInit(): void {
    localStorage.setItem('tradeInLastProductDetailsUrl', JSON.stringify(this.router.url));
    this.setTradeInBusinessPartner();
  }

}
