<section class="inner-content-wrapper inner-content-blue-bg">
  <div class="container">
    <div class="row g-4" *ngIf="tokenValidationError">
      <div class="col-lg-10 offset-1">
        <div class="row g-4 mt-4">
          <div class="col-md-12">
            <div class="alert alert-danger" role="alert">
              <p [innerHTML]="tokenValidationError"></p>
              <button (click)="reSendInvitation()" *ngIf="showReSend"
                class="btn btn-sm btn-primary rounded-btn mb-2 mr-2">
                <small> {{'SIGN_UP.RESEND_THE_INVITATION' | transloco}}</small></button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-4" *ngIf="invitationResendMsg">
      <div class="col-lg-10 offset-1">
        <div class="row g-4 mt-4">
          <div class="col-md-12">
            <div class="alert alert-success" role="alert">
              <p [innerHTML]="invitationResendMsg"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-4" *ngIf="signUpSuccessMessage">
      <div class="col-lg-10 offset-1">
        <div class="row g-4 mt-4">
          <div class="col-md-12">
            <div class="alert alert-success" role="alert">
              <p [innerHTML]="signUpSuccessMessage"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row g-4" *ngIf=" !signUpSuccessMessage && !tokenValidationError && !invitationResendMsg">
      <div class="col-lg-10 offset-1">
        <form class="needs-validation" [formGroup]="customerInformation" (ngSubmit)="createCustomer()">
          <h3 class="mb-3">{{'SIGN_UP.CUSTOMER_INFORMATION' | transloco}}</h3>
          <div class="row g-4" formGroupName="contact_person">
            <!-- <div class="col-md-3">
              <label for="gender" class="form-label">Geslacht</label>
              <div ngbRadioGroup name="gender" ngDefaultControl formControlName="gender"
                class="radio_group radio-row-wrap box-radio-group-sim-only box-radio-group row mb-0 pb-4">
                <div class="col-auto">
                  <div class="radio-group-col radio-col-auto px-0">
                    <label ngbButtonLabel class="default-radio mb-2">
                      <div class="radio-btn">
                        <input ngbButton type="radio" value="M">
                        <span class="radioBullet"></span>
                      </div>
                      <span class="label">Mannelijk</span>
                    </label>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="radio-group-col radio-col-auto px-0">
                    <label ngbButtonLabel class="default-radio mb-2">
                      <div class="radio-btn">
                        <input ngbButton type="radio" value="F">
                        <span class="radioBullet"></span>
                      </div>
                      <span class="label">Vrouwelijk</span>
                    </label>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="radio-group-col radio-col-auto px-0">
                    <label ngbButtonLabel class="default-radio mb-2">
                      <div class="radio-btn">
                        <input ngbButton type="radio" value="">
                        <span class="radioBullet"></span>
                      </div>
                      <span class="label">Anderen</span>
                    </label>
                  </div>
                </div>
              </div>
            </div> -->
            <div class="col-md-4">
              <label for="initials" class="form-label">{{'SIGN_UP.INITIALS' | transloco}}</label>
              <input type="text" class="form-control" id="initials" [placeholder]="'SIGN_UP.INITIALS' | transloco" formControlName="initials"
                [ngClass]="{ 'is-invalid': (customerInformation.controls?.contact_person?.controls?.initials?.errors) || beValidationError?.contact_person?.initials}">
              <div *ngIf="customerInformation.controls?.contact_person?.controls?.initials?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.initials?.errors?.required">
                  {{'SIGN_UP.INITIALS_ARE_REQUIRED' | transloco}}</div>
              </div>
              <div *ngIf="beValidationError?.contact_person?.initials" class="invalid-feedback">
                {{beValidationError?.contact_person?.initials?.message}}
              </div>
            </div>
            <div class="col-md-4">
              <label for="surname_prefix" class="form-label">{{'SIGN_UP.MIDDLE_NAME' | transloco}}</label>
              <input type="text" class="form-control" id="surname_prefix" [placeholder]="'SIGN_UP.MIDDLE_NAME' | transloco"
                formControlName="surname_prefix"
                [ngClass]="{ 'is-invalid': (submitted && customerInformation.controls?.contact_person?.controls?.surname_prefix?.errors) || beValidationError?.contact_person?.surname_prefix}">
              <div *ngIf="submitted && customerInformation.controls?.contact_person?.controls?.surname_prefix?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.surname_prefix?.errors?.required">
                  {{'SIGN_UP.MIDDLE_NAME_REQUIRED' | transloco}}
                </div>
              </div>
              <div *ngIf="beValidationError?.contact_person?.surname_prefix" class="invalid-feedback">
                {{beValidationError?.contact_person?.surname_prefix?.message}}
              </div>
            </div>
            <div class="col-md-4">
              <label for="last_name" class="form-label">{{'SIGN_UP.LAST_NAME' | transloco}}</label>
              <input type="text" class="form-control" id="last_name" [placeholder]="'SIGN_UP.LAST_NAME' | transloco"
                formControlName="last_name"
                [ngClass]="{ 'is-invalid': (customerInformation.controls?.contact_person?.controls?.last_name?.errors) || beValidationError?.contact_person?.last_name || beValidationError?.last_name  }">
              <div *ngIf="customerInformation.controls?.contact_person?.controls?.last_name?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.last_name?.errors?.required">{{'SIGN_UP.LAST_NAME_REQUIRED' | transloco}}</div>
              </div>
              <div *ngIf="beValidationError?.last_name || beValidationError?.contact_person?.last_name" class="invalid-feedback">
                {{beValidationError?.contact_person?.last_name?.message || beValidationError?.last_name?.message}}
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="email" class="form-label">{{'SIGN_UP.E_MAIL_ADDRESS' | transloco}}</label>
              <input type="email" class="form-control" id="email" placeholder="example@example.com" required
                formControlName="email"
                [ngClass]="{ 'is-invalid': (customerInformation.controls?.contact_person?.controls?.email?.errors) || beValidationError?.contact_person?.email}">
              <div *ngIf="customerInformation.controls?.contact_person?.controls?.email?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.email?.errors?.required">{{'SIGN_UP.EMAIL_REQUIRED' | transloco}}</div>
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.email?.errors?.email">{{'SIGN_UP.EMAIL_FORMAT_INVALID' | transloco}}</div>
              </div>
              <div *ngIf="beValidationError?.contact_person?.email" class="invalid-feedback">
                {{beValidationError?.contact_person?.email?.message}}
              </div>
            </div>
            <div class="col-md-6 mt-3">
              <label for="mobile_phone_number" class="form-label">{{'SIGN_UP.PHONE_NUMBER' | transloco}}</label>
              <div class="input-group">
                <span class="input-group-text" id="mobile_phone_number">
                  <i class="icon-phone1"></i>
                </span>
                <input type="text" class="form-control" [placeholder]="'SIGN_UP.PHONE_NUMBER' | transloco"
                  formControlName="mobile_phone_number" maxlength="12"
                  [ngClass]="{ 'is-invalid': (customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.touched && customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.errors) || beValidationError?.contact_person?.mobile_phone_number}">
                <div
                  *ngIf="customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.touched && customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.errors"
                  class="invalid-feedback">
                  <div
                    *ngIf="customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.errors?.minlength || customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.errors?.maxlength">
                    {{'SIGN_UP.MOBILE_LENGTH' | transloco}}
                    {{customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.errors?.minlength?.requiredLength
                    ||
                    customerInformation.controls?.contact_person?.controls?.mobile_phone_number?.errors?.maxlength?.requiredLength}}
                  </div>
                </div>
                <div *ngIf="beValidationError?.contact_person?.mobile_phone_number" class="invalid-feedback">
                  {{beValidationError?.contact_person?.mobile_phone_number?.message}}
                </div>

              </div>
              <small class="text-muted">{{'SIGN_UP.PHONE_NUMBER' | transloco}} {{'SIGN_UP.PATTERN' | transloco}} +31612345678 {{'SIGN_UP.OR' | transloco}} 0612345678</small>
            </div>
            <div class="col-md-4 mt-3">
              <label for="password" class="form-label">{{'SIGN_UP.PASSWORD' | transloco}}</label>
              <input type="password" class="form-control" id="password" [placeholder]="'SIGN_UP.PASSWORD' | transloco" required
                formControlName="password"
                [ngClass]="{ 'is-invalid': customerInformation.controls?.contact_person?.controls?.password?.errors}">
              <div *ngIf="customerInformation.controls?.contact_person?.controls?.password?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.password?.errors?.required">
                  {{'SIGN_UP.PASSWORD_IS_REQUIRED' | transloco}}</div>
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.password?.errors?.minlength">
                  {{'SIGN_UP.PASSWORD_LENGTH' | transloco}}
                  {{customerInformation.controls?.contact_person?.controls?.password?.errors?.minlength?.requiredLength}}
                </div>
              </div>
              <div class="danger-text" *ngIf="customerInformation?.get('contact_person')?.get('password')?.hasError('length') && customerInformation?.get('contact_person')?.get('password').touched">
                You password must be at least 10 characters.
              </div>
              <div class="danger-text" *ngIf="customerInformation?.get('contact_person')?.get('password')?.hasError('numberError') && customerInformation?.get('contact_person')?.get('password').touched">
                You password must contain at least 1 number.
              </div>
              <div class="danger-text" *ngIf="customerInformation?.get('contact_person')?.get('password')?.hasError('upperCaseError') && customerInformation?.get('contact_person')?.get('password').touched">
                You password must contain at least 1 Uppercase Character.
              </div>
              <div class="danger-text" *ngIf="customerInformation?.get('contact_person')?.get('password')?.hasError('lowerCaseError') && customerInformation?.get('contact_person')?.get('password').touched">
                You password must contain at least 1 Lowercase Character.
              </div>
              <div class="danger-text" *ngIf="customerInformation?.get('contact_person')?.get('password')?.hasError('specialCharacterError') && customerInformation?.get('contact_person')?.get('password').touched">
                You password must contain at least 1 special character.
              </div>
            </div>
            <div class="col-md-4 mt-3">
              <label for="password_2" class="form-label">{{'SIGN_UP.PASSWORD_AGAIN' | transloco}}</label>
              <input type="password" class="form-control" id="password_2" [placeholder]="'SIGN_UP.PASSWORD_AGAIN' | transloco" required
                formControlName="password_2"
                [ngClass]="{ 'is-invalid': customerInformation.controls?.contact_person?.controls?.password_2?.errors}">
              <div *ngIf="customerInformation.controls?.contact_person?.controls?.password_2?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.password_2?.errors?.required">
                  {{'SIGN_UP.PASSWORD_IS_REQUIRED' | transloco}}
                </div>
                <div *ngIf="customerInformation.controls?.contact_person?.controls?.password_2?.errors?.mismatch">
                  {{'SIGN_UP.PASSWORDS_DO_NOT_MATCH' | transloco}}
                </div>
              </div>
            </div>
            <div class="row align-items-center mb-3 mt-2">
              <ul>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_1' | transloco}}.</li>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_2' | transloco}}.</li>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_3' | transloco}}.</li>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_4' | transloco}}.</li>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_5' | transloco}}.</li>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_6' | transloco}}.</li>
                <li>{{'ACCOUNT.PASSWORD_VALIDATION_TEXT_7' | transloco}}.</li>
              </ul>
            </div>
          </div>

          <hr class="my-lg-5 my-4">
          <h3 class="mb-1">{{'SIGN_UP.CUSTOMER_ADDRESS' | transloco}}</h3>
          <h6 class="mb-2">{{'SIGN_UP.ENTER_YOUR_ADDRESS' | transloco}}</h6>
          <div class="row g-4 mb-1">
            <div class="col-md-7">
              <ng-select [placeholder]="'SIGN_UP.SELECT_COUNTRY' | transloco" [disabled]="!can_overwrite_address" [items]="country"
                bindLabel="name" bindValue="country_code" [(ngModel)]="selectedCountry"
                [ngModelOptions]="{standalone: true}" (change)="onSelectCountry($event)">
              </ng-select>
            </div>
          </div>
          <div class="row mb-2" *ngIf="selectedCountry && selectedCountry !== NL_CODE">
            <div class="col-7">
              <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
                <div class="form-group kpn-form-group">
                  <div
                    class="kpn-form-group handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                    [ngClass]="{'search-active' : searchFocused}">
                    <i class="search-icon kpn-ui-icon ui-search"></i>
                    <div class="ng-autocomplete">
                      <ng-autocomplete #auto class="" [data]="addressList" [disabled]="!can_overwrite_address"
                                       [placeholder]="'CUSTOMER.SEARCH_BY_ZIP_CODE_HOUSE_NUMBER_HOUSE_NUMBER_EXTENSION' | transloco"  [searchKeyword]="keyword"
                        (selected)='onSelectedAddress($event)' [(ngModel)]="addressSearchValue"
                        [ngModelOptions]="{standalone: true}" (inputChanged)='searchAddress($event)'
                        (focusin)='onFocused()' (focusout)="onFocusedOut()" [itemTemplate]="itemTemplate"
                        [isLoading]="isLoading" [focusFirst]="true" (inputCleared)="searchCleared()"
                        [debounceTime]="600" [notFoundTemplate]="notFoundTemplate">
                      </ng-autocomplete>

                      <ng-template #itemTemplate let-item>
                        <div class="auto-template">
                          <span [innerHTML]="item.value"></span> &nbsp;
                          <i *ngIf="item?.precision !==eAddressPrecision.ADDRESS" class="kphicon-uniE920"></i>
                        </div>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <div [innerHTML]="notFound"></div>
                      </ng-template>
                    </div>
                    <span class="text-muted" *ngIf="!foreignAddressSearchError">
                      <small>{{'SIGN_UP.ADDRESS_SEARCH_LABEL' | transloco}}</small>
                    </span>
                    <span class="danger-text" *ngIf="foreignAddressSearchError">
                      <small>{{foreignAddressSearchError}}</small>
                    </span>

                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row g-4" *ngIf="selectedCountry" formGroupName="contact_address">
            <div class="col-md-4">
              <label for="zip_code" class="form-label">{{'SIGN_UP.POSTCODE' | transloco}}</label>
              <input type="text" class="form-control" id="zip_code" placeholder="Postcode" formControlName="zip_code"
                oninput="this.value = this.value.toUpperCase()" (keyup)="onEditAddressSearch()"
                [ngClass]="{ 'is-invalid': (submitted && customerInformation.controls?.contact_address?.controls?.zip_code?.errors) || beValidationError?.contact_address?.zip_code}">
              <div *ngIf="submitted && customerInformation.controls?.contact_address?.controls?.zip_code?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_address?.controls?.zip_code?.errors?.required">
                  {{'SIGN_UP.ZIPCODE_IS_REQUIRED' | transloco}}</div>
              </div>
              <div *ngIf="beValidationError?.contact_address?.zip_code" class="invalid-feedback">
                {{beValidationError?.contact_address?.zip_code?.message ||
                beValidationError?.contact_address?.zip_code?.[0]}}
              </div>
            </div>
            <div class="col-md-4">
              <label for="house_number" class="form-label">{{'SIGN_UP.HOUSE_NUMBER' | transloco}}</label>
              <input type="text" class="form-control" id="house_number" [placeholder]="'SIGN_UP.HOUSE_NUMBER' | transloco"
                formControlName="house_number" (keyup)="onEditAddressSearch()"
                [ngClass]="{ 'is-invalid': (submitted && customerInformation.controls?.contact_address?.controls?.house_number?.errors) || beValidationError?.contact_address?.house_number}">
              <div *ngIf="submitted && customerInformation.controls?.contact_address?.controls?.house_number?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_address?.controls?.house_number?.errors?.required">
                 {{'SIGN_UP.HOUSE_NUMBER_REQUIRED' | transloco}}
                </div>
              </div>
              <div *ngIf="beValidationError?.contact_address?.house_number" class="invalid-feedback">
                {{beValidationError?.contact_address?.house_number?.message ||
                beValidationError?.contact_address?.house_number?.[0]}}
              </div>
            </div>
            <div class="col-md-4">
              <label for="house_number_extension" class="form-label">{{'SIGN_UP.HOUSE_NUMBER_ADDITION' | transloco}}</label>
              <input type="text" class="form-control" id="house_number_extension" [placeholder]="'SIGN_UP.HOUSE_NUMBER_ADDITION' | transloco"
                formControlName="house_number_extension" (keyup)="onEditAddressSearch()">
            </div>
            <div class="col-md-4 field-with-spinner mt-3">
              <label for="street" class="form-label">{{'SIGN_UP.STREET' | transloco}}</label>
              <input type="text" class="form-control kpn-form-control" id="street" [placeholder]="'SIGN_UP.STREET' | transloco"
                formControlName="street"
                [ngClass]="{ 'is-invalid': (submitted && customerInformation.controls?.contact_address?.controls?.street?.errors) || beValidationError?.contact_address?.street}">
              <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner mt-5 mr-3"
                role="status">
              </div>
              <div *ngIf="submitted && customerInformation.controls?.contact_address?.controls?.street?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_address?.controls?.street?.errors?.required">{{'SIGN_UP.STREET_IS_REQUIRED' | transloco}}</div>
              </div>
              <div *ngIf="beValidationError?.contact_address?.street" class="invalid-feedback">
                {{beValidationError?.contact_address?.street?.message ||
                beValidationError?.contact_address?.street?.[0]}}
              </div>
            </div>
            <div class="col-md-4 field-with-spinner mt-3">
              <label for="city" class="form-label">{{'SIGN_UP.CITY' | transloco}}</label>
              <input type="text" class="form-control kpn-form-control" id="city" [placeholder]="'SIGN_UP.CITY' | transloco"
                formControlName="city"
                [ngClass]="{ 'is-invalid': (submitted && customerInformation.controls?.contact_address?.controls?.street?.errors) || beValidationError?.contact_address?.city}">
              <div *ngIf="isAddressFetching" class="spinner-border spinner-border-sm field-spinner mt-5 mr-3"
                role="status">
              </div>
              <div *ngIf="submitted && customerInformation.controls?.contact_address?.controls?.city?.errors"
                class="invalid-feedback">
                <div *ngIf="customerInformation.controls?.contact_address?.controls?.city?.errors?.required">{{'SIGN_UP.CITY_IS_REQUIRED' | transloco}}</div>
              </div>
              <div *ngIf="beValidationError?.contact_address?.city" class="invalid-feedback">
                {{beValidationError?.contact_address?.city?.message || beValidationError?.contact_address?.city?.[0]}}
              </div>
            </div>
          </div>
          <div class="row g-4 mt-4" *ngIf="addressSearchError">
            <div class="col-md-12">
              <div class="alert alert-danger" role="alert">
                {{addressSearchError}}
              </div>
            </div>
          </div>

          <div class="row g-4 mt-4" *ngIf="customerCreationError">
            <div class="col-md-12">
              <div class="alert alert-danger" role="alert">
                {{customerCreationError?.message || 'Customer Creation failed'}}
              </div>
            </div>
          </div>
          <div class="row g-4 pt-3">
            <div class="col-md-4">
              <button class="btn px-5 btn-primary mb-0 rounded-btn" [disabled]="!selectedCountry || customerInformation?.invalid" type="submit">
                <span>
                  <i class="icon-save pe-1"></i>
                </span>
              {{'SIGN_UP.CREATE_AN_ACCOUNT' | transloco}}</button>
            </div>
          </div>
          <hr class="my-lg-5 my-4">
        </form>
      </div>
    </div>
  </div>
</section>
