import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiBaseService } from 'src/app/shared/api-base.service';

@Injectable({
    providedIn: 'root'
})
export class SignUpService {
    constructor(private apiBaseService: ApiBaseService) { }

    validateToken(token: string) {
        return this.apiBaseService.get(`invitations/user-invitation/verify/${token}`);
    }

    createCustomer(customer) {
        return this.apiBaseService.post(`contacts/customer-registration`, customer, 'v2');
    }

    submitNewAddress(data: any, contactUuid: string): Observable<any> {
        const postUri = `contacts/${contactUuid}/address`;
        return this.apiBaseService.post(postUri, data);
    }

    updateNewAddress(data: any, contactUuid: string, uuid: string): Observable<any> {
        const updateUri = `contacts/${contactUuid}/address/${uuid}`;
        return this.apiBaseService.put(updateUri, data);
    }

    getContactAddress(contactUuid: string, addressUuid: string): Observable<any> {
        const retriveUri = `contacts/${contactUuid}/address/${addressUuid}`;
        return this.apiBaseService.get(retriveUri);
    }

    reSendInvitation(expired_token: string) {
        const url = `vouchers/renew-invitation/${expired_token}`;
        return this.apiBaseService.post(url, {});
    }

    reSendActivation(uid: string, expired_token: string) {
        const url = `contacts/registration/${uid}/confirmation/${expired_token}/renew`;
        return this.apiBaseService.post(url, {}, 'v2');
    }

    activateUser(uid: string, token: string) {
        const url = `contacts/registration/${uid}/confirmation/${token}`;
        return this.apiBaseService.post(url, {}, 'v2');
    }
}
