<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container p-5">
      <div class="kpn-spinner" *ngIf="isLoading;else details_content">
        <div class="text-center">
          <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
          <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
        </div>
      </div>
      <ng-template #details_content>
        <div class="product-details-new-title pl-0">
          <a (click)="backToRMAList()" class="backword-link"><i class="kphicon-uniE906"></i>{{'RMA_DETAILS.ALL_RMA_ORDERS' | transloco}}</a>
        </div>
        <div class="confirmation-cart-overview">
          <div class="voicelog-overview order-confirmation-user-info p-5">
            <div class="row mb-4">
              <div class="col-md-4 client-info client-info-order-conf">
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">{{'CUSTOMER.COMPANY_NAME' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.contact?.company_name}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.surname">{{'ORDER_CONFIRMATION.NAME' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.contact_person?.initials}}
                      {{rmaOrder?.contact_person?.last_name}}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.phone">{{'ORDER_CONFIRMATION.TELEPHONE' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.contact_person?.mobile_phone_number}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.email">E-mail:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.contact_person?.email}}</p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 client-info client-info-order-conf">
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">{{'ORDER_CONFIRMATION.SHIPPING_ADDRESS' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">
                      <span
                        *ngIf="rmaOrder?.shipping_address?.company_name">{{rmaOrder?.shipping_address?.company_name}}<br /></span>
                      {{rmaOrder?.shipping_address?.street}} {{rmaOrder?.shipping_address?.house_number}}
                      {{rmaOrder?.shipping_address?.house_number_extension}}<br />
                      {{rmaOrder?.shipping_address?.zip_code}} {{rmaOrder?.shipping_address?.city}}
                    </p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right" i18n="@@arm.order-confirmation.address">{{'ORDER_CONFIRMATION.BILLING_ADDRESS' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">
                      {{rmaOrder?.invoice_address?.street}} {{rmaOrder?.invoice_address?.house_number}}
                      {{rmaOrder?.invoice_address?.house_number_extension}}<br />
                      {{rmaOrder?.invoice_address?.zip_code}} {{rmaOrder?.invoice_address?.city}}
                    </p>
                  </div>
                </div>
              </div>

              <div class="col-md-4 client-info client-info-order-conf">
                <div class="row" *ngIf="rmaOrder?.shipping_address?.bank_account_number">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right">{{'ORDER_CONFIRMATION.ACCOUNT_NUMBER' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.shipping_address?.bank_account_number}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right">{{'ORDER_CONFIRMATION.ORDER_NUMBER' | transloco }}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.rma?.reference}}</p>
                  </div>
                </div>
                <div class="row" *ngIf="rmaOrder?.rma?.customer_reference">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right">{{siteProfile?.reference_label || 'Klant Referentie'}}:
                    </p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.rma?.customer_reference}}</p>
                  </div>
                </div>
                <div class="row" *ngIf="rmaOrder?.rma?.po_number">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right">{{siteProfile?.po_number_label || 'PO Number'}}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.rma?.po_number}}</p>
                  </div>
                </div>
                <div class="row">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right">{{'RMA_DETAILS.STATUS' | transloco}}:</p>
                  </div>
                  <div class="col">
                    <span class="mb-5">
                      <button class="flat-grey-light" placement="bottom">
                        <span class="dark-orange-tag-pill mr-5"> <b> {{rmaOrder?.rma?.status | uppercase }}</b></span>
                      </button>
                    </span>
                  </div>
                </div>
                <div class="row" *ngIf="rmaOrder?.rma?.approved_by">
                  <div class="col-auto col-add-info-title pr-0">
                    <p class="body-text-1 mb-0 text-right">{{'RMA_DETAILS.APPROVED_BY' | transloco}}:</p>
                  </div>
                  <div class="col">
                    <p class="body-text-1 info-desc mb-0">{{rmaOrder?.rma?.approved_by?.name}}</p>
                  </div>
                </div>
                <div class="row mt-3"
                  *ngIf="!user?.contact_group?.is_external && rmaOrder?.rma?.status === eRmaStatusType.NEW">
                  <div class="col">
                    <span class="mb-5">
                      <button class="flat-grey-light" [disabled]="approveLoading" placement="bottom"
                        (click)="approveRma()">
                        <span class="dark-blue-tag-pill mr-5">
                          <span *ngIf="approveLoading" class="spinner-border spinner-border-sm mr-2" role="status"
                            aria-hidden="true">
                          </span>
                          <b>{{'RMA_DETAILS.APPROVE' | transloco}}</b>
                        </span>
                      </button>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cart-items-wrap">
            <div class="cart-item cart-item-single-box p-5">
              <div class="cart-item-row mb-3 border-0">
                <div class="row">
                  <div class="col-lg">
                    <div class="row">
                      <div class="col">
                        <b>Product</b>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row pt-4 action-cart-price align-items-center justify-content-end">
                      <div class="col-auto">
                        <div class="cart-qty-amount text-center"><b>{{'CART.NUMBER' | transloco }}</b></div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col text-right"><b>{{'PRODUCT_DETAILS.PRICE' | transloco }}</b></div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col text-right"><b>{{'ORDER_CONFIRMATION.TOTAL_PRICE' | transloco }}</b></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="cart-item-row mb-3 border-0" *ngFor="let line of rmaOrder?.rma_line; let i = index">
                <div class="row">
                  <div class="col-lg">
                    <div class="row">
                      <div class="col-auto px-0 hide-on-pad">
                        <div class="cart-item-image">
                          <img *ngIf="line?.product?.image || line?.product?.product_thumbnail"
                            src="{{line?.product?.image || line?.product?.product_thumbnail}}" alt="">
                          <img
                            *ngIf="!line?.product?.image && !line?.product?.product_thumbnail && (!line.brand?.includes('cisco') || !line.brand?.includes('Cisco'))"
                            src="/assets/images/noimage.png" alt="">
                          <img
                            *ngIf="!line?.product?.image && !line?.product?.product_thumbnail && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                            src="/assets/images/cisco-networking.png" alt="">
                        </div>
                      </div>
                      <div class="col">
                        <div class="cart-item-details">
                          <h4 class="title-4 mb-0 pt-2">{{line?.product?.brand__name}}</h4>
                          <p class="model-desc">{{line?.product?.code}}-{{line?.product?.name}}</p>
                          <span *ngIf="line?.delivery_method" class="mb-5">
                            <button class="flat-grey-light" placement="bottom">
                              <h6 class="title-6 mb-0 mr-2"> {{'ORDER_CONFIRMATION.DELIVERY_METHOD' | transloco }}: </h6>
                              <span class="dark-orange-tag-pill mr-5"> <b> {{line?.delivery_method?.name}}</b></span>
                            </button>
                          </span>
                          <div class="row mt-3">
                            <div class="col-md-8">
                              <span class="mb-3">
                                <button class="flat-grey-light" placement="bottom">
                                  <h6 class="title-6 mb-0 mr-2"> RMA {{'RMA_DETAILS.CHOICE' | transloco}}: {{line?.rma_type?.name}} </h6>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-md-8">
                              <span class="mb-3">
                                <button class="flat-grey-light" placement="bottom">
                                  <h6 class="title-6 mb-0 mr-2"> RMA {{'DEPOT_HISTORY.REASON' | transloco}}: {{line?.return_reason_type?.name}}</h6>
                                </button>
                              </span>
                            </div>
                          </div>
                          <div class="row mt-1">
                            <div class="col-md-8">
                              <span class="mb-3">
                                <button class="flat-grey-light" placement="bottom">
                                  <h6 class="title-6 mb-0 mr-2"> {{'RMA_CREATE.CUSTOMER_REASON' | transloco }}:</h6>
                                </button>
                                <textarea name="{{line?.uuid}}" id="{{line?.uuid}}" disabled cols="40" rows="3"
                                  [value]="line.user_reason"></textarea>
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-5">
                    <div class="row pt-4 action-cart-price align-items-center justify-content-end">
                      <div class="col-auto">
                        <div class="cart-qty-amount text-right amount-col pt-1">
                          <span *ngIf="!line?.voucher_code">
                            {{ line?.quantity }}
                          </span>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col pt-2 text-right sub-title-light-4 dark-grey-text">
                          <!-- {{line?.amount_ex_vat | currencyFormat}} -->
                          <span *ngIf="!line?.voucher_code">
                            {{line?.price_ex_vat | currencyFormat}}
                          </span>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="price-col pt-2 text-right sub-title-light-4 dark-grey-text">
                          <span *ngIf="!line?.voucher_code;else voucheramnt">
                            {{ line?.amount_ex_vat | currencyFormat }}
                          </span>
                          <ng-template #voucheramnt>
                            {{ line?.total_amount | currencyFormat}}
                          </ng-template>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-12 cart-sub-items" *ngFor="let subLine of line.sub_lines; let j = index">
                    <div class="row">
                      <div class="col">
                        <div class="row">
                          <div class="col">
                            <div class="cart-item-details">
                              <p class="model-desc text-left">{{subLine?.product?.name}}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="row action-cart-price align-items-center justify-content-end">
                          <div class="col-auto sub-item-price-col-auto">
                            <div class="price-col text-right sub-title-light-4 pt-2 dark-grey-text">
                              {{subLine?.total_amount_ex_vat | currencyFormat}}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row justify-content-end item-cart-footer item-cart-footer-orderoverview pr-0 mr-0"
                *ngIf="!isLoading">
                <div class="col-lg cart-total pr-0">
                  <div class="row justify-content-end align-items-center">
                    <div class="col-md-4 col-lg-3 col-12 pr-0">
                      <div class="">
                        <h5 class="title-3 green-text text-right pr-2">excl. {{'RMA_DETAILS.VAT' | transloco}}</h5>
                        <h5 class="title-3 green-text text-right pr-2">{{'RMA_DETAILS.VAT' | transloco}}</h5>
                        <h5 class="title-3 green-text text-right total-above-line pr-2">{{'ORDER_CONFIRMATION.TOTAL_INCL' | transloco }} </h5>
                      </div>
                    </div>
                    <div class="col-auto pl-0 pr-0">
                      <div class="price-col">
                        <h5 class="title-3 dark-grey-text text-right">{{rmaOrder?.rma?.total_amount_ex_vat |
                                               currencyFormat}}</h5>
                        <h5 class="title-3 dark-grey-text text-right">{{rmaOrder?.rma?.total_amount_vat |
                                               currencyFormat}}</h5>
                        <h5 class="title-3 dark-grey-text text-right total-above-line">{{rmaOrder?.rma?.total_amount |
                                               currencyFormat}}</h5>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </section>
</div>
