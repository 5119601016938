import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { switchMap, tap } from 'rxjs/operators';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { StorageService } from 'src/app/shared/storage.service';
import { ForecastService } from '../forecast.service';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-sales-forecast',
  templateUrl: './sales-forecast.component.html',
  styleUrls: ['./sales-forecast.component.scss']
})
export class SalesForecastComponent implements OnInit {

  public isLoading = false;
  public forecastHeader = [];
  public forecastProducts = [];
  public config = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0
  };

  private contact_uuid = '';
  private catalog_code = '';
  private forecastDuration = 12;
  public planningText = '';
  public pattern = null;
  public totalSum = 0;

  public today = new Date();
  public validFromDate = new Date();
  public maxYear = new Date(`${this.today.getFullYear() + 1}-12-31`);
  public fromDate = this.today;
  public toDate = this.maxYear;
  public showLoader = false;
  constructor(
    private forecastService: ForecastService,
    private storageService: StorageService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private utilService: UtilityService,
    private toastrService: ToastrService,
    private translocoService: TranslocoService

  ) {
    this.pattern = this.utilService.zipCodePattern();
    this.activatedRoute.params.subscribe(params => {
      this.contact_uuid = params?.contact_uuid || '';
    });

    this.activatedRoute.queryParams.subscribe(qp => {
      this.catalog_code = qp?.catalog || '';
      this.forecastDuration = qp?.duration || this.forecastDuration;
      this.maxYear = new Date(moment(this.fromDate).add(this.forecastDuration, 'months').format('YYYY-MM-DD'));
      this.getValidFromDate(this.fromDate);
      this.toDate = this.maxYear;
      if (this.contact_uuid && this.catalog_code) {
        this.getForecastHeader();
      }
    });
  }

  getForecastHeader() {
    this.showLoader = true;
    let date = this.formatDate()
    this.forecastService.getForecastHeader(this.contact_uuid, this.catalog_code, date).pipe(
      tap(res => {
        this.showLoader = false;
        this.forecastHeader = res.data.periods;
        const first = this.forecastHeader[(this.forecastHeader.length - 3)] || null;
        const last = this.forecastHeader[(this.forecastHeader.length - 1)] || null;
        if (first && last) {
          // tslint:disable-next-line:max-line-length
          this.planningText = `${this.translocoService.translate('SALES_FORECAST.NOTE_FORECASTS_FOR')} ${first?.month?.short_name} ${first?.year} ${this.translocoService.translate('SALES_FORECAST.CAN_BE_CHANGED_UNTIL')} ${last?.month?.short_name} ${last?.year}`;
        }
      }),
      switchMap(data => {
        let date = this.formatDate();
        this.showLoader = false;
        return this.forecastService.getForecastProduct(this.contact_uuid, this.catalog_code, this.config.currentPage, date);
      })
    ).subscribe(response => {
      this.config.totalItems = response.data.count;
      this.showLoader = false;
      this.processPlanning(response.data.results);
    }, error => {
      this.showErrorToaster(error?.error?.message);
      this.showLoader = false;
    });
  }

  getValidFromDate(fromDate) {
    this.validFromDate = fromDate;
    this.validFromDate.setDate(fromDate.getDate());
  }

  onChangeFromDate(event: Date) {
    if (event == null) return;
    this.getValidFromDate(event);
  }

  calculateProductWiseTotal(forecasts) {
    return forecasts.reduce((acc, item) => {
      acc += parseInt(item.quantity || '0', 10);
      return acc;
    }, 0);
  }

  calculateMonthWiseTotal() {
    this.forecastHeader = this.forecastHeader.map(item => {
      return {
        ...item,
        monthly_total: this.calculateMonthlyTotal(item.month.no, item.year)
      };
    });
  }

  calculateMonthlyTotal(month, year) {
    let total = 0;
    this.forecastProducts.forEach(product => {
      total += product.forecasts.reduce((acc, item) => {
        if (item.period.month.no === month && item.period.year === year) {
          acc += parseInt(item.quantity || '0', 10);
        }
        return acc;
      }, 0);
    });
    return total;
  }

  totalSumCalculation() {
    this.totalSum = this.forecastProducts.reduce((acc, item) => {
      acc += parseInt(item.total || '0', 10);
      return acc;
    }, 0);
  }

  processPlanning(results: any[]) {
    this.forecastProducts = results?.map(item => {
      return {
        ...item,
        loading: false,
        total: this.calculateProductWiseTotal(item.forecasts),
        forecasts: item.forecasts?.map(data => {
          return {
            ...data,
            quantity: data?.quantity || '0',
            loading: false,
          };
        })
      };
    });
    this.calculateMonthWiseTotal();
    this.totalSumCalculation();
  }

  addSingleProduct(product, forecast) {
    forecast.loading = true;
    const payload = {
      contact_uuid: this.contact_uuid,
      catalog_code: this.catalog_code,
      product_code: product?.product?.code,
      year: forecast.period.year,
      month: forecast.period?.month?.no,
      quantity: forecast.quantity
    };
    this.forecastService.addSingleForecastProduct(payload).subscribe(response => {
      forecast.loading = false;
      this.processPlanning(this.forecastProducts);
    }, error => {
      forecast.loading = false;
    });
  }

  addByPressTab(product, forecast) {
    if (forecast.quantity <= 0) {
      return;
    }

    this.addSingleProduct(product, forecast);

  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getNextPageProduct();
  }

  onChangeDate(dateFrom) {
    if (dateFrom?.control?.status === 'INVALID') {
      return;
    }
    this.config.currentPage = 1;
    this.getForecastHeader();
  }

  formatDate() {
    let date = {
      prev_date: moment(this.fromDate).format('YYYY-MM-DD'),
      next_date: moment(this.toDate).format('YYYY-MM-DD')
    }
    return date;
  }

  getNextPageProduct() {
    let date = this.formatDate();
    this.showLoader = true;
    this.forecastService.getForecastProduct(this.contact_uuid, this.catalog_code, this.config.currentPage, date).subscribe(response => {
      this.config.totalItems = response.data.count;
      this.showLoader = false;
      this.processPlanning(response.data.results);
    }, error => {
      this.showLoader = false;
    });
  }

  showErrorToaster(errMsg = null) {
    this.toastrService.error(errMsg || `${this.translocoService.translate('PRODUCT_DETAILS.OPERATION_FAILED')}!`, 'Error', {
      timeOut: 4000
    });
  }

  ngOnInit(): void {
  }

}
