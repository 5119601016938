<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
      <div class="container">
          <div class="customer-search kpn-card bg-kpn-light mb-4">
              <div class="mb-3">
                  <h4 class="title-4 green-text" i18n="@@arm.order-status.serc">
                    {{'EXPORT_FILE.EXPORT_PRICE_LIST' | transloco}}
                  </h4>
              </div>
              <div class="row align-items-center">
                  <div class="col-md-5 col-6 col-gap-reduce">
                      <div class="form-group kpn-form-group">
                          <label i18n="@@arm.order-status.ext-num"> {{'EXPORT_FILE.CATALOG' | transloco}}</label>
                          <select name="" id="" [(ngModel)]="selectedCatalogCode" class="form-control" [disabled]="isLoading">
                              <option *ngFor="let item of catalogList" [ngValue]="item">{{item?.name}}
                              </option>
                          </select>
                      </div>
                  </div>
                  <div class="col-md-5 col-6 col-gap-reduce">
                      <div class="form-group kpn-form-group">
                          <label i18n="@@arm.order-status.int-num">{{'EXPORT_FILE.PRICE_LIST' | transloco}}</label>
                          <div class="app-multiselect-dropdown">
                              <ng-multiselect-dropdown
                                  [placeholder]="'EXPORT_FILE.SELECT_PRICE' | transloco"
                                  [settings]="dropdownSettings"
                                  [data]="priceList"
                                  [(ngModel)]="selectedPrice"
                                  (onSelect)="onItemSelect($event)"
                                  (onSelectAll)="onSelectAll($event)"
                                  [disabled]="isLoading"
                              >
                              </ng-multiselect-dropdown>
                          </div>
                      </div>
                  </div>
                  <div class="col-md-2 col-6 col-gap-reduce">
                      <button (click)="exportPriceList()" [disabled]='isLoading || !selectedPrice.length || !selectedCatalogCode' class="btn btn-block btn-primary mt-2 rounded-btn" i18n="@@arm.order-status.search-btn">
                          <span *ngIf="!isLoading; else showSpinner">{{'EXPORT_FILE.EXPORT' | transloco}}</span>
                          <ng-template #showSpinner>
                              <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                          </ng-template>
                      </button>
                  </div>
              </div>
              <div class="" style="height: 15px;">
                  <div class="progress" *ngIf="exportPercentage">
                      <div class="progress-bar" role="progressbar" [ngStyle]="{'width': exportPercentage + '%'}" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">{{exportPercentage}}%</div>
                  </div>
              </div>
          </div>
      </div>
  </section>
</div>
