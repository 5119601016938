import { Component, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { StorageService } from 'src/app/shared/storage.service';
import { QuoteDetailsService } from '../../quote-details.service';
import { CustomerService } from 'src/app/pages/customer/customer.service';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from 'src/app/app.state';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';
import { selectSiteProfile } from 'src/app/store/selectors/siteProfile.selectors';
import { EAddressPrecision, EQuoteStatus } from 'src/app/shared/enums/order-status.enums';
import { ESupplierTypes } from 'src/app/shared/enums/product-type.enums';
import { AccountTypeService } from 'src/app/pages/account-type/account-type.service';
import { ExportId } from 'src/app/shared/enums/flow-type.enums';

enum OperationType {
  Add = 'Add',
  Remove = 'Remove'
};
@Component({
  selector: 'app-customer-quote-details-configuration-step',
  templateUrl: './customer-quote-details-configuration-step.component.html',
  styleUrls: ['./customer-quote-details-configuration-step.component.scss']
})
export class CustomerQuoteDetailsConfigurationStepComponent extends BasePermission {
  private quoteUuid: string;
  public user = null;
  isLoading: boolean;
  quote: any;

  cpaasDataUpdateModalRef = null;

  termsAndConditions = [];
  termAccepted = false;
  siteProfile = null;
  po_number = '';
  customer_reference = '';
  debounceTimer = null;
  quoteUpdateError = null;
  feRequiredFieldErrors = {
    po_number: "",
    customer_reference: "",
    term_and_condition: "",
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: ""
  }
  hasCpaas = null;
  isCpassDataAdded = false;
  cPassData = {
    cpaas_user_email: "",
    cpaas_user_first_name: "",
    cpaas_user_last_name: "",
  };

  errors = null;
  contactAccountTypesCustomers = [];
  selectedContactAccountTypesCustomer = null;
  accountTypes = [];
  creditLimit = 0.00;
  helpLink = ''
  public restrictExportId = ExportId;

  @ViewChild('creditLimitModal') creditLimitModal: any;
  constructor(
    private modalService: NgbModal,
    private quoteDetailsService: QuoteDetailsService,
    private router: Router,
    private route: ActivatedRoute,
    private toastrService: ToastrService,
    private storageService: StorageService,
    private customerService: CustomerService,
    public store: Store<IAppState>,
    private translocoService: TranslocoService,
    private accountTypeService: AccountTypeService,


  ) {
    super(store);

    this.quoteUuid = this.route.snapshot.params.quote_uuid;
    this.user = this.storageService.getCurrentUser();
    this.helpLink = !this.restrictExportId?.includes(this.siteProfile?.export_id) ? 'mailto:kpnwebshop@kpn.com' : 'https://ykpn.facilitor.nl/';

  }
  get eQuoteStatus() { return EQuoteStatus; }
  get eAddressPrecision() {
    return EAddressPrecision;
  }


  ngOnInit() {
    if (this.quoteUuid) {
      this.getQuoteDetails();
    }
    this.store.select(selectSiteProfile).subscribe(data => {
      this.siteProfile = data;
    });

  }
  getQuoteDetails() {
    this.isLoading = true;
    this.quoteDetailsService.getQuote(this.quoteUuid).subscribe(
      (res) => {
        this.quote = res.data;
        let sysTemTypes = [];
        this.quote.lines = res?.data?.lines?.map((line) => {
          if (sysTemTypes.includes(line.product_type.system_type)) {
            return {
              ...line,
              showSystemTypeInFrontend: false,
            };
          }
          else {
            sysTemTypes.push(line.product_type.system_type);
            return {
              ...line,
              showSystemTypeInFrontend: true,
            };
          }
        });
        if (this.eQuoteStatus.APPROVED !== this.quote?.status) {
          this.router.navigate(['/customer/quote-details/', `${this.quote.uuid}`]);
        }
        this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
        if (this.hasCpaas) {
          this.cPassData = {
            cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
            cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
            cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
          }
          this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
        };
        this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
        this.getQuoteContactDetails();

        if (this.quote?.is_contact_account_type_required) {
          if (this.hasViewContactAccountTypeCustomer) {
            this.getContactAccountTypesCustomers();
          }
        }
      },
      (err) => {
        this.isLoading = false;
        console.log("err: ", err);
      }
    );
  }
  getContactAccountTypesCustomers() {
    this.accountTypeService.getContactAccountTypesCustomers().subscribe(res => {
      this.contactAccountTypesCustomers = res?.data?.results ?? [];

      // this.isLoading = true;
      // let paramsString = `page=1`;

      // this.accountTypeService.getQuoteAccountTypes(this.quote?.uuid, paramsString).subscribe(res => {
      //   const allAccountTypes = res?.data?.results ?? [];
      //   this.accountTypes = allAccountTypes.filter(item => {
      //     if (this?.quote?.contact_account_types?.find(at=> at === item.id)) {
      //       return item;
      //     }
      //   }).map(item => ({ ...item, checked: true }));
      // }, error => {
      //   this.isLoading = false;
      // })
      // if (existingSelectedContactAccountTypesCustomer) {
      //   this.selectedContactAccountTypesCustomer = this.contactAccountTypesCustomers.find(item => item?.uuid === existingSelectedContactAccountTypesCustomer)?.uuid || null;

      //   this.onContactAccountTypesCustomerChange(existingSelectedContactAccountTypesCustomer);
      // }
    }, error => {
    })
  }






  getQuoteContactDetails() {
    this.customerService.getCustomer(this.quote?.contact?.uuid).subscribe(res => {
      this.isLoading = false;
      this.quoteDetailsService.currentCustomerQuoteDetailsContactInfo$.next(res.data);
      this.po_number = this.quote?.po_number || '';
      this.customer_reference = this.quote?.customer_reference || '';
      this.creditLimit = res?.data?.credit_limit || 0.00;

    }, error => {
      this.isLoading = false;
      console.log('error: ', error);

    })
  }

  async updateQuoteFields(payload = {}, message: string) {
    return this.quoteDetailsService.updateQuoteFields(this.quote?.uuid, payload).toPromise().then(res => {
      this.toastrService.success(`${message} updated`, 'Success');
      this.quote = res.data;
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${message} updated failed`, 'Error');
      return false;
    });
  }









  validateEmail(emailInput = ''): boolean {

    // Regular expression for validating an email address
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

    // Check if the email matches the regex pattern
    if (emailRegex.test(emailInput)) {
      return true;
    } else {
      return false;
    }
  }
  onCpassLastNameChange(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.cpaas_user_last_name = '';
    }
  }
  onCpassFirstNameChange(event) {
    const value = event.target.value;
    if (value) {
      this.feRequiredFieldErrors.cpaas_user_first_name = '';
    }
  }
  onCpassEmailChange(event) {
    const value = event.target.value;
    if (value) {
      if (!this.validateEmail(value)) {
        this.feRequiredFieldErrors.cpaas_user_email = 'Ongeldig e-mailadres';
      } else {
        this.feRequiredFieldErrors.cpaas_user_email = '';
      }
    }
  }

  async gotoSummaryPage() {
    if (this.hasCpaas && !this.isCpassDataAdded) {
      if (!this.cPassData.cpaas_user_email) {
        this.feRequiredFieldErrors.cpaas_user_email = 'E-mailadres is verplicht';
      }
      if (this.cPassData.cpaas_user_email && !this.validateEmail(this.cPassData.cpaas_user_email)) {
        this.feRequiredFieldErrors.cpaas_user_email = 'Ongeldig e-mailadres';
      }
      if (!this.cPassData.cpaas_user_first_name) {
        this.feRequiredFieldErrors.cpaas_user_first_name = 'Voornaam is verplicht';
      }
      if (!this.cPassData.cpaas_user_last_name) {
        this.feRequiredFieldErrors.cpaas_user_last_name = 'Achternaam is verplicht';
      }
      if (!this.cPassData.cpaas_user_email || !this.cPassData.cpaas_user_first_name || !this.cPassData.cpaas_user_last_name) {
        return;
      }
    }
    if ((this.hasCpaas ? (!this.isCpassDataAdded && (!this.cPassData.cpaas_user_email || !this.validateEmail(this.cPassData.cpaas_user_email) || !this.cPassData.cpaas_user_first_name || !this.cPassData.cpaas_user_last_name)) : false)) {

      return;
    }

    if (this.hasCpaas && !this.isCpassDataAdded) {
      if (! await this.saveCpassData(this.cPassData)) {
        this.isLoading = false;
        return;
      }
    }

    this.router.navigate(['/customer/quote-details-summary/', `${this.quote.uuid}`]);


  }


  async saveCpassData(payload) {
    this.isLoading = true;
    return this.quoteDetailsService.updateCpaasUserInfo(this.quoteUuid, payload).toPromise().then(res => {
      this.isLoading = false;
      this.quote = res.data;
      this.quoteDetailsService.currentCustomerDetailsQuote$.next(this.quote);
      this.setCpassData();
      if (this.cpaasDataUpdateModalRef) {
        this.cpaasDataUpdateModalRef.close();
      }
      return true;
    }).catch(error => {
      this.isLoading = false;
      this.toastrService.error(`${this.translocoService.translate('QUOTE_DETAILS.SOMETHING_WENT_WRONG_IN_QUOTE_UPDATING')}`);
      return false;
    });
  }
  updateCpassDataFromChild(quote) {
    this.quote = quote;
    this.setCpassData();
  }
  setCpassData() {
    this.hasCpaas = (this.quote.lines?.find(line => line?.supplier_export_id === ESupplierTypes.CPAAS));
    if (this.hasCpaas) {
      this.cPassData = {
        cpaas_user_email: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_email || null,
        cpaas_user_first_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_first_name || null,
        cpaas_user_last_name: this.hasCpaas?.quotation_line_extra?.extra_info?.cpaas_user_last_name || null,
      }
      this.isCpassDataAdded = (this.cPassData.cpaas_user_email?.trim() && this.cPassData.cpaas_user_first_name?.trim() && this.cPassData.cpaas_user_last_name?.trim()) ? true : false;
    }

  }



  addCpaasForm(cpaasMainUserModal) {
    this.modalService.open(cpaasMainUserModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-595 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    });


    // this.result.then((result) => {
    //   this.selectedQuoteLine = null;
    // }).catch(e => {

    // });
  }




  navigateToQuoteDetails() {
    this.router.navigate(['/customer/quote-details/', `${this.quote.uuid}`]);
  }

  backToPreviousPage() {
    this.router.navigate(['/customer/quote-details-information', this.quote?.uuid]);

  }


  openQuoteDetailsSummeryModal(openQuoteDetailsSummeryModal) {
    this.modalService.open(openQuoteDetailsSummeryModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      modalDialogClass: 'tri-modal-1124 modal-dialog-scrollable  modal-runded',
      scrollable: true,
      backdrop: 'static',
    });
  }
}
