import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  private notification$ = new BehaviorSubject(null);
  currentNotification$ = this.notification$.asObservable();

  constructor() { }

  setNotification(notificationType: string = null, notificationMsg: string = null) {
    this.notification$.next({type: notificationType, msg: notificationMsg});
  }
}
