<div class="bg-kpn-subtle-grey body-container">

    <app-business-partner-selection  [showEdit]="true"></app-business-partner-selection>

  <section class="res-search-section res-search-section-calc pt-0">
      <div class="container">
          <div class="pt-5">
              <h1 class="trade-in-home-title font-kpn-black green-text mb-1 ">Welkom bij Trade-in</h1>
              <p class="fs-20 kpn-font-color font-kpn-regular mb-4 pb-3" >Recycle je oude apparatuur!</p>


              <ng-template #afterLandTradeinModal let-modal>
                <div class="px-4 pt-4">
                    <div class="modal-header tri-modal-header p-0 pb-4">
                        <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
                            Voor wie ga je een aanvraag indienen?
                        </h6>
                        <!-- <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                            <span class="kphicon-uniE926"></span>
                        </button> -->
                    </div>
                </div>

                <div class="modal-body p-0">
                    <div class="p-4 font-kpn-extended">
                        <p class="mb-4">Ga je deze aanvraag voor eigen gebruik of voor een klant indienen?</p>
                        <div class="row gutter-row-15 mb-3">
                            <div class="col-md-6 gutter-col-15">
                                <div class="d-flex align-items-center radio-check-box-type">
                                    <input type="radio" id="playing-for-1" name="playing-for" >
                                    <label for="playing-for-1" class="d-flex w-100 align-items-center font-kpn-extended fs-16">
                                        <span class="radio-bullet"></span>
                                        Eigen gebruik  </label>
                                </div>
                            </div>
                            <div class="col-md-6 gutter-col-15">
                                <div class="d-flex align-items-center radio-check-box-type">
                                    <input type="radio" id="playing-for-2" name="playing-for" >
                                    <label for="playing-for-2" class="d-flex w-100 align-items-center font-kpn-extended fs-16">
                                        <span class="radio-bullet"></span>
                                        Een klant </label>
                                </div>
                            </div>
                        </div>

                        <div class="blue-warning-qoute d-flex mb-2">
                            <i class="kphicon-uniE952 note-icon mr-3"></i>
                            <p class="font-kpn-extended m-0 line-height-24">Je kunt slechts één aanvraag tegelijk per klant indienen.</p>
                        </div>

                        <div class="pt-2">
                          <button (click)="modal.close()"
                              class="btn btn-block btn-primary border-2 mt-2 rounded-btn">
                              <span>Ga verder</span>
                          </button>
                       </div>
                    </div>
                </div>
              </ng-template>

              <div class="what-is-trade-in bg-white">
                  <div class="row align-items-center">
                      <div class="col-md pr-0">
                          <h4 class="what-is-trade-in-title font-kpn-black green-text mb-1">Wat is Trade-in?</h4>
                          <p class="kpn-font-color font-kpn-extended mb-1">Met onze Trade-in service krijg je geld terug voor je oude mobiele apparaten. Een duurzame benadering voor elektronische apparatuur met aanzienlijke voordelen! Trade-in is gunstig voor het milieu, de maatschappij en het zorgt voor <br> kostenbesparing en verantwoord ondernemen. We werken samen met Recommerce voor het recyclen van je oude apparatuur.</p>
                          <a href="javascript:void(0)" (click)="aboutTradeIn(aboutTradeInModal)"
                              class="learn-more-tradein font-kpn-extended">Lees meer over Trade-in <i
                                  class="kphicon-uniE907"></i></a>
                      </div>
                      <div class="col-md-auto">
                          <img class="mr-md-3" src="../../../assets/images/service.svg" alt="">
                      </div>
                  </div>
                  <ng-template #aboutTradeInModal let-modal>
                      <div class="modal-header tri-modal-header">
                          <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
                              Over Trade-in
                          </h6>
                          <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                              <span class="kphicon-uniE926"></span>
                          </button>
                      </div>

                      <div class="modal-body">
                          <div class="p-3">
                              <h4 class="font-kpn-black green-text mb-4">Hoe werkt Trade-in?</h4>
                              <div class="about-tri-list-bullet">
                                  <span class="tri-num-bullet font-kpn-bold green-text text-center">1</span>
                                  <div>
                                      <label class="green-text font-kpn-bold">Je oude apparatuur opgeven</label>
                                      <p class="font-kpn-extended">Zoek eenvoudig je oude apparatuur op en ontvang direct een (geschatte) verkoopprijs.</p>
                                  </div>
                              </div>
                              <div class="about-tri-list-bullet">
                                  <span class="tri-num-bullet font-kpn-bold green-text text-center">2</span>
                                  <div>
                                      <label class="green-text font-kpn-bold">Je hardware versturen of op laten
                                          halen</label>
                                      <p class="font-kpn-extended">Bij het inruilen tot aan 10 apparaten ontvang je een gratis retourlabel binnen 2 werkdagen. Bij meer dan 10 apparaten nemen we binnen 2 werkdagen contact met je op om een afspraak in te plannen om je apparatuur gratis op te halen.</p>
                                  </div>
                              </div>
                              <div class="about-tri-list-bullet">
                                  <span class="tri-num-bullet font-kpn-bold green-text text-center">3</span>
                                  <div>
                                      <label class="green-text font-kpn-bold">Ontvang je geld</label>
                                      <p class="font-kpn-extended">Na controle ontvang je de definitieve verkoopprijs, waarna je Recommerce een factuur kan sturen voor de totale inruilwaard.</p>
                                  </div>
                              </div>
                              <hr class="mb-4">
                              <h4 class="font-kpn-black green-text mb-2 pt-1">Over Recommerce</h4>
                              <p class="mb-0 font-kpn-extended">Voor het inruilen van oude apparatuur werken we samen met het bedrijf Recommerce. Zij zijn gespecialiseerd in het refurbishen en recyclen van apparatuur. Zo ben je er zeker van dat je oude apparatuur op een verantwoorde manier worden verwerkt. Vaak krijg je nog een mooi bedrag terug voor je oude apparatuur. Het kan echter voorkomen dat er voor zeer verouderde of beschadigde apparatuur geen vergoeding wordt gegeven.</p>
                              <h6 class="mt-3 mb-2 font-weight-bold"> Veiligheid en dataverwijdering</h6>
                              <p class="mb-0 font-kpn-extended">Bij Recommerce hanteren zij het ADISA-protocol om ervoor te zorgen dat alle gegevens op apparaten veilig worden gewist. Dit betekent dat alle data onherroepelijk worden verwijderd volgens de strikte industrienormen voordat de telefoons worden doorverkocht of gerecycled. ADISA staat voor Asset Disposal & Information Security Alliance, wat inhoudt dat hun processen specifiek zijn ontworpen om de veiligheid en privacy te waarborgen. Meer informatie over ADISA en de gecertificeerde dataverwijderingsprocessen is te vinden op hun website (<a style="text-decoration: underline;color: black;"  target="_blank" href="https://adisarc.com/product-claims-test/">https://adisarc.com/product-claims-test/</a>).</p>
                            </div>
                      </div>
                  </ng-template>
              </div>

              <div class="advanctage-of-service px-4 mb-4">
                  <div class="row gutter-row-15">
                      <div class="col-md-auto gutter-col-15">
                          <p class="mb-0 kpn-font-color font-kpn-extended">
                              <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g id="UI Icon">
                                      <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M12.5205 4C8.10279 4 4.52051 7.58228 4.52051 12V18.5972L8.53754 14.1848C8.52636 14.1249 8.52051 14.0631 8.52051 14V10C8.52051 9.44772 8.96822 9 9.52051 9C10.0728 9 10.5205 9.44772 10.5205 10V12.0066L14.781 7.3268C15.1528 6.91841 15.7853 6.88874 16.1937 7.26054C16.6021 7.63234 16.6318 8.26481 16.26 8.6732L12.3208 13H15.5205C16.0728 13 16.5205 13.4477 16.5205 14C16.5205 14.5523 16.0728 15 15.5205 15H10.5L5.94804 20H12.5205C16.9382 20 20.5205 16.4177 20.5205 12V4H12.5205ZM2.52051 12C2.52051 6.47772 6.99822 2 12.5205 2H21.5205C22.0728 2 22.5205 2.44772 22.5205 3V12C22.5205 17.5223 18.0428 22 12.5205 22H3.52051C2.96822 22 2.52051 21.5523 2.52051 21V12Z"
                                          fill="#00AD00" />
                                  </g>
                              </svg>
                              Eenvoudig opzoeken van je oude apparaat
                          </p>
                      </div>
                      <div class="col-md gutter-col-15">
                          <p class="mb-0 kpn-font-color font-kpn-extended text-md-center">
                              <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g id="UI Icon">
                                      <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M4.52051 8C4.52051 7.45228 4.97279 7 5.52051 7H15.8975L17.6156 10.4462C17.7847 10.7855 18.1313 11 18.5105 11H20.5105V15H19.4858C19.0483 13.8317 17.9215 13 16.6005 13C15.2795 13 14.1527 13.8317 13.7152 15H11.3258C10.8883 13.8317 9.76154 13 8.4405 13C7.11947 13 5.99268 13.8317 5.55517 15H4.52051V8ZM5.50025 17H4.52051C3.41822 17 2.52051 16.1023 2.52051 15V8C2.52051 6.34772 3.86822 5 5.52051 5H15.9005C16.6387 5 17.3519 5.40703 17.6896 6.11218L19.1293 9H20.5105C21.6128 9 22.5105 9.89772 22.5105 11V15C22.5105 16.1023 21.6128 17 20.5105 17H19.5408C19.1495 18.2517 17.9811 19.16 16.6005 19.16C15.2199 19.16 14.0515 18.2517 13.6603 17H11.3808C10.9895 18.2517 9.82109 19.16 8.4405 19.16C7.05992 19.16 5.89149 18.2517 5.50025 17ZM8.4405 15C7.84404 15 7.3605 15.4835 7.3605 16.08C7.3605 16.6765 7.84404 17.16 8.4405 17.16C9.03697 17.16 9.5205 16.6765 9.5205 16.08C9.5205 15.4835 9.03697 15 8.4405 15ZM16.6005 15C16.004 15 15.5205 15.4835 15.5205 16.08C15.5205 16.6765 16.004 17.16 16.6005 17.16C17.197 17.16 17.6805 16.6765 17.6805 16.08C17.6805 15.4835 17.197 15 16.6005 15Z"
                                          fill="#00AD00" />
                                  </g>
                              </svg>
                              Kosteloos je hardware versturen of op laten halen
                          </p>
                      </div>
                      <div class="col-md-auto gutter-col-15">
                          <p class="mb-0 kpn-font-color font-kpn-extended">
                              <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g id="UI Icon">
                                      <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                          d="M15.5205 6C13.2962 6 11.3576 7.20559 10.3213 9H15.5205C16.0728 9 16.5205 9.44772 16.5205 10C16.5205 10.5523 16.0728 11 15.5205 11H9.60319C9.54881 11.3252 9.52051 11.6592 9.52051 12C9.52051 12.3408 9.54881 12.6748 9.60319 13H15.5205C16.0728 13 16.5205 13.4477 16.5205 14C16.5205 14.5523 16.0728 15 15.5205 15H10.3213C11.3576 16.7944 13.2962 18 15.5205 18C16.8018 18 17.9824 17.6051 18.9475 16.9304C19.4002 16.614 20.0236 16.7244 20.3401 17.177C20.6565 17.6297 20.5461 18.2531 20.0935 18.5696C18.7986 19.4749 17.2192 20 15.5205 20C12.1594 20 9.28625 17.9334 8.10082 15H5.52051C4.96822 15 4.52051 14.5523 4.52051 14C4.52051 13.4477 4.96822 13 5.52051 13H7.58224C7.54149 12.6724 7.52051 12.3387 7.52051 12C7.52051 11.6613 7.54149 11.3276 7.58224 11H5.52051C4.96822 11 4.52051 10.5523 4.52051 10C4.52051 9.44772 4.96822 9 5.52051 9H8.10082C9.28625 6.06664 12.1594 4 15.5205 4C17.164 4 18.7909 4.49638 20.082 5.37252C20.539 5.68263 20.6581 6.3045 20.348 6.7615C20.0379 7.2185 19.416 7.33758 18.959 7.02748C18.0102 6.38362 16.777 6 15.5205 6Z"
                                          fill="#00AD00" />
                                  </g>
                              </svg>
                              Ontvang een voorlopige verkoopprijs
                          </p>
                      </div>
                  </div>
              </div>

              <h3 class="tri-title-40 font-kpn-black green-text pt-2 ">Vind hier jouw model</h3>
              <div class="tri-shadow-xl trade-product-list bg-white mb-4">
                  <div class="tri-list-header">
                      <div class="mb-3">
                          <div class="trade-inlist-search">
                              <input [(ngModel)]="search" (input)="handleSearch()" type="text"
                                  class="from-control tri-list-search-input font-kpn-extended"
                                  placeholder="Zoek op merk, naam, code, model, etc...">
                              <button (click)="handleSearch()" class="kpn-ui-icon ui-search tri-list-search-btn"></button>
                          </div>
                      </div>
                      <div class="d-flex align-items-center">
                          <button class="tri-filter-btn mb-0 mr-3 font-kpn-extended"
                              (click)="onClickFilter(triListFilterModal)">Filteren</button>
                          <span *ngFor="let brand of selectedBrands"
                              class="tri-selected-filter mr-3 font-kpn-extended">
                              {{brand?.name}}
                              <button (click)="removeFromFilter(brand?.code)" class="kphicon-uniE926 filter-close"></button>
                          </span>
                      </div>
                  </div>
                  <ng-template #triListFilterModal let-modal>
                      <div class="modal-header tri-modal-header">
                          <h6 class="modal-title green-text font-kpn-black" id="triListFilter">
                              Filteren
                          </h6>
                          <button type="button" class="btn close" aria-label="Close"
                              (click)="closeBrandFilterModal()">
                              <span class="kphicon-uniE926"></span>
                          </button>
                      </div>

                      <div class="modal-body">
                          <div class="row align-items-center p-2">
                              <div class="col-md-12">
                                  <div class="p-2">
                                      <form>
                                          <div class="mb-4">
                                              <label *ngFor="let brand of brandList"
                                                  class="checkbox-item checkbox-item-tri d-flex align-items-center mb-3">
                                                  <div class="checkbox-btn checkbox-btn-outline">
                                                      <input [(ngModel)]="brand.selected"
                                                          [ngModelOptions]="{standalone: true}" type="checkbox">
                                                      <span class="checkBoxCheckmark"></span>
                                                  </div>
                                                  <div class="checkbox-label font-kpn-extended ml-3">
                                                      <span>{{brand?.name}} </span>
                                                  </div>
                                              </label>
                                          </div>

                                          <div class="pt-2">
                                              <button (click)="applyBrandFilter()"
                                                  class="btn btn-block btn-primary mt-2 rounded-btn">
                                                  <span>Toepassen</span>
                                              </button>
                                              <button (click)="closeBrandFilterModal()"
                                                  class="btn btn-block btn-outline-primary border-2 mt-2 rounded-btn">
                                                  <span>Annuleren</span>
                                              </button>
                                          </div>
                                      </form>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </ng-template>
                  <div class=""
                      *ngIf="loader">
                      <div class="kpn-spinner" *ngIf="loader">
                          <div class="text-center">
                              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
                              <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
                          </div>
                      </div>
                  </div>

                  <div class="tri-list-table" >
                      <table>
                          <thead>
                              <tr>
                                  <th width="30%">
                                      <span class="tri-list-sort font-kpn-extended kpn-extended-bold">
                                          Merk
                                          <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <g id="UI Icon">
                                                  <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M6.31067 9.28994C6.02693 9.5775 5.94408 10.0075 6.10067 10.3799C6.25408 10.7534 6.61697 10.9979 7.02067 10.9999H17.0207C17.4244 10.9979 17.7873 10.7534 17.9407 10.3799C18.0973 10.0075 18.0144 9.5775 17.7307 9.28994L12.7307 4.28994C12.5429 4.10063 12.2873 3.99414 12.0207 3.99414C11.754 3.99414 11.4984 4.10063 11.3107 4.28994L6.31067 9.28994ZM6.10067 13.6199C6.25408 13.2465 6.61697 13.002 7.02067 12.9999H17.0207C17.4244 13.002 17.7873 13.2465 17.9407 13.6199C18.0973 13.9923 18.0144 14.4224 17.7307 14.7099L12.7307 19.7099C12.5429 19.8993 12.2873 20.0057 12.0207 20.0057C11.754 20.0057 11.4984 19.8993 11.3107 19.7099L6.31067 14.7099C6.02693 14.4224 5.94408 13.9923 6.10067 13.6199Z"
                                                      fill="#939393" />
                                              </g>
                                          </svg>
                                      </span>
                                  </th>
                                  <th>
                                      <span class="tri-list-sort font-kpn-extended kpn-extended-bold">
                                          Model
                                          <svg width="25" height="24" viewBox="0 0 25 24" fill="none"
                                              xmlns="http://www.w3.org/2000/svg">
                                              <g id="UI Icon">
                                                  <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                                                      d="M6.31067 9.28994C6.02693 9.5775 5.94408 10.0075 6.10067 10.3799C6.25408 10.7534 6.61697 10.9979 7.02067 10.9999H17.0207C17.4244 10.9979 17.7873 10.7534 17.9407 10.3799C18.0973 10.0075 18.0144 9.5775 17.7307 9.28994L12.7307 4.28994C12.5429 4.10063 12.2873 3.99414 12.0207 3.99414C11.754 3.99414 11.4984 4.10063 11.3107 4.28994L6.31067 9.28994ZM6.10067 13.6199C6.25408 13.2465 6.61697 13.002 7.02067 12.9999H17.0207C17.4244 13.002 17.7873 13.2465 17.9407 13.6199C18.0973 13.9923 18.0144 14.4224 17.7307 14.7099L12.7307 19.7099C12.5429 19.8993 12.2873 20.0057 12.0207 20.0057C11.754 20.0057 11.4984 19.8993 11.3107 19.7099L6.31067 14.7099C6.02693 14.4224 5.94408 13.9923 6.10067 13.6199Z"
                                                      fill="#939393" />
                                              </g>
                                          </svg>
                                      </span>
                                  </th>
                              </tr>
                          </thead>
                          <tbody>
                              <tr *ngFor="let product of  searchProducts"  (click)="gotoDetails(product)">
                                  <td data-cell="Merk:"> {{product?.brand?.name}}</td>
                                  <td data-cell="Model:">{{ product?.ti_family?.name }}</td>
                              </tr>
                      </table>
                      <div class="table-pagination text-right" *ngIf="config?.totalPages">
                          <p class="d-flex align-items-center justify-content-end m-0 font-kpn-extended">
                              <span class="blue-text mr-2">{{config?.from}}-{{config?.to}}</span>
                              of {{config.totalItems}}
                              <button [disabled]="config?.currentPage === 1"
                                  (click)="handlePageClick(config?.currentPage - 1)"
                                  class="table-pagination-btn kphicon-uniE91F ml-2 px-0"></button>
                              <button [disabled]="config?.currentPage === config?.totalPages"
                                  class="table-pagination-btn kphicon-uniE920 px-0"
                                  (click)="handlePageClick(config?.currentPage + 1)"></button>
                          </p>
                      </div>
                  </div>
              </div>


              <h3 class="tri-title-40 font-kpn-black green-text pt-2 mb-3">Lopende acties</h3>
              <div class="tri-promotion-block">
                  <div class="row gutter-row-15">
                      <div class="col-md-6 gutter-col-15 mb-3 last-item-m-0" *ngFor="let item of productPromotionList">
                          <div class="tri-promo-box d-flex align-items-center bg-white">
                              <div class="tri-promo-img" *ngIf="item?.image">
                                  <img [src]="item?.image" alt="">
                              </div>
                              <div class="tri-promo-details">
                                  <h6 class="promo-title">{{ item?.name }}</h6>
                                  <p class="promo-disc mb-1 kpn-font-color font-kpn-extended">
                                    {{ item?.description | slice: 0:100 }}
                                  </p>
                                  <button *ngIf="item?.long_description || item?.description?.length > 100" type="button" class="btn btn-link nav-link p-0 m-0 font-kpn-extended d-inline-flex align-items-center" 
                                      (click)="openPromoProductDetailsModal(aboutPromoProductModal,item)">Meer informatie <i
                                          class="kphicon-uniE907 fs-20"></i></button>
                              </div>
                          </div>
                      </div>

                  </div>
                  <ng-template #aboutPromoProductModal let-modal>
                      <div class="modal-header tri-modal-header">
                          <h6 class="modal-title font-kpn-black green-text" id="aboutTradeIn">
                              {{ selectedPromotionProduct?.name }}
                          </h6>
                          <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                              <span class="kphicon-uniE926"></span>
                          </button>
                      </div>

                      <div class="modal-body">
                          <div class="p-3 font-kpn-extended">
                              <h4 class="font-kpn-black green-text mb-3">Hoe werkt de actie?</h4>
                               <p>{{ selectedPromotionProduct?.long_description || selectedPromotionProduct?.description }}</p>
                          </div>
                      </div>
                  </ng-template>
              </div>
          </div>
      </div>
  </section>
</div>
