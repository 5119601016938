import { Component, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { QuoteDetailsService } from '../../../quote-details.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-customer-quote-steps',
  templateUrl: './customer-quote-steps.component.html',
  styleUrls: ['./customer-quote-steps.component.scss']
})
export class CustomerQuoteStepsComponent {
    quote = null;
    currentCustomerDetailsQuoteSubscription$ : Subscription;
    @Input() activeStep = 1;
    @Input() completedSteps = [];
    @Input() showConfigTab = false;

    constructor(
      private quoteDetailsService: QuoteDetailsService,
      private router: Router

    ) {
      this.currentCustomerDetailsQuoteSubscription$ = this.quoteDetailsService.currentCustomerDetailsQuote$.subscribe(quote => {
        this.quote = quote;
       });
    }

    ngOnInit(): void {
    }
    isCompleted(step: number): boolean {
      return this.completedSteps.includes(step);
    }
    goToQuoteDetails(): void {
      if (this.completedSteps.includes(1) && this.activeStep !== 5) {

        this.router.navigate(['/customer/quote-details', this.quote.uuid]);
      }
    }
    goToQuoteInformation(): void {
      if (this.completedSteps.includes(2) && this.activeStep !== 5) {
        this.router.navigate(['/customer/quote-details-information', this.quote.uuid]);
      }
    }
    goToQuoteConfiguration(): void {
      if (this.completedSteps.includes(3) && this.activeStep !== 5) {
        this.router.navigate(['/customer/quote-details-configuration', this.quote.uuid]);
      }
    }
    ngOnDestroy(): void {
      if (this.currentCustomerDetailsQuoteSubscription$) {
        this.currentCustomerDetailsQuoteSubscription$.unsubscribe();
      }
    }
}
