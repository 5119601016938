import { Action } from '@ngrx/store';

export enum ESiteProfileActions {
    SITE_PROFILE_LOAD = 'site profile load',
    SITE_PROFILE_LOAD_SUCCESS = 'site profile successfully loaded',
}

export class SiteProfileLoadAction implements Action {
    public readonly type = ESiteProfileActions.SITE_PROFILE_LOAD;
    constructor() {}
}

export class SiteProfileSuccessAction implements Action {
    public readonly type = ESiteProfileActions.SITE_PROFILE_LOAD_SUCCESS;
    constructor(public siteProfile: any) {}
}

export type SiteProfileActions = SiteProfileLoadAction | SiteProfileSuccessAction;
