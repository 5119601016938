import { TranslocoService } from '@ngneat/transloco';
import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';
import { BasePermission } from 'src/app/shared/base/base-permission';
import { AccountTypeService } from './account-type.service';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { SmsCampaignToolService } from '../sms-campaigntool/sms-campaign-tool.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { EAccountTypes } from 'src/app/shared/enums/account-type.enums';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-account-type',
  templateUrl: './account-type.component.html',
  styleUrls: ['./account-type.component.scss']
})
export class AccountTypeComponent extends BasePermission implements OnInit {
  isLoading = false;
  accountTypes = [];
  accountTypeCodes = [];
  config = {
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0,
    id: 'account_type_pagination'
  };
  accountTypeForm: UntypedFormGroup;
  accountTypeEditForm: UntypedFormGroup;
  addingAccountType = false;
  accountTypeAddError = null;
  selectedAccountType = null;
  createAccountType = '';
  availableType = {
    ABM: false,
    KNOX: false
  }

  accountTypeCustomerList = [];

  constructor(
    public store: Store<IAppState>,
    private accountTypeService: AccountTypeService,
    private fb: UntypedFormBuilder,
    private smsCampaignToolService: SmsCampaignToolService,
    private modalService: NgbModal,
    private toaster: ToastrService,
    private translocoService: TranslocoService,
    private storageService: StorageService
  ) {
    super(store);
  }

  get eAccountTypes() { return EAccountTypes; }


  ngOnInit(): void {
    this.getAccountTypes();
    this.getAccountTypeCode();

    const permissions = this.storageService.getPermission();
    const hasViewContactAccountTypeCustomer = permissions.some(permission => {
      return permission.code === 'contacts.view_contactaccounttypecustomer';
    });
    if (hasViewContactAccountTypeCustomer) {
      this.getContactAccountTypesCustomers();
    }
  }

  getAccountTypeCode() {
    this.accountTypeService.getAccountTypeCode().subscribe(res => {
      this.accountTypeCodes = res?.data?.results?.filter(item => item?.is_available).map(obj => obj?.code)
    })
  }

  getContactAccountTypesCustomers() {
    this.accountTypeService.getContactAccountTypesCustomers().subscribe(res => {
      this.accountTypeCustomerList = res?.data?.results ?? [];
    }, error => {
    })
  }

  getAccountTypes() {
    this.isLoading = true;
    this.accountTypeService.getAccountTypes(this.config.currentPage).subscribe(res => {
      this.isLoading = false;
      this.config.totalItems = res?.data?.count;
      this.accountTypes = res?.data?.results?.map(item => {
        return {
          ...item,
          updating: false
        }
      });
      this.checkCreateOption();
    }, error => {
      this.isLoading = false;
    })
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getAccountTypes();
  }

  deleteAccountType(accountType) {
    this.accountTypeService.deleteAccountType(accountType?.account_id).subscribe(res => {
      this.accountTypes = this.accountTypes?.filter(item => item?.account_id !== accountType?.account_id);
      this.checkCreateOption();
      this.toaster.success(`${this.translocoService.translate('ACCOUNT_TYPE.ACCOUNT_TYPE_DELETED_SUCCESSFULLY')}`);
    }, error => {
      console.log('error: ', error);
      this.toaster.error(`${this.translocoService.translate('ACCOUNT_TYPE.ACCOUNT_TYPE_DELETED_FAILED')}`);
    })
  }

  initializeAccountTypeForm(code = '') {
    this.accountTypeForm = this.fb.group({
      'account_type_code': new UntypedFormControl(code, [Validators.required]),
      'account_id': new UntypedFormControl('', [Validators.required]),
      'account_name': new UntypedFormControl(code, []),
      'account_secret': new UntypedFormControl('', []),
    });
  }

  initializeAccountTypeEditForm(accountType) {
    this.accountTypeEditForm = this.fb.group({
      'account_type_code': new UntypedFormControl(accountType?.account_type_code || '', [Validators.required]),
      'account_id': new UntypedFormControl(accountType?.account_id || '', [Validators.required]),
      'account_name': new UntypedFormControl(accountType?.account_name || '', []),
      'account_secret': new UntypedFormControl('', []),
    });
  }

  onSetAccountType(setAccountTypeModal) {
    this.initializeAccountTypeForm();
    this.modalService.open(setAccountTypeModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  onEditAccountType(accountType, setAccountTypeModal) {
    this.selectedAccountType = accountType;
    this.initializeAccountTypeEditForm(accountType);
    this.modalService.open(setAccountTypeModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  addAccountType() {

    if (this.accountTypeForm?.invalid || !this.accountTypeForm?.dirty) {
      this.accountTypeForm.markAllAsTouched();
      return;
    }
    this.accountTypeAddError = null;
    this.smsCampaignToolService.addAccountType(this.accountTypeForm.value).subscribe(res => {
      this.accountTypes.unshift({
        ...res?.data,
        updating: false
      });
      this.checkCreateOption();
      this.initializeAccountTypeForm();
      this.toaster.success(`${this.translocoService.translate('ACCOUNT_TYPE.ACCOUNT_TYPE_ADDED_SUCCESS')}`);
      this.createAccountType = '';
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
    }, error => {
      this.accountTypeAddError = error?.error?.message || `${this.translocoService.translate('ACCOUNT_TYPE.ACCOUNT_TYPE_ADDED_FAILED')}`;
      this.toaster.error(this.accountTypeAddError);
    })
  }




  updateAccountType() {
    this.accountTypeAddError = null;
    if (!this.accountTypeEditForm.value?.account_secret?.trim()?.length) {
      delete this.accountTypeEditForm.value?.account_secret;
    }
    this.accountTypeService.updateAccountType(this.selectedAccountType?.account_id, this.accountTypeEditForm.value).subscribe(res => {
      this.accountTypes = this.accountTypes?.map(item => {
        return item?.account_id === this.selectedAccountType?.account_id ? { ...res?.data, updating: false } : { ...item }
      });
      this.initializeAccountTypeEditForm(null);
      this.selectedAccountType = null;
      this.toaster.success(`${this.translocoService.translate('ACCOUNT_TYPE.ACCOUNT_TYPE_OPERATION_SUCCESS')}`);
      if (this.modalService.hasOpenModals()) {
        this.modalService.dismissAll();
      }
    }, error => {
      this.accountTypeAddError = error?.error?.message || `${this.translocoService.translate('ACCOUNT_TYPE.ACCOUNT_TYPE_ADDED_FAILED')}`;
      this.toaster.error(this.accountTypeAddError);
    })
  }


  checkCreateOption() {
    this.availableType.ABM = this.accountTypes?.some(item => item?.account_type_code === EAccountTypes.ABM);
    this.availableType.KNOX = this.accountTypes?.some(item => item?.account_type_code === EAccountTypes.KNOX);
  }


  createEnrollment(enrollmentModal, accountType) {
    this.createAccountType = accountType;
    this.initializeAccountTypeForm(accountType)
    this.modalService.open(enrollmentModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-550 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  editEnrollment(enrollmentModal, accountType) {
    this.selectedAccountType = accountType;
    this.initializeAccountTypeEditForm(accountType);
    this.modalService.open(enrollmentModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      modalDialogClass: 'tri-modal-550 modal-runded',
      size: 'md',
      scrollable: true,
      backdrop: 'static',
    }).result.then((result) => {
      // this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  userByUuid(index, item) {
    return item.uuid;
  }

}
