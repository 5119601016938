import { Injectable } from '@angular/core';
import { ApiBaseService } from 'src/app/shared/api-base.service';
import { Router } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { HttpParams } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class CustomerService {

  private cart$ = new BehaviorSubject(null);
  currentCart$ = this.cart$.asObservable();

  constructor(private apiBaseService: ApiBaseService, private router: Router) { }

  getCustomer(customerUuid: string) {
    const customerlURI = `contacts/${customerUuid}`;
    return this.apiBaseService.get(customerlURI);
  }

  updateCustomer(customerUuid: string, payload) {
    const customerlURI = `contacts/${customerUuid}`;
    return this.apiBaseService.put(customerlURI, payload);
  }

  updateCart(cartUuid: string, payload: any) {
    const cartUpdateURI = `validate/cart/${cartUuid}`;
    return this.apiBaseService.put(cartUpdateURI, payload);
  }

  updateQuote(quoteUuid: string, payload: any) {
    const updateQuoteURI = `quotes/${quoteUuid}`;
    return this.apiBaseService.put(updateQuoteURI, payload);
  }

  createNewContactPerson(customerUuid: string, payload) {
    const createContactPersonURI = `contacts/contacts/${customerUuid}/contact-persons`;
    return this.apiBaseService.post(createContactPersonURI, payload);
  }

  updateContactPerson(contactUUID: string, contactPersonUUID: string, payload) {
    const updateContactPersonURI = `contacts/contacts/${contactUUID}/contact-persons/${contactPersonUUID}`;
    return this.apiBaseService.put(updateContactPersonURI, payload);
  }

  updateContactPersonV2(contactUUID: string, contactPersonUUID: string, payload) {
    const updateContactPersonURI = `contacts/${contactUUID}/persons/${contactPersonUUID}`;
    return this.apiBaseService.put(updateContactPersonURI, payload);
  }


  getContact(contactPersonUuid: string) {
    const url = `contacts/contact-persons/${contactPersonUuid}`;
    return this.apiBaseService.get(url);
  }

  getSearchedAddress(payload: any) {
    const addressSearchURI = `contacts/zip-code`;
    // tslint:disable-next-line:max-line-length
    const params = new HttpParams({ fromString: `zip_code=${payload.zipCode}&house_number=${payload.houseNumber}&house_number_extension=${payload.houseNumberExt}` });
    return this.apiBaseService.get(addressSearchURI, params);
  }

  submitEmptyQuote(payload: any) {
    const URI = `quotes`;
    return this.apiBaseService.post(URI, payload);
  }

  validateCart(cartUuid: string, payload: any) {
    const cartUpdateURI = `validate/cart/${cartUuid}/update`;
    return this.apiBaseService.put(cartUpdateURI, payload);
  }

  addEmailConfiguration(contact_uuid, payload) {
    let URI = `contacts/${contact_uuid}/email-configurations`
    return this.apiBaseService.post(URI, payload);
  }

  updateEmailConfiguration(contact_uuid, payload) {
    let URI = `contacts/${contact_uuid}/email-configurations/${payload?.email_configuration_code}`
    return this.apiBaseService.put(URI, payload);
  }


  getEmailConfiguration() {
    const URI = "email/configurations"
    return this.apiBaseService.get(URI);
  }
}
