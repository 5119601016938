import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';
import { ForecastService } from './forecast.service';

@Component({
  selector: 'app-forecast',
  templateUrl: './forecast.component.html',
  styleUrls: ['./forecast.component.scss']
})
export class ForecastComponent implements OnInit {
  public isLoading = false;
  public currentUser = null;
  public customer = null;
  public customerForecasts = [];
  public config = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  private contact_uuid = '';

  constructor(
    private forecastService: ForecastService,
    private storageService: StorageService,
    private router: Router,
  ) {
    const [currentUser, customer] = this.getStorageData();
    this.currentUser = currentUser;
    this.customer = customer;
  }

  ngOnInit(): void {
    if (this.currentUser.is_external || (!this.currentUser.is_external && this.customer)) {
      this.contact_uuid = this.currentUser.is_external ? this.currentUser?.contact.uuid : this.customer.uuid;
      this.getCustomerForecast();
    }
  }


  getCustomerForecast() {
    this.isLoading = true;
    this.forecastService.getContactForecastList(this.contact_uuid, this.config.currentPage).subscribe(response => {
      this.customerForecasts = response.data.results;
      this.config.totalItems = response.data.count;
      this.isLoading = false;

    }, error => {
      this.isLoading = false;

    });
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getCustomerForecast();
  }


  navigateToConfigForecast(forecast) {
    this.router.navigate([`/sales-forecast/${forecast.contact.uuid}`], {
      queryParams: { catalog: forecast.catalog.code, duration: forecast.forecast_duration },
      queryParamsHandling: 'merge'
    });
  }

  getStorageData() {
    return [this.storageService.getCurrentUser() || null, this.storageService.getCustomer() || null];
  }

}
