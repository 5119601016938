import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';

import { ApiBaseService } from '../../shared/api-base.service';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class HomeService {
  private showedSystemMessagesModal = new BehaviorSubject<boolean>(false);
  public showedSystemMessagesModal$ = this.showedSystemMessagesModal.asObservable();
  constructor(private apiBaseService: ApiBaseService) { }
  setShowedSystemMessagesModal(value: boolean) {
    this.showedSystemMessagesModal.next(value);
  }
  // tslint:disable-next-line:max-line-length
  getProducts(catalogCode: string, productGroupCode: string, page: number, pageSize: number, filterQueryString: string, contactUuid: string, searchedProductKeyword: string) {
    if (page === 0) {
      page = 1;
    }
    let paramsString = `page=${page}&page_size=${pageSize}&product_group=${productGroupCode}`;
    paramsString = paramsString.concat(filterQueryString ? filterQueryString : '');
    paramsString = paramsString.concat(contactUuid ? `&contact_uuid=${contactUuid}` : '');
    let productListURI = `search/catalogs/${catalogCode}/products`;

    if (searchedProductKeyword) {
      paramsString = `page=${page}&keyword=${searchedProductKeyword}`;
      productListURI = `search/products/list`;
    }
    const params = new HttpParams({
      fromString: paramsString
    });
    return this.apiBaseService.get(productListURI, params);
  }

  getDynamicSearchItems(catalogCode: string, productGroupCode: string) {
    const URI = `search/catalogs/${catalogCode}/product-groups/${productGroupCode}/filter-options`;

    return this.apiBaseService.get(URI);
  }

  getSystemMessages() {
    const url = 'system-messages';
    const parms = new HttpParams({
      fromString: 'is_dismissed=true'
    });
    return this.apiBaseService.get(url);
  }
  updateSystemMessage(uuid: string, data: any) {
    const url = `system-messages/${uuid}`;
    return this.apiBaseService.put(url, data);
  }

  // getFilteredProducts() {

  // }
}
