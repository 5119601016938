import { Action } from '@ngrx/store';

export enum EPermissionActions {
    PERMISSION_LOAD = 'permission load',
    PERMISSION_LOAD_SUCCESS = 'permission successfully loaded',
}

export class PermissionLoadAction implements Action {
    public readonly type = EPermissionActions.PERMISSION_LOAD;
    constructor() {}
}

export class PermissionSuccessAction implements Action {
    public readonly type = EPermissionActions.PERMISSION_LOAD_SUCCESS;
    constructor(public permission: any) {}
}

export type PermissionActions = PermissionLoadAction | PermissionSuccessAction;
