<div class="bg-kpn-subtle-grey body-container">
  <div>
    <app-business-partner-selection [showEdit]="true"></app-business-partner-selection>
  </div>
  <section class="pt-4 pb-5">
    <div class="container">
      <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4">
        <li class="back-breadcrumb-btn">
          <a routerLink="/trade-in">
            <i class="kphicon-uniE906"></i> Terug
          </a>
        </li>
        <li class="home-btn ml-2 px-1 pb-1">
          <a routerLink="/">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <g id="UI Icon">
                <path id="Color" fill-rule="evenodd" clip-rule="evenodd"
                  d="M12.6726 4.26049C12.2954 3.91317 11.7046 3.91317 11.3274 4.26049L11.3191 4.26809L11.3191 4.26804L5.68908 9.33664L5.67928 9.34546L5.67922 9.3454C5.24742 9.72395 5 10.2689 5 10.8528V18.9906C5 19.5442 5.45849 20 6 20H18C18.548 20 19 19.5477 19 19.0006V10.8628C19 10.2871 18.7512 9.73278 18.3208 9.35539L18.3102 9.34598L12.6802 4.26739L12.6726 4.26053L12.6726 4.26049ZM14.0233 2.78543C12.8818 1.73832 11.1187 1.73819 9.97717 2.78504L4.35545 7.84617C3.49056 8.60711 3 9.69955 3 10.8528V18.9906C3 20.6363 4.34151 22 6 22H18C19.652 22 21 20.6528 21 19.0006V10.8628C21 9.72147 20.5109 8.61863 19.6449 7.8565L14.0233 2.78543Z"
                  fill="#939393" />
              </g>
            </svg>
          </a>
        </li>
        <li> <a>Alle apparaten </a></li>
      </ul>

      <div class="row">
        <div class="col-md-8">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-32 mb-3">
            <div class="row">
              <div class="col-md-8">
                <h2 class="h1 font-kpn-black green-text mb-4">{{ selectedVariant?.ti_family?.name }}</h2>
                <h3 class="font-kpn-bold fs-20 pt-2">Geheugen</h3>
                <div class="tri-memory-action flex-wrap mb-4">
                  <button *ngFor="let item of productVariantList" class="action-btn font-kpn-sf-pro"
                    (click)="setSelectedVariant(item)"
                    [ngClass]="{'active' : (selectedVariant?.code === item?.code)}">{{item?.memory_size}}</button>
                  <!-- <button class="action-btn font-kpn-sf-pro">256 GB</button>
                  <button class="action-btn font-kpn-sf-pro">512 GB</button> -->
                </div>
                <h3 class="font-kpn-bold fs-20 mb-1 pt-2">Kleur</h3>
                <p class="fs-20 font-kpn-matricregular line-height-24">De kleur van het toestel heeft geen impact op de
                  prijs.</p>
              </div>
              <div class="col-md-4">
                <div class="tri-pdp-img" *ngFor="let item of productVariantList">
                  <img *ngIf="(item?.image) && (selectedVariant?.code === item?.code)" [src]="item?.image" alt="">
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-32">
            <h3 class="green-text font-kpn-black h2 mb-0">Toestellen toevoegen</h3>
            <hr>
            <p class="fs-20 font-kpn-matricregular line-height-24">
              Je kunt werkende en niet werkende apparaten toevoegen. Bij één of meer gebreken kun je het als niet
              werkend toestel opgeven.
            </p>
            <div ngbAccordion class="tri-pdp-accordion" *ngFor="let item of productVariantList; let i = index">
              <div *ngIf="selectedVariant?.code === item?.code" ngbAccordionItem class="tri-pdp-accordion-item">
                <h2 ngbAccordionHeader>
                  <button ngbAccordionButton class="d-flex align-items-end justify-content-between btn p-0 w-100">
                    <div class="text-left">
                      <span class="font-kpn-bold">Werkende apparaten toevoegen</span>
                      <p class="d-flex align-items-center font-kpn-extended m-0 pt-1"><i
                          class="kphicon-uniE973 green-text fs-20 mr-2"></i>Voeg werkende apparaten toe en doorloop het
                        stappenplan</p>
                    </div>
                    <span class="ac-arrow"></span>
                  </button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <div>
                        <hr>
                        <div class="tri-acd-inner-box mb-3">
                          <h5 class="font-kpn-bold h6">Ja, het toestel is functioneel</h5>
                          <div class="font-kpn-extended">
                            <p class="mb-2">Het apparaat schakelt normaal aan en uit:</p>
                            <ul class="pl-4 mb-2">
                              <li class="mb-1">Het apparaat moet normaal kunnen worden in- en uitgeschakeld als het
                                wordt
                                losgekoppeld van de oplader.</li>
                              <li class="mb-1">Het startscherm moet verschijnen.</li>
                            </ul>
                            <p class="mb-2">Fysiek in goede staat:</p>
                            <ul class="pl-4 mb-2">
                              <li class="mb-1">Het apparaat moet er licht gebruikt uitzien, met hooguit oppervlakkige
                                krassen.</li>
                              <li class="mb-1">Alle knoppen en aansluitingen zijn Intact.</li>
                              <li class="mb-1">Er mogen geen ontbrekende onderdelen zijn, zoals de batterij, het
                                batterijklepje, of
                                de stylus.</li>
                            </ul>
                            <p class="mb-2">Scherm functioneert goed:</p>
                            <ul class="pl-4 mb-2">
                              <!-- <li class="mb-1">Er mogen geen ontbrekende onderdelen zijn, zoals de batterij, het batterijklepje, of
                                de stylus.</li> -->
                              <li class="mb-1">Alle delen van het scherm reageren goed op aanrakingen.</li>
                            </ul>
                          </div>
                        </div>
                        <div class="tri-acd-inner-box mb-3"
                          *ngIf="((item?.brand?.check_find_my_device_status) && (item?.brand?.check_google_account_removed)) else funcSingleBrandCondition">
                          <h5 class="font-kpn-bold h6">'Zoek mijn' functie
                            <i class="tri-tooltip-btn" (click)="isCollapsedInfo=!isCollapsedInfo"
                              aria-controls="collapseExample">i</i>
                          </h5>
                          <div *ngIf="isCollapsedInfo" class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                            <p class="font-kpn-extended m-0 line-height-24">Zoek mijn app en iCloud zijn gedeactiveerd
                              op het iOS apparaat (zie op het apparaatInstellingen> iCloud> Zoek mijn), of het apparaat
                              is opnieuw ingesteld( <a target="_blank"
                                href="https://support.apple.com/nl-nl/109511"><b>klik hier</b></a> om de officiële
                              instructies te zien)</p>
                          </div>
                          <p class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                          <div class="orange-warning-qoute d-flex">
                            <i class="kphicon-uniE9B9 note-icon mr-3 "></i>
                            <p class="font-kpn-extended m-0">Apparaten met de 'Zoek mijn' functie ingeschakeld kunnen
                              niet worden ingeruild. Zorg ervoor dat deze functie is uitgeschakeld.</p>
                          </div>
                          <hr>
                          <h5 class="font-kpn-bold h6">Google accounts verwijderd
                            <i class="tri-tooltip-btn" (click)="isFunGoogleCollapsedInfo=!isFunGoogleCollapsedInfo"
                              aria-controls="collapseExample">i</i>
                          </h5>
                          <div *ngIf="isFunGoogleCollapsedInfo"
                            class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                            <p class="font-kpn-extended m-0 line-height-24">De accounts die aan de telefoon zijn
                              gekoppeld (met name Google accounts) zijn verwijderd. (Zie op het toestel Instellingen >
                              Reset > Reset alle gegevens of ( <a target="_blank"
                                href="https://support.google.com/android/answer/7664951?hl=nl"><b>klik hier</b></a> om de officiële
                              instructies te zien)</p>
                          </div>
                          <p *ngIf="isFunGoogleCollapsedInfo" class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                          <p class="font-kpn-extended mb-2">Zijn de Google-accounts en vergrendelingen verwijderd?</p>


                          <hr>
                          <div class="tri-memory-action mt-5 mb-0">
                            <button
                              (click)="updateFindMyDeviceAndGoogleAccountRemoved(i,true,'functional',item?.functional?.line)"
                              class="action-btn font-kpn-sf-pro w-50"
                              [ngClass]="{'active' : (item?.functional?.find_my_device_status_disabled === true)}">Ja</button>
                            <button
                              (click)="updateFindMyDeviceAndGoogleAccountRemoved(i,false,'functional',item?.functional?.line)"
                              class="action-btn font-kpn-sf-pro w-50"
                              [ngClass]="{'active' : (item?.functional?.find_my_device_status_disabled === false)}">Nee</button>
                          </div>
                        </div>
                        <ng-template #funcSingleBrandCondition>
                          <div class="tri-acd-inner-box mb-3" *ngIf="item?.brand?.check_find_my_device_status">
                            <h5 class="font-kpn-bold h6">'Zoek mijn' functie
                              <i class="tri-tooltip-btn" (click)="isCollapsedInfo=!isCollapsedInfo"
                                aria-controls="collapseExample">i</i>
                            </h5>
                            <div *ngIf="isCollapsedInfo"
                              class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                              <p class="font-kpn-extended m-0 line-height-24">Zoek mijn app en iCloud zijn gedeactiveerd
                                op het iOS apparaat (zie op het apparaatInstellingen> iCloud> Zoek mijn), of het
                                apparaat is opnieuw ingesteld( <a target="_blank"
                                  href="https://support.apple.com/nl-nl/109511"><b>klik hier</b></a> om de officiële
                                instructies te zien)</p>
                            </div>
                            <p class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                            <div class="tri-memory-action mb-4">
                              <button (click)="updateFindMyDevice(i,true,'functional',item?.functional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.functional?.find_my_device_status_disabled === true)}">Ja</button>
                              <button (click)="updateFindMyDevice(i,false,'functional',item?.functional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.functional?.find_my_device_status_disabled === false)}">Nee</button>
                            </div>
                            <div class="orange-warning-qoute d-flex">
                              <i class="kphicon-uniE9B9 note-icon mr-3 "></i>
                              <p class="font-kpn-extended m-0">Apparaten met de 'Zoek mijn' functie ingeschakeld kunnen
                                niet worden ingeruild. Zorg ervoor dat deze functie is uitgeschakeld.</p>
                            </div>
                          </div>
                          <div class="tri-acd-inner-box mb-3" *ngIf="item?.brand?.check_google_account_removed">
                            <h5 class="font-kpn-bold h6">Google accounts verwijderd
                              <i class="tri-tooltip-btn" (click)="isCollapsedInfo=!isCollapsedInfo"
                                aria-controls="collapseExample">i</i>
                            </h5>
                            <div *ngIf="isCollapsedInfo"
                              class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                              <p class="font-kpn-extended m-0 line-height-24">De accounts die aan de telefoon zijn
                                gekoppeld (met name Google accounts) zijn verwijderd. (Zie op het toestel Instellingen >
                                Reset > Reset alle gegevens of ( <a target="_blank"
                                  href="https://support.google.com/android/answer/7664951?hl=nl"><b>klik hier</b></a> om de officiële
                                instructies te zien)</p>
                            </div>
                            <p *ngIf="isCollapsedInfo" class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                            <p class="font-kpn-extended mb-2">Zijn de Google-accounts en vergrendelingen verwijderd?</p>

                            <div class="tri-memory-action mb-4">
                              <button (click)="updateGoogleAccountRemoved(i,true,'functional',item?.functional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.functional?.remove_google_account_status_disabled === true )}">Ja</button>
                              <button (click)="updateGoogleAccountRemoved(i,false,'functional',item?.functional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.functional?.remove_google_account_status_disabled === false)}">Nee</button>
                            </div>

                          </div>
                        </ng-template>
                        <div class="tri-acd-inner-box">
                          <h5 class="font-kpn-bold h6">Aantallen </h5>
                          <p class="font-kpn-extended mb-2">Geef aan hoeveel werkende apparaten je wilt inruilen.</p>
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="quantity d-flex align-items-center">
                              <div class="qty-in-di-box d-inline-flex align-items-center" >
                                <button
                                (click)="updateQty(i,item.functional.qty-1,'functional',item?.functional?.line)"
                                [disabled]="(!item.functional.qty) || (item?.functional?.find_my_device_status_disabled === false) || (item?.functional?.remove_google_account_status_disabled === false) ||   item.functional.qty === 0  || ((item?.brand?.check_find_my_device_status === true && item?.brand?.check_google_account_removed === true)  ? (item.functional.qty === 1 ): (item?.brand?.check_find_my_device_status === true ? item.functional.qty === 1 : (item?.brand?.check_google_account_removed === true && item.functional.qty === 1 ) ))"
                                class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                                <input
                                [disabled]="(item?.functional?.find_my_device_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === false )"
                                (keypress)="restrictNanInput($event)"
                                (keyup)="onChangeQty($event,i,item.functional.qty,'functional',item?.functional?.line,item?.brand?.check_find_my_device_status)"
                                [(ngModel)]="item.functional.qty" class="in-di-input font-kpn-extended"
                                [style]="(item?.functional?.find_my_device_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === false) ? 'background: #f3f3f3;' : ''">
                              <button
                                (click)="updateQty(i,item.functional.qty+1,'functional',item?.functional?.line)"
                                [disabled]="item?.functional?.find_my_device_status_disabled  === false || item?.functional?.find_my_device_status_disabled === null || item?.functional?.remove_google_account_status_disabled  === false || item?.functional?.remove_google_account_status_disabled === null"
                                class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                              </div>
                              <!-- <div class="qty-in-di-box d-inline-flex align-items-center" *ngIf="((item?.brand?.check_find_my_device_status) && (item?.brand?.check_google_account_removed));else funDecrementBtnSingleCondition">

                                 <button
                                    (click)="updateQty(i,item.functional.qty-1,'functional',item?.functional?.line)"
                                    [disabled]="
                                    (!item?.functional?.qty) ||
                                    (item?.functional?.find_my_device_status_disabled  === false ) || (item?.functional?.remove_google_account_status_disabled  === false ) ||
                                    (item.functional.qty === 1 )" class="kphicon-uniE972 add-remove-btn dicrement-btn">
                                  </button>
                                  <input
                                  [disabled]="(item?.functional?.find_my_device_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === false )"
                                  (keypress)="restrictNanInput($event)"
                                  (keyup)="onChangeQty($event,i,item.functional.qty,'functional',item?.functional?.line,item?.brand?.check_find_my_device_status)"
                                  [(ngModel)]="item.functional.qty" class="in-di-input font-kpn-extended"
                                  [style]="(item?.functional?.find_my_device_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === false) ? 'background: #f3f3f3;' : ''">
                                <button (click)="updateQty(i,item.functional.qty+1,'functional',item?.functional?.line)"
                                  [disabled]="item?.functional?.find_my_device_status_disabled  === false || item?.functional?.find_my_device_status_disabled  === null || item?.functional?.remove_google_account_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === null"
                                  class="kphicon-uniE985 add-remove-btn incriment-btn"></button>

                              </div> -->
                              <!-- <ng-template  #funDecrementBtnSingleCondition>

                               <div  class="qty-in-di-box d-inline-flex align-items-center">
                                <button

                                (click)="updateQty(i,item.functional.qty-1,'functional',item?.functional?.line)"
                                [disabled]="
                                (!item?.functional?.qty) ||
                                (item?.functional?.find_my_device_status_disabled  === false ) || (item?.functional?.remove_google_account_status_disabled  === false ) ||
                                (item.functional.qty === 1 )" class="kphicon-uniE972 add-remove-btn dicrement-btn">
                              </button>
                              <input
                              [disabled]="(item?.functional?.find_my_device_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === false )"
                              (keypress)="restrictNanInput($event)"
                              (keyup)="onChangeQty($event,i,item.functional.qty,'functional',item?.functional?.line,item?.brand?.check_find_my_device_status)"
                              [(ngModel)]="item.functional.qty" class="in-di-input font-kpn-extended"
                              [style]="(item?.functional?.find_my_device_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === false) ? 'background: #f3f3f3;' : ''">
                              <button (click)="updateQty(i,item.functional.qty+1,'functional',item?.functional?.line)"
                                [disabled]="item?.functional?.find_my_device_status_disabled  === false || item?.functional?.find_my_device_status_disabled  === null || item?.functional?.remove_google_account_status_disabled  === false || item?.functional?.remove_google_account_status_disabled  === null"
                                class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                               </div>
                              </ng-template> -->
                            </div>
                            <div class="text-right">
                              <p class="m-0 fs-12 mb-1 text-black-50">Totaal</p>
                              <h6 class="green-text font-kpn-bold m-0"
                                *ngIf="item?.functional?.line;else funcPrecartPrice"> {{
                                item?.functional?.line?.total_price_ex_vat | currencyFormat}}</h6>
                              <ng-template #funcPrecartPrice>
                                <h6 class="green-text font-kpn-bold m-0"> {{ (item?.functional?.preCart ?
                                  item?.functional?.preCart?.total_price_ex_vat :
                                  ((item?.brand?.check_find_my_device_status === false) ||
                                  (item?.brand?.check_google_account_removed === false)) ? "0.00" :
                                  item?.functional?.totalPrice) | currencyFormat}}</h6>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
              <div *ngIf="selectedVariant?.code === item?.code" ngbAccordionItem class="tri-pdp-accordion-item">
                <h2 ngbAccordionHeader>
                  <button ngbAccordionButton class="d-flex align-items-end justify-content-between btn p-0 w-100">
                    <div class="text-left">
                      <span class="font-kpn-bold">Niet werkende apparaten toevoegen</span>
                      <p class="d-flex align-items-center font-kpn-extended m-0 pt-1"><i
                          class="kphicon-uniE973 green-text fs-20 mr-2"></i>Voeg niet werkende apparaten toe en
                        doorloop het stappenplan</p>
                    </div>
                    <span class="ac-arrow"></span>
                  </button>
                </h2>
                <div ngbAccordionCollapse>
                  <div ngbAccordionBody>
                    <ng-template>
                      <div>
                        <hr>
                        <div class="tri-acd-inner-box mb-3">
                          <h5 class="font-kpn-bold h6">Nee, het apparaat heeft gebreken</h5>
                          <div class="font-kpn-extended">
                            <p class="mb-2">Problemen met in- en uitschakelen:</p>
                            <ul class="pl-4 mb-3">
                              <li class="mb-1">Het apparaat gaat niet (correct) aan of uit wanneer het wordt
                                losgekoppeld van de
                                oplader.</li>
                              <li class="mb-1">Het startscherm wordt niet weergegeven.</li>
                            </ul>
                            <p class="mb-2">Fysiek in gebruikte staat:</p>
                            <ul class="pl-4 mb-2">
                              <li class="mb-1">Het apparaat ziet er gebruikt uit met zichtbare krassen en/of
                                stootschade.</li>

                              <li class="mb-1">Er ontbreken niet-verwijderbare elementen, zoals knoppen,
                                luidsprekerroosters,
                                optische lenzen, SIM-lades, schroeven, enz.</li>
                            </ul>
                            <p class="mb-2">Problemen met het scherm:</p>
                            <ul class="pl-4 mb-2">
                              <li class="mb-1">Een of meerdere delen van het scherm reageren niet op aanraking.</li>
                              <li class="mb-1">Een of meerdere delen van het scherm vertonen weergaveproblemen, zoals
                                gele vlekken,
                                zwarte vlekken, witte banden, enz..</li>
                            </ul>
                          </div>
                        </div>
                        <div class="tri-acd-inner-box mb-3"
                          *ngIf="((item?.brand?.check_find_my_device_status) && (item?.brand?.check_google_account_removed)) else nonFuncSingleBrandCondition">
                          <h5 class="font-kpn-bold h6">'Zoek mijn' functie
                            <i class="tri-tooltip-btn"
                              (click)="isNonFunctionalCollapsedInfo=!isNonFunctionalCollapsedInfo"
                              aria-controls="collapseExample">i</i>
                          </h5>
                          <div *ngIf="isNonFunctionalCollapsedInfo"
                            class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                            <p class="font-kpn-extended m-0 line-height-24">Zoek mijn app en iCloud zijn gedeactiveerd
                              op het iOS apparaat (zie op het apparaatInstellingen> iCloud> Zoek mijn), of het apparaat
                              is opnieuw ingesteld( <a target="_blank"
                                href="https://support.apple.com/nl-nl/109511"><b>klik hier</b></a> om de officiële
                              instructies te zien)</p>
                          </div>
                          <p class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                          <div class="orange-warning-qoute d-flex">
                            <i class="kphicon-uniE9B9 note-icon mr-3 "></i>
                            <p class="font-kpn-extended m-0">Apparaten met de 'Zoek mijn' functie ingeschakeld kunnen
                              niet worden ingeruild. Zorg ervoor dat deze functie is uitgeschakeld.</p>
                          </div>
                          <hr>
                          <h5 class="font-kpn-bold h6">Google accounts verwijderd
                            <i class="tri-tooltip-btn"
                              (click)="isNonFunGoogleCollapsedInfo=!isNonFunGoogleCollapsedInfo"
                              aria-controls="collapseExample">i</i>
                          </h5>
                          <div *ngIf="isNonFunGoogleCollapsedInfo"
                            class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                            <p class="font-kpn-extended m-0 line-height-24">De accounts die aan de telefoon zijn
                              gekoppeld (met name Google accounts) zijn verwijderd. (Zie op het toestel Instellingen >
                              Reset > Reset alle gegevens of ( <a target="_blank"
                                href="https://support.google.com/android/answer/7664951?hl=nl"><b>klik hier</b></a> om de officiële
                              instructies te zien)</p>
                          </div>
                          <p *ngIf="isNonFunGoogleCollapsedInfo" class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                          <p class="font-kpn-extended mb-2">Zijn de Google-accounts en vergrendelingen verwijderd?</p>
                          <hr>
                          <div class="tri-memory-action mt-5 mb-0">
                            <button
                              (click)="updateFindMyDeviceAndGoogleAccountRemoved(i,true,'non_functional',item?.nonFunctional?.line)"
                              class="action-btn font-kpn-sf-pro w-50"
                              [ngClass]="{'active' : ((item?.nonFunctional?.find_my_device_status_disabled === true) ||( item?.nonFunctional?.remove_google_account_status_disabled === true))}">Ja</button>
                            <button
                              (click)="updateFindMyDeviceAndGoogleAccountRemoved(i,false,'non_functional',item?.nonFunctional?.line)"
                              class="action-btn font-kpn-sf-pro w-50"
                              [ngClass]="{'active' : ((item?.nonFunctional?.find_my_device_status_disabled === false) || ( item?.nonFunctional?.remove_google_account_status_disabled === false))}">Nee</button>
                          </div>
                        </div>
                        <ng-template #nonFuncSingleBrandCondition>
                          <div class="tri-acd-inner-box mb-3" *ngIf="item?.brand?.check_find_my_device_status">
                            <h5 class="font-kpn-bold h6">'Zoek mijn' functie
                              <i class="tri-tooltip-btn"
                                (click)="isNonFunctionalCollapsedInfo=!isNonFunctionalCollapsedInfo"
                                aria-controls="collapseExample">i</i>
                            </h5>
                            <div *ngIf="isNonFunctionalCollapsedInfo"
                              class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                              <p class="font-kpn-extended m-0 line-height-24">Zoek mijn app en iCloud zijn gedeactiveerd
                                op het iOS apparaat (zie op het apparaatInstellingen> iCloud> Zoek mijn), of het
                                apparaat
                                is opnieuw ingesteld( <a target="_blank"
                                  href="https://support.apple.com/nl-nl/109511"><b>klik hier</b></a> om de officiële
                                instructies te zien)</p>
                            </div>
                            <p class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                            <div class="tri-memory-action mb-4">
                              <button (click)="updateFindMyDevice(i,true,'non_functional',item?.nonFunctional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.nonFunctional?.find_my_device_status_disabled ===true)}">Ja</button>
                              <button (click)="updateFindMyDevice(i,false,'non_functional',item?.nonFunctional?.line)"
                                class="action-btn font-kpn-sf-pro w-50 "
                                [ngClass]="{'active' : (item?.nonFunctional?.find_my_device_status_disabled === false)}">Nee</button>
                            </div>
                            <div class="orange-warning-qoute d-flex">
                              <i class="kphicon-uniE9B9 note-icon mr-3 "></i>
                              <p class="font-kpn-extended m-0">Apparaten met de 'Zoek mijn' functie ingeschakeld kunnen
                                niet worden ingeruild. Zorg ervoor dat deze functie is uitgeschakeld.</p>
                            </div>
                          </div>
                          <div class="tri-acd-inner-box mb-3" *ngIf="item?.brand?.check_google_account_removed">
                            <h5 class="font-kpn-bold h6">Google accounts verwijderd
                              <i class="tri-tooltip-btn" (click)="isCollapsedInfo=!isCollapsedInfo"
                                aria-controls="collapseExample">i</i>
                            </h5>
                            <div *ngIf="isCollapsedInfo"
                              class="blue-warning-qoute blue-warning-qoute-border d-flex mb-2">
                              <p class="font-kpn-extended m-0 line-height-24">De accounts die aan de telefoon zijn
                                gekoppeld (met name Google accounts) zijn verwijderd. (Zie op het toestel Instellingen >
                                Reset > Reset alle gegevens of ( <a target="_blank"
                                  href="https://support.google.com/android/answer/7664951?hl=nl"><b>klik hier</b></a> om de officiële
                                instructies te zien)</p>
                            </div>
                            <p *ngIf="isCollapsedInfo" class="font-kpn-extended mb-2">Is de 'Zoek mijn' functie uitgeschakeld?</p>
                            <p class="font-kpn-extended mb-2">Zijn de Google-accounts en vergrendelingen verwijderd?</p>
                            <div class="tri-memory-action mb-4">
                              <button
                                (click)="updateGoogleAccountRemoved(i,true,'non_functional',item?.nonFunctional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.nonFunctional?.remove_google_account_status_disabled === true )}">Ja</button>
                              <button
                                (click)="updateGoogleAccountRemoved(i,false,'non_functional',item?.nonFunctional?.line)"
                                class="action-btn font-kpn-sf-pro w-50"
                                [ngClass]="{'active' : (item?.nonFunctional?.remove_google_account_status_disabled === false)}">Nee</button>
                            </div>

                          </div>
                        </ng-template>
                        <div class="tri-acd-inner-box">
                          <h5 class="font-kpn-bold h6">Aantallen</h5>
                          <p class="font-kpn-extended mb-2">Geef aan hoeveel werkende apparaten je wilt inruilen.</p>
                          <div class="d-flex align-items-center justify-content-between">
                            <div class="quantity d-flex align-items-center">
                              <div class="qty-in-di-box d-inline-flex align-items-center">
                                <button
                                  (click)="updateQty(i,item.nonFunctional.qty-1,'non_functional',item?.nonFunctional?.line)"
                                  [disabled]="(!item.nonFunctional.qty) || (item?.nonFunctional?.find_my_device_status_disabled === false) || (item?.nonFunctional?.remove_google_account_status_disabled === false) ||   item.nonFunctional.qty === 0  || ((item?.brand?.check_find_my_device_status === true && item?.brand?.check_google_account_removed === true)  ? (item.nonFunctional.qty === 1 ): (item?.brand?.check_find_my_device_status === true ? item.nonFunctional.qty === 1 : (item?.brand?.check_google_account_removed === true && item.nonFunctional.qty === 1 ) ))"
                                  class="kphicon-uniE972 add-remove-btn dicrement-btn"></button>
                                <input
                                  [disabled]="(item?.nonFunctional?.find_my_device_status_disabled  === false || item?.nonFunctional?.remove_google_account_status_disabled  === false )"
                                  (keypress)="restrictNanInput($event)"
                                  (keyup)="onChangeQty($event,i,item.nonFunctional.qty,'non_functional',item?.nonFunctional?.line,item?.brand?.check_find_my_device_status)"
                                  [(ngModel)]="item.nonFunctional.qty" class="in-di-input font-kpn-extended" type="text"
                                  [style]="item?.nonFunctional?.find_my_device_status_disabled  === false ? 'background: #f3f3f3;' : ''">
                                <button
                                  (click)="updateQty(i,item.nonFunctional.qty+1,'non_functional',item?.nonFunctional?.line)"
                                  [disabled]="item?.nonFunctional?.find_my_device_status_disabled  === false || item?.nonFunctional?.find_my_device_status_disabled === null || item?.nonFunctional?.remove_google_account_status_disabled  === false || item?.nonFunctional?.remove_google_account_status_disabled === null"
                                  class="kphicon-uniE985 add-remove-btn incriment-btn"></button>
                              </div>
                            </div>
                            <div class="text-right">
                              <p class="m-0 fs-12 mb-1 text-black-50">Totaal</p>
                              <!-- <h6 class="green-text font-kpn-bold m-0">€{{ item?.nonFunctional?.preCart ? item?.nonFunctional?.preCart?.total_price_ex_vat : item?.nonFunctional?.totalPrice }}</h6> -->
                              <h6 class="green-text font-kpn-bold m-0"
                                *ngIf="item?.nonFunctional?.line;else nonFuncPrecartPrice"> {{
                                item?.nonFunctional?.line?.total_price_ex_vat | currencyFormat}}</h6>
                              <ng-template #nonFuncPrecartPrice>
                                <h6 class="green-text font-kpn-bold m-0">{{ (item?.nonFunctional?.preCart ?
                                  item?.nonFunctional?.preCart?.total_price_ex_vat :(
                                  (item?.brand?.check_find_my_device_status === false )||
                                  (item?.brand?.check_google_account_removed === false)) ? "0.00" :
                                  item?.nonFunctional?.totalPrice) | currencyFormat}}
                                </h6>
                              </ng-template>
                            </div>
                          </div>
                        </div>
                      </div>
                    </ng-template>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- cart details  -->
        <div class="col-md-4 pl-md-0">
          <div class="bg-white tri-shadow-xl tri-rounded-20 p-4">
            <h3 class="h3 green-text font-kpn-black">Verkoopprijs</h3>
            <div class="blue-warning-qoute d-flex mb-4">
              <i class="kphicon-uniE952 note-icon mr-3 "></i>
              <p class="font-kpn-extended m-0 line-height-24">Dit is je geschatte verkoopprijs. De geschatte prijs kan
                afwijken van de uiteindelijke verkoopprijs na controle.</p>
            </div>
            <hr>
            <h6 class="font-kpn-bold">inruilwaarde</h6>
            <div *ngFor="let item of productVariantList">
              <div *ngIf="item?.code === selectedVariant?.code">
                <p class="d-flex align-items-center justify-content-between mb-1"
                  *ngIf="item?.functional?.line;else functionPreCartPriceDetails">
                  <span class="font-kpn-extended">Werkende apparaten ({{ item?.functional?.line?.qty }})</span>
                  <strong class="font-kpn-bold green-text">{{ item?.functional?.line?.total_price_ex_vat |
                    currencyFormat }}</strong>
                </p>
                <ng-template #functionPreCartPriceDetails>
                  <p class="d-flex align-items-center justify-content-between mb-1"
                    *ngIf="item?.functional?.preCart;else functionalEmptyPreCart">
                    <span class="font-kpn-extended">Werkende apparaten ({{ item?.functional?.preCart?.qty }})</span>
                    <strong class="font-kpn-bold green-text">{{ item?.functional?.preCart?.total_price_ex_vat |
                      currencyFormat
                      }}</strong>
                  </p>
                  <ng-template #functionalEmptyPreCart>
                    <p class="d-flex align-items-center justify-content-between mb-1">
                      <span class="font-kpn-extended">Werkende apparaten </span>
                      <strong class="font-kpn-bold green-text">€0,00</strong>
                    </p>
                  </ng-template>
                </ng-template>

                <p class="d-flex align-items-center justify-content-between"
                  *ngIf="item?.nonFunctional?.line; else nonFunctionPreCartPriceDetails">
                  <span class="font-kpn-extended">Niet werkende apparaten ({{ item?.nonFunctional?.line?.qty }})</span>
                  <strong class="font-kpn-bold green-text">{{ item?.nonFunctional?.line?.total_price_ex_vat |
                    currencyFormat }}</strong>
                </p>
                <ng-template #nonFunctionPreCartPriceDetails>
                  <p class="d-flex align-items-center justify-content-between"
                    *ngIf="item?.nonFunctional?.preCart; else nonFunctionalEmptyPreCart">
                    <span class="font-kpn-extended">Niet werkende apparaten ({{ item?.nonFunctional?.preCart?.qty
                      }})</span>
                    <strong class="font-kpn-bold green-text">{{ item?.nonFunctional?.preCart?.total_price_ex_vat |
                      currencyFormat
                      }}</strong>
                  </p>
                  <ng-template #nonFunctionalEmptyPreCart>
                    <p class="d-flex align-items-center justify-content-between mb-1">
                      <span class="font-kpn-extended">Niet werkende apparaten </span>
                      <strong class="font-kpn-bold green-text">€0,00</strong>
                    </p>
                  </ng-template>
                </ng-template>
                <hr class="mb-3">
                <div>
                  <p class="d-flex align-items-center justify-content-between">
                    <span class="font-kpn-extended">Voorlopig totaal</span>
                    <strong class="font-kpn-black green-text h4 mb-0"
                      *ngIf="(item?.functional?.line || item?.nonFunctional?.line);else preCartTotal">{{
                      getTotalPrice(item?.functional?.line?.total_price_ex_vat,item?.nonFunctional?.line?.total_price_ex_vat)
                      | currencyFormat}}</strong>
                    <ng-template #preCartTotal>
                      <strong class="font-kpn-black green-text h4 mb-0" *ngIf="item?.preCart; else emptyPreCart">{{
                        item?.preCart?.total_price_ex_vat | currencyFormat }}</strong>
                      <ng-template #emptyPreCart>
                        <strong class="font-kpn-black green-text h4 mb-0">€0,00</strong>
                      </ng-template>
                    </ng-template>
                  </p>
                  <!-- <ng-template  #preCartTotal>
                    <p class="d-flex align-items-center justify-content-between" >
                      <span class="font-kpn-extended">Voorlopig totaal</span>
                      <strong class="font-kpn-black green-text h4 mb-0">{{ preCart?.total_price_ex_vat }}</strong>
                    </p>
                  </ng-template> -->

                </div>
                <!-- <p class="d-flex align-items-center justify-content-between">
                  <ng-template *ngIf="tradeInCart;else preCartTotal">
                    <span class="font-kpn-extended">Voorlopig totaal</span>
                    <strong class="font-kpn-black green-text h4 mb-0">2.100,00</strong>
                  </ng-template>
                  <ng-template #preCartTotal>
                    <span class="font-kpn-extended">Voorlopig totaal</span>
                    <strong class="font-kpn-black green-text h4 mb-0">{{ preCart?.total_price_ex_vat }}</strong>
                  </ng-template>
                </p> -->
              </div>
            </div>
            <div>
              <button [disabled]="isAddToCartDisabled" class="btn btn-block btn-primary mt-2 rounded-btn"
                (click)="addToTradeInCart(miniCartDetailsModal)">Doorgaan</button>
            </div>
          </div>

          <ng-template #miniCartDetailsModal let-modal>
            <div class="modal-header tri-modal-header">
              <h6 class="modal-title green-text font-kpn-black" id="triListFilter">Toevoegen</h6>
              <button type="button" class="btn close" aria-label="Close" (click)="modal.close()">
                <span class="kphicon-uniE926"></span>
              </button>
            </div>

            <div class="modal-body px-0 pt-0">
              <div class="add-cart-added-item">
                <div class="d-flex align-items-center justify-content-between add-cart-item"
                  *ngIf="selectedVariant?.functional?.line">
                  <div>
                    <h6 class="font-kpn-bold mb-0">{{ selectedVariant?.ti_family?.name }}</h6>
                    <p class="font-kpn-extended mb-0 line-height-24">Geheugen: {{ selectedVariant?.memory_size }}</p>
                    <p class="font-kpn-extended mb-0 line-height-24">Werkende apparaten: {{
                      selectedVariant?.functional?.qty }}</p>
                  </div>
                  <div class="addCart-img">
                    <img [src]="selectedVariant?.image" alt="">
                  </div>
                </div>
                <div class="d-flex align-items-center justify-content-between add-cart-item"
                  *ngIf="selectedVariant?.nonFunctional?.line">
                  <div>
                    <h6 class="font-kpn-bold mb-0">{{ selectedVariant?.ti_family?.name }}</h6>
                    <p class="font-kpn-extended mb-0 line-height-24">Geheugen: {{ selectedVariant?.memory_size }}</p>
                    <p class="font-kpn-extended mb-0 line-height-24">Niet werkende apparaten: {{
                      selectedVariant?.nonFunctional?.qty }}</p>
                  </div>
                  <div class="addCart-img">
                    <img [src]="selectedVariant?.image" alt="">
                  </div>
                </div>
              </div>
              <div class="px-4 py-3">
                <div class="px-1">
                  <button (click)="navigateToCartDetails()" class="btn btn-block btn-primary mt-2 rounded-btn">
                    <!-- <div
                            class="spinner-border spinner-border-sm field-spinner mr-1" role="status">
                        </div> -->
                    <span>Doorgaan en afronden</span>
                  </button>
                  <button (click)="back()" class="btn btn-block btn-outline-primary border-2 mt-2 rounded-btn">
                    <span>Ander toestel toevoegen</span>
                  </button>
                </div>
              </div>
            </div>
          </ng-template>

          <div class="py-4">
            <p class="font-kpn-extended mb-2">
              <img class="mr-2" src="../../../../assets/images/UI-Icon.svg" alt="">
              Meest duurzame netwerk
            </p>
            <p class="font-kpn-extended mb-2">
              <img class="mr-2" src="../../../../assets/images/UI-euro-Icon.svg" alt="">
              Verantwoord ondernemen
            </p>
            <p class="font-kpn-extended mb-2">
              <img class="mr-2" src="../../../../assets/images/UI-equlizer-Icon.svg" alt="">
              Kostenbesparing
            </p>
          </div>

        </div>
      </div>
    </div>
  </section>
</div>
