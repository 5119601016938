import { Component, OnInit } from '@angular/core';
import { VoucherListService } from './voucher-list.service';
import { StorageService } from '../../shared/storage.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { FormControl, FormGroup, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import * as moment from "moment";
import { BasePermission } from 'src/app/shared/base/base-permission';
import { IAppState } from '../../app.state';
import { Store } from '@ngrx/store';
import { TranslocoService } from '@ngneat/transloco';

@Component({
  selector: 'app-voucher-list',
  templateUrl: './voucher-list.component.html',
  styleUrls: ['./voucher-list.component.scss']
})
export class VoucherListComponent extends BasePermission implements OnInit {
  isLoading = false;
  voucherProfileList: any[] = [];
  voucherList: any[] = [];
  contactUuid = null;
  pageSize = 20;
  public config = {
    id: 'voucher_profile_list',
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  public voucherConfig = {
    id: 'voucher_list',
    itemsPerPage: 30,
    currentPage: 1,
    totalItems: 0
  };
  selectedProfileCode = null;
  basicForm: UntypedFormGroup;
  anonymousVoucherForm: UntypedFormGroup;
  formSubmitting = false;
  errors: any;
  anonymousVoucherErrors: any = null;
  modalReference: NgbModalRef;
  profileCode = null;
  availableAmount = [];
  selectedVoucherCode = '';
  voucherSearchForm: FormGroup;
  startAt = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());;

  constructor(
    private voucherListService: VoucherListService,
    private storageService: StorageService,
    private toastrService: ToastrService,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    public store: Store<IAppState>,
    public translocoService: TranslocoService,
  ) {
    super(store);
  }

  ngOnInit(): void {
    this.contactUuid = this.storageService.getCurrentUser()?.contact?.uuid;
    this.getVoucherProfileList(1);
  }

  getVoucherProfileList(pageNumber) {
    this.isLoading = true;
    this.voucherListService.getVoucherProfile(this.contactUuid, pageNumber, this.pageSize).subscribe(
      (response) => {
        this.config.totalItems = response?.data?.count;
        this.config.itemsPerPage = this.pageSize;
        this.voucherProfileList.push(...response?.data?.results);
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  openVoucherList(code, voucherListModal) {
    this.selectedVoucherCode = code;
    this.isLoading = true;
    this.profileCode = code;
    this.voucherConfig.totalItems = 0;
    this.voucherListService.getVoucherList(code).subscribe(
      (response) => {
        this.voucherConfig.totalItems = response?.data?.count;
        this.voucherList = response?.data?.results?.map(item => {
          return { ...item, loading: false };
        });
        this.isLoading = false;
        this.modalReference = this.modalService.open(voucherListModal, {
          ariaLabelledBy: 'modal-basic-title',
          centered: true,
          size: 'xl',
          scrollable: true,
          backdrop: 'static',
        });
        this.initilizeVoucherSearchForm();
        this.modalReference.result.then((result) => {
          this.voucherList = [];
          this.selectedVoucherCode = '';
        }).catch(e => {

        });
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }



  getPaginateVoucher() {
    this.voucherListService.getVoucherList(
      this.selectedVoucherCode,
      this.voucherConfig.currentPage,
      this.voucherConfig.itemsPerPage).subscribe(
        (response) => {
          this.voucherList = response?.data?.results?.map(item => {
            return { ...item, loading: false };
          });
          this.isLoading = false;
        },
        (error) => {
          this.isLoading = false;
        }
      );
  }

  sendInvitation(profile_code, item) {
    item.loading = true;
    this.voucherListService.sendInvitation(profile_code, item.voucher_code).subscribe(
      (response) => {
        item.loading = false;
        this.showToaster(this.translocoService.translate('VOUCHER_LIST.INVITATION_SENT_TO_EMAIL'), 'success');

      },
      (error) => {
        item.loading = false;
        this.showToaster(error?.error?.data?.message || this.translocoService.translate('VOUCHER_LIST.INVITATION_SENDING_FAILED'), 'Error');


      }
    );
  }

  createVoucher() {
    if (this.basicForm?.touched) {
      const valid_from = this.basicForm.value['valid_from'] ? moment(this.basicForm.value['valid_from']).format() : null;
      const valid_until = this.basicForm.value['valid_until'] ? moment(this.basicForm.value['valid_until']).format() : null;

      this.formSubmitting = true;
      this.voucherListService.submitVoucher(
        this.selectedProfileCode, this.cleanObj({ ...this.basicForm.value, valid_from, valid_until })
      ).subscribe((response) => {
        const v_code = this.selectedProfileCode;
        // this.showToaster(`Voucher successfully created for ${v_code}`, 'success');
        this.formSubmitting = false;
        this.closeVoucher();
      }, error => {
        this.handleError(error?.error?.error);
        this.formSubmitting = false;
      });
    }
  }

  downloadVoucher() {
    if (this.anonymousVoucherForm?.touched) {
      const valid_from = this.anonymousVoucherForm.value['valid_from'] ? moment(this.anonymousVoucherForm.value['valid_from']).format() : null;
      const valid_until = this.anonymousVoucherForm.value['valid_until'] ? moment(this.anonymousVoucherForm.value['valid_until']).format() : null;

      this.formSubmitting = true;
      this.voucherListService.generateAnonymousVoucher(
        this.selectedProfileCode, this.cleanObj({ ...this.anonymousVoucherForm.value, valid_from, valid_until })
      ).subscribe((response) => {
        let download_url = response?.data?.download_link.replace(/\/$/, "");
        this.downloadAnonymousVoucher(download_url);
      }, error => {
        this.formSubmitting = false;
        this.anonymousVoucherErrors = error?.error?.error;
      });
    }
  }

  async downloadAnonymousVoucher(url: string) {
    this.voucherListService.downloadAnonymousVoucher(url).subscribe(response => {
      const fileURL = window.URL.createObjectURL(new Blob([response]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `${this.selectedProfileCode}_${Date.now()}.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();
      this.showToaster(`${this.translocoService.translate('VOUCHER_LIST.DOWNLOAD_COMPLETE')}`, 'success');
      this.formSubmitting = false;
    }, error => {
      this.formSubmitting = false;
      error?.error.text().then(value => {
        let err = JSON.parse(value);
        console.log(err);
        this.showToaster(err?.error?.message || `${this.translocoService.translate('VOUCHER_LIST.DOWNLOAD_FAILED')}`, 'error');
      }).catch(error1 => {
        console.log("Something went wrong" + error1);
      });
    });
  }

  public noTailingSpaceValidator(control: FormControl) {
    if (control?.value?.startsWith(' ')) {
      return {
        'trimError': { value: `${this.translocoService.translate('VOUCHER_LIST.EMAIL_HAS_LEADING_WHITESPACE')}` }
      };
    }
    if (control?.value?.endsWith(' ')) {
      return {
        'trimError': { value: `${this.translocoService.translate('VOUCHER_LIST.EMAIL_HAS_TRAILING_WHITESPACE')}` }
      };
    }

    return null;
  }

  private initiateVoucherForm() {
    this.basicForm = this.fb.group({
      email: new UntypedFormControl(null, [Validators.required, Validators.email, this.noTailingSpaceValidator],),
      name: new UntypedFormControl(null, [Validators.required]),
      amount: new UntypedFormControl('', [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
      reference: new UntypedFormControl(null),
      valid_from: new UntypedFormControl(null),
      valid_until: new UntypedFormControl(null),
    });
  }

  private initiateAnonymousVoucherForm() {
    this.anonymousVoucherForm = this.fb.group({
      amount: new UntypedFormControl('', [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
      quantity: new UntypedFormControl('', [Validators.required, Validators.pattern('[+-]?([0-9]*[.])?[0-9]+')]),
      valid_from: new UntypedFormControl(null),
      valid_until: new UntypedFormControl(null),
    });
  }

  openAnonymousVoucherDownload(code, anonymousVoucherModal) {
    this.anonymousVoucherErrors = null;
    this.initiateAnonymousVoucherForm();
    this.selectedProfileCode = code;
    this.availableAmount = [...this.voucherProfileList.find(data => data.code === code)?.available_voucher_amount];
    this.modalReference = this.modalService.open(anonymousVoucherModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
      this.selectedProfileCode = null;
    }).catch(e => {

    });
  }

  openVoucherModal(code, voucherCreateModal) {
    this.errors = null;
    this.initiateVoucherForm();
    this.selectedProfileCode = code;
    this.availableAmount = [...this.voucherProfileList.find(data => data.code === code)?.available_voucher_amount];
    this.modalReference = this.modalService.open(voucherCreateModal, {
      ariaLabelledBy: 'modal-basic-title',
      centered: true,
      size: 'xl',
      scrollable: true,
      backdrop: 'static',
    });
    this.modalReference.result.then((result) => {
      this.selectedProfileCode = null;
    }).catch(e => {

    });
  }

  closeVoucher() {
    this.selectedProfileCode = null;
    this.modalReference.close();
    this.initiateVoucherForm();
    this.initiateAnonymousVoucherForm();
  }

  cleanObj(obj) {
    for (const propName in obj) {
      if (obj[propName] === null || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  onPageClick(event) {
    this.config.currentPage = event;
    this.getVoucherProfileList(event);
  }

  onVoucherPageClick(event) {
    this.voucherConfig.currentPage = event;
    this.getPaginateVoucher();
  }

  showToaster(msg: string, type: string) {
    if (type === 'success') {
      this.toastrService.success(`${msg}`, 'Success', {
        timeOut: 2000
      });
    } else {
      this.toastrService.error(`${msg}`, 'Failed', {
        timeOut: 2000
      });
    }
  }

  handleError(error: any) {
    this.errors = {};
    for (const key in error) {
      if (error.hasOwnProperty(key) && error?.[key]) {
        if (!error[key].hasOwnProperty('message') || !error[key].hasOwnProperty('error')) {
          this.errors[key] = error[key]?.find(obj => obj) || null;
        } else {
          this.errors[key] = error[key].message || error[key].error || error[key]?.[0];
        }
      } else {
        this.errors['generic'] = error?.message;
      }
    }
  }

  onSearchVoucher(): void {
    this.isLoading = true;
    const searchQuery = this.voucherSearchForm.get('search').value;
    this.voucherListService.getVoucherList(this.selectedVoucherCode, 1, 30, searchQuery).subscribe(
      (response) => {
        this.voucherConfig.totalItems = response?.data?.count ?? 0;
        this.voucherConfig.currentPage = 1;
        this.voucherList = response?.data?.results?.map(item => {
          return { ...item, loading: false };
        });
        this.isLoading = false;
      },
      (error) => {
        this.isLoading = false;
      }
    );
  }

  clearVoucherSearch(): void {
    this.voucherSearchForm.get('search').setValue(null);
    this.onSearchVoucher();
  }

  initilizeVoucherSearchForm(): void {
    this.voucherSearchForm = this.fb.group({
      search: [null, [Validators.required]],
    });
  }

  lockVoucher(voucherCode: string) {
    this.isLoading = true;
    this.voucherListService.lockVoucher(this.profileCode, voucherCode).subscribe(
      (response) => {
        this.voucherList = this.voucherList.map((item) => item.code === voucherCode ? { ...response.data } : item)
        this.isLoading = false;
        this.showToaster(`${this.translocoService.translate('VOUCHER_LIST.VOUCHER_LOCKED')}`, 'success');
      },
      (error) => {
        this.isLoading = false;
        this.showToaster(error?.error?.data?.message || `${this.translocoService.translate('VOUCHER_LIST.VOUCHER_LOCKING_FAILED')}`, 'Error');
      }
    );
  }
}
