import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import KeenSlider, { KeenSliderInstance } from "keen-slider"
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';
import { ProductDetailService } from 'src/app/pages/product-details/product-detail.service';

interface ColorOptions {
  code: string;
  name: string;
  color_code: string;
  attribute_code: string;
}

interface MemoryOptions {
  code: string;
  name: string;
  color_code: string;
  attribute_code: string;
}

@Component({
  selector: 'app-product-details-no-handset',
  templateUrl: './product-details-no-handset.component.html',
  styleUrls: ['./product-details-no-handset.component.scss']
})
export class ProductDetailsNoHandsetComponent implements OnInit, AfterViewInit {
  @Input() product;
  loading = false;

  public product$: Observable<any>;
  selectedProduct: any;
  longDescription: string;
  isSubmitted = false;


  colorOptions: ColorOptions[];
  memorySizeOptions: MemoryOptions[];
  selectedColor: '';
  selectedMemorySize: '';

  moreSpecification = false;
  productAttributes = [];
  productImg = [];
  public qParams = null;
  public catalogCode = '';
  public groupCode = '';

  // keenSlider start

  @ViewChild("sliderRef") sliderRef: ElementRef<HTMLElement>
  currentSlide: number = 1
  dotHelper: Array<Number> = []
  slider: KeenSliderInstance = null

  constructor(
    private productDetailService: ProductDetailService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.queryParams.subscribe(p => {
      if (p.hasOwnProperty('catalogCode')) {
        this.catalogCode = p.catalogCode;
        this.groupCode = p.groupCode;
        this.qParams = p;
      } else {
        this.qParams = { ...p, catalogCode: this.catalogCode, groupCode: this.groupCode };
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.slider = new KeenSlider(this.sliderRef.nativeElement, {
        initial: this.currentSlide,
        slideChanged: (s) => {
          this.currentSlide = s.track.details.rel;
        },
      });
      this.dotHelper = [
        ...Array(this.slider.track.details.slides.length).keys(),
      ];
    });
  }

  ngOnInit() {
    this.product$ = of(this.product).pipe(
      map(res => {
        return res;
      }),
      shareReplay()
    );
    this.selectedProduct = this.product;
    this.productDetailService.setSelectedProduct(this.selectedProduct);
    this.product$.subscribe(event => this.longDescription = event.long_description);
    const imgs = this.product?.image_documents?.length ? [...this.product?.image_documents] : this.product?.image ? [{ 'images': this.product?.image }] : [{ 'images': "/assets/images/image-not-available.png" }];
    this.productImg.push(...imgs);
    this.productAttributes.push(...this.product?.product_attributes);
    this.getSelectedProduct(this.selectedProduct.code);
  }

  readMoreSpecification() {
    this.moreSpecification = !this.moreSpecification;
  }

  private getSelectedProduct(product_code: string) {
    this.productDetailService.getProduct(product_code, this.catalogCode).subscribe((productData) => {
      // this.getProductInfo(productData?.data);
      this.productDetailService.setSelectedProduct(productData?.data);
    }, (error) => {
      // this.showLoding = false;
    })
    // this.refineAttrOptions();
  }

  downloadPDF(url: string) {
    window.open(url, '_blank');
  }

}
