import { HttpParams } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GenericToasterService } from '../../shared/generic-toaster-utils';
import { CartService } from '../cart/cart.service';
import { CustomerListService } from './customer-list.service';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-customer-list',
  templateUrl: './customer-list.component.html',
  styleUrls: ['./customer-list.component.scss']
})
export class CustomerListComponent implements OnInit {
  public config: { itemsPerPage: number, currentPage: number, totalItems: number } = {
    itemsPerPage: 20,
    currentPage: 1,
    totalItems: 0
  };
  customerList: any[] = [];
  isLoading = false;
  hasCartItem = false;
  keyword = 'label';
  searchFocused = false;


  constructor(
    private router: Router,
    private customerListService: CustomerListService,
    private toasterService: GenericToasterService,
    private cartService: CartService,
    private storageService: StorageService
  ) { }

  ngOnInit() {
    this.getCart();
    this.getCustomerList();
  }

  getCustomerList(search = null) {
    this.isLoading = true;
    let queryString = `page=${this.config.currentPage}&page_size=${this.config.itemsPerPage}`;
    if (search) {
      queryString = `${queryString}&search=${search}`;
    }
    const params = new HttpParams({
      fromString: queryString
    });
    this.customerListService.getCustomerList(params).subscribe(response => {
      this.customerList = response?.data?.results?.map(item => {
        return {
          ...item,
          label: `${item?.company_name} (${item?.company_code})`
        };
      });
      this.isLoading = false;
      this.config.totalItems = response.data.count;
    }, error => {
      this.isLoading = false;
      this.customerList = [];
      this.config.totalItems = 0;
    });
  }

  selectCustomer(customer) {
    this.storageService.setContactUuid(customer?.uuid);
    this.storageService.setCustomer(customer);
    this.router.navigateByUrl(`/customer/${customer?.uuid}`);

  }

  onPageClick(event: any) {
    this.isLoading = true;
    this.config.currentPage = event;
    this.getCustomerList();
  }

  getCart() {
    this.cartService.currentCart$.subscribe((res) => {
      this.hasCartItem = res?.lines?.length ? true : false;
    });
  }

  onSelectedCompany(company) {
    this.getCustomerList(company.company_name || company);
  }

  searchCustomer(searchString: string) {
    if (searchString.length >= 3) {
      this.getCustomerList(searchString);
    }
  }



  onFocused() {
    this.searchFocused = true;
  }
  onFocusedOut() {
    this.searchFocused = false;
  }

  searchCleared() {
    this.getCustomerList();
  }
}
