import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class StorageService {
    private storageStatus = new Subject<string>();
    private tokenStatus = new Subject<string>();
    // private paginationInfo = new BehaviorSubject<any>({
    //     page_name: 'quotes',
    //     page_number: 1,
    // });
    private paginationInfo = new BehaviorSubject<any>(this.getInitialPagination());

    private searchKeyword = new BehaviorSubject<any>({
        page_name: 'quotes',
        keyword: null,
    });
    private selectedCartProduct = new BehaviorSubject<any[]>([]);
    private tradeInCart = new BehaviorSubject<any>(null);
    private tradeInBusinessPartner = new BehaviorSubject<any>(null);
    tradeInBusinessPartner$ = this.tradeInBusinessPartner.asObservable();

    private permissionWatch = new BehaviorSubject<boolean>(false);

    constructor() { }

    setTradeInBusinessPartner(businessPartner: any) {
      this.tradeInBusinessPartner.next(businessPartner);
      if (businessPartner) {
          localStorage.setItem('tradeInBusinessPartner', JSON.stringify(businessPartner));
      } else {
          localStorage.removeItem('tradeInBusinessPartner');
      }
    }

    getTradeInBusinessPartner() {
      const tradeInBusinessPartner = localStorage.getItem('tradeInBusinessPartner')
      if (!!tradeInBusinessPartner) {
          return JSON.parse(tradeInBusinessPartner);
      } else {
          return null;
      }
    }

  setPagination(pageInfo: any) {
    const paginationData = pageInfo || null ;

    // Save to BehaviorSubject
    this.paginationInfo.next(paginationData);

    // Save to local storage
    localStorage.setItem('paginationInfo', JSON.stringify(paginationData));
    this.paginationInfo.next(paginationData);
  }

    getPagination(): Observable<any> {
        return this.paginationInfo.asObservable();
    }

    private getInitialPagination(): any {
      const savedPagination = localStorage.getItem('paginationInfo');
      return savedPagination ? JSON.parse(savedPagination) : null
   }


    setSearchKeyword(searchData: any) {
        this.searchKeyword.next(searchData || {
            page_name: 'quotes',
            keyword: null,
        });
    }

    getSearchKeyword(): Observable<any> {
        return this.searchKeyword.asObservable();
    }

    getCurrentUser() {
        // tslint:disable-next-line:no-string-literal
        if (!!window.localStorage['currentUser']) {
            // tslint:disable-next-line:no-string-literal
            return JSON.parse(window.localStorage['currentUser']);
        } else {
            return false;
        }
    }

    setCurrentUser(user: any) {
        if (user) {
            // tslint:disable-next-line:no-string-literal
            window.localStorage['currentUser'] = JSON.stringify(user);
            this.storageStatus.next('changed');
        }
    }

    getSiteProfile() {
        const siteProfile = localStorage.getItem('siteProfile');
        if (siteProfile) {
            return JSON.parse(siteProfile);
        } else {
            return false;
        }
    }

    setSiteProfile(siteProfile: any) {
        if (siteProfile) {
            window.localStorage['siteProfile'] = JSON.stringify(siteProfile);
        }
    }

    getToken(): string {
        // tslint:disable-next-line:no-string-literal
        return window.localStorage['token'];
    }

    setToken(token: string) {
        // tslint:disable-next-line:no-string-literal
        const JWTtoken = `JWT ${token}`;
        window.localStorage['token'] = JWTtoken;
        this.tokenStatus.next('changed');
    }

    removeToken() {
        return localStorage.removeItem('token');
    }

    getRefreshToken(): string {
        // tslint:disable-next-line:no-string-literal
        return window.localStorage['refresh'];
    }

    setRefreshToken(token: string) {
        // tslint:disable-next-line:no-string-literal
        window.localStorage['refresh'] = `${token}`;
    }

    removeRefreshToken() {
        return localStorage.removeItem('refresh');
    }

    setTwoFactorAuthData(data: any) {
        if (!!data) {
            window.localStorage['twofactorauth'] = JSON.stringify(data);
        }
    }

    getTwoFactorAuthData(): any {
        if (!!window.localStorage['twofactorauth']) {
            return JSON.parse(window.localStorage['twofactorauth']);
        } else {
            return false;
        }
    }

    setQuoteUuid(quoteUuid: any) {
        window.localStorage['quoteUuid'] = JSON.stringify(quoteUuid);
    }

    getQuoteUuid() {
        if (!!window.localStorage['quoteUuid']) {
            return JSON.parse(window.localStorage['quoteUuid']);
        } else {
            return false;
        }
    }

    removeQuoteUuid() {
        return localStorage.removeItem('quoteUuid');
    }

    getCart() {
        if (!!window.localStorage['cart']) {
            return JSON.parse(window.localStorage['cart']);
        } else {
            return false;
        }
    }

    setCart(cart: any) {
        window.localStorage['cart'] = JSON.stringify(cart);
    }

    removeCart() {
        return localStorage.removeItem('cart');
    }

    getReComCart() {
        let tradeInCart = localStorage.getItem('tradeInCart')
        if (!!tradeInCart) {
            return JSON.parse(tradeInCart);
        } else {
            return false;
        }
    }

    setReComCart(cart: any) {
        let strCart = cart ? JSON.stringify(cart) : null;
        this.tradeInCart.next(strCart);
        if (strCart) {
            localStorage.setItem('tradeInCart', strCart);
        }
    }

    removeReComCart() {
        this.tradeInCart.next(null);
        return localStorage.removeItem('tradeInCart');
    }

    getContactPersonUUID() {
        return window.localStorage['contact_person_uuid'];
    }

    setContactPersonUUID(contact_person_uuid: string) {
        window.localStorage['contact_person_uuid'] = contact_person_uuid;
    }

    removeContactPersonUUID() {
        return localStorage.removeItem('contact_person_uuid');
    }

    getCustomer() {
        if (!!window.localStorage['customer']) {
            return JSON.parse(window.localStorage['customer']);
        } else {
            return false;
        }
    }

    removeCustomer() {
        return localStorage.removeItem('customer');
    }

    setCustomer(customer: string) {
        window.localStorage['customer'] = JSON.stringify(customer);
        this.storageStatus.next('changed');
    }

    setCatalog(catalog_code: string) {
        if (catalog_code) {
            window.localStorage['selectedCatalog'] = catalog_code;
        }
    }

    getCatalog() {
        if (!!window.localStorage['selectedCatalog']) {
            return window.localStorage['selectedCatalog'];
        } else {
            return null;
        }
    }

    removeCatalog() {
        return localStorage.removeItem('selectedCatalog');
    }

    watchStorage(): Observable<any> {
        return this.storageStatus.asObservable();
    }

    watchToken(): Observable<any> {
        return this.tokenStatus.asObservable();
    }

    watchPermission(): Observable<any> {
        return this.permissionWatch.asObservable();
    }

    setPermission(permissions) {
        if (permissions) {
            // tslint:disable-next-line:no-string-literal
            localStorage['permissions'] = JSON.stringify(permissions);
            this.permissionWatch.next(true);
        }
    }

    getPermission() {
        // tslint:disable-next-line:no-string-literal
        if (!!window.localStorage['permissions']) {
            // tslint:disable-next-line:no-string-literal
            return JSON.parse(window.localStorage['permissions']);
        } else {
            return null;
        }
    }

    removePermission() {
        return localStorage.removeItem('permissions');
    }

    setProductGroup(code: string) {
        if (code) {
            window.localStorage['groupCode'] = code;
        }
    }

    getProductGroup() {
        if (!!window.localStorage['groupCode']) {
            return window.localStorage['groupCode'];
        } else {
            return null;
        }
    }

    removeProductGroup() {
        return localStorage.removeItem('groupCode');
    }

    setShippingAddressID(shippingId: number) {
        window.localStorage['shippingAddressId'] = shippingId;
    }

    getShippingAddressID() {
        return window.localStorage['shippingAddressId'];
    }

    removeShippingAddressID() {
        return localStorage.removeItem('shippingAddressId');
    }

    setContactUuid(contactUuid: string) {
        if (contactUuid) {
            window.localStorage['contactUuid'] = contactUuid;
        }
    }

    getContactUuid() {
        return window.localStorage['contactUuid'];
    }

    removeContactUuid() {
        return localStorage.removeItem('contactUuid');
    }

    quoteSelected() {
        localStorage['quoteSelected'] = true;
    }

    isQuoteSelected() {
        return localStorage['quoteSelected'];
    }

    deleteSelectedQuote() {
        return localStorage.removeItem('quoteSelected');
    }

    getDynamicValue(keyName: string, isParse: boolean = true) {
        return window.localStorage[keyName] ? (isParse ? JSON.parse(window.localStorage[keyName]) : window.localStorage[keyName]) : null;
    }

    setDynamicValue(keyName: string, value) {
        if (value !== null) {
            window.localStorage[keyName] = value;
        } else {
            localStorage.removeItem(keyName);
        }
    }

    setSelectedCartProduct(data: any[]) {
        this.selectedCartProduct.next(data);
    }

    getSelectedCartProduct(): Observable<object | any> {
        return this.selectedCartProduct.asObservable();
    }

    getTradeInCart(): Observable<object | any> {
        return this.tradeInCart.asObservable();
    }

    removeDynamicValue(keyName: string) {
        if (keyName != null) {
            localStorage.removeItem(keyName);
        }
    }


    getFavoriteProducts() {
        let favoriteProducts = localStorage.getItem('favoriteProducts');
        if (favoriteProducts) {
            return JSON.parse(favoriteProducts);
        } else {
            return null;
        }
    }

    setFavoriteProducts(favoriteProducts: any) {
        if (favoriteProducts) {
            localStorage.setItem('favoriteProducts', JSON.stringify(favoriteProducts));
        }
    }

    removeFavoriteProduct() {
        return localStorage.removeItem('favoriteProducts');
    }

    removeAllStorage() {
        const languageCode = JSON.parse(localStorage.getItem('language'));
        localStorage.clear();
        if (languageCode) {
            localStorage.setItem('language', JSON.stringify(languageCode));

        }
    }

    setLanguage(languageCode: string) {
        if (languageCode) {
            localStorage.setItem('language', JSON.stringify(languageCode));
        }
    }
    getLanguage() {
        return JSON.parse(localStorage.getItem('language'));
    }
    setSelectedPaymentData(data: any) {
        if (data) {
            window.localStorage.setItem('selectedPaymentData', JSON.stringify(data));
        }
    }
    getSelectedPaymentData() {
        if (!!window.localStorage['selectedPaymentData']) {
            return JSON.parse(window.localStorage['selectedPaymentData']);
        } else {
            return null;
        }
    }
    removeSelectedPaymentData() {
        if (window.localStorage['selectedPaymentData']) {
            return localStorage.removeItem('selectedPaymentData');
        }
    }

    setHasSeenMessage(yes: boolean) {
        localStorage.setItem('hasSeenPopup', JSON.stringify(yes))
    }

    getHasSeenMessage() {
        return JSON.parse(localStorage.getItem('hasSeenPopup'))
    }


}
