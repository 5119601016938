export enum EMessages {
    INVALID_CART = "De winkelwagen is niet correct. Er zit een verkeerde combinatie van producten in de winkelwagen",
    NO_VAT_NUMBER_MSG = `Geachte klant,</br>
    In Nederland geldt een btw-verleggingsregeling ter zake van de verkoop van mobiele telefoons (zonder belcontract), geïntegreerde schakelingen, tablet pc’s en laptops. De btw-verlegging geldt bij leveringen tussen in Nederland gevestigde ondernemers vanaf 10.000 euro per soort goed, per levering (order). Voor KPN betekent dit dat onder bepaalde voorwaarden op de factuur geen btw mag worden wordt berekend en dat er vermeld dient te worden “Verlegde btw (NL)”. Voorwaarden hiervoor is onder andere dat KPN beschikt over uw Nederlandse btw-identificatienummer. Kortom, als u als klant meer dan 10.000 euro aan mobiele telefoons (zonder belcontract), geïntegreerde schakelingen, tablet pc’s en laptops besteld en KPN beschikt over uw Nederlands btw-identificatienummer, is deze regeling in principe van toepassing.</br>
    Met vriendelijke groet,</br>
    KPN Webshop`,
    NO_VAT_NUMBER = "cart_contact_does_not_have_vat_number"
}

export enum EUrl {
    SALES_FORCE = "quotations/redirect/salesforce"
}
