<section class="inner-content-wrapper inner-content-blue-bg pt-4">
  <div class="container">
    <ul class="tri-breadcrumb font-kpn-extended m-0 p-0 mb-4 pb-3">
      <li class="back-breadcrumb-btn border-0">
        <a (click)="onBack()" class="btn text-primary p-0 m-0">
          <i class="kphicon-uniE906"></i> Vorige stap
        </a>
      </li>
    </ul>

    <app-steps *ngIf="cart" [activeStep]="'order-overview'" [completeStepOne]="'true'" [completeStepTwo]="'true'">
    </app-steps>
    <div class="checkout-cart-box" *ngIf="isLoading; else cart_content">
      <div class="section-loader">
        <div class="kpn-spinner">
          <img src="/assets/images/kpn-loader.gif" alt="Loading" />
        </div>
      </div>
    </div>
    <ng-template #cart_content>
      <h3 class="font-kpn-black tri-title-40 green-text mb-3 mt-n3">Overzicht</h3>
      <div class="facts-box">
        <div class="bg-white tri-shadow-xl tri-rounded-20 mb-4 p-40">
          <div class="row">
            <div class="col">
              <h4 class="font-kpn-black green-text pb-1">Bedrijfsgegevens</h4>
              <div class="row">
                <div class="col-md">
                  <div class="pt-0">
                    <h5 class="mb-1 dark-grey-text fs-12 font-kpn-extneded font-weight-bold text-capitalize">
                      {{'ORDER_OVERVIEW.COMPANY_NAME' | transloco }}</h5>
                    <p class="body-text-1 mb-2">
                      {{ contactPerson?.company_name }}
                    </p>
                    <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">
                      KVK-{{'ORDER_OVERVIEW.NUMBER' | transloco }}</h5>
                    <p class="body-text-1 mb-2">
                      {{ contactPerson?.coc_number }}
                    </p>
                    <h5 *ngIf="contactPerson?.external_id1"
                      class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">KRN-nummer
                    </h5>
                    <p class="body-text-1 mb-2">
                      {{ contactPerson?.external_id1 }}
                    </p>
                    <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">Postcode
                    </h5>
                    <p class="body-text-1 mb-0">
                      {{ contactPerson?.billing_address?.zip_code ?? contactPerson?.contact_address?.zip_code }}
                    </p>
                  </div>
                </div>
                <div class="col-md">
                  <div class="pt-0">
                    <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">Straat</h5>
                    <p class="body-text-1 mb-2">
                      {{ contactPerson?.billing_address?.street ?? contactPerson?.contact_address?.street }}

                    </p>
                    <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">
                      Plaats
                    </h5>
                    <p class="body-text-1 mb-2 pb-1"> {{ contactPerson?.billing_address?.city ?? contactPerson?.contact_address?.city }}</p>
                    <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">
                      Land
                    </h5>
                    <p class="body-text-1 mb-2 pb-1">{{ contactPerson?.billing_address?.country ?? contactPerson?.contact_address?.country}}</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-4">
              <div class="pt-0" *ngIf="shippingAddress">
                <h4 class="font-kpn-black green-text pb-1">Bezorgadres</h4>
                <div *ngIf="shippingAddress?.company_name">
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">
                    {{'ORDER_OVERVIEW.COMPANY_NAME' | transloco }}</h5>
                  <p class="body-text-1 mb-2">
                    {{ shippingAddress?.company_name }}
                  </p>
                </div>
                <div
                  *ngIf="( (shippingAddress?.initials )|| (shippingAddress?.first_name) ||  (shippingAddress?.surname_prefix) || (shippingAddress?.last_name ) )">
                  <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">
                    {{'ORDER_OVERVIEW.CONTACT_PERSON' | transloco }}</h5>
                  <p class="body-text-1 mb-2">
                    {{ shippingAddress?.initials || shippingAddress?.first_name }}
                    {{ shippingAddress?.surname_prefix }}
                    {{ shippingAddress?.last_name }}
                  </p>
                </div>
                <h5 class="mb-1 dark-grey-text fs-12 font-kpn-bold font-weight-bold text-capitalize">Adres</h5>
                <p class="body-text-1 mb-2">
                  <span class="d-block"> {{ shippingAddress?.street }} {{ shippingAddress?.house_number }}  {{ shippingAddress?.house_number_extension }}, {{
                    shippingAddress?.zip_code }},</span>
                  <span class="d-block">{{ shippingAddress?.city }}. {{ shippingAddress?.country }}</span>
                  <span *ngIf="hasInputCustomAddressPermission" class="d-block">{{ cart?.shipping_extra_address_information_1 }}. <br> {{ cart?.shipping_extra_address_information_2 }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white tri-shadow-xl tri-rounded-20 mb-4 p-40">
          <div class="row">
            <div class="col">
              <h4 class="font-kpn-bold green-text">{{ siteProfile?.po_number_label || 'PO '+('CUSTOMER.NUMBER' | transloco) }}</h4>
              <div class="row">
                <div class="col-md-6">
                  <div class="kpn-form-group mb-3">
                    <label for="" class="field-label pb-1"> {{ siteProfile?.po_number_label || 'PO '+('CUSTOMER.NUMBER' | transloco) }}<span class="text-danger">{{
                        siteProfile?.is_po_number_required && (!isOffer && !isQuoteSelected) ? " * " : ""
                        }}</span> <span
                        class="font-kpn-extended font-weight-normal">({{'ORDER_OVERVIEW.MIN_3_AND_MAX_14_CHARACTERS' | transloco }})</span></label>
                    <div class="po-number-field">
                      <input [(ngModel)]="poNumber" type="text" class="form-control tri-form-control w-100 pr-4"
                        [ngClass]="{'is-valid ': ((cart?.po_number ? (cart?.po_number == poNumber): false))}"
                        (keyup.enter)="addPONumber()" [disabled]="shouldShowSpinner || referenceSpinner" (focusout)="addPONumber()" />
                     <span *ngIf="!(shouldShowSpinner || referenceSpinner)">
                      <i *ngIf="((cart?.po_number ? (cart?.po_number == poNumber): false))"
                      class="kphicon-uniE91D po-check"></i>
                     </span>
                      <span *ngIf="shouldShowSpinner || referenceSpinner" class="spinner-border spinner-border-sm mb-1 po-loader"
                        role="status" aria-hidden="true"></span>
                    </div>
                    <div>
                      <p class="text-muted" *ngIf="poNumber?.trim()?.length && (cart?.po_number !== poNumber)">
                        <small>
                          {{'ORDER_OVERVIEW.GIVE' | transloco }} {{ siteProfile?.po_number_label }} {{'ORDER_OVERVIEW.AND_THEN_PRESS_RETURN_OR_TAB_OR_CLICK_ON' | transloco }}
                        </small>
                      </p>
                    </div>
                  </div>
                  <div class="kpn-form-group" *ngIf="!restrictExportId?.includes(siteProfile?.export_id)">
                    <label for="" class="field-label pb-1"> {{ siteProfile?.reference_label || "Uw referentie" }} <span class="text-danger">{{
                        siteProfile?.is_reference_required && (!isOffer && !isQuoteSelected) ? " * " : ""
                        }}</span> <span class="font-kpn-extended font-weight-normal">({{'ORDER_OVERVIEW.MIN_3_AND_MAX_30_CHARACTERS' | transloco }})</span></label>
                    <div class="po-number-field">
                      <input [(ngModel)]="customer_reference" (keyup.enter)="addReference()" (focusout)="addReference()"
                        [disabled]="referenceSpinner || shouldShowSpinner" type="text"
                        class="form-control tri-form-control is-valid w-100 pr-4"
                        [ngClass]="{'is-valid ': ((cart?.customer_reference ? (cart?.customer_reference == customer_reference): false))}" />
                      <span  *ngIf="!(shouldShowSpinner || referenceSpinner)">
                        <i *ngIf="((cart?.customer_reference ? (cart?.customer_reference == customer_reference): false))"
                          class="kphicon-uniE91D po-check"></i>
                      </span>
                      <span *ngIf="shouldShowSpinner || referenceSpinner" class="spinner-border spinner-border-sm mb-1 po-loader"
                      role="status" aria-hidden="true"></span>
                    </div>
                  </div>
                  <div>
                    <p class="text-muted" *ngIf="customer_reference?.trim()?.length && (cart?.customer_reference !== customer_reference)">
                      <small>
                        {{'ORDER_OVERVIEW.GIVE' | transloco }} {{ siteProfile?.reference_label }}  {{'ORDER_OVERVIEW.AND_THEN_PRESS_RETURN_OR_TAB_OR_CLICK_ON' | transloco }}
                      </small>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div  *ngIf="( cart?.is_contact_account_type_required && (hasViewContactAccountTypeCustomer ? (contactAccountTypesCustomers?.length) : true))" class="bg-white tri-shadow-xl tri-rounded-20 mb-4 p-40 loader-wrapper-static loader-wrapper-content-heigth">
          <div class="kpn-spinner tri-rounded-20" *ngIf="contactAccountTypeCustomersLoading">
            <div class="text-center">
              <img src="/assets/images/kpn-loader.gif" alt="isLoading">
            </div>
          </div>
          <div class="row" >
            <div class="col">
              <h4 class="font-kpn-black green-text">Enrollment</h4>
              <p class="font-kpn-extended mb-3">
                Enrollment koppelt je apparaten aan je onderneming, waardoor registratie en beheer eenvoudiger worden
              </p>
              <div class="row gutter-row-15" *ngIf="contactAccountTypesCustomers?.length">
                <div class="col-md-6 gutter-col-15">
                  <label for="" class="font-kpn-bold mb-2" *ngIf="hasViewContactAccountTypeCustomer">Selecteer een klant </label>
                  <div  *ngIf="contactAccountTypesCustomers?.length">
                    <ng-select [placeholder]="'Selecteer een klant'" class="tri-form-control tri-form-control-select-op" [items]="contactAccountTypesCustomers"
                      bindLabel="name" bindValue="uuid" [(ngModel)]="selectedContactAccountTypesCustomer"
                      (ngModelChange)="onContactAccountTypesCustomerChange($event)">
                    </ng-select>
                  </div>
                </div>
              </div>
              <div class="row gutter-row-15 pt-3">
                <div class="col-md-6 gutter-col-15 mb-2" *ngFor="let item of accountTypes">
                  <div class="add-action-prd p-3 " [ngClass]="item?.checked ? 'item-added' :''">
                    <div class="d-flex align-content-center justify-content-between mb-1">
                      <h6 class="font-kpn-bold green-text font-weight-bolder mb-1">{{ item?.account_name }}</h6>
                    </div>
                    <div class="d-flex align-items-end justify-content-between">
                      <div class="mr-2">
                        <p class="font-kpn-extended grey-text mb-1">ID-nummer: {{ item?.account_id }}</p>
                        <p class="font-kpn-extended green-text mb-0">{{ item?.product_count }} apparaten toegevoegd</p>
                      </div>
                      <button (click)="addOrRemoveAccountTypeToCart(item,'Remove')"  *ngIf="item?.checked" class="kphicon-uniE972 add-action-addmore-btn"></button>
                      <button (click)="addOrRemoveAccountTypeToCart(item,'Add')" *ngIf="!item?.checked" class="kphicon-uniE985 add-action-addmore-btn"></button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="mr-2" *ngIf="(!accountTypes?.length) && hasSelectedContactAccountTypesCustomer && (!contactAccountTypeCustomersLoading)">
                <p class="font-kpn-extended text-danger">De client heeft geen ABM/KNOX-account voor inrichting</p>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 mb-4 p-40" *ngIf="special_discount_requested">
          <div class="row">
            <div class="col">
              <h4 class="font-kpn-black green-text"> Special Discount Reason <span class="text-dark">*</span></h4>
              <div class="pt-2">
                <textarea [(ngModel)]="special_discount_reason" cols="20" class="form-control tri-form-control-text-area"></textarea>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="bg-white tri-shadow-xl tri-rounded-20 mb-4 p-40" *ngIf="cart?.is_contact_account_type_required">
          <div class="row">
            <div class="col-md-6">
                <h4 class="font-kpn-black green-text">Selecteer ABM/KNOX account</h4>
                <div class="d-flex align-items-center justify-content-start pt-2">
                  <select name="" class="form-control tri-form-control tri-form-control-select-op" id="account_type_id" [(ngModel)]="cart.contact_account_type_id"
                    (ngModelChange)="updateAccountId()">
                    <option [ngValue]="null">Selecteer account type</option>
                    <option *ngFor="let item of accountTypes" [ngValue]="item.id">
                      {{ item?.account_name }}
                    </option>
                  </select>
                </div>
            </div>
          </div>
        </div> -->
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4">
          <h3 class="tri-title-40 font-kpn-black green-text mb-4">Het totaalplaatje </h3>
          <div class="add-cart-prd mb-2" *ngFor="let line of cart?.lines; let i = index">
            <div class="add-cart-prd-item mb-3">
              <div class="row">
                <div class="col-md-8">
                  <div class="mb-4">
                    <!-- <span class="font-kpn-extended">Brand name</span> -->
                    <h4 class="font-kpn-bold fs-26 pt-1 mb-0">{{line?.product_name}}</h4>
                    <span class="font-kpn-extended text-black-50" *ngIf="line?.memory_size">Geheugen: {{
                      line?.memory_size }} </span>
                    <div class="font-kpn-extended text-black-50" *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">Aantal: {{ line?.quantity }}</div>
                    <div class="font-kpn-extended text-black-50" *ngIf="(hasViewContactAccountTypeCustomer ? hasSelectedContactAccountTypesCustomer : true ) && (line?.extra?.contact_account_type?.account_name) && ((!isOffer && !isQuoteSelected))">Inclusief: {{ line?.extra?.contact_account_type?.account_name }}</div>
                  </div>
                </div>
                <div class="col-md-4 text-right"  *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)">
                  <div class="add-cart-pr-img">
                    <img *ngIf="line?.image" src="{{line?.image}}" alt="">
                    <img *ngIf="!line?.image && !line.brand?.includes('Cisco')" src="/assets/images/noimage.png" alt="">
                    <img *ngIf="!line?.image && (line.brand?.includes('cisco') || line.brand?.includes('Cisco'))"
                      src="/assets/images/cisco-networking.png" alt="">
                  </div>
                </div>
              </div>
              <hr class="mt-0 border-color-tri mt-2">
              <div class="d-flex justify-content-between align-items-center mb-n1 mt-n1">
                <span class="font-kpn-extended">Totaal</span>
                <h4 class="h4 font-kpn-black green-text mb-0">
                  <span>{{ (utilityService.isInclVAT() ? line?.total_amount : line?.total_amount_ex_vat )| currencyFormat}}
                  <i *ngIf="!line?.isSubLineOpen" (click)="line.isSubLineOpen=!line.isSubLineOpen" class="kpn-ui-icon ui-chevron-down" role="button"></i>
                  <i *ngIf="line?.isSubLineOpen" (click)="line.isSubLineOpen=!line.isSubLineOpen" class="kpn-ui-icon ui-chevron-up" role="button"></i>
                  </span>
                </h4>
              </div>
              <div class="pt-2 pr-4 mr-1" *ngIf="line?.isSubLineOpen">
                <p class="d-flex align-items-center justify-content-between mb-1">
                  <span class="font-kpn-extended">{{line?.product_name}}</span>
                  <span class="font-kpn-extended">{{(utilityService.isInclVAT() ? line?.amount :  line?.amount_ex_vat )| currencyFormat : ''}}</span>

                </p>
                <p class="d-flex align-items-center justify-content-between mb-1" *ngFor="let subLine of line?.sub_lines">
                  <span class="font-kpn-extended">{{subLine?.product_name}}</span>
                  <span class="font-kpn-extended">{{(utilityService.isInclVAT() ? subLine?.amount : subLine?.amount_ex_vat ) | currencyFormat  : ''}}</span>
                </p>
              </div>
              <div class="row new-cart-tbody mt-2" *ngIf="line?.deliveryOptions.length">
                <div class="col-md-6 col-12">
                  <div class="td-product">
                    <div class="">
                      <div class="" *ngIf="
                          !cartService.hideDeliveryOptionProductTypes.includes(
                            line?.product_type
                          )
                        ">
                        <div class="product-subline-details">
                          <div class="d-flex align-items-center justify-content-start mb-2"
                            *ngIf="line?.deliveryOptions?.length > 1">
                            <select name="" class="form-control tri-form-control tri-form-control-select-op" id="" [(ngModel)]="line.selectedDeliveryOption"
                              (ngModelChange)="addLineDelivery(line)">
                              <option [ngValue]="null"> {{'ORDER_OVERVIEW.SELECT_DELIVERY_METHOD' | transloco }}
                              </option>
                              <option *ngFor="let item of line?.deliveryOptions" [ngValue]="item">
                                {{ item?.name }}
                              </option>
                            </select>
                            <!-- <button class="btn" disabled>
                              <i class="kphicon-uniE926"></i>
                            </button> -->
                          </div>
                          <span *ngIf="line?.deliveryOptions?.length === 1">
                            <button class="flat-grey-light" placement="bottom" *ngIf="line?.delivery_option">
                              <h6 class="title-6 mb-0 mr-2">{{'ORDER_OVERVIEW.DELIVERY_METHOD' | transloco }}:</h6>
                              <span class="dark-orange-tag-pill mr-5">
                                <b> {{ line?.delivery_option?.name }}</b></span>
                            </button>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="px-3 pt-4">
            <p class="d-flex align-items-center justify-content-between mb-1" *ngFor="let additionalCostLine of additionalCostLies">
              <span class="font-kpn-extended">{{ additionalCostLine?.brand }} {{additionalCostLine?.product_name}} </span>
              <strong class="font-kpn-bold green-text">{{(utilityService.isInclVAT() ? additionalCostLine?.total_amount  :  additionalCostLine?.total_amount_ex_vat) | currencyFormat : ''}}</strong>
            </p>
            <p class="d-flex align-items-center justify-content-between mb-1">
              <span class="font-kpn-extended">Subtotaal</span>
              <strong class="font-kpn-bold green-text">{{ (utilityService.isInclVAT() ? cart?.total_amount :  cart?.total_amount_ex_vat) |
                currencyFormat : ''}}</strong>
            </p>
            <p class="d-flex align-items-center justify-content-between mb-1">
              <span class="font-kpn-extended">BTW </span>
              <strong class="font-kpn-bold green-text">{{cart?.total_amount_vat |
                currencyFormat : ''}}</strong>
            </p>

            <hr class="mb-2">
            <div>
              <p class="d-flex align-items-center justify-content-between mb-0">
                <span class="font-kpn-black fs-24">Totale prijs</span>
                <strong class="font-kpn-black fs-24 green-text">{{cart?.total_amount | currencyFormat : ''}}</strong>
              </p>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4" *ngIf="hasPermissionSetRequestedDeliveryDate && !isOffer && !isQuoteSelected">
          <h3 class="tri-title-40 font-kpn-black green-text mb-3 pb-2">Gevraagde afleverdatum</h3>
          <input [min]="requestedDateMin" (ngModelChange)="onChangeRequestedDateDate($event)"
                [(ngModel)]="requested_delivery_date" #dateFrom="ngModel"
                [owlDateTime]="requestedDeliveryDate" [owlDateTimeTrigger]="requestedDeliveryDate"
                class="form-control tri-form-control w-100 pr-4"
                placeholder="Gevraagde afleverdatum"
                (keydown)="preventKeyEvent($event)"
                />
              <owl-date-time [pickerType]="'calendar'" #requestedDeliveryDate></owl-date-time>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4" *ngIf="paymentOptions.length">
          <h3 class="tri-title-40 font-kpn-black green-text mb-3 pb-2">Hoe wil je betalen?</h3>
          <div class="payment-option mb-2" *ngFor="let payment of paymentOptions;let i = index">
            <div class="d-flex align-items-center payment-option-radio">
              <input type="radio" name="payment-option" [id]="i+'payment-type'" [value]="payment.code" [(ngModel)]="selectedPaymentOption"
                (change)="changePaymentType(payment)">
              <label [for]="i+'payment-type'" class="d-flex align-items-center font-kpn-extended fs-20">
                <span class="radio-bullet"></span>
                {{ payment?.name }} </label>
              <span class="payment-logo" *ngIf="payment?.code === 'ideal_talos'">
                <img src="../../../assets/images/ideal-payment-icon.png" alt="iDeal">
              </span>
            </div>
            <div class="kpn-form-group" *ngIf="payment?.code === 'ideal_talos'">
              <div class="row">
                <div class="col-md-6">
                  <div class="select-option-default btn-block pt-3" *ngIf="selectedPaymentOption === 'ideal_talos'">
                    <select [(ngModel)]="selectedIdealIssuer" name="issuer" (ngModelChange)="onIdealIssuerChange($event)"
                      class="form-control tri-form-control tri-form-control-select-op">
                      <option value="Kies je bank" disabled>Kies je bank</option>
                      <option *ngFor="let item of issuerList" [ngValue]="item.value">{{item?.name}}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div class="pt-3" *ngIf="((payment?.current_amount) || (payment?.future_amount)) && (selectedPaymentOption === payment?.code)">
              <div class="blue-warning-qoute d-flex">
                <i class="kphicon-uniE952 note-icon mr-3"></i>
                <p class="font-kpn-extended m-0 line-height-24">
                  <span class="d-block font-kpn-bold fs-20">Betaal uit depot</span>
                  <span class="d-block font-kpn-extended">Beschikbare bedrag in depot: {{ payment?.current_amount |
                    currencyFormat }}</span>
                  <span class="d-block font-kpn-extended">Over na betaling: {{ payment?.future_amount | currencyFormat
                    }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4"
        *ngIf="hasCpaas">
          <div class="">
            <div class="mb-3">
              <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
                <p>
                  <span class="kphicon-uniE952"></span>
                  {{'ORDER_OVERVIEW.ACTIVE_C_PASS_ALERT' | transloco}}.
                </p>

              </div>
            </div>
            <form [formGroup]="cPaasForm">
              <div class="row">
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.ext-num">Email <span *ngIf="(!(isOffer || isQuoteSelected))" class="danger-text">*</span></label>
                    <input type="text" class="form-control tri-form-control kpn-form-control" formControlName="cpaas_user_email"
                      placeholder="" [ngClass]="{
                                  'is-invalid':cPaasForm.controls.cpaas_user_email?.errors && (!(isOffer || isQuoteSelected))
                                }" />
                    <div *ngIf="cPaasForm.controls.cpaas_user_email.hasError('trimError')" class="invalid-feedback">
                      {{ cPaasForm.controls.cpaas_user_email.errors?.trimError?.value }}
                    </div>
                    <div *ngIf="cPaasForm.controls.cpaas_user_email?.errors &&( cPaasForm.get('cpaas_user_email')?.dirty|| cPaasForm.get('cpaas_user_email')?.touched)" class="text-danger">
                      <span
                        *ngIf="cPaasForm.controls.cpaas_user_email?.errors?.required">{{'ORDER_OVERVIEW.EMAIL_IS_REQUIRED'
                        | transloco}}
                      </span>
                      <span *ngIf="cPaasForm.controls.cpaas_user_email?.errors?.email">
                        {{'ORDER_OVERVIEW.THE_EMAIL_MUST_BE_A_VALID_EMAIL_ADDRESS' | transloco}}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.int-num">{{'CUSTOMER.FIRST_NAME' | transloco }} <span
                        *ngIf="(!(isOffer || isQuoteSelected))" class="danger-text">*</span></label>
                    <input type="text" class="form-control tri-form-control kpn-form-control" formControlName="cpaas_user_first_name"
                      placeholder="" [ngClass]="{ 'is-invalid': cPaasForm.controls.cpaas_user_first_name?.errors && (!(isOffer || isQuoteSelected)) }" />
                    <div *ngIf="cPaasForm.controls.cpaas_user_first_name?.errors && (cPaasForm.get('cpaas_user_first_name')?.dirty|| cPaasForm.get('cpaas_user_first_name')?.touched)" class="text-danger">
                      {{ cPaasForm.controls.cpaas_user_first_name.errors?.required?.value || 'Voornaam is verplicht' }}
                    </div>
                  </div>
                </div>
                <div class="col-md-3 col-6 col-gap-reduce">
                  <div class="form-group kpn-form-group">
                    <label i18n="@@arm.order-status.int-num">{{'SIGN_UP.LAST_NAME' | transloco}} <span
                        *ngIf="(!(isOffer || isQuoteSelected))" class="danger-text">*</span></label>
                    <input type="text" class="form-control tri-form-control kpn-form-control" formControlName="cpaas_user_last_name"
                      placeholder="" [ngClass]="{ 'is-invalid': cPaasForm.controls.cpaas_user_last_name?.errors && (!(isOffer || isQuoteSelected)) }" />
                    <div *ngIf="cPaasForm.controls.cpaas_user_last_name?.errors && (cPaasForm.get('cpaas_user_last_name')?.dirty|| cPaasForm.get('cpaas_user_last_name')?.touched)" class="text-danger">
                      {{ cPaasForm.controls.cpaas_user_last_name?.errors?.required?.value || 'Achternaam is vereist' }}
                    </div>
                  </div>
                </div>
                <div class="col-md-2 col-gap-reduce pt-3">
                  <button type="button" class="btn btn-sm btn-block btn-primary mt-2 rounded-btn"
                    (click)="addCpassData()" [disabled]="!cPaasForm.valid || cPassAddLoader">
                    <span *ngIf="!cPassAddLoader; else showSpinner">{{'RMA_CREATE.ADD' | transloco }}</span>
                    <ng-template #showSpinner>
                      <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                    </ng-template>
                  </button>
                </div>
              </div>

              <div class="row align-items-center" *ngIf="cPassAddError">
                <div class="col-md-10 col-10 col-gap-reduce">
                  <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                    <p>
                      <span class="kphicon-uniE952"></span>
                      {{ cPassAddError }}
                    </p>
                    <button class="kphicon-uniE926"></button>
                  </div>
                </div>
              </div>
            </form>
            <hr>
          </div>
        </div>
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4 loader-wrapper-static loader-wrapper-content-heigth"
          *ngIf="termAndConditions?.length && !isQuoteSelected">
          <div class="kpn-spinner tri-rounded-20" *ngIf="termsAndConditionsLoading">
            <div class="text-center">
              <img src="/assets/images/kpn-loader.gif" alt="isLoading">
            </div>
          </div>
          <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items" [style]="termsAndConditionsLoading ? 'pointer-events: none' : ''">
            <label ngbButtonLabel class="checkbox-item d-flex pl-0 mb-2" *ngFor="let item of termAndConditions;let i = index">
              <div class="checkbox-btn checkbox-btn-outline checkbox-btn-md">
                <input [disabled]="termsAndConditionsLoading" ngbButton type="checkbox" [value]="item.code" [(ngModel)]="item.checked"
                  (ngModelChange)="onChangeTermAndCondition(item)" />
                <span class="checkBoxCheckmark"></span>
              </div>
              <div class="checkbox-label ml-1 font-kpn-extended line-height-20 text-left">
                  <p [innerHTML]="item?.description || item?.name" class="mb-0"></p>
              </div>
            </label>
          </div>
        </div>
        <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4"
          *ngIf="orderError || quoteCreateMessage || (!cart?.is_valid)">
          <div class="row align-items-center" *ngIf="orderError">
            <div class="col-md-12 col-12 col-gap-reduce">
              <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                <p>
                  <span class="kphicon-uniE952"></span>
                  {{ orderError }}
                </p>
                <button class="kphicon-uniE926"></button>
              </div>
            </div>
          </div>
          <div class="row align-items-center" *ngIf="quoteCreateMessage">
            <div class="col-md-12 col-12 col-gap-reduce">
              <div class="mb-3 d-flex align-items-center justify-content-between"
                [ngClass]="hasQuoteCreationPermission ? 'info-bar-blue' : 'info-bar-red'">
                <p>
                  <span class="kphicon-uniE952"></span>
                  <b>{{ quoteCreateMessage }} </b>
                </p>
              </div>
            </div>
          </div>
          <div class="info-bar-red  px-3 pt-3" *ngIf="!cart?.is_valid">
            <div class="mb-3 d-flex align-items-center justify-content-between">
              <p [innerHTML]="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER ? eMessages.NO_VAT_NUMBER_MSG:
                eMessages.INVALID_CART">
              </p>
            </div>
            <app-vat-number-input *ngIf="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER"
              (reFetchCartCart)="reFetchCart($event)"></app-vat-number-input>
          </div>
        </div>
        <div class="row align-items-center justify-content-between pt-0 mb-4">
          <div class="col-auto d-flex">
            <button (click)="onBack()" class="btn btn-link d-flex align-items-center px-0">
              <i class="kphicon-uniE906 fs-22"></i>Vorige</button>
          </div>
          <div class="col-auto">

            <button (click)="createOrder()" *ngIf="hasQuoteCreationPermission && (isOffer || isQuoteSelected)"
              [disabled]="!cart?.is_valid || shouldShowSpinner || poNumber?.trim()?.length > 14 ||
              customer_reference?.trim()?.length > 256 || (special_discount_requested ? special_discount_reason.trim()?.length === 0 : false)"
              class="btn btn-primary rounded-btn px-4 mb-2 tri-btn-disabled">
              Plaats Offerte
            </button>


            <button (click)="createOrder()" *ngIf="!isOffer && !isQuoteSelected"
              class="btn btn-primary rounded-btn px-4 mb-2 tri-btn-disabled"
              [disabled]="!cart?.lines?.length || !cart?.is_valid || shouldShowSpinner || (poNumber?.trim()?.length > 14) ||(customer_reference?.trim()?.length > 256) ||
              (!poNumber && siteProfile?.is_po_number_required) ||
               (!customer_reference && siteProfile?.is_reference_required) ||
               !termAccepted || (hasCpaas && (!cPaasForm.valid || !cPassAdded)) || (this.selectedPaymentOption === 'ideal_talos' && (selectedIdealIssuer && selectedIdealIssuer ==='Kies je bank')  )">
              <span *ngIf="shouldShowSpinner" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
              Bestelling afronden
            </button>
          </div>
        </div>
      </div>
    </ng-template>


    <!-- old -->

    <!-- <div class="new-cart-box-overview pt-2 mb-5" *ngIf="!isLoading">
      <div class="row align-items-center" *ngIf="hasCreditShowPermission && cart?.credit_limit_details">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span>
                {{'ORDER_OVERVIEW.AVAILABLE_CREDIT' | transloco }}:
                {{
                cart?.credit_limit_details?.available_credit | currencyFormat
                }}</span><br />
              <span>
                {{'ORDER_OVERVIEW.CREDIT_LIMIT' | transloco }}:
                {{
                cart?.credit_limit_details?.credit_limit | currencyFormat
                }}</span>
              <br />
              <span class="kphicon-uniE952"></span>
              <b> {{ cart?.credit_limit_details?.message }}</b>
            </p>
            <button class="kphicon-uniE926"></button>
          </div>
        </div>
      </div>
      <div class="row pt-4">
        <div class="col">
          <h3 class="title-2 mb-1 green-text">{{'ORDER_OVERVIEW.CONFIRM_ORDER' | transloco }}</h3>
          <h5 class="title-4 pt-3">{{'ORDER_OVERVIEW.THESE_ARE_YOUR_DETAILS' | transloco }}</h5>
          <div class="row">
            <div class="col-md-4">
              <div class="pt-3">
                <h5 class="title-5 mb-1 dark-grey-text">{{'ORDER_OVERVIEW.COMPANY_NAME' | transloco }}</h5>
                <p class="body-text-1 mb-3">
                  {{ contactPerson?.company_name }}
                </p>

                <h5 *ngIf="contactPerson?.coc_number" class="title-5 mb-1 dark-grey-text">
                  KVK {{'ORDER_OVERVIEW.NUMBER' | transloco }}
                </h5>
                <p class="body-text-1 mb-3">{{ contactPerson?.coc_number }}</p>
              </div>

              <div class="pt-0" *ngIf="contactPerson?.billing_address">
                <h5 class="title-5 mb-1 dark-grey-text">{{'ORDER_OVERVIEW.BILLING_ADDRESS' | transloco }}</h5>
                <p class="body-text-1 mb-0">
                  {{
                  contactPerson?.billing_address?.initials ||
                  contactPerson?.billing_address?.first_name
                  }}
                  {{ contactPerson?.billing_address?.surname_prefix }}
                  {{ contactPerson?.billing_address?.last_name }}
                </p>
                <p class="body-text-1 mb-0">
                  {{ contactPerson?.billing_address?.street }}
                  {{ contactPerson?.billing_address?.house_number }}
                  {{ contactPerson?.billing_address?.house_number_extension }}
                </p>
                <p class="body-text-1 mb-0">
                  {{ contactPerson?.billing_address?.zip_code }}
                  {{ contactPerson?.billing_address?.city }}
                </p>
              </div>

              <div class="pt-0" *ngIf="contactAddress">
                <h5 class="title-5 mb-1 dark-grey-text">{{'ORDER_OVERVIEW.CONTACT_PERSON' | transloco }}</h5>
                <p class="body-text-1 m-0">
                  <span class="title-6 dark-grey-text">{{'ORDER_OVERVIEW.NAME' | transloco }}: </span>
                  {{ contactAddress?.initials }}
                  {{ contactAddress?.last_name }}
                </p>

                <p class="body-text-1 m-0" *ngIf="contactAddress?.mobile_phone_number">
                  <span class="title-6 dark-grey-text">{{'ORDER_OVERVIEW.TELEPHONE' | transloco }}: </span>
                  {{ contactAddress?.mobile_phone_number }}
                </p>

                <p class="body-text-1 mb-1">
                  <span class="title-6 dark-grey-text">E-mail: </span>
                  {{ contactAddress?.email }}
                </p>
              </div>
            </div>
            <div class="col-md-4" *ngIf="shippingAddress">
              <div class="pt-0">
                <h5 class="title-5 mb-1 dark-grey-text">{{'ORDER_OVERVIEW.SHIPPING_ADDRESS' | transloco }}</h5>
                <p class="body-text-1 mb-0" *ngIf="shippingAddress?.company_name">
                  {{ shippingAddress?.company_name }}
                </p>
                <p class="body-text-1 mb-0">
                  {{ shippingAddress?.initials || shippingAddress?.first_name }}
                  {{ shippingAddress?.surname_prefix }}
                  {{ shippingAddress?.last_name }}
                </p>
                <p class="body-text-1 mb-0">
                  {{ shippingAddress?.street }}
                  {{ shippingAddress?.house_number }}
                  {{ shippingAddress?.house_number_extension }}
                </p>
                <p class="body-text-1 mb-3">
                  {{ shippingAddress?.zip_code }} {{ shippingAddress?.city }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="new-cart-box-overview pt-4 mb-5" *ngIf="!isLoading">
      <div class="row align-items-end justify-content-between">
        <div class="col-md-6">
          <div class="row">
            <div class="col-md-12 mb-2">
              <label class="form-label-bold dark-grey-text mb-0">
                {{ siteProfile?.po_number_label || 'PO '+('CUSTOMER.NUMBER' | transloco) }}
                <span class="danger-text">{{
                  siteProfile?.is_po_number_required && (!isOffer && !isQuoteSelected) ? "*" : ""
                  }}</span>
                <span class="text-muted">
                  <small>({{'ORDER_OVERVIEW.MIN_3_AND_MAX_14_CHARACTERS' | transloco }})</small>
                </span>
              </label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <div class="po-number d-flex align-items-center justify-content-start mb-0">
                    <input type="text" [(ngModel)]="poNumber" class="form-control kpn-form-control reference-field"
                      placeholder="{{
                        siteProfile?.po_number_label || 'PO '+('CUSTOMER.NUMBER' | transloco)
                      }}" (keyup.enter)="addPONumber()" [disabled]="shouldShowSpinner" (focusout)="addPONumber()"
                      [ngClass]="{
                        'is-invalid': poNumber?.trim()?.length > 14
                      }" />
                    <button class="btn py-1" (click)="addPONumber()" [disabled]="
                        !poNumber?.trim()?.length ||
                        poNumber?.trim()?.length > 14
                      ">
                      <span *ngIf="shouldShowSpinner; else plusIcon" class="spinner-border spinner-border-sm mb-1"
                        role="status" aria-hidden="true"></span>
                      <ng-template #plusIcon>
                        <i class="kphicon-uniE985"></i>
                      </ng-template>
                    </button>
                    <span *ngIf="cart?.po_number" class="title-2 green-text"><i class="kphicon-uniE91D"></i></span>
                  </div>
                </div>
                <p class="text-muted">
                  <small class="green-text">{{poNumber?.trim()?.length}}</small>
                  <small>
                    {{'ORDER_OVERVIEW.GIVE' | transloco }} {{ siteProfile?.po_number_label }}
                    {{'ORDER_OVERVIEW.AND_THEN_PRESS_RETURN_OR_TAB_OR_CLICK_ON' | transloco }} (+)
                  </small>
                </p>
              </div>
            </div>
            <div class="col-md-12" *ngIf="!restrictExportId?.includes(siteProfile?.export_id)">
              <label class="form-label-bold dark-grey-text mb-0">
                {{ siteProfile?.reference_label || "CustomerReference" }}
                <span class="danger-text">{{
                  siteProfile?.is_reference_required && (!isOffer && !isQuoteSelected) ? "*" : ""
                  }}</span>
                <span class="text-muted">
                  <small>({{'ORDER_OVERVIEW.MIN_3_AND_MAX_14_CHARACTERS' | transloco }})</small>
                </span>
              </label>
              <div class="kpn-form-group">
                <div class="field-with-spinner">
                  <div class="po-number d-flex align-items-center justify-content-start mb-0">
                    <input type="text" [(ngModel)]="customer_reference"
                      class="form-control kpn-form-control reference-field" placeholder="{{
                        siteProfile?.reference_label || 'Klant Referentie'
                      }}" (keyup.enter)="addReference()" (focusout)="addReference()" [disabled]="referenceSpinner"
                      [ngClass]="{
                        'is-invalid': customer_reference?.trim()?.length > 256
                      }" />
                    <button class="btn py-1" (click)="addReference()" [disabled]="
                        !customer_reference?.trim()?.length ||
                        customer_reference?.trim()?.length > 256
                      ">
                      <span *ngIf="referenceSpinner; else plusIcon" class="spinner-border spinner-border-sm mb-1"
                        role="status" aria-hidden="true"></span>
                      <ng-template #plusIcon>
                        <i class="kphicon-uniE985"></i>
                      </ng-template>
                    </button>
                    <span *ngIf="cart?.customer_reference" class="title-2 green-text"><i
                        class="kphicon-uniE91D"></i></span>
                  </div>
                </div>
                <p class="text-muted">
                  <small class="green-text">
                    {{customer_reference?.trim()?.length}}
                  </small>
                  <small>
                    {{'ORDER_OVERVIEW.GIVE' | transloco }} {{ siteProfile?.reference_label }}
                    {{'ORDER_OVERVIEW.THEN_PRESS_RETURN_OR_TAB_OR_CLICK_ON' | transloco }} (+)
                  </small>
                </p>
              </div>
            </div>
            <div class="col-md-12" *ngIf="special_discount_requested">
              <label class="form-label-bold dark-grey-text mb-0">
                Special Discount Reason  <span class="danger-text">*</span>
              </label>
              <textarea  [(ngModel)]="special_discount_reason" cols="20" class="form-control"
             ></textarea>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class=" checkout-cart-box" *ngIf="isLoading; else cart_content_new">
      <div class="section-loader">
        <div class="kpn-spinner">
          <img src="/assets/images/kpn-loader.gif" alt="Loading" />
        </div>
      </div>
    </div>
    <ng-template #cart_content_new>
      <div class="row align-items-center" *ngIf="!cart?.lines?.length">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
            <p>{{'ORDER_OVERVIEW.NO_ITEM_IN_THE_SHOPPING_CART' | transloco}}</p>
            <button class="kphicon-uniE926"></button>
          </div>
        </div>
      </div>
      <div class="new-cart-box-cartline pt-2 mb-2" *ngIf="cart?.lines?.length">
        <div class="item-row-head">
          <div class="row new-cart-thead align-items-center">
            <div class="col">
              <h5 class="title-3 pt-3">{{'ORDER_OVERVIEW.THIS_WILL_BE_DELIVERED_TO_YOU' | transloco }}</h5>
            </div>
          </div>
        </div>
        <div class="item-rows">
          <div class="row new-cart-tbody">
            <div class="col">
              <b>Product</b>
            </div>
            <div class="col-3">
              <b>{{'ORDER_OVERVIEW.PAYMENT_METHOD' | transloco }}</b>
            </div>
            <div class="col-4">
              <div class="row align-items-center">
                <div class="col">
                  <div class="quantity d-flex align-items-center mb-2">
                    <div class="item-add-remove">
                      <div class="d-flex align-items-center">
                        <b>{{'ORDER_OVERVIEW.QUANTITY' | transloco }}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="gross-price pr-0">
                    <h5 class="price-item-new current-price pr-2">{{'ORDER_OVERVIEW.PRICE' | transloco }}</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="item-rows" *ngFor="let line of cart?.lines; let i = index">
          <div class="row new-cart-tbody">
            <div class="col">
              <div class="td-product">
                <div class="row">
                  <div class="col-auto">
                    <div class="cart-img-item">
                      <img *ngIf="line?.image" src="{{ line?.image }}" alt="" />
                      <img *ngIf="!line?.image && !line.brand?.includes('Cisco')" src="/assets/images/noimage.png"
                        alt="" />
                      <img *ngIf="
                          !line?.image &&
                          (line.brand?.includes('cisco') ||
                            line.brand?.includes('Cisco'))
                        " src="/assets/images/cisco-networking.png" alt="" />
                    </div>
                  </div>
                  <div class="col ml-md-0 pl-0">
                    <div class="product-name-details">
                      <h4 class="name-title mb-0 pt-2">
                        {{ !line?.voucher_code ? line?.brand : "Voucher" }}
                      </h4>
                      <p class="att-details">{{ line?.product_name }}
                        <small *ngIf="line?.promotion_description" class="pt-0 ml-1 mr-1" >
                          <span class="badge badge-info" [innerHTML]="line?.promotion_description"></span>
                        </small>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-3">
              <p>{{ line?.payment_type?.name }}</p>
            </div>
            <div class="col-4">
              <div class="row align-items-center">
                <div class="col">
                  <div class="quantity d-flex align-items-center mb-2">
                    <div class="item-add-remove">
                      <div class="d-flex align-items-center">
                        <b *ngIf="!line?.voucher_code">x {{ line.quantity }}</b>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-auto">
                  <div class="gross-price pr-0 price-delete-option">
                    <h5 class="price-item-new current-price pr-2">
                      {{ line?.amount_ex_vat | currencyFormat }}
                    </h5>
                    <a *ngIf="!cartService.hideDeliveryOptionProductTypes.includes( line?.product_type)"
                      class="text-danger text-decoration-none cart-item-delete" href="javascript:void(0)"
                      (click)="deleteCartItem(line)">
                      <i class="ui-delete kpn-ui-icon icon-size-md"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row new-cart-tbody" *ngFor="let subLine of line.sub_lines; let j = index">
            <div class="col">
              <div class="td-product">
                <div class="row">
                  <div class="col-auto">
                    <div class="cart-img-item"></div>
                  </div>
                  <div class="col ml-md-0 pl-0">
                    <div class="product-subline-details">
                      <p class="att-details-name m-0">
                        {{ subLine?.product_name }}
                        <small *ngIf="subLine?.promotion_description" class="pt-0 ml-1 mr-1" >
                          <span class="badge badge-info" [innerHTML]="subLine?.promotion_description"></span>
                        </small>
                        <span *ngIf="!subLine?.product_code?.startsWith('tkh_')">
                          ({{ subLine?.product_code }})
                          <b>x {{ subLine?.quantity }}</b></span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div class="row align-items-center">
                <div class="col-auto">
                  <div class="total-price pr-0">
                    <h4 class="price-item-new-sub pr-2">
                      {{ subLine?.amount_ex_vat | currencyFormat }}
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row new-cart-tbody" *ngIf="line?.deliveryOptions.length">
            <div class="col">
              <div class="td-product">
                <div class="row">
                  <div class="col-auto">
                    <div class="cart-img-item"></div>
                  </div>
                  <div class="col ml-md-0 pl-0" *ngIf="
                      !cartService.hideDeliveryOptionProductTypes.includes(
                        line?.product_type
                      )
                    ">
                    <div class="product-subline-details">
                      <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
                        *ngIf="line?.deliveryOptions?.length > 1">
                        <select name="" class="form-control" id="" [(ngModel)]="line.selectedDeliveryOption"
                          (ngModelChange)="addLineDelivery(line)">
                          <option [ngValue]="null"> {{'ORDER_OVERVIEW.SELECT_DELIVERY_METHOD' | transloco }}</option>
                          <option *ngFor="let item of line?.deliveryOptions" [ngValue]="item">
                            {{ item?.name }}
                          </option>
                        </select>
                        <button class="btn" disabled>
                          <i class="kphicon-uniE926"></i>
                        </button>
                      </div>
                      <span *ngIf="line?.deliveryOptions?.length === 1">
                        <button class="flat-grey-light" placement="bottom" *ngIf="line?.delivery_option">
                          <h6 class="title-6 mb-0 mr-2">{{'ORDER_OVERVIEW.DELIVERY_METHOD' | transloco }}:</h6>
                          <span class="dark-orange-tag-pill mr-5">
                            <b> {{ line?.delivery_option?.name }}</b></span>
                        </button>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row new-cart-tbody"
            *ngIf="line?.extra?.contact_account_type || line?.extra?.comm_plan || line?.extra?.rate_plan">
            <div class="col">
              <div class="td-product">
                <div class="row">
                  <div class="col-auto">
                    <div class="cart-img-item"></div>
                  </div>
                  <div class="col ml-md-0 pl-0">
                    <p class="sub-title-light-5">
                      <small *ngIf="line?.extra?.contact_account_type" class="text-dark">
                        {{'ORDER_OVERVIEW.ACCOUNT_TYPE' | transloco}}: <b> {{
                          line?.extra?.contact_account_type?.account_id
                          }} <span
                            *ngIf="line?.extra?.contact_account_type?.account_type_code">({{line?.extra?.contact_account_type?.account_type_code}})</span>
                        </b>
                      </small><br>
                      <small class="text-dark" *ngIf="line?.extra?.is_default_rate_plan"><b> {{'CART.DEFAULT_RATE_PLAN'
                          | transloco}} </b><br>
                      </small>
                      <small class="text-dark" *ngIf="line?.extra?.comm_plan || line?.extra?.rate"> {{'CART.COMM_PLAN' |
                        transloco}}: <b>
                          {{line?.extra?.comm_plan}}
                        </b><br>
                      </small>
                      <small class="text-dark" *ngIf="line?.extra?.comm_plan || line?.extra?.rate"> {{'CART.RATE_PLAN' |
                        transloco}}: <b>
                          {{line?.extra?.rate |
                          currencyFormat}} </b>
                      </small>
                    </p>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="new-cart-box new-cart-voucher-box" *ngIf="cart?.lines?.length">
        <div class="row">
          <div class="col-md-8">
            <div class="voucher-item-orderoverview mb-3">
              <span *ngIf="addedVouchers?.length">
                <i class="kphicon-uniE91D"></i> Vouchercode
                {{ addedVouchers?.join(",") }} {{'ORDER_OVERVIEW.ADDED' | transloco }}
              </span>
              <span *ngIf="cart?.project">
                <i class="kphicon-uniE91D"></i>Project:
                {{ cart?.project?.name }} - {{'ORDER_OVERVIEW.REFERENCE_NUMBER' | transloco }}:
                {{ cart?.project_reference }}
              </span>
            </div>

            <p class="collapse-link mb-1" *ngIf="deliveryOptions.length === 1">
              <a href="javascript:void(0)"> {{'ORDER_OVERVIEW.ADDED_STANDARD_DELIVERY_METHOD' | transloco }}</a>
            </p>
            <div class="voucher-offer d-flex align-items-center justify-content-start mb-2"
              *ngIf="deliveryOptions.length === 1">
              <span>
                <button class="flat-grey-light" placement="bottom" *ngIf="cart?.delivery_option">
                  <h6 class="title-6 mb-0 mr-2">{{'ORDER_OVERVIEW.SELECT_DELIVERY_METHOD' | transloco }}:</h6>
                  <span class="dark-orange-tag-pill mr-5">
                    <b> {{ cart?.delivery_option?.name }}</b></span>
                </button>
              </span>
            </div>
            <p class="collapse-link mb-1" *ngIf="paymentOptions.length > 1">
              <a href="javascript:void(0)">
                <i class="kphicon-uniE907"></i> {{'ORDER_OVERVIEW.CHOOSE_PAYMENT_METHOD' | transloco }}</a>
            </p>
            <div class="voucher-offer mb-2" *ngIf="paymentOptions.length > 1">
              <div *ngFor="let payment of paymentOptions" ngbRadioGroup>
                <div class="radio_group">
                  <label ngbButtonLabel class="default-radio radio-flat mb-2">
                    <div class="radio-btn">
                      <input ngbButton name="payment_type" type="radio" [value]="payment.code"
                        (change)="changePaymentType(payment)" />
                      <span class="radioBullet"></span>
                    </div>
                    <span class="label">
                      {{ payment?.name }}
                    </span>
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-4">
            <div class="cart-total-box">
              <div class="total-price-line">
                <label>{{'ORDER_OVERVIEW.TOTAL' | transloco }}</label>
                <label>{{ cart?.total_amount_ex_vat | currencyFormat }}</label>
              </div>
              <div class="total-price-line">
                <label>{{'ORDER_OVERVIEW.VAT' | transloco }}</label>
                <label>{{ cart?.total_amount_vat | currencyFormat }}</label>
              </div>
              <div class="total-price-line total-price-line-bold">
                <label>{{'ORDER_OVERVIEW.TOTAL' | transloco }}</label>
                <label>{{ cart?.total_amount | currencyFormat }}</label>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="kpn-card confirmation-cart-overview pt-4" *ngIf="hasCpaas">
          <div class="col-md-12 col-12 col-gap-reduce mb-3">
            <div class="info-bar-blue mb-3 d-flex align-items-center justify-content-between">
              <p>
                <span class="kphicon-uniE952"></span>
                {{'ORDER_OVERVIEW.ACTIVE_C_PASS_ALERT' | transloco}}.
              </p>
              <button class="kphicon-uniE926"></button>
            </div>
          </div>
          <form [formGroup]="cPaasForm">
            <div class="row">
              <div class="col-md-3 col-6 col-gap-reduce">
                <div class="form-group kpn-form-group">
                  <label i18n="@@arm.order-status.ext-num">Email <span class="danger-text">*</span></label>
                  <input type="text" class="form-control kpn-form-control" formControlName="cpaas_user_email"
                    placeholder="" [ngClass]="{
                                'is-invalid':cPaasForm.controls.cpaas_user_email?.errors
                              }" />
                  <div *ngIf="cPaasForm.controls.cpaas_user_email.hasError('trimError')" class="invalid-feedback">
                    {{ cPaasForm.controls.cpaas_user_email.errors?.trimError?.value }}
                  </div>
                  <div *ngIf="cPaasForm.controls.cpaas_user_email?.errors" class="invalid-feedback">
                    <span
                      *ngIf="cPaasForm.controls.cpaas_user_email?.errors?.required">{{'ORDER_OVERVIEW.EMAIL_IS_REQUIRED'
                      | transloco}}
                    </span>
                    <span *ngIf="cPaasForm.controls.cpaas_user_email?.errors?.email">
                      {{'ORDER_OVERVIEW.THE_EMAIL_MUST_BE_A_VALID_EMAIL_ADDRESS' | transloco}}
                    </span>
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-6 col-gap-reduce">
                <div class="form-group kpn-form-group">
                  <label i18n="@@arm.order-status.int-num">{{'CUSTOMER.FIRST_NAME' | transloco }} <span
                      class="danger-text">*</span></label>
                  <input type="text" class="form-control kpn-form-control" formControlName="cpaas_user_first_name"
                    placeholder="" [ngClass]="{ 'is-invalid': cPaasForm.controls.cpaas_user_first_name?.errors }" />
                  <div *ngIf="cPaasForm.controls.cpaas_user_first_name?.errors" class="invalid-feedback">
                    {{ cPaasForm.controls.cpaas_user_first_name.errors?.required?.value || 'Voornaam is
                    verplicht' }}
                  </div>
                </div>
              </div>
              <div class="col-md-3 col-6 col-gap-reduce">
                <div class="form-group kpn-form-group">
                  <label i18n="@@arm.order-status.int-num">{{'SIGN_UP.LAST_NAME' | transloco}} <span
                      class="danger-text">*</span></label>
                  <input type="text" class="form-control kpn-form-control" formControlName="cpaas_user_last_name"
                    placeholder="" [ngClass]="{ 'is-invalid': cPaasForm.controls.cpaas_user_last_name?.errors }" />
                  <div *ngIf="cPaasForm.controls.cpaas_user_last_name?.errors" class="invalid-feedback">
                    {{ cPaasForm.controls.cpaas_user_last_name?.errors?.required?.value || 'Achternaam is
                    vereist' }}
                  </div>
                </div>
              </div>
              <div class="col-md-2 col-gap-reduce pt-3">
                <button type="button" class="btn btn-sm btn-block btn-primary mt-2 rounded-btn" (click)="addCpassData()"
                  [disabled]="!cPaasForm.valid || cPassAddLoader">
                  <span *ngIf="!cPassAddLoader; else showSpinner">{{'RMA_CREATE.ADD' | transloco }}</span>
                  <ng-template #showSpinner>
                    <div class="spinner-border spinner-border-sm field-spinner" role="status"></div>
                  </ng-template>
                </button>
              </div>
            </div>

            <div class="row align-items-center" *ngIf="cPassAddError">
              <div class="col-md-10 col-10 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{ cPassAddError }}
                  </p>
                  <button class="kphicon-uniE926"></button>
                </div>
              </div>
            </div>
          </form>
          <hr>
        </div>
        <div class="product-subline-details mb-2" *ngIf="cart?.is_contact_account_type_required">
          <p class="collapse-link mb-1">
            <a href="javascript:void(0)">
              <i class="kphicon-uniE907"></i> Selecteer Accounttype</a>
          </p>
          <div class="voucher-offer d-flex align-items-center justify-content-start mb-2">
            <select name="" class="form-control" id="account_type_id" [(ngModel)]="cart.contact_account_type_id"
              (ngModelChange)="updateAccountId()">
              <option [ngValue]="null">Selecteer account type</option>
              <option *ngFor="let item of accountTypes" [ngValue]="item.id">
                {{ item?.account_name }}
              </option>
            </select>
          </div>
          <hr>
        </div>
        <div class="row" *ngIf="termAndConditions?.length && !isQuoteSelected">
          <div class="col-md-10">
            <h3 class="title-5 mb-1 green-text">{{'ORDER_OVERVIEW.ACCEPT_THE_TERMS' | transloco }}</h3>
          </div>
          <div class="col-md-10 col-10">
            <div class="btn-group-toggle radio_group radio_group_list_type" ngbRadioGroup name="provider_items">
              <label ngbButtonLabel class="checkbox-item d-flex" *ngFor="let item of termAndConditions">
                <div class="checkbox-btn checkbox-btn-outline pt-1">
                  <input ngbButton type="checkbox" [value]="item.code" [(ngModel)]="item.checked"
                    (ngModelChange)="onChangeTermAndCondition(item)" />
                  <span class="checkBoxCheckmark"></span>
                </div>
                <div class="checkbox-label ml-2 text-left">
                  <span>
                    <small [innerHTML]="item?.description || item?.name"></small>
                  </span>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
      <div class="row align-items-center" *ngIf="orderError">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
            <p>
              <span class="kphicon-uniE952"></span>
              {{ orderError }}
            </p>
            <button class="kphicon-uniE926"></button>
          </div>
        </div>
      </div>
      <div class="row align-items-center p-4" *ngIf="quoteCreateMessage">
        <div class="col-md-12 col-12 col-gap-reduce">
          <div class="mb-3 d-flex align-items-center justify-content-between"
            [ngClass]="hasQuoteCreationPermission ? 'info-bar-blue' : 'info-bar-red'">
            <p>
              <span class="kphicon-uniE952"></span>
              <b>{{ quoteCreateMessage }}</b>
            </p>
          </div>
        </div>
      </div>
      <div class="info-bar-red  px-3 pt-3" *ngIf="!cart?.is_valid">
        <div class="mb-3 d-flex align-items-center justify-content-between">
          <p [innerHTML]="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER ? eMessages.NO_VAT_NUMBER_MSG:
            eMessages.INVALID_CART">
          </p>
        </div>
        <app-vat-number-input *ngIf="cart?.invalid_reasons === eMessages.NO_VAT_NUMBER"
          (reFetchCartCart)="reFetchCart($event)"></app-vat-number-input>
      </div>
      <div class="row align-items-center justify-content-between pt-4">
        <div class="col-auto d-flex" (click)="onBack()">
          <button class="btn btn-outline-primary">{{'ORDER_OVERVIEW.CONTINUE_SHOPPING' | transloco }}</button>
        </div>
        <div class="col-auto">
          <ng-template #tipContent>
            <span *ngIf="
                siteProfile?.is_po_number_required ||
                siteProfile?.is_reference_required
              ">
              {{'ORDER_OVERVIEW.SELECT' | transloco }}
            </span>
            <span *ngIf="siteProfile?.is_po_number_required">
              {{ siteProfile?.po_number_label }}
            </span>
            <span *ngIf="
                siteProfile?.is_po_number_required &&
                siteProfile?.is_reference_required
              ">
              en
            </span>
            <span *ngIf="siteProfile?.is_reference_required">
              {{ siteProfile?.reference_label }}
            </span>
          </ng-template>
          <span>
            <button placement="top" [ngbTooltip]="'Plaats Offerte'"
              *ngIf="hasQuoteCreationPermission && (isOffer || isQuoteSelected)"
              class="btn btn-primary rounded-btn mb-2 mr-2" (click)="createOrder()" [disabled]="!cart?.is_valid || shouldShowSpinner || poNumber?.trim()?.length > 14 ||
                customer_reference?.trim()?.length > 256 || (special_discount_requested ? special_discount_reason.trim()?.length === 0 : false)||
                  (hasCpaas && (!cPaasForm.valid || !cPassAdded))">
              <span *ngIf="shouldShowSpinner" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
              Plaats Offerte
            </button>
            <button *ngIf="!isOffer && !isQuoteSelected" placement="top" [ngbTooltip]="'Plaats bestelling'"
              class="btn btn-primary rounded-btn mb-2 mr-2" (click)="createOrder()"
              [disabled]="!cart?.lines?.length || !cart?.is_valid || shouldShowSpinner || (poNumber?.trim()?.length > 14) ||(customer_reference?.trim()?.length > 256) ||
                            (!poNumber && siteProfile?.is_po_number_required) ||
                             (!customer_reference && siteProfile?.is_reference_required) ||
                             !termAccepted || (hasCpaas && (!cPaasForm.valid || !cPassAdded)) || (cart?.is_contact_account_type_required && !cart?.contact_account_type_id)">
              <span *ngIf="shouldShowSpinner" class="spinner-border spinner-border-sm mr-2" role="status"
                aria-hidden="true"></span>
              {{'ORDER_OVERVIEW.PLACE' | transloco }} {{'ORDER_OVERVIEW.ORDER' | transloco }}
            </button>
          </span>
        </div>
      </div>
    </ng-template> -->

  </div>
</section>
