import { Injectable } from '@angular/core';
import { Actions, createEffect, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { PermissionService } from 'src/app/shared/services/permission.service';
import { StorageService } from 'src/app/shared/storage.service';
import { EPermissionActions, PermissionLoadAction, PermissionSuccessAction } from '../actions/permission.actions';

@Injectable()
export class PermissionEffects {
    constructor(
        private storageService: StorageService,
        private permissionService: PermissionService,
        private actions$: Actions
    ) {}

    loadPermission$ = createEffect(
        () => this.actions$.pipe(
            ofType<PermissionLoadAction>(EPermissionActions.PERMISSION_LOAD),
            switchMap(() => {
                return this.permissionService.getPermission();
            }),
            switchMap((resp: any) => {
                const permissions = resp.data?.results;
                this.storageService.setPermission(permissions);
                return of(new PermissionSuccessAction(permissions));
            }),
            catchError((error, caught) => {
                return caught;
            })
        )
    );
}
