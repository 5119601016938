<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="customer-search kpn-card mb-4">
        <div class="row mb-3">
          <div class="col">
            <h4 class="title-4 green-text">{{rma_type | titlecase}}</h4>
          </div>
        </div>

        <div class="row align-items-top">
          <div class="col-md gutter-col-15">
            <label class="title-semibold-5 green-text">RMA  {{'RMA_CREATE.OPTIONS' | transloco }}</label>
            <div class="form-group kpn-form-group">
              <div class="custom-select-option custom-option-align-height">
                <select [disabled]="rmaCreatePayload?.lines?.length" name="rma-type" id="rma-type"
                  class="full-width form-control kpn-form-control" [(ngModel)]="rmaLine.rma_type"
                  (ngModelChange)="selectReason(rmaLine.rma_type)">
                  <option [ngValue]="null">{{'RMA_CREATE.SELECT_RMA_OPTION' | transloco }}</option>
                  <option *ngFor="let item of rma_options" [ngValue]="item.code">{{item?.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md gutter-col-15">
            <label class="title-semibold-5 green-text">RMA {{'RMA_CREATE.REASON' | transloco }}</label>
            <div class="form-group kpn-form-group">
              <div class="custom-select-option custom-option-align-height">
                <select [disabled]="disableReason" name="rma-type" id="rma-type"
                  class="full-width form-control kpn-form-control" [(ngModel)]="rmaLine.return_reason_type">
                  <option [ngValue]="null"> {{'RMA_CREATE.SELECT_RMA_REASON' | transloco }}</option>
                  <option *ngFor="let item of rma_reasons" [ngValue]="item.code">{{item?.name}}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="col-md">
            <label class="title-semibold-5 green-text">Product</label>
            <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
              <div class="form-group kpn-form-group">
                <div
                  class="kpn-form-group handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                  [ngClass]="{'search-active' : searchFocused}">
                  <i class="search-icon kpn-ui-icon ui-search"></i>
                  <div class="ng-autocomplete">
                    <ng-autocomplete #productAutoComplete [data]="searchProducts" [placeholder]="'RMA_CREATE.SEARCH_PRODUCT' | transloco"
                      [searchKeyword]="'name'" (inputChanged)='onSearchProduct($event)' [disabled]="!rmaLine.rma_type"
                      (inputCleared)="searchProducts = []; selectedProduct = null; " (selected)='selectEvent($event)'
                      (focusin)='onFocused()' [itemTemplate]="itemTemplate" [isLoading]="isLoadingScanIAN"
                      [debounceTime]="600" (keyup.enter)="selectEvent($event)" [notFoundTemplate]="notFoundTemplate"
                      (focusout)="onFocusedOut()">
                    </ng-autocomplete>
                    <ng-template #itemTemplate let-item>
                      <a [innerHTML]="item.name"></a>
                    </ng-template>
                    <ng-template #notFoundTemplate let-NotFound>
                      <div class="text-center" [innerHTML]="NotFound"></div>
                    </ng-template>
                  </div>
                  <span class="danger-text" *ngIf="!rmaLine.rma_type">
                    <small>  {{'RMA_CREATE.FIRST_CHOOSE_THE_TYPE_OF_RMA' | transloco }}</small>
                  </span>
                  <span class="danger-text"
                    *ngIf="selectedProduct && selectedProduct?.repair_allowed && rmaLine.rma_type === eRmaTypes.GUARANTEE_PERIOD">
                    <small>  {{'RMA_CREATE.PRODUCT_CAN_NOT_RETURN_ALERT_TEXT' | transloco }}</small>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md gutter-col-15">
            <label class="title-semibold-5 green-text">{{'RMA_CREATE.PRICE' | transloco }}</label>
            <div class="form-group kpn-form-group">
              <input type="text" mask="separator.2" disabled class="form-control kpn-form-control"
                [(ngModel)]="rmaLine.price">
            </div>
          </div>
          <div class="col-md-2 gutter-col-15" *ngIf="selectedProduct?.is_serial_keeping">
            <label class="title-semibold-5 green-text">{{'RMA_CREATE.SERIAL_NUMBER' | transloco }}</label>
            <div class="form-group kpn-form-group">
              <input type="text" class="form-control kpn-form-control" mask="AAAA-AAAA-AAAA" [showMaskTyped]="true"
                [dropSpecialCharacters]="false" [(ngModel)]="rmaLine.serial_number">
            </div>
          </div>
        </div>

        <div class="row align-items-end">
          <div class="col-md-6 gutter-col-15">
            <label class="title-semibold-5 green-text">{{'RMA_CREATE.REASON_OF_RETURN' | transloco }}</label>
            <div class="form-group kpn-form-group">
              <textarea class="form-control" name="" id="" rows="4" [(ngModel)]="rmaLine.user_reason"></textarea>
            </div>
          </div>
          <div class="col-md-auto gutter-col-15 pt-4 ">
            <button type="button" [disabled]="!selectedProduct ||
            !rmaLine.rma_type ||
            !rmaLine.return_reason_type ||
            (selectedProduct?.is_serial_keeping && rmaLine.serial_number?.trim()?.length<14) ||
            !selectedProduct?.swap_allowed" class="btn btn-primary btn-block mt-1 px-4 mb-3"
              (click)="addProduct()">{{'RMA_CREATE.ADD' | transloco }}</button>
          </div>
        </div>

        <div class="min-height-auto nagetive-mx-15" *ngIf="rmaCreatePayload?.lines?.length">
          <div>
            <div class="table-responsive custom-table custom-table-small row-click-able border-less">
              <table class="table">
                <thead>
                  <tr>
                    <th class="green-text">Product</th>
                    <th class="green-text">RMA {{'RMA_CREATE.OPTION' | transloco }}</th>
                    <th class="green-text">RMA {{'RMA_CREATE.REASON' | transloco }}</th>
                    <th class="green-text">{{'RMA_CREATE.CUSTOMER_REASON' | transloco }}</th>
                    <th class="green-text">{{'RMA_CREATE.SERIAL_NUMBER' | transloco }}</th>
                    <th class="green-text">{{'RMA_CREATE.PRICE' | transloco}}</th>
                    <th class="green-text" width="60"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let item of rmaCreatePayload?.lines; let i = index">
                    <td>{{item?.product_name}}</td>
                    <td>{{getRmaTypeName(item?.rma_type)?.name}}</td>
                    <td>{{getRmaReasonName(item?.return_reason_type)?.name}}</td>
                    <td width="500">{{item?.user_reason}}</td>
                    <td>{{item?.serial_number}}</td>
                    <td>{{item?.price | currencyFormat}}</td>
                    <td class="text-center">
                      <i class="ui-delete kpn-ui-icon icon-size-sm" (click)="removeItem(i)"></i>

                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row justify-content-between flex-direction-column pt-4">
              <div class="col-md-auto mb-1" *ngIf="siteProfile?.is_po_number_required">
                <label class="form-label-bold dark-grey-text mb-0">
                  {{siteProfile?.po_number_label || 'PO Number'}} <sup class="danger-text">{{
                    siteProfile?.is_po_number_required ? '*':'' }}</sup>
                  <span class="text-muted">
                    <small>({{'RMA_CREATE.MIN_3_AND_MAX_14_CHARACTERS' | transloco}})</small>
                  </span>
                </label>
                <div class="kpn-form-group">
                  <div class="field-with-spinner">
                    <div class="po-number d-flex align-items-center justify-content-start mb-0">
                      <input type="text" [(ngModel)]="rmaCreatePayload.po_number"
                        class="form-control kpn-form-control reference-field"
                        placeholder="{{siteProfile?.po_number_label || 'PO Number'}}" [ngClass]="{'is-invalid': rmaCreatePayload?.po_number?.trim()?.length > 14 ||
                        rmaCreateError?.po_number}" />
                      <small></small>
                    </div>
                  </div>
                  <span class="danger-text" *ngIf="rmaCreateError?.po_number">
                    <small> {{rmaCreateError?.po_number[0] || rmaCreateError?.po_number}} </small>
                  </span>
                </div>
              </div>
              <div class="col-md-auto mb-1" *ngIf="siteProfile?.is_reference_required">
                <label class="form-label-bold dark-grey-text mb-0">
                  {{siteProfile?.reference_label || "CustomerReference"}} <sup class="danger-text">{{
                    siteProfile?.is_reference_required ? '*':'' }}</sup>
                  <span class="text-muted">
                    <small>({{'RMA_CREATE.MIN_3_AND_MAX_30_CHARACTERS' | transloco}})</small>
                  </span>
                </label>
                <div class="kpn-form-group">
                  <div class="field-with-spinner">
                    <div class="po-number d-flex align-items-center justify-content-start mb-0">
                      <input type="text" [(ngModel)]="rmaCreatePayload.customer_reference"
                        class="form-control kpn-form-control reference-field"
                        placeholder="{{siteProfile?.reference_label || 'Klant Referentie'}}" [ngClass]="{'is-invalid': rmaCreatePayload?.customer_reference?.trim()?.length > 45 ||
                        rmaCreateError?.customer_reference }" />
                    </div>
                  </div>
                  <span class="danger-text" *ngIf="rmaCreateError?.customer_reference">
                    <small> {{rmaCreateError?.customer_reference[0] || rmaCreateError?.customer_reference}} </small>
                  </span>
                </div>
              </div>
              <div class="col-md-auto mb-1">
                <label class="form-label-bold dark-grey-text mb-0">
                  {{'RMA_CREATE.ORIGINAL_ORDER_NUMBER' | transloco}} <sup class="danger-text"><b> *</b></sup>
                </label>
                <div class="kpn-form-group">
                  <div class="field-with-spinner">
                    <div class="po-number d-flex align-items-center justify-content-start mb-0">
                      <input type="text" [(ngModel)]="rmaCreatePayload.original_order_number"
                        class="form-control kpn-form-control reference-field" [placeholder]="'RMA_CREATE.ORIGINAL_ORDER_NUMBER' | transloco"
                        [ngClass]="{'is-invalid': rmaCreateError?.original_order_number }" />
                    </div>
                  </div>
                  <span class="danger-text" *ngIf="rmaCreateError?.original_order_number">
                    <small> {{rmaCreateError?.original_order_number[0] || rmaCreateError?.original_order_number}}
                    </small>
                  </span>
                </div>
              </div>
              <div class="col-md-auto mb-1">
                <label class="form-label-bold dark-grey-text mb-0">
                  Partner {{'RMA_CREATE.NUMBER' | transloco}} <sup class="danger-text"><b> *</b></sup>
                </label>
                <div class="kpn-form-group">
                  <div class="field-with-spinner">
                    <div class="po-number d-flex align-items-center justify-content-start mb-0">
                      <input type="text" [(ngModel)]="rmaCreatePayload.partner_number"
                        [ngClass]="{'is-invalid': rmaCreateError?.partner_number }"
                        class="form-control kpn-form-control reference-field" [placeholder]="'RMA_CREATE.PARTNER_NUMBER' | transloco" />
                    </div>
                  </div>
                  <span class="danger-text" *ngIf="rmaCreateError?.partner_number">
                    <small> {{rmaCreateError?.partner_number[0] || rmaCreateError?.partner_number}}
                    </small>
                  </span>
                </div>
              </div>
            </div>

            <div class="row align-items-center p-1" *ngIf="rmaCreateGenericError">
              <div class="col-md-12 col-12 col-gap-reduce">
                <div class="info-bar-red mb-3 d-flex align-items-center justify-content-between">
                  <p>
                    <span class="kphicon-uniE952"></span>
                    {{rmaCreateGenericError}}
                  </p>
                </div>
              </div>
            </div>

            <div class=" text-right pt-2 mb-1">
              <button type="button" [disabled]="!rmaCreatePayload.contact_person_uuid || !rmaCreatePayload.contact_uuid ||
                !rmaCreatePayload?.lines?.length || rmaCreateLoader" class="btn btn-primary mt-1"
                (click)="createRma()">
                <span class="spinner-border spinner-border-sm mr-2" *ngIf="rmaCreateLoader" role="status"
                  aria-hidden="true"></span>
                  {{'RMA_CREATE.INITIATE_RETURN' | transloco}}
              </button>
              <div class=" text-right pt-1"
                *ngIf="!rmaCreatePayload.contact_person_uuid || !rmaCreatePayload.contact_uuid">
                <span class="danger-text">
                  <small *ngIf="!rmaCreatePayload.contact_person_uuid">   {{'RMA_CREATE.CONTACT_SELECT_ALERT_TEXT_1' | transloco}}</small>
                  <small *ngIf="!rmaCreatePayload.contact_uuid"> {{'RMA_CREATE.CONTACT_SELECT_ALERT_TEXT_2' | transloco}}  </small>
                </span>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>
  </section>
</div>
