import { createSelector } from '@ngrx/store';
import { IAppState } from 'src/app/app.state';

export const selectChildGroup = (state: IAppState) => state.childGroup;
export const selectAChildGroup = (state: IAppState) => state.selectedChildGroup;


export const childGroupList = createSelector(
    selectChildGroup,
    (childGroup: any, props: any) => {
        return childGroup;
    }
);

export const selectedChildGroup = createSelector(
    selectAChildGroup,
    (childGroup: any, props: any) => {
        return childGroup;
    }
);

