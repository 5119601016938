<div class="bg-kpn-subtle-grey body-container">
  <section class="res-search-section res-search-section-calc pt-5">
    <div class="container">
      <div class="row mb-2">
        <div class="col-7">
          <div class="autocomplete-search-filter select-list-inquote radius-0 block-width my-0">
            <div class="form-group kpn-form-group">
              <div class="kpn-form-group handset-search-field auto-complete-border rounded-0 default-search-field mb-0"
                [ngClass]="{'search-active' : searchFocused}">
                <i class="search-icon kpn-ui-icon ui-search"></i>
                <div class="ng-autocomplete">
                  <ng-autocomplete class="" [data]="customerList" [placeholder]="'CUSTOMER_LIST.SEARCH_BY_COMPANY_NAME_OR_CODE' | transloco"
                    [searchKeyword]="keyword" (selected)='onSelectedCompany($event)'
                    (inputChanged)='searchCustomer($event)' (focusin)='onFocused()' (focusout)="onFocusedOut()"
                    [itemTemplate]="itemTemplate" [isLoading]="isLoading" (inputCleared)="searchCleared()"
                    [debounceTime]="600" [notFoundTemplate]="notFoundTemplate">
                  </ng-autocomplete>

                  <ng-template #itemTemplate let-item>
                    <a [innerHTML]="item.label"></a>
                  </ng-template>

                  <ng-template #notFoundTemplate let-notFound>
                    <div [innerHTML]="notFound"></div>
                  </ng-template>
                </div>
                <span class="text-muted">
                  <small>{{'REPORT.MIN_3_CHARACTERS' | transloco}}</small>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="loader-wrapper box-shadow-sm" [ngClass]="{'spinner-wrapper-height': isLoading}">
        <div class="table-responsive custom-table">
          <h3 class="title-3 green-text ml-4 pt-3" i18n="@@arm.order-status.order-list">{{'PROFILE_DROPDOWN.CHOOSE_CUSTOMER_LINK_TEXT' | transloco }}:</h3>
          <div class="kpn-spinner" *ngIf="isLoading;else table_content">
            <div class="text-center">
              <img class="mb-3" src="/assets/images/kpn-loader.gif" alt="isLoading">
              <p>{{'CUSTOMERS.SEARCH' | transloco}}…</p>
            </div>
          </div>

          <ng-template #table_content>
            <table class="table mb-5">
              <thead>
                <tr>
                  <th>COC number</th>
                  <th>{{'CUSTOMER_LIST.COMPANY' | transloco}} code</th>
                  <th>{{'CUSTOMER_LIST.COMPANY' | transloco}}</th>
                  <th>Contact</th>
                  <th width="250px" class="text-center"></th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let customer of customerList | paginate: config">
                  <td>{{customer?.coc_number}}</td>
                  <td>{{customer?.company_code}}
                  </td>
                  <td>{{customer?.company_name}}
                    <span *ngIf="!customer?.is_active" class="dark-orange-tag-pill"> {{'CUSTOMERS.INACTIVE' | transloco}}</span>
                  </td>
                  <td>{{customer?.contact_name}}
                  </td>
                  <td>
                    <button [disabled]="!customer?.is_active"
                      class="btn btn-primary outline-icon-btn pt-0 pb-0 rounded-btn" (click)="selectCustomer(customer)">
                      {{'CUSTOMER_LIST.SELECTING' | transloco}}
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            <div class="pagination-new" *ngIf="config.totalItems">
              <pagination-controls previousLabel="{{'PAGINATION.PREVIOUS' | transloco }}" nextLabel="{{'PAGINATION.NEXT' | transloco }}" responsive="true"  class="pagination d-flex justify-content-center" (pageChange)="onPageClick($event)">
              </pagination-controls>
            </div>
          </ng-template>
        </div>
      </div>
    </div>
  </section>
</div>
