<div class="new-cart-box">
  <h3 class="title-4 green-text mb-2">{{'PAYMENT.CHECKOUT_METHOD' | transloco}}</h3>
  <form [formGroup]="idealPaymentForm" (ngSubmit)="initiatePayment()">
  <div class="bg-white tri-shadow-xl tri-rounded-20 p-40 mb-4" *ngIf="paymentMethods.length">
    <div class="payment-option mb-2" *ngFor="let payment of paymentMethods;let i = index">
      <div class="d-flex align-items-center payment-option-radio">
        <input type="radio" name="payment-option" [id]="i+'payment-type'" [value]="payment.code"
          (change)="onPaymentMethodChange(payment)">
        <label [for]="i+'payment-type'" class="d-flex align-items-center font-kpn-extended fs-20">
          <span class="radio-bullet"></span>
          {{ payment?.name }} </label>
        <span class="payment-logo" *ngIf="payment?.code === 'ideal_talos'">
          <img src="../../../assets/images/ideal-payment-icon.png" alt="iDeal">
        </span>
      </div>
      <div class="kpn-form-group" *ngIf="payment?.code === 'ideal_talos'">
        <div class="row">
          <div class="col-md-6">



              <div class="select-option-default btn-block pt-3" *ngIf="selectedPaymentMethod?.code === 'ideal_talos'">
                <select formControlName="issuer" [attr.disabled]="!selectedPaymentMethod ? 'disabled' : null"
                  class="form-control tri-form-control dark-border kpn-form-control">
                  <!-- <option value="Kies je bannk" disabled>Kies je bannk</option> -->
                  <option *ngFor="let item of issuerList" [ngValue]="item.value">{{item?.name}}</option>
                </select>
                <div class="invalid-feedback" *ngIf="idealPaymentForm.get('issuer').errors">
                  <div *ngIf="idealPaymentForm.get('issuer').errors?.required">
                      {{'PAYMENT.CHOOSE_YOUR_BANK' | transloco}}
                  </div>
                </div>
              </div>

          </div>
        </div>
      </div>
      <div class="pt-3" *ngIf="((payment?.current_value)) && (selectedPaymentMethod?.code === payment?.code)">
        <div class="blue-warning-qoute d-flex">
          <i class="kphicon-uniE952 note-icon mr-3"></i>
          <p class="font-kpn-extended m-0 line-height-24">
            <span class="d-block font-kpn-bold fs-20">Betaal uit depot</span>
            <span class="d-block font-kpn-extended">{{'PAYMENT.AVAILABLE_FUND' | transloco}}: {{ payment?.current_value |
              currencyFormat }}</span>
               <span class="label text-danger" *ngIf="payment?.code === DEPOT && DEPOT_NOT_FOUNT">
                {{DEPOT_NOT_FOUNT}} </span>
          </p>
        </div>
      </div>
    </div>
    <div class="">
      <label class="title-semibold-5 green-text"></label>
      <div class="kpn-form-group">
        <button class="btn btn-sm btn-success "
          [disabled]="!selectedPaymentMethod || paymentInitiated || idealPaymentForm?.status === 'INVALID'">
          {{'PAYMENT.PAY_NOW' | transloco}}
          <span class="spinner-border spinner-border-sm mr-2" *ngIf="paymentInitiated" role="status"
            aria-hidden="true"></span>
        </button>
      </div>
    </div>
  </div>
</form>
<div class="note-box note-box-red mb-2" *ngIf="paymentInitiateError">
  <span class="circle-hint-icon">i</span>
  <p class="body-text-1 mb-0">
    {{paymentInitiateError}}
  </p>
</div>
<div class="col-md-10" *ngIf="!paymentMethods?.length">
  <div class="col-md-12 mt-2 pt-1">
    <div class="info-bar-blue mb-1 d-flex align-items-center justify-content-between">
      <h6>
        <span class="kphicon-uniE952"></span>
        {{'PAYMENT.SORRY_NO_PAYMENT_METHOD_AVAILABLE_NOW'}}.
      </h6>
    </div>
  </div>
</div>

  <!-- <div class="row">
    <div class="col-md-4" *ngIf="paymentMethods?.length">
      <div  name='payment' class="payment-option mb-2" *ngFor="let method of paymentMethods;let i = index">
        <div class="d-flex align-items-center payment-option-radio">
          <input [ngModel]="selectedPaymentMethod"  [disabled]="!method?.selectable" type="radio" name="payment-option" [id]="i+'payment-type'" [value]="method"
            (click)="onPaymentMethodChange(method)">
          <label [for]="i+'payment-type'" class="d-flex align-items-center font-kpn-extended">
            <span class="radio-bullet"></span>
            {{ method?.name }} </label>
            <span class="label" *ngIf="method?.current_value"> {{'PAYMENT.AVAILABLE_FUND' | transloco}} <b> {{method?.current_value |
              currencyFormat}} </b></span>
          <span class="label text-danger" *ngIf="method?.code === DEPOT && DEPOT_NOT_FOUNT">
            {{DEPOT_NOT_FOUNT}} </span>
        </div>


      </div>

      <div class="address-box flex-box-full-width">
        <form [formGroup]="idealPaymentForm" (ngSubmit)="initiatePayment()">
          <div class="row mb-4 gutter-row-15">
            <div class="col-md-12 gutter-col-15" *ngIf="selectedPaymentMethod?.code === TALOS_PAYMENT">
              <label class="title-semibold-5 green-text">{{'PAYMENT.SELECT_YOUR_BANK' | transloco}}</label>
              <div class="kpn-form-group">
                <div class="select-option-default btn-block">
                  <select formControlName="issuer" [attr.disabled]="!selectedPaymentMethod ? 'disabled' : null"
                    class="form-control kpn-form-control"
                    [ngClass]="{ 'is-invalid': (idealPaymentForm.get('issuer').errors) }">
                    <option *ngFor="let item of issuerList" [ngValue]="item.value">{{item?.name}}</option>
                  </select>
                  <div class="invalid-feedback" *ngIf="idealPaymentForm.get('issuer').errors">
                    <div *ngIf="idealPaymentForm.get('issuer').errors?.required">
                        {{'PAYMENT.CHOOSE_YOUR_BANK' | transloco}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-auto gutter-col-15">
              <label class="title-semibold-5 green-text"></label>
              <div class="kpn-form-group">
                <button class="btn btn-sm btn-success float-right"
                  [disabled]="!selectedPaymentMethod || paymentInitiated || idealPaymentForm?.status === 'INVALID'">
                  {{'PAYMENT.PAY_NOW' | transloco}}
                  <span class="spinner-border spinner-border-sm mr-2" *ngIf="paymentInitiated" role="status"
                    aria-hidden="true"></span>
                </button>
              </div>
            </div>
          </div>
        </form>
        <div class="note-box note-box-red mb-2" *ngIf="paymentInitiateError">
          <span class="circle-hint-icon">i</span>
          <p class="body-text-1 mb-0">
            {{paymentInitiateError}}
          </p>
        </div>
      </div>
    </div>
    <div class="col-md-10" *ngIf="!paymentMethods?.length">
      <div class="col-md-12 mt-2 pt-1">
        <div class="info-bar-blue mb-1 d-flex align-items-center justify-content-between">
          <h6>
            <span class="kphicon-uniE952"></span>
            {{'PAYMENT.SORRY_NO_PAYMENT_METHOD_AVAILABLE_NOW'}}.
          </h6>
        </div>
      </div>
    </div>
  </div> -->
</div>


 <!-- <div  name='payment'
        class="radio_group radio-group-blue radio_group_box radio_group_list_type mb-1">
        <label *ngFor="let method of paymentMethods; let i = index"  class="default-radio mb-2">
          <div class="radio-btn">
            <input [disabled]="!method?.selectable" (click)="onPaymentMethodChange(method)" ngbButton type="radio"
              [value]="method">
            <span class="radioBullet"></span>
          </div>
          <span class="label">{{method?.name}}</span>
          <span class="label" *ngIf="method?.current_value"> {{'PAYMENT.AVAILABLE_FUND' | transloco}} <b> {{method?.current_value |
              currencyFormat}} </b></span>
          <span class="label text-danger" *ngIf="method?.code === DEPOT && DEPOT_NOT_FOUNT">
            {{DEPOT_NOT_FOUNT}} </span>
        </label>
      </div> -->
