import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from 'src/app/shared/storage.service';

@Component({
  selector: 'app-trade-in-finished',
  templateUrl: './trade-in-finished.component.html',
  styleUrls: ['./trade-in-finished.component.scss']
})
export class TradeInFinishedComponent {
  public order_uuid = null;
  public tradeInBusinessPartner: any = null;
  constructor(
    private router: Router,
    private activateRoute: ActivatedRoute,
    private storageService: StorageService,

  ) {
    this.activateRoute.params.subscribe(param => {
      this.order_uuid = param?.order_uuid;
    })
    this.storageService.removeReComCart();
    this.setTradeInBusinessPartner();


  }
  setTradeInBusinessPartner() {
    this.tradeInBusinessPartner = this.storageService.getTradeInBusinessPartner();
  }
  goToOrderDetails() {

    this.router.navigate([`trade-in/order/${this.order_uuid}`], {
      queryParams: {},
      queryParamsHandling: 'merge'
    });
  }
  goToHome() {
    this.router.navigate(['/']);
  }
}
